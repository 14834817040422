/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './style.css';

// import './App.css';
export const LoadCheckoutStripe = async (state) => { 
  const cartt = state.appState.cart.items;
  const userRaw = localStorage.getItem('user');
  const userinfo = JSON.parse(userRaw);
  for (let i = 0; i < cartt.length; i += 1) {
    cartt[i].dlc = { "id": cartt[i].dlc.id };
    cartt[i].originaluser = { "id": cartt[i].originaluser.id };
    cartt[i].user = { "id": cartt[i].user.id };
  }
  let couponCode = '';
  couponCode = state.appState.cart.coupon.coupon ? state.appState.cart.coupon.coupon.id : '';
  // const coupon = JSON.parse(localStorage.getItem('coupon'));
  const oudata = couponCode !== '' ? {
    withCredentials: true, cart: cartt, user: userinfo.app_metadata.stripeId, coupon: couponCode, 
    } : {
    withCredentials: true, cart: cartt, user: userinfo.app_metadata.stripeId, 
    };
  console.log(oudata);
  const headers = {
  'Access-Control-Allow-Origin': '*',
  }
  const outlink = await axios.post(`${process.env.REACT_APP_API }/create-checkout-session`, oudata, { headers });
  const checkoutpage = outlink.data;
  const checkoutpageurl = checkoutpage.url;
  localStorage.setItem('checkoutpage', JSON.stringify(checkoutpage));
  console.log(checkoutpage);
  console.log(checkoutpageurl);
  window.location.href = checkoutpageurl;
};
export const ProductDisplay = () => {
  // const async function with an axios post request to the server localhost:6002/create-checkout-session
  const t = 0;
 
  // axios post async function that returns the json result
  

return (
  <section>
    <div className="product">
      <Logo />
      <div className="description">
        <h3>Starter plan</h3>
        <h5>$20.00 / month</h5>
      </div>
    </div>
    <form action={`${process.env.REACT_APP_API }/create-checkout-session`} method="POST">
      {/* Add a hidden field with the lookup_key of your Price */}
      <input className="stripeinput" type="hidden" name="lookup_key" value="7348hlk8d3" />
      
      
      <button className="stripebutton" id="checkout-and-portal-button" onClick={LoadCheckoutStripe} type="button">
        Checkout
      </button>
    </form>
  </section>
); 
};
export const SuccessDisplay = ({ sessionId }) => {
        // const async function with an axios post request to the server localhost:6002/create-checkout-session
        const nextClicked = async () => { await axios.post(`${process.env.REACT_APP_API }/create-portal-session`, { session_id: sessionId }); };

        // axios post async function that returns the json result
        

    // console.log(sessionId);
    return (
    
      <section>
        <div className="product Box-root">
          <Logo />
          <div className="description Box-root">
            <h3>Subscription to starter plan successful!</h3>
          </div>
        </div>
        <form action={`${process.env.REACT_APP_API }/create-portal-session`} method="POST">
          <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
          />
          <button className="stripebutton" id="checkout-and-portal-button" onClick={nextClicked} type="button">
            Manage your billing information
          </button>
        </form>
      </section>
  );
    };

  SuccessDisplay.propTypes = {
    sessionId: PropTypes.string,
  };
  
  SuccessDisplay.defaultProps = {
    sessionId: '00000',
  };
  

  export const Message = ({ message }) => (
    <section>
      <p>{message}</p>
    </section>
);

Message.propTypes = {
    message: PropTypes.string,
  };
  
  Message.defaultProps = {
    message: '00000',
  };
  
export default function Checkout() {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready.",
      );
    }
  }, [sessionId]);

  if (!success && message === '') {
    return <ProductDisplay />;
  } if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } 
    return <Message message={message} />;
}

export const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14px"
    height="16px"
    viewBox="0 0 14 16"
    version="1.1"
  >
    <defs />
    <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="0-Default"
        transform="translate(-121.000000, -40.000000)"
        fill="#E184DF"
      >
        <path
          d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
          id="Pilcrow"
        />
      </g>
    </g>
  </svg>
);

