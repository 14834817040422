import { useState, useEffect } from 'react';

export const addListener = (signal, slot) => {
    document.addEventListener(signal, slot);
}

export const removeListener = (signal, slot) => {
    document.removeEventListener(signal, slot);
}

export const emitSignal = (signal, data= {}) => {
    document.dispatchEvent(new CustomEvent(signal, {detail: data}));
}
export const emitGetSignal = (signal,data={}, onFinished=(e)=>{}) => {
    document.dispatchEvent(new CustomEvent(signal, {detail: {data:data, onFinished:onFinished}}));
}

export const emit = (signal, data = {}) => {
    emitSignal(signal, data);
}

export const emitSignalWithDelay = (signal, data= {}, delay) => {
    console.log("emitSignalWithDelay 1", signal);

    setTimeout(() => {
        console.log("emitSignalWithDelay");
        emitSignal(signal, data);
    }, delay);
}

export const registerListener = (signal, slot) => {
    addListener(signal, slot);
    return () => {
        removeListener(signal, slot);
    }
}

export const useSignals = (signals = []) => {

    const [initialized, setInitialized] = useState(false);

    const init = () => {
        signals.forEach(signal => {
            registerListener(signal[0], signal[1]);
        });
        setInitialized(true);
    }
    
    useEffect(() => {
        if (!initialized) {
            init();
        }
    }, []);

    return {initialized, emitSignal, emitSignalWithDelay, addListener, removeListener, registerListener, emit, emitGetSignal};




}