
import { useState, useEffect, useRef } from 'react';
import AudioSplitForm from '../../../../components/banner/AudioSplitForm';

import FXDropdown from '../../../components/FXDropdown';
import useAppManager from '../../../api/appManager';
import useFXPanel from '../../../components/UAIAPP/api/useFXPanel';
import DynamicUI from './ui/dynamicUI';
import { useSignal, useSlot } from 'react-signal-slot';
import useRuntime from '../api/useRuntime';
const FXPanel = ({}) => {

    const fxPanel = useFXPanel();
    const [views, setViews] = useState([]);
    const signal = useSignal();

    useSlot('timeline.tracks.showData.fx.panel', (e) => {
        setViews(e.views);
        signal("show.fxPanel", {});
    });
   

   useEffect(() => {

   }, [views])
  

    return (
        <div className="flex flex-col gap-2">
            {
                views && views.map((component) => {
                    return (
                        <DynamicUI component={component} />
                    )
                })

            }
        </div>
    )
}

export default FXPanel;