import { create } from "zustand";
import { persist, createJSONStorage, StateStorage } from 'zustand/middleware'
import localforage from 'localforage';
import { uaimenu,uaiComponents } from "../../../components/UAIModules/uaiModuleConstants";

var store = localforage.createInstance({
    name: "project"
  });
// Custom storage object
const storage: StateStorage = {
    getItem: async (name: string): Promise<string | null> => {
      return (await store.getItem(name)) || null
    },
    setItem: async (name: string, value: string): Promise<void> => {
      await store.setItem(name, value)
    },
    removeItem: async (name: string): Promise<void> => {
      await store.removeItem(name)
    },
  }


const useRuntime = create(
    persist(
    (set, get) => ({
    runtime: {
    },
    
    sequence:{},
    project:{},
    projects:[],
    projectPath:"/",
    projectPathName:"",
    currentFileBrowserPath:"/",
    timeline:[],
    sequences:[],
    effects:[],
    generateFX:[],
    generateFXIDS:[],
    focusedWindow:null,
    fileMap:[],
    global:{} ,
    layout:{} ,

    appTimeline:{
      timeline: [],
      selected: [],
      isRendering: false,
      isGenerating: false,
      isDirty: false,
      progressRender: 0,
      progressLoad: 0,
      state:"loading",
      fxPanelMode:"track",
      browserPanelMode:"file",
      previewPanelMode:"preview",
      focusedWindow:"",
      windowContext:"previewArea",
      currentTimeline: "",
      sockets:[],
      finishedTutorials:[],
    },
    
    setIsDirty: (isDirty) =>{
        const newAppTimeline = get().appTimeline;
        newAppTimeline.isDirty = isDirty;
        set(
            { appTimeline: newAppTimeline }
        )},
    setLayout: (layout) =>{
        set(
            { layout: layout }
        )},
        setTimelineSelected: (selected) =>
          {
        const newAppTimeline = get().appTimeline;
        newAppTimeline.selected = selected;
        set(
            { appTimeline: newAppTimeline }
        )
      },
    
      addGenerateFX: (views=[]) =>
          {
        let newGenerateFX =[... get().generateFX, ... views];
        
        set(
            { generateFX: newGenerateFX, generateFXIDS: newGenerateFX.map((item) => item.component.name)}
        )
      },
      fieldGenerateFXUpdated : (field, component) => {
        const action = component.action;

        const componentItem = component;

 
        const uid = componentItem.uid;
        const updatedGenerateFX = [...get().generateFX];
        for (let i = 0; i < updatedGenerateFX.length; i++) {
            let componentInternal = updatedGenerateFX[i];
            try{
              componentInternal = componentInternal.component;
            }catch{

            }
                        if (componentInternal.uid === uid) {
                            let matched = false;
                            if(componentInternal.properties){
                            for(let l = 0; l < componentInternal.properties.length; l++){
                                const field_ = componentInternal.properties[l];
                                if(field_.name === field.name){
                                    componentInternal.properties[l].value = field.value;
                                    matched = true;
                                }
                            }
                          }else{
                            componentInternal.properties = [];
                          }
                            if(!matched){
                                const newField = {name: field.name, value: field.value};
                                componentInternal.properties.push(newField);
                            }
                        }
                }

        
        set(
          { generateFX: updatedGenerateFX, generateFXIDS: updatedGenerateFX.map((item) => item.component.name) }
      )
        
    },

    
    
      setGenerateFX: (views=[]) =>
          {
        const newGenerateFX = get().generateFX;
        set(
            { generateFX: views, generateFXIDS: views.map((item) => item.component.name) }
        )
      },
      removeGenerateFX: (fx) =>
      {
        const newGenerateFX = get().generateFX.filter((item) => item.component!== fx);
        set(
            { generateFX: newGenerateFX, generateFXIDS: newGenerateFX.map((item) => item.component.name) }
        )
      },
        
    
        addTimelineSelected: (selected) =>
          {
        const newAppTimeline = get().appTimeline;
        let newTimeline = [...newAppTimeline.selected];
        if(newTimeline.includes(selected) == false){
          newTimeline.push(selected);
        }
        newAppTimeline.selected = newTimeline;
        set(
            { appTimeline: newAppTimeline }
        )
      },
      deleteSelectedTimelineItems : (id) => {
        const time_ = get().timeline;
        let outData = [];
        for (let i = 0; i < time_.length; i++) {
            const item = time_[i];
            for (let j = 0; j < item.actions.length; j++) {
                const action = item.actions[j];
 
                if (action.id === id) {
                    outData.push(i);
                    break;
                }
            }
        }
        const newTimeline = time_.filter((item, index) => outData.includes(index) == false);
 
        let newSequence = {...get().sequence};
        let newSequences = get().sequences;
        newSequence.timeline = newTimeline;
            let filtered = newSequences.filter((item) => item.uuid !== newSequence.uuid);
            newSequences = [...filtered, newSequence];
            
        const newProject = get().project;
        newProject.sequences = newSequences;
        const newAppTimeline = get().appTimeline;
        newAppTimeline.selected = [];
            set({sequence:newSequence, sequences:newSequences, timeline:newTimeline, project:newProject, appTimeline:newAppTimeline});
        return newTimeline;
    },
    setProjects: (projects_) =>
        set(
             { projects: projects_ }
        ),
    setProjectPathName: (name) =>
        set(
             { projectPathName: name }
        ),
    setProjectPath: (path) =>
        set(
             { projectPath: path }
        ),
    setCurrentFileBrowserPath: (path) =>

        { 
            set({currentFileBrowserPath:path});
        }
        ,
    setSequencesetSequence: (sequence) =>

        {
            set({sequence:sequence});
        }
        ,
    setSequences: (sequences) =>

        {
          const newProject = get().project;
          newProject.sequences = sequences;
            set({sequences:sequences, project:newProject});
        }
        ,
    addSequences: (sequences) =>
        {
        let newSequences = [...get().sequences , ...sequences];
        
        const newProject = get().project;
        newProject.sequences = newSequences;
            set({sequences:newSequences, project:newProject});
        }
        ,
    updateTimeline: (timeline) =>
        {
        let newSequence = {...get().sequence};
        let newSequences = get().sequences;
        newSequence.timeline = timeline;
            let filtered = newSequences.filter((item) => item.uuid !== newSequence.uuid);
            newSequences = [...filtered, newSequence];
            
        const newProject = get().project;
        newProject.sequences = newSequences;
            set({sequence:newSequence, sequences:newSequences, timeline:timeline, project:newProject});
        }
        ,

    setSequence: (newSequence) =>{
      const newProject = get().project;
        let newSequences = get().sequences;
            let filtered = newSequences.filter((item) => item.uuid !== newSequence.uuid);
            newSequences = [...filtered, newSequence];
        newProject.sequences = newSequences;
        set(
            { sequence: newSequence, timeline: newSequence.timeline, project:newProject, sequences:newSequences }
        )},

    deleteSequenceByIndex: (index) =>{


      const newProject = get().project;
        let newSequences = get().sequences;
        let currentSeq = get().sequence;
        let currentTimeline = get().timeline;
            let seq = newSequences.filter((item, indx) => index == indx);
            let filtered = newSequences.filter((item, indx) => index !== indx);
        if(currentSeq.id == seq[0].id){
          currentSeq = {};
          currentTimeline = [];
        }
        newProject.sequences = filtered;
        set(
            { sequence: currentSeq, timeline: currentTimeline, project:newProject, sequences:filtered }
        )},

    addMediaToLayer: (layerid = "", actionData, subType="imageGen",type="image",  start=-1, end=-1, data= {}) =>{
        
      let id_ = crypto.randomUUID();
    
        const newMedia = {
                id: id_,
                start: start != -1 ?  start : 0,
                end:  end != -1 ?  end : 1,
                effectId: type,
                subType: subType,
                data: data
          };

        let newSequence = get().sequence;
        let index_ = newSequence.timeline.findIndex((item) => item.id == layerid);

        let newActions = newSequence.timeline[index_].actions;
        newActions.push(newMedia);
        newSequence.timeline[index_].actions = newActions;

        let newSequences = get().sequences;
        let filtered = newSequences.filter((item) => item.uuid !== newSequence.uuid);
        newSequences = [...filtered, newSequence];
      set(
             {  sequence: newSequence, sequences: newSequences, timeline: newSequence.timeline  }
        )},

        createLayer:  (type="image", name = "Track", subType="imageGen", start=-1, end=-1, data= {}) => 
        {
          let newData = {...data, components : []};
          let newSequence = get().sequence;
          let comps = uaiComponents;
          for(let i = 0; i < comps.components.length; i++){
            try{
              if(comps.components[i].automatic.includes(type)){
                let comp = comps.components[i];
                let newComponent = {
                  "name":comp.title,
                  "id":comp.name,
                  "uuid": crypto.randomUUID(),
                  "properties":[
                  ]
                  };
                  if(comp.name == "uai.builtin.unlitmaterial"){
                    newComponent.properties.push(
                      {
                        "name":"baseTexture",
                        "value":data.id
                      }
                    );
                  }
                newData.components.push( newComponent);
              }
            }catch{

            }
          }
          const time_ = newSequence.timeline;
        let id_ = crypto.randomUUID();
        let id_2 = crypto.randomUUID();
    
        const newLayer = {
            id: id_,
            name: name == "Track" ? name : `Track ${time_.length + 1}`,
            actions: [
              {
                id: id_2,
                start: start != -1 ?  start : 0,
                end:  end != -1 ?  end : 1,
                effectId: type,
                subType: subType,
                data: newData
              },
            ],
          };
    
            const newTimeline = [...time_, newLayer];
            let newSequences = get().sequences;
            let filtered = newSequences.filter((item) => item.uuid !== newSequence.uuid);
            newSequence.timeline = newTimeline;
            newSequences = [...filtered, newSequence];

        set(
             { sequence: newSequence, sequences: newSequences, timeline: newTimeline }
        );
        return newLayer;
      },
    setProject: (object) =>{
        
      let sequence = {};
      let timeline = [];
      if(object.sequences){
        if(object.sequences.length != 0){
          sequence = object.sequences[0];
          timeline = object.sequences[0].timeline;

        }
      }else{
        object.sequences=[];
      }
      set(
             { project: object, sequences: object.sequences,  sequence:sequence, timeline: timeline  }
        )},
    setProjectPath: (object) =>
        set(
             { projectPath: object }
        ),
    reset: () =>
        set(
             { runtime: {}, sequence:null, project:null, projectPath:"/", timeline:[], sequences:[], effects:[], focusedWindow:null, fileMap:[], global:{} }
        ),
        
})
,
    {
      name: 'project-storage', // unique name
      storage: createJSONStorage(() => storage),
    }

));

export default useRuntime;

export const getGenerateFX = () => {
    return useRuntime.getState().generateFX;
}

export const getGenerateFXIDS = () => {
    return useRuntime.getState().generateFXIDS;
}