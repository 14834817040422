import useRuntime from "../api/useRuntime";
import { useEffect , useState} from "react";
import { classNames } from 'primereact/utils';

import { uaiComponentTree } from "../../UAIModules/uaiModuleConstants";
import { Tree } from 'primereact/tree';
import { getUAIComponent } from '../../../api/firebaseHandler';
import AnimatedButton from "./AnimatedButton";
import AnimatedText from "../../../components/AnimatedText";
import { useSignal, useSlot } from 'react-signal-slot';

import { useDrag, useDrop } from 'react-dnd'

export const ItemTypes = {
    'CARD': "card",
    'PHONE': "phone",
    'WEAPON': "weapon",
    'FOOD': "food",
    'DRINK': "drink"
};

const FXBrowser = ({ extra = {} }) => {
    const sequences = useRuntime((state) => state.sequences);
    const signal = useSignal();
  
    const onClick = (node) => {
        console.log("node: ", node);
        const id = node.data;
        const component = getUAIComponent(id);
        signal(
            "operation.openWindow",
            {data:{ metadata: component}}
        );

    };

    const TogglerTemplate = (node, options) => {
        const [iconState, setIconState] = useState("/images/svg/arrow-right-1-icon.svg");

        return (
            <>
            {
                node.children && node.children.length > 0 ?
            
            <AnimatedButton  placement={"bottom"} icon={iconState} alt="delete" className="!h-[8px] !w-[8px] cursor-pointer hover:texts-[#1e49b6]" onClick={()=>{
                if(iconState == "/images/svg/arrow-right-1-icon.svg"){
                    setIconState("/images/svg/arrow-down-1-icon.svg");
                }else{
                    setIconState("/images/svg/arrow-right-1-icon.svg");
                }
                options.onClick();
            
            }} whileHover={{ scale: 1.8, color:"#1e49b6" }}
                    whileTap={{
                        scale: 0.9,
                        rotate: 360,
                    }}
                    helpTitle={node.label} helpBody={`Open the ${node.label} FX`}
                    />
                     :    <AnimatedButton  placement={"bottom"} icon={'/images/svg/aigenerate.svg'} alt="delete" className="!h-[8px] !w-[8px] cursor-pointer hover:texts-[#1e49b6]" onClick={()=>{
                        onClick(node);
                    }} whileHover={{ scale: 1.8, color:"#1e49b6" }}
                            whileTap={{
                                scale: 0.9,
                                rotate: 360,
                            }}
                            helpTitle={node.label} helpBody={node.help}
                            />}
                    </>
        );
    
    };
    const NodeTemplate = (node, options) => {
        let label = <b>{node.label}</b>;
        let variable = node ? node.label : "";
        console.log("variable: ", variable);
        const type = 'fxitem';
        if (node.url) {
            label = <a href={node.url} className="text-700 hover:text-primary" target="_blank" rel="noopener noreferrer">{node.label}</a>;
        }
        const textColor = node.children ? '!text-white' : '!text-uaiyellow-1 !font-bold';
    
        return(
            <div className="w-full" >
            <AnimatedText className={`${options.className} ${textColor} cursor-pointer`} value={node.label} speed={70} duration={0.1} onClick={()=>{

                if(node.children && node.children.length > 0){
                    options.onClick();
                }else{
                    onClick(node);
                }
            }}/>
            </div>
            );
    }
    

    
    
    useEffect(() => {

    }, [sequences]);

    return (
            <Tree value={uaiComponentTree} nodeTemplate={NodeTemplate} togglerTemplate={TogglerTemplate}  className="w-full md:w-30rem" />

    );
};
export default FXBrowser;
