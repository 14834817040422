// import './style.css';
import {useRef, useState, useEffect  } from 'react';
import { Modal } from "react-bootstrap";

const ConfirmationModal = ({   show=false, setShow=(e)=>{}, title = "",    body = "",    onAccept = () => {},    onReject = () => {}, }) => {
    return (

        <Modal
   show={show}
   onHide={()=>{
    setShow(false);

   }}
   size='lg'
 >
   <Modal.Header closeButton></Modal.Header>
        <section
            className="mx-auto px-20 py-12 md:py-28 lg:gap-3 bg-transparent w-full relative isolate flex overflow-clip
    flex-col  h-fit overflow-auto"
        >
            <div className="h-fit w-full flex flex-row">
                <p className="text-3xl font-bold md:text-5xl text-white text-left">
                    {title}
                </p>
            </div>

            <div className="h-fit w-full flex flex-row">
                <p className="text-white text-left">{body}</p>
            </div>

            <div className="h-fit absolute -left-14 bottom-10 w-full flex flex-row">
              
                    <div className=" flex flex-row gap-6   w-full justify-end">
                        <button
                            type="submit"
                            className="p-6 !rounded-none !border-[#183272]  border "
                            onClick={() => {
                                setShow(false);
                                onReject();
                            }}
                        >
                            {"Cancel"}
                        </button>
                        <button
                            type="submit"
                            className="p-6 !rounded-none !border-[#183272]  border "
                            onClick={
                                onAccept
                                }
                        >
                            {"Accept"}
                        </button>
                    </div>
            </div>
        </section>
        </Modal>

    );
};

export default ConfirmationModal;
