import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import './App.scss'
import ScrollToTop from "./ScrollToTop";
import { ThemeProvider } from "@material-tailwind/react";
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
// import 'bootstrap/dist/css/bootstrap.css';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.Fragment>
        <BrowserRouter>
        <Auth0Provider
      domain="uai-software.us.auth0.com"
      clientId="yTNj0wQucsYzHWt4w3BCeyZxeBlosuNg"
      // audience="http://uai-strapi-auth0-API"
      // redirectUri="https://uai.software/account/profile"
      // screen_hint="signup"
      redirectUri={`${process.env.REACT_APP_FULLPATH }/callback`}
    >
            <ScrollToTop />
            
            <ThemeProvider>

            <App />
            </ThemeProvider>
            </Auth0Provider>
        </BrowserRouter>
    </React.Fragment>
);

