import { useState, useRef } from 'react';
import { useSignals } from './signalSlots';
import fileExtensions from '../constants/fileExtensions';
import useOperations from '../components/UAIAPP/api/useOperations';
import { useSignal, useSlot } from 'react-signal-slot';

const usePreviewWindow = () => {

    const [isOpen, setIsOpen] = useState(false);
    const signal = useSignal();
    

    const showImageURL = (url) => {
        signal("preview.showImage.url", { url: url });
 
    };
    
    const showImageBase64 = (base64) => {
        signal("preview.showImage.base64", { base64: base64 });
    
    };

    const parseContentType = (extension ) =>{
        let extension_ = {};
        for (let extensi of fileExtensions.extensions) {
            for(let ext of extensi.filenamepattern){
                if(ext.value){
                    const fpattern = ext.value.replace("*.", "");
                if(fpattern==extension){
                    extension_ = extensi;
                    break;
                }
                }
              
            }
        }
        return extension_;
    }

    const parseContentEvent = (extension, mimeType, downloadLink)=>{
        if(extension == "gltf" || extension == "glb"){ 
            mimeType = "gltf/gltf";
        }
        if(extension == "fbx"){
            mimeType = "fbx/fbx";
        }
        if(extension == "obj"){
            mimeType = "obj/obj";
        }
        if(extension == "pkf"){
            mimeType = "image/png";
        }
        if(extension == "png"){
            mimeType = "image/png";
        }
        if(extension == "jpg"){
            mimeType = "image/jpg";
        }
        if(extension == "wav"){
            mimeType = "audio/wav";
        }
        if(extension == "mp4"){
            mimeType = "video/mp4";
        }
        if(extension == "mov"){
            mimeType = "video/mov";
        }
        const mimeTop = mimeType.split("/")[0];
        const isNotBase64 = downloadLink.includes('http');
        let eventName = 'preview.showVideo.url';
        console.log("mimeTop ", mimeTop);
        // Process MimeType
        switch(mimeTop){
            case 'image':
                if(isNotBase64){
                    eventName = "preview.showImage.url";
                }else{
                    eventName = "preview.showImage.base64";
                }
            break;

                case 'video':
                    if(isNotBase64){
                        eventName = "preview.showVideo.url";
                    }else{
                        eventName = "preview.showVideo.base64";
                    }
                    break;
                

                case 'audio':
                    if(isNotBase64){
                        eventName = "preview.showAudio.url";
                    }else{
                        eventName = "preview.showAudio.base64";
                    }
                    break;
                case 'gltf':
                    if(isNotBase64){
                        eventName = "preview.gltf.url";
                    }else{
                        eventName = "preview.gltf.base64";
                    }
                    break;
                
                case 'fbx':
                    if(isNotBase64){
                        eventName = "preview.fbx.url";
                    }else{
                        eventName = "preview.fbx.base64";
                    }
                    break;
                
                case 'obj':
                    if(isNotBase64){
                        eventName = "preview.obj.url";
                    }else{
                        eventName = "preview.obj.base64";
                    }
                    break;
                
        }
        console.log('eventName', eventName);
        return {eventName, isNotBase64};

}
    


    const showContentOnPreview = async (extension,mimeType, downloadLink) =>{

      
        let {eventName, isNotBase64} = parseContentEvent(extension,mimeType, downloadLink);
        console.log('eventName', eventName);
        console.log('extension', extension);

        // Run Event
        let event = null;
        if(isNotBase64){
            signal(eventName, { url: downloadLink, key_:"preview" });

        }else{
            signal(eventName, { base64: downloadLink , key_:"preview"});
        }


    }

    const showMediaPreview = async (extension, downloadLink) => {
        const contentInfo = parseContentType(extension);
        console.log(contentInfo);
        console.log(downloadLink);
        if(contentInfo != {}){
            try{
            const mimeType = contentInfo.mimetypes[0].value;
            await showContentOnPreview(extension,mimeType, downloadLink);
            }catch{
                await showContentOnPreview(extension,"image/png", downloadLink);
            }
            return true;
        }else{
            return false;
        }
        
        // if(extensi)

    }
    
    const openPreviewWindow = () => {
        setIsOpen(true);
    };
    
    const closePreviewWindow = () => {
        setIsOpen(false);
    };
    
    return {
        isOpen,
        openPreviewWindow,
        closePreviewWindow,
        showImageURL,
        showImageBase64,
        showMediaPreview,
    };



}

export {usePreviewWindow};