 export const SetOptIn = (optIn) => {
    switch(optIn.optInTarget){
        case("firstRun"):
        optIn.firstRun = optIn.optInTargetValue;
        break;

        case("cameraShot"):
        optIn.cameraShot = optIn.optInTargetValue;
        break;

        case("cameraReview"):
        optIn.cameraReview = optIn.optInTargetValue;
        break;

        case("cameraReviewGenerate"):
        optIn.cameraReviewGenerate = optIn.optInTargetValue;
        break;
        

        case("cameraShare"):
        optIn.cameraShare = optIn.optInTargetValue;
        break;

        case("audienceModeSet"):
        optIn.audienceModeSet = optIn.optInTargetValue;
        break;

        default :
        break;


    }
    return optIn;


 }

 

 export class OptIns
    {
        constructor( ) {
        this.firstRun = false;
        this.experiencesPage = false;
        this.cameraShot = false;
        this.cameraReview = false;
        this.cameraReviewGenerate = false;
        this.cameraShare = false;
        this.audienceModeSet = false;
        this.optInTarget = "";
        this.optInTargetValue = false;
        }

        

        setOptInTarget(target, value){
            this.optInTarget = target;
            this.optInTargetValue = value;
        }

        json(){
            return JSON.stringify(this);
        }
    }