
import { useSignals } from "../../../api/signalSlots";
import { useState, useEffect } from "react";
import { singletonHook } from 'react-singleton-hook';
import { useAppManager } from "../../../api/appManager";
import {getUAIComponent} from "../../../api/firebaseHandler";
import useOperations from '../../UAIAPP/api/useOperations';
import { useSignal, useSlot } from 'react-signal-slot';
const useFXPanelImpl = () => {
    const manager = useAppManager();
    const operations = useOperations((state) => state.operations);
    const removeOperation = useOperations((state) => state.addOperations);
    const addOperation = useOperations((state) => state.addOperation);
    const signal = useSignal();
    const [functions, setFunctions] = useState([]);
    useSlot('timeline.tracks.showData', (e) => {
        updateViewedPanel(e.tracks);
    })

    useSlot('operation.openWindowg', (e) => {
        const data= e.data;
        console.log(data);
        if (data.metadata.window=="FX" ) {
            addViewPanel(e.data.metadata);
            // setShow(e.state);
        }
        if (data.metadata.window=="AIGenerate" ) {
            addGeneratePanel(e.data.metadata);
            // setShow(e.state);
        }
        // const match = operations.filter((item) => item.id == operation);
        console.log(data);
    });
    useSlot('operation.runf', (e) => {
        const {operation, value, dictionary} = e;
        const isUI = operation.includes(".ui.");
        const match = operations.filter((item) => item.id == operation);
        for (let i = 0; i < value.length; i++) {
        const dictKey = dictionary[Object.keys(dictionary)[i]];

            value[i].values = dictKey;
        }
        manager.props.operations = match;
        console.log('match :', match);

        if(match.length > 0){
            for (let i = 0; i < match.length; i++) {
                match[i].props =  {operation, value, dictionary} ;
                if(isUI){
                    console.log("AddToUIQueue");
                manager.props.uaiProject.AddToUIQueue([async () => {
                    match[i].function({operation, value, dictionary});
                }]);
            }else{
                console.log("AddToRenderQueue");

                manager.props.uaiProject.AddToRenderQueue([async () => {
                    match[i].function({operation, value, dictionary});
                }]);
            }
            }
        }
        if(!isUI){
            manager.props.uaiProject.StartRenderQueue();
        }else{
        }

    });
    // const uaiMenu = UAIMenu();
   
    const [selectedID, setSelectedID] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [views, setViews] = useState([]);
    const [selectedTracks, setSelectedTracks] = useState([]);

    // const [registeredComponents , setRegisteredComponents] = useState(defaultOperations());

    const processTrackComponentSubWindow = (subwindow) => {

    }
   
    const buildViews = (components=[]) => {
        const views_ = [];
        for(var c =0; c < components.length ; c++){
            const component = components[c];
            let id_ = component.name;
            try{
                id_ = component.id;
            }catch(e){
                console.log(e);
            }


            const item = getUAIComponent(id_);

            item.component = component;
            // item.action = track;

            // item.uid = 
            views_.push(item);
        }
        return views_;
    }
   
    const addGeneratePanel = (component) => {
        const views_ = buildViews([component]);
        signal('timeline.tracks.showData.generate.panel', {views: views_});

    }
   
   
    const addViewPanel = (component) => {
        const views_ = buildViews([component]);
        setViews(views_);
        signal('timeline.tracks.showData.fx.panel', {views: views_});

    }
   
    const updateViewedPanel = (tracks) => {
        const views_ = [];
        setSelectedTracks(tracks);
        for(var i =0; i < tracks.length ; i++){
            const track = tracks[i];

            let components = [];
                components = track.components;
                if(components == undefined || components == null || components.length == 0){
                    components = track.data.components;
                }
         
        for(var c =0; c < components.length ; c++){
            const component = components[c];

            const item = getUAIComponent(component.id);

            item.component = component;
            item.action = track;

            // item.uid = 
            views_.push(item);
        }
        }
        setViews(views_);
        signal('timeline.tracks.showData.fx.panel', {views: views_});

    }
    const fieldUpdated = (field, component) => {

        manager.props.timeline.fieldUpdated(field, component);

    }
    const registerComponent = (component) => {
        addOperation( component);
    }
    const unregisterComponent = (component) => {
        removeOperation(component);
    }
    

    return {
        selectedID, operations, updateViewedPanel, registerComponent, unregisterComponent, views, fieldUpdated
    }

}

const useFXPanel = singletonHook({}, useFXPanelImpl);

export default useFXPanel;
export { useFXPanel };