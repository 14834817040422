import { useState, useEffect } from 'react';


export function useHideNav() {
    const [hideNav, setHideNav] = useState(null);
    useEffect(() => {
        const key_ = localStorage.getItem('hideNav');
        if (key_) {
            if (key_ === "true") {
                setHideNav(true);
            } else {
                setHideNav(false);
            }

        }
    }, []);
    return { hideNav };
}
