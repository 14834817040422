

const CartGrid = ({ image, name, quantity, price, tax, total, commands, classOverride = "" , allClassOverride = "" }) => {
    return (
<div class="CartContainer">
                                                <div class={"CartImage cart-image-icon"+ " "  + allClassOverride}>{image}</div>
                                                <div class={"CartName centeredText marginAuto " + classOverride + " "  + allClassOverride}>{name}</div>
                                                <div class={"CartQuantity centeredText marginAuto "+ classOverride+ " "  + allClassOverride} centeredText marginAuto> {quantity}</div>
                                                <div class={"CartPrice centeredText marginAuto "+ classOverride+ " "  + allClassOverride}>{price}</div>
                                                <div class={"CartTax centeredText marginAuto "+ classOverride+ " "  + allClassOverride}>{tax}</div>
                                                <div class={"CartTotal centeredText marginAuto "+ classOverride+ " "  + allClassOverride}>{total}</div>
                                                <div class={"CartCommands centeredText marginAuto "   + allClassOverride}>{commands}</div>
                                            </div>

);
};

export default CartGrid;