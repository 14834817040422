import { ImageRequestBase } from "./imageRequest";
import { MediaItems } from "./mediaItems";
export class PhotoboothSettings
    {
        constructor() {
        this.mode = "all";
        this.imageRequest = new ImageRequestBase();
        this.mediaItems= new MediaItems();
        this.imagesShot= [];
        this.selectedStyle = {};
        this.controlnet_conditioning_scales = "normal";

        }

        json(){
            return JSON.stringify(this);
        }
    }