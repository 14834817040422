import useRuntime from "../api/useRuntime";
import { useEffect, useState } from "react";
import { useAppManager } from "../../../api/appManager";
import { Card, Typography } from "@material-tailwind/react";
import { motion } from "framer-motion";
import {sendToast} from "../../../api/firebaseHandler";
import AnimatedButton from "./AnimatedButton";
const SequencesTable = ({ extra = {} }) => {
    const manager = useAppManager();
    const sequences = useRuntime((state) => state.sequences);
    const setSequence = useRuntime((state) => state.setSequence);
    const setSequences = useRuntime((state) => state.setSequences);
    const deleteSequenceByIndex = useRuntime((state) => state.deleteSequenceByIndex);
    const [updatedProps, setUpdatedProps] = useState({});
    const TABLE_HEAD = ["Name", ""];

    useEffect(() => {

    }, [sequences]);

    return (
            <table className="w-full min-w-max table-auto text-left">
                <thead>
                    <tr>
                        {TABLE_HEAD.map((head) => (
                            <th
                                key={head}
                                className=" bg-transparent p-1"
                            >
                                <Typography
                                    variant="small"
                                    color="white"
                                    className="font-normal leading-none opacity-70"
                                >
                                    {head}
                                </Typography>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sequences.map((sequence, index) => {
                        const isLast = index === sequences.length - 1;
                        const classes = isLast
                            ? "p-1"
                            : "p-1 ";

                        return (
                            <tr key={sequence.name}>
                                <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="white"
                                        className="font-normal !m-auto"
                                    >
                                        {sequence.name}
                                    </Typography>
                                </td>
                                
                                <td className={classes}>
                                    <Typography
                                        as="a"
                                        href="#"
                                        variant="xs"
                                        color="white"
                                        className="font-medium !m-auto !text-start !pl-1 flex flex-row gap-3"
                                    >
                                        <AnimatedButton  placement={"bottom"} icon="/images/svg/play-filled-icon.svg" alt="delete" className="!h-[8px] !w-[8px] cursor-pointer hover:texts-[#1e49b6]" onClick={()=>{
                                            setSequence(sequence);
                                            sendToast("Sequence loaded!: " + sequence.name);
                                        
                                        }} whileHover={{ scale: 1.8, color:"#1e49b6" }}
                                                whileTap={{
                                                    scale: 0.9,
                                                    rotate: 360,
                                                }}
                                                helpTitle="Load Sequence" helpBody={`Open the ${sequence.name} sequence in the Preview Window.`}
                                                />
                                                
                                        <AnimatedButton placement={"bottom"} icon="/images/svg/trash-filled-icon.svg" alt="delete" className="!h-[8px] !w-[8px] cursor-pointer hover:texts-[#1e49b6]" onClick={()=>{
                                            deleteSequenceByIndex(index);
                                            sendToast("Deleted Sequence : " + sequence.name);
                                        
                                        }} whileHover={{ scale: 1.8, color:"#1e49b6" }}
                                                whileTap={{
                                                    scale: 0.9,
                                                    rotate: 360,
                                                }} 
                                                helpTitle="Delete Sequence" helpBody={`Delete the ${sequence.name} sequence from the Project.`}
                                                
                                                />

                                    </Typography>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
    );
};
export default SequencesTable;
