/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import { React, useRef } from 'react';
import PropTypes from 'prop-types';


import '../../style.css';

// import { useResize } from '../../../api/useResize';

export const HeaderWithVideo = ({
headerHeight = '250px', translateX = '-200px', translateY = '-400px', mp4Url = 'https://www.dropbox.com/s/hb4x4nmtwm7bkoe/SplashScreen_2022Logo_withTagline_lr.mp4?raw=1', Title, BodyText, ButtonText, ButtonColor, ShowButton, icon, TextBoxYPosition,
}) => { 

  // const componentRef = useRef();
  // const { width, height } = useResize(componentRef);
  return (
    <div
      className="Title"
      style={{
              objectFit: 'contain',
              overflow: 'hidden',
              height: headerHeight,
              maxHeight: headerHeight,
          }}
    >
      
      <video
        autoPlay
        loop
        muted
        id="video"
        className="headervideo"
        style={{
    objectFit: 'fill',
    position: 'relative',
    transform: `translate(${translateX}, ${translateY})`,
}}
      >
        <source src={mp4Url} type="video/mp4" />
      </video>
      {/* <div className="button-holder headerButton" style={{ placeContent: 'center' }}>
        <a href="/e-commerce/uai_product_page" className="button" onClick={GetUAIClicked}>
          {ButtonText}

        </a>
      </div> */}
    </div>
      
        
);
};
HeaderWithVideo.propTypes = {
  Title: PropTypes.string,
  BodyText: PropTypes.string,
  ButtonText: PropTypes.string,
  ButtonColor: PropTypes.string,
  ShowButton: PropTypes.string,
  mp4Url: PropTypes.string,
  translateY: PropTypes.string,
  translateX: PropTypes.string,
  headerHeight: PropTypes.string,
  TextBoxYPosition: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object]),

};

HeaderWithVideo.defaultProps = {
  mp4Url: 'https://www.dropbox.com/s/hb4x4nmtwm7bkoe/SplashScreen_2022Logo_withTagline_lr.mp4?raw=1',
  Title: '',
  BodyText: '',
  ShowButton: '',
  ButtonText: '',
  ButtonColor: 'primary',
  translateY: '-400px',
  translateX: '-200px',
  headerHeight: '50%',
  TextBoxYPosition: '-150%',
  icon: null,
};

export default HeaderWithVideo;
