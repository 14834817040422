import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@material-tailwind/react';
import PageTitle from '../components/pagetitle/PageTitle';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
  } from "@material-tailwind/react";
import { useAuth0 } from '@auth0/auth0-react';

import data from '../assets/fake-data/data-hotpick'
import icon1 from '../assets/images/icon/rain1.svg'
import icon2 from '../assets/images/icon/rain2.svg'
import icon3 from '../assets/images/icon/ethe.svg'
import CardModal from '../components/layouts/CardModal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { getProductsList } from '../uai/api/dlc';

import { LoadCheckoutStripe } from '../uai/api/stripe/Checkout';
import { getMatchingCoupon } from '../uai/api/licenses';
import { Select, Option } from "@material-tailwind/react";
import CartCard from '../uai/api/CartCard';
import '../uai/uaistyle.css';
import  LoadingSpinner  from './LoadingSpinner';



const  Cart = ({props}) => {
    const [modalShow, setModalShow] = useState(false);
    const [catalogList, setcatalogList] = useState(undefined);
    const [mode, setmode] = useState('loading');
    const [cartData, setCartData] = useState([]);
    const [plugins, setplugins] = useState([]);
    const [apps, setapps] = useState([]);
    const [uaiapp, setuaiapp] = useState([]);

    const {
        user, isAuthenticated, loginWithRedirect, isLoading, logout,
         } = useAuth0();
       
      const [coupon, setcoupon] = useState(undefined);
      const [isLoaded, setisLoaded] = useState(false);
      const [percentOff, setpercentOff] = useState(0);
      const [cart, setcart] = useState(JSON.parse(localStorage.getItem('cart')));
      const [cartmap, setcartmap] = useState(null);
      const [applyCouponFunction, setapplyCouponFunction] = useState(() => { });
      const [cartComponent, setcartComponent] = useState(null);
      const [cartTitle, setcartTitle] = useState('Your cart is currently empty');
      const [finalprice, setfinalprice] = useState(0);
      const [subtotalprice, setsubtotalprice] = useState(0);
      
      const userr = localStorage.getItem('user');
    
      // const userObject = user !== undefined ? user : undefined;
      const userObject = userr !== undefined ? JSON.parse(userr) : undefined;
    
        
      const purchaseClicked = async () => {
        if (userObject === undefined || userObject === null) {
          localStorage.setItem('postLoginUrl', '/e-commerce/cart');
        
          loginWithRedirect();
          // window.location.href = '/register';
        } else {
          // console.log('coupon: ', document.getElementById('coupon').value);
          // console.log('coupon: ', document.getElementsByClassName('cart__coupon-input')[0].value);
          const couponText = document.getElementById('coupon').value;
          const matchCoupon = JSON.parse(localStorage.getItem('coupon'), undefined);
          // console.log('couponText: ', couponText);
          // console.log('coupon: ', matchCoupon);
          
          const matchingCoupon = matchCoupon;
          // console.log('coupon', matchingCoupon);
          if (matchingCoupon) {
              const choice = matchingCoupon;  
              const couponID = choice.coupon.id;
              // alert(`Coupon found ${ couponID}`);
    
              LoadCheckoutStripe(couponID);
              // console.log('couponID: ', couponID);
          } else {
            // alert('Coupon not found2');
    
            LoadCheckoutStripe();
          }
        }
        };
        
      const removefromCart = (item) => {
        const filter = cart.filter((obj) => {
          if (obj.index !== item.index) {
              return obj;
          }
          return false;
      });
      setcart(filter);
      localStorage.setItem('cart', JSON.stringify(filter));
      };
    
    
    


    const getcatalog = async () => {
        if (catalogList === undefined) {
        const data = await getProductsList();
        console.log(1);
        // filter data by .metadata.productType === 'Plugin' and name the variable plugins
        const newplugins = data.filter(item => item.metadata.productType === 'Plugins');
        const newapps = data.filter(item => item.metadata.productType === 'App');
        const uaiapps = data.filter(item => item.title === 'UAI License');
        console.log(2);
        setcatalogList(data);
        console.log(3);
        setplugins(newplugins);
        setapps(newapps);
        console.log(4);

        setuaiapp(uaiapps[0]);
        console.log(5);

        setmode('main');
    //     setcatalogpage(
    //     (
          
    //       ),
    // );
        }
      };

    useEffect(() => {
        getcatalog();
        }, []);


    return (
        <div>

<div className="col-xl-9 col-lg-12 col-md-12 overflow-table m-auto">

                                <div className="dashboard-content inventory content-tab mx-auto">
            {mode != "loading" ? 
            <>
            <section className="tf-item-detail w-5/6 ">

            <CartCard />




            </section> 
            
            </>: <LoadingSpinner Title='Loading' Message='Loading user cart'/>}
            
        </div>
        </div>
        </div>
    );
}

Cart.propTypes = {
    
};

export default Cart;