
import {React , useEffect} from 'react';
import AOS from 'aos';
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import { Provider } from "react-redux";
import { ToastContainer, Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'


import routes from './pages';
import Page404 from './pages/404';
import './style.css';
import '../src/assets/binasea.css';
import '../src/assets/font-awesome.css';
import ParticleBackground from './uai/components/ParticleBackground';
import store from "./store";
import {load} from './uai/components/UAIAPP/api/uaiConnector';

const queryClient = new QueryClient()

function App() {
    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
        load();
      }, []);

    return (
        <>
         <QueryClientProvider client={queryClient}>
            <Provider store={store}>
            <Header />
            <ParticleBackground />
            <Routes>

                {
                    routes.map((data,idx) => (
                        <Route key={idx} path={data.path} element={data.component} exact />
                    ))
                }

                <Route path='*' element={<Page404 />} />
            </Routes>

            <Footer />
            </Provider>
            </QueryClientProvider>
            <ToastContainer 
            />

        </>
    );
}

export default App;
