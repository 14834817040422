import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCartShopping, faPhotoFilm, faKey, faTrash } from '@fortawesome/free-solid-svg-icons';
import { setCart } from '../../../reducers/cartReducer';
import moment from 'moment';
import {titleCase} from '../../../uai/utils/common';
import PageTitle from '../../../components/pagetitle/PageTitle';
import {Divider} from '../../utils/common';
import BaseEmptyBG from '../../../images/BaseEmptyBG.png'
import img2 from '../../../assets/images/product/product5.jpg'
import img3 from '../../../assets/images/product/product6.jpg'
import '../../../uai/uaistyle.css';
import icon1 from '../../../assets/images/svg/icon-wallet-1.svg'
import icon2 from '../../../assets/images/svg/icon-wallet-2.svg'
import icon3 from '../../../assets/images/svg/icon-wallet-3.svg'
import icon4 from '../../../assets/images/svg/icon-wallet-4.svg'
import icon5 from '../../../assets/images/svg/icon-wallet-5.svg'
import icon6 from '../../../assets/images/svg/icon-wallet-6.svg'
import icon7 from '../../../assets/images/svg/icon-wallet-7.svg'
import icon8 from '../../../assets/images/svg/icon-wallet-8.svg'

import avtf1 from '../../../assets/images/author/author-follow1.jpg'
import avtf2 from '../../../assets/images/author/author-follow2.jpg'
import avtf3 from '../../../assets/images/author/author-follow3.jpg'
import avtf4 from '../../../assets/images/author/author-follow4.jpg'
import avtf5 from '../../../assets/images/author/author-follow3.jpg'
import avtf6 from '../../../assets/images/author/author-follow4.jpg'
import { useAuth} from '../../../uai/api/useAuth';

import CardItem from './components/cardItem';
import CartGrid from './components/cartGrid';
import { getMatchingCoupon } from '../../../uai/api/licenses';
import { LoadCheckoutStripe } from '../../../uai/api/stripe/Checkout';
import { setAppState } from '../../../reducers/cartReducer';
import '../../../uai/uaistyle.css';
// import {setStrapiUser, setUserMediaContent} from '../reducers/cartReducer';
// import { checkUser } from '../uai/api/auth';
// import {  GetUserMediaContents } from '../uai/api/mediaContent';


CartCard.propTypes = {
    
};

function CartCard(props) {
    const userRaw = localStorage.getItem('user');
    const user_ = userRaw != undefined | userRaw != null  | userRaw != "{}" | userRaw != "" ? JSON.parse(userRaw) : null;
   const { pending:isLoading, isSignedIn:isAuthenticated,user:user, auth:auth_ } = useAuth()

    // const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const [licenseComponents, setLicenseComponents] = useState([]);
    const [finalprice, setfinalprice] = useState(0);
    const [totalprice, settotalprice] = useState(0);
    const [subtotalprice, setsubtotalprice] = useState(0);
    const [promoCode, setpromoCode] = useState("");
    const [discount, setdiscount] = useState(0);
    const [percentOff, setpercentOff] = useState(0);
    const [coupon, setcoupon] = useState(undefined);
    const [isLoaded, setisLoaded] = useState(false);
    const [cartComponent, setcartComponent] = useState(null);
    const state = useSelector(state => state.appState.cart);
    const fullstate = useSelector(state => state);
   
    const [cartItems, setcartItems] = useState([]);
    const dispatch = useDispatch();
    const handleCallback = () => {

    }
    const applyPromo = () => {

        applyCoupon();
    }
    
  const purchaseClicked = async () => {
    if (user_ == null) {
        let newState = fullstate;
        newState.appState.callbackDate = {
        "code": "cart",
        "state": "cart",
        "access_token": "",
        "refresh_token": "",
        "metaData": {},
        }
        dispatch(setAppState(newState));
        window.location.href = '/login';
    //   loginWithRedirect();
    } else {
      if (state.coupon != {} && state.coupon != undefined && state.coupon != null) {
          // alert(`Coupon found ${ couponID}`);

          LoadCheckoutStripe(fullstate);
          // console.log('couponID: ', couponID);
      } else {
        // alert('Coupon not found2');

        LoadCheckoutStripe(fullstate);
      }
    }
    };

    const checkoutClicked = (e) => {
        e.preventDefault();

        purchaseClicked();
    }

    const updateTable = () => {
        setcartItems(state.items);
        console.log(state);
        let RenderArray = state;

        if(state.items.length == 0){
            setLicenseComponents(
                
                <CardItem image={BaseEmptyBG} name={"Nothing" } quantity={0} price={"$"+0.00} tax={"$"+0.00} total={"$"+0.00} commands={<FontAwesomeIcon  className='pr-3 icon-fill hover:text-blue-600 !hover:text-xl2 cursor-pointer' icon={faTrash} onClick={()=>{
                    }} />} />



            )

        }
        else{

        setLicenseComponents(
            state.items.map(item => {
        console.log(item);
        let plan = "";
        if(item.plan){
            plan = " " + item.plan.nickname;
        }

                return(
                    
                    <CardItem image={item.dlc.image} name={item.dlc.name  +  plan } quantity={1} price={"$"+item.plan.unit_amount / 100} tax={0.00} total={"$"+item.plan.unit_amount / 100} commands={<FontAwesomeIcon  className='pr-3 icon-fill hover:text-blue-600 !hover:text-xl2 cursor-pointer' icon={faTrash} onClick={()=>{
    removeCartItem(item);
    }} />} />



                );
            })
        )
    }
    }
    const removeCartItem = (item) => {

        let newCart = state;
        newCart.items = newCart.items.filter((cartItem) => {
            return cartItem != item;
        });
        dispatch(setCart(newCart));
        updateTable();

    }

    const subtotal = () => {

        let newSubtotal = 0;
        for (let i = 0; i < state.items.length; i++) {
            newSubtotal += state.items[i].plan.unit_amount / 100;
          }
            return newSubtotal;
    }


  const applyCoupon = async () => {
    // get text from input named "coupon"
    const couponText = state.couponCode;
    console.log('couponText: ', couponText);

    const matchingCoupon = await getMatchingCoupon(couponText);
    console.log('coupon', matchingCoupon);
    if (Array.isArray(matchingCoupon)) {
      if (matchingCoupon.length !== 0) {
        const choice = matchingCoupon[0];

        localStorage.setItem('coupon', JSON.stringify(choice));
        settotalprice(choice);
        setdiscount((subtotalprice * (choice.coupon.percent_off / 100)));

        let newState = state;
        let newSubtotal = subtotal();
       
        newState.subtotal = newSubtotal;
        newState.coupon = choice;
        newState.totalPrice = (newSubtotal - (newSubtotal * (choice.coupon.percent_off / 100)));
        newState.percentOff = choice.coupon.percent_off;
        newState.discount = (newSubtotal * (choice.coupon.percent_off / 100));
        dispatch(setCart(newState));
        updateTable();
        alert(`Coupon applied! ${couponText}`);
    }else{
        alert('Coupon not found');
    }
    }else{
        alert('Coupon not found');
    }
    };

    useEffect(() => {
        const checkUserStatus = async () => {
            
            let newState = state;
            newState.subtotal = subtotal();
            newState.totalPrice = subtotal();
            dispatch(setCart(newState));
            // setCurrentText('Please wait while your account information loads.\nIf the page does not reload automatically after 1 minute, please click this message.');
            updateTable();
            handleCallback();
            };
            if(isAuthenticated){
                checkUserStatus();
            }
            checkUserStatus();

    }, [user, isAuthenticated, isLoading, state]);

    return (
      
                                            <div className="inner-content inventory">
                                                    <PageTitle sub='Products' title='Cart' />

                                    <div className="table-ranking top cart-heading-padding">

                                        <CartGrid image={"Image"} name={"Name"} quantity={"Quantity"} price={"Price"} tax={"Tax"} total={"Total"} commands={"Commands"} classOverride={"fontsize-small"}  allClassOverride={"fontsize-small"} />
                                         

                                        </div> 





                                        <div className="table-ranking ">

                                    {licenseComponents}
                                    <div className="cart__bottom relative  mb-3 py-10 inline">
              


            <div className="flex !my-4 !py-4 !text-right">
                                                    {/* <label  className="text-lg my-auto mr-4 ">Promo Code</label> */}
                                                    <input type="text" className="h-4  !w-[25rem]  my-auto mr-4 promoCode"  placeholder="Promo Code" onChange={(e)=>{
                                                        
                                                        setpromoCode(e.target.value);
                                                        let newState = state;
                                                        newState.couponCode = e.target.value;
                                                        dispatch(setCart(newState));
                                                        
                                                        }} />
                                                    <div className="table-btn my-auto">
                                                <Link className="" to="#" onClick={applyPromo}>Apply</Link>
                                            </div>
                                                </div>

            <Divider/>
                                                
                                    <p className="cart__sub-total !text-xl text-right pr-4">Sub-total: ${state.subtotal ? state.subtotal.toFixed(2) : 0.00}</p>
              <p className="cart__total !text-right text-xl tracking-normal pr-4">Discount: -${state.discount.toFixed(2)}</p>

              <p className="font-bold !text-right text-2xl tracking-normal pr-4">Total Price: ${state.totalPrice ? state.totalPrice.toFixed(2): 0.00}</p>

              <fieldset className="flex !my-4 !pb-10 !text-right">
                                                    {/* <label  className="text-lg my-auto mr-4 ">Promo Code</label> */}
                                                    <div className="table-btn my-auto absolute right-0">
                                                <Link className=" float-right" to="#" onClick={checkoutClicked}>Check Out</Link>
                                            </div>
                                                </fieldset>

                                            </div>
                                        </div>
                                            </div>
    );
}

export default CartCard;