export class CMSState
    {
        constructor() {
        this.mode = "all";
        this.selectedCMSItem = {};
        this.selectedPlaylistItem = {};
        this.selectedKeyframe = {};
        this.selectedStyle = {};
        this.selectedSceneIndex = 0;
        }

        json(){
            return JSON.stringify(this);
        }
    }


export class ShowAppState
{
    constructor() {
    this.mode = "all";
    this.selectedCMSItem = {};
    this.selectedPlaylistItem = {};
    this.selectedKeyframe = {};
    this.selectedStyle = {};
    this.selectedSceneIndex = 0;
    }

    json(){
        return JSON.stringify(this);
    }
}