import React from 'react';
import {useEffect, useState} from 'react';
import { useAPIKey } from '../../../../api/useAPIKey';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

const APIKeyMessageModal = (props) => {
  
  const [show, setShow] = useState(false);
  const {apiKey , verified, isLoading}  =  useAPIKey();
  const [onHide, setOnHide] = useState(()=>{});
  const [apiKeyModalTitle, setAPIKeyModalTitle] = useState('Missing API Key');
  const [apiKeyModalBody, setAPIKeyModalBody] = useState('An active API Key is required to utilize this application. Please select an active API Key on your Account Page.');

  useEffect(() => {
    if(!isLoading){
    if(!verified && !isLoading){
        setAPIKeyModalTitle('Invalid API Key1');
        setAPIKeyModalBody('An Active API Key is required to utilize this application. Please select an active API Key on your Account Page.');
        setShow(true);
        setOnHide(()=>{
        });
      }
      
    else if(apiKey == null && !isLoading){
        setAPIKeyModalTitle('Invalid API Key2');
        setAPIKeyModalBody('A properly formatted API Key is required to utilize this application. Please select an active API Key on your Account Page.');
        setShow(true);
        setOnHide(()=>{
        });
      }else{
        setShow(false);
      }
      }


  }, [apiKey , verified, isLoading]);


return (

    <Modal
    show={show}
    onHide={()=>{
        setShow(false);
        window.location.href = '/dashboard';

    }}
  >
    <Modal.Header closeButton></Modal.Header>

    <div className="modal-body h-fit">
        <h3>{apiKeyModalTitle}</h3>
        <p className="text-center sub-heading">{apiKeyModalBody}</p>
        <Link to="/wallet-connect" onClick={(e)=>{
            e.preventDefault();
            setShow(false);
          window.location.href = '/dashboard';

        }} className="button-popup" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close">Close</Link>
    </div>
    </Modal>
    
  );
};

export default APIKeyMessageModal;
