const ColorsUAI = () => {
        
        
        return (
            <>
            <div class='bg-uaipurple-0 w-2 h-2' ></div>
<div class='bg-uaipurple-1 w-2 h-2' ></div>
<div class='bg-uaipurple-2 w-2 h-2' ></div>
<div class='bg-uaipurple-3 w-2 h-2' ></div>
<div class='bg-uaipurple-4 w-2 h-2' ></div>
<div class='bg-uaipurple-5 w-2 h-2' ></div>
<div class='bg-uaipurple-6 w-2 h-2' ></div>
<div class='bg-uaipurple-7 w-2 h-2' ></div>
<div class='bg-uaipurple-8 w-2 h-2' ></div>
<div class='bg-uaipurple-9 w-2 h-2' ></div>
<div class='bg-uaipurple-10 w-2 h-2' ></div>
<div class='bg-uaipurple-11 w-2 h-2' ></div>
<div class='bg-uaipurple-12 w-2 h-2' ></div>
<div class='bg-uaipurple-13 w-2 h-2' ></div>
<div class='bg-uaipurple-14 w-2 h-2' ></div>
<div class='bg-uaipurple-15 w-2 h-2' ></div>
<div class='bg-uaipurple-16 w-2 h-2' ></div>
<div class='bg-uaipurple-17 w-2 h-2' ></div>
<div class='bg-uaipurple-18 w-2 h-2' ></div>
<div class='bg-uaipurple-19 w-2 h-2' ></div>
<div class='bg-uaipurple-20 w-2 h-2' ></div>
<div class='bg-uaipurple-21 w-2 h-2' ></div>
<div class='bg-uaipurple-22 w-2 h-2' ></div>
<div class='bg-uaipurple-23 w-2 h-2' ></div>
<div class='bg-uaipurple-24 w-2 h-2' ></div>
<div class='bg-uaipurple-25 w-2 h-2' ></div>
<div class='bg-uaipurple-26 w-2 h-2' ></div>
<div class='bg-uaipurple-27 w-2 h-2' ></div>
<div class='bg-uaipurple-28 w-2 h-2' ></div>
<div class='bg-uaipurple-29 w-2 h-2' ></div>
<div class='bg-uaipurple-30 w-2 h-2' ></div>
<div class='bg-uaipurple-31 w-2 h-2' ></div>
<div class='bg-uaipurple-32 w-2 h-2' ></div>
<div class='bg-uaipurple-33 w-2 h-2' ></div>
<div class='bg-uaipurple-34 w-2 h-2' ></div>
<div class='bg-uaipurple-35 w-2 h-2' ></div>
<div class='bg-uaipurple-36 w-2 h-2' ></div>
<div class='bg-uaipurple-37 w-2 h-2' ></div>
<div class='bg-uaipurple-38 w-2 h-2' ></div>
<div class='bg-uaipurple-39 w-2 h-2' ></div>
<div class='bg-uaipurple-40 w-2 h-2' ></div>
<div class='bg-uaipurple-41 w-2 h-2' ></div>
<div class='bg-uaipurple-42 w-2 h-2' ></div>
<div class='bg-uaipurple-43 w-2 h-2' ></div>
<div class='bg-uaipurple-44 w-2 h-2' ></div>
<div class='bg-uaipurple-45 w-2 h-2' ></div>
<div class='bg-uaipurple-46 w-2 h-2' ></div>
<div class='bg-uaipurple-47 w-2 h-2' ></div>
<div class='bg-uaipurple-48 w-2 h-2' ></div>
<div class='bg-uaipurple-49 w-2 h-2' ></div>
<div class='bg-uaiblue-0 w-2 h-2' ></div>
<div class='bg-uaiblue-1 w-2 h-2' ></div>
<div class='bg-uaiblue-2 w-2 h-2' ></div>
<div class='bg-uaiblue-3 w-2 h-2' ></div>
<div class='bg-uaiblue-4 w-2 h-2' ></div>
<div class='bg-uaiblue-5 w-2 h-2' ></div>
<div class='bg-uaiblue-6 w-2 h-2' ></div>
<div class='bg-uaiblue-7 w-2 h-2' ></div>
<div class='bg-uaiblue-8 w-2 h-2' ></div>
<div class='bg-uaiblue-9 w-2 h-2' ></div>
<div class='bg-uaiblue-10 w-2 h-2' ></div>
<div class='bg-uaiblue-11 w-2 h-2' ></div>
<div class='bg-uaiblue-12 w-2 h-2' ></div>
<div class='bg-uaiblue-13 w-2 h-2' ></div>
<div class='bg-uaiblue-14 w-2 h-2' ></div>
<div class='bg-uaiblue-15 w-2 h-2' ></div>
<div class='bg-uaiblue-16 w-2 h-2' ></div>
<div class='bg-uaiblue-17 w-2 h-2' ></div>
<div class='bg-uaiblue-18 w-2 h-2' ></div>
<div class='bg-uaiblue-19 w-2 h-2' ></div>
<div class='bg-uaiblue-20 w-2 h-2' ></div>
<div class='bg-uaiblue-21 w-2 h-2' ></div>
<div class='bg-uaiblue-22 w-2 h-2' ></div>
<div class='bg-uaiblue-23 w-2 h-2' ></div>
<div class='bg-uaiblue-24 w-2 h-2' ></div>
<div class='bg-uaiblue-25 w-2 h-2' ></div>
<div class='bg-uaiblue-26 w-2 h-2' ></div>
<div class='bg-uaiblue-27 w-2 h-2' ></div>
<div class='bg-uaiblue-28 w-2 h-2' ></div>
<div class='bg-uaiblue-29 w-2 h-2' ></div>
<div class='bg-uaiblue-30 w-2 h-2' ></div>
<div class='bg-uaiblue-31 w-2 h-2' ></div>
<div class='bg-uaiblue-32 w-2 h-2' ></div>
<div class='bg-uaiblue-33 w-2 h-2' ></div>
<div class='bg-uaiblue-34 w-2 h-2' ></div>
<div class='bg-uaiblue-35 w-2 h-2' ></div>
<div class='bg-uaiblue-36 w-2 h-2' ></div>
<div class='bg-uaiblue-37 w-2 h-2' ></div>
<div class='bg-uaiblue-38 w-2 h-2' ></div>
<div class='bg-uaiblue-39 w-2 h-2' ></div>
<div class='bg-uaiblue-40 w-2 h-2' ></div>
<div class='bg-uaiblue-41 w-2 h-2' ></div>
<div class='bg-uaiblue-42 w-2 h-2' ></div>
<div class='bg-uaiblue-43 w-2 h-2' ></div>
<div class='bg-uaiblue-44 w-2 h-2' ></div>
<div class='bg-uaiblue-45 w-2 h-2' ></div>
<div class='bg-uaiblue-46 w-2 h-2' ></div>
<div class='bg-uaiblue-47 w-2 h-2' ></div>
<div class='bg-uaiblue-48 w-2 h-2' ></div>
<div class='bg-uaiblue-49 w-2 h-2' ></div>
<div class='bg-uaiyellow-0 w-2 h-2' ></div>
<div class='bg-uaiyellow-1 w-2 h-2' ></div>
<div class='bg-uaiyellow-2 w-2 h-2' ></div>
<div class='bg-uaiyellow-3 w-2 h-2' ></div>
<div class='bg-uaiyellow-4 w-2 h-2' ></div>
<div class='bg-uaiyellow-5 w-2 h-2' ></div>
<div class='bg-uaiyellow-6 w-2 h-2' ></div>
<div class='bg-uaiyellow-7 w-2 h-2' ></div>
<div class='bg-uaiyellow-8 w-2 h-2' ></div>
<div class='bg-uaiyellow-9 w-2 h-2' ></div>
<div class='bg-uaiyellow-10 w-2 h-2' ></div>
<div class='bg-uaiyellow-11 w-2 h-2' ></div>
<div class='bg-uaiyellow-12 w-2 h-2' ></div>
<div class='bg-uaiyellow-13 w-2 h-2' ></div>
<div class='bg-uaiyellow-14 w-2 h-2' ></div>
<div class='bg-uaiyellow-15 w-2 h-2' ></div>
<div class='bg-uaiyellow-16 w-2 h-2' ></div>
<div class='bg-uaiyellow-17 w-2 h-2' ></div>
<div class='bg-uaiyellow-18 w-2 h-2' ></div>
<div class='bg-uaiyellow-19 w-2 h-2' ></div>
<div class='bg-uaiyellow-20 w-2 h-2' ></div>
<div class='bg-uaiyellow-21 w-2 h-2' ></div>
<div class='bg-uaiyellow-22 w-2 h-2' ></div>
<div class='bg-uaiyellow-23 w-2 h-2' ></div>
<div class='bg-uaiyellow-24 w-2 h-2' ></div>
<div class='bg-uaiyellow-25 w-2 h-2' ></div>
<div class='bg-uaiyellow-26 w-2 h-2' ></div>
<div class='bg-uaiyellow-27 w-2 h-2' ></div>
<div class='bg-uaiyellow-28 w-2 h-2' ></div>
<div class='bg-uaiyellow-29 w-2 h-2' ></div>
<div class='bg-uaiyellow-30 w-2 h-2' ></div>
<div class='bg-uaiyellow-31 w-2 h-2' ></div>
<div class='bg-uaiyellow-32 w-2 h-2' ></div>
<div class='bg-uaiyellow-33 w-2 h-2' ></div>
<div class='bg-uaiyellow-34 w-2 h-2' ></div>
<div class='bg-uaiyellow-35 w-2 h-2' ></div>
<div class='bg-uaiyellow-36 w-2 h-2' ></div>
<div class='bg-uaiyellow-37 w-2 h-2' ></div>
<div class='bg-uaiyellow-38 w-2 h-2' ></div>
<div class='bg-uaiyellow-39 w-2 h-2' ></div>
<div class='bg-uaiyellow-40 w-2 h-2' ></div>
<div class='bg-uaiyellow-41 w-2 h-2' ></div>
<div class='bg-uaiyellow-42 w-2 h-2' ></div>
<div class='bg-uaiyellow-43 w-2 h-2' ></div>
<div class='bg-uaiyellow-44 w-2 h-2' ></div>
<div class='bg-uaiyellow-45 w-2 h-2' ></div>
<div class='bg-uaiyellow-46 w-2 h-2' ></div>
<div class='bg-uaiyellow-47 w-2 h-2' ></div>
<div class='bg-uaiyellow-48 w-2 h-2' ></div>
<div class='bg-uaiyellow-49 w-2 h-2' ></div>
<div class='bg-uailightblue-0 w-2 h-2' ></div>
<div class='bg-uailightblue-1 w-2 h-2' ></div>
<div class='bg-uailightblue-2 w-2 h-2' ></div>
<div class='bg-uailightblue-3 w-2 h-2' ></div>
<div class='bg-uailightblue-4 w-2 h-2' ></div>
<div class='bg-uailightblue-5 w-2 h-2' ></div>
<div class='bg-uailightblue-6 w-2 h-2' ></div>
<div class='bg-uailightblue-7 w-2 h-2' ></div>
<div class='bg-uailightblue-8 w-2 h-2' ></div>
<div class='bg-uailightblue-9 w-2 h-2' ></div>
<div class='bg-uailightblue-10 w-2 h-2' ></div>
<div class='bg-uailightblue-11 w-2 h-2' ></div>
<div class='bg-uailightblue-12 w-2 h-2' ></div>
<div class='bg-uailightblue-13 w-2 h-2' ></div>
<div class='bg-uailightblue-14 w-2 h-2' ></div>
<div class='bg-uailightblue-15 w-2 h-2' ></div>
<div class='bg-uailightblue-16 w-2 h-2' ></div>
<div class='bg-uailightblue-17 w-2 h-2' ></div>
<div class='bg-uailightblue-18 w-2 h-2' ></div>
<div class='bg-uailightblue-19 w-2 h-2' ></div>
<div class='bg-uailightblue-20 w-2 h-2' ></div>
<div class='bg-uailightblue-21 w-2 h-2' ></div>
<div class='bg-uailightblue-22 w-2 h-2' ></div>
<div class='bg-uailightblue-23 w-2 h-2' ></div>
<div class='bg-uailightblue-24 w-2 h-2' ></div>
<div class='bg-uailightblue-25 w-2 h-2' ></div>
<div class='bg-uailightblue-26 w-2 h-2' ></div>
<div class='bg-uailightblue-27 w-2 h-2' ></div>
<div class='bg-uailightblue-28 w-2 h-2' ></div>
<div class='bg-uailightblue-29 w-2 h-2' ></div>
<div class='bg-uailightblue-30 w-2 h-2' ></div>
<div class='bg-uailightblue-31 w-2 h-2' ></div>
<div class='bg-uailightblue-32 w-2 h-2' ></div>
<div class='bg-uailightblue-33 w-2 h-2' ></div>
<div class='bg-uailightblue-34 w-2 h-2' ></div>
<div class='bg-uailightblue-35 w-2 h-2' ></div>
<div class='bg-uailightblue-36 w-2 h-2' ></div>
<div class='bg-uailightblue-37 w-2 h-2' ></div>
<div class='bg-uailightblue-38 w-2 h-2' ></div>
<div class='bg-uailightblue-39 w-2 h-2' ></div>
<div class='bg-uailightblue-40 w-2 h-2' ></div>
<div class='bg-uailightblue-41 w-2 h-2' ></div>
<div class='bg-uailightblue-42 w-2 h-2' ></div>
<div class='bg-uailightblue-43 w-2 h-2' ></div>
<div class='bg-uailightblue-44 w-2 h-2' ></div>
<div class='bg-uailightblue-45 w-2 h-2' ></div>
<div class='bg-uailightblue-46 w-2 h-2' ></div>
<div class='bg-uailightblue-47 w-2 h-2' ></div>
<div class='bg-uailightblue-48 w-2 h-2' ></div>
<div class='bg-uailightblue-49 w-2 h-2' ></div>
<div class='bg-uaiblack-0 w-2 h-2' ></div>
<div class='bg-uaiblack-1 w-2 h-2' ></div>
<div class='bg-uaiblack-2 w-2 h-2' ></div>
<div class='bg-uaiblack-3 w-2 h-2' ></div>
<div class='bg-uaiblack-4 w-2 h-2' ></div>
<div class='bg-uaiblack-5 w-2 h-2' ></div>
<div class='bg-uaiblack-6 w-2 h-2' ></div>
<div class='bg-uaiblack-7 w-2 h-2' ></div>
<div class='bg-uaiblack-8 w-2 h-2' ></div>
<div class='bg-uaiblack-9 w-2 h-2' ></div>
<div class='bg-uaiblack-10 w-2 h-2' ></div>
<div class='bg-uaiblack-11 w-2 h-2' ></div>
<div class='bg-uaiblack-12 w-2 h-2' ></div>
<div class='bg-uaiblack-13 w-2 h-2' ></div>
<div class='bg-uaiblack-14 w-2 h-2' ></div>
<div class='bg-uaiblack-15 w-2 h-2' ></div>
<div class='bg-uaiblack-16 w-2 h-2' ></div>
<div class='bg-uaiblack-17 w-2 h-2' ></div>
<div class='bg-uaiblack-18 w-2 h-2' ></div>
<div class='bg-uaiblack-19 w-2 h-2' ></div>
<div class='bg-uaiblack-20 w-2 h-2' ></div>
<div class='bg-uaiblack-21 w-2 h-2' ></div>
<div class='bg-uaiblack-22 w-2 h-2' ></div>
<div class='bg-uaiblack-23 w-2 h-2' ></div>
<div class='bg-uaiblack-24 w-2 h-2' ></div>
<div class='bg-uaiblack-25 w-2 h-2' ></div>
<div class='bg-uaiblack-26 w-2 h-2' ></div>
<div class='bg-uaiblack-27 w-2 h-2' ></div>
<div class='bg-uaiblack-28 w-2 h-2' ></div>
<div class='bg-uaiblack-29 w-2 h-2' ></div>
<div class='bg-uaiblack-30 w-2 h-2' ></div>
<div class='bg-uaiblack-31 w-2 h-2' ></div>
<div class='bg-uaiblack-32 w-2 h-2' ></div>
<div class='bg-uaiblack-33 w-2 h-2' ></div>
<div class='bg-uaiblack-34 w-2 h-2' ></div>
<div class='bg-uaiblack-35 w-2 h-2' ></div>
<div class='bg-uaiblack-36 w-2 h-2' ></div>
<div class='bg-uaiblack-37 w-2 h-2' ></div>
<div class='bg-uaiblack-38 w-2 h-2' ></div>
<div class='bg-uaiblack-39 w-2 h-2' ></div>
<div class='bg-uaiblack-40 w-2 h-2' ></div>
<div class='bg-uaiblack-41 w-2 h-2' ></div>
<div class='bg-uaiblack-42 w-2 h-2' ></div>
<div class='bg-uaiblack-43 w-2 h-2' ></div>
<div class='bg-uaiblack-44 w-2 h-2' ></div>
<div class='bg-uaiblack-45 w-2 h-2' ></div>
<div class='bg-uaiblack-46 w-2 h-2' ></div>
<div class='bg-uaiblack-47 w-2 h-2' ></div>
<div class='bg-uaiblack-48 w-2 h-2' ></div>
<div class='bg-uaiblack-49 w-2 h-2' ></div>
            </>
            )
    }
 export {ColorsUAI}