import '../../uai/uaistyle.css';


export const baseCollection = {
    "id": -1,
    "name": "Uncatagorized",
    "description": "Media not in a collection",
    "owner": -1,
    "nsfw": false,
    "visibility": "Private",
    "tags": "uncatagorized",
    "category": "User",
    "published_at": "2023-04-15T07:35:53.134Z",
    "created_at": "2023-04-15T07:35:50.668Z",
    "updated_at": "2023-04-15T09:17:23.824Z"
}

export function titleCase(str) {
  
    var lowercase = str.toLowerCase();
    //return lowercase;
    
    var split = lowercase.split(" ");
    var fullword = [];
    //return split;
    
    for(var i = 0; i < split.length; i++) {
      
      var firstletter = split[i].charAt(0).toUpperCase();
      var reststring = split[i].slice(1);
      
      
      fullword[i] = firstletter + reststring;
      
      
    }
    
    return fullword.join(" ");
      
      }
    export  function copy(source, deep) {
        var o, prop, type;
     
       if (typeof source != 'object' || source === null) {
         // What do to with functions, throw an error?
         o = source;
         return o;
       }
     
       o = new source.constructor();
     
       for (prop in source) {
     
         if (source.hasOwnProperty(prop)) {
           type = typeof source[prop];
     
           if (deep && type == 'object' && source[prop] !== null) {
             o[prop] = copy(source[prop]);
     
           } else {
             o[prop] = source[prop];
           }
         }
       }
       return o;
     }
export  const MediaContentToPlaylists = (state) =>{
    let collections = [];
    let userState = JSON.parse(localStorage.getItem('user'));
    let containsplaylists = userState.mediaContent.filter(item => item.playlists.length > 0);
    // let containsplaylists = state.appState.mediaContent.filter(item => item.playlists.length > 0);
    for (let i = 0; i < containsplaylists.length; i++) {
        for (let j = 0; j < containsplaylists[i].playlists.length; j++) {
            let newPlaylist = copy(containsplaylists[i].playlists[j],true);
            newPlaylist.mediaContent = [];
            collections.push(newPlaylist);
        }
    }
    // remove duplicates
    collections = collections.filter((item, index, self) =>
        index === self.findIndex((t) => (
        t.id === item.id
        ))
    )
    for (let i = 0; i < collections.length; i++) {
        for (let j = 0; j < userState.mediaContent.length; j++) {
            for (let k = 0; k < userState.mediaContent[j].playlists.length; k++) {
                if (collections[i].id == userState.mediaContent[j].playlists[k].id) {
                    const matchedContent = collections[i].mediaContent.filter(item => item.id == userState.mediaContent[j].id);
                    if(matchedContent.length == 0){
                        let newContent = copy(userState.mediaContent[j],true);
                        delete newContent.playlists;
                    collections[i].mediaContent.push(newContent);
                    }
                }
            }
        }
    }
    let emptyCollection = baseCollection;
    emptyCollection.mediaContent = [];
    for (let j = 0; j < userState.mediaContent.length; j++) {
        if(userState.mediaContent[j].playlists.length == 0){
            let newContent = userState.mediaContent[j];
                        delete newContent.playlists;
            emptyCollection.mediaContent.push(newContent);
        }
    }
    collections.push(emptyCollection);
    return collections;
}

export     const Divider = () => {
    return (
        <hr
            className="Divider border-solid border-[#28303fb7] !border-t "
        ></hr>
    );
};