/* eslint-disable prefer-destructuring */
import axios from 'axios';
import { getLicenseTypes, createPlaceholderLicense } from '../api/licenses';
import {hideLoading, setMode, setProducts, showLoading, setIsProcessing} from '../../reducers/cartReducer';
import { loginFrontEndUser, getFrontendJWT } from './auth';
import {getProducts} from './firebaseHandler';
import StandardPack from '../utils/ProductStandardPack';

export async function getMatchingVariation(product, targetString) {
    const match = product.metadata.variations.filter((item) => {
        let outitem = false;
if (item.Name === targetString) {
    outitem = item;
} 
return outitem;
});
return match;
}

export async function getdlcs() {
    // const jwt = await getFrontendJWT();
    
    const endpoint = `${process.env.REACT_APP_API }/dlcs/list`;
    const result = await axios
        .get(endpoint, {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}
const template = {
    id: 1,
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
    title: 'Blue Vase',
    price: '12',
    oldprice: '10',
    description: 'Awesome item from Glassy brand',
    colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
    new: true,
    };
export const asyncFilter = async (arr, predicate) => {
        const results = await Promise.all(arr.map(predicate));
    
        return arr.filter((_v, index) => results[index]);
    };

export const getPriceByID = async (priceID) => {
    // // eslint-disable-next-line max-len
    // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    //     const price = await stripePromise.prices.retrieve(
    //     priceID,
    // );
    const price = await axios.post(`${process.env.REACT_APP_API }/product/getMatchingPriceID`, { StripeID: priceID });

    return price.data;
};

function toTitleCase(str) {
    const newStr = str
      .split(' ')
      .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
    return newStr;
  }

  export const getcatalog = async (state, dispatch, product="" ) => {
    // if (state.appState.products.catalogList === undefined || state.appState.products.catalogList.length === 0) {
    dispatch(showLoading('Loading','Loading Products List'));

    const data = await getProductsList();
    dispatch(showLoading('Loading','Parsing Products List'));

    // filter data by .metadata.productType === 'Plugin' and name the variable plugins
    const newplugins = data.filter(item => item.metadata.productType === 'Plugins');
    const newapps = data.filter(item => item.metadata.productType === 'App');
    let newproducts = state.appState.products;
    newproducts.catalogList = data;
    newproducts.plugins = newplugins;
    newproducts.apps = newapps;
    if(product !== ""){
    const currentProducts = data.filter(item => item.title === product);
    if(currentProducts.length > 0){
    newproducts.currentProduct = currentProducts[0];
    }
    }
    dispatch(setProducts(newproducts));
    dispatch(setMode('main'));
    dispatch(hideLoading());
    dispatch(setIsProcessing(false));
    localStorage.setItem('products', JSON.stringify(newproducts));
    return data;

    // }
  };


  export const getStripeInfo = async (productId ) => {

    const endpoint = `${process.env.REACT_APP_API }/apiv1/products/getStripeInfo`;
    const result = await axios
        .post(endpoint, { product: productId },
        {
            headers: {
                'content-type': 'application/json',
                },
        }).catch(err => (err));
    try {
        return await result.data;
    } catch {
        return undefined;
    }
    
  }

  export const getPriceInfo = async (priceId ) => {

    const endpoint = `${process.env.REACT_APP_API }/apiv1/prices/getMatch`;
    const result = await axios
        .post(endpoint, { price: priceId },
        {
            headers: {
                'content-type': 'application/json',
                },
        }).catch(err => (err));
    try {
        return await result.data;
    } catch {
        return undefined;
    }
    
  }

  export const refreshProducts = async (state, dispatch ) => {
    // console.log(store.getState().uaiUser.currentproductSelected);
    dispatch(showLoading('Loading','Loading license types'));

    const userresult = await getLicenseTypes();
    dispatch(showLoading('Loading','Loading products'));

    let productsdlc = await getdlcs();
    const data = await userresult;
    const licenseselect = [];
    let index = 0;
    for (index = 0; index < data.data.length; index += 1) {
      licenseselect.push({
        value: data.data[index].id,
        label: toTitleCase(data.data[index].name),
        color: '#f7a9c4',
      });
    }

    let newproducts = state.appState.products;
    const uaiLicenseIndex = productsdlc.data.findIndex(x => x.name === 'UAI License');
    newproducts.uaiLicenseProduct = productsdlc.data[uaiLicenseIndex];
    productsdlc.data.unshift(productsdlc.data.splice(uaiLicenseIndex, 1)[0]);

    newproducts.products = productsdlc.data;
    newproducts.licenseTypes = data.data;
    newproducts.licenseTypesSelect = licenseselect;
    newproducts.viewResults = productsdlc.data.data;
    newproducts.currentProduct = StandardPack;
    
    localStorage.setItem('products', JSON.stringify(newproducts));
    dispatch(setProducts(newproducts));
    await getcatalog(state, dispatch)
    dispatch(hideLoading());
return newproducts;
  };
//   export const refreshProducts = async (state, dispatch ) => {
//     console.log("refreshProducts");
//     // console.log(store.getState().uaiUser.currentproductSelected);
//     dispatch(showLoading('Loading','Loading license types'));

//     const userresult = await getLicenseTypes();
//     dispatch(showLoading('Loading','Loading products'));

//     const productsdlc = await getdlcs();
//     const data = await userresult;
//     const licenseselect = [];
//     let index = 0;
//     for (index = 0; index < data.data.length; index += 1) {
//       licenseselect.push({
//         value: data.data[index].id,
//         label: toTitleCase(data.data[index].name),
//         color: '#f7a9c4',
//       });
//     }

//     let newproducts = state.appState.products;
//     newproducts.products = productsdlc.data;
//     newproducts.licenseTypes = data;
//     newproducts.licenseTypesSelect = licenseselect;
//     console.log('productsdlc', productsdlc);

//     newproducts.viewResults = productsdlc.data;
//     newproducts.currentProduct = StandardPack;
//     newproducts.uaiLicenseProduct = productsdlc.data.filter(x => x? x.name === 'UAI License': false)[0];
//     dispatch(setProducts(newproducts));
//     await getcatalog(state, dispatch)
//     dispatch(hideLoading());
// return newproducts;
//   };


export const getProductsList = async () => {

    // const priceobject = await getPriceByID(itemproductid);

    // const jwt = await getFrontendJWT('https://68.183.115.32:1337');
    const newproducts = await getProducts(false);
    const filter = newproducts;
    // const newproducts = await axios.post(`${process.env.REACT_APP_API }/product/getcatalog`);
    // console.log('newproducts', newproducts);

    // const productsdlc = await getdlcs('https://68.183.115.32:1337');
    // const productsdlc = await getdlcs('https://68.183.115.32:1337');
    console.log('newproducts', newproducts);
//     const filter = newproducts.data.filter((item) => {
//     if (item.showInShop === true) {
//         // const price = await usegetPriceByID(stripeobject, item.Variation[1].Plans[0].StripeID);
//         // console.log('Price', price);
//         return item;
//     }
//     return false;
// });


const outar = [];
    let indx = 0;
    for (indx = 0; indx < filter.length; indx += 1) {
        const item = filter[indx];
        // console.log(item);
        let isSale = false;
        let isNew = false;
        const today = new Date();
        const lastmonth = Date.parse(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30));

        if (item.pricecurrent < item.pricemsrp) {
            isSale = true;
        } else {
            isSale = false;
        }
        if (lastmonth < item.created_at) {
            isNew = false;
        } else {
            isNew = true;
        }
        if (isSale) {
        outar.push({
            id: item.id,
            src: item.image,
            title: item.name,
            price: item.pricecurrent,
            oldprice: item.pricemsrp,
            description: item.shortdescription,
            variations: item.Variation,
            colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
            new: isNew,
            sale: isSale,
            metadata: item,
            });
        } else {
            outar.push({
                id: item.id,
                src: item.image,
                title: item.name,
                price: item.pricecurrent,
                description: item.shortdescription,
                variations: item.Variation,
                colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
                new: isNew,
            metadata: item,
                sale: isSale,
                
                });
        }
    }
    // console.log(outar);
    return outar;
  };
export const getProductsListStrapi = async (stripeobject) => {
    const jwt = await getFrontendJWT();
    // console.log(jwt);
    const productsdlc = await getdlcs();
    const filter = productsdlc.data.filter((item) => {
    // console.log('Variation', item.Variation);
    // console.log('StripeID', item.Variation[1].Plans[0].StripeID);
    if (item.showInShop === true) {
        // const price = await usegetPriceByID(stripeobject, item.Variation[1].Plans[0].StripeID);
        // console.log('Price', price);
        return {
            id: item.id,
            src: item.image,
            title: item.name,
            price: item.pricecurrent,
            oldprice: item.pricemsrp,
            variations: item.variation,
            description: item.shortdescription,
            colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
            };
    }
    return false;
});
const outar = [];
    let indx = 0;
    for (indx = 0; indx < filter.length; indx += 1) {
        const item = filter[indx];
        // console.log(item);
        let isSale = false;
        let isNew = false;
        const today = new Date();
        const lastmonth = Date.parse(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30));

        if (item.pricecurrent < item.pricemsrp) {
            isSale = true;
        } else {
            isSale = false;
        }
        if (lastmonth < item.created_at) {
            isNew = false;
        } else {
            isNew = true;
        }
        if (isSale) {
        outar.push({
            id: item.id,
            src: item.image,
            title: item.name,
            price: item.pricecurrent,
            oldprice: item.pricemsrp,
            description: item.shortdescription,
            variations: item.variation,
            colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
            new: isNew,
            sale: isSale,
            });
        } else {
            outar.push({
                id: item.id,
                src: item.image,
                title: item.name,
                price: item.pricecurrent,
                description: item.shortdescription,
                variations: item.variation,
                colors: ['#00b3c6', '#50e3c2', '#fa4a86'],
                new: isNew,
                sale: isSale,
                });
        }
    }
    // console.log(outar);
    return outar;
  };
export default getdlcs;
