import React from "react";
import "./style.css";
import { BuyIconHorizontal } from "./components/BuyIconHorizontal";

export const ContentInfo = ({children , title="", subtitle="", bodytext="", bgImage = "", showTopRow=true, showSubtitle=true, shadowHeightTop=392, shadowHeightBottom=392, shadowBottom=-166, shadowTop=-243, height='124vh'}) => {
  
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 800;
  
  return (
    <div className="content-info" style={{backgroundImage:'url('+bgImage+')', 
    minHeight: height}}>
      <div className="intro-page">
        <div className="media-content">
          <div className="text-content-area">
          {showTopRow ? 
            <div className="top-content">
              <div className="text-wrapper">{title}</div>
              <img className="sepperating-line" alt="Sepperating line" src="./img/sepperatingline.svg" />
            </div> : <> </>
}


            <div className="bottom-content">
            {showSubtitle? 
              <div className="div">
                <p className="sub-title">{subtitle}</p>
                { width > breakpoint ? children : <></> }
              </div>
              : <> </> }
              <div className="div">
                <p className="body-text text-align-left">
                    {bodytext}
                </p>
              </div>
            { width < breakpoint ? children : <></> }

            </div>



          </div>

          <div className="shadow shadow-top" style={{ top: shadowTop+'px', height: shadowHeightTop+'px',  }} />
          <div className="shadow shadow-bottom" style={{ bottom: shadowBottom+'px', height: shadowHeightBottom+'px',  }}  />

        </div>
      </div>
    </div>
  );
};

export default ContentInfo;

