import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHideNav } from "../../reducers/cartReducer";
import "./styles.scss";
import { ChevronUpIcon, StopIcon } from "@heroicons/react/24/solid";
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button,
} from "@material-tailwind/react";
import { useSignals } from "../../uai/api/signalSlots";
import useRuntime from "../../uai/components/UAIAPP/api/useRuntime";
import AnimatedNumber from "../../uai/components/AnimatedNumber";
import AnimatedText from "../../uai/components/AnimatedText";
import { useSignal, useSlot } from 'react-signal-slot';
// import Button from '../button';

const NavMenuItem = ({ manager = undefined, data = {}, root = false }) => {
    const moduleClicked = (module) => {
        switch (module) {
            case "logout":
                window.location.href = "/logout";

                break;

            case "account":
                window.location.href = "/dashboard";
                break;

            case "quit":
                window.location.href = "/dashboard";
                break;

            default:
                break;
        }
    };
    const menuItemClicked = (module) => {
        switch (module) {
            case "logout":
                window.location.href = "/logout";
                break;

            case "account":
                window.location.href = "/dashboard";
                break;

            case "file.quit":
                dispatch(setHideNav(false));
                localStorage.removeItem("hideNav");
                window.location.href = "/dashboard";
                console.log("quit");
                break;

            default:
                break;
        }
    };
    const project = useRuntime((state) => state.project);
    const sequences = useRuntime((state) => state.sequences);
    const sequence = useRuntime((state) => state.sequence);
    const projectPath = useRuntime((state) => state.projectPath);
    const currentFileBrowserPath = useRuntime((state) => state.currentFileBrowserPath);
    const focusedWindow = useRuntime((state) => state.focusedWindow);
    const appTimeline = useRuntime((state) => state.appTimeline);
    const setIsDirty = useRuntime((state) => state.setIsDirty);
    const setProjectPath = useRuntime((state) => state.setProjectPath);

    const state = useSelector((state) => state);
    const signal = useSignal();
    // const { loginWithRedirect, logout, user, isAuthenticated ,isLoading } = useAuth0();
    const dispatch = useDispatch();
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        if (state.appState.hideNav !== true) {
            dispatch(setHideNav(true));
            window.location.reload();
        }
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        };
    }, []);

    const [openMenu, setOpenMenu] = React.useState(false);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const emitProps = (submenu) =>{
        return {
            data: submenu,
            project: project,
            manager: manager,
            sequences: sequences,
            sequence: sequence,
            
            projectPath:projectPath,
            currentFileBrowserPath:currentFileBrowserPath,
            focusedWindow:focusedWindow,
            appTimeline:appTimeline,
            setIsDirty:setIsDirty,
            setProjectPath:setProjectPath,

        }
    }
    const handleDropdown = (index) => {
        // console.log('index', index);
        setActiveIndex(index);
    };
    useEffect(() => {
        // console.log('activeIndex', activeIndex);
    }, [activeIndex]);

    const onPreProcess = ({ preprofunc = "", onFinished = (e) => {} }) => {
        if (preprofunc !== "") {
            eval(preprofunc);
            let res = new Function(preprofunc)();
            onFinished(res);
        } else {
            onFinished("");
        }
    };

    const onPostProcess = ({ data, onFinished = (e) => {} }) => {
        console.log("onPostProcess", data);

        onFinished(data);
    };

    const onModuleRun = ({ data }) => {
        console.log("onModuleRun", data);

        return data;
    };

    const onResult = ({ data, onFinished = (e) => {} }) => {
        console.log("onResult", data);

        onFinished(data);
    };
    console.log(data);
    return (
        <Menu
            placement="bottom-start"
            open={openMenu}
            handler={setOpenMenu}
            offset={15}
            key={data.id}
        >
            <MenuHandler className="p-1 pr-4 bg-transparent">
                <Button
                    className={` z-10 bg-transparent text-white hover:text-white hover:bg-gray-800  ${
                        !root ? "blueborder-bottomm " : ""
                    }  rounded-none `}
                >
                    <div className={`!flex !flex-row gap-2 items-center  text-sm font-normal  menu_${data.id}`} 
                    id={"menu_"+data.id}>
                        {!root && (
                            <ChevronUpIcon
                                strokeWidth={2.5}
                                className={`h-3.5 w-3.5 transition-transform ${
                                    openMenu ? "rotate-180" : "rotate-90"
                                }`}
                            />
                        )}
                        <AnimatedText value={data.name ? data.name : data.sub ? data.sub : "NA"} duration={0.01} speed={80} />
                        
                    </div>
                </Button>
            </MenuHandler>
            {data.namesub && data.namesub.length > 0 ? (
                <MenuList className="flex flex-col gap-3 !bg-[#0a0b10] appBlueBorder ">
                    {data.namesub &&
                        data.namesub.filter((e) => {
                            try {
                                if (e.hidden) {
                                    return false;
                                } else {
                                    return true;
                                }
                            } catch (e) {
                                return true;
                            }
                        }).length > 0 &&
                        data.namesub.map((submenu, idx) => {
                            if (submenu.namesub && submenu.namesub.length > 0) {
                                return (
                                    <>
                                        <NavMenuItem
                                            manager={manager}
                                            data={submenu}
                                        />

                                        {data.namesub && (
                                            //    <ChevronUpIcon
                                            //    strokeWidth={2.5}
                                            //    className={`h-3.5 w-3.5 transition-transform ${
                                            //      openMenu ? "rotate-90" : ""
                                            //    }`}
                                            //  />
                                            <></>
                                        )}
                                    </>
                                );
                            } else {
                                if (submenu.hidden == false) {
                                    return (
                                        <MenuItem
                                            className="z-10 text-sm font-normal bg-transparent text-white flex flex-row gap-2 items-center hover:text-white hover:bg-gray-800 hover:border hover:border-red-500 blueborder-bottomm rounded-none p-1 pr-4 "
                                            onClick={() => {
                                                submenu.documentation
                                                    ? localStorage.setItem(
                                                        "documentation",  
                                                        submenu.documentation
                                                    )
                                                    : handleDropdown(
                                                        submenu.id
                                                    );

                                                let baseFunction =
                                                    submenu.function;
                                                if (
                                                    typeof submenu.function ===
                                                    "string"
                                                ) {
                                                    console.log(
                                                        "Submenu",
                                                        submenu
                                                    );
                                                    signal(
                                                        "operation.openWindow",
                                                        emitProps(submenu)
                                                    );
                                                    return;
                                                } else {
                                                }
                                                console.log(
                                                    "baseFunction",
                                                    baseFunction
                                                );

                                                let res = submenu.function();
                                                console.log("Res", res);
                                            }}
                                        >
                                            <StopIcon
                                                strokeWidth={2.5}
                                                className={`h-3.5 w-3.5 transition-transform ${
                                                    openMenu
                                                        ? "rotate-180"
                                                        : "rotate-90"
                                                }`}
                                            />
                        <AnimatedText value={submenu.sub} duration={0.001}  speed={80}/>

                                            
                                        </MenuItem>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        })}
                </MenuList>
            ) : null}
        </Menu>
    );
};

export default NavMenuItem;
