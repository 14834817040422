
    const uaimenu = [
    {
        "id": "File",
        "sub": "File",
        "type": "route",
        "metadata": {},
        "function": "\n                    () => {}\n                    ",
        "parent": "",
        "namesub": [
            {
                "id": "File.Project",
                "sub": "Project",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "File",
                "namesub": [
                    {
                        "id": "File.Project.New",
                        "sub": "Create: Project",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.ui.project.create",
                            "title": "Create: Project",
                            "path": "File/Project/New",
                            "category": "Project",
                            "icon": "",
                            "hidden": false,
                            "tags": [
                                "Project",
                                "UI"
                            ],
                            "description": "Create the current Project.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Project"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "name",
                                            "label": "Name",
                                            "type": "string",
                                            "lines": "single",
                                            "placeholder": "",
                                            "value": "Untitled Project",
                                            "required": true,
                                            "help": "Name of the project",
                                            "uuid": "a0cf2c78-4",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "projectType",
                                            "label": "Project Type",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": "",
                                            "options": [
                                                "Mixer",
                                                "Dataset",
                                                "XR Scene"
                                            ],
                                            "value": "Mixer",
                                            "required": false,
                                            "help": "Project type to be created.",
                                            "uuid": "22da99b0-f",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "thumbnail",
                                            "label": "Thumbnail",
                                            "type": "file.image",
                                            "lines": "multi",
                                            "value": "",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "Thumbnail for the project",
                                            "uuid": "50accfae-1",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "56707619-e",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": true,
                                    "name": "settings"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Cancel",
                                    "module": "internal.ui.projectView",
                                    "moduleType": "internal.ui.projectView",
                                    "operation": "cancel",
                                    "args": "",
                                    "executable": "internal.ui.projectView",
                                    "url": "/",
                                    "type": "submit",
                                    "help": "Cancel the current operation.",
                                    "requestType": "fx.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-ban",
                                    "uuid": "6cad93fa-e"
                                },
                                {
                                    "name": "Create",
                                    "module": "internal.ui.project",
                                    "moduleType": "internal.ui.project",
                                    "operation": "createProject",
                                    "args": "",
                                    "executable": "internal.ui.project",
                                    "url": "/image/stableDiffusion_",
                                    "type": "submit",
                                    "help": "Create the project with the settings.",
                                    "requestType": "ui.project.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-paint-brush",
                                    "uuid": "4a583763-1"
                                }
                            ],
                            "uuid": "c1781c23-1",
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": true,
                            "toggleable": true,
                            "isFX": false,
                            "window": "Modal"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"File/Project/New\");\n                    }\n                    ",
                        "parent": "File.Project",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "name",
                                        "label": "Name",
                                        "type": "string",
                                        "lines": "single",
                                        "placeholder": "",
                                        "value": "Untitled Project",
                                        "required": true,
                                        "help": "Name of the project",
                                        "uuid": "a0cf2c78-4",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "projectType",
                                        "label": "Project Type",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": "",
                                        "options": [
                                            "Mixer",
                                            "Dataset",
                                            "XR Scene"
                                        ],
                                        "value": "Mixer",
                                        "required": false,
                                        "help": "Project type to be created.",
                                        "uuid": "22da99b0-f",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "thumbnail",
                                        "label": "Thumbnail",
                                        "type": "file.image",
                                        "lines": "multi",
                                        "value": "",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "Thumbnail for the project",
                                        "uuid": "50accfae-1",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "56707619-e",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": true,
                                "name": "settings"
                            }
                        ],
                        "automatic": [],
                        "hidden": false
                    },
                    {
                        "id": "File.Project.Saveprojectas",
                        "sub": "Save: Project As",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.ui.project.save",
                            "title": "Save: Project As",
                            "path": "File/Project/Save Project As",
                            "category": "Project",
                            "icon": "",
                            "hidden": false,
                            "tags": [
                                "Project",
                                "UI"
                            ],
                            "description": "Save the current Project as...",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Project"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "path",
                                            "label": "Path",
                                            "type": "string",
                                            "lines": "single",
                                            "placeholder": "",
                                            "value": "Untitled Project.uaiproj",
                                            "required": true,
                                            "help": "Name of the project",
                                            "uuid": "5a425245-5",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "b8df20da-2",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "settings",
                                    "buttons": []
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Save",
                                    "module": "internal.ui.project",
                                    "moduleType": "internal.ui.project",
                                    "operation": "saveProjectAs",
                                    "args": "",
                                    "executable": "internal.ui.project",
                                    "url": "/image/stableDiffusion_",
                                    "type": "auto",
                                    "help": "Save the project with the new settings.",
                                    "requestType": "ui.project.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-paint-brush",
                                    "uuid": "479c8df6-2"
                                }
                            ],
                            "uuid": "df673fab-1",
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": true,
                            "toggleable": true,
                            "isFX": false,
                            "window": "Modal"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"File/Project/Save Project As\");\n                    }\n                    ",
                        "parent": "File.Project",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "path",
                                        "label": "Path",
                                        "type": "string",
                                        "lines": "single",
                                        "placeholder": "",
                                        "value": "Untitled Project.uaiproj",
                                        "required": true,
                                        "help": "Name of the project",
                                        "uuid": "5a425245-5",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "b8df20da-2",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "settings",
                                "buttons": []
                            }
                        ],
                        "automatic": [],
                        "hidden": false
                    },
                    {
                        "id": "File.Project.Open",
                        "sub": "Open: Project",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.ui.project.open",
                            "title": "Open: Project",
                            "path": "File/Project/Open",
                            "category": "Project",
                            "icon": "",
                            "hidden": false,
                            "tags": [
                                "Project",
                                "UI"
                            ],
                            "description": "Open a Project.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Project"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Settings",
                                    "size": "Small",
                                    "fields": [
                                        {
                                            "name": "path",
                                            "label": "Path",
                                            "type": "file",
                                            "lines": "single",
                                            "placeholder": "",
                                            "value": "Untitled Project",
                                            "required": true,
                                            "help": "Path of the project",
                                            "uuid": "ac94d720-e",
                                            "options": [],
                                            "keyframeable": false,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "583594d2-d",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": true,
                                    "name": "settings"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Cancel",
                                    "module": "internal.ui.projectView",
                                    "moduleType": "internal.ui.projectView",
                                    "operation": "cancel",
                                    "args": "",
                                    "executable": "internal.ui.projectView",
                                    "url": "/",
                                    "type": "submit",
                                    "help": "Cancel the current operation.",
                                    "requestType": "fx.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-ban",
                                    "uuid": "6cad93fa-e"
                                },
                                {
                                    "name": "Open",
                                    "module": "internal.ui.project",
                                    "moduleType": "internal.ui.project",
                                    "operation": "openProject",
                                    "args": "",
                                    "executable": "internal.ui.project",
                                    "url": "/image/stableDiffusion_",
                                    "type": "submit",
                                    "help": "Open the project with the settings.",
                                    "requestType": "ui.project.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-paint-brush",
                                    "uuid": "4a583763-1"
                                }
                            ],
                            "uuid": "c1781c23-1",
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": true,
                            "toggleable": true,
                            "isFX": false,
                            "window": "Modal"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"File/Project/Open\");\n                    }\n                    ",
                        "parent": "File.Project",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Settings",
                                "size": "Small",
                                "fields": [
                                    {
                                        "name": "path",
                                        "label": "Path",
                                        "type": "file",
                                        "lines": "single",
                                        "placeholder": "",
                                        "value": "Untitled Project",
                                        "required": true,
                                        "help": "Path of the project",
                                        "uuid": "ac94d720-e",
                                        "options": [],
                                        "keyframeable": false,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "583594d2-d",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": true,
                                "name": "settings"
                            }
                        ],
                        "automatic": [],
                        "hidden": false
                    },
                    {
                        "id": "File.Project.Saveproject",
                        "sub": "Save: Project",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.headless.project.save",
                            "title": "Save: Project",
                            "path": "File/Project/Save Project",
                            "category": "Project",
                            "icon": "",
                            "hidden": false,
                            "tags": [
                                "Project",
                                "UI"
                            ],
                            "description": "Edit the current Project.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Project"
                            ],
                            "panel": 2,
                            "subwindows": [],
                            "functions": [
                                {
                                    "name": "Save",
                                    "module": "internal.headless.project",
                                    "moduleType": "internal.headless.project",
                                    "operation": "saveProjectHeadless",
                                    "args": "",
                                    "executable": "internal.headless.project",
                                    "url": "/image/stableDiffusion_",
                                    "type": "auto",
                                    "help": "Save the project with the new settings.",
                                    "requestType": "ui.project.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-paint-brush",
                                    "uuid": "67ab72db-2"
                                }
                            ],
                            "uuid": "21faf981-d",
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": true,
                            "toggleable": true,
                            "isFX": false,
                            "window": "Headless"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"File/Project/Save Project\");\n                    }\n                    ",
                        "parent": "File.Project",
                        "namesub": [],
                        "subwindows": [],
                        "automatic": [],
                        "hidden": false
                    },
                    {
                        "id": "File.Project.Deleteproject",
                        "sub": "Delete: Project",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.headless.project.delete",
                            "title": "Delete: Project",
                            "path": "File/Project/Delete Project",
                            "category": "Project",
                            "icon": "",
                            "hidden": false,
                            "tags": [
                                "Project",
                                "UI"
                            ],
                            "description": "Edit the current Project.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Project"
                            ],
                            "panel": 2,
                            "subwindows": [],
                            "functions": [
                                {
                                    "name": "Delete",
                                    "module": "internal.headless.project",
                                    "moduleType": "internal.headless.project",
                                    "operation": "deleteProjectHeadless",
                                    "args": "",
                                    "executable": "internal.headless.project",
                                    "url": "/image/stableDiffusion_",
                                    "type": "auto",
                                    "help": "Delete the project",
                                    "requestType": "ui.project.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-paint-brush",
                                    "uuid": "7b3d02e1-2"
                                }
                            ],
                            "uuid": "979bfdeb-d",
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": true,
                            "toggleable": true,
                            "isFX": false,
                            "window": "Headless"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"File/Project/Delete Project\");\n                    }\n                    ",
                        "parent": "File.Project",
                        "namesub": [],
                        "subwindows": [],
                        "automatic": [],
                        "hidden": false
                    }
                ]
            },
            {
                "id": "File.Sequence",
                "sub": "Sequence",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "File",
                "namesub": [
                    {
                        "id": "File.Sequence.Newsequence",
                        "sub": "Create: Sequence",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.ui.project.sequence.create",
                            "title": "Create: Sequence",
                            "path": "File/Sequence/New Sequence",
                            "category": "Sequence",
                            "icon": "",
                            "hidden": false,
                            "tags": [
                                "Sequence",
                                "UI"
                            ],
                            "description": "create the current Sequence.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Sequence"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "name",
                                            "label": "Name",
                                            "type": "string",
                                            "lines": "single",
                                            "placeholder": "",
                                            "value": "",
                                            "required": true,
                                            "help": "Name of the sequence",
                                            "uuid": "67e5a69e-c",
                                            "options": [],
                                            "keyframeable": false,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "description",
                                            "label": "Description",
                                            "type": "string",
                                            "lines": "multi",
                                            "value": "",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "Description for the sequence",
                                            "uuid": "9b513f2c-d",
                                            "options": [],
                                            "keyframeable": false,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "comments",
                                            "label": "Comments",
                                            "type": "string",
                                            "lines": "multi",
                                            "value": "",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "Comments for the sequence",
                                            "uuid": "4a2f9d3d-3",
                                            "options": [],
                                            "keyframeable": false,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "1331d03d-2",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": true,
                                    "name": "settings"
                                },
                                {
                                    "title": "Render",
                                    "size": "Medium",
                                    "fields": [
                                        {
                                            "name": "resolution",
                                            "label": "Resolution",
                                            "type": "vector2",
                                            "lines": "single",
                                            "placeholder": "[1920,1080]",
                                            "value": [
                                                1920,
                                                1080
                                            ],
                                            "required": true,
                                            "help": "Resolution",
                                            "uuid": "f4c44444-0",
                                            "options": [],
                                            "keyframeable": false,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "fps",
                                            "label": "Framerate",
                                            "type": "float",
                                            "lines": "single",
                                            "value": 23.976,
                                            "placeholder": "23.976",
                                            "min": 0,
                                            "max": 10000000,
                                            "required": true,
                                            "help": "Framerate of the video.",
                                            "uuid": "7a4bd2ca-d",
                                            "options": [],
                                            "keyframeable": false,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "audiobitrate",
                                            "label": "Audio Bitrate",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": "",
                                            "options": [
                                                "192k",
                                                "256k",
                                                "320k"
                                            ],
                                            "value": "256k",
                                            "required": false,
                                            "help": "Audio Bitrate.",
                                            "uuid": "ba896622-6",
                                            "keyframeable": false,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "stereo",
                                            "label": "Stereo",
                                            "type": "bool",
                                            "lines": "single",
                                            "placeholder": "true",
                                            "value": true,
                                            "required": true,
                                            "help": "Wether it is stereo or not.",
                                            "uuid": "e3132b00-1",
                                            "options": [],
                                            "keyframeable": false,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "d0386991-b",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": true,
                                    "buttons": [],
                                    "name": "render"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Cancel",
                                    "module": "internal.ui.projectView",
                                    "moduleType": "internal.ui.projectView",
                                    "operation": "cancel",
                                    "args": "",
                                    "executable": "internal.ui.projectView",
                                    "url": "/",
                                    "type": "submit",
                                    "help": "Cancel the current operation.",
                                    "requestType": "fx.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-ban",
                                    "uuid": "6cad93fa-e"
                                },
                                {
                                    "name": "Create",
                                    "module": "internal.ui.sequence",
                                    "moduleType": "internal.ui.sequence",
                                    "operation": "createSequence",
                                    "args": "",
                                    "executable": "internal.ui.sequence",
                                    "url": "/image/stableDiffusion_",
                                    "type": "submit",
                                    "help": "Update the sequence with the new settings.",
                                    "requestType": "ui.sequence.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-paint-brush",
                                    "uuid": "b5cce8e4-7"
                                }
                            ],
                            "uuid": "de7984bd-c",
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": true,
                            "toggleable": false,
                            "isFX": false,
                            "window": "Modal"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"File/Sequence/New Sequence\");\n                    }\n                    ",
                        "parent": "File.Sequence",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "name",
                                        "label": "Name",
                                        "type": "string",
                                        "lines": "single",
                                        "placeholder": "",
                                        "value": "",
                                        "required": true,
                                        "help": "Name of the sequence",
                                        "uuid": "67e5a69e-c",
                                        "options": [],
                                        "keyframeable": false,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "description",
                                        "label": "Description",
                                        "type": "string",
                                        "lines": "multi",
                                        "value": "",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "Description for the sequence",
                                        "uuid": "9b513f2c-d",
                                        "options": [],
                                        "keyframeable": false,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "comments",
                                        "label": "Comments",
                                        "type": "string",
                                        "lines": "multi",
                                        "value": "",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "Comments for the sequence",
                                        "uuid": "4a2f9d3d-3",
                                        "options": [],
                                        "keyframeable": false,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "1331d03d-2",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": true,
                                "name": "settings"
                            },
                            {
                                "title": "Render",
                                "size": "Medium",
                                "fields": [
                                    {
                                        "name": "resolution",
                                        "label": "Resolution",
                                        "type": "vector2",
                                        "lines": "single",
                                        "placeholder": "[1920,1080]",
                                        "value": [
                                            1920,
                                            1080
                                        ],
                                        "required": true,
                                        "help": "Resolution",
                                        "uuid": "f4c44444-0",
                                        "options": [],
                                        "keyframeable": false,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "fps",
                                        "label": "Framerate",
                                        "type": "float",
                                        "lines": "single",
                                        "value": 23.976,
                                        "placeholder": "23.976",
                                        "min": 0,
                                        "max": 10000000,
                                        "required": true,
                                        "help": "Framerate of the video.",
                                        "uuid": "7a4bd2ca-d",
                                        "options": [],
                                        "keyframeable": false,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "audiobitrate",
                                        "label": "Audio Bitrate",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": "",
                                        "options": [
                                            "192k",
                                            "256k",
                                            "320k"
                                        ],
                                        "value": "256k",
                                        "required": false,
                                        "help": "Audio Bitrate.",
                                        "uuid": "ba896622-6",
                                        "keyframeable": false,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "stereo",
                                        "label": "Stereo",
                                        "type": "bool",
                                        "lines": "single",
                                        "placeholder": "true",
                                        "value": true,
                                        "required": true,
                                        "help": "Wether it is stereo or not.",
                                        "uuid": "e3132b00-1",
                                        "options": [],
                                        "keyframeable": false,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "d0386991-b",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": true,
                                "buttons": [],
                                "name": "render"
                            }
                        ],
                        "automatic": [],
                        "hidden": false
                    }
                ]
            },
            {
                "id": "File.Quit",
                "sub": "Quit",
                "type": "function",
                "metadata": {
                    "name": "uai.builtin.headless.app.quit",
                    "title": "Quit",
                    "path": "File/Quit",
                    "category": "Project",
                    "icon": "",
                    "hidden": false,
                    "tags": [
                        "Project",
                        "UI"
                    ],
                    "description": "Quit the app.",
                    "inputs": [
                        "Text",
                        "None"
                    ],
                    "outputs": [
                        "Project"
                    ],
                    "panel": 2,
                    "subwindows": [],
                    "functions": [
                        {
                            "name": "Quit",
                            "module": "internal.headless.app",
                            "moduleType": "internal.headless.app",
                            "operation": "quit",
                            "args": "",
                            "executable": "internal.headless.app",
                            "url": "/image/stableDiffusion_",
                            "type": "auto",
                            "help": "Quit the app.",
                            "requestType": "ui.project.properties",
                            "responseType": "",
                            "preFunction": "",
                            "postFunction": "",
                            "icon": "fa-paint-brush",
                            "uuid": "f933d09d-f2"
                        }
                    ],
                    "uuid": "69a8a3be-c",
                    "automatic": [],
                    "documentation": "https://uai.software/docs",
                    "initialState": true,
                    "toggleable": true,
                    "isFX": false,
                    "window": "Headless"
                },
                "function": "\n                    () => {\n                        onModuleRun(\"File/Quit\");\n                    }\n                    ",
                "parent": "File",
                "namesub": [],
                "subwindows": [],
                "automatic": [],
                "hidden": false
            }
        ]
    },
    {
        "id": "Edit",
        "sub": "Edit",
        "type": "route",
        "metadata": {},
        "function": "\n                    () => {}\n                    ",
        "parent": "",
        "namesub": [
            {
                "id": "Edit.Projectsettings",
                "sub": "Edit: Project Settings",
                "type": "function",
                "metadata": {
                    "name": "uai.builtin.ui.project.edit",
                    "title": "Edit: Project Settings",
                    "path": "Edit/Project Settings",
                    "category": "Project",
                    "icon": "",
                    "hidden": false,
                    "tags": [
                        "Project",
                        "UI"
                    ],
                    "description": "Edit the current Project.",
                    "inputs": [
                        "Text",
                        "None"
                    ],
                    "outputs": [
                        "Project"
                    ],
                    "panel": 2,
                    "subwindows": [
                        {
                            "title": "Settings",
                            "size": "Large",
                            "fields": [
                                {
                                    "name": "name",
                                    "label": "Name",
                                    "type": "string",
                                    "lines": "single",
                                    "placeholder": "",
                                    "value": "Untitled Project",
                                    "required": true,
                                    "help": "Name of the project",
                                    "uuid": "28f71d6e-7",
                                    "options": [],
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                },
                                {
                                    "name": "projectType",
                                    "label": "Project Type",
                                    "type": "select",
                                    "lines": "single",
                                    "placeholder": "",
                                    "options": [
                                        "Mixer",
                                        "Dataset",
                                        "XR Scene"
                                    ],
                                    "value": "Mixer",
                                    "required": false,
                                    "help": "Project type to be created.",
                                    "uuid": "43704972-b",
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                },
                                {
                                    "name": "thumbnail",
                                    "label": "Thumbnail",
                                    "type": "file.image",
                                    "lines": "multi",
                                    "value": "",
                                    "placeholder": "",
                                    "required": true,
                                    "help": "Thumbnail for the project",
                                    "uuid": "eb73b630-b",
                                    "options": [],
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                }
                            ],
                            "buttons": [],
                            "uuid": "d0c57d8b-5",
                            "enabled": true,
                            "toggleable": true,
                            "initialState": true,
                            "name": "settings"
                        }
                    ],
                    "functions": [
                        {
                            "name": "Cancel",
                            "module": "internal.ui.projectView",
                            "moduleType": "internal.ui.projectView",
                            "operation": "cancel",
                            "args": "",
                            "executable": "internal.ui.projectView",
                            "url": "/",
                            "type": "submit",
                            "help": "Cancel the current operation.",
                            "requestType": "fx.properties",
                            "responseType": "",
                            "preFunction": "",
                            "postFunction": "",
                            "icon": "fa-ban",
                            "uuid": "6cad93fa-e"
                        },
                        {
                            "name": "Update",
                            "module": "internal.ui.project",
                            "moduleType": "internal.ui.project",
                            "operation": "updateProject",
                            "args": "",
                            "executable": "internal.ui.project",
                            "url": "/image/stableDiffusion_",
                            "type": "submit",
                            "help": "Update the project with the new settings.",
                            "requestType": "ui.project.properties",
                            "responseType": "",
                            "preFunction": "",
                            "postFunction": "",
                            "icon": "fa-paint-brush",
                            "uuid": "5790cea1-3"
                        }
                    ],
                    "uuid": "cf138763-8",
                    "automatic": [],
                    "documentation": "https://uai.software/docs",
                    "initialState": true,
                    "toggleable": true,
                    "isFX": false,
                    "window": "Modal"
                },
                "function": "\n                    () => {\n                        onModuleRun(\"Edit/Project Settings\");\n                    }\n                    ",
                "parent": "Edit",
                "namesub": [],
                "subwindows": [
                    {
                        "title": "Settings",
                        "size": "Large",
                        "fields": [
                            {
                                "name": "name",
                                "label": "Name",
                                "type": "string",
                                "lines": "single",
                                "placeholder": "",
                                "value": "Untitled Project",
                                "required": true,
                                "help": "Name of the project",
                                "uuid": "28f71d6e-7",
                                "options": [],
                                "keyframeable": true,
                                "keyframeType": "all"
                            },
                            {
                                "name": "projectType",
                                "label": "Project Type",
                                "type": "select",
                                "lines": "single",
                                "placeholder": "",
                                "options": [
                                    "Mixer",
                                    "Dataset",
                                    "XR Scene"
                                ],
                                "value": "Mixer",
                                "required": false,
                                "help": "Project type to be created.",
                                "uuid": "43704972-b",
                                "keyframeable": true,
                                "keyframeType": "all"
                            },
                            {
                                "name": "thumbnail",
                                "label": "Thumbnail",
                                "type": "file.image",
                                "lines": "multi",
                                "value": "",
                                "placeholder": "",
                                "required": true,
                                "help": "Thumbnail for the project",
                                "uuid": "eb73b630-b",
                                "options": [],
                                "keyframeable": true,
                                "keyframeType": "all"
                            }
                        ],
                        "buttons": [],
                        "uuid": "d0c57d8b-5",
                        "enabled": true,
                        "toggleable": true,
                        "initialState": true,
                        "name": "settings"
                    }
                ],
                "automatic": [],
                "hidden": false
            },
            {
                "id": "Edit.Sequencesettings",
                "sub": "Edit: Sequence Settings",
                "type": "function",
                "metadata": {
                    "name": "uai.builtin.ui.project.sequence.edit",
                    "title": "Edit: Sequence Settings",
                    "path": "Edit/Sequence Settings",
                    "category": "Sequence",
                    "icon": "",
                    "hidden": false,
                    "tags": [
                        "Sequence",
                        "UI"
                    ],
                    "description": "Edit the current Sequence.",
                    "inputs": [
                        "Text",
                        "None"
                    ],
                    "outputs": [
                        "Sequence"
                    ],
                    "panel": 2,
                    "subwindows": [
                        {
                            "title": "Settings",
                            "size": "Large",
                            "fields": [
                                {
                                    "name": "name",
                                    "label": "Name",
                                    "type": "string",
                                    "lines": "single",
                                    "placeholder": "",
                                    "value": "",
                                    "required": true,
                                    "help": "Name of the sequence",
                                    "uuid": "fc710970-8",
                                    "options": [],
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                },
                                {
                                    "name": "description",
                                    "label": "Description",
                                    "type": "string",
                                    "lines": "multi",
                                    "value": "",
                                    "placeholder": "",
                                    "required": true,
                                    "help": "Description for the sequence",
                                    "uuid": "01ffc8e8-8",
                                    "options": [],
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                },
                                {
                                    "name": "comments",
                                    "label": "Comments",
                                    "type": "string",
                                    "lines": "multi",
                                    "value": "",
                                    "placeholder": "",
                                    "required": true,
                                    "help": "Comments for the sequence",
                                    "uuid": "2fb3fd99-0",
                                    "options": [],
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                }
                            ],
                            "buttons": [],
                            "uuid": "54d6c37a-7",
                            "enabled": true,
                            "toggleable": true,
                            "initialState": true,
                            "name": "settings"
                        },
                        {
                            "title": "Render",
                            "size": "Medium",
                            "fields": [
                                {
                                    "name": "resolution",
                                    "label": "Resolution",
                                    "type": "vector2",
                                    "lines": "single",
                                    "placeholder": "[1920,1080]",
                                    "value": [
                                        1920,
                                        1080
                                    ],
                                    "required": true,
                                    "help": "Resolution",
                                    "uuid": "0fb42844-6",
                                    "options": [],
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                },
                                {
                                    "name": "fps",
                                    "label": "Framerate",
                                    "type": "float",
                                    "lines": "single",
                                    "value": 23.976,
                                    "placeholder": "23.976",
                                    "min": 0,
                                    "max": 10000000,
                                    "required": true,
                                    "help": "Framerate of the video.",
                                    "uuid": "9687c7d4-0",
                                    "options": [],
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                },
                                {
                                    "name": "audiobitrate",
                                    "label": "Audio Bitrate",
                                    "type": "select",
                                    "lines": "single",
                                    "placeholder": "",
                                    "options": [
                                        "192k",
                                        "256k",
                                        "320k"
                                    ],
                                    "value": "256k",
                                    "required": false,
                                    "help": "Audio Bitrate.",
                                    "uuid": "666fa0d4-c",
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                },
                                {
                                    "name": "stereo",
                                    "label": "Stereo",
                                    "type": "bool",
                                    "lines": "single",
                                    "placeholder": "true",
                                    "value": true,
                                    "required": true,
                                    "help": "Wether it is stereo or not.",
                                    "uuid": "540ed0d2-f",
                                    "options": [],
                                    "keyframeable": true,
                                    "keyframeType": "all"
                                }
                            ],
                            "uuid": "aa876c25-9",
                            "enabled": true,
                            "toggleable": true,
                            "initialState": true,
                            "buttons": [],
                            "name": "render"
                        }
                    ],
                    "functions": [
                        {
                            "name": "Cancel",
                            "module": "internal.ui.projectView",
                            "moduleType": "internal.ui.projectView",
                            "operation": "cancel",
                            "args": "",
                            "executable": "internal.ui.projectView",
                            "url": "/",
                            "type": "submit",
                            "help": "Cancel the current operation.",
                            "requestType": "fx.properties",
                            "responseType": "",
                            "preFunction": "",
                            "postFunction": "",
                            "icon": "fa-ban",
                            "uuid": "6cad93fa-e"
                        },
                        {
                            "name": "Update",
                            "module": "internal.ui.sequence",
                            "moduleType": "internal.ui.sequence",
                            "operation": "updateSequence",
                            "args": "",
                            "executable": "internal.ui.sequence",
                            "url": "/image/stableDiffusion_",
                            "type": "submit",
                            "help": "Update the sequence with the new settings.",
                            "requestType": "ui.sequence.properties",
                            "responseType": "",
                            "preFunction": "",
                            "postFunction": "",
                            "icon": "fa-paint-brush",
                            "uuid": "0e59a913-9"
                        }
                    ],
                    "uuid": "167c3f6c-e",
                    "automatic": [],
                    "documentation": "https://uai.software/docs",
                    "initialState": true,
                    "toggleable": true,
                    "isFX": false,
                    "window": "Modal"
                },
                "function": "\n                    () => {\n                        onModuleRun(\"Edit/Sequence Settings\");\n                    }\n                    ",
                "parent": "Edit",
                "namesub": [],
                "subwindows": [
                    {
                        "title": "Settings",
                        "size": "Large",
                        "fields": [
                            {
                                "name": "name",
                                "label": "Name",
                                "type": "string",
                                "lines": "single",
                                "placeholder": "",
                                "value": "",
                                "required": true,
                                "help": "Name of the sequence",
                                "uuid": "fc710970-8",
                                "options": [],
                                "keyframeable": true,
                                "keyframeType": "all"
                            },
                            {
                                "name": "description",
                                "label": "Description",
                                "type": "string",
                                "lines": "multi",
                                "value": "",
                                "placeholder": "",
                                "required": true,
                                "help": "Description for the sequence",
                                "uuid": "01ffc8e8-8",
                                "options": [],
                                "keyframeable": true,
                                "keyframeType": "all"
                            },
                            {
                                "name": "comments",
                                "label": "Comments",
                                "type": "string",
                                "lines": "multi",
                                "value": "",
                                "placeholder": "",
                                "required": true,
                                "help": "Comments for the sequence",
                                "uuid": "2fb3fd99-0",
                                "options": [],
                                "keyframeable": true,
                                "keyframeType": "all"
                            }
                        ],
                        "buttons": [],
                        "uuid": "54d6c37a-7",
                        "enabled": true,
                        "toggleable": true,
                        "initialState": true,
                        "name": "settings"
                    },
                    {
                        "title": "Render",
                        "size": "Medium",
                        "fields": [
                            {
                                "name": "resolution",
                                "label": "Resolution",
                                "type": "vector2",
                                "lines": "single",
                                "placeholder": "[1920,1080]",
                                "value": [
                                    1920,
                                    1080
                                ],
                                "required": true,
                                "help": "Resolution",
                                "uuid": "0fb42844-6",
                                "options": [],
                                "keyframeable": true,
                                "keyframeType": "all"
                            },
                            {
                                "name": "fps",
                                "label": "Framerate",
                                "type": "float",
                                "lines": "single",
                                "value": 23.976,
                                "placeholder": "23.976",
                                "min": 0,
                                "max": 10000000,
                                "required": true,
                                "help": "Framerate of the video.",
                                "uuid": "9687c7d4-0",
                                "options": [],
                                "keyframeable": true,
                                "keyframeType": "all"
                            },
                            {
                                "name": "audiobitrate",
                                "label": "Audio Bitrate",
                                "type": "select",
                                "lines": "single",
                                "placeholder": "",
                                "options": [
                                    "192k",
                                    "256k",
                                    "320k"
                                ],
                                "value": "256k",
                                "required": false,
                                "help": "Audio Bitrate.",
                                "uuid": "666fa0d4-c",
                                "keyframeable": true,
                                "keyframeType": "all"
                            },
                            {
                                "name": "stereo",
                                "label": "Stereo",
                                "type": "bool",
                                "lines": "single",
                                "placeholder": "true",
                                "value": true,
                                "required": true,
                                "help": "Wether it is stereo or not.",
                                "uuid": "540ed0d2-f",
                                "options": [],
                                "keyframeable": true,
                                "keyframeType": "all"
                            }
                        ],
                        "uuid": "aa876c25-9",
                        "enabled": true,
                        "toggleable": true,
                        "initialState": true,
                        "buttons": [],
                        "name": "render"
                    }
                ],
                "automatic": [],
                "hidden": false
            }
        ]
    },
    {
        "id": "Ai",
        "sub": "Ai",
        "type": "route",
        "metadata": {},
        "function": "\n                    () => {}\n                    ",
        "parent": "",
        "namesub": [
            {
                "id": "Ai.Audio",
                "sub": "Audio",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "Ai",
                "namesub": [
                    {
                        "id": "Ai.Audio.Splitstems",
                        "sub": "Split Stems",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.audio.split",
                            "title": "Split Stems",
                            "path": "AI/Audio/Split Stems",
                            "category": "Audio",
                            "icon": "",
                            "hidden": false,
                            "tags": [
                                "Audio",
                                "Base",
                                "Mix",
                                "UAI"
                            ],
                            "automatic": [],
                            "description": "Split the audio into stems.",
                            "inputs": [
                                "AudioSource",
                                "None"
                            ],
                            "outputs": [
                                "MixedAudio"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Basic Settings",
                                    "size": "Small",
                                    "fields": [
                                        {
                                            "name": "input",
                                            "label": "Input Wav",
                                            "type": "file",
                                            "lines": "single",
                                            "objectType": "Audio",
                                            "base64": true,
                                            "placeholder": "",
                                            "value": "",
                                            "required": true,
                                            "help": "The Audio to process.",
                                            "uuid": "a47d0e6e-2",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "model",
                                            "label": "AI Model",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": " ",
                                            "options": [
                                                "spleeter:4stems",
                                                "spleeter:5stems"
                                            ],
                                            "value": "spleeter:4stems",
                                            "required": true,
                                            "help": "The Model to use for the audio split. 4 Stems or 5 Stems.",
                                            "uuid": "6b70d0e0-3",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "c29b3b8d-5",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "basicsettings"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Run",
                                    "module": "internal.audio",
                                    "moduleType": "internal.audio",
                                    "operation": "splitAudio",
                                    "args": "",
                                    "executable": "internal.audio",
                                    "url": "",
                                    "type": "submit",
                                    "help": "Split the audio into stems.",
                                    "requestType": "fx.properties",
                                    "responseType": "MultipleMediaRequest",
                                    "preFunction": "com.uai.createImageRequest",
                                    "postFunction": "",
                                    "icon": "fa-rocket",
                                    "uuid": "2c4df1d8-5"
                                }
                            ],
                            "uuid": "27810149-cd",
                            "documentation": "https://uai.software/docs/Modules/Augment/Audio%20To%20Audio/SplitStems",
                            "initialState": false,
                            "toggleable": true,
                            "isFX": false,
                            "window": "AIGenerate"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Audio/Split Stems\");\n                    }\n                    ",
                        "parent": "Ai.Audio",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Basic Settings",
                                "size": "Small",
                                "fields": [
                                    {
                                        "name": "input",
                                        "label": "Input Wav",
                                        "type": "file",
                                        "lines": "single",
                                        "objectType": "Audio",
                                        "base64": true,
                                        "placeholder": "",
                                        "value": "",
                                        "required": true,
                                        "help": "The Audio to process.",
                                        "uuid": "a47d0e6e-2",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "model",
                                        "label": "AI Model",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": " ",
                                        "options": [
                                            "spleeter:4stems",
                                            "spleeter:5stems"
                                        ],
                                        "value": "spleeter:4stems",
                                        "required": true,
                                        "help": "The Model to use for the audio split. 4 Stems or 5 Stems.",
                                        "uuid": "6b70d0e0-3",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "c29b3b8d-5",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "basicsettings"
                            }
                        ],
                        "automatic": [],
                        "hidden": false
                    },
                    {
                        "id": "Ai.Audio.Generate",
                        "sub": "Generate",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.Audio",
                        "namesub": [
                            {
                                "id": "Ai.Audio.Generate.Text2music",
                                "sub": "Text2music",
                                "type": "route",
                                "metadata": {},
                                "function": "\n                    () => {}\n                    ",
                                "parent": "Ai.Audio.Generate",
                                "namesub": [
                                    {
                                        "id": "Ai.Audio.Generate.Text2music.Advanced",
                                        "sub": "Music Gen: Text 2 Music Advanced ",
                                        "type": "function",
                                        "metadata": {
                                            "name": "uai.builtin.audio.musicgen.custom",
                                            "title": "Music Gen: Text 2 Music Advanced ",
                                            "path": "AI/Audio/Generate/Text 2 Music/Advanced",
                                            "category": "Audio",
                                            "icon": "",
                                            "tags": [
                                                "Audio",
                                                "Stable Diffusion",
                                                "XL Lightning",
                                                "Text2Image",
                                                "UAI"
                                            ],
                                            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
                                            "inputs": [
                                                "Text",
                                                "None"
                                            ],
                                            "outputs": [
                                                "Audio"
                                            ],
                                            "panel": 2,
                                            "subwindows": [
                                                {
                                                    "title": "Basic Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "prompt",
                                                            "label": "Lyrics",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "placeholder": "",
                                                            "value": "[Verse] Hello world\n[Chorus] It's me.",
                                                            "required": true,
                                                            "help": "The lyrics of the song to generate. Use [Verse], [Chorus], [Bridge] to separate the sections.",
                                                            "uuid": "52a92d30-b099-4966-b0f6-93d3daf6099f",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "tags",
                                                            "label": "Tags",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "value": "bad quality. low resolution",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "Tags that describe the song to generate. Use spaces to separate the tags and use hyphens for multi-word tags.",
                                                            "uuid": "de7e5db9-3bec-43f1-acc5-744773b68dfd",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "buttons": [],
                                                    "uuid": "042508e2-b7f7-4d45-97da-c0153ec939c7",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "basicsettings"
                                                }
                                            ],
                                            "functions": [
                                                {
                                                    "name": "Run",
                                                    "module": "internal.audio",
                                                    "moduleType": "internal.audio.generate.music",
                                                    "operation": "generateMusicAdvanced",
                                                    "args": "",
                                                    "executable": "internal.audio",
                                                    "url": "/api/v1/music/text/to/audio/music/custom",
                                                    "type": "submit",
                                                    "help": "Split the audio into stems.",
                                                    "requestType": "fx.properties",
                                                    "responseType": "MultipleMediaRequest",
                                                    "preFunction": "",
                                                    "postFunction": "",
                                                    "icon": "fa-rocket",
                                                    "uuid": "2f837169-28e0-4763-8184-747f3811a9ab"
                                                }
                                            ],
                                            "uuid": "0ab32296-782b-43f5-8391-5fd5a977d586",
                                            "hidden": false,
                                            "automatic": [],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": false,
                                            "window": "AIGenerate"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Audio/Generate/Text 2 Music/Advanced\");\n                    }\n                    ",
                                        "parent": "Ai.Audio.Generate.Text2music",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Basic Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "prompt",
                                                        "label": "Lyrics",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "placeholder": "",
                                                        "value": "[Verse] Hello world\n[Chorus] It's me.",
                                                        "required": true,
                                                        "help": "The lyrics of the song to generate. Use [Verse], [Chorus], [Bridge] to separate the sections.",
                                                        "uuid": "52a92d30-b099-4966-b0f6-93d3daf6099f",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "tags",
                                                        "label": "Tags",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "value": "bad quality. low resolution",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "Tags that describe the song to generate. Use spaces to separate the tags and use hyphens for multi-word tags.",
                                                        "uuid": "de7e5db9-3bec-43f1-acc5-744773b68dfd",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "buttons": [],
                                                "uuid": "042508e2-b7f7-4d45-97da-c0153ec939c7",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "basicsettings"
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": false
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": "Ai.Audio.Mix",
                        "sub": "Audio",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.audio.base",
                            "title": "Audio",
                            "path": "AI/Audio/Mix",
                            "category": "Audio",
                            "icon": "",
                            "hidden": true,
                            "tags": [
                                "Audio",
                                "Base",
                                "Mix",
                                "UAI"
                            ],
                            "automatic": [
                                "audio",
                                "video"
                            ],
                            "description": "This module provides basic audio mixing capabilities.",
                            "inputs": [
                                "AudioSource",
                                "None"
                            ],
                            "outputs": [
                                "MixedAudio"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Basic Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "Volume",
                                            "label": "Volume",
                                            "type": "float",
                                            "lines": "single",
                                            "placeholder": "0.0",
                                            "value": 0.0,
                                            "min": -1000.0,
                                            "max": 1000.0,
                                            "required": true,
                                            "help": "Set the volume level.",
                                            "uuid": "c841b940-a",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "Balance",
                                            "label": "Balance",
                                            "type": "int",
                                            "lines": "single",
                                            "placeholder": "0",
                                            "value": 0,
                                            "min": -1.0,
                                            "max": 1.0,
                                            "required": true,
                                            "help": "Set the audio LR balance.",
                                            "uuid": "c9209a68-3",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "Mute",
                                            "label": "Mute",
                                            "type": "bool",
                                            "lines": "single",
                                            "placeholder": "false",
                                            "value": false,
                                            "required": true,
                                            "help": "Mute the audio.",
                                            "min": -1000.0,
                                            "max": 1000.0,
                                            "uuid": "430458b1-e",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "c29b3b8d-5",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "basicsettings"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Run",
                                    "module": "internal.audio",
                                    "moduleType": "internal.audio",
                                    "operation": "setAudioBase",
                                    "args": "",
                                    "executable": "internal.audio",
                                    "url": "/image/stableDiffusion_",
                                    "type": "update",
                                    "help": "Process the audio mix.",
                                    "requestType": "fx.properties",
                                    "responseType": "MultipleMediaRequest",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-rocket",
                                    "uuid": "b1012191-d"
                                }
                            ],
                            "uuid": "b7c418df-3",
                            "documentation": "https://uai.software/docs",
                            "initialState": false,
                            "toggleable": true,
                            "isFX": true,
                            "window": "FX"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Audio/Mix\");\n                    }\n                    ",
                        "parent": "Ai.Audio",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Basic Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "Volume",
                                        "label": "Volume",
                                        "type": "float",
                                        "lines": "single",
                                        "placeholder": "0.0",
                                        "value": 0.0,
                                        "min": -1000.0,
                                        "max": 1000.0,
                                        "required": true,
                                        "help": "Set the volume level.",
                                        "uuid": "c841b940-a",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "Balance",
                                        "label": "Balance",
                                        "type": "int",
                                        "lines": "single",
                                        "placeholder": "0",
                                        "value": 0,
                                        "min": -1.0,
                                        "max": 1.0,
                                        "required": true,
                                        "help": "Set the audio LR balance.",
                                        "uuid": "c9209a68-3",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "Mute",
                                        "label": "Mute",
                                        "type": "bool",
                                        "lines": "single",
                                        "placeholder": "false",
                                        "value": false,
                                        "required": true,
                                        "help": "Mute the audio.",
                                        "min": -1000.0,
                                        "max": 1000.0,
                                        "uuid": "430458b1-e",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "c29b3b8d-5",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "basicsettings"
                            }
                        ],
                        "automatic": [
                            "audio",
                            "video"
                        ],
                        "hidden": true
                    },
                    {
                        "id": "Ai.Audio.Testrun",
                        "sub": "Test Import Audio Model",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.audio.generate.test",
                            "title": "Test Import Audio Model",
                            "path": "AI/Audio/TestRun",
                            "category": "Audio",
                            "icon": "",
                            "tags": [
                                "Audio",
                                "UAI"
                            ],
                            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Audio"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Basic Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "prompt",
                                            "label": "Prompt",
                                            "type": "string",
                                            "lines": "multi",
                                            "placeholder": "",
                                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                            "required": true,
                                            "help": "Enter the text prompt for generating the image.",
                                            "uuid": "16369745-d",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "neg_prompt",
                                            "label": "Negative Prompt",
                                            "type": "string",
                                            "lines": "multi",
                                            "value": "bad quality. low resolution",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "Enter the negative text prompt for generating the image.",
                                            "uuid": "d5b9f138-3",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "ff72e069-8",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "basicsettings"
                                },
                                {
                                    "title": "Advanced Settings",
                                    "size": "Small",
                                    "fields": [
                                        {
                                            "name": "model",
                                            "label": "AI Model",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": " ",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                                            "required": false,
                                            "help": "Select the AI Model to use for generating the image.",
                                            "uuid": "b340fab1-4",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "steps",
                                            "label": "Steps",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "6",
                                            "min": "0",
                                            "max": "100",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "The amount of imastepsges to generate.",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "uuid": "a58b23a8-f",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "guidance_scale",
                                            "label": "Config Scale",
                                            "type": "float",
                                            "lines": "single",
                                            "value": "3",
                                            "min": "0",
                                            "max": "100",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "The configuration scale.",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "uuid": "cdcefff9-1",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "bf5ecefc-6",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "buttons": [],
                                    "name": "advancedsettings"
                                },
                                {
                                    "title": "Output",
                                    "size": "Medium",
                                    "fields": [
                                        {
                                            "name": "seed",
                                            "label": "Seed",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "512",
                                            "placeholder": "512",
                                            "min": "0",
                                            "max": "1000000",
                                            "required": true,
                                            "help": "Enter the seed value for randomizing the image generation.",
                                            "uuid": "82c71db5-6",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "imagesToGenerate",
                                            "label": "Images to Generate",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "1",
                                            "placeholder": "1",
                                            "min": "0",
                                            "max": "8",
                                            "required": true,
                                            "help": "The amount of images to generate.",
                                            "uuid": "1fd62729-e",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "exportSize",
                                            "label": "Image Size",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": "",
                                            "options": [
                                                "S 1024X1024",
                                                "S 512X512",
                                                "S 1920X1920",
                                                "L 1920X1080",
                                                "L 1280X720",
                                                "L 1024X576",
                                                "P 1080X1920",
                                                "P 720X1280",
                                                "P 576X1240"
                                            ],
                                            "value": "S 1024X1024",
                                            "required": false,
                                            "help": "Select the size of the generated image.",
                                            "min": "0",
                                            "max": "1000000",
                                            "uuid": "af084d4f-1",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "3769a16a-1",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "buttons": [],
                                    "name": "output"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Generate",
                                    "module": "testRun_Audio",
                                    "moduleType": "python",
                                    "operation": "RunProcess",
                                    "args": "",
                                    "executable": "python.exe",
                                    "url": "/image/stableDiffusion_",
                                    "type": "submit",
                                    "help": "Click to generate the image.",
                                    "requestType": "ImageRequestConsole",
                                    "responseType": "MultipleMediaRequest",
                                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                    "postFunction": "com.uai.showMultiMediaAudio",
                                    "icon": "fa-rocket",
                                    "uuid": "fcef0cff-7"
                                }
                            ],
                            "uuid": "83d75978-2",
                            "hidden": false,
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": false,
                            "toggleable": true,
                            "isFX": true,
                            "window": "FX"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Audio/TestRun\");\n                    }\n                    ",
                        "parent": "Ai.Audio",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Basic Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "prompt",
                                        "label": "Prompt",
                                        "type": "string",
                                        "lines": "multi",
                                        "placeholder": "",
                                        "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                        "required": true,
                                        "help": "Enter the text prompt for generating the image.",
                                        "uuid": "16369745-d",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "neg_prompt",
                                        "label": "Negative Prompt",
                                        "type": "string",
                                        "lines": "multi",
                                        "value": "bad quality. low resolution",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "Enter the negative text prompt for generating the image.",
                                        "uuid": "d5b9f138-3",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "ff72e069-8",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "basicsettings"
                            },
                            {
                                "title": "Advanced Settings",
                                "size": "Small",
                                "fields": [
                                    {
                                        "name": "model",
                                        "label": "AI Model",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": " ",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "value": "SG161222/RealVisXL_V4.0_Lightning",
                                        "required": false,
                                        "help": "Select the AI Model to use for generating the image.",
                                        "uuid": "b340fab1-4",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "steps",
                                        "label": "Steps",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "6",
                                        "min": "0",
                                        "max": "100",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "The amount of imastepsges to generate.",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "uuid": "a58b23a8-f",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "guidance_scale",
                                        "label": "Config Scale",
                                        "type": "float",
                                        "lines": "single",
                                        "value": "3",
                                        "min": "0",
                                        "max": "100",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "The configuration scale.",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "uuid": "cdcefff9-1",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "bf5ecefc-6",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "buttons": [],
                                "name": "advancedsettings"
                            },
                            {
                                "title": "Output",
                                "size": "Medium",
                                "fields": [
                                    {
                                        "name": "seed",
                                        "label": "Seed",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "512",
                                        "placeholder": "512",
                                        "min": "0",
                                        "max": "1000000",
                                        "required": true,
                                        "help": "Enter the seed value for randomizing the image generation.",
                                        "uuid": "82c71db5-6",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "imagesToGenerate",
                                        "label": "Images to Generate",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "1",
                                        "placeholder": "1",
                                        "min": "0",
                                        "max": "8",
                                        "required": true,
                                        "help": "The amount of images to generate.",
                                        "uuid": "1fd62729-e",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "exportSize",
                                        "label": "Image Size",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": "",
                                        "options": [
                                            "S 1024X1024",
                                            "S 512X512",
                                            "S 1920X1920",
                                            "L 1920X1080",
                                            "L 1280X720",
                                            "L 1024X576",
                                            "P 1080X1920",
                                            "P 720X1280",
                                            "P 576X1240"
                                        ],
                                        "value": "S 1024X1024",
                                        "required": false,
                                        "help": "Select the size of the generated image.",
                                        "min": "0",
                                        "max": "1000000",
                                        "uuid": "af084d4f-1",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "3769a16a-1",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "buttons": [],
                                "name": "output"
                            }
                        ],
                        "automatic": [],
                        "hidden": false
                    }
                ]
            },
            {
                "id": "Ai.Text",
                "sub": "Text",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "Ai",
                "namesub": [
                    {
                        "id": "Ai.Text.Generate",
                        "sub": "Generate",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.Text",
                        "namesub": [
                            {
                                "id": "Ai.Text.Generate.Lyrics",
                                "sub": "Music Gen: Lyrics Generator",
                                "type": "function",
                                "metadata": {
                                    "name": "uai.builtin.audio.musicgen.custom",
                                    "title": "Music Gen: Lyrics Generator",
                                    "path": "AI/Text/Generate/Lyrics",
                                    "category": "Text",
                                    "icon": "",
                                    "tags": [
                                        "Text",
                                        "UAI"
                                    ],
                                    "description": "This module generates a song based on the lyrics provided. The lyrics should be separated by sections such as [Verse], [Chorus], [Bridge]. The module will generate a song based on the lyrics provided and return the audio of the song. The module also accepts tags that describe the song to generate. Use spaces to separate the tags and use hyphens for multi-word tags.",
                                    "inputs": [
                                        "Text",
                                        "None"
                                    ],
                                    "outputs": [
                                        "Text"
                                    ],
                                    "panel": 2,
                                    "subwindows": [
                                        {
                                            "title": "Basic Settings",
                                            "size": "Large",
                                            "fields": [
                                                {
                                                    "name": "prompt",
                                                    "label": "Prompt",
                                                    "type": "string",
                                                    "lines": "multi",
                                                    "placeholder": "",
                                                    "value": "A song about love and heartbreak.",
                                                    "required": true,
                                                    "help": "The prompt to generate the lyrics from.",
                                                    "uuid": "6bbba1be-5d04-42cb-9198-002d51d59cc3",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "buttons": [],
                                            "uuid": "496558a0-dd05-4770-ac3e-4a8b106fcc23",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "name": "basicsettings"
                                        }
                                    ],
                                    "functions": [
                                        {
                                            "name": "Run",
                                            "module": "internal.audio",
                                            "moduleType": "internal.text.generate.lyrics",
                                            "operation": "generateLyrics",
                                            "args": "",
                                            "executable": "internal.audio",
                                            "url": "/api/v1/music/text/to/text/lyrics",
                                            "type": "submit",
                                            "help": "Generate lyrics from a prompt.",
                                            "requestType": "fx.properties",
                                            "responseType": "MultipleMediaRequest",
                                            "preFunction": "",
                                            "postFunction": "",
                                            "icon": "fa-rocket",
                                            "uuid": "600e688d-5ae8-4053-ba58-c9e42e5c958a"
                                        }
                                    ],
                                    "uuid": "98b5249e-1707-4cbd-8872-dd981e2d96df",
                                    "hidden": false,
                                    "automatic": [],
                                    "documentation": "https://uai.software/docs",
                                    "initialState": false,
                                    "toggleable": true,
                                    "isFX": false,
                                    "window": "AIGenerate"
                                },
                                "function": "\n                    () => {\n                        onModuleRun(\"AI/Text/Generate/Lyrics\");\n                    }\n                    ",
                                "parent": "Ai.Text.Generate",
                                "namesub": [],
                                "subwindows": [
                                    {
                                        "title": "Basic Settings",
                                        "size": "Large",
                                        "fields": [
                                            {
                                                "name": "prompt",
                                                "label": "Prompt",
                                                "type": "string",
                                                "lines": "multi",
                                                "placeholder": "",
                                                "value": "A song about love and heartbreak.",
                                                "required": true,
                                                "help": "The prompt to generate the lyrics from.",
                                                "uuid": "6bbba1be-5d04-42cb-9198-002d51d59cc3",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "buttons": [],
                                        "uuid": "496558a0-dd05-4770-ac3e-4a8b106fcc23",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "name": "basicsettings"
                                    }
                                ],
                                "automatic": [],
                                "hidden": false
                            }
                        ]
                    }
                ]
            },
            {
                "id": "Ai.3d",
                "sub": "3d",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "Ai",
                "namesub": [
                    {
                        "id": "Ai.3d.Testrun",
                        "sub": "Test Import 3D Model",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.3D.generate.test",
                            "title": "Test Import 3D Model",
                            "path": "AI/3D/TestRun",
                            "category": "3D",
                            "icon": "",
                            "tags": [
                                "3D",
                                "UAI"
                            ],
                            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Image"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Basic Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "prompt",
                                            "label": "Prompt",
                                            "type": "string",
                                            "lines": "multi",
                                            "placeholder": "",
                                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                            "required": true,
                                            "help": "Enter the text prompt for generating the image.",
                                            "uuid": "474328f0-4",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "neg_prompt",
                                            "label": "Negative Prompt",
                                            "type": "string",
                                            "lines": "multi",
                                            "value": "bad quality. low resolution",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "Enter the negative text prompt for generating the image.",
                                            "uuid": "4c63b7b0-2",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "75e11b25-3",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "basicsettings"
                                },
                                {
                                    "title": "Advanced Settings",
                                    "size": "Small",
                                    "fields": [
                                        {
                                            "name": "model",
                                            "label": "AI Model",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": " ",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                                            "required": false,
                                            "help": "Select the AI Model to use for generating the image.",
                                            "uuid": "f7fa4693-7",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "steps",
                                            "label": "Steps",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "6",
                                            "min": "0",
                                            "max": "100",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "The amount of imastepsges to generate.",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "uuid": "de64e427-3",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "guidance_scale",
                                            "label": "Config Scale",
                                            "type": "float",
                                            "lines": "single",
                                            "value": "3",
                                            "min": "0",
                                            "max": "100",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "The configuration scale.",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "uuid": "18d5720e-d",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "edf91864-7",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "buttons": [],
                                    "name": "advancedsettings"
                                },
                                {
                                    "title": "Output",
                                    "size": "Medium",
                                    "fields": [
                                        {
                                            "name": "seed",
                                            "label": "Seed",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "512",
                                            "placeholder": "512",
                                            "min": "0",
                                            "max": "1000000",
                                            "required": true,
                                            "help": "Enter the seed value for randomizing the image generation.",
                                            "uuid": "be3d93e0-8",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "imagesToGenerate",
                                            "label": "Images to Generate",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "1",
                                            "placeholder": "1",
                                            "min": "0",
                                            "max": "8",
                                            "required": true,
                                            "help": "The amount of images to generate.",
                                            "uuid": "52de1377-d",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "exportSize",
                                            "label": "Image Size",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": "",
                                            "options": [
                                                "S 1024X1024",
                                                "S 512X512",
                                                "S 1920X1920",
                                                "L 1920X1080",
                                                "L 1280X720",
                                                "L 1024X576",
                                                "P 1080X1920",
                                                "P 720X1280",
                                                "P 576X1240"
                                            ],
                                            "value": "S 1024X1024",
                                            "required": false,
                                            "help": "Select the size of the generated image.",
                                            "min": "0",
                                            "max": "1000000",
                                            "uuid": "a04e51ad-8",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "0afe9a5a-f",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "buttons": [],
                                    "name": "output"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Generate",
                                    "module": "testRun_3D",
                                    "moduleType": "python",
                                    "operation": "RunProcess",
                                    "args": "",
                                    "executable": "python.exe",
                                    "url": "/image/stableDiffusion_",
                                    "type": "submit",
                                    "help": "Click to generate the image.",
                                    "requestType": "ImageRequestConsole",
                                    "responseType": "MultipleMediaRequest",
                                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                    "postFunction": "com.uai.showMultiMedia3DModel",
                                    "icon": "fa-rocket",
                                    "uuid": "b6dcbe04-2"
                                }
                            ],
                            "uuid": "e752496f-2",
                            "hidden": false,
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": false,
                            "toggleable": true,
                            "isFX": true,
                            "window": "FX"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"AI/3D/TestRun\");\n                    }\n                    ",
                        "parent": "Ai.3d",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Basic Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "prompt",
                                        "label": "Prompt",
                                        "type": "string",
                                        "lines": "multi",
                                        "placeholder": "",
                                        "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                        "required": true,
                                        "help": "Enter the text prompt for generating the image.",
                                        "uuid": "474328f0-4",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "neg_prompt",
                                        "label": "Negative Prompt",
                                        "type": "string",
                                        "lines": "multi",
                                        "value": "bad quality. low resolution",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "Enter the negative text prompt for generating the image.",
                                        "uuid": "4c63b7b0-2",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "75e11b25-3",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "basicsettings"
                            },
                            {
                                "title": "Advanced Settings",
                                "size": "Small",
                                "fields": [
                                    {
                                        "name": "model",
                                        "label": "AI Model",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": " ",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "value": "SG161222/RealVisXL_V4.0_Lightning",
                                        "required": false,
                                        "help": "Select the AI Model to use for generating the image.",
                                        "uuid": "f7fa4693-7",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "steps",
                                        "label": "Steps",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "6",
                                        "min": "0",
                                        "max": "100",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "The amount of imastepsges to generate.",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "uuid": "de64e427-3",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "guidance_scale",
                                        "label": "Config Scale",
                                        "type": "float",
                                        "lines": "single",
                                        "value": "3",
                                        "min": "0",
                                        "max": "100",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "The configuration scale.",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "uuid": "18d5720e-d",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "edf91864-7",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "buttons": [],
                                "name": "advancedsettings"
                            },
                            {
                                "title": "Output",
                                "size": "Medium",
                                "fields": [
                                    {
                                        "name": "seed",
                                        "label": "Seed",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "512",
                                        "placeholder": "512",
                                        "min": "0",
                                        "max": "1000000",
                                        "required": true,
                                        "help": "Enter the seed value for randomizing the image generation.",
                                        "uuid": "be3d93e0-8",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "imagesToGenerate",
                                        "label": "Images to Generate",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "1",
                                        "placeholder": "1",
                                        "min": "0",
                                        "max": "8",
                                        "required": true,
                                        "help": "The amount of images to generate.",
                                        "uuid": "52de1377-d",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "exportSize",
                                        "label": "Image Size",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": "",
                                        "options": [
                                            "S 1024X1024",
                                            "S 512X512",
                                            "S 1920X1920",
                                            "L 1920X1080",
                                            "L 1280X720",
                                            "L 1024X576",
                                            "P 1080X1920",
                                            "P 720X1280",
                                            "P 576X1240"
                                        ],
                                        "value": "S 1024X1024",
                                        "required": false,
                                        "help": "Select the size of the generated image.",
                                        "min": "0",
                                        "max": "1000000",
                                        "uuid": "a04e51ad-8",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "0afe9a5a-f",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "buttons": [],
                                "name": "output"
                            }
                        ],
                        "automatic": [],
                        "hidden": false
                    },
                    {
                        "id": "Ai.3d.Image3d",
                        "sub": "Image3d",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.3d",
                        "namesub": [
                            {
                                "id": "Ai.3d.Image3d.Triposr",
                                "sub": "Image3D: TripoSR",
                                "type": "function",
                                "metadata": {
                                    "name": "uai.builtin.3D.generate.triposr",
                                    "title": "Image3D: TripoSR",
                                    "path": "AI/3D/Image3D/TripoSR",
                                    "category": "3D",
                                    "icon": "",
                                    "tags": [
                                        "3D",
                                        "UAI"
                                    ],
                                    "description": "This module generates an obj 3D object from an image.",
                                    "inputs": [
                                        "Text",
                                        "None"
                                    ],
                                    "outputs": [
                                        "Image"
                                    ],
                                    "panel": 2,
                                    "subwindows": [
                                        {
                                            "title": "Basic Settings",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "input",
                                                    "label": "Input Image",
                                                    "type": "file",
                                                    "lines": "single",
                                                    "objectType": "Image",
                                                    "base64": true,
                                                    "placeholder": "",
                                                    "value": "",
                                                    "required": true,
                                                    "help": "The image to process.",
                                                    "uuid": "0703c8ea-3",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "seed",
                                                    "label": "Seed",
                                                    "type": "seed",
                                                    "lines": "single",
                                                    "value": "512",
                                                    "placeholder": "512",
                                                    "min": "0",
                                                    "max": "1000000",
                                                    "required": true,
                                                    "help": "Enter the seed value for randomizing the image generation.",
                                                    "objectType": "Image",
                                                    "base64": true,
                                                    "uuid": "df346a1e-2",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "buttons": [],
                                            "uuid": "2723523a-c",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "name": "basicsettings"
                                        },
                                        {
                                            "title": "Advanced Settings",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "model",
                                                    "label": "AI Model",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": "stabilityai/TripoSR",
                                                    "options": [
                                                        "stabilityai/TripoSR"
                                                    ],
                                                    "value": "stabilityai/TripoSR",
                                                    "required": false,
                                                    "help": "Select the AI Model to use for generating the image.",
                                                    "uuid": "3f79d6e2-5",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "removeBg",
                                                    "label": "Remove Bg",
                                                    "type": "bool",
                                                    "lines": "single",
                                                    "placeholder": false,
                                                    "value": false,
                                                    "required": false,
                                                    "help": "Whether to remove the BG from the input image",
                                                    "options": [
                                                        "stabilityai/TripoSR"
                                                    ],
                                                    "uuid": "b9c73afd-b",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "chunkSize",
                                                    "label": "Chunk Size",
                                                    "type": "int",
                                                    "lines": "single",
                                                    "value": "8192",
                                                    "min": "0",
                                                    "max": "100",
                                                    "placeholder": "",
                                                    "required": true,
                                                    "help": "The amount of imastepsges to generate.",
                                                    "options": [
                                                        "stabilityai/TripoSR"
                                                    ],
                                                    "uuid": "ae29745a-f",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "foregroundRatio",
                                                    "label": "Foreground Ratio",
                                                    "type": "float",
                                                    "lines": "single",
                                                    "value": "3",
                                                    "min": "0",
                                                    "max": "100",
                                                    "placeholder": "",
                                                    "required": true,
                                                    "help": "The configuration scale.",
                                                    "options": [
                                                        "stabilityai/TripoSR"
                                                    ],
                                                    "uuid": "710003ba-2",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "b64138ca-0",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "advancedsettings"
                                        }
                                    ],
                                    "functions": [
                                        {
                                            "name": "Generate",
                                            "module": "image23D_TripoSR",
                                            "moduleType": "python",
                                            "operation": "RunProcess",
                                            "args": "",
                                            "executable": "python.exe",
                                            "url": "/image/stableDiffusion_",
                                            "type": "submit",
                                            "help": "Click to generate the image.",
                                            "requestType": "ImageRequestConsole",
                                            "responseType": "MultipleMediaRequest",
                                            "preFunction": "com.uai.base64InputImage",
                                            "postFunction": "com.uai.showMultiMedia3DModel",
                                            "icon": "fa-rocket",
                                            "uuid": "f2b06a46-a"
                                        }
                                    ],
                                    "uuid": "6d9488ae-4",
                                    "hidden": false,
                                    "automatic": [],
                                    "documentation": "https://uai.software/docs",
                                    "initialState": false,
                                    "toggleable": true,
                                    "isFX": true,
                                    "window": "FX"
                                },
                                "function": "\n                    () => {\n                        onModuleRun(\"AI/3D/Image3D/TripoSR\");\n                    }\n                    ",
                                "parent": "Ai.3d.Image3d",
                                "namesub": [],
                                "subwindows": [
                                    {
                                        "title": "Basic Settings",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "input",
                                                "label": "Input Image",
                                                "type": "file",
                                                "lines": "single",
                                                "objectType": "Image",
                                                "base64": true,
                                                "placeholder": "",
                                                "value": "",
                                                "required": true,
                                                "help": "The image to process.",
                                                "uuid": "0703c8ea-3",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "seed",
                                                "label": "Seed",
                                                "type": "seed",
                                                "lines": "single",
                                                "value": "512",
                                                "placeholder": "512",
                                                "min": "0",
                                                "max": "1000000",
                                                "required": true,
                                                "help": "Enter the seed value for randomizing the image generation.",
                                                "objectType": "Image",
                                                "base64": true,
                                                "uuid": "df346a1e-2",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "buttons": [],
                                        "uuid": "2723523a-c",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "name": "basicsettings"
                                    },
                                    {
                                        "title": "Advanced Settings",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "model",
                                                "label": "AI Model",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": "stabilityai/TripoSR",
                                                "options": [
                                                    "stabilityai/TripoSR"
                                                ],
                                                "value": "stabilityai/TripoSR",
                                                "required": false,
                                                "help": "Select the AI Model to use for generating the image.",
                                                "uuid": "3f79d6e2-5",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "removeBg",
                                                "label": "Remove Bg",
                                                "type": "bool",
                                                "lines": "single",
                                                "placeholder": false,
                                                "value": false,
                                                "required": false,
                                                "help": "Whether to remove the BG from the input image",
                                                "options": [
                                                    "stabilityai/TripoSR"
                                                ],
                                                "uuid": "b9c73afd-b",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "chunkSize",
                                                "label": "Chunk Size",
                                                "type": "int",
                                                "lines": "single",
                                                "value": "8192",
                                                "min": "0",
                                                "max": "100",
                                                "placeholder": "",
                                                "required": true,
                                                "help": "The amount of imastepsges to generate.",
                                                "options": [
                                                    "stabilityai/TripoSR"
                                                ],
                                                "uuid": "ae29745a-f",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "foregroundRatio",
                                                "label": "Foreground Ratio",
                                                "type": "float",
                                                "lines": "single",
                                                "value": "3",
                                                "min": "0",
                                                "max": "100",
                                                "placeholder": "",
                                                "required": true,
                                                "help": "The configuration scale.",
                                                "options": [
                                                    "stabilityai/TripoSR"
                                                ],
                                                "uuid": "710003ba-2",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "b64138ca-0",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "advancedsettings"
                                    }
                                ],
                                "automatic": [],
                                "hidden": false
                            }
                        ]
                    },
                    {
                        "id": "Ai.3d.Pbr",
                        "sub": "Pbr",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.3d",
                        "namesub": [
                            {
                                "id": "Ai.3d.Pbr.Material",
                                "sub": "3D PBR BSDF Material",
                                "type": "function",
                                "metadata": {
                                    "name": "uai.builtin.pbrbsdfmaterial",
                                    "title": "3D PBR BSDF Material",
                                    "path": "AI/3D/PBR/Material",
                                    "category": "3D",
                                    "icon": "",
                                    "hidden": false,
                                    "tags": [
                                        "3D",
                                        "PBR",
                                        "BSDF",
                                        "Material",
                                        "UAI"
                                    ],
                                    "automatic": [
                                        "3d"
                                    ],
                                    "description": "This module provides PBR BSDF material settings including texture URLs and UV settings per texture.",
                                    "inputs": [
                                        "MaterialSource",
                                        "None"
                                    ],
                                    "outputs": [
                                        "Material"
                                    ],
                                    "panel": 2,
                                    "subwindows": [
                                        {
                                            "title": "Diffuse",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "baseColorTexture",
                                                    "label": "Base Color Texture URL",
                                                    "type": "string",
                                                    "lines": "single",
                                                    "placeholder": "http://example.com/base_color.png",
                                                    "value": "",
                                                    "required": true,
                                                    "help": "URL for the base color texture.",
                                                    "uuid": "e9e828be-d",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "uvBaseColor",
                                                    "label": "Base Color UV Settings",
                                                    "type": "vector2",
                                                    "lines": "single",
                                                    "placeholder": "[1, 1]",
                                                    "value": [
                                                        1,
                                                        1
                                                    ],
                                                    "required": true,
                                                    "help": "UV settings for the base color texture.",
                                                    "uuid": "302b6214-8",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "9507b508-d",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "diffuse"
                                        },
                                        {
                                            "title": "Normal",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "normalTexture",
                                                    "label": "Normal Texture URL",
                                                    "type": "string",
                                                    "lines": "single",
                                                    "placeholder": "http://example.com/normal.png",
                                                    "value": "",
                                                    "required": true,
                                                    "help": "URL for the normal texture.",
                                                    "uuid": "928dc411-f",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "uvNormal",
                                                    "label": "Normal UV Settings",
                                                    "type": "vector2",
                                                    "lines": "single",
                                                    "placeholder": "[1, 1]",
                                                    "value": [
                                                        1,
                                                        1
                                                    ],
                                                    "required": true,
                                                    "help": "UV settings for the normal texture.",
                                                    "uuid": "a117a25b-b",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "06704102-a",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "normal"
                                        },
                                        {
                                            "title": "Metallic",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "metallicTexture",
                                                    "label": "Metallic Texture URL",
                                                    "type": "string",
                                                    "lines": "single",
                                                    "placeholder": "http://example.com/metallic.png",
                                                    "value": "",
                                                    "required": true,
                                                    "help": "URL for the metallic texture.",
                                                    "uuid": "190f61a9-d",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "uvMetallic",
                                                    "label": "Metallic UV Settings",
                                                    "type": "vector2",
                                                    "lines": "single",
                                                    "placeholder": "[1, 1]",
                                                    "value": [
                                                        1,
                                                        1
                                                    ],
                                                    "required": true,
                                                    "help": "UV settings for the metallic texture.",
                                                    "uuid": "7bff1031-e",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "4b0e16ba-5",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "metallic"
                                        },
                                        {
                                            "title": "Roughness",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "roughnessTexture",
                                                    "label": "Roughness Texture URL",
                                                    "type": "string",
                                                    "lines": "single",
                                                    "placeholder": "http://example.com/roughness.png",
                                                    "value": "",
                                                    "required": true,
                                                    "help": "URL for the roughness texture.",
                                                    "uuid": "35a423b0-6",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "uvRoughness",
                                                    "label": "Roughness UV Settings",
                                                    "type": "vector2",
                                                    "lines": "single",
                                                    "placeholder": "[1, 1]",
                                                    "value": [
                                                        1,
                                                        1
                                                    ],
                                                    "required": true,
                                                    "help": "UV settings for the roughness texture.",
                                                    "uuid": "525965c7-3",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "cd1bd702-1",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "roughness"
                                        },
                                        {
                                            "title": "Ambient Occlusion",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "aoTexture",
                                                    "label": "Ambient Occlusion Texture URL",
                                                    "type": "string",
                                                    "lines": "single",
                                                    "placeholder": "http://example.com/ao.png",
                                                    "value": "",
                                                    "required": true,
                                                    "help": "URL for the ambient occlusion texture.",
                                                    "uuid": "c59b6e96-b",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "uvAO",
                                                    "label": "Ambient Occlusion UV Settings",
                                                    "type": "vector2",
                                                    "lines": "single",
                                                    "placeholder": "[1, 1]",
                                                    "value": [
                                                        1,
                                                        1
                                                    ],
                                                    "required": true,
                                                    "help": "UV settings for the ambient occlusion texture.",
                                                    "uuid": "97592799-7",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "12158434-9",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "ambientocclusion"
                                        }
                                    ],
                                    "functions": [
                                        {
                                            "name": "Run",
                                            "module": "internal.3d",
                                            "moduleType": "internal.3d",
                                            "operation": "setPbrbsdfmaterial",
                                            "args": "",
                                            "executable": "internal.3d",
                                            "url": "/pbrbsdfmaterial/execute",
                                            "type": "update",
                                            "help": "Click to apply the PBR BSDF material.",
                                            "requestType": "",
                                            "responseType": "MterialResponse",
                                            "preFunction": "",
                                            "postFunction": "",
                                            "icon": "fa-paint-brush",
                                            "uuid": "2d0c2fd4-0"
                                        }
                                    ],
                                    "uuid": "0d83cdbc-f",
                                    "documentation": "https://uai.software/docs",
                                    "initialState": false,
                                    "toggleable": true,
                                    "isFX": true,
                                    "window": "FX"
                                },
                                "function": "\n                    () => {\n                        onModuleRun(\"AI/3D/PBR/Material\");\n                    }\n                    ",
                                "parent": "Ai.3d.Pbr",
                                "namesub": [],
                                "subwindows": [
                                    {
                                        "title": "Diffuse",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "baseColorTexture",
                                                "label": "Base Color Texture URL",
                                                "type": "string",
                                                "lines": "single",
                                                "placeholder": "http://example.com/base_color.png",
                                                "value": "",
                                                "required": true,
                                                "help": "URL for the base color texture.",
                                                "uuid": "e9e828be-d",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "uvBaseColor",
                                                "label": "Base Color UV Settings",
                                                "type": "vector2",
                                                "lines": "single",
                                                "placeholder": "[1, 1]",
                                                "value": [
                                                    1,
                                                    1
                                                ],
                                                "required": true,
                                                "help": "UV settings for the base color texture.",
                                                "uuid": "302b6214-8",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "9507b508-d",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "diffuse"
                                    },
                                    {
                                        "title": "Normal",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "normalTexture",
                                                "label": "Normal Texture URL",
                                                "type": "string",
                                                "lines": "single",
                                                "placeholder": "http://example.com/normal.png",
                                                "value": "",
                                                "required": true,
                                                "help": "URL for the normal texture.",
                                                "uuid": "928dc411-f",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "uvNormal",
                                                "label": "Normal UV Settings",
                                                "type": "vector2",
                                                "lines": "single",
                                                "placeholder": "[1, 1]",
                                                "value": [
                                                    1,
                                                    1
                                                ],
                                                "required": true,
                                                "help": "UV settings for the normal texture.",
                                                "uuid": "a117a25b-b",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "06704102-a",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "normal"
                                    },
                                    {
                                        "title": "Metallic",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "metallicTexture",
                                                "label": "Metallic Texture URL",
                                                "type": "string",
                                                "lines": "single",
                                                "placeholder": "http://example.com/metallic.png",
                                                "value": "",
                                                "required": true,
                                                "help": "URL for the metallic texture.",
                                                "uuid": "190f61a9-d",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "uvMetallic",
                                                "label": "Metallic UV Settings",
                                                "type": "vector2",
                                                "lines": "single",
                                                "placeholder": "[1, 1]",
                                                "value": [
                                                    1,
                                                    1
                                                ],
                                                "required": true,
                                                "help": "UV settings for the metallic texture.",
                                                "uuid": "7bff1031-e",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "4b0e16ba-5",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "metallic"
                                    },
                                    {
                                        "title": "Roughness",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "roughnessTexture",
                                                "label": "Roughness Texture URL",
                                                "type": "string",
                                                "lines": "single",
                                                "placeholder": "http://example.com/roughness.png",
                                                "value": "",
                                                "required": true,
                                                "help": "URL for the roughness texture.",
                                                "uuid": "35a423b0-6",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "uvRoughness",
                                                "label": "Roughness UV Settings",
                                                "type": "vector2",
                                                "lines": "single",
                                                "placeholder": "[1, 1]",
                                                "value": [
                                                    1,
                                                    1
                                                ],
                                                "required": true,
                                                "help": "UV settings for the roughness texture.",
                                                "uuid": "525965c7-3",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "cd1bd702-1",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "roughness"
                                    },
                                    {
                                        "title": "Ambient Occlusion",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "aoTexture",
                                                "label": "Ambient Occlusion Texture URL",
                                                "type": "string",
                                                "lines": "single",
                                                "placeholder": "http://example.com/ao.png",
                                                "value": "",
                                                "required": true,
                                                "help": "URL for the ambient occlusion texture.",
                                                "uuid": "c59b6e96-b",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "uvAO",
                                                "label": "Ambient Occlusion UV Settings",
                                                "type": "vector2",
                                                "lines": "single",
                                                "placeholder": "[1, 1]",
                                                "value": [
                                                    1,
                                                    1
                                                ],
                                                "required": true,
                                                "help": "UV settings for the ambient occlusion texture.",
                                                "uuid": "97592799-7",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "12158434-9",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "ambientocclusion"
                                    }
                                ],
                                "automatic": [
                                    "3d"
                                ],
                                "hidden": false
                            }
                        ]
                    },
                    {
                        "id": "Ai.3d.Unlit",
                        "sub": "Unlit",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.3d",
                        "namesub": [
                            {
                                "id": "Ai.3d.Unlit.Material",
                                "sub": "3D Unlit Material",
                                "type": "function",
                                "metadata": {
                                    "name": "uai.builtin.unlitmaterial",
                                    "title": "3D Unlit Material",
                                    "path": "AI/3D/Unlit/Material",
                                    "category": "3D",
                                    "icon": "",
                                    "hidden": false,
                                    "automatic": [
                                        "2d",
                                        "image",
                                        "video"
                                    ],
                                    "tags": [
                                        "3D",
                                        "Unlit",
                                        "Material",
                                        "UAI"
                                    ],
                                    "description": "This module provides unlit material settings including a base texture URL or base color.",
                                    "inputs": [
                                        "MaterialSource",
                                        "None"
                                    ],
                                    "outputs": [
                                        "Material"
                                    ],
                                    "panel": 2,
                                    "subwindows": [
                                        {
                                            "title": "Base Texture",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "baseTexture",
                                                    "label": "Base Texture URL",
                                                    "type": "string",
                                                    "lines": "single",
                                                    "placeholder": "http://example.com/base_texture.png",
                                                    "value": "",
                                                    "required": false,
                                                    "help": "URL for the base texture.",
                                                    "uuid": "1ddbe5be-a",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "57606b7c-2",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "basetexture"
                                        },
                                        {
                                            "title": "Base Color",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "baseColor",
                                                    "label": "Base Color",
                                                    "type": "color",
                                                    "lines": "single",
                                                    "placeholder": "#ffffff",
                                                    "value": "#ffffff",
                                                    "required": false,
                                                    "help": "Hex value for the base color.",
                                                    "uuid": "b3b45a6e-5",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "2c604564-e",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "basecolor"
                                        }
                                    ],
                                    "functions": [
                                        {
                                            "name": "Run",
                                            "module": "internal.3d",
                                            "moduleType": "internal.3d",
                                            "operation": "setUnlitmaterial",
                                            "args": "",
                                            "executable": "internal.3d",
                                            "url": "/pbrbsdfmaterial/execute",
                                            "type": "update",
                                            "help": "Click to apply the unlit material.",
                                            "requestType": "MaterialRequest",
                                            "responseType": "MaterialResponse",
                                            "preFunction": "",
                                            "postFunction": "",
                                            "icon": "fa-paint-brush",
                                            "uuid": "59512b2c-c"
                                        }
                                    ],
                                    "uuid": "99ef7020-9",
                                    "documentation": "https://uai.software/docs",
                                    "initialState": false,
                                    "toggleable": true,
                                    "isFX": true,
                                    "window": "FX"
                                },
                                "function": "\n                    () => {\n                        onModuleRun(\"AI/3D/Unlit/Material\");\n                    }\n                    ",
                                "parent": "Ai.3d.Unlit",
                                "namesub": [],
                                "subwindows": [
                                    {
                                        "title": "Base Texture",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "baseTexture",
                                                "label": "Base Texture URL",
                                                "type": "string",
                                                "lines": "single",
                                                "placeholder": "http://example.com/base_texture.png",
                                                "value": "",
                                                "required": false,
                                                "help": "URL for the base texture.",
                                                "uuid": "1ddbe5be-a",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "57606b7c-2",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "basetexture"
                                    },
                                    {
                                        "title": "Base Color",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "baseColor",
                                                "label": "Base Color",
                                                "type": "color",
                                                "lines": "single",
                                                "placeholder": "#ffffff",
                                                "value": "#ffffff",
                                                "required": false,
                                                "help": "Hex value for the base color.",
                                                "uuid": "b3b45a6e-5",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "2c604564-e",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "basecolor"
                                    }
                                ],
                                "automatic": [
                                    "2d",
                                    "image",
                                    "video"
                                ],
                                "hidden": false
                            }
                        ]
                    }
                ]
            },
            {
                "id": "Ai.Transform",
                "sub": "Transform",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "Ai",
                "namesub": [
                    {
                        "id": "Ai.Transform.Base",
                        "sub": "Transform",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.transform.base",
                            "title": "Transform",
                            "path": "AI/Transform/Base",
                            "category": "Transform",
                            "icon": "",
                            "hidden": true,
                            "automatic": [
                                "image",
                                "3d",
                                "video",
                                "2d"
                            ],
                            "tags": [
                                "Transform",
                                "Base",
                                "UAI"
                            ],
                            "description": "This module provides basic transform capabilities.",
                            "inputs": [
                                "TransformSource",
                                "None"
                            ],
                            "outputs": [
                                "TransformedObject"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "position",
                                            "label": "Position",
                                            "type": "vector3",
                                            "lines": "single",
                                            "placeholder": "[0, 0, 0]",
                                            "value": [
                                                0,
                                                0,
                                                0
                                            ],
                                            "required": true,
                                            "help": "Set the position of the object.",
                                            "uuid": "c568090b-c",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "rotation",
                                            "label": "Rotation",
                                            "type": "vector3",
                                            "lines": "single",
                                            "placeholder": "[0, 0, 0]",
                                            "value": [
                                                0,
                                                0,
                                                0
                                            ],
                                            "required": true,
                                            "help": "Set the rotation of the object.",
                                            "uuid": "bc034bf0-5",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "scale",
                                            "label": "Scale",
                                            "type": "vector3",
                                            "lines": "single",
                                            "placeholder": "[1, 1, 1]",
                                            "value": [
                                                1,
                                                1,
                                                1
                                            ],
                                            "required": true,
                                            "help": "Set the scale of the object.",
                                            "uuid": "425b84c7-a",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "pivot",
                                            "label": "Pivot",
                                            "type": "vector3",
                                            "lines": "single",
                                            "placeholder": "[0, 0, 0]",
                                            "value": [
                                                0,
                                                0,
                                                0
                                            ],
                                            "required": true,
                                            "help": "Set the pivot point of the object.",
                                            "uuid": "80033f24-5",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "anchor",
                                            "label": "Anchor",
                                            "type": "vector3",
                                            "lines": "single",
                                            "placeholder": "[0, 0, 0]",
                                            "value": [
                                                0,
                                                0,
                                                0
                                            ],
                                            "required": true,
                                            "help": "Set the anchor point of the object.",
                                            "uuid": "77728286-8",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "flipX",
                                            "label": "Flip X",
                                            "type": "bool",
                                            "lines": "single",
                                            "placeholder": "false",
                                            "value": false,
                                            "required": true,
                                            "help": "Set the X flip status of the object.",
                                            "uuid": "7a36be86-3",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "flipY",
                                            "label": "Flip Y",
                                            "type": "bool",
                                            "lines": "single",
                                            "placeholder": "false",
                                            "value": false,
                                            "required": true,
                                            "help": "Set the Y flip status of the object.",
                                            "uuid": "65b72687-d",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "46ebc92a-6",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "settings"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Run",
                                    "module": "internal.3d",
                                    "moduleType": "internal.3d",
                                    "operation": "setTransform",
                                    "args": "",
                                    "executable": "internal.3d",
                                    "url": "/image/stableDiffusion_",
                                    "type": "update",
                                    "help": "Process the 3D Transform Matrix.",
                                    "requestType": "fx.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-cube",
                                    "uuid": "c5edf9d2-6"
                                }
                            ],
                            "uuid": "61ce372b-a",
                            "documentation": "https://uai.software/docs",
                            "initialState": false,
                            "toggleable": true,
                            "isFX": true,
                            "window": "FX"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Transform/Base\");\n                    }\n                    ",
                        "parent": "Ai.Transform",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "position",
                                        "label": "Position",
                                        "type": "vector3",
                                        "lines": "single",
                                        "placeholder": "[0, 0, 0]",
                                        "value": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "required": true,
                                        "help": "Set the position of the object.",
                                        "uuid": "c568090b-c",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "rotation",
                                        "label": "Rotation",
                                        "type": "vector3",
                                        "lines": "single",
                                        "placeholder": "[0, 0, 0]",
                                        "value": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "required": true,
                                        "help": "Set the rotation of the object.",
                                        "uuid": "bc034bf0-5",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "scale",
                                        "label": "Scale",
                                        "type": "vector3",
                                        "lines": "single",
                                        "placeholder": "[1, 1, 1]",
                                        "value": [
                                            1,
                                            1,
                                            1
                                        ],
                                        "required": true,
                                        "help": "Set the scale of the object.",
                                        "uuid": "425b84c7-a",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "pivot",
                                        "label": "Pivot",
                                        "type": "vector3",
                                        "lines": "single",
                                        "placeholder": "[0, 0, 0]",
                                        "value": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "required": true,
                                        "help": "Set the pivot point of the object.",
                                        "uuid": "80033f24-5",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "anchor",
                                        "label": "Anchor",
                                        "type": "vector3",
                                        "lines": "single",
                                        "placeholder": "[0, 0, 0]",
                                        "value": [
                                            0,
                                            0,
                                            0
                                        ],
                                        "required": true,
                                        "help": "Set the anchor point of the object.",
                                        "uuid": "77728286-8",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "flipX",
                                        "label": "Flip X",
                                        "type": "bool",
                                        "lines": "single",
                                        "placeholder": "false",
                                        "value": false,
                                        "required": true,
                                        "help": "Set the X flip status of the object.",
                                        "uuid": "7a36be86-3",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "flipY",
                                        "label": "Flip Y",
                                        "type": "bool",
                                        "lines": "single",
                                        "placeholder": "false",
                                        "value": false,
                                        "required": true,
                                        "help": "Set the Y flip status of the object.",
                                        "uuid": "65b72687-d",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "46ebc92a-6",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "settings"
                            }
                        ],
                        "automatic": [
                            "image",
                            "3d",
                            "video",
                            "2d"
                        ],
                        "hidden": true
                    }
                ]
            },
            {
                "id": "Ai.Render",
                "sub": "Render",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "Ai",
                "namesub": [
                    {
                        "id": "Ai.Render.Base",
                        "sub": "Render",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.render.base",
                            "title": "Render",
                            "path": "AI/Render/Base",
                            "category": "Render",
                            "icon": "",
                            "hidden": true,
                            "tags": [
                                "Render",
                                "Base",
                                "UAI"
                            ],
                            "automatic": [
                                "image",
                                "3d",
                                "video",
                                "2d"
                            ],
                            "description": "This module provides basic rendering capabilities.",
                            "inputs": [
                                "RenderSource",
                                "None"
                            ],
                            "outputs": [
                                "canvas.render"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Render Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "opacity",
                                            "label": "Opacity",
                                            "type": "float",
                                            "lines": "single",
                                            "placeholder": "1.0",
                                            "value": 1.0,
                                            "min": 0.0,
                                            "max": 1.0,
                                            "required": true,
                                            "help": "Set the opacity level.",
                                            "uuid": "0ff81b9f-c",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "inFrame",
                                            "label": "In Frame",
                                            "type": "int",
                                            "lines": "single",
                                            "placeholder": "0",
                                            "value": 0,
                                            "min": 0,
                                            "max": 10000,
                                            "required": true,
                                            "help": "Set the starting frame.",
                                            "uuid": "31019713-0",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "outFrame",
                                            "label": "Out Frame",
                                            "type": "int",
                                            "lines": "single",
                                            "placeholder": "300",
                                            "value": 300,
                                            "min": 0,
                                            "max": 10000,
                                            "required": true,
                                            "help": "Set the ending frame.",
                                            "uuid": "d89fab46-2",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "blendMode",
                                            "label": "Blend Mode",
                                            "type": "string",
                                            "lines": "single",
                                            "placeholder": "Over",
                                            "value": "Over",
                                            "required": true,
                                            "help": "Set the blend mode.",
                                            "min": 0.0,
                                            "max": 1.0,
                                            "uuid": "e12f21ab-6",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "title": "Channels",
                                            "size": "Medium",
                                            "fields": [
                                                {
                                                    "name": "rChannel",
                                                    "label": "R Channel",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": "",
                                                    "options": [
                                                        "R",
                                                        "G",
                                                        "B",
                                                        "A",
                                                        "Z"
                                                    ],
                                                    "value": "R",
                                                    "required": true,
                                                    "help": "Select the R Channel"
                                                },
                                                {
                                                    "name": "gChannel",
                                                    "label": "G Channel",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": "",
                                                    "options": [
                                                        "R",
                                                        "G",
                                                        "B",
                                                        "A",
                                                        "Z"
                                                    ],
                                                    "value": "G",
                                                    "required": true,
                                                    "help": "Select the R Channel"
                                                },
                                                {
                                                    "name": "bChannel",
                                                    "label": "B Channel",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": "",
                                                    "options": [
                                                        "R",
                                                        "G",
                                                        "B",
                                                        "A",
                                                        "Z"
                                                    ],
                                                    "value": "B",
                                                    "required": true,
                                                    "help": "Select the R Channel"
                                                },
                                                {
                                                    "name": "aChannel",
                                                    "label": "A Channel",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": "",
                                                    "options": [
                                                        "R",
                                                        "G",
                                                        "B",
                                                        "A",
                                                        "Z"
                                                    ],
                                                    "value": "A",
                                                    "required": true,
                                                    "help": "Select the R Channel"
                                                },
                                                {
                                                    "name": "zChannel",
                                                    "label": "Z Channel",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": "",
                                                    "options": [
                                                        "R",
                                                        "G",
                                                        "B",
                                                        "A",
                                                        "Z"
                                                    ],
                                                    "value": "Z",
                                                    "required": true,
                                                    "help": "Select the R Channel"
                                                },
                                                {
                                                    "name": "invertAlpha",
                                                    "label": "Invert Alpha",
                                                    "type": "bool",
                                                    "lines": "single",
                                                    "placeholder": "false",
                                                    "value": false,
                                                    "required": true,
                                                    "help": "Invert the alpha channel."
                                                },
                                                {
                                                    "name": "colorTint",
                                                    "label": "Color Tint",
                                                    "type": "color4",
                                                    "lines": "single",
                                                    "placeholder": "[255,255,255,255]",
                                                    "value": [
                                                        255,
                                                        255,
                                                        255,
                                                        255
                                                    ],
                                                    "required": true,
                                                    "help": "Set the channel mix values."
                                                }
                                            ],
                                            "name": "opacity",
                                            "label": "Opacity",
                                            "type": "float",
                                            "lines": "single",
                                            "placeholder": "1.0",
                                            "value": 1.0,
                                            "min": 0.0,
                                            "max": 1.0,
                                            "required": true,
                                            "help": "Set the opacity level.",
                                            "uuid": "26952352-f",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "19edad9c-5",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "rendersettings"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Run",
                                    "module": "internal.2d.render",
                                    "moduleType": "internal.2d.render",
                                    "operation": "setMediaRenderSettings",
                                    "args": "",
                                    "executable": "internal.3d",
                                    "url": "/image/stableDiffusion_",
                                    "type": "update",
                                    "help": "Process the 3D Transform Matrix.",
                                    "requestType": "fx.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-paint-brush",
                                    "uuid": "aa3d3445-3"
                                }
                            ],
                            "uuid": "cdc0c897-1",
                            "documentation": "https://uai.software/docs",
                            "initialState": false,
                            "toggleable": true,
                            "isFX": true,
                            "window": "FX"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Render/Base\");\n                    }\n                    ",
                        "parent": "Ai.Render",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Render Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "opacity",
                                        "label": "Opacity",
                                        "type": "float",
                                        "lines": "single",
                                        "placeholder": "1.0",
                                        "value": 1.0,
                                        "min": 0.0,
                                        "max": 1.0,
                                        "required": true,
                                        "help": "Set the opacity level.",
                                        "uuid": "0ff81b9f-c",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "inFrame",
                                        "label": "In Frame",
                                        "type": "int",
                                        "lines": "single",
                                        "placeholder": "0",
                                        "value": 0,
                                        "min": 0,
                                        "max": 10000,
                                        "required": true,
                                        "help": "Set the starting frame.",
                                        "uuid": "31019713-0",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "outFrame",
                                        "label": "Out Frame",
                                        "type": "int",
                                        "lines": "single",
                                        "placeholder": "300",
                                        "value": 300,
                                        "min": 0,
                                        "max": 10000,
                                        "required": true,
                                        "help": "Set the ending frame.",
                                        "uuid": "d89fab46-2",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "blendMode",
                                        "label": "Blend Mode",
                                        "type": "string",
                                        "lines": "single",
                                        "placeholder": "Over",
                                        "value": "Over",
                                        "required": true,
                                        "help": "Set the blend mode.",
                                        "min": 0.0,
                                        "max": 1.0,
                                        "uuid": "e12f21ab-6",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "title": "Channels",
                                        "size": "Medium",
                                        "fields": [
                                            {
                                                "name": "rChannel",
                                                "label": "R Channel",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": "",
                                                "options": [
                                                    "R",
                                                    "G",
                                                    "B",
                                                    "A",
                                                    "Z"
                                                ],
                                                "value": "R",
                                                "required": true,
                                                "help": "Select the R Channel"
                                            },
                                            {
                                                "name": "gChannel",
                                                "label": "G Channel",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": "",
                                                "options": [
                                                    "R",
                                                    "G",
                                                    "B",
                                                    "A",
                                                    "Z"
                                                ],
                                                "value": "G",
                                                "required": true,
                                                "help": "Select the R Channel"
                                            },
                                            {
                                                "name": "bChannel",
                                                "label": "B Channel",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": "",
                                                "options": [
                                                    "R",
                                                    "G",
                                                    "B",
                                                    "A",
                                                    "Z"
                                                ],
                                                "value": "B",
                                                "required": true,
                                                "help": "Select the R Channel"
                                            },
                                            {
                                                "name": "aChannel",
                                                "label": "A Channel",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": "",
                                                "options": [
                                                    "R",
                                                    "G",
                                                    "B",
                                                    "A",
                                                    "Z"
                                                ],
                                                "value": "A",
                                                "required": true,
                                                "help": "Select the R Channel"
                                            },
                                            {
                                                "name": "zChannel",
                                                "label": "Z Channel",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": "",
                                                "options": [
                                                    "R",
                                                    "G",
                                                    "B",
                                                    "A",
                                                    "Z"
                                                ],
                                                "value": "Z",
                                                "required": true,
                                                "help": "Select the R Channel"
                                            },
                                            {
                                                "name": "invertAlpha",
                                                "label": "Invert Alpha",
                                                "type": "bool",
                                                "lines": "single",
                                                "placeholder": "false",
                                                "value": false,
                                                "required": true,
                                                "help": "Invert the alpha channel."
                                            },
                                            {
                                                "name": "colorTint",
                                                "label": "Color Tint",
                                                "type": "color4",
                                                "lines": "single",
                                                "placeholder": "[255,255,255,255]",
                                                "value": [
                                                    255,
                                                    255,
                                                    255,
                                                    255
                                                ],
                                                "required": true,
                                                "help": "Set the channel mix values."
                                            }
                                        ],
                                        "name": "opacity",
                                        "label": "Opacity",
                                        "type": "float",
                                        "lines": "single",
                                        "placeholder": "1.0",
                                        "value": 1.0,
                                        "min": 0.0,
                                        "max": 1.0,
                                        "required": true,
                                        "help": "Set the opacity level.",
                                        "uuid": "26952352-f",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "19edad9c-5",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "rendersettings"
                            }
                        ],
                        "automatic": [
                            "image",
                            "3d",
                            "video",
                            "2d"
                        ],
                        "hidden": true
                    }
                ]
            },
            {
                "id": "Ai.Image",
                "sub": "Image",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "Ai",
                "namesub": [
                    {
                        "id": "Ai.Image.Generate",
                        "sub": "Generate",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.Image",
                        "namesub": [
                            {
                                "id": "Ai.Image.Generate.Testrun",
                                "sub": "XL Lightning: T2I",
                                "type": "function",
                                "metadata": {
                                    "name": "uai.builtin.image.generate.test",
                                    "title": "XL Lightning: T2I",
                                    "path": "AI/Image/Generate/TestRun",
                                    "category": "Image",
                                    "icon": "",
                                    "tags": [
                                        "Image",
                                        "Stable Diffusion",
                                        "XL Lightning",
                                        "Text2Image",
                                        "UAI"
                                    ],
                                    "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
                                    "inputs": [
                                        "Text",
                                        "None"
                                    ],
                                    "outputs": [
                                        "Image"
                                    ],
                                    "panel": 2,
                                    "subwindows": [
                                        {
                                            "title": "Basic Settings",
                                            "size": "Large",
                                            "fields": [
                                                {
                                                    "name": "prompt",
                                                    "label": "Prompt",
                                                    "type": "string",
                                                    "lines": "multi",
                                                    "placeholder": "",
                                                    "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                    "required": true,
                                                    "help": "Enter the text prompt for generating the image.",
                                                    "uuid": "7dbad9d8-4",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "neg_prompt",
                                                    "label": "Negative Prompt",
                                                    "type": "string",
                                                    "lines": "multi",
                                                    "value": "bad quality. low resolution",
                                                    "placeholder": "",
                                                    "required": true,
                                                    "help": "Enter the negative text prompt for generating the image.",
                                                    "uuid": "814243eb-f",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "buttons": [],
                                            "uuid": "06e9e0d7-f",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "name": "basicsettings"
                                        },
                                        {
                                            "title": "Advanced Settings",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "model",
                                                    "label": "AI Model",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": " ",
                                                    "options": [
                                                        "components.models.stablediffusion.xl"
                                                    ],
                                                    "value": "SG161222/RealVisXL_V4.0_Lightning",
                                                    "required": false,
                                                    "help": "Select the AI Model to use for generating the image.",
                                                    "uuid": "571a4e48-6",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "steps",
                                                    "label": "Steps",
                                                    "type": "int",
                                                    "lines": "single",
                                                    "value": "6",
                                                    "min": "0",
                                                    "max": "100",
                                                    "placeholder": "",
                                                    "required": true,
                                                    "help": "The amount of imastepsges to generate.",
                                                    "options": [
                                                        "components.models.stablediffusion.xl"
                                                    ],
                                                    "uuid": "00c96e33-4",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "guidance_scale",
                                                    "label": "Config Scale",
                                                    "type": "float",
                                                    "lines": "single",
                                                    "value": "3",
                                                    "min": "0",
                                                    "max": "100",
                                                    "placeholder": "",
                                                    "required": true,
                                                    "help": "The configuration scale.",
                                                    "options": [
                                                        "components.models.stablediffusion.xl"
                                                    ],
                                                    "uuid": "663ff6d8-5",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "4786977f-f",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "advancedsettings"
                                        },
                                        {
                                            "title": "Output",
                                            "size": "Medium",
                                            "fields": [
                                                {
                                                    "name": "seed",
                                                    "label": "Seed",
                                                    "type": "int",
                                                    "lines": "single",
                                                    "value": "512",
                                                    "placeholder": "512",
                                                    "min": "0",
                                                    "max": "1000000",
                                                    "required": true,
                                                    "help": "Enter the seed value for randomizing the image generation.",
                                                    "uuid": "fd088c14-b",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "imagesToGenerate",
                                                    "label": "Images to Generate",
                                                    "type": "int",
                                                    "lines": "single",
                                                    "value": "1",
                                                    "placeholder": "1",
                                                    "min": "0",
                                                    "max": "8",
                                                    "required": true,
                                                    "help": "The amount of images to generate.",
                                                    "uuid": "269a51a4-b",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "exportSize",
                                                    "label": "Image Size",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": "",
                                                    "options": [
                                                        "S 1024X1024",
                                                        "S 512X512",
                                                        "S 1920X1920",
                                                        "L 1920X1080",
                                                        "L 1280X720",
                                                        "L 1024X576",
                                                        "P 1080X1920",
                                                        "P 720X1280",
                                                        "P 576X1240"
                                                    ],
                                                    "value": "S 1024X1024",
                                                    "required": false,
                                                    "help": "Select the size of the generated image.",
                                                    "min": "0",
                                                    "max": "1000000",
                                                    "uuid": "fc1db7cb-a",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "f4fd1905-e",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "output"
                                        }
                                    ],
                                    "functions": [
                                        {
                                            "name": "Generate",
                                            "module": "testRun_Image",
                                            "moduleType": "python",
                                            "operation": "RunProcess",
                                            "args": "",
                                            "executable": "python.exe",
                                            "url": "/image/stableDiffusion_",
                                            "type": "submit",
                                            "help": "Click to generate the image.",
                                            "requestType": "ImageRequestConsole",
                                            "responseType": "MultipleMediaRequest",
                                            "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                            "postFunction": "com.uai.showMultiImageOutput",
                                            "icon": "fa-rocket",
                                            "uuid": "258f194d-6"
                                        }
                                    ],
                                    "uuid": "9b92c90b-7",
                                    "hidden": false,
                                    "automatic": [],
                                    "documentation": "https://uai.software/docs",
                                    "initialState": false,
                                    "toggleable": true,
                                    "isFX": true,
                                    "window": "FX"
                                },
                                "function": "\n                    () => {\n                        onModuleRun(\"AI/Image/Generate/TestRun\");\n                    }\n                    ",
                                "parent": "Ai.Image.Generate",
                                "namesub": [],
                                "subwindows": [
                                    {
                                        "title": "Basic Settings",
                                        "size": "Large",
                                        "fields": [
                                            {
                                                "name": "prompt",
                                                "label": "Prompt",
                                                "type": "string",
                                                "lines": "multi",
                                                "placeholder": "",
                                                "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                "required": true,
                                                "help": "Enter the text prompt for generating the image.",
                                                "uuid": "7dbad9d8-4",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "neg_prompt",
                                                "label": "Negative Prompt",
                                                "type": "string",
                                                "lines": "multi",
                                                "value": "bad quality. low resolution",
                                                "placeholder": "",
                                                "required": true,
                                                "help": "Enter the negative text prompt for generating the image.",
                                                "uuid": "814243eb-f",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "buttons": [],
                                        "uuid": "06e9e0d7-f",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "name": "basicsettings"
                                    },
                                    {
                                        "title": "Advanced Settings",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "model",
                                                "label": "AI Model",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": " ",
                                                "options": [
                                                    "components.models.stablediffusion.xl"
                                                ],
                                                "value": "SG161222/RealVisXL_V4.0_Lightning",
                                                "required": false,
                                                "help": "Select the AI Model to use for generating the image.",
                                                "uuid": "571a4e48-6",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "steps",
                                                "label": "Steps",
                                                "type": "int",
                                                "lines": "single",
                                                "value": "6",
                                                "min": "0",
                                                "max": "100",
                                                "placeholder": "",
                                                "required": true,
                                                "help": "The amount of imastepsges to generate.",
                                                "options": [
                                                    "components.models.stablediffusion.xl"
                                                ],
                                                "uuid": "00c96e33-4",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "guidance_scale",
                                                "label": "Config Scale",
                                                "type": "float",
                                                "lines": "single",
                                                "value": "3",
                                                "min": "0",
                                                "max": "100",
                                                "placeholder": "",
                                                "required": true,
                                                "help": "The configuration scale.",
                                                "options": [
                                                    "components.models.stablediffusion.xl"
                                                ],
                                                "uuid": "663ff6d8-5",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "4786977f-f",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "advancedsettings"
                                    },
                                    {
                                        "title": "Output",
                                        "size": "Medium",
                                        "fields": [
                                            {
                                                "name": "seed",
                                                "label": "Seed",
                                                "type": "int",
                                                "lines": "single",
                                                "value": "512",
                                                "placeholder": "512",
                                                "min": "0",
                                                "max": "1000000",
                                                "required": true,
                                                "help": "Enter the seed value for randomizing the image generation.",
                                                "uuid": "fd088c14-b",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "imagesToGenerate",
                                                "label": "Images to Generate",
                                                "type": "int",
                                                "lines": "single",
                                                "value": "1",
                                                "placeholder": "1",
                                                "min": "0",
                                                "max": "8",
                                                "required": true,
                                                "help": "The amount of images to generate.",
                                                "uuid": "269a51a4-b",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "exportSize",
                                                "label": "Image Size",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": "",
                                                "options": [
                                                    "S 1024X1024",
                                                    "S 512X512",
                                                    "S 1920X1920",
                                                    "L 1920X1080",
                                                    "L 1280X720",
                                                    "L 1024X576",
                                                    "P 1080X1920",
                                                    "P 720X1280",
                                                    "P 576X1240"
                                                ],
                                                "value": "S 1024X1024",
                                                "required": false,
                                                "help": "Select the size of the generated image.",
                                                "min": "0",
                                                "max": "1000000",
                                                "uuid": "fc1db7cb-a",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "f4fd1905-e",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "output"
                                    }
                                ],
                                "automatic": [],
                                "hidden": false
                            }
                        ]
                    },
                    {
                        "id": "Ai.Image.Stablediffusion",
                        "sub": "Stablediffusion",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.Image",
                        "namesub": [
                            {
                                "id": "Ai.Image.Stablediffusion.Xllightning",
                                "sub": "Xllightning",
                                "type": "route",
                                "metadata": {},
                                "function": "\n                    () => {}\n                    ",
                                "parent": "Ai.Image.Stablediffusion",
                                "namesub": [
                                    {
                                        "id": "Ai.Image.Stablediffusion.Xllightning.Text2image",
                                        "sub": "XL Lightning: T2I",
                                        "type": "function",
                                        "metadata": {
                                            "name": "uai.builtin.stableDiffusion.XLLightning",
                                            "title": "XL Lightning: T2I",
                                            "path": "AI/Image/Stable Diffusion/XL Lightning/Text 2 Image",
                                            "category": "Image",
                                            "icon": "",
                                            "tags": [
                                                "Image",
                                                "Stable Diffusion",
                                                "XL Lightning",
                                                "Text2Image",
                                                "UAI"
                                            ],
                                            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
                                            "inputs": [
                                                "Text",
                                                "None"
                                            ],
                                            "outputs": [
                                                "Image"
                                            ],
                                            "panel": 2,
                                            "subwindows": [
                                                {
                                                    "title": "Basic Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "prompt",
                                                            "label": "Prompt",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "placeholder": "",
                                                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                            "required": true,
                                                            "help": "Enter the text prompt for generating the image.",
                                                            "uuid": "1dd420db-5",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "neg_prompt",
                                                            "label": "Negative Prompt",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "value": "bad quality. low resolution",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "Enter the negative text prompt for generating the image.",
                                                            "uuid": "cf1b1c6b-9",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "buttons": [],
                                                    "uuid": "8ba403fa-a",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "basicsettings"
                                                },
                                                {
                                                    "title": "Advanced Settings",
                                                    "size": "Small",
                                                    "fields": [
                                                        {
                                                            "name": "model",
                                                            "label": "AI Model",
                                                            "type": "select",
                                                            "lines": "single",
                                                            "placeholder": " ",
                                                            "options": [
                                                                "components.models.stablediffusion.xl"
                                                            ],
                                                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                                                            "required": false,
                                                            "help": "Select the AI Model to use for generating the image.",
                                                            "uuid": "b554c449-4",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "steps",
                                                            "label": "Steps",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "6",
                                                            "min": "0",
                                                            "max": "100",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "The amount of imastepsges to generate.",
                                                            "options": [
                                                                "components.models.stablediffusion.xl"
                                                            ],
                                                            "uuid": "427beb18-1",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "guidance_scale",
                                                            "label": "Config Scale",
                                                            "type": "float",
                                                            "lines": "single",
                                                            "value": "3",
                                                            "min": "0",
                                                            "max": "100",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "The configuration scale.",
                                                            "options": [
                                                                "components.models.stablediffusion.xl"
                                                            ],
                                                            "uuid": "ca3cf2a7-c",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "uuid": "e2a5191b-9",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "buttons": [],
                                                    "name": "advancedsettings"
                                                },
                                                {
                                                    "title": "Output",
                                                    "size": "Medium",
                                                    "fields": [
                                                        {
                                                            "name": "seed",
                                                            "label": "Seed",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "512",
                                                            "placeholder": "512",
                                                            "min": "0",
                                                            "max": "1000000",
                                                            "required": true,
                                                            "help": "Enter the seed value for randomizing the image generation.",
                                                            "uuid": "4188fff1-f",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "imagesToGenerate",
                                                            "label": "Images to Generate",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "1",
                                                            "placeholder": "1",
                                                            "min": "0",
                                                            "max": "8",
                                                            "required": true,
                                                            "help": "The amount of images to generate.",
                                                            "uuid": "e5abc139-4",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "exportSize",
                                                            "label": "Image Size",
                                                            "type": "select",
                                                            "lines": "single",
                                                            "placeholder": "",
                                                            "options": [
                                                                "S 1024X1024",
                                                                "S 512X512",
                                                                "S 1920X1920",
                                                                "L 1920X1080",
                                                                "L 1280X720",
                                                                "L 1024X576",
                                                                "P 1080X1920",
                                                                "P 720X1280",
                                                                "P 576X1240"
                                                            ],
                                                            "value": "S 1024X1024",
                                                            "required": false,
                                                            "help": "Select the size of the generated image.",
                                                            "min": "0",
                                                            "max": "1000000",
                                                            "uuid": "88ca5db0-8",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "uuid": "8f885bc6-5",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "buttons": [],
                                                    "name": "output"
                                                }
                                            ],
                                            "functions": [
                                                {
                                                    "name": "Generate",
                                                    "module": "stableDiffusion_",
                                                    "moduleType": "python",
                                                    "operation": "RunProcess",
                                                    "args": "",
                                                    "executable": "python.exe",
                                                    "url": "/",
                                                    "type": "submit",
                                                    "help": "Click to generate the image.",
                                                    "requestType": "ImageRequestConsole",
                                                    "responseType": "MultipleMediaRequest",
                                                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                                    "postFunction": "com.uai.showMultiImageOutput",
                                                    "icon": "fa-rocket",
                                                    "uuid": "29311d2a-7"
                                                },
                                                {
                                                    "name": "Generate",
                                                    "module": "com.uai.stableV.stableDiffusionXL.Lightning",
                                                    "moduleType": "com.uai.stableV",
                                                    "operation": "text2image",
                                                    "args": "",
                                                    "executable": "js",
                                                    "url": "https://uai.software/warp/api/v1/text/to/image/generate",
                                                    "type": "submit",
                                                    "help": "Click to generate the image.",
                                                    "requestType": "ImageRequestConsole",
                                                    "responseType": "MultipleMediaRequest",
                                                    "preFunction": "com.uai.createImageRequest",
                                                    "postFunction": "com.uai.showMultiImageOutput",
                                                    "icon": "fa-paint-brush",
                                                    "uuid": "29311d2a-9"
                                                }
                                            ],
                                            "uuid": "570537e7-7",
                                            "hidden": false,
                                            "automatic": [],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": false,
                                            "window": "AIGenerate"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Image/Stable Diffusion/XL Lightning/Text 2 Image\");\n                    }\n                    ",
                                        "parent": "Ai.Image.Stablediffusion.Xllightning",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Basic Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "prompt",
                                                        "label": "Prompt",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "placeholder": "",
                                                        "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                        "required": true,
                                                        "help": "Enter the text prompt for generating the image.",
                                                        "uuid": "1dd420db-5",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "neg_prompt",
                                                        "label": "Negative Prompt",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "value": "bad quality. low resolution",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "Enter the negative text prompt for generating the image.",
                                                        "uuid": "cf1b1c6b-9",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "buttons": [],
                                                "uuid": "8ba403fa-a",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "basicsettings"
                                            },
                                            {
                                                "title": "Advanced Settings",
                                                "size": "Small",
                                                "fields": [
                                                    {
                                                        "name": "model",
                                                        "label": "AI Model",
                                                        "type": "select",
                                                        "lines": "single",
                                                        "placeholder": " ",
                                                        "options": [
                                                            "components.models.stablediffusion.xl"
                                                        ],
                                                        "value": "SG161222/RealVisXL_V4.0_Lightning",
                                                        "required": false,
                                                        "help": "Select the AI Model to use for generating the image.",
                                                        "uuid": "b554c449-4",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "steps",
                                                        "label": "Steps",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "6",
                                                        "min": "0",
                                                        "max": "100",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "The amount of imastepsges to generate.",
                                                        "options": [
                                                            "components.models.stablediffusion.xl"
                                                        ],
                                                        "uuid": "427beb18-1",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "guidance_scale",
                                                        "label": "Config Scale",
                                                        "type": "float",
                                                        "lines": "single",
                                                        "value": "3",
                                                        "min": "0",
                                                        "max": "100",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "The configuration scale.",
                                                        "options": [
                                                            "components.models.stablediffusion.xl"
                                                        ],
                                                        "uuid": "ca3cf2a7-c",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "uuid": "e2a5191b-9",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "buttons": [],
                                                "name": "advancedsettings"
                                            },
                                            {
                                                "title": "Output",
                                                "size": "Medium",
                                                "fields": [
                                                    {
                                                        "name": "seed",
                                                        "label": "Seed",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "512",
                                                        "placeholder": "512",
                                                        "min": "0",
                                                        "max": "1000000",
                                                        "required": true,
                                                        "help": "Enter the seed value for randomizing the image generation.",
                                                        "uuid": "4188fff1-f",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "imagesToGenerate",
                                                        "label": "Images to Generate",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "1",
                                                        "placeholder": "1",
                                                        "min": "0",
                                                        "max": "8",
                                                        "required": true,
                                                        "help": "The amount of images to generate.",
                                                        "uuid": "e5abc139-4",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "exportSize",
                                                        "label": "Image Size",
                                                        "type": "select",
                                                        "lines": "single",
                                                        "placeholder": "",
                                                        "options": [
                                                            "S 1024X1024",
                                                            "S 512X512",
                                                            "S 1920X1920",
                                                            "L 1920X1080",
                                                            "L 1280X720",
                                                            "L 1024X576",
                                                            "P 1080X1920",
                                                            "P 720X1280",
                                                            "P 576X1240"
                                                        ],
                                                        "value": "S 1024X1024",
                                                        "required": false,
                                                        "help": "Select the size of the generated image.",
                                                        "min": "0",
                                                        "max": "1000000",
                                                        "uuid": "88ca5db0-8",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "uuid": "8f885bc6-5",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "buttons": [],
                                                "name": "output"
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": false
                                    },
                                    {
                                        "id": "Ai.Image.Stablediffusion.Xllightning.Photobooth",
                                        "sub": "SDXL : Photobooth",
                                        "type": "function",
                                        "metadata": {
                                            "name": "uai.builtin.stableDiffusion.xlphotobooth",
                                            "title": "SDXL : Photobooth",
                                            "path": "AI/Image/Stable Diffusion/XL Lightning/Photobooth",
                                            "category": "Image",
                                            "icon": "",
                                            "tags": [
                                                "Image",
                                                "Stable Diffusion",
                                                "XL",
                                                "Photobooth",
                                                "Image2Image",
                                                "UAI"
                                            ],
                                            "description": "Input a photo of a human and turn it into another photo of the same human..",
                                            "inputs": [
                                                "Text",
                                                "Image"
                                            ],
                                            "outputs": [
                                                "Image"
                                            ],
                                            "panel": 2,
                                            "subwindows": [
                                                {
                                                    "title": "Basic Settings",
                                                    "size": "Medium",
                                                    "fields": [
                                                        {
                                                            "name": "input",
                                                            "label": "Input Image",
                                                            "type": "file",
                                                            "lines": "single",
                                                            "objectType": "Image",
                                                            "base64": true,
                                                            "placeholder": "",
                                                            "value": "",
                                                            "required": true,
                                                            "help": "The image to process.",
                                                            "uuid": "a47d0e6e-d",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "prompt",
                                                            "label": "Prompt",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "placeholder": "",
                                                            "value": "1{GENDER} . A solo headshot photo of a {ETHNICITY} {GENDER} person school photo.  glamour photograph. wearing {YEAR} style clothing and {YEAR} style hair. {AGE}yearold 1{GENDER}.  single person portrait. close up. face in the center of the frame. headshot.  cool kids clothing from {YEAR}. Film Grain. Shot of Film. ",
                                                            "required": true,
                                                            "help": "Enter the text prompt for generating the image.",
                                                            "objectType": "Image",
                                                            "base64": true,
                                                            "uuid": "c52979a1-7",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "neg_prompt",
                                                            "label": "Negative Prompt",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "value": "Empty Space. Multiple panels. multiple photos. a photobook. Angry. multiple people. Not a school photo. not  {YEAR} style clothing. not {YEAR} style hair.  Many photos. many people. not a headshot. face not in the middle.  Neanderthal face. ugly face. High Contrast.",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "Enter the negative text prompt for generating the image.",
                                                            "objectType": "Image",
                                                            "base64": true,
                                                            "uuid": "59959e46-b",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "buttons": [],
                                                    "uuid": "f77ab96c-b",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "basicsettings"
                                                },
                                                {
                                                    "title": "Appearance",
                                                    "size": "Small",
                                                    "fields": [
                                                        {
                                                            "name": "ethnicity",
                                                            "label": "Ethnicity",
                                                            "type": "select",
                                                            "lines": "single",
                                                            "placeholder": " ",
                                                            "options": [
                                                                "African",
                                                                "African American",
                                                                "Asian",
                                                                "Caucasian",
                                                                "Hispanic",
                                                                "Indian",
                                                                "Middle Eastern",
                                                                "Multi-Racial",
                                                                "Native American",
                                                                "Pacific Islander",
                                                                "Other"
                                                            ],
                                                            "value": "Caucasian",
                                                            "required": false,
                                                            "help": "The Ethnicity of the person you want to generate.",
                                                            "uuid": "74a56966-6",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "gender",
                                                            "label": "Gender",
                                                            "type": "select",
                                                            "lines": "multi",
                                                            "value": "Female",
                                                            "placeholder": "",
                                                            "options": [
                                                                "Female",
                                                                "Male",
                                                                "Non-Binary",
                                                                "Other",
                                                                "Prefer Not to Say"
                                                            ],
                                                            "required": true,
                                                            "help": "The Gender of the person you want to generate.",
                                                            "uuid": "34a2ff92-c",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "scale",
                                                            "label": "Face Features Scale",
                                                            "type": "float",
                                                            "lines": "single",
                                                            "value": "0.9",
                                                            "min": "0",
                                                            "max": "5",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "How much to blend the generated AI facial features with the input image.",
                                                            "options": [
                                                                "African",
                                                                "African American",
                                                                "Asian",
                                                                "Caucasian",
                                                                "Hispanic",
                                                                "Indian",
                                                                "Middle Eastern",
                                                                "Multi-Racial",
                                                                "Native American",
                                                                "Pacific Islander",
                                                                "Other"
                                                            ],
                                                            "uuid": "68e9ce06-4",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "s_scale",
                                                            "label": "Face Shape Scale",
                                                            "type": "float",
                                                            "lines": "single",
                                                            "value": "0.8",
                                                            "min": "0",
                                                            "max": "5",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "How much to force the generated AI face to match the input face shape.",
                                                            "options": [
                                                                "African",
                                                                "African American",
                                                                "Asian",
                                                                "Caucasian",
                                                                "Hispanic",
                                                                "Indian",
                                                                "Middle Eastern",
                                                                "Multi-Racial",
                                                                "Native American",
                                                                "Pacific Islander",
                                                                "Other"
                                                            ],
                                                            "uuid": "b3df0d57-6",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "weight",
                                                            "label": "AI Face Fix Weight",
                                                            "type": "float",
                                                            "lines": "single",
                                                            "value": "0.8",
                                                            "min": "0",
                                                            "max": "1",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "How much to fix the generated AI face if it is very badly generated.",
                                                            "options": [
                                                                "African",
                                                                "African American",
                                                                "Asian",
                                                                "Caucasian",
                                                                "Hispanic",
                                                                "Indian",
                                                                "Middle Eastern",
                                                                "Multi-Racial",
                                                                "Native American",
                                                                "Pacific Islander",
                                                                "Other"
                                                            ],
                                                            "uuid": "bf13b701-6",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "buttons": [],
                                                    "uuid": "8159e67d-0",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "appearance"
                                                },
                                                {
                                                    "title": "Advanced Settings",
                                                    "size": "Small",
                                                    "fields": [
                                                        {
                                                            "name": "steps",
                                                            "label": "Steps",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "20",
                                                            "min": "0",
                                                            "max": "100",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "The amount of imastepsges to generate.",
                                                            "uuid": "9ac8973d-3",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "guidance_scale",
                                                            "label": "Config Scale",
                                                            "type": "float",
                                                            "lines": "single",
                                                            "value": "2.5",
                                                            "min": "0",
                                                            "max": "100",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "The configuration scale.",
                                                            "uuid": "de0c965c-c",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "aligned",
                                                            "label": "Face Aligned",
                                                            "type": "bool",
                                                            "lines": "single",
                                                            "value": "false",
                                                            "min": "0",
                                                            "max": "100",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "If the input image of the face has already been aligned by a face detector, set this to true. Otherwise, set it to false.",
                                                            "uuid": "4c660802-8",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "saveFaceEmbeddings",
                                                            "label": "Save Face Embeddings",
                                                            "type": "bool",
                                                            "lines": "single",
                                                            "objectType": "ai",
                                                            "placeholder": "",
                                                            "value": "false",
                                                            "required": true,
                                                            "help": "Save the face embeddings of the input image for future use.",
                                                            "min": "0",
                                                            "max": "100",
                                                            "uuid": "ffc7977b-5",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "uuid": "8f33f422-8",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "buttons": [],
                                                    "name": "advancedsettings"
                                                },
                                                {
                                                    "title": "Output",
                                                    "size": "Small",
                                                    "fields": [
                                                        {
                                                            "name": "seed",
                                                            "label": "Seed",
                                                            "type": "seed",
                                                            "lines": "single",
                                                            "value": "512",
                                                            "placeholder": "512",
                                                            "min": "0",
                                                            "max": "1000000",
                                                            "required": true,
                                                            "help": "Enter the seed value for randomizing the image generation.",
                                                            "uuid": "a6f9de64-c",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "num_images_per_prompt",
                                                            "label": "Images to Generate",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "1",
                                                            "placeholder": "1",
                                                            "min": "0",
                                                            "max": "8",
                                                            "required": true,
                                                            "help": "The amount of images to generate.",
                                                            "uuid": "53eabee4-9",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "exportSize",
                                                            "label": "Image Size",
                                                            "type": "select",
                                                            "lines": "single",
                                                            "placeholder": "",
                                                            "options": [
                                                                "S 1024X1024",
                                                                "S 512X512",
                                                                "S 1920X1920",
                                                                "L 1920X1080",
                                                                "L 1280X720",
                                                                "L 1024X576",
                                                                "P 1080X1920",
                                                                "P 720X1280",
                                                                "P 576X1240"
                                                            ],
                                                            "value": "S 1024X1024",
                                                            "required": false,
                                                            "help": "Select the size of the generated image.",
                                                            "min": "0",
                                                            "max": "1000000",
                                                            "uuid": "eff913bd-9",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "uuid": "dc855576-a",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "buttons": [],
                                                    "name": "output"
                                                }
                                            ],
                                            "functions": [
                                                {
                                                    "name": "Generate",
                                                    "module": "stableDiffusionXL_Photobooth",
                                                    "moduleType": "python",
                                                    "operation": "RunProcess",
                                                    "args": "",
                                                    "executable": "python.exe",
                                                    "url": "/image/stableDiffusion_",
                                                    "type": "submit",
                                                    "help": "Click to generate the image.",
                                                    "requestType": "ImageRequestConsole",
                                                    "responseType": "MultipleMediaRequest",
                                                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                                    "postFunction": "com.uai.showMultiImageOutput",
                                                    "icon": "fa-rocket",
                                                    "uuid": "6dd8af25-3"
                                                }
                                            ],
                                            "uuid": "6e7a04b3-8",
                                            "hidden": false,
                                            "automatic": [],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": true,
                                            "window": "AIGenerate"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Image/Stable Diffusion/XL Lightning/Photobooth\");\n                    }\n                    ",
                                        "parent": "Ai.Image.Stablediffusion.Xllightning",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Basic Settings",
                                                "size": "Medium",
                                                "fields": [
                                                    {
                                                        "name": "input",
                                                        "label": "Input Image",
                                                        "type": "file",
                                                        "lines": "single",
                                                        "objectType": "Image",
                                                        "base64": true,
                                                        "placeholder": "",
                                                        "value": "",
                                                        "required": true,
                                                        "help": "The image to process.",
                                                        "uuid": "a47d0e6e-d",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "prompt",
                                                        "label": "Prompt",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "placeholder": "",
                                                        "value": "1{GENDER} . A solo headshot photo of a {ETHNICITY} {GENDER} person school photo.  glamour photograph. wearing {YEAR} style clothing and {YEAR} style hair. {AGE}yearold 1{GENDER}.  single person portrait. close up. face in the center of the frame. headshot.  cool kids clothing from {YEAR}. Film Grain. Shot of Film. ",
                                                        "required": true,
                                                        "help": "Enter the text prompt for generating the image.",
                                                        "objectType": "Image",
                                                        "base64": true,
                                                        "uuid": "c52979a1-7",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "neg_prompt",
                                                        "label": "Negative Prompt",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "value": "Empty Space. Multiple panels. multiple photos. a photobook. Angry. multiple people. Not a school photo. not  {YEAR} style clothing. not {YEAR} style hair.  Many photos. many people. not a headshot. face not in the middle.  Neanderthal face. ugly face. High Contrast.",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "Enter the negative text prompt for generating the image.",
                                                        "objectType": "Image",
                                                        "base64": true,
                                                        "uuid": "59959e46-b",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "buttons": [],
                                                "uuid": "f77ab96c-b",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "basicsettings"
                                            },
                                            {
                                                "title": "Appearance",
                                                "size": "Small",
                                                "fields": [
                                                    {
                                                        "name": "ethnicity",
                                                        "label": "Ethnicity",
                                                        "type": "select",
                                                        "lines": "single",
                                                        "placeholder": " ",
                                                        "options": [
                                                            "African",
                                                            "African American",
                                                            "Asian",
                                                            "Caucasian",
                                                            "Hispanic",
                                                            "Indian",
                                                            "Middle Eastern",
                                                            "Multi-Racial",
                                                            "Native American",
                                                            "Pacific Islander",
                                                            "Other"
                                                        ],
                                                        "value": "Caucasian",
                                                        "required": false,
                                                        "help": "The Ethnicity of the person you want to generate.",
                                                        "uuid": "74a56966-6",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "gender",
                                                        "label": "Gender",
                                                        "type": "select",
                                                        "lines": "multi",
                                                        "value": "Female",
                                                        "placeholder": "",
                                                        "options": [
                                                            "Female",
                                                            "Male",
                                                            "Non-Binary",
                                                            "Other",
                                                            "Prefer Not to Say"
                                                        ],
                                                        "required": true,
                                                        "help": "The Gender of the person you want to generate.",
                                                        "uuid": "34a2ff92-c",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "scale",
                                                        "label": "Face Features Scale",
                                                        "type": "float",
                                                        "lines": "single",
                                                        "value": "0.9",
                                                        "min": "0",
                                                        "max": "5",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "How much to blend the generated AI facial features with the input image.",
                                                        "options": [
                                                            "African",
                                                            "African American",
                                                            "Asian",
                                                            "Caucasian",
                                                            "Hispanic",
                                                            "Indian",
                                                            "Middle Eastern",
                                                            "Multi-Racial",
                                                            "Native American",
                                                            "Pacific Islander",
                                                            "Other"
                                                        ],
                                                        "uuid": "68e9ce06-4",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "s_scale",
                                                        "label": "Face Shape Scale",
                                                        "type": "float",
                                                        "lines": "single",
                                                        "value": "0.8",
                                                        "min": "0",
                                                        "max": "5",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "How much to force the generated AI face to match the input face shape.",
                                                        "options": [
                                                            "African",
                                                            "African American",
                                                            "Asian",
                                                            "Caucasian",
                                                            "Hispanic",
                                                            "Indian",
                                                            "Middle Eastern",
                                                            "Multi-Racial",
                                                            "Native American",
                                                            "Pacific Islander",
                                                            "Other"
                                                        ],
                                                        "uuid": "b3df0d57-6",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "weight",
                                                        "label": "AI Face Fix Weight",
                                                        "type": "float",
                                                        "lines": "single",
                                                        "value": "0.8",
                                                        "min": "0",
                                                        "max": "1",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "How much to fix the generated AI face if it is very badly generated.",
                                                        "options": [
                                                            "African",
                                                            "African American",
                                                            "Asian",
                                                            "Caucasian",
                                                            "Hispanic",
                                                            "Indian",
                                                            "Middle Eastern",
                                                            "Multi-Racial",
                                                            "Native American",
                                                            "Pacific Islander",
                                                            "Other"
                                                        ],
                                                        "uuid": "bf13b701-6",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "buttons": [],
                                                "uuid": "8159e67d-0",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "appearance"
                                            },
                                            {
                                                "title": "Advanced Settings",
                                                "size": "Small",
                                                "fields": [
                                                    {
                                                        "name": "steps",
                                                        "label": "Steps",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "20",
                                                        "min": "0",
                                                        "max": "100",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "The amount of imastepsges to generate.",
                                                        "uuid": "9ac8973d-3",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "guidance_scale",
                                                        "label": "Config Scale",
                                                        "type": "float",
                                                        "lines": "single",
                                                        "value": "2.5",
                                                        "min": "0",
                                                        "max": "100",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "The configuration scale.",
                                                        "uuid": "de0c965c-c",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "aligned",
                                                        "label": "Face Aligned",
                                                        "type": "bool",
                                                        "lines": "single",
                                                        "value": "false",
                                                        "min": "0",
                                                        "max": "100",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "If the input image of the face has already been aligned by a face detector, set this to true. Otherwise, set it to false.",
                                                        "uuid": "4c660802-8",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "saveFaceEmbeddings",
                                                        "label": "Save Face Embeddings",
                                                        "type": "bool",
                                                        "lines": "single",
                                                        "objectType": "ai",
                                                        "placeholder": "",
                                                        "value": "false",
                                                        "required": true,
                                                        "help": "Save the face embeddings of the input image for future use.",
                                                        "min": "0",
                                                        "max": "100",
                                                        "uuid": "ffc7977b-5",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "uuid": "8f33f422-8",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "buttons": [],
                                                "name": "advancedsettings"
                                            },
                                            {
                                                "title": "Output",
                                                "size": "Small",
                                                "fields": [
                                                    {
                                                        "name": "seed",
                                                        "label": "Seed",
                                                        "type": "seed",
                                                        "lines": "single",
                                                        "value": "512",
                                                        "placeholder": "512",
                                                        "min": "0",
                                                        "max": "1000000",
                                                        "required": true,
                                                        "help": "Enter the seed value for randomizing the image generation.",
                                                        "uuid": "a6f9de64-c",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "num_images_per_prompt",
                                                        "label": "Images to Generate",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "1",
                                                        "placeholder": "1",
                                                        "min": "0",
                                                        "max": "8",
                                                        "required": true,
                                                        "help": "The amount of images to generate.",
                                                        "uuid": "53eabee4-9",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "exportSize",
                                                        "label": "Image Size",
                                                        "type": "select",
                                                        "lines": "single",
                                                        "placeholder": "",
                                                        "options": [
                                                            "S 1024X1024",
                                                            "S 512X512",
                                                            "S 1920X1920",
                                                            "L 1920X1080",
                                                            "L 1280X720",
                                                            "L 1024X576",
                                                            "P 1080X1920",
                                                            "P 720X1280",
                                                            "P 576X1240"
                                                        ],
                                                        "value": "S 1024X1024",
                                                        "required": false,
                                                        "help": "Select the size of the generated image.",
                                                        "min": "0",
                                                        "max": "1000000",
                                                        "uuid": "eff913bd-9",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "uuid": "dc855576-a",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "buttons": [],
                                                "name": "output"
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": false
                                    }
                                ]
                            },
                            {
                                "id": "Ai.Image.Stablediffusion.Xs512",
                                "sub": "Xs512",
                                "type": "route",
                                "metadata": {},
                                "function": "\n                    () => {}\n                    ",
                                "parent": "Ai.Image.Stablediffusion",
                                "namesub": [
                                    {
                                        "id": "Ai.Image.Stablediffusion.Xs512.Text2image",
                                        "sub": "SDXS 512: T2I",
                                        "type": "function",
                                        "metadata": {
                                            "name": "uai.builtin.stableDiffusion.xs512",
                                            "title": "SDXS 512: T2I",
                                            "path": "AI/Image/Stable Diffusion/XS512/Text 2 Image",
                                            "category": "Image",
                                            "icon": "",
                                            "tags": [
                                                "Image",
                                                "Stable Diffusion",
                                                "XS",
                                                "512",
                                                "Text2Image",
                                                "UAI"
                                            ],
                                            "description": "SDXS is a model that can generate high-resolution images in real-time based on prompt texts, trained using score distillation and feature matching.",
                                            "inputs": [
                                                "Text",
                                                "None"
                                            ],
                                            "outputs": [
                                                "Image"
                                            ],
                                            "panel": 2,
                                            "subwindows": [
                                                {
                                                    "title": "Basic Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "prompt",
                                                            "label": "Prompt",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "placeholder": "",
                                                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                            "required": true,
                                                            "help": "Enter the text prompt for generating the image.",
                                                            "uuid": "04f30a8d-d",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "neg_prompt",
                                                            "label": "Negative Prompt",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "value": "bad quality. low resolution",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "Enter the negative text prompt for generating the image.",
                                                            "uuid": "f62e19e2-e",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "buttons": [],
                                                    "uuid": "22a03d5d-5",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "basicsettings"
                                                },
                                                {
                                                    "title": "Advanced Settings",
                                                    "size": "Small",
                                                    "fields": [
                                                        {
                                                            "name": "steps",
                                                            "label": "Steps",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "1",
                                                            "min": "0",
                                                            "max": "100",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "The amount of imastepsges to generate.",
                                                            "uuid": "385a5ff5-7",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "guidance_scale",
                                                            "label": "Config Scale",
                                                            "type": "float",
                                                            "lines": "single",
                                                            "value": "0",
                                                            "min": "0",
                                                            "max": "100",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "The configuration scale.",
                                                            "uuid": "fc715668-6",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "uuid": "3a52dc42-3",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "buttons": [],
                                                    "name": "advancedsettings"
                                                },
                                                {
                                                    "title": "Output",
                                                    "size": "Medium",
                                                    "fields": [
                                                        {
                                                            "name": "seed",
                                                            "label": "Seed",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "512",
                                                            "placeholder": "512",
                                                            "min": "0",
                                                            "max": "1000000",
                                                            "required": true,
                                                            "help": "Enter the seed value for randomizing the image generation.",
                                                            "uuid": "584b4d99-6",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "num_images_per_prompt",
                                                            "label": "Images to Generate",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "1",
                                                            "placeholder": "1",
                                                            "min": "0",
                                                            "max": "8",
                                                            "required": true,
                                                            "help": "The amount of images to generate.",
                                                            "uuid": "2033a26f-a",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "uuid": "e1e77b5e-d",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "buttons": [],
                                                    "name": "output"
                                                }
                                            ],
                                            "functions": [
                                                {
                                                    "name": "Generate",
                                                    "module": "stableDiffusion_XS512",
                                                    "moduleType": "python",
                                                    "operation": "RunProcess",
                                                    "args": "",
                                                    "executable": "python.exe",
                                                    "url": "/image/stableDiffusion_",
                                                    "type": "submit",
                                                    "help": "Click to generate the image.",
                                                    "requestType": "ImageRequestConsole",
                                                    "responseType": "MultipleMediaRequest",
                                                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                                    "postFunction": "com.uai.showMultiImageOutput",
                                                    "icon": "fa-rocket",
                                                    "uuid": "024f4912-c"
                                                }
                                            ],
                                            "uuid": "3acf4678-5",
                                            "hidden": false,
                                            "automatic": [],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": true,
                                            "window": "AIGenerate"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Image/Stable Diffusion/XS512/Text 2 Image\");\n                    }\n                    ",
                                        "parent": "Ai.Image.Stablediffusion.Xs512",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Basic Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "prompt",
                                                        "label": "Prompt",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "placeholder": "",
                                                        "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                        "required": true,
                                                        "help": "Enter the text prompt for generating the image.",
                                                        "uuid": "04f30a8d-d",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "neg_prompt",
                                                        "label": "Negative Prompt",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "value": "bad quality. low resolution",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "Enter the negative text prompt for generating the image.",
                                                        "uuid": "f62e19e2-e",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "buttons": [],
                                                "uuid": "22a03d5d-5",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "basicsettings"
                                            },
                                            {
                                                "title": "Advanced Settings",
                                                "size": "Small",
                                                "fields": [
                                                    {
                                                        "name": "steps",
                                                        "label": "Steps",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "1",
                                                        "min": "0",
                                                        "max": "100",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "The amount of imastepsges to generate.",
                                                        "uuid": "385a5ff5-7",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "guidance_scale",
                                                        "label": "Config Scale",
                                                        "type": "float",
                                                        "lines": "single",
                                                        "value": "0",
                                                        "min": "0",
                                                        "max": "100",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "The configuration scale.",
                                                        "uuid": "fc715668-6",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "uuid": "3a52dc42-3",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "buttons": [],
                                                "name": "advancedsettings"
                                            },
                                            {
                                                "title": "Output",
                                                "size": "Medium",
                                                "fields": [
                                                    {
                                                        "name": "seed",
                                                        "label": "Seed",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "512",
                                                        "placeholder": "512",
                                                        "min": "0",
                                                        "max": "1000000",
                                                        "required": true,
                                                        "help": "Enter the seed value for randomizing the image generation.",
                                                        "uuid": "584b4d99-6",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "num_images_per_prompt",
                                                        "label": "Images to Generate",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "1",
                                                        "placeholder": "1",
                                                        "min": "0",
                                                        "max": "8",
                                                        "required": true,
                                                        "help": "The amount of images to generate.",
                                                        "uuid": "2033a26f-a",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "uuid": "e1e77b5e-d",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "buttons": [],
                                                "name": "output"
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": false
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": "Ai.Image.Facefix",
                        "sub": "Face Fix",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.image.augment.facefix",
                            "title": "Face Fix",
                            "path": "AI/Image/Face Fix",
                            "category": "Image",
                            "icon": "",
                            "tags": [
                                "Image",
                                "Augment",
                                "Face",
                                "UAI"
                            ],
                            "description": "Fix an AI generated face to look more realistic.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Image"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Basic Settings",
                                    "size": "Small",
                                    "fields": [
                                        {
                                            "name": "input",
                                            "label": "Input Image",
                                            "type": "file",
                                            "lines": "single",
                                            "objectType": "Image",
                                            "base64": true,
                                            "placeholder": "",
                                            "value": "",
                                            "required": true,
                                            "help": "The image to process.",
                                            "uuid": "818e8b8f-f",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "weight",
                                            "label": "Blend",
                                            "type": "float",
                                            "lines": "single",
                                            "value": "0.8",
                                            "min": "0",
                                            "max": "1",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "How much to blend the final augmented face with the original face.",
                                            "objectType": "Image",
                                            "base64": true,
                                            "uuid": "84b2f5cb-5",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "2ddcb432-8",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "basicsettings"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Generate",
                                    "module": "config/uai/image/faceFix/index",
                                    "moduleType": "lua",
                                    "operation": "RunProcess",
                                    "args": "",
                                    "executable": "python.exe",
                                    "url": "/image/stableDiffusion_",
                                    "type": "submit",
                                    "help": "Click to generate the image.",
                                    "requestType": "ImageRequestConsole",
                                    "responseType": "MultipleMediaRequest",
                                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                    "postFunction": "com.uai.showMultiMediaImage",
                                    "icon": "fa-rocket",
                                    "uuid": "d5f5180b-2"
                                }
                            ],
                            "uuid": "fa555bed-5",
                            "hidden": false,
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": false,
                            "toggleable": true,
                            "isFX": true,
                            "window": "AIGenerate"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Image/Face Fix\");\n                    }\n                    ",
                        "parent": "Ai.Image",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Basic Settings",
                                "size": "Small",
                                "fields": [
                                    {
                                        "name": "input",
                                        "label": "Input Image",
                                        "type": "file",
                                        "lines": "single",
                                        "objectType": "Image",
                                        "base64": true,
                                        "placeholder": "",
                                        "value": "",
                                        "required": true,
                                        "help": "The image to process.",
                                        "uuid": "818e8b8f-f",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "weight",
                                        "label": "Blend",
                                        "type": "float",
                                        "lines": "single",
                                        "value": "0.8",
                                        "min": "0",
                                        "max": "1",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "How much to blend the final augmented face with the original face.",
                                        "objectType": "Image",
                                        "base64": true,
                                        "uuid": "84b2f5cb-5",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "2ddcb432-8",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "basicsettings"
                            }
                        ],
                        "automatic": [],
                        "hidden": false
                    }
                ]
            },
            {
                "id": "Ai.Video",
                "sub": "Video",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "Ai",
                "namesub": [
                    {
                        "id": "Ai.Video.Generate",
                        "sub": "Generate",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.Video",
                        "namesub": [
                            {
                                "id": "Ai.Video.Generate.Testrun",
                                "sub": "XL Lightning: T2I",
                                "type": "function",
                                "metadata": {
                                    "name": "uai.builtin.video.generate.test",
                                    "title": "XL Lightning: T2I",
                                    "path": "AI/Video/Generate/TestRun",
                                    "category": "Image",
                                    "icon": "",
                                    "tags": [
                                        "Image",
                                        "Stable Diffusion",
                                        "XL Lightning",
                                        "Text2Image",
                                        "UAI"
                                    ],
                                    "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
                                    "inputs": [
                                        "Text",
                                        "None"
                                    ],
                                    "outputs": [
                                        "Image"
                                    ],
                                    "panel": 2,
                                    "subwindows": [
                                        {
                                            "title": "Basic Settings",
                                            "size": "Large",
                                            "fields": [
                                                {
                                                    "name": "prompt",
                                                    "label": "Prompt",
                                                    "type": "string",
                                                    "lines": "multi",
                                                    "placeholder": "",
                                                    "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                    "required": true,
                                                    "help": "Enter the text prompt for generating the image.",
                                                    "uuid": "0877ce6c-d",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "neg_prompt",
                                                    "label": "Negative Prompt",
                                                    "type": "string",
                                                    "lines": "multi",
                                                    "value": "bad quality. low resolution",
                                                    "placeholder": "",
                                                    "required": true,
                                                    "help": "Enter the negative text prompt for generating the image.",
                                                    "uuid": "45a57333-b",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "buttons": [],
                                            "uuid": "2f18d600-0",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "name": "basicsettings"
                                        },
                                        {
                                            "title": "Advanced Settings",
                                            "size": "Small",
                                            "fields": [
                                                {
                                                    "name": "model",
                                                    "label": "AI Model",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": " ",
                                                    "options": [
                                                        "components.models.stablediffusion.xl"
                                                    ],
                                                    "value": "SG161222/RealVisXL_V4.0_Lightning",
                                                    "required": false,
                                                    "help": "Select the AI Model to use for generating the image.",
                                                    "uuid": "902d7724-8",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "steps",
                                                    "label": "Steps",
                                                    "type": "int",
                                                    "lines": "single",
                                                    "value": "6",
                                                    "min": "0",
                                                    "max": "100",
                                                    "placeholder": "",
                                                    "required": true,
                                                    "help": "The amount of imastepsges to generate.",
                                                    "options": [
                                                        "components.models.stablediffusion.xl"
                                                    ],
                                                    "uuid": "18f771ef-c",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "guidance_scale",
                                                    "label": "Config Scale",
                                                    "type": "float",
                                                    "lines": "single",
                                                    "value": "3",
                                                    "min": "0",
                                                    "max": "100",
                                                    "placeholder": "",
                                                    "required": true,
                                                    "help": "The configuration scale.",
                                                    "options": [
                                                        "components.models.stablediffusion.xl"
                                                    ],
                                                    "uuid": "6af98582-b",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "94329ec3-a",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "advancedsettings"
                                        },
                                        {
                                            "title": "Output",
                                            "size": "Medium",
                                            "fields": [
                                                {
                                                    "name": "seed",
                                                    "label": "Seed",
                                                    "type": "int",
                                                    "lines": "single",
                                                    "value": "512",
                                                    "placeholder": "512",
                                                    "min": "0",
                                                    "max": "1000000",
                                                    "required": true,
                                                    "help": "Enter the seed value for randomizing the image generation.",
                                                    "uuid": "4655bd8e-8",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "imagesToGenerate",
                                                    "label": "Images to Generate",
                                                    "type": "int",
                                                    "lines": "single",
                                                    "value": "1",
                                                    "placeholder": "1",
                                                    "min": "0",
                                                    "max": "8",
                                                    "required": true,
                                                    "help": "The amount of images to generate.",
                                                    "uuid": "2a06b4fb-e",
                                                    "options": [],
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                },
                                                {
                                                    "name": "exportSize",
                                                    "label": "Image Size",
                                                    "type": "select",
                                                    "lines": "single",
                                                    "placeholder": "",
                                                    "options": [
                                                        "S 1024X1024",
                                                        "S 512X512",
                                                        "S 1920X1920",
                                                        "L 1920X1080",
                                                        "L 1280X720",
                                                        "L 1024X576",
                                                        "P 1080X1920",
                                                        "P 720X1280",
                                                        "P 576X1240"
                                                    ],
                                                    "value": "S 1024X1024",
                                                    "required": false,
                                                    "help": "Select the size of the generated image.",
                                                    "min": "0",
                                                    "max": "1000000",
                                                    "uuid": "03fc4198-9",
                                                    "keyframeable": true,
                                                    "keyframeType": "all"
                                                }
                                            ],
                                            "uuid": "a82f567b-c",
                                            "enabled": true,
                                            "toggleable": true,
                                            "initialState": false,
                                            "buttons": [],
                                            "name": "output"
                                        }
                                    ],
                                    "functions": [
                                        {
                                            "name": "Generate",
                                            "module": "testRun_Video",
                                            "moduleType": "python",
                                            "operation": "RunProcess",
                                            "args": "",
                                            "executable": "python.exe",
                                            "url": "/image/stableDiffusion_",
                                            "type": "submit",
                                            "help": "Click to generate the image.",
                                            "requestType": "ImageRequestConsole",
                                            "responseType": "MultipleMediaRequest",
                                            "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                            "postFunction": "com.uai.showMultiVideoOutput",
                                            "icon": "fa-rocket",
                                            "uuid": "b80a1755-4"
                                        }
                                    ],
                                    "uuid": "d71be2c1-3",
                                    "hidden": false,
                                    "automatic": [],
                                    "documentation": "https://uai.software/docs",
                                    "initialState": false,
                                    "toggleable": true,
                                    "isFX": true,
                                    "window": "AIGenerate"
                                },
                                "function": "\n                    () => {\n                        onModuleRun(\"AI/Video/Generate/TestRun\");\n                    }\n                    ",
                                "parent": "Ai.Video.Generate",
                                "namesub": [],
                                "subwindows": [
                                    {
                                        "title": "Basic Settings",
                                        "size": "Large",
                                        "fields": [
                                            {
                                                "name": "prompt",
                                                "label": "Prompt",
                                                "type": "string",
                                                "lines": "multi",
                                                "placeholder": "",
                                                "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                "required": true,
                                                "help": "Enter the text prompt for generating the image.",
                                                "uuid": "0877ce6c-d",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "neg_prompt",
                                                "label": "Negative Prompt",
                                                "type": "string",
                                                "lines": "multi",
                                                "value": "bad quality. low resolution",
                                                "placeholder": "",
                                                "required": true,
                                                "help": "Enter the negative text prompt for generating the image.",
                                                "uuid": "45a57333-b",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "buttons": [],
                                        "uuid": "2f18d600-0",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "name": "basicsettings"
                                    },
                                    {
                                        "title": "Advanced Settings",
                                        "size": "Small",
                                        "fields": [
                                            {
                                                "name": "model",
                                                "label": "AI Model",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": " ",
                                                "options": [
                                                    "components.models.stablediffusion.xl"
                                                ],
                                                "value": "SG161222/RealVisXL_V4.0_Lightning",
                                                "required": false,
                                                "help": "Select the AI Model to use for generating the image.",
                                                "uuid": "902d7724-8",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "steps",
                                                "label": "Steps",
                                                "type": "int",
                                                "lines": "single",
                                                "value": "6",
                                                "min": "0",
                                                "max": "100",
                                                "placeholder": "",
                                                "required": true,
                                                "help": "The amount of imastepsges to generate.",
                                                "options": [
                                                    "components.models.stablediffusion.xl"
                                                ],
                                                "uuid": "18f771ef-c",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "guidance_scale",
                                                "label": "Config Scale",
                                                "type": "float",
                                                "lines": "single",
                                                "value": "3",
                                                "min": "0",
                                                "max": "100",
                                                "placeholder": "",
                                                "required": true,
                                                "help": "The configuration scale.",
                                                "options": [
                                                    "components.models.stablediffusion.xl"
                                                ],
                                                "uuid": "6af98582-b",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "94329ec3-a",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "advancedsettings"
                                    },
                                    {
                                        "title": "Output",
                                        "size": "Medium",
                                        "fields": [
                                            {
                                                "name": "seed",
                                                "label": "Seed",
                                                "type": "int",
                                                "lines": "single",
                                                "value": "512",
                                                "placeholder": "512",
                                                "min": "0",
                                                "max": "1000000",
                                                "required": true,
                                                "help": "Enter the seed value for randomizing the image generation.",
                                                "uuid": "4655bd8e-8",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "imagesToGenerate",
                                                "label": "Images to Generate",
                                                "type": "int",
                                                "lines": "single",
                                                "value": "1",
                                                "placeholder": "1",
                                                "min": "0",
                                                "max": "8",
                                                "required": true,
                                                "help": "The amount of images to generate.",
                                                "uuid": "2a06b4fb-e",
                                                "options": [],
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            },
                                            {
                                                "name": "exportSize",
                                                "label": "Image Size",
                                                "type": "select",
                                                "lines": "single",
                                                "placeholder": "",
                                                "options": [
                                                    "S 1024X1024",
                                                    "S 512X512",
                                                    "S 1920X1920",
                                                    "L 1920X1080",
                                                    "L 1280X720",
                                                    "L 1024X576",
                                                    "P 1080X1920",
                                                    "P 720X1280",
                                                    "P 576X1240"
                                                ],
                                                "value": "S 1024X1024",
                                                "required": false,
                                                "help": "Select the size of the generated image.",
                                                "min": "0",
                                                "max": "1000000",
                                                "uuid": "03fc4198-9",
                                                "keyframeable": true,
                                                "keyframeType": "all"
                                            }
                                        ],
                                        "uuid": "a82f567b-c",
                                        "enabled": true,
                                        "toggleable": true,
                                        "initialState": false,
                                        "buttons": [],
                                        "name": "output"
                                    }
                                ],
                                "automatic": [],
                                "hidden": false
                            }
                        ]
                    },
                    {
                        "id": "Ai.Video.Animatediff",
                        "sub": "Animatediff",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.Video",
                        "namesub": [
                            {
                                "id": "Ai.Video.Animatediff.Lightning",
                                "sub": "Lightning",
                                "type": "route",
                                "metadata": {},
                                "function": "\n                    () => {}\n                    ",
                                "parent": "Ai.Video.Animatediff",
                                "namesub": [
                                    {
                                        "id": "Ai.Video.Animatediff.Lightning.Text2video",
                                        "sub": "Animate Diff Lightning",
                                        "type": "function",
                                        "metadata": {
                                            "name": "uai.builtin.animatediff.Lightning.text2video",
                                            "title": "Animate Diff Lightning",
                                            "path": "AI/Video/Animate Diff/Lightning/Text 2 Video",
                                            "category": "Video",
                                            "tags": [
                                                "Video",
                                                "Animate Diff",
                                                "Lightning",
                                                "SD1.5",
                                                "UAI"
                                            ],
                                            "description": "This module generates an animated video  using the Animate Diff method at a rather quick sped.",
                                            "inputs": [
                                                "Text",
                                                "None"
                                            ],
                                            "outputs": [
                                                "Video"
                                            ],
                                            "panel": 2,
                                            "subwindows": [
                                                {
                                                    "title": "Basic Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "prompt",
                                                            "label": "Prompt",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "placeholder": "",
                                                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                            "required": true,
                                                            "help": "Enter the text prompt for generating the image.",
                                                            "uuid": "cc039d67-5",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "neg_prompt",
                                                            "label": "Negative Prompt",
                                                            "type": "string",
                                                            "lines": "multi",
                                                            "value": "bad quality. low resolution",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "Enter the negative text prompt for generating the image.",
                                                            "uuid": "83142876-7",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "buttons": [],
                                                    "uuid": "5a54b3e4-3",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "basicsettings"
                                                },
                                                {
                                                    "title": "Advanced Settings",
                                                    "size": "Small",
                                                    "fields": [
                                                        {
                                                            "name": "steps",
                                                            "label": "Steps",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "8",
                                                            "min": "1",
                                                            "max": "200",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "The amount of imastepsges to generate.",
                                                            "uuid": "0b9d9ea3-6",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "guidance_scale",
                                                            "label": "Config Scale",
                                                            "type": "float",
                                                            "lines": "single",
                                                            "value": "5",
                                                            "min": "1",
                                                            "max": "80",
                                                            "placeholder": "",
                                                            "required": true,
                                                            "help": "The configuration scale.",
                                                            "uuid": "32fa4350-4",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "encodeSize",
                                                            "label": "Encoding Frame Size",
                                                            "type": "select",
                                                            "lines": "single",
                                                            "placeholder": "S 512X512",
                                                            "options": [
                                                                "S 512X512",
                                                                "P 576X1240"
                                                            ],
                                                            "value": "S 512X512",
                                                            "required": false,
                                                            "help": "Select the size of the generated image.",
                                                            "min": "1",
                                                            "max": "200",
                                                            "uuid": "abe32a7b-f",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "encodedFps",
                                                            "label": "Encoding Frames Per Second",
                                                            "type": "float",
                                                            "lines": "single",
                                                            "value": "8",
                                                            "placeholder": "8",
                                                            "min": "1",
                                                            "max": "80",
                                                            "required": true,
                                                            "help": "Enter the frames per second for the generated frames.",
                                                            "uuid": "3c23683a-1",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "uuid": "f0772379-a",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "buttons": [],
                                                    "name": "advancedsettings"
                                                },
                                                {
                                                    "title": "Output",
                                                    "size": "Small",
                                                    "fields": [
                                                        {
                                                            "name": "seed",
                                                            "label": "Seed",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "512",
                                                            "placeholder": "512",
                                                            "min": "1",
                                                            "max": "1065061510",
                                                            "required": true,
                                                            "help": "Enter the seed value for randomizing the image generation.",
                                                            "uuid": "29106076-0",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "imagesToGenerate",
                                                            "label": "Frames to Generate",
                                                            "type": "int",
                                                            "lines": "single",
                                                            "value": "16",
                                                            "min": "1",
                                                            "max": "80",
                                                            "placeholder": "1",
                                                            "required": true,
                                                            "help": "The amount of frames to generate.",
                                                            "uuid": "87bdb480-4",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "fps",
                                                            "label": "Frames Per Second",
                                                            "type": "float",
                                                            "lines": "single",
                                                            "value": "24",
                                                            "placeholder": "24",
                                                            "required": true,
                                                            "help": "Enter the frames per second for the video.",
                                                            "min": "1",
                                                            "max": "1065061510",
                                                            "uuid": "39e2f243-a",
                                                            "options": [],
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        },
                                                        {
                                                            "name": "exportSize",
                                                            "label": "Frame Size",
                                                            "type": "select",
                                                            "lines": "single",
                                                            "placeholder": " 1024x1024",
                                                            "options": [
                                                                "S 1024X1024",
                                                                "S 512X512",
                                                                "S 1920x1920",
                                                                "L 1920X1080",
                                                                "L 1280X720",
                                                                "L 1024X576",
                                                                "P 1080X1920",
                                                                "P 720X1280",
                                                                "P 576X1240"
                                                            ],
                                                            "value": "S 1024x1024",
                                                            "required": false,
                                                            "help": "Select the size of the generated image.",
                                                            "min": "1",
                                                            "max": "1065061510",
                                                            "uuid": "3284c8da-5",
                                                            "keyframeable": true,
                                                            "keyframeType": "all"
                                                        }
                                                    ],
                                                    "uuid": "a49c9326-2",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "buttons": [],
                                                    "name": "output"
                                                }
                                            ],
                                            "functions": [
                                                {
                                                    "name": "Generate",
                                                    "moduleType": "python",
                                                    "module": "animateDiff",
                                                    "executeable": "python.exe",
                                                    "args": "",
                                                    "operation": "RunProcess",
                                                    "url": "/image/animateDiff",
                                                    "type": "submit",
                                                    "help": "Click to generate the video.",
                                                    "requestType": "ImageRequestConsole",
                                                    "responseType": "MultipleMediaRequest",
                                                    "preFunction": "com.uai.stableV.animateDiff.Lightning.text2video",
                                                    "postFunction": "com.uai.showMultiVideoOutput",
                                                    "icon": "fa-rocket",
                                                    "uuid": "0a66a487-8",
                                                    "executable": "internal.3d"
                                                }
                                            ],
                                            "uuid": "2cdb0ba2-e",
                                            "icon": "",
                                            "hidden": false,
                                            "automatic": [],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": true,
                                            "window": "AIGenerate"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Video/Animate Diff/Lightning/Text 2 Video\");\n                    }\n                    ",
                                        "parent": "Ai.Video.Animatediff.Lightning",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Basic Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "prompt",
                                                        "label": "Prompt",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "placeholder": "",
                                                        "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                                        "required": true,
                                                        "help": "Enter the text prompt for generating the image.",
                                                        "uuid": "cc039d67-5",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "neg_prompt",
                                                        "label": "Negative Prompt",
                                                        "type": "string",
                                                        "lines": "multi",
                                                        "value": "bad quality. low resolution",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "Enter the negative text prompt for generating the image.",
                                                        "uuid": "83142876-7",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "buttons": [],
                                                "uuid": "5a54b3e4-3",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "basicsettings"
                                            },
                                            {
                                                "title": "Advanced Settings",
                                                "size": "Small",
                                                "fields": [
                                                    {
                                                        "name": "steps",
                                                        "label": "Steps",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "8",
                                                        "min": "1",
                                                        "max": "200",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "The amount of imastepsges to generate.",
                                                        "uuid": "0b9d9ea3-6",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "guidance_scale",
                                                        "label": "Config Scale",
                                                        "type": "float",
                                                        "lines": "single",
                                                        "value": "5",
                                                        "min": "1",
                                                        "max": "80",
                                                        "placeholder": "",
                                                        "required": true,
                                                        "help": "The configuration scale.",
                                                        "uuid": "32fa4350-4",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "encodeSize",
                                                        "label": "Encoding Frame Size",
                                                        "type": "select",
                                                        "lines": "single",
                                                        "placeholder": "S 512X512",
                                                        "options": [
                                                            "S 512X512",
                                                            "P 576X1240"
                                                        ],
                                                        "value": "S 512X512",
                                                        "required": false,
                                                        "help": "Select the size of the generated image.",
                                                        "min": "1",
                                                        "max": "200",
                                                        "uuid": "abe32a7b-f",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "encodedFps",
                                                        "label": "Encoding Frames Per Second",
                                                        "type": "float",
                                                        "lines": "single",
                                                        "value": "8",
                                                        "placeholder": "8",
                                                        "min": "1",
                                                        "max": "80",
                                                        "required": true,
                                                        "help": "Enter the frames per second for the generated frames.",
                                                        "uuid": "3c23683a-1",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "uuid": "f0772379-a",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "buttons": [],
                                                "name": "advancedsettings"
                                            },
                                            {
                                                "title": "Output",
                                                "size": "Small",
                                                "fields": [
                                                    {
                                                        "name": "seed",
                                                        "label": "Seed",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "512",
                                                        "placeholder": "512",
                                                        "min": "1",
                                                        "max": "1065061510",
                                                        "required": true,
                                                        "help": "Enter the seed value for randomizing the image generation.",
                                                        "uuid": "29106076-0",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "imagesToGenerate",
                                                        "label": "Frames to Generate",
                                                        "type": "int",
                                                        "lines": "single",
                                                        "value": "16",
                                                        "min": "1",
                                                        "max": "80",
                                                        "placeholder": "1",
                                                        "required": true,
                                                        "help": "The amount of frames to generate.",
                                                        "uuid": "87bdb480-4",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "fps",
                                                        "label": "Frames Per Second",
                                                        "type": "float",
                                                        "lines": "single",
                                                        "value": "24",
                                                        "placeholder": "24",
                                                        "required": true,
                                                        "help": "Enter the frames per second for the video.",
                                                        "min": "1",
                                                        "max": "1065061510",
                                                        "uuid": "39e2f243-a",
                                                        "options": [],
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    },
                                                    {
                                                        "name": "exportSize",
                                                        "label": "Frame Size",
                                                        "type": "select",
                                                        "lines": "single",
                                                        "placeholder": " 1024x1024",
                                                        "options": [
                                                            "S 1024X1024",
                                                            "S 512X512",
                                                            "S 1920x1920",
                                                            "L 1920X1080",
                                                            "L 1280X720",
                                                            "L 1024X576",
                                                            "P 1080X1920",
                                                            "P 720X1280",
                                                            "P 576X1240"
                                                        ],
                                                        "value": "S 1024x1024",
                                                        "required": false,
                                                        "help": "Select the size of the generated image.",
                                                        "min": "1",
                                                        "max": "1065061510",
                                                        "uuid": "3284c8da-5",
                                                        "keyframeable": true,
                                                        "keyframeType": "all"
                                                    }
                                                ],
                                                "uuid": "a49c9326-2",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "buttons": [],
                                                "name": "output"
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": false
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "id": "Ai.Video.Lipsync",
                        "sub": "Lipsync",
                        "type": "route",
                        "metadata": {},
                        "function": "\n                    () => {}\n                    ",
                        "parent": "Ai.Video",
                        "namesub": [
                            {
                                "id": "Ai.Video.Lipsync.Retalker",
                                "sub": "Retalker",
                                "type": "route",
                                "metadata": {},
                                "function": "\n                    () => {}\n                    ",
                                "parent": "Ai.Video.Lipsync",
                                "namesub": [
                                    {
                                        "id": "Ai.Video.Lipsync.Retalker.Audiodrivenlipsync",
                                        "sub": "ReTalker: Audio Driven Lipsync",
                                        "type": "function",
                                        "metadata": {
                                            "uuid": "7b747cd4-5245-4afe-9371-7c662a6e32e3",
                                            "category": "Transform",
                                            "icon": "",
                                            "hidden": false,
                                            "subwindows": [
                                                {
                                                    "title": "Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "image_input",
                                                            "label": "Video",
                                                            "placeholder": "",
                                                            "type": "file",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "ad757d4d-452e-4c5b-a5b4-6bc14e53b466",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "audio_input",
                                                            "label": "Audio",
                                                            "placeholder": "",
                                                            "type": "file",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {
                                                                "type": "\"filepath\""
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "ae6ee0d2-14c1-44be-9391-469ebc09b946",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "tts_button",
                                                            "label": "",
                                                            "placeholder": "",
                                                            "type": "button",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "69dd2b59-e386-4c7c-8b98-817ccd38519d",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "idleAnimationOutput",
                                                            "label": "Animation Output",
                                                            "placeholder": "",
                                                            "type": "file",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "c167821a-b974-4161-b8ea-462b2e0ec0a3",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        }
                                                    ],
                                                    "uuid": "c55dabef-4",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "settings",
                                                    "buttons": []
                                                }
                                            ],
                                            "name": "uai.extension.retalker.audio",
                                            "title": "ReTalker: Audio Driven Lipsync",
                                            "path": "AI/Video/Lipsync/ReTalker/Audio Driven Lipsync",
                                            "automatic": [],
                                            "tags": [
                                                "audio",
                                                " lipsync"
                                            ],
                                            "description": "Drive a video's lipsync with an audio file",
                                            "inputs": [],
                                            "outputs": [],
                                            "panel": 2,
                                            "functions": [
                                                {
                                                    "name": "Run",
                                                    "module": "internal.3d",
                                                    "moduleType": "internal.3d",
                                                    "operation": "setTransform",
                                                    "args": "",
                                                    "executable": "internal.3d",
                                                    "url": "/image/stableDiffusion_",
                                                    "type": "update",
                                                    "help": "Process the 3D Transform Matrix.",
                                                    "requestType": "fx.properties",
                                                    "responseType": "",
                                                    "preFunction": "",
                                                    "postFunction": "",
                                                    "icon": "fa-cube",
                                                    "uuid": "d73d58e8-0287-4cb0-8ca4-a6cd882d1b32"
                                                }
                                            ],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": false,
                                            "window": "FX"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Video/Lipsync/ReTalker/Audio Driven Lipsync\");\n                    }\n                    ",
                                        "parent": "Ai.Video.Lipsync.Retalker",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "image_input",
                                                        "label": "Video",
                                                        "placeholder": "",
                                                        "type": "file",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "ad757d4d-452e-4c5b-a5b4-6bc14e53b466",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "audio_input",
                                                        "label": "Audio",
                                                        "placeholder": "",
                                                        "type": "file",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {
                                                            "type": "\"filepath\""
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "ae6ee0d2-14c1-44be-9391-469ebc09b946",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "tts_button",
                                                        "label": "",
                                                        "placeholder": "",
                                                        "type": "button",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "69dd2b59-e386-4c7c-8b98-817ccd38519d",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "idleAnimationOutput",
                                                        "label": "Animation Output",
                                                        "placeholder": "",
                                                        "type": "file",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "c167821a-b974-4161-b8ea-462b2e0ec0a3",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    }
                                                ],
                                                "uuid": "c55dabef-4",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "settings",
                                                "buttons": []
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": false
                                    },
                                    {
                                        "id": "Ai.Video.Lipsync.Retalker.Ttsanimator",
                                        "sub": "ReTalker: TTS Driven Lipsync",
                                        "type": "function",
                                        "metadata": {
                                            "uuid": "e6cba1cb-2b8c-4075-b931-882785c4eea9",
                                            "category": "Transform",
                                            "icon": "",
                                            "hidden": false,
                                            "subwindows": [
                                                {
                                                    "title": "Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "image_input",
                                                            "label": "Video",
                                                            "placeholder": "",
                                                            "type": "file",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "2029a928-ff46-4c22-a870-96b25cf39fc4",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "text",
                                                            "label": "Text",
                                                            "placeholder": "",
                                                            "type": "string",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {
                                                                "placeholder": "\"What",
                                                                "lines": "1"
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "3ec29a73-654b-43df-8708-5dd06761ee22",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "tts_button",
                                                            "label": "",
                                                            "placeholder": "",
                                                            "type": "button",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "c4c28382-201e-49d0-b89a-194629b3ba71",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "idleAnimationOutput",
                                                            "label": "Animation Output",
                                                            "placeholder": "",
                                                            "type": "file",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "dbdfaeb1-6091-4661-af61-293d23d45b12",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        }
                                                    ],
                                                    "uuid": "d7443ab6-a",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "settings",
                                                    "buttons": []
                                                }
                                            ],
                                            "name": "uai.extension.retalker.tts",
                                            "title": "ReTalker: TTS Driven Lipsync",
                                            "path": "AI/Video/Lipsync/ReTalker/TTS Animator",
                                            "automatic": [],
                                            "tags": [
                                                "audio",
                                                "lipsync"
                                            ],
                                            "description": "Drive a video's lipsync with ReTalker",
                                            "inputs": [],
                                            "outputs": [],
                                            "panel": 2,
                                            "functions": [
                                                {
                                                    "name": "Run",
                                                    "module": "internal.3d",
                                                    "moduleType": "internal.3d",
                                                    "operation": "setTransform",
                                                    "args": "",
                                                    "executable": "internal.3d",
                                                    "url": "/image/stableDiffusion_",
                                                    "type": "update",
                                                    "help": "Process the 3D Transform Matrix.",
                                                    "requestType": "fx.properties",
                                                    "responseType": "",
                                                    "preFunction": "",
                                                    "postFunction": "",
                                                    "icon": "fa-cube",
                                                    "uuid": "179ffd61-cba2-4c8b-8bbf-5e1934d84576"
                                                }
                                            ],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": false,
                                            "window": "FX"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Video/Lipsync/ReTalker/TTS Animator\");\n                    }\n                    ",
                                        "parent": "Ai.Video.Lipsync.Retalker",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "image_input",
                                                        "label": "Video",
                                                        "placeholder": "",
                                                        "type": "file",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "2029a928-ff46-4c22-a870-96b25cf39fc4",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "text",
                                                        "label": "Text",
                                                        "placeholder": "",
                                                        "type": "string",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {
                                                            "placeholder": "\"What",
                                                            "lines": "1"
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "3ec29a73-654b-43df-8708-5dd06761ee22",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "tts_button",
                                                        "label": "",
                                                        "placeholder": "",
                                                        "type": "button",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "c4c28382-201e-49d0-b89a-194629b3ba71",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "idleAnimationOutput",
                                                        "label": "Animation Output",
                                                        "placeholder": "",
                                                        "type": "file",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "dbdfaeb1-6091-4661-af61-293d23d45b12",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    }
                                                ],
                                                "uuid": "d7443ab6-a",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "settings",
                                                "buttons": []
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": false
                                    }
                                ]
                            },
                            {
                                "id": "Ai.Video.Lipsync.Wav2lip",
                                "sub": "Wav2lip",
                                "type": "route",
                                "metadata": {},
                                "function": "\n                    () => {}\n                    ",
                                "parent": "Ai.Video.Lipsync",
                                "namesub": [
                                    {
                                        "id": "Ai.Video.Lipsync.Wav2lip.Augmentvideolipsyncbyaudio",
                                        "sub": "Wav2Lip: Audio Video",
                                        "type": "function",
                                        "metadata": {
                                            "uuid": "m00cgeu1mn7qu",
                                            "category": "Transform",
                                            "icon": "",
                                            "hidden": true,
                                            "subwindows": [
                                                {
                                                    "title": "Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "image_input",
                                                            "label": "Video",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00cgeu14w5dr",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "audio_input",
                                                            "label": "Audio",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00cgeu18bq7p",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "tts_button",
                                                            "label": "",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00cgeu19kvq7",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "idleAnimationOutput",
                                                            "label": "Animation Output",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00cgeu1stvy1",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        }
                                                    ],
                                                    "uuid": "3c026d28-0",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "settings",
                                                    "buttons": []
                                                }
                                            ],
                                            "name": "uai.extension.wav2lip.audiovideo",
                                            "title": "Wav2Lip: Audio Video",
                                            "path": "AI/Video/Lipsync/Wav2Lip/Augment Video Lipsync By Audio",
                                            "automatic": [],
                                            "tags": [],
                                            "description": "Generate a video of a character with animated speech from audio and a video.",
                                            "inputs": [],
                                            "outputs": [],
                                            "panel": 2,
                                            "functions": [
                                                {
                                                    "name": "Run",
                                                    "module": "internal.3d",
                                                    "moduleType": "internal.3d",
                                                    "operation": "setTransform",
                                                    "args": "",
                                                    "executable": "internal.3d",
                                                    "url": "/image/stableDiffusion_",
                                                    "type": "update",
                                                    "help": "Process the 3D Transform Matrix.",
                                                    "requestType": "fx.properties",
                                                    "responseType": "",
                                                    "preFunction": "",
                                                    "postFunction": "",
                                                    "icon": "fa-cube",
                                                    "uuid": "m00cgeu1x3nck"
                                                }
                                            ],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": false,
                                            "window": "FX"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Video/Lipsync/Wav2Lip/Augment Video Lipsync By Audio\");\n                    }\n                    ",
                                        "parent": "Ai.Video.Lipsync.Wav2lip",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "image_input",
                                                        "label": "Video",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00cgeu14w5dr",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "audio_input",
                                                        "label": "Audio",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00cgeu18bq7p",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "tts_button",
                                                        "label": "",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00cgeu19kvq7",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "idleAnimationOutput",
                                                        "label": "Animation Output",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00cgeu1stvy1",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    }
                                                ],
                                                "uuid": "3c026d28-0",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "settings",
                                                "buttons": []
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": true
                                    },
                                    {
                                        "id": "Ai.Video.Lipsync.Wav2lip.Ttsvideolipsync",
                                        "sub": "Wav2Lip: TTS Video",
                                        "type": "function",
                                        "metadata": {
                                            "uuid": "m00cigf7v61r7",
                                            "category": "Transform",
                                            "icon": "",
                                            "hidden": true,
                                            "subwindows": [
                                                {
                                                    "title": "Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "image_input",
                                                            "label": "Video",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00cigf72jz3i",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "text",
                                                            "label": "Text",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00cigf7av4bp",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "tts_button",
                                                            "label": "",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00cigf709nid",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "idleAnimationOutput",
                                                            "label": "Animation Output",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00cigf7xu5md",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        }
                                                    ],
                                                    "uuid": "684e3380-b",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "settings",
                                                    "buttons": []
                                                }
                                            ],
                                            "name": "uai.extension.wav2lip.tts",
                                            "title": "Wav2Lip: TTS Video",
                                            "path": "AI/Video/Lipsync/Wav2Lip/TTS Video Lipsync",
                                            "automatic": [],
                                            "tags": [],
                                            "description": "",
                                            "inputs": [],
                                            "outputs": [],
                                            "panel": 2,
                                            "functions": [
                                                {
                                                    "name": "Run",
                                                    "module": "internal.3d",
                                                    "moduleType": "internal.3d",
                                                    "operation": "setTransform",
                                                    "args": "",
                                                    "executable": "internal.3d",
                                                    "url": "/image/stableDiffusion_",
                                                    "type": "update",
                                                    "help": "Process the 3D Transform Matrix.",
                                                    "requestType": "fx.properties",
                                                    "responseType": "",
                                                    "preFunction": "",
                                                    "postFunction": "",
                                                    "icon": "fa-cube",
                                                    "uuid": "m00cigf7rfx3u"
                                                }
                                            ],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": false,
                                            "window": "FX"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Video/Lipsync/Wav2Lip/TTS Video Lipsync\");\n                    }\n                    ",
                                        "parent": "Ai.Video.Lipsync.Wav2lip",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "image_input",
                                                        "label": "Video",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00cigf72jz3i",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "text",
                                                        "label": "Text",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00cigf7av4bp",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "tts_button",
                                                        "label": "",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00cigf709nid",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "idleAnimationOutput",
                                                        "label": "Animation Output",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00cigf7xu5md",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    }
                                                ],
                                                "uuid": "684e3380-b",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "settings",
                                                "buttons": []
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": true
                                    }
                                ]
                            },
                            {
                                "id": "Ai.Video.Lipsync.Sadtalker",
                                "sub": "Sadtalker",
                                "type": "route",
                                "metadata": {},
                                "function": "\n                    () => {}\n                    ",
                                "parent": "Ai.Video.Lipsync",
                                "namesub": [
                                    {
                                        "id": "Ai.Video.Lipsync.Sadtalker.Audiodrivenlipsync",
                                        "sub": "SadTalker: Audio Driven Lipsync",
                                        "type": "function",
                                        "metadata": {
                                            "uuid": "fbf8e3ce-f730-419e-9f36-29024ab57d12",
                                            "category": "Transform",
                                            "icon": "",
                                            "hidden": false,
                                            "subwindows": [
                                                {
                                                    "title": "Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "image_input",
                                                            "label": "Image",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "db2e4942-3ec2-4eb3-9a47-70e4f0ad2d08",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "audio_input",
                                                            "label": "Audio",
                                                            "placeholder": "",
                                                            "type": "file",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "046716b0-b1c5-4626-893a-bad8b96c761f",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        }
                                                    ],
                                                    "uuid": "333d6343-0",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "settings",
                                                    "buttons": []
                                                },
                                                {
                                                    "title": "Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "expression_scale",
                                                            "label": "Expression Scale",
                                                            "min": 0,
                                                            "max": 1,
                                                            "step": 0.01,
                                                            "value": 1,
                                                            "type": "select",
                                                            "properties": {
                                                                "minimum": "0.1,",
                                                                "maximum": "5.0,",
                                                                "value": "1.9"
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "9a781dbb-30e9-41e9-aa60-72a0f183a075",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "lines": "single",
                                                            "placeholder": "Johno",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "head_motion_scale",
                                                            "label": "Head Motion Scale",
                                                            "min": 0,
                                                            "max": 1,
                                                            "step": 0.01,
                                                            "value": 1,
                                                            "type": "select",
                                                            "properties": {
                                                                "minimum": "0.1,",
                                                                "maximum": "5.0,",
                                                                "value": "0.2"
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "eb2129d4-aa31-4988-84c6-908785d287a8",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "lines": "single",
                                                            "placeholder": "Johno",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "preprocess",
                                                            "label": "Preprocess",
                                                            "placeholder": "",
                                                            "type": "select",
                                                            "lines": "single",
                                                            "value": "",
                                                            "options": [],
                                                            "properties": {
                                                                "choices": "[\"resize\",",
                                                                "value": "\"resize\""
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "e8aa1db4-008c-49af-9f77-9eed057adb52",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "extraArgs",
                                                            "label": "Extra Args",
                                                            "placeholder": "",
                                                            "type": "string",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {
                                                                "placeholder": "\"Extra",
                                                                "lines": "1"
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "deb0af78-e36e-419c-9c0c-b3a2747f7efc",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "tts_button",
                                                            "label": "",
                                                            "placeholder": "",
                                                            "type": "button",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "2007dd4c-2395-4c67-b01e-7c7a54ee9d9b",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "idleAnimationOutput",
                                                            "label": "Animation Output",
                                                            "placeholder": "",
                                                            "type": "file",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "7f0a2600-cdc6-4047-9685-7c2c33134dd6",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        }
                                                    ],
                                                    "uuid": "d9521768-5",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "settings",
                                                    "buttons": []
                                                }
                                            ],
                                            "name": "uai.extension.sadtalker.audio",
                                            "title": "SadTalker: Audio Driven Lipsync",
                                            "path": "AI/Video/Lipsync/SadTalker/Audio Driven Lipsync",
                                            "automatic": [],
                                            "tags": [
                                                "Audio",
                                                " lipsync"
                                            ],
                                            "description": "Drive a video's lipsync animation with an audio file.",
                                            "inputs": [],
                                            "outputs": [],
                                            "panel": 2,
                                            "functions": [
                                                {
                                                    "name": "Run",
                                                    "module": "internal.3d",
                                                    "moduleType": "internal.3d",
                                                    "operation": "setTransform",
                                                    "args": "",
                                                    "executable": "internal.3d",
                                                    "url": "/image/stableDiffusion_",
                                                    "type": "update",
                                                    "help": "Process the 3D Transform Matrix.",
                                                    "requestType": "fx.properties",
                                                    "responseType": "",
                                                    "preFunction": "",
                                                    "postFunction": "",
                                                    "icon": "fa-cube",
                                                    "uuid": "8d5a2ff3-16fb-4f9a-9336-09e5dfdc36ff"
                                                }
                                            ],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": false,
                                            "window": "FX"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Video/Lipsync/SadTalker/Audio Driven Lipsync\");\n                    }\n                    ",
                                        "parent": "Ai.Video.Lipsync.Sadtalker",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "image_input",
                                                        "label": "Image",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "db2e4942-3ec2-4eb3-9a47-70e4f0ad2d08",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "audio_input",
                                                        "label": "Audio",
                                                        "placeholder": "",
                                                        "type": "file",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "046716b0-b1c5-4626-893a-bad8b96c761f",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    }
                                                ],
                                                "uuid": "333d6343-0",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "settings",
                                                "buttons": []
                                            },
                                            {
                                                "title": "Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "expression_scale",
                                                        "label": "Expression Scale",
                                                        "min": 0,
                                                        "max": 1,
                                                        "step": 0.01,
                                                        "value": 1,
                                                        "type": "select",
                                                        "properties": {
                                                            "minimum": "0.1,",
                                                            "maximum": "5.0,",
                                                            "value": "1.9"
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "9a781dbb-30e9-41e9-aa60-72a0f183a075",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "lines": "single",
                                                        "placeholder": "Johno",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "head_motion_scale",
                                                        "label": "Head Motion Scale",
                                                        "min": 0,
                                                        "max": 1,
                                                        "step": 0.01,
                                                        "value": 1,
                                                        "type": "select",
                                                        "properties": {
                                                            "minimum": "0.1,",
                                                            "maximum": "5.0,",
                                                            "value": "0.2"
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "eb2129d4-aa31-4988-84c6-908785d287a8",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "lines": "single",
                                                        "placeholder": "Johno",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "preprocess",
                                                        "label": "Preprocess",
                                                        "placeholder": "",
                                                        "type": "select",
                                                        "lines": "single",
                                                        "value": "",
                                                        "options": [],
                                                        "properties": {
                                                            "choices": "[\"resize\",",
                                                            "value": "\"resize\""
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "e8aa1db4-008c-49af-9f77-9eed057adb52",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "extraArgs",
                                                        "label": "Extra Args",
                                                        "placeholder": "",
                                                        "type": "string",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {
                                                            "placeholder": "\"Extra",
                                                            "lines": "1"
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "deb0af78-e36e-419c-9c0c-b3a2747f7efc",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "tts_button",
                                                        "label": "",
                                                        "placeholder": "",
                                                        "type": "button",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "2007dd4c-2395-4c67-b01e-7c7a54ee9d9b",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "idleAnimationOutput",
                                                        "label": "Animation Output",
                                                        "placeholder": "",
                                                        "type": "file",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "7f0a2600-cdc6-4047-9685-7c2c33134dd6",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    }
                                                ],
                                                "uuid": "d9521768-5",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "settings",
                                                "buttons": []
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": false
                                    },
                                    {
                                        "id": "Ai.Video.Lipsync.Sadtalker.Ttsanimator",
                                        "sub": "SadTalker: TTS Lipsync",
                                        "type": "function",
                                        "metadata": {
                                            "uuid": "505173e9-cad2-4162-a12a-0b075d601c09",
                                            "category": "Transform",
                                            "icon": "",
                                            "hidden": false,
                                            "subwindows": [
                                                {
                                                    "title": "Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "image_input",
                                                            "label": "Image",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "53aaeaf6-f679-44aa-9a3b-ce3f607e8546",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        }
                                                    ],
                                                    "uuid": "b60ee533-5",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "settings",
                                                    "buttons": []
                                                },
                                                {
                                                    "title": "Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "expression_scale",
                                                            "label": "Expression Scale",
                                                            "min": 0,
                                                            "max": 1,
                                                            "step": 0.01,
                                                            "value": 1,
                                                            "type": "select",
                                                            "properties": {
                                                                "minimum": "0.1,",
                                                                "maximum": "5.0,",
                                                                "value": "1.9"
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "84fc03cd-68ba-4eeb-b547-adbb30f03618",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "lines": "single",
                                                            "placeholder": "Johno",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "head_motion_scale",
                                                            "label": "Head Motion Scale",
                                                            "min": 0,
                                                            "max": 1,
                                                            "step": 0.01,
                                                            "value": 1,
                                                            "type": "select",
                                                            "properties": {
                                                                "minimum": "0.1,",
                                                                "maximum": "5.0,",
                                                                "value": "0.2"
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "50e499e5-76af-4572-b564-615ddf4fb60f",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "lines": "single",
                                                            "placeholder": "Johno",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "preprocess",
                                                            "label": "Preprocess",
                                                            "placeholder": "",
                                                            "type": "select",
                                                            "lines": "single",
                                                            "value": "",
                                                            "options": [],
                                                            "properties": {
                                                                "choices": "[\"resize\",",
                                                                "value": "\"resize\""
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "26805821-16d6-4523-875c-94ece8e753a6",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "extraArgs",
                                                            "label": "Extra Args",
                                                            "placeholder": "",
                                                            "type": "string",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {
                                                                "placeholder": "\"Extra",
                                                                "lines": "1"
                                                            },
                                                            "help": "This is a help text",
                                                            "uuid": "2e8906a8-5fd6-438a-9c1d-cccd8d650af4",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "tts_button",
                                                            "label": "",
                                                            "placeholder": "",
                                                            "type": "button",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "2f18d6ee-91af-43f6-85c6-548a0f132ecd",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "idleAnimationOutput",
                                                            "label": "Animation Output",
                                                            "placeholder": "",
                                                            "type": "file",
                                                            "lines": "single",
                                                            "value": "",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "518df76c-e28c-4765-9d2b-a1f7819704fd",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        }
                                                    ],
                                                    "uuid": "78901cd1-7",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "settings",
                                                    "buttons": []
                                                }
                                            ],
                                            "name": "uai.extension.sadtalker.tts",
                                            "title": "SadTalker: TTS Lipsync",
                                            "path": "AI/Video/Lipsync/SadTalker/TTS Animator",
                                            "automatic": [],
                                            "tags": [
                                                "Audio",
                                                " lipsync",
                                                " tts"
                                            ],
                                            "description": "Augment a video's lipsync with Text to Speech Audio",
                                            "inputs": [],
                                            "outputs": [],
                                            "panel": 2,
                                            "functions": [
                                                {
                                                    "name": "Run",
                                                    "module": "internal.3d",
                                                    "moduleType": "internal.3d",
                                                    "operation": "setTransform",
                                                    "args": "",
                                                    "executable": "internal.3d",
                                                    "url": "/image/stableDiffusion_",
                                                    "type": "update",
                                                    "help": "Process the 3D Transform Matrix.",
                                                    "requestType": "fx.properties",
                                                    "responseType": "",
                                                    "preFunction": "",
                                                    "postFunction": "",
                                                    "icon": "fa-cube",
                                                    "uuid": "37bd32ae-10ed-48b5-b293-a73de2205aa6"
                                                }
                                            ],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": false,
                                            "window": "FX"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Video/Lipsync/SadTalker/TTS Animator\");\n                    }\n                    ",
                                        "parent": "Ai.Video.Lipsync.Sadtalker",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "image_input",
                                                        "label": "Image",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "53aaeaf6-f679-44aa-9a3b-ce3f607e8546",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    }
                                                ],
                                                "uuid": "b60ee533-5",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "settings",
                                                "buttons": []
                                            },
                                            {
                                                "title": "Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "expression_scale",
                                                        "label": "Expression Scale",
                                                        "min": 0,
                                                        "max": 1,
                                                        "step": 0.01,
                                                        "value": 1,
                                                        "type": "select",
                                                        "properties": {
                                                            "minimum": "0.1,",
                                                            "maximum": "5.0,",
                                                            "value": "1.9"
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "84fc03cd-68ba-4eeb-b547-adbb30f03618",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "lines": "single",
                                                        "placeholder": "Johno",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "head_motion_scale",
                                                        "label": "Head Motion Scale",
                                                        "min": 0,
                                                        "max": 1,
                                                        "step": 0.01,
                                                        "value": 1,
                                                        "type": "select",
                                                        "properties": {
                                                            "minimum": "0.1,",
                                                            "maximum": "5.0,",
                                                            "value": "0.2"
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "50e499e5-76af-4572-b564-615ddf4fb60f",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "lines": "single",
                                                        "placeholder": "Johno",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "preprocess",
                                                        "label": "Preprocess",
                                                        "placeholder": "",
                                                        "type": "select",
                                                        "lines": "single",
                                                        "value": "",
                                                        "options": [],
                                                        "properties": {
                                                            "choices": "[\"resize\",",
                                                            "value": "\"resize\""
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "26805821-16d6-4523-875c-94ece8e753a6",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "extraArgs",
                                                        "label": "Extra Args",
                                                        "placeholder": "",
                                                        "type": "string",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {
                                                            "placeholder": "\"Extra",
                                                            "lines": "1"
                                                        },
                                                        "help": "This is a help text",
                                                        "uuid": "2e8906a8-5fd6-438a-9c1d-cccd8d650af4",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "tts_button",
                                                        "label": "",
                                                        "placeholder": "",
                                                        "type": "button",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "2f18d6ee-91af-43f6-85c6-548a0f132ecd",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "idleAnimationOutput",
                                                        "label": "Animation Output",
                                                        "placeholder": "",
                                                        "type": "file",
                                                        "lines": "single",
                                                        "value": "",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "518df76c-e28c-4765-9d2b-a1f7819704fd",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    }
                                                ],
                                                "uuid": "78901cd1-7",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "settings",
                                                "buttons": []
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": false
                                    },
                                    {
                                        "id": "Ai.Video.Lipsync.Sadtalker.Idleanimator",
                                        "sub": "SadTalker: Idle Animator",
                                        "type": "function",
                                        "metadata": {
                                            "uuid": "m00dsd5v350k6",
                                            "category": "Transform",
                                            "icon": "",
                                            "hidden": true,
                                            "subwindows": [
                                                {
                                                    "title": "Settings",
                                                    "size": "Large",
                                                    "fields": [
                                                        {
                                                            "name": "image_input",
                                                            "label": "Image",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00dsd5u75ptv",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "idleSlider",
                                                            "label": "Idle Duration",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00dsd5virbvm",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "tts_button",
                                                            "label": "",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00dsd5vicjql",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        },
                                                        {
                                                            "name": "idleAnimationOutput",
                                                            "label": "Idle Animation Output",
                                                            "placeholder": "",
                                                            "lines": "single",
                                                            "value": "",
                                                            "type": "string",
                                                            "properties": {},
                                                            "help": "This is a help text",
                                                            "uuid": "m00dsd5vgq7mc",
                                                            "keyframeable": true,
                                                            "keyframeType": "all",
                                                            "options": [],
                                                            "required": true
                                                        }
                                                    ],
                                                    "uuid": "aff0ba19-a",
                                                    "enabled": true,
                                                    "toggleable": true,
                                                    "initialState": false,
                                                    "name": "settings",
                                                    "buttons": []
                                                }
                                            ],
                                            "name": "uai.extension.sadtalker.idle",
                                            "title": "SadTalker: Idle Animator",
                                            "path": "AI/Video/Lipsync/SadTalker/Idle Animator",
                                            "automatic": [],
                                            "tags": [],
                                            "description": "",
                                            "inputs": [],
                                            "outputs": [],
                                            "panel": 2,
                                            "functions": [
                                                {
                                                    "name": "Run",
                                                    "module": "internal.3d",
                                                    "moduleType": "internal.3d",
                                                    "operation": "setTransform",
                                                    "args": "",
                                                    "executable": "internal.3d",
                                                    "url": "/image/stableDiffusion_",
                                                    "type": "update",
                                                    "help": "Process the 3D Transform Matrix.",
                                                    "requestType": "fx.properties",
                                                    "responseType": "",
                                                    "preFunction": "",
                                                    "postFunction": "",
                                                    "icon": "fa-cube",
                                                    "uuid": "m00dsd5vvnzyo"
                                                }
                                            ],
                                            "documentation": "https://uai.software/docs",
                                            "initialState": false,
                                            "toggleable": true,
                                            "isFX": false,
                                            "window": "FX"
                                        },
                                        "function": "\n                    () => {\n                        onModuleRun(\"AI/Video/Lipsync/SadTalker/Idle Animator\");\n                    }\n                    ",
                                        "parent": "Ai.Video.Lipsync.Sadtalker",
                                        "namesub": [],
                                        "subwindows": [
                                            {
                                                "title": "Settings",
                                                "size": "Large",
                                                "fields": [
                                                    {
                                                        "name": "image_input",
                                                        "label": "Image",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00dsd5u75ptv",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "idleSlider",
                                                        "label": "Idle Duration",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00dsd5virbvm",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "tts_button",
                                                        "label": "",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00dsd5vicjql",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    },
                                                    {
                                                        "name": "idleAnimationOutput",
                                                        "label": "Idle Animation Output",
                                                        "placeholder": "",
                                                        "lines": "single",
                                                        "value": "",
                                                        "type": "string",
                                                        "properties": {},
                                                        "help": "This is a help text",
                                                        "uuid": "m00dsd5vgq7mc",
                                                        "keyframeable": true,
                                                        "keyframeType": "all",
                                                        "options": [],
                                                        "required": true
                                                    }
                                                ],
                                                "uuid": "aff0ba19-a",
                                                "enabled": true,
                                                "toggleable": true,
                                                "initialState": false,
                                                "name": "settings",
                                                "buttons": []
                                            }
                                        ],
                                        "automatic": [],
                                        "hidden": true
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "id": "Window",
        "sub": "Window",
        "type": "route",
        "metadata": {},
        "function": "\n                    () => {}\n                    ",
        "parent": "",
        "namesub": [
            {
                "id": "Window.Layouts",
                "sub": "Layouts",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "Window",
                "namesub": [
                    {
                        "id": "Window.Layouts.Defaulteditor",
                        "sub": "Default Editor",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.headless.window.default",
                            "title": "Default Editor",
                            "path": "Window/Layouts/Default Editor",
                            "category": "Window",
                            "icon": "",
                            "hidden": false,
                            "tags": [
                                "Window",
                                "UI"
                            ],
                            "description": "Apply the Default editor Layout",
                            "inputs": [
                                "None"
                            ],
                            "outputs": [
                                "None"
                            ],
                            "panel": 2,
                            "subwindows": [],
                            "functions": [
                                {
                                    "name": "Open",
                                    "module": "internal.headless.window",
                                    "moduleType": "internal.headless.window",
                                    "operation": "showDefaultEditor",
                                    "args": "",
                                    "executable": "internal.headless.project",
                                    "url": "",
                                    "type": "auto",
                                    "help": "Open Layout",
                                    "requestType": "ui.project.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-paint-brush",
                                    "uuid": "fdc565be-7c"
                                }
                            ],
                            "uuid": "cc335c32-f",
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": true,
                            "toggleable": true,
                            "isFX": false,
                            "window": "Headless"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"Window/Layouts/Default Editor\");\n                    }\n                    ",
                        "parent": "Window.Layouts",
                        "namesub": [],
                        "subwindows": [],
                        "automatic": [],
                        "hidden": false
                    },
                    {
                        "id": "Window.Layouts.Timelineeditor",
                        "sub": "Timeline Editor",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.headless.window.timelineeditor",
                            "title": "Timeline Editor",
                            "path": "Window/Layouts/Timeline Editor",
                            "category": "Window",
                            "icon": "",
                            "hidden": false,
                            "tags": [
                                "Window",
                                "UI"
                            ],
                            "description": "Apply the timeline editor Layout",
                            "inputs": [
                                "None"
                            ],
                            "outputs": [
                                "None"
                            ],
                            "panel": 2,
                            "subwindows": [],
                            "functions": [
                                {
                                    "name": "Open",
                                    "module": "internal.headless.window",
                                    "moduleType": "internal.headless.window",
                                    "operation": "showTimelineEditor",
                                    "args": "",
                                    "executable": "internal.headless.project",
                                    "url": "",
                                    "type": "auto",
                                    "help": "Open Layout",
                                    "requestType": "ui.project.properties",
                                    "responseType": "",
                                    "preFunction": "",
                                    "postFunction": "",
                                    "icon": "fa-paint-brush",
                                    "uuid": "0280a154-5a"
                                }
                            ],
                            "uuid": "fa8fdd6c-e",
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": true,
                            "toggleable": true,
                            "isFX": false,
                            "window": "Headless"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"Window/Layouts/Timeline Editor\");\n                    }\n                    ",
                        "parent": "Window.Layouts",
                        "namesub": [],
                        "subwindows": [],
                        "automatic": [],
                        "hidden": false
                    }
                ]
            }
        ]
    },
    {
        "id": "Test",
        "sub": "Test",
        "type": "route",
        "metadata": {},
        "function": "\n                    () => {}\n                    ",
        "parent": "",
        "namesub": [
            {
                "id": "Test.Lua",
                "sub": "Lua",
                "type": "route",
                "metadata": {},
                "function": "\n                    () => {}\n                    ",
                "parent": "Test",
                "namesub": [
                    {
                        "id": "Test.Lua.Print",
                        "sub": "Tset Lua Print",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.lua.test.print",
                            "title": "Tset Lua Print",
                            "path": "Test/Lua/Print",
                            "category": "Image",
                            "icon": "",
                            "tags": [
                                "Image",
                                "Stable Diffusion",
                                "XL Lightning",
                                "Text2Image",
                                "UAI"
                            ],
                            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Image"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Basic Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "prompt",
                                            "label": "Prompt",
                                            "type": "string",
                                            "lines": "multi",
                                            "placeholder": "",
                                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                            "required": true,
                                            "help": "Enter the text prompt for generating the image.",
                                            "uuid": "983cea6c-c",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "neg_prompt",
                                            "label": "Negative Prompt",
                                            "type": "string",
                                            "lines": "multi",
                                            "value": "bad quality. low resolution",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "Enter the negative text prompt for generating the image.",
                                            "uuid": "ff8e7dc7-7",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "c9e64bd9-f",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "basicsettings"
                                },
                                {
                                    "title": "Advanced Settings",
                                    "size": "Small",
                                    "fields": [
                                        {
                                            "name": "model",
                                            "label": "AI Model",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": " ",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                                            "required": false,
                                            "help": "Select the AI Model to use for generating the image.",
                                            "uuid": "51513bd0-5",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "steps",
                                            "label": "Steps",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "6",
                                            "min": "0",
                                            "max": "100",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "The amount of imastepsges to generate.",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "uuid": "40a6037d-3",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "guidance_scale",
                                            "label": "Config Scale",
                                            "type": "float",
                                            "lines": "single",
                                            "value": "3",
                                            "min": "0",
                                            "max": "100",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "The configuration scale.",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "uuid": "c17579c5-5",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "be38cf60-3",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "buttons": [],
                                    "name": "advancedsettings"
                                },
                                {
                                    "title": "Output",
                                    "size": "Medium",
                                    "fields": [
                                        {
                                            "name": "seed",
                                            "label": "Seed",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "512",
                                            "placeholder": "512",
                                            "min": "0",
                                            "max": "1000000",
                                            "required": true,
                                            "help": "Enter the seed value for randomizing the image generation.",
                                            "uuid": "de099f42-9",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "imagesToGenerate",
                                            "label": "Images to Generate",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "1",
                                            "placeholder": "1",
                                            "min": "0",
                                            "max": "8",
                                            "required": true,
                                            "help": "The amount of images to generate.",
                                            "uuid": "55b2a3da-b",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "exportSize",
                                            "label": "Image Size",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": "",
                                            "options": [
                                                "S 1024X1024",
                                                "S 512X512",
                                                "S 1920X1920",
                                                "L 1920X1080",
                                                "L 1280X720",
                                                "L 1024X576",
                                                "P 1080X1920",
                                                "P 720X1280",
                                                "P 576X1240"
                                            ],
                                            "value": "S 1024X1024",
                                            "required": false,
                                            "help": "Select the size of the generated image.",
                                            "min": "0",
                                            "max": "1000000",
                                            "uuid": "ded52141-f",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "12f4e670-e",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "buttons": [],
                                    "name": "output"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Generate",
                                    "module": "testString",
                                    "moduleType": "lua",
                                    "operation": "RunProcess",
                                    "args": "",
                                    "executable": "lua",
                                    "url": "/image/stableDiffusion_",
                                    "type": "submit",
                                    "help": "Click to generate the image.",
                                    "requestType": "ImageRequestConsole",
                                    "responseType": "MultipleMediaRequest",
                                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                    "postFunction": "com.uai.print",
                                    "icon": "fa-rocket",
                                    "uuid": "0851bff1-4"
                                }
                            ],
                            "uuid": "2871d851-a",
                            "hidden": true,
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": false,
                            "toggleable": true,
                            "isFX": true,
                            "window": "FX"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"Test/Lua/Print\");\n                    }\n                    ",
                        "parent": "Test.Lua",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Basic Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "prompt",
                                        "label": "Prompt",
                                        "type": "string",
                                        "lines": "multi",
                                        "placeholder": "",
                                        "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                        "required": true,
                                        "help": "Enter the text prompt for generating the image.",
                                        "uuid": "983cea6c-c",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "neg_prompt",
                                        "label": "Negative Prompt",
                                        "type": "string",
                                        "lines": "multi",
                                        "value": "bad quality. low resolution",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "Enter the negative text prompt for generating the image.",
                                        "uuid": "ff8e7dc7-7",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "c9e64bd9-f",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "basicsettings"
                            },
                            {
                                "title": "Advanced Settings",
                                "size": "Small",
                                "fields": [
                                    {
                                        "name": "model",
                                        "label": "AI Model",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": " ",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "value": "SG161222/RealVisXL_V4.0_Lightning",
                                        "required": false,
                                        "help": "Select the AI Model to use for generating the image.",
                                        "uuid": "51513bd0-5",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "steps",
                                        "label": "Steps",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "6",
                                        "min": "0",
                                        "max": "100",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "The amount of imastepsges to generate.",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "uuid": "40a6037d-3",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "guidance_scale",
                                        "label": "Config Scale",
                                        "type": "float",
                                        "lines": "single",
                                        "value": "3",
                                        "min": "0",
                                        "max": "100",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "The configuration scale.",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "uuid": "c17579c5-5",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "be38cf60-3",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "buttons": [],
                                "name": "advancedsettings"
                            },
                            {
                                "title": "Output",
                                "size": "Medium",
                                "fields": [
                                    {
                                        "name": "seed",
                                        "label": "Seed",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "512",
                                        "placeholder": "512",
                                        "min": "0",
                                        "max": "1000000",
                                        "required": true,
                                        "help": "Enter the seed value for randomizing the image generation.",
                                        "uuid": "de099f42-9",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "imagesToGenerate",
                                        "label": "Images to Generate",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "1",
                                        "placeholder": "1",
                                        "min": "0",
                                        "max": "8",
                                        "required": true,
                                        "help": "The amount of images to generate.",
                                        "uuid": "55b2a3da-b",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "exportSize",
                                        "label": "Image Size",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": "",
                                        "options": [
                                            "S 1024X1024",
                                            "S 512X512",
                                            "S 1920X1920",
                                            "L 1920X1080",
                                            "L 1280X720",
                                            "L 1024X576",
                                            "P 1080X1920",
                                            "P 720X1280",
                                            "P 576X1240"
                                        ],
                                        "value": "S 1024X1024",
                                        "required": false,
                                        "help": "Select the size of the generated image.",
                                        "min": "0",
                                        "max": "1000000",
                                        "uuid": "ded52141-f",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "12f4e670-e",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "buttons": [],
                                "name": "output"
                            }
                        ],
                        "automatic": [],
                        "hidden": true
                    },
                    {
                        "id": "Test.Lua.Pythonprint",
                        "sub": "Tset Lua Python Print",
                        "type": "function",
                        "metadata": {
                            "name": "uai.builtin.lua.test.pythonprint",
                            "title": "Tset Lua Python Print",
                            "path": "Test/Lua/Python Print",
                            "category": "Image",
                            "icon": "",
                            "tags": [
                                "Image",
                                "Stable Diffusion",
                                "XL Lightning",
                                "Text2Image",
                                "UAI"
                            ],
                            "description": "Test print a python string.",
                            "inputs": [
                                "Text",
                                "None"
                            ],
                            "outputs": [
                                "Image"
                            ],
                            "panel": 2,
                            "subwindows": [
                                {
                                    "title": "Basic Settings",
                                    "size": "Large",
                                    "fields": [
                                        {
                                            "name": "output",
                                            "label": "Output Image",
                                            "type": "save",
                                            "lines": "single",
                                            "objectType": "Image",
                                            "base64": true,
                                            "placeholder": "",
                                            "value": "",
                                            "required": true,
                                            "help": "The image to process.",
                                            "uuid": "867c1f2f-2",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "prompt",
                                            "label": "Prompt",
                                            "type": "string",
                                            "lines": "multi",
                                            "placeholder": "",
                                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                            "required": true,
                                            "help": "Enter the text prompt for generating the image.",
                                            "objectType": "Image",
                                            "base64": true,
                                            "uuid": "9d7ca63f-c",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "neg_prompt",
                                            "label": "Negative Prompt",
                                            "type": "string",
                                            "lines": "multi",
                                            "value": "bad quality. low resolution",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "Enter the negative text prompt for generating the image.",
                                            "objectType": "Image",
                                            "base64": true,
                                            "uuid": "cb95051e-8",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "buttons": [],
                                    "uuid": "93ddd6dd-8",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "name": "basicsettings"
                                },
                                {
                                    "title": "Advanced Settings",
                                    "size": "Small",
                                    "fields": [
                                        {
                                            "name": "model",
                                            "label": "AI Model",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": " ",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                                            "required": false,
                                            "help": "Select the AI Model to use for generating the image.",
                                            "uuid": "f7040c93-9",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "steps",
                                            "label": "Steps",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "6",
                                            "min": "0",
                                            "max": "100",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "The amount of imastepsges to generate.",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "uuid": "a5f9a24b-8",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "guidance_scale",
                                            "label": "Config Scale",
                                            "type": "float",
                                            "lines": "single",
                                            "value": "3",
                                            "min": "0",
                                            "max": "100",
                                            "placeholder": "",
                                            "required": true,
                                            "help": "The configuration scale.",
                                            "options": [
                                                "components.models.stablediffusion.xl"
                                            ],
                                            "uuid": "55dbec7d-6",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "c4700da0-b",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "buttons": [],
                                    "name": "advancedsettings"
                                },
                                {
                                    "title": "Output",
                                    "size": "Medium",
                                    "fields": [
                                        {
                                            "name": "seed",
                                            "label": "Seed",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "512",
                                            "placeholder": "512",
                                            "min": "0",
                                            "max": "1000000",
                                            "required": true,
                                            "help": "Enter the seed value for randomizing the image generation.",
                                            "uuid": "494cb647-b",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "imagesToGenerate",
                                            "label": "Images to Generate",
                                            "type": "int",
                                            "lines": "single",
                                            "value": "1",
                                            "placeholder": "1",
                                            "min": "0",
                                            "max": "8",
                                            "required": true,
                                            "help": "The amount of images to generate.",
                                            "uuid": "b0ccd31e-b",
                                            "options": [],
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        },
                                        {
                                            "name": "exportSize",
                                            "label": "Image Size",
                                            "type": "select",
                                            "lines": "single",
                                            "placeholder": "",
                                            "options": [
                                                "S 1024X1024",
                                                "S 512X512",
                                                "S 1920X1920",
                                                "L 1920X1080",
                                                "L 1280X720",
                                                "L 1024X576",
                                                "P 1080X1920",
                                                "P 720X1280",
                                                "P 576X1240"
                                            ],
                                            "value": "S 1024X1024",
                                            "required": false,
                                            "help": "Select the size of the generated image.",
                                            "min": "0",
                                            "max": "1000000",
                                            "uuid": "d0ff34d6-8",
                                            "keyframeable": true,
                                            "keyframeType": "all"
                                        }
                                    ],
                                    "uuid": "0da82535-6",
                                    "enabled": true,
                                    "toggleable": true,
                                    "initialState": false,
                                    "buttons": [],
                                    "name": "output"
                                }
                            ],
                            "functions": [
                                {
                                    "name": "Generate",
                                    "module": "testPythonScript",
                                    "moduleType": "lua",
                                    "operation": "RunProcess",
                                    "args": "",
                                    "executable": "lua",
                                    "url": "/image/stableDiffusion_",
                                    "type": "submit",
                                    "help": "Click to generate the image.",
                                    "requestType": "ImageRequestConsole",
                                    "responseType": "MultipleMediaRequest",
                                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                                    "postFunction": "com.uai.nothing",
                                    "icon": "fa-rocket",
                                    "uuid": "7994a9d1-7"
                                }
                            ],
                            "uuid": "4db51aae-d",
                            "hidden": false,
                            "automatic": [],
                            "documentation": "https://uai.software/docs",
                            "initialState": false,
                            "toggleable": true,
                            "isFX": true,
                            "window": "FX"
                        },
                        "function": "\n                    () => {\n                        onModuleRun(\"Test/Lua/Python Print\");\n                    }\n                    ",
                        "parent": "Test.Lua",
                        "namesub": [],
                        "subwindows": [
                            {
                                "title": "Basic Settings",
                                "size": "Large",
                                "fields": [
                                    {
                                        "name": "output",
                                        "label": "Output Image",
                                        "type": "save",
                                        "lines": "single",
                                        "objectType": "Image",
                                        "base64": true,
                                        "placeholder": "",
                                        "value": "",
                                        "required": true,
                                        "help": "The image to process.",
                                        "uuid": "867c1f2f-2",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "prompt",
                                        "label": "Prompt",
                                        "type": "string",
                                        "lines": "multi",
                                        "placeholder": "",
                                        "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                                        "required": true,
                                        "help": "Enter the text prompt for generating the image.",
                                        "objectType": "Image",
                                        "base64": true,
                                        "uuid": "9d7ca63f-c",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "neg_prompt",
                                        "label": "Negative Prompt",
                                        "type": "string",
                                        "lines": "multi",
                                        "value": "bad quality. low resolution",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "Enter the negative text prompt for generating the image.",
                                        "objectType": "Image",
                                        "base64": true,
                                        "uuid": "cb95051e-8",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "buttons": [],
                                "uuid": "93ddd6dd-8",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "name": "basicsettings"
                            },
                            {
                                "title": "Advanced Settings",
                                "size": "Small",
                                "fields": [
                                    {
                                        "name": "model",
                                        "label": "AI Model",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": " ",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "value": "SG161222/RealVisXL_V4.0_Lightning",
                                        "required": false,
                                        "help": "Select the AI Model to use for generating the image.",
                                        "uuid": "f7040c93-9",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "steps",
                                        "label": "Steps",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "6",
                                        "min": "0",
                                        "max": "100",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "The amount of imastepsges to generate.",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "uuid": "a5f9a24b-8",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "guidance_scale",
                                        "label": "Config Scale",
                                        "type": "float",
                                        "lines": "single",
                                        "value": "3",
                                        "min": "0",
                                        "max": "100",
                                        "placeholder": "",
                                        "required": true,
                                        "help": "The configuration scale.",
                                        "options": [
                                            "components.models.stablediffusion.xl"
                                        ],
                                        "uuid": "55dbec7d-6",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "c4700da0-b",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "buttons": [],
                                "name": "advancedsettings"
                            },
                            {
                                "title": "Output",
                                "size": "Medium",
                                "fields": [
                                    {
                                        "name": "seed",
                                        "label": "Seed",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "512",
                                        "placeholder": "512",
                                        "min": "0",
                                        "max": "1000000",
                                        "required": true,
                                        "help": "Enter the seed value for randomizing the image generation.",
                                        "uuid": "494cb647-b",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "imagesToGenerate",
                                        "label": "Images to Generate",
                                        "type": "int",
                                        "lines": "single",
                                        "value": "1",
                                        "placeholder": "1",
                                        "min": "0",
                                        "max": "8",
                                        "required": true,
                                        "help": "The amount of images to generate.",
                                        "uuid": "b0ccd31e-b",
                                        "options": [],
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    },
                                    {
                                        "name": "exportSize",
                                        "label": "Image Size",
                                        "type": "select",
                                        "lines": "single",
                                        "placeholder": "",
                                        "options": [
                                            "S 1024X1024",
                                            "S 512X512",
                                            "S 1920X1920",
                                            "L 1920X1080",
                                            "L 1280X720",
                                            "L 1024X576",
                                            "P 1080X1920",
                                            "P 720X1280",
                                            "P 576X1240"
                                        ],
                                        "value": "S 1024X1024",
                                        "required": false,
                                        "help": "Select the size of the generated image.",
                                        "min": "0",
                                        "max": "1000000",
                                        "uuid": "d0ff34d6-8",
                                        "keyframeable": true,
                                        "keyframeType": "all"
                                    }
                                ],
                                "uuid": "0da82535-6",
                                "enabled": true,
                                "toggleable": true,
                                "initialState": false,
                                "buttons": [],
                                "name": "output"
                            }
                        ],
                        "automatic": [],
                        "hidden": false
                    }
                ]
            }
        ]
    }
];
    
    const uaiComponents = {
    "components": [
        {
            "name": "uai.builtin.ui.project.create",
            "title": "Create: Project",
            "path": "File/Project/New",
            "category": "Project",
            "icon": "",
            "hidden": false,
            "tags": [
                "Project",
                "UI"
            ],
            "description": "Create the current Project.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Project"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "name",
                            "label": "Name",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "",
                            "value": "Untitled Project",
                            "required": true,
                            "help": "Name of the project",
                            "uuid": "a0cf2c78-4",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "projectType",
                            "label": "Project Type",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "Mixer",
                                "Dataset",
                                "XR Scene"
                            ],
                            "value": "Mixer",
                            "required": false,
                            "help": "Project type to be created.",
                            "uuid": "22da99b0-f",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "thumbnail",
                            "label": "Thumbnail",
                            "type": "file.image",
                            "lines": "multi",
                            "value": "",
                            "placeholder": "",
                            "required": true,
                            "help": "Thumbnail for the project",
                            "uuid": "50accfae-1",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "56707619-e",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": true,
                    "name": "settings"
                }
            ],
            "functions": [
                {
                    "name": "Cancel",
                    "module": "internal.ui.projectView",
                    "moduleType": "internal.ui.projectView",
                    "operation": "cancel",
                    "args": "",
                    "executable": "internal.ui.projectView",
                    "url": "/",
                    "type": "submit",
                    "help": "Cancel the current operation.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-ban",
                    "uuid": "6cad93fa-e"
                },
                {
                    "name": "Create",
                    "module": "internal.ui.project",
                    "moduleType": "internal.ui.project",
                    "operation": "createProject",
                    "args": "",
                    "executable": "internal.ui.project",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Create the project with the settings.",
                    "requestType": "ui.project.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "4a583763-1"
                }
            ],
            "uuid": "c1781c23-1",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Modal"
        },
        {
            "name": "uai.builtin.ui.project.sequence.create",
            "title": "Create: Sequence",
            "path": "File/Sequence/New Sequence",
            "category": "Sequence",
            "icon": "",
            "hidden": false,
            "tags": [
                "Sequence",
                "UI"
            ],
            "description": "create the current Sequence.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Sequence"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "name",
                            "label": "Name",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "",
                            "value": "",
                            "required": true,
                            "help": "Name of the sequence",
                            "uuid": "67e5a69e-c",
                            "options": [],
                            "keyframeable": false,
                            "keyframeType": "all"
                        },
                        {
                            "name": "description",
                            "label": "Description",
                            "type": "string",
                            "lines": "multi",
                            "value": "",
                            "placeholder": "",
                            "required": true,
                            "help": "Description for the sequence",
                            "uuid": "9b513f2c-d",
                            "options": [],
                            "keyframeable": false,
                            "keyframeType": "all"
                        },
                        {
                            "name": "comments",
                            "label": "Comments",
                            "type": "string",
                            "lines": "multi",
                            "value": "",
                            "placeholder": "",
                            "required": true,
                            "help": "Comments for the sequence",
                            "uuid": "4a2f9d3d-3",
                            "options": [],
                            "keyframeable": false,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "1331d03d-2",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": true,
                    "name": "settings"
                },
                {
                    "title": "Render",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "resolution",
                            "label": "Resolution",
                            "type": "vector2",
                            "lines": "single",
                            "placeholder": "[1920,1080]",
                            "value": [
                                1920,
                                1080
                            ],
                            "required": true,
                            "help": "Resolution",
                            "uuid": "f4c44444-0",
                            "options": [],
                            "keyframeable": false,
                            "keyframeType": "all"
                        },
                        {
                            "name": "fps",
                            "label": "Framerate",
                            "type": "float",
                            "lines": "single",
                            "value": 23.976,
                            "placeholder": "23.976",
                            "min": 0,
                            "max": 10000000,
                            "required": true,
                            "help": "Framerate of the video.",
                            "uuid": "7a4bd2ca-d",
                            "options": [],
                            "keyframeable": false,
                            "keyframeType": "all"
                        },
                        {
                            "name": "audiobitrate",
                            "label": "Audio Bitrate",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "192k",
                                "256k",
                                "320k"
                            ],
                            "value": "256k",
                            "required": false,
                            "help": "Audio Bitrate.",
                            "uuid": "ba896622-6",
                            "keyframeable": false,
                            "keyframeType": "all"
                        },
                        {
                            "name": "stereo",
                            "label": "Stereo",
                            "type": "bool",
                            "lines": "single",
                            "placeholder": "true",
                            "value": true,
                            "required": true,
                            "help": "Wether it is stereo or not.",
                            "uuid": "e3132b00-1",
                            "options": [],
                            "keyframeable": false,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "d0386991-b",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": true,
                    "buttons": [],
                    "name": "render"
                }
            ],
            "functions": [
                {
                    "name": "Cancel",
                    "module": "internal.ui.projectView",
                    "moduleType": "internal.ui.projectView",
                    "operation": "cancel",
                    "args": "",
                    "executable": "internal.ui.projectView",
                    "url": "/",
                    "type": "submit",
                    "help": "Cancel the current operation.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-ban",
                    "uuid": "6cad93fa-e"
                },
                {
                    "name": "Create",
                    "module": "internal.ui.sequence",
                    "moduleType": "internal.ui.sequence",
                    "operation": "createSequence",
                    "args": "",
                    "executable": "internal.ui.sequence",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Update the sequence with the new settings.",
                    "requestType": "ui.sequence.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "b5cce8e4-7"
                }
            ],
            "uuid": "de7984bd-c",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": false,
            "isFX": false,
            "window": "Modal"
        },
        {
            "name": "uai.builtin.ui.project.edit",
            "title": "Edit: Project Settings",
            "path": "Edit/Project Settings",
            "category": "Project",
            "icon": "",
            "hidden": false,
            "tags": [
                "Project",
                "UI"
            ],
            "description": "Edit the current Project.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Project"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "name",
                            "label": "Name",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "",
                            "value": "Untitled Project",
                            "required": true,
                            "help": "Name of the project",
                            "uuid": "28f71d6e-7",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "projectType",
                            "label": "Project Type",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "Mixer",
                                "Dataset",
                                "XR Scene"
                            ],
                            "value": "Mixer",
                            "required": false,
                            "help": "Project type to be created.",
                            "uuid": "43704972-b",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "thumbnail",
                            "label": "Thumbnail",
                            "type": "file.image",
                            "lines": "multi",
                            "value": "",
                            "placeholder": "",
                            "required": true,
                            "help": "Thumbnail for the project",
                            "uuid": "eb73b630-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "d0c57d8b-5",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": true,
                    "name": "settings"
                }
            ],
            "functions": [
                {
                    "name": "Cancel",
                    "module": "internal.ui.projectView",
                    "moduleType": "internal.ui.projectView",
                    "operation": "cancel",
                    "args": "",
                    "executable": "internal.ui.projectView",
                    "url": "/",
                    "type": "submit",
                    "help": "Cancel the current operation.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-ban",
                    "uuid": "6cad93fa-e"
                },
                {
                    "name": "Update",
                    "module": "internal.ui.project",
                    "moduleType": "internal.ui.project",
                    "operation": "updateProject",
                    "args": "",
                    "executable": "internal.ui.project",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Update the project with the new settings.",
                    "requestType": "ui.project.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "5790cea1-3"
                }
            ],
            "uuid": "cf138763-8",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Modal"
        },
        {
            "name": "uai.builtin.ui.project.sequence.edit",
            "title": "Edit: Sequence Settings",
            "path": "Edit/Sequence Settings",
            "category": "Sequence",
            "icon": "",
            "hidden": false,
            "tags": [
                "Sequence",
                "UI"
            ],
            "description": "Edit the current Sequence.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Sequence"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "name",
                            "label": "Name",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "",
                            "value": "",
                            "required": true,
                            "help": "Name of the sequence",
                            "uuid": "fc710970-8",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "description",
                            "label": "Description",
                            "type": "string",
                            "lines": "multi",
                            "value": "",
                            "placeholder": "",
                            "required": true,
                            "help": "Description for the sequence",
                            "uuid": "01ffc8e8-8",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "comments",
                            "label": "Comments",
                            "type": "string",
                            "lines": "multi",
                            "value": "",
                            "placeholder": "",
                            "required": true,
                            "help": "Comments for the sequence",
                            "uuid": "2fb3fd99-0",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "54d6c37a-7",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": true,
                    "name": "settings"
                },
                {
                    "title": "Render",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "resolution",
                            "label": "Resolution",
                            "type": "vector2",
                            "lines": "single",
                            "placeholder": "[1920,1080]",
                            "value": [
                                1920,
                                1080
                            ],
                            "required": true,
                            "help": "Resolution",
                            "uuid": "0fb42844-6",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "fps",
                            "label": "Framerate",
                            "type": "float",
                            "lines": "single",
                            "value": 23.976,
                            "placeholder": "23.976",
                            "min": 0,
                            "max": 10000000,
                            "required": true,
                            "help": "Framerate of the video.",
                            "uuid": "9687c7d4-0",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "audiobitrate",
                            "label": "Audio Bitrate",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "192k",
                                "256k",
                                "320k"
                            ],
                            "value": "256k",
                            "required": false,
                            "help": "Audio Bitrate.",
                            "uuid": "666fa0d4-c",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "stereo",
                            "label": "Stereo",
                            "type": "bool",
                            "lines": "single",
                            "placeholder": "true",
                            "value": true,
                            "required": true,
                            "help": "Wether it is stereo or not.",
                            "uuid": "540ed0d2-f",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "aa876c25-9",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": true,
                    "buttons": [],
                    "name": "render"
                }
            ],
            "functions": [
                {
                    "name": "Cancel",
                    "module": "internal.ui.projectView",
                    "moduleType": "internal.ui.projectView",
                    "operation": "cancel",
                    "args": "",
                    "executable": "internal.ui.projectView",
                    "url": "/",
                    "type": "submit",
                    "help": "Cancel the current operation.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-ban",
                    "uuid": "6cad93fa-e"
                },
                {
                    "name": "Update",
                    "module": "internal.ui.sequence",
                    "moduleType": "internal.ui.sequence",
                    "operation": "updateSequence",
                    "args": "",
                    "executable": "internal.ui.sequence",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Update the sequence with the new settings.",
                    "requestType": "ui.sequence.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "0e59a913-9"
                }
            ],
            "uuid": "167c3f6c-e",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Modal"
        },
        {
            "name": "uai.builtin.ui.project.save",
            "title": "Save: Project As",
            "path": "File/Project/Save Project As",
            "category": "Project",
            "icon": "",
            "hidden": false,
            "tags": [
                "Project",
                "UI"
            ],
            "description": "Save the current Project as...",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Project"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "path",
                            "label": "Path",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "",
                            "value": "Untitled Project.uaiproj",
                            "required": true,
                            "help": "Name of the project",
                            "uuid": "5a425245-5",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "b8df20da-2",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                }
            ],
            "functions": [
                {
                    "name": "Save",
                    "module": "internal.ui.project",
                    "moduleType": "internal.ui.project",
                    "operation": "saveProjectAs",
                    "args": "",
                    "executable": "internal.ui.project",
                    "url": "/image/stableDiffusion_",
                    "type": "auto",
                    "help": "Save the project with the new settings.",
                    "requestType": "ui.project.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "479c8df6-2"
                }
            ],
            "uuid": "df673fab-1",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Modal"
        },
        {
            "name": "uai.builtin.ui.project.open",
            "title": "Open: Project",
            "path": "File/Project/Open",
            "category": "Project",
            "icon": "",
            "hidden": false,
            "tags": [
                "Project",
                "UI"
            ],
            "description": "Open a Project.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Project"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "path",
                            "label": "Path",
                            "type": "file",
                            "lines": "single",
                            "placeholder": "",
                            "value": "Untitled Project",
                            "required": true,
                            "help": "Path of the project",
                            "uuid": "ac94d720-e",
                            "options": [],
                            "keyframeable": false,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "583594d2-d",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": true,
                    "name": "settings"
                }
            ],
            "functions": [
                {
                    "name": "Cancel",
                    "module": "internal.ui.projectView",
                    "moduleType": "internal.ui.projectView",
                    "operation": "cancel",
                    "args": "",
                    "executable": "internal.ui.projectView",
                    "url": "/",
                    "type": "submit",
                    "help": "Cancel the current operation.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-ban",
                    "uuid": "6cad93fa-e"
                },
                {
                    "name": "Open",
                    "module": "internal.ui.project",
                    "moduleType": "internal.ui.project",
                    "operation": "openProject",
                    "args": "",
                    "executable": "internal.ui.project",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Open the project with the settings.",
                    "requestType": "ui.project.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "4a583763-1"
                }
            ],
            "uuid": "c1781c23-1",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Modal"
        },
        {
            "name": "uai.builtin.audio.split",
            "title": "Split Stems",
            "path": "AI/Audio/Split Stems",
            "category": "Audio",
            "icon": "",
            "hidden": false,
            "tags": [
                "Audio",
                "Base",
                "Mix",
                "UAI"
            ],
            "automatic": [],
            "description": "Split the audio into stems.",
            "inputs": [
                "AudioSource",
                "None"
            ],
            "outputs": [
                "MixedAudio"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "input",
                            "label": "Input Wav",
                            "type": "file",
                            "lines": "single",
                            "objectType": "Audio",
                            "base64": true,
                            "placeholder": "",
                            "value": "",
                            "required": true,
                            "help": "The Audio to process.",
                            "uuid": "a47d0e6e-2",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "model",
                            "label": "AI Model",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " ",
                            "options": [
                                "spleeter:4stems",
                                "spleeter:5stems"
                            ],
                            "value": "spleeter:4stems",
                            "required": true,
                            "help": "The Model to use for the audio split. 4 Stems or 5 Stems.",
                            "uuid": "6b70d0e0-3",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "c29b3b8d-5",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                }
            ],
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.audio",
                    "moduleType": "internal.audio",
                    "operation": "splitAudio",
                    "args": "",
                    "executable": "internal.audio",
                    "url": "",
                    "type": "submit",
                    "help": "Split the audio into stems.",
                    "requestType": "fx.properties",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.createImageRequest",
                    "postFunction": "",
                    "icon": "fa-rocket",
                    "uuid": "2c4df1d8-5"
                }
            ],
            "uuid": "27810149-cd",
            "documentation": "https://uai.software/docs/Modules/Augment/Audio%20To%20Audio/SplitStems",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "AIGenerate"
        },
        {
            "name": "uai.builtin.audio.musicgen.custom",
            "title": "Music Gen: Text 2 Music Advanced ",
            "path": "AI/Audio/Generate/Text 2 Music/Advanced",
            "category": "Audio",
            "icon": "",
            "tags": [
                "Audio",
                "Stable Diffusion",
                "XL Lightning",
                "Text2Image",
                "UAI"
            ],
            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Audio"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Lyrics",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "[Verse] Hello world\n[Chorus] It's me.",
                            "required": true,
                            "help": "The lyrics of the song to generate. Use [Verse], [Chorus], [Bridge] to separate the sections.",
                            "uuid": "52a92d30-b099-4966-b0f6-93d3daf6099f",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "tags",
                            "label": "Tags",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Tags that describe the song to generate. Use spaces to separate the tags and use hyphens for multi-word tags.",
                            "uuid": "de7e5db9-3bec-43f1-acc5-744773b68dfd",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "042508e2-b7f7-4d45-97da-c0153ec939c7",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                }
            ],
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.audio",
                    "moduleType": "internal.audio.generate.music",
                    "operation": "generateMusicAdvanced",
                    "args": "",
                    "executable": "internal.audio",
                    "url": "/api/v1/music/text/to/audio/music/custom",
                    "type": "submit",
                    "help": "Split the audio into stems.",
                    "requestType": "fx.properties",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-rocket",
                    "uuid": "2f837169-28e0-4763-8184-747f3811a9ab"
                }
            ],
            "uuid": "0ab32296-782b-43f5-8391-5fd5a977d586",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "AIGenerate"
        },
        {
            "name": "uai.builtin.audio.musicgen.custom",
            "title": "Music Gen: Lyrics Generator",
            "path": "AI/Text/Generate/Lyrics",
            "category": "Text",
            "icon": "",
            "tags": [
                "Text",
                "UAI"
            ],
            "description": "This module generates a song based on the lyrics provided. The lyrics should be separated by sections such as [Verse], [Chorus], [Bridge]. The module will generate a song based on the lyrics provided and return the audio of the song. The module also accepts tags that describe the song to generate. Use spaces to separate the tags and use hyphens for multi-word tags.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Text"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A song about love and heartbreak.",
                            "required": true,
                            "help": "The prompt to generate the lyrics from.",
                            "uuid": "6bbba1be-5d04-42cb-9198-002d51d59cc3",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "496558a0-dd05-4770-ac3e-4a8b106fcc23",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                }
            ],
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.audio",
                    "moduleType": "internal.text.generate.lyrics",
                    "operation": "generateLyrics",
                    "args": "",
                    "executable": "internal.audio",
                    "url": "/api/v1/music/text/to/text/lyrics",
                    "type": "submit",
                    "help": "Generate lyrics from a prompt.",
                    "requestType": "fx.properties",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-rocket",
                    "uuid": "600e688d-5ae8-4053-ba58-c9e42e5c958a"
                }
            ],
            "uuid": "98b5249e-1707-4cbd-8872-dd981e2d96df",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "AIGenerate"
        },
        {
            "name": "uai.builtin.headless.project.save",
            "title": "Save: Project",
            "path": "File/Project/Save Project",
            "category": "Project",
            "icon": "",
            "hidden": false,
            "tags": [
                "Project",
                "UI"
            ],
            "description": "Edit the current Project.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Project"
            ],
            "panel": 2,
            "subwindows": [],
            "functions": [
                {
                    "name": "Save",
                    "module": "internal.headless.project",
                    "moduleType": "internal.headless.project",
                    "operation": "saveProjectHeadless",
                    "args": "",
                    "executable": "internal.headless.project",
                    "url": "/image/stableDiffusion_",
                    "type": "auto",
                    "help": "Save the project with the new settings.",
                    "requestType": "ui.project.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "67ab72db-2"
                }
            ],
            "uuid": "21faf981-d",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Headless"
        },
        {
            "name": "uai.builtin.headless.project.delete",
            "title": "Delete: Project",
            "path": "File/Project/Delete Project",
            "category": "Project",
            "icon": "",
            "hidden": false,
            "tags": [
                "Project",
                "UI"
            ],
            "description": "Edit the current Project.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Project"
            ],
            "panel": 2,
            "subwindows": [],
            "functions": [
                {
                    "name": "Delete",
                    "module": "internal.headless.project",
                    "moduleType": "internal.headless.project",
                    "operation": "deleteProjectHeadless",
                    "args": "",
                    "executable": "internal.headless.project",
                    "url": "/image/stableDiffusion_",
                    "type": "auto",
                    "help": "Delete the project",
                    "requestType": "ui.project.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "7b3d02e1-2"
                }
            ],
            "uuid": "979bfdeb-d",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Headless"
        },
        {
            "name": "uai.builtin.headless.app.quit",
            "title": "Quit",
            "path": "File/Quit",
            "category": "Project",
            "icon": "",
            "hidden": false,
            "tags": [
                "Project",
                "UI"
            ],
            "description": "Quit the app.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Project"
            ],
            "panel": 2,
            "subwindows": [],
            "functions": [
                {
                    "name": "Quit",
                    "module": "internal.headless.app",
                    "moduleType": "internal.headless.app",
                    "operation": "quit",
                    "args": "",
                    "executable": "internal.headless.app",
                    "url": "/image/stableDiffusion_",
                    "type": "auto",
                    "help": "Quit the app.",
                    "requestType": "ui.project.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "f933d09d-f2"
                }
            ],
            "uuid": "69a8a3be-c",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Headless"
        },
        {
            "name": "uai.builtin.headless.window.default",
            "title": "Default Editor",
            "path": "Window/Layouts/Default Editor",
            "category": "Window",
            "icon": "",
            "hidden": false,
            "tags": [
                "Window",
                "UI"
            ],
            "description": "Apply the Default editor Layout",
            "inputs": [
                "None"
            ],
            "outputs": [
                "None"
            ],
            "panel": 2,
            "subwindows": [],
            "functions": [
                {
                    "name": "Open",
                    "module": "internal.headless.window",
                    "moduleType": "internal.headless.window",
                    "operation": "showDefaultEditor",
                    "args": "",
                    "executable": "internal.headless.project",
                    "url": "",
                    "type": "auto",
                    "help": "Open Layout",
                    "requestType": "ui.project.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "fdc565be-7c"
                }
            ],
            "uuid": "cc335c32-f",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Headless"
        },
        {
            "name": "uai.builtin.headless.window.timelineeditor",
            "title": "Timeline Editor",
            "path": "Window/Layouts/Timeline Editor",
            "category": "Window",
            "icon": "",
            "hidden": false,
            "tags": [
                "Window",
                "UI"
            ],
            "description": "Apply the timeline editor Layout",
            "inputs": [
                "None"
            ],
            "outputs": [
                "None"
            ],
            "panel": 2,
            "subwindows": [],
            "functions": [
                {
                    "name": "Open",
                    "module": "internal.headless.window",
                    "moduleType": "internal.headless.window",
                    "operation": "showTimelineEditor",
                    "args": "",
                    "executable": "internal.headless.project",
                    "url": "",
                    "type": "auto",
                    "help": "Open Layout",
                    "requestType": "ui.project.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "0280a154-5a"
                }
            ],
            "uuid": "fa8fdd6c-e",
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": true,
            "toggleable": true,
            "isFX": false,
            "window": "Headless"
        },
        {
            "name": "uai.builtin.lua.test.print",
            "title": "Tset Lua Print",
            "path": "Test/Lua/Print",
            "category": "Image",
            "icon": "",
            "tags": [
                "Image",
                "Stable Diffusion",
                "XL Lightning",
                "Text2Image",
                "UAI"
            ],
            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "uuid": "983cea6c-c",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "uuid": "ff8e7dc7-7",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "c9e64bd9-f",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "model",
                            "label": "AI Model",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " ",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                            "required": false,
                            "help": "Select the AI Model to use for generating the image.",
                            "uuid": "51513bd0-5",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "6",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "40a6037d-3",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "3",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "c17579c5-5",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "be38cf60-3",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "int",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "de099f42-9",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "imagesToGenerate",
                            "label": "Images to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "placeholder": "1",
                            "min": "0",
                            "max": "8",
                            "required": true,
                            "help": "The amount of images to generate.",
                            "uuid": "55b2a3da-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "exportSize",
                            "label": "Image Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "S 1024X1024",
                                "S 512X512",
                                "S 1920X1920",
                                "L 1920X1080",
                                "L 1280X720",
                                "L 1024X576",
                                "P 1080X1920",
                                "P 720X1280",
                                "P 576X1240"
                            ],
                            "value": "S 1024X1024",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "0",
                            "max": "1000000",
                            "uuid": "ded52141-f",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "12f4e670-e",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "testString",
                    "moduleType": "lua",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "lua",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.print",
                    "icon": "fa-rocket",
                    "uuid": "0851bff1-4"
                }
            ],
            "uuid": "2871d851-a",
            "hidden": true,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.lua.test.pythonprint",
            "title": "Tset Lua Python Print",
            "path": "Test/Lua/Python Print",
            "category": "Image",
            "icon": "",
            "tags": [
                "Image",
                "Stable Diffusion",
                "XL Lightning",
                "Text2Image",
                "UAI"
            ],
            "description": "Test print a python string.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "output",
                            "label": "Output Image",
                            "type": "save",
                            "lines": "single",
                            "objectType": "Image",
                            "base64": true,
                            "placeholder": "",
                            "value": "",
                            "required": true,
                            "help": "The image to process.",
                            "uuid": "867c1f2f-2",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "objectType": "Image",
                            "base64": true,
                            "uuid": "9d7ca63f-c",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "objectType": "Image",
                            "base64": true,
                            "uuid": "cb95051e-8",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "93ddd6dd-8",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "model",
                            "label": "AI Model",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " ",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                            "required": false,
                            "help": "Select the AI Model to use for generating the image.",
                            "uuid": "f7040c93-9",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "6",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "a5f9a24b-8",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "3",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "55dbec7d-6",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "c4700da0-b",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "int",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "494cb647-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "imagesToGenerate",
                            "label": "Images to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "placeholder": "1",
                            "min": "0",
                            "max": "8",
                            "required": true,
                            "help": "The amount of images to generate.",
                            "uuid": "b0ccd31e-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "exportSize",
                            "label": "Image Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "S 1024X1024",
                                "S 512X512",
                                "S 1920X1920",
                                "L 1920X1080",
                                "L 1280X720",
                                "L 1024X576",
                                "P 1080X1920",
                                "P 720X1280",
                                "P 576X1240"
                            ],
                            "value": "S 1024X1024",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "0",
                            "max": "1000000",
                            "uuid": "d0ff34d6-8",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "0da82535-6",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "testPythonScript",
                    "moduleType": "lua",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "lua",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.nothing",
                    "icon": "fa-rocket",
                    "uuid": "7994a9d1-7"
                }
            ],
            "uuid": "4db51aae-d",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.3D.generate.test",
            "title": "Test Import 3D Model",
            "path": "AI/3D/TestRun",
            "category": "3D",
            "icon": "",
            "tags": [
                "3D",
                "UAI"
            ],
            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "uuid": "474328f0-4",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "uuid": "4c63b7b0-2",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "75e11b25-3",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "model",
                            "label": "AI Model",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " ",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                            "required": false,
                            "help": "Select the AI Model to use for generating the image.",
                            "uuid": "f7fa4693-7",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "6",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "de64e427-3",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "3",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "18d5720e-d",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "edf91864-7",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "int",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "be3d93e0-8",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "imagesToGenerate",
                            "label": "Images to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "placeholder": "1",
                            "min": "0",
                            "max": "8",
                            "required": true,
                            "help": "The amount of images to generate.",
                            "uuid": "52de1377-d",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "exportSize",
                            "label": "Image Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "S 1024X1024",
                                "S 512X512",
                                "S 1920X1920",
                                "L 1920X1080",
                                "L 1280X720",
                                "L 1024X576",
                                "P 1080X1920",
                                "P 720X1280",
                                "P 576X1240"
                            ],
                            "value": "S 1024X1024",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "0",
                            "max": "1000000",
                            "uuid": "a04e51ad-8",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "0afe9a5a-f",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "testRun_3D",
                    "moduleType": "python",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "python.exe",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.showMultiMedia3DModel",
                    "icon": "fa-rocket",
                    "uuid": "b6dcbe04-2"
                }
            ],
            "uuid": "e752496f-2",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.3D.generate.triposr",
            "title": "Image3D: TripoSR",
            "path": "AI/3D/Image3D/TripoSR",
            "category": "3D",
            "icon": "",
            "tags": [
                "3D",
                "UAI"
            ],
            "description": "This module generates an obj 3D object from an image.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "input",
                            "label": "Input Image",
                            "type": "file",
                            "lines": "single",
                            "objectType": "Image",
                            "base64": true,
                            "placeholder": "",
                            "value": "",
                            "required": true,
                            "help": "The image to process.",
                            "uuid": "0703c8ea-3",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "seed",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "objectType": "Image",
                            "base64": true,
                            "uuid": "df346a1e-2",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "2723523a-c",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "model",
                            "label": "AI Model",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "stabilityai/TripoSR",
                            "options": [
                                "stabilityai/TripoSR"
                            ],
                            "value": "stabilityai/TripoSR",
                            "required": false,
                            "help": "Select the AI Model to use for generating the image.",
                            "uuid": "3f79d6e2-5",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "removeBg",
                            "label": "Remove Bg",
                            "type": "bool",
                            "lines": "single",
                            "placeholder": false,
                            "value": false,
                            "required": false,
                            "help": "Whether to remove the BG from the input image",
                            "options": [
                                "stabilityai/TripoSR"
                            ],
                            "uuid": "b9c73afd-b",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "chunkSize",
                            "label": "Chunk Size",
                            "type": "int",
                            "lines": "single",
                            "value": "8192",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "options": [
                                "stabilityai/TripoSR"
                            ],
                            "uuid": "ae29745a-f",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "foregroundRatio",
                            "label": "Foreground Ratio",
                            "type": "float",
                            "lines": "single",
                            "value": "3",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "options": [
                                "stabilityai/TripoSR"
                            ],
                            "uuid": "710003ba-2",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "b64138ca-0",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "image23D_TripoSR",
                    "moduleType": "python",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "python.exe",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.base64InputImage",
                    "postFunction": "com.uai.showMultiMedia3DModel",
                    "icon": "fa-rocket",
                    "uuid": "f2b06a46-a"
                }
            ],
            "uuid": "6d9488ae-4",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.transform.base",
            "title": "Transform",
            "path": "AI/Transform/Base",
            "category": "Transform",
            "icon": "",
            "hidden": true,
            "automatic": [
                "image",
                "3d",
                "video",
                "2d"
            ],
            "tags": [
                "Transform",
                "Base",
                "UAI"
            ],
            "description": "This module provides basic transform capabilities.",
            "inputs": [
                "TransformSource",
                "None"
            ],
            "outputs": [
                "TransformedObject"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "position",
                            "label": "Position",
                            "type": "vector3",
                            "lines": "single",
                            "placeholder": "[0, 0, 0]",
                            "value": [
                                0,
                                0,
                                0
                            ],
                            "required": true,
                            "help": "Set the position of the object.",
                            "uuid": "c568090b-c",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "rotation",
                            "label": "Rotation",
                            "type": "vector3",
                            "lines": "single",
                            "placeholder": "[0, 0, 0]",
                            "value": [
                                0,
                                0,
                                0
                            ],
                            "required": true,
                            "help": "Set the rotation of the object.",
                            "uuid": "bc034bf0-5",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "scale",
                            "label": "Scale",
                            "type": "vector3",
                            "lines": "single",
                            "placeholder": "[1, 1, 1]",
                            "value": [
                                1,
                                1,
                                1
                            ],
                            "required": true,
                            "help": "Set the scale of the object.",
                            "uuid": "425b84c7-a",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "pivot",
                            "label": "Pivot",
                            "type": "vector3",
                            "lines": "single",
                            "placeholder": "[0, 0, 0]",
                            "value": [
                                0,
                                0,
                                0
                            ],
                            "required": true,
                            "help": "Set the pivot point of the object.",
                            "uuid": "80033f24-5",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "anchor",
                            "label": "Anchor",
                            "type": "vector3",
                            "lines": "single",
                            "placeholder": "[0, 0, 0]",
                            "value": [
                                0,
                                0,
                                0
                            ],
                            "required": true,
                            "help": "Set the anchor point of the object.",
                            "uuid": "77728286-8",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "flipX",
                            "label": "Flip X",
                            "type": "bool",
                            "lines": "single",
                            "placeholder": "false",
                            "value": false,
                            "required": true,
                            "help": "Set the X flip status of the object.",
                            "uuid": "7a36be86-3",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "flipY",
                            "label": "Flip Y",
                            "type": "bool",
                            "lines": "single",
                            "placeholder": "false",
                            "value": false,
                            "required": true,
                            "help": "Set the Y flip status of the object.",
                            "uuid": "65b72687-d",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "46ebc92a-6",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings"
                }
            ],
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setTransform",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the 3D Transform Matrix.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-cube",
                    "uuid": "c5edf9d2-6"
                }
            ],
            "uuid": "61ce372b-a",
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.pbrbsdfmaterial",
            "title": "3D PBR BSDF Material",
            "path": "AI/3D/PBR/Material",
            "category": "3D",
            "icon": "",
            "hidden": false,
            "tags": [
                "3D",
                "PBR",
                "BSDF",
                "Material",
                "UAI"
            ],
            "automatic": [
                "3d"
            ],
            "description": "This module provides PBR BSDF material settings including texture URLs and UV settings per texture.",
            "inputs": [
                "MaterialSource",
                "None"
            ],
            "outputs": [
                "Material"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Diffuse",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "baseColorTexture",
                            "label": "Base Color Texture URL",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "http://example.com/base_color.png",
                            "value": "",
                            "required": true,
                            "help": "URL for the base color texture.",
                            "uuid": "e9e828be-d",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "uvBaseColor",
                            "label": "Base Color UV Settings",
                            "type": "vector2",
                            "lines": "single",
                            "placeholder": "[1, 1]",
                            "value": [
                                1,
                                1
                            ],
                            "required": true,
                            "help": "UV settings for the base color texture.",
                            "uuid": "302b6214-8",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "9507b508-d",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "diffuse"
                },
                {
                    "title": "Normal",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "normalTexture",
                            "label": "Normal Texture URL",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "http://example.com/normal.png",
                            "value": "",
                            "required": true,
                            "help": "URL for the normal texture.",
                            "uuid": "928dc411-f",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "uvNormal",
                            "label": "Normal UV Settings",
                            "type": "vector2",
                            "lines": "single",
                            "placeholder": "[1, 1]",
                            "value": [
                                1,
                                1
                            ],
                            "required": true,
                            "help": "UV settings for the normal texture.",
                            "uuid": "a117a25b-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "06704102-a",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "normal"
                },
                {
                    "title": "Metallic",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "metallicTexture",
                            "label": "Metallic Texture URL",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "http://example.com/metallic.png",
                            "value": "",
                            "required": true,
                            "help": "URL for the metallic texture.",
                            "uuid": "190f61a9-d",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "uvMetallic",
                            "label": "Metallic UV Settings",
                            "type": "vector2",
                            "lines": "single",
                            "placeholder": "[1, 1]",
                            "value": [
                                1,
                                1
                            ],
                            "required": true,
                            "help": "UV settings for the metallic texture.",
                            "uuid": "7bff1031-e",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "4b0e16ba-5",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "metallic"
                },
                {
                    "title": "Roughness",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "roughnessTexture",
                            "label": "Roughness Texture URL",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "http://example.com/roughness.png",
                            "value": "",
                            "required": true,
                            "help": "URL for the roughness texture.",
                            "uuid": "35a423b0-6",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "uvRoughness",
                            "label": "Roughness UV Settings",
                            "type": "vector2",
                            "lines": "single",
                            "placeholder": "[1, 1]",
                            "value": [
                                1,
                                1
                            ],
                            "required": true,
                            "help": "UV settings for the roughness texture.",
                            "uuid": "525965c7-3",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "cd1bd702-1",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "roughness"
                },
                {
                    "title": "Ambient Occlusion",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "aoTexture",
                            "label": "Ambient Occlusion Texture URL",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "http://example.com/ao.png",
                            "value": "",
                            "required": true,
                            "help": "URL for the ambient occlusion texture.",
                            "uuid": "c59b6e96-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "uvAO",
                            "label": "Ambient Occlusion UV Settings",
                            "type": "vector2",
                            "lines": "single",
                            "placeholder": "[1, 1]",
                            "value": [
                                1,
                                1
                            ],
                            "required": true,
                            "help": "UV settings for the ambient occlusion texture.",
                            "uuid": "97592799-7",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "12158434-9",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "ambientocclusion"
                }
            ],
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setPbrbsdfmaterial",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/pbrbsdfmaterial/execute",
                    "type": "update",
                    "help": "Click to apply the PBR BSDF material.",
                    "requestType": "",
                    "responseType": "MterialResponse",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "2d0c2fd4-0"
                }
            ],
            "uuid": "0d83cdbc-f",
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.unlitmaterial",
            "title": "3D Unlit Material",
            "path": "AI/3D/Unlit/Material",
            "category": "3D",
            "icon": "",
            "hidden": false,
            "automatic": [
                "2d",
                "image",
                "video"
            ],
            "tags": [
                "3D",
                "Unlit",
                "Material",
                "UAI"
            ],
            "description": "This module provides unlit material settings including a base texture URL or base color.",
            "inputs": [
                "MaterialSource",
                "None"
            ],
            "outputs": [
                "Material"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Base Texture",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "baseTexture",
                            "label": "Base Texture URL",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "http://example.com/base_texture.png",
                            "value": "",
                            "required": false,
                            "help": "URL for the base texture.",
                            "uuid": "1ddbe5be-a",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "57606b7c-2",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "basetexture"
                },
                {
                    "title": "Base Color",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "baseColor",
                            "label": "Base Color",
                            "type": "color",
                            "lines": "single",
                            "placeholder": "#ffffff",
                            "value": "#ffffff",
                            "required": false,
                            "help": "Hex value for the base color.",
                            "uuid": "b3b45a6e-5",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "2c604564-e",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "basecolor"
                }
            ],
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setUnlitmaterial",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/pbrbsdfmaterial/execute",
                    "type": "update",
                    "help": "Click to apply the unlit material.",
                    "requestType": "MaterialRequest",
                    "responseType": "MaterialResponse",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "59512b2c-c"
                }
            ],
            "uuid": "99ef7020-9",
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.render.base",
            "title": "Render",
            "path": "AI/Render/Base",
            "category": "Render",
            "icon": "",
            "hidden": true,
            "tags": [
                "Render",
                "Base",
                "UAI"
            ],
            "automatic": [
                "image",
                "3d",
                "video",
                "2d"
            ],
            "description": "This module provides basic rendering capabilities.",
            "inputs": [
                "RenderSource",
                "None"
            ],
            "outputs": [
                "canvas.render"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Render Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "opacity",
                            "label": "Opacity",
                            "type": "float",
                            "lines": "single",
                            "placeholder": "1.0",
                            "value": 1.0,
                            "min": 0.0,
                            "max": 1.0,
                            "required": true,
                            "help": "Set the opacity level.",
                            "uuid": "0ff81b9f-c",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "inFrame",
                            "label": "In Frame",
                            "type": "int",
                            "lines": "single",
                            "placeholder": "0",
                            "value": 0,
                            "min": 0,
                            "max": 10000,
                            "required": true,
                            "help": "Set the starting frame.",
                            "uuid": "31019713-0",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "outFrame",
                            "label": "Out Frame",
                            "type": "int",
                            "lines": "single",
                            "placeholder": "300",
                            "value": 300,
                            "min": 0,
                            "max": 10000,
                            "required": true,
                            "help": "Set the ending frame.",
                            "uuid": "d89fab46-2",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "blendMode",
                            "label": "Blend Mode",
                            "type": "string",
                            "lines": "single",
                            "placeholder": "Over",
                            "value": "Over",
                            "required": true,
                            "help": "Set the blend mode.",
                            "min": 0.0,
                            "max": 1.0,
                            "uuid": "e12f21ab-6",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "title": "Channels",
                            "size": "Medium",
                            "fields": [
                                {
                                    "name": "rChannel",
                                    "label": "R Channel",
                                    "type": "select",
                                    "lines": "single",
                                    "placeholder": "",
                                    "options": [
                                        "R",
                                        "G",
                                        "B",
                                        "A",
                                        "Z"
                                    ],
                                    "value": "R",
                                    "required": true,
                                    "help": "Select the R Channel"
                                },
                                {
                                    "name": "gChannel",
                                    "label": "G Channel",
                                    "type": "select",
                                    "lines": "single",
                                    "placeholder": "",
                                    "options": [
                                        "R",
                                        "G",
                                        "B",
                                        "A",
                                        "Z"
                                    ],
                                    "value": "G",
                                    "required": true,
                                    "help": "Select the R Channel"
                                },
                                {
                                    "name": "bChannel",
                                    "label": "B Channel",
                                    "type": "select",
                                    "lines": "single",
                                    "placeholder": "",
                                    "options": [
                                        "R",
                                        "G",
                                        "B",
                                        "A",
                                        "Z"
                                    ],
                                    "value": "B",
                                    "required": true,
                                    "help": "Select the R Channel"
                                },
                                {
                                    "name": "aChannel",
                                    "label": "A Channel",
                                    "type": "select",
                                    "lines": "single",
                                    "placeholder": "",
                                    "options": [
                                        "R",
                                        "G",
                                        "B",
                                        "A",
                                        "Z"
                                    ],
                                    "value": "A",
                                    "required": true,
                                    "help": "Select the R Channel"
                                },
                                {
                                    "name": "zChannel",
                                    "label": "Z Channel",
                                    "type": "select",
                                    "lines": "single",
                                    "placeholder": "",
                                    "options": [
                                        "R",
                                        "G",
                                        "B",
                                        "A",
                                        "Z"
                                    ],
                                    "value": "Z",
                                    "required": true,
                                    "help": "Select the R Channel"
                                },
                                {
                                    "name": "invertAlpha",
                                    "label": "Invert Alpha",
                                    "type": "bool",
                                    "lines": "single",
                                    "placeholder": "false",
                                    "value": false,
                                    "required": true,
                                    "help": "Invert the alpha channel."
                                },
                                {
                                    "name": "colorTint",
                                    "label": "Color Tint",
                                    "type": "color4",
                                    "lines": "single",
                                    "placeholder": "[255,255,255,255]",
                                    "value": [
                                        255,
                                        255,
                                        255,
                                        255
                                    ],
                                    "required": true,
                                    "help": "Set the channel mix values."
                                }
                            ],
                            "name": "opacity",
                            "label": "Opacity",
                            "type": "float",
                            "lines": "single",
                            "placeholder": "1.0",
                            "value": 1.0,
                            "min": 0.0,
                            "max": 1.0,
                            "required": true,
                            "help": "Set the opacity level.",
                            "uuid": "26952352-f",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "19edad9c-5",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "rendersettings"
                }
            ],
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.2d.render",
                    "moduleType": "internal.2d.render",
                    "operation": "setMediaRenderSettings",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the 3D Transform Matrix.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-paint-brush",
                    "uuid": "aa3d3445-3"
                }
            ],
            "uuid": "cdc0c897-1",
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.audio.base",
            "title": "Audio",
            "path": "AI/Audio/Mix",
            "category": "Audio",
            "icon": "",
            "hidden": true,
            "tags": [
                "Audio",
                "Base",
                "Mix",
                "UAI"
            ],
            "automatic": [
                "audio",
                "video"
            ],
            "description": "This module provides basic audio mixing capabilities.",
            "inputs": [
                "AudioSource",
                "None"
            ],
            "outputs": [
                "MixedAudio"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "Volume",
                            "label": "Volume",
                            "type": "float",
                            "lines": "single",
                            "placeholder": "0.0",
                            "value": 0.0,
                            "min": -1000.0,
                            "max": 1000.0,
                            "required": true,
                            "help": "Set the volume level.",
                            "uuid": "c841b940-a",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "Balance",
                            "label": "Balance",
                            "type": "int",
                            "lines": "single",
                            "placeholder": "0",
                            "value": 0,
                            "min": -1.0,
                            "max": 1.0,
                            "required": true,
                            "help": "Set the audio LR balance.",
                            "uuid": "c9209a68-3",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "Mute",
                            "label": "Mute",
                            "type": "bool",
                            "lines": "single",
                            "placeholder": "false",
                            "value": false,
                            "required": true,
                            "help": "Mute the audio.",
                            "min": -1000.0,
                            "max": 1000.0,
                            "uuid": "430458b1-e",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "c29b3b8d-5",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                }
            ],
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.audio",
                    "moduleType": "internal.audio",
                    "operation": "setAudioBase",
                    "args": "",
                    "executable": "internal.audio",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the audio mix.",
                    "requestType": "fx.properties",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-rocket",
                    "uuid": "b1012191-d"
                }
            ],
            "uuid": "b7c418df-3",
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.audio.generate.test",
            "title": "Test Import Audio Model",
            "path": "AI/Audio/TestRun",
            "category": "Audio",
            "icon": "",
            "tags": [
                "Audio",
                "UAI"
            ],
            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Audio"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "uuid": "16369745-d",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "uuid": "d5b9f138-3",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "ff72e069-8",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "model",
                            "label": "AI Model",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " ",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                            "required": false,
                            "help": "Select the AI Model to use for generating the image.",
                            "uuid": "b340fab1-4",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "6",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "a58b23a8-f",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "3",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "cdcefff9-1",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "bf5ecefc-6",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "int",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "82c71db5-6",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "imagesToGenerate",
                            "label": "Images to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "placeholder": "1",
                            "min": "0",
                            "max": "8",
                            "required": true,
                            "help": "The amount of images to generate.",
                            "uuid": "1fd62729-e",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "exportSize",
                            "label": "Image Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "S 1024X1024",
                                "S 512X512",
                                "S 1920X1920",
                                "L 1920X1080",
                                "L 1280X720",
                                "L 1024X576",
                                "P 1080X1920",
                                "P 720X1280",
                                "P 576X1240"
                            ],
                            "value": "S 1024X1024",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "0",
                            "max": "1000000",
                            "uuid": "af084d4f-1",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "3769a16a-1",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "testRun_Audio",
                    "moduleType": "python",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "python.exe",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.showMultiMediaAudio",
                    "icon": "fa-rocket",
                    "uuid": "fcef0cff-7"
                }
            ],
            "uuid": "83d75978-2",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.image.generate.test",
            "title": "XL Lightning: T2I",
            "path": "AI/Image/Generate/TestRun",
            "category": "Image",
            "icon": "",
            "tags": [
                "Image",
                "Stable Diffusion",
                "XL Lightning",
                "Text2Image",
                "UAI"
            ],
            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "uuid": "7dbad9d8-4",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "uuid": "814243eb-f",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "06e9e0d7-f",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "model",
                            "label": "AI Model",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " ",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                            "required": false,
                            "help": "Select the AI Model to use for generating the image.",
                            "uuid": "571a4e48-6",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "6",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "00c96e33-4",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "3",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "663ff6d8-5",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "4786977f-f",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "int",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "fd088c14-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "imagesToGenerate",
                            "label": "Images to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "placeholder": "1",
                            "min": "0",
                            "max": "8",
                            "required": true,
                            "help": "The amount of images to generate.",
                            "uuid": "269a51a4-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "exportSize",
                            "label": "Image Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "S 1024X1024",
                                "S 512X512",
                                "S 1920X1920",
                                "L 1920X1080",
                                "L 1280X720",
                                "L 1024X576",
                                "P 1080X1920",
                                "P 720X1280",
                                "P 576X1240"
                            ],
                            "value": "S 1024X1024",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "0",
                            "max": "1000000",
                            "uuid": "fc1db7cb-a",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "f4fd1905-e",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "testRun_Image",
                    "moduleType": "python",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "python.exe",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.showMultiImageOutput",
                    "icon": "fa-rocket",
                    "uuid": "258f194d-6"
                }
            ],
            "uuid": "9b92c90b-7",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "FX"
        },
        {
            "name": "uai.builtin.video.generate.test",
            "title": "XL Lightning: T2I",
            "path": "AI/Video/Generate/TestRun",
            "category": "Image",
            "icon": "",
            "tags": [
                "Image",
                "Stable Diffusion",
                "XL Lightning",
                "Text2Image",
                "UAI"
            ],
            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "uuid": "0877ce6c-d",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "uuid": "45a57333-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "2f18d600-0",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "model",
                            "label": "AI Model",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " ",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                            "required": false,
                            "help": "Select the AI Model to use for generating the image.",
                            "uuid": "902d7724-8",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "6",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "18f771ef-c",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "3",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "6af98582-b",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "94329ec3-a",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "int",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "4655bd8e-8",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "imagesToGenerate",
                            "label": "Images to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "placeholder": "1",
                            "min": "0",
                            "max": "8",
                            "required": true,
                            "help": "The amount of images to generate.",
                            "uuid": "2a06b4fb-e",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "exportSize",
                            "label": "Image Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "S 1024X1024",
                                "S 512X512",
                                "S 1920X1920",
                                "L 1920X1080",
                                "L 1280X720",
                                "L 1024X576",
                                "P 1080X1920",
                                "P 720X1280",
                                "P 576X1240"
                            ],
                            "value": "S 1024X1024",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "0",
                            "max": "1000000",
                            "uuid": "03fc4198-9",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "a82f567b-c",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "testRun_Video",
                    "moduleType": "python",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "python.exe",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.showMultiVideoOutput",
                    "icon": "fa-rocket",
                    "uuid": "b80a1755-4"
                }
            ],
            "uuid": "d71be2c1-3",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "AIGenerate"
        },
        {
            "name": "uai.builtin.stableDiffusion.XLLightning",
            "title": "XL Lightning: T2I",
            "path": "AI/Image/Stable Diffusion/XL Lightning/Text 2 Image",
            "category": "Image",
            "icon": "",
            "tags": [
                "Image",
                "Stable Diffusion",
                "XL Lightning",
                "Text2Image",
                "UAI"
            ],
            "description": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "uuid": "1dd420db-5",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "uuid": "cf1b1c6b-9",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "8ba403fa-a",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "model",
                            "label": "AI Model",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " ",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "value": "SG161222/RealVisXL_V4.0_Lightning",
                            "required": false,
                            "help": "Select the AI Model to use for generating the image.",
                            "uuid": "b554c449-4",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "6",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "427beb18-1",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "3",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "options": [
                                "components.models.stablediffusion.xl"
                            ],
                            "uuid": "ca3cf2a7-c",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "e2a5191b-9",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "int",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "4188fff1-f",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "imagesToGenerate",
                            "label": "Images to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "placeholder": "1",
                            "min": "0",
                            "max": "8",
                            "required": true,
                            "help": "The amount of images to generate.",
                            "uuid": "e5abc139-4",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "exportSize",
                            "label": "Image Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "S 1024X1024",
                                "S 512X512",
                                "S 1920X1920",
                                "L 1920X1080",
                                "L 1280X720",
                                "L 1024X576",
                                "P 1080X1920",
                                "P 720X1280",
                                "P 576X1240"
                            ],
                            "value": "S 1024X1024",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "0",
                            "max": "1000000",
                            "uuid": "88ca5db0-8",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "8f885bc6-5",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "stableDiffusion_",
                    "moduleType": "python",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "python.exe",
                    "url": "/",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.showMultiImageOutput",
                    "icon": "fa-rocket",
                    "uuid": "29311d2a-7"
                },
                {
                    "name": "Generate",
                    "module": "com.uai.stableV.stableDiffusionXL.Lightning",
                    "moduleType": "com.uai.stableV",
                    "operation": "text2image",
                    "args": "",
                    "executable": "js",
                    "url": "https://uai.software/warp/api/v1/text/to/image/generate",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.createImageRequest",
                    "postFunction": "com.uai.showMultiImageOutput",
                    "icon": "fa-paint-brush",
                    "uuid": "29311d2a-9"
                }
            ],
            "uuid": "570537e7-7",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "AIGenerate"
        },
        {
            "name": "uai.builtin.stableDiffusion.xs512",
            "title": "SDXS 512: T2I",
            "path": "AI/Image/Stable Diffusion/XS512/Text 2 Image",
            "category": "Image",
            "icon": "",
            "tags": [
                "Image",
                "Stable Diffusion",
                "XS",
                "512",
                "Text2Image",
                "UAI"
            ],
            "description": "SDXS is a model that can generate high-resolution images in real-time based on prompt texts, trained using score distillation and feature matching.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "uuid": "04f30a8d-d",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "uuid": "f62e19e2-e",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "22a03d5d-5",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "uuid": "385a5ff5-7",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "0",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "uuid": "fc715668-6",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "3a52dc42-3",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "int",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "584b4d99-6",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "num_images_per_prompt",
                            "label": "Images to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "placeholder": "1",
                            "min": "0",
                            "max": "8",
                            "required": true,
                            "help": "The amount of images to generate.",
                            "uuid": "2033a26f-a",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "e1e77b5e-d",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "stableDiffusion_XS512",
                    "moduleType": "python",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "python.exe",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.showMultiImageOutput",
                    "icon": "fa-rocket",
                    "uuid": "024f4912-c"
                }
            ],
            "uuid": "3acf4678-5",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "AIGenerate"
        },
        {
            "name": "uai.builtin.stableDiffusion.xlphotobooth",
            "title": "SDXL : Photobooth",
            "path": "AI/Image/Stable Diffusion/XL Lightning/Photobooth",
            "category": "Image",
            "icon": "",
            "tags": [
                "Image",
                "Stable Diffusion",
                "XL",
                "Photobooth",
                "Image2Image",
                "UAI"
            ],
            "description": "Input a photo of a human and turn it into another photo of the same human..",
            "inputs": [
                "Text",
                "Image"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Medium",
                    "fields": [
                        {
                            "name": "input",
                            "label": "Input Image",
                            "type": "file",
                            "lines": "single",
                            "objectType": "Image",
                            "base64": true,
                            "placeholder": "",
                            "value": "",
                            "required": true,
                            "help": "The image to process.",
                            "uuid": "a47d0e6e-d",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "1{GENDER} . A solo headshot photo of a {ETHNICITY} {GENDER} person school photo.  glamour photograph. wearing {YEAR} style clothing and {YEAR} style hair. {AGE}yearold 1{GENDER}.  single person portrait. close up. face in the center of the frame. headshot.  cool kids clothing from {YEAR}. Film Grain. Shot of Film. ",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "objectType": "Image",
                            "base64": true,
                            "uuid": "c52979a1-7",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "Empty Space. Multiple panels. multiple photos. a photobook. Angry. multiple people. Not a school photo. not  {YEAR} style clothing. not {YEAR} style hair.  Many photos. many people. not a headshot. face not in the middle.  Neanderthal face. ugly face. High Contrast.",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "objectType": "Image",
                            "base64": true,
                            "uuid": "59959e46-b",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "f77ab96c-b",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Appearance",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "ethnicity",
                            "label": "Ethnicity",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " ",
                            "options": [
                                "African",
                                "African American",
                                "Asian",
                                "Caucasian",
                                "Hispanic",
                                "Indian",
                                "Middle Eastern",
                                "Multi-Racial",
                                "Native American",
                                "Pacific Islander",
                                "Other"
                            ],
                            "value": "Caucasian",
                            "required": false,
                            "help": "The Ethnicity of the person you want to generate.",
                            "uuid": "74a56966-6",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "gender",
                            "label": "Gender",
                            "type": "select",
                            "lines": "multi",
                            "value": "Female",
                            "placeholder": "",
                            "options": [
                                "Female",
                                "Male",
                                "Non-Binary",
                                "Other",
                                "Prefer Not to Say"
                            ],
                            "required": true,
                            "help": "The Gender of the person you want to generate.",
                            "uuid": "34a2ff92-c",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "scale",
                            "label": "Face Features Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "0.9",
                            "min": "0",
                            "max": "5",
                            "placeholder": "",
                            "required": true,
                            "help": "How much to blend the generated AI facial features with the input image.",
                            "options": [
                                "African",
                                "African American",
                                "Asian",
                                "Caucasian",
                                "Hispanic",
                                "Indian",
                                "Middle Eastern",
                                "Multi-Racial",
                                "Native American",
                                "Pacific Islander",
                                "Other"
                            ],
                            "uuid": "68e9ce06-4",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "s_scale",
                            "label": "Face Shape Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "0.8",
                            "min": "0",
                            "max": "5",
                            "placeholder": "",
                            "required": true,
                            "help": "How much to force the generated AI face to match the input face shape.",
                            "options": [
                                "African",
                                "African American",
                                "Asian",
                                "Caucasian",
                                "Hispanic",
                                "Indian",
                                "Middle Eastern",
                                "Multi-Racial",
                                "Native American",
                                "Pacific Islander",
                                "Other"
                            ],
                            "uuid": "b3df0d57-6",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "weight",
                            "label": "AI Face Fix Weight",
                            "type": "float",
                            "lines": "single",
                            "value": "0.8",
                            "min": "0",
                            "max": "1",
                            "placeholder": "",
                            "required": true,
                            "help": "How much to fix the generated AI face if it is very badly generated.",
                            "options": [
                                "African",
                                "African American",
                                "Asian",
                                "Caucasian",
                                "Hispanic",
                                "Indian",
                                "Middle Eastern",
                                "Multi-Racial",
                                "Native American",
                                "Pacific Islander",
                                "Other"
                            ],
                            "uuid": "bf13b701-6",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "8159e67d-0",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "appearance"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "20",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "uuid": "9ac8973d-3",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "2.5",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "uuid": "de0c965c-c",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "aligned",
                            "label": "Face Aligned",
                            "type": "bool",
                            "lines": "single",
                            "value": "false",
                            "min": "0",
                            "max": "100",
                            "placeholder": "",
                            "required": true,
                            "help": "If the input image of the face has already been aligned by a face detector, set this to true. Otherwise, set it to false.",
                            "uuid": "4c660802-8",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "saveFaceEmbeddings",
                            "label": "Save Face Embeddings",
                            "type": "bool",
                            "lines": "single",
                            "objectType": "ai",
                            "placeholder": "",
                            "value": "false",
                            "required": true,
                            "help": "Save the face embeddings of the input image for future use.",
                            "min": "0",
                            "max": "100",
                            "uuid": "ffc7977b-5",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "8f33f422-8",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "seed",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "0",
                            "max": "1000000",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "a6f9de64-c",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "num_images_per_prompt",
                            "label": "Images to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "1",
                            "placeholder": "1",
                            "min": "0",
                            "max": "8",
                            "required": true,
                            "help": "The amount of images to generate.",
                            "uuid": "53eabee4-9",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "exportSize",
                            "label": "Image Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "",
                            "options": [
                                "S 1024X1024",
                                "S 512X512",
                                "S 1920X1920",
                                "L 1920X1080",
                                "L 1280X720",
                                "L 1024X576",
                                "P 1080X1920",
                                "P 720X1280",
                                "P 576X1240"
                            ],
                            "value": "S 1024X1024",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "0",
                            "max": "1000000",
                            "uuid": "eff913bd-9",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "dc855576-a",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "stableDiffusionXL_Photobooth",
                    "moduleType": "python",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "python.exe",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.showMultiImageOutput",
                    "icon": "fa-rocket",
                    "uuid": "6dd8af25-3"
                }
            ],
            "uuid": "6e7a04b3-8",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "AIGenerate"
        },
        {
            "name": "uai.builtin.animatediff.Lightning.text2video",
            "title": "Animate Diff Lightning",
            "path": "AI/Video/Animate Diff/Lightning/Text 2 Video",
            "category": "Video",
            "tags": [
                "Video",
                "Animate Diff",
                "Lightning",
                "SD1.5",
                "UAI"
            ],
            "description": "This module generates an animated video  using the Animate Diff method at a rather quick sped.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Video"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "prompt",
                            "label": "Prompt",
                            "type": "string",
                            "lines": "multi",
                            "placeholder": "",
                            "value": "A Photograph of a dog. high quality. Cinematic lighting.",
                            "required": true,
                            "help": "Enter the text prompt for generating the image.",
                            "uuid": "cc039d67-5",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "neg_prompt",
                            "label": "Negative Prompt",
                            "type": "string",
                            "lines": "multi",
                            "value": "bad quality. low resolution",
                            "placeholder": "",
                            "required": true,
                            "help": "Enter the negative text prompt for generating the image.",
                            "uuid": "83142876-7",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "5a54b3e4-3",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                },
                {
                    "title": "Advanced Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "steps",
                            "label": "Steps",
                            "type": "int",
                            "lines": "single",
                            "value": "8",
                            "min": "1",
                            "max": "200",
                            "placeholder": "",
                            "required": true,
                            "help": "The amount of imastepsges to generate.",
                            "uuid": "0b9d9ea3-6",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "guidance_scale",
                            "label": "Config Scale",
                            "type": "float",
                            "lines": "single",
                            "value": "5",
                            "min": "1",
                            "max": "80",
                            "placeholder": "",
                            "required": true,
                            "help": "The configuration scale.",
                            "uuid": "32fa4350-4",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "encodeSize",
                            "label": "Encoding Frame Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": "S 512X512",
                            "options": [
                                "S 512X512",
                                "P 576X1240"
                            ],
                            "value": "S 512X512",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "1",
                            "max": "200",
                            "uuid": "abe32a7b-f",
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "encodedFps",
                            "label": "Encoding Frames Per Second",
                            "type": "float",
                            "lines": "single",
                            "value": "8",
                            "placeholder": "8",
                            "min": "1",
                            "max": "80",
                            "required": true,
                            "help": "Enter the frames per second for the generated frames.",
                            "uuid": "3c23683a-1",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "f0772379-a",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "advancedsettings"
                },
                {
                    "title": "Output",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "seed",
                            "label": "Seed",
                            "type": "int",
                            "lines": "single",
                            "value": "512",
                            "placeholder": "512",
                            "min": "1",
                            "max": "1065061510",
                            "required": true,
                            "help": "Enter the seed value for randomizing the image generation.",
                            "uuid": "29106076-0",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "imagesToGenerate",
                            "label": "Frames to Generate",
                            "type": "int",
                            "lines": "single",
                            "value": "16",
                            "min": "1",
                            "max": "80",
                            "placeholder": "1",
                            "required": true,
                            "help": "The amount of frames to generate.",
                            "uuid": "87bdb480-4",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "fps",
                            "label": "Frames Per Second",
                            "type": "float",
                            "lines": "single",
                            "value": "24",
                            "placeholder": "24",
                            "required": true,
                            "help": "Enter the frames per second for the video.",
                            "min": "1",
                            "max": "1065061510",
                            "uuid": "39e2f243-a",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "exportSize",
                            "label": "Frame Size",
                            "type": "select",
                            "lines": "single",
                            "placeholder": " 1024x1024",
                            "options": [
                                "S 1024X1024",
                                "S 512X512",
                                "S 1920x1920",
                                "L 1920X1080",
                                "L 1280X720",
                                "L 1024X576",
                                "P 1080X1920",
                                "P 720X1280",
                                "P 576X1240"
                            ],
                            "value": "S 1024x1024",
                            "required": false,
                            "help": "Select the size of the generated image.",
                            "min": "1",
                            "max": "1065061510",
                            "uuid": "3284c8da-5",
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "uuid": "a49c9326-2",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "buttons": [],
                    "name": "output"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "moduleType": "python",
                    "module": "animateDiff",
                    "executeable": "python.exe",
                    "args": "",
                    "operation": "RunProcess",
                    "url": "/image/animateDiff",
                    "type": "submit",
                    "help": "Click to generate the video.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.animateDiff.Lightning.text2video",
                    "postFunction": "com.uai.showMultiVideoOutput",
                    "icon": "fa-rocket",
                    "uuid": "0a66a487-8",
                    "executable": "internal.3d"
                }
            ],
            "uuid": "2cdb0ba2-e",
            "icon": "",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "AIGenerate"
        },
        {},
        {
            "uuid": "7b747cd4-5245-4afe-9371-7c662a6e32e3",
            "category": "Transform",
            "icon": "",
            "hidden": false,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "image_input",
                            "label": "Video",
                            "placeholder": "",
                            "type": "file",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "ad757d4d-452e-4c5b-a5b4-6bc14e53b466",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "audio_input",
                            "label": "Audio",
                            "placeholder": "",
                            "type": "file",
                            "lines": "single",
                            "value": "",
                            "properties": {
                                "type": "\"filepath\""
                            },
                            "help": "This is a help text",
                            "uuid": "ae6ee0d2-14c1-44be-9391-469ebc09b946",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "tts_button",
                            "label": "",
                            "placeholder": "",
                            "type": "button",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "69dd2b59-e386-4c7c-8b98-817ccd38519d",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "idleAnimationOutput",
                            "label": "Animation Output",
                            "placeholder": "",
                            "type": "file",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "c167821a-b974-4161-b8ea-462b2e0ec0a3",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        }
                    ],
                    "uuid": "c55dabef-4",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                }
            ],
            "name": "uai.extension.retalker.audio",
            "title": "ReTalker: Audio Driven Lipsync",
            "path": "AI/Video/Lipsync/ReTalker/Audio Driven Lipsync",
            "automatic": [],
            "tags": [
                "audio",
                " lipsync"
            ],
            "description": "Drive a video's lipsync with an audio file",
            "inputs": [],
            "outputs": [],
            "panel": 2,
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setTransform",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the 3D Transform Matrix.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-cube",
                    "uuid": "d73d58e8-0287-4cb0-8ca4-a6cd882d1b32"
                }
            ],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "FX"
        },
        {
            "uuid": "e6cba1cb-2b8c-4075-b931-882785c4eea9",
            "category": "Transform",
            "icon": "",
            "hidden": false,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "image_input",
                            "label": "Video",
                            "placeholder": "",
                            "type": "file",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "2029a928-ff46-4c22-a870-96b25cf39fc4",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "text",
                            "label": "Text",
                            "placeholder": "",
                            "type": "string",
                            "lines": "single",
                            "value": "",
                            "properties": {
                                "placeholder": "\"What",
                                "lines": "1"
                            },
                            "help": "This is a help text",
                            "uuid": "3ec29a73-654b-43df-8708-5dd06761ee22",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "tts_button",
                            "label": "",
                            "placeholder": "",
                            "type": "button",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "c4c28382-201e-49d0-b89a-194629b3ba71",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "idleAnimationOutput",
                            "label": "Animation Output",
                            "placeholder": "",
                            "type": "file",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "dbdfaeb1-6091-4661-af61-293d23d45b12",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        }
                    ],
                    "uuid": "d7443ab6-a",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                }
            ],
            "name": "uai.extension.retalker.tts",
            "title": "ReTalker: TTS Driven Lipsync",
            "path": "AI/Video/Lipsync/ReTalker/TTS Animator",
            "automatic": [],
            "tags": [
                "audio",
                "lipsync"
            ],
            "description": "Drive a video's lipsync with ReTalker",
            "inputs": [],
            "outputs": [],
            "panel": 2,
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setTransform",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the 3D Transform Matrix.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-cube",
                    "uuid": "179ffd61-cba2-4c8b-8bbf-5e1934d84576"
                }
            ],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "FX"
        },
        {
            "uuid": "m00cgeu1mn7qu",
            "category": "Transform",
            "icon": "",
            "hidden": true,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "image_input",
                            "label": "Video",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00cgeu14w5dr",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "audio_input",
                            "label": "Audio",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00cgeu18bq7p",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "tts_button",
                            "label": "",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00cgeu19kvq7",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "idleAnimationOutput",
                            "label": "Animation Output",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00cgeu1stvy1",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        }
                    ],
                    "uuid": "3c026d28-0",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                }
            ],
            "name": "uai.extension.wav2lip.audiovideo",
            "title": "Wav2Lip: Audio Video",
            "path": "AI/Video/Lipsync/Wav2Lip/Augment Video Lipsync By Audio",
            "automatic": [],
            "tags": [],
            "description": "Generate a video of a character with animated speech from audio and a video.",
            "inputs": [],
            "outputs": [],
            "panel": 2,
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setTransform",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the 3D Transform Matrix.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-cube",
                    "uuid": "m00cgeu1x3nck"
                }
            ],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "FX"
        },
        {
            "uuid": "m00cigf7v61r7",
            "category": "Transform",
            "icon": "",
            "hidden": true,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "image_input",
                            "label": "Video",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00cigf72jz3i",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "text",
                            "label": "Text",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00cigf7av4bp",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "tts_button",
                            "label": "",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00cigf709nid",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "idleAnimationOutput",
                            "label": "Animation Output",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00cigf7xu5md",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        }
                    ],
                    "uuid": "684e3380-b",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                }
            ],
            "name": "uai.extension.wav2lip.tts",
            "title": "Wav2Lip: TTS Video",
            "path": "AI/Video/Lipsync/Wav2Lip/TTS Video Lipsync",
            "automatic": [],
            "tags": [],
            "description": "",
            "inputs": [],
            "outputs": [],
            "panel": 2,
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setTransform",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the 3D Transform Matrix.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-cube",
                    "uuid": "m00cigf7rfx3u"
                }
            ],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "FX"
        },
        {
            "uuid": "fbf8e3ce-f730-419e-9f36-29024ab57d12",
            "category": "Transform",
            "icon": "",
            "hidden": false,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "image_input",
                            "label": "Image",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "db2e4942-3ec2-4eb3-9a47-70e4f0ad2d08",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "audio_input",
                            "label": "Audio",
                            "placeholder": "",
                            "type": "file",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "046716b0-b1c5-4626-893a-bad8b96c761f",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        }
                    ],
                    "uuid": "333d6343-0",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                },
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "expression_scale",
                            "label": "Expression Scale",
                            "min": 0,
                            "max": 1,
                            "step": 0.01,
                            "value": 1,
                            "type": "select",
                            "properties": {
                                "minimum": "0.1,",
                                "maximum": "5.0,",
                                "value": "1.9"
                            },
                            "help": "This is a help text",
                            "uuid": "9a781dbb-30e9-41e9-aa60-72a0f183a075",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "lines": "single",
                            "placeholder": "Johno",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "head_motion_scale",
                            "label": "Head Motion Scale",
                            "min": 0,
                            "max": 1,
                            "step": 0.01,
                            "value": 1,
                            "type": "select",
                            "properties": {
                                "minimum": "0.1,",
                                "maximum": "5.0,",
                                "value": "0.2"
                            },
                            "help": "This is a help text",
                            "uuid": "eb2129d4-aa31-4988-84c6-908785d287a8",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "lines": "single",
                            "placeholder": "Johno",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "preprocess",
                            "label": "Preprocess",
                            "placeholder": "",
                            "type": "select",
                            "lines": "single",
                            "value": "",
                            "options": [],
                            "properties": {
                                "choices": "[\"resize\",",
                                "value": "\"resize\""
                            },
                            "help": "This is a help text",
                            "uuid": "e8aa1db4-008c-49af-9f77-9eed057adb52",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "required": true
                        },
                        {
                            "name": "extraArgs",
                            "label": "Extra Args",
                            "placeholder": "",
                            "type": "string",
                            "lines": "single",
                            "value": "",
                            "properties": {
                                "placeholder": "\"Extra",
                                "lines": "1"
                            },
                            "help": "This is a help text",
                            "uuid": "deb0af78-e36e-419c-9c0c-b3a2747f7efc",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "tts_button",
                            "label": "",
                            "placeholder": "",
                            "type": "button",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "2007dd4c-2395-4c67-b01e-7c7a54ee9d9b",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "idleAnimationOutput",
                            "label": "Animation Output",
                            "placeholder": "",
                            "type": "file",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "7f0a2600-cdc6-4047-9685-7c2c33134dd6",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        }
                    ],
                    "uuid": "d9521768-5",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                }
            ],
            "name": "uai.extension.sadtalker.audio",
            "title": "SadTalker: Audio Driven Lipsync",
            "path": "AI/Video/Lipsync/SadTalker/Audio Driven Lipsync",
            "automatic": [],
            "tags": [
                "Audio",
                " lipsync"
            ],
            "description": "Drive a video's lipsync animation with an audio file.",
            "inputs": [],
            "outputs": [],
            "panel": 2,
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setTransform",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the 3D Transform Matrix.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-cube",
                    "uuid": "8d5a2ff3-16fb-4f9a-9336-09e5dfdc36ff"
                }
            ],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "FX"
        },
        {
            "uuid": "505173e9-cad2-4162-a12a-0b075d601c09",
            "category": "Transform",
            "icon": "",
            "hidden": false,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "image_input",
                            "label": "Image",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "53aaeaf6-f679-44aa-9a3b-ce3f607e8546",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        }
                    ],
                    "uuid": "b60ee533-5",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                },
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "expression_scale",
                            "label": "Expression Scale",
                            "min": 0,
                            "max": 1,
                            "step": 0.01,
                            "value": 1,
                            "type": "select",
                            "properties": {
                                "minimum": "0.1,",
                                "maximum": "5.0,",
                                "value": "1.9"
                            },
                            "help": "This is a help text",
                            "uuid": "84fc03cd-68ba-4eeb-b547-adbb30f03618",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "lines": "single",
                            "placeholder": "Johno",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "head_motion_scale",
                            "label": "Head Motion Scale",
                            "min": 0,
                            "max": 1,
                            "step": 0.01,
                            "value": 1,
                            "type": "select",
                            "properties": {
                                "minimum": "0.1,",
                                "maximum": "5.0,",
                                "value": "0.2"
                            },
                            "help": "This is a help text",
                            "uuid": "50e499e5-76af-4572-b564-615ddf4fb60f",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "lines": "single",
                            "placeholder": "Johno",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "preprocess",
                            "label": "Preprocess",
                            "placeholder": "",
                            "type": "select",
                            "lines": "single",
                            "value": "",
                            "options": [],
                            "properties": {
                                "choices": "[\"resize\",",
                                "value": "\"resize\""
                            },
                            "help": "This is a help text",
                            "uuid": "26805821-16d6-4523-875c-94ece8e753a6",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "required": true
                        },
                        {
                            "name": "extraArgs",
                            "label": "Extra Args",
                            "placeholder": "",
                            "type": "string",
                            "lines": "single",
                            "value": "",
                            "properties": {
                                "placeholder": "\"Extra",
                                "lines": "1"
                            },
                            "help": "This is a help text",
                            "uuid": "2e8906a8-5fd6-438a-9c1d-cccd8d650af4",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "tts_button",
                            "label": "",
                            "placeholder": "",
                            "type": "button",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "2f18d6ee-91af-43f6-85c6-548a0f132ecd",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "idleAnimationOutput",
                            "label": "Animation Output",
                            "placeholder": "",
                            "type": "file",
                            "lines": "single",
                            "value": "",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "518df76c-e28c-4765-9d2b-a1f7819704fd",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        }
                    ],
                    "uuid": "78901cd1-7",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                }
            ],
            "name": "uai.extension.sadtalker.tts",
            "title": "SadTalker: TTS Lipsync",
            "path": "AI/Video/Lipsync/SadTalker/TTS Animator",
            "automatic": [],
            "tags": [
                "Audio",
                " lipsync",
                " tts"
            ],
            "description": "Augment a video's lipsync with Text to Speech Audio",
            "inputs": [],
            "outputs": [],
            "panel": 2,
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setTransform",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the 3D Transform Matrix.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-cube",
                    "uuid": "37bd32ae-10ed-48b5-b293-a73de2205aa6"
                }
            ],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "FX"
        },
        {
            "uuid": "m00dsd5v350k6",
            "category": "Transform",
            "icon": "",
            "hidden": true,
            "subwindows": [
                {
                    "title": "Settings",
                    "size": "Large",
                    "fields": [
                        {
                            "name": "image_input",
                            "label": "Image",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00dsd5u75ptv",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "idleSlider",
                            "label": "Idle Duration",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00dsd5virbvm",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "tts_button",
                            "label": "",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00dsd5vicjql",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        },
                        {
                            "name": "idleAnimationOutput",
                            "label": "Idle Animation Output",
                            "placeholder": "",
                            "lines": "single",
                            "value": "",
                            "type": "string",
                            "properties": {},
                            "help": "This is a help text",
                            "uuid": "m00dsd5vgq7mc",
                            "keyframeable": true,
                            "keyframeType": "all",
                            "options": [],
                            "required": true
                        }
                    ],
                    "uuid": "aff0ba19-a",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "settings",
                    "buttons": []
                }
            ],
            "name": "uai.extension.sadtalker.idle",
            "title": "SadTalker: Idle Animator",
            "path": "AI/Video/Lipsync/SadTalker/Idle Animator",
            "automatic": [],
            "tags": [],
            "description": "",
            "inputs": [],
            "outputs": [],
            "panel": 2,
            "functions": [
                {
                    "name": "Run",
                    "module": "internal.3d",
                    "moduleType": "internal.3d",
                    "operation": "setTransform",
                    "args": "",
                    "executable": "internal.3d",
                    "url": "/image/stableDiffusion_",
                    "type": "update",
                    "help": "Process the 3D Transform Matrix.",
                    "requestType": "fx.properties",
                    "responseType": "",
                    "preFunction": "",
                    "postFunction": "",
                    "icon": "fa-cube",
                    "uuid": "m00dsd5vvnzyo"
                }
            ],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": false,
            "window": "FX"
        },
        {
            "name": "uai.builtin.image.augment.facefix",
            "title": "Face Fix",
            "path": "AI/Image/Face Fix",
            "category": "Image",
            "icon": "",
            "tags": [
                "Image",
                "Augment",
                "Face",
                "UAI"
            ],
            "description": "Fix an AI generated face to look more realistic.",
            "inputs": [
                "Text",
                "None"
            ],
            "outputs": [
                "Image"
            ],
            "panel": 2,
            "subwindows": [
                {
                    "title": "Basic Settings",
                    "size": "Small",
                    "fields": [
                        {
                            "name": "input",
                            "label": "Input Image",
                            "type": "file",
                            "lines": "single",
                            "objectType": "Image",
                            "base64": true,
                            "placeholder": "",
                            "value": "",
                            "required": true,
                            "help": "The image to process.",
                            "uuid": "818e8b8f-f",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        },
                        {
                            "name": "weight",
                            "label": "Blend",
                            "type": "float",
                            "lines": "single",
                            "value": "0.8",
                            "min": "0",
                            "max": "1",
                            "placeholder": "",
                            "required": true,
                            "help": "How much to blend the final augmented face with the original face.",
                            "objectType": "Image",
                            "base64": true,
                            "uuid": "84b2f5cb-5",
                            "options": [],
                            "keyframeable": true,
                            "keyframeType": "all"
                        }
                    ],
                    "buttons": [],
                    "uuid": "2ddcb432-8",
                    "enabled": true,
                    "toggleable": true,
                    "initialState": false,
                    "name": "basicsettings"
                }
            ],
            "functions": [
                {
                    "name": "Generate",
                    "module": "config/uai/image/faceFix/index",
                    "moduleType": "lua",
                    "operation": "RunProcess",
                    "args": "",
                    "executable": "python.exe",
                    "url": "/image/stableDiffusion_",
                    "type": "submit",
                    "help": "Click to generate the image.",
                    "requestType": "ImageRequestConsole",
                    "responseType": "MultipleMediaRequest",
                    "preFunction": "com.uai.stableV.stableDiffusionXL.Lightning.text2image",
                    "postFunction": "com.uai.showMultiMediaImage",
                    "icon": "fa-rocket",
                    "uuid": "d5f5180b-2"
                }
            ],
            "uuid": "fa555bed-5",
            "hidden": false,
            "automatic": [],
            "documentation": "https://uai.software/docs",
            "initialState": false,
            "toggleable": true,
            "isFX": true,
            "window": "AIGenerate"
        }
    ]
};
    
    const uaiComponentFileMap = [
    {
        "id": "File",
        "name": "Create: Project",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "File.Project",
        "name": "Create: Project",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File"
    },
    {
        "id": "File.Project.New",
        "name": "Create: Project",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File.Project"
    },
    {
        "id": "File",
        "name": "Create: Sequence",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "File.Sequence",
        "name": "Create: Sequence",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File"
    },
    {
        "id": "File.Sequence.Newsequence",
        "name": "Create: Sequence",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File.Sequence"
    },
    {
        "id": "Edit",
        "name": "Edit: Project Settings",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Edit.Projectsettings",
        "name": "Edit: Project Settings",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Edit"
    },
    {
        "id": "Edit",
        "name": "Edit: Sequence Settings",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Edit.Sequencesettings",
        "name": "Edit: Sequence Settings",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Edit"
    },
    {
        "id": "File",
        "name": "Save: Project As",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "File.Project",
        "name": "Save: Project As",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File"
    },
    {
        "id": "File.Project.Saveprojectas",
        "name": "Save: Project As",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File.Project"
    },
    {
        "id": "File",
        "name": "Open: Project",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "File.Project",
        "name": "Open: Project",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File"
    },
    {
        "id": "File.Project.Open",
        "name": "Open: Project",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File.Project"
    },
    {
        "id": "Ai",
        "name": "Split Stems",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Audio",
        "name": "Split Stems",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Audio.Splitstems",
        "name": "Split Stems",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Audio"
    },
    {
        "id": "Ai",
        "name": "Music Gen: Text 2 Music Advanced ",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Audio",
        "name": "Music Gen: Text 2 Music Advanced ",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Audio.Generate",
        "name": "Music Gen: Text 2 Music Advanced ",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Audio"
    },
    {
        "id": "Ai.Audio.Generate.Text2music",
        "name": "Music Gen: Text 2 Music Advanced ",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Audio.Generate"
    },
    {
        "id": "Ai.Audio.Generate.Text2music.Advanced",
        "name": "Music Gen: Text 2 Music Advanced ",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Audio.Generate.Text2music"
    },
    {
        "id": "Ai",
        "name": "Music Gen: Lyrics Generator",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Text",
        "name": "Music Gen: Lyrics Generator",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Text.Generate",
        "name": "Music Gen: Lyrics Generator",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Text"
    },
    {
        "id": "Ai.Text.Generate.Lyrics",
        "name": "Music Gen: Lyrics Generator",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Text.Generate"
    },
    {
        "id": "File",
        "name": "Save: Project",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "File.Project",
        "name": "Save: Project",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File"
    },
    {
        "id": "File.Project.Saveproject",
        "name": "Save: Project",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File.Project"
    },
    {
        "id": "File",
        "name": "Delete: Project",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "File.Project",
        "name": "Delete: Project",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File"
    },
    {
        "id": "File.Project.Deleteproject",
        "name": "Delete: Project",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File.Project"
    },
    {
        "id": "File",
        "name": "Quit",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "File.Quit",
        "name": "Quit",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "File"
    },
    {
        "id": "Window",
        "name": "Default Editor",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Window.Layouts",
        "name": "Default Editor",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Window"
    },
    {
        "id": "Window.Layouts.Defaulteditor",
        "name": "Default Editor",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Window.Layouts"
    },
    {
        "id": "Window",
        "name": "Timeline Editor",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Window.Layouts",
        "name": "Timeline Editor",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Window"
    },
    {
        "id": "Window.Layouts.Timelineeditor",
        "name": "Timeline Editor",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Window.Layouts"
    },
    {
        "id": "Test",
        "name": "Tset Lua Print",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Test.Lua",
        "name": "Tset Lua Print",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Test"
    },
    {
        "id": "Test.Lua.Print",
        "name": "Tset Lua Print",
        "isDir": false,
        "isHidden": true,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Test.Lua"
    },
    {
        "id": "Test",
        "name": "Tset Lua Python Print",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Test.Lua",
        "name": "Tset Lua Python Print",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Test"
    },
    {
        "id": "Test.Lua.Pythonprint",
        "name": "Tset Lua Python Print",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Test.Lua"
    },
    {
        "id": "Ai",
        "name": "Test Import 3D Model",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.3d",
        "name": "Test Import 3D Model",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.3d.Testrun",
        "name": "Test Import 3D Model",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.3d"
    },
    {
        "id": "Ai",
        "name": "Image3D: TripoSR",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.3d",
        "name": "Image3D: TripoSR",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.3d.Image3d",
        "name": "Image3D: TripoSR",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.3d"
    },
    {
        "id": "Ai.3d.Image3d.Triposr",
        "name": "Image3D: TripoSR",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.3d.Image3d"
    },
    {
        "id": "Ai",
        "name": "Transform",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Transform",
        "name": "Transform",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Transform.Base",
        "name": "Transform",
        "isDir": false,
        "isHidden": true,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Transform"
    },
    {
        "id": "Ai",
        "name": "3D PBR BSDF Material",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.3d",
        "name": "3D PBR BSDF Material",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.3d.Pbr",
        "name": "3D PBR BSDF Material",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.3d"
    },
    {
        "id": "Ai.3d.Pbr.Material",
        "name": "3D PBR BSDF Material",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.3d.Pbr"
    },
    {
        "id": "Ai",
        "name": "3D Unlit Material",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.3d",
        "name": "3D Unlit Material",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.3d.Unlit",
        "name": "3D Unlit Material",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.3d"
    },
    {
        "id": "Ai.3d.Unlit.Material",
        "name": "3D Unlit Material",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.3d.Unlit"
    },
    {
        "id": "Ai",
        "name": "Render",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Render",
        "name": "Render",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Render.Base",
        "name": "Render",
        "isDir": false,
        "isHidden": true,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Render"
    },
    {
        "id": "Ai",
        "name": "Audio",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Audio",
        "name": "Audio",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Audio.Mix",
        "name": "Audio",
        "isDir": false,
        "isHidden": true,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Audio"
    },
    {
        "id": "Ai",
        "name": "Test Import Audio Model",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Audio",
        "name": "Test Import Audio Model",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Audio.Testrun",
        "name": "Test Import Audio Model",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Audio"
    },
    {
        "id": "Ai",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Image",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Image.Generate",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image"
    },
    {
        "id": "Ai.Image.Generate.Testrun",
        "name": "XL Lightning: T2I",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image.Generate"
    },
    {
        "id": "Ai",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Video",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Video.Generate",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video"
    },
    {
        "id": "Ai.Video.Generate.Testrun",
        "name": "XL Lightning: T2I",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Generate"
    },
    {
        "id": "Ai",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Image",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Image.Stablediffusion",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image"
    },
    {
        "id": "Ai.Image.Stablediffusion.Xllightning",
        "name": "XL Lightning: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image.Stablediffusion"
    },
    {
        "id": "Ai.Image.Stablediffusion.Xllightning.Text2image",
        "name": "XL Lightning: T2I",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image.Stablediffusion.Xllightning"
    },
    {
        "id": "Ai",
        "name": "SDXS 512: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Image",
        "name": "SDXS 512: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Image.Stablediffusion",
        "name": "SDXS 512: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image"
    },
    {
        "id": "Ai.Image.Stablediffusion.Xs512",
        "name": "SDXS 512: T2I",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image.Stablediffusion"
    },
    {
        "id": "Ai.Image.Stablediffusion.Xs512.Text2image",
        "name": "SDXS 512: T2I",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image.Stablediffusion.Xs512"
    },
    {
        "id": "Ai",
        "name": "SDXL : Photobooth",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Image",
        "name": "SDXL : Photobooth",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Image.Stablediffusion",
        "name": "SDXL : Photobooth",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image"
    },
    {
        "id": "Ai.Image.Stablediffusion.Xllightning",
        "name": "SDXL : Photobooth",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image.Stablediffusion"
    },
    {
        "id": "Ai.Image.Stablediffusion.Xllightning.Photobooth",
        "name": "SDXL : Photobooth",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image.Stablediffusion.Xllightning"
    },
    {
        "id": "Ai",
        "name": "Animate Diff Lightning",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Video",
        "name": "Animate Diff Lightning",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Video.Animatediff",
        "name": "Animate Diff Lightning",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video"
    },
    {
        "id": "Ai.Video.Animatediff.Lightning",
        "name": "Animate Diff Lightning",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Animatediff"
    },
    {
        "id": "Ai.Video.Animatediff.Lightning.Text2video",
        "name": "Animate Diff Lightning",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Animatediff.Lightning"
    },
    {
        "id": "Ai",
        "name": "ReTalker: Audio Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Video",
        "name": "ReTalker: Audio Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Video.Lipsync",
        "name": "ReTalker: Audio Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video"
    },
    {
        "id": "Ai.Video.Lipsync.Retalker",
        "name": "ReTalker: Audio Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync"
    },
    {
        "id": "Ai.Video.Lipsync.Retalker.Audiodrivenlipsync",
        "name": "ReTalker: Audio Driven Lipsync",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync.Retalker"
    },
    {
        "id": "Ai",
        "name": "ReTalker: TTS Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Video",
        "name": "ReTalker: TTS Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Video.Lipsync",
        "name": "ReTalker: TTS Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video"
    },
    {
        "id": "Ai.Video.Lipsync.Retalker",
        "name": "ReTalker: TTS Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync"
    },
    {
        "id": "Ai.Video.Lipsync.Retalker.Ttsanimator",
        "name": "ReTalker: TTS Driven Lipsync",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync.Retalker"
    },
    {
        "id": "Ai",
        "name": "Wav2Lip: Audio Video",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Video",
        "name": "Wav2Lip: Audio Video",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Video.Lipsync",
        "name": "Wav2Lip: Audio Video",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video"
    },
    {
        "id": "Ai.Video.Lipsync.Wav2lip",
        "name": "Wav2Lip: Audio Video",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync"
    },
    {
        "id": "Ai.Video.Lipsync.Wav2lip.Augmentvideolipsyncbyaudio",
        "name": "Wav2Lip: Audio Video",
        "isDir": false,
        "isHidden": true,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync.Wav2lip"
    },
    {
        "id": "Ai",
        "name": "Wav2Lip: TTS Video",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Video",
        "name": "Wav2Lip: TTS Video",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Video.Lipsync",
        "name": "Wav2Lip: TTS Video",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video"
    },
    {
        "id": "Ai.Video.Lipsync.Wav2lip",
        "name": "Wav2Lip: TTS Video",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync"
    },
    {
        "id": "Ai.Video.Lipsync.Wav2lip.Ttsvideolipsync",
        "name": "Wav2Lip: TTS Video",
        "isDir": false,
        "isHidden": true,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync.Wav2lip"
    },
    {
        "id": "Ai",
        "name": "SadTalker: Audio Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Video",
        "name": "SadTalker: Audio Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Video.Lipsync",
        "name": "SadTalker: Audio Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video"
    },
    {
        "id": "Ai.Video.Lipsync.Sadtalker",
        "name": "SadTalker: Audio Driven Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync"
    },
    {
        "id": "Ai.Video.Lipsync.Sadtalker.Audiodrivenlipsync",
        "name": "SadTalker: Audio Driven Lipsync",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync.Sadtalker"
    },
    {
        "id": "Ai",
        "name": "SadTalker: TTS Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Video",
        "name": "SadTalker: TTS Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Video.Lipsync",
        "name": "SadTalker: TTS Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video"
    },
    {
        "id": "Ai.Video.Lipsync.Sadtalker",
        "name": "SadTalker: TTS Lipsync",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync"
    },
    {
        "id": "Ai.Video.Lipsync.Sadtalker.Ttsanimator",
        "name": "SadTalker: TTS Lipsync",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync.Sadtalker"
    },
    {
        "id": "Ai",
        "name": "SadTalker: Idle Animator",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Video",
        "name": "SadTalker: Idle Animator",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Video.Lipsync",
        "name": "SadTalker: Idle Animator",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video"
    },
    {
        "id": "Ai.Video.Lipsync.Sadtalker",
        "name": "SadTalker: Idle Animator",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync"
    },
    {
        "id": "Ai.Video.Lipsync.Sadtalker.Idleanimator",
        "name": "SadTalker: Idle Animator",
        "isDir": false,
        "isHidden": true,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Video.Lipsync.Sadtalker"
    },
    {
        "id": "Ai",
        "name": "Face Fix",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": ""
    },
    {
        "id": "Ai.Image",
        "name": "Face Fix",
        "isDir": true,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai"
    },
    {
        "id": "Ai.Image.Facefix",
        "name": "Face Fix",
        "isDir": false,
        "isHidden": false,
        "size": 1881558,
        "modDate": "2024-06-28T05:46:31.545Z",
        "parentId": "Ai.Image"
    }
];
    
    const uaiComponentTree = [
    {
        "key": "Ai",
        "label": "Ai",
        "data": "Ai",
        "help": "",
        "type": "route",
        "icon": "pi pi-fw pi-inbox",
        "children": [
            {
                "key": "Ai.Audio",
                "label": "Audio",
                "data": "Ai.Audio",
                "help": "",
                "type": "route",
                "icon": "pi pi-fw pi-inbox",
                "children": [
                    {
                        "key": "uai.builtin.audio.split",
                        "label": "Split Stems",
                        "data": "uai.builtin.audio.split",
                        "help": "Split the audio into stems.",
                        "type": "function",
                        "icon": "pi pi-fw pi-inbox"
                    },
                    {
                        "key": "Ai.Audio.Generate",
                        "label": "Generate",
                        "data": "Ai.Audio.Generate",
                        "help": "",
                        "type": "route",
                        "icon": "pi pi-fw pi-inbox",
                        "children": [
                            {
                                "key": "Ai.Audio.Generate.Text2music",
                                "label": "Text2music",
                                "data": "Ai.Audio.Generate.Text2music",
                                "help": "",
                                "type": "route",
                                "icon": "pi pi-fw pi-inbox",
                                "children": [
                                    {
                                        "key": "uai.builtin.audio.musicgen.custom",
                                        "label": "Music Gen: Text 2 Music Advanced ",
                                        "data": "uai.builtin.audio.musicgen.custom",
                                        "help": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
                                        "type": "function",
                                        "icon": "pi pi-fw pi-inbox"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "key": "Ai.Text",
                "label": "Text",
                "data": "Ai.Text",
                "help": "",
                "type": "route",
                "icon": "pi pi-fw pi-inbox",
                "children": [
                    {
                        "key": "Ai.Text.Generate",
                        "label": "Generate",
                        "data": "Ai.Text.Generate",
                        "help": "",
                        "type": "route",
                        "icon": "pi pi-fw pi-inbox",
                        "children": [
                            {
                                "key": "uai.builtin.audio.musicgen.custom",
                                "label": "Music Gen: Lyrics Generator",
                                "data": "uai.builtin.audio.musicgen.custom",
                                "help": "This module generates a song based on the lyrics provided. The lyrics should be separated by sections such as [Verse], [Chorus], [Bridge]. The module will generate a song based on the lyrics provided and return the audio of the song. The module also accepts tags that describe the song to generate. Use spaces to separate the tags and use hyphens for multi-word tags.",
                                "type": "function",
                                "icon": "pi pi-fw pi-inbox"
                            }
                        ]
                    }
                ]
            },
            {
                "key": "Ai.3d",
                "label": "3d",
                "data": "Ai.3d",
                "help": "",
                "type": "route",
                "icon": "pi pi-fw pi-inbox",
                "children": [
                    {
                        "key": "Ai.3d.Pbr",
                        "label": "Pbr",
                        "data": "Ai.3d.Pbr",
                        "help": "",
                        "type": "route",
                        "icon": "pi pi-fw pi-inbox",
                        "children": [
                            {
                                "key": "uai.builtin.pbrbsdfmaterial",
                                "label": "3D PBR BSDF Material",
                                "data": "uai.builtin.pbrbsdfmaterial",
                                "help": "This module provides PBR BSDF material settings including texture URLs and UV settings per texture.",
                                "type": "function",
                                "icon": "pi pi-fw pi-inbox"
                            }
                        ]
                    },
                    {
                        "key": "Ai.3d.Unlit",
                        "label": "Unlit",
                        "data": "Ai.3d.Unlit",
                        "help": "",
                        "type": "route",
                        "icon": "pi pi-fw pi-inbox",
                        "children": [
                            {
                                "key": "uai.builtin.unlitmaterial",
                                "label": "3D Unlit Material",
                                "data": "uai.builtin.unlitmaterial",
                                "help": "This module provides unlit material settings including a base texture URL or base color.",
                                "type": "function",
                                "icon": "pi pi-fw pi-inbox"
                            }
                        ]
                    }
                ]
            },
            {
                "key": "Ai.Image",
                "label": "Image",
                "data": "Ai.Image",
                "help": "",
                "type": "route",
                "icon": "pi pi-fw pi-inbox",
                "children": [
                    {
                        "key": "Ai.Image.Stablediffusion",
                        "label": "Stablediffusion",
                        "data": "Ai.Image.Stablediffusion",
                        "help": "",
                        "type": "route",
                        "icon": "pi pi-fw pi-inbox",
                        "children": [
                            {
                                "key": "Ai.Image.Stablediffusion.Xllightning",
                                "label": "Xllightning",
                                "data": "Ai.Image.Stablediffusion.Xllightning",
                                "help": "",
                                "type": "route",
                                "icon": "pi pi-fw pi-inbox",
                                "children": [
                                    {
                                        "key": "uai.builtin.stableDiffusion.XLLightning",
                                        "label": "XL Lightning: T2I",
                                        "data": "uai.builtin.stableDiffusion.XLLightning",
                                        "help": "This module generates an image of a lightning bolt based on the given text prompt using the XL Lightning model of Stable Diffusion.",
                                        "type": "function",
                                        "icon": "pi pi-fw pi-inbox"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "key": "uai.builtin.image.augment.facefix",
                        "label": "Face Fix",
                        "data": "uai.builtin.image.augment.facefix",
                        "help": "Fix an AI generated face to look more realistic.",
                        "type": "function",
                        "icon": "pi pi-fw pi-inbox"
                    }
                ]
            },
            {
                "key": "Ai.Video",
                "label": "Video",
                "data": "Ai.Video",
                "help": "",
                "type": "route",
                "icon": "pi pi-fw pi-inbox",
                "children": [
                    {
                        "key": "Ai.Video.Lipsync",
                        "label": "Lipsync",
                        "data": "Ai.Video.Lipsync",
                        "help": "",
                        "type": "route",
                        "icon": "pi pi-fw pi-inbox",
                        "children": [
                            {
                                "key": "Ai.Video.Lipsync.Retalker",
                                "label": "Retalker",
                                "data": "Ai.Video.Lipsync.Retalker",
                                "help": "",
                                "type": "route",
                                "icon": "pi pi-fw pi-inbox",
                                "children": [
                                    {
                                        "key": "uai.extension.retalker.audio",
                                        "label": "ReTalker: Audio Driven Lipsync",
                                        "data": "uai.extension.retalker.audio",
                                        "help": "Drive a video's lipsync with an audio file",
                                        "type": "function",
                                        "icon": "pi pi-fw pi-inbox"
                                    },
                                    {
                                        "key": "uai.extension.retalker.tts",
                                        "label": "ReTalker: TTS Driven Lipsync",
                                        "data": "uai.extension.retalker.tts",
                                        "help": "Drive a video's lipsync with ReTalker",
                                        "type": "function",
                                        "icon": "pi pi-fw pi-inbox"
                                    }
                                ]
                            },
                            {
                                "key": "Ai.Video.Lipsync.Sadtalker",
                                "label": "Sadtalker",
                                "data": "Ai.Video.Lipsync.Sadtalker",
                                "help": "",
                                "type": "route",
                                "icon": "pi pi-fw pi-inbox",
                                "children": [
                                    {
                                        "key": "uai.extension.sadtalker.audio",
                                        "label": "SadTalker: Audio Driven Lipsync",
                                        "data": "uai.extension.sadtalker.audio",
                                        "help": "Drive a video's lipsync animation with an audio file.",
                                        "type": "function",
                                        "icon": "pi pi-fw pi-inbox"
                                    },
                                    {
                                        "key": "uai.extension.sadtalker.tts",
                                        "label": "SadTalker: TTS Lipsync",
                                        "data": "uai.extension.sadtalker.tts",
                                        "help": "Augment a video's lipsync with Text to Speech Audio",
                                        "type": "function",
                                        "icon": "pi pi-fw pi-inbox"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "key": "Test",
        "label": "Test",
        "data": "Test",
        "help": "",
        "type": "route",
        "icon": "pi pi-fw pi-inbox",
        "children": [
            {
                "key": "Test.Lua",
                "label": "Lua",
                "data": "Test.Lua",
                "help": "",
                "type": "route",
                "icon": "pi pi-fw pi-inbox",
                "children": [
                    {
                        "key": "uai.builtin.lua.test.pythonprint",
                        "label": "Tset Lua Python Print",
                        "data": "uai.builtin.lua.test.pythonprint",
                        "help": "Test print a python string.",
                        "type": "function",
                        "icon": "pi pi-fw pi-inbox"
                    }
                ]
            }
        ]
    }
];
    
    export {uaimenu, uaiComponents, uaiComponentFileMap, uaiComponentTree };


    