import { verifyAPIKey } from './auth';
import { useState, useEffect } from 'react';


export function useAPIKey() {
    const [apiKey, setAPIKey] = useState(null);
    const [verified, setVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const key_ = localStorage.getItem('APIKey');

        const verifyKey = async () => {
            const verified_ = await verifyAPIKey(key_);
            setVerified(verified_);
            setIsLoading(false);
        };
        if (key_) {
            setAPIKey(key_);
            verifyKey();
        }
    }, []);
    return { apiKey, verified, isLoading };
}
