import { useEffect, useState } from "react";
import Header from "../../../components/header/appHeader.jsx";
import TimelineEditor from "../TimelineEditor/index";
import ContentArea from "./components/contentArea.js";
import { useProject } from "../../api/useProject.js";
import { DNA } from "react-loader-spinner";
import {Layout, Model, Actions} from 'flexlayout-react';
// import 'flexlayout-react/style/dark.css';  
import './style/darkMode.css';
import ReactJson from 'react-json-view'
import Joyride from 'react-joyride';

import PreviewArea, {PreviewButtonControls} from "./components/previewArea.js";
import {UAIBrowser} from "../FileBrowser/uaiBrowser.js";
import { useHotkeys, isHotkeyPressed } from 'react-hotkeys-hook'

import { defaultLayout, layoutPremiere, layoutGenerate } from "./layouts/layouts.jsx";
import ProjectView from "./components/Project/projectView.js";
// import { useSignals } from "../../api/signalSlots.js";
import {getUAIComponent,sendToast} from "../../api/firebaseHandler.js";
import useAppManager from "../../api/appManager.js";
import FXPanel from "./components/fxPanel.js";
import GeneratePanel from "./components/generatePanel.js";
import usePreviewArea from "./api/usePreviewArea.jsx";
import useRuntime from "./api/useRuntime.jsx";
import { faL } from "@fortawesome/free-solid-svg-icons";
import localforage from "localforage";
import DebugState from "./components/debugState.jsx";
import SequencesTable from "./components/sequencesTable.jsx";
import FXBrowser from "./components/fxBrowser.jsx";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { useSignal, useSlot } from 'react-signal-slot';
import { useSlot as us } from './api/useSignalSlots.js';
import AppHotkeys from "./components/AppHotkeys/index.jsx";
import {getDefaultUAIComponents} from "../../components/UAIModules/uaiModules.js";
import UIOperations from './modules/builtin/ui/uiOperations.jsx';
const UAIAppBase = ({ appName = "stableV" }) => {
    const manager = useAppManager();
    // const uiOperations = UIOperations();
    const uaiProject = useProject(appName, false);
    const [uiLayoutModel, setUiLayoutModel] = useState(defaultLayout);
    const [steps, setSteps] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [runSteps, setRunSteps] = useState(false);
    const [uiLayoutJson, setUiLayoutJson] = useState("");
    const [htmlTimer, setHtmlTimer] = useState(null);

    const updateResolution = usePreviewArea((state) => state.updateResolution);
    const updateGlobal = useRuntime((state) => state.updateGlobal);
    const updateGlobalObject = useRuntime((state) => state.updateGlobalObject);
    const signal = useSignal();
    useSlot( "project.setProject",
    (e) => {
        setProject(e.project);
    },);
    useSlot("project.createNewProject",
    (e) => {
        createNewProject(e.project);
    },);
  
    useSlot("show.aiGenerate",
    (e) => {
        uiLayoutModel.doAction(Actions.selectTab("#eb0ab435-c082-4011-9955-50a04e0cff7c"));
    },);
  
    us("show.aiGenerate",
    (e) => {
        uiLayoutModel.doAction(Actions.selectTab("#eb0ab435-c082-4011-9955-50a04e0cff7c"));
    },);
  
  
    useSlot("show.fxPanel",
    (e) => {
        uiLayoutModel.doAction(Actions.selectTab("#45f9627e-db56-4c4e-bf96-6b859d70dc18"));
    },);

    us("show.fxPanel",
    (e) => {
        uiLayoutModel.doAction(Actions.selectTab("#45f9627e-db56-4c4e-bf96-6b859d70dc18"));
    },);

  
    useSlot("show.filebrowser",
    (e) => {
        uiLayoutModel.doAction(Actions.selectTab("#7b061871-9954-4b61-82f8-c5a8e33ccfa9"));
    },);
  
    us("show.filebrowser",
    (e) => {
        uiLayoutModel.doAction(Actions.selectTab("#7b061871-9954-4b61-82f8-c5a8e33ccfa9"));
    },);

    useSlot("operation.openWindow", async (e) => {
         
        const id_ = e.data.metadata.name;
        console.log(id_);
        const filtered = defaultOperations().filter((item) => item.id === id_);
        if(filtered.length > 0){
            filtered[0].function(e);
        }
        if (e.data.metadata.window=="FX" ) {
            addViewPanel(e.data.metadata);
            // setShow(e.state);
        }
        if (e.data.metadata.window=="AIGenerate" ) {
            addGeneratePanel(e.data.metadata);
            // setShow(e.state);
        }

        }


    );

    useEffect(() => {
        setTimeout(() => {
        if(!initialized){
            setInitialized(true);

            const project__ = JSON.parse(localStorage.getItem('project'));
            if(project__ == null){

                setSteps([
                    {
                      target: '.menu_File',
                      content: 'Create or Open a Project First!',
                    },
                  ])
                  var el = document.querySelector('.menu_File');

                  setRunSteps(true);
                sendToast("Please create or open a project first");

                return ;
            }
            setRunSteps(false);

        }
        }, 2000);
    }, []); 

    
    const buildViews = (components=[]) => {
        const views_ = [];
        for(var c =0; c < components.length ; c++){
            const component = components[c];
            let id_ = component.name;
            try{
                id_ = component.id;
            }catch(e){
                console.log(e);
            }


            const item = getUAIComponent(id_);

            item.component = component;
            // item.action = track;

            // item.uid = 
            views_.push(item);
        }
        return views_;
    }
   

    const addGeneratePanel = (component) => {
        const views_ = buildViews([component]);
        signal('timeline.tracks.showData.generate.panel', {views: views_});

    }
   
   
    const addViewPanel = (component) => {
        const views_ = buildViews([component]);
        // setViews(views_);
        signal('timeline.tracks.showData.fx.panel', {views: views_});

    }
    const defaultOperations = () =>{
        return( 
    [
        
        {
            "id": "uai.builtin.headless.window.default",
            "function": showDefaultEditor,
        },
        {
            "id": "uai.builtin.headless.window.timelineeditor",
            "function": showTimelineEditor,
        }
    ]
    )
    }

    const showTimelineEditor = (props) =>{
        setUiLayoutModel(Model.fromJson( layoutPremiere));
    
    }
    
    
    const showDefaultEditor = (props) =>{
        setUiLayoutModel(Model.fromJson(layoutGenerate));
    }
    

    // useHotkeys('x', () => {
    //     console.log("Hotkey pressed");
    //     // downloadLayout();
    //     signal("show.aiGenerate", {});
    //     // downloadLayout();
    //   });


    const downloadLayout = () => {

        var jsonStr = JSON.stringify(uiLayoutModel.toJson(), null, "\t");
        console.log(jsonStr);

        var blob = new Blob([jsonStr], {type: "text/plain;charset=utf-8"});
        var url = URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'layout.json';
        a.click();
    }

    const UpdateMediaCatalogs = (newCatalogs) => {
        uaiProject.setMediaCatalogs([
            ...uaiProject.mediaCatalogs,
            ...newCatalogs,
        ]);
    };
    const UpdateCurrentCatalog = (newCatalog) => {
        uaiProject.setCurrentCatalog(newCatalog);
    };

    const ConvertBase64ToImage = (base64) => {
        return "data:image/png;base64," + base64;
    };

    const SyncCatalogView = () => {
        if (
            uaiProject.currentCatalog.media === undefined ||
            uaiProject.currentCatalog.media === null ||
            uaiProject.currentCatalog.media.length === 0
        ) {
            return;
        }
        let base64img = uaiProject.currentCatalog.media[0].media
            ? uaiProject.currentCatalog.media[0].media
            : uaiProject.currentCatalog.media[0].url;
        if (base64img.includes("http")) {
            const previewImage = document.getElementById("previewImage");
            previewImage.src = base64img;
            return;
        }
        const baseString = "data:image/png;base64," + base64img;
        const previewImage = document.getElementById("previewImage");
        previewImage.src = baseString;
    };

    const SyncViews = async (project) => {};

    const setProject = async (project) => {
        console.log("SetLoadedProject ", project);
        await uaiProject.SetLoadedProject(project);
        uaiProject.setSelectProject(false);
        await SyncViews();
    };

    const createNewProject = async (project) => {
        console.log("createNewProject ", project);

        const res = await uaiProject.createNewProject(project);
        uaiProject.setSelectProject(false);
        await SyncViews();
    };
    const oldProps = {...manager.props,
        appName: appName,
        uaiProject: uaiProject,
        uaiBrowser: uaiProject.uaiBrowser,
        timeline: uaiProject.timeline,
        mediaCatalogs: uaiProject.mediaCatalogs,
        currentCatalog: uaiProject.currentCatalog,
        setCurrentCatalog: uaiProject.setCurrentCatalog,
        setMediaCatalogs: uaiProject.setMediaCatalogs,
        createNewProject: createNewProject,
        mode: uaiProject.mode,
        setMode: uaiProject.setMode,
        print: uaiProject.printStatus,
        SyncCatalogView: SyncCatalogView,
        setProject: setProject,
        isTimeline: uaiProject.previewMode == "timeline",
        previewMode: uaiProject.previewMode,
        sendToast: uaiProject.sendToast,
        user: uaiProject.user,
        focusedPanel: "",
        operations: [],
        finishedOperations: [],
        projectData : {
            project: null,
            projects: [],
            projectPath: "",
            sequence: {
                metadata:{
    
                },
                timeline:[],
            },
            sequences: [],
            addSequence: (sequence) => {
                manager.props.projectData.sequences.push(sequence);
            },
    
        },
        appBase: this,


    };
    manager.props = oldProps;
    
    // useEffect(() => {
    //     console.log("UAIAPPBASE", manager);
    //     console.log("localforage", localforage);
    //     var store = localforage.createInstance({
    //         name: "project"
    //       });
    // }, []);

    useEffect(() => {
        if (uaiProject.currentCatalog !== null) {
            SyncCatalogView();
        }
    }, [uaiProject.currentCatalog]);

    // useEffect(() => {
    //     // console.log(uaiProject);
    //     // console.log('uaiProject :', uaiProject);

    //     if (uaiProject.project === null) {
    //         uaiProject.setSelectProject(true);
    //     }
    //     if(initialized == false){
         
    //   const project__ = JSON.parse(localStorage.getItem('project'));
    //   if(project__ != null){
    //      manager.props.projectData.sequences = project__.sequences;
    //         setInitialized(true);
    //     }
    // }

    // }, [uaiProject]);

    useEffect(() => {
        // console.log("uaiBrowser",uaiBrowser);
    }, [uaiProject.uaiBrowser]);
    useEffect(() => {
        // console.log(contentArea);
    }, [uaiProject.contentArea.current]);

    const FXPanelComponent = (windowType = "FX") => {

        return (
            <FXPanel windowType={windowType} />
        )
    }




    const componentRender = (node) => {
        var component = node.getComponent();
       // console.log(node);
        if (component === "debugStatePanel") {
          return <DebugState extra={{}}/>
        //   return <ReactJson src={manager.props.projectData} />
          ;
        } 

        if (component === "fxPanel") {
          return <FXPanel /> ;
        } 

        if (component === "generatePanel") {
          return <GeneratePanel/>;
        } 

        if (component === "fileBrowser") {
          return <UAIBrowser manager={manager} />;
        }


        if (component === "sequences") {
          return <SequencesTable />;
        }

        if (component === "fxBrowser") {
          return <FXBrowser />;
        }


        // if (component === "fileMenu") {
        //   return <Header manager={manager} />;
        // }

        if (component === "timelineViewer") {
            // save state in flexlayout node tree
            node.setEventListener("resize", (p) => {
                const width_ = p.rect.width;
                const aspectRatio = 16/9;
                const height_ = Math.round( width_ / aspectRatio);
                updateResolution([width_, height_], "timeline");
        });
          return (
            <PreviewArea manager={manager} key={'timeline'} key_={'timeline'} node={node} />
           
      )
        }

        if (component === "timelineViewerControls") {
          return (
            <PreviewButtonControls key={'timeline'} />
           
      )
        }

        if (component === "previewMode") {
            node.setEventListener("resize", (p) => {
                const width_ = p.rect.width;
                const aspectRatio = 16/9;
                const height_ = Math.round( width_ / aspectRatio);
                // updateResolution([width_, height_], "preview");
        });
          return (
            <PreviewArea manager={manager} key_={'preview'} key={'preview'} />
           
      )
        }

        if (component === "previewViewerControls") {
          return (
            <PreviewButtonControls key={'preview'} />
           
      )
        }

        if (component === "timeline") {
          return (
          <div className="text-gray-800 w-full h-full flex flex-col bg-transparent blueborder-bottom">
          <TimelineEditor manager={manager} />
      </div>
      )
        }


        if (component === "statusBar") {
          return (
            <div className="w-full !h-[18px]  justify-center flex flex-col bg-[#000000] blueborder-top overflow-hidden  z-40 fixed mb-1 bottom-[-3px] ">
            <p className="!text-[#c9c9c9f0] !text-[9px] !h-[23px] !font-[Saira] font-medium  text-left m-0 pl-2 ">
                {uaiProject.statusMessage}
            </p>
        </div>
      )
        }


      }
      console.log("Base");
    const  onModelChange = () => {
        // if (htmlTimer) {
        //     clearTimeout(htmlTimer);
        // }
        // setHtmlTimer( setTimeout(() => {
        //     const jsonText = JSON.stringify(uiLayoutModel.toJson(), null, "\t");
        //     this.setState({ json: html });
        //     this.htmlTimer = null;
        // }, 500));
        setUiLayoutJson(JSON.stringify(uiLayoutModel.toJson(), null, "\t"));
    }
    // Please use 'useState()' to implement Navbar functionality
    return (
        <>
         <DndProvider backend={HTML5Backend}>
        <AppHotkeys />

            {uaiProject.loading != 3 ? (
                <>

                            
<Header manager={manager} />
<div  className="h-full w-full absolute top-[18px]">


                    <Layout
      model={uiLayoutModel}
      factory={componentRender}
      onModelChange={onModelChange}

      />
                        </div>

                </>
            ) : (
                <div className="canvas-paper flex w-full h-full  items-center justify-center">
                    <DNA className="m-auto" />
                </div>
            )}
            <ProjectView uaiProject={uaiProject} manager={manager} />
            {
                steps.length > 0 ? <Joyride steps={steps} run={true} continuous={true} callback={(e)=>{
                    console.log(e);
                    var el = document.querySelector(e.step.target);
                    console.log(el);
                    if(e.step.event == "click"){
                        switch(e.step.target){
                            case ".menu_File":
                                if(e.action == "close" ){
                                el.parentElement.click();
                                }
                                if(e.action == "next" ){
                                el.parentElement.click();
                                }
                                break;
                            case ".menu_File.Project":
                                // el.click();
                                break;
                        }
                    }
                }} 
                
                styles={{
                    options: {
                        arrowColor: '#d71d1d',
                        backgroundColor: '#0A0B10',
                        overlayColor: 'rgba(79, 26, 0, 0.4)',
                        primaryColor: '#d71d1d',
                        textColor: '#ffffff',
                        width: 400,
                        zIndex: 10000,
                    },
                  }}
                /> : null
            }
            </DndProvider>
        </>
    );
};

export default UAIAppBase;
