import React from "react";
import { ButtonGroup, Button } from "@material-tailwind/react";
import {useState} from "react";
import {useSignals} from "../../../api/signalSlots";
import {
  Square3Stack3DIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  ViewfinderCircleIcon,
  TvIcon
} from "@heroicons/react/24/solid";
import { useSignal, useSlot } from 'react-signal-slot';

const PreviewModeButtons = ({manager = undefined})=> {
    const [mode, setMode] = useState("preview");
    const signal = useSignal();
    useSlot("previewArea.setMode.preview", (e) => {
      setMode("preview");

  })
    useSlot("previewArea.setMode.timeline", (e) => {
      setMode("timeline");
  })

  return (
    < div className="w-full h-full flex flex-col gap-4 absolute top-[-1.5em]">
     <ButtonGroup>
      <Button   className={`!w-fit !h-fit    p-[5px] rounded-none bg-transparent `} onClick={()=>{
        setMode("preview");
 
        manager.props.uaiProject.sendToast('Preview Mode Activated');
            manager.props.print("Preview mode activated");
        signal("previewArea.setMode.preview");
      }}><ViewfinderCircleIcon className={`!w-[10px] !h-[10px]  ${mode == "preview" ?  "text-[#3A57E8]" : "text-white"}`}/></Button>
      <Button   className={`!w-fit !h-fit    p-[5px] rounded-none bg-transparent`} onClick={()=>{
        setMode("timeline");
        
        signal("previewArea.setMode.timeline");
     
            manager.props.print("Timeline Mode Activated");

        manager.props.uaiProject.sendToast('Timeline Mode Activated');
        manager.props.uaiProject.setPreviewMode("timeline");
      }}><TvIcon className={`!w-[10px] !h-[10px]  ${mode == "timeline" ?  "text-[#3A57E8]" : "text-white"}`}/></Button>
     
    </ButtonGroup>

    </div>
  );
}

export default PreviewModeButtons;