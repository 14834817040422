const generateInit = (id)=> {

    return {
        id: id,
        name: 'init.meta',
        isDir: false,
        isHidden: true,
        openable: false,
    }

}

const directories = [
    "assets",
    "assets/geo",
    "assets/tex",
    "assets/audio",
    "assets/code",
    "assets/misc",
    "render",
    "render/draft",
    "render/final",
    "pipeline",
    "scenes",

]
const baseScene = (path)=> {

    const outputItems = [];
    for (let i = 0; i < directories.length; i++) {
        outputItems.push(generateInit(path+"/"+directories[i]+ "/init.meta"));
    }

    return outputItems;

}

export { baseScene, directories, generateInit};