import { motion } from "framer-motion";
import { Tooltip } from 'react-tooltip'

import React, { useEffect, useState } from "react";
import AnimatedText from '../../../../components/AnimatedText';
const AnimatedButton = ({
    onClick,
    alt = "",
    id = "",
    className = "",
    toolTipClassName = "",
    helpTitle = "Material Tailwind",
    helpBody = "Body",
    icon = "/images/svg/play-filled-icon.svg",
    whileHover = { scale: 1.8, color: "#1e49b6" },
    whileTap = { scale: 0.9, rotate: 360 },
    placement="top",
    delay=1000,
    ref=null
}) => {
    const [id_, setId] = useState(id);
    useEffect(() => {
        setId(`${id}_${crypto.randomUUID()}`);
    }, []);
    return (
        <>
            <Tooltip delayShow={delay} className={` border !border-uailightblue-1 z-[50000] !bg-uaiblack-1 !rounded-none ${toolTipClassName}`} placement={placement}
            id={id_}
          
            >
                <div className="w-fit h-fit" ref={ref}>
                        <AnimatedText value={helpTitle}  speed={80}  className=" font-extrabold font-[Saira] !text-base text-start pb-1" duration={0.01} />
                        {helpBody != "" ?
                        <AnimatedText value={helpBody}  speed={80} className="font-normal  font-[Saira] text-sm opacity-60 text-start" duration={0.01} />
                        : null}
                    </div>
                </Tooltip>
                <motion.img
                data-tooltip-id={id_}
                    src={icon}
                    alt={alt}
                    className={`h-[8px] w-[8px] cursor-pointer hover:texts-[#1e49b6] ${className} `}
                    onClick={onClick}
                    whileHover={whileHover}
                    whileTap={whileTap}
                />
        </>
    );
};

export default AnimatedButton;
