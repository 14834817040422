 export class MediaItem
    {
        constructor() {
        this.media = "";
        this.model = "prompthero/openjourney-v4";
        this.input = "";
        this.output = "";
        this.name = "";
        this.prompt = "";
        this.neg_prompt = "";
        this.seed = 42;
        }

        json(){
            return JSON.stringify(this);
        }
    }

export class MediaItems
{
    constructor() {
    this.media = [];
    }

    json(){
        return JSON.stringify(this);
    }

}