


export const sequence = () => {
    
    return({
    id: crypto.randomUUID(),
    project: '',
    name: 'Untitled Sequence',
    thumbnail: '',
    comments: '',
    description: '',
    timeline: [],
    version: 1.0,
    settings: {
      scale: 5,
      scaleWidth: 160,
      startLeft: 20,
      fps: 24,
      in:0,
      out:20,
      render:{
          width: 1920,
          height: 1080,
          type: "video",
          codec: "h264",
          quality: 100,
          audio: true,
          audioCodec: "aac",
          audioQuality: 100,
          audioBitrate: 320,
          audioSampleRate: 44100,
          audioChannels: 2,
          audioVolume: 1.0,
          outputPath: "render/01/output.mp4",
  
      },
      
    }
  })};