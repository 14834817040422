import { useState, useEffect } from 'react';
import { uploadFile, uploadFiles, uploadMediaCatalog } from './firebaseHandler';

export function useStorage() {
    useEffect(() => { }, []);

    return { uploadFile, uploadFiles, uploadMediaCatalog };
}

export function useUserProjectStorage(appName = "stableV") {
    const [files, setFiles] = useState([]);
    const [fileMap, setFileMap] = useState({});
    const [projectPath, setProjectPath] = useState("");

    const RefreshProjectPath = () => {
        const projectText = localStorage.getItem('project');
        const projectJSON = JSON.parse(projectText);

        const userText = localStorage.getItem('user');
        const userJSON = JSON.parse(userText);
        const path = `${appName}/${userJSON.id}/${projectJSON.id}`;
        setProjectPath(path);
        return path;
    };

    useEffect(() => {
        const ppath = RefreshProjectPath();
        console.log("Project Path: ", ppath);
    }, []);

    return { files, fileMap, projectPath, RefreshProjectPath,setFiles ,setFileMap};
}
