import React from 'react';
import { Spinner } from "@material-tailwind/react";
import {DNA} from 'react-loader-spinner';
// import { SunspotLoader } from "react-awesome-loaders";

const LoadingSpinner = ({Title="Loading", Message="Please wait." ,Height="50vh", Context = "page"}) => {


    return (
            <div id='page ' className={Context == "page" ? '!h-['+Height+']' : '' + ' w-full'}>

            <section className="tf-section tf-create-and-sell p-0 h-full">
                <div className="tf-container p-0 h-full flex">
                    <div className="row justify-center w-full h-[160px] grid m-auto">
                        {/* <h1>Loading..</h1> */}
                        {/* <Spinner /> */}
                        <Spinner color="blue" size="xl" />
{/*                         
              <SunspotLoader className='self-center m-auto'
        gradientColors={["#093484", "#60bdf7"]}
        shadowColor={"#06265E"}
        desktopSize={"40px"}
        mobileSize={"80px"}
      /> */}
      <p className='text-center pt-3 text-white text-3xl font-bold m-auto'>{Title}</p>
      <p className='text-center text-blue-gray-200 text-lg  m-auto'>{Message}</p>
                    </div>
                </div>
            </section>
                {/* <TopSeller data={dataSeller} /> */}

            </div>
    );
}

export default LoadingSpinner;