export class AISettings
    {
        constructor() {

        this.media = [];
        this.catalogs = [];
        this.currentMedia = { "media": "", "prompt": "", "steps": 20, "model": "StyleTTS2", "speaker": "Lady", "date": new Date().toLocaleString(), "id": "sfjlkjf3", "gender": "Feminine" };
        this.mode = "input";
        this.model = "StyleTTS2";
        this.request = {};
        this.response = {};
    
    }
    }