import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const LoadPlugin = () => {
    const [pluginName, setPluginName] = useState("");
    const [components, setComponents] = useState([]);
    const [plugins, setPlugins] = useState([]); //['D1', 'D2', 'D3', 'D4
    const [pluginRegistry, setPluginRegistry] = useState({});
    const loadPlugins2 = () => {
        setPlugins([...plugins, `./dist/uaimoduleaudiosplit.js`]);
        // setPlugins(...plugins, pluginName);
        // fetch(`./dist/uaimoduleaudiosplit.js`)
        //     .then((resp) => resp.text())
        //     .then((resp) => {
        //         const component_ = eval(resp);
        //         console.log(component_);
        //     });
    };
    useEffect(() => {
        if (plugins.length > 0) {
        }
    }, [plugins]);
    const loadPlugins = () => {
        fetch(`./dist/${pluginName}.plugin.js`)
            .then((resp) => resp.text())
            .then((resp) => {
                const component_ = eval(resp);
                console.log(component_);
                setComponents([...components, pluginName]);
                let pluginRegistry_ = { ...pluginRegistry };
                pluginRegistry_[pluginName] = component_.default;
                setPluginRegistry(pluginRegistry_);
            });
    };

    useEffect(() => {
        if (window["React"] === undefined) {
            window.React = React;
        }
    }, []);

    return (
        <div>
            <h2>Dynamic Components Loading</h2>
            <input
                type="text"
                onChange={(e) => setPluginName(e.target.value)}
                placeholder="Plugin Name D1,D2,...D4 etc"
            />
            <button onClick={loadPlugins2}>Load</button>
            <Helmet>
                {plugins.map((plug) => {
                    return <script src={plug} type="module" />;
                })}
            </Helmet>
            <div>
                {components.map((componentId) => {
                    let Component = pluginRegistry[componentId];
                    return <Component>{componentId}</Component>;
                })}
            </div>
        </div>
    );
};

export default LoadPlugin;
