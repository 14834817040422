/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @copyright 2020
 * @license MIT
 */

import {
    ChonkyActions,
    ChonkyFileActionData,
    FileArray,
    FileBrowserProps,
    FileData,
    FileHelper,
    FullFileBrowser,
} from "chonky";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { basemap } from "./demo.fs_map";
import { useAppManager } from "../../api/appManager";

import { showActionNotification } from "./util";
import { setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
setChonkyDefaults({ iconComponent: ChonkyIconFA });
// We define a custom interface for file data because we want to add some custom fields
// to Chonky's built-in `FileData` interface.
interface CustomFileData extends FileData {
    parentId?: string;
    childrenIds?: string[];
}
interface CustomFileMap {
    [fileId: string]: CustomFileData;
}

// Helper method to attach our custom TypeScript types to the imported JSON file map.

// Hook that sets up our file map and defines functions used to mutate - `deleteFiles`,
// `moveFiles`, and so on.
const useCustomFileMap = (props) => {
    const manager = useAppManager();

    const prepareCustomFileMap = () => {
        // const baseFileMap = fmap.fileMap ;
        // const rootFolderId = fmap.rootFolderId;
        const baseFileMap = manager.props.uaiProject.fileMap.fileMap;
        const rootFolderId = manager.props.uaiProject.fileMap.rootFolderId;
        return { baseFileMap, rootFolderId };
    };

    const { baseFileMap, rootFolderId } = useMemo(prepareCustomFileMap, []);

    // Setup the React state for our file map and the current folder.
    const [fileMap, setFileMap] = useState(
        manager.props.uaiProject.fileMap
            ? manager.props.uaiProject.fileMap.fileMap
            : basemap
    );
    const [currentFolderId, setCurrentFolderId] = useState(
        manager.props.uaiProject.fileMap
            ? manager.props.uaiProject.fileMap.rootFolderId
            : "stableV/151/c1b7-46db"
    );

    // Setup the function used to reset our file map to its initial value. Note that
    // here and below we will always use `useCallback` hook for our functions - this is
    // a crucial React performance optimization, read more about it here:
    // https://reactjs.org/docs/hooks-reference.html#usecallback
    const resetFileMap = useCallback(() => {
        setFileMap(baseFileMap);
        setCurrentFolderId(rootFolderId);
    }, [baseFileMap, rootFolderId]);

    // Setup logic to listen to changes in current folder ID without having to update
    // `useCallback` hooks. Read more about it here:
    // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
    const currentFolderIdRef = useRef(currentFolderId);
    useEffect(() => {
        currentFolderIdRef.current = currentFolderId;
    }, [currentFolderId]);

    // Function that will be called when user deletes files either using the toolbar
    // button or `Delete` key.
    const deleteFiles = useCallback((files: CustomFileData[]) => {
        // We use the so-called "functional update" to set the new file map. This
        // lets us access the current file map value without having to track it
        // explicitly. Read more about it here:
        // https://reactjs.org/docs/hooks-reference.html#functional-updates
        setFileMap((currentFileMap) => {
            // Create a copy of the file map to make sure we don't mutate it.
            const newFileMap = { ...currentFileMap };

            files.forEach((file) => {
                // Delete file from the file map.
                delete newFileMap[file.id];

                // Update the parent folder to make sure it doesn't try to load the
                // file we just deleted.
                if (file.parentId) {
                    const parent = newFileMap[file.parentId];
                    const newChildrenIds = parent.childrenIds.filter(
                        (id) => id !== file.id
                    );
                    newFileMap[file.parentId] = {
                        ...parent,
                        childrenIds: newChildrenIds,
                        childrenCount: newChildrenIds.length,
                    };
                }
            });

            return newFileMap;
        });
    }, []);

    // Function that will be called when files are moved from one folder to another
    // using drag & drop.
    const moveFiles = useCallback(
        (
            files: CustomFileData[],
            source: CustomFileData,
            destination: CustomFileData
        ) => {
            setFileMap((currentFileMap) => {
                const newFileMap = { ...currentFileMap };
                const moveFileIds = new Set(files.map((f) => f.id));

                // Delete files from their source folder.
                const newSourceChildrenIds = source.childrenIds.filter(
                    (id) => !moveFileIds.has(id)
                );
                newFileMap[source.id] = {
                    ...source,
                    childrenIds: newSourceChildrenIds,
                    childrenCount: newSourceChildrenIds.length,
                };

                // Add the files to their destination folder.
                const newDestinationChildrenIds = [
                    ...destination.childrenIds,
                    ...files.map((f) => f.id),
                ];
                newFileMap[destination.id] = {
                    ...destination,
                    childrenIds: newDestinationChildrenIds,
                    childrenCount: newDestinationChildrenIds.length,
                };

                // Finally, update the parent folder ID on the files from source folder
                // ID to the destination folder ID.
                files.forEach((file) => {
                    newFileMap[file.id] = {
                        ...file,
                        parentId: destination.id,
                    };
                });

                return newFileMap;
            });
        },
        []
    );

    // Function that will be called when user creates a new folder using the toolbar
    // button. That that we use incremental integer IDs for new folder, but this is
    // not a good practice in production! Instead, you should use something like UUIDs
    // or MD5 hashes for file paths.
    const idCounter = useRef(0);
    const createFolder = useCallback(async (folderName: string) => {
        console.log("Create Folder1");

        console.log("Create Folder2");
        setFileMap((currentFileMap) => {
            const newFileMap = { ...currentFileMap };

            // Create the new folder
            const newFolderId = `new-folder-${idCounter.current++}`;
            newFileMap[newFolderId] = {
                id: newFolderId,
                name: folderName,
                isDir: true,
                modDate: new Date(),
                parentId: currentFolderIdRef.current,
                childrenIds: [],
                childrenCount: 0,
            };

            // Update parent folder to reference the new folder.
            const parent = newFileMap[currentFolderIdRef.current];
            newFileMap[currentFolderIdRef.current] = {
                ...parent,
                childrenIds: [...parent.childrenIds, newFolderId],
            };
            return newFileMap;
        });
    }, []);

    return {
        fileMap,
        currentFolderId,
        setCurrentFolderId,
        resetFileMap,
        deleteFiles,
        moveFiles,
        createFolder,
    };
};

export const useFiles = (
    fileMap: CustomFileMap,
    currentFolderId: string
): FileArray => {
    return useMemo(() => {
        if (currentFolderId === undefined || currentFolderId === null) {
            return [null];
        }
        const currentFolder = fileMap[currentFolderId];
        const childrenIds = currentFolder.childrenIds;
        const files = childrenIds.map((fileId: string) => fileMap[fileId]);
        return files;
    }, [currentFolderId, fileMap]);
};

export const useFolderChain = (
    fileMap: CustomFileMap,
    currentFolderId: string
): FileArray => {
    return useMemo(() => {
        if (currentFolderId === undefined || currentFolderId === null) {
            return [null];
        }
        const currentFolder = fileMap[currentFolderId];

        const folderChain = [currentFolder];

        let parentId = currentFolder.parentId;
        while (parentId) {
            const parentFile = fileMap[parentId];
            if (parentFile) {
                folderChain.unshift(parentFile);
                parentId = parentFile.parentId;
            } else {
                break;
            }
        }

        return folderChain;
    }, [currentFolderId, fileMap]);
};

export const useFileActionHandler = ({
    setCurrentFolderId = (folderId: string) => {},
    deleteFiles = (files: CustomFileData[]) => {},
    moveFiles = (
        files: FileData[],
        source: FileData,
        destination: FileData
    ) => {},
    createFolder = (folderName: string) => {},
    customProps,
}) => {
    return useCallback(
        async (data: ChonkyFileActionData) => {
            if (data.id === ChonkyActions.OpenFiles.id) {
                const { targetFile, files } = data.payload;
                const fileToOpen = targetFile ?? files[0];
                if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                    setCurrentFolderId(fileToOpen.id);
                    return;
                }
            } else if (data.id === ChonkyActions.DeleteFiles.id) {
                deleteFiles(data.state.selectedFilesForAction);
            } else if (data.id === ChonkyActions.MoveFiles.id) {
                moveFiles(
                    data.payload.files,
                    data.payload.source,
                    data.payload.destination
                );
            } else if (data.id === ChonkyActions.CreateFolder.id) {
                const folderName = prompt(
                    "Provide the name for your new folder:"
                );
                console.log(customProps);
                const props_ = customProps;
                console.log(props_);
                console.log(folderName);
                createFolder(folderName);
                await props_.createFolder(folderName);
            }

            showActionNotification(data);
        },
        [createFolder, deleteFiles, moveFiles, setCurrentFolderId, customProps]
    );
};

export type VFSProps = Partial<FileBrowserProps>;

export const FileBrowser: React.FC<VFSProps> = React.memo((props) => {
    const manager = useAppManager();
    const {
        fileMap,
        currentFolderId,
        setCurrentFolderId,
        resetFileMap,
        deleteFiles,
        moveFiles,
        createFolder,
    } = useCustomFileMap(props);
    const [folderChain, setFolderChain] = useState([]);
    // const files = useFiles(fileMap, currentFolderId);

    const generateFolderChain = () => {
        if (currentFolderId === undefined || currentFolderId === null) {
            return [null];
        }
        const currentFolder = fileMap[currentFolderId];

        const folderChain_ = [currentFolder];

        let parentId = currentFolder.parentId;
        while (parentId) {
            const parentFile = fileMap[parentId];
            if (parentFile) {
                folderChain_.unshift(parentFile);
                parentId = parentFile.parentId;
            } else {
                break;
            }
        }
        setFolderChain(folderChain_);
        return folderChain_;
    };
    const [chonkyFiles, setChonkyFiles] = useState([null]);

    useEffect(() => {
        // const currentFolder = manager.props.uaiProject.fileMap.fileMap[currentFolderId];
        // const childrenIds = currentFolder.childrenIds;
        if (
            manager.props.uaiProject.fileMap === null ||
            manager.props.uaiProject.fileMap === undefined ||
            manager.props.uaiProject.fileMap != {}
        ) {
            return;
        }
        console.log("uaiProject", manager.props.uaiProject.fileMap);
        let files: FileArray = [];

        const keys = Object.keys(manager.props.uaiProject.fileMap["fileMap"]);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            files.push(manager.props.uaiProject.fileMap["fileMap"][key]);
        }
        setChonkyFiles(files);
        // generateFolderChain();
        console.log("uaiProject fileMap", manager.props.uaiProject.fileMap);
        console.log("uaiProject files", files);
    }, [manager.props.uaiProject.fileMap]);

    const handleAction: FileActionHandler = async (data) => {
        if (data.id === ChonkyActions.OpenFiles.id) {
            const { targetFile, files } = data.payload;
            const fileToOpen = targetFile ?? files[0];
            if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                setCurrentFolderId(fileToOpen.id);
                return;
            }
        } else if (data.id === ChonkyActions.DeleteFiles.id) {
            deleteFiles(data.state.selectedFilesForAction);
        } else if (data.id === ChonkyActions.MoveFiles.id) {
            moveFiles(
                data.payload.files,
                data.payload.source,
                data.payload.destination
            );
        } else if (data.id === ChonkyActions.CreateFolder.id) {
            const folderName = prompt("Provide the name for your new folder:");
            console.log(
                manager.props.uaiProject.currentPath + "/" + folderName
            );
            createFolder(folderName);
            console.log("Create Folder 1");
            // await manager.props.uaiProject.createFolder(folderName);
            console.log("Create Folder 2");
        }

        showActionNotification(data);
    };

    const fileActions = useMemo(
        () => [ChonkyActions.CreateFolder, ChonkyActions.DeleteFiles],
        []
    );
    const thumbnailGenerator = useCallback(
        (file: FileData) =>
            file.thumbnailUrl ? `https://chonky.io${file.thumbnailUrl}` : null,
        []
    );

    return (
        <>
            <div className="h-fit">
                <FullFileBrowser
                    files={chonkyFiles}
                    fileActions={fileActions}
                    onFileAction={handleAction}
                    thumbnailGenerator={thumbnailGenerator}
                    {...props}
                />
            </div>
        </>
    );
});

export const FileBrowserUAI: React.FC<VFSProps> = React.memo((props) => {
    const {
        fileMap,
        currentFolderId,
        setCurrentFolderId,
        resetFileMap,
        deleteFiles,
        moveFiles,
        createFolder,
    } = useCustomFileMap();
    const files = useFiles(fileMap, currentFolderId);
    const folderChain = useFolderChain(fileMap, currentFolderId);
    const handleFileAction = useFileActionHandler(
        setCurrentFolderId,
        deleteFiles,
        moveFiles,
        (e) => {
            console.log(e);
            createFolder(e);
        }
    );
    const fileActions = useMemo(
        () => [ChonkyActions.CreateFolder, ChonkyActions.DeleteFiles],
        []
    );
    const thumbnailGenerator = useCallback(
        (file: FileData) =>
            file.thumbnailUrl ? `https://chonky.io${file.thumbnailUrl}` : null,
        []
    );

    return (
        <>
            <div className="h-fit">
                <FullFileBrowser
                    files={files}
                    folderChain={folderChain}
                    fileActions={fileActions}
                    onFileAction={handleFileAction}
                    thumbnailGenerator={thumbnailGenerator}
                    {...props}
                />
            </div>
        </>
    );
});
