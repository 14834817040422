import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

const MessageModal = (props) => {
    
return (

    <Modal
    show={props.show}
    onHide={props.onHide}
  >
    <Modal.Header closeButton></Modal.Header>

    <div className="modal-body h-fit">
        <h3>{props.title}</h3>
        <p className="text-center sub-heading">{props.body}</p>
        <Link to="/wallet-connect" onClick={(e)=>{
            e.preventDefault();
            props.onHide();
            props.onClose();
        }} className="button-popup" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close">Close</Link>
    </div>
    </Modal>
    
  );
};

export default MessageModal;
