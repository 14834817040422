

// import './style.css';
import {useRef, useState, useEffect  } from 'react';
import { useStorage } from "../../../..//api/useStorage";
import { useSignals } from '../../../..//api/signalSlots';
import { getUAIComponent, sendToast } from '../../../..//api/firebaseHandler';
import {useAppManager} from '../../../../api/appManager';
import DynamicUI from './dynamicUI';
import { useSignal, useSlot } from 'react-signal-slot';


import {DNA} from 'react-loader-spinner';

const DynamicWindow = ({setShow = (e)=>{}, ComponentIDs=["uai.builtin.ui.sequence.create"], Title="Create Project", onChange = ({newValue, component}) => { }}) => {
    const fileInput = useRef(null);
    const manager = useAppManager();
    const [isLoading, setIsLoading] = useState(false);
    const [component, setComponent] = useState(undefined);
    const [value, setValue] = useState([]);
    const signal = useSignal();

    useEffect (() => {
        const data = ComponentIDs.map(ComponentID => getUAIComponent(ComponentID));
        setValue(data);
        // setComponent(data);
 

    },[]);

    const onValueChanged = ({newValue, component})=>{
        const updatedValue = [...value];

        let updated = false;
        for (let ib = 0; ib < newValue.length; ib++) {
            const target = newValue[ib];
            const targetUUID = target.uuid;
        for (let i = 0; i < updatedValue.length; i++) {
            for (let j = 0; j < updatedValue[i].subwindows.length; j++) {
                
                for (let k = 0; k < updatedValue[i].subwindows[j].fields.length; k++) {

                    if (updatedValue[i].subwindows[j].fields[k].uuid == targetUUID) {

                        updatedValue[i].subwindows[j].fields[k].value = target.value;
                        updated = true;
                    }
                }
            }
        }
    }
    setValue(updatedValue);
        onChange({newValue, updatedValue});
    }

    const createDictionary = (component) => {
        // [0].subwindows[0].fields[0].name
        let dictionary = { 
        };
        for (let i = 0; i < component.length; i++) {
            dictionary[component[i].uuid] ={ uuid:component[i].uuid, name:component[i].name, subwindows:[]};
            for (let j = 0; j < component[i].subwindows.length; j++) {
                let subWindow = { uuid:component[i].subwindows[j].uuid, name:component[i].subwindows[j].name, fields:[]};
                for (let k = 0; k < component[i].subwindows[j].fields.length; k++) {
                    subWindow.fields.push( {
                        uuid:component[i].subwindows[j].fields[k].uuid,
                        name:component[i].subwindows[j].fields[k].name,
                        value:component[i].subwindows[j].fields[k].value
                    });
                }
                dictionary[component[i].uuid].subwindows.push(subWindow);

            }
        }
        return dictionary;
    }

    const runAction = (data) => {
        const operation  = `${data.action.module}.${data.action.operation}`; 
        let val = [...value];
        val[0].action = data.action;
        const project_ = JSON.parse(localStorage.getItem("project"));
if(project_== null){
    sendToast("Please create a project first");
    return;
}
        signal('operation.run', {operation: operation, value: val, dictionary: createDictionary(value)})
    }
 
    return (

        <>
     { isLoading ? 
     
     <div className="canvas-paper w-full h-full  flex items-center justify-center">
     <DNA className="m-auto" />
     </div>
     
     :

     <section className="mx-auto px-20 py-12 md:py-28 lg:gap-3 bg-transparent w-full relative isolate flex overflow-clip
    flex-col h-full overflow-auto">
      { value.map( component =>{
 
        return (
        
        
        <div className="flex flex-col w-full">
      
        <DynamicUI component={component} onChange={onValueChanged} />
        <div className="flex flex-row w-full justify-end pt-3">
            {component.functions.map((func, index) => {
                if(func.type == "submit"){
                return <button key={index} onClick={() =>{runAction(func)}} className="ml-2 p-2 bg-transparent appBlueBorder text-white">{func.name}</button>
                }else{
                    return false;
                }
            })
            }
            </div>
        </div>
        
        )
        } )}  
        
</section>}

</>
    );
  };

  export default DynamicWindow;