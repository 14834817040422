import React, { FC, useState, useEffect } from 'react';
import { TimelineAction, TimelineRow } from '@xzdarcy/react-timeline-editor';
// import '../index.less';
import { useDispatch, useSelector } from 'react-redux';
import { getDownloadURLFromPath } from '../../../api/firebaseHandler';
import { BaseTrack } from './baseTrack';
export const ImagePanel: FC<{ action: TimelineAction; row: TimelineRow }> =
  ({ action, row }) => {

    // const selected = useSelector((state) => state.appTimeline.selected.includes(action.id));
    const [imgURL , setImgURL] = useState("");

    const updateImageURL = async () => {
      const url = await getDownloadURLFromPath(action.data.id);
      setImgURL(url);
    }
    useEffect(() => {
        updateImageURL();
    }, [action.data.id]);

    return (

      <BaseTrack action={action}  row={row} className='effectImage' >
        <img src={imgURL} className="hidden" id={`imageTrack_${action.data.id}`}  />
        </BaseTrack>
    );
  };