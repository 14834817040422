import { create } from "zustand";

const useOperations = create((set) => ({
    operations: [],
    addOperation: (operation) =>
        set((state) => {
            let newOperations = [...state.operations];
            var match = newOperations.filter(operation_ => operation_.id == operation.id);
            return { operations: [...state.operations, ...match] };
        }),
    addOperations: (operations) =>
        set((state) => {
            let newOperations = [];
            for (var i = 0; i < operations.length; i++) {
                var match = newOperations.filter(operation => operation.id == operations[i].id);
                if (match.length == 0) {
                    newOperations.push(operations[i]);
                }
            }
            return { operations: [...state.operations, ...newOperations] };
        }),
    removeOperation: (operation) =>
        set((state) => {
            return {
                operations: state.operations.filter(
                    (item) => item !== operation
                ),
            };
        }),
}));

export default useOperations;

export const getOperations = () => {
    return useOperations.getState().operations;
}

export const getOperation = (id) => {
    const operations = useOperations.getState().operations;
    const match = operations.filter(operation => operation.id == id);
    if (match.length > 0) {
        return match[0];
    }
    return null;
}

export const addOperation = (operation) => {
    useOperations.getState().addOperation(operation);
}
