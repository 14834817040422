import React, { FC, useEffect, useState } from 'react';
import { TimelineAction, TimelineRow } from '@xzdarcy/react-timeline-editor';
// import '../index.less';
import { useDispatch, useSelector } from 'react-redux';
import useAppManager from '../../../api/appManager';
import { useSignals } from '../../../api/signalSlots';
import { isHotkeyPressed } from 'react-hotkeys-hook'
import { motion } from "framer-motion"
import {generateColors} from '../../../constants/generateColors';
import { useSignal, useSlot } from 'react-signal-slot';
import  useRuntime from "../../UAIAPP/api/useRuntime";
function rgbaToHex(r, g, b, a) {
  const toHex = (n) => {
      const hex = Math.round(n).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
  };
  const alpha = Math.round(a * 255);
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

function shadeColor(color, percent) {
  const f = color.split(","),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = parseInt(f[0].slice(5)),
        G = parseInt(f[1]),
        B = parseInt(f[2]),
        A = parseFloat(f[3]);
  const shadedR = Math.round((t - R) * p) + R;
  const shadedG = Math.round((t - G) * p) + G;
  const shadedB = Math.round((t - B) * p) + B;
  const rgba = `rgba(${shadedR},${shadedG},${shadedB},${A})`;
  const hex = rgbaToHex(shadedR, shadedG, shadedB, A);
  return hex;
}


export const BaseTrack =
  ({ action, row, children, className="" }) => {

    const manager = useAppManager();
    const [extraStyles, setExtraStyles] = useState("");
    const [color, setColor] = useState(generateColors(row.id * 5 % 50));
    const [highlightColor, setHighlightColor] = useState(generateColors(row.id * 1 % 50));
  const selected = useRuntime(state => state.appTimeline.selected);
  const setTimelineSelected = useRuntime(state => state.setTimelineSelected);
  const addTimelineSelected = useRuntime(state => state.addTimelineSelected);

    useSlot('timeline.tracks.deselect', (e) => {
      // setIsSelected(e.tracks.includes(action.id));
    });

    const handleSubType = (subType) => {
      let subtype_ = subType;
      if( typeof subType === 'undefined' || subType === null){
          subtype_ = 'unknown';
      }
      return subtype_;
   
    };

    useEffect(() => {
      // setIsSelected(selected.includes(action.id));
    }, [selected]);

 
    // const selected = manager.props.timeline ? manager.props.timeline.selectedTracks.includes(action.id) : false;
    // const selected = useSelector((state) => state.appTimeline.selected.includes(action.id));
    const clicked = () => {
      if(isHotkeyPressed('shift') ){
        manager.props.timeline.addSelected(action.id);
        addTimelineSelected(action.id);
     }else{
        manager.props.timeline.setSelectedTrack(action.id);
        // setIsSelected(!isSelected);
        setTimelineSelected([action.id]);
     }
    };
    return (
      <motion.div onDoubleClick={()=>{
        manager.props.timeline.selectForEdit(action);
        
        setExtraStyles('!bg-blue-800');
      }} 
      onClick={clicked} 
      onPointerDown={()=>{
        setExtraStyles('!bg-red-800');
      }}
      
      className={`effect0   select-none !h-[90%] cursor-move ${className}`  + extraStyles  + handleSubType(action.subType ?  action.subType.replaceAll(".","-") : "undefined")  + (selected.includes(action.id)  ? 'isActive ' + highlightColor  + ' border border-[1px] !border-white': ` ${color}` + ` `)}
      >
        <div className={`pl-4 select-none flex items-center`}>
        <p className={`text-xs font-bold  px-2 m-0 ${ (selected.includes(action.id) ?  highlightColor  : color)} ` }>{action.data.name.substring(0,10) + '...'}</p>

          {children}
          </div>
      </motion.div>
    );
  };