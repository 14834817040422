import React from 'react';

const useViewport = () => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const [orientation, setOrientation] = React.useState(width > height ? "Landscape" : "Portrait");
  

    const calculateOrientation = (width, height) => {
        if(width > height ){
            setOrientation("Landscape");
        }else{
            setOrientation("Portrait");
        }
    }
    React.useEffect(() => {
      calculateOrientation(window.innerWidth,window.innerHeight);
      const handleWindowResize = () => {
        
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        calculateOrientation(window.innerWidth,window.innerHeight);
    };
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);


    }, []);
  
    // Return the width so we can use it in our components
    return { width, height, orientation};
  }

export default useViewport;