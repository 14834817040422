 export class UAIMessageBox
    {
        constructor( ) {
        this.mode = "all";
        this.title = "Title";
        this.subtitle = "Subtitle";
        this.className = "uaiMessageBox-basic";
        this.body = "Body Text.";
        this.Agree = "Ok";
        this.Disagree = "Cancel";
        this.onAgree = undefined;
        this.onDisagree = undefined;
        this.open = false;
        }

        json(){
            return JSON.stringify(this);
        }
    }