import React, { useEffect, useState } from 'react'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { PivotControls } from '@react-three/drei';

// import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

const GLTFModel = ({url = 'https://firebasestorage.googleapis.com/v0/b/uai-backend.appspot.com/o/common%2Fassets%2Fgeo%2Fcube.glb?alt=media', position=[0, 0, 0],
    rotation=[0, 90, 0],
    scale=[1,1,1] ,
    loader=GLTFLoader
} ) => {
    const threejsObject = useLoader(loader, url);
    useEffect (() => {
       
    }, [url]);



    return (
        <PivotControls>
        <primitive
        object={loader == GLTFLoader ?  threejsObject.scene : threejsObject}
        position={position}
        rotation={rotation}
        scale={scale}
        children-0-castShadow
      />
      </PivotControls>
    // <></>
    )
}

export default GLTFModel;