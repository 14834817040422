import React, { useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { CameraControls, useGLTF } from '@react-three/drei'
import GLTFModel from './gltfModel';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

const ThreeD = ({url = 'https://firebasestorage.googleapis.com/v0/b/uai-backend.appspot.com/o/common%2Fassets%2Fgeo%2Fcube.glb?alt=media', loader=GLTFLoader}) => {

useEffect (() => {

}, [url]);

    return (
       
<div className="  appBlueBorder rounded-none h-full w-full ">
<Canvas >
<CameraControls makeDefault={true} />
<ambientLight intensity={1} />
  <directionalLight color="white" position={[0, 0, 5]} />
  <GLTFModel url={url} scale={20,20,20} loader={loader} />
 
</Canvas>
</div>
    )
}

export default ThreeD;