
import { useState, useEffect, useRef } from 'react';
// import AudioSplitForm from '../../../../components/banner/AudioSplitForm';

import FXDropdown from '../../FXDropdown';
import useAppManager from '../../../api/appManager';
import {createDictionary} from '../../../api/firebaseHandler';
import useFXPanel from '../api/useFXPanel';
import DynamicUI from './ui/dynamicUI';
import DynamicWindow from './ui/dynamicWindow';
import { useSignal, useSlot } from 'react-signal-slot';
import useRuntime from '../api/useRuntime';
import {RunComponent} from '../api/useComposite';
import AudioSplitForm from "../modules/builtin/audio/augment/AudioSplitForm";

const GeneratePanel = ({}) => {
    const manager =     useAppManager();
    const [views, setViews] = useState([]);
    const signal = useSignal();
    const addGenerateFX = useRuntime((state) => state.addGenerateFX);
    const setGenerateFX = useRuntime((state) => state.setGenerateFX);
    const generateFX = useRuntime((state) => state.generateFX);

  
    useSlot('timeline.tracks.showData.generate.panel', (e) => {
        addGenerateFX(e.views);
        signal("show.aiGenerate", {});
    });



   useEffect(() => {

   }, [generateFX])

   const defaultComponents = {
    "uai.builtin.audio.split1": ()=>{ return < AudioSplitForm />},
   }

   const onValueChanged = ({newValue, component})=>{
    let updatedValue = [];
    let newGenerateFX = [];
    if(generateFX){
    newGenerateFX = [...generateFX];

        for(let i = 0; i < newGenerateFX.length; i++){
            updatedValue.push(newGenerateFX[i].component);
        }
    }

    let updated = false;
    for (let ib = 0; ib < newValue.length; ib++) {
        const target = newValue[ib];
        const targetUUID = target.uuid;
    for (let i = 0; i < updatedValue.length; i++) {
        for (let j = 0; j < updatedValue[i].subwindows.length; j++) {
            
            for (let k = 0; k < updatedValue[i].subwindows[j].fields.length; k++) {

                if (updatedValue[i].subwindows[j].fields[k].uuid == targetUUID) {

                    updatedValue[i].subwindows[j].fields[k].value = target.value;
                    updated = true;
                }
            }
        }
    }
}
for(let i = 0; i < newGenerateFX.length; i++){
    newGenerateFX[i].component = updatedValue[i];
}
setGenerateFX(newGenerateFX);
    // onChange({newValue, updatedValue});
}
  
   const runAction = (props) => {
    const operation  = `${props.action.module}.${props.action.operation}`; 

    const cleanedUp = generateFX.map((item) => item.component);
    cleanedUp[0].action = props.action;
    const dict = createDictionary(cleanedUp);
    // signal('operation.run', {operation: operation, value: cleanedUp, dictionary: dict})
    RunComponent({operation: operation, value: cleanedUp, dictionary: dict, component:props.component, action:props.action, signal:signal, manager:manager});
}

    return (
        <div className="flex flex-col gap-2">
            {
                generateFX &&
                 generateFX.map((component) => {
                    console.log(component);

                    return (
        <div className="flex flex-col w-full">
                {
                Object.keys(defaultComponents).includes(component.component.name)? defaultComponents[component.component.name]():
                        <DynamicUI component={component.component}  onChange={onValueChanged} runAction={runAction} showFunctions={true} />
                }
                    
        </div>
        
                    )
                })

            }
        </div>
    )
}

export default GeneratePanel;