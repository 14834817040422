import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import dataBanner from "../assets/fake-data/data-banner";
import dataCategory from "../assets/fake-data/data-category";
import dataCollection from "../assets/fake-data/data-collection";
import dataCreate from "../assets/fake-data/data-create";
import dataExplore from "../assets/fake-data/data-explore";
import dataHotpick from "../assets/fake-data/data-hotpick";
import dataLiveaution from "../assets/fake-data/data-liveaution";
import dataSeller from "../assets/fake-data/data-topseller";
import Banner01UAI from "../components/banner/Banner01UAI";
import Category from "../components/category/Category";
import Collection from "../components/collection/Collection";
import Create from "../components/create/Create";
import Explore from "../components/explore/Explore";
import CommunitySpotlight from "../components/communitySpotlight/CommunitySpotlight";
import LiveAutions from "../components/liveautions/LiveAuctions";
import { DivVideo } from "../components/divs/DivVideo";
import { HeaderWithVideo } from "../components/header/HeaderWithVideo";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../uai/api/useAuth";
import TopSeller from "../components/topseller/TopSeller";
import LoadingSpinner from "./LoadingSpinner";
import Banner05 from "../components/banner/Banner05";
import { setHideNav } from "../reducers/cartReducer";
import LoadPlugin from "../uai/components/UAIAPP/modules/builtin/dist/loadPlugin";
// import HeaderWithVideo from './HeaderWithVideo';
import TestPanelImageGeneration from "../components/banner/TestPanelImageGeneration";
import PluginsHomePreviewPanel from "../components/banner/PluginsHomePreviewPanel";
import WebAPIHomePreviewPanel from "../components/banner/WebAPIHomePreviewPanel";
import TextToImage from "../uai/pages/ai/TextToImage";

function HomeUAI(props) {
    // const {isAuthenticated, user, isLoading} = useAuth0();
    const auth = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHideNav(false));
        console.log(process.env);
    }, []);

    return (
        <div className="home-1">
            {auth.pending ? (
                <LoadingSpinner Title="Loading" Message="Please wait" />
            ) : (
                <></>
            )}
            <div id="page" className={auth.pending ? "hidden" : ""}>
                <HeaderWithVideo
                    headerHeight="100%"
                    translateX="0px"
                    translateY="0px"
                />
                <LoadPlugin />
                {/* <Banner01UAI data={dataBanner} /> */}
                <Banner05 data={dataBanner} />

                {/* <Category data={dataCategory} /> */}
                {/* <Create data={dataCreate} /> */}
                <PluginsHomePreviewPanel />
                <WebAPIHomePreviewPanel />
                {/* <TextToImage /> */}
                <TestPanelImageGeneration data={dataBanner} />

                {/* <LiveAutions data={dataLiveaution} /> */}

                {/* <Explore data={dataExplore} /> */}

                {/* <CommunitySpotlight data={dataHotpick} /> */}

                {/* <Collection data={dataCollection} /> */}

                {/* <TopSeller data={dataSeller} /> */}
            </div>
        </div>
    );
}

export default HomeUAI;
