import { React, useState, useEffect } from 'react';
import { getuaiInstallers, getuaiAppsVisible , getuaiAppByName} from '../../../uai/api/downloads';
import DownloadsTable from '../components/DownloadsTable';
import DownloadsTableSolo from '../components/DownloadsTableSolo';




const DownloadsPage = () => {
  const [downloads, setdownloads] = useState([]);
  const [appNames, setAppNames] = useState([]);
  const [cachedApps, setCachedApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState('');


  const getdownloads = async () => {
    const downloadsFetch = await getuaiInstallers(`${process.env.REACT_APP_API }`);
    // console.log('downloadsFetch', downloadsFetch);
    setdownloads(downloadsFetch);
  };
  const getAppByName = async (name="") => {
    let name_ = name;
    if (name_ === "") {
      name_ = selectedApp;
    }

    if(name_ === "") {
      return;

    }
    const filtered = cachedApps.filter((app) => app.name === name_);
    if (filtered.length > 0) {
      setdownloads(filtered);

      return;
    }
    const downloadsFetch = await getuaiAppByName(`${process.env.REACT_APP_API }`, name_);
    // console.log('downloadsFetch', downloadsFetch);
    console.log('downloadsFetch', downloadsFetch);

    setdownloads(downloadsFetch);
    setCachedApps([...cachedApps, ...downloadsFetch]);
  };

  const getAppNames = async () => {
  
    const appNames = await getuaiAppsVisible(`${process.env.REACT_APP_API }`);
    setAppNames(appNames);
    setSelectedApp(appNames[0]);
    await getAppByName(appNames[0]);
    // console.log('appNames', appNames);
    return appNames;
  }

  useEffect(() => {
    getAppNames();
  }, []);

  return (
    <div className="dashboard">

<div className="w-full flex mb-4">
<div className="w-72 !bg-tranparent flex">
  <div className="flex flex-col gap-4">
                                        <h3 className="title text-2xl">Apps</h3>
                                    <select   className="selectDropdown h-16 my-auto text-center  hover:text-yellow-800" name="variation" id="vaiation"
                                    onChange={ async (e) => {

                                        console.log(e.target.value);
                                        setSelectedApp(e.target.value);
                                        await getAppByName(e.target.value);
                                    }}
                                    >
                                        {appNames.map(idx => {
                                            return <option value={idx}>{idx}</option>
                                        })}
</select>

</div>




    
    </div>
    
                                        </div>
      <div className="dashboard-group">
      <h2 className='page-title'>Latest</h2>
      <div className="dashboard-row">
        <DownloadsTableSolo download={downloads[0]} title="Current UAI Installer" />
      </div>
      </div>
      
      <div className="dashboard-group">
      <h2 className='page-title'>Archive</h2>
      <div className="dashboard-row">
        <DownloadsTable downloads={downloads} title="Previous UAI Installers" />
      </div>
      </div>
    </div>
  );
};

DownloadsPage.propTypes = {
};

export default DownloadsPage;
