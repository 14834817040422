/* eslint-disable import/no-named-as-default */
/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import {
React, useRef, useEffect, useState, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { setCart } from '../reducers/cartReducer';
import '../uai/uaistyle.css';

// import { UAIShopifyClient, getServerSideProps, UAIShopifyHealthCheck } from '../../../api/shopify/api/UAIShopifyClient';


// import { useResize } from '../../../api/useResize';


export const SuccessfulPurchase = () => { 
  const dispatch = useDispatch();
  const state = useSelector(state => state.appState.cart);
  const { loginWithRedirect, logout } = useAuth0();
  const GetUAIClicked = () => {
    let cart = {
      "percentOff": 0,
      "totalPrice": 0,
      "discount": 0,
      "subtotal": 0,
      "items": [],
      "couponCode": "",
      "coupon": {},
      "metaData": {},
  };
    dispatch(setCart(cart));
    window.location.href = '/dashboard';
  };
  localStorage.setItem('cart', JSON.stringify([]));

  const items = 'f';

  return (
    <div className="home-1">
    <div id="content" className="site-content center-relative">    
      <div id="services" className="section  !py-9">                   
        <div className="boundless-centered-txt">
          <h4>Success!</h4>
        </div>                   
        <div className="pt-5">
          <p>Thank you for purchasing a license! This newly purchased license will soon be visible on your User Dashboard.</p>
        </div>
        <br />
        <div className="button-holder headerButton" style={{ placeContent: 'center' }}>
          <button type="button" className="vButton" onClick={GetUAIClicked}>
            {/* <a href="/e-commerce/uai_product_page" className="uai-button-base button" onClick={GetUAIClicked}> */}
            Continue
          </button>
        </div>  
      </div>
      </div>




    </div> 
    
);
};


export default SuccessfulPurchase;
