import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';
import { Link } from 'react-router-dom';

import img1 from '../assets/images/details/imagegen/StableV_ImageGen_03.png'
import img2 from '../assets/images/details/imagegen/frog_03.png'
import img3 from '../assets/images/details/imagegen/frog_02.png'
import img6 from '../assets/images/details/imagegen/frog_01.png'
import img4 from '../assets/images/details/imagegen/frog_04.png'
import img5 from '../assets/images/details/imagegen/stablev_settings_frog.png'
import dog1 from '../assets/images/details/imagegen/dog_01.png'
import dog2 from '../assets/images/details/imagegen/dog_02.png'
import dog3 from '../assets/images/details/imagegen/dog_03.png'

import moth1 from '../assets/images/details/imagegen/mothman_01.png'
import moth2 from '../assets/images/details/imagegen/mothman_02.png'
import moth3 from '../assets/images/details/imagegen/mothman_03.png'
import dragon1 from '../assets/images/details/imagegen/dragon_01.png'
import dragon2 from '../assets/images/details/imagegen/dragon_04.png'
import durian1 from '../assets/images/details/imagegen/durian_01.png'
import durian2 from '../assets/images/details/imagegen/durian_02.png'
import durian3 from '../assets/images/details/imagegen/durian_03.png'
import avt1 from '../assets/images/blog/author.jpg'

Details_ImageGen.propTypes = {
    
};

function Details_ImageGen(props) {
    return (
        <div>

            <PageTitle sub='Learn More' title='Image Gen' />
            
            <section className="tf-blog-detail">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="detail-inner">
                                <div className="content-top">
                                    <h4 className="title ">Unlocking Creative Potential with Stable V and Image Generation</h4>
                                    {/* <div className="author-infor">
                                        <div className="image">
                                            <img src={avt1} alt="Binasea" />
                                        </div>
                                        <span>by</span>
                                        <Link to="#">Binasea</Link>
                                        <span className="mr9">|</span>
                                        <span>August 13, 2022</span>

                                    </div> */}

                                         
                                </div>
                                
                                <div className="image">
                                    <img src={img1} alt="Binasea" />
                                </div>
                             
                                <div className="content-inner">
                                    <h6 className="title">The Power of Stable V</h6>
                                    <p className='text-align-left'>Stable V's intuitive interface and powerful features empower users to create stunning visuals with ease. Whether you're a professional artist or a hobbyist, Stable V offers a range of tools and capabilities to bring your creative vision to life.</p>
                                    <p className="images-prompt">Prompt: A photograph of a dragon flying through a mountain. Drone shot. </p>
                                    
                                    <img className="" src={dragon1} alt="Binasea" />
                                    <img className="" src={dragon2} alt="Binasea" />
                                
                                </div>
                      
                                <div className="content-inner">
                                    <h6 className="title">ImageGen: Bringing Your Imagination to Life</h6>
                                    <p className='text-align-left'>ImageGen is the beating heart of Stable V, a feature that empowers users to create stunning images with unparalleled ease and speed. This innovative tool utilizes the power of Stable Diffusion XL Lightning, a cutting-edge AI technique, to generate images that are not only high in quality but also produced quickly, giving artists more time to focus on their creativity.</p>
                                </div>
                                <div className="image">
                                    <img src={img4} alt="Binasea" />
                                </div>

                                <div className="content-inner">
                                    <h6 className="title">How It Works</h6>
                                    <p className='text-align-left'>Using ImageGen is simple yet powerful. Users start by providing a prompt that describes the image they want to generate. This could be as specific or as abstract as they desire, allowing for a wide range of creative possibilities. Additionally, users can set parameters such as export size, generation seed, and even change models to further customize their images.</p>
                                    
                                    
                                    <img src={img5} alt="Binasea" />
                                
                                    <div className="images-3">
                                
                                    <img className="imgGroup-item" src={img2} alt="Binasea" />
                                    <img className="imgGroup-item" src={img3} alt="Binasea" />
                                    <img className="imgGroup-item" src={img6} alt="Binasea" />
                                </div>
                                    
                                    <h6 className="title">The Magic of Stable Diffusion XL Lightning</h6>
                                    <p className='text-align-left' >What sets ImageGen apart is its use of Stable Diffusion XL Lightning. This advanced AI technique allows for the gradual addition of noise to an existing image, creating a diffusion process that results in images with fine details and smooth transitions. This not only produces high-quality images but also enables quick generation, making it ideal for artists working on tight deadlines.</p>
                                

                                    <div className="images-container">
                                    
                                    <p className="images-prompt">Prompt: A photograph of a *insert breed* dog asleep under a truck. cute photo.  high quality. award winning. not sad. eyes closed </p>
                                <div className="images-3">
                                    <div onClick={()=>{
                                        console.log("Clicked");
                                    }} ><img src={dog1} alt="Binasea" /></div>
                                    <div onClick={()=>{
                                        console.log("Clicked2");
                                    }} ><img src={dog2} alt="Binasea" /></div>
                                    <div onClick={()=>{
                                        console.log("Clicked3");
                                    }} ><img src={dog3} alt="Binasea" /></div>
                                </div>

                                <div className="images-container">
                                    
                                    <p className="images-prompt">Prompt: A photo of a lady posing with a huge durian in front of a palm tree. promotional photo. commercial photo.  1960s style. shot on film. headshot </p>
                                <div className="images-3">
                                    <div onClick={()=>{
                                        console.log("Clicked");
                                    }} ><img src={durian1} alt="Binasea" /></div>
                                    <div onClick={()=>{
                                        console.log("Clicked2");
                                    }} ><img src={durian2} alt="Binasea" /></div>
                                    <div onClick={()=>{
                                        console.log("Clicked3");
                                    }} ><img src={durian3} alt="Binasea" /></div>
                                </div>
                                </div>


                                <div className="images-container">
                                    
                                    <p className="images-prompt">Prompt: A photo of a mothman baseball mascot costume. cute kawaii. purple theme. Not scary </p>
                                <div className="images-3">
                                    <div onClick={()=>{
                                        console.log("Clicked");
                                    }} ><img src={moth1} alt="Binasea" /></div>
                                    <div onClick={()=>{
                                        console.log("Clicked2");
                                    }} ><img src={moth2} alt="Binasea" /></div>
                                    <div onClick={()=>{
                                        console.log("Clicked3");
                                    }} ><img src={moth3} alt="Binasea" /></div>
                                </div>
                                </div>

                               
                                </div>

                                <h6 className="title">Seamless Integration with the Web API</h6>
                                    <p className='text-align-left'>Stable V's Web API enables seamless integration with Mac and mobile applications, extending its functionality to a wider range of platforms. Whether you're on the go or working on a Mac device, you can access Stable V's powerful features through the Web API.</p>
                                <h6 className="title">Unlocking Creative Potential</h6>
                                    <p className='text-align-left'>ImageGen is more than just a tool for generating images; it's a catalyst for creativity. By streamlining the image generation process and offering a wide range of customization options, ImageGen empowers artists to explore new ideas and push the boundaries of their creativity. Whether you're a professional artist looking to create concept art or an amateur photographer experimenting with new styles, ImageGen is the perfect tool to bring your imagination to life.</p>
                                
                                
                                </div>


                            
                            </div>                        
                        </div>                                     
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default Details_ImageGen;