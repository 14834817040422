import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { setTimelineSelected, setIsDirty, setCurrentTimeline } from '../../../../reducers/cartReducer';
import { TimelineEffect, TimelineRow } from '@xzdarcy/react-timeline-editor';
import { timeline } from '@material-tailwind/react';
import { useSignals } from '../../../api/signalSlots';
import useRuntime from '../../../components/UAIAPP/api/useRuntime';
import { audioTrackEffect} from '../../../components/UAIAPP/components/timeline/audioTrack';
import { imageTrackEffect} from '../../../components/UAIAPP/components/timeline/imageTrack';
import { useSignal, useSlot } from 'react-signal-slot';

const defaultTimeline: TimelineRow[] = () =>{

    return [];
};

export const baseEffects: Record<string, TimelineEffect> =() => {
    return( {
    ...imageTrackEffect,
   ...audioTrackEffect
  })};



class TimelineRuntime {
    constructor() {
        this.timeline = null;
        this.effect = null;
        this.editor = null;
        this.init();
    }

    init = () => {
        this.loadTimeline();
        this.getEffects();
    }

    setEditor = (editor) => {
        this.editor = editor;
    }


    loadTimeline = () => {

        let key_ = null;
        try{
            key_ =JSON.parse( localStorage.getItem('timeline'));
            }catch(e){
                console.log(e);
            }

        if(key_ && key_ != "" ){
            this.timeline =key_;
            return key_;
        }else{
            this.timeline = defaultTimeline();
            const timelineJS = JSON.stringify(this.timeline);
            localStorage.setItem('timeline', timelineJS);
        }
        return this.timeline;
    }


    getTimeline = () => {
        if(this.timeline == null){
            this.loadTimeline();
        }
        return this.timeline;
    }

    loadEffects = () => {
        let key_ = null;
      
            this.effect = baseEffects();
            // const effectjs = JSON.stringify(this.effect);
            // localStorage.setItem('effects', effectjs);
            console.log("loaded effects", this.effect);
        return this.effect;
    }
    getEffects = () => {
        if(this.effect == null){
            this.loadEffects();
        }
        return this.effect;
    }

    addEffect = (id, name) => {
        this.timeline.push({
            id: id,
            name: name,
        });
    }

    addRow = (id, actions, name="Track") => {
        this.timeline.push({
            id: id,
            actions: actions,
            name: name ? name : `Track ${this.timeline.length + 1}`,
        });
    }
    updateTimeline = (timeline) => {
        localStorage.setItem('timeline', JSON.stringify( timeline));

        this.timeline = timeline;
        return this.timeline;
    }

    deleteItem = (id) => {
        const time_ = this.getTimeline();
        let outData = [];
        for (let i = 0; i < time_.length; i++) {
            const item = time_[i];
            for (let j = 0; j < item.actions.length; j++) {
                const action = item.actions[j];
                console.log(action.id, id);
                if (action.id === id) {
                    outData.push(i);
                    break;
                }
            }
        }
        const newTimeline = time_.filter((item, index) => outData.includes(index) == false);
        console.log("removed Indexes", outData);
        // const newTimeline = time_.filter((item) => item.id !== id);
        this.updateTimeline(newTimeline);
        return newTimeline;
    }

    deleteTimeline = () => {
        localStorage.removeItem('timeline');
        this.timeline = defaultTimeline();
        this.init();
        return this.timeline;
    }

    newTimeline = () => {
        return this.deleteTimeline();
    }
    
    createMedia = (type="image", subType="imageGen", name="Track", start=-1, end=-1, data= {}) => {
        const time_ = this.getTimeline();
        let nextId = 0;

        const lastItem = time_[time_.length - 1];
        if(lastItem != undefined){
        const lastItemID = Number(lastItem.id);
        nextId = lastItemID + 1;
        }
    
        const newMedia = {
            id: nextId.toString(),
            name: name == "Track" ? name : `Track ${this.timeline.length + 1}`,
            actions: [
              {
                id: type+ nextId.toString(),
                start: start != -1 ?  start : 0,
                end:  end != -1 ?  end : 1,
                effectId: type,
                subType: subType,
                data: data
              },
            ],
          };
    
            const newTimeline = [...time_, newMedia];
            this.updateTimeline(newTimeline);
            return newMedia;
        }

    renameTrack = (id, name) => {

        const time_ = this.getTimeline();
        const indexOf = time_.findIndex((item) => item.id === id);
        if (indexOf === -1) {

            return;

        }
        }

    updateMediaByID = (id, media) => {
        const time_ = this.getTimeline();
        const indexOf = time_.findIndex((item) => item.id === id);
        if (indexOf === -1) {
            time_.push(media);
        }
        else {
            time_[indexOf] = media;
        }
        this.updateTimeline(time_);
        return media;
    }



}

let Timeline = null;


const syncEvent = new CustomEvent("syncTimeline", {
    detail: {
      timelineClass : Timeline
    }
  });







const useTimeline= ()=>{
    useSlot("syncTimeline", ()=>{
        setTimeline(Timeline.loadTimeline());
        return Timeline.getTimeline();
    });
    useSlot("timeline.addMedia", (e)=>{
        console.log("timeline.addMedia", e.detail);
        createMedia(e.type, "Track", e.subType, e.start, e.end, e.data);
    });
    const state = useSelector(state => state);
    const timelineState = useRuntime(state => state.sequence? state.sequence.timeline : []);
    const updateTimelineState = useRuntime(state => state.updateTimeline);
    const createLayer = useRuntime(state => state.createLayer);
    // const selected = [];
    const selected = useRuntime(state => state.appTimeline.selected);
    const [selectedTracks , setSelectedTracks] = useState([]);
    const [selectedForEdit, setSelectedForEdit] = useState([]);
    const isGenerating = useRuntime(state => state.appTimeline.isGenerating);
    const isRendering = useRuntime(state => state.appTimeline.isRendering);
    const setIsDirty = useRuntime(state => state.setIsDirty);
    const timelineID = useRuntime(state => state.appTimeline.currentTimeline);
    const isDirty = useRuntime(state => state.appTimeline.isDirty);
    const dispatch = useDispatch();
    const [timeline, setTimeline] = useState(defaultTimeline());
    const [initialized, setInitialized] = useState(false);
    const editor = useRef(null);
    const signal = useSignal();

    useEffect(() => {
        if(initialized == false){
        
        setInitialized(true);
        }
        getTimeline();

        
    }, [Timeline])

    const getSelected = () => {
        return state.appTimeline.selected;
    }
    const setSelected = (id) => {
        setTimelineSelected([id]);
        signal('timeline.tracks.deselect', {tracks: []});
    }
    const setSelectedTrack = (id) => {
        if(!Array.isArray(id)){
            id = [id];
        }
        setSelectedTracks(id);
        signal('timeline.tracks.deselect', {tracks: id});

    }
    const selectForEdit = (id) => {
        console.log("selectForEdit", id);
        // check if id is a list or string if not convert to list
        if(!Array.isArray(id)){
            id = [id];
        }
        setTimelineSelected(id);
        signal('timeline.tracks.showData', {tracks: id});

    }
    const addSelected = (id) => {
        const newSelected = [...selected, id];
        const newSelected2 = [...selectedTracks, id];
        setSelectedTracks(newSelected2);
        setTimelineSelected(newSelected);
        signal('timeline.tracks.deselect', {tracks: newSelected2});
    }

    const removeSelected = (id) => {
        const newSelected = selected.filter((item) => item !== id);
        setTimelineSelected(newSelected);
    }

    const clearSelected = () => {
        setTimelineSelected([]);
    }

    const play = () => {
        editor.current.play();}
    const pause = () => {
        editor.current.pause();}
    const stop = () => {
        editor.current.stop();}
    const seek = (time) => {
        editor.current.seek(time);}
    const getTime = () => {
        return editor.current.getTime();}
    const setTime = (time) => {
        editor.current.setTime(time);}

    const getPlayRate = () => {
        return editor.current.getPlayRate();}
    const setPlayRate = (rate) => {
        editor.current.setPlayRate(rate);}
    const isPlaying = () => {
        return editor.current.isPlaying();}
    const isPaused = () => {
        return editor.current.isPaused();}

    const setEditor = (editor) => {
        if(Timeline == null){
            Timeline = new TimelineRuntime();
        }
        Timeline.setEditor(editor);
    }
    const refreshTimeline = () => {
        setTimeline(Timeline.loadTimeline());
        return Timeline.getTimeline();
    }

    const updateTimeline = (timeline) => {
        Timeline.updateTimeline(timeline);
        setTimeline(Timeline.getTimeline());
        setIsDirty(true);
        syncHooks();
        return Timeline.getTimeline();
    }

    const saveTimeline = () => {


    }

    const updateMediaByID = (id, media) => {
        Timeline.updateMediaByID(id, media);
        setTimeline(Timeline.getTimeline());
        return Timeline.getTimeline();
    }

    const deleteTimeline = () => {
        Timeline.deleteTimeline();
        setTimeline(Timeline.getTimeline());
    }
    const deleteItem = (id) => {
        console.log("deleteItem", id);
        Timeline.deleteItem(id);
        setTimeline(Timeline.getTimeline());
        return Timeline.getTimeline();
    }
    const getTimeline = () => {
        if(Timeline == null){
            Timeline = new TimelineRuntime();
        }
        setTimeline(Timeline.getTimeline());
        return Timeline.getTimeline();
    }

    const syncHooks = () => {
        signal(syncEvent);
    }

    const fieldUpdated = (field, component) => {
        const action = component.action;
        if(component.isFX == false){
            return;
        }
        const componentItem = component.component;

        console.log("fieldUpdated", field, component);
        const uid = componentItem.uid;
        const updatedTimeline = [...timeline];
        for (let i = 0; i < updatedTimeline.length; i++) {
            const item = updatedTimeline[i];
            for (let j = 0; j < item.actions.length; j++) {
                const action_ = item.actions[j];
                if (action_.id === action.id) {


                    for (let k = 0; k < action_.data.components.length; k++) {
                        const componentInternal = action_.data.components[k];

                        if (componentInternal.uid === uid) {
                            let matched = false;
                            for(let l = 0; l < componentInternal.properties.length; l++){
                                const field_ = componentInternal.properties[l];
                                if(field_.name === field.name){
                                    componentInternal.properties[l].value = field.value;
                                    matched = true;
                                }
                            }
                            if(!matched){
                                const newField = {name: field.name, value: field.value};
                                componentInternal.properties.push(newField);
                            }
                        }
                    }
                }
            }
        }
        updateTimeline(updatedTimeline);
        
    }

    const createMedia = (type="image", name = "Track", subType="imageGen", start=-1, end=-1, data= {}) => {
        return  createLayer(type, name, subType, start, end, data);
        // return newMedia;
    }

    // const timeline = ()=>{ 
    //     return getTimeline();
    // }
    const effects = ()=>{ 
        getTimeline();
        return Timeline.getEffects();}
    const timelineClass = ()=>{ return Timeline;}

    return {timeline, effects, editor, timelineClass, getTimeline , deleteItem,
        updateTimeline, deleteTimeline, createMedia, updateMediaByID,
        play, pause, stop, seek, getTime, setTime, getPlayRate,
        setPlayRate, isPlaying, isPaused, setEditor, syncEvent, syncHooks,
        getSelected, selected, isGenerating, isRendering,
        setSelected, addSelected, removeSelected, clearSelected, setSelectedForEdit, selectedForEdit, selectForEdit,
        setSelectedTrack, selectedTracks, fieldUpdated
    } ;

}

export {useTimeline};