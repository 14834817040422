import { useMotionValue, useSpring } from "framer-motion";
import React, { useEffect, useRef } from "react";
const AnimatedNumber = ({ value = 0, className="" })=> {
  const ref = useRef(null);
  const motionValue = useMotionValue(0);
  const springValue = useSpring(motionValue);

  useEffect(() => {
    motionValue.set(value);
  }, [motionValue, value]);

  useEffect(
    () =>
      springValue.onChange((latest) => {
        if (ref.current) {
          ref.current.textContent = latest.toFixed(0);
        }
      }),
    [springValue]
  );

  return <span  ref={ref} className={className} />;
}

export default AnimatedNumber;