import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useAPIKey } from "../../uai/api/useAPIKey";
import { Navigation, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {setTrialState} from '../../reducers/cartReducer';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Link } from 'react-router-dom';
import useViewport from '../../uai/components/hooks/useViewport';
import img1 from '../../images/BaseEmptyBG.png';
import { set } from 'immutable';
import axios from 'axios';
import dinoegg1 from '../../assets/images/details/imagegen/dinoEgg_01.png';
import dinoegg2 from '../../assets/images/details/imagegen/dinoEgg_02.png';
import dinoegg3 from '../../assets/images/details/imagegen/dinoEgg_03.png';
import dinoegg4 from '../../assets/images/details/imagegen/dinoEgg_04.png';
import dinoegg5 from '../../assets/images/details/imagegen/dinoEgg_05.png';
import dinoegg6 from '../../assets/images/details/imagegen/dinoEgg_06.png';
import dinoegg7 from '../../assets/images/details/imagegen/dinoEgg_07.png';
import dinoegg8 from '../../assets/images/details/imagegen/dinoEgg_08.png';
import dinoegg9 from '../../assets/images/details/imagegen/dinoEgg_09.png';
import dinoegg10 from '../../assets/images/details/imagegen/dinoEgg_10.png';
import { prev } from 'dom7';
import { max } from 'moment'
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
  } from "@material-tailwind/react";
 
  
import '../../uai/uaistyle.css';

 
function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
      </svg>
    );
  }
 
  
const previewImages = [dinoegg1, dinoegg2, dinoegg3, dinoegg4, dinoegg5, dinoegg6, dinoegg7, dinoegg8, dinoegg9, dinoegg10];

TextToImageGeneration.propTypes = {
    
};


function TextToImageGeneration({maxImagesGenerated = 5}) {
    const dispatch = useDispatch();
    const {apiKey , verified, isLoading:apiKeyIsVerifying}  = useAPIKey();
    const {width, height, orientation} = useViewport();
    const [prompt, setPrompt] = useState("");
    const [negprompt, setNegPrompt] = useState("Distorted, discontinuous, Ugly, blurry, low resolution, motionless, static, disfigured, disconnected limbs, Ugly faces, incomplete arms. low quality. bad quality. low detail.");
    const [isGenerating, setIsGenerating] = useState(false);
    const [generatedMedia, setGeneratedMedia] = useState(false);
    const [advancedSettingsOpened, setAdvancedSettingsOpened] = useState(false);
    const trial = useSelector(state => state.appState.trial);
    const maxImages = maxImagesGenerated;
    const [trialFinished, setTrialFinished] = useState(maxImages == -1 ? false : trial.imagegen > maxImages ? true : false);

    const generateButton = useRef(null);
    const previewImageGen = useRef(null);
    const [ generatedImage, setGeneratedImage] = useState(<img className="previewImage-row" ref={previewImageGen}  src={img1} alt="Binasea" />);
    // const [imageClass, setImageClass] = useState(orientation != "Portrait" ? "previewImage-row" : "previewImage-row-portrait")

    const checkTrialFinished = (trialState) => {
        if(maxImages != -1 && trialState.imagegen > 5){
            setTrialFinished(true);
        }
        }
    useEffect(() => {
        checkTrialFinished(trial);
        if(generatedMedia == false && previewImageGen.current != null){
        const randomPreviewImage = Math.floor(Math.random() * previewImages.length);
        previewImageGen.current.src = previewImages[randomPreviewImage];
        }
    }, []);

    useEffect(() => {
        console.log("width: ", width);
        console.log("height: ", height);
        console.log("orientation: ", orientation);
    }, [width, height, orientation]);

    const generateImage = async () => {

        if( maxImages != -1&&  maxImages < trial.imagegen){
            alert("You have reached the maximum number of generated images for the free trial. Please purchase a license or tokens to continue.");
            return;
        }
        const endpoint = "https://uai.software/warp/api/v1/text/to/image/generate";
        const baseButtonHTML = generateButton.current.innerText;
        setIsGenerating(true);
        setGeneratedMedia(true);

        generateButton.current.innerText = "Generating...";
        
        const randomNumber = Math.floor(Math.random() * 100000);
        const req = {
            "ratio": 1,
            "configScale": 0,
            "seed": randomNumber,
            "prompt": prompt,
            "negative_prompt": negprompt +" nsfw. boobs. not safe for work. nudity. nipples. vagina. penis. high contrast. very contrast. .2d. drawing. painterly. multiple people. more than one person. (octane render, render, drawing, anime, bad photo, bad photography:1.3), (worst quality, low quality, blurry:1.2), (bad teeth, deformed teeth, deformed lips), (bad anatomy, bad proportions:1.1), (deformed iris, deformed pupils), (deformed eyes, bad eyes), (deformed face, ugly face, bad face), (deformed hands, bad hands, fused fingers), morbid, mutilated, mutation, disfigured",
            "num_images_per_prompt": 1,
            "size": [1024, 1024],
            "steps": 8,

            "model":"/repository",
            "modelType": "sdxll",
            "inputs":[],
    
    "faceFix": 1,
    "version" :1.3,
    "upscale" :2,
    "bg_upsampler" :"realesrgan",
    "bg_tile" :400,
    "suffix" :"None",
    "only_center_face" :false,
    "aligned" :true,
    "ext" :"auto",
    "weight" :0.75,
        }
        console.log(req);
        
        const response = await axios.post(
            endpoint, 
            req,
            {headers:{ "Authorization": "Bearer " + process.env.REACT_APP_UAI_API_KEY}}
        );

        const newTrial = {... trial}
        newTrial.imagegen += 1;
   dispatch(setTrialState(newTrial));

   checkTrialFinished(newTrial);
    
        const base64img = response.data.media[0].media;
        const baseString=  "data:image/png;base64," + base64img;
        previewImageGen.current.src = baseString;
        generateButton.current.innerText = baseButtonHTML;
setIsGenerating(false);
    }

    const validateInput = (value) =>{

        setPrompt(value);

    }

    return (
        <>
        { apiKey == null ?
        <div className="tf-container"><div className="row"><div className="col-md-12 w-full"><div className="tf-slider-item style-4 p-10px w-full place-content-center">
            <div className="content-container flex flex-col">
            <h1>API Key Required</h1>
            <Link to="/dashboard">Please select an API Key on your account page to continue.</Link>

            </div>
            </div></div>
            </div>
            </div>
            : 
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                                    <div className="tf-slider-item style-4 p-10px">
                                        <div className={"content-container" + (orientation != "Portrait" ? "" : "-portrait") }>


                                        <div class="container flex flex-row gap-8 p-8">
        <div class="row">
                                        <div hidden="hidden" data-form-alert-danger="" class="alert alert-danger col-12">Oops...! some problem!</div>
</div>



<div class="dragArea row w-1/2 flow-root">
<div class="col-lg-12 col-md-12 col-sm-12">
<h1 class="mbr-fonts-style display-5">Text To Image: Lightning</h1>
</div>





                                     
{/* <div id="inputImageDiv" data-for="topOffset" className="col-lg-12 col-md-12 col-sm-12 form-group">
    <label for="inputImage-formbuilder-6" className="form-control-label mbr-fonts-style display-7">Input Image</label>
    <input type="file" name="inputImage" id="inputImage-formbuilder-6" data-form-field="inputImage" className="form-control display-7 text-white" step="1"onChange={(e)=>{}}/>
    </div>

     */}
<div id="promptArea" class="col-lg-12 col-md-12 col-sm-12 form-group" data-for="prompt">
<label for="prompt-formbuilder-6" class="form-control-label mbr-fonts-style display-7">Prompt</label>
<textarea name="prompt" onChange={(e)=>{
    console.log(e.target.value);
    setPrompt(e.target.value);
 }} placeholder="Describe what will be happening in the generated media." data-form-field="prompt"  class="form-control display-7 inputField text-white" id="prompt-formbuilder-6"></textarea>
</div>
<div  id="negativepromptArea" data-for="negativeprompt" class="col-lg-12 col-md-12 col-sm-12 form-group">
<label for="negativeprompt-formbuilder-6" class="form-control-label mbr-fonts-style display-7">Negative Prompt</label>
<textarea name="negativeprompt"  onChange={(e)=>{
    setNegPrompt(e.target.value);
 }} placeholder="Update based on what you don't want in the generation." data-form-field="negativeprompt" class="form-control display-7 text-white" value={negprompt}  id="negativeprompt-formbuilder-6"></textarea>
</div>


<Accordion open={advancedSettingsOpened } className=" form-group px-4 text-white">
        <AccordionHeader className="rounded-none bg-transparent border-[#183272] border-solid border-solid border-[1px] " icon={<Icon id={1} open={advancedSettingsOpened} />} onClick={() => setAdvancedSettingsOpened(!advancedSettingsOpened)}>Advaned Settings</AccordionHeader>
        <AccordionBody>

<div data-for="seed" class="col-lg-12 col-md-12 col-sm-12 form-group">
<label for="seed-formbuilder-6" class="form-control-label mbr-fonts-style display-7">Seed</label>
<input type="text" name="seed"  id="seed" placeholder="Change to get different exports" data-form-field="seed" required="required" class="form-control display-7" value="987545" id="seed-formbuilder-6"/>
<button type="button" id="randomizeSeed" class="btn btn-primary">Randomize Seed</button>
</div>
<div data-for="scale" class="col-lg-12 col-md-12 col-sm-12 form-group">
<label for="scale-formbuilder-6" class="form-control-label mbr-fonts-style display-7">Input Integrity</label>
<input type="text" name="scale" placeholder="Change to get different exports" data-form-field="scale"  class="form-control display-7" value="0.45" max="1.0" min="0.1" id="seed-formbuilder-6"/>
</div>
<div data-for="num_images_per_prompt" class="col-lg-12 col-md-12 col-sm-12 form-group">
<label for="num_frames-formbuilder-6" class="form-control-label mbr-fonts-style display-7">Images To Generate</label>
<input type="number" name="num_images_per_prompt" placeholder="1" max="1" min="1" step="1" data-form-field="num_images_per_prompt" required="required" class="form-control display-7" value="1" id="num_frames-formbuilder-6"/>
</div>



    
    
<div data-for="exportSize" class="col-lg-12 col-md-12 col-sm-12 form-group">
<label for="exportSize-formbuilder-6" class="form-control-label mbr-fonts-style display-7">Export Size</label>
<select name="exportSize" data-form-field="exportSize" class="form-control form-select display-7" id="exportSize-formbuilder-6">
<option value="1024,1024">1024 x 1024</option>
<option value="1024,576">1024 x 576</option>
<option value="1920,1080">1920 x 1080</option>
<option value="1280,720">1280 x 720</option>
<option value="640,360">640 x 360</option>
<option value="512,512">512 x 512</option>

</select>
</div>
</AccordionBody>
</Accordion>

<div class="col-auto m-0"><button ref={generateButton} onClick={(e)=>{

    e.preventDefault();
    generateImage();
}} id="generateButton" class="w-100 w-100 w-100 btn btn-primary display-7  m-0"><span class="fa fa-magic mbr-iconfont mbr-iconfont-btn"></span>Generate&nbsp;</button></div>

                                            
                        </div>

        <div class="row  w-1/2 m-auto">
        <div class="col-lg-12 col-md-12 col-sm-12">

{/* <h1 class="mbr-fonts-style display-5">Generated Media</h1> */}
<img className="previewImage-row w-full" ref={previewImageGen}  src={img1} alt="Binasea" />
</div>
</div>















                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
}
</>

    );
}

export default TextToImageGeneration;