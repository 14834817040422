import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCartShopping, faEdit, faKey } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {titleCase} from '../../../uai/utils/common';
import {GetCustomerSubscriptionPortal} from '../../../uai/api/auth';
import img1 from '../../../assets/images/product/product4.jpg'
import img2 from '../../../assets/images/product/product5.jpg'
import img3 from '../../../assets/images/product/product6.jpg'

import icon1 from '../../../assets/images/svg/icon-wallet-1.svg'
import icon2 from '../../../assets/images/svg/icon-wallet-2.svg'
import icon3 from '../../../assets/images/svg/icon-wallet-3.svg'
import icon4 from '../../../assets/images/svg/icon-wallet-4.svg'
import icon5 from '../../../assets/images/svg/icon-wallet-5.svg'
import icon6 from '../../../assets/images/svg/icon-wallet-6.svg'
import icon7 from '../../../assets/images/svg/icon-wallet-7.svg'
import icon8 from '../../../assets/images/svg/icon-wallet-8.svg'

import avtf1 from '../../../assets/images/author/author-follow1.jpg'
import avtf2 from '../../../assets/images/author/author-follow2.jpg'
import avtf3 from '../../../assets/images/author/author-follow3.jpg'
import avtf4 from '../../../assets/images/author/author-follow4.jpg'
import avtf5 from '../../../assets/images/author/author-follow3.jpg'
import avtf6 from '../../../assets/images/author/author-follow4.jpg'

import { useAuth} from '../../api/useAuth';
import MessageModal    from '../UAIAPP/components/layouts/MessageModal';

// import {setStrapiUser, setUserMediaContent} from '../reducers/cartReducer';
// import { checkUser } from '../uai/api/auth';
// import {  GetUserMediaContents } from '../uai/api/mediaContent';

import {getUserLicenses , getUserSubscriptions } from '../../../uai/api/licenses';
import LoadingSpinner from '../../../pages/LoadingSpinner';
Licenses.propTypes = {
    
};

function Licenses(props) {
    // const { user, isAuthenticated, isLoading } = useAuth0();
   const { pending:isLoading, isSignedIn:isAuthenticated,user:user, auth:auth_ } = useAuth()
    const stripeID = JSON.parse(localStorage.getItem('user')).app_metadata.stripeId;
    const [licenseComponents, setLicenseComponents] = useState([]);
    const [showAPIKeyModal, setShowAPIKeyModal] = useState(false);
    const state = useSelector(state => state);
    const [mode, setMode] = useState('loading');
    const [loadingMessage, setLoadingMessage] = useState('Loading licenses');
    
    const [licenses, setLicenses] = useState([]);
    const dispatch = useDispatch();
    const handleCallback = () => {
        setMode('main');
    }
    const updateSubscriptionClicked = async (item) => {
        const url = await GetCustomerSubscriptionPortal(stripeID, process.env.REACT_APP_FULLPATH+"/dashboard", item.id,'subscription_cancel' );
        window.location.href = url;
    }

    useEffect(() => {
        const checkUserStatus = async () => {
            // setCurrentText('Please wait while your account information loads.\nIf the page does not reload automatically after 1 minute, please click this message.');
            // const licenses = await getUserLicenses(state.appState.strapiUser.username, state.appState.jwt);
            const subscriptions = await getUserSubscriptions(stripeID);
            console.log(stripeID);
            console.log(subscriptions);
            setLicenses(subscriptions);

            
            setLicenseComponents(
                subscriptions.map(item => {


                    return(
                        <div className="content-ranking">
                        <div className="col-rankingg !text-lg"><div className="image cursor-pointer"><a href={item.plan.metadata.page}><img src={item.image} alt="Binasea" /></a></div></div>
                        <div className="col-rankingg !text-lg">{item.items.data[0].plan.product.name}</div>
                        <div className="col-rankingg !text-base"> {titleCase(item.plan.metadata.PlanType) +" : "+ titleCase(item.plan.interval)}</div>
                        {/* <div className="col-rankingg coin !text-lg">{product_.interval}</div> */}
                        <div className="col-rankingg !text-lg">{moment(item.start_date*1000).format("MM/DD/YY HH:mm")}</div>
                        <div className="col-rankingg !text-lg">{item.canceled_at != null ? "Canceled" :  
                        <>
                        <FontAwesomeIcon  className='pr-3 icon-fill hover:text-blue-600 !hover:text-xl2 cursor-pointer' icon={faEdit} onClick={()=>{
                           updateSubscriptionClicked(item);

                        }} />Live</>
                        }</div>
                        <div className="col-rankingg !text-lg ">
                            <FontAwesomeIcon  className='pr-3 icon-fill hover:text-blue-600 !hover:text-xl2 cursor-pointer' icon={faKey} onClick={()=>{
                            const item_ = item.id.split("_")[1];
                            navigator.clipboard.writeText(item_);
                            localStorage.setItem('APIKey', item_);
                            setShowAPIKeyModal(true);
                        }} />
                           
                        
                        </div>
                    </div>


                    );
                })
            )
            handleCallback();
            };
                checkUserStatus();
    }, [isLoading]);


    return (
      <>
      
                                            <div className="inner-content inventory">
                                        <h4 className="title-Licenses">Licenses</h4>
                                        <div className="table-ranking top">
                                        
                                            <div className="title-ranking">
                                                <div className="col-rankingg !text-lg !text-white"><Link to="#"  className="!text-lg !text-white">Image</Link></div>
                                                <div className="col-rankingg !text-lg !text-white"><Link to="#" className="!text-lg !text-white">Name</Link></div>
                                                <div className="col-rankingg !text-lg !text-white"><Link to="#" className="!text-lg !text-white">Type</Link></div>

                                                <div className="col-rankingg !text-lg !text-white"><Link to="#" className="!text-lg !text-white">Purchased</Link></div>
                                                <div className="col-rankingg !text-lg !text-white"><Link to="#" className="!text-lg !text-white">Plan</Link></div>
                                                <div className="col-rankingg !text-lg !text-white pr-3"><Link to="#" className="!text-lg !text-white">API</Link></div>
                                            </div>
                                        </div>
                                        {mode == 'loading' ? <LoadingSpinner Title={loadingMessage} Message="Parsing Licenses" Context="small"/> : 
                                            <>
                                        <div className="table-ranking ">

                                            
                                      
                                    {licenseComponents}

                                            <div className="table-btn">
                                                <Link to="#">Load more</Link>
                                            </div>
                                            
                                        </div>
                                        </>
}

                                            </div>
        <MessageModal show={showAPIKeyModal} onClose={()=>{
            window.location.reload();
        }} onHide={()=>{
            setShowAPIKeyModal(false);
        }} title='API Key Copied' body='API Key has been applied along with being copied to your clipboard.' />
        </>
    );
}

export default Licenses;