
import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BaseEmptyBG from '../../images/BaseEmptyBG.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCartShopping, faPhotoFilm, faComments } from '@fortawesome/free-solid-svg-icons';
import icon1 from '../../assets/images/icon/rain1.svg'
import icon2 from '../../assets/images/icon/rain2.svg'
import icon3 from '../../assets/images/icon/ethe.svg'
import CardModal from '../layouts/CardModal';
import {setCurrentViewMedia} from '../../reducers/cartReducer';
const addDefaultSrc = (ev) =>{
    // delete the ev item from document
    // document.body.removeChild(ev.target);
    ev.target.src = BaseEmptyBG;
  }

export const MediaContentCard = ({ mediaContent })  =>{
    const { user, isAuthenticated, isLoading } = useAuth0();
    const dispatch = useDispatch();
             
    
    const MediaSelected = (media) => {

        dispatch(setCurrentViewMedia(media));
        window.location.href = "/mediaDetails";
    }

    useEffect(() => {



    }, [mediaContent]);



return (
    <div key={mediaContent.id} className="col-xl-3 col-lg-4 col-md-6 col-sm-6 tf-loadmore 3d cyber">
        <div className="sc-product style2" onClick={( e) => {
                        e.preventDefault();
                        MediaSelected(mediaContent);

                    } }>
            <div className="top">
                {mediaContent.name ? 
                <Link to="/item-details-v1" className="tag !text-2xl text-white hover:text-blue-gray-900" onClick={( e) => {
                    e.preventDefault();
                    MediaSelected(mediaContent);

                } }>{ mediaContent.name.substring(0, 20) + (mediaContent.name.length > 20 ? " ..." :"" )}</Link>
                : <></>}
                <div className="wish-list">
                    <Link to="#" className="heart-icon"></Link>
                    <div className="name absolute !text-sm text-indigo-400 font-bold">{mediaContent.Comments.length}</div>
                
                </div>
            </div>
            <div className="bottom">
                <div className="details-product">
                    <div className="author">
                        <div className="avatar">
                            <img src={user ? user.picture : ""} alt="images" className="rounded-2xl" onError={addDefaultSrc}/>
                        </div>
                        <div className="content">
                            <div className="position">Creator</div>
                            <div className="name  !text-base"> <Link to="#">{mediaContent.user.username ? mediaContent.user.username.substring(0, 20) : mediaContent.user }</Link></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="features">
                <div className="product-media max-h-72 max-w-72">
                    <img src={
                        
                    "https://uai-web-bucket-2201.s3.amazonaws.com/"+ mediaContent.url
                    } alt="images" />
                </div>
                <div className="rain-drop1"><img src={icon1} alt="images" /></div>
                <div className="rain-drop2"><img src={icon2} alt="images" /></div>
            </div>
            <div className="bottom-style2">
                <div className="price">
                    <div className="icon">
<FontAwesomeIcon  className='p-3 icon-fill border-0 borer-[#b9b8bb] rounded-full' icon={faComments} />
                        </div>
                    <div className="content">
                        <div className="name !text-base">{mediaContent.Comments.length}</div>
                        <div className="cash">{mediaContent.price}</div>
                    </div>
                </div>
                <div className="product-button">
                    <Link to="#" onClick={( e) => {
                        e.preventDefault();
                        MediaSelected(mediaContent);

                    } } data-toggle="modal" data-target="#popup_bid" className="tf-button">View</Link>
                </div>
            </div>

        </div>
    </div>
)}