import {copy} from '../utils/common'
import  app  from './firebaseHandler';
import  {getCollection}  from './firebaseHandler';
/* eslint-disable prefer-destructuring */
import axios from 'axios';

export async function GetMediaContentProjects(userID, appName, jwt) {
    const endpoint = `${process.env.REACT_APP_API }/apiv1/user/get/mediacontent/projects/byapp/object`;
    const outData = {
        user: userID,
        app: appName,
        jwt,
    };
    const result = await axios
        .post(endpoint, outData,
        {
            headers: {
                'content-type': 'application/json',
                },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}

export async function GetMediaContentProjectNames(userID, appName, jwt) {
    const endpoint = `${process.env.REACT_APP_API }/apiv1/user/get/mediacontent/projects/byapp/names`;
    const outData = {
        user: userID,
        app: appName,
        jwt,
    };
    const result = await axios
        .post(endpoint, outData,
        {
            headers: {
                'content-type': 'application/json',
                },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return [];
    }
}
export async function GetMediaContentProjectMediaContents(userID, project_, jwt_) {
    const endpoint = `${process.env.REACT_APP_API }/apiv1/user/get/mediacontent/projects/base`;
    const outData = {
        user: userID,
        project: project_,
        jwt: jwt_,
    };
    const result = await axios
        .post(endpoint, outData,
        {
            headers: {
                'content-type': 'application/json',
                },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return [];
    }
}

export async function GetUserMediaContents(userID, jwt_) {
    const collection_ = await getCollection('media-contents');
    const filteredItems = collection_.filter(item => item.user.id === userID);
    
    const result = { data: filteredItems}

    // const endpoint = `${process.env.REACT_APP_API }/apiv1/user/get/mediacontent`;
    // const outData = {
    //     user: userID,
    //     jwt: jwt_,
    // };
    // const result = await axios
    //     .post(endpoint, outData,
    //     {
    //         headers: {
    //             'content-type': 'application/json',
    //             },
    //     }).catch(err => (err));



    try {
        console.log('GetUserMediaContents Good', result);
        // console.log('GetUserMediaContents Good', result.data);
        let mediaContent = await result.data; 
        let outMediaContent = [];
        for (let i = 0; i < mediaContent.length; i++) {
            if(mediaContent[i].url.includes("auth0|") || mediaContent[i].url.includes("auth0-")){
                // remove item from array
            }else{

                let newContent = copy(mediaContent[i],true);
                for (let j = 0; j < mediaContent[i].playlists.length; j++) {
                    newContent.playlists[j] = copy(mediaContent[i].playlists[j],true);
                    if(mediaContent[i].playlists[j].mediaContent == undefined){
                        newContent.playlists[j].mediaContent = [];

                    }else{
                    for (let k = 0; k < mediaContent[i].playlists[j].mediaContent.length; k++) {
                        newContent.playlists[j].mediaContent[k] = copy(mediaContent[i].playlists[j].mediaContent[k],true);
                        newContent.playlists[j].mediaContent[k].playlists = [];
                    }
                }
                }

                outMediaContent.push(newContent);
            }
        }   
      
        return  outMediaContent;
    } catch(error) {
        console.error(error);
        console.error('GetUserMediaContents FAIL');

        return [];
    }
}
export async function GetFrontpageMediaContents(nsfw_, limit_ = 10, start_ = 0) {
    const endpoint = `${process.env.REACT_APP_API }/apiv1/user/get/mediacontent/frontpage`;
    const outData = {
        nsfw: nsfw_,
        limit: limit_,
        start: start_,
    };
    const result = await axios
        .post(endpoint, outData,
        {
            headers: {
                'content-type': 'application/json',
                },
        }).catch(err => (err));
    try {
        // console.log('GetUserMediaContents Good', result);
        return await result;
    } catch {
        console.error('GetUserMediaContents FAIL');

        return [];
    }
}
export async function GetUserMediaContentsRAW(userID, jwt_) {
    const endpoint = `http://68.183.115.32:1337/media-contents?user.id=${ userID.toString()}`;
    
    const result = await axios
        .get(endpoint,
        {
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${ jwt_ }`,
                },
        }).catch(err => (err));
    try {
        // console.log('GetUserMediaContents Good', result);
        return await result;
    } catch {
        console.error('GetUserMediaContents FAIL');

        return [];
    }
}

export async function CreateMediaContent(userID, data) {
    const endpoint = `${process.env.REACT_APP_API }/apiv1/user/register/mediacontent`;

    const result = await axios
        .post(endpoint, data,
        {
            headers: {
                'content-type': 'application/json',
                },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}

export async function UpdateMediaContent(userID, data) {
    const endpoint = `${process.env.REACT_APP_API }/apiv1/user/update/mediacontent`;

    const result = await axios
        .post(endpoint, { media: data },
        {
            headers: {
                'content-type': 'application/json',
                },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}


export async function DeleteMediaContent(userID, id_, jwt_) {
    const endpoint = `${process.env.REACT_APP_API }/apiv1/user/delete/mediacontent`;
    const data = { id: id_, jwt: jwt_ };

    const result = await axios
        .post(endpoint, { media: data },
        {
            headers: {
                'content-type': 'application/json',
                },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}

export const asyncFilter = async (arr, predicate) => {
        const results = await Promise.all(arr.map(predicate));
    
        return arr.filter((_v, index) => results[index]);
    };

export default GetMediaContentProjects;
