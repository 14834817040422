import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from "@material-tailwind/react";
import { useAuth0 } from '@auth0/auth0-react';
import {setStrapiUser, setUserMediaContent, setMediaCollections, setAppState} from '../reducers/cartReducer';
import { checkUser } from '../uai/api/auth';
import {  GetUserMediaContents } from '../uai/api/mediaContent';
import {MediaContentToPlaylists, baseCollection} from '../uai/utils/common';
import  LoadingSpinner  from './LoadingSpinner';


function CallBack(props) {
    const state = useSelector(state => state);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const dispatch = useDispatch();
    const [mediaContents, setmediaContents] = React.useState([]);
    const [userObject, setuserObject] = React.useState({});
    const [licenses, setlicenses] = React.useState([]);
    const [renderComponent, setRenderComponent] = React.useState(<div></div>);
    const [currentText, setCurrentText] = React.useState('Please wait while your account information loads.\nIf the page does not reload automatically after 1 minute, please click this message.');
    const [retries, setRetries] = React.useState(0);
    const maxRetries = 5;


    const handleLogin = () => {
        if(isAuthenticated){
        const callbackData = state.appState.callbackData;
        window.location.href = callbackData.returnPage;
        }
    }

    const handleSignUp = () => {
        if(isAuthenticated){

        const callbackData = state.appState.callbackData;
        window.location.href = callbackData.returnPage;
        }
    }

    const handleCallback = () => {
        const callbackData = state.appState.callbackData;
        switch(callbackData.code){
            case "login":
                handleLogin();
                break;

            case "signup":
                handleSignUp();
                break;


            default:
                if(callbackData.returnPage){
                window.location.href = callbackData.returnPage;
                }else if(state.appState.returnPage){
                    window.location.href = state.appState.returnPage;
                }else{
                    window.location.href = '/';
                }
                break;
        }
    }

    useEffect(() => {
        
        const checkUserStatus = async () => {
            let newState = state.appState;
            console.log(user);
            setCurrentText('Please wait while your account information loads.\nIf the page does not reload automatically after 1 minute, please click this message.');
            console.log(0);
            
            let userResp = await checkUser(user);
            console.log(1);

            let userExists = userResp.data;
            console.log(userExists);
            newState.strapiUser = userExists.user;
            newState.jwt = userExists.jwt;
            dispatch(setStrapiUser(userExists));
            const Projects = await GetUserMediaContents(userExists.user.id, userExists.jwt);
            dispatch(setUserMediaContent(Projects));
            newState.mediaContent = Projects;
            const collections = MediaContentToPlaylists(state);
            newState.collections = collections;
            dispatch(setMediaCollections(collections));
            console.log('collections', collections);
            console.log('Projects', Projects);
            console.log('state', state);
            // dispatch(setAppState(newState));
            handleCallback();
            };
            if(isAuthenticated){
                checkUserStatus();
            }

    }, [isLoading]);

    return (
        <div className="home-1">
            
            <LoadingSpinner Title="Loading" Message="Loading user data"/>
        </div>

    );
}

export default CallBack;