let fileExtensions = {
    "extensions": [
        {
            "waveaudiofileformat": "",
            "name": "WAVE Audio File Format",
            "id": "fdd000001",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/vnd.wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.wave"
                },
                {
                    "value": "audio/x-pn-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-wav"
                },
                {
                    "value": "audio/x-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-wav"
                },
                {
                    "value": "audio/wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wave"
                },
                {
                    "value": "audio/wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000001.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000001.xml"
            }
        },
        {
            "waveaudiofileformat": "",
            "name": "WAVE Audio File Format",
            "id": "fdd000001",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/vnd.wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.wave"
                },
                {
                    "value": "audio/x-pn-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-wav"
                },
                {
                    "value": "audio/x-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-wav"
                },
                {
                    "value": "audio/wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wave"
                },
                {
                    "value": "audio/wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000001.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000001.xml"
            }
        },
        {
            "waveaudiofileformat": "",
            "name": "WAVE Audio File Format",
            "id": "fdd000001",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/vnd.wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.wave"
                },
                {
                    "value": "audio/x-pn-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-wav"
                },
                {
                    "value": "audio/x-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-wav"
                },
                {
                    "value": "audio/wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wave"
                },
                {
                    "value": "audio/wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000001.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000001.xml"
            }
        },
        {
            "waveaudiofileformat": "",
            "name": "WAVE Audio File Format",
            "id": "fdd000001",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/vnd.wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.wave"
                },
                {
                    "value": "audio/x-pn-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-wav"
                },
                {
                    "value": "audio/x-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-wav"
                },
                {
                    "value": "audio/wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wave"
                },
                {
                    "value": "audio/wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000001.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000001.xml"
            }
        },
        {
            "waveaudiofileformat": "",
            "name": "WAVE Audio File Format",
            "id": "fdd000001",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/vnd.wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.wave"
                },
                {
                    "value": "audio/x-pn-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-wav"
                },
                {
                    "value": "audio/x-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-wav"
                },
                {
                    "value": "audio/wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wave"
                },
                {
                    "value": "audio/wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000001.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000001.xml"
            }
        },
        {
            "waveaudiofileformat": "",
            "name": "WAVE Audio File Format",
            "id": "fdd000001",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/vnd.wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.wave"
                },
                {
                    "value": "audio/x-pn-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-wav"
                },
                {
                    "value": "audio/x-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-wav"
                },
                {
                    "value": "audio/wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wave"
                },
                {
                    "value": "audio/wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000001.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000001.xml"
            }
        },
        {
            "waveaudiofileformat": "",
            "name": "WAVE Audio File Format",
            "id": "fdd000001",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/vnd.wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.wave"
                },
                {
                    "value": "audio/x-pn-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-wav"
                },
                {
                    "value": "audio/x-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-wav"
                },
                {
                    "value": "audio/wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wave"
                },
                {
                    "value": "audio/wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000001.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000001.xml"
            }
        },
        {
            "waveaudiofileformat": "",
            "name": "WAVE Audio File Format",
            "id": "fdd000001",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/vnd.wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.wave"
                },
                {
                    "value": "audio/x-pn-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-wav"
                },
                {
                    "value": "audio/x-wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-wav"
                },
                {
                    "value": "audio/wave",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wave"
                },
                {
                    "value": "audio/wav",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/wav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000001.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000001.xml"
            }
        },
        {
            "waveaudiofileformatwithlpcmaudio": "",
            "name": "WAVE Audio File Format with LPCM audio",
            "id": "fdd000002",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000002.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000002.xml"
            }
        },
        {
            "waveaudiofileformatwithlpcmaudio": "",
            "name": "WAVE Audio File Format with LPCM audio",
            "id": "fdd000002",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000002.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000002.xml"
            }
        },
        {
            "waveaudiofileformatwithlpcmaudio": "",
            "name": "WAVE Audio File Format with LPCM audio",
            "id": "fdd000002",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000002.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000002.xml"
            }
        },
        {
            "waveaudiofileformatwithlpcmaudio": "",
            "name": "WAVE Audio File Format with LPCM audio",
            "id": "fdd000002",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000002.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000002.xml"
            }
        },
        {
            "waveaudiofileformatwithlpcmaudio": "",
            "name": "WAVE Audio File Format with LPCM audio",
            "id": "fdd000002",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000002.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000002.xml"
            }
        },
        {
            "waveaudiofileformatwithlpcmaudio": "",
            "name": "WAVE Audio File Format with LPCM audio",
            "id": "fdd000002",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000002.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000002.xml"
            }
        },
        {
            "waveaudiofileformatwithlpcmaudio": "",
            "name": "WAVE Audio File Format with LPCM audio",
            "id": "fdd000002",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000002.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000002.xml"
            }
        },
        {
            "waveaudiofileformatwithlpcmaudio": "",
            "name": "WAVE Audio File Format with LPCM audio",
            "id": "fdd000002",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000002.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000002.xml"
            }
        },
        {
            "broadcastwavefileformat,version1,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 1, with LPCM Audio",
            "id": "fdd000003",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000003.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000003.xml"
            }
        },
        {
            "broadcastwavefileformat,version1,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 1, with LPCM Audio",
            "id": "fdd000003",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000003.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000003.xml"
            }
        },
        {
            "broadcastwavefileformat,version1,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 1, with LPCM Audio",
            "id": "fdd000003",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000003.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000003.xml"
            }
        },
        {
            "broadcastwavefileformat,version1,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 1, with LPCM Audio",
            "id": "fdd000003",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000003.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000003.xml"
            }
        },
        {
            "broadcastwavefileformat,version1,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 1, with LPCM Audio",
            "id": "fdd000003",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000003.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000003.xml"
            }
        },
        {
            "broadcastwavefileformat,version1,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 1, with LPCM Audio",
            "id": "fdd000003",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000003.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000003.xml"
            }
        },
        {
            "broadcastwavefileformat,version1,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 1, with LPCM Audio",
            "id": "fdd000003",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000003.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000003.xml"
            }
        },
        {
            "broadcastwavefileformat,version1,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 1, with LPCM Audio",
            "id": "fdd000003",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000003.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000003.xml"
            }
        },
        {
            "advancedauthoringformat(aaf)object,version1.1": "",
            "name": "Advanced Authoring Format (AAF) Object, Version 1.1",
            "id": "fdd000004",
            "filenamepattern": [
                {
                    "value": "*.aaf",
                    "url": "https://www.digipres.org/formats/extensions/#*.aaf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000004.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000004.xml"
            }
        },
        {
            "advancedauthoringformat(aaf)object,version1.1": "",
            "name": "Advanced Authoring Format (AAF) Object, Version 1.1",
            "id": "fdd000004",
            "filenamepattern": [
                {
                    "value": "*.aaf",
                    "url": "https://www.digipres.org/formats/extensions/#*.aaf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000004.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000004.xml"
            }
        },
        {
            "advancedauthoringformat(aaf)object,version1.1": "",
            "name": "Advanced Authoring Format (AAF) Object, Version 1.1",
            "id": "fdd000004",
            "filenamepattern": [
                {
                    "value": "*.aaf",
                    "url": "https://www.digipres.org/formats/extensions/#*.aaf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000004.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000004.xml"
            }
        },
        {
            "advancedauthoringformat(aaf)object,version1.1": "",
            "name": "Advanced Authoring Format (AAF) Object, Version 1.1",
            "id": "fdd000004",
            "filenamepattern": [
                {
                    "value": "*.aaf",
                    "url": "https://www.digipres.org/formats/extensions/#*.aaf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000004.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000004.xml"
            }
        },
        {
            "advancedauthoringformat(aaf)object,version1.1": "",
            "name": "Advanced Authoring Format (AAF) Object, Version 1.1",
            "id": "fdd000004",
            "filenamepattern": [
                {
                    "value": "*.aaf",
                    "url": "https://www.digipres.org/formats/extensions/#*.aaf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000004.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000004.xml"
            }
        },
        {
            "advancedauthoringformat(aaf)object,version1.1": "",
            "name": "Advanced Authoring Format (AAF) Object, Version 1.1",
            "id": "fdd000004",
            "filenamepattern": [
                {
                    "value": "*.aaf",
                    "url": "https://www.digipres.org/formats/extensions/#*.aaf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000004.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000004.xml"
            }
        },
        {
            "advancedauthoringformat(aaf)object,version1.1": "",
            "name": "Advanced Authoring Format (AAF) Object, Version 1.1",
            "id": "fdd000004",
            "filenamepattern": [
                {
                    "value": "*.aaf",
                    "url": "https://www.digipres.org/formats/extensions/#*.aaf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000004.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000004.xml"
            }
        },
        {
            "advancedauthoringformat(aaf)object,version1.1": "",
            "name": "Advanced Authoring Format (AAF) Object, Version 1.1",
            "id": "fdd000004",
            "filenamepattern": [
                {
                    "value": "*.aaf",
                    "url": "https://www.digipres.org/formats/extensions/#*.aaf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000004.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000004.xml"
            }
        },
        {
            "aiff(audiointerchangefileformat)": "",
            "name": "AIFF (Audio Interchange File Format)",
            "id": "fdd000005",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                },
                {
                    "value": "*.ief",
                    "url": "https://www.digipres.org/formats/extensions/#*.ief"
                },
                {
                    "value": "*.aiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.aiff"
                }
            ],
            "mimetypes": [
                {
                    "value": " audio/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": " audio/x-gsm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-gsm"
                },
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": " audio/x-rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-rmf"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                },
                {
                    "value": " audio/vnd.qcelp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.qcelp"
                },
                {
                    "value": " audio/rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/rmf"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000005.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000005.xml"
            }
        },
        {
            "aiff(audiointerchangefileformat)": "",
            "name": "AIFF (Audio Interchange File Format)",
            "id": "fdd000005",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                },
                {
                    "value": "*.ief",
                    "url": "https://www.digipres.org/formats/extensions/#*.ief"
                },
                {
                    "value": "*.aiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.aiff"
                }
            ],
            "mimetypes": [
                {
                    "value": " audio/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": " audio/x-gsm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-gsm"
                },
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": " audio/x-rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-rmf"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                },
                {
                    "value": " audio/vnd.qcelp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.qcelp"
                },
                {
                    "value": " audio/rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/rmf"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000005.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000005.xml"
            }
        },
        {
            "aiff(audiointerchangefileformat)": "",
            "name": "AIFF (Audio Interchange File Format)",
            "id": "fdd000005",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                },
                {
                    "value": "*.ief",
                    "url": "https://www.digipres.org/formats/extensions/#*.ief"
                },
                {
                    "value": "*.aiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.aiff"
                }
            ],
            "mimetypes": [
                {
                    "value": " audio/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": " audio/x-gsm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-gsm"
                },
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": " audio/x-rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-rmf"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                },
                {
                    "value": " audio/vnd.qcelp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.qcelp"
                },
                {
                    "value": " audio/rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/rmf"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000005.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000005.xml"
            }
        },
        {
            "aiff(audiointerchangefileformat)": "",
            "name": "AIFF (Audio Interchange File Format)",
            "id": "fdd000005",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                },
                {
                    "value": "*.ief",
                    "url": "https://www.digipres.org/formats/extensions/#*.ief"
                },
                {
                    "value": "*.aiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.aiff"
                }
            ],
            "mimetypes": [
                {
                    "value": " audio/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": " audio/x-gsm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-gsm"
                },
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": " audio/x-rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-rmf"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                },
                {
                    "value": " audio/vnd.qcelp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.qcelp"
                },
                {
                    "value": " audio/rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/rmf"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000005.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000005.xml"
            }
        },
        {
            "aiff(audiointerchangefileformat)": "",
            "name": "AIFF (Audio Interchange File Format)",
            "id": "fdd000005",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                },
                {
                    "value": "*.ief",
                    "url": "https://www.digipres.org/formats/extensions/#*.ief"
                },
                {
                    "value": "*.aiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.aiff"
                }
            ],
            "mimetypes": [
                {
                    "value": " audio/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": " audio/x-gsm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-gsm"
                },
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": " audio/x-rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-rmf"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                },
                {
                    "value": " audio/vnd.qcelp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.qcelp"
                },
                {
                    "value": " audio/rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/rmf"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000005.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000005.xml"
            }
        },
        {
            "aiff(audiointerchangefileformat)": "",
            "name": "AIFF (Audio Interchange File Format)",
            "id": "fdd000005",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                },
                {
                    "value": "*.ief",
                    "url": "https://www.digipres.org/formats/extensions/#*.ief"
                },
                {
                    "value": "*.aiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.aiff"
                }
            ],
            "mimetypes": [
                {
                    "value": " audio/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": " audio/x-gsm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-gsm"
                },
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": " audio/x-rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-rmf"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                },
                {
                    "value": " audio/vnd.qcelp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.qcelp"
                },
                {
                    "value": " audio/rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/rmf"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000005.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000005.xml"
            }
        },
        {
            "aiff(audiointerchangefileformat)": "",
            "name": "AIFF (Audio Interchange File Format)",
            "id": "fdd000005",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                },
                {
                    "value": "*.ief",
                    "url": "https://www.digipres.org/formats/extensions/#*.ief"
                },
                {
                    "value": "*.aiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.aiff"
                }
            ],
            "mimetypes": [
                {
                    "value": " audio/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": " audio/x-gsm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-gsm"
                },
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": " audio/x-rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-rmf"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                },
                {
                    "value": " audio/vnd.qcelp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.qcelp"
                },
                {
                    "value": " audio/rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/rmf"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000005.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000005.xml"
            }
        },
        {
            "aiff(audiointerchangefileformat)": "",
            "name": "AIFF (Audio Interchange File Format)",
            "id": "fdd000005",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                },
                {
                    "value": "*.ief",
                    "url": "https://www.digipres.org/formats/extensions/#*.ief"
                },
                {
                    "value": "*.aiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.aiff"
                }
            ],
            "mimetypes": [
                {
                    "value": " audio/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": " audio/x-gsm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-gsm"
                },
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": " audio/x-rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-rmf"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                },
                {
                    "value": " audio/vnd.qcelp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.qcelp"
                },
                {
                    "value": " audio/rmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/rmf"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000005.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000005.xml"
            }
        },
        {
            "cpc,cartesianperceptualcompression": "",
            "name": "CPC, Cartesian Perceptual Compression",
            "id": "fdd000007",
            "filenamepattern": [
                {
                    "value": "*.cpi",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpi"
                },
                {
                    "value": "*.cpc",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000007.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000007.xml"
            }
        },
        {
            "cpc,cartesianperceptualcompression": "",
            "name": "CPC, Cartesian Perceptual Compression",
            "id": "fdd000007",
            "filenamepattern": [
                {
                    "value": "*.cpi",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpi"
                },
                {
                    "value": "*.cpc",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000007.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000007.xml"
            }
        },
        {
            "cpc,cartesianperceptualcompression": "",
            "name": "CPC, Cartesian Perceptual Compression",
            "id": "fdd000007",
            "filenamepattern": [
                {
                    "value": "*.cpi",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpi"
                },
                {
                    "value": "*.cpc",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000007.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000007.xml"
            }
        },
        {
            "cpc,cartesianperceptualcompression": "",
            "name": "CPC, Cartesian Perceptual Compression",
            "id": "fdd000007",
            "filenamepattern": [
                {
                    "value": "*.cpi",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpi"
                },
                {
                    "value": "*.cpc",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000007.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000007.xml"
            }
        },
        {
            "cpc,cartesianperceptualcompression": "",
            "name": "CPC, Cartesian Perceptual Compression",
            "id": "fdd000007",
            "filenamepattern": [
                {
                    "value": "*.cpi",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpi"
                },
                {
                    "value": "*.cpc",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000007.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000007.xml"
            }
        },
        {
            "cpc,cartesianperceptualcompression": "",
            "name": "CPC, Cartesian Perceptual Compression",
            "id": "fdd000007",
            "filenamepattern": [
                {
                    "value": "*.cpi",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpi"
                },
                {
                    "value": "*.cpc",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000007.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000007.xml"
            }
        },
        {
            "cpc,cartesianperceptualcompression": "",
            "name": "CPC, Cartesian Perceptual Compression",
            "id": "fdd000007",
            "filenamepattern": [
                {
                    "value": "*.cpi",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpi"
                },
                {
                    "value": "*.cpc",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000007.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000007.xml"
            }
        },
        {
            "cpc,cartesianperceptualcompression": "",
            "name": "CPC, Cartesian Perceptual Compression",
            "id": "fdd000007",
            "filenamepattern": [
                {
                    "value": "*.cpi",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpi"
                },
                {
                    "value": "*.cpc",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000007.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000007.xml"
            }
        },
        {
            "linearpulsecodemodulatedaudio(lpcm)": "",
            "name": "Linear Pulse Code Modulated Audio (LPCM)",
            "id": "fdd000011",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000011.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000011.xml"
            }
        },
        {
            "linearpulsecodemodulatedaudio(lpcm)": "",
            "name": "Linear Pulse Code Modulated Audio (LPCM)",
            "id": "fdd000011",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000011.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000011.xml"
            }
        },
        {
            "linearpulsecodemodulatedaudio(lpcm)": "",
            "name": "Linear Pulse Code Modulated Audio (LPCM)",
            "id": "fdd000011",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000011.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000011.xml"
            }
        },
        {
            "linearpulsecodemodulatedaudio(lpcm)": "",
            "name": "Linear Pulse Code Modulated Audio (LPCM)",
            "id": "fdd000011",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000011.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000011.xml"
            }
        },
        {
            "linearpulsecodemodulatedaudio(lpcm)": "",
            "name": "Linear Pulse Code Modulated Audio (LPCM)",
            "id": "fdd000011",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000011.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000011.xml"
            }
        },
        {
            "linearpulsecodemodulatedaudio(lpcm)": "",
            "name": "Linear Pulse Code Modulated Audio (LPCM)",
            "id": "fdd000011",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000011.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000011.xml"
            }
        },
        {
            "linearpulsecodemodulatedaudio(lpcm)": "",
            "name": "Linear Pulse Code Modulated Audio (LPCM)",
            "id": "fdd000011",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000011.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000011.xml"
            }
        },
        {
            "linearpulsecodemodulatedaudio(lpcm)": "",
            "name": "Linear Pulse Code Modulated Audio (LPCM)",
            "id": "fdd000011",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000011.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000011.xml"
            }
        },
        {
            "mp3(mpeglayeriiiaudioencoding)": "",
            "name": "MP3 (MPEG Layer III Audio Encoding)",
            "id": "fdd000012",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000012.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000012.xml"
            }
        },
        {
            "mp3(mpeglayeriiiaudioencoding)": "",
            "name": "MP3 (MPEG Layer III Audio Encoding)",
            "id": "fdd000012",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000012.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000012.xml"
            }
        },
        {
            "mp3(mpeglayeriiiaudioencoding)": "",
            "name": "MP3 (MPEG Layer III Audio Encoding)",
            "id": "fdd000012",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000012.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000012.xml"
            }
        },
        {
            "mp3(mpeglayeriiiaudioencoding)": "",
            "name": "MP3 (MPEG Layer III Audio Encoding)",
            "id": "fdd000012",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000012.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000012.xml"
            }
        },
        {
            "mp3(mpeglayeriiiaudioencoding)": "",
            "name": "MP3 (MPEG Layer III Audio Encoding)",
            "id": "fdd000012",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000012.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000012.xml"
            }
        },
        {
            "mp3(mpeglayeriiiaudioencoding)": "",
            "name": "MP3 (MPEG Layer III Audio Encoding)",
            "id": "fdd000012",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000012.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000012.xml"
            }
        },
        {
            "mp3(mpeglayeriiiaudioencoding)": "",
            "name": "MP3 (MPEG Layer III Audio Encoding)",
            "id": "fdd000012",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000012.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000012.xml"
            }
        },
        {
            "mp3(mpeglayeriiiaudioencoding)": "",
            "name": "MP3 (MPEG Layer III Audio Encoding)",
            "id": "fdd000012",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000012.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000012.xml"
            }
        },
        {
            "materialexchangeformat(mxf)": "",
            "name": "Material Exchange Format (MXF)",
            "id": "fdd000013",
            "filenamepattern": [
                {
                    "value": "*.mxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mxf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mxf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000013.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000013.xml"
            }
        },
        {
            "materialexchangeformat(mxf)": "",
            "name": "Material Exchange Format (MXF)",
            "id": "fdd000013",
            "filenamepattern": [
                {
                    "value": "*.mxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mxf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mxf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000013.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000013.xml"
            }
        },
        {
            "materialexchangeformat(mxf)": "",
            "name": "Material Exchange Format (MXF)",
            "id": "fdd000013",
            "filenamepattern": [
                {
                    "value": "*.mxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mxf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mxf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000013.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000013.xml"
            }
        },
        {
            "materialexchangeformat(mxf)": "",
            "name": "Material Exchange Format (MXF)",
            "id": "fdd000013",
            "filenamepattern": [
                {
                    "value": "*.mxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mxf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mxf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000013.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000013.xml"
            }
        },
        {
            "materialexchangeformat(mxf)": "",
            "name": "Material Exchange Format (MXF)",
            "id": "fdd000013",
            "filenamepattern": [
                {
                    "value": "*.mxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mxf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mxf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000013.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000013.xml"
            }
        },
        {
            "materialexchangeformat(mxf)": "",
            "name": "Material Exchange Format (MXF)",
            "id": "fdd000013",
            "filenamepattern": [
                {
                    "value": "*.mxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mxf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mxf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000013.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000013.xml"
            }
        },
        {
            "materialexchangeformat(mxf)": "",
            "name": "Material Exchange Format (MXF)",
            "id": "fdd000013",
            "filenamepattern": [
                {
                    "value": "*.mxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mxf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mxf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000013.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000013.xml"
            }
        },
        {
            "materialexchangeformat(mxf)": "",
            "name": "Material Exchange Format (MXF)",
            "id": "fdd000013",
            "filenamepattern": [
                {
                    "value": "*.mxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mxf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mxf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000013.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000013.xml"
            }
        },
        {
            "nitf,newsindustrytextformat": "",
            "name": "NITF, News Industry Text Format",
            "id": "fdd000014",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vnd.IPTC.NITF",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vnd.iptc.nitf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000014.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000014.xml"
            }
        },
        {
            "nitf,newsindustrytextformat": "",
            "name": "NITF, News Industry Text Format",
            "id": "fdd000014",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vnd.IPTC.NITF",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vnd.iptc.nitf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000014.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000014.xml"
            }
        },
        {
            "nitf,newsindustrytextformat": "",
            "name": "NITF, News Industry Text Format",
            "id": "fdd000014",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vnd.IPTC.NITF",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vnd.iptc.nitf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000014.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000014.xml"
            }
        },
        {
            "nitf,newsindustrytextformat": "",
            "name": "NITF, News Industry Text Format",
            "id": "fdd000014",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vnd.IPTC.NITF",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vnd.iptc.nitf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000014.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000014.xml"
            }
        },
        {
            "nitf,newsindustrytextformat": "",
            "name": "NITF, News Industry Text Format",
            "id": "fdd000014",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vnd.IPTC.NITF",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vnd.iptc.nitf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000014.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000014.xml"
            }
        },
        {
            "nitf,newsindustrytextformat": "",
            "name": "NITF, News Industry Text Format",
            "id": "fdd000014",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vnd.IPTC.NITF",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vnd.iptc.nitf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000014.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000014.xml"
            }
        },
        {
            "nitf,newsindustrytextformat": "",
            "name": "NITF, News Industry Text Format",
            "id": "fdd000014",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vnd.IPTC.NITF",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vnd.iptc.nitf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000014.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000014.xml"
            }
        },
        {
            "nitf,newsindustrytextformat": "",
            "name": "NITF, News Industry Text Format",
            "id": "fdd000014",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vnd.IPTC.NITF",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vnd.iptc.nitf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000014.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000014.xml"
            }
        },
        {
            "pcm,pulsecodemodulatedaudio": "",
            "name": "PCM, Pulse Code Modulated Audio",
            "id": "fdd000016",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000016.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000016.xml"
            }
        },
        {
            "pcm,pulsecodemodulatedaudio": "",
            "name": "PCM, Pulse Code Modulated Audio",
            "id": "fdd000016",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000016.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000016.xml"
            }
        },
        {
            "pcm,pulsecodemodulatedaudio": "",
            "name": "PCM, Pulse Code Modulated Audio",
            "id": "fdd000016",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000016.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000016.xml"
            }
        },
        {
            "pcm,pulsecodemodulatedaudio": "",
            "name": "PCM, Pulse Code Modulated Audio",
            "id": "fdd000016",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000016.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000016.xml"
            }
        },
        {
            "pcm,pulsecodemodulatedaudio": "",
            "name": "PCM, Pulse Code Modulated Audio",
            "id": "fdd000016",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000016.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000016.xml"
            }
        },
        {
            "pcm,pulsecodemodulatedaudio": "",
            "name": "PCM, Pulse Code Modulated Audio",
            "id": "fdd000016",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000016.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000016.xml"
            }
        },
        {
            "pcm,pulsecodemodulatedaudio": "",
            "name": "PCM, Pulse Code Modulated Audio",
            "id": "fdd000016",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000016.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000016.xml"
            }
        },
        {
            "pcm,pulsecodemodulatedaudio": "",
            "name": "PCM, Pulse Code Modulated Audio",
            "id": "fdd000016",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000016.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000016.xml"
            }
        },
        {
            "jpegimageencodingfamily": "",
            "name": "JPEG Image Encoding Family",
            "id": "fdd000017",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000017.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000017.xml"
            }
        },
        {
            "jpegimageencodingfamily": "",
            "name": "JPEG Image Encoding Family",
            "id": "fdd000017",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000017.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000017.xml"
            }
        },
        {
            "jpegimageencodingfamily": "",
            "name": "JPEG Image Encoding Family",
            "id": "fdd000017",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000017.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000017.xml"
            }
        },
        {
            "jpegimageencodingfamily": "",
            "name": "JPEG Image Encoding Family",
            "id": "fdd000017",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000017.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000017.xml"
            }
        },
        {
            "jpegimageencodingfamily": "",
            "name": "JPEG Image Encoding Family",
            "id": "fdd000017",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000017.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000017.xml"
            }
        },
        {
            "jpegimageencodingfamily": "",
            "name": "JPEG Image Encoding Family",
            "id": "fdd000017",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000017.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000017.xml"
            }
        },
        {
            "jpegimageencodingfamily": "",
            "name": "JPEG Image Encoding Family",
            "id": "fdd000017",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000017.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000017.xml"
            }
        },
        {
            "jpegimageencodingfamily": "",
            "name": "JPEG Image Encoding Family",
            "id": "fdd000017",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000017.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000017.xml"
            }
        },
        {
            "jfif,jpegfileinterchangeformat,version1.02": "",
            "name": "JFIF, JPEG File Interchange Format, Version 1.02",
            "id": "fdd000018",
            "filenamepattern": [
                {
                    "value": "*.jmh",
                    "url": "https://www.digipres.org/formats/extensions/#*.jmh"
                },
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                },
                {
                    "value": "*.j",
                    "url": "https://www.digipres.org/formats/extensions/#*.j"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-jpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-jpg"
                },
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "application/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/jpg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000018.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000018.xml"
            }
        },
        {
            "jfif,jpegfileinterchangeformat,version1.02": "",
            "name": "JFIF, JPEG File Interchange Format, Version 1.02",
            "id": "fdd000018",
            "filenamepattern": [
                {
                    "value": "*.jmh",
                    "url": "https://www.digipres.org/formats/extensions/#*.jmh"
                },
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                },
                {
                    "value": "*.j",
                    "url": "https://www.digipres.org/formats/extensions/#*.j"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-jpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-jpg"
                },
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "application/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/jpg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000018.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000018.xml"
            }
        },
        {
            "jfif,jpegfileinterchangeformat,version1.02": "",
            "name": "JFIF, JPEG File Interchange Format, Version 1.02",
            "id": "fdd000018",
            "filenamepattern": [
                {
                    "value": "*.jmh",
                    "url": "https://www.digipres.org/formats/extensions/#*.jmh"
                },
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                },
                {
                    "value": "*.j",
                    "url": "https://www.digipres.org/formats/extensions/#*.j"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-jpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-jpg"
                },
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "application/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/jpg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000018.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000018.xml"
            }
        },
        {
            "jfif,jpegfileinterchangeformat,version1.02": "",
            "name": "JFIF, JPEG File Interchange Format, Version 1.02",
            "id": "fdd000018",
            "filenamepattern": [
                {
                    "value": "*.jmh",
                    "url": "https://www.digipres.org/formats/extensions/#*.jmh"
                },
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                },
                {
                    "value": "*.j",
                    "url": "https://www.digipres.org/formats/extensions/#*.j"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-jpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-jpg"
                },
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "application/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/jpg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000018.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000018.xml"
            }
        },
        {
            "jfif,jpegfileinterchangeformat,version1.02": "",
            "name": "JFIF, JPEG File Interchange Format, Version 1.02",
            "id": "fdd000018",
            "filenamepattern": [
                {
                    "value": "*.jmh",
                    "url": "https://www.digipres.org/formats/extensions/#*.jmh"
                },
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                },
                {
                    "value": "*.j",
                    "url": "https://www.digipres.org/formats/extensions/#*.j"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-jpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-jpg"
                },
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "application/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/jpg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000018.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000018.xml"
            }
        },
        {
            "jfif,jpegfileinterchangeformat,version1.02": "",
            "name": "JFIF, JPEG File Interchange Format, Version 1.02",
            "id": "fdd000018",
            "filenamepattern": [
                {
                    "value": "*.jmh",
                    "url": "https://www.digipres.org/formats/extensions/#*.jmh"
                },
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                },
                {
                    "value": "*.j",
                    "url": "https://www.digipres.org/formats/extensions/#*.j"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-jpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-jpg"
                },
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "application/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/jpg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000018.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000018.xml"
            }
        },
        {
            "jfif,jpegfileinterchangeformat,version1.02": "",
            "name": "JFIF, JPEG File Interchange Format, Version 1.02",
            "id": "fdd000018",
            "filenamepattern": [
                {
                    "value": "*.jmh",
                    "url": "https://www.digipres.org/formats/extensions/#*.jmh"
                },
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                },
                {
                    "value": "*.j",
                    "url": "https://www.digipres.org/formats/extensions/#*.j"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-jpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-jpg"
                },
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "application/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/jpg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000018.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000018.xml"
            }
        },
        {
            "jfif,jpegfileinterchangeformat,version1.02": "",
            "name": "JFIF, JPEG File Interchange Format, Version 1.02",
            "id": "fdd000018",
            "filenamepattern": [
                {
                    "value": "*.jmh",
                    "url": "https://www.digipres.org/formats/extensions/#*.jmh"
                },
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                },
                {
                    "value": "*.j",
                    "url": "https://www.digipres.org/formats/extensions/#*.j"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-jpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-jpg"
                },
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "application/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/jpg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000018.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000018.xml"
            }
        },
        {
            "spiff,stillpictureinterchangefileformat": "",
            "name": "SPIFF, Still Picture Interchange File Format",
            "id": "fdd000019",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.spiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.spiff"
                },
                {
                    "value": "*.spf",
                    "url": "https://www.digipres.org/formats/extensions/#*.spf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000019.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000019.xml"
            }
        },
        {
            "spiff,stillpictureinterchangefileformat": "",
            "name": "SPIFF, Still Picture Interchange File Format",
            "id": "fdd000019",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.spiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.spiff"
                },
                {
                    "value": "*.spf",
                    "url": "https://www.digipres.org/formats/extensions/#*.spf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000019.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000019.xml"
            }
        },
        {
            "spiff,stillpictureinterchangefileformat": "",
            "name": "SPIFF, Still Picture Interchange File Format",
            "id": "fdd000019",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.spiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.spiff"
                },
                {
                    "value": "*.spf",
                    "url": "https://www.digipres.org/formats/extensions/#*.spf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000019.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000019.xml"
            }
        },
        {
            "spiff,stillpictureinterchangefileformat": "",
            "name": "SPIFF, Still Picture Interchange File Format",
            "id": "fdd000019",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.spiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.spiff"
                },
                {
                    "value": "*.spf",
                    "url": "https://www.digipres.org/formats/extensions/#*.spf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000019.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000019.xml"
            }
        },
        {
            "spiff,stillpictureinterchangefileformat": "",
            "name": "SPIFF, Still Picture Interchange File Format",
            "id": "fdd000019",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.spiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.spiff"
                },
                {
                    "value": "*.spf",
                    "url": "https://www.digipres.org/formats/extensions/#*.spf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000019.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000019.xml"
            }
        },
        {
            "spiff,stillpictureinterchangefileformat": "",
            "name": "SPIFF, Still Picture Interchange File Format",
            "id": "fdd000019",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.spiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.spiff"
                },
                {
                    "value": "*.spf",
                    "url": "https://www.digipres.org/formats/extensions/#*.spf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000019.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000019.xml"
            }
        },
        {
            "spiff,stillpictureinterchangefileformat": "",
            "name": "SPIFF, Still Picture Interchange File Format",
            "id": "fdd000019",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.spiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.spiff"
                },
                {
                    "value": "*.spf",
                    "url": "https://www.digipres.org/formats/extensions/#*.spf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000019.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000019.xml"
            }
        },
        {
            "spiff,stillpictureinterchangefileformat": "",
            "name": "SPIFF, Still Picture Interchange File Format",
            "id": "fdd000019",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.spiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.spiff"
                },
                {
                    "value": "*.spf",
                    "url": "https://www.digipres.org/formats/extensions/#*.spf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000019.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000019.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version1.1": "",
            "name": "Scalable Vector Graphics (SVG), Version 1.1",
            "id": "fdd000020",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000020.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000020.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version1.1": "",
            "name": "Scalable Vector Graphics (SVG), Version 1.1",
            "id": "fdd000020",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000020.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000020.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version1.1": "",
            "name": "Scalable Vector Graphics (SVG), Version 1.1",
            "id": "fdd000020",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000020.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000020.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version1.1": "",
            "name": "Scalable Vector Graphics (SVG), Version 1.1",
            "id": "fdd000020",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000020.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000020.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version1.1": "",
            "name": "Scalable Vector Graphics (SVG), Version 1.1",
            "id": "fdd000020",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000020.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000020.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version1.1": "",
            "name": "Scalable Vector Graphics (SVG), Version 1.1",
            "id": "fdd000020",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000020.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000020.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version1.1": "",
            "name": "Scalable Vector Graphics (SVG), Version 1.1",
            "id": "fdd000020",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000020.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000020.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version1.1": "",
            "name": "Scalable Vector Graphics (SVG), Version 1.1",
            "id": "fdd000020",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000020.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000020.xml"
            }
        },
        {
            "visiovsdxdrawingfileformat": "",
            "name": "Visio VSDX Drawing File Format",
            "id": "fdd000021",
            "filenamepattern": [
                {
                    "value": "*.vsdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.vsdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.visio",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio"
                },
                {
                    "value": "application/vnd.ms-visio.drawing",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-visio.drawing"
                },
                {
                    "value": "application/vnd.visio2013",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio2013"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000021.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000021.xml"
            }
        },
        {
            "visiovsdxdrawingfileformat": "",
            "name": "Visio VSDX Drawing File Format",
            "id": "fdd000021",
            "filenamepattern": [
                {
                    "value": "*.vsdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.vsdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.visio",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio"
                },
                {
                    "value": "application/vnd.ms-visio.drawing",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-visio.drawing"
                },
                {
                    "value": "application/vnd.visio2013",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio2013"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000021.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000021.xml"
            }
        },
        {
            "visiovsdxdrawingfileformat": "",
            "name": "Visio VSDX Drawing File Format",
            "id": "fdd000021",
            "filenamepattern": [
                {
                    "value": "*.vsdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.vsdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.visio",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio"
                },
                {
                    "value": "application/vnd.ms-visio.drawing",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-visio.drawing"
                },
                {
                    "value": "application/vnd.visio2013",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio2013"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000021.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000021.xml"
            }
        },
        {
            "visiovsdxdrawingfileformat": "",
            "name": "Visio VSDX Drawing File Format",
            "id": "fdd000021",
            "filenamepattern": [
                {
                    "value": "*.vsdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.vsdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.visio",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio"
                },
                {
                    "value": "application/vnd.ms-visio.drawing",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-visio.drawing"
                },
                {
                    "value": "application/vnd.visio2013",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio2013"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000021.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000021.xml"
            }
        },
        {
            "visiovsdxdrawingfileformat": "",
            "name": "Visio VSDX Drawing File Format",
            "id": "fdd000021",
            "filenamepattern": [
                {
                    "value": "*.vsdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.vsdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.visio",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio"
                },
                {
                    "value": "application/vnd.ms-visio.drawing",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-visio.drawing"
                },
                {
                    "value": "application/vnd.visio2013",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio2013"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000021.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000021.xml"
            }
        },
        {
            "visiovsdxdrawingfileformat": "",
            "name": "Visio VSDX Drawing File Format",
            "id": "fdd000021",
            "filenamepattern": [
                {
                    "value": "*.vsdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.vsdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.visio",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio"
                },
                {
                    "value": "application/vnd.ms-visio.drawing",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-visio.drawing"
                },
                {
                    "value": "application/vnd.visio2013",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio2013"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000021.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000021.xml"
            }
        },
        {
            "visiovsdxdrawingfileformat": "",
            "name": "Visio VSDX Drawing File Format",
            "id": "fdd000021",
            "filenamepattern": [
                {
                    "value": "*.vsdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.vsdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.visio",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio"
                },
                {
                    "value": "application/vnd.ms-visio.drawing",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-visio.drawing"
                },
                {
                    "value": "application/vnd.visio2013",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio2013"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000021.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000021.xml"
            }
        },
        {
            "visiovsdxdrawingfileformat": "",
            "name": "Visio VSDX Drawing File Format",
            "id": "fdd000021",
            "filenamepattern": [
                {
                    "value": "*.vsdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.vsdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.visio",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio"
                },
                {
                    "value": "application/vnd.ms-visio.drawing",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-visio.drawing"
                },
                {
                    "value": "application/vnd.visio2013",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.visio2013"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000021.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000021.xml"
            }
        },
        {
            "tiff,revision6.0": "",
            "name": "TIFF, Revision 6.0",
            "id": "fdd000022",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tiff"
                },
                {
                    "value": "application/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tiff"
                },
                {
                    "value": "image/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tif"
                },
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                },
                {
                    "value": "image/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tif"
                },
                {
                    "value": "image/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tiff"
                },
                {
                    "value": "application/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tif"
                },
                {
                    "value": "application/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000022.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000022.xml"
            }
        },
        {
            "tiff,revision6.0": "",
            "name": "TIFF, Revision 6.0",
            "id": "fdd000022",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tiff"
                },
                {
                    "value": "application/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tiff"
                },
                {
                    "value": "image/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tif"
                },
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                },
                {
                    "value": "image/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tif"
                },
                {
                    "value": "image/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tiff"
                },
                {
                    "value": "application/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tif"
                },
                {
                    "value": "application/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000022.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000022.xml"
            }
        },
        {
            "tiff,revision6.0": "",
            "name": "TIFF, Revision 6.0",
            "id": "fdd000022",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tiff"
                },
                {
                    "value": "application/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tiff"
                },
                {
                    "value": "image/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tif"
                },
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                },
                {
                    "value": "image/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tif"
                },
                {
                    "value": "image/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tiff"
                },
                {
                    "value": "application/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tif"
                },
                {
                    "value": "application/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000022.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000022.xml"
            }
        },
        {
            "tiff,revision6.0": "",
            "name": "TIFF, Revision 6.0",
            "id": "fdd000022",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tiff"
                },
                {
                    "value": "application/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tiff"
                },
                {
                    "value": "image/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tif"
                },
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                },
                {
                    "value": "image/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tif"
                },
                {
                    "value": "image/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tiff"
                },
                {
                    "value": "application/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tif"
                },
                {
                    "value": "application/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000022.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000022.xml"
            }
        },
        {
            "tiff,revision6.0": "",
            "name": "TIFF, Revision 6.0",
            "id": "fdd000022",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tiff"
                },
                {
                    "value": "application/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tiff"
                },
                {
                    "value": "image/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tif"
                },
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                },
                {
                    "value": "image/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tif"
                },
                {
                    "value": "image/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tiff"
                },
                {
                    "value": "application/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tif"
                },
                {
                    "value": "application/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000022.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000022.xml"
            }
        },
        {
            "tiff,revision6.0": "",
            "name": "TIFF, Revision 6.0",
            "id": "fdd000022",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tiff"
                },
                {
                    "value": "application/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tiff"
                },
                {
                    "value": "image/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tif"
                },
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                },
                {
                    "value": "image/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tif"
                },
                {
                    "value": "image/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tiff"
                },
                {
                    "value": "application/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tif"
                },
                {
                    "value": "application/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000022.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000022.xml"
            }
        },
        {
            "tiff,revision6.0": "",
            "name": "TIFF, Revision 6.0",
            "id": "fdd000022",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tiff"
                },
                {
                    "value": "application/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tiff"
                },
                {
                    "value": "image/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tif"
                },
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                },
                {
                    "value": "image/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tif"
                },
                {
                    "value": "image/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tiff"
                },
                {
                    "value": "application/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tif"
                },
                {
                    "value": "application/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000022.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000022.xml"
            }
        },
        {
            "tiff,revision6.0": "",
            "name": "TIFF, Revision 6.0",
            "id": "fdd000022",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tiff"
                },
                {
                    "value": "application/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tiff"
                },
                {
                    "value": "image/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tif"
                },
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                },
                {
                    "value": "image/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tif"
                },
                {
                    "value": "image/x-tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-tiff"
                },
                {
                    "value": "application/tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/tif"
                },
                {
                    "value": "application/x-tif",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000022.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000022.xml"
            }
        },
        {
            "tiff,uncompressedbitmap": "",
            "name": "TIFF, Uncompressed Bitmap",
            "id": "fdd000023",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000023.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000023.xml"
            }
        },
        {
            "tiff,uncompressedbitmap": "",
            "name": "TIFF, Uncompressed Bitmap",
            "id": "fdd000023",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000023.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000023.xml"
            }
        },
        {
            "tiff,uncompressedbitmap": "",
            "name": "TIFF, Uncompressed Bitmap",
            "id": "fdd000023",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000023.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000023.xml"
            }
        },
        {
            "tiff,uncompressedbitmap": "",
            "name": "TIFF, Uncompressed Bitmap",
            "id": "fdd000023",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000023.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000023.xml"
            }
        },
        {
            "tiff,uncompressedbitmap": "",
            "name": "TIFF, Uncompressed Bitmap",
            "id": "fdd000023",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000023.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000023.xml"
            }
        },
        {
            "tiff,uncompressedbitmap": "",
            "name": "TIFF, Uncompressed Bitmap",
            "id": "fdd000023",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000023.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000023.xml"
            }
        },
        {
            "tiff,uncompressedbitmap": "",
            "name": "TIFF, Uncompressed Bitmap",
            "id": "fdd000023",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000023.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000023.xml"
            }
        },
        {
            "tiff,uncompressedbitmap": "",
            "name": "TIFF, Uncompressed Bitmap",
            "id": "fdd000023",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000023.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000023.xml"
            }
        },
        {
            "tiffbitmapwithgroup4compression": "",
            "name": "TIFF Bitmap with Group 4 Compression",
            "id": "fdd000024",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000024.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000024.xml"
            }
        },
        {
            "tiffbitmapwithgroup4compression": "",
            "name": "TIFF Bitmap with Group 4 Compression",
            "id": "fdd000024",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000024.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000024.xml"
            }
        },
        {
            "tiffbitmapwithgroup4compression": "",
            "name": "TIFF Bitmap with Group 4 Compression",
            "id": "fdd000024",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000024.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000024.xml"
            }
        },
        {
            "tiffbitmapwithgroup4compression": "",
            "name": "TIFF Bitmap with Group 4 Compression",
            "id": "fdd000024",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000024.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000024.xml"
            }
        },
        {
            "tiffbitmapwithgroup4compression": "",
            "name": "TIFF Bitmap with Group 4 Compression",
            "id": "fdd000024",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000024.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000024.xml"
            }
        },
        {
            "tiffbitmapwithgroup4compression": "",
            "name": "TIFF Bitmap with Group 4 Compression",
            "id": "fdd000024",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000024.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000024.xml"
            }
        },
        {
            "tiffbitmapwithgroup4compression": "",
            "name": "TIFF Bitmap with Group 4 Compression",
            "id": "fdd000024",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000024.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000024.xml"
            }
        },
        {
            "tiffbitmapwithgroup4compression": "",
            "name": "TIFF Bitmap with Group 4 Compression",
            "id": "fdd000024",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000024.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000024.xml"
            }
        },
        {
            "riff(resourceinterchangefileformat)": "",
            "name": "RIFF (Resource Interchange File Format)",
            "id": "fdd000025",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000025.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000025.xml"
            }
        },
        {
            "riff(resourceinterchangefileformat)": "",
            "name": "RIFF (Resource Interchange File Format)",
            "id": "fdd000025",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000025.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000025.xml"
            }
        },
        {
            "riff(resourceinterchangefileformat)": "",
            "name": "RIFF (Resource Interchange File Format)",
            "id": "fdd000025",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000025.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000025.xml"
            }
        },
        {
            "riff(resourceinterchangefileformat)": "",
            "name": "RIFF (Resource Interchange File Format)",
            "id": "fdd000025",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000025.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000025.xml"
            }
        },
        {
            "riff(resourceinterchangefileformat)": "",
            "name": "RIFF (Resource Interchange File Format)",
            "id": "fdd000025",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000025.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000025.xml"
            }
        },
        {
            "riff(resourceinterchangefileformat)": "",
            "name": "RIFF (Resource Interchange File Format)",
            "id": "fdd000025",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000025.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000025.xml"
            }
        },
        {
            "riff(resourceinterchangefileformat)": "",
            "name": "RIFF (Resource Interchange File Format)",
            "id": "fdd000025",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000025.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000025.xml"
            }
        },
        {
            "riff(resourceinterchangefileformat)": "",
            "name": "RIFF (Resource Interchange File Format)",
            "id": "fdd000025",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000025.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000025.xml"
            }
        },
        {
            "oggfileformat": "",
            "name": "Ogg File Format",
            "id": "fdd000026",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000026.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000026.xml"
            }
        },
        {
            "oggfileformat": "",
            "name": "Ogg File Format",
            "id": "fdd000026",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000026.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000026.xml"
            }
        },
        {
            "oggfileformat": "",
            "name": "Ogg File Format",
            "id": "fdd000026",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000026.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000026.xml"
            }
        },
        {
            "oggfileformat": "",
            "name": "Ogg File Format",
            "id": "fdd000026",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000026.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000026.xml"
            }
        },
        {
            "oggfileformat": "",
            "name": "Ogg File Format",
            "id": "fdd000026",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000026.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000026.xml"
            }
        },
        {
            "oggfileformat": "",
            "name": "Ogg File Format",
            "id": "fdd000026",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000026.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000026.xml"
            }
        },
        {
            "oggfileformat": "",
            "name": "Ogg File Format",
            "id": "fdd000026",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000026.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000026.xml"
            }
        },
        {
            "oggfileformat": "",
            "name": "Ogg File Format",
            "id": "fdd000026",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000026.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000026.xml"
            }
        },
        {
            "wma(windowsmediaaudio)fileformat": "",
            "name": "WMA (Windows Media Audio) File Format",
            "id": "fdd000027",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-ms-wma",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ms-wma"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000027.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000027.xml"
            }
        },
        {
            "wma(windowsmediaaudio)fileformat": "",
            "name": "WMA (Windows Media Audio) File Format",
            "id": "fdd000027",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-ms-wma",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ms-wma"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000027.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000027.xml"
            }
        },
        {
            "wma(windowsmediaaudio)fileformat": "",
            "name": "WMA (Windows Media Audio) File Format",
            "id": "fdd000027",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-ms-wma",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ms-wma"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000027.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000027.xml"
            }
        },
        {
            "wma(windowsmediaaudio)fileformat": "",
            "name": "WMA (Windows Media Audio) File Format",
            "id": "fdd000027",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-ms-wma",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ms-wma"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000027.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000027.xml"
            }
        },
        {
            "wma(windowsmediaaudio)fileformat": "",
            "name": "WMA (Windows Media Audio) File Format",
            "id": "fdd000027",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-ms-wma",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ms-wma"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000027.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000027.xml"
            }
        },
        {
            "wma(windowsmediaaudio)fileformat": "",
            "name": "WMA (Windows Media Audio) File Format",
            "id": "fdd000027",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-ms-wma",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ms-wma"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000027.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000027.xml"
            }
        },
        {
            "wma(windowsmediaaudio)fileformat": "",
            "name": "WMA (Windows Media Audio) File Format",
            "id": "fdd000027",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-ms-wma",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ms-wma"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000027.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000027.xml"
            }
        },
        {
            "wma(windowsmediaaudio)fileformat": "",
            "name": "WMA (Windows Media Audio) File Format",
            "id": "fdd000027",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-ms-wma",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ms-wma"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000027.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000027.xml"
            }
        },
        {
            "mpeg2videoencoding(h.262)": "",
            "name": "MPEG-2 Video Encoding (H.262)",
            "id": "fdd000028",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000028.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000028.xml"
            }
        },
        {
            "mpeg2videoencoding(h.262)": "",
            "name": "MPEG-2 Video Encoding (H.262)",
            "id": "fdd000028",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000028.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000028.xml"
            }
        },
        {
            "mpeg2videoencoding(h.262)": "",
            "name": "MPEG-2 Video Encoding (H.262)",
            "id": "fdd000028",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000028.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000028.xml"
            }
        },
        {
            "mpeg2videoencoding(h.262)": "",
            "name": "MPEG-2 Video Encoding (H.262)",
            "id": "fdd000028",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000028.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000028.xml"
            }
        },
        {
            "mpeg2videoencoding(h.262)": "",
            "name": "MPEG-2 Video Encoding (H.262)",
            "id": "fdd000028",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000028.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000028.xml"
            }
        },
        {
            "mpeg2videoencoding(h.262)": "",
            "name": "MPEG-2 Video Encoding (H.262)",
            "id": "fdd000028",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000028.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000028.xml"
            }
        },
        {
            "mpeg2videoencoding(h.262)": "",
            "name": "MPEG-2 Video Encoding (H.262)",
            "id": "fdd000028",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000028.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000028.xml"
            }
        },
        {
            "mpeg2videoencoding(h.262)": "",
            "name": "MPEG-2 Video Encoding (H.262)",
            "id": "fdd000028",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000028.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000028.xml"
            }
        },
        {
            "postscriptformatfamily": "",
            "name": "PostScript Format Family",
            "id": "fdd000029",
            "filenamepattern": [
                {
                    "value": "*.ps",
                    "url": "https://www.digipres.org/formats/extensions/#*.ps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000029.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000029.xml"
            }
        },
        {
            "postscriptformatfamily": "",
            "name": "PostScript Format Family",
            "id": "fdd000029",
            "filenamepattern": [
                {
                    "value": "*.ps",
                    "url": "https://www.digipres.org/formats/extensions/#*.ps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000029.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000029.xml"
            }
        },
        {
            "postscriptformatfamily": "",
            "name": "PostScript Format Family",
            "id": "fdd000029",
            "filenamepattern": [
                {
                    "value": "*.ps",
                    "url": "https://www.digipres.org/formats/extensions/#*.ps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000029.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000029.xml"
            }
        },
        {
            "postscriptformatfamily": "",
            "name": "PostScript Format Family",
            "id": "fdd000029",
            "filenamepattern": [
                {
                    "value": "*.ps",
                    "url": "https://www.digipres.org/formats/extensions/#*.ps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000029.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000029.xml"
            }
        },
        {
            "postscriptformatfamily": "",
            "name": "PostScript Format Family",
            "id": "fdd000029",
            "filenamepattern": [
                {
                    "value": "*.ps",
                    "url": "https://www.digipres.org/formats/extensions/#*.ps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000029.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000029.xml"
            }
        },
        {
            "postscriptformatfamily": "",
            "name": "PostScript Format Family",
            "id": "fdd000029",
            "filenamepattern": [
                {
                    "value": "*.ps",
                    "url": "https://www.digipres.org/formats/extensions/#*.ps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000029.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000029.xml"
            }
        },
        {
            "postscriptformatfamily": "",
            "name": "PostScript Format Family",
            "id": "fdd000029",
            "filenamepattern": [
                {
                    "value": "*.ps",
                    "url": "https://www.digipres.org/formats/extensions/#*.ps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000029.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000029.xml"
            }
        },
        {
            "postscriptformatfamily": "",
            "name": "PostScript Format Family",
            "id": "fdd000029",
            "filenamepattern": [
                {
                    "value": "*.ps",
                    "url": "https://www.digipres.org/formats/extensions/#*.ps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000029.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000029.xml"
            }
        },
        {
            "pdf(portabledocumentformat)family": "",
            "name": "PDF (Portable Document Format) Family",
            "id": "fdd000030",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-pdf"
                },
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                },
                {
                    "value": "application/vnd.pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.pdf"
                },
                {
                    "value": "application/acrobat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/acrobat"
                },
                {
                    "value": "text/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/pdf"
                },
                {
                    "value": "application/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000030.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000030.xml"
            }
        },
        {
            "pdf(portabledocumentformat)family": "",
            "name": "PDF (Portable Document Format) Family",
            "id": "fdd000030",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-pdf"
                },
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                },
                {
                    "value": "application/vnd.pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.pdf"
                },
                {
                    "value": "application/acrobat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/acrobat"
                },
                {
                    "value": "text/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/pdf"
                },
                {
                    "value": "application/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000030.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000030.xml"
            }
        },
        {
            "pdf(portabledocumentformat)family": "",
            "name": "PDF (Portable Document Format) Family",
            "id": "fdd000030",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-pdf"
                },
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                },
                {
                    "value": "application/vnd.pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.pdf"
                },
                {
                    "value": "application/acrobat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/acrobat"
                },
                {
                    "value": "text/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/pdf"
                },
                {
                    "value": "application/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000030.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000030.xml"
            }
        },
        {
            "pdf(portabledocumentformat)family": "",
            "name": "PDF (Portable Document Format) Family",
            "id": "fdd000030",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-pdf"
                },
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                },
                {
                    "value": "application/vnd.pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.pdf"
                },
                {
                    "value": "application/acrobat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/acrobat"
                },
                {
                    "value": "text/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/pdf"
                },
                {
                    "value": "application/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000030.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000030.xml"
            }
        },
        {
            "pdf(portabledocumentformat)family": "",
            "name": "PDF (Portable Document Format) Family",
            "id": "fdd000030",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-pdf"
                },
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                },
                {
                    "value": "application/vnd.pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.pdf"
                },
                {
                    "value": "application/acrobat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/acrobat"
                },
                {
                    "value": "text/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/pdf"
                },
                {
                    "value": "application/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000030.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000030.xml"
            }
        },
        {
            "pdf(portabledocumentformat)family": "",
            "name": "PDF (Portable Document Format) Family",
            "id": "fdd000030",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-pdf"
                },
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                },
                {
                    "value": "application/vnd.pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.pdf"
                },
                {
                    "value": "application/acrobat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/acrobat"
                },
                {
                    "value": "text/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/pdf"
                },
                {
                    "value": "application/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000030.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000030.xml"
            }
        },
        {
            "pdf(portabledocumentformat)family": "",
            "name": "PDF (Portable Document Format) Family",
            "id": "fdd000030",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-pdf"
                },
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                },
                {
                    "value": "application/vnd.pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.pdf"
                },
                {
                    "value": "application/acrobat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/acrobat"
                },
                {
                    "value": "text/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/pdf"
                },
                {
                    "value": "application/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000030.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000030.xml"
            }
        },
        {
            "pdf(portabledocumentformat)family": "",
            "name": "PDF (Portable Document Format) Family",
            "id": "fdd000030",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-pdf"
                },
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                },
                {
                    "value": "application/vnd.pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.pdf"
                },
                {
                    "value": "application/acrobat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/acrobat"
                },
                {
                    "value": "text/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/pdf"
                },
                {
                    "value": "application/x-pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000030.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000030.xml"
            }
        },
        {
            "mrsidimageformat,generation2": "",
            "name": "MrSID Image Format, Generation 2",
            "id": "fdd000031",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000031.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000031.xml"
            }
        },
        {
            "mrsidimageformat,generation2": "",
            "name": "MrSID Image Format, Generation 2",
            "id": "fdd000031",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000031.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000031.xml"
            }
        },
        {
            "mrsidimageformat,generation2": "",
            "name": "MrSID Image Format, Generation 2",
            "id": "fdd000031",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000031.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000031.xml"
            }
        },
        {
            "mrsidimageformat,generation2": "",
            "name": "MrSID Image Format, Generation 2",
            "id": "fdd000031",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000031.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000031.xml"
            }
        },
        {
            "mrsidimageformat,generation2": "",
            "name": "MrSID Image Format, Generation 2",
            "id": "fdd000031",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000031.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000031.xml"
            }
        },
        {
            "mrsidimageformat,generation2": "",
            "name": "MrSID Image Format, Generation 2",
            "id": "fdd000031",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000031.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000031.xml"
            }
        },
        {
            "mrsidimageformat,generation2": "",
            "name": "MrSID Image Format, Generation 2",
            "id": "fdd000031",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000031.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000031.xml"
            }
        },
        {
            "mrsidimageformat,generation2": "",
            "name": "MrSID Image Format, Generation 2",
            "id": "fdd000031",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000031.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000031.xml"
            }
        },
        {
            "mpeg2,mainprofile": "",
            "name": "MPEG-2, Main Profile",
            "id": "fdd000032",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000032.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000032.xml"
            }
        },
        {
            "mpeg2,mainprofile": "",
            "name": "MPEG-2, Main Profile",
            "id": "fdd000032",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000032.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000032.xml"
            }
        },
        {
            "mpeg2,mainprofile": "",
            "name": "MPEG-2, Main Profile",
            "id": "fdd000032",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000032.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000032.xml"
            }
        },
        {
            "mpeg2,mainprofile": "",
            "name": "MPEG-2, Main Profile",
            "id": "fdd000032",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000032.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000032.xml"
            }
        },
        {
            "mpeg2,mainprofile": "",
            "name": "MPEG-2, Main Profile",
            "id": "fdd000032",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000032.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000032.xml"
            }
        },
        {
            "mpeg2,mainprofile": "",
            "name": "MPEG-2, Main Profile",
            "id": "fdd000032",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000032.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000032.xml"
            }
        },
        {
            "mpeg2,mainprofile": "",
            "name": "MPEG-2, Main Profile",
            "id": "fdd000032",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000032.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000032.xml"
            }
        },
        {
            "mpeg2,mainprofile": "",
            "name": "MPEG-2, Main Profile",
            "id": "fdd000032",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000032.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000032.xml"
            }
        },
        {
            "mpeg2,simpleprofile": "",
            "name": "MPEG-2, Simple Profile",
            "id": "fdd000033",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000033.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000033.xml"
            }
        },
        {
            "mpeg2,simpleprofile": "",
            "name": "MPEG-2, Simple Profile",
            "id": "fdd000033",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000033.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000033.xml"
            }
        },
        {
            "mpeg2,simpleprofile": "",
            "name": "MPEG-2, Simple Profile",
            "id": "fdd000033",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000033.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000033.xml"
            }
        },
        {
            "mpeg2,simpleprofile": "",
            "name": "MPEG-2, Simple Profile",
            "id": "fdd000033",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000033.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000033.xml"
            }
        },
        {
            "mpeg2,simpleprofile": "",
            "name": "MPEG-2, Simple Profile",
            "id": "fdd000033",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000033.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000033.xml"
            }
        },
        {
            "mpeg2,simpleprofile": "",
            "name": "MPEG-2, Simple Profile",
            "id": "fdd000033",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000033.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000033.xml"
            }
        },
        {
            "mpeg2,simpleprofile": "",
            "name": "MPEG-2, Simple Profile",
            "id": "fdd000033",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000033.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000033.xml"
            }
        },
        {
            "mpeg2,simpleprofile": "",
            "name": "MPEG-2, Simple Profile",
            "id": "fdd000033",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000033.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000033.xml"
            }
        },
        {
            "mpeg2,4:2:2profile": "",
            "name": "MPEG-2, 4:2:2 Profile",
            "id": "fdd000034",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000034.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000034.xml"
            }
        },
        {
            "mpeg2,4:2:2profile": "",
            "name": "MPEG-2, 4:2:2 Profile",
            "id": "fdd000034",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000034.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000034.xml"
            }
        },
        {
            "mpeg2,4:2:2profile": "",
            "name": "MPEG-2, 4:2:2 Profile",
            "id": "fdd000034",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000034.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000034.xml"
            }
        },
        {
            "mpeg2,4:2:2profile": "",
            "name": "MPEG-2, 4:2:2 Profile",
            "id": "fdd000034",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000034.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000034.xml"
            }
        },
        {
            "mpeg2,4:2:2profile": "",
            "name": "MPEG-2, 4:2:2 Profile",
            "id": "fdd000034",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000034.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000034.xml"
            }
        },
        {
            "mpeg2,4:2:2profile": "",
            "name": "MPEG-2, 4:2:2 Profile",
            "id": "fdd000034",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000034.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000034.xml"
            }
        },
        {
            "mpeg2,4:2:2profile": "",
            "name": "MPEG-2, 4:2:2 Profile",
            "id": "fdd000034",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000034.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000034.xml"
            }
        },
        {
            "mpeg2,4:2:2profile": "",
            "name": "MPEG-2, 4:2:2 Profile",
            "id": "fdd000034",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000034.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000034.xml"
            }
        },
        {
            "mpeg1videocoding(h.261)": "",
            "name": "MPEG-1 Video Coding (H.261)",
            "id": "fdd000035",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000035.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000035.xml"
            }
        },
        {
            "mpeg1videocoding(h.261)": "",
            "name": "MPEG-1 Video Coding (H.261)",
            "id": "fdd000035",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000035.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000035.xml"
            }
        },
        {
            "mpeg1videocoding(h.261)": "",
            "name": "MPEG-1 Video Coding (H.261)",
            "id": "fdd000035",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000035.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000035.xml"
            }
        },
        {
            "mpeg1videocoding(h.261)": "",
            "name": "MPEG-1 Video Coding (H.261)",
            "id": "fdd000035",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000035.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000035.xml"
            }
        },
        {
            "mpeg1videocoding(h.261)": "",
            "name": "MPEG-1 Video Coding (H.261)",
            "id": "fdd000035",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000035.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000035.xml"
            }
        },
        {
            "mpeg1videocoding(h.261)": "",
            "name": "MPEG-1 Video Coding (H.261)",
            "id": "fdd000035",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000035.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000035.xml"
            }
        },
        {
            "mpeg1videocoding(h.261)": "",
            "name": "MPEG-1 Video Coding (H.261)",
            "id": "fdd000035",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000035.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000035.xml"
            }
        },
        {
            "mpeg1videocoding(h.261)": "",
            "name": "MPEG-1 Video Coding (H.261)",
            "id": "fdd000035",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000035.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000035.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2)": "",
            "name": "Advanced Audio Coding (MPEG-2)",
            "id": "fdd000036",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000036.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000036.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2)": "",
            "name": "Advanced Audio Coding (MPEG-2)",
            "id": "fdd000036",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000036.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000036.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2)": "",
            "name": "Advanced Audio Coding (MPEG-2)",
            "id": "fdd000036",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000036.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000036.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2)": "",
            "name": "Advanced Audio Coding (MPEG-2)",
            "id": "fdd000036",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000036.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000036.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2)": "",
            "name": "Advanced Audio Coding (MPEG-2)",
            "id": "fdd000036",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000036.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000036.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2)": "",
            "name": "Advanced Audio Coding (MPEG-2)",
            "id": "fdd000036",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000036.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000036.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2)": "",
            "name": "Advanced Audio Coding (MPEG-2)",
            "id": "fdd000036",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000036.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000036.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2)": "",
            "name": "Advanced Audio Coding (MPEG-2)",
            "id": "fdd000036",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000036.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000036.xml"
            }
        },
        {
            "mpeg4fileformat,version1": "",
            "name": "MPEG-4 File Format, Version 1",
            "id": "fdd000037",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000037.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000037.xml"
            }
        },
        {
            "mpeg4fileformat,version1": "",
            "name": "MPEG-4 File Format, Version 1",
            "id": "fdd000037",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000037.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000037.xml"
            }
        },
        {
            "mpeg4fileformat,version1": "",
            "name": "MPEG-4 File Format, Version 1",
            "id": "fdd000037",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000037.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000037.xml"
            }
        },
        {
            "mpeg4fileformat,version1": "",
            "name": "MPEG-4 File Format, Version 1",
            "id": "fdd000037",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000037.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000037.xml"
            }
        },
        {
            "mpeg4fileformat,version1": "",
            "name": "MPEG-4 File Format, Version 1",
            "id": "fdd000037",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000037.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000037.xml"
            }
        },
        {
            "mpeg4fileformat,version1": "",
            "name": "MPEG-4 File Format, Version 1",
            "id": "fdd000037",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000037.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000037.xml"
            }
        },
        {
            "mpeg4fileformat,version1": "",
            "name": "MPEG-4 File Format, Version 1",
            "id": "fdd000037",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000037.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000037.xml"
            }
        },
        {
            "mpeg4fileformat,version1": "",
            "name": "MPEG-4 File Format, Version 1",
            "id": "fdd000037",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000037.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000037.xml"
            }
        },
        {
            "alawcompressedsoundformat": "",
            "name": "A-Law Compressed Sound Format",
            "id": "fdd000038",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000038.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000038.xml"
            }
        },
        {
            "alawcompressedsoundformat": "",
            "name": "A-Law Compressed Sound Format",
            "id": "fdd000038",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000038.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000038.xml"
            }
        },
        {
            "alawcompressedsoundformat": "",
            "name": "A-Law Compressed Sound Format",
            "id": "fdd000038",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000038.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000038.xml"
            }
        },
        {
            "alawcompressedsoundformat": "",
            "name": "A-Law Compressed Sound Format",
            "id": "fdd000038",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000038.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000038.xml"
            }
        },
        {
            "alawcompressedsoundformat": "",
            "name": "A-Law Compressed Sound Format",
            "id": "fdd000038",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000038.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000038.xml"
            }
        },
        {
            "alawcompressedsoundformat": "",
            "name": "A-Law Compressed Sound Format",
            "id": "fdd000038",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000038.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000038.xml"
            }
        },
        {
            "alawcompressedsoundformat": "",
            "name": "A-Law Compressed Sound Format",
            "id": "fdd000038",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000038.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000038.xml"
            }
        },
        {
            "alawcompressedsoundformat": "",
            "name": "A-Law Compressed Sound Format",
            "id": "fdd000038",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000038.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000038.xml"
            }
        },
        {
            "\u00e2\u00b5lawcompressedsoundformat": "",
            "name": "\u00c2\u00b5-Law Compressed Sound Format",
            "id": "fdd000039",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000039.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000039.xml"
            }
        },
        {
            "\u00e2\u00b5lawcompressedsoundformat": "",
            "name": "\u00c2\u00b5-Law Compressed Sound Format",
            "id": "fdd000039",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000039.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000039.xml"
            }
        },
        {
            "\u00e2\u00b5lawcompressedsoundformat": "",
            "name": "\u00c2\u00b5-Law Compressed Sound Format",
            "id": "fdd000039",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000039.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000039.xml"
            }
        },
        {
            "\u00e2\u00b5lawcompressedsoundformat": "",
            "name": "\u00c2\u00b5-Law Compressed Sound Format",
            "id": "fdd000039",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000039.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000039.xml"
            }
        },
        {
            "\u00e2\u00b5lawcompressedsoundformat": "",
            "name": "\u00c2\u00b5-Law Compressed Sound Format",
            "id": "fdd000039",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000039.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000039.xml"
            }
        },
        {
            "\u00e2\u00b5lawcompressedsoundformat": "",
            "name": "\u00c2\u00b5-Law Compressed Sound Format",
            "id": "fdd000039",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000039.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000039.xml"
            }
        },
        {
            "\u00e2\u00b5lawcompressedsoundformat": "",
            "name": "\u00c2\u00b5-Law Compressed Sound Format",
            "id": "fdd000039",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000039.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000039.xml"
            }
        },
        {
            "\u00e2\u00b5lawcompressedsoundformat": "",
            "name": "\u00c2\u00b5-Law Compressed Sound Format",
            "id": "fdd000039",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000039.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000039.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)tiny,version1.2": "",
            "name": "Scalable Vector Graphics (SVG) Tiny, Version 1.2",
            "id": "fdd000043",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000043.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000043.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)tiny,version1.2": "",
            "name": "Scalable Vector Graphics (SVG) Tiny, Version 1.2",
            "id": "fdd000043",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000043.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000043.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)tiny,version1.2": "",
            "name": "Scalable Vector Graphics (SVG) Tiny, Version 1.2",
            "id": "fdd000043",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000043.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000043.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)tiny,version1.2": "",
            "name": "Scalable Vector Graphics (SVG) Tiny, Version 1.2",
            "id": "fdd000043",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000043.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000043.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)tiny,version1.2": "",
            "name": "Scalable Vector Graphics (SVG) Tiny, Version 1.2",
            "id": "fdd000043",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000043.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000043.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)tiny,version1.2": "",
            "name": "Scalable Vector Graphics (SVG) Tiny, Version 1.2",
            "id": "fdd000043",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000043.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000043.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)tiny,version1.2": "",
            "name": "Scalable Vector Graphics (SVG) Tiny, Version 1.2",
            "id": "fdd000043",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000043.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000043.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)tiny,version1.2": "",
            "name": "Scalable Vector Graphics (SVG) Tiny, Version 1.2",
            "id": "fdd000043",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000043.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000043.xml"
            }
        },
        {
            "mpeg4,visualcoding,simpleprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Profile",
            "id": "fdd000044",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000044.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000044.xml"
            }
        },
        {
            "mpeg4,visualcoding,simpleprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Profile",
            "id": "fdd000044",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000044.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000044.xml"
            }
        },
        {
            "mpeg4,visualcoding,simpleprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Profile",
            "id": "fdd000044",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000044.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000044.xml"
            }
        },
        {
            "mpeg4,visualcoding,simpleprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Profile",
            "id": "fdd000044",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000044.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000044.xml"
            }
        },
        {
            "mpeg4,visualcoding,simpleprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Profile",
            "id": "fdd000044",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000044.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000044.xml"
            }
        },
        {
            "mpeg4,visualcoding,simpleprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Profile",
            "id": "fdd000044",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000044.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000044.xml"
            }
        },
        {
            "mpeg4,visualcoding,simpleprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Profile",
            "id": "fdd000044",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000044.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000044.xml"
            }
        },
        {
            "mpeg4,visualcoding,simpleprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Profile",
            "id": "fdd000044",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000044.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000044.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplescalableprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Scalable Profile",
            "id": "fdd000045",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000045.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000045.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplescalableprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Scalable Profile",
            "id": "fdd000045",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000045.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000045.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplescalableprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Scalable Profile",
            "id": "fdd000045",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000045.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000045.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplescalableprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Scalable Profile",
            "id": "fdd000045",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000045.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000045.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplescalableprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Scalable Profile",
            "id": "fdd000045",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000045.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000045.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplescalableprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Scalable Profile",
            "id": "fdd000045",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000045.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000045.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplescalableprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Scalable Profile",
            "id": "fdd000045",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000045.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000045.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplescalableprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Scalable Profile",
            "id": "fdd000045",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000045.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000045.xml"
            }
        },
        {
            "mpeg4,visualcoding,advancedsimpleprofile": "",
            "name": "MPEG-4, Visual Coding, Advanced Simple Profile",
            "id": "fdd000046",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000046.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000046.xml"
            }
        },
        {
            "mpeg4,visualcoding,advancedsimpleprofile": "",
            "name": "MPEG-4, Visual Coding, Advanced Simple Profile",
            "id": "fdd000046",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000046.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000046.xml"
            }
        },
        {
            "mpeg4,visualcoding,advancedsimpleprofile": "",
            "name": "MPEG-4, Visual Coding, Advanced Simple Profile",
            "id": "fdd000046",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000046.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000046.xml"
            }
        },
        {
            "mpeg4,visualcoding,advancedsimpleprofile": "",
            "name": "MPEG-4, Visual Coding, Advanced Simple Profile",
            "id": "fdd000046",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000046.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000046.xml"
            }
        },
        {
            "mpeg4,visualcoding,advancedsimpleprofile": "",
            "name": "MPEG-4, Visual Coding, Advanced Simple Profile",
            "id": "fdd000046",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000046.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000046.xml"
            }
        },
        {
            "mpeg4,visualcoding,advancedsimpleprofile": "",
            "name": "MPEG-4, Visual Coding, Advanced Simple Profile",
            "id": "fdd000046",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000046.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000046.xml"
            }
        },
        {
            "mpeg4,visualcoding,advancedsimpleprofile": "",
            "name": "MPEG-4, Visual Coding, Advanced Simple Profile",
            "id": "fdd000046",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000046.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000046.xml"
            }
        },
        {
            "mpeg4,visualcoding,advancedsimpleprofile": "",
            "name": "MPEG-4, Visual Coding, Advanced Simple Profile",
            "id": "fdd000046",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000046.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000046.xml"
            }
        },
        {
            "mpeg4,visualcoding,coreprofile": "",
            "name": "MPEG-4, Visual Coding, Core Profile",
            "id": "fdd000047",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000047.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000047.xml"
            }
        },
        {
            "mpeg4,visualcoding,coreprofile": "",
            "name": "MPEG-4, Visual Coding, Core Profile",
            "id": "fdd000047",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000047.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000047.xml"
            }
        },
        {
            "mpeg4,visualcoding,coreprofile": "",
            "name": "MPEG-4, Visual Coding, Core Profile",
            "id": "fdd000047",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000047.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000047.xml"
            }
        },
        {
            "mpeg4,visualcoding,coreprofile": "",
            "name": "MPEG-4, Visual Coding, Core Profile",
            "id": "fdd000047",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000047.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000047.xml"
            }
        },
        {
            "mpeg4,visualcoding,coreprofile": "",
            "name": "MPEG-4, Visual Coding, Core Profile",
            "id": "fdd000047",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000047.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000047.xml"
            }
        },
        {
            "mpeg4,visualcoding,coreprofile": "",
            "name": "MPEG-4, Visual Coding, Core Profile",
            "id": "fdd000047",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000047.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000047.xml"
            }
        },
        {
            "mpeg4,visualcoding,coreprofile": "",
            "name": "MPEG-4, Visual Coding, Core Profile",
            "id": "fdd000047",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000047.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000047.xml"
            }
        },
        {
            "mpeg4,visualcoding,coreprofile": "",
            "name": "MPEG-4, Visual Coding, Core Profile",
            "id": "fdd000047",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000047.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000047.xml"
            }
        },
        {
            "mpeg4,visualcoding,mainprofile": "",
            "name": "MPEG-4, Visual Coding, Main Profile",
            "id": "fdd000048",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000048.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000048.xml"
            }
        },
        {
            "mpeg4,visualcoding,mainprofile": "",
            "name": "MPEG-4, Visual Coding, Main Profile",
            "id": "fdd000048",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000048.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000048.xml"
            }
        },
        {
            "mpeg4,visualcoding,mainprofile": "",
            "name": "MPEG-4, Visual Coding, Main Profile",
            "id": "fdd000048",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000048.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000048.xml"
            }
        },
        {
            "mpeg4,visualcoding,mainprofile": "",
            "name": "MPEG-4, Visual Coding, Main Profile",
            "id": "fdd000048",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000048.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000048.xml"
            }
        },
        {
            "mpeg4,visualcoding,mainprofile": "",
            "name": "MPEG-4, Visual Coding, Main Profile",
            "id": "fdd000048",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000048.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000048.xml"
            }
        },
        {
            "mpeg4,visualcoding,mainprofile": "",
            "name": "MPEG-4, Visual Coding, Main Profile",
            "id": "fdd000048",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000048.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000048.xml"
            }
        },
        {
            "mpeg4,visualcoding,mainprofile": "",
            "name": "MPEG-4, Visual Coding, Main Profile",
            "id": "fdd000048",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000048.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000048.xml"
            }
        },
        {
            "mpeg4,visualcoding,mainprofile": "",
            "name": "MPEG-4, Visual Coding, Main Profile",
            "id": "fdd000048",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000048.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000048.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplestudioprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Studio Profile",
            "id": "fdd000049",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000049.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000049.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplestudioprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Studio Profile",
            "id": "fdd000049",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000049.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000049.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplestudioprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Studio Profile",
            "id": "fdd000049",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000049.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000049.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplestudioprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Studio Profile",
            "id": "fdd000049",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000049.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000049.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplestudioprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Studio Profile",
            "id": "fdd000049",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000049.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000049.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplestudioprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Studio Profile",
            "id": "fdd000049",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000049.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000049.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplestudioprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Studio Profile",
            "id": "fdd000049",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000049.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000049.xml"
            }
        },
        {
            "mpeg4,visualcoding,simplestudioprofile": "",
            "name": "MPEG-4, Visual Coding, Simple Studio Profile",
            "id": "fdd000049",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000049.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000049.xml"
            }
        },
        {
            "realvideo,version10": "",
            "name": "RealVideo, Version 10",
            "id": "fdd000050",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.rmvb",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmvb"
                },
                {
                    "value": "*.rv",
                    "url": "https://www.digipres.org/formats/extensions/#*.rv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "video/vnd.rn-realvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.rn-realvideo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000050.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000050.xml"
            }
        },
        {
            "realvideo,version10": "",
            "name": "RealVideo, Version 10",
            "id": "fdd000050",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.rmvb",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmvb"
                },
                {
                    "value": "*.rv",
                    "url": "https://www.digipres.org/formats/extensions/#*.rv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "video/vnd.rn-realvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.rn-realvideo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000050.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000050.xml"
            }
        },
        {
            "realvideo,version10": "",
            "name": "RealVideo, Version 10",
            "id": "fdd000050",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.rmvb",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmvb"
                },
                {
                    "value": "*.rv",
                    "url": "https://www.digipres.org/formats/extensions/#*.rv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "video/vnd.rn-realvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.rn-realvideo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000050.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000050.xml"
            }
        },
        {
            "realvideo,version10": "",
            "name": "RealVideo, Version 10",
            "id": "fdd000050",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.rmvb",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmvb"
                },
                {
                    "value": "*.rv",
                    "url": "https://www.digipres.org/formats/extensions/#*.rv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "video/vnd.rn-realvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.rn-realvideo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000050.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000050.xml"
            }
        },
        {
            "realvideo,version10": "",
            "name": "RealVideo, Version 10",
            "id": "fdd000050",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.rmvb",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmvb"
                },
                {
                    "value": "*.rv",
                    "url": "https://www.digipres.org/formats/extensions/#*.rv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "video/vnd.rn-realvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.rn-realvideo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000050.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000050.xml"
            }
        },
        {
            "realvideo,version10": "",
            "name": "RealVideo, Version 10",
            "id": "fdd000050",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.rmvb",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmvb"
                },
                {
                    "value": "*.rv",
                    "url": "https://www.digipres.org/formats/extensions/#*.rv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "video/vnd.rn-realvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.rn-realvideo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000050.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000050.xml"
            }
        },
        {
            "realvideo,version10": "",
            "name": "RealVideo, Version 10",
            "id": "fdd000050",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.rmvb",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmvb"
                },
                {
                    "value": "*.rv",
                    "url": "https://www.digipres.org/formats/extensions/#*.rv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "video/vnd.rn-realvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.rn-realvideo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000050.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000050.xml"
            }
        },
        {
            "realvideo,version10": "",
            "name": "RealVideo, Version 10",
            "id": "fdd000050",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.rmvb",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmvb"
                },
                {
                    "value": "*.rv",
                    "url": "https://www.digipres.org/formats/extensions/#*.rv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "video/vnd.rn-realvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.rn-realvideo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000050.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000050.xml"
            }
        },
        {
            "realaudio,version10": "",
            "name": "RealAudio, Version 10",
            "id": "fdd000051",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.ra",
                    "url": "https://www.digipres.org/formats/extensions/#*.ra"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "audio/vnd.rn-realaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.rn-realaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000051.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000051.xml"
            }
        },
        {
            "realaudio,version10": "",
            "name": "RealAudio, Version 10",
            "id": "fdd000051",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.ra",
                    "url": "https://www.digipres.org/formats/extensions/#*.ra"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "audio/vnd.rn-realaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.rn-realaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000051.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000051.xml"
            }
        },
        {
            "realaudio,version10": "",
            "name": "RealAudio, Version 10",
            "id": "fdd000051",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.ra",
                    "url": "https://www.digipres.org/formats/extensions/#*.ra"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "audio/vnd.rn-realaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.rn-realaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000051.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000051.xml"
            }
        },
        {
            "realaudio,version10": "",
            "name": "RealAudio, Version 10",
            "id": "fdd000051",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.ra",
                    "url": "https://www.digipres.org/formats/extensions/#*.ra"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "audio/vnd.rn-realaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.rn-realaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000051.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000051.xml"
            }
        },
        {
            "realaudio,version10": "",
            "name": "RealAudio, Version 10",
            "id": "fdd000051",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.ra",
                    "url": "https://www.digipres.org/formats/extensions/#*.ra"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "audio/vnd.rn-realaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.rn-realaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000051.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000051.xml"
            }
        },
        {
            "realaudio,version10": "",
            "name": "RealAudio, Version 10",
            "id": "fdd000051",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.ra",
                    "url": "https://www.digipres.org/formats/extensions/#*.ra"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "audio/vnd.rn-realaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.rn-realaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000051.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000051.xml"
            }
        },
        {
            "realaudio,version10": "",
            "name": "RealAudio, Version 10",
            "id": "fdd000051",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.ra",
                    "url": "https://www.digipres.org/formats/extensions/#*.ra"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "audio/vnd.rn-realaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.rn-realaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000051.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000051.xml"
            }
        },
        {
            "realaudio,version10": "",
            "name": "RealAudio, Version 10",
            "id": "fdd000051",
            "filenamepattern": [
                {
                    "value": "*.rm",
                    "url": "https://www.digipres.org/formats/extensions/#*.rm"
                },
                {
                    "value": "*.ra",
                    "url": "https://www.digipres.org/formats/extensions/#*.ra"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rn-realmedia",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rn-realmedia"
                },
                {
                    "value": "audio/vnd.rn-realaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vnd.rn-realaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000051.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000051.xml"
            }
        },
        {
            "quicktimefileformat": "",
            "name": "QuickTime File Format",
            "id": "fdd000052",
            "filenamepattern": [
                {
                    "value": "*.qtif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qtif"
                },
                {
                    "value": "*.qt",
                    "url": "https://www.digipres.org/formats/extensions/#*.qt"
                },
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                },
                {
                    "value": "*.qif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qif"
                },
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                },
                {
                    "value": "*.qti",
                    "url": "https://www.digipres.org/formats/extensions/#*.qti"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-quicktime"
                },
                {
                    "value": "image/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-quicktime"
                },
                {
                    "value": "video/quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/quicktime"
                },
                {
                    "value": "image/mov",
                    "url": "https://www.digipres.org/formats/mime-types/#image/mov"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000052.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000052.xml"
            }
        },
        {
            "quicktimefileformat": "",
            "name": "QuickTime File Format",
            "id": "fdd000052",
            "filenamepattern": [
                {
                    "value": "*.qtif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qtif"
                },
                {
                    "value": "*.qt",
                    "url": "https://www.digipres.org/formats/extensions/#*.qt"
                },
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                },
                {
                    "value": "*.qif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qif"
                },
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                },
                {
                    "value": "*.qti",
                    "url": "https://www.digipres.org/formats/extensions/#*.qti"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-quicktime"
                },
                {
                    "value": "image/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-quicktime"
                },
                {
                    "value": "video/quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/quicktime"
                },
                {
                    "value": "image/mov",
                    "url": "https://www.digipres.org/formats/mime-types/#image/mov"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000052.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000052.xml"
            }
        },
        {
            "quicktimefileformat": "",
            "name": "QuickTime File Format",
            "id": "fdd000052",
            "filenamepattern": [
                {
                    "value": "*.qtif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qtif"
                },
                {
                    "value": "*.qt",
                    "url": "https://www.digipres.org/formats/extensions/#*.qt"
                },
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                },
                {
                    "value": "*.qif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qif"
                },
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                },
                {
                    "value": "*.qti",
                    "url": "https://www.digipres.org/formats/extensions/#*.qti"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-quicktime"
                },
                {
                    "value": "image/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-quicktime"
                },
                {
                    "value": "video/quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/quicktime"
                },
                {
                    "value": "image/mov",
                    "url": "https://www.digipres.org/formats/mime-types/#image/mov"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000052.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000052.xml"
            }
        },
        {
            "quicktimefileformat": "",
            "name": "QuickTime File Format",
            "id": "fdd000052",
            "filenamepattern": [
                {
                    "value": "*.qtif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qtif"
                },
                {
                    "value": "*.qt",
                    "url": "https://www.digipres.org/formats/extensions/#*.qt"
                },
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                },
                {
                    "value": "*.qif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qif"
                },
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                },
                {
                    "value": "*.qti",
                    "url": "https://www.digipres.org/formats/extensions/#*.qti"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-quicktime"
                },
                {
                    "value": "image/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-quicktime"
                },
                {
                    "value": "video/quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/quicktime"
                },
                {
                    "value": "image/mov",
                    "url": "https://www.digipres.org/formats/mime-types/#image/mov"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000052.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000052.xml"
            }
        },
        {
            "quicktimefileformat": "",
            "name": "QuickTime File Format",
            "id": "fdd000052",
            "filenamepattern": [
                {
                    "value": "*.qtif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qtif"
                },
                {
                    "value": "*.qt",
                    "url": "https://www.digipres.org/formats/extensions/#*.qt"
                },
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                },
                {
                    "value": "*.qif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qif"
                },
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                },
                {
                    "value": "*.qti",
                    "url": "https://www.digipres.org/formats/extensions/#*.qti"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-quicktime"
                },
                {
                    "value": "image/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-quicktime"
                },
                {
                    "value": "video/quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/quicktime"
                },
                {
                    "value": "image/mov",
                    "url": "https://www.digipres.org/formats/mime-types/#image/mov"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000052.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000052.xml"
            }
        },
        {
            "quicktimefileformat": "",
            "name": "QuickTime File Format",
            "id": "fdd000052",
            "filenamepattern": [
                {
                    "value": "*.qtif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qtif"
                },
                {
                    "value": "*.qt",
                    "url": "https://www.digipres.org/formats/extensions/#*.qt"
                },
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                },
                {
                    "value": "*.qif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qif"
                },
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                },
                {
                    "value": "*.qti",
                    "url": "https://www.digipres.org/formats/extensions/#*.qti"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-quicktime"
                },
                {
                    "value": "image/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-quicktime"
                },
                {
                    "value": "video/quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/quicktime"
                },
                {
                    "value": "image/mov",
                    "url": "https://www.digipres.org/formats/mime-types/#image/mov"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000052.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000052.xml"
            }
        },
        {
            "quicktimefileformat": "",
            "name": "QuickTime File Format",
            "id": "fdd000052",
            "filenamepattern": [
                {
                    "value": "*.qtif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qtif"
                },
                {
                    "value": "*.qt",
                    "url": "https://www.digipres.org/formats/extensions/#*.qt"
                },
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                },
                {
                    "value": "*.qif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qif"
                },
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                },
                {
                    "value": "*.qti",
                    "url": "https://www.digipres.org/formats/extensions/#*.qti"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-quicktime"
                },
                {
                    "value": "image/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-quicktime"
                },
                {
                    "value": "video/quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/quicktime"
                },
                {
                    "value": "image/mov",
                    "url": "https://www.digipres.org/formats/mime-types/#image/mov"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000052.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000052.xml"
            }
        },
        {
            "quicktimefileformat": "",
            "name": "QuickTime File Format",
            "id": "fdd000052",
            "filenamepattern": [
                {
                    "value": "*.qtif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qtif"
                },
                {
                    "value": "*.qt",
                    "url": "https://www.digipres.org/formats/extensions/#*.qt"
                },
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                },
                {
                    "value": "*.qif",
                    "url": "https://www.digipres.org/formats/extensions/#*.qif"
                },
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                },
                {
                    "value": "*.qti",
                    "url": "https://www.digipres.org/formats/extensions/#*.qti"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-quicktime"
                },
                {
                    "value": "image/x-quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-quicktime"
                },
                {
                    "value": "video/quicktime",
                    "url": "https://www.digipres.org/formats/mime-types/#video/quicktime"
                },
                {
                    "value": "image/mov",
                    "url": "https://www.digipres.org/formats/mime-types/#image/mov"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000052.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000052.xml"
            }
        },
        
        {
            "oebps(openebookforumpublicationstructure)1.0": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.0",
            "id": "fdd000054",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000054.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000054.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.0": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.0",
            "id": "fdd000054",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000054.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000054.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.0": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.0",
            "id": "fdd000054",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000054.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000054.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.0": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.0",
            "id": "fdd000054",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000054.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000054.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.0": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.0",
            "id": "fdd000054",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000054.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000054.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.0": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.0",
            "id": "fdd000054",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000054.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000054.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.0": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.0",
            "id": "fdd000054",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000054.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000054.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.0": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.0",
            "id": "fdd000054",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000054.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000054.xml"
            }
        },
        {
            "realaudio,realaudiocodec": "",
            "name": "RealAudio, RealAudio Codec",
            "id": "fdd000055",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000055.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000055.xml"
            }
        },
        {
            "realaudio,realaudiocodec": "",
            "name": "RealAudio, RealAudio Codec",
            "id": "fdd000055",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000055.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000055.xml"
            }
        },
        {
            "realaudio,realaudiocodec": "",
            "name": "RealAudio, RealAudio Codec",
            "id": "fdd000055",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000055.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000055.xml"
            }
        },
        {
            "realaudio,realaudiocodec": "",
            "name": "RealAudio, RealAudio Codec",
            "id": "fdd000055",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000055.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000055.xml"
            }
        },
        {
            "realaudio,realaudiocodec": "",
            "name": "RealAudio, RealAudio Codec",
            "id": "fdd000055",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000055.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000055.xml"
            }
        },
        {
            "realaudio,realaudiocodec": "",
            "name": "RealAudio, RealAudio Codec",
            "id": "fdd000055",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000055.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000055.xml"
            }
        },
        {
            "realaudio,realaudiocodec": "",
            "name": "RealAudio, RealAudio Codec",
            "id": "fdd000055",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000055.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000055.xml"
            }
        },
        {
            "realaudio,realaudiocodec": "",
            "name": "RealAudio, RealAudio Codec",
            "id": "fdd000055",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000055.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000055.xml"
            }
        },
        {
            "realaudio,aaccodec": "",
            "name": "RealAudio, AAC Codec",
            "id": "fdd000056",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000056.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000056.xml"
            }
        },
        {
            "realaudio,aaccodec": "",
            "name": "RealAudio, AAC Codec",
            "id": "fdd000056",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000056.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000056.xml"
            }
        },
        {
            "realaudio,aaccodec": "",
            "name": "RealAudio, AAC Codec",
            "id": "fdd000056",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000056.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000056.xml"
            }
        },
        {
            "realaudio,aaccodec": "",
            "name": "RealAudio, AAC Codec",
            "id": "fdd000056",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000056.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000056.xml"
            }
        },
        {
            "realaudio,aaccodec": "",
            "name": "RealAudio, AAC Codec",
            "id": "fdd000056",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000056.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000056.xml"
            }
        },
        {
            "realaudio,aaccodec": "",
            "name": "RealAudio, AAC Codec",
            "id": "fdd000056",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000056.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000056.xml"
            }
        },
        {
            "realaudio,aaccodec": "",
            "name": "RealAudio, AAC Codec",
            "id": "fdd000056",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000056.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000056.xml"
            }
        },
        {
            "realaudio,aaccodec": "",
            "name": "RealAudio, AAC Codec",
            "id": "fdd000056",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000056.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000056.xml"
            }
        },
        {
            "realaudio,losslesscodec": "",
            "name": "RealAudio, Lossless Codec",
            "id": "fdd000057",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000057.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000057.xml"
            }
        },
        {
            "realaudio,losslesscodec": "",
            "name": "RealAudio, Lossless Codec",
            "id": "fdd000057",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000057.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000057.xml"
            }
        },
        {
            "realaudio,losslesscodec": "",
            "name": "RealAudio, Lossless Codec",
            "id": "fdd000057",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000057.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000057.xml"
            }
        },
        {
            "realaudio,losslesscodec": "",
            "name": "RealAudio, Lossless Codec",
            "id": "fdd000057",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000057.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000057.xml"
            }
        },
        {
            "realaudio,losslesscodec": "",
            "name": "RealAudio, Lossless Codec",
            "id": "fdd000057",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000057.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000057.xml"
            }
        },
        {
            "realaudio,losslesscodec": "",
            "name": "RealAudio, Lossless Codec",
            "id": "fdd000057",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000057.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000057.xml"
            }
        },
        {
            "realaudio,losslesscodec": "",
            "name": "RealAudio, Lossless Codec",
            "id": "fdd000057",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000057.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000057.xml"
            }
        },
        {
            "realaudio,losslesscodec": "",
            "name": "RealAudio, Lossless Codec",
            "id": "fdd000057",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000057.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000057.xml"
            }
        },
        {
            "realaudio,multichannelcodec": "",
            "name": "RealAudio, Multichannel Codec",
            "id": "fdd000058",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000058.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000058.xml"
            }
        },
        {
            "realaudio,multichannelcodec": "",
            "name": "RealAudio, Multichannel Codec",
            "id": "fdd000058",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000058.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000058.xml"
            }
        },
        {
            "realaudio,multichannelcodec": "",
            "name": "RealAudio, Multichannel Codec",
            "id": "fdd000058",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000058.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000058.xml"
            }
        },
        {
            "realaudio,multichannelcodec": "",
            "name": "RealAudio, Multichannel Codec",
            "id": "fdd000058",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000058.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000058.xml"
            }
        },
        {
            "realaudio,multichannelcodec": "",
            "name": "RealAudio, Multichannel Codec",
            "id": "fdd000058",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000058.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000058.xml"
            }
        },
        {
            "realaudio,multichannelcodec": "",
            "name": "RealAudio, Multichannel Codec",
            "id": "fdd000058",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000058.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000058.xml"
            }
        },
        {
            "realaudio,multichannelcodec": "",
            "name": "RealAudio, Multichannel Codec",
            "id": "fdd000058",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000058.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000058.xml"
            }
        },
        {
            "realaudio,multichannelcodec": "",
            "name": "RealAudio, Multichannel Codec",
            "id": "fdd000058",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000058.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000058.xml"
            }
        },
        {
            "avi(audiovideointerleaved)fileformat": "",
            "name": "AVI (Audio Video Interleaved) File Format",
            "id": "fdd000059",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/avi"
                },
                {
                    "value": "application/x-troff-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-troff-msvideo"
                },
                {
                    "value": "image/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avi"
                },
                {
                    "value": "audio/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/avi"
                },
                {
                    "value": "video/x-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-msvideo"
                },
                {
                    "value": "video/msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/msvideo"
                },
                {
                    "value": "video/vnd.avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.avi"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000059.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000059.xml"
            }
        },
        {
            "avi(audiovideointerleaved)fileformat": "",
            "name": "AVI (Audio Video Interleaved) File Format",
            "id": "fdd000059",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/avi"
                },
                {
                    "value": "application/x-troff-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-troff-msvideo"
                },
                {
                    "value": "image/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avi"
                },
                {
                    "value": "audio/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/avi"
                },
                {
                    "value": "video/x-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-msvideo"
                },
                {
                    "value": "video/msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/msvideo"
                },
                {
                    "value": "video/vnd.avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.avi"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000059.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000059.xml"
            }
        },
        {
            "avi(audiovideointerleaved)fileformat": "",
            "name": "AVI (Audio Video Interleaved) File Format",
            "id": "fdd000059",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/avi"
                },
                {
                    "value": "application/x-troff-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-troff-msvideo"
                },
                {
                    "value": "image/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avi"
                },
                {
                    "value": "audio/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/avi"
                },
                {
                    "value": "video/x-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-msvideo"
                },
                {
                    "value": "video/msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/msvideo"
                },
                {
                    "value": "video/vnd.avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.avi"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000059.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000059.xml"
            }
        },
        {
            "avi(audiovideointerleaved)fileformat": "",
            "name": "AVI (Audio Video Interleaved) File Format",
            "id": "fdd000059",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/avi"
                },
                {
                    "value": "application/x-troff-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-troff-msvideo"
                },
                {
                    "value": "image/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avi"
                },
                {
                    "value": "audio/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/avi"
                },
                {
                    "value": "video/x-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-msvideo"
                },
                {
                    "value": "video/msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/msvideo"
                },
                {
                    "value": "video/vnd.avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.avi"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000059.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000059.xml"
            }
        },
        {
            "avi(audiovideointerleaved)fileformat": "",
            "name": "AVI (Audio Video Interleaved) File Format",
            "id": "fdd000059",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/avi"
                },
                {
                    "value": "application/x-troff-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-troff-msvideo"
                },
                {
                    "value": "image/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avi"
                },
                {
                    "value": "audio/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/avi"
                },
                {
                    "value": "video/x-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-msvideo"
                },
                {
                    "value": "video/msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/msvideo"
                },
                {
                    "value": "video/vnd.avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.avi"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000059.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000059.xml"
            }
        },
        {
            "avi(audiovideointerleaved)fileformat": "",
            "name": "AVI (Audio Video Interleaved) File Format",
            "id": "fdd000059",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/avi"
                },
                {
                    "value": "application/x-troff-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-troff-msvideo"
                },
                {
                    "value": "image/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avi"
                },
                {
                    "value": "audio/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/avi"
                },
                {
                    "value": "video/x-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-msvideo"
                },
                {
                    "value": "video/msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/msvideo"
                },
                {
                    "value": "video/vnd.avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.avi"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000059.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000059.xml"
            }
        },
        {
            "avi(audiovideointerleaved)fileformat": "",
            "name": "AVI (Audio Video Interleaved) File Format",
            "id": "fdd000059",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/avi"
                },
                {
                    "value": "application/x-troff-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-troff-msvideo"
                },
                {
                    "value": "image/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avi"
                },
                {
                    "value": "audio/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/avi"
                },
                {
                    "value": "video/x-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-msvideo"
                },
                {
                    "value": "video/msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/msvideo"
                },
                {
                    "value": "video/vnd.avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.avi"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000059.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000059.xml"
            }
        },
        {
            "avi(audiovideointerleaved)fileformat": "",
            "name": "AVI (Audio Video Interleaved) File Format",
            "id": "fdd000059",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/avi"
                },
                {
                    "value": "application/x-troff-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-troff-msvideo"
                },
                {
                    "value": "image/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avi"
                },
                {
                    "value": "audio/avi",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/avi"
                },
                {
                    "value": "video/x-msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-msvideo"
                },
                {
                    "value": "video/msvideo",
                    "url": "https://www.digipres.org/formats/mime-types/#video/msvideo"
                },
                {
                    "value": "video/vnd.avi",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vnd.avi"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000059.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000059.xml"
            }
        },
        {
            "aviopendmlwithmotionjpegvideo": "",
            "name": "AVI OpenDML with Motion JPEG Video",
            "id": "fdd000060",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000060.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000060.xml"
            }
        },
        {
            "aviopendmlwithmotionjpegvideo": "",
            "name": "AVI OpenDML with Motion JPEG Video",
            "id": "fdd000060",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000060.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000060.xml"
            }
        },
        {
            "aviopendmlwithmotionjpegvideo": "",
            "name": "AVI OpenDML with Motion JPEG Video",
            "id": "fdd000060",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000060.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000060.xml"
            }
        },
        {
            "aviopendmlwithmotionjpegvideo": "",
            "name": "AVI OpenDML with Motion JPEG Video",
            "id": "fdd000060",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000060.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000060.xml"
            }
        },
        {
            "aviopendmlwithmotionjpegvideo": "",
            "name": "AVI OpenDML with Motion JPEG Video",
            "id": "fdd000060",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000060.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000060.xml"
            }
        },
        {
            "aviopendmlwithmotionjpegvideo": "",
            "name": "AVI OpenDML with Motion JPEG Video",
            "id": "fdd000060",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000060.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000060.xml"
            }
        },
        {
            "aviopendmlwithmotionjpegvideo": "",
            "name": "AVI OpenDML with Motion JPEG Video",
            "id": "fdd000060",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000060.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000060.xml"
            }
        },
        {
            "aviopendmlwithmotionjpegvideo": "",
            "name": "AVI OpenDML with Motion JPEG Video",
            "id": "fdd000060",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000060.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000060.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withindeocodec": "",
            "name": "AVI (Audio Video Interleaved) with Indeo Codec",
            "id": "fdd000061",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000061.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000061.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withindeocodec": "",
            "name": "AVI (Audio Video Interleaved) with Indeo Codec",
            "id": "fdd000061",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000061.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000061.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withindeocodec": "",
            "name": "AVI (Audio Video Interleaved) with Indeo Codec",
            "id": "fdd000061",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000061.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000061.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withindeocodec": "",
            "name": "AVI (Audio Video Interleaved) with Indeo Codec",
            "id": "fdd000061",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000061.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000061.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withindeocodec": "",
            "name": "AVI (Audio Video Interleaved) with Indeo Codec",
            "id": "fdd000061",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000061.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000061.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withindeocodec": "",
            "name": "AVI (Audio Video Interleaved) with Indeo Codec",
            "id": "fdd000061",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000061.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000061.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withindeocodec": "",
            "name": "AVI (Audio Video Interleaved) with Indeo Codec",
            "id": "fdd000061",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000061.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000061.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withindeocodec": "",
            "name": "AVI (Audio Video Interleaved) with Indeo Codec",
            "id": "fdd000061",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000061.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000061.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withcinepakcodec": "",
            "name": "AVI (Audio Video Interleaved) with Cinepak Codec",
            "id": "fdd000062",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000062.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000062.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withcinepakcodec": "",
            "name": "AVI (Audio Video Interleaved) with Cinepak Codec",
            "id": "fdd000062",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000062.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000062.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withcinepakcodec": "",
            "name": "AVI (Audio Video Interleaved) with Cinepak Codec",
            "id": "fdd000062",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000062.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000062.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withcinepakcodec": "",
            "name": "AVI (Audio Video Interleaved) with Cinepak Codec",
            "id": "fdd000062",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000062.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000062.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withcinepakcodec": "",
            "name": "AVI (Audio Video Interleaved) with Cinepak Codec",
            "id": "fdd000062",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000062.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000062.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withcinepakcodec": "",
            "name": "AVI (Audio Video Interleaved) with Cinepak Codec",
            "id": "fdd000062",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000062.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000062.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withcinepakcodec": "",
            "name": "AVI (Audio Video Interleaved) with Cinepak Codec",
            "id": "fdd000062",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000062.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000062.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withcinepakcodec": "",
            "name": "AVI (Audio Video Interleaved) with Cinepak Codec",
            "id": "fdd000062",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000062.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000062.xml"
            }
        },
        {
            "mjpeg(motionjpeg)videocodec": "",
            "name": "MJPEG (Motion JPEG) Video Codec",
            "id": "fdd000063",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000063.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000063.xml"
            }
        },
        {
            "mjpeg(motionjpeg)videocodec": "",
            "name": "MJPEG (Motion JPEG) Video Codec",
            "id": "fdd000063",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000063.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000063.xml"
            }
        },
        {
            "mjpeg(motionjpeg)videocodec": "",
            "name": "MJPEG (Motion JPEG) Video Codec",
            "id": "fdd000063",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000063.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000063.xml"
            }
        },
        {
            "mjpeg(motionjpeg)videocodec": "",
            "name": "MJPEG (Motion JPEG) Video Codec",
            "id": "fdd000063",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000063.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000063.xml"
            }
        },
        {
            "mjpeg(motionjpeg)videocodec": "",
            "name": "MJPEG (Motion JPEG) Video Codec",
            "id": "fdd000063",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000063.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000063.xml"
            }
        },
        {
            "mjpeg(motionjpeg)videocodec": "",
            "name": "MJPEG (Motion JPEG) Video Codec",
            "id": "fdd000063",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000063.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000063.xml"
            }
        },
        {
            "mjpeg(motionjpeg)videocodec": "",
            "name": "MJPEG (Motion JPEG) Video Codec",
            "id": "fdd000063",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000063.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000063.xml"
            }
        },
        {
            "mjpeg(motionjpeg)videocodec": "",
            "name": "MJPEG (Motion JPEG) Video Codec",
            "id": "fdd000063",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000063.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000063.xml"
            }
        },
        {
            "cinepak": "",
            "name": "Cinepak",
            "id": "fdd000064",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000064.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000064.xml"
            }
        },
        {
            "cinepak": "",
            "name": "Cinepak",
            "id": "fdd000064",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000064.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000064.xml"
            }
        },
        {
            "cinepak": "",
            "name": "Cinepak",
            "id": "fdd000064",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000064.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000064.xml"
            }
        },
        {
            "cinepak": "",
            "name": "Cinepak",
            "id": "fdd000064",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000064.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000064.xml"
            }
        },
        {
            "cinepak": "",
            "name": "Cinepak",
            "id": "fdd000064",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000064.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000064.xml"
            }
        },
        {
            "cinepak": "",
            "name": "Cinepak",
            "id": "fdd000064",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000064.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000064.xml"
            }
        },
        {
            "cinepak": "",
            "name": "Cinepak",
            "id": "fdd000064",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000064.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000064.xml"
            }
        },
        {
            "cinepak": "",
            "name": "Cinepak",
            "id": "fdd000064",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000064.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000064.xml"
            }
        },
        {
            "indeovideocodec,version3": "",
            "name": "Indeo Video Codec, Version 3",
            "id": "fdd000065",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000065.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000065.xml"
            }
        },
        {
            "indeovideocodec,version3": "",
            "name": "Indeo Video Codec, Version 3",
            "id": "fdd000065",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000065.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000065.xml"
            }
        },
        {
            "indeovideocodec,version3": "",
            "name": "Indeo Video Codec, Version 3",
            "id": "fdd000065",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000065.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000065.xml"
            }
        },
        {
            "indeovideocodec,version3": "",
            "name": "Indeo Video Codec, Version 3",
            "id": "fdd000065",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000065.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000065.xml"
            }
        },
        {
            "indeovideocodec,version3": "",
            "name": "Indeo Video Codec, Version 3",
            "id": "fdd000065",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000065.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000065.xml"
            }
        },
        {
            "indeovideocodec,version3": "",
            "name": "Indeo Video Codec, Version 3",
            "id": "fdd000065",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000065.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000065.xml"
            }
        },
        {
            "indeovideocodec,version3": "",
            "name": "Indeo Video Codec, Version 3",
            "id": "fdd000065",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000065.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000065.xml"
            }
        },
        {
            "indeovideocodec,version3": "",
            "name": "Indeo Video Codec, Version 3",
            "id": "fdd000065",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000065.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000065.xml"
            }
        },
        {
            "sorensonvideocodec,version3": "",
            "name": "Sorenson Video Codec, Version 3",
            "id": "fdd000066",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000066.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000066.xml"
            }
        },
        {
            "sorensonvideocodec,version3": "",
            "name": "Sorenson Video Codec, Version 3",
            "id": "fdd000066",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000066.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000066.xml"
            }
        },
        {
            "sorensonvideocodec,version3": "",
            "name": "Sorenson Video Codec, Version 3",
            "id": "fdd000066",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000066.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000066.xml"
            }
        },
        {
            "sorensonvideocodec,version3": "",
            "name": "Sorenson Video Codec, Version 3",
            "id": "fdd000066",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000066.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000066.xml"
            }
        },
        {
            "sorensonvideocodec,version3": "",
            "name": "Sorenson Video Codec, Version 3",
            "id": "fdd000066",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000066.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000066.xml"
            }
        },
        {
            "sorensonvideocodec,version3": "",
            "name": "Sorenson Video Codec, Version 3",
            "id": "fdd000066",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000066.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000066.xml"
            }
        },
        {
            "sorensonvideocodec,version3": "",
            "name": "Sorenson Video Codec, Version 3",
            "id": "fdd000066",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000066.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000066.xml"
            }
        },
        {
            "sorensonvideocodec,version3": "",
            "name": "Sorenson Video Codec, Version 3",
            "id": "fdd000066",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000066.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000066.xml"
            }
        },
        {
            "asf(advancedsystemsformat)": "",
            "name": "ASF (Advanced Systems Format)",
            "id": "fdd000067",
            "filenamepattern": [
                {
                    "value": "*.asf",
                    "url": "https://www.digipres.org/formats/extensions/#*.asf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/asf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/asf"
                },
                {
                    "value": "video/x-ms-wmx",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmx"
                },
                {
                    "value": "video/x-ms-wm ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wm"
                },
                {
                    "value": "application/x-mplayer2 ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mplayer2"
                },
                {
                    "value": "application/vnd.ms-asf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-asf"
                },
                {
                    "value": "video/x-ms-asf ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf"
                },
                {
                    "value": "video/x-ms-asf-plugin ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf-plugin"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000067.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000067.xml"
            }
        },
        {
            "asf(advancedsystemsformat)": "",
            "name": "ASF (Advanced Systems Format)",
            "id": "fdd000067",
            "filenamepattern": [
                {
                    "value": "*.asf",
                    "url": "https://www.digipres.org/formats/extensions/#*.asf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/asf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/asf"
                },
                {
                    "value": "video/x-ms-wmx",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmx"
                },
                {
                    "value": "video/x-ms-wm ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wm"
                },
                {
                    "value": "application/x-mplayer2 ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mplayer2"
                },
                {
                    "value": "application/vnd.ms-asf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-asf"
                },
                {
                    "value": "video/x-ms-asf ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf"
                },
                {
                    "value": "video/x-ms-asf-plugin ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf-plugin"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000067.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000067.xml"
            }
        },
        {
            "asf(advancedsystemsformat)": "",
            "name": "ASF (Advanced Systems Format)",
            "id": "fdd000067",
            "filenamepattern": [
                {
                    "value": "*.asf",
                    "url": "https://www.digipres.org/formats/extensions/#*.asf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/asf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/asf"
                },
                {
                    "value": "video/x-ms-wmx",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmx"
                },
                {
                    "value": "video/x-ms-wm ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wm"
                },
                {
                    "value": "application/x-mplayer2 ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mplayer2"
                },
                {
                    "value": "application/vnd.ms-asf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-asf"
                },
                {
                    "value": "video/x-ms-asf ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf"
                },
                {
                    "value": "video/x-ms-asf-plugin ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf-plugin"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000067.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000067.xml"
            }
        },
        {
            "asf(advancedsystemsformat)": "",
            "name": "ASF (Advanced Systems Format)",
            "id": "fdd000067",
            "filenamepattern": [
                {
                    "value": "*.asf",
                    "url": "https://www.digipres.org/formats/extensions/#*.asf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/asf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/asf"
                },
                {
                    "value": "video/x-ms-wmx",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmx"
                },
                {
                    "value": "video/x-ms-wm ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wm"
                },
                {
                    "value": "application/x-mplayer2 ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mplayer2"
                },
                {
                    "value": "application/vnd.ms-asf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-asf"
                },
                {
                    "value": "video/x-ms-asf ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf"
                },
                {
                    "value": "video/x-ms-asf-plugin ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf-plugin"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000067.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000067.xml"
            }
        },
        {
            "asf(advancedsystemsformat)": "",
            "name": "ASF (Advanced Systems Format)",
            "id": "fdd000067",
            "filenamepattern": [
                {
                    "value": "*.asf",
                    "url": "https://www.digipres.org/formats/extensions/#*.asf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/asf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/asf"
                },
                {
                    "value": "video/x-ms-wmx",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmx"
                },
                {
                    "value": "video/x-ms-wm ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wm"
                },
                {
                    "value": "application/x-mplayer2 ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mplayer2"
                },
                {
                    "value": "application/vnd.ms-asf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-asf"
                },
                {
                    "value": "video/x-ms-asf ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf"
                },
                {
                    "value": "video/x-ms-asf-plugin ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf-plugin"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000067.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000067.xml"
            }
        },
        {
            "asf(advancedsystemsformat)": "",
            "name": "ASF (Advanced Systems Format)",
            "id": "fdd000067",
            "filenamepattern": [
                {
                    "value": "*.asf",
                    "url": "https://www.digipres.org/formats/extensions/#*.asf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/asf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/asf"
                },
                {
                    "value": "video/x-ms-wmx",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmx"
                },
                {
                    "value": "video/x-ms-wm ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wm"
                },
                {
                    "value": "application/x-mplayer2 ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mplayer2"
                },
                {
                    "value": "application/vnd.ms-asf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-asf"
                },
                {
                    "value": "video/x-ms-asf ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf"
                },
                {
                    "value": "video/x-ms-asf-plugin ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf-plugin"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000067.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000067.xml"
            }
        },
        {
            "asf(advancedsystemsformat)": "",
            "name": "ASF (Advanced Systems Format)",
            "id": "fdd000067",
            "filenamepattern": [
                {
                    "value": "*.asf",
                    "url": "https://www.digipres.org/formats/extensions/#*.asf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/asf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/asf"
                },
                {
                    "value": "video/x-ms-wmx",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmx"
                },
                {
                    "value": "video/x-ms-wm ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wm"
                },
                {
                    "value": "application/x-mplayer2 ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mplayer2"
                },
                {
                    "value": "application/vnd.ms-asf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-asf"
                },
                {
                    "value": "video/x-ms-asf ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf"
                },
                {
                    "value": "video/x-ms-asf-plugin ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf-plugin"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000067.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000067.xml"
            }
        },
        {
            "asf(advancedsystemsformat)": "",
            "name": "ASF (Advanced Systems Format)",
            "id": "fdd000067",
            "filenamepattern": [
                {
                    "value": "*.asf",
                    "url": "https://www.digipres.org/formats/extensions/#*.asf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/asf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/asf"
                },
                {
                    "value": "video/x-ms-wmx",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmx"
                },
                {
                    "value": "video/x-ms-wm ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wm"
                },
                {
                    "value": "application/x-mplayer2 ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mplayer2"
                },
                {
                    "value": "application/vnd.ms-asf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-asf"
                },
                {
                    "value": "video/x-ms-asf ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf"
                },
                {
                    "value": "video/x-ms-asf-plugin ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-asf-plugin"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000067.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000067.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withdivxcodec": "",
            "name": "AVI (Audio Video Interleaved) with DivX Codec",
            "id": "fdd000068",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                },
                {
                    "value": "*.gvi",
                    "url": "https://www.digipres.org/formats/extensions/#*.gvi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000068.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000068.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withdivxcodec": "",
            "name": "AVI (Audio Video Interleaved) with DivX Codec",
            "id": "fdd000068",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                },
                {
                    "value": "*.gvi",
                    "url": "https://www.digipres.org/formats/extensions/#*.gvi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000068.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000068.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withdivxcodec": "",
            "name": "AVI (Audio Video Interleaved) with DivX Codec",
            "id": "fdd000068",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                },
                {
                    "value": "*.gvi",
                    "url": "https://www.digipres.org/formats/extensions/#*.gvi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000068.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000068.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withdivxcodec": "",
            "name": "AVI (Audio Video Interleaved) with DivX Codec",
            "id": "fdd000068",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                },
                {
                    "value": "*.gvi",
                    "url": "https://www.digipres.org/formats/extensions/#*.gvi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000068.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000068.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withdivxcodec": "",
            "name": "AVI (Audio Video Interleaved) with DivX Codec",
            "id": "fdd000068",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                },
                {
                    "value": "*.gvi",
                    "url": "https://www.digipres.org/formats/extensions/#*.gvi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000068.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000068.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withdivxcodec": "",
            "name": "AVI (Audio Video Interleaved) with DivX Codec",
            "id": "fdd000068",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                },
                {
                    "value": "*.gvi",
                    "url": "https://www.digipres.org/formats/extensions/#*.gvi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000068.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000068.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withdivxcodec": "",
            "name": "AVI (Audio Video Interleaved) with DivX Codec",
            "id": "fdd000068",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                },
                {
                    "value": "*.gvi",
                    "url": "https://www.digipres.org/formats/extensions/#*.gvi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000068.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000068.xml"
            }
        },
        {
            "avi(audiovideointerleaved)withdivxcodec": "",
            "name": "AVI (Audio Video Interleaved) with DivX Codec",
            "id": "fdd000068",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                },
                {
                    "value": "*.gvi",
                    "url": "https://www.digipres.org/formats/extensions/#*.gvi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000068.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000068.xml"
            }
        },
        {
            "divxvideocodec,version5": "",
            "name": "DivX Video Codec, Version 5",
            "id": "fdd000069",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000069.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000069.xml"
            }
        },
        {
            "divxvideocodec,version5": "",
            "name": "DivX Video Codec, Version 5",
            "id": "fdd000069",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000069.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000069.xml"
            }
        },
        {
            "divxvideocodec,version5": "",
            "name": "DivX Video Codec, Version 5",
            "id": "fdd000069",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000069.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000069.xml"
            }
        },
        {
            "divxvideocodec,version5": "",
            "name": "DivX Video Codec, Version 5",
            "id": "fdd000069",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000069.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000069.xml"
            }
        },
        {
            "divxvideocodec,version5": "",
            "name": "DivX Video Codec, Version 5",
            "id": "fdd000069",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000069.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000069.xml"
            }
        },
        {
            "divxvideocodec,version5": "",
            "name": "DivX Video Codec, Version 5",
            "id": "fdd000069",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000069.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000069.xml"
            }
        },
        {
            "divxvideocodec,version5": "",
            "name": "DivX Video Codec, Version 5",
            "id": "fdd000069",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000069.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000069.xml"
            }
        },
        {
            "divxvideocodec,version5": "",
            "name": "DivX Video Codec, Version 5",
            "id": "fdd000069",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000069.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000069.xml"
            }
        },
        {
            "tiff/itforimagetechnology": "",
            "name": "TIFF/IT for Image Technology",
            "id": "fdd000072",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000072.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000072.xml"
            }
        },
        {
            "tiff/itforimagetechnology": "",
            "name": "TIFF/IT for Image Technology",
            "id": "fdd000072",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000072.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000072.xml"
            }
        },
        {
            "tiff/itforimagetechnology": "",
            "name": "TIFF/IT for Image Technology",
            "id": "fdd000072",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000072.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000072.xml"
            }
        },
        {
            "tiff/itforimagetechnology": "",
            "name": "TIFF/IT for Image Technology",
            "id": "fdd000072",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000072.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000072.xml"
            }
        },
        {
            "tiff/itforimagetechnology": "",
            "name": "TIFF/IT for Image Technology",
            "id": "fdd000072",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000072.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000072.xml"
            }
        },
        {
            "tiff/itforimagetechnology": "",
            "name": "TIFF/IT for Image Technology",
            "id": "fdd000072",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000072.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000072.xml"
            }
        },
        {
            "tiff/itforimagetechnology": "",
            "name": "TIFF/IT for Image Technology",
            "id": "fdd000072",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000072.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000072.xml"
            }
        },
        {
            "tiff/itforimagetechnology": "",
            "name": "TIFF/IT for Image Technology",
            "id": "fdd000072",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000072.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000072.xml"
            }
        },
        {
            "tiff/ep,iso122342:2001": "",
            "name": "TIFF/EP, ISO 12234-2:2001",
            "id": "fdd000073",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000073.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000073.xml"
            }
        },
        {
            "tiff/ep,iso122342:2001": "",
            "name": "TIFF/EP, ISO 12234-2:2001",
            "id": "fdd000073",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000073.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000073.xml"
            }
        },
        {
            "tiff/ep,iso122342:2001": "",
            "name": "TIFF/EP, ISO 12234-2:2001",
            "id": "fdd000073",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000073.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000073.xml"
            }
        },
        {
            "tiff/ep,iso122342:2001": "",
            "name": "TIFF/EP, ISO 12234-2:2001",
            "id": "fdd000073",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000073.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000073.xml"
            }
        },
        {
            "tiff/ep,iso122342:2001": "",
            "name": "TIFF/EP, ISO 12234-2:2001",
            "id": "fdd000073",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000073.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000073.xml"
            }
        },
        {
            "tiff/ep,iso122342:2001": "",
            "name": "TIFF/EP, ISO 12234-2:2001",
            "id": "fdd000073",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000073.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000073.xml"
            }
        },
        {
            "tiff/ep,iso122342:2001": "",
            "name": "TIFF/EP, ISO 12234-2:2001",
            "id": "fdd000073",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000073.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000073.xml"
            }
        },
        {
            "tiff/ep,iso122342:2001": "",
            "name": "TIFF/EP, ISO 12234-2:2001",
            "id": "fdd000073",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000073.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000073.xml"
            }
        },
        {
            "tiffbitmapwithlzwcompression": "",
            "name": "TIFF Bitmap with LZW Compression",
            "id": "fdd000074",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000074.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000074.xml"
            }
        },
        {
            "tiffbitmapwithlzwcompression": "",
            "name": "TIFF Bitmap with LZW Compression",
            "id": "fdd000074",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000074.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000074.xml"
            }
        },
        {
            "tiffbitmapwithlzwcompression": "",
            "name": "TIFF Bitmap with LZW Compression",
            "id": "fdd000074",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000074.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000074.xml"
            }
        },
        {
            "tiffbitmapwithlzwcompression": "",
            "name": "TIFF Bitmap with LZW Compression",
            "id": "fdd000074",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000074.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000074.xml"
            }
        },
        {
            "tiffbitmapwithlzwcompression": "",
            "name": "TIFF Bitmap with LZW Compression",
            "id": "fdd000074",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000074.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000074.xml"
            }
        },
        {
            "tiffbitmapwithlzwcompression": "",
            "name": "TIFF Bitmap with LZW Compression",
            "id": "fdd000074",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000074.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000074.xml"
            }
        },
        {
            "tiffbitmapwithlzwcompression": "",
            "name": "TIFF Bitmap with LZW Compression",
            "id": "fdd000074",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000074.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000074.xml"
            }
        },
        {
            "tiffbitmapwithlzwcompression": "",
            "name": "TIFF Bitmap with LZW Compression",
            "id": "fdd000074",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000074.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000074.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)": "",
            "name": "XML (Extensible Markup Language)",
            "id": "fdd000075",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000075.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000075.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)": "",
            "name": "XML (Extensible Markup Language)",
            "id": "fdd000075",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000075.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000075.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)": "",
            "name": "XML (Extensible Markup Language)",
            "id": "fdd000075",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000075.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000075.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)": "",
            "name": "XML (Extensible Markup Language)",
            "id": "fdd000075",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000075.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000075.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)": "",
            "name": "XML (Extensible Markup Language)",
            "id": "fdd000075",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000075.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000075.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)": "",
            "name": "XML (Extensible Markup Language)",
            "id": "fdd000075",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000075.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000075.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)": "",
            "name": "XML (Extensible Markup Language)",
            "id": "fdd000075",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000075.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000075.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)": "",
            "name": "XML (Extensible Markup Language)",
            "id": "fdd000075",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000075.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000075.xml"
            }
        },
        {
            "xmldocumenttypedefinition(dtd)": "",
            "name": "XML Document Type Definition (DTD)",
            "id": "fdd000076",
            "filenamepattern": [
                {
                    "value": "*.dtd",
                    "url": "https://www.digipres.org/formats/extensions/#*.dtd"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml-dtd",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml-dtd"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000076.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000076.xml"
            }
        },
        {
            "xmldocumenttypedefinition(dtd)": "",
            "name": "XML Document Type Definition (DTD)",
            "id": "fdd000076",
            "filenamepattern": [
                {
                    "value": "*.dtd",
                    "url": "https://www.digipres.org/formats/extensions/#*.dtd"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml-dtd",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml-dtd"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000076.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000076.xml"
            }
        },
        {
            "xmldocumenttypedefinition(dtd)": "",
            "name": "XML Document Type Definition (DTD)",
            "id": "fdd000076",
            "filenamepattern": [
                {
                    "value": "*.dtd",
                    "url": "https://www.digipres.org/formats/extensions/#*.dtd"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml-dtd",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml-dtd"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000076.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000076.xml"
            }
        },
        {
            "xmldocumenttypedefinition(dtd)": "",
            "name": "XML Document Type Definition (DTD)",
            "id": "fdd000076",
            "filenamepattern": [
                {
                    "value": "*.dtd",
                    "url": "https://www.digipres.org/formats/extensions/#*.dtd"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml-dtd",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml-dtd"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000076.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000076.xml"
            }
        },
        {
            "xmldocumenttypedefinition(dtd)": "",
            "name": "XML Document Type Definition (DTD)",
            "id": "fdd000076",
            "filenamepattern": [
                {
                    "value": "*.dtd",
                    "url": "https://www.digipres.org/formats/extensions/#*.dtd"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml-dtd",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml-dtd"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000076.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000076.xml"
            }
        },
        {
            "xmldocumenttypedefinition(dtd)": "",
            "name": "XML Document Type Definition (DTD)",
            "id": "fdd000076",
            "filenamepattern": [
                {
                    "value": "*.dtd",
                    "url": "https://www.digipres.org/formats/extensions/#*.dtd"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml-dtd",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml-dtd"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000076.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000076.xml"
            }
        },
        {
            "xmldocumenttypedefinition(dtd)": "",
            "name": "XML Document Type Definition (DTD)",
            "id": "fdd000076",
            "filenamepattern": [
                {
                    "value": "*.dtd",
                    "url": "https://www.digipres.org/formats/extensions/#*.dtd"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml-dtd",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml-dtd"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000076.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000076.xml"
            }
        },
        {
            "xmldocumenttypedefinition(dtd)": "",
            "name": "XML Document Type Definition (DTD)",
            "id": "fdd000076",
            "filenamepattern": [
                {
                    "value": "*.dtd",
                    "url": "https://www.digipres.org/formats/extensions/#*.dtd"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml-dtd",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml-dtd"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000076.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000076.xml"
            }
        },
        {
            "w3cxmlschema1.0": "",
            "name": "W3C XML Schema 1.0",
            "id": "fdd000077",
            "filenamepattern": [
                {
                    "value": "*.xsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xsd"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000077.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000077.xml"
            }
        },
        {
            "w3cxmlschema1.0": "",
            "name": "W3C XML Schema 1.0",
            "id": "fdd000077",
            "filenamepattern": [
                {
                    "value": "*.xsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xsd"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000077.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000077.xml"
            }
        },
        {
            "w3cxmlschema1.0": "",
            "name": "W3C XML Schema 1.0",
            "id": "fdd000077",
            "filenamepattern": [
                {
                    "value": "*.xsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xsd"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000077.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000077.xml"
            }
        },
        {
            "w3cxmlschema1.0": "",
            "name": "W3C XML Schema 1.0",
            "id": "fdd000077",
            "filenamepattern": [
                {
                    "value": "*.xsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xsd"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000077.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000077.xml"
            }
        },
        {
            "w3cxmlschema1.0": "",
            "name": "W3C XML Schema 1.0",
            "id": "fdd000077",
            "filenamepattern": [
                {
                    "value": "*.xsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xsd"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000077.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000077.xml"
            }
        },
        {
            "w3cxmlschema1.0": "",
            "name": "W3C XML Schema 1.0",
            "id": "fdd000077",
            "filenamepattern": [
                {
                    "value": "*.xsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xsd"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000077.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000077.xml"
            }
        },
        {
            "w3cxmlschema1.0": "",
            "name": "W3C XML Schema 1.0",
            "id": "fdd000077",
            "filenamepattern": [
                {
                    "value": "*.xsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xsd"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000077.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000077.xml"
            }
        },
        {
            "w3cxmlschema1.0": "",
            "name": "W3C XML Schema 1.0",
            "id": "fdd000077",
            "filenamepattern": [
                {
                    "value": "*.xsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xsd"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000077.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000077.xml"
            }
        },
        {
            "newsml(newsmarkuplanguage),version1": "",
            "name": "NewsML (News Markup Language), version 1",
            "id": "fdd000078",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000078.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000078.xml"
            }
        },
        {
            "newsml(newsmarkuplanguage),version1": "",
            "name": "NewsML (News Markup Language), version 1",
            "id": "fdd000078",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000078.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000078.xml"
            }
        },
        {
            "newsml(newsmarkuplanguage),version1": "",
            "name": "NewsML (News Markup Language), version 1",
            "id": "fdd000078",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000078.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000078.xml"
            }
        },
        {
            "newsml(newsmarkuplanguage),version1": "",
            "name": "NewsML (News Markup Language), version 1",
            "id": "fdd000078",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000078.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000078.xml"
            }
        },
        {
            "newsml(newsmarkuplanguage),version1": "",
            "name": "NewsML (News Markup Language), version 1",
            "id": "fdd000078",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000078.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000078.xml"
            }
        },
        {
            "newsml(newsmarkuplanguage),version1": "",
            "name": "NewsML (News Markup Language), version 1",
            "id": "fdd000078",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000078.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000078.xml"
            }
        },
        {
            "newsml(newsmarkuplanguage),version1": "",
            "name": "NewsML (News Markup Language), version 1",
            "id": "fdd000078",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000078.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000078.xml"
            }
        },
        {
            "newsml(newsmarkuplanguage),version1": "",
            "name": "NewsML (News Markup Language), version 1",
            "id": "fdd000078",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000078.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000078.xml"
            }
        },
        {
            "isobasemediafileformat": "",
            "name": "ISO Base Media File Format",
            "id": "fdd000079",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000079.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000079.xml"
            }
        },
        {
            "isobasemediafileformat": "",
            "name": "ISO Base Media File Format",
            "id": "fdd000079",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000079.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000079.xml"
            }
        },
        {
            "isobasemediafileformat": "",
            "name": "ISO Base Media File Format",
            "id": "fdd000079",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000079.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000079.xml"
            }
        },
        {
            "isobasemediafileformat": "",
            "name": "ISO Base Media File Format",
            "id": "fdd000079",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000079.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000079.xml"
            }
        },
        {
            "isobasemediafileformat": "",
            "name": "ISO Base Media File Format",
            "id": "fdd000079",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000079.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000079.xml"
            }
        },
        {
            "isobasemediafileformat": "",
            "name": "ISO Base Media File Format",
            "id": "fdd000079",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000079.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000079.xml"
            }
        },
        {
            "isobasemediafileformat": "",
            "name": "ISO Base Media File Format",
            "id": "fdd000079",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000079.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000079.xml"
            }
        },
        {
            "isobasemediafileformat": "",
            "name": "ISO Base Media File Format",
            "id": "fdd000079",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000079.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000079.xml"
            }
        },
        {
            "mpeg4,visualcoding(part2)(h.263)": "",
            "name": "MPEG-4, Visual Coding (Part 2) (H.263)",
            "id": "fdd000080",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000080.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000080.xml"
            }
        },
        {
            "mpeg4,visualcoding(part2)(h.263)": "",
            "name": "MPEG-4, Visual Coding (Part 2) (H.263)",
            "id": "fdd000080",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000080.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000080.xml"
            }
        },
        {
            "mpeg4,visualcoding(part2)(h.263)": "",
            "name": "MPEG-4, Visual Coding (Part 2) (H.263)",
            "id": "fdd000080",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000080.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000080.xml"
            }
        },
        {
            "mpeg4,visualcoding(part2)(h.263)": "",
            "name": "MPEG-4, Visual Coding (Part 2) (H.263)",
            "id": "fdd000080",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000080.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000080.xml"
            }
        },
        {
            "mpeg4,visualcoding(part2)(h.263)": "",
            "name": "MPEG-4, Visual Coding (Part 2) (H.263)",
            "id": "fdd000080",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000080.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000080.xml"
            }
        },
        {
            "mpeg4,visualcoding(part2)(h.263)": "",
            "name": "MPEG-4, Visual Coding (Part 2) (H.263)",
            "id": "fdd000080",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000080.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000080.xml"
            }
        },
        {
            "mpeg4,visualcoding(part2)(h.263)": "",
            "name": "MPEG-4, Visual Coding (Part 2) (H.263)",
            "id": "fdd000080",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000080.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000080.xml"
            }
        },
        {
            "mpeg4,visualcoding(part2)(h.263)": "",
            "name": "MPEG-4, Visual Coding (Part 2) (H.263)",
            "id": "fdd000080",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000080.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000080.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000081",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000081.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000081.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000081",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000081.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000081.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000081",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000081.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000081.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000081",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000081.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000081.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000081",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000081.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000081.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000081",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000081.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000081.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000081",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000081.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000081.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000081",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000081.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000081.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,baselineprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Baseline Profile",
            "id": "fdd000082",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000082.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000082.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,baselineprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Baseline Profile",
            "id": "fdd000082",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000082.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000082.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,baselineprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Baseline Profile",
            "id": "fdd000082",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000082.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000082.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,baselineprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Baseline Profile",
            "id": "fdd000082",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000082.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000082.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,baselineprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Baseline Profile",
            "id": "fdd000082",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000082.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000082.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,baselineprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Baseline Profile",
            "id": "fdd000082",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000082.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000082.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,baselineprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Baseline Profile",
            "id": "fdd000082",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000082.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000082.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,baselineprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Baseline Profile",
            "id": "fdd000082",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000082.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000082.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,mainprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Main Profile",
            "id": "fdd000083",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000083.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000083.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,mainprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Main Profile",
            "id": "fdd000083",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000083.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000083.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,mainprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Main Profile",
            "id": "fdd000083",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000083.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000083.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,mainprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Main Profile",
            "id": "fdd000083",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000083.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000083.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,mainprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Main Profile",
            "id": "fdd000083",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000083.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000083.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,mainprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Main Profile",
            "id": "fdd000083",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000083.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000083.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,mainprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Main Profile",
            "id": "fdd000083",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000083.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000083.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,mainprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Main Profile",
            "id": "fdd000083",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000083.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000083.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,extendedprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Extended Profile",
            "id": "fdd000084",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000084.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000084.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,extendedprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Extended Profile",
            "id": "fdd000084",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000084.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000084.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,extendedprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Extended Profile",
            "id": "fdd000084",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000084.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000084.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,extendedprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Extended Profile",
            "id": "fdd000084",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000084.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000084.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,extendedprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Extended Profile",
            "id": "fdd000084",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000084.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000084.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,extendedprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Extended Profile",
            "id": "fdd000084",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000084.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000084.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,extendedprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Extended Profile",
            "id": "fdd000084",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000084.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000084.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,extendedprofile": "",
            "name": "MPEG-4, Advanced Video Coding, Extended Profile",
            "id": "fdd000084",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000084.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000084.xml"
            }
        },
        {
            "indeovideocodec,version5": "",
            "name": "Indeo Video Codec, Version 5",
            "id": "fdd000085",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000085.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000085.xml"
            }
        },
        {
            "indeovideocodec,version5": "",
            "name": "Indeo Video Codec, Version 5",
            "id": "fdd000085",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000085.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000085.xml"
            }
        },
        {
            "indeovideocodec,version5": "",
            "name": "Indeo Video Codec, Version 5",
            "id": "fdd000085",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000085.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000085.xml"
            }
        },
        {
            "indeovideocodec,version5": "",
            "name": "Indeo Video Codec, Version 5",
            "id": "fdd000085",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000085.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000085.xml"
            }
        },
        {
            "indeovideocodec,version5": "",
            "name": "Indeo Video Codec, Version 5",
            "id": "fdd000085",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000085.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000085.xml"
            }
        },
        {
            "indeovideocodec,version5": "",
            "name": "Indeo Video Codec, Version 5",
            "id": "fdd000085",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000085.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000085.xml"
            }
        },
        {
            "indeovideocodec,version5": "",
            "name": "Indeo Video Codec, Version 5",
            "id": "fdd000085",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000085.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000085.xml"
            }
        },
        {
            "indeovideocodec,version5": "",
            "name": "Indeo Video Codec, Version 5",
            "id": "fdd000085",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000085.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000085.xml"
            }
        },
        {
            "quicktimevideo,applecodec": "",
            "name": "QuickTime Video, Apple Codec",
            "id": "fdd000086",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000086.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000086.xml"
            }
        },
        {
            "quicktimevideo,applecodec": "",
            "name": "QuickTime Video, Apple Codec",
            "id": "fdd000086",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000086.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000086.xml"
            }
        },
        {
            "quicktimevideo,applecodec": "",
            "name": "QuickTime Video, Apple Codec",
            "id": "fdd000086",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000086.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000086.xml"
            }
        },
        {
            "quicktimevideo,applecodec": "",
            "name": "QuickTime Video, Apple Codec",
            "id": "fdd000086",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000086.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000086.xml"
            }
        },
        {
            "quicktimevideo,applecodec": "",
            "name": "QuickTime Video, Apple Codec",
            "id": "fdd000086",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000086.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000086.xml"
            }
        },
        {
            "quicktimevideo,applecodec": "",
            "name": "QuickTime Video, Apple Codec",
            "id": "fdd000086",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000086.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000086.xml"
            }
        },
        {
            "quicktimevideo,applecodec": "",
            "name": "QuickTime Video, Apple Codec",
            "id": "fdd000086",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000086.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000086.xml"
            }
        },
        {
            "quicktimevideo,applecodec": "",
            "name": "QuickTime Video, Apple Codec",
            "id": "fdd000086",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000086.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000086.xml"
            }
        },
        {
            "quicktimevideo,cinepakcodec": "",
            "name": "QuickTime Video, Cinepak Codec",
            "id": "fdd000087",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000087.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000087.xml"
            }
        },
        {
            "quicktimevideo,cinepakcodec": "",
            "name": "QuickTime Video, Cinepak Codec",
            "id": "fdd000087",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000087.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000087.xml"
            }
        },
        {
            "quicktimevideo,cinepakcodec": "",
            "name": "QuickTime Video, Cinepak Codec",
            "id": "fdd000087",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000087.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000087.xml"
            }
        },
        {
            "quicktimevideo,cinepakcodec": "",
            "name": "QuickTime Video, Cinepak Codec",
            "id": "fdd000087",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000087.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000087.xml"
            }
        },
        {
            "quicktimevideo,cinepakcodec": "",
            "name": "QuickTime Video, Cinepak Codec",
            "id": "fdd000087",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000087.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000087.xml"
            }
        },
        {
            "quicktimevideo,cinepakcodec": "",
            "name": "QuickTime Video, Cinepak Codec",
            "id": "fdd000087",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000087.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000087.xml"
            }
        },
        {
            "quicktimevideo,cinepakcodec": "",
            "name": "QuickTime Video, Cinepak Codec",
            "id": "fdd000087",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000087.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000087.xml"
            }
        },
        {
            "quicktimevideo,cinepakcodec": "",
            "name": "QuickTime Video, Cinepak Codec",
            "id": "fdd000087",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000087.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000087.xml"
            }
        },
        {
            "quicktimevideo,sorensoncodec": "",
            "name": "QuickTime Video, Sorenson Codec",
            "id": "fdd000088",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000088.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000088.xml"
            }
        },
        {
            "quicktimevideo,sorensoncodec": "",
            "name": "QuickTime Video, Sorenson Codec",
            "id": "fdd000088",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000088.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000088.xml"
            }
        },
        {
            "quicktimevideo,sorensoncodec": "",
            "name": "QuickTime Video, Sorenson Codec",
            "id": "fdd000088",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000088.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000088.xml"
            }
        },
        {
            "quicktimevideo,sorensoncodec": "",
            "name": "QuickTime Video, Sorenson Codec",
            "id": "fdd000088",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000088.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000088.xml"
            }
        },
        {
            "quicktimevideo,sorensoncodec": "",
            "name": "QuickTime Video, Sorenson Codec",
            "id": "fdd000088",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000088.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000088.xml"
            }
        },
        {
            "quicktimevideo,sorensoncodec": "",
            "name": "QuickTime Video, Sorenson Codec",
            "id": "fdd000088",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000088.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000088.xml"
            }
        },
        {
            "quicktimevideo,sorensoncodec": "",
            "name": "QuickTime Video, Sorenson Codec",
            "id": "fdd000088",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000088.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000088.xml"
            }
        },
        {
            "quicktimevideo,sorensoncodec": "",
            "name": "QuickTime Video, Sorenson Codec",
            "id": "fdd000088",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000088.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000088.xml"
            }
        },
        {
            "quicktimevideo,mjpegcodec": "",
            "name": "QuickTime Video, MJPEG Codec",
            "id": "fdd000089",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000089.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000089.xml"
            }
        },
        {
            "quicktimevideo,mjpegcodec": "",
            "name": "QuickTime Video, MJPEG Codec",
            "id": "fdd000089",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000089.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000089.xml"
            }
        },
        {
            "quicktimevideo,mjpegcodec": "",
            "name": "QuickTime Video, MJPEG Codec",
            "id": "fdd000089",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000089.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000089.xml"
            }
        },
        {
            "quicktimevideo,mjpegcodec": "",
            "name": "QuickTime Video, MJPEG Codec",
            "id": "fdd000089",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000089.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000089.xml"
            }
        },
        {
            "quicktimevideo,mjpegcodec": "",
            "name": "QuickTime Video, MJPEG Codec",
            "id": "fdd000089",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000089.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000089.xml"
            }
        },
        {
            "quicktimevideo,mjpegcodec": "",
            "name": "QuickTime Video, MJPEG Codec",
            "id": "fdd000089",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000089.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000089.xml"
            }
        },
        {
            "quicktimevideo,mjpegcodec": "",
            "name": "QuickTime Video, MJPEG Codec",
            "id": "fdd000089",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000089.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000089.xml"
            }
        },
        {
            "quicktimevideo,mjpegcodec": "",
            "name": "QuickTime Video, MJPEG Codec",
            "id": "fdd000089",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000089.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000089.xml"
            }
        },
        {
            "quicktimevideo,mpegcodec": "",
            "name": "QuickTime Video, MPEG Codec",
            "id": "fdd000090",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000090.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000090.xml"
            }
        },
        {
            "quicktimevideo,mpegcodec": "",
            "name": "QuickTime Video, MPEG Codec",
            "id": "fdd000090",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000090.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000090.xml"
            }
        },
        {
            "quicktimevideo,mpegcodec": "",
            "name": "QuickTime Video, MPEG Codec",
            "id": "fdd000090",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000090.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000090.xml"
            }
        },
        {
            "quicktimevideo,mpegcodec": "",
            "name": "QuickTime Video, MPEG Codec",
            "id": "fdd000090",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000090.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000090.xml"
            }
        },
        {
            "quicktimevideo,mpegcodec": "",
            "name": "QuickTime Video, MPEG Codec",
            "id": "fdd000090",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000090.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000090.xml"
            }
        },
        {
            "quicktimevideo,mpegcodec": "",
            "name": "QuickTime Video, MPEG Codec",
            "id": "fdd000090",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000090.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000090.xml"
            }
        },
        {
            "quicktimevideo,mpegcodec": "",
            "name": "QuickTime Video, MPEG Codec",
            "id": "fdd000090",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000090.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000090.xml"
            }
        },
        {
            "quicktimevideo,mpegcodec": "",
            "name": "QuickTime Video, MPEG Codec",
            "id": "fdd000090",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000090.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000090.xml"
            }
        },
        {
            "wmv(windowsmediavideo)fileformat": "",
            "name": "WMV (Windows Media Video) File Format",
            "id": "fdd000091",
            "filenamepattern": [
                {
                    "value": "*.wmv",
                    "url": "https://www.digipres.org/formats/extensions/#*.wmv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-ms-wmv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000091.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000091.xml"
            }
        },
        {
            "wmv(windowsmediavideo)fileformat": "",
            "name": "WMV (Windows Media Video) File Format",
            "id": "fdd000091",
            "filenamepattern": [
                {
                    "value": "*.wmv",
                    "url": "https://www.digipres.org/formats/extensions/#*.wmv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-ms-wmv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000091.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000091.xml"
            }
        },
        {
            "wmv(windowsmediavideo)fileformat": "",
            "name": "WMV (Windows Media Video) File Format",
            "id": "fdd000091",
            "filenamepattern": [
                {
                    "value": "*.wmv",
                    "url": "https://www.digipres.org/formats/extensions/#*.wmv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-ms-wmv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000091.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000091.xml"
            }
        },
        {
            "wmv(windowsmediavideo)fileformat": "",
            "name": "WMV (Windows Media Video) File Format",
            "id": "fdd000091",
            "filenamepattern": [
                {
                    "value": "*.wmv",
                    "url": "https://www.digipres.org/formats/extensions/#*.wmv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-ms-wmv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000091.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000091.xml"
            }
        },
        {
            "wmv(windowsmediavideo)fileformat": "",
            "name": "WMV (Windows Media Video) File Format",
            "id": "fdd000091",
            "filenamepattern": [
                {
                    "value": "*.wmv",
                    "url": "https://www.digipres.org/formats/extensions/#*.wmv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-ms-wmv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000091.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000091.xml"
            }
        },
        {
            "wmv(windowsmediavideo)fileformat": "",
            "name": "WMV (Windows Media Video) File Format",
            "id": "fdd000091",
            "filenamepattern": [
                {
                    "value": "*.wmv",
                    "url": "https://www.digipres.org/formats/extensions/#*.wmv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-ms-wmv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000091.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000091.xml"
            }
        },
        {
            "wmv(windowsmediavideo)fileformat": "",
            "name": "WMV (Windows Media Video) File Format",
            "id": "fdd000091",
            "filenamepattern": [
                {
                    "value": "*.wmv",
                    "url": "https://www.digipres.org/formats/extensions/#*.wmv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-ms-wmv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000091.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000091.xml"
            }
        },
        {
            "wmv(windowsmediavideo)fileformat": "",
            "name": "WMV (Windows Media Video) File Format",
            "id": "fdd000091",
            "filenamepattern": [
                {
                    "value": "*.wmv",
                    "url": "https://www.digipres.org/formats/extensions/#*.wmv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-ms-wmv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-ms-wmv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000091.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000091.xml"
            }
        },
        {
            "windowsmedia9audiocodec": "",
            "name": "Windows Media 9 Audio Codec",
            "id": "fdd000092",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000092.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000092.xml"
            }
        },
        {
            "windowsmedia9audiocodec": "",
            "name": "Windows Media 9 Audio Codec",
            "id": "fdd000092",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000092.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000092.xml"
            }
        },
        {
            "windowsmedia9audiocodec": "",
            "name": "Windows Media 9 Audio Codec",
            "id": "fdd000092",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000092.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000092.xml"
            }
        },
        {
            "windowsmedia9audiocodec": "",
            "name": "Windows Media 9 Audio Codec",
            "id": "fdd000092",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000092.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000092.xml"
            }
        },
        {
            "windowsmedia9audiocodec": "",
            "name": "Windows Media 9 Audio Codec",
            "id": "fdd000092",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000092.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000092.xml"
            }
        },
        {
            "windowsmedia9audiocodec": "",
            "name": "Windows Media 9 Audio Codec",
            "id": "fdd000092",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000092.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000092.xml"
            }
        },
        {
            "windowsmedia9audiocodec": "",
            "name": "Windows Media 9 Audio Codec",
            "id": "fdd000092",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000092.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000092.xml"
            }
        },
        {
            "windowsmedia9audiocodec": "",
            "name": "Windows Media 9 Audio Codec",
            "id": "fdd000092",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000092.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000092.xml"
            }
        },
        {
            "windowsmedia9professionalaudiocodec": "",
            "name": "Windows Media 9 Professional Audio Codec",
            "id": "fdd000093",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000093.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000093.xml"
            }
        },
        {
            "windowsmedia9professionalaudiocodec": "",
            "name": "Windows Media 9 Professional Audio Codec",
            "id": "fdd000093",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000093.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000093.xml"
            }
        },
        {
            "windowsmedia9professionalaudiocodec": "",
            "name": "Windows Media 9 Professional Audio Codec",
            "id": "fdd000093",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000093.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000093.xml"
            }
        },
        {
            "windowsmedia9professionalaudiocodec": "",
            "name": "Windows Media 9 Professional Audio Codec",
            "id": "fdd000093",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000093.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000093.xml"
            }
        },
        {
            "windowsmedia9professionalaudiocodec": "",
            "name": "Windows Media 9 Professional Audio Codec",
            "id": "fdd000093",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000093.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000093.xml"
            }
        },
        {
            "windowsmedia9professionalaudiocodec": "",
            "name": "Windows Media 9 Professional Audio Codec",
            "id": "fdd000093",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000093.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000093.xml"
            }
        },
        {
            "windowsmedia9professionalaudiocodec": "",
            "name": "Windows Media 9 Professional Audio Codec",
            "id": "fdd000093",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000093.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000093.xml"
            }
        },
        {
            "windowsmedia9professionalaudiocodec": "",
            "name": "Windows Media 9 Professional Audio Codec",
            "id": "fdd000093",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000093.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000093.xml"
            }
        },
        {
            "windowsmedia9losslessaudiocodec": "",
            "name": "Windows Media 9 Lossless Audio Codec",
            "id": "fdd000094",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000094.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000094.xml"
            }
        },
        {
            "windowsmedia9losslessaudiocodec": "",
            "name": "Windows Media 9 Lossless Audio Codec",
            "id": "fdd000094",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000094.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000094.xml"
            }
        },
        {
            "windowsmedia9losslessaudiocodec": "",
            "name": "Windows Media 9 Lossless Audio Codec",
            "id": "fdd000094",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000094.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000094.xml"
            }
        },
        {
            "windowsmedia9losslessaudiocodec": "",
            "name": "Windows Media 9 Lossless Audio Codec",
            "id": "fdd000094",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000094.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000094.xml"
            }
        },
        {
            "windowsmedia9losslessaudiocodec": "",
            "name": "Windows Media 9 Lossless Audio Codec",
            "id": "fdd000094",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000094.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000094.xml"
            }
        },
        {
            "windowsmedia9losslessaudiocodec": "",
            "name": "Windows Media 9 Lossless Audio Codec",
            "id": "fdd000094",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000094.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000094.xml"
            }
        },
        {
            "windowsmedia9losslessaudiocodec": "",
            "name": "Windows Media 9 Lossless Audio Codec",
            "id": "fdd000094",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000094.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000094.xml"
            }
        },
        {
            "windowsmedia9losslessaudiocodec": "",
            "name": "Windows Media 9 Lossless Audio Codec",
            "id": "fdd000094",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000094.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000094.xml"
            }
        },
        {
            "windowsmedia9videocodec;smptevc1": "",
            "name": "Windows Media 9 Video Codec; SMPTE VC-1",
            "id": "fdd000095",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000095.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000095.xml"
            }
        },
        {
            "windowsmedia9videocodec;smptevc1": "",
            "name": "Windows Media 9 Video Codec; SMPTE VC-1",
            "id": "fdd000095",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000095.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000095.xml"
            }
        },
        {
            "windowsmedia9videocodec;smptevc1": "",
            "name": "Windows Media 9 Video Codec; SMPTE VC-1",
            "id": "fdd000095",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000095.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000095.xml"
            }
        },
        {
            "windowsmedia9videocodec;smptevc1": "",
            "name": "Windows Media 9 Video Codec; SMPTE VC-1",
            "id": "fdd000095",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000095.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000095.xml"
            }
        },
        {
            "windowsmedia9videocodec;smptevc1": "",
            "name": "Windows Media 9 Video Codec; SMPTE VC-1",
            "id": "fdd000095",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000095.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000095.xml"
            }
        },
        {
            "windowsmedia9videocodec;smptevc1": "",
            "name": "Windows Media 9 Video Codec; SMPTE VC-1",
            "id": "fdd000095",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000095.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000095.xml"
            }
        },
        {
            "windowsmedia9videocodec;smptevc1": "",
            "name": "Windows Media 9 Video Codec; SMPTE VC-1",
            "id": "fdd000095",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000095.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000095.xml"
            }
        },
        {
            "windowsmedia9videocodec;smptevc1": "",
            "name": "Windows Media 9 Video Codec; SMPTE VC-1",
            "id": "fdd000095",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000095.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000095.xml"
            }
        },
        {
            "windowsmedia9professionalvideocodec": "",
            "name": "Windows Media 9 Professional Video Codec",
            "id": "fdd000096",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000096.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000096.xml"
            }
        },
        {
            "windowsmedia9professionalvideocodec": "",
            "name": "Windows Media 9 Professional Video Codec",
            "id": "fdd000096",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000096.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000096.xml"
            }
        },
        {
            "windowsmedia9professionalvideocodec": "",
            "name": "Windows Media 9 Professional Video Codec",
            "id": "fdd000096",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000096.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000096.xml"
            }
        },
        {
            "windowsmedia9professionalvideocodec": "",
            "name": "Windows Media 9 Professional Video Codec",
            "id": "fdd000096",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000096.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000096.xml"
            }
        },
        {
            "windowsmedia9professionalvideocodec": "",
            "name": "Windows Media 9 Professional Video Codec",
            "id": "fdd000096",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000096.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000096.xml"
            }
        },
        {
            "windowsmedia9professionalvideocodec": "",
            "name": "Windows Media 9 Professional Video Codec",
            "id": "fdd000096",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000096.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000096.xml"
            }
        },
        {
            "windowsmedia9professionalvideocodec": "",
            "name": "Windows Media 9 Professional Video Codec",
            "id": "fdd000096",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000096.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000096.xml"
            }
        },
        {
            "windowsmedia9professionalvideocodec": "",
            "name": "Windows Media 9 Professional Video Codec",
            "id": "fdd000096",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000096.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000096.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9codec": "",
            "name": "Windows Media Audio File with WMA9 Codec",
            "id": "fdd000097",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000097.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000097.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9codec": "",
            "name": "Windows Media Audio File with WMA9 Codec",
            "id": "fdd000097",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000097.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000097.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9codec": "",
            "name": "Windows Media Audio File with WMA9 Codec",
            "id": "fdd000097",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000097.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000097.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9codec": "",
            "name": "Windows Media Audio File with WMA9 Codec",
            "id": "fdd000097",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000097.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000097.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9codec": "",
            "name": "Windows Media Audio File with WMA9 Codec",
            "id": "fdd000097",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000097.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000097.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9codec": "",
            "name": "Windows Media Audio File with WMA9 Codec",
            "id": "fdd000097",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000097.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000097.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9codec": "",
            "name": "Windows Media Audio File with WMA9 Codec",
            "id": "fdd000097",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000097.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000097.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9codec": "",
            "name": "Windows Media Audio File with WMA9 Codec",
            "id": "fdd000097",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000097.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000097.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9professionalcodec": "",
            "name": "Windows Media Audio File with WMA9 Professional Codec",
            "id": "fdd000098",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000098.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000098.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9professionalcodec": "",
            "name": "Windows Media Audio File with WMA9 Professional Codec",
            "id": "fdd000098",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000098.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000098.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9professionalcodec": "",
            "name": "Windows Media Audio File with WMA9 Professional Codec",
            "id": "fdd000098",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000098.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000098.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9professionalcodec": "",
            "name": "Windows Media Audio File with WMA9 Professional Codec",
            "id": "fdd000098",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000098.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000098.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9professionalcodec": "",
            "name": "Windows Media Audio File with WMA9 Professional Codec",
            "id": "fdd000098",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000098.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000098.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9professionalcodec": "",
            "name": "Windows Media Audio File with WMA9 Professional Codec",
            "id": "fdd000098",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000098.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000098.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9professionalcodec": "",
            "name": "Windows Media Audio File with WMA9 Professional Codec",
            "id": "fdd000098",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000098.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000098.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9professionalcodec": "",
            "name": "Windows Media Audio File with WMA9 Professional Codec",
            "id": "fdd000098",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000098.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000098.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9losslesscodec": "",
            "name": "Windows Media Audio File with WMA9 Lossless Codec",
            "id": "fdd000099",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000099.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000099.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9losslesscodec": "",
            "name": "Windows Media Audio File with WMA9 Lossless Codec",
            "id": "fdd000099",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000099.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000099.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9losslesscodec": "",
            "name": "Windows Media Audio File with WMA9 Lossless Codec",
            "id": "fdd000099",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000099.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000099.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9losslesscodec": "",
            "name": "Windows Media Audio File with WMA9 Lossless Codec",
            "id": "fdd000099",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000099.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000099.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9losslesscodec": "",
            "name": "Windows Media Audio File with WMA9 Lossless Codec",
            "id": "fdd000099",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000099.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000099.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9losslesscodec": "",
            "name": "Windows Media Audio File with WMA9 Lossless Codec",
            "id": "fdd000099",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000099.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000099.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9losslesscodec": "",
            "name": "Windows Media Audio File with WMA9 Lossless Codec",
            "id": "fdd000099",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000099.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000099.xml"
            }
        },
        {
            "windowsmediaaudiofilewithwma9losslesscodec": "",
            "name": "Windows Media Audio File with WMA9 Lossless Codec",
            "id": "fdd000099",
            "filenamepattern": [
                {
                    "value": "*.wma",
                    "url": "https://www.digipres.org/formats/extensions/#*.wma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000099.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000099.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9codec": "",
            "name": "Windows Media Video File with WMV9 Codec",
            "id": "fdd000100",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000100.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000100.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9codec": "",
            "name": "Windows Media Video File with WMV9 Codec",
            "id": "fdd000100",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000100.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000100.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9codec": "",
            "name": "Windows Media Video File with WMV9 Codec",
            "id": "fdd000100",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000100.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000100.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9codec": "",
            "name": "Windows Media Video File with WMV9 Codec",
            "id": "fdd000100",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000100.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000100.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9codec": "",
            "name": "Windows Media Video File with WMV9 Codec",
            "id": "fdd000100",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000100.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000100.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9codec": "",
            "name": "Windows Media Video File with WMV9 Codec",
            "id": "fdd000100",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000100.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000100.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9codec": "",
            "name": "Windows Media Video File with WMV9 Codec",
            "id": "fdd000100",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000100.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000100.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9codec": "",
            "name": "Windows Media Video File with WMV9 Codec",
            "id": "fdd000100",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000100.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000100.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9professionalcodec": "",
            "name": "Windows Media Video File with WMV9 Professional Codec",
            "id": "fdd000101",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000101.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000101.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9professionalcodec": "",
            "name": "Windows Media Video File with WMV9 Professional Codec",
            "id": "fdd000101",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000101.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000101.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9professionalcodec": "",
            "name": "Windows Media Video File with WMV9 Professional Codec",
            "id": "fdd000101",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000101.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000101.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9professionalcodec": "",
            "name": "Windows Media Video File with WMV9 Professional Codec",
            "id": "fdd000101",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000101.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000101.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9professionalcodec": "",
            "name": "Windows Media Video File with WMV9 Professional Codec",
            "id": "fdd000101",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000101.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000101.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9professionalcodec": "",
            "name": "Windows Media Video File with WMV9 Professional Codec",
            "id": "fdd000101",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000101.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000101.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9professionalcodec": "",
            "name": "Windows Media Video File with WMV9 Professional Codec",
            "id": "fdd000101",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000101.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000101.xml"
            }
        },
        {
            "windowsmediavideofilewithwmv9professionalcodec": "",
            "name": "Windows Media Video File with WMV9 Professional Codec",
            "id": "fdd000101",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000101.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000101.xml"
            }
        },
        {
            "midisequencedata": "",
            "name": "MIDI Sequence Data",
            "id": "fdd000102",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000102.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000102.xml"
            }
        },
        {
            "midisequencedata": "",
            "name": "MIDI Sequence Data",
            "id": "fdd000102",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000102.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000102.xml"
            }
        },
        {
            "midisequencedata": "",
            "name": "MIDI Sequence Data",
            "id": "fdd000102",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000102.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000102.xml"
            }
        },
        {
            "midisequencedata": "",
            "name": "MIDI Sequence Data",
            "id": "fdd000102",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000102.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000102.xml"
            }
        },
        {
            "midisequencedata": "",
            "name": "MIDI Sequence Data",
            "id": "fdd000102",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000102.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000102.xml"
            }
        },
        {
            "midisequencedata": "",
            "name": "MIDI Sequence Data",
            "id": "fdd000102",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000102.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000102.xml"
            }
        },
        {
            "midisequencedata": "",
            "name": "MIDI Sequence Data",
            "id": "fdd000102",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000102.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000102.xml"
            }
        },
        {
            "midisequencedata": "",
            "name": "MIDI Sequence Data",
            "id": "fdd000102",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000102.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000102.xml"
            }
        },
        {
            "audible.comfileformat": "",
            "name": "Audible.Com File Format",
            "id": "fdd000103",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/audible",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/audible"
                },
                {
                    "value": "audio/x-pn-audibleaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-audibleaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000103.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000103.xml"
            }
        },
        {
            "audible.comfileformat": "",
            "name": "Audible.Com File Format",
            "id": "fdd000103",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/audible",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/audible"
                },
                {
                    "value": "audio/x-pn-audibleaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-audibleaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000103.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000103.xml"
            }
        },
        {
            "audible.comfileformat": "",
            "name": "Audible.Com File Format",
            "id": "fdd000103",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/audible",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/audible"
                },
                {
                    "value": "audio/x-pn-audibleaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-audibleaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000103.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000103.xml"
            }
        },
        {
            "audible.comfileformat": "",
            "name": "Audible.Com File Format",
            "id": "fdd000103",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/audible",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/audible"
                },
                {
                    "value": "audio/x-pn-audibleaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-audibleaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000103.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000103.xml"
            }
        },
        {
            "audible.comfileformat": "",
            "name": "Audible.Com File Format",
            "id": "fdd000103",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/audible",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/audible"
                },
                {
                    "value": "audio/x-pn-audibleaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-audibleaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000103.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000103.xml"
            }
        },
        {
            "audible.comfileformat": "",
            "name": "Audible.Com File Format",
            "id": "fdd000103",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/audible",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/audible"
                },
                {
                    "value": "audio/x-pn-audibleaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-audibleaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000103.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000103.xml"
            }
        },
        {
            "audible.comfileformat": "",
            "name": "Audible.Com File Format",
            "id": "fdd000103",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/audible",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/audible"
                },
                {
                    "value": "audio/x-pn-audibleaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-audibleaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000103.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000103.xml"
            }
        },
        {
            "audible.comfileformat": "",
            "name": "Audible.Com File Format",
            "id": "fdd000103",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/audible",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/audible"
                },
                {
                    "value": "audio/x-pn-audibleaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-audibleaudio"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000103.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000103.xml"
            }
        },
        {
            "audible.commp3": "",
            "name": "Audible.Com MP3",
            "id": "fdd000104",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000104.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000104.xml"
            }
        },
        {
            "audible.commp3": "",
            "name": "Audible.Com MP3",
            "id": "fdd000104",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000104.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000104.xml"
            }
        },
        {
            "audible.commp3": "",
            "name": "Audible.Com MP3",
            "id": "fdd000104",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000104.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000104.xml"
            }
        },
        {
            "audible.commp3": "",
            "name": "Audible.Com MP3",
            "id": "fdd000104",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000104.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000104.xml"
            }
        },
        {
            "audible.commp3": "",
            "name": "Audible.Com MP3",
            "id": "fdd000104",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000104.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000104.xml"
            }
        },
        {
            "audible.commp3": "",
            "name": "Audible.Com MP3",
            "id": "fdd000104",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000104.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000104.xml"
            }
        },
        {
            "audible.commp3": "",
            "name": "Audible.Com MP3",
            "id": "fdd000104",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000104.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000104.xml"
            }
        },
        {
            "audible.commp3": "",
            "name": "Audible.Com MP3",
            "id": "fdd000104",
            "filenamepattern": [
                {
                    "value": "*.aa",
                    "url": "https://www.digipres.org/formats/extensions/#*.aa"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000104.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000104.xml"
            }
        },
        {
            "mp3fileformat": "",
            "name": "MP3 File Format",
            "id": "fdd000105",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp3"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                },
                {
                    "value": "audio/mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpg"
                },
                {
                    "value": "audio/x-mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mp3"
                },
                {
                    "value": "audio/x-mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpg"
                },
                {
                    "value": "audio/x-mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg3"
                },
                {
                    "value": "audio/mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg3"
                },
                {
                    "value": "audio/x-mpegaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpegaudio"
                },
                {
                    "value": "audio/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000105.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000105.xml"
            }
        },
        {
            "mp3fileformat": "",
            "name": "MP3 File Format",
            "id": "fdd000105",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp3"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                },
                {
                    "value": "audio/mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpg"
                },
                {
                    "value": "audio/x-mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mp3"
                },
                {
                    "value": "audio/x-mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpg"
                },
                {
                    "value": "audio/x-mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg3"
                },
                {
                    "value": "audio/mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg3"
                },
                {
                    "value": "audio/x-mpegaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpegaudio"
                },
                {
                    "value": "audio/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000105.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000105.xml"
            }
        },
        {
            "mp3fileformat": "",
            "name": "MP3 File Format",
            "id": "fdd000105",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp3"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                },
                {
                    "value": "audio/mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpg"
                },
                {
                    "value": "audio/x-mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mp3"
                },
                {
                    "value": "audio/x-mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpg"
                },
                {
                    "value": "audio/x-mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg3"
                },
                {
                    "value": "audio/mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg3"
                },
                {
                    "value": "audio/x-mpegaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpegaudio"
                },
                {
                    "value": "audio/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000105.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000105.xml"
            }
        },
        {
            "mp3fileformat": "",
            "name": "MP3 File Format",
            "id": "fdd000105",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp3"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                },
                {
                    "value": "audio/mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpg"
                },
                {
                    "value": "audio/x-mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mp3"
                },
                {
                    "value": "audio/x-mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpg"
                },
                {
                    "value": "audio/x-mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg3"
                },
                {
                    "value": "audio/mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg3"
                },
                {
                    "value": "audio/x-mpegaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpegaudio"
                },
                {
                    "value": "audio/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000105.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000105.xml"
            }
        },
        {
            "mp3fileformat": "",
            "name": "MP3 File Format",
            "id": "fdd000105",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp3"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                },
                {
                    "value": "audio/mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpg"
                },
                {
                    "value": "audio/x-mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mp3"
                },
                {
                    "value": "audio/x-mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpg"
                },
                {
                    "value": "audio/x-mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg3"
                },
                {
                    "value": "audio/mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg3"
                },
                {
                    "value": "audio/x-mpegaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpegaudio"
                },
                {
                    "value": "audio/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000105.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000105.xml"
            }
        },
        {
            "mp3fileformat": "",
            "name": "MP3 File Format",
            "id": "fdd000105",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp3"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                },
                {
                    "value": "audio/mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpg"
                },
                {
                    "value": "audio/x-mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mp3"
                },
                {
                    "value": "audio/x-mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpg"
                },
                {
                    "value": "audio/x-mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg3"
                },
                {
                    "value": "audio/mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg3"
                },
                {
                    "value": "audio/x-mpegaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpegaudio"
                },
                {
                    "value": "audio/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000105.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000105.xml"
            }
        },
        {
            "mp3fileformat": "",
            "name": "MP3 File Format",
            "id": "fdd000105",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp3"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                },
                {
                    "value": "audio/mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpg"
                },
                {
                    "value": "audio/x-mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mp3"
                },
                {
                    "value": "audio/x-mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpg"
                },
                {
                    "value": "audio/x-mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg3"
                },
                {
                    "value": "audio/mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg3"
                },
                {
                    "value": "audio/x-mpegaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpegaudio"
                },
                {
                    "value": "audio/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000105.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000105.xml"
            }
        },
        {
            "mp3fileformat": "",
            "name": "MP3 File Format",
            "id": "fdd000105",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp3"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                },
                {
                    "value": "audio/mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpg"
                },
                {
                    "value": "audio/x-mp3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mp3"
                },
                {
                    "value": "audio/x-mpg ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpg"
                },
                {
                    "value": "audio/x-mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg3"
                },
                {
                    "value": "audio/mpeg3 ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg3"
                },
                {
                    "value": "audio/x-mpegaudio",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpegaudio"
                },
                {
                    "value": "audio/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mpeg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000105.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000105.xml"
            }
        },
        {
            "id3metadataformp3": "",
            "name": "ID3 Metadata for MP3",
            "id": "fdd000106",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000106.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000106.xml"
            }
        },
        {
            "id3metadataformp3": "",
            "name": "ID3 Metadata for MP3",
            "id": "fdd000106",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000106.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000106.xml"
            }
        },
        {
            "id3metadataformp3": "",
            "name": "ID3 Metadata for MP3",
            "id": "fdd000106",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000106.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000106.xml"
            }
        },
        {
            "id3metadataformp3": "",
            "name": "ID3 Metadata for MP3",
            "id": "fdd000106",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000106.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000106.xml"
            }
        },
        {
            "id3metadataformp3": "",
            "name": "ID3 Metadata for MP3",
            "id": "fdd000106",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000106.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000106.xml"
            }
        },
        {
            "id3metadataformp3": "",
            "name": "ID3 Metadata for MP3",
            "id": "fdd000106",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000106.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000106.xml"
            }
        },
        {
            "id3metadataformp3": "",
            "name": "ID3 Metadata for MP3",
            "id": "fdd000106",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000106.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000106.xml"
            }
        },
        {
            "id3metadataformp3": "",
            "name": "ID3 Metadata for MP3",
            "id": "fdd000106",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000106.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000106.xml"
            }
        },
        {
            "id3metadataformp3,version1": "",
            "name": "ID3 Metadata for MP3, Version 1",
            "id": "fdd000107",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000107.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000107.xml"
            }
        },
        {
            "id3metadataformp3,version1": "",
            "name": "ID3 Metadata for MP3, Version 1",
            "id": "fdd000107",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000107.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000107.xml"
            }
        },
        {
            "id3metadataformp3,version1": "",
            "name": "ID3 Metadata for MP3, Version 1",
            "id": "fdd000107",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000107.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000107.xml"
            }
        },
        {
            "id3metadataformp3,version1": "",
            "name": "ID3 Metadata for MP3, Version 1",
            "id": "fdd000107",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000107.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000107.xml"
            }
        },
        {
            "id3metadataformp3,version1": "",
            "name": "ID3 Metadata for MP3, Version 1",
            "id": "fdd000107",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000107.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000107.xml"
            }
        },
        {
            "id3metadataformp3,version1": "",
            "name": "ID3 Metadata for MP3, Version 1",
            "id": "fdd000107",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000107.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000107.xml"
            }
        },
        {
            "id3metadataformp3,version1": "",
            "name": "ID3 Metadata for MP3, Version 1",
            "id": "fdd000107",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000107.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000107.xml"
            }
        },
        {
            "id3metadataformp3,version1": "",
            "name": "ID3 Metadata for MP3, Version 1",
            "id": "fdd000107",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000107.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000107.xml"
            }
        },
        {
            "id3metadataformp3,version2": "",
            "name": "ID3 Metadata for MP3, Version 2",
            "id": "fdd000108",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000108.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000108.xml"
            }
        },
        {
            "id3metadataformp3,version2": "",
            "name": "ID3 Metadata for MP3, Version 2",
            "id": "fdd000108",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000108.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000108.xml"
            }
        },
        {
            "id3metadataformp3,version2": "",
            "name": "ID3 Metadata for MP3, Version 2",
            "id": "fdd000108",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000108.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000108.xml"
            }
        },
        {
            "id3metadataformp3,version2": "",
            "name": "ID3 Metadata for MP3, Version 2",
            "id": "fdd000108",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000108.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000108.xml"
            }
        },
        {
            "id3metadataformp3,version2": "",
            "name": "ID3 Metadata for MP3, Version 2",
            "id": "fdd000108",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000108.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000108.xml"
            }
        },
        {
            "id3metadataformp3,version2": "",
            "name": "ID3 Metadata for MP3, Version 2",
            "id": "fdd000108",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000108.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000108.xml"
            }
        },
        {
            "id3metadataformp3,version2": "",
            "name": "ID3 Metadata for MP3, Version 2",
            "id": "fdd000108",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000108.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000108.xml"
            }
        },
        {
            "id3metadataformp3,version2": "",
            "name": "ID3 Metadata for MP3, Version 2",
            "id": "fdd000108",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000108.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000108.xml"
            }
        },
        {
            "vorbisaudiocodec,versioni": "",
            "name": "Vorbis Audio Codec, Version I",
            "id": "fdd000109",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                },
                {
                    "value": "*.oga",
                    "url": "https://www.digipres.org/formats/extensions/#*.oga"
                },
                {
                    "value": "*.sb0",
                    "url": "https://www.digipres.org/formats/extensions/#*.sb0"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/vorbis-config",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis-config"
                },
                {
                    "value": "audio/vorbis",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "audio/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000109.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000109.xml"
            }
        },
        {
            "vorbisaudiocodec,versioni": "",
            "name": "Vorbis Audio Codec, Version I",
            "id": "fdd000109",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                },
                {
                    "value": "*.oga",
                    "url": "https://www.digipres.org/formats/extensions/#*.oga"
                },
                {
                    "value": "*.sb0",
                    "url": "https://www.digipres.org/formats/extensions/#*.sb0"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/vorbis-config",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis-config"
                },
                {
                    "value": "audio/vorbis",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "audio/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000109.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000109.xml"
            }
        },
        {
            "vorbisaudiocodec,versioni": "",
            "name": "Vorbis Audio Codec, Version I",
            "id": "fdd000109",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                },
                {
                    "value": "*.oga",
                    "url": "https://www.digipres.org/formats/extensions/#*.oga"
                },
                {
                    "value": "*.sb0",
                    "url": "https://www.digipres.org/formats/extensions/#*.sb0"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/vorbis-config",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis-config"
                },
                {
                    "value": "audio/vorbis",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "audio/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000109.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000109.xml"
            }
        },
        {
            "vorbisaudiocodec,versioni": "",
            "name": "Vorbis Audio Codec, Version I",
            "id": "fdd000109",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                },
                {
                    "value": "*.oga",
                    "url": "https://www.digipres.org/formats/extensions/#*.oga"
                },
                {
                    "value": "*.sb0",
                    "url": "https://www.digipres.org/formats/extensions/#*.sb0"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/vorbis-config",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis-config"
                },
                {
                    "value": "audio/vorbis",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "audio/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000109.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000109.xml"
            }
        },
        {
            "vorbisaudiocodec,versioni": "",
            "name": "Vorbis Audio Codec, Version I",
            "id": "fdd000109",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                },
                {
                    "value": "*.oga",
                    "url": "https://www.digipres.org/formats/extensions/#*.oga"
                },
                {
                    "value": "*.sb0",
                    "url": "https://www.digipres.org/formats/extensions/#*.sb0"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/vorbis-config",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis-config"
                },
                {
                    "value": "audio/vorbis",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "audio/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000109.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000109.xml"
            }
        },
        {
            "vorbisaudiocodec,versioni": "",
            "name": "Vorbis Audio Codec, Version I",
            "id": "fdd000109",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                },
                {
                    "value": "*.oga",
                    "url": "https://www.digipres.org/formats/extensions/#*.oga"
                },
                {
                    "value": "*.sb0",
                    "url": "https://www.digipres.org/formats/extensions/#*.sb0"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/vorbis-config",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis-config"
                },
                {
                    "value": "audio/vorbis",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "audio/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000109.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000109.xml"
            }
        },
        {
            "vorbisaudiocodec,versioni": "",
            "name": "Vorbis Audio Codec, Version I",
            "id": "fdd000109",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                },
                {
                    "value": "*.oga",
                    "url": "https://www.digipres.org/formats/extensions/#*.oga"
                },
                {
                    "value": "*.sb0",
                    "url": "https://www.digipres.org/formats/extensions/#*.sb0"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/vorbis-config",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis-config"
                },
                {
                    "value": "audio/vorbis",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "audio/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000109.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000109.xml"
            }
        },
        {
            "vorbisaudiocodec,versioni": "",
            "name": "Vorbis Audio Codec, Version I",
            "id": "fdd000109",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                },
                {
                    "value": "*.oga",
                    "url": "https://www.digipres.org/formats/extensions/#*.oga"
                },
                {
                    "value": "*.sb0",
                    "url": "https://www.digipres.org/formats/extensions/#*.sb0"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/vorbis-config",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis-config"
                },
                {
                    "value": "audio/vorbis",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "audio/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000109.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000109.xml"
            }
        },
        {
            "quicktimeaudio,aaccodec": "",
            "name": "QuickTime Audio, AAC Codec",
            "id": "fdd000110",
            "filenamepattern": [
                {
                    "value": "*.m4b",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4b"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000110.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000110.xml"
            }
        },
        {
            "quicktimeaudio,aaccodec": "",
            "name": "QuickTime Audio, AAC Codec",
            "id": "fdd000110",
            "filenamepattern": [
                {
                    "value": "*.m4b",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4b"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000110.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000110.xml"
            }
        },
        {
            "quicktimeaudio,aaccodec": "",
            "name": "QuickTime Audio, AAC Codec",
            "id": "fdd000110",
            "filenamepattern": [
                {
                    "value": "*.m4b",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4b"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000110.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000110.xml"
            }
        },
        {
            "quicktimeaudio,aaccodec": "",
            "name": "QuickTime Audio, AAC Codec",
            "id": "fdd000110",
            "filenamepattern": [
                {
                    "value": "*.m4b",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4b"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000110.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000110.xml"
            }
        },
        {
            "quicktimeaudio,aaccodec": "",
            "name": "QuickTime Audio, AAC Codec",
            "id": "fdd000110",
            "filenamepattern": [
                {
                    "value": "*.m4b",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4b"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000110.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000110.xml"
            }
        },
        {
            "quicktimeaudio,aaccodec": "",
            "name": "QuickTime Audio, AAC Codec",
            "id": "fdd000110",
            "filenamepattern": [
                {
                    "value": "*.m4b",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4b"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000110.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000110.xml"
            }
        },
        {
            "quicktimeaudio,aaccodec": "",
            "name": "QuickTime Audio, AAC Codec",
            "id": "fdd000110",
            "filenamepattern": [
                {
                    "value": "*.m4b",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4b"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000110.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000110.xml"
            }
        },
        {
            "quicktimeaudio,aaccodec": "",
            "name": "QuickTime Audio, AAC Codec",
            "id": "fdd000110",
            "filenamepattern": [
                {
                    "value": "*.m4b",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4b"
                },
                {
                    "value": "*.m4p",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4p"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000110.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000110.xml"
            }
        },
        {
            "quicktimeaudio,mp3codec": "",
            "name": "QuickTime Audio, MP3 Codec",
            "id": "fdd000111",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000111.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000111.xml"
            }
        },
        {
            "quicktimeaudio,mp3codec": "",
            "name": "QuickTime Audio, MP3 Codec",
            "id": "fdd000111",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000111.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000111.xml"
            }
        },
        {
            "quicktimeaudio,mp3codec": "",
            "name": "QuickTime Audio, MP3 Codec",
            "id": "fdd000111",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000111.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000111.xml"
            }
        },
        {
            "quicktimeaudio,mp3codec": "",
            "name": "QuickTime Audio, MP3 Codec",
            "id": "fdd000111",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000111.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000111.xml"
            }
        },
        {
            "quicktimeaudio,mp3codec": "",
            "name": "QuickTime Audio, MP3 Codec",
            "id": "fdd000111",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000111.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000111.xml"
            }
        },
        {
            "quicktimeaudio,mp3codec": "",
            "name": "QuickTime Audio, MP3 Codec",
            "id": "fdd000111",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000111.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000111.xml"
            }
        },
        {
            "quicktimeaudio,mp3codec": "",
            "name": "QuickTime Audio, MP3 Codec",
            "id": "fdd000111",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000111.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000111.xml"
            }
        },
        {
            "quicktimeaudio,mp3codec": "",
            "name": "QuickTime Audio, MP3 Codec",
            "id": "fdd000111",
            "filenamepattern": [
                {
                    "value": "*.mp3",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp3"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000111.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000111.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2),audiodatainterchangeformat": "",
            "name": "Advanced Audio Coding (MPEG-2), Audio Data Interchange Format",
            "id": "fdd000112",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000112.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000112.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2),audiodatainterchangeformat": "",
            "name": "Advanced Audio Coding (MPEG-2), Audio Data Interchange Format",
            "id": "fdd000112",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000112.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000112.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2),audiodatainterchangeformat": "",
            "name": "Advanced Audio Coding (MPEG-2), Audio Data Interchange Format",
            "id": "fdd000112",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000112.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000112.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2),audiodatainterchangeformat": "",
            "name": "Advanced Audio Coding (MPEG-2), Audio Data Interchange Format",
            "id": "fdd000112",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000112.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000112.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2),audiodatainterchangeformat": "",
            "name": "Advanced Audio Coding (MPEG-2), Audio Data Interchange Format",
            "id": "fdd000112",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000112.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000112.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2),audiodatainterchangeformat": "",
            "name": "Advanced Audio Coding (MPEG-2), Audio Data Interchange Format",
            "id": "fdd000112",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000112.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000112.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2),audiodatainterchangeformat": "",
            "name": "Advanced Audio Coding (MPEG-2), Audio Data Interchange Format",
            "id": "fdd000112",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000112.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000112.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg2),audiodatainterchangeformat": "",
            "name": "Advanced Audio Coding (MPEG-2), Audio Data Interchange Format",
            "id": "fdd000112",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/aac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aac"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000112.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000112.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg4)": "",
            "name": "Advanced Audio Coding (MPEG-4)",
            "id": "fdd000114",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000114.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000114.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg4)": "",
            "name": "Advanced Audio Coding (MPEG-4)",
            "id": "fdd000114",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000114.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000114.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg4)": "",
            "name": "Advanced Audio Coding (MPEG-4)",
            "id": "fdd000114",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000114.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000114.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg4)": "",
            "name": "Advanced Audio Coding (MPEG-4)",
            "id": "fdd000114",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000114.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000114.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg4)": "",
            "name": "Advanced Audio Coding (MPEG-4)",
            "id": "fdd000114",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000114.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000114.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg4)": "",
            "name": "Advanced Audio Coding (MPEG-4)",
            "id": "fdd000114",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000114.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000114.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg4)": "",
            "name": "Advanced Audio Coding (MPEG-4)",
            "id": "fdd000114",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000114.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000114.xml"
            }
        },
        {
            "advancedaudiocoding(mpeg4)": "",
            "name": "Advanced Audio Coding (MPEG-4)",
            "id": "fdd000114",
            "filenamepattern": [
                {
                    "value": "*.aac",
                    "url": "https://www.digipres.org/formats/extensions/#*.aac"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000114.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000114.xml"
            }
        },
        {
            "iff(eainterchangefileformat1985)": "",
            "name": "IFF (EA Interchange File Format 1985)",
            "id": "fdd000115",
            "filenamepattern": [
                {
                    "value": "*.iff",
                    "url": "https://www.digipres.org/formats/extensions/#*.iff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000115.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000115.xml"
            }
        },
        {
            "iff(eainterchangefileformat1985)": "",
            "name": "IFF (EA Interchange File Format 1985)",
            "id": "fdd000115",
            "filenamepattern": [
                {
                    "value": "*.iff",
                    "url": "https://www.digipres.org/formats/extensions/#*.iff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000115.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000115.xml"
            }
        },
        {
            "iff(eainterchangefileformat1985)": "",
            "name": "IFF (EA Interchange File Format 1985)",
            "id": "fdd000115",
            "filenamepattern": [
                {
                    "value": "*.iff",
                    "url": "https://www.digipres.org/formats/extensions/#*.iff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000115.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000115.xml"
            }
        },
        {
            "iff(eainterchangefileformat1985)": "",
            "name": "IFF (EA Interchange File Format 1985)",
            "id": "fdd000115",
            "filenamepattern": [
                {
                    "value": "*.iff",
                    "url": "https://www.digipres.org/formats/extensions/#*.iff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000115.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000115.xml"
            }
        },
        {
            "iff(eainterchangefileformat1985)": "",
            "name": "IFF (EA Interchange File Format 1985)",
            "id": "fdd000115",
            "filenamepattern": [
                {
                    "value": "*.iff",
                    "url": "https://www.digipres.org/formats/extensions/#*.iff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000115.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000115.xml"
            }
        },
        {
            "iff(eainterchangefileformat1985)": "",
            "name": "IFF (EA Interchange File Format 1985)",
            "id": "fdd000115",
            "filenamepattern": [
                {
                    "value": "*.iff",
                    "url": "https://www.digipres.org/formats/extensions/#*.iff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000115.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000115.xml"
            }
        },
        {
            "iff(eainterchangefileformat1985)": "",
            "name": "IFF (EA Interchange File Format 1985)",
            "id": "fdd000115",
            "filenamepattern": [
                {
                    "value": "*.iff",
                    "url": "https://www.digipres.org/formats/extensions/#*.iff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000115.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000115.xml"
            }
        },
        {
            "iff(eainterchangefileformat1985)": "",
            "name": "IFF (EA Interchange File Format 1985)",
            "id": "fdd000115",
            "filenamepattern": [
                {
                    "value": "*.iff",
                    "url": "https://www.digipres.org/formats/extensions/#*.iff"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iff",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000115.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000115.xml"
            }
        },
        {
            "aifffileformatwithlpcmaudio": "",
            "name": "AIFF File Format with LPCM Audio",
            "id": "fdd000116",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000116.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000116.xml"
            }
        },
        {
            "aifffileformatwithlpcmaudio": "",
            "name": "AIFF File Format with LPCM Audio",
            "id": "fdd000116",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000116.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000116.xml"
            }
        },
        {
            "aifffileformatwithlpcmaudio": "",
            "name": "AIFF File Format with LPCM Audio",
            "id": "fdd000116",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000116.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000116.xml"
            }
        },
        {
            "aifffileformatwithlpcmaudio": "",
            "name": "AIFF File Format with LPCM Audio",
            "id": "fdd000116",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000116.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000116.xml"
            }
        },
        {
            "aifffileformatwithlpcmaudio": "",
            "name": "AIFF File Format with LPCM Audio",
            "id": "fdd000116",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000116.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000116.xml"
            }
        },
        {
            "aifffileformatwithlpcmaudio": "",
            "name": "AIFF File Format with LPCM Audio",
            "id": "fdd000116",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000116.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000116.xml"
            }
        },
        {
            "aifffileformatwithlpcmaudio": "",
            "name": "AIFF File Format with LPCM Audio",
            "id": "fdd000116",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000116.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000116.xml"
            }
        },
        {
            "aifffileformatwithlpcmaudio": "",
            "name": "AIFF File Format with LPCM Audio",
            "id": "fdd000116",
            "filenamepattern": [
                {
                    "value": "*.aif",
                    "url": "https://www.digipres.org/formats/extensions/#*.aif"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-aiff"
                },
                {
                    "value": "audio/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/aiff"
                },
                {
                    "value": " audio/x-pn-aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-pn-aiff"
                },
                {
                    "value": " sound/aiff",
                    "url": "https://www.digipres.org/formats/mime-types/#sound/aiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000116.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000116.xml"
            }
        },
        {
            "oggvorbisaudioformat": "",
            "name": "Ogg Vorbis Audio Format",
            "id": "fdd000117",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "audio/vorbis    ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000117.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000117.xml"
            }
        },
        {
            "oggvorbisaudioformat": "",
            "name": "Ogg Vorbis Audio Format",
            "id": "fdd000117",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "audio/vorbis    ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000117.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000117.xml"
            }
        },
        {
            "oggvorbisaudioformat": "",
            "name": "Ogg Vorbis Audio Format",
            "id": "fdd000117",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "audio/vorbis    ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000117.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000117.xml"
            }
        },
        {
            "oggvorbisaudioformat": "",
            "name": "Ogg Vorbis Audio Format",
            "id": "fdd000117",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "audio/vorbis    ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000117.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000117.xml"
            }
        },
        {
            "oggvorbisaudioformat": "",
            "name": "Ogg Vorbis Audio Format",
            "id": "fdd000117",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "audio/vorbis    ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000117.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000117.xml"
            }
        },
        {
            "oggvorbisaudioformat": "",
            "name": "Ogg Vorbis Audio Format",
            "id": "fdd000117",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "audio/vorbis    ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000117.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000117.xml"
            }
        },
        {
            "oggvorbisaudioformat": "",
            "name": "Ogg Vorbis Audio Format",
            "id": "fdd000117",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "audio/vorbis    ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000117.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000117.xml"
            }
        },
        {
            "oggvorbisaudioformat": "",
            "name": "Ogg Vorbis Audio Format",
            "id": "fdd000117",
            "filenamepattern": [
                {
                    "value": "*.ogg",
                    "url": "https://www.digipres.org/formats/extensions/#*.ogg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ogg"
                },
                {
                    "value": "audio/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-ogg"
                },
                {
                    "value": "audio/vorbis    ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/vorbis"
                },
                {
                    "value": "application/x-ogg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ogg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000117.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000117.xml"
            }
        },
        {
            "downloadablesoundsformat,version1.1b": "",
            "name": "Downloadable Sounds Format, Version 1.1b",
            "id": "fdd000118",
            "filenamepattern": [
                {
                    "value": "*.dls",
                    "url": "https://www.digipres.org/formats/extensions/#*.dls"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000118.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000118.xml"
            }
        },
        {
            "downloadablesoundsformat,version1.1b": "",
            "name": "Downloadable Sounds Format, Version 1.1b",
            "id": "fdd000118",
            "filenamepattern": [
                {
                    "value": "*.dls",
                    "url": "https://www.digipres.org/formats/extensions/#*.dls"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000118.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000118.xml"
            }
        },
        {
            "downloadablesoundsformat,version1.1b": "",
            "name": "Downloadable Sounds Format, Version 1.1b",
            "id": "fdd000118",
            "filenamepattern": [
                {
                    "value": "*.dls",
                    "url": "https://www.digipres.org/formats/extensions/#*.dls"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000118.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000118.xml"
            }
        },
        {
            "downloadablesoundsformat,version1.1b": "",
            "name": "Downloadable Sounds Format, Version 1.1b",
            "id": "fdd000118",
            "filenamepattern": [
                {
                    "value": "*.dls",
                    "url": "https://www.digipres.org/formats/extensions/#*.dls"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000118.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000118.xml"
            }
        },
        {
            "downloadablesoundsformat,version1.1b": "",
            "name": "Downloadable Sounds Format, Version 1.1b",
            "id": "fdd000118",
            "filenamepattern": [
                {
                    "value": "*.dls",
                    "url": "https://www.digipres.org/formats/extensions/#*.dls"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000118.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000118.xml"
            }
        },
        {
            "downloadablesoundsformat,version1.1b": "",
            "name": "Downloadable Sounds Format, Version 1.1b",
            "id": "fdd000118",
            "filenamepattern": [
                {
                    "value": "*.dls",
                    "url": "https://www.digipres.org/formats/extensions/#*.dls"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000118.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000118.xml"
            }
        },
        {
            "downloadablesoundsformat,version1.1b": "",
            "name": "Downloadable Sounds Format, Version 1.1b",
            "id": "fdd000118",
            "filenamepattern": [
                {
                    "value": "*.dls",
                    "url": "https://www.digipres.org/formats/extensions/#*.dls"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000118.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000118.xml"
            }
        },
        {
            "downloadablesoundsformat,version1.1b": "",
            "name": "Downloadable Sounds Format, Version 1.1b",
            "id": "fdd000118",
            "filenamepattern": [
                {
                    "value": "*.dls",
                    "url": "https://www.digipres.org/formats/extensions/#*.dls"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000118.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000118.xml"
            }
        },
        {
            "standardmidifileformat": "",
            "name": "Standard MIDI File Format",
            "id": "fdd000119",
            "filenamepattern": [
                {
                    "value": "*.midi",
                    "url": "https://www.digipres.org/formats/extensions/#*.midi"
                },
                {
                    "value": "*.mid",
                    "url": "https://www.digipres.org/formats/extensions/#*.mid"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": "application/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-midi"
                },
                {
                    "value": "audio/m ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/m"
                },
                {
                    "value": "audio/midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/midi"
                },
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000119.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000119.xml"
            }
        },
        {
            "standardmidifileformat": "",
            "name": "Standard MIDI File Format",
            "id": "fdd000119",
            "filenamepattern": [
                {
                    "value": "*.midi",
                    "url": "https://www.digipres.org/formats/extensions/#*.midi"
                },
                {
                    "value": "*.mid",
                    "url": "https://www.digipres.org/formats/extensions/#*.mid"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": "application/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-midi"
                },
                {
                    "value": "audio/m ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/m"
                },
                {
                    "value": "audio/midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/midi"
                },
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000119.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000119.xml"
            }
        },
        {
            "standardmidifileformat": "",
            "name": "Standard MIDI File Format",
            "id": "fdd000119",
            "filenamepattern": [
                {
                    "value": "*.midi",
                    "url": "https://www.digipres.org/formats/extensions/#*.midi"
                },
                {
                    "value": "*.mid",
                    "url": "https://www.digipres.org/formats/extensions/#*.mid"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": "application/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-midi"
                },
                {
                    "value": "audio/m ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/m"
                },
                {
                    "value": "audio/midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/midi"
                },
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000119.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000119.xml"
            }
        },
        {
            "standardmidifileformat": "",
            "name": "Standard MIDI File Format",
            "id": "fdd000119",
            "filenamepattern": [
                {
                    "value": "*.midi",
                    "url": "https://www.digipres.org/formats/extensions/#*.midi"
                },
                {
                    "value": "*.mid",
                    "url": "https://www.digipres.org/formats/extensions/#*.mid"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": "application/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-midi"
                },
                {
                    "value": "audio/m ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/m"
                },
                {
                    "value": "audio/midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/midi"
                },
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000119.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000119.xml"
            }
        },
        {
            "standardmidifileformat": "",
            "name": "Standard MIDI File Format",
            "id": "fdd000119",
            "filenamepattern": [
                {
                    "value": "*.midi",
                    "url": "https://www.digipres.org/formats/extensions/#*.midi"
                },
                {
                    "value": "*.mid",
                    "url": "https://www.digipres.org/formats/extensions/#*.mid"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": "application/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-midi"
                },
                {
                    "value": "audio/m ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/m"
                },
                {
                    "value": "audio/midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/midi"
                },
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000119.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000119.xml"
            }
        },
        {
            "standardmidifileformat": "",
            "name": "Standard MIDI File Format",
            "id": "fdd000119",
            "filenamepattern": [
                {
                    "value": "*.midi",
                    "url": "https://www.digipres.org/formats/extensions/#*.midi"
                },
                {
                    "value": "*.mid",
                    "url": "https://www.digipres.org/formats/extensions/#*.mid"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": "application/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-midi"
                },
                {
                    "value": "audio/m ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/m"
                },
                {
                    "value": "audio/midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/midi"
                },
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000119.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000119.xml"
            }
        },
        {
            "standardmidifileformat": "",
            "name": "Standard MIDI File Format",
            "id": "fdd000119",
            "filenamepattern": [
                {
                    "value": "*.midi",
                    "url": "https://www.digipres.org/formats/extensions/#*.midi"
                },
                {
                    "value": "*.mid",
                    "url": "https://www.digipres.org/formats/extensions/#*.mid"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": "application/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-midi"
                },
                {
                    "value": "audio/m ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/m"
                },
                {
                    "value": "audio/midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/midi"
                },
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000119.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000119.xml"
            }
        },
        {
            "standardmidifileformat": "",
            "name": "Standard MIDI File Format",
            "id": "fdd000119",
            "filenamepattern": [
                {
                    "value": "*.midi",
                    "url": "https://www.digipres.org/formats/extensions/#*.midi"
                },
                {
                    "value": "*.mid",
                    "url": "https://www.digipres.org/formats/extensions/#*.mid"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-midi"
                },
                {
                    "value": "application/x-midi",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-midi"
                },
                {
                    "value": "audio/m ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/m"
                },
                {
                    "value": "audio/midi ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/midi"
                },
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000119.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000119.xml"
            }
        },
        {
            "riffbasedmidifileformat": "",
            "name": "RIFF-based MIDI File Format",
            "id": "fdd000120",
            "filenamepattern": [
                {
                    "value": "*.rmi",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmi"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000120.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000120.xml"
            }
        },
        {
            "riffbasedmidifileformat": "",
            "name": "RIFF-based MIDI File Format",
            "id": "fdd000120",
            "filenamepattern": [
                {
                    "value": "*.rmi",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmi"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000120.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000120.xml"
            }
        },
        {
            "riffbasedmidifileformat": "",
            "name": "RIFF-based MIDI File Format",
            "id": "fdd000120",
            "filenamepattern": [
                {
                    "value": "*.rmi",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmi"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000120.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000120.xml"
            }
        },
        {
            "riffbasedmidifileformat": "",
            "name": "RIFF-based MIDI File Format",
            "id": "fdd000120",
            "filenamepattern": [
                {
                    "value": "*.rmi",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmi"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000120.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000120.xml"
            }
        },
        {
            "riffbasedmidifileformat": "",
            "name": "RIFF-based MIDI File Format",
            "id": "fdd000120",
            "filenamepattern": [
                {
                    "value": "*.rmi",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmi"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000120.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000120.xml"
            }
        },
        {
            "riffbasedmidifileformat": "",
            "name": "RIFF-based MIDI File Format",
            "id": "fdd000120",
            "filenamepattern": [
                {
                    "value": "*.rmi",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmi"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000120.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000120.xml"
            }
        },
        {
            "riffbasedmidifileformat": "",
            "name": "RIFF-based MIDI File Format",
            "id": "fdd000120",
            "filenamepattern": [
                {
                    "value": "*.rmi",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmi"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000120.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000120.xml"
            }
        },
        {
            "riffbasedmidifileformat": "",
            "name": "RIFF-based MIDI File Format",
            "id": "fdd000120",
            "filenamepattern": [
                {
                    "value": "*.rmi",
                    "url": "https://www.digipres.org/formats/extensions/#*.rmi"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mid",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mid"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000120.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000120.xml"
            }
        },
        {
            "xmf,extensiblemusicfileformat,version1.0": "",
            "name": "XMF, eXtensible Music File Format, Version 1.0",
            "id": "fdd000121",
            "filenamepattern": [
                {
                    "value": "*.mxmf ",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxmf "
                },
                {
                    "value": "*.xmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mobile-xmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mobile-xmf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000121.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000121.xml"
            }
        },
        {
            "xmf,extensiblemusicfileformat,version1.0": "",
            "name": "XMF, eXtensible Music File Format, Version 1.0",
            "id": "fdd000121",
            "filenamepattern": [
                {
                    "value": "*.mxmf ",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxmf "
                },
                {
                    "value": "*.xmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mobile-xmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mobile-xmf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000121.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000121.xml"
            }
        },
        {
            "xmf,extensiblemusicfileformat,version1.0": "",
            "name": "XMF, eXtensible Music File Format, Version 1.0",
            "id": "fdd000121",
            "filenamepattern": [
                {
                    "value": "*.mxmf ",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxmf "
                },
                {
                    "value": "*.xmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mobile-xmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mobile-xmf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000121.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000121.xml"
            }
        },
        {
            "xmf,extensiblemusicfileformat,version1.0": "",
            "name": "XMF, eXtensible Music File Format, Version 1.0",
            "id": "fdd000121",
            "filenamepattern": [
                {
                    "value": "*.mxmf ",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxmf "
                },
                {
                    "value": "*.xmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mobile-xmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mobile-xmf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000121.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000121.xml"
            }
        },
        {
            "xmf,extensiblemusicfileformat,version1.0": "",
            "name": "XMF, eXtensible Music File Format, Version 1.0",
            "id": "fdd000121",
            "filenamepattern": [
                {
                    "value": "*.mxmf ",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxmf "
                },
                {
                    "value": "*.xmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mobile-xmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mobile-xmf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000121.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000121.xml"
            }
        },
        {
            "xmf,extensiblemusicfileformat,version1.0": "",
            "name": "XMF, eXtensible Music File Format, Version 1.0",
            "id": "fdd000121",
            "filenamepattern": [
                {
                    "value": "*.mxmf ",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxmf "
                },
                {
                    "value": "*.xmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mobile-xmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mobile-xmf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000121.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000121.xml"
            }
        },
        {
            "xmf,extensiblemusicfileformat,version1.0": "",
            "name": "XMF, eXtensible Music File Format, Version 1.0",
            "id": "fdd000121",
            "filenamepattern": [
                {
                    "value": "*.mxmf ",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxmf "
                },
                {
                    "value": "*.xmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mobile-xmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mobile-xmf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000121.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000121.xml"
            }
        },
        {
            "xmf,extensiblemusicfileformat,version1.0": "",
            "name": "XMF, eXtensible Music File Format, Version 1.0",
            "id": "fdd000121",
            "filenamepattern": [
                {
                    "value": "*.mxmf ",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxmf "
                },
                {
                    "value": "*.xmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mobile-xmf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mobile-xmf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000121.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000121.xml"
            }
        },
        {
            "pdf_1_4,pdfversion1.4": "",
            "name": "PDF_1_4, PDF Version 1.4",
            "id": "fdd000122",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000122.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000122.xml"
            }
        },
        {
            "pdf_1_4,pdfversion1.4": "",
            "name": "PDF_1_4, PDF Version 1.4",
            "id": "fdd000122",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000122.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000122.xml"
            }
        },
        {
            "pdf_1_4,pdfversion1.4": "",
            "name": "PDF_1_4, PDF Version 1.4",
            "id": "fdd000122",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000122.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000122.xml"
            }
        },
        {
            "pdf_1_4,pdfversion1.4": "",
            "name": "PDF_1_4, PDF Version 1.4",
            "id": "fdd000122",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000122.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000122.xml"
            }
        },
        {
            "pdf_1_4,pdfversion1.4": "",
            "name": "PDF_1_4, PDF Version 1.4",
            "id": "fdd000122",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000122.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000122.xml"
            }
        },
        {
            "pdf_1_4,pdfversion1.4": "",
            "name": "PDF_1_4, PDF Version 1.4",
            "id": "fdd000122",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000122.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000122.xml"
            }
        },
        {
            "pdf_1_4,pdfversion1.4": "",
            "name": "PDF_1_4, PDF Version 1.4",
            "id": "fdd000122",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000122.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000122.xml"
            }
        },
        {
            "pdf_1_4,pdfversion1.4": "",
            "name": "PDF_1_4, PDF Version 1.4",
            "id": "fdd000122",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000122.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000122.xml"
            }
        },
        {
            "pdf,version1.5": "",
            "name": "PDF, Version 1.5",
            "id": "fdd000123",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000123.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000123.xml"
            }
        },
        {
            "pdf,version1.5": "",
            "name": "PDF, Version 1.5",
            "id": "fdd000123",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000123.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000123.xml"
            }
        },
        {
            "pdf,version1.5": "",
            "name": "PDF, Version 1.5",
            "id": "fdd000123",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000123.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000123.xml"
            }
        },
        {
            "pdf,version1.5": "",
            "name": "PDF, Version 1.5",
            "id": "fdd000123",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000123.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000123.xml"
            }
        },
        {
            "pdf,version1.5": "",
            "name": "PDF, Version 1.5",
            "id": "fdd000123",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000123.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000123.xml"
            }
        },
        {
            "pdf,version1.5": "",
            "name": "PDF, Version 1.5",
            "id": "fdd000123",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000123.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000123.xml"
            }
        },
        {
            "pdf,version1.5": "",
            "name": "PDF, Version 1.5",
            "id": "fdd000123",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000123.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000123.xml"
            }
        },
        {
            "pdf,version1.5": "",
            "name": "PDF, Version 1.5",
            "id": "fdd000123",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000123.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000123.xml"
            }
        },
        {
            "pdf/xformatfamily(pdfforprepressgraphicsfileexchange)": "",
            "name": "PDF/X Format Family (PDF for Prepress Graphics File Exchange)",
            "id": "fdd000124",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000124.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000124.xml"
            }
        },
        {
            "pdf/xformatfamily(pdfforprepressgraphicsfileexchange)": "",
            "name": "PDF/X Format Family (PDF for Prepress Graphics File Exchange)",
            "id": "fdd000124",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000124.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000124.xml"
            }
        },
        {
            "pdf/xformatfamily(pdfforprepressgraphicsfileexchange)": "",
            "name": "PDF/X Format Family (PDF for Prepress Graphics File Exchange)",
            "id": "fdd000124",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000124.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000124.xml"
            }
        },
        {
            "pdf/xformatfamily(pdfforprepressgraphicsfileexchange)": "",
            "name": "PDF/X Format Family (PDF for Prepress Graphics File Exchange)",
            "id": "fdd000124",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000124.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000124.xml"
            }
        },
        {
            "pdf/xformatfamily(pdfforprepressgraphicsfileexchange)": "",
            "name": "PDF/X Format Family (PDF for Prepress Graphics File Exchange)",
            "id": "fdd000124",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000124.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000124.xml"
            }
        },
        {
            "pdf/xformatfamily(pdfforprepressgraphicsfileexchange)": "",
            "name": "PDF/X Format Family (PDF for Prepress Graphics File Exchange)",
            "id": "fdd000124",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000124.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000124.xml"
            }
        },
        {
            "pdf/xformatfamily(pdfforprepressgraphicsfileexchange)": "",
            "name": "PDF/X Format Family (PDF for Prepress Graphics File Exchange)",
            "id": "fdd000124",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000124.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000124.xml"
            }
        },
        {
            "pdf/xformatfamily(pdfforprepressgraphicsfileexchange)": "",
            "name": "PDF/X Format Family (PDF for Prepress Graphics File Exchange)",
            "id": "fdd000124",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000124.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000124.xml"
            }
        },
        {
            "pdf/a1,pdfforlongtermpreservation,useofpdf1.4": "",
            "name": "PDF/A-1, PDF for Long-term Preservation, Use of PDF 1.4",
            "id": "fdd000125",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000125.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000125.xml"
            }
        },
        {
            "pdf/a1,pdfforlongtermpreservation,useofpdf1.4": "",
            "name": "PDF/A-1, PDF for Long-term Preservation, Use of PDF 1.4",
            "id": "fdd000125",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000125.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000125.xml"
            }
        },
        {
            "pdf/a1,pdfforlongtermpreservation,useofpdf1.4": "",
            "name": "PDF/A-1, PDF for Long-term Preservation, Use of PDF 1.4",
            "id": "fdd000125",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000125.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000125.xml"
            }
        },
        {
            "pdf/a1,pdfforlongtermpreservation,useofpdf1.4": "",
            "name": "PDF/A-1, PDF for Long-term Preservation, Use of PDF 1.4",
            "id": "fdd000125",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000125.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000125.xml"
            }
        },
        {
            "pdf/a1,pdfforlongtermpreservation,useofpdf1.4": "",
            "name": "PDF/A-1, PDF for Long-term Preservation, Use of PDF 1.4",
            "id": "fdd000125",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000125.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000125.xml"
            }
        },
        {
            "pdf/a1,pdfforlongtermpreservation,useofpdf1.4": "",
            "name": "PDF/A-1, PDF for Long-term Preservation, Use of PDF 1.4",
            "id": "fdd000125",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000125.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000125.xml"
            }
        },
        {
            "pdf/a1,pdfforlongtermpreservation,useofpdf1.4": "",
            "name": "PDF/A-1, PDF for Long-term Preservation, Use of PDF 1.4",
            "id": "fdd000125",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000125.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000125.xml"
            }
        },
        {
            "pdf/a1,pdfforlongtermpreservation,useofpdf1.4": "",
            "name": "PDF/A-1, PDF for Long-term Preservation, Use of PDF 1.4",
            "id": "fdd000125",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000125.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000125.xml"
            }
        },
        {
            "modulemusicformats(mods)": "",
            "name": "Module Music Formats (Mods)",
            "id": "fdd000126",
            "filenamepattern": [
                {
                    "value": "*.mt2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mt2"
                },
                {
                    "value": "*.ult",
                    "url": "https://www.digipres.org/formats/extensions/#*.ult"
                },
                {
                    "value": "*.xm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xm"
                },
                {
                    "value": "*.far",
                    "url": "https://www.digipres.org/formats/extensions/#*.far"
                },
                {
                    "value": "*.s3m",
                    "url": "https://www.digipres.org/formats/extensions/#*.s3m"
                },
                {
                    "value": "*.stm",
                    "url": "https://www.digipres.org/formats/extensions/#*.stm"
                },
                {
                    "value": "*.it",
                    "url": "https://www.digipres.org/formats/extensions/#*.it"
                },
                {
                    "value": "*.mod",
                    "url": "https://www.digipres.org/formats/extensions/#*.mod"
                },
                {
                    "value": "*.mtm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtm"
                },
                {
                    "value": "*.dmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dmf"
                },
                {
                    "value": "*.wow",
                    "url": "https://www.digipres.org/formats/extensions/#*.wow"
                },
                {
                    "value": "*.669",
                    "url": "https://www.digipres.org/formats/extensions/#*.669"
                },
                {
                    "value": "*.amf",
                    "url": "https://www.digipres.org/formats/extensions/#*.amf"
                },
                {
                    "value": "*.med",
                    "url": "https://www.digipres.org/formats/extensions/#*.med"
                },
                {
                    "value": "*.okt",
                    "url": "https://www.digipres.org/formats/extensions/#*.okt"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mod"
                },
                {
                    "value": "audio/s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/s3m"
                },
                {
                    "value": "application/soundapp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/soundapp"
                },
                {
                    "value": "audio/x-s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-s3m"
                },
                {
                    "value": "audio/xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/xm"
                },
                {
                    "value": "application/playerpro",
                    "url": "https://www.digipres.org/formats/mime-types/#application/playerpro"
                },
                {
                    "value": "audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": " audio/module-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/module-xm"
                },
                {
                    "value": "audio/x-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-xm"
                },
                {
                    "value": " audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": "audio/med",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/med"
                },
                {
                    "value": "audio/it",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/it"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000126.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000126.xml"
            }
        },
        {
            "modulemusicformats(mods)": "",
            "name": "Module Music Formats (Mods)",
            "id": "fdd000126",
            "filenamepattern": [
                {
                    "value": "*.mt2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mt2"
                },
                {
                    "value": "*.ult",
                    "url": "https://www.digipres.org/formats/extensions/#*.ult"
                },
                {
                    "value": "*.xm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xm"
                },
                {
                    "value": "*.far",
                    "url": "https://www.digipres.org/formats/extensions/#*.far"
                },
                {
                    "value": "*.s3m",
                    "url": "https://www.digipres.org/formats/extensions/#*.s3m"
                },
                {
                    "value": "*.stm",
                    "url": "https://www.digipres.org/formats/extensions/#*.stm"
                },
                {
                    "value": "*.it",
                    "url": "https://www.digipres.org/formats/extensions/#*.it"
                },
                {
                    "value": "*.mod",
                    "url": "https://www.digipres.org/formats/extensions/#*.mod"
                },
                {
                    "value": "*.mtm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtm"
                },
                {
                    "value": "*.dmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dmf"
                },
                {
                    "value": "*.wow",
                    "url": "https://www.digipres.org/formats/extensions/#*.wow"
                },
                {
                    "value": "*.669",
                    "url": "https://www.digipres.org/formats/extensions/#*.669"
                },
                {
                    "value": "*.amf",
                    "url": "https://www.digipres.org/formats/extensions/#*.amf"
                },
                {
                    "value": "*.med",
                    "url": "https://www.digipres.org/formats/extensions/#*.med"
                },
                {
                    "value": "*.okt",
                    "url": "https://www.digipres.org/formats/extensions/#*.okt"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mod"
                },
                {
                    "value": "audio/s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/s3m"
                },
                {
                    "value": "application/soundapp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/soundapp"
                },
                {
                    "value": "audio/x-s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-s3m"
                },
                {
                    "value": "audio/xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/xm"
                },
                {
                    "value": "application/playerpro",
                    "url": "https://www.digipres.org/formats/mime-types/#application/playerpro"
                },
                {
                    "value": "audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": " audio/module-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/module-xm"
                },
                {
                    "value": "audio/x-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-xm"
                },
                {
                    "value": " audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": "audio/med",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/med"
                },
                {
                    "value": "audio/it",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/it"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000126.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000126.xml"
            }
        },
        {
            "modulemusicformats(mods)": "",
            "name": "Module Music Formats (Mods)",
            "id": "fdd000126",
            "filenamepattern": [
                {
                    "value": "*.mt2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mt2"
                },
                {
                    "value": "*.ult",
                    "url": "https://www.digipres.org/formats/extensions/#*.ult"
                },
                {
                    "value": "*.xm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xm"
                },
                {
                    "value": "*.far",
                    "url": "https://www.digipres.org/formats/extensions/#*.far"
                },
                {
                    "value": "*.s3m",
                    "url": "https://www.digipres.org/formats/extensions/#*.s3m"
                },
                {
                    "value": "*.stm",
                    "url": "https://www.digipres.org/formats/extensions/#*.stm"
                },
                {
                    "value": "*.it",
                    "url": "https://www.digipres.org/formats/extensions/#*.it"
                },
                {
                    "value": "*.mod",
                    "url": "https://www.digipres.org/formats/extensions/#*.mod"
                },
                {
                    "value": "*.mtm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtm"
                },
                {
                    "value": "*.dmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dmf"
                },
                {
                    "value": "*.wow",
                    "url": "https://www.digipres.org/formats/extensions/#*.wow"
                },
                {
                    "value": "*.669",
                    "url": "https://www.digipres.org/formats/extensions/#*.669"
                },
                {
                    "value": "*.amf",
                    "url": "https://www.digipres.org/formats/extensions/#*.amf"
                },
                {
                    "value": "*.med",
                    "url": "https://www.digipres.org/formats/extensions/#*.med"
                },
                {
                    "value": "*.okt",
                    "url": "https://www.digipres.org/formats/extensions/#*.okt"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mod"
                },
                {
                    "value": "audio/s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/s3m"
                },
                {
                    "value": "application/soundapp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/soundapp"
                },
                {
                    "value": "audio/x-s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-s3m"
                },
                {
                    "value": "audio/xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/xm"
                },
                {
                    "value": "application/playerpro",
                    "url": "https://www.digipres.org/formats/mime-types/#application/playerpro"
                },
                {
                    "value": "audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": " audio/module-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/module-xm"
                },
                {
                    "value": "audio/x-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-xm"
                },
                {
                    "value": " audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": "audio/med",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/med"
                },
                {
                    "value": "audio/it",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/it"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000126.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000126.xml"
            }
        },
        {
            "modulemusicformats(mods)": "",
            "name": "Module Music Formats (Mods)",
            "id": "fdd000126",
            "filenamepattern": [
                {
                    "value": "*.mt2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mt2"
                },
                {
                    "value": "*.ult",
                    "url": "https://www.digipres.org/formats/extensions/#*.ult"
                },
                {
                    "value": "*.xm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xm"
                },
                {
                    "value": "*.far",
                    "url": "https://www.digipres.org/formats/extensions/#*.far"
                },
                {
                    "value": "*.s3m",
                    "url": "https://www.digipres.org/formats/extensions/#*.s3m"
                },
                {
                    "value": "*.stm",
                    "url": "https://www.digipres.org/formats/extensions/#*.stm"
                },
                {
                    "value": "*.it",
                    "url": "https://www.digipres.org/formats/extensions/#*.it"
                },
                {
                    "value": "*.mod",
                    "url": "https://www.digipres.org/formats/extensions/#*.mod"
                },
                {
                    "value": "*.mtm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtm"
                },
                {
                    "value": "*.dmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dmf"
                },
                {
                    "value": "*.wow",
                    "url": "https://www.digipres.org/formats/extensions/#*.wow"
                },
                {
                    "value": "*.669",
                    "url": "https://www.digipres.org/formats/extensions/#*.669"
                },
                {
                    "value": "*.amf",
                    "url": "https://www.digipres.org/formats/extensions/#*.amf"
                },
                {
                    "value": "*.med",
                    "url": "https://www.digipres.org/formats/extensions/#*.med"
                },
                {
                    "value": "*.okt",
                    "url": "https://www.digipres.org/formats/extensions/#*.okt"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mod"
                },
                {
                    "value": "audio/s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/s3m"
                },
                {
                    "value": "application/soundapp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/soundapp"
                },
                {
                    "value": "audio/x-s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-s3m"
                },
                {
                    "value": "audio/xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/xm"
                },
                {
                    "value": "application/playerpro",
                    "url": "https://www.digipres.org/formats/mime-types/#application/playerpro"
                },
                {
                    "value": "audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": " audio/module-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/module-xm"
                },
                {
                    "value": "audio/x-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-xm"
                },
                {
                    "value": " audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": "audio/med",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/med"
                },
                {
                    "value": "audio/it",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/it"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000126.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000126.xml"
            }
        },
        {
            "modulemusicformats(mods)": "",
            "name": "Module Music Formats (Mods)",
            "id": "fdd000126",
            "filenamepattern": [
                {
                    "value": "*.mt2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mt2"
                },
                {
                    "value": "*.ult",
                    "url": "https://www.digipres.org/formats/extensions/#*.ult"
                },
                {
                    "value": "*.xm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xm"
                },
                {
                    "value": "*.far",
                    "url": "https://www.digipres.org/formats/extensions/#*.far"
                },
                {
                    "value": "*.s3m",
                    "url": "https://www.digipres.org/formats/extensions/#*.s3m"
                },
                {
                    "value": "*.stm",
                    "url": "https://www.digipres.org/formats/extensions/#*.stm"
                },
                {
                    "value": "*.it",
                    "url": "https://www.digipres.org/formats/extensions/#*.it"
                },
                {
                    "value": "*.mod",
                    "url": "https://www.digipres.org/formats/extensions/#*.mod"
                },
                {
                    "value": "*.mtm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtm"
                },
                {
                    "value": "*.dmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dmf"
                },
                {
                    "value": "*.wow",
                    "url": "https://www.digipres.org/formats/extensions/#*.wow"
                },
                {
                    "value": "*.669",
                    "url": "https://www.digipres.org/formats/extensions/#*.669"
                },
                {
                    "value": "*.amf",
                    "url": "https://www.digipres.org/formats/extensions/#*.amf"
                },
                {
                    "value": "*.med",
                    "url": "https://www.digipres.org/formats/extensions/#*.med"
                },
                {
                    "value": "*.okt",
                    "url": "https://www.digipres.org/formats/extensions/#*.okt"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mod"
                },
                {
                    "value": "audio/s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/s3m"
                },
                {
                    "value": "application/soundapp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/soundapp"
                },
                {
                    "value": "audio/x-s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-s3m"
                },
                {
                    "value": "audio/xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/xm"
                },
                {
                    "value": "application/playerpro",
                    "url": "https://www.digipres.org/formats/mime-types/#application/playerpro"
                },
                {
                    "value": "audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": " audio/module-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/module-xm"
                },
                {
                    "value": "audio/x-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-xm"
                },
                {
                    "value": " audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": "audio/med",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/med"
                },
                {
                    "value": "audio/it",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/it"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000126.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000126.xml"
            }
        },
        {
            "modulemusicformats(mods)": "",
            "name": "Module Music Formats (Mods)",
            "id": "fdd000126",
            "filenamepattern": [
                {
                    "value": "*.mt2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mt2"
                },
                {
                    "value": "*.ult",
                    "url": "https://www.digipres.org/formats/extensions/#*.ult"
                },
                {
                    "value": "*.xm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xm"
                },
                {
                    "value": "*.far",
                    "url": "https://www.digipres.org/formats/extensions/#*.far"
                },
                {
                    "value": "*.s3m",
                    "url": "https://www.digipres.org/formats/extensions/#*.s3m"
                },
                {
                    "value": "*.stm",
                    "url": "https://www.digipres.org/formats/extensions/#*.stm"
                },
                {
                    "value": "*.it",
                    "url": "https://www.digipres.org/formats/extensions/#*.it"
                },
                {
                    "value": "*.mod",
                    "url": "https://www.digipres.org/formats/extensions/#*.mod"
                },
                {
                    "value": "*.mtm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtm"
                },
                {
                    "value": "*.dmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dmf"
                },
                {
                    "value": "*.wow",
                    "url": "https://www.digipres.org/formats/extensions/#*.wow"
                },
                {
                    "value": "*.669",
                    "url": "https://www.digipres.org/formats/extensions/#*.669"
                },
                {
                    "value": "*.amf",
                    "url": "https://www.digipres.org/formats/extensions/#*.amf"
                },
                {
                    "value": "*.med",
                    "url": "https://www.digipres.org/formats/extensions/#*.med"
                },
                {
                    "value": "*.okt",
                    "url": "https://www.digipres.org/formats/extensions/#*.okt"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mod"
                },
                {
                    "value": "audio/s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/s3m"
                },
                {
                    "value": "application/soundapp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/soundapp"
                },
                {
                    "value": "audio/x-s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-s3m"
                },
                {
                    "value": "audio/xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/xm"
                },
                {
                    "value": "application/playerpro",
                    "url": "https://www.digipres.org/formats/mime-types/#application/playerpro"
                },
                {
                    "value": "audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": " audio/module-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/module-xm"
                },
                {
                    "value": "audio/x-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-xm"
                },
                {
                    "value": " audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": "audio/med",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/med"
                },
                {
                    "value": "audio/it",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/it"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000126.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000126.xml"
            }
        },
        {
            "modulemusicformats(mods)": "",
            "name": "Module Music Formats (Mods)",
            "id": "fdd000126",
            "filenamepattern": [
                {
                    "value": "*.mt2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mt2"
                },
                {
                    "value": "*.ult",
                    "url": "https://www.digipres.org/formats/extensions/#*.ult"
                },
                {
                    "value": "*.xm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xm"
                },
                {
                    "value": "*.far",
                    "url": "https://www.digipres.org/formats/extensions/#*.far"
                },
                {
                    "value": "*.s3m",
                    "url": "https://www.digipres.org/formats/extensions/#*.s3m"
                },
                {
                    "value": "*.stm",
                    "url": "https://www.digipres.org/formats/extensions/#*.stm"
                },
                {
                    "value": "*.it",
                    "url": "https://www.digipres.org/formats/extensions/#*.it"
                },
                {
                    "value": "*.mod",
                    "url": "https://www.digipres.org/formats/extensions/#*.mod"
                },
                {
                    "value": "*.mtm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtm"
                },
                {
                    "value": "*.dmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dmf"
                },
                {
                    "value": "*.wow",
                    "url": "https://www.digipres.org/formats/extensions/#*.wow"
                },
                {
                    "value": "*.669",
                    "url": "https://www.digipres.org/formats/extensions/#*.669"
                },
                {
                    "value": "*.amf",
                    "url": "https://www.digipres.org/formats/extensions/#*.amf"
                },
                {
                    "value": "*.med",
                    "url": "https://www.digipres.org/formats/extensions/#*.med"
                },
                {
                    "value": "*.okt",
                    "url": "https://www.digipres.org/formats/extensions/#*.okt"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mod"
                },
                {
                    "value": "audio/s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/s3m"
                },
                {
                    "value": "application/soundapp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/soundapp"
                },
                {
                    "value": "audio/x-s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-s3m"
                },
                {
                    "value": "audio/xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/xm"
                },
                {
                    "value": "application/playerpro",
                    "url": "https://www.digipres.org/formats/mime-types/#application/playerpro"
                },
                {
                    "value": "audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": " audio/module-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/module-xm"
                },
                {
                    "value": "audio/x-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-xm"
                },
                {
                    "value": " audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": "audio/med",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/med"
                },
                {
                    "value": "audio/it",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/it"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000126.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000126.xml"
            }
        },
        {
            "modulemusicformats(mods)": "",
            "name": "Module Music Formats (Mods)",
            "id": "fdd000126",
            "filenamepattern": [
                {
                    "value": "*.mt2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mt2"
                },
                {
                    "value": "*.ult",
                    "url": "https://www.digipres.org/formats/extensions/#*.ult"
                },
                {
                    "value": "*.xm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xm"
                },
                {
                    "value": "*.far",
                    "url": "https://www.digipres.org/formats/extensions/#*.far"
                },
                {
                    "value": "*.s3m",
                    "url": "https://www.digipres.org/formats/extensions/#*.s3m"
                },
                {
                    "value": "*.stm",
                    "url": "https://www.digipres.org/formats/extensions/#*.stm"
                },
                {
                    "value": "*.it",
                    "url": "https://www.digipres.org/formats/extensions/#*.it"
                },
                {
                    "value": "*.mod",
                    "url": "https://www.digipres.org/formats/extensions/#*.mod"
                },
                {
                    "value": "*.mtm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtm"
                },
                {
                    "value": "*.dmf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dmf"
                },
                {
                    "value": "*.wow",
                    "url": "https://www.digipres.org/formats/extensions/#*.wow"
                },
                {
                    "value": "*.669",
                    "url": "https://www.digipres.org/formats/extensions/#*.669"
                },
                {
                    "value": "*.amf",
                    "url": "https://www.digipres.org/formats/extensions/#*.amf"
                },
                {
                    "value": "*.med",
                    "url": "https://www.digipres.org/formats/extensions/#*.med"
                },
                {
                    "value": "*.okt",
                    "url": "https://www.digipres.org/formats/extensions/#*.okt"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mod"
                },
                {
                    "value": "audio/s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/s3m"
                },
                {
                    "value": "application/soundapp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/soundapp"
                },
                {
                    "value": "audio/x-s3m",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-s3m"
                },
                {
                    "value": "audio/xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/xm"
                },
                {
                    "value": "application/playerpro",
                    "url": "https://www.digipres.org/formats/mime-types/#application/playerpro"
                },
                {
                    "value": "audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": " audio/module-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/module-xm"
                },
                {
                    "value": "audio/x-xm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-xm"
                },
                {
                    "value": " audio/x-mod",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-mod"
                },
                {
                    "value": "audio/med",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/med"
                },
                {
                    "value": "audio/it",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/it"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000126.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000126.xml"
            }
        },
        {
            "motionjpeg2000fileformat": "",
            "name": "Motion JPEG 2000 File Format",
            "id": "fdd000127",
            "filenamepattern": [
                {
                    "value": "*.mj2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mj2"
                },
                {
                    "value": "*.mjp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mjp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mj2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mj2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000127.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000127.xml"
            }
        },
        {
            "motionjpeg2000fileformat": "",
            "name": "Motion JPEG 2000 File Format",
            "id": "fdd000127",
            "filenamepattern": [
                {
                    "value": "*.mj2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mj2"
                },
                {
                    "value": "*.mjp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mjp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mj2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mj2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000127.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000127.xml"
            }
        },
        {
            "motionjpeg2000fileformat": "",
            "name": "Motion JPEG 2000 File Format",
            "id": "fdd000127",
            "filenamepattern": [
                {
                    "value": "*.mj2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mj2"
                },
                {
                    "value": "*.mjp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mjp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mj2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mj2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000127.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000127.xml"
            }
        },
        {
            "motionjpeg2000fileformat": "",
            "name": "Motion JPEG 2000 File Format",
            "id": "fdd000127",
            "filenamepattern": [
                {
                    "value": "*.mj2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mj2"
                },
                {
                    "value": "*.mjp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mjp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mj2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mj2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000127.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000127.xml"
            }
        },
        {
            "motionjpeg2000fileformat": "",
            "name": "Motion JPEG 2000 File Format",
            "id": "fdd000127",
            "filenamepattern": [
                {
                    "value": "*.mj2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mj2"
                },
                {
                    "value": "*.mjp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mjp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mj2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mj2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000127.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000127.xml"
            }
        },
        {
            "motionjpeg2000fileformat": "",
            "name": "Motion JPEG 2000 File Format",
            "id": "fdd000127",
            "filenamepattern": [
                {
                    "value": "*.mj2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mj2"
                },
                {
                    "value": "*.mjp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mjp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mj2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mj2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000127.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000127.xml"
            }
        },
        {
            "motionjpeg2000fileformat": "",
            "name": "Motion JPEG 2000 File Format",
            "id": "fdd000127",
            "filenamepattern": [
                {
                    "value": "*.mj2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mj2"
                },
                {
                    "value": "*.mjp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mjp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mj2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mj2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000127.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000127.xml"
            }
        },
        {
            "motionjpeg2000fileformat": "",
            "name": "Motion JPEG 2000 File Format",
            "id": "fdd000127",
            "filenamepattern": [
                {
                    "value": "*.mj2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mj2"
                },
                {
                    "value": "*.mjp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mjp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mj2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mj2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000127.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000127.xml"
            }
        },
        {
            "mxfgenericcontainermappedtouncompressedimages": "",
            "name": "MXF Generic Container Mapped to Uncompressed Images",
            "id": "fdd000129",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000129.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000129.xml"
            }
        },
        {
            "mxfgenericcontainermappedtouncompressedimages": "",
            "name": "MXF Generic Container Mapped to Uncompressed Images",
            "id": "fdd000129",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000129.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000129.xml"
            }
        },
        {
            "mxfgenericcontainermappedtouncompressedimages": "",
            "name": "MXF Generic Container Mapped to Uncompressed Images",
            "id": "fdd000129",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000129.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000129.xml"
            }
        },
        {
            "mxfgenericcontainermappedtouncompressedimages": "",
            "name": "MXF Generic Container Mapped to Uncompressed Images",
            "id": "fdd000129",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000129.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000129.xml"
            }
        },
        {
            "mxfgenericcontainermappedtouncompressedimages": "",
            "name": "MXF Generic Container Mapped to Uncompressed Images",
            "id": "fdd000129",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000129.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000129.xml"
            }
        },
        {
            "mxfgenericcontainermappedtouncompressedimages": "",
            "name": "MXF Generic Container Mapped to Uncompressed Images",
            "id": "fdd000129",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000129.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000129.xml"
            }
        },
        {
            "mxfgenericcontainermappedtouncompressedimages": "",
            "name": "MXF Generic Container Mapped to Uncompressed Images",
            "id": "fdd000129",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000129.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000129.xml"
            }
        },
        {
            "mxfgenericcontainermappedtouncompressedimages": "",
            "name": "MXF Generic Container Mapped to Uncompressed Images",
            "id": "fdd000129",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000129.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000129.xml"
            }
        },
        {
            "macromediaflashswffileformat,version7": "",
            "name": "Macromedia Flash SWF File Format, Version 7",
            "id": "fdd000130",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000130.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000130.xml"
            }
        },
        {
            "macromediaflashswffileformat,version7": "",
            "name": "Macromedia Flash SWF File Format, Version 7",
            "id": "fdd000130",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000130.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000130.xml"
            }
        },
        {
            "macromediaflashswffileformat,version7": "",
            "name": "Macromedia Flash SWF File Format, Version 7",
            "id": "fdd000130",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000130.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000130.xml"
            }
        },
        {
            "macromediaflashswffileformat,version7": "",
            "name": "Macromedia Flash SWF File Format, Version 7",
            "id": "fdd000130",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000130.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000130.xml"
            }
        },
        {
            "macromediaflashswffileformat,version7": "",
            "name": "Macromedia Flash SWF File Format, Version 7",
            "id": "fdd000130",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000130.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000130.xml"
            }
        },
        {
            "macromediaflashswffileformat,version7": "",
            "name": "Macromedia Flash SWF File Format, Version 7",
            "id": "fdd000130",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000130.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000130.xml"
            }
        },
        {
            "macromediaflashswffileformat,version7": "",
            "name": "Macromedia Flash SWF File Format, Version 7",
            "id": "fdd000130",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000130.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000130.xml"
            }
        },
        {
            "macromediaflashswffileformat,version7": "",
            "name": "Macromedia Flash SWF File Format, Version 7",
            "id": "fdd000130",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000130.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000130.xml"
            }
        },
        {
            "macromediaflashflvvideofileformat": "",
            "name": "Macromedia Flash FLV Video File Format",
            "id": "fdd000131",
            "filenamepattern": [
                {
                    "value": "*.flv",
                    "url": "https://www.digipres.org/formats/extensions/#*.flv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-flv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-flv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000131.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000131.xml"
            }
        },
        {
            "macromediaflashflvvideofileformat": "",
            "name": "Macromedia Flash FLV Video File Format",
            "id": "fdd000131",
            "filenamepattern": [
                {
                    "value": "*.flv",
                    "url": "https://www.digipres.org/formats/extensions/#*.flv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-flv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-flv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000131.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000131.xml"
            }
        },
        {
            "macromediaflashflvvideofileformat": "",
            "name": "Macromedia Flash FLV Video File Format",
            "id": "fdd000131",
            "filenamepattern": [
                {
                    "value": "*.flv",
                    "url": "https://www.digipres.org/formats/extensions/#*.flv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-flv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-flv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000131.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000131.xml"
            }
        },
        {
            "macromediaflashflvvideofileformat": "",
            "name": "Macromedia Flash FLV Video File Format",
            "id": "fdd000131",
            "filenamepattern": [
                {
                    "value": "*.flv",
                    "url": "https://www.digipres.org/formats/extensions/#*.flv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-flv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-flv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000131.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000131.xml"
            }
        },
        {
            "macromediaflashflvvideofileformat": "",
            "name": "Macromedia Flash FLV Video File Format",
            "id": "fdd000131",
            "filenamepattern": [
                {
                    "value": "*.flv",
                    "url": "https://www.digipres.org/formats/extensions/#*.flv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-flv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-flv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000131.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000131.xml"
            }
        },
        {
            "macromediaflashflvvideofileformat": "",
            "name": "Macromedia Flash FLV Video File Format",
            "id": "fdd000131",
            "filenamepattern": [
                {
                    "value": "*.flv",
                    "url": "https://www.digipres.org/formats/extensions/#*.flv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-flv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-flv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000131.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000131.xml"
            }
        },
        {
            "macromediaflashflvvideofileformat": "",
            "name": "Macromedia Flash FLV Video File Format",
            "id": "fdd000131",
            "filenamepattern": [
                {
                    "value": "*.flv",
                    "url": "https://www.digipres.org/formats/extensions/#*.flv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-flv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-flv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000131.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000131.xml"
            }
        },
        {
            "macromediaflashflvvideofileformat": "",
            "name": "Macromedia Flash FLV Video File Format",
            "id": "fdd000131",
            "filenamepattern": [
                {
                    "value": "*.flv",
                    "url": "https://www.digipres.org/formats/extensions/#*.flv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-flv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-flv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000131.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000131.xml"
            }
        },
        {
            "macromediaflashflaprojectfileformat": "",
            "name": "Macromedia Flash FLA Project File Format",
            "id": "fdd000132",
            "filenamepattern": [
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.adobe.fla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.fla"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000132.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000132.xml"
            }
        },
        {
            "macromediaflashflaprojectfileformat": "",
            "name": "Macromedia Flash FLA Project File Format",
            "id": "fdd000132",
            "filenamepattern": [
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.adobe.fla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.fla"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000132.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000132.xml"
            }
        },
        {
            "macromediaflashflaprojectfileformat": "",
            "name": "Macromedia Flash FLA Project File Format",
            "id": "fdd000132",
            "filenamepattern": [
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.adobe.fla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.fla"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000132.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000132.xml"
            }
        },
        {
            "macromediaflashflaprojectfileformat": "",
            "name": "Macromedia Flash FLA Project File Format",
            "id": "fdd000132",
            "filenamepattern": [
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.adobe.fla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.fla"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000132.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000132.xml"
            }
        },
        {
            "macromediaflashflaprojectfileformat": "",
            "name": "Macromedia Flash FLA Project File Format",
            "id": "fdd000132",
            "filenamepattern": [
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.adobe.fla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.fla"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000132.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000132.xml"
            }
        },
        {
            "macromediaflashflaprojectfileformat": "",
            "name": "Macromedia Flash FLA Project File Format",
            "id": "fdd000132",
            "filenamepattern": [
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.adobe.fla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.fla"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000132.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000132.xml"
            }
        },
        {
            "macromediaflashflaprojectfileformat": "",
            "name": "Macromedia Flash FLA Project File Format",
            "id": "fdd000132",
            "filenamepattern": [
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.adobe.fla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.fla"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000132.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000132.xml"
            }
        },
        {
            "macromediaflashflaprojectfileformat": "",
            "name": "Macromedia Flash FLA Project File Format",
            "id": "fdd000132",
            "filenamepattern": [
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.adobe.fla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.fla"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000132.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000132.xml"
            }
        },
        {
            "gifgraphicsinterchangeformat,version89a": "",
            "name": "GIF Graphics Interchange Format, Version 89a",
            "id": "fdd000133",
            "filenamepattern": [
                {
                    "value": "*.gif",
                    "url": "https://www.digipres.org/formats/extensions/#*.gif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/gif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/gif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000133.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000133.xml"
            }
        },
        {
            "gifgraphicsinterchangeformat,version89a": "",
            "name": "GIF Graphics Interchange Format, Version 89a",
            "id": "fdd000133",
            "filenamepattern": [
                {
                    "value": "*.gif",
                    "url": "https://www.digipres.org/formats/extensions/#*.gif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/gif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/gif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000133.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000133.xml"
            }
        },
        {
            "gifgraphicsinterchangeformat,version89a": "",
            "name": "GIF Graphics Interchange Format, Version 89a",
            "id": "fdd000133",
            "filenamepattern": [
                {
                    "value": "*.gif",
                    "url": "https://www.digipres.org/formats/extensions/#*.gif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/gif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/gif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000133.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000133.xml"
            }
        },
        {
            "gifgraphicsinterchangeformat,version89a": "",
            "name": "GIF Graphics Interchange Format, Version 89a",
            "id": "fdd000133",
            "filenamepattern": [
                {
                    "value": "*.gif",
                    "url": "https://www.digipres.org/formats/extensions/#*.gif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/gif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/gif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000133.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000133.xml"
            }
        },
        {
            "gifgraphicsinterchangeformat,version89a": "",
            "name": "GIF Graphics Interchange Format, Version 89a",
            "id": "fdd000133",
            "filenamepattern": [
                {
                    "value": "*.gif",
                    "url": "https://www.digipres.org/formats/extensions/#*.gif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/gif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/gif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000133.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000133.xml"
            }
        },
        {
            "gifgraphicsinterchangeformat,version89a": "",
            "name": "GIF Graphics Interchange Format, Version 89a",
            "id": "fdd000133",
            "filenamepattern": [
                {
                    "value": "*.gif",
                    "url": "https://www.digipres.org/formats/extensions/#*.gif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/gif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/gif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000133.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000133.xml"
            }
        },
        {
            "gifgraphicsinterchangeformat,version89a": "",
            "name": "GIF Graphics Interchange Format, Version 89a",
            "id": "fdd000133",
            "filenamepattern": [
                {
                    "value": "*.gif",
                    "url": "https://www.digipres.org/formats/extensions/#*.gif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/gif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/gif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000133.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000133.xml"
            }
        },
        {
            "gifgraphicsinterchangeformat,version89a": "",
            "name": "GIF Graphics Interchange Format, Version 89a",
            "id": "fdd000133",
            "filenamepattern": [
                {
                    "value": "*.gif",
                    "url": "https://www.digipres.org/formats/extensions/#*.gif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/gif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/gif"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000133.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000133.xml"
            }
        },
        {
            "lzwcompressionencoding": "",
            "name": "LZW Compression Encoding",
            "id": "fdd000135",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000135.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000135.xml"
            }
        },
        {
            "lzwcompressionencoding": "",
            "name": "LZW Compression Encoding",
            "id": "fdd000135",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000135.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000135.xml"
            }
        },
        {
            "lzwcompressionencoding": "",
            "name": "LZW Compression Encoding",
            "id": "fdd000135",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000135.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000135.xml"
            }
        },
        {
            "lzwcompressionencoding": "",
            "name": "LZW Compression Encoding",
            "id": "fdd000135",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000135.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000135.xml"
            }
        },
        {
            "lzwcompressionencoding": "",
            "name": "LZW Compression Encoding",
            "id": "fdd000135",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000135.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000135.xml"
            }
        },
        {
            "lzwcompressionencoding": "",
            "name": "LZW Compression Encoding",
            "id": "fdd000135",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000135.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000135.xml"
            }
        },
        {
            "lzwcompressionencoding": "",
            "name": "LZW Compression Encoding",
            "id": "fdd000135",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000135.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000135.xml"
            }
        },
        {
            "lzwcompressionencoding": "",
            "name": "LZW Compression Encoding",
            "id": "fdd000135",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000135.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000135.xml"
            }
        },
        {
            "itutgroup4faxcompression(t.6)": "",
            "name": "ITU-T Group 4 FAX Compression (T.6)",
            "id": "fdd000136",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000136.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000136.xml"
            }
        },
        {
            "itutgroup4faxcompression(t.6)": "",
            "name": "ITU-T Group 4 FAX Compression (T.6)",
            "id": "fdd000136",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000136.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000136.xml"
            }
        },
        {
            "itutgroup4faxcompression(t.6)": "",
            "name": "ITU-T Group 4 FAX Compression (T.6)",
            "id": "fdd000136",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000136.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000136.xml"
            }
        },
        {
            "itutgroup4faxcompression(t.6)": "",
            "name": "ITU-T Group 4 FAX Compression (T.6)",
            "id": "fdd000136",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000136.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000136.xml"
            }
        },
        {
            "itutgroup4faxcompression(t.6)": "",
            "name": "ITU-T Group 4 FAX Compression (T.6)",
            "id": "fdd000136",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000136.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000136.xml"
            }
        },
        {
            "itutgroup4faxcompression(t.6)": "",
            "name": "ITU-T Group 4 FAX Compression (T.6)",
            "id": "fdd000136",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000136.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000136.xml"
            }
        },
        {
            "itutgroup4faxcompression(t.6)": "",
            "name": "ITU-T Group 4 FAX Compression (T.6)",
            "id": "fdd000136",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000136.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000136.xml"
            }
        },
        {
            "itutgroup4faxcompression(t.6)": "",
            "name": "ITU-T Group 4 FAX Compression (T.6)",
            "id": "fdd000136",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000136.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000136.xml"
            }
        },
        {
            "mpeg4fileformatforadvancedvideocoding(nonfrextextensions,part15)": "",
            "name": "MPEG-4 File Format for Advanced Video Coding (Non-FRExt Extensions, Part 15)",
            "id": "fdd000137",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000137.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000137.xml"
            }
        },
        {
            "mpeg4fileformatforadvancedvideocoding(nonfrextextensions,part15)": "",
            "name": "MPEG-4 File Format for Advanced Video Coding (Non-FRExt Extensions, Part 15)",
            "id": "fdd000137",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000137.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000137.xml"
            }
        },
        {
            "mpeg4fileformatforadvancedvideocoding(nonfrextextensions,part15)": "",
            "name": "MPEG-4 File Format for Advanced Video Coding (Non-FRExt Extensions, Part 15)",
            "id": "fdd000137",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000137.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000137.xml"
            }
        },
        {
            "mpeg4fileformatforadvancedvideocoding(nonfrextextensions,part15)": "",
            "name": "MPEG-4 File Format for Advanced Video Coding (Non-FRExt Extensions, Part 15)",
            "id": "fdd000137",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000137.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000137.xml"
            }
        },
        {
            "mpeg4fileformatforadvancedvideocoding(nonfrextextensions,part15)": "",
            "name": "MPEG-4 File Format for Advanced Video Coding (Non-FRExt Extensions, Part 15)",
            "id": "fdd000137",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000137.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000137.xml"
            }
        },
        {
            "mpeg4fileformatforadvancedvideocoding(nonfrextextensions,part15)": "",
            "name": "MPEG-4 File Format for Advanced Video Coding (Non-FRExt Extensions, Part 15)",
            "id": "fdd000137",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000137.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000137.xml"
            }
        },
        {
            "mpeg4fileformatforadvancedvideocoding(nonfrextextensions,part15)": "",
            "name": "MPEG-4 File Format for Advanced Video Coding (Non-FRExt Extensions, Part 15)",
            "id": "fdd000137",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000137.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000137.xml"
            }
        },
        {
            "mpeg4fileformatforadvancedvideocoding(nonfrextextensions,part15)": "",
            "name": "MPEG-4 File Format for Advanced Video Coding (Non-FRExt Extensions, Part 15)",
            "id": "fdd000137",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000137.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000137.xml"
            }
        },
        {
            "jpeg2000part1,corecodingsystem": "",
            "name": "JPEG 2000 Part 1, Core Coding System",
            "id": "fdd000138",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000138.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000138.xml"
            }
        },
        {
            "jpeg2000part1,corecodingsystem": "",
            "name": "JPEG 2000 Part 1, Core Coding System",
            "id": "fdd000138",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000138.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000138.xml"
            }
        },
        {
            "jpeg2000part1,corecodingsystem": "",
            "name": "JPEG 2000 Part 1, Core Coding System",
            "id": "fdd000138",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000138.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000138.xml"
            }
        },
        {
            "jpeg2000part1,corecodingsystem": "",
            "name": "JPEG 2000 Part 1, Core Coding System",
            "id": "fdd000138",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000138.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000138.xml"
            }
        },
        {
            "jpeg2000part1,corecodingsystem": "",
            "name": "JPEG 2000 Part 1, Core Coding System",
            "id": "fdd000138",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000138.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000138.xml"
            }
        },
        {
            "jpeg2000part1,corecodingsystem": "",
            "name": "JPEG 2000 Part 1, Core Coding System",
            "id": "fdd000138",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000138.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000138.xml"
            }
        },
        {
            "jpeg2000part1,corecodingsystem": "",
            "name": "JPEG 2000 Part 1, Core Coding System",
            "id": "fdd000138",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000138.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000138.xml"
            }
        },
        {
            "jpeg2000part1,corecodingsystem": "",
            "name": "JPEG 2000 Part 1, Core Coding System",
            "id": "fdd000138",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000138.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000138.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,losslesscompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossless Compression",
            "id": "fdd000139",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000139.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000139.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,losslesscompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossless Compression",
            "id": "fdd000139",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000139.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000139.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,losslesscompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossless Compression",
            "id": "fdd000139",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000139.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000139.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,losslesscompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossless Compression",
            "id": "fdd000139",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000139.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000139.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,losslesscompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossless Compression",
            "id": "fdd000139",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000139.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000139.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,losslesscompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossless Compression",
            "id": "fdd000139",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000139.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000139.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,losslesscompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossless Compression",
            "id": "fdd000139",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000139.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000139.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,losslesscompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossless Compression",
            "id": "fdd000139",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000139.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000139.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,lossycompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossy Compression",
            "id": "fdd000140",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000140.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000140.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,lossycompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossy Compression",
            "id": "fdd000140",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000140.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000140.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,lossycompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossy Compression",
            "id": "fdd000140",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000140.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000140.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,lossycompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossy Compression",
            "id": "fdd000140",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000140.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000140.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,lossycompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossy Compression",
            "id": "fdd000140",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000140.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000140.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,lossycompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossy Compression",
            "id": "fdd000140",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000140.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000140.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,lossycompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossy Compression",
            "id": "fdd000140",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000140.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000140.xml"
            }
        },
        {
            "jpeg2000part1,corecoding,lossycompression": "",
            "name": "JPEG 2000 Part 1, Core Coding, Lossy Compression",
            "id": "fdd000140",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000140.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000140.xml"
            }
        },
        {
            "jpeg2000part2,codingextensions": "",
            "name": "JPEG 2000 Part 2, Coding Extensions",
            "id": "fdd000141",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000141.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000141.xml"
            }
        },
        {
            "jpeg2000part2,codingextensions": "",
            "name": "JPEG 2000 Part 2, Coding Extensions",
            "id": "fdd000141",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000141.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000141.xml"
            }
        },
        {
            "jpeg2000part2,codingextensions": "",
            "name": "JPEG 2000 Part 2, Coding Extensions",
            "id": "fdd000141",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000141.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000141.xml"
            }
        },
        {
            "jpeg2000part2,codingextensions": "",
            "name": "JPEG 2000 Part 2, Coding Extensions",
            "id": "fdd000141",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000141.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000141.xml"
            }
        },
        {
            "jpeg2000part2,codingextensions": "",
            "name": "JPEG 2000 Part 2, Coding Extensions",
            "id": "fdd000141",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000141.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000141.xml"
            }
        },
        {
            "jpeg2000part2,codingextensions": "",
            "name": "JPEG 2000 Part 2, Coding Extensions",
            "id": "fdd000141",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000141.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000141.xml"
            }
        },
        {
            "jpeg2000part2,codingextensions": "",
            "name": "JPEG 2000 Part 2, Coding Extensions",
            "id": "fdd000141",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000141.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000141.xml"
            }
        },
        {
            "jpeg2000part2,codingextensions": "",
            "name": "JPEG 2000 Part 2, Coding Extensions",
            "id": "fdd000141",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000141.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000141.xml"
            }
        },
        {
            "aes3,digitalaudiointerfaceformat": "",
            "name": "AES3, Digital Audio Interface Format",
            "id": "fdd000142",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000142.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000142.xml"
            }
        },
        {
            "aes3,digitalaudiointerfaceformat": "",
            "name": "AES3, Digital Audio Interface Format",
            "id": "fdd000142",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000142.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000142.xml"
            }
        },
        {
            "aes3,digitalaudiointerfaceformat": "",
            "name": "AES3, Digital Audio Interface Format",
            "id": "fdd000142",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000142.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000142.xml"
            }
        },
        {
            "aes3,digitalaudiointerfaceformat": "",
            "name": "AES3, Digital Audio Interface Format",
            "id": "fdd000142",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000142.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000142.xml"
            }
        },
        {
            "aes3,digitalaudiointerfaceformat": "",
            "name": "AES3, Digital Audio Interface Format",
            "id": "fdd000142",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000142.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000142.xml"
            }
        },
        {
            "aes3,digitalaudiointerfaceformat": "",
            "name": "AES3, Digital Audio Interface Format",
            "id": "fdd000142",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000142.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000142.xml"
            }
        },
        {
            "aes3,digitalaudiointerfaceformat": "",
            "name": "AES3, Digital Audio Interface Format",
            "id": "fdd000142",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000142.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000142.xml"
            }
        },
        {
            "aes3,digitalaudiointerfaceformat": "",
            "name": "AES3, Digital Audio Interface Format",
            "id": "fdd000142",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000142.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000142.xml"
            }
        },
        {
            "jpeg2000part1(core)jp2fileformat": "",
            "name": "JPEG 2000 Part 1 (Core) jp2 File Format",
            "id": "fdd000143",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jp2",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jp2"
                },
                {
                    "value": "image/jpeg2000",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000"
                },
                {
                    "value": "image/x-jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-jpeg2000-image"
                },
                {
                    "value": "image/jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000143.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000143.xml"
            }
        },
        {
            "jpeg2000part1(core)jp2fileformat": "",
            "name": "JPEG 2000 Part 1 (Core) jp2 File Format",
            "id": "fdd000143",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jp2",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jp2"
                },
                {
                    "value": "image/jpeg2000",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000"
                },
                {
                    "value": "image/x-jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-jpeg2000-image"
                },
                {
                    "value": "image/jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000143.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000143.xml"
            }
        },
        {
            "jpeg2000part1(core)jp2fileformat": "",
            "name": "JPEG 2000 Part 1 (Core) jp2 File Format",
            "id": "fdd000143",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jp2",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jp2"
                },
                {
                    "value": "image/jpeg2000",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000"
                },
                {
                    "value": "image/x-jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-jpeg2000-image"
                },
                {
                    "value": "image/jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000143.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000143.xml"
            }
        },
        {
            "jpeg2000part1(core)jp2fileformat": "",
            "name": "JPEG 2000 Part 1 (Core) jp2 File Format",
            "id": "fdd000143",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jp2",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jp2"
                },
                {
                    "value": "image/jpeg2000",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000"
                },
                {
                    "value": "image/x-jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-jpeg2000-image"
                },
                {
                    "value": "image/jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000143.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000143.xml"
            }
        },
        {
            "jpeg2000part1(core)jp2fileformat": "",
            "name": "JPEG 2000 Part 1 (Core) jp2 File Format",
            "id": "fdd000143",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jp2",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jp2"
                },
                {
                    "value": "image/jpeg2000",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000"
                },
                {
                    "value": "image/x-jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-jpeg2000-image"
                },
                {
                    "value": "image/jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000143.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000143.xml"
            }
        },
        {
            "jpeg2000part1(core)jp2fileformat": "",
            "name": "JPEG 2000 Part 1 (Core) jp2 File Format",
            "id": "fdd000143",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jp2",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jp2"
                },
                {
                    "value": "image/jpeg2000",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000"
                },
                {
                    "value": "image/x-jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-jpeg2000-image"
                },
                {
                    "value": "image/jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000143.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000143.xml"
            }
        },
        {
            "jpeg2000part1(core)jp2fileformat": "",
            "name": "JPEG 2000 Part 1 (Core) jp2 File Format",
            "id": "fdd000143",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jp2",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jp2"
                },
                {
                    "value": "image/jpeg2000",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000"
                },
                {
                    "value": "image/x-jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-jpeg2000-image"
                },
                {
                    "value": "image/jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000143.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000143.xml"
            }
        },
        {
            "jpeg2000part1(core)jp2fileformat": "",
            "name": "JPEG 2000 Part 1 (Core) jp2 File Format",
            "id": "fdd000143",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jp2",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jp2"
                },
                {
                    "value": "image/jpeg2000",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000"
                },
                {
                    "value": "image/x-jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-jpeg2000-image"
                },
                {
                    "value": "image/jpeg2000-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg2000-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000143.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000143.xml"
            }
        },
        {
            "jpeg2000part6(compound)jpmfileformat": "",
            "name": "JPEG 2000 Part 6 (Compound) jpm File Format",
            "id": "fdd000144",
            "filenamepattern": [
                {
                    "value": "*.jpm",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000144.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000144.xml"
            }
        },
        {
            "jpeg2000part6(compound)jpmfileformat": "",
            "name": "JPEG 2000 Part 6 (Compound) jpm File Format",
            "id": "fdd000144",
            "filenamepattern": [
                {
                    "value": "*.jpm",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000144.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000144.xml"
            }
        },
        {
            "jpeg2000part6(compound)jpmfileformat": "",
            "name": "JPEG 2000 Part 6 (Compound) jpm File Format",
            "id": "fdd000144",
            "filenamepattern": [
                {
                    "value": "*.jpm",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000144.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000144.xml"
            }
        },
        {
            "jpeg2000part6(compound)jpmfileformat": "",
            "name": "JPEG 2000 Part 6 (Compound) jpm File Format",
            "id": "fdd000144",
            "filenamepattern": [
                {
                    "value": "*.jpm",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000144.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000144.xml"
            }
        },
        {
            "jpeg2000part6(compound)jpmfileformat": "",
            "name": "JPEG 2000 Part 6 (Compound) jpm File Format",
            "id": "fdd000144",
            "filenamepattern": [
                {
                    "value": "*.jpm",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000144.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000144.xml"
            }
        },
        {
            "jpeg2000part6(compound)jpmfileformat": "",
            "name": "JPEG 2000 Part 6 (Compound) jpm File Format",
            "id": "fdd000144",
            "filenamepattern": [
                {
                    "value": "*.jpm",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000144.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000144.xml"
            }
        },
        {
            "jpeg2000part6(compound)jpmfileformat": "",
            "name": "JPEG 2000 Part 6 (Compound) jpm File Format",
            "id": "fdd000144",
            "filenamepattern": [
                {
                    "value": "*.jpm",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000144.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000144.xml"
            }
        },
        {
            "jpeg2000part6(compound)jpmfileformat": "",
            "name": "JPEG 2000 Part 6 (Compound) jpm File Format",
            "id": "fdd000144",
            "filenamepattern": [
                {
                    "value": "*.jpm",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000144.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000144.xml"
            }
        },
        {
            "tiffuncompressedfilewithexifmetadata": "",
            "name": "TIFF Uncompressed File with Exif Metadata",
            "id": "fdd000145",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000145.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000145.xml"
            }
        },
        {
            "tiffuncompressedfilewithexifmetadata": "",
            "name": "TIFF Uncompressed File with Exif Metadata",
            "id": "fdd000145",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000145.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000145.xml"
            }
        },
        {
            "tiffuncompressedfilewithexifmetadata": "",
            "name": "TIFF Uncompressed File with Exif Metadata",
            "id": "fdd000145",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000145.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000145.xml"
            }
        },
        {
            "tiffuncompressedfilewithexifmetadata": "",
            "name": "TIFF Uncompressed File with Exif Metadata",
            "id": "fdd000145",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000145.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000145.xml"
            }
        },
        {
            "tiffuncompressedfilewithexifmetadata": "",
            "name": "TIFF Uncompressed File with Exif Metadata",
            "id": "fdd000145",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000145.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000145.xml"
            }
        },
        {
            "tiffuncompressedfilewithexifmetadata": "",
            "name": "TIFF Uncompressed File with Exif Metadata",
            "id": "fdd000145",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000145.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000145.xml"
            }
        },
        {
            "tiffuncompressedfilewithexifmetadata": "",
            "name": "TIFF Uncompressed File with Exif Metadata",
            "id": "fdd000145",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000145.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000145.xml"
            }
        },
        {
            "tiffuncompressedfilewithexifmetadata": "",
            "name": "TIFF Uncompressed File with Exif Metadata",
            "id": "fdd000145",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000145.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000145.xml"
            }
        },
        {
            "exifexchangeableimagefileformat,version2.2": "",
            "name": "Exif Exchangeable Image File Format, Version 2.2",
            "id": "fdd000146",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000146.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000146.xml"
            }
        },
        {
            "exifexchangeableimagefileformat,version2.2": "",
            "name": "Exif Exchangeable Image File Format, Version 2.2",
            "id": "fdd000146",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000146.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000146.xml"
            }
        },
        {
            "exifexchangeableimagefileformat,version2.2": "",
            "name": "Exif Exchangeable Image File Format, Version 2.2",
            "id": "fdd000146",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000146.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000146.xml"
            }
        },
        {
            "exifexchangeableimagefileformat,version2.2": "",
            "name": "Exif Exchangeable Image File Format, Version 2.2",
            "id": "fdd000146",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000146.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000146.xml"
            }
        },
        {
            "exifexchangeableimagefileformat,version2.2": "",
            "name": "Exif Exchangeable Image File Format, Version 2.2",
            "id": "fdd000146",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000146.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000146.xml"
            }
        },
        {
            "exifexchangeableimagefileformat,version2.2": "",
            "name": "Exif Exchangeable Image File Format, Version 2.2",
            "id": "fdd000146",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000146.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000146.xml"
            }
        },
        {
            "exifexchangeableimagefileformat,version2.2": "",
            "name": "Exif Exchangeable Image File Format, Version 2.2",
            "id": "fdd000146",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000146.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000146.xml"
            }
        },
        {
            "exifexchangeableimagefileformat,version2.2": "",
            "name": "Exif Exchangeable Image File Format, Version 2.2",
            "id": "fdd000146",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000146.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000146.xml"
            }
        },
        {
            "jpegencodedfilewithexifmetadata": "",
            "name": "JPEG Encoded File with Exif Metadata",
            "id": "fdd000147",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000147.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000147.xml"
            }
        },
        {
            "jpegencodedfilewithexifmetadata": "",
            "name": "JPEG Encoded File with Exif Metadata",
            "id": "fdd000147",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000147.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000147.xml"
            }
        },
        {
            "jpegencodedfilewithexifmetadata": "",
            "name": "JPEG Encoded File with Exif Metadata",
            "id": "fdd000147",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000147.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000147.xml"
            }
        },
        {
            "jpegencodedfilewithexifmetadata": "",
            "name": "JPEG Encoded File with Exif Metadata",
            "id": "fdd000147",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000147.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000147.xml"
            }
        },
        {
            "jpegencodedfilewithexifmetadata": "",
            "name": "JPEG Encoded File with Exif Metadata",
            "id": "fdd000147",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000147.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000147.xml"
            }
        },
        {
            "jpegencodedfilewithexifmetadata": "",
            "name": "JPEG Encoded File with Exif Metadata",
            "id": "fdd000147",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000147.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000147.xml"
            }
        },
        {
            "jpegencodedfilewithexifmetadata": "",
            "name": "JPEG Encoded File with Exif Metadata",
            "id": "fdd000147",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000147.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000147.xml"
            }
        },
        {
            "jpegencodedfilewithexifmetadata": "",
            "name": "JPEG Encoded File with Exif Metadata",
            "id": "fdd000147",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000147.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000147.xml"
            }
        },
        {
            "jpegdctcompressionencoding,baseline": "",
            "name": "JPEG DCT Compression Encoding, Baseline",
            "id": "fdd000149",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000149.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000149.xml"
            }
        },
        {
            "jpegdctcompressionencoding,baseline": "",
            "name": "JPEG DCT Compression Encoding, Baseline",
            "id": "fdd000149",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000149.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000149.xml"
            }
        },
        {
            "jpegdctcompressionencoding,baseline": "",
            "name": "JPEG DCT Compression Encoding, Baseline",
            "id": "fdd000149",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000149.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000149.xml"
            }
        },
        {
            "jpegdctcompressionencoding,baseline": "",
            "name": "JPEG DCT Compression Encoding, Baseline",
            "id": "fdd000149",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000149.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000149.xml"
            }
        },
        {
            "jpegdctcompressionencoding,baseline": "",
            "name": "JPEG DCT Compression Encoding, Baseline",
            "id": "fdd000149",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000149.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000149.xml"
            }
        },
        {
            "jpegdctcompressionencoding,baseline": "",
            "name": "JPEG DCT Compression Encoding, Baseline",
            "id": "fdd000149",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000149.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000149.xml"
            }
        },
        {
            "jpegdctcompressionencoding,baseline": "",
            "name": "JPEG DCT Compression Encoding, Baseline",
            "id": "fdd000149",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000149.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000149.xml"
            }
        },
        {
            "jpegdctcompressionencoding,baseline": "",
            "name": "JPEG DCT Compression Encoding, Baseline",
            "id": "fdd000149",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000149.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000149.xml"
            }
        },
        {
            "jpegdctcompressionencoding,extensions": "",
            "name": "JPEG DCT Compression Encoding, Extensions",
            "id": "fdd000150",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000150.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000150.xml"
            }
        },
        {
            "jpegdctcompressionencoding,extensions": "",
            "name": "JPEG DCT Compression Encoding, Extensions",
            "id": "fdd000150",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000150.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000150.xml"
            }
        },
        {
            "jpegdctcompressionencoding,extensions": "",
            "name": "JPEG DCT Compression Encoding, Extensions",
            "id": "fdd000150",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000150.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000150.xml"
            }
        },
        {
            "jpegdctcompressionencoding,extensions": "",
            "name": "JPEG DCT Compression Encoding, Extensions",
            "id": "fdd000150",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000150.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000150.xml"
            }
        },
        {
            "jpegdctcompressionencoding,extensions": "",
            "name": "JPEG DCT Compression Encoding, Extensions",
            "id": "fdd000150",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000150.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000150.xml"
            }
        },
        {
            "jpegdctcompressionencoding,extensions": "",
            "name": "JPEG DCT Compression Encoding, Extensions",
            "id": "fdd000150",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000150.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000150.xml"
            }
        },
        {
            "jpegdctcompressionencoding,extensions": "",
            "name": "JPEG DCT Compression Encoding, Extensions",
            "id": "fdd000150",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000150.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000150.xml"
            }
        },
        {
            "jpegdctcompressionencoding,extensions": "",
            "name": "JPEG DCT Compression Encoding, Extensions",
            "id": "fdd000150",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000150.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000150.xml"
            }
        },
        {
            "jpeglosslesscompression(iso/iec14495)": "",
            "name": "JPEG Lossless Compression (ISO/IEC 14495)",
            "id": "fdd000151",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000151.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000151.xml"
            }
        },
        {
            "jpeglosslesscompression(iso/iec14495)": "",
            "name": "JPEG Lossless Compression (ISO/IEC 14495)",
            "id": "fdd000151",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000151.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000151.xml"
            }
        },
        {
            "jpeglosslesscompression(iso/iec14495)": "",
            "name": "JPEG Lossless Compression (ISO/IEC 14495)",
            "id": "fdd000151",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000151.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000151.xml"
            }
        },
        {
            "jpeglosslesscompression(iso/iec14495)": "",
            "name": "JPEG Lossless Compression (ISO/IEC 14495)",
            "id": "fdd000151",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000151.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000151.xml"
            }
        },
        {
            "jpeglosslesscompression(iso/iec14495)": "",
            "name": "JPEG Lossless Compression (ISO/IEC 14495)",
            "id": "fdd000151",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000151.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000151.xml"
            }
        },
        {
            "jpeglosslesscompression(iso/iec14495)": "",
            "name": "JPEG Lossless Compression (ISO/IEC 14495)",
            "id": "fdd000151",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000151.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000151.xml"
            }
        },
        {
            "jpeglosslesscompression(iso/iec14495)": "",
            "name": "JPEG Lossless Compression (ISO/IEC 14495)",
            "id": "fdd000151",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000151.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000151.xml"
            }
        },
        {
            "jpeglosslesscompression(iso/iec14495)": "",
            "name": "JPEG Lossless Compression (ISO/IEC 14495)",
            "id": "fdd000151",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000151.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000151.xml"
            }
        },
        {
            "png,portablenetworkgraphics": "",
            "name": "PNG, Portable Network Graphics",
            "id": "fdd000153",
            "filenamepattern": [
                {
                    "value": "*.png",
                    "url": "https://www.digipres.org/formats/extensions/#*.png"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/png",
                    "url": "https://www.digipres.org/formats/mime-types/#image/png"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000153.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000153.xml"
            }
        },
        {
            "png,portablenetworkgraphics": "",
            "name": "PNG, Portable Network Graphics",
            "id": "fdd000153",
            "filenamepattern": [
                {
                    "value": "*.png",
                    "url": "https://www.digipres.org/formats/extensions/#*.png"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/png",
                    "url": "https://www.digipres.org/formats/mime-types/#image/png"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000153.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000153.xml"
            }
        },
        {
            "png,portablenetworkgraphics": "",
            "name": "PNG, Portable Network Graphics",
            "id": "fdd000153",
            "filenamepattern": [
                {
                    "value": "*.png",
                    "url": "https://www.digipres.org/formats/extensions/#*.png"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/png",
                    "url": "https://www.digipres.org/formats/mime-types/#image/png"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000153.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000153.xml"
            }
        },
        {
            "png,portablenetworkgraphics": "",
            "name": "PNG, Portable Network Graphics",
            "id": "fdd000153",
            "filenamepattern": [
                {
                    "value": "*.png",
                    "url": "https://www.digipres.org/formats/extensions/#*.png"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/png",
                    "url": "https://www.digipres.org/formats/mime-types/#image/png"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000153.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000153.xml"
            }
        },
        {
            "png,portablenetworkgraphics": "",
            "name": "PNG, Portable Network Graphics",
            "id": "fdd000153",
            "filenamepattern": [
                {
                    "value": "*.png",
                    "url": "https://www.digipres.org/formats/extensions/#*.png"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/png",
                    "url": "https://www.digipres.org/formats/mime-types/#image/png"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000153.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000153.xml"
            }
        },
        {
            "png,portablenetworkgraphics": "",
            "name": "PNG, Portable Network Graphics",
            "id": "fdd000153",
            "filenamepattern": [
                {
                    "value": "*.png",
                    "url": "https://www.digipres.org/formats/extensions/#*.png"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/png",
                    "url": "https://www.digipres.org/formats/mime-types/#image/png"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000153.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000153.xml"
            }
        },
        {
            "png,portablenetworkgraphics": "",
            "name": "PNG, Portable Network Graphics",
            "id": "fdd000153",
            "filenamepattern": [
                {
                    "value": "*.png",
                    "url": "https://www.digipres.org/formats/extensions/#*.png"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/png",
                    "url": "https://www.digipres.org/formats/mime-types/#image/png"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000153.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000153.xml"
            }
        },
        {
            "png,portablenetworkgraphics": "",
            "name": "PNG, Portable Network Graphics",
            "id": "fdd000153",
            "filenamepattern": [
                {
                    "value": "*.png",
                    "url": "https://www.digipres.org/formats/extensions/#*.png"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/png",
                    "url": "https://www.digipres.org/formats/mime-types/#image/png"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000153.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000153.xml"
            }
        },
        {
            "jpeg2000part2(extensions)jpf(jpx)fileformat": "",
            "name": "JPEG 2000 Part 2 (Extensions) jpf (jpx) File Format",
            "id": "fdd000154",
            "filenamepattern": [
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                },
                {
                    "value": "*.jpf",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000154.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000154.xml"
            }
        },
        {
            "jpeg2000part2(extensions)jpf(jpx)fileformat": "",
            "name": "JPEG 2000 Part 2 (Extensions) jpf (jpx) File Format",
            "id": "fdd000154",
            "filenamepattern": [
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                },
                {
                    "value": "*.jpf",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000154.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000154.xml"
            }
        },
        {
            "jpeg2000part2(extensions)jpf(jpx)fileformat": "",
            "name": "JPEG 2000 Part 2 (Extensions) jpf (jpx) File Format",
            "id": "fdd000154",
            "filenamepattern": [
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                },
                {
                    "value": "*.jpf",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000154.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000154.xml"
            }
        },
        {
            "jpeg2000part2(extensions)jpf(jpx)fileformat": "",
            "name": "JPEG 2000 Part 2 (Extensions) jpf (jpx) File Format",
            "id": "fdd000154",
            "filenamepattern": [
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                },
                {
                    "value": "*.jpf",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000154.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000154.xml"
            }
        },
        {
            "jpeg2000part2(extensions)jpf(jpx)fileformat": "",
            "name": "JPEG 2000 Part 2 (Extensions) jpf (jpx) File Format",
            "id": "fdd000154",
            "filenamepattern": [
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                },
                {
                    "value": "*.jpf",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000154.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000154.xml"
            }
        },
        {
            "jpeg2000part2(extensions)jpf(jpx)fileformat": "",
            "name": "JPEG 2000 Part 2 (Extensions) jpf (jpx) File Format",
            "id": "fdd000154",
            "filenamepattern": [
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                },
                {
                    "value": "*.jpf",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000154.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000154.xml"
            }
        },
        {
            "jpeg2000part2(extensions)jpf(jpx)fileformat": "",
            "name": "JPEG 2000 Part 2 (Extensions) jpf (jpx) File Format",
            "id": "fdd000154",
            "filenamepattern": [
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                },
                {
                    "value": "*.jpf",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000154.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000154.xml"
            }
        },
        {
            "jpeg2000part2(extensions)jpf(jpx)fileformat": "",
            "name": "JPEG 2000 Part 2 (Extensions) jpf (jpx) File Format",
            "id": "fdd000154",
            "filenamepattern": [
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                },
                {
                    "value": "*.jpf",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000154.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000154.xml"
            }
        },
        {
            "mpeg4fileformat,version2": "",
            "name": "MPEG-4 File Format, Version 2",
            "id": "fdd000155",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                },
                {
                    "value": "*.m4a",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4a"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mpeg4-generic",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg4-generic"
                },
                {
                    "value": "video/mp4v-es",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4v-es"
                },
                {
                    "value": "application/mpeg4-iod",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod"
                },
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                },
                {
                    "value": "application/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mp4"
                },
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                },
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000155.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000155.xml"
            }
        },
        {
            "mpeg4fileformat,version2": "",
            "name": "MPEG-4 File Format, Version 2",
            "id": "fdd000155",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                },
                {
                    "value": "*.m4a",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4a"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mpeg4-generic",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg4-generic"
                },
                {
                    "value": "video/mp4v-es",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4v-es"
                },
                {
                    "value": "application/mpeg4-iod",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod"
                },
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                },
                {
                    "value": "application/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mp4"
                },
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                },
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000155.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000155.xml"
            }
        },
        {
            "mpeg4fileformat,version2": "",
            "name": "MPEG-4 File Format, Version 2",
            "id": "fdd000155",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                },
                {
                    "value": "*.m4a",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4a"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mpeg4-generic",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg4-generic"
                },
                {
                    "value": "video/mp4v-es",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4v-es"
                },
                {
                    "value": "application/mpeg4-iod",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod"
                },
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                },
                {
                    "value": "application/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mp4"
                },
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                },
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000155.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000155.xml"
            }
        },
        {
            "mpeg4fileformat,version2": "",
            "name": "MPEG-4 File Format, Version 2",
            "id": "fdd000155",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                },
                {
                    "value": "*.m4a",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4a"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mpeg4-generic",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg4-generic"
                },
                {
                    "value": "video/mp4v-es",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4v-es"
                },
                {
                    "value": "application/mpeg4-iod",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod"
                },
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                },
                {
                    "value": "application/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mp4"
                },
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                },
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000155.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000155.xml"
            }
        },
        {
            "mpeg4fileformat,version2": "",
            "name": "MPEG-4 File Format, Version 2",
            "id": "fdd000155",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                },
                {
                    "value": "*.m4a",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4a"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mpeg4-generic",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg4-generic"
                },
                {
                    "value": "video/mp4v-es",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4v-es"
                },
                {
                    "value": "application/mpeg4-iod",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod"
                },
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                },
                {
                    "value": "application/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mp4"
                },
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                },
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000155.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000155.xml"
            }
        },
        {
            "mpeg4fileformat,version2": "",
            "name": "MPEG-4 File Format, Version 2",
            "id": "fdd000155",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                },
                {
                    "value": "*.m4a",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4a"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mpeg4-generic",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg4-generic"
                },
                {
                    "value": "video/mp4v-es",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4v-es"
                },
                {
                    "value": "application/mpeg4-iod",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod"
                },
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                },
                {
                    "value": "application/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mp4"
                },
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                },
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000155.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000155.xml"
            }
        },
        {
            "mpeg4fileformat,version2": "",
            "name": "MPEG-4 File Format, Version 2",
            "id": "fdd000155",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                },
                {
                    "value": "*.m4a",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4a"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mpeg4-generic",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg4-generic"
                },
                {
                    "value": "video/mp4v-es",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4v-es"
                },
                {
                    "value": "application/mpeg4-iod",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod"
                },
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                },
                {
                    "value": "application/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mp4"
                },
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                },
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000155.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000155.xml"
            }
        },
        {
            "mpeg4fileformat,version2": "",
            "name": "MPEG-4 File Format, Version 2",
            "id": "fdd000155",
            "filenamepattern": [
                {
                    "value": "*.mp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp4"
                },
                {
                    "value": "*.m4a",
                    "url": "https://www.digipres.org/formats/extensions/#*.m4a"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/mpeg4-generic",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg4-generic"
                },
                {
                    "value": "video/mp4v-es",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4v-es"
                },
                {
                    "value": "application/mpeg4-iod",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod"
                },
                {
                    "value": "video/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp4"
                },
                {
                    "value": "application/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mp4"
                },
                {
                    "value": "audio/mp4",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mp4"
                },
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000155.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000155.xml"
            }
        },
        {
            "mpeg4,extensiblempeg4textualformat(xmt)": "",
            "name": "MPEG-4, eXtensible MPEG-4 Textual Format (XMT)",
            "id": "fdd000156",
            "filenamepattern": [
                {
                    "value": "*.xmt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000156.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000156.xml"
            }
        },
        {
            "mpeg4,extensiblempeg4textualformat(xmt)": "",
            "name": "MPEG-4, eXtensible MPEG-4 Textual Format (XMT)",
            "id": "fdd000156",
            "filenamepattern": [
                {
                    "value": "*.xmt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000156.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000156.xml"
            }
        },
        {
            "mpeg4,extensiblempeg4textualformat(xmt)": "",
            "name": "MPEG-4, eXtensible MPEG-4 Textual Format (XMT)",
            "id": "fdd000156",
            "filenamepattern": [
                {
                    "value": "*.xmt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000156.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000156.xml"
            }
        },
        {
            "mpeg4,extensiblempeg4textualformat(xmt)": "",
            "name": "MPEG-4, eXtensible MPEG-4 Textual Format (XMT)",
            "id": "fdd000156",
            "filenamepattern": [
                {
                    "value": "*.xmt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000156.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000156.xml"
            }
        },
        {
            "mpeg4,extensiblempeg4textualformat(xmt)": "",
            "name": "MPEG-4, eXtensible MPEG-4 Textual Format (XMT)",
            "id": "fdd000156",
            "filenamepattern": [
                {
                    "value": "*.xmt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000156.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000156.xml"
            }
        },
        {
            "mpeg4,extensiblempeg4textualformat(xmt)": "",
            "name": "MPEG-4, eXtensible MPEG-4 Textual Format (XMT)",
            "id": "fdd000156",
            "filenamepattern": [
                {
                    "value": "*.xmt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000156.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000156.xml"
            }
        },
        {
            "mpeg4,extensiblempeg4textualformat(xmt)": "",
            "name": "MPEG-4, eXtensible MPEG-4 Textual Format (XMT)",
            "id": "fdd000156",
            "filenamepattern": [
                {
                    "value": "*.xmt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000156.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000156.xml"
            }
        },
        {
            "mpeg4,extensiblempeg4textualformat(xmt)": "",
            "name": "MPEG-4, eXtensible MPEG-4 Textual Format (XMT)",
            "id": "fdd000156",
            "filenamepattern": [
                {
                    "value": "*.xmt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xmt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mpeg4-iod-xmt",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mpeg4-iod-xmt"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000156.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000156.xml"
            }
        },
        {
            "mpeg4fileformat,version2,withvisualcoding": "",
            "name": "MPEG-4 File Format, Version 2, with Visual Coding",
            "id": "fdd000157",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000157.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000157.xml"
            }
        },
        {
            "mpeg4fileformat,version2,withvisualcoding": "",
            "name": "MPEG-4 File Format, Version 2, with Visual Coding",
            "id": "fdd000157",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000157.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000157.xml"
            }
        },
        {
            "mpeg4fileformat,version2,withvisualcoding": "",
            "name": "MPEG-4 File Format, Version 2, with Visual Coding",
            "id": "fdd000157",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000157.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000157.xml"
            }
        },
        {
            "mpeg4fileformat,version2,withvisualcoding": "",
            "name": "MPEG-4 File Format, Version 2, with Visual Coding",
            "id": "fdd000157",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000157.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000157.xml"
            }
        },
        {
            "mpeg4fileformat,version2,withvisualcoding": "",
            "name": "MPEG-4 File Format, Version 2, with Visual Coding",
            "id": "fdd000157",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000157.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000157.xml"
            }
        },
        {
            "mpeg4fileformat,version2,withvisualcoding": "",
            "name": "MPEG-4 File Format, Version 2, with Visual Coding",
            "id": "fdd000157",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000157.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000157.xml"
            }
        },
        {
            "mpeg4fileformat,version2,withvisualcoding": "",
            "name": "MPEG-4 File Format, Version 2, with Visual Coding",
            "id": "fdd000157",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000157.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000157.xml"
            }
        },
        {
            "mpeg4fileformat,version2,withvisualcoding": "",
            "name": "MPEG-4 File Format, Version 2, with Visual Coding",
            "id": "fdd000157",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000157.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000157.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,noprofileindicated": "",
            "name": "MPEG-4 File Format, V.2, with AVC, No Profile Indicated",
            "id": "fdd000158",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000158.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000158.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,noprofileindicated": "",
            "name": "MPEG-4 File Format, V.2, with AVC, No Profile Indicated",
            "id": "fdd000158",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000158.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000158.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,noprofileindicated": "",
            "name": "MPEG-4 File Format, V.2, with AVC, No Profile Indicated",
            "id": "fdd000158",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000158.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000158.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,noprofileindicated": "",
            "name": "MPEG-4 File Format, V.2, with AVC, No Profile Indicated",
            "id": "fdd000158",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000158.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000158.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,noprofileindicated": "",
            "name": "MPEG-4 File Format, V.2, with AVC, No Profile Indicated",
            "id": "fdd000158",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000158.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000158.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,noprofileindicated": "",
            "name": "MPEG-4 File Format, V.2, with AVC, No Profile Indicated",
            "id": "fdd000158",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000158.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000158.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,noprofileindicated": "",
            "name": "MPEG-4 File Format, V.2, with AVC, No Profile Indicated",
            "id": "fdd000158",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000158.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000158.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,noprofileindicated": "",
            "name": "MPEG-4 File Format, V.2, with AVC, No Profile Indicated",
            "id": "fdd000158",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000158.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000158.xml"
            }
        },
        {
            "mpeg4fileformatforavc(ext),withnonfrextextendedavccoding": "",
            "name": "MPEG-4 File Format for AVC (Ext), with Non-FRExt Extended AVC Coding",
            "id": "fdd000161",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000161.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000161.xml"
            }
        },
        {
            "mpeg4fileformatforavc(ext),withnonfrextextendedavccoding": "",
            "name": "MPEG-4 File Format for AVC (Ext), with Non-FRExt Extended AVC Coding",
            "id": "fdd000161",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000161.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000161.xml"
            }
        },
        {
            "mpeg4fileformatforavc(ext),withnonfrextextendedavccoding": "",
            "name": "MPEG-4 File Format for AVC (Ext), with Non-FRExt Extended AVC Coding",
            "id": "fdd000161",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000161.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000161.xml"
            }
        },
        {
            "mpeg4fileformatforavc(ext),withnonfrextextendedavccoding": "",
            "name": "MPEG-4 File Format for AVC (Ext), with Non-FRExt Extended AVC Coding",
            "id": "fdd000161",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000161.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000161.xml"
            }
        },
        {
            "mpeg4fileformatforavc(ext),withnonfrextextendedavccoding": "",
            "name": "MPEG-4 File Format for AVC (Ext), with Non-FRExt Extended AVC Coding",
            "id": "fdd000161",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000161.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000161.xml"
            }
        },
        {
            "mpeg4fileformatforavc(ext),withnonfrextextendedavccoding": "",
            "name": "MPEG-4 File Format for AVC (Ext), with Non-FRExt Extended AVC Coding",
            "id": "fdd000161",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000161.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000161.xml"
            }
        },
        {
            "mpeg4fileformatforavc(ext),withnonfrextextendedavccoding": "",
            "name": "MPEG-4 File Format for AVC (Ext), with Non-FRExt Extended AVC Coding",
            "id": "fdd000161",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000161.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000161.xml"
            }
        },
        {
            "mpeg4fileformatforavc(ext),withnonfrextextendedavccoding": "",
            "name": "MPEG-4 File Format for AVC (Ext), with Non-FRExt Extended AVC Coding",
            "id": "fdd000161",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000161.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000161.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,nonfrextextensions": "",
            "name": "MPEG-4, Advanced Video Coding, Non-FRExt Extensions",
            "id": "fdd000162",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000162.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000162.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,nonfrextextensions": "",
            "name": "MPEG-4, Advanced Video Coding, Non-FRExt Extensions",
            "id": "fdd000162",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000162.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000162.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,nonfrextextensions": "",
            "name": "MPEG-4, Advanced Video Coding, Non-FRExt Extensions",
            "id": "fdd000162",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000162.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000162.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,nonfrextextensions": "",
            "name": "MPEG-4, Advanced Video Coding, Non-FRExt Extensions",
            "id": "fdd000162",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000162.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000162.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,nonfrextextensions": "",
            "name": "MPEG-4, Advanced Video Coding, Non-FRExt Extensions",
            "id": "fdd000162",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000162.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000162.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,nonfrextextensions": "",
            "name": "MPEG-4, Advanced Video Coding, Non-FRExt Extensions",
            "id": "fdd000162",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000162.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000162.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,nonfrextextensions": "",
            "name": "MPEG-4, Advanced Video Coding, Non-FRExt Extensions",
            "id": "fdd000162",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000162.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000162.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,nonfrextextensions": "",
            "name": "MPEG-4, Advanced Video Coding, Non-FRExt Extensions",
            "id": "fdd000162",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000162.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000162.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossless": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossless",
            "id": "fdd000167",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000167.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000167.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossless": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossless",
            "id": "fdd000167",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000167.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000167.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossless": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossless",
            "id": "fdd000167",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000167.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000167.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossless": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossless",
            "id": "fdd000167",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000167.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000167.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossless": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossless",
            "id": "fdd000167",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000167.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000167.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossless": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossless",
            "id": "fdd000167",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000167.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000167.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossless": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossless",
            "id": "fdd000167",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000167.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000167.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossless": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossless",
            "id": "fdd000167",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000167.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000167.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossy": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossy",
            "id": "fdd000168",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000168.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000168.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossy": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossy",
            "id": "fdd000168",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000168.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000168.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossy": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossy",
            "id": "fdd000168",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000168.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000168.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossy": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossy",
            "id": "fdd000168",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000168.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000168.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossy": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossy",
            "id": "fdd000168",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000168.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000168.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossy": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossy",
            "id": "fdd000168",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000168.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000168.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossy": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossy",
            "id": "fdd000168",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000168.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000168.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,lossy": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Lossy",
            "id": "fdd000168",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000168.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000168.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,biifprofile(v.01.00)": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, BIIF Profile (v. 01.00)",
            "id": "fdd000169",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000169.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000169.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,biifprofile(v.01.00)": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, BIIF Profile (v. 01.00)",
            "id": "fdd000169",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000169.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000169.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,biifprofile(v.01.00)": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, BIIF Profile (v. 01.00)",
            "id": "fdd000169",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000169.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000169.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,biifprofile(v.01.00)": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, BIIF Profile (v. 01.00)",
            "id": "fdd000169",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000169.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000169.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,biifprofile(v.01.00)": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, BIIF Profile (v. 01.00)",
            "id": "fdd000169",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000169.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000169.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,biifprofile(v.01.00)": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, BIIF Profile (v. 01.00)",
            "id": "fdd000169",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000169.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000169.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,biifprofile(v.01.00)": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, BIIF Profile (v. 01.00)",
            "id": "fdd000169",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000169.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000169.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,biifprofile(v.01.00)": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, BIIF Profile (v. 01.00)",
            "id": "fdd000169",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000169.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000169.xml"
            }
        },
        {
            "biifprofileforjpeg2000,part1,corecoding,version01.00": "",
            "name": "BIIF Profile for JPEG 2000, Part 1, Core Coding, Version 01.00",
            "id": "fdd000170",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000170.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000170.xml"
            }
        },
        {
            "biifprofileforjpeg2000,part1,corecoding,version01.00": "",
            "name": "BIIF Profile for JPEG 2000, Part 1, Core Coding, Version 01.00",
            "id": "fdd000170",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000170.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000170.xml"
            }
        },
        {
            "biifprofileforjpeg2000,part1,corecoding,version01.00": "",
            "name": "BIIF Profile for JPEG 2000, Part 1, Core Coding, Version 01.00",
            "id": "fdd000170",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000170.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000170.xml"
            }
        },
        {
            "biifprofileforjpeg2000,part1,corecoding,version01.00": "",
            "name": "BIIF Profile for JPEG 2000, Part 1, Core Coding, Version 01.00",
            "id": "fdd000170",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000170.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000170.xml"
            }
        },
        {
            "biifprofileforjpeg2000,part1,corecoding,version01.00": "",
            "name": "BIIF Profile for JPEG 2000, Part 1, Core Coding, Version 01.00",
            "id": "fdd000170",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000170.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000170.xml"
            }
        },
        {
            "biifprofileforjpeg2000,part1,corecoding,version01.00": "",
            "name": "BIIF Profile for JPEG 2000, Part 1, Core Coding, Version 01.00",
            "id": "fdd000170",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000170.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000170.xml"
            }
        },
        {
            "biifprofileforjpeg2000,part1,corecoding,version01.00": "",
            "name": "BIIF Profile for JPEG 2000, Part 1, Core Coding, Version 01.00",
            "id": "fdd000170",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000170.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000170.xml"
            }
        },
        {
            "biifprofileforjpeg2000,part1,corecoding,version01.00": "",
            "name": "BIIF Profile for JPEG 2000, Part 1, Core Coding, Version 01.00",
            "id": "fdd000170",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000170.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000170.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.2": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.2",
            "id": "fdd000171",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000171.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000171.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.2": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.2",
            "id": "fdd000171",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000171.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000171.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.2": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.2",
            "id": "fdd000171",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000171.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000171.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.2": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.2",
            "id": "fdd000171",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000171.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000171.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.2": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.2",
            "id": "fdd000171",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000171.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000171.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.2": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.2",
            "id": "fdd000171",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000171.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000171.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.2": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.2",
            "id": "fdd000171",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000171.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000171.xml"
            }
        },
        {
            "oebps(openebookforumpublicationstructure)1.2": "",
            "name": "OEBPS (Open Ebook Forum Publication Structure) 1.2",
            "id": "fdd000171",
            "filenamepattern": [
                {
                    "value": "*.opf",
                    "url": "https://www.digipres.org/formats/extensions/#*.opf"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000171.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000171.xml"
            }
        },
        {
            "dvdif(digitalvideodigitalinterfaceformat)": "",
            "name": "DV-DIF (Digital Video Digital Interface Format)",
            "id": "fdd000173",
            "filenamepattern": [
                {
                    "value": "*.dif",
                    "url": "https://www.digipres.org/formats/extensions/#*.dif"
                },
                {
                    "value": "*.dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.dv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-dv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-dv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000173.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000173.xml"
            }
        },
        {
            "dvdif(digitalvideodigitalinterfaceformat)": "",
            "name": "DV-DIF (Digital Video Digital Interface Format)",
            "id": "fdd000173",
            "filenamepattern": [
                {
                    "value": "*.dif",
                    "url": "https://www.digipres.org/formats/extensions/#*.dif"
                },
                {
                    "value": "*.dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.dv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-dv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-dv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000173.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000173.xml"
            }
        },
        {
            "dvdif(digitalvideodigitalinterfaceformat)": "",
            "name": "DV-DIF (Digital Video Digital Interface Format)",
            "id": "fdd000173",
            "filenamepattern": [
                {
                    "value": "*.dif",
                    "url": "https://www.digipres.org/formats/extensions/#*.dif"
                },
                {
                    "value": "*.dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.dv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-dv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-dv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000173.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000173.xml"
            }
        },
        {
            "dvdif(digitalvideodigitalinterfaceformat)": "",
            "name": "DV-DIF (Digital Video Digital Interface Format)",
            "id": "fdd000173",
            "filenamepattern": [
                {
                    "value": "*.dif",
                    "url": "https://www.digipres.org/formats/extensions/#*.dif"
                },
                {
                    "value": "*.dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.dv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-dv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-dv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000173.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000173.xml"
            }
        },
        {
            "dvdif(digitalvideodigitalinterfaceformat)": "",
            "name": "DV-DIF (Digital Video Digital Interface Format)",
            "id": "fdd000173",
            "filenamepattern": [
                {
                    "value": "*.dif",
                    "url": "https://www.digipres.org/formats/extensions/#*.dif"
                },
                {
                    "value": "*.dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.dv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-dv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-dv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000173.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000173.xml"
            }
        },
        {
            "dvdif(digitalvideodigitalinterfaceformat)": "",
            "name": "DV-DIF (Digital Video Digital Interface Format)",
            "id": "fdd000173",
            "filenamepattern": [
                {
                    "value": "*.dif",
                    "url": "https://www.digipres.org/formats/extensions/#*.dif"
                },
                {
                    "value": "*.dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.dv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-dv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-dv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000173.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000173.xml"
            }
        },
        {
            "dvdif(digitalvideodigitalinterfaceformat)": "",
            "name": "DV-DIF (Digital Video Digital Interface Format)",
            "id": "fdd000173",
            "filenamepattern": [
                {
                    "value": "*.dif",
                    "url": "https://www.digipres.org/formats/extensions/#*.dif"
                },
                {
                    "value": "*.dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.dv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-dv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-dv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000173.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000173.xml"
            }
        },
        {
            "dvdif(digitalvideodigitalinterfaceformat)": "",
            "name": "DV-DIF (Digital Video Digital Interface Format)",
            "id": "fdd000173",
            "filenamepattern": [
                {
                    "value": "*.dif",
                    "url": "https://www.digipres.org/formats/extensions/#*.dif"
                },
                {
                    "value": "*.dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.dv"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/x-dv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-dv"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000173.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000173.xml"
            }
        },
        {
            "ncbiarch_1,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version1": "",
            "name": "NCBIArch_1, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 1",
            "id": "fdd000174",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000174.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000174.xml"
            }
        },
        {
            "ncbiarch_1,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version1": "",
            "name": "NCBIArch_1, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 1",
            "id": "fdd000174",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000174.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000174.xml"
            }
        },
        {
            "ncbiarch_1,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version1": "",
            "name": "NCBIArch_1, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 1",
            "id": "fdd000174",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000174.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000174.xml"
            }
        },
        {
            "ncbiarch_1,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version1": "",
            "name": "NCBIArch_1, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 1",
            "id": "fdd000174",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000174.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000174.xml"
            }
        },
        {
            "ncbiarch_1,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version1": "",
            "name": "NCBIArch_1, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 1",
            "id": "fdd000174",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000174.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000174.xml"
            }
        },
        {
            "ncbiarch_1,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version1": "",
            "name": "NCBIArch_1, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 1",
            "id": "fdd000174",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000174.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000174.xml"
            }
        },
        {
            "ncbiarch_1,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version1": "",
            "name": "NCBIArch_1, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 1",
            "id": "fdd000174",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000174.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000174.xml"
            }
        },
        {
            "ncbiarch_1,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version1": "",
            "name": "NCBIArch_1, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 1",
            "id": "fdd000174",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000174.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000174.xml"
            }
        },
        {
            "ncbiarch_2,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version2.x": "",
            "name": "NCBIArch_2, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 2.x",
            "id": "fdd000175",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000175.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000175.xml"
            }
        },
        {
            "ncbiarch_2,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version2.x": "",
            "name": "NCBIArch_2, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 2.x",
            "id": "fdd000175",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000175.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000175.xml"
            }
        },
        {
            "ncbiarch_2,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version2.x": "",
            "name": "NCBIArch_2, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 2.x",
            "id": "fdd000175",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000175.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000175.xml"
            }
        },
        {
            "ncbiarch_2,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version2.x": "",
            "name": "NCBIArch_2, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 2.x",
            "id": "fdd000175",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000175.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000175.xml"
            }
        },
        {
            "ncbiarch_2,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version2.x": "",
            "name": "NCBIArch_2, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 2.x",
            "id": "fdd000175",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000175.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000175.xml"
            }
        },
        {
            "ncbiarch_2,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version2.x": "",
            "name": "NCBIArch_2, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 2.x",
            "id": "fdd000175",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000175.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000175.xml"
            }
        },
        {
            "ncbiarch_2,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version2.x": "",
            "name": "NCBIArch_2, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 2.x",
            "id": "fdd000175",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000175.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000175.xml"
            }
        },
        {
            "ncbiarch_2,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version2.x": "",
            "name": "NCBIArch_2, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 2.x",
            "id": "fdd000175",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000175.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000175.xml"
            }
        },
        {
            "mxfformatgenericcontainer": "",
            "name": "MXF Format Generic Container",
            "id": "fdd000176",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000176.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000176.xml"
            }
        },
        {
            "mxfformatgenericcontainer": "",
            "name": "MXF Format Generic Container",
            "id": "fdd000176",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000176.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000176.xml"
            }
        },
        {
            "mxfformatgenericcontainer": "",
            "name": "MXF Format Generic Container",
            "id": "fdd000176",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000176.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000176.xml"
            }
        },
        {
            "mxfformatgenericcontainer": "",
            "name": "MXF Format Generic Container",
            "id": "fdd000176",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000176.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000176.xml"
            }
        },
        {
            "mxfformatgenericcontainer": "",
            "name": "MXF Format Generic Container",
            "id": "fdd000176",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000176.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000176.xml"
            }
        },
        {
            "mxfformatgenericcontainer": "",
            "name": "MXF Format Generic Container",
            "id": "fdd000176",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000176.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000176.xml"
            }
        },
        {
            "mxfformatgenericcontainer": "",
            "name": "MXF Format Generic Container",
            "id": "fdd000176",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000176.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000176.xml"
            }
        },
        {
            "mxfformatgenericcontainer": "",
            "name": "MXF Format Generic Container",
            "id": "fdd000176",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000176.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000176.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionmaster(dcdm),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Master (DCDM), Version 1.0",
            "id": "fdd000177",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000177.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000177.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionmaster(dcdm),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Master (DCDM), Version 1.0",
            "id": "fdd000177",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000177.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000177.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionmaster(dcdm),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Master (DCDM), Version 1.0",
            "id": "fdd000177",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000177.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000177.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionmaster(dcdm),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Master (DCDM), Version 1.0",
            "id": "fdd000177",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000177.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000177.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionmaster(dcdm),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Master (DCDM), Version 1.0",
            "id": "fdd000177",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000177.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000177.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionmaster(dcdm),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Master (DCDM), Version 1.0",
            "id": "fdd000177",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000177.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000177.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionmaster(dcdm),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Master (DCDM), Version 1.0",
            "id": "fdd000177",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000177.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000177.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionmaster(dcdm),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Master (DCDM), Version 1.0",
            "id": "fdd000177",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000177.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000177.xml"
            }
        },
        {
            "digitalmovingpictureexchange(dpx),version2.0": "",
            "name": "Digital Moving-Picture Exchange (DPX), Version 2.0",
            "id": "fdd000178",
            "filenamepattern": [
                {
                    "value": "*.dpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dpx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dpx"
                },
                {
                    "value": "image/dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/dpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000178.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000178.xml"
            }
        },
        {
            "digitalmovingpictureexchange(dpx),version2.0": "",
            "name": "Digital Moving-Picture Exchange (DPX), Version 2.0",
            "id": "fdd000178",
            "filenamepattern": [
                {
                    "value": "*.dpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dpx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dpx"
                },
                {
                    "value": "image/dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/dpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000178.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000178.xml"
            }
        },
        {
            "digitalmovingpictureexchange(dpx),version2.0": "",
            "name": "Digital Moving-Picture Exchange (DPX), Version 2.0",
            "id": "fdd000178",
            "filenamepattern": [
                {
                    "value": "*.dpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dpx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dpx"
                },
                {
                    "value": "image/dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/dpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000178.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000178.xml"
            }
        },
        {
            "digitalmovingpictureexchange(dpx),version2.0": "",
            "name": "Digital Moving-Picture Exchange (DPX), Version 2.0",
            "id": "fdd000178",
            "filenamepattern": [
                {
                    "value": "*.dpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dpx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dpx"
                },
                {
                    "value": "image/dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/dpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000178.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000178.xml"
            }
        },
        {
            "digitalmovingpictureexchange(dpx),version2.0": "",
            "name": "Digital Moving-Picture Exchange (DPX), Version 2.0",
            "id": "fdd000178",
            "filenamepattern": [
                {
                    "value": "*.dpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dpx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dpx"
                },
                {
                    "value": "image/dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/dpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000178.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000178.xml"
            }
        },
        {
            "digitalmovingpictureexchange(dpx),version2.0": "",
            "name": "Digital Moving-Picture Exchange (DPX), Version 2.0",
            "id": "fdd000178",
            "filenamepattern": [
                {
                    "value": "*.dpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dpx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dpx"
                },
                {
                    "value": "image/dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/dpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000178.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000178.xml"
            }
        },
        {
            "digitalmovingpictureexchange(dpx),version2.0": "",
            "name": "Digital Moving-Picture Exchange (DPX), Version 2.0",
            "id": "fdd000178",
            "filenamepattern": [
                {
                    "value": "*.dpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dpx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dpx"
                },
                {
                    "value": "image/dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/dpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000178.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000178.xml"
            }
        },
        {
            "digitalmovingpictureexchange(dpx),version2.0": "",
            "name": "Digital Moving-Picture Exchange (DPX), Version 2.0",
            "id": "fdd000178",
            "filenamepattern": [
                {
                    "value": "*.dpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dpx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dpx"
                },
                {
                    "value": "image/dpx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/dpx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000178.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000178.xml"
            }
        },
        {
            "truevisiontga,version1.0": "",
            "name": "Truevision TGA, version 1.0",
            "id": "fdd000179",
            "filenamepattern": [
                {
                    "value": "*.icb",
                    "url": "https://www.digipres.org/formats/extensions/#*.icb"
                },
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                },
                {
                    "value": "*.vda",
                    "url": "https://www.digipres.org/formats/extensions/#*.vda"
                },
                {
                    "value": "*.vst",
                    "url": "https://www.digipres.org/formats/extensions/#*.vst"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000179.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000179.xml"
            }
        },
        {
            "truevisiontga,version1.0": "",
            "name": "Truevision TGA, version 1.0",
            "id": "fdd000179",
            "filenamepattern": [
                {
                    "value": "*.icb",
                    "url": "https://www.digipres.org/formats/extensions/#*.icb"
                },
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                },
                {
                    "value": "*.vda",
                    "url": "https://www.digipres.org/formats/extensions/#*.vda"
                },
                {
                    "value": "*.vst",
                    "url": "https://www.digipres.org/formats/extensions/#*.vst"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000179.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000179.xml"
            }
        },
        {
            "truevisiontga,version1.0": "",
            "name": "Truevision TGA, version 1.0",
            "id": "fdd000179",
            "filenamepattern": [
                {
                    "value": "*.icb",
                    "url": "https://www.digipres.org/formats/extensions/#*.icb"
                },
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                },
                {
                    "value": "*.vda",
                    "url": "https://www.digipres.org/formats/extensions/#*.vda"
                },
                {
                    "value": "*.vst",
                    "url": "https://www.digipres.org/formats/extensions/#*.vst"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000179.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000179.xml"
            }
        },
        {
            "truevisiontga,version1.0": "",
            "name": "Truevision TGA, version 1.0",
            "id": "fdd000179",
            "filenamepattern": [
                {
                    "value": "*.icb",
                    "url": "https://www.digipres.org/formats/extensions/#*.icb"
                },
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                },
                {
                    "value": "*.vda",
                    "url": "https://www.digipres.org/formats/extensions/#*.vda"
                },
                {
                    "value": "*.vst",
                    "url": "https://www.digipres.org/formats/extensions/#*.vst"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000179.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000179.xml"
            }
        },
        {
            "truevisiontga,version1.0": "",
            "name": "Truevision TGA, version 1.0",
            "id": "fdd000179",
            "filenamepattern": [
                {
                    "value": "*.icb",
                    "url": "https://www.digipres.org/formats/extensions/#*.icb"
                },
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                },
                {
                    "value": "*.vda",
                    "url": "https://www.digipres.org/formats/extensions/#*.vda"
                },
                {
                    "value": "*.vst",
                    "url": "https://www.digipres.org/formats/extensions/#*.vst"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000179.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000179.xml"
            }
        },
        {
            "truevisiontga,version1.0": "",
            "name": "Truevision TGA, version 1.0",
            "id": "fdd000179",
            "filenamepattern": [
                {
                    "value": "*.icb",
                    "url": "https://www.digipres.org/formats/extensions/#*.icb"
                },
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                },
                {
                    "value": "*.vda",
                    "url": "https://www.digipres.org/formats/extensions/#*.vda"
                },
                {
                    "value": "*.vst",
                    "url": "https://www.digipres.org/formats/extensions/#*.vst"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000179.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000179.xml"
            }
        },
        {
            "truevisiontga,version1.0": "",
            "name": "Truevision TGA, version 1.0",
            "id": "fdd000179",
            "filenamepattern": [
                {
                    "value": "*.icb",
                    "url": "https://www.digipres.org/formats/extensions/#*.icb"
                },
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                },
                {
                    "value": "*.vda",
                    "url": "https://www.digipres.org/formats/extensions/#*.vda"
                },
                {
                    "value": "*.vst",
                    "url": "https://www.digipres.org/formats/extensions/#*.vst"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000179.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000179.xml"
            }
        },
        {
            "truevisiontga,version1.0": "",
            "name": "Truevision TGA, version 1.0",
            "id": "fdd000179",
            "filenamepattern": [
                {
                    "value": "*.icb",
                    "url": "https://www.digipres.org/formats/extensions/#*.icb"
                },
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                },
                {
                    "value": "*.vda",
                    "url": "https://www.digipres.org/formats/extensions/#*.vda"
                },
                {
                    "value": "*.vst",
                    "url": "https://www.digipres.org/formats/extensions/#*.vst"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000179.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000179.xml"
            }
        },
        {
            "truevisiontga,version2.0": "",
            "name": "Truevision TGA, version 2.0",
            "id": "fdd000180",
            "filenamepattern": [
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000180.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000180.xml"
            }
        },
        {
            "truevisiontga,version2.0": "",
            "name": "Truevision TGA, version 2.0",
            "id": "fdd000180",
            "filenamepattern": [
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000180.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000180.xml"
            }
        },
        {
            "truevisiontga,version2.0": "",
            "name": "Truevision TGA, version 2.0",
            "id": "fdd000180",
            "filenamepattern": [
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000180.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000180.xml"
            }
        },
        {
            "truevisiontga,version2.0": "",
            "name": "Truevision TGA, version 2.0",
            "id": "fdd000180",
            "filenamepattern": [
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000180.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000180.xml"
            }
        },
        {
            "truevisiontga,version2.0": "",
            "name": "Truevision TGA, version 2.0",
            "id": "fdd000180",
            "filenamepattern": [
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000180.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000180.xml"
            }
        },
        {
            "truevisiontga,version2.0": "",
            "name": "Truevision TGA, version 2.0",
            "id": "fdd000180",
            "filenamepattern": [
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000180.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000180.xml"
            }
        },
        {
            "truevisiontga,version2.0": "",
            "name": "Truevision TGA, version 2.0",
            "id": "fdd000180",
            "filenamepattern": [
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000180.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000180.xml"
            }
        },
        {
            "truevisiontga,version2.0": "",
            "name": "Truevision TGA, version 2.0",
            "id": "fdd000180",
            "filenamepattern": [
                {
                    "value": "*.tga",
                    "url": "https://www.digipres.org/formats/extensions/#*.tga"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000180.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000180.xml"
            }
        },
        {
            "digitalvideoencoding(dv,dvcam,dvcpro)": "",
            "name": "Digital Video Encoding (DV, DVCAM, DVCPRO)",
            "id": "fdd000183",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000183.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000183.xml"
            }
        },
        {
            "digitalvideoencoding(dv,dvcam,dvcpro)": "",
            "name": "Digital Video Encoding (DV, DVCAM, DVCPRO)",
            "id": "fdd000183",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000183.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000183.xml"
            }
        },
        {
            "digitalvideoencoding(dv,dvcam,dvcpro)": "",
            "name": "Digital Video Encoding (DV, DVCAM, DVCPRO)",
            "id": "fdd000183",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000183.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000183.xml"
            }
        },
        {
            "digitalvideoencoding(dv,dvcam,dvcpro)": "",
            "name": "Digital Video Encoding (DV, DVCAM, DVCPRO)",
            "id": "fdd000183",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000183.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000183.xml"
            }
        },
        {
            "digitalvideoencoding(dv,dvcam,dvcpro)": "",
            "name": "Digital Video Encoding (DV, DVCAM, DVCPRO)",
            "id": "fdd000183",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000183.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000183.xml"
            }
        },
        {
            "digitalvideoencoding(dv,dvcam,dvcpro)": "",
            "name": "Digital Video Encoding (DV, DVCAM, DVCPRO)",
            "id": "fdd000183",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000183.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000183.xml"
            }
        },
        {
            "digitalvideoencoding(dv,dvcam,dvcpro)": "",
            "name": "Digital Video Encoding (DV, DVCAM, DVCPRO)",
            "id": "fdd000183",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000183.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000183.xml"
            }
        },
        {
            "digitalvideoencoding(dv,dvcam,dvcpro)": "",
            "name": "Digital Video Encoding (DV, DVCAM, DVCPRO)",
            "id": "fdd000183",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000183.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000183.xml"
            }
        },
        {
            "mrsidimageformat,generation3": "",
            "name": "MrSID Image Format, Generation 3",
            "id": "fdd000184",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000184.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000184.xml"
            }
        },
        {
            "mrsidimageformat,generation3": "",
            "name": "MrSID Image Format, Generation 3",
            "id": "fdd000184",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000184.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000184.xml"
            }
        },
        {
            "mrsidimageformat,generation3": "",
            "name": "MrSID Image Format, Generation 3",
            "id": "fdd000184",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000184.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000184.xml"
            }
        },
        {
            "mrsidimageformat,generation3": "",
            "name": "MrSID Image Format, Generation 3",
            "id": "fdd000184",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000184.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000184.xml"
            }
        },
        {
            "mrsidimageformat,generation3": "",
            "name": "MrSID Image Format, Generation 3",
            "id": "fdd000184",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000184.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000184.xml"
            }
        },
        {
            "mrsidimageformat,generation3": "",
            "name": "MrSID Image Format, Generation 3",
            "id": "fdd000184",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000184.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000184.xml"
            }
        },
        {
            "mrsidimageformat,generation3": "",
            "name": "MrSID Image Format, Generation 3",
            "id": "fdd000184",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000184.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000184.xml"
            }
        },
        {
            "mrsidimageformat,generation3": "",
            "name": "MrSID Image Format, Generation 3",
            "id": "fdd000184",
            "filenamepattern": [
                {
                    "value": "*.sid",
                    "url": "https://www.digipres.org/formats/extensions/#*.sid"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-mrsid-image",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-mrsid-image"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000184.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000184.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.1": "",
            "name": "Adobe Digital Negative (DNG), Version 1.1",
            "id": "fdd000188",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000188.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000188.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.1": "",
            "name": "Adobe Digital Negative (DNG), Version 1.1",
            "id": "fdd000188",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000188.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000188.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.1": "",
            "name": "Adobe Digital Negative (DNG), Version 1.1",
            "id": "fdd000188",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000188.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000188.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.1": "",
            "name": "Adobe Digital Negative (DNG), Version 1.1",
            "id": "fdd000188",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000188.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000188.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.1": "",
            "name": "Adobe Digital Negative (DNG), Version 1.1",
            "id": "fdd000188",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000188.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000188.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.1": "",
            "name": "Adobe Digital Negative (DNG), Version 1.1",
            "id": "fdd000188",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000188.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000188.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.1": "",
            "name": "Adobe Digital Negative (DNG), Version 1.1",
            "id": "fdd000188",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000188.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000188.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.1": "",
            "name": "Adobe Digital Negative (DNG), Version 1.1",
            "id": "fdd000188",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000188.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000188.xml"
            }
        },
        {
            "bitmapimagefile(bmp),version5": "",
            "name": "Bitmap Image File (BMP), Version 5",
            "id": "fdd000189",
            "filenamepattern": [
                {
                    "value": "*.bmp",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-bmp"
                },
                {
                    "value": "image/x-ms-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-ms-bmp"
                },
                {
                    "value": "image/bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/bmp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000189.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000189.xml"
            }
        },
        {
            "bitmapimagefile(bmp),version5": "",
            "name": "Bitmap Image File (BMP), Version 5",
            "id": "fdd000189",
            "filenamepattern": [
                {
                    "value": "*.bmp",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-bmp"
                },
                {
                    "value": "image/x-ms-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-ms-bmp"
                },
                {
                    "value": "image/bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/bmp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000189.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000189.xml"
            }
        },
        {
            "bitmapimagefile(bmp),version5": "",
            "name": "Bitmap Image File (BMP), Version 5",
            "id": "fdd000189",
            "filenamepattern": [
                {
                    "value": "*.bmp",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-bmp"
                },
                {
                    "value": "image/x-ms-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-ms-bmp"
                },
                {
                    "value": "image/bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/bmp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000189.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000189.xml"
            }
        },
        {
            "bitmapimagefile(bmp),version5": "",
            "name": "Bitmap Image File (BMP), Version 5",
            "id": "fdd000189",
            "filenamepattern": [
                {
                    "value": "*.bmp",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-bmp"
                },
                {
                    "value": "image/x-ms-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-ms-bmp"
                },
                {
                    "value": "image/bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/bmp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000189.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000189.xml"
            }
        },
        {
            "bitmapimagefile(bmp),version5": "",
            "name": "Bitmap Image File (BMP), Version 5",
            "id": "fdd000189",
            "filenamepattern": [
                {
                    "value": "*.bmp",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-bmp"
                },
                {
                    "value": "image/x-ms-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-ms-bmp"
                },
                {
                    "value": "image/bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/bmp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000189.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000189.xml"
            }
        },
        {
            "bitmapimagefile(bmp),version5": "",
            "name": "Bitmap Image File (BMP), Version 5",
            "id": "fdd000189",
            "filenamepattern": [
                {
                    "value": "*.bmp",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-bmp"
                },
                {
                    "value": "image/x-ms-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-ms-bmp"
                },
                {
                    "value": "image/bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/bmp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000189.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000189.xml"
            }
        },
        {
            "bitmapimagefile(bmp),version5": "",
            "name": "Bitmap Image File (BMP), Version 5",
            "id": "fdd000189",
            "filenamepattern": [
                {
                    "value": "*.bmp",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-bmp"
                },
                {
                    "value": "image/x-ms-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-ms-bmp"
                },
                {
                    "value": "image/bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/bmp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000189.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000189.xml"
            }
        },
        {
            "bitmapimagefile(bmp),version5": "",
            "name": "Bitmap Image File (BMP), Version 5",
            "id": "fdd000189",
            "filenamepattern": [
                {
                    "value": "*.bmp",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-bmp"
                },
                {
                    "value": "image/x-ms-bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-ms-bmp"
                },
                {
                    "value": "image/bmp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/bmp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000189.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000189.xml"
            }
        },
        {
            "aviopendmlwithdvdigitalvideo": "",
            "name": "AVI OpenDML with DV Digital Video",
            "id": "fdd000190",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000190.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000190.xml"
            }
        },
        {
            "aviopendmlwithdvdigitalvideo": "",
            "name": "AVI OpenDML with DV Digital Video",
            "id": "fdd000190",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000190.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000190.xml"
            }
        },
        {
            "aviopendmlwithdvdigitalvideo": "",
            "name": "AVI OpenDML with DV Digital Video",
            "id": "fdd000190",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000190.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000190.xml"
            }
        },
        {
            "aviopendmlwithdvdigitalvideo": "",
            "name": "AVI OpenDML with DV Digital Video",
            "id": "fdd000190",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000190.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000190.xml"
            }
        },
        {
            "aviopendmlwithdvdigitalvideo": "",
            "name": "AVI OpenDML with DV Digital Video",
            "id": "fdd000190",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000190.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000190.xml"
            }
        },
        {
            "aviopendmlwithdvdigitalvideo": "",
            "name": "AVI OpenDML with DV Digital Video",
            "id": "fdd000190",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000190.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000190.xml"
            }
        },
        {
            "aviopendmlwithdvdigitalvideo": "",
            "name": "AVI OpenDML with DV Digital Video",
            "id": "fdd000190",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000190.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000190.xml"
            }
        },
        {
            "aviopendmlwithdvdigitalvideo": "",
            "name": "AVI OpenDML with DV Digital Video",
            "id": "fdd000190",
            "filenamepattern": [
                {
                    "value": "*.avi",
                    "url": "https://www.digipres.org/formats/extensions/#*.avi"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000190.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000190.xml"
            }
        },
        {
            "ndnpprofileforjpeg2000,part1,corecoding": "",
            "name": "NDNP Profile for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000192",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000192.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000192.xml"
            }
        },
        {
            "ndnpprofileforjpeg2000,part1,corecoding": "",
            "name": "NDNP Profile for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000192",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000192.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000192.xml"
            }
        },
        {
            "ndnpprofileforjpeg2000,part1,corecoding": "",
            "name": "NDNP Profile for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000192",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000192.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000192.xml"
            }
        },
        {
            "ndnpprofileforjpeg2000,part1,corecoding": "",
            "name": "NDNP Profile for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000192",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000192.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000192.xml"
            }
        },
        {
            "ndnpprofileforjpeg2000,part1,corecoding": "",
            "name": "NDNP Profile for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000192",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000192.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000192.xml"
            }
        },
        {
            "ndnpprofileforjpeg2000,part1,corecoding": "",
            "name": "NDNP Profile for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000192",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000192.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000192.xml"
            }
        },
        {
            "ndnpprofileforjpeg2000,part1,corecoding": "",
            "name": "NDNP Profile for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000192",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000192.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000192.xml"
            }
        },
        {
            "ndnpprofileforjpeg2000,part1,corecoding": "",
            "name": "NDNP Profile for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000192",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000192.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000192.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,ndnpprofile": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, NDNP Profile",
            "id": "fdd000193",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000193.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000193.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,ndnpprofile": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, NDNP Profile",
            "id": "fdd000193",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000193.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000193.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,ndnpprofile": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, NDNP Profile",
            "id": "fdd000193",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000193.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000193.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,ndnpprofile": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, NDNP Profile",
            "id": "fdd000193",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000193.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000193.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,ndnpprofile": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, NDNP Profile",
            "id": "fdd000193",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000193.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000193.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,ndnpprofile": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, NDNP Profile",
            "id": "fdd000193",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000193.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000193.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,ndnpprofile": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, NDNP Profile",
            "id": "fdd000193",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000193.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000193.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,ndnpprofile": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, NDNP Profile",
            "id": "fdd000193",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000193.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000193.xml"
            }
        },
        {
            "profile0forjpeg2000,part1,corecoding": "",
            "name": "Profile 0 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000194",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000194.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000194.xml"
            }
        },
        {
            "profile0forjpeg2000,part1,corecoding": "",
            "name": "Profile 0 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000194",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000194.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000194.xml"
            }
        },
        {
            "profile0forjpeg2000,part1,corecoding": "",
            "name": "Profile 0 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000194",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000194.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000194.xml"
            }
        },
        {
            "profile0forjpeg2000,part1,corecoding": "",
            "name": "Profile 0 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000194",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000194.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000194.xml"
            }
        },
        {
            "profile0forjpeg2000,part1,corecoding": "",
            "name": "Profile 0 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000194",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000194.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000194.xml"
            }
        },
        {
            "profile0forjpeg2000,part1,corecoding": "",
            "name": "Profile 0 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000194",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000194.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000194.xml"
            }
        },
        {
            "profile0forjpeg2000,part1,corecoding": "",
            "name": "Profile 0 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000194",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000194.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000194.xml"
            }
        },
        {
            "profile0forjpeg2000,part1,corecoding": "",
            "name": "Profile 0 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000194",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000194.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000194.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile0": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 0",
            "id": "fdd000195",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000195.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000195.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile0": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 0",
            "id": "fdd000195",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000195.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000195.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile0": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 0",
            "id": "fdd000195",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000195.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000195.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile0": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 0",
            "id": "fdd000195",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000195.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000195.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile0": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 0",
            "id": "fdd000195",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000195.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000195.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile0": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 0",
            "id": "fdd000195",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000195.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000195.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile0": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 0",
            "id": "fdd000195",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000195.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000195.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile0": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 0",
            "id": "fdd000195",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000195.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000195.xml"
            }
        },
        {
            "profile1forjpeg2000,part1,corecoding": "",
            "name": "Profile 1 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000196",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000196.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000196.xml"
            }
        },
        {
            "profile1forjpeg2000,part1,corecoding": "",
            "name": "Profile 1 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000196",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000196.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000196.xml"
            }
        },
        {
            "profile1forjpeg2000,part1,corecoding": "",
            "name": "Profile 1 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000196",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000196.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000196.xml"
            }
        },
        {
            "profile1forjpeg2000,part1,corecoding": "",
            "name": "Profile 1 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000196",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000196.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000196.xml"
            }
        },
        {
            "profile1forjpeg2000,part1,corecoding": "",
            "name": "Profile 1 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000196",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000196.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000196.xml"
            }
        },
        {
            "profile1forjpeg2000,part1,corecoding": "",
            "name": "Profile 1 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000196",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000196.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000196.xml"
            }
        },
        {
            "profile1forjpeg2000,part1,corecoding": "",
            "name": "Profile 1 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000196",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000196.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000196.xml"
            }
        },
        {
            "profile1forjpeg2000,part1,corecoding": "",
            "name": "Profile 1 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000196",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000196.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000196.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile1": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 1",
            "id": "fdd000197",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000197.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000197.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile1": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 1",
            "id": "fdd000197",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000197.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000197.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile1": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 1",
            "id": "fdd000197",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000197.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000197.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile1": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 1",
            "id": "fdd000197",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000197.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000197.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile1": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 1",
            "id": "fdd000197",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000197.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000197.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile1": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 1",
            "id": "fdd000197",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000197.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000197.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile1": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 1",
            "id": "fdd000197",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000197.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000197.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile1": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 1",
            "id": "fdd000197",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000197.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000197.xml"
            }
        },
        {
            "flac(freelosslessaudiocodec),version1.1.2": "",
            "name": "FLAC (Free Lossless Audio Codec), Version 1.1.2",
            "id": "fdd000198",
            "filenamepattern": [
                {
                    "value": "*.flac",
                    "url": "https://www.digipres.org/formats/extensions/#*.flac"
                },
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/flac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/flac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000198.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000198.xml"
            }
        },
        {
            "flac(freelosslessaudiocodec),version1.1.2": "",
            "name": "FLAC (Free Lossless Audio Codec), Version 1.1.2",
            "id": "fdd000198",
            "filenamepattern": [
                {
                    "value": "*.flac",
                    "url": "https://www.digipres.org/formats/extensions/#*.flac"
                },
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/flac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/flac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000198.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000198.xml"
            }
        },
        {
            "flac(freelosslessaudiocodec),version1.1.2": "",
            "name": "FLAC (Free Lossless Audio Codec), Version 1.1.2",
            "id": "fdd000198",
            "filenamepattern": [
                {
                    "value": "*.flac",
                    "url": "https://www.digipres.org/formats/extensions/#*.flac"
                },
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/flac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/flac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000198.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000198.xml"
            }
        },
        {
            "flac(freelosslessaudiocodec),version1.1.2": "",
            "name": "FLAC (Free Lossless Audio Codec), Version 1.1.2",
            "id": "fdd000198",
            "filenamepattern": [
                {
                    "value": "*.flac",
                    "url": "https://www.digipres.org/formats/extensions/#*.flac"
                },
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/flac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/flac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000198.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000198.xml"
            }
        },
        {
            "flac(freelosslessaudiocodec),version1.1.2": "",
            "name": "FLAC (Free Lossless Audio Codec), Version 1.1.2",
            "id": "fdd000198",
            "filenamepattern": [
                {
                    "value": "*.flac",
                    "url": "https://www.digipres.org/formats/extensions/#*.flac"
                },
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/flac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/flac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000198.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000198.xml"
            }
        },
        {
            "flac(freelosslessaudiocodec),version1.1.2": "",
            "name": "FLAC (Free Lossless Audio Codec), Version 1.1.2",
            "id": "fdd000198",
            "filenamepattern": [
                {
                    "value": "*.flac",
                    "url": "https://www.digipres.org/formats/extensions/#*.flac"
                },
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/flac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/flac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000198.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000198.xml"
            }
        },
        {
            "flac(freelosslessaudiocodec),version1.1.2": "",
            "name": "FLAC (Free Lossless Audio Codec), Version 1.1.2",
            "id": "fdd000198",
            "filenamepattern": [
                {
                    "value": "*.flac",
                    "url": "https://www.digipres.org/formats/extensions/#*.flac"
                },
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/flac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/flac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000198.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000198.xml"
            }
        },
        {
            "flac(freelosslessaudiocodec),version1.1.2": "",
            "name": "FLAC (Free Lossless Audio Codec), Version 1.1.2",
            "id": "fdd000198",
            "filenamepattern": [
                {
                    "value": "*.flac",
                    "url": "https://www.digipres.org/formats/extensions/#*.flac"
                },
                {
                    "value": "*.fla",
                    "url": "https://www.digipres.org/formats/extensions/#*.fla"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/flac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/flac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000198.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000198.xml"
            }
        },
        {
            "shortenlosslessaudiocompressionformat(shn),version3.5.1": "",
            "name": "Shorten Lossless Audio Compression Format (SHN), Version 3.5.1",
            "id": "fdd000199",
            "filenamepattern": [
                {
                    "value": "*.shn",
                    "url": "https://www.digipres.org/formats/extensions/#*.shn"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-shorten",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shorten"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000199.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000199.xml"
            }
        },
        {
            "shortenlosslessaudiocompressionformat(shn),version3.5.1": "",
            "name": "Shorten Lossless Audio Compression Format (SHN), Version 3.5.1",
            "id": "fdd000199",
            "filenamepattern": [
                {
                    "value": "*.shn",
                    "url": "https://www.digipres.org/formats/extensions/#*.shn"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-shorten",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shorten"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000199.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000199.xml"
            }
        },
        {
            "shortenlosslessaudiocompressionformat(shn),version3.5.1": "",
            "name": "Shorten Lossless Audio Compression Format (SHN), Version 3.5.1",
            "id": "fdd000199",
            "filenamepattern": [
                {
                    "value": "*.shn",
                    "url": "https://www.digipres.org/formats/extensions/#*.shn"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-shorten",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shorten"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000199.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000199.xml"
            }
        },
        {
            "shortenlosslessaudiocompressionformat(shn),version3.5.1": "",
            "name": "Shorten Lossless Audio Compression Format (SHN), Version 3.5.1",
            "id": "fdd000199",
            "filenamepattern": [
                {
                    "value": "*.shn",
                    "url": "https://www.digipres.org/formats/extensions/#*.shn"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-shorten",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shorten"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000199.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000199.xml"
            }
        },
        {
            "shortenlosslessaudiocompressionformat(shn),version3.5.1": "",
            "name": "Shorten Lossless Audio Compression Format (SHN), Version 3.5.1",
            "id": "fdd000199",
            "filenamepattern": [
                {
                    "value": "*.shn",
                    "url": "https://www.digipres.org/formats/extensions/#*.shn"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-shorten",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shorten"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000199.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000199.xml"
            }
        },
        {
            "shortenlosslessaudiocompressionformat(shn),version3.5.1": "",
            "name": "Shorten Lossless Audio Compression Format (SHN), Version 3.5.1",
            "id": "fdd000199",
            "filenamepattern": [
                {
                    "value": "*.shn",
                    "url": "https://www.digipres.org/formats/extensions/#*.shn"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-shorten",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shorten"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000199.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000199.xml"
            }
        },
        {
            "shortenlosslessaudiocompressionformat(shn),version3.5.1": "",
            "name": "Shorten Lossless Audio Compression Format (SHN), Version 3.5.1",
            "id": "fdd000199",
            "filenamepattern": [
                {
                    "value": "*.shn",
                    "url": "https://www.digipres.org/formats/extensions/#*.shn"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-shorten",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shorten"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000199.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000199.xml"
            }
        },
        {
            "shortenlosslessaudiocompressionformat(shn),version3.5.1": "",
            "name": "Shorten Lossless Audio Compression Format (SHN), Version 3.5.1",
            "id": "fdd000199",
            "filenamepattern": [
                {
                    "value": "*.shn",
                    "url": "https://www.digipres.org/formats/extensions/#*.shn"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-shorten",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shorten"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000199.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000199.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionpackage(dcp),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Package (DCP), Version 1.0",
            "id": "fdd000200",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000200.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000200.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionpackage(dcp),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Package (DCP), Version 1.0",
            "id": "fdd000200",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000200.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000200.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionpackage(dcp),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Package (DCP), Version 1.0",
            "id": "fdd000200",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000200.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000200.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionpackage(dcp),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Package (DCP), Version 1.0",
            "id": "fdd000200",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000200.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000200.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionpackage(dcp),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Package (DCP), Version 1.0",
            "id": "fdd000200",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000200.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000200.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionpackage(dcp),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Package (DCP), Version 1.0",
            "id": "fdd000200",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000200.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000200.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionpackage(dcp),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Package (DCP), Version 1.0",
            "id": "fdd000200",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000200.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000200.xml"
            }
        },
        {
            "digitalcinemainitiativedistributionpackage(dcp),version1.0": "",
            "name": "Digital Cinema Initiative Distribution Package (DCP), Version 1.0",
            "id": "fdd000200",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000200.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000200.xml"
            }
        },
        {
            "mxffile,op1a,mpeg2ingenericcontainer": "",
            "name": "MXF File, OP1a, MPEG-2 in Generic Container",
            "id": "fdd000204",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000204.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000204.xml"
            }
        },
        {
            "mxffile,op1a,mpeg2ingenericcontainer": "",
            "name": "MXF File, OP1a, MPEG-2 in Generic Container",
            "id": "fdd000204",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000204.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000204.xml"
            }
        },
        {
            "mxffile,op1a,mpeg2ingenericcontainer": "",
            "name": "MXF File, OP1a, MPEG-2 in Generic Container",
            "id": "fdd000204",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000204.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000204.xml"
            }
        },
        {
            "mxffile,op1a,mpeg2ingenericcontainer": "",
            "name": "MXF File, OP1a, MPEG-2 in Generic Container",
            "id": "fdd000204",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000204.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000204.xml"
            }
        },
        {
            "mxffile,op1a,mpeg2ingenericcontainer": "",
            "name": "MXF File, OP1a, MPEG-2 in Generic Container",
            "id": "fdd000204",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000204.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000204.xml"
            }
        },
        {
            "mxffile,op1a,mpeg2ingenericcontainer": "",
            "name": "MXF File, OP1a, MPEG-2 in Generic Container",
            "id": "fdd000204",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000204.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000204.xml"
            }
        },
        {
            "mxffile,op1a,mpeg2ingenericcontainer": "",
            "name": "MXF File, OP1a, MPEG-2 in Generic Container",
            "id": "fdd000204",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000204.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000204.xml"
            }
        },
        {
            "mxffile,op1a,mpeg2ingenericcontainer": "",
            "name": "MXF File, OP1a, MPEG-2 in Generic Container",
            "id": "fdd000204",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000204.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000204.xml"
            }
        },
        {
            "mxffile,op1a,losslessjpeg2000ingenericcontainer": "",
            "name": "MXF File, OP1a, Lossless JPEG 2000 in Generic Container",
            "id": "fdd000206",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000206.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000206.xml"
            }
        },
        {
            "mxffile,op1a,losslessjpeg2000ingenericcontainer": "",
            "name": "MXF File, OP1a, Lossless JPEG 2000 in Generic Container",
            "id": "fdd000206",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000206.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000206.xml"
            }
        },
        {
            "mxffile,op1a,losslessjpeg2000ingenericcontainer": "",
            "name": "MXF File, OP1a, Lossless JPEG 2000 in Generic Container",
            "id": "fdd000206",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000206.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000206.xml"
            }
        },
        {
            "mxffile,op1a,losslessjpeg2000ingenericcontainer": "",
            "name": "MXF File, OP1a, Lossless JPEG 2000 in Generic Container",
            "id": "fdd000206",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000206.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000206.xml"
            }
        },
        {
            "mxffile,op1a,losslessjpeg2000ingenericcontainer": "",
            "name": "MXF File, OP1a, Lossless JPEG 2000 in Generic Container",
            "id": "fdd000206",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000206.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000206.xml"
            }
        },
        {
            "mxffile,op1a,losslessjpeg2000ingenericcontainer": "",
            "name": "MXF File, OP1a, Lossless JPEG 2000 in Generic Container",
            "id": "fdd000206",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000206.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000206.xml"
            }
        },
        {
            "mxffile,op1a,losslessjpeg2000ingenericcontainer": "",
            "name": "MXF File, OP1a, Lossless JPEG 2000 in Generic Container",
            "id": "fdd000206",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000206.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000206.xml"
            }
        },
        {
            "mxffile,op1a,losslessjpeg2000ingenericcontainer": "",
            "name": "MXF File, OP1a, Lossless JPEG 2000 in Generic Container",
            "id": "fdd000206",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000206.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000206.xml"
            }
        },
        {
            "aes3digitalaudiointerface,smpteextensions": "",
            "name": "AES3 Digital Audio Interface, SMPTE Extensions",
            "id": "fdd000208",
            "filenamepattern": [
                {
                    "value": "*.aes",
                    "url": "https://www.digipres.org/formats/extensions/#*.aes"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000208.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000208.xml"
            }
        },
        {
            "aes3digitalaudiointerface,smpteextensions": "",
            "name": "AES3 Digital Audio Interface, SMPTE Extensions",
            "id": "fdd000208",
            "filenamepattern": [
                {
                    "value": "*.aes",
                    "url": "https://www.digipres.org/formats/extensions/#*.aes"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000208.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000208.xml"
            }
        },
        {
            "aes3digitalaudiointerface,smpteextensions": "",
            "name": "AES3 Digital Audio Interface, SMPTE Extensions",
            "id": "fdd000208",
            "filenamepattern": [
                {
                    "value": "*.aes",
                    "url": "https://www.digipres.org/formats/extensions/#*.aes"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000208.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000208.xml"
            }
        },
        {
            "aes3digitalaudiointerface,smpteextensions": "",
            "name": "AES3 Digital Audio Interface, SMPTE Extensions",
            "id": "fdd000208",
            "filenamepattern": [
                {
                    "value": "*.aes",
                    "url": "https://www.digipres.org/formats/extensions/#*.aes"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000208.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000208.xml"
            }
        },
        {
            "aes3digitalaudiointerface,smpteextensions": "",
            "name": "AES3 Digital Audio Interface, SMPTE Extensions",
            "id": "fdd000208",
            "filenamepattern": [
                {
                    "value": "*.aes",
                    "url": "https://www.digipres.org/formats/extensions/#*.aes"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000208.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000208.xml"
            }
        },
        {
            "aes3digitalaudiointerface,smpteextensions": "",
            "name": "AES3 Digital Audio Interface, SMPTE Extensions",
            "id": "fdd000208",
            "filenamepattern": [
                {
                    "value": "*.aes",
                    "url": "https://www.digipres.org/formats/extensions/#*.aes"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000208.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000208.xml"
            }
        },
        {
            "aes3digitalaudiointerface,smpteextensions": "",
            "name": "AES3 Digital Audio Interface, SMPTE Extensions",
            "id": "fdd000208",
            "filenamepattern": [
                {
                    "value": "*.aes",
                    "url": "https://www.digipres.org/formats/extensions/#*.aes"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000208.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000208.xml"
            }
        },
        {
            "aes3digitalaudiointerface,smpteextensions": "",
            "name": "AES3 Digital Audio Interface, SMPTE Extensions",
            "id": "fdd000208",
            "filenamepattern": [
                {
                    "value": "*.aes",
                    "url": "https://www.digipres.org/formats/extensions/#*.aes"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000208.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000208.xml"
            }
        },
        {
            "ac3compressedaudio(dolbydigital),revisiona": "",
            "name": "AC-3 Compressed Audio (Dolby Digital), Revision A",
            "id": "fdd000209",
            "filenamepattern": [
                {
                    "value": "*.ac3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ac3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/ac3",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ac3"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000209.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000209.xml"
            }
        },
        {
            "ac3compressedaudio(dolbydigital),revisiona": "",
            "name": "AC-3 Compressed Audio (Dolby Digital), Revision A",
            "id": "fdd000209",
            "filenamepattern": [
                {
                    "value": "*.ac3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ac3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/ac3",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ac3"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000209.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000209.xml"
            }
        },
        {
            "ac3compressedaudio(dolbydigital),revisiona": "",
            "name": "AC-3 Compressed Audio (Dolby Digital), Revision A",
            "id": "fdd000209",
            "filenamepattern": [
                {
                    "value": "*.ac3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ac3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/ac3",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ac3"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000209.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000209.xml"
            }
        },
        {
            "ac3compressedaudio(dolbydigital),revisiona": "",
            "name": "AC-3 Compressed Audio (Dolby Digital), Revision A",
            "id": "fdd000209",
            "filenamepattern": [
                {
                    "value": "*.ac3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ac3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/ac3",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ac3"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000209.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000209.xml"
            }
        },
        {
            "ac3compressedaudio(dolbydigital),revisiona": "",
            "name": "AC-3 Compressed Audio (Dolby Digital), Revision A",
            "id": "fdd000209",
            "filenamepattern": [
                {
                    "value": "*.ac3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ac3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/ac3",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ac3"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000209.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000209.xml"
            }
        },
        {
            "ac3compressedaudio(dolbydigital),revisiona": "",
            "name": "AC-3 Compressed Audio (Dolby Digital), Revision A",
            "id": "fdd000209",
            "filenamepattern": [
                {
                    "value": "*.ac3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ac3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/ac3",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ac3"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000209.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000209.xml"
            }
        },
        {
            "ac3compressedaudio(dolbydigital),revisiona": "",
            "name": "AC-3 Compressed Audio (Dolby Digital), Revision A",
            "id": "fdd000209",
            "filenamepattern": [
                {
                    "value": "*.ac3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ac3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/ac3",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ac3"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000209.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000209.xml"
            }
        },
        {
            "ac3compressedaudio(dolbydigital),revisiona": "",
            "name": "AC-3 Compressed Audio (Dolby Digital), Revision A",
            "id": "fdd000209",
            "filenamepattern": [
                {
                    "value": "*.ac3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ac3"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/ac3",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ac3"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000209.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000209.xml"
            }
        },
        {
            "profile3forjpeg2000,part1,corecoding": "",
            "name": "Profile 3 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000211",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000211.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000211.xml"
            }
        },
        {
            "profile3forjpeg2000,part1,corecoding": "",
            "name": "Profile 3 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000211",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000211.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000211.xml"
            }
        },
        {
            "profile3forjpeg2000,part1,corecoding": "",
            "name": "Profile 3 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000211",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000211.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000211.xml"
            }
        },
        {
            "profile3forjpeg2000,part1,corecoding": "",
            "name": "Profile 3 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000211",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000211.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000211.xml"
            }
        },
        {
            "profile3forjpeg2000,part1,corecoding": "",
            "name": "Profile 3 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000211",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000211.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000211.xml"
            }
        },
        {
            "profile3forjpeg2000,part1,corecoding": "",
            "name": "Profile 3 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000211",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000211.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000211.xml"
            }
        },
        {
            "profile3forjpeg2000,part1,corecoding": "",
            "name": "Profile 3 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000211",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000211.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000211.xml"
            }
        },
        {
            "profile3forjpeg2000,part1,corecoding": "",
            "name": "Profile 3 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000211",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000211.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000211.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile3": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 3",
            "id": "fdd000212",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000212.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000212.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile3": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 3",
            "id": "fdd000212",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000212.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000212.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile3": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 3",
            "id": "fdd000212",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000212.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000212.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile3": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 3",
            "id": "fdd000212",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000212.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000212.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile3": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 3",
            "id": "fdd000212",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000212.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000212.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile3": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 3",
            "id": "fdd000212",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000212.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000212.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile3": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 3",
            "id": "fdd000212",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000212.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000212.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile3": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 3",
            "id": "fdd000212",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000212.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000212.xml"
            }
        },
        {
            "profile4forjpeg2000,part1,corecoding": "",
            "name": "Profile 4 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000213",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000213.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000213.xml"
            }
        },
        {
            "profile4forjpeg2000,part1,corecoding": "",
            "name": "Profile 4 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000213",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000213.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000213.xml"
            }
        },
        {
            "profile4forjpeg2000,part1,corecoding": "",
            "name": "Profile 4 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000213",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000213.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000213.xml"
            }
        },
        {
            "profile4forjpeg2000,part1,corecoding": "",
            "name": "Profile 4 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000213",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000213.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000213.xml"
            }
        },
        {
            "profile4forjpeg2000,part1,corecoding": "",
            "name": "Profile 4 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000213",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000213.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000213.xml"
            }
        },
        {
            "profile4forjpeg2000,part1,corecoding": "",
            "name": "Profile 4 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000213",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000213.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000213.xml"
            }
        },
        {
            "profile4forjpeg2000,part1,corecoding": "",
            "name": "Profile 4 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000213",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000213.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000213.xml"
            }
        },
        {
            "profile4forjpeg2000,part1,corecoding": "",
            "name": "Profile 4 for JPEG 2000, Part 1, Core Coding",
            "id": "fdd000213",
            "filenamepattern": [
                {
                    "value": "*.j2c",
                    "url": "https://www.digipres.org/formats/extensions/#*.j2c"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000213.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000213.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile4": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 4",
            "id": "fdd000214",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000214.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000214.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile4": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 4",
            "id": "fdd000214",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000214.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000214.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile4": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 4",
            "id": "fdd000214",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000214.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000214.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile4": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 4",
            "id": "fdd000214",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000214.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000214.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile4": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 4",
            "id": "fdd000214",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000214.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000214.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile4": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 4",
            "id": "fdd000214",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000214.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000214.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile4": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 4",
            "id": "fdd000214",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000214.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000214.xml"
            }
        },
        {
            "jp2fileformatwithjpeg2000corecoding,profile4": "",
            "name": "JP2 File Format with JPEG 2000 Core Coding, Profile 4",
            "id": "fdd000214",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000214.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000214.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,highprofile": "",
            "name": "MPEG-4, Advanced Video Coding, High Profile",
            "id": "fdd000215",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000215.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000215.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,highprofile": "",
            "name": "MPEG-4, Advanced Video Coding, High Profile",
            "id": "fdd000215",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000215.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000215.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,highprofile": "",
            "name": "MPEG-4, Advanced Video Coding, High Profile",
            "id": "fdd000215",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000215.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000215.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,highprofile": "",
            "name": "MPEG-4, Advanced Video Coding, High Profile",
            "id": "fdd000215",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000215.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000215.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,highprofile": "",
            "name": "MPEG-4, Advanced Video Coding, High Profile",
            "id": "fdd000215",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000215.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000215.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,highprofile": "",
            "name": "MPEG-4, Advanced Video Coding, High Profile",
            "id": "fdd000215",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000215.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000215.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,highprofile": "",
            "name": "MPEG-4, Advanced Video Coding, High Profile",
            "id": "fdd000215",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000215.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000215.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,highprofile": "",
            "name": "MPEG-4, Advanced Video Coding, High Profile",
            "id": "fdd000215",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000215.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000215.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high10profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 10 Profile",
            "id": "fdd000216",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000216.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000216.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high10profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 10 Profile",
            "id": "fdd000216",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000216.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000216.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high10profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 10 Profile",
            "id": "fdd000216",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000216.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000216.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high10profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 10 Profile",
            "id": "fdd000216",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000216.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000216.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high10profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 10 Profile",
            "id": "fdd000216",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000216.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000216.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high10profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 10 Profile",
            "id": "fdd000216",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000216.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000216.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high10profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 10 Profile",
            "id": "fdd000216",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000216.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000216.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high10profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 10 Profile",
            "id": "fdd000216",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000216.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000216.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:2:2profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:2:2 Profile",
            "id": "fdd000217",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000217.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000217.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:2:2profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:2:2 Profile",
            "id": "fdd000217",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000217.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000217.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:2:2profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:2:2 Profile",
            "id": "fdd000217",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000217.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000217.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:2:2profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:2:2 Profile",
            "id": "fdd000217",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000217.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000217.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:2:2profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:2:2 Profile",
            "id": "fdd000217",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000217.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000217.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:2:2profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:2:2 Profile",
            "id": "fdd000217",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000217.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000217.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:2:2profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:2:2 Profile",
            "id": "fdd000217",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000217.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000217.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:2:2profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:2:2 Profile",
            "id": "fdd000217",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000217.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000217.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:4:4profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:4:4 Profile",
            "id": "fdd000218",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000218.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000218.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:4:4profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:4:4 Profile",
            "id": "fdd000218",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000218.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000218.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:4:4profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:4:4 Profile",
            "id": "fdd000218",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000218.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000218.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:4:4profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:4:4 Profile",
            "id": "fdd000218",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000218.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000218.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:4:4profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:4:4 Profile",
            "id": "fdd000218",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000218.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000218.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:4:4profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:4:4 Profile",
            "id": "fdd000218",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000218.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000218.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:4:4profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:4:4 Profile",
            "id": "fdd000218",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000218.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000218.xml"
            }
        },
        {
            "mpeg4,advancedvideocoding,high4:4:4profile": "",
            "name": "MPEG-4, Advanced Video Coding, High 4:4:4 Profile",
            "id": "fdd000218",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000218.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000218.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,mainprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Main Profile",
            "id": "fdd000219",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000219.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000219.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,mainprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Main Profile",
            "id": "fdd000219",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000219.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000219.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,mainprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Main Profile",
            "id": "fdd000219",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000219.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000219.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,mainprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Main Profile",
            "id": "fdd000219",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000219.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000219.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,mainprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Main Profile",
            "id": "fdd000219",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000219.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000219.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,mainprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Main Profile",
            "id": "fdd000219",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000219.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000219.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,mainprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Main Profile",
            "id": "fdd000219",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000219.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000219.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,mainprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Main Profile",
            "id": "fdd000219",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000219.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000219.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,extendedprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Extended Profile",
            "id": "fdd000220",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000220.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000220.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,extendedprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Extended Profile",
            "id": "fdd000220",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000220.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000220.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,extendedprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Extended Profile",
            "id": "fdd000220",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000220.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000220.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,extendedprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Extended Profile",
            "id": "fdd000220",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000220.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000220.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,extendedprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Extended Profile",
            "id": "fdd000220",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000220.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000220.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,extendedprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Extended Profile",
            "id": "fdd000220",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000220.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000220.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,extendedprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Extended Profile",
            "id": "fdd000220",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000220.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000220.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,extendedprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Extended Profile",
            "id": "fdd000220",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000220.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000220.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,highprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High Profile",
            "id": "fdd000221",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000221.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000221.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,highprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High Profile",
            "id": "fdd000221",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000221.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000221.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,highprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High Profile",
            "id": "fdd000221",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000221.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000221.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,highprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High Profile",
            "id": "fdd000221",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000221.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000221.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,highprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High Profile",
            "id": "fdd000221",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000221.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000221.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,highprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High Profile",
            "id": "fdd000221",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000221.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000221.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,highprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High Profile",
            "id": "fdd000221",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000221.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000221.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,highprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High Profile",
            "id": "fdd000221",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000221.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000221.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high10profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 10 Profile",
            "id": "fdd000222",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000222.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000222.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high10profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 10 Profile",
            "id": "fdd000222",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000222.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000222.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high10profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 10 Profile",
            "id": "fdd000222",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000222.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000222.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high10profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 10 Profile",
            "id": "fdd000222",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000222.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000222.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high10profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 10 Profile",
            "id": "fdd000222",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000222.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000222.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high10profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 10 Profile",
            "id": "fdd000222",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000222.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000222.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high10profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 10 Profile",
            "id": "fdd000222",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000222.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000222.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high10profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 10 Profile",
            "id": "fdd000222",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000222.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000222.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:2:2profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:2:2 Profile",
            "id": "fdd000223",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000223.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000223.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:2:2profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:2:2 Profile",
            "id": "fdd000223",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000223.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000223.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:2:2profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:2:2 Profile",
            "id": "fdd000223",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000223.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000223.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:2:2profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:2:2 Profile",
            "id": "fdd000223",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000223.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000223.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:2:2profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:2:2 Profile",
            "id": "fdd000223",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000223.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000223.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:2:2profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:2:2 Profile",
            "id": "fdd000223",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000223.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000223.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:2:2profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:2:2 Profile",
            "id": "fdd000223",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000223.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000223.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:2:2profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:2:2 Profile",
            "id": "fdd000223",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000223.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000223.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:4:4profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:4:4 Profile",
            "id": "fdd000224",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000224.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000224.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:4:4profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:4:4 Profile",
            "id": "fdd000224",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000224.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000224.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:4:4profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:4:4 Profile",
            "id": "fdd000224",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000224.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000224.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:4:4profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:4:4 Profile",
            "id": "fdd000224",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000224.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000224.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:4:4profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:4:4 Profile",
            "id": "fdd000224",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000224.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000224.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:4:4profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:4:4 Profile",
            "id": "fdd000224",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000224.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000224.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:4:4profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:4:4 Profile",
            "id": "fdd000224",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000224.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000224.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,high4:4:4profile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, High 4:4:4 Profile",
            "id": "fdd000224",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000224.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000224.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,baselineprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Baseline Profile",
            "id": "fdd000225",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000225.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000225.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,baselineprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Baseline Profile",
            "id": "fdd000225",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000225.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000225.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,baselineprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Baseline Profile",
            "id": "fdd000225",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000225.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000225.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,baselineprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Baseline Profile",
            "id": "fdd000225",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000225.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000225.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,baselineprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Baseline Profile",
            "id": "fdd000225",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000225.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000225.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,baselineprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Baseline Profile",
            "id": "fdd000225",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000225.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000225.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,baselineprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Baseline Profile",
            "id": "fdd000225",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000225.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000225.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withavc,baselineprofile": "",
            "name": "MPEG-4 File Format, V.2, with AVC, Baseline Profile",
            "id": "fdd000225",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000225.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000225.xml"
            }
        },
        {
            "cdf,commondataformat(multidimensionaldatasets)": "",
            "name": "CDF, Common Data Format (multidimensional datasets)",
            "id": "fdd000226",
            "filenamepattern": [
                {
                    "value": "*.cdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000226.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000226.xml"
            }
        },
        {
            "cdf,commondataformat(multidimensionaldatasets)": "",
            "name": "CDF, Common Data Format (multidimensional datasets)",
            "id": "fdd000226",
            "filenamepattern": [
                {
                    "value": "*.cdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000226.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000226.xml"
            }
        },
        {
            "cdf,commondataformat(multidimensionaldatasets)": "",
            "name": "CDF, Common Data Format (multidimensional datasets)",
            "id": "fdd000226",
            "filenamepattern": [
                {
                    "value": "*.cdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000226.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000226.xml"
            }
        },
        {
            "cdf,commondataformat(multidimensionaldatasets)": "",
            "name": "CDF, Common Data Format (multidimensional datasets)",
            "id": "fdd000226",
            "filenamepattern": [
                {
                    "value": "*.cdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000226.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000226.xml"
            }
        },
        {
            "cdf,commondataformat(multidimensionaldatasets)": "",
            "name": "CDF, Common Data Format (multidimensional datasets)",
            "id": "fdd000226",
            "filenamepattern": [
                {
                    "value": "*.cdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000226.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000226.xml"
            }
        },
        {
            "cdf,commondataformat(multidimensionaldatasets)": "",
            "name": "CDF, Common Data Format (multidimensional datasets)",
            "id": "fdd000226",
            "filenamepattern": [
                {
                    "value": "*.cdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000226.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000226.xml"
            }
        },
        {
            "cdf,commondataformat(multidimensionaldatasets)": "",
            "name": "CDF, Common Data Format (multidimensional datasets)",
            "id": "fdd000226",
            "filenamepattern": [
                {
                    "value": "*.cdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000226.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000226.xml"
            }
        },
        {
            "cdf,commondataformat(multidimensionaldatasets)": "",
            "name": "CDF, Common Data Format (multidimensional datasets)",
            "id": "fdd000226",
            "filenamepattern": [
                {
                    "value": "*.cdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000226.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000226.xml"
            }
        },
        {
            "oggflacaudioformat,version1.0": "",
            "name": "Ogg FLAC Audio Format, Version 1.0",
            "id": "fdd000227",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000227.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000227.xml"
            }
        },
        {
            "oggflacaudioformat,version1.0": "",
            "name": "Ogg FLAC Audio Format, Version 1.0",
            "id": "fdd000227",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000227.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000227.xml"
            }
        },
        {
            "oggflacaudioformat,version1.0": "",
            "name": "Ogg FLAC Audio Format, Version 1.0",
            "id": "fdd000227",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000227.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000227.xml"
            }
        },
        {
            "oggflacaudioformat,version1.0": "",
            "name": "Ogg FLAC Audio Format, Version 1.0",
            "id": "fdd000227",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000227.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000227.xml"
            }
        },
        {
            "oggflacaudioformat,version1.0": "",
            "name": "Ogg FLAC Audio Format, Version 1.0",
            "id": "fdd000227",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000227.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000227.xml"
            }
        },
        {
            "oggflacaudioformat,version1.0": "",
            "name": "Ogg FLAC Audio Format, Version 1.0",
            "id": "fdd000227",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000227.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000227.xml"
            }
        },
        {
            "oggflacaudioformat,version1.0": "",
            "name": "Ogg FLAC Audio Format, Version 1.0",
            "id": "fdd000227",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000227.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000227.xml"
            }
        },
        {
            "oggflacaudioformat,version1.0": "",
            "name": "Ogg FLAC Audio Format, Version 1.0",
            "id": "fdd000227",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000227.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000227.xml"
            }
        },
        {
            "hdf4,hierarchicaldataformat,version4andearlier": "",
            "name": "HDF4, Hierarchical Data Format, Version 4 and earlier",
            "id": "fdd000228",
            "filenamepattern": [
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-hdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-hdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000228.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000228.xml"
            }
        },
        {
            "hdf4,hierarchicaldataformat,version4andearlier": "",
            "name": "HDF4, Hierarchical Data Format, Version 4 and earlier",
            "id": "fdd000228",
            "filenamepattern": [
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-hdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-hdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000228.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000228.xml"
            }
        },
        {
            "hdf4,hierarchicaldataformat,version4andearlier": "",
            "name": "HDF4, Hierarchical Data Format, Version 4 and earlier",
            "id": "fdd000228",
            "filenamepattern": [
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-hdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-hdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000228.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000228.xml"
            }
        },
        {
            "hdf4,hierarchicaldataformat,version4andearlier": "",
            "name": "HDF4, Hierarchical Data Format, Version 4 and earlier",
            "id": "fdd000228",
            "filenamepattern": [
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-hdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-hdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000228.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000228.xml"
            }
        },
        {
            "hdf4,hierarchicaldataformat,version4andearlier": "",
            "name": "HDF4, Hierarchical Data Format, Version 4 and earlier",
            "id": "fdd000228",
            "filenamepattern": [
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-hdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-hdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000228.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000228.xml"
            }
        },
        {
            "hdf4,hierarchicaldataformat,version4andearlier": "",
            "name": "HDF4, Hierarchical Data Format, Version 4 and earlier",
            "id": "fdd000228",
            "filenamepattern": [
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-hdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-hdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000228.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000228.xml"
            }
        },
        {
            "hdf4,hierarchicaldataformat,version4andearlier": "",
            "name": "HDF4, Hierarchical Data Format, Version 4 and earlier",
            "id": "fdd000228",
            "filenamepattern": [
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-hdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-hdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000228.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000228.xml"
            }
        },
        {
            "hdf4,hierarchicaldataformat,version4andearlier": "",
            "name": "HDF4, Hierarchical Data Format, Version 4 and earlier",
            "id": "fdd000228",
            "filenamepattern": [
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-hdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-hdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000228.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000228.xml"
            }
        },
        {
            "hdf5,hierarchicaldataformat,version5": "",
            "name": "HDF5, Hierarchical Data Format, Version 5",
            "id": "fdd000229",
            "filenamepattern": [
                {
                    "value": "*.h5",
                    "url": "https://www.digipres.org/formats/extensions/#*.h5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000229.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000229.xml"
            }
        },
        {
            "hdf5,hierarchicaldataformat,version5": "",
            "name": "HDF5, Hierarchical Data Format, Version 5",
            "id": "fdd000229",
            "filenamepattern": [
                {
                    "value": "*.h5",
                    "url": "https://www.digipres.org/formats/extensions/#*.h5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000229.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000229.xml"
            }
        },
        {
            "hdf5,hierarchicaldataformat,version5": "",
            "name": "HDF5, Hierarchical Data Format, Version 5",
            "id": "fdd000229",
            "filenamepattern": [
                {
                    "value": "*.h5",
                    "url": "https://www.digipres.org/formats/extensions/#*.h5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000229.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000229.xml"
            }
        },
        {
            "hdf5,hierarchicaldataformat,version5": "",
            "name": "HDF5, Hierarchical Data Format, Version 5",
            "id": "fdd000229",
            "filenamepattern": [
                {
                    "value": "*.h5",
                    "url": "https://www.digipres.org/formats/extensions/#*.h5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000229.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000229.xml"
            }
        },
        {
            "hdf5,hierarchicaldataformat,version5": "",
            "name": "HDF5, Hierarchical Data Format, Version 5",
            "id": "fdd000229",
            "filenamepattern": [
                {
                    "value": "*.h5",
                    "url": "https://www.digipres.org/formats/extensions/#*.h5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000229.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000229.xml"
            }
        },
        {
            "hdf5,hierarchicaldataformat,version5": "",
            "name": "HDF5, Hierarchical Data Format, Version 5",
            "id": "fdd000229",
            "filenamepattern": [
                {
                    "value": "*.h5",
                    "url": "https://www.digipres.org/formats/extensions/#*.h5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000229.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000229.xml"
            }
        },
        {
            "hdf5,hierarchicaldataformat,version5": "",
            "name": "HDF5, Hierarchical Data Format, Version 5",
            "id": "fdd000229",
            "filenamepattern": [
                {
                    "value": "*.h5",
                    "url": "https://www.digipres.org/formats/extensions/#*.h5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000229.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000229.xml"
            }
        },
        {
            "hdf5,hierarchicaldataformat,version5": "",
            "name": "HDF5, Hierarchical Data Format, Version 5",
            "id": "fdd000229",
            "filenamepattern": [
                {
                    "value": "*.h5",
                    "url": "https://www.digipres.org/formats/extensions/#*.h5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000229.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000229.xml"
            }
        },
        {
            "onebitdeltasigmaaudioencoding(dsd),directstreamdigital": "",
            "name": "One-bit Delta Sigma Audio Encoding (DSD), Direct Stream Digital",
            "id": "fdd000230",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000230.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000230.xml"
            }
        },
        {
            "onebitdeltasigmaaudioencoding(dsd),directstreamdigital": "",
            "name": "One-bit Delta Sigma Audio Encoding (DSD), Direct Stream Digital",
            "id": "fdd000230",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000230.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000230.xml"
            }
        },
        {
            "onebitdeltasigmaaudioencoding(dsd),directstreamdigital": "",
            "name": "One-bit Delta Sigma Audio Encoding (DSD), Direct Stream Digital",
            "id": "fdd000230",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000230.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000230.xml"
            }
        },
        {
            "onebitdeltasigmaaudioencoding(dsd),directstreamdigital": "",
            "name": "One-bit Delta Sigma Audio Encoding (DSD), Direct Stream Digital",
            "id": "fdd000230",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000230.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000230.xml"
            }
        },
        {
            "onebitdeltasigmaaudioencoding(dsd),directstreamdigital": "",
            "name": "One-bit Delta Sigma Audio Encoding (DSD), Direct Stream Digital",
            "id": "fdd000230",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000230.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000230.xml"
            }
        },
        {
            "onebitdeltasigmaaudioencoding(dsd),directstreamdigital": "",
            "name": "One-bit Delta Sigma Audio Encoding (DSD), Direct Stream Digital",
            "id": "fdd000230",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000230.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000230.xml"
            }
        },
        {
            "onebitdeltasigmaaudioencoding(dsd),directstreamdigital": "",
            "name": "One-bit Delta Sigma Audio Encoding (DSD), Direct Stream Digital",
            "id": "fdd000230",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000230.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000230.xml"
            }
        },
        {
            "onebitdeltasigmaaudioencoding(dsd),directstreamdigital": "",
            "name": "One-bit Delta Sigma Audio Encoding (DSD), Direct Stream Digital",
            "id": "fdd000230",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000230.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000230.xml"
            }
        },
        {
            "digitaltheatersystemsaudioformats": "",
            "name": "Digital Theater Systems Audio Formats",
            "id": "fdd000232",
            "filenamepattern": [
                {
                    "value": "*.dts",
                    "url": "https://www.digipres.org/formats/extensions/#*.dts"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000232.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000232.xml"
            }
        },
        {
            "digitaltheatersystemsaudioformats": "",
            "name": "Digital Theater Systems Audio Formats",
            "id": "fdd000232",
            "filenamepattern": [
                {
                    "value": "*.dts",
                    "url": "https://www.digipres.org/formats/extensions/#*.dts"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000232.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000232.xml"
            }
        },
        {
            "digitaltheatersystemsaudioformats": "",
            "name": "Digital Theater Systems Audio Formats",
            "id": "fdd000232",
            "filenamepattern": [
                {
                    "value": "*.dts",
                    "url": "https://www.digipres.org/formats/extensions/#*.dts"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000232.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000232.xml"
            }
        },
        {
            "digitaltheatersystemsaudioformats": "",
            "name": "Digital Theater Systems Audio Formats",
            "id": "fdd000232",
            "filenamepattern": [
                {
                    "value": "*.dts",
                    "url": "https://www.digipres.org/formats/extensions/#*.dts"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000232.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000232.xml"
            }
        },
        {
            "digitaltheatersystemsaudioformats": "",
            "name": "Digital Theater Systems Audio Formats",
            "id": "fdd000232",
            "filenamepattern": [
                {
                    "value": "*.dts",
                    "url": "https://www.digipres.org/formats/extensions/#*.dts"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000232.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000232.xml"
            }
        },
        {
            "digitaltheatersystemsaudioformats": "",
            "name": "Digital Theater Systems Audio Formats",
            "id": "fdd000232",
            "filenamepattern": [
                {
                    "value": "*.dts",
                    "url": "https://www.digipres.org/formats/extensions/#*.dts"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000232.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000232.xml"
            }
        },
        {
            "digitaltheatersystemsaudioformats": "",
            "name": "Digital Theater Systems Audio Formats",
            "id": "fdd000232",
            "filenamepattern": [
                {
                    "value": "*.dts",
                    "url": "https://www.digipres.org/formats/extensions/#*.dts"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000232.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000232.xml"
            }
        },
        {
            "digitaltheatersystemsaudioformats": "",
            "name": "Digital Theater Systems Audio Formats",
            "id": "fdd000232",
            "filenamepattern": [
                {
                    "value": "*.dts",
                    "url": "https://www.digipres.org/formats/extensions/#*.dts"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000232.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000232.xml"
            }
        },
        {
            "aac(mpeg4)lowcomplexityobject": "",
            "name": "AAC (MPEG-4) Low Complexity Object",
            "id": "fdd000233",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000233.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000233.xml"
            }
        },
        {
            "aac(mpeg4)lowcomplexityobject": "",
            "name": "AAC (MPEG-4) Low Complexity Object",
            "id": "fdd000233",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000233.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000233.xml"
            }
        },
        {
            "aac(mpeg4)lowcomplexityobject": "",
            "name": "AAC (MPEG-4) Low Complexity Object",
            "id": "fdd000233",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000233.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000233.xml"
            }
        },
        {
            "aac(mpeg4)lowcomplexityobject": "",
            "name": "AAC (MPEG-4) Low Complexity Object",
            "id": "fdd000233",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000233.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000233.xml"
            }
        },
        {
            "aac(mpeg4)lowcomplexityobject": "",
            "name": "AAC (MPEG-4) Low Complexity Object",
            "id": "fdd000233",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000233.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000233.xml"
            }
        },
        {
            "aac(mpeg4)lowcomplexityobject": "",
            "name": "AAC (MPEG-4) Low Complexity Object",
            "id": "fdd000233",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000233.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000233.xml"
            }
        },
        {
            "aac(mpeg4)lowcomplexityobject": "",
            "name": "AAC (MPEG-4) Low Complexity Object",
            "id": "fdd000233",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000233.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000233.xml"
            }
        },
        {
            "aac(mpeg4)lowcomplexityobject": "",
            "name": "AAC (MPEG-4) Low Complexity Object",
            "id": "fdd000233",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000233.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000233.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withadvancedaudiocoding": "",
            "name": "MPEG-4 File Format, V.2, with Advanced Audio Coding",
            "id": "fdd000234",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000234.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000234.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withadvancedaudiocoding": "",
            "name": "MPEG-4 File Format, V.2, with Advanced Audio Coding",
            "id": "fdd000234",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000234.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000234.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withadvancedaudiocoding": "",
            "name": "MPEG-4 File Format, V.2, with Advanced Audio Coding",
            "id": "fdd000234",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000234.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000234.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withadvancedaudiocoding": "",
            "name": "MPEG-4 File Format, V.2, with Advanced Audio Coding",
            "id": "fdd000234",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000234.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000234.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withadvancedaudiocoding": "",
            "name": "MPEG-4 File Format, V.2, with Advanced Audio Coding",
            "id": "fdd000234",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000234.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000234.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withadvancedaudiocoding": "",
            "name": "MPEG-4 File Format, V.2, with Advanced Audio Coding",
            "id": "fdd000234",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000234.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000234.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withadvancedaudiocoding": "",
            "name": "MPEG-4 File Format, V.2, with Advanced Audio Coding",
            "id": "fdd000234",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000234.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000234.xml"
            }
        },
        {
            "mpeg4fileformat,v.2,withadvancedaudiocoding": "",
            "name": "MPEG-4 File Format, V.2, with Advanced Audio Coding",
            "id": "fdd000234",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000234.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000234.xml"
            }
        },
        {
            "arc_ia,internetarchivearcfileformat": "",
            "name": "ARC_IA, Internet Archive ARC file format",
            "id": "fdd000235",
            "filenamepattern": [
                {
                    "value": "*.arc",
                    "url": "https://www.digipres.org/formats/extensions/#*.arc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000235.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000235.xml"
            }
        },
        {
            "arc_ia,internetarchivearcfileformat": "",
            "name": "ARC_IA, Internet Archive ARC file format",
            "id": "fdd000235",
            "filenamepattern": [
                {
                    "value": "*.arc",
                    "url": "https://www.digipres.org/formats/extensions/#*.arc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000235.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000235.xml"
            }
        },
        {
            "arc_ia,internetarchivearcfileformat": "",
            "name": "ARC_IA, Internet Archive ARC file format",
            "id": "fdd000235",
            "filenamepattern": [
                {
                    "value": "*.arc",
                    "url": "https://www.digipres.org/formats/extensions/#*.arc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000235.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000235.xml"
            }
        },
        {
            "arc_ia,internetarchivearcfileformat": "",
            "name": "ARC_IA, Internet Archive ARC file format",
            "id": "fdd000235",
            "filenamepattern": [
                {
                    "value": "*.arc",
                    "url": "https://www.digipres.org/formats/extensions/#*.arc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000235.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000235.xml"
            }
        },
        {
            "arc_ia,internetarchivearcfileformat": "",
            "name": "ARC_IA, Internet Archive ARC file format",
            "id": "fdd000235",
            "filenamepattern": [
                {
                    "value": "*.arc",
                    "url": "https://www.digipres.org/formats/extensions/#*.arc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000235.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000235.xml"
            }
        },
        {
            "arc_ia,internetarchivearcfileformat": "",
            "name": "ARC_IA, Internet Archive ARC file format",
            "id": "fdd000235",
            "filenamepattern": [
                {
                    "value": "*.arc",
                    "url": "https://www.digipres.org/formats/extensions/#*.arc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000235.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000235.xml"
            }
        },
        {
            "arc_ia,internetarchivearcfileformat": "",
            "name": "ARC_IA, Internet Archive ARC file format",
            "id": "fdd000235",
            "filenamepattern": [
                {
                    "value": "*.arc",
                    "url": "https://www.digipres.org/formats/extensions/#*.arc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000235.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000235.xml"
            }
        },
        {
            "arc_ia,internetarchivearcfileformat": "",
            "name": "ARC_IA, Internet Archive ARC file format",
            "id": "fdd000235",
            "filenamepattern": [
                {
                    "value": "*.arc",
                    "url": "https://www.digipres.org/formats/extensions/#*.arc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000235.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000235.xml"
            }
        },
        {
            "warc,webarchivefileformat": "",
            "name": "WARC, Web ARChive file format",
            "id": "fdd000236",
            "filenamepattern": [
                {
                    "value": "*.warc",
                    "url": "https://www.digipres.org/formats/extensions/#*.warc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/warc",
                    "url": "https://www.digipres.org/formats/mime-types/#application/warc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000236.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000236.xml"
            }
        },
        {
            "warc,webarchivefileformat": "",
            "name": "WARC, Web ARChive file format",
            "id": "fdd000236",
            "filenamepattern": [
                {
                    "value": "*.warc",
                    "url": "https://www.digipres.org/formats/extensions/#*.warc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/warc",
                    "url": "https://www.digipres.org/formats/mime-types/#application/warc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000236.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000236.xml"
            }
        },
        {
            "warc,webarchivefileformat": "",
            "name": "WARC, Web ARChive file format",
            "id": "fdd000236",
            "filenamepattern": [
                {
                    "value": "*.warc",
                    "url": "https://www.digipres.org/formats/extensions/#*.warc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/warc",
                    "url": "https://www.digipres.org/formats/mime-types/#application/warc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000236.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000236.xml"
            }
        },
        {
            "warc,webarchivefileformat": "",
            "name": "WARC, Web ARChive file format",
            "id": "fdd000236",
            "filenamepattern": [
                {
                    "value": "*.warc",
                    "url": "https://www.digipres.org/formats/extensions/#*.warc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/warc",
                    "url": "https://www.digipres.org/formats/mime-types/#application/warc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000236.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000236.xml"
            }
        },
        {
            "warc,webarchivefileformat": "",
            "name": "WARC, Web ARChive file format",
            "id": "fdd000236",
            "filenamepattern": [
                {
                    "value": "*.warc",
                    "url": "https://www.digipres.org/formats/extensions/#*.warc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/warc",
                    "url": "https://www.digipres.org/formats/mime-types/#application/warc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000236.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000236.xml"
            }
        },
        {
            "warc,webarchivefileformat": "",
            "name": "WARC, Web ARChive file format",
            "id": "fdd000236",
            "filenamepattern": [
                {
                    "value": "*.warc",
                    "url": "https://www.digipres.org/formats/extensions/#*.warc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/warc",
                    "url": "https://www.digipres.org/formats/mime-types/#application/warc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000236.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000236.xml"
            }
        },
        {
            "warc,webarchivefileformat": "",
            "name": "WARC, Web ARChive file format",
            "id": "fdd000236",
            "filenamepattern": [
                {
                    "value": "*.warc",
                    "url": "https://www.digipres.org/formats/extensions/#*.warc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/warc",
                    "url": "https://www.digipres.org/formats/mime-types/#application/warc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000236.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000236.xml"
            }
        },
        {
            "warc,webarchivefileformat": "",
            "name": "WARC, Web ARChive file format",
            "id": "fdd000236",
            "filenamepattern": [
                {
                    "value": "*.warc",
                    "url": "https://www.digipres.org/formats/extensions/#*.warc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/warc",
                    "url": "https://www.digipres.org/formats/mime-types/#application/warc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000236.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000236.xml"
            }
        },
        {
            "tiff,pyramid": "",
            "name": "TIFF, Pyramid",
            "id": "fdd000237",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.ptif",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000237.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000237.xml"
            }
        },
        {
            "tiff,pyramid": "",
            "name": "TIFF, Pyramid",
            "id": "fdd000237",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.ptif",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000237.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000237.xml"
            }
        },
        {
            "tiff,pyramid": "",
            "name": "TIFF, Pyramid",
            "id": "fdd000237",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.ptif",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000237.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000237.xml"
            }
        },
        {
            "tiff,pyramid": "",
            "name": "TIFF, Pyramid",
            "id": "fdd000237",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.ptif",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000237.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000237.xml"
            }
        },
        {
            "tiff,pyramid": "",
            "name": "TIFF, Pyramid",
            "id": "fdd000237",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.ptif",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000237.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000237.xml"
            }
        },
        {
            "tiff,pyramid": "",
            "name": "TIFF, Pyramid",
            "id": "fdd000237",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.ptif",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000237.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000237.xml"
            }
        },
        {
            "tiff,pyramid": "",
            "name": "TIFF, Pyramid",
            "id": "fdd000237",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.ptif",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000237.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000237.xml"
            }
        },
        {
            "tiff,pyramid": "",
            "name": "TIFF, Pyramid",
            "id": "fdd000237",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.ptif",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000237.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000237.xml"
            }
        },
        {
            "aviopendmlfileformatwithv210videoencoding": "",
            "name": "AVI OpenDML File Format with V210 Video Encoding",
            "id": "fdd000240",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000240.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000240.xml"
            }
        },
        {
            "aviopendmlfileformatwithv210videoencoding": "",
            "name": "AVI OpenDML File Format with V210 Video Encoding",
            "id": "fdd000240",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000240.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000240.xml"
            }
        },
        {
            "aviopendmlfileformatwithv210videoencoding": "",
            "name": "AVI OpenDML File Format with V210 Video Encoding",
            "id": "fdd000240",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000240.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000240.xml"
            }
        },
        {
            "aviopendmlfileformatwithv210videoencoding": "",
            "name": "AVI OpenDML File Format with V210 Video Encoding",
            "id": "fdd000240",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000240.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000240.xml"
            }
        },
        {
            "aviopendmlfileformatwithv210videoencoding": "",
            "name": "AVI OpenDML File Format with V210 Video Encoding",
            "id": "fdd000240",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000240.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000240.xml"
            }
        },
        {
            "aviopendmlfileformatwithv210videoencoding": "",
            "name": "AVI OpenDML File Format with V210 Video Encoding",
            "id": "fdd000240",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000240.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000240.xml"
            }
        },
        {
            "aviopendmlfileformatwithv210videoencoding": "",
            "name": "AVI OpenDML File Format with V210 Video Encoding",
            "id": "fdd000240",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000240.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000240.xml"
            }
        },
        {
            "aviopendmlfileformatwithv210videoencoding": "",
            "name": "AVI OpenDML File Format with V210 Video Encoding",
            "id": "fdd000240",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000240.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000240.xml"
            }
        },
        {
            "camerarawformats(groupdescription)": "",
            "name": "Camera Raw Formats (Group Description)",
            "id": "fdd000241",
            "filenamepattern": [
                {
                    "value": "*.dcr",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcr"
                },
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                },
                {
                    "value": "*.arw",
                    "url": "https://www.digipres.org/formats/extensions/#*.arw"
                },
                {
                    "value": "*.kdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.kdc"
                },
                {
                    "value": "*.cap",
                    "url": "https://www.digipres.org/formats/extensions/#*.cap"
                },
                {
                    "value": "*.mrw",
                    "url": "https://www.digipres.org/formats/extensions/#*.mrw"
                },
                {
                    "value": "*.srf",
                    "url": "https://www.digipres.org/formats/extensions/#*.srf"
                },
                {
                    "value": "*.x3f",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3f"
                },
                {
                    "value": "*.cr2",
                    "url": "https://www.digipres.org/formats/extensions/#*.cr2"
                },
                {
                    "value": "*.crw",
                    "url": "https://www.digipres.org/formats/extensions/#*.crw"
                },
                {
                    "value": "*.iiq",
                    "url": "https://www.digipres.org/formats/extensions/#*.iiq"
                },
                {
                    "value": "*.orf",
                    "url": "https://www.digipres.org/formats/extensions/#*.orf"
                },
                {
                    "value": "*.capture",
                    "url": "https://www.digipres.org/formats/extensions/#*.capture"
                },
                {
                    "value": "*.raf",
                    "url": "https://www.digipres.org/formats/extensions/#*.raf"
                },
                {
                    "value": "*.nef",
                    "url": "https://www.digipres.org/formats/extensions/#*.nef"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-nikon-nef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-nikon-nef"
                },
                {
                    "value": "image/x-sony-srf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-sony-srf"
                },
                {
                    "value": "image/x-pentax-pef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pentax-pef"
                },
                {
                    "value": "image/x-minolta-mrw",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-minolta-mrw"
                },
                {
                    "value": "image/x-olympus-orf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-olympus-orf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000241.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000241.xml"
            }
        },
        {
            "camerarawformats(groupdescription)": "",
            "name": "Camera Raw Formats (Group Description)",
            "id": "fdd000241",
            "filenamepattern": [
                {
                    "value": "*.dcr",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcr"
                },
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                },
                {
                    "value": "*.arw",
                    "url": "https://www.digipres.org/formats/extensions/#*.arw"
                },
                {
                    "value": "*.kdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.kdc"
                },
                {
                    "value": "*.cap",
                    "url": "https://www.digipres.org/formats/extensions/#*.cap"
                },
                {
                    "value": "*.mrw",
                    "url": "https://www.digipres.org/formats/extensions/#*.mrw"
                },
                {
                    "value": "*.srf",
                    "url": "https://www.digipres.org/formats/extensions/#*.srf"
                },
                {
                    "value": "*.x3f",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3f"
                },
                {
                    "value": "*.cr2",
                    "url": "https://www.digipres.org/formats/extensions/#*.cr2"
                },
                {
                    "value": "*.crw",
                    "url": "https://www.digipres.org/formats/extensions/#*.crw"
                },
                {
                    "value": "*.iiq",
                    "url": "https://www.digipres.org/formats/extensions/#*.iiq"
                },
                {
                    "value": "*.orf",
                    "url": "https://www.digipres.org/formats/extensions/#*.orf"
                },
                {
                    "value": "*.capture",
                    "url": "https://www.digipres.org/formats/extensions/#*.capture"
                },
                {
                    "value": "*.raf",
                    "url": "https://www.digipres.org/formats/extensions/#*.raf"
                },
                {
                    "value": "*.nef",
                    "url": "https://www.digipres.org/formats/extensions/#*.nef"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-nikon-nef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-nikon-nef"
                },
                {
                    "value": "image/x-sony-srf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-sony-srf"
                },
                {
                    "value": "image/x-pentax-pef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pentax-pef"
                },
                {
                    "value": "image/x-minolta-mrw",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-minolta-mrw"
                },
                {
                    "value": "image/x-olympus-orf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-olympus-orf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000241.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000241.xml"
            }
        },
        {
            "camerarawformats(groupdescription)": "",
            "name": "Camera Raw Formats (Group Description)",
            "id": "fdd000241",
            "filenamepattern": [
                {
                    "value": "*.dcr",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcr"
                },
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                },
                {
                    "value": "*.arw",
                    "url": "https://www.digipres.org/formats/extensions/#*.arw"
                },
                {
                    "value": "*.kdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.kdc"
                },
                {
                    "value": "*.cap",
                    "url": "https://www.digipres.org/formats/extensions/#*.cap"
                },
                {
                    "value": "*.mrw",
                    "url": "https://www.digipres.org/formats/extensions/#*.mrw"
                },
                {
                    "value": "*.srf",
                    "url": "https://www.digipres.org/formats/extensions/#*.srf"
                },
                {
                    "value": "*.x3f",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3f"
                },
                {
                    "value": "*.cr2",
                    "url": "https://www.digipres.org/formats/extensions/#*.cr2"
                },
                {
                    "value": "*.crw",
                    "url": "https://www.digipres.org/formats/extensions/#*.crw"
                },
                {
                    "value": "*.iiq",
                    "url": "https://www.digipres.org/formats/extensions/#*.iiq"
                },
                {
                    "value": "*.orf",
                    "url": "https://www.digipres.org/formats/extensions/#*.orf"
                },
                {
                    "value": "*.capture",
                    "url": "https://www.digipres.org/formats/extensions/#*.capture"
                },
                {
                    "value": "*.raf",
                    "url": "https://www.digipres.org/formats/extensions/#*.raf"
                },
                {
                    "value": "*.nef",
                    "url": "https://www.digipres.org/formats/extensions/#*.nef"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-nikon-nef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-nikon-nef"
                },
                {
                    "value": "image/x-sony-srf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-sony-srf"
                },
                {
                    "value": "image/x-pentax-pef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pentax-pef"
                },
                {
                    "value": "image/x-minolta-mrw",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-minolta-mrw"
                },
                {
                    "value": "image/x-olympus-orf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-olympus-orf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000241.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000241.xml"
            }
        },
        {
            "camerarawformats(groupdescription)": "",
            "name": "Camera Raw Formats (Group Description)",
            "id": "fdd000241",
            "filenamepattern": [
                {
                    "value": "*.dcr",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcr"
                },
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                },
                {
                    "value": "*.arw",
                    "url": "https://www.digipres.org/formats/extensions/#*.arw"
                },
                {
                    "value": "*.kdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.kdc"
                },
                {
                    "value": "*.cap",
                    "url": "https://www.digipres.org/formats/extensions/#*.cap"
                },
                {
                    "value": "*.mrw",
                    "url": "https://www.digipres.org/formats/extensions/#*.mrw"
                },
                {
                    "value": "*.srf",
                    "url": "https://www.digipres.org/formats/extensions/#*.srf"
                },
                {
                    "value": "*.x3f",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3f"
                },
                {
                    "value": "*.cr2",
                    "url": "https://www.digipres.org/formats/extensions/#*.cr2"
                },
                {
                    "value": "*.crw",
                    "url": "https://www.digipres.org/formats/extensions/#*.crw"
                },
                {
                    "value": "*.iiq",
                    "url": "https://www.digipres.org/formats/extensions/#*.iiq"
                },
                {
                    "value": "*.orf",
                    "url": "https://www.digipres.org/formats/extensions/#*.orf"
                },
                {
                    "value": "*.capture",
                    "url": "https://www.digipres.org/formats/extensions/#*.capture"
                },
                {
                    "value": "*.raf",
                    "url": "https://www.digipres.org/formats/extensions/#*.raf"
                },
                {
                    "value": "*.nef",
                    "url": "https://www.digipres.org/formats/extensions/#*.nef"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-nikon-nef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-nikon-nef"
                },
                {
                    "value": "image/x-sony-srf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-sony-srf"
                },
                {
                    "value": "image/x-pentax-pef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pentax-pef"
                },
                {
                    "value": "image/x-minolta-mrw",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-minolta-mrw"
                },
                {
                    "value": "image/x-olympus-orf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-olympus-orf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000241.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000241.xml"
            }
        },
        {
            "camerarawformats(groupdescription)": "",
            "name": "Camera Raw Formats (Group Description)",
            "id": "fdd000241",
            "filenamepattern": [
                {
                    "value": "*.dcr",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcr"
                },
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                },
                {
                    "value": "*.arw",
                    "url": "https://www.digipres.org/formats/extensions/#*.arw"
                },
                {
                    "value": "*.kdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.kdc"
                },
                {
                    "value": "*.cap",
                    "url": "https://www.digipres.org/formats/extensions/#*.cap"
                },
                {
                    "value": "*.mrw",
                    "url": "https://www.digipres.org/formats/extensions/#*.mrw"
                },
                {
                    "value": "*.srf",
                    "url": "https://www.digipres.org/formats/extensions/#*.srf"
                },
                {
                    "value": "*.x3f",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3f"
                },
                {
                    "value": "*.cr2",
                    "url": "https://www.digipres.org/formats/extensions/#*.cr2"
                },
                {
                    "value": "*.crw",
                    "url": "https://www.digipres.org/formats/extensions/#*.crw"
                },
                {
                    "value": "*.iiq",
                    "url": "https://www.digipres.org/formats/extensions/#*.iiq"
                },
                {
                    "value": "*.orf",
                    "url": "https://www.digipres.org/formats/extensions/#*.orf"
                },
                {
                    "value": "*.capture",
                    "url": "https://www.digipres.org/formats/extensions/#*.capture"
                },
                {
                    "value": "*.raf",
                    "url": "https://www.digipres.org/formats/extensions/#*.raf"
                },
                {
                    "value": "*.nef",
                    "url": "https://www.digipres.org/formats/extensions/#*.nef"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-nikon-nef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-nikon-nef"
                },
                {
                    "value": "image/x-sony-srf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-sony-srf"
                },
                {
                    "value": "image/x-pentax-pef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pentax-pef"
                },
                {
                    "value": "image/x-minolta-mrw",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-minolta-mrw"
                },
                {
                    "value": "image/x-olympus-orf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-olympus-orf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000241.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000241.xml"
            }
        },
        {
            "camerarawformats(groupdescription)": "",
            "name": "Camera Raw Formats (Group Description)",
            "id": "fdd000241",
            "filenamepattern": [
                {
                    "value": "*.dcr",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcr"
                },
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                },
                {
                    "value": "*.arw",
                    "url": "https://www.digipres.org/formats/extensions/#*.arw"
                },
                {
                    "value": "*.kdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.kdc"
                },
                {
                    "value": "*.cap",
                    "url": "https://www.digipres.org/formats/extensions/#*.cap"
                },
                {
                    "value": "*.mrw",
                    "url": "https://www.digipres.org/formats/extensions/#*.mrw"
                },
                {
                    "value": "*.srf",
                    "url": "https://www.digipres.org/formats/extensions/#*.srf"
                },
                {
                    "value": "*.x3f",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3f"
                },
                {
                    "value": "*.cr2",
                    "url": "https://www.digipres.org/formats/extensions/#*.cr2"
                },
                {
                    "value": "*.crw",
                    "url": "https://www.digipres.org/formats/extensions/#*.crw"
                },
                {
                    "value": "*.iiq",
                    "url": "https://www.digipres.org/formats/extensions/#*.iiq"
                },
                {
                    "value": "*.orf",
                    "url": "https://www.digipres.org/formats/extensions/#*.orf"
                },
                {
                    "value": "*.capture",
                    "url": "https://www.digipres.org/formats/extensions/#*.capture"
                },
                {
                    "value": "*.raf",
                    "url": "https://www.digipres.org/formats/extensions/#*.raf"
                },
                {
                    "value": "*.nef",
                    "url": "https://www.digipres.org/formats/extensions/#*.nef"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-nikon-nef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-nikon-nef"
                },
                {
                    "value": "image/x-sony-srf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-sony-srf"
                },
                {
                    "value": "image/x-pentax-pef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pentax-pef"
                },
                {
                    "value": "image/x-minolta-mrw",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-minolta-mrw"
                },
                {
                    "value": "image/x-olympus-orf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-olympus-orf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000241.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000241.xml"
            }
        },
        {
            "camerarawformats(groupdescription)": "",
            "name": "Camera Raw Formats (Group Description)",
            "id": "fdd000241",
            "filenamepattern": [
                {
                    "value": "*.dcr",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcr"
                },
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                },
                {
                    "value": "*.arw",
                    "url": "https://www.digipres.org/formats/extensions/#*.arw"
                },
                {
                    "value": "*.kdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.kdc"
                },
                {
                    "value": "*.cap",
                    "url": "https://www.digipres.org/formats/extensions/#*.cap"
                },
                {
                    "value": "*.mrw",
                    "url": "https://www.digipres.org/formats/extensions/#*.mrw"
                },
                {
                    "value": "*.srf",
                    "url": "https://www.digipres.org/formats/extensions/#*.srf"
                },
                {
                    "value": "*.x3f",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3f"
                },
                {
                    "value": "*.cr2",
                    "url": "https://www.digipres.org/formats/extensions/#*.cr2"
                },
                {
                    "value": "*.crw",
                    "url": "https://www.digipres.org/formats/extensions/#*.crw"
                },
                {
                    "value": "*.iiq",
                    "url": "https://www.digipres.org/formats/extensions/#*.iiq"
                },
                {
                    "value": "*.orf",
                    "url": "https://www.digipres.org/formats/extensions/#*.orf"
                },
                {
                    "value": "*.capture",
                    "url": "https://www.digipres.org/formats/extensions/#*.capture"
                },
                {
                    "value": "*.raf",
                    "url": "https://www.digipres.org/formats/extensions/#*.raf"
                },
                {
                    "value": "*.nef",
                    "url": "https://www.digipres.org/formats/extensions/#*.nef"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-nikon-nef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-nikon-nef"
                },
                {
                    "value": "image/x-sony-srf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-sony-srf"
                },
                {
                    "value": "image/x-pentax-pef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pentax-pef"
                },
                {
                    "value": "image/x-minolta-mrw",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-minolta-mrw"
                },
                {
                    "value": "image/x-olympus-orf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-olympus-orf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000241.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000241.xml"
            }
        },
        {
            "camerarawformats(groupdescription)": "",
            "name": "Camera Raw Formats (Group Description)",
            "id": "fdd000241",
            "filenamepattern": [
                {
                    "value": "*.dcr",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcr"
                },
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                },
                {
                    "value": "*.arw",
                    "url": "https://www.digipres.org/formats/extensions/#*.arw"
                },
                {
                    "value": "*.kdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.kdc"
                },
                {
                    "value": "*.cap",
                    "url": "https://www.digipres.org/formats/extensions/#*.cap"
                },
                {
                    "value": "*.mrw",
                    "url": "https://www.digipres.org/formats/extensions/#*.mrw"
                },
                {
                    "value": "*.srf",
                    "url": "https://www.digipres.org/formats/extensions/#*.srf"
                },
                {
                    "value": "*.x3f",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3f"
                },
                {
                    "value": "*.cr2",
                    "url": "https://www.digipres.org/formats/extensions/#*.cr2"
                },
                {
                    "value": "*.crw",
                    "url": "https://www.digipres.org/formats/extensions/#*.crw"
                },
                {
                    "value": "*.iiq",
                    "url": "https://www.digipres.org/formats/extensions/#*.iiq"
                },
                {
                    "value": "*.orf",
                    "url": "https://www.digipres.org/formats/extensions/#*.orf"
                },
                {
                    "value": "*.capture",
                    "url": "https://www.digipres.org/formats/extensions/#*.capture"
                },
                {
                    "value": "*.raf",
                    "url": "https://www.digipres.org/formats/extensions/#*.raf"
                },
                {
                    "value": "*.nef",
                    "url": "https://www.digipres.org/formats/extensions/#*.nef"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-nikon-nef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-nikon-nef"
                },
                {
                    "value": "image/x-sony-srf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-sony-srf"
                },
                {
                    "value": "image/x-pentax-pef",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pentax-pef"
                },
                {
                    "value": "image/x-minolta-mrw",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-minolta-mrw"
                },
                {
                    "value": "image/x-olympus-orf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-olympus-orf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000241.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000241.xml"
            }
        },
        {
            "biif,basicimageinterchangeformat": "",
            "name": "BIIF, Basic Image Interchange Format",
            "id": "fdd000242",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000242.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000242.xml"
            }
        },
        {
            "biif,basicimageinterchangeformat": "",
            "name": "BIIF, Basic Image Interchange Format",
            "id": "fdd000242",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000242.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000242.xml"
            }
        },
        {
            "biif,basicimageinterchangeformat": "",
            "name": "BIIF, Basic Image Interchange Format",
            "id": "fdd000242",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000242.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000242.xml"
            }
        },
        {
            "biif,basicimageinterchangeformat": "",
            "name": "BIIF, Basic Image Interchange Format",
            "id": "fdd000242",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000242.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000242.xml"
            }
        },
        {
            "biif,basicimageinterchangeformat": "",
            "name": "BIIF, Basic Image Interchange Format",
            "id": "fdd000242",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000242.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000242.xml"
            }
        },
        {
            "biif,basicimageinterchangeformat": "",
            "name": "BIIF, Basic Image Interchange Format",
            "id": "fdd000242",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000242.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000242.xml"
            }
        },
        {
            "biif,basicimageinterchangeformat": "",
            "name": "BIIF, Basic Image Interchange Format",
            "id": "fdd000242",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000242.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000242.xml"
            }
        },
        {
            "biif,basicimageinterchangeformat": "",
            "name": "BIIF, Basic Image Interchange Format",
            "id": "fdd000242",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000242.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000242.xml"
            }
        },
        {
            "hdphoto,version1.0(windowsmediaphoto)": "",
            "name": "HD Photo, Version 1.0 (Windows Media Photo)",
            "id": "fdd000243",
            "filenamepattern": [
                {
                    "value": "*.hdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdp"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000243.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000243.xml"
            }
        },
        {
            "hdphoto,version1.0(windowsmediaphoto)": "",
            "name": "HD Photo, Version 1.0 (Windows Media Photo)",
            "id": "fdd000243",
            "filenamepattern": [
                {
                    "value": "*.hdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdp"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000243.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000243.xml"
            }
        },
        {
            "hdphoto,version1.0(windowsmediaphoto)": "",
            "name": "HD Photo, Version 1.0 (Windows Media Photo)",
            "id": "fdd000243",
            "filenamepattern": [
                {
                    "value": "*.hdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdp"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000243.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000243.xml"
            }
        },
        {
            "hdphoto,version1.0(windowsmediaphoto)": "",
            "name": "HD Photo, Version 1.0 (Windows Media Photo)",
            "id": "fdd000243",
            "filenamepattern": [
                {
                    "value": "*.hdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdp"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000243.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000243.xml"
            }
        },
        {
            "hdphoto,version1.0(windowsmediaphoto)": "",
            "name": "HD Photo, Version 1.0 (Windows Media Photo)",
            "id": "fdd000243",
            "filenamepattern": [
                {
                    "value": "*.hdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdp"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000243.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000243.xml"
            }
        },
        {
            "hdphoto,version1.0(windowsmediaphoto)": "",
            "name": "HD Photo, Version 1.0 (Windows Media Photo)",
            "id": "fdd000243",
            "filenamepattern": [
                {
                    "value": "*.hdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdp"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000243.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000243.xml"
            }
        },
        {
            "hdphoto,version1.0(windowsmediaphoto)": "",
            "name": "HD Photo, Version 1.0 (Windows Media Photo)",
            "id": "fdd000243",
            "filenamepattern": [
                {
                    "value": "*.hdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdp"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000243.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000243.xml"
            }
        },
        {
            "hdphoto,version1.0(windowsmediaphoto)": "",
            "name": "HD Photo, Version 1.0 (Windows Media Photo)",
            "id": "fdd000243",
            "filenamepattern": [
                {
                    "value": "*.hdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdp"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000243.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000243.xml"
            }
        },
        {
            "jpegxrimageencoding": "",
            "name": "JPEG XR Image Encoding",
            "id": "fdd000244",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000244.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000244.xml"
            }
        },
        {
            "jpegxrimageencoding": "",
            "name": "JPEG XR Image Encoding",
            "id": "fdd000244",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000244.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000244.xml"
            }
        },
        {
            "jpegxrimageencoding": "",
            "name": "JPEG XR Image Encoding",
            "id": "fdd000244",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000244.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000244.xml"
            }
        },
        {
            "jpegxrimageencoding": "",
            "name": "JPEG XR Image Encoding",
            "id": "fdd000244",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000244.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000244.xml"
            }
        },
        {
            "jpegxrimageencoding": "",
            "name": "JPEG XR Image Encoding",
            "id": "fdd000244",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000244.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000244.xml"
            }
        },
        {
            "jpegxrimageencoding": "",
            "name": "JPEG XR Image Encoding",
            "id": "fdd000244",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000244.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000244.xml"
            }
        },
        {
            "jpegxrimageencoding": "",
            "name": "JPEG XR Image Encoding",
            "id": "fdd000244",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000244.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000244.xml"
            }
        },
        {
            "jpegxrimageencoding": "",
            "name": "JPEG XR Image Encoding",
            "id": "fdd000244",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000244.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000244.xml"
            }
        },
        {
            "dsdiff,directstreamdigitalinterchangefileformat,version1.5": "",
            "name": "DSDIFF, Direct Stream Digital Interchange File Format, Version 1.5",
            "id": "fdd000245",
            "filenamepattern": [
                {
                    "value": "*.dft",
                    "url": "https://www.digipres.org/formats/extensions/#*.dft"
                },
                {
                    "value": "*.df5",
                    "url": "https://www.digipres.org/formats/extensions/#*.df5"
                },
                {
                    "value": "*.df2",
                    "url": "https://www.digipres.org/formats/extensions/#*.df2"
                },
                {
                    "value": "*.df3",
                    "url": "https://www.digipres.org/formats/extensions/#*.df3"
                },
                {
                    "value": "*.df9",
                    "url": "https://www.digipres.org/formats/extensions/#*.df9"
                },
                {
                    "value": "*.df8",
                    "url": "https://www.digipres.org/formats/extensions/#*.df8"
                },
                {
                    "value": "*.dff",
                    "url": "https://www.digipres.org/formats/extensions/#*.dff"
                },
                {
                    "value": "*.df4",
                    "url": "https://www.digipres.org/formats/extensions/#*.df4"
                },
                {
                    "value": "*.df7",
                    "url": "https://www.digipres.org/formats/extensions/#*.df7"
                },
                {
                    "value": "*.df1",
                    "url": "https://www.digipres.org/formats/extensions/#*.df1"
                },
                {
                    "value": "*.df6",
                    "url": "https://www.digipres.org/formats/extensions/#*.df6"
                },
                {
                    "value": "*.df0",
                    "url": "https://www.digipres.org/formats/extensions/#*.df0"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000245.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000245.xml"
            }
        },
        {
            "dsdiff,directstreamdigitalinterchangefileformat,version1.5": "",
            "name": "DSDIFF, Direct Stream Digital Interchange File Format, Version 1.5",
            "id": "fdd000245",
            "filenamepattern": [
                {
                    "value": "*.dft",
                    "url": "https://www.digipres.org/formats/extensions/#*.dft"
                },
                {
                    "value": "*.df5",
                    "url": "https://www.digipres.org/formats/extensions/#*.df5"
                },
                {
                    "value": "*.df2",
                    "url": "https://www.digipres.org/formats/extensions/#*.df2"
                },
                {
                    "value": "*.df3",
                    "url": "https://www.digipres.org/formats/extensions/#*.df3"
                },
                {
                    "value": "*.df9",
                    "url": "https://www.digipres.org/formats/extensions/#*.df9"
                },
                {
                    "value": "*.df8",
                    "url": "https://www.digipres.org/formats/extensions/#*.df8"
                },
                {
                    "value": "*.dff",
                    "url": "https://www.digipres.org/formats/extensions/#*.dff"
                },
                {
                    "value": "*.df4",
                    "url": "https://www.digipres.org/formats/extensions/#*.df4"
                },
                {
                    "value": "*.df7",
                    "url": "https://www.digipres.org/formats/extensions/#*.df7"
                },
                {
                    "value": "*.df1",
                    "url": "https://www.digipres.org/formats/extensions/#*.df1"
                },
                {
                    "value": "*.df6",
                    "url": "https://www.digipres.org/formats/extensions/#*.df6"
                },
                {
                    "value": "*.df0",
                    "url": "https://www.digipres.org/formats/extensions/#*.df0"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000245.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000245.xml"
            }
        },
        {
            "dsdiff,directstreamdigitalinterchangefileformat,version1.5": "",
            "name": "DSDIFF, Direct Stream Digital Interchange File Format, Version 1.5",
            "id": "fdd000245",
            "filenamepattern": [
                {
                    "value": "*.dft",
                    "url": "https://www.digipres.org/formats/extensions/#*.dft"
                },
                {
                    "value": "*.df5",
                    "url": "https://www.digipres.org/formats/extensions/#*.df5"
                },
                {
                    "value": "*.df2",
                    "url": "https://www.digipres.org/formats/extensions/#*.df2"
                },
                {
                    "value": "*.df3",
                    "url": "https://www.digipres.org/formats/extensions/#*.df3"
                },
                {
                    "value": "*.df9",
                    "url": "https://www.digipres.org/formats/extensions/#*.df9"
                },
                {
                    "value": "*.df8",
                    "url": "https://www.digipres.org/formats/extensions/#*.df8"
                },
                {
                    "value": "*.dff",
                    "url": "https://www.digipres.org/formats/extensions/#*.dff"
                },
                {
                    "value": "*.df4",
                    "url": "https://www.digipres.org/formats/extensions/#*.df4"
                },
                {
                    "value": "*.df7",
                    "url": "https://www.digipres.org/formats/extensions/#*.df7"
                },
                {
                    "value": "*.df1",
                    "url": "https://www.digipres.org/formats/extensions/#*.df1"
                },
                {
                    "value": "*.df6",
                    "url": "https://www.digipres.org/formats/extensions/#*.df6"
                },
                {
                    "value": "*.df0",
                    "url": "https://www.digipres.org/formats/extensions/#*.df0"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000245.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000245.xml"
            }
        },
        {
            "dsdiff,directstreamdigitalinterchangefileformat,version1.5": "",
            "name": "DSDIFF, Direct Stream Digital Interchange File Format, Version 1.5",
            "id": "fdd000245",
            "filenamepattern": [
                {
                    "value": "*.dft",
                    "url": "https://www.digipres.org/formats/extensions/#*.dft"
                },
                {
                    "value": "*.df5",
                    "url": "https://www.digipres.org/formats/extensions/#*.df5"
                },
                {
                    "value": "*.df2",
                    "url": "https://www.digipres.org/formats/extensions/#*.df2"
                },
                {
                    "value": "*.df3",
                    "url": "https://www.digipres.org/formats/extensions/#*.df3"
                },
                {
                    "value": "*.df9",
                    "url": "https://www.digipres.org/formats/extensions/#*.df9"
                },
                {
                    "value": "*.df8",
                    "url": "https://www.digipres.org/formats/extensions/#*.df8"
                },
                {
                    "value": "*.dff",
                    "url": "https://www.digipres.org/formats/extensions/#*.dff"
                },
                {
                    "value": "*.df4",
                    "url": "https://www.digipres.org/formats/extensions/#*.df4"
                },
                {
                    "value": "*.df7",
                    "url": "https://www.digipres.org/formats/extensions/#*.df7"
                },
                {
                    "value": "*.df1",
                    "url": "https://www.digipres.org/formats/extensions/#*.df1"
                },
                {
                    "value": "*.df6",
                    "url": "https://www.digipres.org/formats/extensions/#*.df6"
                },
                {
                    "value": "*.df0",
                    "url": "https://www.digipres.org/formats/extensions/#*.df0"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000245.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000245.xml"
            }
        },
        {
            "dsdiff,directstreamdigitalinterchangefileformat,version1.5": "",
            "name": "DSDIFF, Direct Stream Digital Interchange File Format, Version 1.5",
            "id": "fdd000245",
            "filenamepattern": [
                {
                    "value": "*.dft",
                    "url": "https://www.digipres.org/formats/extensions/#*.dft"
                },
                {
                    "value": "*.df5",
                    "url": "https://www.digipres.org/formats/extensions/#*.df5"
                },
                {
                    "value": "*.df2",
                    "url": "https://www.digipres.org/formats/extensions/#*.df2"
                },
                {
                    "value": "*.df3",
                    "url": "https://www.digipres.org/formats/extensions/#*.df3"
                },
                {
                    "value": "*.df9",
                    "url": "https://www.digipres.org/formats/extensions/#*.df9"
                },
                {
                    "value": "*.df8",
                    "url": "https://www.digipres.org/formats/extensions/#*.df8"
                },
                {
                    "value": "*.dff",
                    "url": "https://www.digipres.org/formats/extensions/#*.dff"
                },
                {
                    "value": "*.df4",
                    "url": "https://www.digipres.org/formats/extensions/#*.df4"
                },
                {
                    "value": "*.df7",
                    "url": "https://www.digipres.org/formats/extensions/#*.df7"
                },
                {
                    "value": "*.df1",
                    "url": "https://www.digipres.org/formats/extensions/#*.df1"
                },
                {
                    "value": "*.df6",
                    "url": "https://www.digipres.org/formats/extensions/#*.df6"
                },
                {
                    "value": "*.df0",
                    "url": "https://www.digipres.org/formats/extensions/#*.df0"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000245.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000245.xml"
            }
        },
        {
            "dsdiff,directstreamdigitalinterchangefileformat,version1.5": "",
            "name": "DSDIFF, Direct Stream Digital Interchange File Format, Version 1.5",
            "id": "fdd000245",
            "filenamepattern": [
                {
                    "value": "*.dft",
                    "url": "https://www.digipres.org/formats/extensions/#*.dft"
                },
                {
                    "value": "*.df5",
                    "url": "https://www.digipres.org/formats/extensions/#*.df5"
                },
                {
                    "value": "*.df2",
                    "url": "https://www.digipres.org/formats/extensions/#*.df2"
                },
                {
                    "value": "*.df3",
                    "url": "https://www.digipres.org/formats/extensions/#*.df3"
                },
                {
                    "value": "*.df9",
                    "url": "https://www.digipres.org/formats/extensions/#*.df9"
                },
                {
                    "value": "*.df8",
                    "url": "https://www.digipres.org/formats/extensions/#*.df8"
                },
                {
                    "value": "*.dff",
                    "url": "https://www.digipres.org/formats/extensions/#*.dff"
                },
                {
                    "value": "*.df4",
                    "url": "https://www.digipres.org/formats/extensions/#*.df4"
                },
                {
                    "value": "*.df7",
                    "url": "https://www.digipres.org/formats/extensions/#*.df7"
                },
                {
                    "value": "*.df1",
                    "url": "https://www.digipres.org/formats/extensions/#*.df1"
                },
                {
                    "value": "*.df6",
                    "url": "https://www.digipres.org/formats/extensions/#*.df6"
                },
                {
                    "value": "*.df0",
                    "url": "https://www.digipres.org/formats/extensions/#*.df0"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000245.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000245.xml"
            }
        },
        {
            "dsdiff,directstreamdigitalinterchangefileformat,version1.5": "",
            "name": "DSDIFF, Direct Stream Digital Interchange File Format, Version 1.5",
            "id": "fdd000245",
            "filenamepattern": [
                {
                    "value": "*.dft",
                    "url": "https://www.digipres.org/formats/extensions/#*.dft"
                },
                {
                    "value": "*.df5",
                    "url": "https://www.digipres.org/formats/extensions/#*.df5"
                },
                {
                    "value": "*.df2",
                    "url": "https://www.digipres.org/formats/extensions/#*.df2"
                },
                {
                    "value": "*.df3",
                    "url": "https://www.digipres.org/formats/extensions/#*.df3"
                },
                {
                    "value": "*.df9",
                    "url": "https://www.digipres.org/formats/extensions/#*.df9"
                },
                {
                    "value": "*.df8",
                    "url": "https://www.digipres.org/formats/extensions/#*.df8"
                },
                {
                    "value": "*.dff",
                    "url": "https://www.digipres.org/formats/extensions/#*.dff"
                },
                {
                    "value": "*.df4",
                    "url": "https://www.digipres.org/formats/extensions/#*.df4"
                },
                {
                    "value": "*.df7",
                    "url": "https://www.digipres.org/formats/extensions/#*.df7"
                },
                {
                    "value": "*.df1",
                    "url": "https://www.digipres.org/formats/extensions/#*.df1"
                },
                {
                    "value": "*.df6",
                    "url": "https://www.digipres.org/formats/extensions/#*.df6"
                },
                {
                    "value": "*.df0",
                    "url": "https://www.digipres.org/formats/extensions/#*.df0"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000245.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000245.xml"
            }
        },
        {
            "dsdiff,directstreamdigitalinterchangefileformat,version1.5": "",
            "name": "DSDIFF, Direct Stream Digital Interchange File Format, Version 1.5",
            "id": "fdd000245",
            "filenamepattern": [
                {
                    "value": "*.dft",
                    "url": "https://www.digipres.org/formats/extensions/#*.dft"
                },
                {
                    "value": "*.df5",
                    "url": "https://www.digipres.org/formats/extensions/#*.df5"
                },
                {
                    "value": "*.df2",
                    "url": "https://www.digipres.org/formats/extensions/#*.df2"
                },
                {
                    "value": "*.df3",
                    "url": "https://www.digipres.org/formats/extensions/#*.df3"
                },
                {
                    "value": "*.df9",
                    "url": "https://www.digipres.org/formats/extensions/#*.df9"
                },
                {
                    "value": "*.df8",
                    "url": "https://www.digipres.org/formats/extensions/#*.df8"
                },
                {
                    "value": "*.dff",
                    "url": "https://www.digipres.org/formats/extensions/#*.dff"
                },
                {
                    "value": "*.df4",
                    "url": "https://www.digipres.org/formats/extensions/#*.df4"
                },
                {
                    "value": "*.df7",
                    "url": "https://www.digipres.org/formats/extensions/#*.df7"
                },
                {
                    "value": "*.df1",
                    "url": "https://www.digipres.org/formats/extensions/#*.df1"
                },
                {
                    "value": "*.df6",
                    "url": "https://www.digipres.org/formats/extensions/#*.df6"
                },
                {
                    "value": "*.df0",
                    "url": "https://www.digipres.org/formats/extensions/#*.df0"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000245.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000245.xml"
            }
        },
        {
            "encapsulatedpostscript(eps)fileformat,version3.x": "",
            "name": "Encapsulated PostScript (EPS) File Format, Version 3.x",
            "id": "fdd000246",
            "filenamepattern": [
                {
                    "value": "*.epsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsf"
                },
                {
                    "value": "*.epsi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsi"
                },
                {
                    "value": "*.eps",
                    "url": "https://www.digipres.org/formats/extensions/#*.eps"
                },
                {
                    "value": "*.epi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000246.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000246.xml"
            }
        },
        {
            "encapsulatedpostscript(eps)fileformat,version3.x": "",
            "name": "Encapsulated PostScript (EPS) File Format, Version 3.x",
            "id": "fdd000246",
            "filenamepattern": [
                {
                    "value": "*.epsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsf"
                },
                {
                    "value": "*.epsi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsi"
                },
                {
                    "value": "*.eps",
                    "url": "https://www.digipres.org/formats/extensions/#*.eps"
                },
                {
                    "value": "*.epi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000246.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000246.xml"
            }
        },
        {
            "encapsulatedpostscript(eps)fileformat,version3.x": "",
            "name": "Encapsulated PostScript (EPS) File Format, Version 3.x",
            "id": "fdd000246",
            "filenamepattern": [
                {
                    "value": "*.epsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsf"
                },
                {
                    "value": "*.epsi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsi"
                },
                {
                    "value": "*.eps",
                    "url": "https://www.digipres.org/formats/extensions/#*.eps"
                },
                {
                    "value": "*.epi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000246.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000246.xml"
            }
        },
        {
            "encapsulatedpostscript(eps)fileformat,version3.x": "",
            "name": "Encapsulated PostScript (EPS) File Format, Version 3.x",
            "id": "fdd000246",
            "filenamepattern": [
                {
                    "value": "*.epsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsf"
                },
                {
                    "value": "*.epsi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsi"
                },
                {
                    "value": "*.eps",
                    "url": "https://www.digipres.org/formats/extensions/#*.eps"
                },
                {
                    "value": "*.epi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000246.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000246.xml"
            }
        },
        {
            "encapsulatedpostscript(eps)fileformat,version3.x": "",
            "name": "Encapsulated PostScript (EPS) File Format, Version 3.x",
            "id": "fdd000246",
            "filenamepattern": [
                {
                    "value": "*.epsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsf"
                },
                {
                    "value": "*.epsi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsi"
                },
                {
                    "value": "*.eps",
                    "url": "https://www.digipres.org/formats/extensions/#*.eps"
                },
                {
                    "value": "*.epi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000246.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000246.xml"
            }
        },
        {
            "encapsulatedpostscript(eps)fileformat,version3.x": "",
            "name": "Encapsulated PostScript (EPS) File Format, Version 3.x",
            "id": "fdd000246",
            "filenamepattern": [
                {
                    "value": "*.epsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsf"
                },
                {
                    "value": "*.epsi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsi"
                },
                {
                    "value": "*.eps",
                    "url": "https://www.digipres.org/formats/extensions/#*.eps"
                },
                {
                    "value": "*.epi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000246.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000246.xml"
            }
        },
        {
            "encapsulatedpostscript(eps)fileformat,version3.x": "",
            "name": "Encapsulated PostScript (EPS) File Format, Version 3.x",
            "id": "fdd000246",
            "filenamepattern": [
                {
                    "value": "*.epsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsf"
                },
                {
                    "value": "*.epsi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsi"
                },
                {
                    "value": "*.eps",
                    "url": "https://www.digipres.org/formats/extensions/#*.eps"
                },
                {
                    "value": "*.epi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000246.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000246.xml"
            }
        },
        {
            "encapsulatedpostscript(eps)fileformat,version3.x": "",
            "name": "Encapsulated PostScript (EPS) File Format, Version 3.x",
            "id": "fdd000246",
            "filenamepattern": [
                {
                    "value": "*.epsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsf"
                },
                {
                    "value": "*.epsi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epsi"
                },
                {
                    "value": "*.eps",
                    "url": "https://www.digipres.org/formats/extensions/#*.eps"
                },
                {
                    "value": "*.epi",
                    "url": "https://www.digipres.org/formats/extensions/#*.epi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/postscript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/postscript"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000246.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000246.xml"
            }
        },
        {
            "opendocumentformat(odf)family,oasisandiso/iec26300": "",
            "name": "OpenDocument Format (ODF) Family, OASIS and ISO/IEC 26300",
            "id": "fdd000247",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000247.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000247.xml"
            }
        },
        {
            "opendocumentformat(odf)family,oasisandiso/iec26300": "",
            "name": "OpenDocument Format (ODF) Family, OASIS and ISO/IEC 26300",
            "id": "fdd000247",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000247.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000247.xml"
            }
        },
        {
            "opendocumentformat(odf)family,oasisandiso/iec26300": "",
            "name": "OpenDocument Format (ODF) Family, OASIS and ISO/IEC 26300",
            "id": "fdd000247",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000247.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000247.xml"
            }
        },
        {
            "opendocumentformat(odf)family,oasisandiso/iec26300": "",
            "name": "OpenDocument Format (ODF) Family, OASIS and ISO/IEC 26300",
            "id": "fdd000247",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000247.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000247.xml"
            }
        },
        {
            "opendocumentformat(odf)family,oasisandiso/iec26300": "",
            "name": "OpenDocument Format (ODF) Family, OASIS and ISO/IEC 26300",
            "id": "fdd000247",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000247.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000247.xml"
            }
        },
        {
            "opendocumentformat(odf)family,oasisandiso/iec26300": "",
            "name": "OpenDocument Format (ODF) Family, OASIS and ISO/IEC 26300",
            "id": "fdd000247",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000247.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000247.xml"
            }
        },
        {
            "opendocumentformat(odf)family,oasisandiso/iec26300": "",
            "name": "OpenDocument Format (ODF) Family, OASIS and ISO/IEC 26300",
            "id": "fdd000247",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000247.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000247.xml"
            }
        },
        {
            "opendocumentformat(odf)family,oasisandiso/iec26300": "",
            "name": "OpenDocument Format (ODF) Family, OASIS and ISO/IEC 26300",
            "id": "fdd000247",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000247.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000247.xml"
            }
        },
        {
            "adobeflashswffileformat,version8": "",
            "name": "Adobe Flash SWF File Format, Version 8",
            "id": "fdd000248",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000248.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000248.xml"
            }
        },
        {
            "adobeflashswffileformat,version8": "",
            "name": "Adobe Flash SWF File Format, Version 8",
            "id": "fdd000248",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000248.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000248.xml"
            }
        },
        {
            "adobeflashswffileformat,version8": "",
            "name": "Adobe Flash SWF File Format, Version 8",
            "id": "fdd000248",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000248.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000248.xml"
            }
        },
        {
            "adobeflashswffileformat,version8": "",
            "name": "Adobe Flash SWF File Format, Version 8",
            "id": "fdd000248",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000248.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000248.xml"
            }
        },
        {
            "adobeflashswffileformat,version8": "",
            "name": "Adobe Flash SWF File Format, Version 8",
            "id": "fdd000248",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000248.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000248.xml"
            }
        },
        {
            "adobeflashswffileformat,version8": "",
            "name": "Adobe Flash SWF File Format, Version 8",
            "id": "fdd000248",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000248.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000248.xml"
            }
        },
        {
            "adobeflashswffileformat,version8": "",
            "name": "Adobe Flash SWF File Format, Version 8",
            "id": "fdd000248",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000248.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000248.xml"
            }
        },
        {
            "adobeflashswffileformat,version8": "",
            "name": "Adobe Flash SWF File Format, Version 8",
            "id": "fdd000248",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000248.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000248.xml"
            }
        },
        {
            "pdf/a1a,pdfforlongtermpreservation,useofpdf1.4,levelaconformance": "",
            "name": "PDF/A-1a, PDF for Long-term Preservation, Use of PDF 1.4, Level A Conformance",
            "id": "fdd000251",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000251.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000251.xml"
            }
        },
        {
            "pdf/a1a,pdfforlongtermpreservation,useofpdf1.4,levelaconformance": "",
            "name": "PDF/A-1a, PDF for Long-term Preservation, Use of PDF 1.4, Level A Conformance",
            "id": "fdd000251",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000251.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000251.xml"
            }
        },
        {
            "pdf/a1a,pdfforlongtermpreservation,useofpdf1.4,levelaconformance": "",
            "name": "PDF/A-1a, PDF for Long-term Preservation, Use of PDF 1.4, Level A Conformance",
            "id": "fdd000251",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000251.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000251.xml"
            }
        },
        {
            "pdf/a1a,pdfforlongtermpreservation,useofpdf1.4,levelaconformance": "",
            "name": "PDF/A-1a, PDF for Long-term Preservation, Use of PDF 1.4, Level A Conformance",
            "id": "fdd000251",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000251.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000251.xml"
            }
        },
        {
            "pdf/a1a,pdfforlongtermpreservation,useofpdf1.4,levelaconformance": "",
            "name": "PDF/A-1a, PDF for Long-term Preservation, Use of PDF 1.4, Level A Conformance",
            "id": "fdd000251",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000251.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000251.xml"
            }
        },
        {
            "pdf/a1a,pdfforlongtermpreservation,useofpdf1.4,levelaconformance": "",
            "name": "PDF/A-1a, PDF for Long-term Preservation, Use of PDF 1.4, Level A Conformance",
            "id": "fdd000251",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000251.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000251.xml"
            }
        },
        {
            "pdf/a1a,pdfforlongtermpreservation,useofpdf1.4,levelaconformance": "",
            "name": "PDF/A-1a, PDF for Long-term Preservation, Use of PDF 1.4, Level A Conformance",
            "id": "fdd000251",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000251.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000251.xml"
            }
        },
        {
            "pdf/a1a,pdfforlongtermpreservation,useofpdf1.4,levelaconformance": "",
            "name": "PDF/A-1a, PDF for Long-term Preservation, Use of PDF 1.4, Level A Conformance",
            "id": "fdd000251",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000251.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000251.xml"
            }
        },
        {
            "pdf/a1b,pdfforlongtermpreservation,useofpdf1.4,levelbconformance": "",
            "name": "PDF/A-1b, PDF for Long-term Preservation, Use of PDF 1.4, Level B Conformance",
            "id": "fdd000252",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000252.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000252.xml"
            }
        },
        {
            "pdf/a1b,pdfforlongtermpreservation,useofpdf1.4,levelbconformance": "",
            "name": "PDF/A-1b, PDF for Long-term Preservation, Use of PDF 1.4, Level B Conformance",
            "id": "fdd000252",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000252.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000252.xml"
            }
        },
        {
            "pdf/a1b,pdfforlongtermpreservation,useofpdf1.4,levelbconformance": "",
            "name": "PDF/A-1b, PDF for Long-term Preservation, Use of PDF 1.4, Level B Conformance",
            "id": "fdd000252",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000252.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000252.xml"
            }
        },
        {
            "pdf/a1b,pdfforlongtermpreservation,useofpdf1.4,levelbconformance": "",
            "name": "PDF/A-1b, PDF for Long-term Preservation, Use of PDF 1.4, Level B Conformance",
            "id": "fdd000252",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000252.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000252.xml"
            }
        },
        {
            "pdf/a1b,pdfforlongtermpreservation,useofpdf1.4,levelbconformance": "",
            "name": "PDF/A-1b, PDF for Long-term Preservation, Use of PDF 1.4, Level B Conformance",
            "id": "fdd000252",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000252.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000252.xml"
            }
        },
        {
            "pdf/a1b,pdfforlongtermpreservation,useofpdf1.4,levelbconformance": "",
            "name": "PDF/A-1b, PDF for Long-term Preservation, Use of PDF 1.4, Level B Conformance",
            "id": "fdd000252",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000252.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000252.xml"
            }
        },
        {
            "pdf/a1b,pdfforlongtermpreservation,useofpdf1.4,levelbconformance": "",
            "name": "PDF/A-1b, PDF for Long-term Preservation, Use of PDF 1.4, Level B Conformance",
            "id": "fdd000252",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000252.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000252.xml"
            }
        },
        {
            "pdf/a1b,pdfforlongtermpreservation,useofpdf1.4,levelbconformance": "",
            "name": "PDF/A-1b, PDF for Long-term Preservation, Use of PDF 1.4, Level B Conformance",
            "id": "fdd000252",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000252.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000252.xml"
            }
        },
        {
            "amrwb+,extendedadaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB+, Extended Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000253",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-WB+",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb+"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000253.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000253.xml"
            }
        },
        {
            "amrwb+,extendedadaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB+, Extended Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000253",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-WB+",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb+"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000253.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000253.xml"
            }
        },
        {
            "amrwb+,extendedadaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB+, Extended Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000253",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-WB+",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb+"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000253.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000253.xml"
            }
        },
        {
            "amrwb+,extendedadaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB+, Extended Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000253",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-WB+",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb+"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000253.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000253.xml"
            }
        },
        {
            "amrwb+,extendedadaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB+, Extended Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000253",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-WB+",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb+"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000253.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000253.xml"
            }
        },
        {
            "amrwb+,extendedadaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB+, Extended Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000253",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-WB+",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb+"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000253.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000253.xml"
            }
        },
        {
            "amrwb+,extendedadaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB+, Extended Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000253",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-WB+",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb+"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000253.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000253.xml"
            }
        },
        {
            "amrwb+,extendedadaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB+, Extended Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000253",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-WB+",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb+"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000253.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000253.xml"
            }
        },
        {
            "amr,adaptivemultiratespeechcodec": "",
            "name": "AMR, Adaptive Multi-Rate Speech Codec",
            "id": "fdd000254",
            "filenamepattern": [
                {
                    "value": "*.3gp",
                    "url": "https://www.digipres.org/formats/extensions/#*.3gp"
                },
                {
                    "value": "*.amr",
                    "url": "https://www.digipres.org/formats/extensions/#*.amr"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr"
                },
                {
                    "value": "audio/3gpp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/3gpp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000254.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000254.xml"
            }
        },
        {
            "amr,adaptivemultiratespeechcodec": "",
            "name": "AMR, Adaptive Multi-Rate Speech Codec",
            "id": "fdd000254",
            "filenamepattern": [
                {
                    "value": "*.3gp",
                    "url": "https://www.digipres.org/formats/extensions/#*.3gp"
                },
                {
                    "value": "*.amr",
                    "url": "https://www.digipres.org/formats/extensions/#*.amr"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr"
                },
                {
                    "value": "audio/3gpp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/3gpp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000254.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000254.xml"
            }
        },
        {
            "amr,adaptivemultiratespeechcodec": "",
            "name": "AMR, Adaptive Multi-Rate Speech Codec",
            "id": "fdd000254",
            "filenamepattern": [
                {
                    "value": "*.3gp",
                    "url": "https://www.digipres.org/formats/extensions/#*.3gp"
                },
                {
                    "value": "*.amr",
                    "url": "https://www.digipres.org/formats/extensions/#*.amr"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr"
                },
                {
                    "value": "audio/3gpp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/3gpp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000254.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000254.xml"
            }
        },
        {
            "amr,adaptivemultiratespeechcodec": "",
            "name": "AMR, Adaptive Multi-Rate Speech Codec",
            "id": "fdd000254",
            "filenamepattern": [
                {
                    "value": "*.3gp",
                    "url": "https://www.digipres.org/formats/extensions/#*.3gp"
                },
                {
                    "value": "*.amr",
                    "url": "https://www.digipres.org/formats/extensions/#*.amr"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr"
                },
                {
                    "value": "audio/3gpp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/3gpp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000254.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000254.xml"
            }
        },
        {
            "amr,adaptivemultiratespeechcodec": "",
            "name": "AMR, Adaptive Multi-Rate Speech Codec",
            "id": "fdd000254",
            "filenamepattern": [
                {
                    "value": "*.3gp",
                    "url": "https://www.digipres.org/formats/extensions/#*.3gp"
                },
                {
                    "value": "*.amr",
                    "url": "https://www.digipres.org/formats/extensions/#*.amr"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr"
                },
                {
                    "value": "audio/3gpp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/3gpp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000254.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000254.xml"
            }
        },
        {
            "amr,adaptivemultiratespeechcodec": "",
            "name": "AMR, Adaptive Multi-Rate Speech Codec",
            "id": "fdd000254",
            "filenamepattern": [
                {
                    "value": "*.3gp",
                    "url": "https://www.digipres.org/formats/extensions/#*.3gp"
                },
                {
                    "value": "*.amr",
                    "url": "https://www.digipres.org/formats/extensions/#*.amr"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr"
                },
                {
                    "value": "audio/3gpp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/3gpp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000254.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000254.xml"
            }
        },
        {
            "amr,adaptivemultiratespeechcodec": "",
            "name": "AMR, Adaptive Multi-Rate Speech Codec",
            "id": "fdd000254",
            "filenamepattern": [
                {
                    "value": "*.3gp",
                    "url": "https://www.digipres.org/formats/extensions/#*.3gp"
                },
                {
                    "value": "*.amr",
                    "url": "https://www.digipres.org/formats/extensions/#*.amr"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr"
                },
                {
                    "value": "audio/3gpp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/3gpp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000254.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000254.xml"
            }
        },
        {
            "amr,adaptivemultiratespeechcodec": "",
            "name": "AMR, Adaptive Multi-Rate Speech Codec",
            "id": "fdd000254",
            "filenamepattern": [
                {
                    "value": "*.3gp",
                    "url": "https://www.digipres.org/formats/extensions/#*.3gp"
                },
                {
                    "value": "*.amr",
                    "url": "https://www.digipres.org/formats/extensions/#*.amr"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr"
                },
                {
                    "value": "audio/3gpp",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/3gpp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000254.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000254.xml"
            }
        },
        {
            "amrwb,adaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB, Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000255",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-wb",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000255.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000255.xml"
            }
        },
        {
            "amrwb,adaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB, Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000255",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-wb",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000255.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000255.xml"
            }
        },
        {
            "amrwb,adaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB, Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000255",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-wb",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000255.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000255.xml"
            }
        },
        {
            "amrwb,adaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB, Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000255",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-wb",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000255.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000255.xml"
            }
        },
        {
            "amrwb,adaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB, Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000255",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-wb",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000255.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000255.xml"
            }
        },
        {
            "amrwb,adaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB, Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000255",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-wb",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000255.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000255.xml"
            }
        },
        {
            "amrwb,adaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB, Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000255",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-wb",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000255.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000255.xml"
            }
        },
        {
            "amrwb,adaptivemultiratewidebandspeechcodec": "",
            "name": "AMR-WB, Adaptive Multi-Rate - Wideband Speech Codec",
            "id": "fdd000255",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/AMR-wb",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/amr-wb"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000255.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000255.xml"
            }
        },
        
        {
            "speexaudiocodec,version1.2": "",
            "name": "Speex Audio Codec, Version 1.2",
            "id": "fdd000259",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/ogg; codecs=speex        ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000259.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000259.xml"
            }
        },
        {
            "speexaudiocodec,version1.2": "",
            "name": "Speex Audio Codec, Version 1.2",
            "id": "fdd000259",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/ogg; codecs=speex        ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000259.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000259.xml"
            }
        },
        {
            "speexaudiocodec,version1.2": "",
            "name": "Speex Audio Codec, Version 1.2",
            "id": "fdd000259",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/ogg; codecs=speex        ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000259.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000259.xml"
            }
        },
        {
            "speexaudiocodec,version1.2": "",
            "name": "Speex Audio Codec, Version 1.2",
            "id": "fdd000259",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/ogg; codecs=speex        ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000259.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000259.xml"
            }
        },
        {
            "speexaudiocodec,version1.2": "",
            "name": "Speex Audio Codec, Version 1.2",
            "id": "fdd000259",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/ogg; codecs=speex        ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000259.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000259.xml"
            }
        },
        {
            "speexaudiocodec,version1.2": "",
            "name": "Speex Audio Codec, Version 1.2",
            "id": "fdd000259",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/ogg; codecs=speex        ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000259.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000259.xml"
            }
        },
        {
            "speexaudiocodec,version1.2": "",
            "name": "Speex Audio Codec, Version 1.2",
            "id": "fdd000259",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/ogg; codecs=speex        ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000259.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000259.xml"
            }
        },
        {
            "speexaudiocodec,version1.2": "",
            "name": "Speex Audio Codec, Version 1.2",
            "id": "fdd000259",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "audio/ogg; codecs=speex        ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000259.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000259.xml"
            }
        },
        {
            "oggspeexaudioformat": "",
            "name": "Ogg Speex Audio Format",
            "id": "fdd000260",
            "filenamepattern": [
                {
                    "value": "*.spx",
                    "url": "https://www.digipres.org/formats/extensions/#*.spx"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/ogg; codecs=speex                            ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000260.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000260.xml"
            }
        },
        {
            "oggspeexaudioformat": "",
            "name": "Ogg Speex Audio Format",
            "id": "fdd000260",
            "filenamepattern": [
                {
                    "value": "*.spx",
                    "url": "https://www.digipres.org/formats/extensions/#*.spx"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/ogg; codecs=speex                            ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000260.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000260.xml"
            }
        },
        {
            "oggspeexaudioformat": "",
            "name": "Ogg Speex Audio Format",
            "id": "fdd000260",
            "filenamepattern": [
                {
                    "value": "*.spx",
                    "url": "https://www.digipres.org/formats/extensions/#*.spx"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/ogg; codecs=speex                            ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000260.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000260.xml"
            }
        },
        {
            "oggspeexaudioformat": "",
            "name": "Ogg Speex Audio Format",
            "id": "fdd000260",
            "filenamepattern": [
                {
                    "value": "*.spx",
                    "url": "https://www.digipres.org/formats/extensions/#*.spx"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/ogg; codecs=speex                            ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000260.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000260.xml"
            }
        },
        {
            "oggspeexaudioformat": "",
            "name": "Ogg Speex Audio Format",
            "id": "fdd000260",
            "filenamepattern": [
                {
                    "value": "*.spx",
                    "url": "https://www.digipres.org/formats/extensions/#*.spx"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/ogg; codecs=speex                            ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000260.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000260.xml"
            }
        },
        {
            "oggspeexaudioformat": "",
            "name": "Ogg Speex Audio Format",
            "id": "fdd000260",
            "filenamepattern": [
                {
                    "value": "*.spx",
                    "url": "https://www.digipres.org/formats/extensions/#*.spx"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/ogg; codecs=speex                            ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000260.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000260.xml"
            }
        },
        {
            "oggspeexaudioformat": "",
            "name": "Ogg Speex Audio Format",
            "id": "fdd000260",
            "filenamepattern": [
                {
                    "value": "*.spx",
                    "url": "https://www.digipres.org/formats/extensions/#*.spx"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/ogg; codecs=speex                            ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000260.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000260.xml"
            }
        },
        {
            "oggspeexaudioformat": "",
            "name": "Ogg Speex Audio Format",
            "id": "fdd000260",
            "filenamepattern": [
                {
                    "value": "*.spx",
                    "url": "https://www.digipres.org/formats/extensions/#*.spx"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/speex"
                },
                {
                    "value": "audio/ogg; codecs=speex                            ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/ogg;codecs=speex"
                },
                {
                    "value": "audio/x-speex",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-speex"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000260.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000260.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.0": "",
            "name": "XML (Extensible Markup Language) 1.0",
            "id": "fdd000263",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000263.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000263.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.0": "",
            "name": "XML (Extensible Markup Language) 1.0",
            "id": "fdd000263",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000263.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000263.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.0": "",
            "name": "XML (Extensible Markup Language) 1.0",
            "id": "fdd000263",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000263.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000263.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.0": "",
            "name": "XML (Extensible Markup Language) 1.0",
            "id": "fdd000263",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000263.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000263.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.0": "",
            "name": "XML (Extensible Markup Language) 1.0",
            "id": "fdd000263",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000263.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000263.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.0": "",
            "name": "XML (Extensible Markup Language) 1.0",
            "id": "fdd000263",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000263.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000263.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.0": "",
            "name": "XML (Extensible Markup Language) 1.0",
            "id": "fdd000263",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000263.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000263.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.0": "",
            "name": "XML (Extensible Markup Language) 1.0",
            "id": "fdd000263",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000263.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000263.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.1": "",
            "name": "XML (Extensible Markup Language) 1.1",
            "id": "fdd000264",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000264.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000264.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.1": "",
            "name": "XML (Extensible Markup Language) 1.1",
            "id": "fdd000264",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000264.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000264.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.1": "",
            "name": "XML (Extensible Markup Language) 1.1",
            "id": "fdd000264",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000264.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000264.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.1": "",
            "name": "XML (Extensible Markup Language) 1.1",
            "id": "fdd000264",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000264.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000264.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.1": "",
            "name": "XML (Extensible Markup Language) 1.1",
            "id": "fdd000264",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000264.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000264.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.1": "",
            "name": "XML (Extensible Markup Language) 1.1",
            "id": "fdd000264",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000264.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000264.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.1": "",
            "name": "XML (Extensible Markup Language) 1.1",
            "id": "fdd000264",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000264.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000264.xml"
            }
        },
        {
            "xml(extensiblemarkuplanguage)1.1": "",
            "name": "XML (Extensible Markup Language) 1.1",
            "id": "fdd000264",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000264.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000264.xml"
            }
        },
        {
            "mxfoperationalpatternatom(opatom)": [
                ""
            ],
            "name": "MXF Operational Pattern Atom (OP-Atom)",
            "id": "fdd000265",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000265.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000265.xml"
            }
        },
        {
            "mxfoperationalpatternatom(opatom)": [
                ""
            ],
            "name": "MXF Operational Pattern Atom (OP-Atom)",
            "id": "fdd000265",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000265.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000265.xml"
            }
        },
        {
            "mxfoperationalpatternatom(opatom)": [
                ""
            ],
            "name": "MXF Operational Pattern Atom (OP-Atom)",
            "id": "fdd000265",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000265.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000265.xml"
            }
        },
        {
            "mxfoperationalpatternatom(opatom)": [
                ""
            ],
            "name": "MXF Operational Pattern Atom (OP-Atom)",
            "id": "fdd000265",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000265.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000265.xml"
            }
        },
        {
            "mxfoperationalpatternatom(opatom)": [
                ""
            ],
            "name": "MXF Operational Pattern Atom (OP-Atom)",
            "id": "fdd000265",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000265.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000265.xml"
            }
        },
        {
            "mxfoperationalpatternatom(opatom)": [
                ""
            ],
            "name": "MXF Operational Pattern Atom (OP-Atom)",
            "id": "fdd000265",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000265.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000265.xml"
            }
        },
        {
            "mxfoperationalpatternatom(opatom)": [
                ""
            ],
            "name": "MXF Operational Pattern Atom (OP-Atom)",
            "id": "fdd000265",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000265.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000265.xml"
            }
        },
        {
            "mxfoperationalpatternatom(opatom)": [
                ""
            ],
            "name": "MXF Operational Pattern Atom (OP-Atom)",
            "id": "fdd000265",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000265.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000265.xml"
            }
        },
        {
            "mxfoperationalpattern1a(op1a)": [
                ""
            ],
            "name": "MXF Operational Pattern 1a (OP1a)",
            "id": "fdd000266",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000266.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000266.xml"
            }
        },
        {
            "mxfoperationalpattern1a(op1a)": [
                ""
            ],
            "name": "MXF Operational Pattern 1a (OP1a)",
            "id": "fdd000266",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000266.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000266.xml"
            }
        },
        {
            "mxfoperationalpattern1a(op1a)": [
                ""
            ],
            "name": "MXF Operational Pattern 1a (OP1a)",
            "id": "fdd000266",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000266.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000266.xml"
            }
        },
        {
            "mxfoperationalpattern1a(op1a)": [
                ""
            ],
            "name": "MXF Operational Pattern 1a (OP1a)",
            "id": "fdd000266",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000266.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000266.xml"
            }
        },
        {
            "mxfoperationalpattern1a(op1a)": [
                ""
            ],
            "name": "MXF Operational Pattern 1a (OP1a)",
            "id": "fdd000266",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000266.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000266.xml"
            }
        },
        {
            "mxfoperationalpattern1a(op1a)": [
                ""
            ],
            "name": "MXF Operational Pattern 1a (OP1a)",
            "id": "fdd000266",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000266.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000266.xml"
            }
        },
        {
            "mxfoperationalpattern1a(op1a)": [
                ""
            ],
            "name": "MXF Operational Pattern 1a (OP1a)",
            "id": "fdd000266",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000266.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000266.xml"
            }
        },
        {
            "mxfoperationalpattern1a(op1a)": [
                ""
            ],
            "name": "MXF Operational Pattern 1a (OP1a)",
            "id": "fdd000266",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000266.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000266.xml"
            }
        },
        
        {
            "pdf,version1.6": "",
            "name": "PDF, Version 1.6",
            "id": "fdd000276",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000276.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000276.xml"
            }
        },
        {
            "pdf,version1.6": "",
            "name": "PDF, Version 1.6",
            "id": "fdd000276",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000276.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000276.xml"
            }
        },
        {
            "pdf,version1.6": "",
            "name": "PDF, Version 1.6",
            "id": "fdd000276",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000276.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000276.xml"
            }
        },
        {
            "pdf,version1.6": "",
            "name": "PDF, Version 1.6",
            "id": "fdd000276",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000276.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000276.xml"
            }
        },
        {
            "pdf,version1.6": "",
            "name": "PDF, Version 1.6",
            "id": "fdd000276",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000276.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000276.xml"
            }
        },
        {
            "pdf,version1.6": "",
            "name": "PDF, Version 1.6",
            "id": "fdd000276",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000276.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000276.xml"
            }
        },
        {
            "pdf,version1.6": "",
            "name": "PDF, Version 1.6",
            "id": "fdd000276",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000276.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000276.xml"
            }
        },
        {
            "pdf,version1.6": "",
            "name": "PDF, Version 1.6",
            "id": "fdd000276",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000276.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000276.xml"
            }
        },
        {
            "pdf,version1.7(iso320001:2008)": "",
            "name": "PDF, Version 1.7  (ISO 32000-1:2008)",
            "id": "fdd000277",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000277.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000277.xml"
            }
        },
        {
            "pdf,version1.7(iso320001:2008)": "",
            "name": "PDF, Version 1.7  (ISO 32000-1:2008)",
            "id": "fdd000277",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000277.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000277.xml"
            }
        },
        {
            "pdf,version1.7(iso320001:2008)": "",
            "name": "PDF, Version 1.7  (ISO 32000-1:2008)",
            "id": "fdd000277",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000277.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000277.xml"
            }
        },
        {
            "pdf,version1.7(iso320001:2008)": "",
            "name": "PDF, Version 1.7  (ISO 32000-1:2008)",
            "id": "fdd000277",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000277.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000277.xml"
            }
        },
        {
            "pdf,version1.7(iso320001:2008)": "",
            "name": "PDF, Version 1.7  (ISO 32000-1:2008)",
            "id": "fdd000277",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000277.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000277.xml"
            }
        },
        {
            "pdf,version1.7(iso320001:2008)": "",
            "name": "PDF, Version 1.7  (ISO 32000-1:2008)",
            "id": "fdd000277",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000277.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000277.xml"
            }
        },
        {
            "pdf,version1.7(iso320001:2008)": "",
            "name": "PDF, Version 1.7  (ISO 32000-1:2008)",
            "id": "fdd000277",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000277.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000277.xml"
            }
        },
        {
            "pdf,version1.7(iso320001:2008)": "",
            "name": "PDF, Version 1.7  (ISO 32000-1:2008)",
            "id": "fdd000277",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000277.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000277.xml"
            }
        },
        {
            "epub,electronicpublication,version2": "",
            "name": "EPUB, Electronic Publication, Version 2",
            "id": "fdd000278",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000278.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000278.xml"
            }
        },
        {
            "epub,electronicpublication,version2": "",
            "name": "EPUB, Electronic Publication, Version 2",
            "id": "fdd000278",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000278.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000278.xml"
            }
        },
        {
            "epub,electronicpublication,version2": "",
            "name": "EPUB, Electronic Publication, Version 2",
            "id": "fdd000278",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000278.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000278.xml"
            }
        },
        {
            "epub,electronicpublication,version2": "",
            "name": "EPUB, Electronic Publication, Version 2",
            "id": "fdd000278",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000278.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000278.xml"
            }
        },
        {
            "epub,electronicpublication,version2": "",
            "name": "EPUB, Electronic Publication, Version 2",
            "id": "fdd000278",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000278.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000278.xml"
            }
        },
        {
            "epub,electronicpublication,version2": "",
            "name": "EPUB, Electronic Publication, Version 2",
            "id": "fdd000278",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000278.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000278.xml"
            }
        },
        {
            "epub,electronicpublication,version2": "",
            "name": "EPUB, Electronic Publication, Version 2",
            "id": "fdd000278",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000278.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000278.xml"
            }
        },
        {
            "epub,electronicpublication,version2": "",
            "name": "EPUB, Electronic Publication, Version 2",
            "id": "fdd000278",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000278.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000278.xml"
            }
        },
        {
            "geotiff,revision1.0": "",
            "name": "GeoTIFF, Revision 1.0",
            "id": "fdd000279",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000279.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000279.xml"
            }
        },
        {
            "geotiff,revision1.0": "",
            "name": "GeoTIFF, Revision 1.0",
            "id": "fdd000279",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000279.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000279.xml"
            }
        },
        {
            "geotiff,revision1.0": "",
            "name": "GeoTIFF, Revision 1.0",
            "id": "fdd000279",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000279.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000279.xml"
            }
        },
        {
            "geotiff,revision1.0": "",
            "name": "GeoTIFF, Revision 1.0",
            "id": "fdd000279",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000279.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000279.xml"
            }
        },
        {
            "geotiff,revision1.0": "",
            "name": "GeoTIFF, Revision 1.0",
            "id": "fdd000279",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000279.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000279.xml"
            }
        },
        {
            "geotiff,revision1.0": "",
            "name": "GeoTIFF, Revision 1.0",
            "id": "fdd000279",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000279.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000279.xml"
            }
        },
        {
            "geotiff,revision1.0": "",
            "name": "GeoTIFF, Revision 1.0",
            "id": "fdd000279",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000279.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000279.xml"
            }
        },
        {
            "geotiff,revision1.0": "",
            "name": "GeoTIFF, Revision 1.0",
            "id": "fdd000279",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000279.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000279.xml"
            }
        },
        {
            "esrishapefile": "",
            "name": "ESRI Shapefile",
            "id": "fdd000280",
            "filenamepattern": [
                {
                    "value": "*.aih",
                    "url": "https://www.digipres.org/formats/extensions/#*.aih"
                },
                {
                    "value": "*.sbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbn"
                },
                {
                    "value": "*.shp",
                    "url": "https://www.digipres.org/formats/extensions/#*.shp"
                },
                {
                    "value": "*.ixs",
                    "url": "https://www.digipres.org/formats/extensions/#*.ixs"
                },
                {
                    "value": "*.cpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpg"
                },
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                },
                {
                    "value": "*.mxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxs"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                },
                {
                    "value": "*.fbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbn"
                },
                {
                    "value": "*.sbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbx"
                },
                {
                    "value": "*.atx",
                    "url": "https://www.digipres.org/formats/extensions/#*.atx"
                },
                {
                    "value": "*.shx",
                    "url": "https://www.digipres.org/formats/extensions/#*.shx"
                },
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                },
                {
                    "value": "*.ain",
                    "url": "https://www.digipres.org/formats/extensions/#*.ain"
                },
                {
                    "value": "*.prj",
                    "url": "https://www.digipres.org/formats/extensions/#*.prj"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000280.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000280.xml"
            }
        },
        {
            "esrishapefile": "",
            "name": "ESRI Shapefile",
            "id": "fdd000280",
            "filenamepattern": [
                {
                    "value": "*.aih",
                    "url": "https://www.digipres.org/formats/extensions/#*.aih"
                },
                {
                    "value": "*.sbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbn"
                },
                {
                    "value": "*.shp",
                    "url": "https://www.digipres.org/formats/extensions/#*.shp"
                },
                {
                    "value": "*.ixs",
                    "url": "https://www.digipres.org/formats/extensions/#*.ixs"
                },
                {
                    "value": "*.cpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpg"
                },
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                },
                {
                    "value": "*.mxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxs"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                },
                {
                    "value": "*.fbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbn"
                },
                {
                    "value": "*.sbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbx"
                },
                {
                    "value": "*.atx",
                    "url": "https://www.digipres.org/formats/extensions/#*.atx"
                },
                {
                    "value": "*.shx",
                    "url": "https://www.digipres.org/formats/extensions/#*.shx"
                },
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                },
                {
                    "value": "*.ain",
                    "url": "https://www.digipres.org/formats/extensions/#*.ain"
                },
                {
                    "value": "*.prj",
                    "url": "https://www.digipres.org/formats/extensions/#*.prj"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000280.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000280.xml"
            }
        },
        {
            "esrishapefile": "",
            "name": "ESRI Shapefile",
            "id": "fdd000280",
            "filenamepattern": [
                {
                    "value": "*.aih",
                    "url": "https://www.digipres.org/formats/extensions/#*.aih"
                },
                {
                    "value": "*.sbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbn"
                },
                {
                    "value": "*.shp",
                    "url": "https://www.digipres.org/formats/extensions/#*.shp"
                },
                {
                    "value": "*.ixs",
                    "url": "https://www.digipres.org/formats/extensions/#*.ixs"
                },
                {
                    "value": "*.cpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpg"
                },
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                },
                {
                    "value": "*.mxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxs"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                },
                {
                    "value": "*.fbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbn"
                },
                {
                    "value": "*.sbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbx"
                },
                {
                    "value": "*.atx",
                    "url": "https://www.digipres.org/formats/extensions/#*.atx"
                },
                {
                    "value": "*.shx",
                    "url": "https://www.digipres.org/formats/extensions/#*.shx"
                },
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                },
                {
                    "value": "*.ain",
                    "url": "https://www.digipres.org/formats/extensions/#*.ain"
                },
                {
                    "value": "*.prj",
                    "url": "https://www.digipres.org/formats/extensions/#*.prj"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000280.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000280.xml"
            }
        },
        {
            "esrishapefile": "",
            "name": "ESRI Shapefile",
            "id": "fdd000280",
            "filenamepattern": [
                {
                    "value": "*.aih",
                    "url": "https://www.digipres.org/formats/extensions/#*.aih"
                },
                {
                    "value": "*.sbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbn"
                },
                {
                    "value": "*.shp",
                    "url": "https://www.digipres.org/formats/extensions/#*.shp"
                },
                {
                    "value": "*.ixs",
                    "url": "https://www.digipres.org/formats/extensions/#*.ixs"
                },
                {
                    "value": "*.cpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpg"
                },
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                },
                {
                    "value": "*.mxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxs"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                },
                {
                    "value": "*.fbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbn"
                },
                {
                    "value": "*.sbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbx"
                },
                {
                    "value": "*.atx",
                    "url": "https://www.digipres.org/formats/extensions/#*.atx"
                },
                {
                    "value": "*.shx",
                    "url": "https://www.digipres.org/formats/extensions/#*.shx"
                },
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                },
                {
                    "value": "*.ain",
                    "url": "https://www.digipres.org/formats/extensions/#*.ain"
                },
                {
                    "value": "*.prj",
                    "url": "https://www.digipres.org/formats/extensions/#*.prj"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000280.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000280.xml"
            }
        },
        {
            "esrishapefile": "",
            "name": "ESRI Shapefile",
            "id": "fdd000280",
            "filenamepattern": [
                {
                    "value": "*.aih",
                    "url": "https://www.digipres.org/formats/extensions/#*.aih"
                },
                {
                    "value": "*.sbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbn"
                },
                {
                    "value": "*.shp",
                    "url": "https://www.digipres.org/formats/extensions/#*.shp"
                },
                {
                    "value": "*.ixs",
                    "url": "https://www.digipres.org/formats/extensions/#*.ixs"
                },
                {
                    "value": "*.cpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpg"
                },
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                },
                {
                    "value": "*.mxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxs"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                },
                {
                    "value": "*.fbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbn"
                },
                {
                    "value": "*.sbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbx"
                },
                {
                    "value": "*.atx",
                    "url": "https://www.digipres.org/formats/extensions/#*.atx"
                },
                {
                    "value": "*.shx",
                    "url": "https://www.digipres.org/formats/extensions/#*.shx"
                },
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                },
                {
                    "value": "*.ain",
                    "url": "https://www.digipres.org/formats/extensions/#*.ain"
                },
                {
                    "value": "*.prj",
                    "url": "https://www.digipres.org/formats/extensions/#*.prj"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000280.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000280.xml"
            }
        },
        {
            "esrishapefile": "",
            "name": "ESRI Shapefile",
            "id": "fdd000280",
            "filenamepattern": [
                {
                    "value": "*.aih",
                    "url": "https://www.digipres.org/formats/extensions/#*.aih"
                },
                {
                    "value": "*.sbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbn"
                },
                {
                    "value": "*.shp",
                    "url": "https://www.digipres.org/formats/extensions/#*.shp"
                },
                {
                    "value": "*.ixs",
                    "url": "https://www.digipres.org/formats/extensions/#*.ixs"
                },
                {
                    "value": "*.cpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpg"
                },
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                },
                {
                    "value": "*.mxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxs"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                },
                {
                    "value": "*.fbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbn"
                },
                {
                    "value": "*.sbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbx"
                },
                {
                    "value": "*.atx",
                    "url": "https://www.digipres.org/formats/extensions/#*.atx"
                },
                {
                    "value": "*.shx",
                    "url": "https://www.digipres.org/formats/extensions/#*.shx"
                },
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                },
                {
                    "value": "*.ain",
                    "url": "https://www.digipres.org/formats/extensions/#*.ain"
                },
                {
                    "value": "*.prj",
                    "url": "https://www.digipres.org/formats/extensions/#*.prj"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000280.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000280.xml"
            }
        },
        {
            "esrishapefile": "",
            "name": "ESRI Shapefile",
            "id": "fdd000280",
            "filenamepattern": [
                {
                    "value": "*.aih",
                    "url": "https://www.digipres.org/formats/extensions/#*.aih"
                },
                {
                    "value": "*.sbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbn"
                },
                {
                    "value": "*.shp",
                    "url": "https://www.digipres.org/formats/extensions/#*.shp"
                },
                {
                    "value": "*.ixs",
                    "url": "https://www.digipres.org/formats/extensions/#*.ixs"
                },
                {
                    "value": "*.cpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpg"
                },
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                },
                {
                    "value": "*.mxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxs"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                },
                {
                    "value": "*.fbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbn"
                },
                {
                    "value": "*.sbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbx"
                },
                {
                    "value": "*.atx",
                    "url": "https://www.digipres.org/formats/extensions/#*.atx"
                },
                {
                    "value": "*.shx",
                    "url": "https://www.digipres.org/formats/extensions/#*.shx"
                },
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                },
                {
                    "value": "*.ain",
                    "url": "https://www.digipres.org/formats/extensions/#*.ain"
                },
                {
                    "value": "*.prj",
                    "url": "https://www.digipres.org/formats/extensions/#*.prj"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000280.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000280.xml"
            }
        },
        {
            "esrishapefile": "",
            "name": "ESRI Shapefile",
            "id": "fdd000280",
            "filenamepattern": [
                {
                    "value": "*.aih",
                    "url": "https://www.digipres.org/formats/extensions/#*.aih"
                },
                {
                    "value": "*.sbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbn"
                },
                {
                    "value": "*.shp",
                    "url": "https://www.digipres.org/formats/extensions/#*.shp"
                },
                {
                    "value": "*.ixs",
                    "url": "https://www.digipres.org/formats/extensions/#*.ixs"
                },
                {
                    "value": "*.cpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.cpg"
                },
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                },
                {
                    "value": "*.mxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.mxs"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                },
                {
                    "value": "*.fbn",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbn"
                },
                {
                    "value": "*.sbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.sbx"
                },
                {
                    "value": "*.atx",
                    "url": "https://www.digipres.org/formats/extensions/#*.atx"
                },
                {
                    "value": "*.shx",
                    "url": "https://www.digipres.org/formats/extensions/#*.shx"
                },
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                },
                {
                    "value": "*.ain",
                    "url": "https://www.digipres.org/formats/extensions/#*.ain"
                },
                {
                    "value": "*.prj",
                    "url": "https://www.digipres.org/formats/extensions/#*.prj"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000280.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000280.xml"
            }
        },
        {
            "esriarcinfogrid(binary)": "",
            "name": "ESRI ArcInfo Grid (binary)",
            "id": "fdd000281",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                },
                {
                    "value": "*.stk",
                    "url": "https://www.digipres.org/formats/extensions/#*.stk"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000281.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000281.xml"
            }
        },
        {
            "esriarcinfogrid(binary)": "",
            "name": "ESRI ArcInfo Grid (binary)",
            "id": "fdd000281",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                },
                {
                    "value": "*.stk",
                    "url": "https://www.digipres.org/formats/extensions/#*.stk"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000281.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000281.xml"
            }
        },
        {
            "esriarcinfogrid(binary)": "",
            "name": "ESRI ArcInfo Grid (binary)",
            "id": "fdd000281",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                },
                {
                    "value": "*.stk",
                    "url": "https://www.digipres.org/formats/extensions/#*.stk"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000281.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000281.xml"
            }
        },
        {
            "esriarcinfogrid(binary)": "",
            "name": "ESRI ArcInfo Grid (binary)",
            "id": "fdd000281",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                },
                {
                    "value": "*.stk",
                    "url": "https://www.digipres.org/formats/extensions/#*.stk"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000281.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000281.xml"
            }
        },
        {
            "esriarcinfogrid(binary)": "",
            "name": "ESRI ArcInfo Grid (binary)",
            "id": "fdd000281",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                },
                {
                    "value": "*.stk",
                    "url": "https://www.digipres.org/formats/extensions/#*.stk"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000281.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000281.xml"
            }
        },
        {
            "esriarcinfogrid(binary)": "",
            "name": "ESRI ArcInfo Grid (binary)",
            "id": "fdd000281",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                },
                {
                    "value": "*.stk",
                    "url": "https://www.digipres.org/formats/extensions/#*.stk"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000281.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000281.xml"
            }
        },
        {
            "esriarcinfogrid(binary)": "",
            "name": "ESRI ArcInfo Grid (binary)",
            "id": "fdd000281",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                },
                {
                    "value": "*.stk",
                    "url": "https://www.digipres.org/formats/extensions/#*.stk"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000281.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000281.xml"
            }
        },
        {
            "esriarcinfogrid(binary)": "",
            "name": "ESRI ArcInfo Grid (binary)",
            "id": "fdd000281",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                },
                {
                    "value": "*.stk",
                    "url": "https://www.digipres.org/formats/extensions/#*.stk"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000281.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000281.xml"
            }
        },
        {
            "arcdigitizedrastergraphic(adrg)": "",
            "name": "Arc Digitized Raster Graphic (ADRG)",
            "id": "fdd000282",
            "filenamepattern": [
                {
                    "value": "*.cph",
                    "url": "https://www.digipres.org/formats/extensions/#*.cph"
                },
                {
                    "value": "*.gen",
                    "url": "https://www.digipres.org/formats/extensions/#*.gen"
                },
                {
                    "value": "*.ovr",
                    "url": "https://www.digipres.org/formats/extensions/#*.ovr"
                },
                {
                    "value": "*.thf",
                    "url": "https://www.digipres.org/formats/extensions/#*.thf"
                },
                {
                    "value": "*.qal",
                    "url": "https://www.digipres.org/formats/extensions/#*.qal"
                },
                {
                    "value": "*.lgg",
                    "url": "https://www.digipres.org/formats/extensions/#*.lgg"
                },
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.sou",
                    "url": "https://www.digipres.org/formats/extensions/#*.sou"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000282.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000282.xml"
            }
        },
        {
            "arcdigitizedrastergraphic(adrg)": "",
            "name": "Arc Digitized Raster Graphic (ADRG)",
            "id": "fdd000282",
            "filenamepattern": [
                {
                    "value": "*.cph",
                    "url": "https://www.digipres.org/formats/extensions/#*.cph"
                },
                {
                    "value": "*.gen",
                    "url": "https://www.digipres.org/formats/extensions/#*.gen"
                },
                {
                    "value": "*.ovr",
                    "url": "https://www.digipres.org/formats/extensions/#*.ovr"
                },
                {
                    "value": "*.thf",
                    "url": "https://www.digipres.org/formats/extensions/#*.thf"
                },
                {
                    "value": "*.qal",
                    "url": "https://www.digipres.org/formats/extensions/#*.qal"
                },
                {
                    "value": "*.lgg",
                    "url": "https://www.digipres.org/formats/extensions/#*.lgg"
                },
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.sou",
                    "url": "https://www.digipres.org/formats/extensions/#*.sou"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000282.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000282.xml"
            }
        },
        {
            "arcdigitizedrastergraphic(adrg)": "",
            "name": "Arc Digitized Raster Graphic (ADRG)",
            "id": "fdd000282",
            "filenamepattern": [
                {
                    "value": "*.cph",
                    "url": "https://www.digipres.org/formats/extensions/#*.cph"
                },
                {
                    "value": "*.gen",
                    "url": "https://www.digipres.org/formats/extensions/#*.gen"
                },
                {
                    "value": "*.ovr",
                    "url": "https://www.digipres.org/formats/extensions/#*.ovr"
                },
                {
                    "value": "*.thf",
                    "url": "https://www.digipres.org/formats/extensions/#*.thf"
                },
                {
                    "value": "*.qal",
                    "url": "https://www.digipres.org/formats/extensions/#*.qal"
                },
                {
                    "value": "*.lgg",
                    "url": "https://www.digipres.org/formats/extensions/#*.lgg"
                },
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.sou",
                    "url": "https://www.digipres.org/formats/extensions/#*.sou"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000282.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000282.xml"
            }
        },
        {
            "arcdigitizedrastergraphic(adrg)": "",
            "name": "Arc Digitized Raster Graphic (ADRG)",
            "id": "fdd000282",
            "filenamepattern": [
                {
                    "value": "*.cph",
                    "url": "https://www.digipres.org/formats/extensions/#*.cph"
                },
                {
                    "value": "*.gen",
                    "url": "https://www.digipres.org/formats/extensions/#*.gen"
                },
                {
                    "value": "*.ovr",
                    "url": "https://www.digipres.org/formats/extensions/#*.ovr"
                },
                {
                    "value": "*.thf",
                    "url": "https://www.digipres.org/formats/extensions/#*.thf"
                },
                {
                    "value": "*.qal",
                    "url": "https://www.digipres.org/formats/extensions/#*.qal"
                },
                {
                    "value": "*.lgg",
                    "url": "https://www.digipres.org/formats/extensions/#*.lgg"
                },
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.sou",
                    "url": "https://www.digipres.org/formats/extensions/#*.sou"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000282.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000282.xml"
            }
        },
        {
            "arcdigitizedrastergraphic(adrg)": "",
            "name": "Arc Digitized Raster Graphic (ADRG)",
            "id": "fdd000282",
            "filenamepattern": [
                {
                    "value": "*.cph",
                    "url": "https://www.digipres.org/formats/extensions/#*.cph"
                },
                {
                    "value": "*.gen",
                    "url": "https://www.digipres.org/formats/extensions/#*.gen"
                },
                {
                    "value": "*.ovr",
                    "url": "https://www.digipres.org/formats/extensions/#*.ovr"
                },
                {
                    "value": "*.thf",
                    "url": "https://www.digipres.org/formats/extensions/#*.thf"
                },
                {
                    "value": "*.qal",
                    "url": "https://www.digipres.org/formats/extensions/#*.qal"
                },
                {
                    "value": "*.lgg",
                    "url": "https://www.digipres.org/formats/extensions/#*.lgg"
                },
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.sou",
                    "url": "https://www.digipres.org/formats/extensions/#*.sou"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000282.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000282.xml"
            }
        },
        {
            "arcdigitizedrastergraphic(adrg)": "",
            "name": "Arc Digitized Raster Graphic (ADRG)",
            "id": "fdd000282",
            "filenamepattern": [
                {
                    "value": "*.cph",
                    "url": "https://www.digipres.org/formats/extensions/#*.cph"
                },
                {
                    "value": "*.gen",
                    "url": "https://www.digipres.org/formats/extensions/#*.gen"
                },
                {
                    "value": "*.ovr",
                    "url": "https://www.digipres.org/formats/extensions/#*.ovr"
                },
                {
                    "value": "*.thf",
                    "url": "https://www.digipres.org/formats/extensions/#*.thf"
                },
                {
                    "value": "*.qal",
                    "url": "https://www.digipres.org/formats/extensions/#*.qal"
                },
                {
                    "value": "*.lgg",
                    "url": "https://www.digipres.org/formats/extensions/#*.lgg"
                },
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.sou",
                    "url": "https://www.digipres.org/formats/extensions/#*.sou"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000282.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000282.xml"
            }
        },
        {
            "arcdigitizedrastergraphic(adrg)": "",
            "name": "Arc Digitized Raster Graphic (ADRG)",
            "id": "fdd000282",
            "filenamepattern": [
                {
                    "value": "*.cph",
                    "url": "https://www.digipres.org/formats/extensions/#*.cph"
                },
                {
                    "value": "*.gen",
                    "url": "https://www.digipres.org/formats/extensions/#*.gen"
                },
                {
                    "value": "*.ovr",
                    "url": "https://www.digipres.org/formats/extensions/#*.ovr"
                },
                {
                    "value": "*.thf",
                    "url": "https://www.digipres.org/formats/extensions/#*.thf"
                },
                {
                    "value": "*.qal",
                    "url": "https://www.digipres.org/formats/extensions/#*.qal"
                },
                {
                    "value": "*.lgg",
                    "url": "https://www.digipres.org/formats/extensions/#*.lgg"
                },
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.sou",
                    "url": "https://www.digipres.org/formats/extensions/#*.sou"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000282.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000282.xml"
            }
        },
        {
            "arcdigitizedrastergraphic(adrg)": "",
            "name": "Arc Digitized Raster Graphic (ADRG)",
            "id": "fdd000282",
            "filenamepattern": [
                {
                    "value": "*.cph",
                    "url": "https://www.digipres.org/formats/extensions/#*.cph"
                },
                {
                    "value": "*.gen",
                    "url": "https://www.digipres.org/formats/extensions/#*.gen"
                },
                {
                    "value": "*.ovr",
                    "url": "https://www.digipres.org/formats/extensions/#*.ovr"
                },
                {
                    "value": "*.thf",
                    "url": "https://www.digipres.org/formats/extensions/#*.thf"
                },
                {
                    "value": "*.qal",
                    "url": "https://www.digipres.org/formats/extensions/#*.qal"
                },
                {
                    "value": "*.lgg",
                    "url": "https://www.digipres.org/formats/extensions/#*.lgg"
                },
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.sou",
                    "url": "https://www.digipres.org/formats/extensions/#*.sou"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000282.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000282.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imagefile": "",
            "name": "Band Interleaved by Line (BIL) Image File",
            "id": "fdd000283",
            "filenamepattern": [
                {
                    "value": "*.bil",
                    "url": "https://www.digipres.org/formats/extensions/#*.bil"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000283.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000283.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imagefile": "",
            "name": "Band Interleaved by Line (BIL) Image File",
            "id": "fdd000283",
            "filenamepattern": [
                {
                    "value": "*.bil",
                    "url": "https://www.digipres.org/formats/extensions/#*.bil"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000283.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000283.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imagefile": "",
            "name": "Band Interleaved by Line (BIL) Image File",
            "id": "fdd000283",
            "filenamepattern": [
                {
                    "value": "*.bil",
                    "url": "https://www.digipres.org/formats/extensions/#*.bil"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000283.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000283.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imagefile": "",
            "name": "Band Interleaved by Line (BIL) Image File",
            "id": "fdd000283",
            "filenamepattern": [
                {
                    "value": "*.bil",
                    "url": "https://www.digipres.org/formats/extensions/#*.bil"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000283.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000283.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imagefile": "",
            "name": "Band Interleaved by Line (BIL) Image File",
            "id": "fdd000283",
            "filenamepattern": [
                {
                    "value": "*.bil",
                    "url": "https://www.digipres.org/formats/extensions/#*.bil"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000283.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000283.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imagefile": "",
            "name": "Band Interleaved by Line (BIL) Image File",
            "id": "fdd000283",
            "filenamepattern": [
                {
                    "value": "*.bil",
                    "url": "https://www.digipres.org/formats/extensions/#*.bil"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000283.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000283.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imagefile": "",
            "name": "Band Interleaved by Line (BIL) Image File",
            "id": "fdd000283",
            "filenamepattern": [
                {
                    "value": "*.bil",
                    "url": "https://www.digipres.org/formats/extensions/#*.bil"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000283.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000283.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imagefile": "",
            "name": "Band Interleaved by Line (BIL) Image File",
            "id": "fdd000283",
            "filenamepattern": [
                {
                    "value": "*.bil",
                    "url": "https://www.digipres.org/formats/extensions/#*.bil"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000283.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000283.xml"
            }
        },
        {
            "esriarcinfocoverage": "",
            "name": "ESRI ArcInfo Coverage",
            "id": "fdd000284",
            "filenamepattern": [
                {
                    "value": "*.dir",
                    "url": "https://www.digipres.org/formats/extensions/#*.dir"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.sec",
                    "url": "https://www.digipres.org/formats/extensions/#*.sec"
                },
                {
                    "value": "*.rat",
                    "url": "https://www.digipres.org/formats/extensions/#*.rat"
                },
                {
                    "value": "*.pat",
                    "url": "https://www.digipres.org/formats/extensions/#*.pat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000284.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000284.xml"
            }
        },
        {
            "esriarcinfocoverage": "",
            "name": "ESRI ArcInfo Coverage",
            "id": "fdd000284",
            "filenamepattern": [
                {
                    "value": "*.dir",
                    "url": "https://www.digipres.org/formats/extensions/#*.dir"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.sec",
                    "url": "https://www.digipres.org/formats/extensions/#*.sec"
                },
                {
                    "value": "*.rat",
                    "url": "https://www.digipres.org/formats/extensions/#*.rat"
                },
                {
                    "value": "*.pat",
                    "url": "https://www.digipres.org/formats/extensions/#*.pat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000284.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000284.xml"
            }
        },
        {
            "esriarcinfocoverage": "",
            "name": "ESRI ArcInfo Coverage",
            "id": "fdd000284",
            "filenamepattern": [
                {
                    "value": "*.dir",
                    "url": "https://www.digipres.org/formats/extensions/#*.dir"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.sec",
                    "url": "https://www.digipres.org/formats/extensions/#*.sec"
                },
                {
                    "value": "*.rat",
                    "url": "https://www.digipres.org/formats/extensions/#*.rat"
                },
                {
                    "value": "*.pat",
                    "url": "https://www.digipres.org/formats/extensions/#*.pat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000284.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000284.xml"
            }
        },
        {
            "esriarcinfocoverage": "",
            "name": "ESRI ArcInfo Coverage",
            "id": "fdd000284",
            "filenamepattern": [
                {
                    "value": "*.dir",
                    "url": "https://www.digipres.org/formats/extensions/#*.dir"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.sec",
                    "url": "https://www.digipres.org/formats/extensions/#*.sec"
                },
                {
                    "value": "*.rat",
                    "url": "https://www.digipres.org/formats/extensions/#*.rat"
                },
                {
                    "value": "*.pat",
                    "url": "https://www.digipres.org/formats/extensions/#*.pat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000284.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000284.xml"
            }
        },
        {
            "esriarcinfocoverage": "",
            "name": "ESRI ArcInfo Coverage",
            "id": "fdd000284",
            "filenamepattern": [
                {
                    "value": "*.dir",
                    "url": "https://www.digipres.org/formats/extensions/#*.dir"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.sec",
                    "url": "https://www.digipres.org/formats/extensions/#*.sec"
                },
                {
                    "value": "*.rat",
                    "url": "https://www.digipres.org/formats/extensions/#*.rat"
                },
                {
                    "value": "*.pat",
                    "url": "https://www.digipres.org/formats/extensions/#*.pat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000284.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000284.xml"
            }
        },
        {
            "esriarcinfocoverage": "",
            "name": "ESRI ArcInfo Coverage",
            "id": "fdd000284",
            "filenamepattern": [
                {
                    "value": "*.dir",
                    "url": "https://www.digipres.org/formats/extensions/#*.dir"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.sec",
                    "url": "https://www.digipres.org/formats/extensions/#*.sec"
                },
                {
                    "value": "*.rat",
                    "url": "https://www.digipres.org/formats/extensions/#*.rat"
                },
                {
                    "value": "*.pat",
                    "url": "https://www.digipres.org/formats/extensions/#*.pat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000284.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000284.xml"
            }
        },
        {
            "esriarcinfocoverage": "",
            "name": "ESRI ArcInfo Coverage",
            "id": "fdd000284",
            "filenamepattern": [
                {
                    "value": "*.dir",
                    "url": "https://www.digipres.org/formats/extensions/#*.dir"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.sec",
                    "url": "https://www.digipres.org/formats/extensions/#*.sec"
                },
                {
                    "value": "*.rat",
                    "url": "https://www.digipres.org/formats/extensions/#*.rat"
                },
                {
                    "value": "*.pat",
                    "url": "https://www.digipres.org/formats/extensions/#*.pat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000284.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000284.xml"
            }
        },
        {
            "esriarcinfocoverage": "",
            "name": "ESRI ArcInfo Coverage",
            "id": "fdd000284",
            "filenamepattern": [
                {
                    "value": "*.dir",
                    "url": "https://www.digipres.org/formats/extensions/#*.dir"
                },
                {
                    "value": "*.adf",
                    "url": "https://www.digipres.org/formats/extensions/#*.adf"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.sec",
                    "url": "https://www.digipres.org/formats/extensions/#*.sec"
                },
                {
                    "value": "*.rat",
                    "url": "https://www.digipres.org/formats/extensions/#*.rat"
                },
                {
                    "value": "*.pat",
                    "url": "https://www.digipres.org/formats/extensions/#*.pat"
                },
                {
                    "value": "*.nit",
                    "url": "https://www.digipres.org/formats/extensions/#*.nit"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000284.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000284.xml"
            }
        },
        {
            "digitalelevationmodel(dem)format": "",
            "name": "Digital Elevation Model (DEM) Format",
            "id": "fdd000285",
            "filenamepattern": [
                {
                    "value": "*.dem",
                    "url": "https://www.digipres.org/formats/extensions/#*.dem"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000285.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000285.xml"
            }
        },
        {
            "digitalelevationmodel(dem)format": "",
            "name": "Digital Elevation Model (DEM) Format",
            "id": "fdd000285",
            "filenamepattern": [
                {
                    "value": "*.dem",
                    "url": "https://www.digipres.org/formats/extensions/#*.dem"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000285.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000285.xml"
            }
        },
        {
            "digitalelevationmodel(dem)format": "",
            "name": "Digital Elevation Model (DEM) Format",
            "id": "fdd000285",
            "filenamepattern": [
                {
                    "value": "*.dem",
                    "url": "https://www.digipres.org/formats/extensions/#*.dem"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000285.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000285.xml"
            }
        },
        {
            "digitalelevationmodel(dem)format": "",
            "name": "Digital Elevation Model (DEM) Format",
            "id": "fdd000285",
            "filenamepattern": [
                {
                    "value": "*.dem",
                    "url": "https://www.digipres.org/formats/extensions/#*.dem"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000285.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000285.xml"
            }
        },
        {
            "digitalelevationmodel(dem)format": "",
            "name": "Digital Elevation Model (DEM) Format",
            "id": "fdd000285",
            "filenamepattern": [
                {
                    "value": "*.dem",
                    "url": "https://www.digipres.org/formats/extensions/#*.dem"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000285.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000285.xml"
            }
        },
        {
            "digitalelevationmodel(dem)format": "",
            "name": "Digital Elevation Model (DEM) Format",
            "id": "fdd000285",
            "filenamepattern": [
                {
                    "value": "*.dem",
                    "url": "https://www.digipres.org/formats/extensions/#*.dem"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000285.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000285.xml"
            }
        },
        {
            "digitalelevationmodel(dem)format": "",
            "name": "Digital Elevation Model (DEM) Format",
            "id": "fdd000285",
            "filenamepattern": [
                {
                    "value": "*.dem",
                    "url": "https://www.digipres.org/formats/extensions/#*.dem"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000285.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000285.xml"
            }
        },
        {
            "digitalelevationmodel(dem)format": "",
            "name": "Digital Elevation Model (DEM) Format",
            "id": "fdd000285",
            "filenamepattern": [
                {
                    "value": "*.dem",
                    "url": "https://www.digipres.org/formats/extensions/#*.dem"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000285.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000285.xml"
            }
        },
        {
            "spatialdatatransferstandard": "",
            "name": "Spatial Data Transfer Standard",
            "id": "fdd000286",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                },
                {
                    "value": "*.tar.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar.gz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000286.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000286.xml"
            }
        },
        {
            "spatialdatatransferstandard": "",
            "name": "Spatial Data Transfer Standard",
            "id": "fdd000286",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                },
                {
                    "value": "*.tar.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar.gz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000286.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000286.xml"
            }
        },
        {
            "spatialdatatransferstandard": "",
            "name": "Spatial Data Transfer Standard",
            "id": "fdd000286",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                },
                {
                    "value": "*.tar.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar.gz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000286.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000286.xml"
            }
        },
        {
            "spatialdatatransferstandard": "",
            "name": "Spatial Data Transfer Standard",
            "id": "fdd000286",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                },
                {
                    "value": "*.tar.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar.gz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000286.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000286.xml"
            }
        },
        {
            "spatialdatatransferstandard": "",
            "name": "Spatial Data Transfer Standard",
            "id": "fdd000286",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                },
                {
                    "value": "*.tar.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar.gz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000286.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000286.xml"
            }
        },
        {
            "spatialdatatransferstandard": "",
            "name": "Spatial Data Transfer Standard",
            "id": "fdd000286",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                },
                {
                    "value": "*.tar.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar.gz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000286.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000286.xml"
            }
        },
        {
            "spatialdatatransferstandard": "",
            "name": "Spatial Data Transfer Standard",
            "id": "fdd000286",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                },
                {
                    "value": "*.tar.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar.gz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000286.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000286.xml"
            }
        },
        {
            "spatialdatatransferstandard": "",
            "name": "Spatial Data Transfer Standard",
            "id": "fdd000286",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                },
                {
                    "value": "*.tar.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar.gz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000286.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000286.xml"
            }
        },
        {
            "esriworldfile": "",
            "name": "ESRI World File",
            "id": "fdd000287",
            "filenamepattern": [
                {
                    "value": "*.bmpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmpw"
                },
                {
                    "value": "*.jpgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpgw"
                },
                {
                    "value": "*.tifw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tifw"
                },
                {
                    "value": "*.blw",
                    "url": "https://www.digipres.org/formats/extensions/#*.blw"
                },
                {
                    "value": "*.bpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bpw"
                },
                {
                    "value": "*.sdw",
                    "url": "https://www.digipres.org/formats/extensions/#*.sdw"
                },
                {
                    "value": "*.bsqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsqw"
                },
                {
                    "value": "*.bilw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bilw"
                },
                {
                    "value": "*.bqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bqw"
                },
                {
                    "value": "*.rcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rcw"
                },
                {
                    "value": "*.btw",
                    "url": "https://www.digipres.org/formats/extensions/#*.btw"
                },
                {
                    "value": "*.bipw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bipw"
                },
                {
                    "value": "*.jgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jgw"
                },
                {
                    "value": "*.rlcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rlcw"
                },
                {
                    "value": "*.pgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.pgw"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000287.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000287.xml"
            }
        },
        {
            "esriworldfile": "",
            "name": "ESRI World File",
            "id": "fdd000287",
            "filenamepattern": [
                {
                    "value": "*.bmpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmpw"
                },
                {
                    "value": "*.jpgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpgw"
                },
                {
                    "value": "*.tifw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tifw"
                },
                {
                    "value": "*.blw",
                    "url": "https://www.digipres.org/formats/extensions/#*.blw"
                },
                {
                    "value": "*.bpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bpw"
                },
                {
                    "value": "*.sdw",
                    "url": "https://www.digipres.org/formats/extensions/#*.sdw"
                },
                {
                    "value": "*.bsqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsqw"
                },
                {
                    "value": "*.bilw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bilw"
                },
                {
                    "value": "*.bqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bqw"
                },
                {
                    "value": "*.rcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rcw"
                },
                {
                    "value": "*.btw",
                    "url": "https://www.digipres.org/formats/extensions/#*.btw"
                },
                {
                    "value": "*.bipw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bipw"
                },
                {
                    "value": "*.jgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jgw"
                },
                {
                    "value": "*.rlcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rlcw"
                },
                {
                    "value": "*.pgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.pgw"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000287.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000287.xml"
            }
        },
        {
            "esriworldfile": "",
            "name": "ESRI World File",
            "id": "fdd000287",
            "filenamepattern": [
                {
                    "value": "*.bmpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmpw"
                },
                {
                    "value": "*.jpgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpgw"
                },
                {
                    "value": "*.tifw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tifw"
                },
                {
                    "value": "*.blw",
                    "url": "https://www.digipres.org/formats/extensions/#*.blw"
                },
                {
                    "value": "*.bpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bpw"
                },
                {
                    "value": "*.sdw",
                    "url": "https://www.digipres.org/formats/extensions/#*.sdw"
                },
                {
                    "value": "*.bsqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsqw"
                },
                {
                    "value": "*.bilw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bilw"
                },
                {
                    "value": "*.bqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bqw"
                },
                {
                    "value": "*.rcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rcw"
                },
                {
                    "value": "*.btw",
                    "url": "https://www.digipres.org/formats/extensions/#*.btw"
                },
                {
                    "value": "*.bipw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bipw"
                },
                {
                    "value": "*.jgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jgw"
                },
                {
                    "value": "*.rlcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rlcw"
                },
                {
                    "value": "*.pgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.pgw"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000287.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000287.xml"
            }
        },
        {
            "esriworldfile": "",
            "name": "ESRI World File",
            "id": "fdd000287",
            "filenamepattern": [
                {
                    "value": "*.bmpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmpw"
                },
                {
                    "value": "*.jpgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpgw"
                },
                {
                    "value": "*.tifw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tifw"
                },
                {
                    "value": "*.blw",
                    "url": "https://www.digipres.org/formats/extensions/#*.blw"
                },
                {
                    "value": "*.bpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bpw"
                },
                {
                    "value": "*.sdw",
                    "url": "https://www.digipres.org/formats/extensions/#*.sdw"
                },
                {
                    "value": "*.bsqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsqw"
                },
                {
                    "value": "*.bilw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bilw"
                },
                {
                    "value": "*.bqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bqw"
                },
                {
                    "value": "*.rcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rcw"
                },
                {
                    "value": "*.btw",
                    "url": "https://www.digipres.org/formats/extensions/#*.btw"
                },
                {
                    "value": "*.bipw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bipw"
                },
                {
                    "value": "*.jgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jgw"
                },
                {
                    "value": "*.rlcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rlcw"
                },
                {
                    "value": "*.pgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.pgw"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000287.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000287.xml"
            }
        },
        {
            "esriworldfile": "",
            "name": "ESRI World File",
            "id": "fdd000287",
            "filenamepattern": [
                {
                    "value": "*.bmpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmpw"
                },
                {
                    "value": "*.jpgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpgw"
                },
                {
                    "value": "*.tifw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tifw"
                },
                {
                    "value": "*.blw",
                    "url": "https://www.digipres.org/formats/extensions/#*.blw"
                },
                {
                    "value": "*.bpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bpw"
                },
                {
                    "value": "*.sdw",
                    "url": "https://www.digipres.org/formats/extensions/#*.sdw"
                },
                {
                    "value": "*.bsqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsqw"
                },
                {
                    "value": "*.bilw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bilw"
                },
                {
                    "value": "*.bqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bqw"
                },
                {
                    "value": "*.rcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rcw"
                },
                {
                    "value": "*.btw",
                    "url": "https://www.digipres.org/formats/extensions/#*.btw"
                },
                {
                    "value": "*.bipw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bipw"
                },
                {
                    "value": "*.jgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jgw"
                },
                {
                    "value": "*.rlcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rlcw"
                },
                {
                    "value": "*.pgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.pgw"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000287.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000287.xml"
            }
        },
        {
            "esriworldfile": "",
            "name": "ESRI World File",
            "id": "fdd000287",
            "filenamepattern": [
                {
                    "value": "*.bmpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmpw"
                },
                {
                    "value": "*.jpgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpgw"
                },
                {
                    "value": "*.tifw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tifw"
                },
                {
                    "value": "*.blw",
                    "url": "https://www.digipres.org/formats/extensions/#*.blw"
                },
                {
                    "value": "*.bpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bpw"
                },
                {
                    "value": "*.sdw",
                    "url": "https://www.digipres.org/formats/extensions/#*.sdw"
                },
                {
                    "value": "*.bsqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsqw"
                },
                {
                    "value": "*.bilw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bilw"
                },
                {
                    "value": "*.bqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bqw"
                },
                {
                    "value": "*.rcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rcw"
                },
                {
                    "value": "*.btw",
                    "url": "https://www.digipres.org/formats/extensions/#*.btw"
                },
                {
                    "value": "*.bipw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bipw"
                },
                {
                    "value": "*.jgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jgw"
                },
                {
                    "value": "*.rlcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rlcw"
                },
                {
                    "value": "*.pgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.pgw"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000287.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000287.xml"
            }
        },
        {
            "esriworldfile": "",
            "name": "ESRI World File",
            "id": "fdd000287",
            "filenamepattern": [
                {
                    "value": "*.bmpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmpw"
                },
                {
                    "value": "*.jpgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpgw"
                },
                {
                    "value": "*.tifw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tifw"
                },
                {
                    "value": "*.blw",
                    "url": "https://www.digipres.org/formats/extensions/#*.blw"
                },
                {
                    "value": "*.bpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bpw"
                },
                {
                    "value": "*.sdw",
                    "url": "https://www.digipres.org/formats/extensions/#*.sdw"
                },
                {
                    "value": "*.bsqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsqw"
                },
                {
                    "value": "*.bilw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bilw"
                },
                {
                    "value": "*.bqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bqw"
                },
                {
                    "value": "*.rcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rcw"
                },
                {
                    "value": "*.btw",
                    "url": "https://www.digipres.org/formats/extensions/#*.btw"
                },
                {
                    "value": "*.bipw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bipw"
                },
                {
                    "value": "*.jgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jgw"
                },
                {
                    "value": "*.rlcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rlcw"
                },
                {
                    "value": "*.pgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.pgw"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000287.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000287.xml"
            }
        },
        {
            "esriworldfile": "",
            "name": "ESRI World File",
            "id": "fdd000287",
            "filenamepattern": [
                {
                    "value": "*.bmpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bmpw"
                },
                {
                    "value": "*.jpgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpgw"
                },
                {
                    "value": "*.tifw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tifw"
                },
                {
                    "value": "*.blw",
                    "url": "https://www.digipres.org/formats/extensions/#*.blw"
                },
                {
                    "value": "*.bpw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bpw"
                },
                {
                    "value": "*.sdw",
                    "url": "https://www.digipres.org/formats/extensions/#*.sdw"
                },
                {
                    "value": "*.bsqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsqw"
                },
                {
                    "value": "*.bilw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bilw"
                },
                {
                    "value": "*.bqw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bqw"
                },
                {
                    "value": "*.rcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rcw"
                },
                {
                    "value": "*.btw",
                    "url": "https://www.digipres.org/formats/extensions/#*.btw"
                },
                {
                    "value": "*.bipw",
                    "url": "https://www.digipres.org/formats/extensions/#*.bipw"
                },
                {
                    "value": "*.jgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.jgw"
                },
                {
                    "value": "*.rlcw",
                    "url": "https://www.digipres.org/formats/extensions/#*.rlcw"
                },
                {
                    "value": "*.pgw",
                    "url": "https://www.digipres.org/formats/extensions/#*.pgw"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000287.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000287.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imagefile": "",
            "name": "Band Interleaved by Pixel (BIP) Image File",
            "id": "fdd000288",
            "filenamepattern": [
                {
                    "value": "*.bip",
                    "url": "https://www.digipres.org/formats/extensions/#*.bip"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000288.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000288.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imagefile": "",
            "name": "Band Interleaved by Pixel (BIP) Image File",
            "id": "fdd000288",
            "filenamepattern": [
                {
                    "value": "*.bip",
                    "url": "https://www.digipres.org/formats/extensions/#*.bip"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000288.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000288.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imagefile": "",
            "name": "Band Interleaved by Pixel (BIP) Image File",
            "id": "fdd000288",
            "filenamepattern": [
                {
                    "value": "*.bip",
                    "url": "https://www.digipres.org/formats/extensions/#*.bip"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000288.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000288.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imagefile": "",
            "name": "Band Interleaved by Pixel (BIP) Image File",
            "id": "fdd000288",
            "filenamepattern": [
                {
                    "value": "*.bip",
                    "url": "https://www.digipres.org/formats/extensions/#*.bip"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000288.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000288.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imagefile": "",
            "name": "Band Interleaved by Pixel (BIP) Image File",
            "id": "fdd000288",
            "filenamepattern": [
                {
                    "value": "*.bip",
                    "url": "https://www.digipres.org/formats/extensions/#*.bip"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000288.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000288.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imagefile": "",
            "name": "Band Interleaved by Pixel (BIP) Image File",
            "id": "fdd000288",
            "filenamepattern": [
                {
                    "value": "*.bip",
                    "url": "https://www.digipres.org/formats/extensions/#*.bip"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000288.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000288.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imagefile": "",
            "name": "Band Interleaved by Pixel (BIP) Image File",
            "id": "fdd000288",
            "filenamepattern": [
                {
                    "value": "*.bip",
                    "url": "https://www.digipres.org/formats/extensions/#*.bip"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000288.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000288.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imagefile": "",
            "name": "Band Interleaved by Pixel (BIP) Image File",
            "id": "fdd000288",
            "filenamepattern": [
                {
                    "value": "*.bip",
                    "url": "https://www.digipres.org/formats/extensions/#*.bip"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000288.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000288.xml"
            }
        },
        {
            "bandsequential(bsq)imagefile": "",
            "name": "Band Sequential (BSQ) Image File",
            "id": "fdd000289",
            "filenamepattern": [
                {
                    "value": "*.bsq",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000289.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000289.xml"
            }
        },
        {
            "bandsequential(bsq)imagefile": "",
            "name": "Band Sequential (BSQ) Image File",
            "id": "fdd000289",
            "filenamepattern": [
                {
                    "value": "*.bsq",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000289.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000289.xml"
            }
        },
        {
            "bandsequential(bsq)imagefile": "",
            "name": "Band Sequential (BSQ) Image File",
            "id": "fdd000289",
            "filenamepattern": [
                {
                    "value": "*.bsq",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000289.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000289.xml"
            }
        },
        {
            "bandsequential(bsq)imagefile": "",
            "name": "Band Sequential (BSQ) Image File",
            "id": "fdd000289",
            "filenamepattern": [
                {
                    "value": "*.bsq",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000289.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000289.xml"
            }
        },
        {
            "bandsequential(bsq)imagefile": "",
            "name": "Band Sequential (BSQ) Image File",
            "id": "fdd000289",
            "filenamepattern": [
                {
                    "value": "*.bsq",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000289.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000289.xml"
            }
        },
        {
            "bandsequential(bsq)imagefile": "",
            "name": "Band Sequential (BSQ) Image File",
            "id": "fdd000289",
            "filenamepattern": [
                {
                    "value": "*.bsq",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000289.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000289.xml"
            }
        },
        {
            "bandsequential(bsq)imagefile": "",
            "name": "Band Sequential (BSQ) Image File",
            "id": "fdd000289",
            "filenamepattern": [
                {
                    "value": "*.bsq",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000289.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000289.xml"
            }
        },
        {
            "bandsequential(bsq)imagefile": "",
            "name": "Band Sequential (BSQ) Image File",
            "id": "fdd000289",
            "filenamepattern": [
                {
                    "value": "*.bsq",
                    "url": "https://www.digipres.org/formats/extensions/#*.bsq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000289.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000289.xml"
            }
        },
        {
            "digitalorthophotos(doq&doqq)": "",
            "name": "Digital Orthophotos (DOQ & DOQQ)",
            "id": "fdd000290",
            "filenamepattern": [
                {
                    "value": "*.doq",
                    "url": "https://www.digipres.org/formats/extensions/#*.doq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000290.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000290.xml"
            }
        },
        {
            "digitalorthophotos(doq&doqq)": "",
            "name": "Digital Orthophotos (DOQ & DOQQ)",
            "id": "fdd000290",
            "filenamepattern": [
                {
                    "value": "*.doq",
                    "url": "https://www.digipres.org/formats/extensions/#*.doq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000290.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000290.xml"
            }
        },
        {
            "digitalorthophotos(doq&doqq)": "",
            "name": "Digital Orthophotos (DOQ & DOQQ)",
            "id": "fdd000290",
            "filenamepattern": [
                {
                    "value": "*.doq",
                    "url": "https://www.digipres.org/formats/extensions/#*.doq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000290.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000290.xml"
            }
        },
        {
            "digitalorthophotos(doq&doqq)": "",
            "name": "Digital Orthophotos (DOQ & DOQQ)",
            "id": "fdd000290",
            "filenamepattern": [
                {
                    "value": "*.doq",
                    "url": "https://www.digipres.org/formats/extensions/#*.doq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000290.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000290.xml"
            }
        },
        {
            "digitalorthophotos(doq&doqq)": "",
            "name": "Digital Orthophotos (DOQ & DOQQ)",
            "id": "fdd000290",
            "filenamepattern": [
                {
                    "value": "*.doq",
                    "url": "https://www.digipres.org/formats/extensions/#*.doq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000290.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000290.xml"
            }
        },
        {
            "digitalorthophotos(doq&doqq)": "",
            "name": "Digital Orthophotos (DOQ & DOQQ)",
            "id": "fdd000290",
            "filenamepattern": [
                {
                    "value": "*.doq",
                    "url": "https://www.digipres.org/formats/extensions/#*.doq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000290.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000290.xml"
            }
        },
        {
            "digitalorthophotos(doq&doqq)": "",
            "name": "Digital Orthophotos (DOQ & DOQQ)",
            "id": "fdd000290",
            "filenamepattern": [
                {
                    "value": "*.doq",
                    "url": "https://www.digipres.org/formats/extensions/#*.doq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000290.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000290.xml"
            }
        },
        {
            "digitalorthophotos(doq&doqq)": "",
            "name": "Digital Orthophotos (DOQ & DOQQ)",
            "id": "fdd000290",
            "filenamepattern": [
                {
                    "value": "*.doq",
                    "url": "https://www.digipres.org/formats/extensions/#*.doq"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000290.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000290.xml"
            }
        },
        {
            "esriarcinfointerchangefile(e00)": "",
            "name": "ESRI ArcInfo interchange file (E00)",
            "id": "fdd000291",
            "filenamepattern": [
                {
                    "value": "*.e00",
                    "url": "https://www.digipres.org/formats/extensions/#*.e00"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000291.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000291.xml"
            }
        },
        {
            "esriarcinfointerchangefile(e00)": "",
            "name": "ESRI ArcInfo interchange file (E00)",
            "id": "fdd000291",
            "filenamepattern": [
                {
                    "value": "*.e00",
                    "url": "https://www.digipres.org/formats/extensions/#*.e00"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000291.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000291.xml"
            }
        },
        {
            "esriarcinfointerchangefile(e00)": "",
            "name": "ESRI ArcInfo interchange file (E00)",
            "id": "fdd000291",
            "filenamepattern": [
                {
                    "value": "*.e00",
                    "url": "https://www.digipres.org/formats/extensions/#*.e00"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000291.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000291.xml"
            }
        },
        {
            "esriarcinfointerchangefile(e00)": "",
            "name": "ESRI ArcInfo interchange file (E00)",
            "id": "fdd000291",
            "filenamepattern": [
                {
                    "value": "*.e00",
                    "url": "https://www.digipres.org/formats/extensions/#*.e00"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000291.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000291.xml"
            }
        },
        {
            "esriarcinfointerchangefile(e00)": "",
            "name": "ESRI ArcInfo interchange file (E00)",
            "id": "fdd000291",
            "filenamepattern": [
                {
                    "value": "*.e00",
                    "url": "https://www.digipres.org/formats/extensions/#*.e00"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000291.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000291.xml"
            }
        },
        {
            "esriarcinfointerchangefile(e00)": "",
            "name": "ESRI ArcInfo interchange file (E00)",
            "id": "fdd000291",
            "filenamepattern": [
                {
                    "value": "*.e00",
                    "url": "https://www.digipres.org/formats/extensions/#*.e00"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000291.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000291.xml"
            }
        },
        {
            "esriarcinfointerchangefile(e00)": "",
            "name": "ESRI ArcInfo interchange file (E00)",
            "id": "fdd000291",
            "filenamepattern": [
                {
                    "value": "*.e00",
                    "url": "https://www.digipres.org/formats/extensions/#*.e00"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000291.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000291.xml"
            }
        },
        {
            "esriarcinfointerchangefile(e00)": "",
            "name": "ESRI ArcInfo interchange file (E00)",
            "id": "fdd000291",
            "filenamepattern": [
                {
                    "value": "*.e00",
                    "url": "https://www.digipres.org/formats/extensions/#*.e00"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000291.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000291.xml"
            }
        },
        {
            "digitalrastergraphicastiff": "",
            "name": "Digital Raster Graphic as TIFF",
            "id": "fdd000292",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.fgd",
                    "url": "https://www.digipres.org/formats/extensions/#*.fgd"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000292.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000292.xml"
            }
        },
        {
            "digitalrastergraphicastiff": "",
            "name": "Digital Raster Graphic as TIFF",
            "id": "fdd000292",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.fgd",
                    "url": "https://www.digipres.org/formats/extensions/#*.fgd"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000292.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000292.xml"
            }
        },
        {
            "digitalrastergraphicastiff": "",
            "name": "Digital Raster Graphic as TIFF",
            "id": "fdd000292",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.fgd",
                    "url": "https://www.digipres.org/formats/extensions/#*.fgd"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000292.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000292.xml"
            }
        },
        {
            "digitalrastergraphicastiff": "",
            "name": "Digital Raster Graphic as TIFF",
            "id": "fdd000292",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.fgd",
                    "url": "https://www.digipres.org/formats/extensions/#*.fgd"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000292.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000292.xml"
            }
        },
        {
            "digitalrastergraphicastiff": "",
            "name": "Digital Raster Graphic as TIFF",
            "id": "fdd000292",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.fgd",
                    "url": "https://www.digipres.org/formats/extensions/#*.fgd"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000292.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000292.xml"
            }
        },
        {
            "digitalrastergraphicastiff": "",
            "name": "Digital Raster Graphic as TIFF",
            "id": "fdd000292",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.fgd",
                    "url": "https://www.digipres.org/formats/extensions/#*.fgd"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000292.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000292.xml"
            }
        },
        {
            "digitalrastergraphicastiff": "",
            "name": "Digital Raster Graphic as TIFF",
            "id": "fdd000292",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.fgd",
                    "url": "https://www.digipres.org/formats/extensions/#*.fgd"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000292.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000292.xml"
            }
        },
        {
            "digitalrastergraphicastiff": "",
            "name": "Digital Raster Graphic as TIFF",
            "id": "fdd000292",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.fgd",
                    "url": "https://www.digipres.org/formats/extensions/#*.fgd"
                },
                {
                    "value": "*.tfw",
                    "url": "https://www.digipres.org/formats/extensions/#*.tfw"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000292.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000292.xml"
            }
        },
        {
            "esriarcgeodatabaseformatfamily": "",
            "name": "ESRI Arc Geodatabase Format Family",
            "id": "fdd000293",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000293.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000293.xml"
            }
        },
        {
            "esriarcgeodatabaseformatfamily": "",
            "name": "ESRI Arc Geodatabase Format Family",
            "id": "fdd000293",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000293.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000293.xml"
            }
        },
        {
            "esriarcgeodatabaseformatfamily": "",
            "name": "ESRI Arc Geodatabase Format Family",
            "id": "fdd000293",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000293.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000293.xml"
            }
        },
        {
            "esriarcgeodatabaseformatfamily": "",
            "name": "ESRI Arc Geodatabase Format Family",
            "id": "fdd000293",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000293.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000293.xml"
            }
        },
        {
            "esriarcgeodatabaseformatfamily": "",
            "name": "ESRI Arc Geodatabase Format Family",
            "id": "fdd000293",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000293.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000293.xml"
            }
        },
        {
            "esriarcgeodatabaseformatfamily": "",
            "name": "ESRI Arc Geodatabase Format Family",
            "id": "fdd000293",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000293.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000293.xml"
            }
        },
        {
            "esriarcgeodatabaseformatfamily": "",
            "name": "ESRI Arc Geodatabase Format Family",
            "id": "fdd000293",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000293.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000293.xml"
            }
        },
        {
            "esriarcgeodatabaseformatfamily": "",
            "name": "ESRI Arc Geodatabase Format Family",
            "id": "fdd000293",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000293.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000293.xml"
            }
        },
        {
            "esriarcgeodatabase(filebased)": "",
            "name": "ESRI Arc Geodatabase (file-based)",
            "id": "fdd000294",
            "filenamepattern": [
                {
                    "value": "*.gdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.gdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000294.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000294.xml"
            }
        },
        {
            "esriarcgeodatabase(filebased)": "",
            "name": "ESRI Arc Geodatabase (file-based)",
            "id": "fdd000294",
            "filenamepattern": [
                {
                    "value": "*.gdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.gdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000294.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000294.xml"
            }
        },
        {
            "esriarcgeodatabase(filebased)": "",
            "name": "ESRI Arc Geodatabase (file-based)",
            "id": "fdd000294",
            "filenamepattern": [
                {
                    "value": "*.gdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.gdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000294.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000294.xml"
            }
        },
        {
            "esriarcgeodatabase(filebased)": "",
            "name": "ESRI Arc Geodatabase (file-based)",
            "id": "fdd000294",
            "filenamepattern": [
                {
                    "value": "*.gdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.gdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000294.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000294.xml"
            }
        },
        {
            "esriarcgeodatabase(filebased)": "",
            "name": "ESRI Arc Geodatabase (file-based)",
            "id": "fdd000294",
            "filenamepattern": [
                {
                    "value": "*.gdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.gdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000294.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000294.xml"
            }
        },
        {
            "esriarcgeodatabase(filebased)": "",
            "name": "ESRI Arc Geodatabase (file-based)",
            "id": "fdd000294",
            "filenamepattern": [
                {
                    "value": "*.gdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.gdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000294.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000294.xml"
            }
        },
        {
            "esriarcgeodatabase(filebased)": "",
            "name": "ESRI Arc Geodatabase (file-based)",
            "id": "fdd000294",
            "filenamepattern": [
                {
                    "value": "*.gdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.gdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000294.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000294.xml"
            }
        },
        {
            "esriarcgeodatabase(filebased)": "",
            "name": "ESRI Arc Geodatabase (file-based)",
            "id": "fdd000294",
            "filenamepattern": [
                {
                    "value": "*.gdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.gdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000294.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000294.xml"
            }
        },
        {
            "esrigeodatabasexml": "",
            "name": "ESRI Geodatabase XML",
            "id": "fdd000295",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000295.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000295.xml"
            }
        },
        {
            "esrigeodatabasexml": "",
            "name": "ESRI Geodatabase XML",
            "id": "fdd000295",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000295.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000295.xml"
            }
        },
        {
            "esrigeodatabasexml": "",
            "name": "ESRI Geodatabase XML",
            "id": "fdd000295",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000295.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000295.xml"
            }
        },
        {
            "esrigeodatabasexml": "",
            "name": "ESRI Geodatabase XML",
            "id": "fdd000295",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000295.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000295.xml"
            }
        },
        {
            "esrigeodatabasexml": "",
            "name": "ESRI Geodatabase XML",
            "id": "fdd000295",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000295.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000295.xml"
            }
        },
        {
            "esrigeodatabasexml": "",
            "name": "ESRI Geodatabase XML",
            "id": "fdd000295",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000295.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000295.xml"
            }
        },
        {
            "esrigeodatabasexml": "",
            "name": "ESRI Geodatabase XML",
            "id": "fdd000295",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000295.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000295.xml"
            }
        },
        {
            "esrigeodatabasexml": "",
            "name": "ESRI Geodatabase XML",
            "id": "fdd000295",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000295.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000295.xml"
            }
        },
        {
            "geographymarkuplanguage(gml)": "",
            "name": "Geography Markup Language (GML)",
            "id": "fdd000296",
            "filenamepattern": [
                {
                    "value": "*.gml",
                    "url": "https://www.digipres.org/formats/extensions/#*.gml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000296.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000296.xml"
            }
        },
        {
            "geographymarkuplanguage(gml)": "",
            "name": "Geography Markup Language (GML)",
            "id": "fdd000296",
            "filenamepattern": [
                {
                    "value": "*.gml",
                    "url": "https://www.digipres.org/formats/extensions/#*.gml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000296.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000296.xml"
            }
        },
        {
            "geographymarkuplanguage(gml)": "",
            "name": "Geography Markup Language (GML)",
            "id": "fdd000296",
            "filenamepattern": [
                {
                    "value": "*.gml",
                    "url": "https://www.digipres.org/formats/extensions/#*.gml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000296.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000296.xml"
            }
        },
        {
            "geographymarkuplanguage(gml)": "",
            "name": "Geography Markup Language (GML)",
            "id": "fdd000296",
            "filenamepattern": [
                {
                    "value": "*.gml",
                    "url": "https://www.digipres.org/formats/extensions/#*.gml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000296.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000296.xml"
            }
        },
        {
            "geographymarkuplanguage(gml)": "",
            "name": "Geography Markup Language (GML)",
            "id": "fdd000296",
            "filenamepattern": [
                {
                    "value": "*.gml",
                    "url": "https://www.digipres.org/formats/extensions/#*.gml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000296.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000296.xml"
            }
        },
        {
            "geographymarkuplanguage(gml)": "",
            "name": "Geography Markup Language (GML)",
            "id": "fdd000296",
            "filenamepattern": [
                {
                    "value": "*.gml",
                    "url": "https://www.digipres.org/formats/extensions/#*.gml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000296.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000296.xml"
            }
        },
        {
            "geographymarkuplanguage(gml)": "",
            "name": "Geography Markup Language (GML)",
            "id": "fdd000296",
            "filenamepattern": [
                {
                    "value": "*.gml",
                    "url": "https://www.digipres.org/formats/extensions/#*.gml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000296.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000296.xml"
            }
        },
        {
            "geographymarkuplanguage(gml)": "",
            "name": "Geography Markup Language (GML)",
            "id": "fdd000296",
            "filenamepattern": [
                {
                    "value": "*.gml",
                    "url": "https://www.digipres.org/formats/extensions/#*.gml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000296.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000296.xml"
            }
        },
        {
            "hdfeos,hierarchicaldataformatearthobservingsystem(basedonhdf4)": "",
            "name": "HDF-EOS, Hierarchical Data Format-Earth Observing System (based on HDF4)",
            "id": "fdd000297",
            "filenamepattern": [
                {
                    "value": "*.he4",
                    "url": "https://www.digipres.org/formats/extensions/#*.he4"
                },
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                },
                {
                    "value": "*.hdfeos",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdfeos"
                },
                {
                    "value": "*.he2",
                    "url": "https://www.digipres.org/formats/extensions/#*.he2"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000297.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000297.xml"
            }
        },
        {
            "hdfeos,hierarchicaldataformatearthobservingsystem(basedonhdf4)": "",
            "name": "HDF-EOS, Hierarchical Data Format-Earth Observing System (based on HDF4)",
            "id": "fdd000297",
            "filenamepattern": [
                {
                    "value": "*.he4",
                    "url": "https://www.digipres.org/formats/extensions/#*.he4"
                },
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                },
                {
                    "value": "*.hdfeos",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdfeos"
                },
                {
                    "value": "*.he2",
                    "url": "https://www.digipres.org/formats/extensions/#*.he2"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000297.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000297.xml"
            }
        },
        {
            "hdfeos,hierarchicaldataformatearthobservingsystem(basedonhdf4)": "",
            "name": "HDF-EOS, Hierarchical Data Format-Earth Observing System (based on HDF4)",
            "id": "fdd000297",
            "filenamepattern": [
                {
                    "value": "*.he4",
                    "url": "https://www.digipres.org/formats/extensions/#*.he4"
                },
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                },
                {
                    "value": "*.hdfeos",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdfeos"
                },
                {
                    "value": "*.he2",
                    "url": "https://www.digipres.org/formats/extensions/#*.he2"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000297.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000297.xml"
            }
        },
        {
            "hdfeos,hierarchicaldataformatearthobservingsystem(basedonhdf4)": "",
            "name": "HDF-EOS, Hierarchical Data Format-Earth Observing System (based on HDF4)",
            "id": "fdd000297",
            "filenamepattern": [
                {
                    "value": "*.he4",
                    "url": "https://www.digipres.org/formats/extensions/#*.he4"
                },
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                },
                {
                    "value": "*.hdfeos",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdfeos"
                },
                {
                    "value": "*.he2",
                    "url": "https://www.digipres.org/formats/extensions/#*.he2"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000297.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000297.xml"
            }
        },
        {
            "hdfeos,hierarchicaldataformatearthobservingsystem(basedonhdf4)": "",
            "name": "HDF-EOS, Hierarchical Data Format-Earth Observing System (based on HDF4)",
            "id": "fdd000297",
            "filenamepattern": [
                {
                    "value": "*.he4",
                    "url": "https://www.digipres.org/formats/extensions/#*.he4"
                },
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                },
                {
                    "value": "*.hdfeos",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdfeos"
                },
                {
                    "value": "*.he2",
                    "url": "https://www.digipres.org/formats/extensions/#*.he2"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000297.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000297.xml"
            }
        },
        {
            "hdfeos,hierarchicaldataformatearthobservingsystem(basedonhdf4)": "",
            "name": "HDF-EOS, Hierarchical Data Format-Earth Observing System (based on HDF4)",
            "id": "fdd000297",
            "filenamepattern": [
                {
                    "value": "*.he4",
                    "url": "https://www.digipres.org/formats/extensions/#*.he4"
                },
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                },
                {
                    "value": "*.hdfeos",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdfeos"
                },
                {
                    "value": "*.he2",
                    "url": "https://www.digipres.org/formats/extensions/#*.he2"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000297.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000297.xml"
            }
        },
        {
            "hdfeos,hierarchicaldataformatearthobservingsystem(basedonhdf4)": "",
            "name": "HDF-EOS, Hierarchical Data Format-Earth Observing System (based on HDF4)",
            "id": "fdd000297",
            "filenamepattern": [
                {
                    "value": "*.he4",
                    "url": "https://www.digipres.org/formats/extensions/#*.he4"
                },
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                },
                {
                    "value": "*.hdfeos",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdfeos"
                },
                {
                    "value": "*.he2",
                    "url": "https://www.digipres.org/formats/extensions/#*.he2"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000297.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000297.xml"
            }
        },
        {
            "hdfeos,hierarchicaldataformatearthobservingsystem(basedonhdf4)": "",
            "name": "HDF-EOS, Hierarchical Data Format-Earth Observing System (based on HDF4)",
            "id": "fdd000297",
            "filenamepattern": [
                {
                    "value": "*.he4",
                    "url": "https://www.digipres.org/formats/extensions/#*.he4"
                },
                {
                    "value": "*.hdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdf"
                },
                {
                    "value": "*.hdfeos",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdfeos"
                },
                {
                    "value": "*.he2",
                    "url": "https://www.digipres.org/formats/extensions/#*.he2"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000297.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000297.xml"
            }
        },
        {
            "rasterproductformat": "",
            "name": "Raster Product Format",
            "id": "fdd000298",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000298.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000298.xml"
            }
        },
        {
            "rasterproductformat": "",
            "name": "Raster Product Format",
            "id": "fdd000298",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000298.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000298.xml"
            }
        },
        {
            "rasterproductformat": "",
            "name": "Raster Product Format",
            "id": "fdd000298",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000298.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000298.xml"
            }
        },
        {
            "rasterproductformat": "",
            "name": "Raster Product Format",
            "id": "fdd000298",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000298.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000298.xml"
            }
        },
        {
            "rasterproductformat": "",
            "name": "Raster Product Format",
            "id": "fdd000298",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000298.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000298.xml"
            }
        },
        {
            "rasterproductformat": "",
            "name": "Raster Product Format",
            "id": "fdd000298",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000298.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000298.xml"
            }
        },
        {
            "rasterproductformat": "",
            "name": "Raster Product Format",
            "id": "fdd000298",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000298.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000298.xml"
            }
        },
        {
            "rasterproductformat": "",
            "name": "Raster Product Format",
            "id": "fdd000298",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000298.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000298.xml"
            }
        },
        {
            "nlapsdataformat(ndf)": "",
            "name": "NLAPS Data Format (NDF)",
            "id": "fdd000299",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000299.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000299.xml"
            }
        },
        {
            "nlapsdataformat(ndf)": "",
            "name": "NLAPS Data Format (NDF)",
            "id": "fdd000299",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000299.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000299.xml"
            }
        },
        {
            "nlapsdataformat(ndf)": "",
            "name": "NLAPS Data Format (NDF)",
            "id": "fdd000299",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000299.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000299.xml"
            }
        },
        {
            "nlapsdataformat(ndf)": "",
            "name": "NLAPS Data Format (NDF)",
            "id": "fdd000299",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000299.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000299.xml"
            }
        },
        {
            "nlapsdataformat(ndf)": "",
            "name": "NLAPS Data Format (NDF)",
            "id": "fdd000299",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000299.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000299.xml"
            }
        },
        {
            "nlapsdataformat(ndf)": "",
            "name": "NLAPS Data Format (NDF)",
            "id": "fdd000299",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000299.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000299.xml"
            }
        },
        {
            "nlapsdataformat(ndf)": "",
            "name": "NLAPS Data Format (NDF)",
            "id": "fdd000299",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000299.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000299.xml"
            }
        },
        {
            "nlapsdataformat(ndf)": "",
            "name": "NLAPS Data Format (NDF)",
            "id": "fdd000299",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000299.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000299.xml"
            }
        },
        {
            "mapinfodatasetformat": "",
            "name": "MapInfo Dataset Format",
            "id": "fdd000300",
            "filenamepattern": [
                {
                    "value": "*.map",
                    "url": "https://www.digipres.org/formats/extensions/#*.map"
                },
                {
                    "value": "*.id",
                    "url": "https://www.digipres.org/formats/extensions/#*.id"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                },
                {
                    "value": "*.ind",
                    "url": "https://www.digipres.org/formats/extensions/#*.ind"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000300.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000300.xml"
            }
        },
        {
            "mapinfodatasetformat": "",
            "name": "MapInfo Dataset Format",
            "id": "fdd000300",
            "filenamepattern": [
                {
                    "value": "*.map",
                    "url": "https://www.digipres.org/formats/extensions/#*.map"
                },
                {
                    "value": "*.id",
                    "url": "https://www.digipres.org/formats/extensions/#*.id"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                },
                {
                    "value": "*.ind",
                    "url": "https://www.digipres.org/formats/extensions/#*.ind"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000300.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000300.xml"
            }
        },
        {
            "mapinfodatasetformat": "",
            "name": "MapInfo Dataset Format",
            "id": "fdd000300",
            "filenamepattern": [
                {
                    "value": "*.map",
                    "url": "https://www.digipres.org/formats/extensions/#*.map"
                },
                {
                    "value": "*.id",
                    "url": "https://www.digipres.org/formats/extensions/#*.id"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                },
                {
                    "value": "*.ind",
                    "url": "https://www.digipres.org/formats/extensions/#*.ind"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000300.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000300.xml"
            }
        },
        {
            "mapinfodatasetformat": "",
            "name": "MapInfo Dataset Format",
            "id": "fdd000300",
            "filenamepattern": [
                {
                    "value": "*.map",
                    "url": "https://www.digipres.org/formats/extensions/#*.map"
                },
                {
                    "value": "*.id",
                    "url": "https://www.digipres.org/formats/extensions/#*.id"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                },
                {
                    "value": "*.ind",
                    "url": "https://www.digipres.org/formats/extensions/#*.ind"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000300.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000300.xml"
            }
        },
        {
            "mapinfodatasetformat": "",
            "name": "MapInfo Dataset Format",
            "id": "fdd000300",
            "filenamepattern": [
                {
                    "value": "*.map",
                    "url": "https://www.digipres.org/formats/extensions/#*.map"
                },
                {
                    "value": "*.id",
                    "url": "https://www.digipres.org/formats/extensions/#*.id"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                },
                {
                    "value": "*.ind",
                    "url": "https://www.digipres.org/formats/extensions/#*.ind"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000300.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000300.xml"
            }
        },
        {
            "mapinfodatasetformat": "",
            "name": "MapInfo Dataset Format",
            "id": "fdd000300",
            "filenamepattern": [
                {
                    "value": "*.map",
                    "url": "https://www.digipres.org/formats/extensions/#*.map"
                },
                {
                    "value": "*.id",
                    "url": "https://www.digipres.org/formats/extensions/#*.id"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                },
                {
                    "value": "*.ind",
                    "url": "https://www.digipres.org/formats/extensions/#*.ind"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000300.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000300.xml"
            }
        },
        {
            "mapinfodatasetformat": "",
            "name": "MapInfo Dataset Format",
            "id": "fdd000300",
            "filenamepattern": [
                {
                    "value": "*.map",
                    "url": "https://www.digipres.org/formats/extensions/#*.map"
                },
                {
                    "value": "*.id",
                    "url": "https://www.digipres.org/formats/extensions/#*.id"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                },
                {
                    "value": "*.ind",
                    "url": "https://www.digipres.org/formats/extensions/#*.ind"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000300.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000300.xml"
            }
        },
        {
            "mapinfodatasetformat": "",
            "name": "MapInfo Dataset Format",
            "id": "fdd000300",
            "filenamepattern": [
                {
                    "value": "*.map",
                    "url": "https://www.digipres.org/formats/extensions/#*.map"
                },
                {
                    "value": "*.id",
                    "url": "https://www.digipres.org/formats/extensions/#*.id"
                },
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                },
                {
                    "value": "*.ind",
                    "url": "https://www.digipres.org/formats/extensions/#*.ind"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000300.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000300.xml"
            }
        },
        {
            "spatialdatatransferstandardtopologicalvectorprofile": "",
            "name": "Spatial Data Transfer Standard - Topological Vector Profile",
            "id": "fdd000301",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000301.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000301.xml"
            }
        },
        {
            "spatialdatatransferstandardtopologicalvectorprofile": "",
            "name": "Spatial Data Transfer Standard - Topological Vector Profile",
            "id": "fdd000301",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000301.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000301.xml"
            }
        },
        {
            "spatialdatatransferstandardtopologicalvectorprofile": "",
            "name": "Spatial Data Transfer Standard - Topological Vector Profile",
            "id": "fdd000301",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000301.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000301.xml"
            }
        },
        {
            "spatialdatatransferstandardtopologicalvectorprofile": "",
            "name": "Spatial Data Transfer Standard - Topological Vector Profile",
            "id": "fdd000301",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000301.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000301.xml"
            }
        },
        {
            "spatialdatatransferstandardtopologicalvectorprofile": "",
            "name": "Spatial Data Transfer Standard - Topological Vector Profile",
            "id": "fdd000301",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000301.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000301.xml"
            }
        },
        {
            "spatialdatatransferstandardtopologicalvectorprofile": "",
            "name": "Spatial Data Transfer Standard - Topological Vector Profile",
            "id": "fdd000301",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000301.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000301.xml"
            }
        },
        {
            "spatialdatatransferstandardtopologicalvectorprofile": "",
            "name": "Spatial Data Transfer Standard - Topological Vector Profile",
            "id": "fdd000301",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000301.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000301.xml"
            }
        },
        {
            "spatialdatatransferstandardtopologicalvectorprofile": "",
            "name": "Spatial Data Transfer Standard - Topological Vector Profile",
            "id": "fdd000301",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000301.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000301.xml"
            }
        },
        {
            "vectorproductformat": "",
            "name": "Vector Product Format",
            "id": "fdd000302",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000302.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000302.xml"
            }
        },
        {
            "vectorproductformat": "",
            "name": "Vector Product Format",
            "id": "fdd000302",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000302.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000302.xml"
            }
        },
        {
            "vectorproductformat": "",
            "name": "Vector Product Format",
            "id": "fdd000302",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000302.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000302.xml"
            }
        },
        {
            "vectorproductformat": "",
            "name": "Vector Product Format",
            "id": "fdd000302",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000302.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000302.xml"
            }
        },
        {
            "vectorproductformat": "",
            "name": "Vector Product Format",
            "id": "fdd000302",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000302.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000302.xml"
            }
        },
        {
            "vectorproductformat": "",
            "name": "Vector Product Format",
            "id": "fdd000302",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000302.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000302.xml"
            }
        },
        {
            "vectorproductformat": "",
            "name": "Vector Product Format",
            "id": "fdd000302",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000302.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000302.xml"
            }
        },
        {
            "vectorproductformat": "",
            "name": "Vector Product Format",
            "id": "fdd000302",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000302.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000302.xml"
            }
        },
        {
            "gmlinjpeg2000forgeographicimagery": "",
            "name": "GML in JPEG 2000 for Geographic Imagery",
            "id": "fdd000303",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                },
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000303.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000303.xml"
            }
        },
        {
            "gmlinjpeg2000forgeographicimagery": "",
            "name": "GML in JPEG 2000 for Geographic Imagery",
            "id": "fdd000303",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                },
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000303.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000303.xml"
            }
        },
        {
            "gmlinjpeg2000forgeographicimagery": "",
            "name": "GML in JPEG 2000 for Geographic Imagery",
            "id": "fdd000303",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                },
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000303.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000303.xml"
            }
        },
        {
            "gmlinjpeg2000forgeographicimagery": "",
            "name": "GML in JPEG 2000 for Geographic Imagery",
            "id": "fdd000303",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                },
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000303.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000303.xml"
            }
        },
        {
            "gmlinjpeg2000forgeographicimagery": "",
            "name": "GML in JPEG 2000 for Geographic Imagery",
            "id": "fdd000303",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                },
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000303.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000303.xml"
            }
        },
        {
            "gmlinjpeg2000forgeographicimagery": "",
            "name": "GML in JPEG 2000 for Geographic Imagery",
            "id": "fdd000303",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                },
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000303.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000303.xml"
            }
        },
        {
            "gmlinjpeg2000forgeographicimagery": "",
            "name": "GML in JPEG 2000 for Geographic Imagery",
            "id": "fdd000303",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                },
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000303.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000303.xml"
            }
        },
        {
            "gmlinjpeg2000forgeographicimagery": "",
            "name": "GML in JPEG 2000 for Geographic Imagery",
            "id": "fdd000303",
            "filenamepattern": [
                {
                    "value": "*.jp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.jp2"
                },
                {
                    "value": "*.jpx",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000303.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000303.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imageencoding": "",
            "name": "Band Interleaved by Line (BIL) Image Encoding",
            "id": "fdd000304",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000304.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000304.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imageencoding": "",
            "name": "Band Interleaved by Line (BIL) Image Encoding",
            "id": "fdd000304",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000304.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000304.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imageencoding": "",
            "name": "Band Interleaved by Line (BIL) Image Encoding",
            "id": "fdd000304",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000304.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000304.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imageencoding": "",
            "name": "Band Interleaved by Line (BIL) Image Encoding",
            "id": "fdd000304",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000304.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000304.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imageencoding": "",
            "name": "Band Interleaved by Line (BIL) Image Encoding",
            "id": "fdd000304",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000304.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000304.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imageencoding": "",
            "name": "Band Interleaved by Line (BIL) Image Encoding",
            "id": "fdd000304",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000304.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000304.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imageencoding": "",
            "name": "Band Interleaved by Line (BIL) Image Encoding",
            "id": "fdd000304",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000304.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000304.xml"
            }
        },
        {
            "bandinterleavedbyline(bil)imageencoding": "",
            "name": "Band Interleaved by Line (BIL) Image Encoding",
            "id": "fdd000304",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000304.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000304.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imageencoding": "",
            "name": "Band Interleaved by Pixel (BIP) Image Encoding",
            "id": "fdd000305",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000305.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000305.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imageencoding": "",
            "name": "Band Interleaved by Pixel (BIP) Image Encoding",
            "id": "fdd000305",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000305.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000305.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imageencoding": "",
            "name": "Band Interleaved by Pixel (BIP) Image Encoding",
            "id": "fdd000305",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000305.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000305.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imageencoding": "",
            "name": "Band Interleaved by Pixel (BIP) Image Encoding",
            "id": "fdd000305",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000305.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000305.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imageencoding": "",
            "name": "Band Interleaved by Pixel (BIP) Image Encoding",
            "id": "fdd000305",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000305.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000305.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imageencoding": "",
            "name": "Band Interleaved by Pixel (BIP) Image Encoding",
            "id": "fdd000305",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000305.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000305.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imageencoding": "",
            "name": "Band Interleaved by Pixel (BIP) Image Encoding",
            "id": "fdd000305",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000305.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000305.xml"
            }
        },
        {
            "bandinterleavedbypixel(bip)imageencoding": "",
            "name": "Band Interleaved by Pixel (BIP) Image Encoding",
            "id": "fdd000305",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000305.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000305.xml"
            }
        },
        {
            "bandsequential(bsq)imageencoding": "",
            "name": "Band Sequential (BSQ) Image Encoding",
            "id": "fdd000306",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000306.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000306.xml"
            }
        },
        {
            "bandsequential(bsq)imageencoding": "",
            "name": "Band Sequential (BSQ) Image Encoding",
            "id": "fdd000306",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000306.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000306.xml"
            }
        },
        {
            "bandsequential(bsq)imageencoding": "",
            "name": "Band Sequential (BSQ) Image Encoding",
            "id": "fdd000306",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000306.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000306.xml"
            }
        },
        {
            "bandsequential(bsq)imageencoding": "",
            "name": "Band Sequential (BSQ) Image Encoding",
            "id": "fdd000306",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000306.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000306.xml"
            }
        },
        {
            "bandsequential(bsq)imageencoding": "",
            "name": "Band Sequential (BSQ) Image Encoding",
            "id": "fdd000306",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000306.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000306.xml"
            }
        },
        {
            "bandsequential(bsq)imageencoding": "",
            "name": "Band Sequential (BSQ) Image Encoding",
            "id": "fdd000306",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000306.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000306.xml"
            }
        },
        {
            "bandsequential(bsq)imageencoding": "",
            "name": "Band Sequential (BSQ) Image Encoding",
            "id": "fdd000306",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000306.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000306.xml"
            }
        },
        {
            "bandsequential(bsq)imageencoding": "",
            "name": "Band Sequential (BSQ) Image Encoding",
            "id": "fdd000306",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000306.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000306.xml"
            }
        },
        {
            "iso/iec8211datadescriptivefile(ddf)": "",
            "name": "ISO/IEC 8211 Data Descriptive File (DDF)",
            "id": "fdd000307",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000307.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000307.xml"
            }
        },
        {
            "iso/iec8211datadescriptivefile(ddf)": "",
            "name": "ISO/IEC 8211 Data Descriptive File (DDF)",
            "id": "fdd000307",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000307.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000307.xml"
            }
        },
        {
            "iso/iec8211datadescriptivefile(ddf)": "",
            "name": "ISO/IEC 8211 Data Descriptive File (DDF)",
            "id": "fdd000307",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000307.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000307.xml"
            }
        },
        {
            "iso/iec8211datadescriptivefile(ddf)": "",
            "name": "ISO/IEC 8211 Data Descriptive File (DDF)",
            "id": "fdd000307",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000307.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000307.xml"
            }
        },
        {
            "iso/iec8211datadescriptivefile(ddf)": "",
            "name": "ISO/IEC 8211 Data Descriptive File (DDF)",
            "id": "fdd000307",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000307.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000307.xml"
            }
        },
        {
            "iso/iec8211datadescriptivefile(ddf)": "",
            "name": "ISO/IEC 8211 Data Descriptive File (DDF)",
            "id": "fdd000307",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000307.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000307.xml"
            }
        },
        {
            "iso/iec8211datadescriptivefile(ddf)": "",
            "name": "ISO/IEC 8211 Data Descriptive File (DDF)",
            "id": "fdd000307",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000307.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000307.xml"
            }
        },
        {
            "iso/iec8211datadescriptivefile(ddf)": "",
            "name": "ISO/IEC 8211 Data Descriptive File (DDF)",
            "id": "fdd000307",
            "filenamepattern": [
                {
                    "value": "*.ddf",
                    "url": "https://www.digipres.org/formats/extensions/#*.ddf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000307.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000307.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0(2011).iso/iects30135:2014": "",
            "name": "EPUB, Electronic Publication, Version 3.0 (2011).  ISO/IEC TS 30135:2014",
            "id": "fdd000308",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000308.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000308.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0(2011).iso/iects30135:2014": "",
            "name": "EPUB, Electronic Publication, Version 3.0 (2011).  ISO/IEC TS 30135:2014",
            "id": "fdd000308",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000308.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000308.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0(2011).iso/iects30135:2014": "",
            "name": "EPUB, Electronic Publication, Version 3.0 (2011).  ISO/IEC TS 30135:2014",
            "id": "fdd000308",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000308.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000308.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0(2011).iso/iects30135:2014": "",
            "name": "EPUB, Electronic Publication, Version 3.0 (2011).  ISO/IEC TS 30135:2014",
            "id": "fdd000308",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000308.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000308.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0(2011).iso/iects30135:2014": "",
            "name": "EPUB, Electronic Publication, Version 3.0 (2011).  ISO/IEC TS 30135:2014",
            "id": "fdd000308",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000308.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000308.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0(2011).iso/iects30135:2014": "",
            "name": "EPUB, Electronic Publication, Version 3.0 (2011).  ISO/IEC TS 30135:2014",
            "id": "fdd000308",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000308.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000308.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0(2011).iso/iects30135:2014": "",
            "name": "EPUB, Electronic Publication, Version 3.0 (2011).  ISO/IEC TS 30135:2014",
            "id": "fdd000308",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000308.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000308.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0(2011).iso/iects30135:2014": "",
            "name": "EPUB, Electronic Publication, Version 3.0 (2011).  ISO/IEC TS 30135:2014",
            "id": "fdd000308",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000308.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000308.xml"
            }
        },
        {
            "epub,electronicpublication,version3.2": "",
            "name": "EPUB, Electronic Publication, Version 3.2",
            "id": "fdd000309",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000309.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000309.xml"
            }
        },
        {
            "epub,electronicpublication,version3.2": "",
            "name": "EPUB, Electronic Publication, Version 3.2",
            "id": "fdd000309",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000309.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000309.xml"
            }
        },
        {
            "epub,electronicpublication,version3.2": "",
            "name": "EPUB, Electronic Publication, Version 3.2",
            "id": "fdd000309",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000309.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000309.xml"
            }
        },
        {
            "epub,electronicpublication,version3.2": "",
            "name": "EPUB, Electronic Publication, Version 3.2",
            "id": "fdd000309",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000309.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000309.xml"
            }
        },
        {
            "epub,electronicpublication,version3.2": "",
            "name": "EPUB, Electronic Publication, Version 3.2",
            "id": "fdd000309",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000309.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000309.xml"
            }
        },
        {
            "epub,electronicpublication,version3.2": "",
            "name": "EPUB, Electronic Publication, Version 3.2",
            "id": "fdd000309",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000309.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000309.xml"
            }
        },
        {
            "epub,electronicpublication,version3.2": "",
            "name": "EPUB, Electronic Publication, Version 3.2",
            "id": "fdd000309",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000309.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000309.xml"
            }
        },
        {
            "epub,electronicpublication,version3.2": "",
            "name": "EPUB, Electronic Publication, Version 3.2",
            "id": "fdd000309",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000309.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000309.xml"
            }
        },
        {
            "epub(electronicpublication)fileformatfamily": "",
            "name": "EPUB (Electronic Publication) File Format Family",
            "id": "fdd000310",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000310.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000310.xml"
            }
        },
        {
            "epub(electronicpublication)fileformatfamily": "",
            "name": "EPUB (Electronic Publication) File Format Family",
            "id": "fdd000310",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000310.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000310.xml"
            }
        },
        {
            "epub(electronicpublication)fileformatfamily": "",
            "name": "EPUB (Electronic Publication) File Format Family",
            "id": "fdd000310",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000310.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000310.xml"
            }
        },
        {
            "epub(electronicpublication)fileformatfamily": "",
            "name": "EPUB (Electronic Publication) File Format Family",
            "id": "fdd000310",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000310.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000310.xml"
            }
        },
        {
            "epub(electronicpublication)fileformatfamily": "",
            "name": "EPUB (Electronic Publication) File Format Family",
            "id": "fdd000310",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000310.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000310.xml"
            }
        },
        {
            "epub(electronicpublication)fileformatfamily": "",
            "name": "EPUB (Electronic Publication) File Format Family",
            "id": "fdd000310",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000310.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000310.xml"
            }
        },
        {
            "epub(electronicpublication)fileformatfamily": "",
            "name": "EPUB (Electronic Publication) File Format Family",
            "id": "fdd000310",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000310.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000310.xml"
            }
        },
        {
            "epub(electronicpublication)fileformatfamily": "",
            "name": "EPUB (Electronic Publication) File Format Family",
            "id": "fdd000310",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000310.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000310.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0.1(2014).iso/iec23736:2020": "",
            "name": "EPUB, Electronic Publication, Version 3.0.1 (2014). ISO/IEC 23736:2020",
            "id": "fdd000311",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000311.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000311.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0.1(2014).iso/iec23736:2020": "",
            "name": "EPUB, Electronic Publication, Version 3.0.1 (2014). ISO/IEC 23736:2020",
            "id": "fdd000311",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000311.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000311.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0.1(2014).iso/iec23736:2020": "",
            "name": "EPUB, Electronic Publication, Version 3.0.1 (2014). ISO/IEC 23736:2020",
            "id": "fdd000311",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000311.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000311.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0.1(2014).iso/iec23736:2020": "",
            "name": "EPUB, Electronic Publication, Version 3.0.1 (2014). ISO/IEC 23736:2020",
            "id": "fdd000311",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000311.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000311.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0.1(2014).iso/iec23736:2020": "",
            "name": "EPUB, Electronic Publication, Version 3.0.1 (2014). ISO/IEC 23736:2020",
            "id": "fdd000311",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000311.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000311.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0.1(2014).iso/iec23736:2020": "",
            "name": "EPUB, Electronic Publication, Version 3.0.1 (2014). ISO/IEC 23736:2020",
            "id": "fdd000311",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000311.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000311.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0.1(2014).iso/iec23736:2020": "",
            "name": "EPUB, Electronic Publication, Version 3.0.1 (2014). ISO/IEC 23736:2020",
            "id": "fdd000311",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000311.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000311.xml"
            }
        },
        {
            "epub,electronicpublication,version3.0.1(2014).iso/iec23736:2020": "",
            "name": "EPUB, Electronic Publication, Version 3.0.1 (2014). ISO/IEC 23736:2020",
            "id": "fdd000311",
            "filenamepattern": [
                {
                    "value": "*.epub",
                    "url": "https://www.digipres.org/formats/extensions/#*.epub"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/epub+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/epub+zip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000311.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000311.xml"
            }
        },
        {
            "geopdfencoding(terrago2.2),ogcbestpractice": "",
            "name": "GeoPDF Encoding (TerraGo 2.2), OGC Best Practice",
            "id": "fdd000312",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000312.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000312.xml"
            }
        },
        {
            "geopdfencoding(terrago2.2),ogcbestpractice": "",
            "name": "GeoPDF Encoding (TerraGo 2.2), OGC Best Practice",
            "id": "fdd000312",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000312.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000312.xml"
            }
        },
        {
            "geopdfencoding(terrago2.2),ogcbestpractice": "",
            "name": "GeoPDF Encoding (TerraGo 2.2), OGC Best Practice",
            "id": "fdd000312",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000312.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000312.xml"
            }
        },
        {
            "geopdfencoding(terrago2.2),ogcbestpractice": "",
            "name": "GeoPDF Encoding (TerraGo 2.2), OGC Best Practice",
            "id": "fdd000312",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000312.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000312.xml"
            }
        },
        {
            "geopdfencoding(terrago2.2),ogcbestpractice": "",
            "name": "GeoPDF Encoding (TerraGo 2.2), OGC Best Practice",
            "id": "fdd000312",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000312.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000312.xml"
            }
        },
        {
            "geopdfencoding(terrago2.2),ogcbestpractice": "",
            "name": "GeoPDF Encoding (TerraGo 2.2), OGC Best Practice",
            "id": "fdd000312",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000312.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000312.xml"
            }
        },
        {
            "geopdfencoding(terrago2.2),ogcbestpractice": "",
            "name": "GeoPDF Encoding (TerraGo 2.2), OGC Best Practice",
            "id": "fdd000312",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000312.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000312.xml"
            }
        },
        {
            "geopdfencoding(terrago2.2),ogcbestpractice": "",
            "name": "GeoPDF Encoding (TerraGo 2.2), OGC Best Practice",
            "id": "fdd000312",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000312.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000312.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3",
            "id": "fdd000313",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000313.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000313.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3",
            "id": "fdd000313",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000313.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000313.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3",
            "id": "fdd000313",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000313.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000313.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3",
            "id": "fdd000313",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000313.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000313.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3",
            "id": "fdd000313",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000313.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000313.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3",
            "id": "fdd000313",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000313.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000313.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3",
            "id": "fdd000313",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000313.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000313.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3",
            "id": "fdd000313",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000313.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000313.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel5": "",
            "name": "PDF, Version 1.7, ExtensionLevel 5",
            "id": "fdd000314",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000314.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000314.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel5": "",
            "name": "PDF, Version 1.7, ExtensionLevel 5",
            "id": "fdd000314",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000314.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000314.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel5": "",
            "name": "PDF, Version 1.7, ExtensionLevel 5",
            "id": "fdd000314",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000314.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000314.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel5": "",
            "name": "PDF, Version 1.7, ExtensionLevel 5",
            "id": "fdd000314",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000314.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000314.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel5": "",
            "name": "PDF, Version 1.7, ExtensionLevel 5",
            "id": "fdd000314",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000314.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000314.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel5": "",
            "name": "PDF, Version 1.7, ExtensionLevel 5",
            "id": "fdd000314",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000314.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000314.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel5": "",
            "name": "PDF, Version 1.7, ExtensionLevel 5",
            "id": "fdd000314",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000314.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000314.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel5": "",
            "name": "PDF, Version 1.7, ExtensionLevel 5",
            "id": "fdd000314",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000314.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000314.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3,geospatialencoding(adobe)": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3, Geospatial Encoding (Adobe)",
            "id": "fdd000315",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000315.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000315.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3,geospatialencoding(adobe)": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3, Geospatial Encoding (Adobe)",
            "id": "fdd000315",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000315.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000315.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3,geospatialencoding(adobe)": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3, Geospatial Encoding (Adobe)",
            "id": "fdd000315",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000315.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000315.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3,geospatialencoding(adobe)": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3, Geospatial Encoding (Adobe)",
            "id": "fdd000315",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000315.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000315.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3,geospatialencoding(adobe)": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3, Geospatial Encoding (Adobe)",
            "id": "fdd000315",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000315.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000315.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3,geospatialencoding(adobe)": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3, Geospatial Encoding (Adobe)",
            "id": "fdd000315",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000315.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000315.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3,geospatialencoding(adobe)": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3, Geospatial Encoding (Adobe)",
            "id": "fdd000315",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000315.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000315.xml"
            }
        },
        {
            "pdf,version1.7,extensionlevel3,geospatialencoding(adobe)": "",
            "name": "PDF, Version 1.7, ExtensionLevel 3, Geospatial Encoding (Adobe)",
            "id": "fdd000315",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000315.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000315.xml"
            }
        },
        {
            "pdf_1_3,pdfversions1.01.3": "",
            "name": "PDF_1_3, PDF Versions 1.0-1.3",
            "id": "fdd000316",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000316.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000316.xml"
            }
        },
        {
            "pdf_1_3,pdfversions1.01.3": "",
            "name": "PDF_1_3, PDF Versions 1.0-1.3",
            "id": "fdd000316",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000316.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000316.xml"
            }
        },
        {
            "pdf_1_3,pdfversions1.01.3": "",
            "name": "PDF_1_3, PDF Versions 1.0-1.3",
            "id": "fdd000316",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000316.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000316.xml"
            }
        },
        {
            "pdf_1_3,pdfversions1.01.3": "",
            "name": "PDF_1_3, PDF Versions 1.0-1.3",
            "id": "fdd000316",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000316.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000316.xml"
            }
        },
        {
            "pdf_1_3,pdfversions1.01.3": "",
            "name": "PDF_1_3, PDF Versions 1.0-1.3",
            "id": "fdd000316",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000316.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000316.xml"
            }
        },
        {
            "pdf_1_3,pdfversions1.01.3": "",
            "name": "PDF_1_3, PDF Versions 1.0-1.3",
            "id": "fdd000316",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000316.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000316.xml"
            }
        },
        {
            "pdf_1_3,pdfversions1.01.3": "",
            "name": "PDF_1_3, PDF Versions 1.0-1.3",
            "id": "fdd000316",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000316.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000316.xml"
            }
        },
        {
            "pdf_1_3,pdfversions1.01.3": "",
            "name": "PDF_1_3, PDF Versions 1.0-1.3",
            "id": "fdd000316",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000316.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000316.xml"
            }
        },
        {
            "flexibleimagetransportsystem(fits),version3.0": "",
            "name": "Flexible Image Transport System (FITS), Version 3.0",
            "id": "fdd000317",
            "filenamepattern": [
                {
                    "value": "*.fits",
                    "url": "https://www.digipres.org/formats/extensions/#*.fits"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#application/fits"
                },
                {
                    "value": "image/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#image/fits"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000317.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000317.xml"
            }
        },
        {
            "flexibleimagetransportsystem(fits),version3.0": "",
            "name": "Flexible Image Transport System (FITS), Version 3.0",
            "id": "fdd000317",
            "filenamepattern": [
                {
                    "value": "*.fits",
                    "url": "https://www.digipres.org/formats/extensions/#*.fits"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#application/fits"
                },
                {
                    "value": "image/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#image/fits"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000317.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000317.xml"
            }
        },
        {
            "flexibleimagetransportsystem(fits),version3.0": "",
            "name": "Flexible Image Transport System (FITS), Version 3.0",
            "id": "fdd000317",
            "filenamepattern": [
                {
                    "value": "*.fits",
                    "url": "https://www.digipres.org/formats/extensions/#*.fits"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#application/fits"
                },
                {
                    "value": "image/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#image/fits"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000317.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000317.xml"
            }
        },
        {
            "flexibleimagetransportsystem(fits),version3.0": "",
            "name": "Flexible Image Transport System (FITS), Version 3.0",
            "id": "fdd000317",
            "filenamepattern": [
                {
                    "value": "*.fits",
                    "url": "https://www.digipres.org/formats/extensions/#*.fits"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#application/fits"
                },
                {
                    "value": "image/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#image/fits"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000317.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000317.xml"
            }
        },
        {
            "flexibleimagetransportsystem(fits),version3.0": "",
            "name": "Flexible Image Transport System (FITS), Version 3.0",
            "id": "fdd000317",
            "filenamepattern": [
                {
                    "value": "*.fits",
                    "url": "https://www.digipres.org/formats/extensions/#*.fits"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#application/fits"
                },
                {
                    "value": "image/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#image/fits"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000317.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000317.xml"
            }
        },
        {
            "flexibleimagetransportsystem(fits),version3.0": "",
            "name": "Flexible Image Transport System (FITS), Version 3.0",
            "id": "fdd000317",
            "filenamepattern": [
                {
                    "value": "*.fits",
                    "url": "https://www.digipres.org/formats/extensions/#*.fits"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#application/fits"
                },
                {
                    "value": "image/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#image/fits"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000317.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000317.xml"
            }
        },
        {
            "flexibleimagetransportsystem(fits),version3.0": "",
            "name": "Flexible Image Transport System (FITS), Version 3.0",
            "id": "fdd000317",
            "filenamepattern": [
                {
                    "value": "*.fits",
                    "url": "https://www.digipres.org/formats/extensions/#*.fits"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#application/fits"
                },
                {
                    "value": "image/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#image/fits"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000317.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000317.xml"
            }
        },
        {
            "flexibleimagetransportsystem(fits),version3.0": "",
            "name": "Flexible Image Transport System (FITS), Version 3.0",
            "id": "fdd000317",
            "filenamepattern": [
                {
                    "value": "*.fits",
                    "url": "https://www.digipres.org/formats/extensions/#*.fits"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#application/fits"
                },
                {
                    "value": "image/fits",
                    "url": "https://www.digipres.org/formats/mime-types/#image/fits"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000317.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000317.xml"
            }
        },
        {
            "pdf/afamily,pdfforlongtermpreservation": "",
            "name": "PDF/A Family, PDF for Long-term Preservation",
            "id": "fdd000318",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000318.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000318.xml"
            }
        },
        {
            "pdf/afamily,pdfforlongtermpreservation": "",
            "name": "PDF/A Family, PDF for Long-term Preservation",
            "id": "fdd000318",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000318.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000318.xml"
            }
        },
        {
            "pdf/afamily,pdfforlongtermpreservation": "",
            "name": "PDF/A Family, PDF for Long-term Preservation",
            "id": "fdd000318",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000318.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000318.xml"
            }
        },
        {
            "pdf/afamily,pdfforlongtermpreservation": "",
            "name": "PDF/A Family, PDF for Long-term Preservation",
            "id": "fdd000318",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000318.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000318.xml"
            }
        },
        {
            "pdf/afamily,pdfforlongtermpreservation": "",
            "name": "PDF/A Family, PDF for Long-term Preservation",
            "id": "fdd000318",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000318.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000318.xml"
            }
        },
        {
            "pdf/afamily,pdfforlongtermpreservation": "",
            "name": "PDF/A Family, PDF for Long-term Preservation",
            "id": "fdd000318",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000318.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000318.xml"
            }
        },
        {
            "pdf/afamily,pdfforlongtermpreservation": "",
            "name": "PDF/A Family, PDF for Long-term Preservation",
            "id": "fdd000318",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000318.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000318.xml"
            }
        },
        {
            "pdf/afamily,pdfforlongtermpreservation": "",
            "name": "PDF/A Family, PDF for Long-term Preservation",
            "id": "fdd000318",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000318.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000318.xml"
            }
        },
        {
            "pdf/a2,pdfforlongtermpreservation,useofiso320001(pdf1.7)": "",
            "name": "PDF/A-2, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7)",
            "id": "fdd000319",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000319.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000319.xml"
            }
        },
        {
            "pdf/a2,pdfforlongtermpreservation,useofiso320001(pdf1.7)": "",
            "name": "PDF/A-2, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7)",
            "id": "fdd000319",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000319.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000319.xml"
            }
        },
        {
            "pdf/a2,pdfforlongtermpreservation,useofiso320001(pdf1.7)": "",
            "name": "PDF/A-2, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7)",
            "id": "fdd000319",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000319.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000319.xml"
            }
        },
        {
            "pdf/a2,pdfforlongtermpreservation,useofiso320001(pdf1.7)": "",
            "name": "PDF/A-2, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7)",
            "id": "fdd000319",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000319.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000319.xml"
            }
        },
        {
            "pdf/a2,pdfforlongtermpreservation,useofiso320001(pdf1.7)": "",
            "name": "PDF/A-2, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7)",
            "id": "fdd000319",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000319.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000319.xml"
            }
        },
        {
            "pdf/a2,pdfforlongtermpreservation,useofiso320001(pdf1.7)": "",
            "name": "PDF/A-2, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7)",
            "id": "fdd000319",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000319.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000319.xml"
            }
        },
        {
            "pdf/a2,pdfforlongtermpreservation,useofiso320001(pdf1.7)": "",
            "name": "PDF/A-2, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7)",
            "id": "fdd000319",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000319.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000319.xml"
            }
        },
        {
            "pdf/a2,pdfforlongtermpreservation,useofiso320001(pdf1.7)": "",
            "name": "PDF/A-2, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7)",
            "id": "fdd000319",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000319.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000319.xml"
            }
        },
        {
            "pdf/a2a,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelaconformance": "",
            "name": "PDF/A-2a, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level A Conformance",
            "id": "fdd000320",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000320.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000320.xml"
            }
        },
        {
            "pdf/a2a,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelaconformance": "",
            "name": "PDF/A-2a, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level A Conformance",
            "id": "fdd000320",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000320.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000320.xml"
            }
        },
        {
            "pdf/a2a,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelaconformance": "",
            "name": "PDF/A-2a, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level A Conformance",
            "id": "fdd000320",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000320.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000320.xml"
            }
        },
        {
            "pdf/a2a,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelaconformance": "",
            "name": "PDF/A-2a, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level A Conformance",
            "id": "fdd000320",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000320.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000320.xml"
            }
        },
        {
            "pdf/a2a,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelaconformance": "",
            "name": "PDF/A-2a, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level A Conformance",
            "id": "fdd000320",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000320.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000320.xml"
            }
        },
        {
            "pdf/a2a,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelaconformance": "",
            "name": "PDF/A-2a, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level A Conformance",
            "id": "fdd000320",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000320.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000320.xml"
            }
        },
        {
            "pdf/a2a,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelaconformance": "",
            "name": "PDF/A-2a, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level A Conformance",
            "id": "fdd000320",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000320.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000320.xml"
            }
        },
        {
            "pdf/a2a,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelaconformance": "",
            "name": "PDF/A-2a, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level A Conformance",
            "id": "fdd000320",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000320.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000320.xml"
            }
        },
        {
            "pdf/a2u,pdfforlongtermpreservation,useofiso320001(pdf1.7),leveluconformance": "",
            "name": "PDF/A-2u, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level U Conformance",
            "id": "fdd000321",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000321.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000321.xml"
            }
        },
        {
            "pdf/a2u,pdfforlongtermpreservation,useofiso320001(pdf1.7),leveluconformance": "",
            "name": "PDF/A-2u, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level U Conformance",
            "id": "fdd000321",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000321.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000321.xml"
            }
        },
        {
            "pdf/a2u,pdfforlongtermpreservation,useofiso320001(pdf1.7),leveluconformance": "",
            "name": "PDF/A-2u, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level U Conformance",
            "id": "fdd000321",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000321.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000321.xml"
            }
        },
        {
            "pdf/a2u,pdfforlongtermpreservation,useofiso320001(pdf1.7),leveluconformance": "",
            "name": "PDF/A-2u, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level U Conformance",
            "id": "fdd000321",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000321.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000321.xml"
            }
        },
        {
            "pdf/a2u,pdfforlongtermpreservation,useofiso320001(pdf1.7),leveluconformance": "",
            "name": "PDF/A-2u, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level U Conformance",
            "id": "fdd000321",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000321.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000321.xml"
            }
        },
        {
            "pdf/a2u,pdfforlongtermpreservation,useofiso320001(pdf1.7),leveluconformance": "",
            "name": "PDF/A-2u, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level U Conformance",
            "id": "fdd000321",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000321.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000321.xml"
            }
        },
        {
            "pdf/a2u,pdfforlongtermpreservation,useofiso320001(pdf1.7),leveluconformance": "",
            "name": "PDF/A-2u, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level U Conformance",
            "id": "fdd000321",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000321.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000321.xml"
            }
        },
        {
            "pdf/a2u,pdfforlongtermpreservation,useofiso320001(pdf1.7),leveluconformance": "",
            "name": "PDF/A-2u, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level U Conformance",
            "id": "fdd000321",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000321.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000321.xml"
            }
        },
        {
            "pdf/a2b,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelbconformance": "",
            "name": "PDF/A-2b, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level B Conformance",
            "id": "fdd000322",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000322.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000322.xml"
            }
        },
        {
            "pdf/a2b,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelbconformance": "",
            "name": "PDF/A-2b, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level B Conformance",
            "id": "fdd000322",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000322.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000322.xml"
            }
        },
        {
            "pdf/a2b,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelbconformance": "",
            "name": "PDF/A-2b, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level B Conformance",
            "id": "fdd000322",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000322.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000322.xml"
            }
        },
        {
            "pdf/a2b,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelbconformance": "",
            "name": "PDF/A-2b, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level B Conformance",
            "id": "fdd000322",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000322.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000322.xml"
            }
        },
        {
            "pdf/a2b,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelbconformance": "",
            "name": "PDF/A-2b, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level B Conformance",
            "id": "fdd000322",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000322.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000322.xml"
            }
        },
        {
            "pdf/a2b,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelbconformance": "",
            "name": "PDF/A-2b, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level B Conformance",
            "id": "fdd000322",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000322.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000322.xml"
            }
        },
        {
            "pdf/a2b,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelbconformance": "",
            "name": "PDF/A-2b, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level B Conformance",
            "id": "fdd000322",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000322.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000322.xml"
            }
        },
        {
            "pdf/a2b,pdfforlongtermpreservation,useofiso320001(pdf1.7),levelbconformance": "",
            "name": "PDF/A-2b, PDF for Long-term Preservation, Use of ISO 32000-1 (PDF 1.7), Level B Conformance",
            "id": "fdd000322",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000322.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000322.xml"
            }
        },
        {
            "csv,commaseparatedvalues(rfc4180)": "",
            "name": "CSV, Comma Separated Values (RFC 4180)",
            "id": "fdd000323",
            "filenamepattern": [
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/csv",
                    "url": "https://www.digipres.org/formats/mime-types/#text/csv"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000323.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000323.xml"
            }
        },
        {
            "csv,commaseparatedvalues(rfc4180)": "",
            "name": "CSV, Comma Separated Values (RFC 4180)",
            "id": "fdd000323",
            "filenamepattern": [
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/csv",
                    "url": "https://www.digipres.org/formats/mime-types/#text/csv"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000323.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000323.xml"
            }
        },
        {
            "csv,commaseparatedvalues(rfc4180)": "",
            "name": "CSV, Comma Separated Values (RFC 4180)",
            "id": "fdd000323",
            "filenamepattern": [
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/csv",
                    "url": "https://www.digipres.org/formats/mime-types/#text/csv"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000323.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000323.xml"
            }
        },
        {
            "csv,commaseparatedvalues(rfc4180)": "",
            "name": "CSV, Comma Separated Values (RFC 4180)",
            "id": "fdd000323",
            "filenamepattern": [
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/csv",
                    "url": "https://www.digipres.org/formats/mime-types/#text/csv"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000323.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000323.xml"
            }
        },
        {
            "csv,commaseparatedvalues(rfc4180)": "",
            "name": "CSV, Comma Separated Values (RFC 4180)",
            "id": "fdd000323",
            "filenamepattern": [
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/csv",
                    "url": "https://www.digipres.org/formats/mime-types/#text/csv"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000323.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000323.xml"
            }
        },
        {
            "csv,commaseparatedvalues(rfc4180)": "",
            "name": "CSV, Comma Separated Values (RFC 4180)",
            "id": "fdd000323",
            "filenamepattern": [
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/csv",
                    "url": "https://www.digipres.org/formats/mime-types/#text/csv"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000323.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000323.xml"
            }
        },
        {
            "csv,commaseparatedvalues(rfc4180)": "",
            "name": "CSV, Comma Separated Values (RFC 4180)",
            "id": "fdd000323",
            "filenamepattern": [
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/csv",
                    "url": "https://www.digipres.org/formats/mime-types/#text/csv"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000323.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000323.xml"
            }
        },
        {
            "csv,commaseparatedvalues(rfc4180)": "",
            "name": "CSV, Comma Separated Values (RFC 4180)",
            "id": "fdd000323",
            "filenamepattern": [
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/csv",
                    "url": "https://www.digipres.org/formats/mime-types/#text/csv"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000323.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000323.xml"
            }
        },
        {
            "mapinfodatafile(dat)": "",
            "name": "MapInfo Data File (DAT)",
            "id": "fdd000324",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000324.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000324.xml"
            }
        },
        {
            "mapinfodatafile(dat)": "",
            "name": "MapInfo Data File (DAT)",
            "id": "fdd000324",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000324.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000324.xml"
            }
        },
        {
            "mapinfodatafile(dat)": "",
            "name": "MapInfo Data File (DAT)",
            "id": "fdd000324",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000324.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000324.xml"
            }
        },
        {
            "mapinfodatafile(dat)": "",
            "name": "MapInfo Data File (DAT)",
            "id": "fdd000324",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000324.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000324.xml"
            }
        },
        {
            "mapinfodatafile(dat)": "",
            "name": "MapInfo Data File (DAT)",
            "id": "fdd000324",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000324.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000324.xml"
            }
        },
        {
            "mapinfodatafile(dat)": "",
            "name": "MapInfo Data File (DAT)",
            "id": "fdd000324",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000324.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000324.xml"
            }
        },
        {
            "mapinfodatafile(dat)": "",
            "name": "MapInfo Data File (DAT)",
            "id": "fdd000324",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000324.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000324.xml"
            }
        },
        {
            "mapinfodatafile(dat)": "",
            "name": "MapInfo Data File (DAT)",
            "id": "fdd000324",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000324.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000324.xml"
            }
        },
        {
            "dbasetablefileformat(dbf)": "",
            "name": "dBASE Table File Format (DBF)",
            "id": "fdd000325",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbase"
                },
                {
                    "value": "application/x-dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbf"
                },
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000325.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000325.xml"
            }
        },
        {
            "dbasetablefileformat(dbf)": "",
            "name": "dBASE Table File Format (DBF)",
            "id": "fdd000325",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbase"
                },
                {
                    "value": "application/x-dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbf"
                },
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000325.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000325.xml"
            }
        },
        {
            "dbasetablefileformat(dbf)": "",
            "name": "dBASE Table File Format (DBF)",
            "id": "fdd000325",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbase"
                },
                {
                    "value": "application/x-dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbf"
                },
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000325.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000325.xml"
            }
        },
        {
            "dbasetablefileformat(dbf)": "",
            "name": "dBASE Table File Format (DBF)",
            "id": "fdd000325",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbase"
                },
                {
                    "value": "application/x-dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbf"
                },
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000325.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000325.xml"
            }
        },
        {
            "dbasetablefileformat(dbf)": "",
            "name": "dBASE Table File Format (DBF)",
            "id": "fdd000325",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbase"
                },
                {
                    "value": "application/x-dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbf"
                },
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000325.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000325.xml"
            }
        },
        {
            "dbasetablefileformat(dbf)": "",
            "name": "dBASE Table File Format (DBF)",
            "id": "fdd000325",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbase"
                },
                {
                    "value": "application/x-dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbf"
                },
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000325.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000325.xml"
            }
        },
        {
            "dbasetablefileformat(dbf)": "",
            "name": "dBASE Table File Format (DBF)",
            "id": "fdd000325",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbase"
                },
                {
                    "value": "application/x-dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbf"
                },
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000325.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000325.xml"
            }
        },
        {
            "dbasetablefileformat(dbf)": "",
            "name": "dBASE Table File Format (DBF)",
            "id": "fdd000325",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbase"
                },
                {
                    "value": "application/x-dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-dbf"
                },
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000325.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000325.xml"
            }
        },
        {
            "dbasetableforesrishapefile(dbf)": "",
            "name": "dBASE Table for ESRI Shapefile (DBF)",
            "id": "fdd000326",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000326.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000326.xml"
            }
        },
        {
            "dbasetableforesrishapefile(dbf)": "",
            "name": "dBASE Table for ESRI Shapefile (DBF)",
            "id": "fdd000326",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000326.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000326.xml"
            }
        },
        {
            "dbasetableforesrishapefile(dbf)": "",
            "name": "dBASE Table for ESRI Shapefile (DBF)",
            "id": "fdd000326",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000326.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000326.xml"
            }
        },
        {
            "dbasetableforesrishapefile(dbf)": "",
            "name": "dBASE Table for ESRI Shapefile (DBF)",
            "id": "fdd000326",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000326.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000326.xml"
            }
        },
        {
            "dbasetableforesrishapefile(dbf)": "",
            "name": "dBASE Table for ESRI Shapefile (DBF)",
            "id": "fdd000326",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000326.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000326.xml"
            }
        },
        {
            "dbasetableforesrishapefile(dbf)": "",
            "name": "dBASE Table for ESRI Shapefile (DBF)",
            "id": "fdd000326",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000326.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000326.xml"
            }
        },
        {
            "dbasetableforesrishapefile(dbf)": "",
            "name": "dBASE Table for ESRI Shapefile (DBF)",
            "id": "fdd000326",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000326.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000326.xml"
            }
        },
        {
            "dbasetableforesrishapefile(dbf)": "",
            "name": "dBASE Table for ESRI Shapefile (DBF)",
            "id": "fdd000326",
            "filenamepattern": [
                {
                    "value": "*.dbf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dbf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/dbf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbf"
                },
                {
                    "value": "application/dbase",
                    "url": "https://www.digipres.org/formats/mime-types/#application/dbase"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000326.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000326.xml"
            }
        },
        {
            "hdf4map,hdf4filecontentmap": "",
            "name": "HDF4map, HDF4 File Content Map",
            "id": "fdd000327",
            "filenamepattern": [
                {
                    "value": "*.map.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.map.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000327.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000327.xml"
            }
        },
        {
            "hdf4map,hdf4filecontentmap": "",
            "name": "HDF4map, HDF4 File Content Map",
            "id": "fdd000327",
            "filenamepattern": [
                {
                    "value": "*.map.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.map.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000327.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000327.xml"
            }
        },
        {
            "hdf4map,hdf4filecontentmap": "",
            "name": "HDF4map, HDF4 File Content Map",
            "id": "fdd000327",
            "filenamepattern": [
                {
                    "value": "*.map.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.map.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000327.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000327.xml"
            }
        },
        {
            "hdf4map,hdf4filecontentmap": "",
            "name": "HDF4map, HDF4 File Content Map",
            "id": "fdd000327",
            "filenamepattern": [
                {
                    "value": "*.map.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.map.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000327.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000327.xml"
            }
        },
        {
            "hdf4map,hdf4filecontentmap": "",
            "name": "HDF4map, HDF4 File Content Map",
            "id": "fdd000327",
            "filenamepattern": [
                {
                    "value": "*.map.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.map.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000327.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000327.xml"
            }
        },
        {
            "hdf4map,hdf4filecontentmap": "",
            "name": "HDF4map, HDF4 File Content Map",
            "id": "fdd000327",
            "filenamepattern": [
                {
                    "value": "*.map.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.map.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000327.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000327.xml"
            }
        },
        {
            "hdf4map,hdf4filecontentmap": "",
            "name": "HDF4map, HDF4 File Content Map",
            "id": "fdd000327",
            "filenamepattern": [
                {
                    "value": "*.map.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.map.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000327.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000327.xml"
            }
        },
        {
            "hdf4map,hdf4filecontentmap": "",
            "name": "HDF4map, HDF4 File Content Map",
            "id": "fdd000327",
            "filenamepattern": [
                {
                    "value": "*.map.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.map.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000327.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000327.xml"
            }
        },
        {
            "bigtiff": "",
            "name": "BigTIFF",
            "id": "fdd000328",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.btf",
                    "url": "https://www.digipres.org/formats/extensions/#*.btf"
                },
                {
                    "value": "*.tf8",
                    "url": "https://www.digipres.org/formats/extensions/#*.tf8"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000328.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000328.xml"
            }
        },
        {
            "bigtiff": "",
            "name": "BigTIFF",
            "id": "fdd000328",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.btf",
                    "url": "https://www.digipres.org/formats/extensions/#*.btf"
                },
                {
                    "value": "*.tf8",
                    "url": "https://www.digipres.org/formats/extensions/#*.tf8"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000328.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000328.xml"
            }
        },
        {
            "bigtiff": "",
            "name": "BigTIFF",
            "id": "fdd000328",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.btf",
                    "url": "https://www.digipres.org/formats/extensions/#*.btf"
                },
                {
                    "value": "*.tf8",
                    "url": "https://www.digipres.org/formats/extensions/#*.tf8"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000328.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000328.xml"
            }
        },
        {
            "bigtiff": "",
            "name": "BigTIFF",
            "id": "fdd000328",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.btf",
                    "url": "https://www.digipres.org/formats/extensions/#*.btf"
                },
                {
                    "value": "*.tf8",
                    "url": "https://www.digipres.org/formats/extensions/#*.tf8"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000328.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000328.xml"
            }
        },
        {
            "bigtiff": "",
            "name": "BigTIFF",
            "id": "fdd000328",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.btf",
                    "url": "https://www.digipres.org/formats/extensions/#*.btf"
                },
                {
                    "value": "*.tf8",
                    "url": "https://www.digipres.org/formats/extensions/#*.tf8"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000328.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000328.xml"
            }
        },
        {
            "bigtiff": "",
            "name": "BigTIFF",
            "id": "fdd000328",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.btf",
                    "url": "https://www.digipres.org/formats/extensions/#*.btf"
                },
                {
                    "value": "*.tf8",
                    "url": "https://www.digipres.org/formats/extensions/#*.tf8"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000328.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000328.xml"
            }
        },
        {
            "bigtiff": "",
            "name": "BigTIFF",
            "id": "fdd000328",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.btf",
                    "url": "https://www.digipres.org/formats/extensions/#*.btf"
                },
                {
                    "value": "*.tf8",
                    "url": "https://www.digipres.org/formats/extensions/#*.tf8"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000328.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000328.xml"
            }
        },
        {
            "bigtiff": "",
            "name": "BigTIFF",
            "id": "fdd000328",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.gtiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.gtiff"
                },
                {
                    "value": "*.tiff",
                    "url": "https://www.digipres.org/formats/extensions/#*.tiff"
                },
                {
                    "value": "*.btf",
                    "url": "https://www.digipres.org/formats/extensions/#*.btf"
                },
                {
                    "value": "*.tf8",
                    "url": "https://www.digipres.org/formats/extensions/#*.tf8"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000328.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000328.xml"
            }
        },
        {
            "geodb,esrigeodatabasearcsde": "",
            "name": "GeoDB, ESRI Geodatabase ArcSDE",
            "id": "fdd000329",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000329.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000329.xml"
            }
        },
        {
            "geodb,esrigeodatabasearcsde": "",
            "name": "GeoDB, ESRI Geodatabase ArcSDE",
            "id": "fdd000329",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000329.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000329.xml"
            }
        },
        {
            "geodb,esrigeodatabasearcsde": "",
            "name": "GeoDB, ESRI Geodatabase ArcSDE",
            "id": "fdd000329",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000329.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000329.xml"
            }
        },
        {
            "geodb,esrigeodatabasearcsde": "",
            "name": "GeoDB, ESRI Geodatabase ArcSDE",
            "id": "fdd000329",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000329.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000329.xml"
            }
        },
        {
            "geodb,esrigeodatabasearcsde": "",
            "name": "GeoDB, ESRI Geodatabase ArcSDE",
            "id": "fdd000329",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000329.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000329.xml"
            }
        },
        {
            "geodb,esrigeodatabasearcsde": "",
            "name": "GeoDB, ESRI Geodatabase ArcSDE",
            "id": "fdd000329",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000329.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000329.xml"
            }
        },
        {
            "geodb,esrigeodatabasearcsde": "",
            "name": "GeoDB, ESRI Geodatabase ArcSDE",
            "id": "fdd000329",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000329.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000329.xml"
            }
        },
        {
            "geodb,esrigeodatabasearcsde": "",
            "name": "GeoDB, ESRI Geodatabase ArcSDE",
            "id": "fdd000329",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000329.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000329.xml"
            }
        },
        {
            "netcdf3(networkcommondataform,version3)": "",
            "name": "NetCDF-3 (Network Common Data Form, version 3)",
            "id": "fdd000330",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-netcdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-netcdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000330.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000330.xml"
            }
        },
        {
            "netcdf3(networkcommondataform,version3)": "",
            "name": "NetCDF-3 (Network Common Data Form, version 3)",
            "id": "fdd000330",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-netcdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-netcdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000330.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000330.xml"
            }
        },
        {
            "netcdf3(networkcommondataform,version3)": "",
            "name": "NetCDF-3 (Network Common Data Form, version 3)",
            "id": "fdd000330",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-netcdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-netcdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000330.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000330.xml"
            }
        },
        {
            "netcdf3(networkcommondataform,version3)": "",
            "name": "NetCDF-3 (Network Common Data Form, version 3)",
            "id": "fdd000330",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-netcdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-netcdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000330.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000330.xml"
            }
        },
        {
            "netcdf3(networkcommondataform,version3)": "",
            "name": "NetCDF-3 (Network Common Data Form, version 3)",
            "id": "fdd000330",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-netcdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-netcdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000330.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000330.xml"
            }
        },
        {
            "netcdf3(networkcommondataform,version3)": "",
            "name": "NetCDF-3 (Network Common Data Form, version 3)",
            "id": "fdd000330",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-netcdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-netcdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000330.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000330.xml"
            }
        },
        {
            "netcdf3(networkcommondataform,version3)": "",
            "name": "NetCDF-3 (Network Common Data Form, version 3)",
            "id": "fdd000330",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-netcdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-netcdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000330.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000330.xml"
            }
        },
        {
            "netcdf3(networkcommondataform,version3)": "",
            "name": "NetCDF-3 (Network Common Data Form, version 3)",
            "id": "fdd000330",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-netcdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-netcdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000330.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000330.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4)": "",
            "name": "NetCDF-4 (Network Common Data Form, version 4)",
            "id": "fdd000332",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000332.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000332.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4)": "",
            "name": "NetCDF-4 (Network Common Data Form, version 4)",
            "id": "fdd000332",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000332.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000332.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4)": "",
            "name": "NetCDF-4 (Network Common Data Form, version 4)",
            "id": "fdd000332",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000332.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000332.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4)": "",
            "name": "NetCDF-4 (Network Common Data Form, version 4)",
            "id": "fdd000332",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000332.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000332.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4)": "",
            "name": "NetCDF-4 (Network Common Data Form, version 4)",
            "id": "fdd000332",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000332.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000332.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4)": "",
            "name": "NetCDF-4 (Network Common Data Form, version 4)",
            "id": "fdd000332",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000332.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000332.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4)": "",
            "name": "NetCDF-4 (Network Common Data Form, version 4)",
            "id": "fdd000332",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000332.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000332.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4)": "",
            "name": "NetCDF-4 (Network Common Data Form, version 4)",
            "id": "fdd000332",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000332.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000332.xml"
            }
        },
        {
            "jpegdctcompressionencoding,progressive": "",
            "name": "JPEG DCT Compression Encoding, Progressive",
            "id": "fdd000333",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000333.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000333.xml"
            }
        },
        {
            "jpegdctcompressionencoding,progressive": "",
            "name": "JPEG DCT Compression Encoding, Progressive",
            "id": "fdd000333",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000333.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000333.xml"
            }
        },
        {
            "jpegdctcompressionencoding,progressive": "",
            "name": "JPEG DCT Compression Encoding, Progressive",
            "id": "fdd000333",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000333.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000333.xml"
            }
        },
        {
            "jpegdctcompressionencoding,progressive": "",
            "name": "JPEG DCT Compression Encoding, Progressive",
            "id": "fdd000333",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000333.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000333.xml"
            }
        },
        {
            "jpegdctcompressionencoding,progressive": "",
            "name": "JPEG DCT Compression Encoding, Progressive",
            "id": "fdd000333",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000333.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000333.xml"
            }
        },
        {
            "jpegdctcompressionencoding,progressive": "",
            "name": "JPEG DCT Compression Encoding, Progressive",
            "id": "fdd000333",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000333.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000333.xml"
            }
        },
        {
            "jpegdctcompressionencoding,progressive": "",
            "name": "JPEG DCT Compression Encoding, Progressive",
            "id": "fdd000333",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000333.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000333.xml"
            }
        },
        {
            "jpegdctcompressionencoding,progressive": "",
            "name": "JPEG DCT Compression Encoding, Progressive",
            "id": "fdd000333",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000333.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000333.xml"
            }
        },
        {
            "jpegoriginallosslesscompression(iso/iec10918)": "",
            "name": "JPEG Original Lossless Compression (ISO/IEC 10918)",
            "id": "fdd000334",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000334.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000334.xml"
            }
        },
        {
            "jpegoriginallosslesscompression(iso/iec10918)": "",
            "name": "JPEG Original Lossless Compression (ISO/IEC 10918)",
            "id": "fdd000334",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000334.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000334.xml"
            }
        },
        {
            "jpegoriginallosslesscompression(iso/iec10918)": "",
            "name": "JPEG Original Lossless Compression (ISO/IEC 10918)",
            "id": "fdd000334",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000334.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000334.xml"
            }
        },
        {
            "jpegoriginallosslesscompression(iso/iec10918)": "",
            "name": "JPEG Original Lossless Compression (ISO/IEC 10918)",
            "id": "fdd000334",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000334.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000334.xml"
            }
        },
        {
            "jpegoriginallosslesscompression(iso/iec10918)": "",
            "name": "JPEG Original Lossless Compression (ISO/IEC 10918)",
            "id": "fdd000334",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000334.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000334.xml"
            }
        },
        {
            "jpegoriginallosslesscompression(iso/iec10918)": "",
            "name": "JPEG Original Lossless Compression (ISO/IEC 10918)",
            "id": "fdd000334",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000334.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000334.xml"
            }
        },
        {
            "jpegoriginallosslesscompression(iso/iec10918)": "",
            "name": "JPEG Original Lossless Compression (ISO/IEC 10918)",
            "id": "fdd000334",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000334.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000334.xml"
            }
        },
        {
            "jpegoriginallosslesscompression(iso/iec10918)": "",
            "name": "JPEG Original Lossless Compression (ISO/IEC 10918)",
            "id": "fdd000334",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000334.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000334.xml"
            }
        },
        {
            "mpeg2encodingfamily": "",
            "name": "MPEG-2 Encoding Family",
            "id": "fdd000335",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000335.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000335.xml"
            }
        },
        {
            "mpeg2encodingfamily": "",
            "name": "MPEG-2 Encoding Family",
            "id": "fdd000335",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000335.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000335.xml"
            }
        },
        {
            "mpeg2encodingfamily": "",
            "name": "MPEG-2 Encoding Family",
            "id": "fdd000335",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000335.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000335.xml"
            }
        },
        {
            "mpeg2encodingfamily": "",
            "name": "MPEG-2 Encoding Family",
            "id": "fdd000335",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000335.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000335.xml"
            }
        },
        {
            "mpeg2encodingfamily": "",
            "name": "MPEG-2 Encoding Family",
            "id": "fdd000335",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000335.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000335.xml"
            }
        },
        {
            "mpeg2encodingfamily": "",
            "name": "MPEG-2 Encoding Family",
            "id": "fdd000335",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000335.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000335.xml"
            }
        },
        {
            "mpeg2encodingfamily": "",
            "name": "MPEG-2 Encoding Family",
            "id": "fdd000335",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000335.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000335.xml"
            }
        },
        {
            "mpeg2encodingfamily": "",
            "name": "MPEG-2 Encoding Family",
            "id": "fdd000335",
            "filenamepattern": [
                {
                    "value": "*.mpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpeg"
                },
                {
                    "value": "*.mpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.mpg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pn-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pn-mpg"
                },
                {
                    "value": "video/mpv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpv"
                },
                {
                    "value": "video/x-mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg"
                },
                {
                    "value": "video/mp2t",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2t"
                },
                {
                    "value": "video/mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpg"
                },
                {
                    "value": " video/mp2p ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp2p"
                },
                {
                    "value": "video/x-mpg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpg"
                },
                {
                    "value": "video/x-mpeg2a",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-mpeg2a"
                },
                {
                    "value": "video/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg"
                },
                {
                    "value": " video/mp1s",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mp1s"
                },
                {
                    "value": "video/mpeg2",
                    "url": "https://www.digipres.org/formats/mime-types/#video/mpeg2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000335.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000335.xml"
            }
        },
        {
            "mpeg2filewithmainprofilevideoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with Main Profile Video and Layer II Audio Encoding",
            "id": "fdd000336",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000336.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000336.xml"
            }
        },
        {
            "mpeg2filewithmainprofilevideoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with Main Profile Video and Layer II Audio Encoding",
            "id": "fdd000336",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000336.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000336.xml"
            }
        },
        {
            "mpeg2filewithmainprofilevideoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with Main Profile Video and Layer II Audio Encoding",
            "id": "fdd000336",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000336.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000336.xml"
            }
        },
        {
            "mpeg2filewithmainprofilevideoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with Main Profile Video and Layer II Audio Encoding",
            "id": "fdd000336",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000336.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000336.xml"
            }
        },
        {
            "mpeg2filewithmainprofilevideoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with Main Profile Video and Layer II Audio Encoding",
            "id": "fdd000336",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000336.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000336.xml"
            }
        },
        {
            "mpeg2filewithmainprofilevideoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with Main Profile Video and Layer II Audio Encoding",
            "id": "fdd000336",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000336.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000336.xml"
            }
        },
        {
            "mpeg2filewithmainprofilevideoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with Main Profile Video and Layer II Audio Encoding",
            "id": "fdd000336",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000336.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000336.xml"
            }
        },
        {
            "mpeg2filewithmainprofilevideoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with Main Profile Video and Layer II Audio Encoding",
            "id": "fdd000336",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000336.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000336.xml"
            }
        },
        {
            "mpeg2filewith422videoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with 422 Video and Layer II Audio Encoding",
            "id": "fdd000337",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000337.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000337.xml"
            }
        },
        {
            "mpeg2filewith422videoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with 422 Video and Layer II Audio Encoding",
            "id": "fdd000337",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000337.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000337.xml"
            }
        },
        {
            "mpeg2filewith422videoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with 422 Video and Layer II Audio Encoding",
            "id": "fdd000337",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000337.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000337.xml"
            }
        },
        {
            "mpeg2filewith422videoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with 422 Video and Layer II Audio Encoding",
            "id": "fdd000337",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000337.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000337.xml"
            }
        },
        {
            "mpeg2filewith422videoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with 422 Video and Layer II Audio Encoding",
            "id": "fdd000337",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000337.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000337.xml"
            }
        },
        {
            "mpeg2filewith422videoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with 422 Video and Layer II Audio Encoding",
            "id": "fdd000337",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000337.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000337.xml"
            }
        },
        {
            "mpeg2filewith422videoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with 422 Video and Layer II Audio Encoding",
            "id": "fdd000337",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000337.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000337.xml"
            }
        },
        {
            "mpeg2filewith422videoandlayeriiaudioencoding": "",
            "name": "MPEG-2 File with 422 Video and Layer II Audio Encoding",
            "id": "fdd000337",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000337.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000337.xml"
            }
        },
        {
            "mpeg1andmpeg2layeriiaudioencoding": "",
            "name": "MPEG-1 and MPEG-2 Layer II Audio Encoding",
            "id": "fdd000338",
            "filenamepattern": [
                {
                    "value": "*.mp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/MPA",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpa"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000338.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000338.xml"
            }
        },
        {
            "mpeg1andmpeg2layeriiaudioencoding": "",
            "name": "MPEG-1 and MPEG-2 Layer II Audio Encoding",
            "id": "fdd000338",
            "filenamepattern": [
                {
                    "value": "*.mp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/MPA",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpa"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000338.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000338.xml"
            }
        },
        {
            "mpeg1andmpeg2layeriiaudioencoding": "",
            "name": "MPEG-1 and MPEG-2 Layer II Audio Encoding",
            "id": "fdd000338",
            "filenamepattern": [
                {
                    "value": "*.mp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/MPA",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpa"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000338.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000338.xml"
            }
        },
        {
            "mpeg1andmpeg2layeriiaudioencoding": "",
            "name": "MPEG-1 and MPEG-2 Layer II Audio Encoding",
            "id": "fdd000338",
            "filenamepattern": [
                {
                    "value": "*.mp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/MPA",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpa"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000338.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000338.xml"
            }
        },
        {
            "mpeg1andmpeg2layeriiaudioencoding": "",
            "name": "MPEG-1 and MPEG-2 Layer II Audio Encoding",
            "id": "fdd000338",
            "filenamepattern": [
                {
                    "value": "*.mp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/MPA",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpa"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000338.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000338.xml"
            }
        },
        {
            "mpeg1andmpeg2layeriiaudioencoding": "",
            "name": "MPEG-1 and MPEG-2 Layer II Audio Encoding",
            "id": "fdd000338",
            "filenamepattern": [
                {
                    "value": "*.mp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/MPA",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpa"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000338.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000338.xml"
            }
        },
        {
            "mpeg1andmpeg2layeriiaudioencoding": "",
            "name": "MPEG-1 and MPEG-2 Layer II Audio Encoding",
            "id": "fdd000338",
            "filenamepattern": [
                {
                    "value": "*.mp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/MPA",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpa"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000338.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000338.xml"
            }
        },
        {
            "mpeg1andmpeg2layeriiaudioencoding": "",
            "name": "MPEG-1 and MPEG-2 Layer II Audio Encoding",
            "id": "fdd000338",
            "filenamepattern": [
                {
                    "value": "*.mp2",
                    "url": "https://www.digipres.org/formats/extensions/#*.mp2"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/MPA",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpa"
                },
                {
                    "value": "audio/mpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/mpeg"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000338.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000338.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4),classicmodel": "",
            "name": "NetCDF-4 (Network Common Data Form, Version 4), Classic Model",
            "id": "fdd000339",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000339.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000339.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4),classicmodel": "",
            "name": "NetCDF-4 (Network Common Data Form, Version 4), Classic Model",
            "id": "fdd000339",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000339.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000339.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4),classicmodel": "",
            "name": "NetCDF-4 (Network Common Data Form, Version 4), Classic Model",
            "id": "fdd000339",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000339.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000339.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4),classicmodel": "",
            "name": "NetCDF-4 (Network Common Data Form, Version 4), Classic Model",
            "id": "fdd000339",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000339.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000339.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4),classicmodel": "",
            "name": "NetCDF-4 (Network Common Data Form, Version 4), Classic Model",
            "id": "fdd000339",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000339.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000339.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4),classicmodel": "",
            "name": "NetCDF-4 (Network Common Data Form, Version 4), Classic Model",
            "id": "fdd000339",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000339.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000339.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4),classicmodel": "",
            "name": "NetCDF-4 (Network Common Data Form, Version 4), Classic Model",
            "id": "fdd000339",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000339.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000339.xml"
            }
        },
        {
            "netcdf4(networkcommondataform,version4),classicmodel": "",
            "name": "NetCDF-4 (Network Common Data Form, Version 4), Classic Model",
            "id": "fdd000339",
            "filenamepattern": [
                {
                    "value": "*.nc",
                    "url": "https://www.digipres.org/formats/extensions/#*.nc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000339.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000339.xml"
            }
        },
        {
            "kml,version2.2": "",
            "name": "KML, Version 2.2",
            "id": "fdd000340",
            "filenamepattern": [
                {
                    "value": "*.kml",
                    "url": "https://www.digipres.org/formats/extensions/#*.kml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000340.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000340.xml"
            }
        },
        {
            "kml,version2.2": "",
            "name": "KML, Version 2.2",
            "id": "fdd000340",
            "filenamepattern": [
                {
                    "value": "*.kml",
                    "url": "https://www.digipres.org/formats/extensions/#*.kml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000340.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000340.xml"
            }
        },
        {
            "kml,version2.2": "",
            "name": "KML, Version 2.2",
            "id": "fdd000340",
            "filenamepattern": [
                {
                    "value": "*.kml",
                    "url": "https://www.digipres.org/formats/extensions/#*.kml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000340.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000340.xml"
            }
        },
        {
            "kml,version2.2": "",
            "name": "KML, Version 2.2",
            "id": "fdd000340",
            "filenamepattern": [
                {
                    "value": "*.kml",
                    "url": "https://www.digipres.org/formats/extensions/#*.kml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000340.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000340.xml"
            }
        },
        {
            "kml,version2.2": "",
            "name": "KML, Version 2.2",
            "id": "fdd000340",
            "filenamepattern": [
                {
                    "value": "*.kml",
                    "url": "https://www.digipres.org/formats/extensions/#*.kml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000340.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000340.xml"
            }
        },
        {
            "kml,version2.2": "",
            "name": "KML, Version 2.2",
            "id": "fdd000340",
            "filenamepattern": [
                {
                    "value": "*.kml",
                    "url": "https://www.digipres.org/formats/extensions/#*.kml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000340.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000340.xml"
            }
        },
        {
            "kml,version2.2": "",
            "name": "KML, Version 2.2",
            "id": "fdd000340",
            "filenamepattern": [
                {
                    "value": "*.kml",
                    "url": "https://www.digipres.org/formats/extensions/#*.kml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000340.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000340.xml"
            }
        },
        {
            "kml,version2.2": "",
            "name": "KML, Version 2.2",
            "id": "fdd000340",
            "filenamepattern": [
                {
                    "value": "*.kml",
                    "url": "https://www.digipres.org/formats/extensions/#*.kml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000340.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000340.xml"
            }
        },
        {
            "ffvideocodec1,version0,1and3": "",
            "name": "FF Video Codec 1, Version 0, 1 and 3",
            "id": "fdd000341",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/FFV1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/ffv1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000341.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000341.xml"
            }
        },
        {
            "ffvideocodec1,version0,1and3": "",
            "name": "FF Video Codec 1, Version 0, 1 and 3",
            "id": "fdd000341",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/FFV1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/ffv1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000341.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000341.xml"
            }
        },
        {
            "ffvideocodec1,version0,1and3": "",
            "name": "FF Video Codec 1, Version 0, 1 and 3",
            "id": "fdd000341",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/FFV1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/ffv1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000341.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000341.xml"
            }
        },
        {
            "ffvideocodec1,version0,1and3": "",
            "name": "FF Video Codec 1, Version 0, 1 and 3",
            "id": "fdd000341",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/FFV1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/ffv1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000341.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000341.xml"
            }
        },
        {
            "ffvideocodec1,version0,1and3": "",
            "name": "FF Video Codec 1, Version 0, 1 and 3",
            "id": "fdd000341",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/FFV1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/ffv1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000341.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000341.xml"
            }
        },
        {
            "ffvideocodec1,version0,1and3": "",
            "name": "FF Video Codec 1, Version 0, 1 and 3",
            "id": "fdd000341",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/FFV1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/ffv1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000341.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000341.xml"
            }
        },
        {
            "ffvideocodec1,version0,1and3": "",
            "name": "FF Video Codec 1, Version 0, 1 and 3",
            "id": "fdd000341",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/FFV1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/ffv1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000341.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000341.xml"
            }
        },
        {
            "ffvideocodec1,version0,1and3": "",
            "name": "FF Video Codec 1, Version 0, 1 and 3",
            "id": "fdd000341",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/FFV1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/ffv1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000341.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000341.xml"
            }
        },
        {
            "matroskamultimediacontainer": "",
            "name": "Matroska Multimedia Container",
            "id": "fdd000342",
            "filenamepattern": [
                {
                    "value": "*.mks",
                    "url": "https://www.digipres.org/formats/extensions/#*.mks"
                },
                {
                    "value": "*.mkv",
                    "url": "https://www.digipres.org/formats/extensions/#*.mkv"
                },
                {
                    "value": "*.mka",
                    "url": "https://www.digipres.org/formats/extensions/#*.mka"
                },
                {
                    "value": "*.mk3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.mk3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-matroska"
                },
                {
                    "value": "video/matroska-3d",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska-3d"
                },
                {
                    "value": "video/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-matroska"
                },
                {
                    "value": "video/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska"
                },
                {
                    "value": "audio/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/matroska"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000342.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000342.xml"
            }
        },
        {
            "matroskamultimediacontainer": "",
            "name": "Matroska Multimedia Container",
            "id": "fdd000342",
            "filenamepattern": [
                {
                    "value": "*.mks",
                    "url": "https://www.digipres.org/formats/extensions/#*.mks"
                },
                {
                    "value": "*.mkv",
                    "url": "https://www.digipres.org/formats/extensions/#*.mkv"
                },
                {
                    "value": "*.mka",
                    "url": "https://www.digipres.org/formats/extensions/#*.mka"
                },
                {
                    "value": "*.mk3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.mk3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-matroska"
                },
                {
                    "value": "video/matroska-3d",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska-3d"
                },
                {
                    "value": "video/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-matroska"
                },
                {
                    "value": "video/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska"
                },
                {
                    "value": "audio/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/matroska"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000342.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000342.xml"
            }
        },
        {
            "matroskamultimediacontainer": "",
            "name": "Matroska Multimedia Container",
            "id": "fdd000342",
            "filenamepattern": [
                {
                    "value": "*.mks",
                    "url": "https://www.digipres.org/formats/extensions/#*.mks"
                },
                {
                    "value": "*.mkv",
                    "url": "https://www.digipres.org/formats/extensions/#*.mkv"
                },
                {
                    "value": "*.mka",
                    "url": "https://www.digipres.org/formats/extensions/#*.mka"
                },
                {
                    "value": "*.mk3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.mk3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-matroska"
                },
                {
                    "value": "video/matroska-3d",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska-3d"
                },
                {
                    "value": "video/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-matroska"
                },
                {
                    "value": "video/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska"
                },
                {
                    "value": "audio/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/matroska"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000342.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000342.xml"
            }
        },
        {
            "matroskamultimediacontainer": "",
            "name": "Matroska Multimedia Container",
            "id": "fdd000342",
            "filenamepattern": [
                {
                    "value": "*.mks",
                    "url": "https://www.digipres.org/formats/extensions/#*.mks"
                },
                {
                    "value": "*.mkv",
                    "url": "https://www.digipres.org/formats/extensions/#*.mkv"
                },
                {
                    "value": "*.mka",
                    "url": "https://www.digipres.org/formats/extensions/#*.mka"
                },
                {
                    "value": "*.mk3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.mk3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-matroska"
                },
                {
                    "value": "video/matroska-3d",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska-3d"
                },
                {
                    "value": "video/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-matroska"
                },
                {
                    "value": "video/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska"
                },
                {
                    "value": "audio/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/matroska"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000342.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000342.xml"
            }
        },
        {
            "matroskamultimediacontainer": "",
            "name": "Matroska Multimedia Container",
            "id": "fdd000342",
            "filenamepattern": [
                {
                    "value": "*.mks",
                    "url": "https://www.digipres.org/formats/extensions/#*.mks"
                },
                {
                    "value": "*.mkv",
                    "url": "https://www.digipres.org/formats/extensions/#*.mkv"
                },
                {
                    "value": "*.mka",
                    "url": "https://www.digipres.org/formats/extensions/#*.mka"
                },
                {
                    "value": "*.mk3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.mk3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-matroska"
                },
                {
                    "value": "video/matroska-3d",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska-3d"
                },
                {
                    "value": "video/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-matroska"
                },
                {
                    "value": "video/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska"
                },
                {
                    "value": "audio/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/matroska"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000342.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000342.xml"
            }
        },
        {
            "matroskamultimediacontainer": "",
            "name": "Matroska Multimedia Container",
            "id": "fdd000342",
            "filenamepattern": [
                {
                    "value": "*.mks",
                    "url": "https://www.digipres.org/formats/extensions/#*.mks"
                },
                {
                    "value": "*.mkv",
                    "url": "https://www.digipres.org/formats/extensions/#*.mkv"
                },
                {
                    "value": "*.mka",
                    "url": "https://www.digipres.org/formats/extensions/#*.mka"
                },
                {
                    "value": "*.mk3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.mk3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-matroska"
                },
                {
                    "value": "video/matroska-3d",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska-3d"
                },
                {
                    "value": "video/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-matroska"
                },
                {
                    "value": "video/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska"
                },
                {
                    "value": "audio/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/matroska"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000342.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000342.xml"
            }
        },
        {
            "matroskamultimediacontainer": "",
            "name": "Matroska Multimedia Container",
            "id": "fdd000342",
            "filenamepattern": [
                {
                    "value": "*.mks",
                    "url": "https://www.digipres.org/formats/extensions/#*.mks"
                },
                {
                    "value": "*.mkv",
                    "url": "https://www.digipres.org/formats/extensions/#*.mkv"
                },
                {
                    "value": "*.mka",
                    "url": "https://www.digipres.org/formats/extensions/#*.mka"
                },
                {
                    "value": "*.mk3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.mk3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-matroska"
                },
                {
                    "value": "video/matroska-3d",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska-3d"
                },
                {
                    "value": "video/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-matroska"
                },
                {
                    "value": "video/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska"
                },
                {
                    "value": "audio/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/matroska"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000342.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000342.xml"
            }
        },
        {
            "matroskamultimediacontainer": "",
            "name": "Matroska Multimedia Container",
            "id": "fdd000342",
            "filenamepattern": [
                {
                    "value": "*.mks",
                    "url": "https://www.digipres.org/formats/extensions/#*.mks"
                },
                {
                    "value": "*.mkv",
                    "url": "https://www.digipres.org/formats/extensions/#*.mkv"
                },
                {
                    "value": "*.mka",
                    "url": "https://www.digipres.org/formats/extensions/#*.mka"
                },
                {
                    "value": "*.mk3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.mk3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-matroska"
                },
                {
                    "value": "video/matroska-3d",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska-3d"
                },
                {
                    "value": "video/x-matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/x-matroska"
                },
                {
                    "value": "video/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#video/matroska"
                },
                {
                    "value": "audio/matroska ",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/matroska"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000342.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000342.xml"
            }
        },
        {
            "matroskafileformatwithffv1videoencoding": "",
            "name": "Matroska File Format with FFV1 video encoding",
            "id": "fdd000343",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000343.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000343.xml"
            }
        },
        {
            "matroskafileformatwithffv1videoencoding": "",
            "name": "Matroska File Format with FFV1 video encoding",
            "id": "fdd000343",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000343.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000343.xml"
            }
        },
        {
            "matroskafileformatwithffv1videoencoding": "",
            "name": "Matroska File Format with FFV1 video encoding",
            "id": "fdd000343",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000343.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000343.xml"
            }
        },
        {
            "matroskafileformatwithffv1videoencoding": "",
            "name": "Matroska File Format with FFV1 video encoding",
            "id": "fdd000343",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000343.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000343.xml"
            }
        },
        {
            "matroskafileformatwithffv1videoencoding": "",
            "name": "Matroska File Format with FFV1 video encoding",
            "id": "fdd000343",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000343.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000343.xml"
            }
        },
        {
            "matroskafileformatwithffv1videoencoding": "",
            "name": "Matroska File Format with FFV1 video encoding",
            "id": "fdd000343",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000343.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000343.xml"
            }
        },
        {
            "matroskafileformatwithffv1videoencoding": "",
            "name": "Matroska File Format with FFV1 video encoding",
            "id": "fdd000343",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000343.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000343.xml"
            }
        },
        {
            "matroskafileformatwithffv1videoencoding": "",
            "name": "Matroska File Format with FFV1 video encoding",
            "id": "fdd000343",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000343.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000343.xml"
            }
        },
        {
            "matroskafileformatwithmpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "Matroska File Format with MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000344",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000344.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000344.xml"
            }
        },
        {
            "matroskafileformatwithmpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "Matroska File Format with MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000344",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000344.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000344.xml"
            }
        },
        {
            "matroskafileformatwithmpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "Matroska File Format with MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000344",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000344.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000344.xml"
            }
        },
        {
            "matroskafileformatwithmpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "Matroska File Format with MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000344",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000344.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000344.xml"
            }
        },
        {
            "matroskafileformatwithmpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "Matroska File Format with MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000344",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000344.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000344.xml"
            }
        },
        {
            "matroskafileformatwithmpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "Matroska File Format with MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000344",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000344.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000344.xml"
            }
        },
        {
            "matroskafileformatwithmpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "Matroska File Format with MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000344",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000344.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000344.xml"
            }
        },
        {
            "matroskafileformatwithmpeg4,advancedvideocoding(part10)(h.264)": "",
            "name": "Matroska File Format with MPEG-4, Advanced Video Coding (Part 10) (H.264)",
            "id": "fdd000344",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000344.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000344.xml"
            }
        },
        {
            "matroskafileformatwithmpeg2videoencoding(h.262)": "",
            "name": "Matroska File Format with MPEG-2 Video Encoding (H.262)",
            "id": "fdd000345",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000345.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000345.xml"
            }
        },
        {
            "matroskafileformatwithmpeg2videoencoding(h.262)": "",
            "name": "Matroska File Format with MPEG-2 Video Encoding (H.262)",
            "id": "fdd000345",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000345.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000345.xml"
            }
        },
        {
            "matroskafileformatwithmpeg2videoencoding(h.262)": "",
            "name": "Matroska File Format with MPEG-2 Video Encoding (H.262)",
            "id": "fdd000345",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000345.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000345.xml"
            }
        },
        {
            "matroskafileformatwithmpeg2videoencoding(h.262)": "",
            "name": "Matroska File Format with MPEG-2 Video Encoding (H.262)",
            "id": "fdd000345",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000345.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000345.xml"
            }
        },
        {
            "matroskafileformatwithmpeg2videoencoding(h.262)": "",
            "name": "Matroska File Format with MPEG-2 Video Encoding (H.262)",
            "id": "fdd000345",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000345.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000345.xml"
            }
        },
        {
            "matroskafileformatwithmpeg2videoencoding(h.262)": "",
            "name": "Matroska File Format with MPEG-2 Video Encoding (H.262)",
            "id": "fdd000345",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000345.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000345.xml"
            }
        },
        {
            "matroskafileformatwithmpeg2videoencoding(h.262)": "",
            "name": "Matroska File Format with MPEG-2 Video Encoding (H.262)",
            "id": "fdd000345",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000345.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000345.xml"
            }
        },
        {
            "matroskafileformatwithmpeg2videoencoding(h.262)": "",
            "name": "Matroska File Format with MPEG-2 Video Encoding (H.262)",
            "id": "fdd000345",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000345.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000345.xml"
            }
        },
        {
            "matroskafileformatwithlpcmaudioencoding": "",
            "name": "Matroska File Format with LPCM Audio Encoding",
            "id": "fdd000346",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000346.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000346.xml"
            }
        },
        {
            "matroskafileformatwithlpcmaudioencoding": "",
            "name": "Matroska File Format with LPCM Audio Encoding",
            "id": "fdd000346",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000346.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000346.xml"
            }
        },
        {
            "matroskafileformatwithlpcmaudioencoding": "",
            "name": "Matroska File Format with LPCM Audio Encoding",
            "id": "fdd000346",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000346.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000346.xml"
            }
        },
        {
            "matroskafileformatwithlpcmaudioencoding": "",
            "name": "Matroska File Format with LPCM Audio Encoding",
            "id": "fdd000346",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000346.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000346.xml"
            }
        },
        {
            "matroskafileformatwithlpcmaudioencoding": "",
            "name": "Matroska File Format with LPCM Audio Encoding",
            "id": "fdd000346",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000346.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000346.xml"
            }
        },
        {
            "matroskafileformatwithlpcmaudioencoding": "",
            "name": "Matroska File Format with LPCM Audio Encoding",
            "id": "fdd000346",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000346.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000346.xml"
            }
        },
        {
            "matroskafileformatwithlpcmaudioencoding": "",
            "name": "Matroska File Format with LPCM Audio Encoding",
            "id": "fdd000346",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000346.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000346.xml"
            }
        },
        {
            "matroskafileformatwithlpcmaudioencoding": "",
            "name": "Matroska File Format with LPCM Audio Encoding",
            "id": "fdd000346",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000346.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000346.xml"
            }
        },
        {
            "matroskafileformatwithmp3audioencoding": "",
            "name": "Matroska File Format with MP3 Audio Encoding",
            "id": "fdd000347",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000347.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000347.xml"
            }
        },
        {
            "matroskafileformatwithmp3audioencoding": "",
            "name": "Matroska File Format with MP3 Audio Encoding",
            "id": "fdd000347",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000347.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000347.xml"
            }
        },
        {
            "matroskafileformatwithmp3audioencoding": "",
            "name": "Matroska File Format with MP3 Audio Encoding",
            "id": "fdd000347",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000347.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000347.xml"
            }
        },
        {
            "matroskafileformatwithmp3audioencoding": "",
            "name": "Matroska File Format with MP3 Audio Encoding",
            "id": "fdd000347",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000347.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000347.xml"
            }
        },
        {
            "matroskafileformatwithmp3audioencoding": "",
            "name": "Matroska File Format with MP3 Audio Encoding",
            "id": "fdd000347",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000347.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000347.xml"
            }
        },
        {
            "matroskafileformatwithmp3audioencoding": "",
            "name": "Matroska File Format with MP3 Audio Encoding",
            "id": "fdd000347",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000347.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000347.xml"
            }
        },
        {
            "matroskafileformatwithmp3audioencoding": "",
            "name": "Matroska File Format with MP3 Audio Encoding",
            "id": "fdd000347",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000347.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000347.xml"
            }
        },
        {
            "matroskafileformatwithmp3audioencoding": "",
            "name": "Matroska File Format with MP3 Audio Encoding",
            "id": "fdd000347",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000347.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000347.xml"
            }
        },
        {
            "isodiskimagefileformat": "",
            "name": "ISO Disk Image File Format",
            "id": "fdd000348",
            "filenamepattern": [
                {
                    "value": "*.iso",
                    "url": "https://www.digipres.org/formats/extensions/#*.iso"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iso9660-image",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iso9660-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000348.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000348.xml"
            }
        },
        {
            "isodiskimagefileformat": "",
            "name": "ISO Disk Image File Format",
            "id": "fdd000348",
            "filenamepattern": [
                {
                    "value": "*.iso",
                    "url": "https://www.digipres.org/formats/extensions/#*.iso"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iso9660-image",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iso9660-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000348.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000348.xml"
            }
        },
        {
            "isodiskimagefileformat": "",
            "name": "ISO Disk Image File Format",
            "id": "fdd000348",
            "filenamepattern": [
                {
                    "value": "*.iso",
                    "url": "https://www.digipres.org/formats/extensions/#*.iso"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iso9660-image",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iso9660-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000348.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000348.xml"
            }
        },
        {
            "isodiskimagefileformat": "",
            "name": "ISO Disk Image File Format",
            "id": "fdd000348",
            "filenamepattern": [
                {
                    "value": "*.iso",
                    "url": "https://www.digipres.org/formats/extensions/#*.iso"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iso9660-image",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iso9660-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000348.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000348.xml"
            }
        },
        {
            "isodiskimagefileformat": "",
            "name": "ISO Disk Image File Format",
            "id": "fdd000348",
            "filenamepattern": [
                {
                    "value": "*.iso",
                    "url": "https://www.digipres.org/formats/extensions/#*.iso"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iso9660-image",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iso9660-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000348.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000348.xml"
            }
        },
        {
            "isodiskimagefileformat": "",
            "name": "ISO Disk Image File Format",
            "id": "fdd000348",
            "filenamepattern": [
                {
                    "value": "*.iso",
                    "url": "https://www.digipres.org/formats/extensions/#*.iso"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iso9660-image",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iso9660-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000348.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000348.xml"
            }
        },
        {
            "isodiskimagefileformat": "",
            "name": "ISO Disk Image File Format",
            "id": "fdd000348",
            "filenamepattern": [
                {
                    "value": "*.iso",
                    "url": "https://www.digipres.org/formats/extensions/#*.iso"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iso9660-image",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iso9660-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000348.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000348.xml"
            }
        },
        {
            "isodiskimagefileformat": "",
            "name": "ISO Disk Image File Format",
            "id": "fdd000348",
            "filenamepattern": [
                {
                    "value": "*.iso",
                    "url": "https://www.digipres.org/formats/extensions/#*.iso"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-iso9660-image",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-iso9660-image"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000348.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000348.xml"
            }
        },
        {
            "aviopendmlfileformatwithffv1videoencoding": "",
            "name": "AVI OpenDML File Format with FFV1 video encoding",
            "id": "fdd000349",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000349.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000349.xml"
            }
        },
        {
            "aviopendmlfileformatwithffv1videoencoding": "",
            "name": "AVI OpenDML File Format with FFV1 video encoding",
            "id": "fdd000349",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000349.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000349.xml"
            }
        },
        {
            "aviopendmlfileformatwithffv1videoencoding": "",
            "name": "AVI OpenDML File Format with FFV1 video encoding",
            "id": "fdd000349",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000349.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000349.xml"
            }
        },
        {
            "aviopendmlfileformatwithffv1videoencoding": "",
            "name": "AVI OpenDML File Format with FFV1 video encoding",
            "id": "fdd000349",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000349.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000349.xml"
            }
        },
        {
            "aviopendmlfileformatwithffv1videoencoding": "",
            "name": "AVI OpenDML File Format with FFV1 video encoding",
            "id": "fdd000349",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000349.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000349.xml"
            }
        },
        {
            "aviopendmlfileformatwithffv1videoencoding": "",
            "name": "AVI OpenDML File Format with FFV1 video encoding",
            "id": "fdd000349",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000349.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000349.xml"
            }
        },
        {
            "aviopendmlfileformatwithffv1videoencoding": "",
            "name": "AVI OpenDML File Format with FFV1 video encoding",
            "id": "fdd000349",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000349.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000349.xml"
            }
        },
        {
            "aviopendmlfileformatwithffv1videoencoding": "",
            "name": "AVI OpenDML File Format with FFV1 video encoding",
            "id": "fdd000349",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000349.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000349.xml"
            }
        },
        {
            "pdf/ua1,pdfenhancementforaccessibility,useofiso320001": "",
            "name": "PDF/UA-1, PDF Enhancement for Accessibility, Use of ISO 32000-1",
            "id": "fdd000350",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000350.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000350.xml"
            }
        },
        {
            "pdf/ua1,pdfenhancementforaccessibility,useofiso320001": "",
            "name": "PDF/UA-1, PDF Enhancement for Accessibility, Use of ISO 32000-1",
            "id": "fdd000350",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000350.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000350.xml"
            }
        },
        {
            "pdf/ua1,pdfenhancementforaccessibility,useofiso320001": "",
            "name": "PDF/UA-1, PDF Enhancement for Accessibility, Use of ISO 32000-1",
            "id": "fdd000350",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000350.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000350.xml"
            }
        },
        {
            "pdf/ua1,pdfenhancementforaccessibility,useofiso320001": "",
            "name": "PDF/UA-1, PDF Enhancement for Accessibility, Use of ISO 32000-1",
            "id": "fdd000350",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000350.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000350.xml"
            }
        },
        {
            "pdf/ua1,pdfenhancementforaccessibility,useofiso320001": "",
            "name": "PDF/UA-1, PDF Enhancement for Accessibility, Use of ISO 32000-1",
            "id": "fdd000350",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000350.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000350.xml"
            }
        },
        {
            "pdf/ua1,pdfenhancementforaccessibility,useofiso320001": "",
            "name": "PDF/UA-1, PDF Enhancement for Accessibility, Use of ISO 32000-1",
            "id": "fdd000350",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000350.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000350.xml"
            }
        },
        {
            "pdf/ua1,pdfenhancementforaccessibility,useofiso320001": "",
            "name": "PDF/UA-1, PDF Enhancement for Accessibility, Use of ISO 32000-1",
            "id": "fdd000350",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000350.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000350.xml"
            }
        },
        {
            "pdf/ua1,pdfenhancementforaccessibility,useofiso320001": "",
            "name": "PDF/UA-1, PDF Enhancement for Accessibility, Use of ISO 32000-1",
            "id": "fdd000350",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000350.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000350.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestreamfamily": "",
            "name": "Uncompressed YCbCr Video Picture Stream Family",
            "id": "fdd000351",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000351.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000351.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestreamfamily": "",
            "name": "Uncompressed YCbCr Video Picture Stream Family",
            "id": "fdd000351",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000351.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000351.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestreamfamily": "",
            "name": "Uncompressed YCbCr Video Picture Stream Family",
            "id": "fdd000351",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000351.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000351.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestreamfamily": "",
            "name": "Uncompressed YCbCr Video Picture Stream Family",
            "id": "fdd000351",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000351.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000351.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestreamfamily": "",
            "name": "Uncompressed YCbCr Video Picture Stream Family",
            "id": "fdd000351",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000351.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000351.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestreamfamily": "",
            "name": "Uncompressed YCbCr Video Picture Stream Family",
            "id": "fdd000351",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000351.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000351.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestreamfamily": "",
            "name": "Uncompressed YCbCr Video Picture Stream Family",
            "id": "fdd000351",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000351.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000351.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestreamfamily": "",
            "name": "Uncompressed YCbCr Video Picture Stream Family",
            "id": "fdd000351",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000351.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000351.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestream(4:2:2)": "",
            "name": "Uncompressed YCbCr Video Picture Stream (4:2:2)",
            "id": "fdd000352",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000352.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000352.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestream(4:2:2)": "",
            "name": "Uncompressed YCbCr Video Picture Stream (4:2:2)",
            "id": "fdd000352",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000352.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000352.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestream(4:2:2)": "",
            "name": "Uncompressed YCbCr Video Picture Stream (4:2:2)",
            "id": "fdd000352",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000352.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000352.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestream(4:2:2)": "",
            "name": "Uncompressed YCbCr Video Picture Stream (4:2:2)",
            "id": "fdd000352",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000352.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000352.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestream(4:2:2)": "",
            "name": "Uncompressed YCbCr Video Picture Stream (4:2:2)",
            "id": "fdd000352",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000352.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000352.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestream(4:2:2)": "",
            "name": "Uncompressed YCbCr Video Picture Stream (4:2:2)",
            "id": "fdd000352",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000352.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000352.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestream(4:2:2)": "",
            "name": "Uncompressed YCbCr Video Picture Stream (4:2:2)",
            "id": "fdd000352",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000352.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000352.xml"
            }
        },
        {
            "uncompressedycbcrvideopicturestream(4:2:2)": "",
            "name": "Uncompressed YCbCr Video Picture Stream (4:2:2)",
            "id": "fdd000352",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000352.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000352.xml"
            }
        },
        {
            "v210videopictureencoding": "",
            "name": "V210 Video Picture Encoding",
            "id": "fdd000353",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000353.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000353.xml"
            }
        },
        {
            "v210videopictureencoding": "",
            "name": "V210 Video Picture Encoding",
            "id": "fdd000353",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000353.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000353.xml"
            }
        },
        {
            "v210videopictureencoding": "",
            "name": "V210 Video Picture Encoding",
            "id": "fdd000353",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000353.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000353.xml"
            }
        },
        {
            "v210videopictureencoding": "",
            "name": "V210 Video Picture Encoding",
            "id": "fdd000353",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000353.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000353.xml"
            }
        },
        {
            "v210videopictureencoding": "",
            "name": "V210 Video Picture Encoding",
            "id": "fdd000353",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000353.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000353.xml"
            }
        },
        {
            "v210videopictureencoding": "",
            "name": "V210 Video Picture Encoding",
            "id": "fdd000353",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000353.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000353.xml"
            }
        },
        {
            "v210videopictureencoding": "",
            "name": "V210 Video Picture Encoding",
            "id": "fdd000353",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000353.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000353.xml"
            }
        },
        {
            "v210videopictureencoding": "",
            "name": "V210 Video Picture Encoding",
            "id": "fdd000353",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000353.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000353.xml"
            }
        },
        {
            "zipfileformat(pkware)": "",
            "name": "ZIP File Format (PKWARE)",
            "id": "fdd000354",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000354.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000354.xml"
            }
        },
        {
            "zipfileformat(pkware)": "",
            "name": "ZIP File Format (PKWARE)",
            "id": "fdd000354",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000354.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000354.xml"
            }
        },
        {
            "zipfileformat(pkware)": "",
            "name": "ZIP File Format (PKWARE)",
            "id": "fdd000354",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000354.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000354.xml"
            }
        },
        {
            "zipfileformat(pkware)": "",
            "name": "ZIP File Format (PKWARE)",
            "id": "fdd000354",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000354.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000354.xml"
            }
        },
        {
            "zipfileformat(pkware)": "",
            "name": "ZIP File Format (PKWARE)",
            "id": "fdd000354",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000354.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000354.xml"
            }
        },
        {
            "zipfileformat(pkware)": "",
            "name": "ZIP File Format (PKWARE)",
            "id": "fdd000354",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000354.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000354.xml"
            }
        },
        {
            "zipfileformat(pkware)": "",
            "name": "ZIP File Format (PKWARE)",
            "id": "fdd000354",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000354.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000354.xml"
            }
        },
        {
            "zipfileformat(pkware)": "",
            "name": "ZIP File Format (PKWARE)",
            "id": "fdd000354",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000354.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000354.xml"
            }
        },
        {
            "zipfileformat,version6.2.0(pkware)": "",
            "name": "ZIP File Format, Version 6.2.0 (PKWARE)",
            "id": "fdd000355",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000355.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000355.xml"
            }
        },
        {
            "zipfileformat,version6.2.0(pkware)": "",
            "name": "ZIP File Format, Version 6.2.0 (PKWARE)",
            "id": "fdd000355",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000355.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000355.xml"
            }
        },
        {
            "zipfileformat,version6.2.0(pkware)": "",
            "name": "ZIP File Format, Version 6.2.0 (PKWARE)",
            "id": "fdd000355",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000355.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000355.xml"
            }
        },
        {
            "zipfileformat,version6.2.0(pkware)": "",
            "name": "ZIP File Format, Version 6.2.0 (PKWARE)",
            "id": "fdd000355",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000355.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000355.xml"
            }
        },
        {
            "zipfileformat,version6.2.0(pkware)": "",
            "name": "ZIP File Format, Version 6.2.0 (PKWARE)",
            "id": "fdd000355",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000355.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000355.xml"
            }
        },
        {
            "zipfileformat,version6.2.0(pkware)": "",
            "name": "ZIP File Format, Version 6.2.0 (PKWARE)",
            "id": "fdd000355",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000355.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000355.xml"
            }
        },
        {
            "zipfileformat,version6.2.0(pkware)": "",
            "name": "ZIP File Format, Version 6.2.0 (PKWARE)",
            "id": "fdd000355",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000355.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000355.xml"
            }
        },
        {
            "zipfileformat,version6.2.0(pkware)": "",
            "name": "ZIP File Format, Version 6.2.0 (PKWARE)",
            "id": "fdd000355",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000355.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000355.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version1": "",
            "name": "Broadcast WAVE Audio File Format, Version 1",
            "id": "fdd000356",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000356.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000356.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version1": "",
            "name": "Broadcast WAVE Audio File Format, Version 1",
            "id": "fdd000356",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000356.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000356.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version1": "",
            "name": "Broadcast WAVE Audio File Format, Version 1",
            "id": "fdd000356",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000356.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000356.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version1": "",
            "name": "Broadcast WAVE Audio File Format, Version 1",
            "id": "fdd000356",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000356.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000356.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version1": "",
            "name": "Broadcast WAVE Audio File Format, Version 1",
            "id": "fdd000356",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000356.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000356.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version1": "",
            "name": "Broadcast WAVE Audio File Format, Version 1",
            "id": "fdd000356",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000356.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000356.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version1": "",
            "name": "Broadcast WAVE Audio File Format, Version 1",
            "id": "fdd000356",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000356.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000356.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version1": "",
            "name": "Broadcast WAVE Audio File Format, Version 1",
            "id": "fdd000356",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000356.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000356.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version2": "",
            "name": "Broadcast WAVE Audio File Format, Version 2",
            "id": "fdd000357",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000357.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000357.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version2": "",
            "name": "Broadcast WAVE Audio File Format, Version 2",
            "id": "fdd000357",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000357.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000357.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version2": "",
            "name": "Broadcast WAVE Audio File Format, Version 2",
            "id": "fdd000357",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000357.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000357.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version2": "",
            "name": "Broadcast WAVE Audio File Format, Version 2",
            "id": "fdd000357",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000357.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000357.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version2": "",
            "name": "Broadcast WAVE Audio File Format, Version 2",
            "id": "fdd000357",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000357.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000357.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version2": "",
            "name": "Broadcast WAVE Audio File Format, Version 2",
            "id": "fdd000357",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000357.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000357.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version2": "",
            "name": "Broadcast WAVE Audio File Format, Version 2",
            "id": "fdd000357",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000357.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000357.xml"
            }
        },
        {
            "broadcastwaveaudiofileformat,version2": "",
            "name": "Broadcast WAVE Audio File Format, Version 2",
            "id": "fdd000357",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000357.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000357.xml"
            }
        },
        {
            "musicxmlfileformatfamily": "",
            "name": "MusicXML File Format Family",
            "id": "fdd000358",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000358.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000358.xml"
            }
        },
        {
            "musicxmlfileformatfamily": "",
            "name": "MusicXML File Format Family",
            "id": "fdd000358",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000358.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000358.xml"
            }
        },
        {
            "musicxmlfileformatfamily": "",
            "name": "MusicXML File Format Family",
            "id": "fdd000358",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000358.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000358.xml"
            }
        },
        {
            "musicxmlfileformatfamily": "",
            "name": "MusicXML File Format Family",
            "id": "fdd000358",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000358.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000358.xml"
            }
        },
        {
            "musicxmlfileformatfamily": "",
            "name": "MusicXML File Format Family",
            "id": "fdd000358",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000358.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000358.xml"
            }
        },
        {
            "musicxmlfileformatfamily": "",
            "name": "MusicXML File Format Family",
            "id": "fdd000358",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000358.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000358.xml"
            }
        },
        {
            "musicxmlfileformatfamily": "",
            "name": "MusicXML File Format Family",
            "id": "fdd000358",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000358.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000358.xml"
            }
        },
        {
            "musicxmlfileformatfamily": "",
            "name": "MusicXML File Format Family",
            "id": "fdd000358",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000358.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000358.xml"
            }
        },
        {
            "broadcastwavefileformat,version2,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 2, with LPCM Audio",
            "id": "fdd000359",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000359.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000359.xml"
            }
        },
        {
            "broadcastwavefileformat,version2,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 2, with LPCM Audio",
            "id": "fdd000359",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000359.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000359.xml"
            }
        },
        {
            "broadcastwavefileformat,version2,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 2, with LPCM Audio",
            "id": "fdd000359",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000359.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000359.xml"
            }
        },
        {
            "broadcastwavefileformat,version2,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 2, with LPCM Audio",
            "id": "fdd000359",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000359.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000359.xml"
            }
        },
        {
            "broadcastwavefileformat,version2,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 2, with LPCM Audio",
            "id": "fdd000359",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000359.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000359.xml"
            }
        },
        {
            "broadcastwavefileformat,version2,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 2, with LPCM Audio",
            "id": "fdd000359",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000359.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000359.xml"
            }
        },
        {
            "broadcastwavefileformat,version2,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 2, with LPCM Audio",
            "id": "fdd000359",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000359.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000359.xml"
            }
        },
        {
            "broadcastwavefileformat,version2,withlpcmaudio": "",
            "name": "Broadcast WAVE File Format, Version 2, with LPCM Audio",
            "id": "fdd000359",
            "filenamepattern": [
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000359.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000359.xml"
            }
        },
        {
            "pdf/a3,pdfforlongtermpreservation,useofiso320001,withembeddedfiles": "",
            "name": "PDF/A-3, PDF for Long-term Preservation, Use of ISO 32000-1, With Embedded Files",
            "id": "fdd000360",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000360.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000360.xml"
            }
        },
        {
            "pdf/a3,pdfforlongtermpreservation,useofiso320001,withembeddedfiles": "",
            "name": "PDF/A-3, PDF for Long-term Preservation, Use of ISO 32000-1, With Embedded Files",
            "id": "fdd000360",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000360.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000360.xml"
            }
        },
        {
            "pdf/a3,pdfforlongtermpreservation,useofiso320001,withembeddedfiles": "",
            "name": "PDF/A-3, PDF for Long-term Preservation, Use of ISO 32000-1, With Embedded Files",
            "id": "fdd000360",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000360.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000360.xml"
            }
        },
        {
            "pdf/a3,pdfforlongtermpreservation,useofiso320001,withembeddedfiles": "",
            "name": "PDF/A-3, PDF for Long-term Preservation, Use of ISO 32000-1, With Embedded Files",
            "id": "fdd000360",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000360.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000360.xml"
            }
        },
        {
            "pdf/a3,pdfforlongtermpreservation,useofiso320001,withembeddedfiles": "",
            "name": "PDF/A-3, PDF for Long-term Preservation, Use of ISO 32000-1, With Embedded Files",
            "id": "fdd000360",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000360.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000360.xml"
            }
        },
        {
            "pdf/a3,pdfforlongtermpreservation,useofiso320001,withembeddedfiles": "",
            "name": "PDF/A-3, PDF for Long-term Preservation, Use of ISO 32000-1, With Embedded Files",
            "id": "fdd000360",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000360.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000360.xml"
            }
        },
        {
            "pdf/a3,pdfforlongtermpreservation,useofiso320001,withembeddedfiles": "",
            "name": "PDF/A-3, PDF for Long-term Preservation, Use of ISO 32000-1, With Embedded Files",
            "id": "fdd000360",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000360.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000360.xml"
            }
        },
        {
            "pdf/a3,pdfforlongtermpreservation,useofiso320001,withembeddedfiles": "",
            "name": "PDF/A-3, PDF for Long-term Preservation, Use of ISO 32000-1, With Embedded Files",
            "id": "fdd000360",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000360.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000360.xml"
            }
        },
        {
            "documentcontainerfile:core(basedonzip6.3.3)": "",
            "name": "Document Container File: Core (based on ZIP 6.3.3)",
            "id": "fdd000361",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000361.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000361.xml"
            }
        },
        {
            "documentcontainerfile:core(basedonzip6.3.3)": "",
            "name": "Document Container File: Core (based on ZIP 6.3.3)",
            "id": "fdd000361",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000361.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000361.xml"
            }
        },
        {
            "documentcontainerfile:core(basedonzip6.3.3)": "",
            "name": "Document Container File: Core (based on ZIP 6.3.3)",
            "id": "fdd000361",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000361.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000361.xml"
            }
        },
        {
            "documentcontainerfile:core(basedonzip6.3.3)": "",
            "name": "Document Container File: Core (based on ZIP 6.3.3)",
            "id": "fdd000361",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000361.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000361.xml"
            }
        },
        {
            "documentcontainerfile:core(basedonzip6.3.3)": "",
            "name": "Document Container File: Core (based on ZIP 6.3.3)",
            "id": "fdd000361",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000361.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000361.xml"
            }
        },
        {
            "documentcontainerfile:core(basedonzip6.3.3)": "",
            "name": "Document Container File: Core (based on ZIP 6.3.3)",
            "id": "fdd000361",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000361.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000361.xml"
            }
        },
        {
            "documentcontainerfile:core(basedonzip6.3.3)": "",
            "name": "Document Container File: Core (based on ZIP 6.3.3)",
            "id": "fdd000361",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000361.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000361.xml"
            }
        },
        {
            "documentcontainerfile:core(basedonzip6.3.3)": "",
            "name": "Document Container File: Core (based on ZIP 6.3.3)",
            "id": "fdd000361",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000361.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000361.xml"
            }
        },
        {
            "zipfileformat,version6.3.3(pkware)": "",
            "name": "ZIP File Format, Version 6.3.3 (PKWARE)",
            "id": "fdd000362",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000362.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000362.xml"
            }
        },
        {
            "zipfileformat,version6.3.3(pkware)": "",
            "name": "ZIP File Format, Version 6.3.3 (PKWARE)",
            "id": "fdd000362",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000362.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000362.xml"
            }
        },
        {
            "zipfileformat,version6.3.3(pkware)": "",
            "name": "ZIP File Format, Version 6.3.3 (PKWARE)",
            "id": "fdd000362",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000362.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000362.xml"
            }
        },
        {
            "zipfileformat,version6.3.3(pkware)": "",
            "name": "ZIP File Format, Version 6.3.3 (PKWARE)",
            "id": "fdd000362",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000362.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000362.xml"
            }
        },
        {
            "zipfileformat,version6.3.3(pkware)": "",
            "name": "ZIP File Format, Version 6.3.3 (PKWARE)",
            "id": "fdd000362",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000362.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000362.xml"
            }
        },
        {
            "zipfileformat,version6.3.3(pkware)": "",
            "name": "ZIP File Format, Version 6.3.3 (PKWARE)",
            "id": "fdd000362",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000362.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000362.xml"
            }
        },
        {
            "zipfileformat,version6.3.3(pkware)": "",
            "name": "ZIP File Format, Version 6.3.3 (PKWARE)",
            "id": "fdd000362",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000362.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000362.xml"
            }
        },
        {
            "zipfileformat,version6.3.3(pkware)": "",
            "name": "ZIP File Format, Version 6.3.3 (PKWARE)",
            "id": "fdd000362",
            "filenamepattern": [
                {
                    "value": "*.ZIP",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                },
                {
                    "value": "*.zip",
                    "url": "https://www.digipres.org/formats/extensions/#*.zip"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000362.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000362.xml"
            }
        },
        {
            "openpackagingconventions(officeopenxml),iso295002:20082012": "",
            "name": "Open Packaging Conventions (Office Open XML), ISO 29500-2:2008-2012",
            "id": "fdd000363",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000363.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000363.xml"
            }
        },
        {
            "openpackagingconventions(officeopenxml),iso295002:20082012": "",
            "name": "Open Packaging Conventions (Office Open XML), ISO 29500-2:2008-2012",
            "id": "fdd000363",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000363.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000363.xml"
            }
        },
        {
            "openpackagingconventions(officeopenxml),iso295002:20082012": "",
            "name": "Open Packaging Conventions (Office Open XML), ISO 29500-2:2008-2012",
            "id": "fdd000363",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000363.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000363.xml"
            }
        },
        {
            "openpackagingconventions(officeopenxml),iso295002:20082012": "",
            "name": "Open Packaging Conventions (Office Open XML), ISO 29500-2:2008-2012",
            "id": "fdd000363",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000363.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000363.xml"
            }
        },
        {
            "openpackagingconventions(officeopenxml),iso295002:20082012": "",
            "name": "Open Packaging Conventions (Office Open XML), ISO 29500-2:2008-2012",
            "id": "fdd000363",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000363.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000363.xml"
            }
        },
        {
            "openpackagingconventions(officeopenxml),iso295002:20082012": "",
            "name": "Open Packaging Conventions (Office Open XML), ISO 29500-2:2008-2012",
            "id": "fdd000363",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000363.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000363.xml"
            }
        },
        {
            "openpackagingconventions(officeopenxml),iso295002:20082012": "",
            "name": "Open Packaging Conventions (Office Open XML), ISO 29500-2:2008-2012",
            "id": "fdd000363",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000363.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000363.xml"
            }
        },
        {
            "openpackagingconventions(officeopenxml),iso295002:20082012": "",
            "name": "Open Packaging Conventions (Office Open XML), ISO 29500-2:2008-2012",
            "id": "fdd000363",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000363.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000363.xml"
            }
        },
        {
            "yuy2videopictureencoding": "",
            "name": "YUY2 Video Picture Encoding",
            "id": "fdd000364",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000364.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000364.xml"
            }
        },
        {
            "yuy2videopictureencoding": "",
            "name": "YUY2 Video Picture Encoding",
            "id": "fdd000364",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000364.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000364.xml"
            }
        },
        {
            "yuy2videopictureencoding": "",
            "name": "YUY2 Video Picture Encoding",
            "id": "fdd000364",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000364.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000364.xml"
            }
        },
        {
            "yuy2videopictureencoding": "",
            "name": "YUY2 Video Picture Encoding",
            "id": "fdd000364",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000364.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000364.xml"
            }
        },
        {
            "yuy2videopictureencoding": "",
            "name": "YUY2 Video Picture Encoding",
            "id": "fdd000364",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000364.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000364.xml"
            }
        },
        {
            "yuy2videopictureencoding": "",
            "name": "YUY2 Video Picture Encoding",
            "id": "fdd000364",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000364.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000364.xml"
            }
        },
        {
            "yuy2videopictureencoding": "",
            "name": "YUY2 Video Picture Encoding",
            "id": "fdd000364",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000364.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000364.xml"
            }
        },
        {
            "yuy2videopictureencoding": "",
            "name": "YUY2 Video Picture Encoding",
            "id": "fdd000364",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000364.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000364.xml"
            }
        },
        {
            "uyvyvideopictureencoding": "",
            "name": "UYVY Video Picture Encoding",
            "id": "fdd000365",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000365.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000365.xml"
            }
        },
        {
            "uyvyvideopictureencoding": "",
            "name": "UYVY Video Picture Encoding",
            "id": "fdd000365",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000365.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000365.xml"
            }
        },
        {
            "uyvyvideopictureencoding": "",
            "name": "UYVY Video Picture Encoding",
            "id": "fdd000365",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000365.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000365.xml"
            }
        },
        {
            "uyvyvideopictureencoding": "",
            "name": "UYVY Video Picture Encoding",
            "id": "fdd000365",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000365.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000365.xml"
            }
        },
        {
            "uyvyvideopictureencoding": "",
            "name": "UYVY Video Picture Encoding",
            "id": "fdd000365",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000365.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000365.xml"
            }
        },
        {
            "uyvyvideopictureencoding": "",
            "name": "UYVY Video Picture Encoding",
            "id": "fdd000365",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000365.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000365.xml"
            }
        },
        {
            "uyvyvideopictureencoding": "",
            "name": "UYVY Video Picture Encoding",
            "id": "fdd000365",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000365.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000365.xml"
            }
        },
        {
            "uyvyvideopictureencoding": "",
            "name": "UYVY Video Picture Encoding",
            "id": "fdd000365",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000365.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000365.xml"
            }
        },
        {
            "aviopendmlfileformatwithyuy2videoencoding": "",
            "name": "AVI OpenDML File Format with YUY2 Video Encoding",
            "id": "fdd000366",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000366.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000366.xml"
            }
        },
        {
            "aviopendmlfileformatwithyuy2videoencoding": "",
            "name": "AVI OpenDML File Format with YUY2 Video Encoding",
            "id": "fdd000366",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000366.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000366.xml"
            }
        },
        {
            "aviopendmlfileformatwithyuy2videoencoding": "",
            "name": "AVI OpenDML File Format with YUY2 Video Encoding",
            "id": "fdd000366",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000366.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000366.xml"
            }
        },
        {
            "aviopendmlfileformatwithyuy2videoencoding": "",
            "name": "AVI OpenDML File Format with YUY2 Video Encoding",
            "id": "fdd000366",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000366.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000366.xml"
            }
        },
        {
            "aviopendmlfileformatwithyuy2videoencoding": "",
            "name": "AVI OpenDML File Format with YUY2 Video Encoding",
            "id": "fdd000366",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000366.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000366.xml"
            }
        },
        {
            "aviopendmlfileformatwithyuy2videoencoding": "",
            "name": "AVI OpenDML File Format with YUY2 Video Encoding",
            "id": "fdd000366",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000366.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000366.xml"
            }
        },
        {
            "aviopendmlfileformatwithyuy2videoencoding": "",
            "name": "AVI OpenDML File Format with YUY2 Video Encoding",
            "id": "fdd000366",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000366.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000366.xml"
            }
        },
        {
            "aviopendmlfileformatwithyuy2videoencoding": "",
            "name": "AVI OpenDML File Format with YUY2 Video Encoding",
            "id": "fdd000366",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000366.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000366.xml"
            }
        },
        {
            "aviopendmlfileformatwithuyuvvideoencoding": "",
            "name": "AVI OpenDML File Format with UYUV Video Encoding",
            "id": "fdd000367",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000367.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000367.xml"
            }
        },
        {
            "aviopendmlfileformatwithuyuvvideoencoding": "",
            "name": "AVI OpenDML File Format with UYUV Video Encoding",
            "id": "fdd000367",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000367.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000367.xml"
            }
        },
        {
            "aviopendmlfileformatwithuyuvvideoencoding": "",
            "name": "AVI OpenDML File Format with UYUV Video Encoding",
            "id": "fdd000367",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000367.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000367.xml"
            }
        },
        {
            "aviopendmlfileformatwithuyuvvideoencoding": "",
            "name": "AVI OpenDML File Format with UYUV Video Encoding",
            "id": "fdd000367",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000367.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000367.xml"
            }
        },
        {
            "aviopendmlfileformatwithuyuvvideoencoding": "",
            "name": "AVI OpenDML File Format with UYUV Video Encoding",
            "id": "fdd000367",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000367.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000367.xml"
            }
        },
        {
            "aviopendmlfileformatwithuyuvvideoencoding": "",
            "name": "AVI OpenDML File Format with UYUV Video Encoding",
            "id": "fdd000367",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000367.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000367.xml"
            }
        },
        {
            "aviopendmlfileformatwithuyuvvideoencoding": "",
            "name": "AVI OpenDML File Format with UYUV Video Encoding",
            "id": "fdd000367",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000367.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000367.xml"
            }
        },
        {
            "aviopendmlfileformatwithuyuvvideoencoding": "",
            "name": "AVI OpenDML File Format with UYUV Video Encoding",
            "id": "fdd000367",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000367.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000367.xml"
            }
        },
        {
            "quicktimefileformatwithv210videoencoding": "",
            "name": "QuickTime File Format with V210 Video Encoding",
            "id": "fdd000368",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000368.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000368.xml"
            }
        },
        {
            "quicktimefileformatwithv210videoencoding": "",
            "name": "QuickTime File Format with V210 Video Encoding",
            "id": "fdd000368",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000368.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000368.xml"
            }
        },
        {
            "quicktimefileformatwithv210videoencoding": "",
            "name": "QuickTime File Format with V210 Video Encoding",
            "id": "fdd000368",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000368.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000368.xml"
            }
        },
        {
            "quicktimefileformatwithv210videoencoding": "",
            "name": "QuickTime File Format with V210 Video Encoding",
            "id": "fdd000368",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000368.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000368.xml"
            }
        },
        {
            "quicktimefileformatwithv210videoencoding": "",
            "name": "QuickTime File Format with V210 Video Encoding",
            "id": "fdd000368",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000368.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000368.xml"
            }
        },
        {
            "quicktimefileformatwithv210videoencoding": "",
            "name": "QuickTime File Format with V210 Video Encoding",
            "id": "fdd000368",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000368.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000368.xml"
            }
        },
        {
            "quicktimefileformatwithv210videoencoding": "",
            "name": "QuickTime File Format with V210 Video Encoding",
            "id": "fdd000368",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000368.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000368.xml"
            }
        },
        {
            "quicktimefileformatwithv210videoencoding": "",
            "name": "QuickTime File Format with V210 Video Encoding",
            "id": "fdd000368",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000368.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000368.xml"
            }
        },
        {
            "quicktimefileformatwithyuy2(yuv2)videoencoding": "",
            "name": "QuickTime File Format with YUY2 (yuv2) Video Encoding",
            "id": "fdd000369",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000369.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000369.xml"
            }
        },
        {
            "quicktimefileformatwithyuy2(yuv2)videoencoding": "",
            "name": "QuickTime File Format with YUY2 (yuv2) Video Encoding",
            "id": "fdd000369",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000369.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000369.xml"
            }
        },
        {
            "quicktimefileformatwithyuy2(yuv2)videoencoding": "",
            "name": "QuickTime File Format with YUY2 (yuv2) Video Encoding",
            "id": "fdd000369",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000369.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000369.xml"
            }
        },
        {
            "quicktimefileformatwithyuy2(yuv2)videoencoding": "",
            "name": "QuickTime File Format with YUY2 (yuv2) Video Encoding",
            "id": "fdd000369",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000369.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000369.xml"
            }
        },
        {
            "quicktimefileformatwithyuy2(yuv2)videoencoding": "",
            "name": "QuickTime File Format with YUY2 (yuv2) Video Encoding",
            "id": "fdd000369",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000369.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000369.xml"
            }
        },
        {
            "quicktimefileformatwithyuy2(yuv2)videoencoding": "",
            "name": "QuickTime File Format with YUY2 (yuv2) Video Encoding",
            "id": "fdd000369",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000369.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000369.xml"
            }
        },
        {
            "quicktimefileformatwithyuy2(yuv2)videoencoding": "",
            "name": "QuickTime File Format with YUY2 (yuv2) Video Encoding",
            "id": "fdd000369",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000369.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000369.xml"
            }
        },
        {
            "quicktimefileformatwithyuy2(yuv2)videoencoding": "",
            "name": "QuickTime File Format with YUY2 (yuv2) Video Encoding",
            "id": "fdd000369",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000369.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000369.xml"
            }
        },
        {
            "quicktimefileformatwithuyvy(2vuy)videoencoding": "",
            "name": "QuickTime File Format with UYVY (2vuy) Video Encoding",
            "id": "fdd000370",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000370.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000370.xml"
            }
        },
        {
            "quicktimefileformatwithuyvy(2vuy)videoencoding": "",
            "name": "QuickTime File Format with UYVY (2vuy) Video Encoding",
            "id": "fdd000370",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000370.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000370.xml"
            }
        },
        {
            "quicktimefileformatwithuyvy(2vuy)videoencoding": "",
            "name": "QuickTime File Format with UYVY (2vuy) Video Encoding",
            "id": "fdd000370",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000370.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000370.xml"
            }
        },
        {
            "quicktimefileformatwithuyvy(2vuy)videoencoding": "",
            "name": "QuickTime File Format with UYVY (2vuy) Video Encoding",
            "id": "fdd000370",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000370.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000370.xml"
            }
        },
        {
            "quicktimefileformatwithuyvy(2vuy)videoencoding": "",
            "name": "QuickTime File Format with UYVY (2vuy) Video Encoding",
            "id": "fdd000370",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000370.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000370.xml"
            }
        },
        {
            "quicktimefileformatwithuyvy(2vuy)videoencoding": "",
            "name": "QuickTime File Format with UYVY (2vuy) Video Encoding",
            "id": "fdd000370",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000370.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000370.xml"
            }
        },
        {
            "quicktimefileformatwithuyvy(2vuy)videoencoding": "",
            "name": "QuickTime File Format with UYVY (2vuy) Video Encoding",
            "id": "fdd000370",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000370.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000370.xml"
            }
        },
        {
            "quicktimefileformatwithuyvy(2vuy)videoencoding": "",
            "name": "QuickTime File Format with UYVY (2vuy) Video Encoding",
            "id": "fdd000370",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000370.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000370.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessencev210": "",
            "name": "MXF Generic Container with Uncompressed Video Essence V210",
            "id": "fdd000371",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000371.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000371.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessencev210": "",
            "name": "MXF Generic Container with Uncompressed Video Essence V210",
            "id": "fdd000371",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000371.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000371.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessencev210": "",
            "name": "MXF Generic Container with Uncompressed Video Essence V210",
            "id": "fdd000371",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000371.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000371.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessencev210": "",
            "name": "MXF Generic Container with Uncompressed Video Essence V210",
            "id": "fdd000371",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000371.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000371.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessencev210": "",
            "name": "MXF Generic Container with Uncompressed Video Essence V210",
            "id": "fdd000371",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000371.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000371.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessencev210": "",
            "name": "MXF Generic Container with Uncompressed Video Essence V210",
            "id": "fdd000371",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000371.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000371.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessencev210": "",
            "name": "MXF Generic Container with Uncompressed Video Essence V210",
            "id": "fdd000371",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000371.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000371.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessencev210": "",
            "name": "MXF Generic Container with Uncompressed Video Essence V210",
            "id": "fdd000371",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000371.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000371.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceyuy2(yuv2)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence YUY2 (yuv2)",
            "id": "fdd000372",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000372.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000372.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceyuy2(yuv2)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence YUY2 (yuv2)",
            "id": "fdd000372",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000372.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000372.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceyuy2(yuv2)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence YUY2 (yuv2)",
            "id": "fdd000372",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000372.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000372.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceyuy2(yuv2)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence YUY2 (yuv2)",
            "id": "fdd000372",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000372.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000372.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceyuy2(yuv2)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence YUY2 (yuv2)",
            "id": "fdd000372",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000372.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000372.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceyuy2(yuv2)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence YUY2 (yuv2)",
            "id": "fdd000372",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000372.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000372.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceyuy2(yuv2)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence YUY2 (yuv2)",
            "id": "fdd000372",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000372.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000372.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceyuy2(yuv2)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence YUY2 (yuv2)",
            "id": "fdd000372",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000372.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000372.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceuyvy(2vuy)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence UYVY (2vuy)",
            "id": "fdd000373",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000373.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000373.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceuyvy(2vuy)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence UYVY (2vuy)",
            "id": "fdd000373",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000373.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000373.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceuyvy(2vuy)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence UYVY (2vuy)",
            "id": "fdd000373",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000373.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000373.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceuyvy(2vuy)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence UYVY (2vuy)",
            "id": "fdd000373",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000373.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000373.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceuyvy(2vuy)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence UYVY (2vuy)",
            "id": "fdd000373",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000373.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000373.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceuyvy(2vuy)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence UYVY (2vuy)",
            "id": "fdd000373",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000373.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000373.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceuyvy(2vuy)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence UYVY (2vuy)",
            "id": "fdd000373",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000373.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000373.xml"
            }
        },
        {
            "mxfgenericcontainerwithuncompressedvideoessenceuyvy(2vuy)": "",
            "name": "MXF Generic Container with Uncompressed Video Essence UYVY (2vuy)",
            "id": "fdd000373",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000373.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000373.xml"
            }
        },
        {
            "matroskafileformatwithv210videoencoding": "",
            "name": "Matroska File Format with V210 Video Encoding",
            "id": "fdd000374",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000374.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000374.xml"
            }
        },
        {
            "matroskafileformatwithv210videoencoding": "",
            "name": "Matroska File Format with V210 Video Encoding",
            "id": "fdd000374",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000374.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000374.xml"
            }
        },
        {
            "matroskafileformatwithv210videoencoding": "",
            "name": "Matroska File Format with V210 Video Encoding",
            "id": "fdd000374",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000374.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000374.xml"
            }
        },
        {
            "matroskafileformatwithv210videoencoding": "",
            "name": "Matroska File Format with V210 Video Encoding",
            "id": "fdd000374",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000374.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000374.xml"
            }
        },
        {
            "matroskafileformatwithv210videoencoding": "",
            "name": "Matroska File Format with V210 Video Encoding",
            "id": "fdd000374",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000374.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000374.xml"
            }
        },
        {
            "matroskafileformatwithv210videoencoding": "",
            "name": "Matroska File Format with V210 Video Encoding",
            "id": "fdd000374",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000374.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000374.xml"
            }
        },
        {
            "matroskafileformatwithv210videoencoding": "",
            "name": "Matroska File Format with V210 Video Encoding",
            "id": "fdd000374",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000374.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000374.xml"
            }
        },
        {
            "matroskafileformatwithv210videoencoding": "",
            "name": "Matroska File Format with V210 Video Encoding",
            "id": "fdd000374",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000374.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000374.xml"
            }
        },
        {
            "matroskafileformatwithyuy2videoencoding": "",
            "name": "Matroska File Format with YUY2 Video Encoding",
            "id": "fdd000375",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000375.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000375.xml"
            }
        },
        {
            "matroskafileformatwithyuy2videoencoding": "",
            "name": "Matroska File Format with YUY2 Video Encoding",
            "id": "fdd000375",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000375.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000375.xml"
            }
        },
        {
            "matroskafileformatwithyuy2videoencoding": "",
            "name": "Matroska File Format with YUY2 Video Encoding",
            "id": "fdd000375",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000375.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000375.xml"
            }
        },
        {
            "matroskafileformatwithyuy2videoencoding": "",
            "name": "Matroska File Format with YUY2 Video Encoding",
            "id": "fdd000375",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000375.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000375.xml"
            }
        },
        {
            "matroskafileformatwithyuy2videoencoding": "",
            "name": "Matroska File Format with YUY2 Video Encoding",
            "id": "fdd000375",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000375.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000375.xml"
            }
        },
        {
            "matroskafileformatwithyuy2videoencoding": "",
            "name": "Matroska File Format with YUY2 Video Encoding",
            "id": "fdd000375",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000375.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000375.xml"
            }
        },
        {
            "matroskafileformatwithyuy2videoencoding": "",
            "name": "Matroska File Format with YUY2 Video Encoding",
            "id": "fdd000375",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000375.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000375.xml"
            }
        },
        {
            "matroskafileformatwithyuy2videoencoding": "",
            "name": "Matroska File Format with YUY2 Video Encoding",
            "id": "fdd000375",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000375.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000375.xml"
            }
        },
        {
            "matroskafileformatwithuyvyvideoencoding": "",
            "name": "Matroska File Format with UYVY Video Encoding",
            "id": "fdd000376",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000376.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000376.xml"
            }
        },
        {
            "matroskafileformatwithuyvyvideoencoding": "",
            "name": "Matroska File Format with UYVY Video Encoding",
            "id": "fdd000376",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000376.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000376.xml"
            }
        },
        {
            "matroskafileformatwithuyvyvideoencoding": "",
            "name": "Matroska File Format with UYVY Video Encoding",
            "id": "fdd000376",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000376.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000376.xml"
            }
        },
        {
            "matroskafileformatwithuyvyvideoencoding": "",
            "name": "Matroska File Format with UYVY Video Encoding",
            "id": "fdd000376",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000376.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000376.xml"
            }
        },
        {
            "matroskafileformatwithuyvyvideoencoding": "",
            "name": "Matroska File Format with UYVY Video Encoding",
            "id": "fdd000376",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000376.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000376.xml"
            }
        },
        {
            "matroskafileformatwithuyvyvideoencoding": "",
            "name": "Matroska File Format with UYVY Video Encoding",
            "id": "fdd000376",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000376.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000376.xml"
            }
        },
        {
            "matroskafileformatwithuyvyvideoencoding": "",
            "name": "Matroska File Format with UYVY Video Encoding",
            "id": "fdd000376",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000376.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000376.xml"
            }
        },
        {
            "matroskafileformatwithuyvyvideoencoding": "",
            "name": "Matroska File Format with UYVY Video Encoding",
            "id": "fdd000376",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000376.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000376.xml"
            }
        },
        {
            "microsoftoutlookpst972002(ansi)": "",
            "name": "Microsoft Outlook PST 97-2002 (ANSI)",
            "id": "fdd000377",
            "filenamepattern": [
                {
                    "value": "*.pst",
                    "url": "https://www.digipres.org/formats/extensions/#*.pst"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000377.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000377.xml"
            }
        },
        {
            "microsoftoutlookpst972002(ansi)": "",
            "name": "Microsoft Outlook PST 97-2002 (ANSI)",
            "id": "fdd000377",
            "filenamepattern": [
                {
                    "value": "*.pst",
                    "url": "https://www.digipres.org/formats/extensions/#*.pst"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000377.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000377.xml"
            }
        },
        {
            "microsoftoutlookpst972002(ansi)": "",
            "name": "Microsoft Outlook PST 97-2002 (ANSI)",
            "id": "fdd000377",
            "filenamepattern": [
                {
                    "value": "*.pst",
                    "url": "https://www.digipres.org/formats/extensions/#*.pst"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000377.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000377.xml"
            }
        },
        {
            "microsoftoutlookpst972002(ansi)": "",
            "name": "Microsoft Outlook PST 97-2002 (ANSI)",
            "id": "fdd000377",
            "filenamepattern": [
                {
                    "value": "*.pst",
                    "url": "https://www.digipres.org/formats/extensions/#*.pst"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000377.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000377.xml"
            }
        },
        {
            "microsoftoutlookpst972002(ansi)": "",
            "name": "Microsoft Outlook PST 97-2002 (ANSI)",
            "id": "fdd000377",
            "filenamepattern": [
                {
                    "value": "*.pst",
                    "url": "https://www.digipres.org/formats/extensions/#*.pst"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000377.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000377.xml"
            }
        },
        {
            "microsoftoutlookpst972002(ansi)": "",
            "name": "Microsoft Outlook PST 97-2002 (ANSI)",
            "id": "fdd000377",
            "filenamepattern": [
                {
                    "value": "*.pst",
                    "url": "https://www.digipres.org/formats/extensions/#*.pst"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000377.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000377.xml"
            }
        },
        {
            "microsoftoutlookpst972002(ansi)": "",
            "name": "Microsoft Outlook PST 97-2002 (ANSI)",
            "id": "fdd000377",
            "filenamepattern": [
                {
                    "value": "*.pst",
                    "url": "https://www.digipres.org/formats/extensions/#*.pst"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000377.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000377.xml"
            }
        },
        {
            "microsoftoutlookpst972002(ansi)": "",
            "name": "Microsoft Outlook PST 97-2002 (ANSI)",
            "id": "fdd000377",
            "filenamepattern": [
                {
                    "value": "*.pst",
                    "url": "https://www.digipres.org/formats/extensions/#*.pst"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000377.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000377.xml"
            }
        },
        {
            "microsoftoutlookpst2003(unicode)": "",
            "name": "Microsoft Outlook PST 2003 (Unicode)",
            "id": "fdd000378",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000378.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000378.xml"
            }
        },
        {
            "microsoftoutlookpst2003(unicode)": "",
            "name": "Microsoft Outlook PST 2003 (Unicode)",
            "id": "fdd000378",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000378.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000378.xml"
            }
        },
        {
            "microsoftoutlookpst2003(unicode)": "",
            "name": "Microsoft Outlook PST 2003 (Unicode)",
            "id": "fdd000378",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000378.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000378.xml"
            }
        },
        {
            "microsoftoutlookpst2003(unicode)": "",
            "name": "Microsoft Outlook PST 2003 (Unicode)",
            "id": "fdd000378",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000378.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000378.xml"
            }
        },
        {
            "microsoftoutlookpst2003(unicode)": "",
            "name": "Microsoft Outlook PST 2003 (Unicode)",
            "id": "fdd000378",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000378.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000378.xml"
            }
        },
        {
            "microsoftoutlookpst2003(unicode)": "",
            "name": "Microsoft Outlook PST 2003 (Unicode)",
            "id": "fdd000378",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000378.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000378.xml"
            }
        },
        {
            "microsoftoutlookpst2003(unicode)": "",
            "name": "Microsoft Outlook PST 2003 (Unicode)",
            "id": "fdd000378",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000378.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000378.xml"
            }
        },
        {
            "microsoftoutlookpst2003(unicode)": "",
            "name": "Microsoft Outlook PST 2003 (Unicode)",
            "id": "fdd000378",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000378.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000378.xml"
            }
        },
        {
            "microsoftoutlookitem(msg)": "",
            "name": "Microsoft Outlook Item (MSG)",
            "id": "fdd000379",
            "filenamepattern": [
                {
                    "value": "*.msg",
                    "url": "https://www.digipres.org/formats/extensions/#*.msg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000379.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000379.xml"
            }
        },
        {
            "microsoftoutlookitem(msg)": "",
            "name": "Microsoft Outlook Item (MSG)",
            "id": "fdd000379",
            "filenamepattern": [
                {
                    "value": "*.msg",
                    "url": "https://www.digipres.org/formats/extensions/#*.msg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000379.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000379.xml"
            }
        },
        {
            "microsoftoutlookitem(msg)": "",
            "name": "Microsoft Outlook Item (MSG)",
            "id": "fdd000379",
            "filenamepattern": [
                {
                    "value": "*.msg",
                    "url": "https://www.digipres.org/formats/extensions/#*.msg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000379.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000379.xml"
            }
        },
        {
            "microsoftoutlookitem(msg)": "",
            "name": "Microsoft Outlook Item (MSG)",
            "id": "fdd000379",
            "filenamepattern": [
                {
                    "value": "*.msg",
                    "url": "https://www.digipres.org/formats/extensions/#*.msg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000379.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000379.xml"
            }
        },
        {
            "microsoftoutlookitem(msg)": "",
            "name": "Microsoft Outlook Item (MSG)",
            "id": "fdd000379",
            "filenamepattern": [
                {
                    "value": "*.msg",
                    "url": "https://www.digipres.org/formats/extensions/#*.msg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000379.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000379.xml"
            }
        },
        {
            "microsoftoutlookitem(msg)": "",
            "name": "Microsoft Outlook Item (MSG)",
            "id": "fdd000379",
            "filenamepattern": [
                {
                    "value": "*.msg",
                    "url": "https://www.digipres.org/formats/extensions/#*.msg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000379.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000379.xml"
            }
        },
        {
            "microsoftoutlookitem(msg)": "",
            "name": "Microsoft Outlook Item (MSG)",
            "id": "fdd000379",
            "filenamepattern": [
                {
                    "value": "*.msg",
                    "url": "https://www.digipres.org/formats/extensions/#*.msg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000379.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000379.xml"
            }
        },
        {
            "microsoftoutlookitem(msg)": "",
            "name": "Microsoft Outlook Item (MSG)",
            "id": "fdd000379",
            "filenamepattern": [
                {
                    "value": "*.msg",
                    "url": "https://www.digipres.org/formats/extensions/#*.msg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-outlook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-outlook"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000379.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000379.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version3": "",
            "name": "Microsoft Compound File Binary File Format, Version 3",
            "id": "fdd000380",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000380.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000380.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version3": "",
            "name": "Microsoft Compound File Binary File Format, Version 3",
            "id": "fdd000380",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000380.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000380.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version3": "",
            "name": "Microsoft Compound File Binary File Format, Version 3",
            "id": "fdd000380",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000380.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000380.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version3": "",
            "name": "Microsoft Compound File Binary File Format, Version 3",
            "id": "fdd000380",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000380.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000380.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version3": "",
            "name": "Microsoft Compound File Binary File Format, Version 3",
            "id": "fdd000380",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000380.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000380.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version3": "",
            "name": "Microsoft Compound File Binary File Format, Version 3",
            "id": "fdd000380",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000380.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000380.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version3": "",
            "name": "Microsoft Compound File Binary File Format, Version 3",
            "id": "fdd000380",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000380.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000380.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version3": "",
            "name": "Microsoft Compound File Binary File Format, Version 3",
            "id": "fdd000380",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000380.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000380.xml"
            }
        },
        {
            "json(javascriptobjectnotation)": "",
            "name": "JSON (JavaScript Object Notation)",
            "id": "fdd000381",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "text/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#text/javascript"
                },
                {
                    "value": "text/json",
                    "url": "https://www.digipres.org/formats/mime-types/#text/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000381.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000381.xml"
            }
        },
        {
            "json(javascriptobjectnotation)": "",
            "name": "JSON (JavaScript Object Notation)",
            "id": "fdd000381",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "text/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#text/javascript"
                },
                {
                    "value": "text/json",
                    "url": "https://www.digipres.org/formats/mime-types/#text/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000381.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000381.xml"
            }
        },
        {
            "json(javascriptobjectnotation)": "",
            "name": "JSON (JavaScript Object Notation)",
            "id": "fdd000381",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "text/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#text/javascript"
                },
                {
                    "value": "text/json",
                    "url": "https://www.digipres.org/formats/mime-types/#text/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000381.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000381.xml"
            }
        },
        {
            "json(javascriptobjectnotation)": "",
            "name": "JSON (JavaScript Object Notation)",
            "id": "fdd000381",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "text/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#text/javascript"
                },
                {
                    "value": "text/json",
                    "url": "https://www.digipres.org/formats/mime-types/#text/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000381.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000381.xml"
            }
        },
        {
            "json(javascriptobjectnotation)": "",
            "name": "JSON (JavaScript Object Notation)",
            "id": "fdd000381",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "text/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#text/javascript"
                },
                {
                    "value": "text/json",
                    "url": "https://www.digipres.org/formats/mime-types/#text/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000381.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000381.xml"
            }
        },
        {
            "json(javascriptobjectnotation)": "",
            "name": "JSON (JavaScript Object Notation)",
            "id": "fdd000381",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "text/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#text/javascript"
                },
                {
                    "value": "text/json",
                    "url": "https://www.digipres.org/formats/mime-types/#text/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000381.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000381.xml"
            }
        },
        {
            "json(javascriptobjectnotation)": "",
            "name": "JSON (JavaScript Object Notation)",
            "id": "fdd000381",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "text/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#text/javascript"
                },
                {
                    "value": "text/json",
                    "url": "https://www.digipres.org/formats/mime-types/#text/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000381.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000381.xml"
            }
        },
        {
            "json(javascriptobjectnotation)": "",
            "name": "JSON (JavaScript Object Notation)",
            "id": "fdd000381",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "text/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#text/javascript"
                },
                {
                    "value": "text/json",
                    "url": "https://www.digipres.org/formats/mime-types/#text/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000381.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000381.xml"
            }
        },
        {
            "geojson,version1.0(2008)": "",
            "name": "GeoJSON, Version 1.0 (2008)",
            "id": "fdd000382",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000382.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000382.xml"
            }
        },
        {
            "geojson,version1.0(2008)": "",
            "name": "GeoJSON, Version 1.0 (2008)",
            "id": "fdd000382",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000382.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000382.xml"
            }
        },
        {
            "geojson,version1.0(2008)": "",
            "name": "GeoJSON, Version 1.0 (2008)",
            "id": "fdd000382",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000382.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000382.xml"
            }
        },
        {
            "geojson,version1.0(2008)": "",
            "name": "GeoJSON, Version 1.0 (2008)",
            "id": "fdd000382",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000382.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000382.xml"
            }
        },
        {
            "geojson,version1.0(2008)": "",
            "name": "GeoJSON, Version 1.0 (2008)",
            "id": "fdd000382",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000382.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000382.xml"
            }
        },
        {
            "geojson,version1.0(2008)": "",
            "name": "GeoJSON, Version 1.0 (2008)",
            "id": "fdd000382",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000382.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000382.xml"
            }
        },
        {
            "geojson,version1.0(2008)": "",
            "name": "GeoJSON, Version 1.0 (2008)",
            "id": "fdd000382",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000382.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000382.xml"
            }
        },
        {
            "geojson,version1.0(2008)": "",
            "name": "GeoJSON, Version 1.0 (2008)",
            "id": "fdd000382",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000382.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000382.xml"
            }
        },
        {
            "mboxemailformat": "",
            "name": "MBOX Email Format",
            "id": "fdd000383",
            "filenamepattern": [
                {
                    "value": "*.mbox",
                    "url": "https://www.digipres.org/formats/extensions/#*.mbox"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mbox",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mbox"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000383.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000383.xml"
            }
        },
        {
            "mboxemailformat": "",
            "name": "MBOX Email Format",
            "id": "fdd000383",
            "filenamepattern": [
                {
                    "value": "*.mbox",
                    "url": "https://www.digipres.org/formats/extensions/#*.mbox"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mbox",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mbox"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000383.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000383.xml"
            }
        },
        {
            "mboxemailformat": "",
            "name": "MBOX Email Format",
            "id": "fdd000383",
            "filenamepattern": [
                {
                    "value": "*.mbox",
                    "url": "https://www.digipres.org/formats/extensions/#*.mbox"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mbox",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mbox"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000383.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000383.xml"
            }
        },
        {
            "mboxemailformat": "",
            "name": "MBOX Email Format",
            "id": "fdd000383",
            "filenamepattern": [
                {
                    "value": "*.mbox",
                    "url": "https://www.digipres.org/formats/extensions/#*.mbox"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mbox",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mbox"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000383.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000383.xml"
            }
        },
        {
            "mboxemailformat": "",
            "name": "MBOX Email Format",
            "id": "fdd000383",
            "filenamepattern": [
                {
                    "value": "*.mbox",
                    "url": "https://www.digipres.org/formats/extensions/#*.mbox"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mbox",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mbox"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000383.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000383.xml"
            }
        },
        {
            "mboxemailformat": "",
            "name": "MBOX Email Format",
            "id": "fdd000383",
            "filenamepattern": [
                {
                    "value": "*.mbox",
                    "url": "https://www.digipres.org/formats/extensions/#*.mbox"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mbox",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mbox"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000383.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000383.xml"
            }
        },
        {
            "mboxemailformat": "",
            "name": "MBOX Email Format",
            "id": "fdd000383",
            "filenamepattern": [
                {
                    "value": "*.mbox",
                    "url": "https://www.digipres.org/formats/extensions/#*.mbox"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mbox",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mbox"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000383.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000383.xml"
            }
        },
        {
            "mboxemailformat": "",
            "name": "MBOX Email Format",
            "id": "fdd000383",
            "filenamepattern": [
                {
                    "value": "*.mbox",
                    "url": "https://www.digipres.org/formats/extensions/#*.mbox"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/mbox",
                    "url": "https://www.digipres.org/formats/mime-types/#application/mbox"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000383.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000383.xml"
            }
        },
        {
            "mboxoemailformat": "",
            "name": "MBOXO Email Format",
            "id": "fdd000384",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000384.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000384.xml"
            }
        },
        {
            "mboxoemailformat": "",
            "name": "MBOXO Email Format",
            "id": "fdd000384",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000384.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000384.xml"
            }
        },
        {
            "mboxoemailformat": "",
            "name": "MBOXO Email Format",
            "id": "fdd000384",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000384.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000384.xml"
            }
        },
        {
            "mboxoemailformat": "",
            "name": "MBOXO Email Format",
            "id": "fdd000384",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000384.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000384.xml"
            }
        },
        {
            "mboxoemailformat": "",
            "name": "MBOXO Email Format",
            "id": "fdd000384",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000384.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000384.xml"
            }
        },
        {
            "mboxoemailformat": "",
            "name": "MBOXO Email Format",
            "id": "fdd000384",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000384.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000384.xml"
            }
        },
        {
            "mboxoemailformat": "",
            "name": "MBOXO Email Format",
            "id": "fdd000384",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000384.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000384.xml"
            }
        },
        {
            "mboxoemailformat": "",
            "name": "MBOXO Email Format",
            "id": "fdd000384",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000384.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000384.xml"
            }
        },
        {
            "mboxrdemailformat": "",
            "name": "MBOXRD Email Format",
            "id": "fdd000385",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000385.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000385.xml"
            }
        },
        {
            "mboxrdemailformat": "",
            "name": "MBOXRD Email Format",
            "id": "fdd000385",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000385.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000385.xml"
            }
        },
        {
            "mboxrdemailformat": "",
            "name": "MBOXRD Email Format",
            "id": "fdd000385",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000385.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000385.xml"
            }
        },
        {
            "mboxrdemailformat": "",
            "name": "MBOXRD Email Format",
            "id": "fdd000385",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000385.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000385.xml"
            }
        },
        {
            "mboxrdemailformat": "",
            "name": "MBOXRD Email Format",
            "id": "fdd000385",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000385.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000385.xml"
            }
        },
        {
            "mboxrdemailformat": "",
            "name": "MBOXRD Email Format",
            "id": "fdd000385",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000385.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000385.xml"
            }
        },
        {
            "mboxrdemailformat": "",
            "name": "MBOXRD Email Format",
            "id": "fdd000385",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000385.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000385.xml"
            }
        },
        {
            "mboxrdemailformat": "",
            "name": "MBOXRD Email Format",
            "id": "fdd000385",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000385.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000385.xml"
            }
        },
        {
            "mboxclemailformat": "",
            "name": "MBOXCL Email Format",
            "id": "fdd000386",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000386.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000386.xml"
            }
        },
        {
            "mboxclemailformat": "",
            "name": "MBOXCL Email Format",
            "id": "fdd000386",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000386.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000386.xml"
            }
        },
        {
            "mboxclemailformat": "",
            "name": "MBOXCL Email Format",
            "id": "fdd000386",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000386.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000386.xml"
            }
        },
        {
            "mboxclemailformat": "",
            "name": "MBOXCL Email Format",
            "id": "fdd000386",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000386.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000386.xml"
            }
        },
        {
            "mboxclemailformat": "",
            "name": "MBOXCL Email Format",
            "id": "fdd000386",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000386.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000386.xml"
            }
        },
        {
            "mboxclemailformat": "",
            "name": "MBOXCL Email Format",
            "id": "fdd000386",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000386.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000386.xml"
            }
        },
        {
            "mboxclemailformat": "",
            "name": "MBOXCL Email Format",
            "id": "fdd000386",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000386.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000386.xml"
            }
        },
        {
            "mboxclemailformat": "",
            "name": "MBOXCL Email Format",
            "id": "fdd000386",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000386.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000386.xml"
            }
        },
        {
            "mboxcl2emailformat": "",
            "name": "MBOXCL2 Email Format",
            "id": "fdd000387",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000387.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000387.xml"
            }
        },
        {
            "mboxcl2emailformat": "",
            "name": "MBOXCL2 Email Format",
            "id": "fdd000387",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000387.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000387.xml"
            }
        },
        {
            "mboxcl2emailformat": "",
            "name": "MBOXCL2 Email Format",
            "id": "fdd000387",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000387.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000387.xml"
            }
        },
        {
            "mboxcl2emailformat": "",
            "name": "MBOXCL2 Email Format",
            "id": "fdd000387",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000387.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000387.xml"
            }
        },
        {
            "mboxcl2emailformat": "",
            "name": "MBOXCL2 Email Format",
            "id": "fdd000387",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000387.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000387.xml"
            }
        },
        {
            "mboxcl2emailformat": "",
            "name": "MBOXCL2 Email Format",
            "id": "fdd000387",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000387.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000387.xml"
            }
        },
        {
            "mboxcl2emailformat": "",
            "name": "MBOXCL2 Email Format",
            "id": "fdd000387",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000387.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000387.xml"
            }
        },
        {
            "mboxcl2emailformat": "",
            "name": "MBOXCL2 Email Format",
            "id": "fdd000387",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000387.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000387.xml"
            }
        },
        {
            "email(electronicmailformat)": "",
            "name": "Email (Electronic Mail Format)",
            "id": "fdd000388",
            "filenamepattern": [
                {
                    "value": "*.eml",
                    "url": "https://www.digipres.org/formats/extensions/#*.eml"
                }
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000388.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000388.xml"
            }
        },
        {
            "email(electronicmailformat)": "",
            "name": "Email (Electronic Mail Format)",
            "id": "fdd000388",
            "filenamepattern": [
                {
                    "value": "*.eml",
                    "url": "https://www.digipres.org/formats/extensions/#*.eml"
                }
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000388.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000388.xml"
            }
        },
        {
            "email(electronicmailformat)": "",
            "name": "Email (Electronic Mail Format)",
            "id": "fdd000388",
            "filenamepattern": [
                {
                    "value": "*.eml",
                    "url": "https://www.digipres.org/formats/extensions/#*.eml"
                }
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000388.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000388.xml"
            }
        },
        {
            "email(electronicmailformat)": "",
            "name": "Email (Electronic Mail Format)",
            "id": "fdd000388",
            "filenamepattern": [
                {
                    "value": "*.eml",
                    "url": "https://www.digipres.org/formats/extensions/#*.eml"
                }
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000388.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000388.xml"
            }
        },
        {
            "email(electronicmailformat)": "",
            "name": "Email (Electronic Mail Format)",
            "id": "fdd000388",
            "filenamepattern": [
                {
                    "value": "*.eml",
                    "url": "https://www.digipres.org/formats/extensions/#*.eml"
                }
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000388.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000388.xml"
            }
        },
        {
            "email(electronicmailformat)": "",
            "name": "Email (Electronic Mail Format)",
            "id": "fdd000388",
            "filenamepattern": [
                {
                    "value": "*.eml",
                    "url": "https://www.digipres.org/formats/extensions/#*.eml"
                }
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000388.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000388.xml"
            }
        },
        {
            "email(electronicmailformat)": "",
            "name": "Email (Electronic Mail Format)",
            "id": "fdd000388",
            "filenamepattern": [
                {
                    "value": "*.eml",
                    "url": "https://www.digipres.org/formats/extensions/#*.eml"
                }
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000388.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000388.xml"
            }
        },
        {
            "email(electronicmailformat)": "",
            "name": "Email (Electronic Mail Format)",
            "id": "fdd000388",
            "filenamepattern": [
                {
                    "value": "*.eml",
                    "url": "https://www.digipres.org/formats/extensions/#*.eml"
                }
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000388.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000388.xml"
            }
        },
        {
            "appleprores422codecfamily": "",
            "name": "Apple ProRes 422 Codec Family",
            "id": "fdd000389",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000389.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000389.xml"
            }
        },
        {
            "appleprores422codecfamily": "",
            "name": "Apple ProRes 422 Codec Family",
            "id": "fdd000389",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000389.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000389.xml"
            }
        },
        {
            "appleprores422codecfamily": "",
            "name": "Apple ProRes 422 Codec Family",
            "id": "fdd000389",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000389.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000389.xml"
            }
        },
        {
            "appleprores422codecfamily": "",
            "name": "Apple ProRes 422 Codec Family",
            "id": "fdd000389",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000389.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000389.xml"
            }
        },
        {
            "appleprores422codecfamily": "",
            "name": "Apple ProRes 422 Codec Family",
            "id": "fdd000389",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000389.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000389.xml"
            }
        },
        {
            "appleprores422codecfamily": "",
            "name": "Apple ProRes 422 Codec Family",
            "id": "fdd000389",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000389.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000389.xml"
            }
        },
        {
            "appleprores422codecfamily": "",
            "name": "Apple ProRes 422 Codec Family",
            "id": "fdd000389",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000389.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000389.xml"
            }
        },
        {
            "appleprores422codecfamily": "",
            "name": "Apple ProRes 422 Codec Family",
            "id": "fdd000389",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000389.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000389.xml"
            }
        },
        {
            "appleprores422": "",
            "name": "Apple ProRes 422",
            "id": "fdd000390",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000390.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000390.xml"
            }
        },
        {
            "appleprores422": "",
            "name": "Apple ProRes 422",
            "id": "fdd000390",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000390.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000390.xml"
            }
        },
        {
            "appleprores422": "",
            "name": "Apple ProRes 422",
            "id": "fdd000390",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000390.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000390.xml"
            }
        },
        {
            "appleprores422": "",
            "name": "Apple ProRes 422",
            "id": "fdd000390",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000390.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000390.xml"
            }
        },
        {
            "appleprores422": "",
            "name": "Apple ProRes 422",
            "id": "fdd000390",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000390.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000390.xml"
            }
        },
        {
            "appleprores422": "",
            "name": "Apple ProRes 422",
            "id": "fdd000390",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000390.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000390.xml"
            }
        },
        {
            "appleprores422": "",
            "name": "Apple ProRes 422",
            "id": "fdd000390",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000390.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000390.xml"
            }
        },
        {
            "appleprores422": "",
            "name": "Apple ProRes 422",
            "id": "fdd000390",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000390.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000390.xml"
            }
        },
        {
            "cc:mailarchiveemailformat": "",
            "name": "cc:Mail Archive Email Format",
            "id": "fdd000391",
            "filenamepattern": [
                {
                    "value": "*.cca",
                    "url": "https://www.digipres.org/formats/extensions/#*.cca"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000391.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000391.xml"
            }
        },
        {
            "cc:mailarchiveemailformat": "",
            "name": "cc:Mail Archive Email Format",
            "id": "fdd000391",
            "filenamepattern": [
                {
                    "value": "*.cca",
                    "url": "https://www.digipres.org/formats/extensions/#*.cca"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000391.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000391.xml"
            }
        },
        {
            "cc:mailarchiveemailformat": "",
            "name": "cc:Mail Archive Email Format",
            "id": "fdd000391",
            "filenamepattern": [
                {
                    "value": "*.cca",
                    "url": "https://www.digipres.org/formats/extensions/#*.cca"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000391.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000391.xml"
            }
        },
        {
            "cc:mailarchiveemailformat": "",
            "name": "cc:Mail Archive Email Format",
            "id": "fdd000391",
            "filenamepattern": [
                {
                    "value": "*.cca",
                    "url": "https://www.digipres.org/formats/extensions/#*.cca"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000391.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000391.xml"
            }
        },
        {
            "cc:mailarchiveemailformat": "",
            "name": "cc:Mail Archive Email Format",
            "id": "fdd000391",
            "filenamepattern": [
                {
                    "value": "*.cca",
                    "url": "https://www.digipres.org/formats/extensions/#*.cca"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000391.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000391.xml"
            }
        },
        {
            "cc:mailarchiveemailformat": "",
            "name": "cc:Mail Archive Email Format",
            "id": "fdd000391",
            "filenamepattern": [
                {
                    "value": "*.cca",
                    "url": "https://www.digipres.org/formats/extensions/#*.cca"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000391.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000391.xml"
            }
        },
        {
            "cc:mailarchiveemailformat": "",
            "name": "cc:Mail Archive Email Format",
            "id": "fdd000391",
            "filenamepattern": [
                {
                    "value": "*.cca",
                    "url": "https://www.digipres.org/formats/extensions/#*.cca"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000391.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000391.xml"
            }
        },
        {
            "cc:mailarchiveemailformat": "",
            "name": "cc:Mail Archive Email Format",
            "id": "fdd000391",
            "filenamepattern": [
                {
                    "value": "*.cca",
                    "url": "https://www.digipres.org/formats/extensions/#*.cca"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000391.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000391.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version4": "",
            "name": "Microsoft Compound File Binary File Format, Version 4",
            "id": "fdd000392",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000392.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000392.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version4": "",
            "name": "Microsoft Compound File Binary File Format, Version 4",
            "id": "fdd000392",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000392.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000392.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version4": "",
            "name": "Microsoft Compound File Binary File Format, Version 4",
            "id": "fdd000392",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000392.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000392.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version4": "",
            "name": "Microsoft Compound File Binary File Format, Version 4",
            "id": "fdd000392",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000392.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000392.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version4": "",
            "name": "Microsoft Compound File Binary File Format, Version 4",
            "id": "fdd000392",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000392.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000392.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version4": "",
            "name": "Microsoft Compound File Binary File Format, Version 4",
            "id": "fdd000392",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000392.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000392.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version4": "",
            "name": "Microsoft Compound File Binary File Format, Version 4",
            "id": "fdd000392",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000392.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000392.xml"
            }
        },
        {
            "microsoftcompoundfilebinaryfileformat,version4": "",
            "name": "Microsoft Compound File Binary File Format, Version 4",
            "id": "fdd000392",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000392.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000392.xml"
            }
        },
        {
            "internetmessageformat": "",
            "name": "Internet Message Format",
            "id": "fdd000393",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000393.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000393.xml"
            }
        },
        {
            "internetmessageformat": "",
            "name": "Internet Message Format",
            "id": "fdd000393",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000393.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000393.xml"
            }
        },
        {
            "internetmessageformat": "",
            "name": "Internet Message Format",
            "id": "fdd000393",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000393.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000393.xml"
            }
        },
        {
            "internetmessageformat": "",
            "name": "Internet Message Format",
            "id": "fdd000393",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000393.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000393.xml"
            }
        },
        {
            "internetmessageformat": "",
            "name": "Internet Message Format",
            "id": "fdd000393",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000393.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000393.xml"
            }
        },
        {
            "internetmessageformat": "",
            "name": "Internet Message Format",
            "id": "fdd000393",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000393.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000393.xml"
            }
        },
        {
            "internetmessageformat": "",
            "name": "Internet Message Format",
            "id": "fdd000393",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000393.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000393.xml"
            }
        },
        {
            "internetmessageformat": "",
            "name": "Internet Message Format",
            "id": "fdd000393",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/rfc822",
                    "url": "https://www.digipres.org/formats/mime-types/#message/rfc822"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000393.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000393.xml"
            }
        },
        {
            "icalendarelectroniccalendarandschedulingformat": "",
            "name": "iCalendar Electronic Calendar and Scheduling Format",
            "id": "fdd000394",
            "filenamepattern": [
                {
                    "value": "*.ics",
                    "url": "https://www.digipres.org/formats/extensions/#*.ics"
                },
                {
                    "value": "*.ifb",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifb"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/calendar",
                    "url": "https://www.digipres.org/formats/mime-types/#text/calendar"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000394.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000394.xml"
            }
        },
        {
            "icalendarelectroniccalendarandschedulingformat": "",
            "name": "iCalendar Electronic Calendar and Scheduling Format",
            "id": "fdd000394",
            "filenamepattern": [
                {
                    "value": "*.ics",
                    "url": "https://www.digipres.org/formats/extensions/#*.ics"
                },
                {
                    "value": "*.ifb",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifb"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/calendar",
                    "url": "https://www.digipres.org/formats/mime-types/#text/calendar"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000394.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000394.xml"
            }
        },
        {
            "icalendarelectroniccalendarandschedulingformat": "",
            "name": "iCalendar Electronic Calendar and Scheduling Format",
            "id": "fdd000394",
            "filenamepattern": [
                {
                    "value": "*.ics",
                    "url": "https://www.digipres.org/formats/extensions/#*.ics"
                },
                {
                    "value": "*.ifb",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifb"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/calendar",
                    "url": "https://www.digipres.org/formats/mime-types/#text/calendar"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000394.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000394.xml"
            }
        },
        {
            "icalendarelectroniccalendarandschedulingformat": "",
            "name": "iCalendar Electronic Calendar and Scheduling Format",
            "id": "fdd000394",
            "filenamepattern": [
                {
                    "value": "*.ics",
                    "url": "https://www.digipres.org/formats/extensions/#*.ics"
                },
                {
                    "value": "*.ifb",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifb"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/calendar",
                    "url": "https://www.digipres.org/formats/mime-types/#text/calendar"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000394.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000394.xml"
            }
        },
        {
            "icalendarelectroniccalendarandschedulingformat": "",
            "name": "iCalendar Electronic Calendar and Scheduling Format",
            "id": "fdd000394",
            "filenamepattern": [
                {
                    "value": "*.ics",
                    "url": "https://www.digipres.org/formats/extensions/#*.ics"
                },
                {
                    "value": "*.ifb",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifb"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/calendar",
                    "url": "https://www.digipres.org/formats/mime-types/#text/calendar"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000394.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000394.xml"
            }
        },
        {
            "icalendarelectroniccalendarandschedulingformat": "",
            "name": "iCalendar Electronic Calendar and Scheduling Format",
            "id": "fdd000394",
            "filenamepattern": [
                {
                    "value": "*.ics",
                    "url": "https://www.digipres.org/formats/extensions/#*.ics"
                },
                {
                    "value": "*.ifb",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifb"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/calendar",
                    "url": "https://www.digipres.org/formats/mime-types/#text/calendar"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000394.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000394.xml"
            }
        },
        {
            "icalendarelectroniccalendarandschedulingformat": "",
            "name": "iCalendar Electronic Calendar and Scheduling Format",
            "id": "fdd000394",
            "filenamepattern": [
                {
                    "value": "*.ics",
                    "url": "https://www.digipres.org/formats/extensions/#*.ics"
                },
                {
                    "value": "*.ifb",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifb"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/calendar",
                    "url": "https://www.digipres.org/formats/mime-types/#text/calendar"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000394.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000394.xml"
            }
        },
        {
            "icalendarelectroniccalendarandschedulingformat": "",
            "name": "iCalendar Electronic Calendar and Scheduling Format",
            "id": "fdd000394",
            "filenamepattern": [
                {
                    "value": "*.ics",
                    "url": "https://www.digipres.org/formats/extensions/#*.ics"
                },
                {
                    "value": "*.ifb",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifb"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/calendar",
                    "url": "https://www.digipres.org/formats/mime-types/#text/calendar"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000394.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000394.xml"
            }
        },
        {
            "ooxmlformatfamilyiso/iec29500andecma376": "",
            "name": "OOXML Format Family -- ISO/IEC 29500 and ECMA 376",
            "id": "fdd000395",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                },
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000395.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000395.xml"
            }
        },
        {
            "ooxmlformatfamilyiso/iec29500andecma376": "",
            "name": "OOXML Format Family -- ISO/IEC 29500 and ECMA 376",
            "id": "fdd000395",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                },
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000395.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000395.xml"
            }
        },
        {
            "ooxmlformatfamilyiso/iec29500andecma376": "",
            "name": "OOXML Format Family -- ISO/IEC 29500 and ECMA 376",
            "id": "fdd000395",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                },
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000395.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000395.xml"
            }
        },
        {
            "ooxmlformatfamilyiso/iec29500andecma376": "",
            "name": "OOXML Format Family -- ISO/IEC 29500 and ECMA 376",
            "id": "fdd000395",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                },
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000395.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000395.xml"
            }
        },
        {
            "ooxmlformatfamilyiso/iec29500andecma376": "",
            "name": "OOXML Format Family -- ISO/IEC 29500 and ECMA 376",
            "id": "fdd000395",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                },
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000395.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000395.xml"
            }
        },
        {
            "ooxmlformatfamilyiso/iec29500andecma376": "",
            "name": "OOXML Format Family -- ISO/IEC 29500 and ECMA 376",
            "id": "fdd000395",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                },
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000395.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000395.xml"
            }
        },
        {
            "ooxmlformatfamilyiso/iec29500andecma376": "",
            "name": "OOXML Format Family -- ISO/IEC 29500 and ECMA 376",
            "id": "fdd000395",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                },
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000395.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000395.xml"
            }
        },
        {
            "ooxmlformatfamilyiso/iec29500andecma376": "",
            "name": "OOXML Format Family -- ISO/IEC 29500 and ECMA 376",
            "id": "fdd000395",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                },
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.document"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000395.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000395.xml"
            }
        },
        {
            "markupcompatibilityandextensibility(officeopenxml),iso295003:20082015,ecma376,editions15": "",
            "name": "Markup Compatibility and Extensibility (Office Open XML), ISO 29500-3:2008-2015, ECMA-376, Editions 1-5",
            "id": "fdd000396",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000396.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000396.xml"
            }
        },
        {
            "markupcompatibilityandextensibility(officeopenxml),iso295003:20082015,ecma376,editions15": "",
            "name": "Markup Compatibility and Extensibility (Office Open XML), ISO 29500-3:2008-2015, ECMA-376, Editions 1-5",
            "id": "fdd000396",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000396.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000396.xml"
            }
        },
        {
            "markupcompatibilityandextensibility(officeopenxml),iso295003:20082015,ecma376,editions15": "",
            "name": "Markup Compatibility and Extensibility (Office Open XML), ISO 29500-3:2008-2015, ECMA-376, Editions 1-5",
            "id": "fdd000396",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000396.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000396.xml"
            }
        },
        {
            "markupcompatibilityandextensibility(officeopenxml),iso295003:20082015,ecma376,editions15": "",
            "name": "Markup Compatibility and Extensibility (Office Open XML), ISO 29500-3:2008-2015, ECMA-376, Editions 1-5",
            "id": "fdd000396",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000396.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000396.xml"
            }
        },
        {
            "markupcompatibilityandextensibility(officeopenxml),iso295003:20082015,ecma376,editions15": "",
            "name": "Markup Compatibility and Extensibility (Office Open XML), ISO 29500-3:2008-2015, ECMA-376, Editions 1-5",
            "id": "fdd000396",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000396.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000396.xml"
            }
        },
        {
            "markupcompatibilityandextensibility(officeopenxml),iso295003:20082015,ecma376,editions15": "",
            "name": "Markup Compatibility and Extensibility (Office Open XML), ISO 29500-3:2008-2015, ECMA-376, Editions 1-5",
            "id": "fdd000396",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000396.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000396.xml"
            }
        },
        {
            "markupcompatibilityandextensibility(officeopenxml),iso295003:20082015,ecma376,editions15": "",
            "name": "Markup Compatibility and Extensibility (Office Open XML), ISO 29500-3:2008-2015, ECMA-376, Editions 1-5",
            "id": "fdd000396",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000396.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000396.xml"
            }
        },
        {
            "markupcompatibilityandextensibility(officeopenxml),iso295003:20082015,ecma376,editions15": "",
            "name": "Markup Compatibility and Extensibility (Office Open XML), ISO 29500-3:2008-2015, ECMA-376, Editions 1-5",
            "id": "fdd000396",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000396.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000396.xml"
            }
        },
        {
            "docxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "DOCX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000397",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000397.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000397.xml"
            }
        },
        {
            "docxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "DOCX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000397",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000397.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000397.xml"
            }
        },
        {
            "docxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "DOCX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000397",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000397.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000397.xml"
            }
        },
        {
            "docxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "DOCX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000397",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000397.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000397.xml"
            }
        },
        {
            "docxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "DOCX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000397",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000397.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000397.xml"
            }
        },
        {
            "docxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "DOCX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000397",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000397.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000397.xml"
            }
        },
        {
            "docxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "DOCX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000397",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000397.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000397.xml"
            }
        },
        {
            "docxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "DOCX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000397",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000397.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000397.xml"
            }
        },
        {
            "xlsxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "XLSX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000398",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000398.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000398.xml"
            }
        },
        {
            "xlsxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "XLSX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000398",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000398.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000398.xml"
            }
        },
        {
            "xlsxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "XLSX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000398",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000398.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000398.xml"
            }
        },
        {
            "xlsxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "XLSX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000398",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000398.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000398.xml"
            }
        },
        {
            "xlsxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "XLSX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000398",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000398.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000398.xml"
            }
        },
        {
            "xlsxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "XLSX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000398",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000398.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000398.xml"
            }
        },
        {
            "xlsxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "XLSX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000398",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000398.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000398.xml"
            }
        },
        {
            "xlsxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "XLSX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000398",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000398.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000398.xml"
            }
        },
        {
            "pptxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "PPTX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000399",
            "filenamepattern": [
                {
                    "value": "*.ppsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.slideshow"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000399.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000399.xml"
            }
        },
        {
            "pptxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "PPTX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000399",
            "filenamepattern": [
                {
                    "value": "*.ppsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.slideshow"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000399.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000399.xml"
            }
        },
        {
            "pptxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "PPTX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000399",
            "filenamepattern": [
                {
                    "value": "*.ppsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.slideshow"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000399.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000399.xml"
            }
        },
        {
            "pptxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "PPTX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000399",
            "filenamepattern": [
                {
                    "value": "*.ppsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.slideshow"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000399.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000399.xml"
            }
        },
        {
            "pptxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "PPTX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000399",
            "filenamepattern": [
                {
                    "value": "*.ppsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.slideshow"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000399.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000399.xml"
            }
        },
        {
            "pptxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "PPTX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000399",
            "filenamepattern": [
                {
                    "value": "*.ppsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.slideshow"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000399.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000399.xml"
            }
        },
        {
            "pptxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "PPTX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000399",
            "filenamepattern": [
                {
                    "value": "*.ppsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.slideshow"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000399.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000399.xml"
            }
        },
        {
            "pptxtransitional(officeopenxml),iso29500:20082016,ecma376,editions15": "",
            "name": "PPTX Transitional (Office Open XML), ISO 29500:2008-2016, ECMA-376, Editions 1-5",
            "id": "fdd000399",
            "filenamepattern": [
                {
                    "value": "*.ppsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppsx"
                },
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                },
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.slideshow"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000399.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000399.xml"
            }
        },
        {
            "docxstrict(officeopenxml),iso295001:20082016": "",
            "name": "DOCX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000400",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000400.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000400.xml"
            }
        },
        {
            "docxstrict(officeopenxml),iso295001:20082016": "",
            "name": "DOCX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000400",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000400.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000400.xml"
            }
        },
        {
            "docxstrict(officeopenxml),iso295001:20082016": "",
            "name": "DOCX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000400",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000400.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000400.xml"
            }
        },
        {
            "docxstrict(officeopenxml),iso295001:20082016": "",
            "name": "DOCX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000400",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000400.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000400.xml"
            }
        },
        {
            "docxstrict(officeopenxml),iso295001:20082016": "",
            "name": "DOCX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000400",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000400.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000400.xml"
            }
        },
        {
            "docxstrict(officeopenxml),iso295001:20082016": "",
            "name": "DOCX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000400",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000400.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000400.xml"
            }
        },
        {
            "docxstrict(officeopenxml),iso295001:20082016": "",
            "name": "DOCX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000400",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000400.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000400.xml"
            }
        },
        {
            "docxstrict(officeopenxml),iso295001:20082016": "",
            "name": "DOCX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000400",
            "filenamepattern": [
                {
                    "value": "*.docx",
                    "url": "https://www.digipres.org/formats/extensions/#*.docx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000400.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000400.xml"
            }
        },
        {
            "xlsxstrict(officeopenxml),iso295001:20082016": "",
            "name": "XLSX Strict (Office Open XML), ISO 29500-1:2008-2016",
            "id": "fdd000401",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000401.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000401.xml"
            }
        },
        {
            "xlsxstrict(officeopenxml),iso295001:20082016": "",
            "name": "XLSX Strict (Office Open XML), ISO 29500-1:2008-2016",
            "id": "fdd000401",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000401.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000401.xml"
            }
        },
        {
            "xlsxstrict(officeopenxml),iso295001:20082016": "",
            "name": "XLSX Strict (Office Open XML), ISO 29500-1:2008-2016",
            "id": "fdd000401",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000401.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000401.xml"
            }
        },
        {
            "xlsxstrict(officeopenxml),iso295001:20082016": "",
            "name": "XLSX Strict (Office Open XML), ISO 29500-1:2008-2016",
            "id": "fdd000401",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000401.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000401.xml"
            }
        },
        {
            "xlsxstrict(officeopenxml),iso295001:20082016": "",
            "name": "XLSX Strict (Office Open XML), ISO 29500-1:2008-2016",
            "id": "fdd000401",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000401.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000401.xml"
            }
        },
        {
            "xlsxstrict(officeopenxml),iso295001:20082016": "",
            "name": "XLSX Strict (Office Open XML), ISO 29500-1:2008-2016",
            "id": "fdd000401",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000401.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000401.xml"
            }
        },
        {
            "xlsxstrict(officeopenxml),iso295001:20082016": "",
            "name": "XLSX Strict (Office Open XML), ISO 29500-1:2008-2016",
            "id": "fdd000401",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000401.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000401.xml"
            }
        },
        {
            "xlsxstrict(officeopenxml),iso295001:20082016": "",
            "name": "XLSX Strict (Office Open XML), ISO 29500-1:2008-2016",
            "id": "fdd000401",
            "filenamepattern": [
                {
                    "value": "*.xlsx",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000401.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000401.xml"
            }
        },
        {
            "pptxstrict(officeopenxml),iso295001:20082016": "",
            "name": "PPTX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000402",
            "filenamepattern": [
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000402.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000402.xml"
            }
        },
        {
            "pptxstrict(officeopenxml),iso295001:20082016": "",
            "name": "PPTX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000402",
            "filenamepattern": [
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000402.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000402.xml"
            }
        },
        {
            "pptxstrict(officeopenxml),iso295001:20082016": "",
            "name": "PPTX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000402",
            "filenamepattern": [
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000402.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000402.xml"
            }
        },
        {
            "pptxstrict(officeopenxml),iso295001:20082016": "",
            "name": "PPTX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000402",
            "filenamepattern": [
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000402.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000402.xml"
            }
        },
        {
            "pptxstrict(officeopenxml),iso295001:20082016": "",
            "name": "PPTX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000402",
            "filenamepattern": [
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000402.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000402.xml"
            }
        },
        {
            "pptxstrict(officeopenxml),iso295001:20082016": "",
            "name": "PPTX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000402",
            "filenamepattern": [
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000402.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000402.xml"
            }
        },
        {
            "pptxstrict(officeopenxml),iso295001:20082016": "",
            "name": "PPTX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000402",
            "filenamepattern": [
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000402.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000402.xml"
            }
        },
        {
            "pptxstrict(officeopenxml),iso295001:20082016": "",
            "name": "PPTX Strict (Office Open XML), ISO 29500-1: 2008-2016",
            "id": "fdd000402",
            "filenamepattern": [
                {
                    "value": "*.pptx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pptx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.openxmlformats-officedocument.presentationml.presentation"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000402.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000402.xml"
            }
        },
        {
            "appleprores422highquality": "",
            "name": "Apple ProRes 422 High Quality",
            "id": "fdd000403",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000403.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000403.xml"
            }
        },
        {
            "appleprores422highquality": "",
            "name": "Apple ProRes 422 High Quality",
            "id": "fdd000403",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000403.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000403.xml"
            }
        },
        {
            "appleprores422highquality": "",
            "name": "Apple ProRes 422 High Quality",
            "id": "fdd000403",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000403.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000403.xml"
            }
        },
        {
            "appleprores422highquality": "",
            "name": "Apple ProRes 422 High Quality",
            "id": "fdd000403",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000403.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000403.xml"
            }
        },
        {
            "appleprores422highquality": "",
            "name": "Apple ProRes 422 High Quality",
            "id": "fdd000403",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000403.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000403.xml"
            }
        },
        {
            "appleprores422highquality": "",
            "name": "Apple ProRes 422 High Quality",
            "id": "fdd000403",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000403.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000403.xml"
            }
        },
        {
            "appleprores422highquality": "",
            "name": "Apple ProRes 422 High Quality",
            "id": "fdd000403",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000403.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000403.xml"
            }
        },
        {
            "appleprores422highquality": "",
            "name": "Apple ProRes 422 High Quality",
            "id": "fdd000403",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000403.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000403.xml"
            }
        },
        {
            "appleprores422lt": "",
            "name": "Apple ProRes 422 LT",
            "id": "fdd000404",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000404.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000404.xml"
            }
        },
        {
            "appleprores422lt": "",
            "name": "Apple ProRes 422 LT",
            "id": "fdd000404",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000404.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000404.xml"
            }
        },
        {
            "appleprores422lt": "",
            "name": "Apple ProRes 422 LT",
            "id": "fdd000404",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000404.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000404.xml"
            }
        },
        {
            "appleprores422lt": "",
            "name": "Apple ProRes 422 LT",
            "id": "fdd000404",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000404.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000404.xml"
            }
        },
        {
            "appleprores422lt": "",
            "name": "Apple ProRes 422 LT",
            "id": "fdd000404",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000404.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000404.xml"
            }
        },
        {
            "appleprores422lt": "",
            "name": "Apple ProRes 422 LT",
            "id": "fdd000404",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000404.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000404.xml"
            }
        },
        {
            "appleprores422lt": "",
            "name": "Apple ProRes 422 LT",
            "id": "fdd000404",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000404.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000404.xml"
            }
        },
        {
            "appleprores422lt": "",
            "name": "Apple ProRes 422 LT",
            "id": "fdd000404",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000404.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000404.xml"
            }
        },
        {
            "appleprores422proxy": "",
            "name": "Apple ProRes 422 Proxy",
            "id": "fdd000405",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000405.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000405.xml"
            }
        },
        {
            "appleprores422proxy": "",
            "name": "Apple ProRes 422 Proxy",
            "id": "fdd000405",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000405.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000405.xml"
            }
        },
        {
            "appleprores422proxy": "",
            "name": "Apple ProRes 422 Proxy",
            "id": "fdd000405",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000405.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000405.xml"
            }
        },
        {
            "appleprores422proxy": "",
            "name": "Apple ProRes 422 Proxy",
            "id": "fdd000405",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000405.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000405.xml"
            }
        },
        {
            "appleprores422proxy": "",
            "name": "Apple ProRes 422 Proxy",
            "id": "fdd000405",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000405.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000405.xml"
            }
        },
        {
            "appleprores422proxy": "",
            "name": "Apple ProRes 422 Proxy",
            "id": "fdd000405",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000405.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000405.xml"
            }
        },
        {
            "appleprores422proxy": "",
            "name": "Apple ProRes 422 Proxy",
            "id": "fdd000405",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000405.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000405.xml"
            }
        },
        {
            "appleprores422proxy": "",
            "name": "Apple ProRes 422 Proxy",
            "id": "fdd000405",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000405.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000405.xml"
            }
        },
        {
            "expertwitnessdiskimageformat(ewf)family": "",
            "name": "Expert Witness Disk Image Format (EWF) Family",
            "id": "fdd000406",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000406.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000406.xml"
            }
        },
        {
            "expertwitnessdiskimageformat(ewf)family": "",
            "name": "Expert Witness Disk Image Format (EWF) Family",
            "id": "fdd000406",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000406.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000406.xml"
            }
        },
        {
            "expertwitnessdiskimageformat(ewf)family": "",
            "name": "Expert Witness Disk Image Format (EWF) Family",
            "id": "fdd000406",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000406.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000406.xml"
            }
        },
        {
            "expertwitnessdiskimageformat(ewf)family": "",
            "name": "Expert Witness Disk Image Format (EWF) Family",
            "id": "fdd000406",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000406.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000406.xml"
            }
        },
        {
            "expertwitnessdiskimageformat(ewf)family": "",
            "name": "Expert Witness Disk Image Format (EWF) Family",
            "id": "fdd000406",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000406.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000406.xml"
            }
        },
        {
            "expertwitnessdiskimageformat(ewf)family": "",
            "name": "Expert Witness Disk Image Format (EWF) Family",
            "id": "fdd000406",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000406.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000406.xml"
            }
        },
        {
            "expertwitnessdiskimageformat(ewf)family": "",
            "name": "Expert Witness Disk Image Format (EWF) Family",
            "id": "fdd000406",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000406.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000406.xml"
            }
        },
        {
            "expertwitnessdiskimageformat(ewf)family": "",
            "name": "Expert Witness Disk Image Format (EWF) Family",
            "id": "fdd000406",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000406.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000406.xml"
            }
        },
        {
            "expertwitnessdiskimage,asrsmart": "",
            "name": "Expert Witness Disk Image, ASR SMART",
            "id": "fdd000407",
            "filenamepattern": [
                {
                    "value": "*.s01",
                    "url": "https://www.digipres.org/formats/extensions/#*.s01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000407.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000407.xml"
            }
        },
        {
            "expertwitnessdiskimage,asrsmart": "",
            "name": "Expert Witness Disk Image, ASR SMART",
            "id": "fdd000407",
            "filenamepattern": [
                {
                    "value": "*.s01",
                    "url": "https://www.digipres.org/formats/extensions/#*.s01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000407.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000407.xml"
            }
        },
        {
            "expertwitnessdiskimage,asrsmart": "",
            "name": "Expert Witness Disk Image, ASR SMART",
            "id": "fdd000407",
            "filenamepattern": [
                {
                    "value": "*.s01",
                    "url": "https://www.digipres.org/formats/extensions/#*.s01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000407.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000407.xml"
            }
        },
        {
            "expertwitnessdiskimage,asrsmart": "",
            "name": "Expert Witness Disk Image, ASR SMART",
            "id": "fdd000407",
            "filenamepattern": [
                {
                    "value": "*.s01",
                    "url": "https://www.digipres.org/formats/extensions/#*.s01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000407.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000407.xml"
            }
        },
        {
            "expertwitnessdiskimage,asrsmart": "",
            "name": "Expert Witness Disk Image, ASR SMART",
            "id": "fdd000407",
            "filenamepattern": [
                {
                    "value": "*.s01",
                    "url": "https://www.digipres.org/formats/extensions/#*.s01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000407.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000407.xml"
            }
        },
        {
            "expertwitnessdiskimage,asrsmart": "",
            "name": "Expert Witness Disk Image, ASR SMART",
            "id": "fdd000407",
            "filenamepattern": [
                {
                    "value": "*.s01",
                    "url": "https://www.digipres.org/formats/extensions/#*.s01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000407.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000407.xml"
            }
        },
        {
            "expertwitnessdiskimage,asrsmart": "",
            "name": "Expert Witness Disk Image, ASR SMART",
            "id": "fdd000407",
            "filenamepattern": [
                {
                    "value": "*.s01",
                    "url": "https://www.digipres.org/formats/extensions/#*.s01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000407.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000407.xml"
            }
        },
        {
            "expertwitnessdiskimage,asrsmart": "",
            "name": "Expert Witness Disk Image, ASR SMART",
            "id": "fdd000407",
            "filenamepattern": [
                {
                    "value": "*.s01",
                    "url": "https://www.digipres.org/formats/extensions/#*.s01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000407.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000407.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasee01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase E01 Bitstream",
            "id": "fdd000408",
            "filenamepattern": [
                {
                    "value": "*.E01",
                    "url": "https://www.digipres.org/formats/extensions/#*.e01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000408.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000408.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasee01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase E01 Bitstream",
            "id": "fdd000408",
            "filenamepattern": [
                {
                    "value": "*.E01",
                    "url": "https://www.digipres.org/formats/extensions/#*.e01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000408.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000408.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasee01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase E01 Bitstream",
            "id": "fdd000408",
            "filenamepattern": [
                {
                    "value": "*.E01",
                    "url": "https://www.digipres.org/formats/extensions/#*.e01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000408.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000408.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasee01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase E01 Bitstream",
            "id": "fdd000408",
            "filenamepattern": [
                {
                    "value": "*.E01",
                    "url": "https://www.digipres.org/formats/extensions/#*.e01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000408.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000408.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasee01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase E01 Bitstream",
            "id": "fdd000408",
            "filenamepattern": [
                {
                    "value": "*.E01",
                    "url": "https://www.digipres.org/formats/extensions/#*.e01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000408.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000408.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasee01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase E01 Bitstream",
            "id": "fdd000408",
            "filenamepattern": [
                {
                    "value": "*.E01",
                    "url": "https://www.digipres.org/formats/extensions/#*.e01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000408.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000408.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasee01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase E01 Bitstream",
            "id": "fdd000408",
            "filenamepattern": [
                {
                    "value": "*.E01",
                    "url": "https://www.digipres.org/formats/extensions/#*.e01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000408.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000408.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasee01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase E01 Bitstream",
            "id": "fdd000408",
            "filenamepattern": [
                {
                    "value": "*.E01",
                    "url": "https://www.digipres.org/formats/extensions/#*.e01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000408.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000408.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasel01logical": "",
            "name": "Expert Witness Disk Image, EnCase L01 Logical",
            "id": "fdd000409",
            "filenamepattern": [
                {
                    "value": "*.L01",
                    "url": "https://www.digipres.org/formats/extensions/#*.l01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000409.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000409.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasel01logical": "",
            "name": "Expert Witness Disk Image, EnCase L01 Logical",
            "id": "fdd000409",
            "filenamepattern": [
                {
                    "value": "*.L01",
                    "url": "https://www.digipres.org/formats/extensions/#*.l01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000409.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000409.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasel01logical": "",
            "name": "Expert Witness Disk Image, EnCase L01 Logical",
            "id": "fdd000409",
            "filenamepattern": [
                {
                    "value": "*.L01",
                    "url": "https://www.digipres.org/formats/extensions/#*.l01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000409.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000409.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasel01logical": "",
            "name": "Expert Witness Disk Image, EnCase L01 Logical",
            "id": "fdd000409",
            "filenamepattern": [
                {
                    "value": "*.L01",
                    "url": "https://www.digipres.org/formats/extensions/#*.l01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000409.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000409.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasel01logical": "",
            "name": "Expert Witness Disk Image, EnCase L01 Logical",
            "id": "fdd000409",
            "filenamepattern": [
                {
                    "value": "*.L01",
                    "url": "https://www.digipres.org/formats/extensions/#*.l01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000409.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000409.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasel01logical": "",
            "name": "Expert Witness Disk Image, EnCase L01 Logical",
            "id": "fdd000409",
            "filenamepattern": [
                {
                    "value": "*.L01",
                    "url": "https://www.digipres.org/formats/extensions/#*.l01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000409.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000409.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasel01logical": "",
            "name": "Expert Witness Disk Image, EnCase L01 Logical",
            "id": "fdd000409",
            "filenamepattern": [
                {
                    "value": "*.L01",
                    "url": "https://www.digipres.org/formats/extensions/#*.l01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000409.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000409.xml"
            }
        },
        {
            "expertwitnessdiskimage,encasel01logical": "",
            "name": "Expert Witness Disk Image, EnCase L01 Logical",
            "id": "fdd000409",
            "filenamepattern": [
                {
                    "value": "*.L01",
                    "url": "https://www.digipres.org/formats/extensions/#*.l01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000409.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000409.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaseex01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase Ex01 Bitstream",
            "id": "fdd000410",
            "filenamepattern": [
                {
                    "value": "*.Ex01",
                    "url": "https://www.digipres.org/formats/extensions/#*.ex01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000410.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000410.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaseex01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase Ex01 Bitstream",
            "id": "fdd000410",
            "filenamepattern": [
                {
                    "value": "*.Ex01",
                    "url": "https://www.digipres.org/formats/extensions/#*.ex01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000410.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000410.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaseex01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase Ex01 Bitstream",
            "id": "fdd000410",
            "filenamepattern": [
                {
                    "value": "*.Ex01",
                    "url": "https://www.digipres.org/formats/extensions/#*.ex01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000410.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000410.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaseex01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase Ex01 Bitstream",
            "id": "fdd000410",
            "filenamepattern": [
                {
                    "value": "*.Ex01",
                    "url": "https://www.digipres.org/formats/extensions/#*.ex01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000410.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000410.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaseex01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase Ex01 Bitstream",
            "id": "fdd000410",
            "filenamepattern": [
                {
                    "value": "*.Ex01",
                    "url": "https://www.digipres.org/formats/extensions/#*.ex01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000410.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000410.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaseex01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase Ex01 Bitstream",
            "id": "fdd000410",
            "filenamepattern": [
                {
                    "value": "*.Ex01",
                    "url": "https://www.digipres.org/formats/extensions/#*.ex01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000410.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000410.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaseex01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase Ex01 Bitstream",
            "id": "fdd000410",
            "filenamepattern": [
                {
                    "value": "*.Ex01",
                    "url": "https://www.digipres.org/formats/extensions/#*.ex01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000410.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000410.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaseex01bitstream": "",
            "name": "Expert Witness Disk Image, EnCase Ex01 Bitstream",
            "id": "fdd000410",
            "filenamepattern": [
                {
                    "value": "*.Ex01",
                    "url": "https://www.digipres.org/formats/extensions/#*.ex01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000410.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000410.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaselx01logical": "",
            "name": "Expert Witness Disk Image, EnCase Lx01 Logical",
            "id": "fdd000411",
            "filenamepattern": [
                {
                    "value": "*.Lx01",
                    "url": "https://www.digipres.org/formats/extensions/#*.lx01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000411.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000411.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaselx01logical": "",
            "name": "Expert Witness Disk Image, EnCase Lx01 Logical",
            "id": "fdd000411",
            "filenamepattern": [
                {
                    "value": "*.Lx01",
                    "url": "https://www.digipres.org/formats/extensions/#*.lx01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000411.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000411.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaselx01logical": "",
            "name": "Expert Witness Disk Image, EnCase Lx01 Logical",
            "id": "fdd000411",
            "filenamepattern": [
                {
                    "value": "*.Lx01",
                    "url": "https://www.digipres.org/formats/extensions/#*.lx01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000411.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000411.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaselx01logical": "",
            "name": "Expert Witness Disk Image, EnCase Lx01 Logical",
            "id": "fdd000411",
            "filenamepattern": [
                {
                    "value": "*.Lx01",
                    "url": "https://www.digipres.org/formats/extensions/#*.lx01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000411.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000411.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaselx01logical": "",
            "name": "Expert Witness Disk Image, EnCase Lx01 Logical",
            "id": "fdd000411",
            "filenamepattern": [
                {
                    "value": "*.Lx01",
                    "url": "https://www.digipres.org/formats/extensions/#*.lx01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000411.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000411.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaselx01logical": "",
            "name": "Expert Witness Disk Image, EnCase Lx01 Logical",
            "id": "fdd000411",
            "filenamepattern": [
                {
                    "value": "*.Lx01",
                    "url": "https://www.digipres.org/formats/extensions/#*.lx01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000411.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000411.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaselx01logical": "",
            "name": "Expert Witness Disk Image, EnCase Lx01 Logical",
            "id": "fdd000411",
            "filenamepattern": [
                {
                    "value": "*.Lx01",
                    "url": "https://www.digipres.org/formats/extensions/#*.lx01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000411.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000411.xml"
            }
        },
        {
            "expertwitnessdiskimage,encaselx01logical": "",
            "name": "Expert Witness Disk Image, EnCase Lx01 Logical",
            "id": "fdd000411",
            "filenamepattern": [
                {
                    "value": "*.Lx01",
                    "url": "https://www.digipres.org/formats/extensions/#*.lx01"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000411.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000411.xml"
            }
        },
        {
            "advancedforensicformatdiskimage,affversion1.0": "",
            "name": "Advanced Forensic Format Disk Image, AFF Version 1.0",
            "id": "fdd000412",
            "filenamepattern": [
                {
                    "value": "*.AFF",
                    "url": "https://www.digipres.org/formats/extensions/#*.aff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000412.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000412.xml"
            }
        },
        {
            "advancedforensicformatdiskimage,affversion1.0": "",
            "name": "Advanced Forensic Format Disk Image, AFF Version 1.0",
            "id": "fdd000412",
            "filenamepattern": [
                {
                    "value": "*.AFF",
                    "url": "https://www.digipres.org/formats/extensions/#*.aff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000412.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000412.xml"
            }
        },
        {
            "advancedforensicformatdiskimage,affversion1.0": "",
            "name": "Advanced Forensic Format Disk Image, AFF Version 1.0",
            "id": "fdd000412",
            "filenamepattern": [
                {
                    "value": "*.AFF",
                    "url": "https://www.digipres.org/formats/extensions/#*.aff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000412.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000412.xml"
            }
        },
        {
            "advancedforensicformatdiskimage,affversion1.0": "",
            "name": "Advanced Forensic Format Disk Image, AFF Version 1.0",
            "id": "fdd000412",
            "filenamepattern": [
                {
                    "value": "*.AFF",
                    "url": "https://www.digipres.org/formats/extensions/#*.aff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000412.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000412.xml"
            }
        },
        {
            "advancedforensicformatdiskimage,affversion1.0": "",
            "name": "Advanced Forensic Format Disk Image, AFF Version 1.0",
            "id": "fdd000412",
            "filenamepattern": [
                {
                    "value": "*.AFF",
                    "url": "https://www.digipres.org/formats/extensions/#*.aff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000412.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000412.xml"
            }
        },
        {
            "advancedforensicformatdiskimage,affversion1.0": "",
            "name": "Advanced Forensic Format Disk Image, AFF Version 1.0",
            "id": "fdd000412",
            "filenamepattern": [
                {
                    "value": "*.AFF",
                    "url": "https://www.digipres.org/formats/extensions/#*.aff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000412.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000412.xml"
            }
        },
        {
            "advancedforensicformatdiskimage,affversion1.0": "",
            "name": "Advanced Forensic Format Disk Image, AFF Version 1.0",
            "id": "fdd000412",
            "filenamepattern": [
                {
                    "value": "*.AFF",
                    "url": "https://www.digipres.org/formats/extensions/#*.aff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000412.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000412.xml"
            }
        },
        {
            "advancedforensicformatdiskimage,affversion1.0": "",
            "name": "Advanced Forensic Format Disk Image, AFF Version 1.0",
            "id": "fdd000412",
            "filenamepattern": [
                {
                    "value": "*.AFF",
                    "url": "https://www.digipres.org/formats/extensions/#*.aff"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000412.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000412.xml"
            }
        },
        {
            "advancedforensicframeworkdiskimage,affversion4(aff4)": "",
            "name": "Advanced Forensic Framework Disk Image, AFF Version 4 (AFF4)",
            "id": "fdd000413",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000413.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000413.xml"
            }
        },
        {
            "advancedforensicframeworkdiskimage,affversion4(aff4)": "",
            "name": "Advanced Forensic Framework Disk Image, AFF Version 4 (AFF4)",
            "id": "fdd000413",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000413.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000413.xml"
            }
        },
        {
            "advancedforensicframeworkdiskimage,affversion4(aff4)": "",
            "name": "Advanced Forensic Framework Disk Image, AFF Version 4 (AFF4)",
            "id": "fdd000413",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000413.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000413.xml"
            }
        },
        {
            "advancedforensicframeworkdiskimage,affversion4(aff4)": "",
            "name": "Advanced Forensic Framework Disk Image, AFF Version 4 (AFF4)",
            "id": "fdd000413",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000413.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000413.xml"
            }
        },
        {
            "advancedforensicframeworkdiskimage,affversion4(aff4)": "",
            "name": "Advanced Forensic Framework Disk Image, AFF Version 4 (AFF4)",
            "id": "fdd000413",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000413.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000413.xml"
            }
        },
        {
            "advancedforensicframeworkdiskimage,affversion4(aff4)": "",
            "name": "Advanced Forensic Framework Disk Image, AFF Version 4 (AFF4)",
            "id": "fdd000413",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000413.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000413.xml"
            }
        },
        {
            "advancedforensicframeworkdiskimage,affversion4(aff4)": "",
            "name": "Advanced Forensic Framework Disk Image, AFF Version 4 (AFF4)",
            "id": "fdd000413",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000413.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000413.xml"
            }
        },
        {
            "advancedforensicframeworkdiskimage,affversion4(aff4)": "",
            "name": "Advanced Forensic Framework Disk Image, AFF Version 4 (AFF4)",
            "id": "fdd000413",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000413.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000413.xml"
            }
        },
        {
            "las(laser)fileformat,version1.4": "",
            "name": "LAS (LASer) File Format, Version 1.4",
            "id": "fdd000418",
            "filenamepattern": [
                {
                    "value": "*.las",
                    "url": "https://www.digipres.org/formats/extensions/#*.las"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.las",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.las"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000418.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000418.xml"
            }
        },
        {
            "las(laser)fileformat,version1.4": "",
            "name": "LAS (LASer) File Format, Version 1.4",
            "id": "fdd000418",
            "filenamepattern": [
                {
                    "value": "*.las",
                    "url": "https://www.digipres.org/formats/extensions/#*.las"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.las",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.las"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000418.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000418.xml"
            }
        },
        {
            "las(laser)fileformat,version1.4": "",
            "name": "LAS (LASer) File Format, Version 1.4",
            "id": "fdd000418",
            "filenamepattern": [
                {
                    "value": "*.las",
                    "url": "https://www.digipres.org/formats/extensions/#*.las"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.las",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.las"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000418.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000418.xml"
            }
        },
        {
            "las(laser)fileformat,version1.4": "",
            "name": "LAS (LASer) File Format, Version 1.4",
            "id": "fdd000418",
            "filenamepattern": [
                {
                    "value": "*.las",
                    "url": "https://www.digipres.org/formats/extensions/#*.las"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.las",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.las"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000418.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000418.xml"
            }
        },
        {
            "las(laser)fileformat,version1.4": "",
            "name": "LAS (LASer) File Format, Version 1.4",
            "id": "fdd000418",
            "filenamepattern": [
                {
                    "value": "*.las",
                    "url": "https://www.digipres.org/formats/extensions/#*.las"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.las",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.las"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000418.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000418.xml"
            }
        },
        {
            "las(laser)fileformat,version1.4": "",
            "name": "LAS (LASer) File Format, Version 1.4",
            "id": "fdd000418",
            "filenamepattern": [
                {
                    "value": "*.las",
                    "url": "https://www.digipres.org/formats/extensions/#*.las"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.las",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.las"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000418.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000418.xml"
            }
        },
        {
            "las(laser)fileformat,version1.4": "",
            "name": "LAS (LASer) File Format, Version 1.4",
            "id": "fdd000418",
            "filenamepattern": [
                {
                    "value": "*.las",
                    "url": "https://www.digipres.org/formats/extensions/#*.las"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.las",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.las"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000418.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000418.xml"
            }
        },
        {
            "las(laser)fileformat,version1.4": "",
            "name": "LAS (LASer) File Format, Version 1.4",
            "id": "fdd000418",
            "filenamepattern": [
                {
                    "value": "*.las",
                    "url": "https://www.digipres.org/formats/extensions/#*.las"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.las",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.las"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000418.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000418.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc),version1.0": "",
            "name": "GeoPackage Encoding Standard (OGC), version 1.0",
            "id": "fdd000419",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000419.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000419.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc),version1.0": "",
            "name": "GeoPackage Encoding Standard (OGC), version 1.0",
            "id": "fdd000419",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000419.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000419.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc),version1.0": "",
            "name": "GeoPackage Encoding Standard (OGC), version 1.0",
            "id": "fdd000419",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000419.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000419.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc),version1.0": "",
            "name": "GeoPackage Encoding Standard (OGC), version 1.0",
            "id": "fdd000419",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000419.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000419.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc),version1.0": "",
            "name": "GeoPackage Encoding Standard (OGC), version 1.0",
            "id": "fdd000419",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000419.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000419.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc),version1.0": "",
            "name": "GeoPackage Encoding Standard (OGC), version 1.0",
            "id": "fdd000419",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000419.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000419.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc),version1.0": "",
            "name": "GeoPackage Encoding Standard (OGC), version 1.0",
            "id": "fdd000419",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000419.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000419.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc),version1.0": "",
            "name": "GeoPackage Encoding Standard (OGC), version 1.0",
            "id": "fdd000419",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000419.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000419.xml"
            }
        },
        {
            "erdasimagine(img)format": "",
            "name": "ERDAS IMAGINE (IMG) Format",
            "id": "fdd000420",
            "filenamepattern": [
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.ige",
                    "url": "https://www.digipres.org/formats/extensions/#*.ige"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-erdas-hfa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-erdas-hfa"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000420.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000420.xml"
            }
        },
        {
            "erdasimagine(img)format": "",
            "name": "ERDAS IMAGINE (IMG) Format",
            "id": "fdd000420",
            "filenamepattern": [
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.ige",
                    "url": "https://www.digipres.org/formats/extensions/#*.ige"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-erdas-hfa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-erdas-hfa"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000420.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000420.xml"
            }
        },
        {
            "erdasimagine(img)format": "",
            "name": "ERDAS IMAGINE (IMG) Format",
            "id": "fdd000420",
            "filenamepattern": [
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.ige",
                    "url": "https://www.digipres.org/formats/extensions/#*.ige"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-erdas-hfa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-erdas-hfa"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000420.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000420.xml"
            }
        },
        {
            "erdasimagine(img)format": "",
            "name": "ERDAS IMAGINE (IMG) Format",
            "id": "fdd000420",
            "filenamepattern": [
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.ige",
                    "url": "https://www.digipres.org/formats/extensions/#*.ige"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-erdas-hfa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-erdas-hfa"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000420.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000420.xml"
            }
        },
        {
            "erdasimagine(img)format": "",
            "name": "ERDAS IMAGINE (IMG) Format",
            "id": "fdd000420",
            "filenamepattern": [
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.ige",
                    "url": "https://www.digipres.org/formats/extensions/#*.ige"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-erdas-hfa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-erdas-hfa"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000420.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000420.xml"
            }
        },
        {
            "erdasimagine(img)format": "",
            "name": "ERDAS IMAGINE (IMG) Format",
            "id": "fdd000420",
            "filenamepattern": [
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.ige",
                    "url": "https://www.digipres.org/formats/extensions/#*.ige"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-erdas-hfa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-erdas-hfa"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000420.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000420.xml"
            }
        },
        {
            "erdasimagine(img)format": "",
            "name": "ERDAS IMAGINE (IMG) Format",
            "id": "fdd000420",
            "filenamepattern": [
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.ige",
                    "url": "https://www.digipres.org/formats/extensions/#*.ige"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-erdas-hfa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-erdas-hfa"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000420.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000420.xml"
            }
        },
        {
            "erdasimagine(img)format": "",
            "name": "ERDAS IMAGINE (IMG) Format",
            "id": "fdd000420",
            "filenamepattern": [
                {
                    "value": "*.img",
                    "url": "https://www.digipres.org/formats/extensions/#*.img"
                },
                {
                    "value": "*.ige",
                    "url": "https://www.digipres.org/formats/extensions/#*.ige"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-erdas-hfa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-erdas-hfa"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000420.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000420.xml"
            }
        },
        {
            "esriarcinfoasciigrid": "",
            "name": "ESRI ArcInfo ASCII Grid",
            "id": "fdd000421",
            "filenamepattern": [
                {
                    "value": "*.asc",
                    "url": "https://www.digipres.org/formats/extensions/#*.asc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000421.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000421.xml"
            }
        },
        {
            "esriarcinfoasciigrid": "",
            "name": "ESRI ArcInfo ASCII Grid",
            "id": "fdd000421",
            "filenamepattern": [
                {
                    "value": "*.asc",
                    "url": "https://www.digipres.org/formats/extensions/#*.asc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000421.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000421.xml"
            }
        },
        {
            "esriarcinfoasciigrid": "",
            "name": "ESRI ArcInfo ASCII Grid",
            "id": "fdd000421",
            "filenamepattern": [
                {
                    "value": "*.asc",
                    "url": "https://www.digipres.org/formats/extensions/#*.asc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000421.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000421.xml"
            }
        },
        {
            "esriarcinfoasciigrid": "",
            "name": "ESRI ArcInfo ASCII Grid",
            "id": "fdd000421",
            "filenamepattern": [
                {
                    "value": "*.asc",
                    "url": "https://www.digipres.org/formats/extensions/#*.asc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000421.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000421.xml"
            }
        },
        {
            "esriarcinfoasciigrid": "",
            "name": "ESRI ArcInfo ASCII Grid",
            "id": "fdd000421",
            "filenamepattern": [
                {
                    "value": "*.asc",
                    "url": "https://www.digipres.org/formats/extensions/#*.asc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000421.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000421.xml"
            }
        },
        {
            "esriarcinfoasciigrid": "",
            "name": "ESRI ArcInfo ASCII Grid",
            "id": "fdd000421",
            "filenamepattern": [
                {
                    "value": "*.asc",
                    "url": "https://www.digipres.org/formats/extensions/#*.asc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000421.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000421.xml"
            }
        },
        {
            "esriarcinfoasciigrid": "",
            "name": "ESRI ArcInfo ASCII Grid",
            "id": "fdd000421",
            "filenamepattern": [
                {
                    "value": "*.asc",
                    "url": "https://www.digipres.org/formats/extensions/#*.asc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000421.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000421.xml"
            }
        },
        {
            "esriarcinfoasciigrid": "",
            "name": "ESRI ArcInfo ASCII Grid",
            "id": "fdd000421",
            "filenamepattern": [
                {
                    "value": "*.asc",
                    "url": "https://www.digipres.org/formats/extensions/#*.asc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000421.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000421.xml"
            }
        },
        {
            "esrigridfloatoutputfile": "",
            "name": "ESRI GridFloat Output File",
            "id": "fdd000422",
            "filenamepattern": [
                {
                    "value": "*.hdr",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdr"
                },
                {
                    "value": "*.flt",
                    "url": "https://www.digipres.org/formats/extensions/#*.flt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000422.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000422.xml"
            }
        },
        {
            "esrigridfloatoutputfile": "",
            "name": "ESRI GridFloat Output File",
            "id": "fdd000422",
            "filenamepattern": [
                {
                    "value": "*.hdr",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdr"
                },
                {
                    "value": "*.flt",
                    "url": "https://www.digipres.org/formats/extensions/#*.flt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000422.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000422.xml"
            }
        },
        {
            "esrigridfloatoutputfile": "",
            "name": "ESRI GridFloat Output File",
            "id": "fdd000422",
            "filenamepattern": [
                {
                    "value": "*.hdr",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdr"
                },
                {
                    "value": "*.flt",
                    "url": "https://www.digipres.org/formats/extensions/#*.flt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000422.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000422.xml"
            }
        },
        {
            "esrigridfloatoutputfile": "",
            "name": "ESRI GridFloat Output File",
            "id": "fdd000422",
            "filenamepattern": [
                {
                    "value": "*.hdr",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdr"
                },
                {
                    "value": "*.flt",
                    "url": "https://www.digipres.org/formats/extensions/#*.flt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000422.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000422.xml"
            }
        },
        {
            "esrigridfloatoutputfile": "",
            "name": "ESRI GridFloat Output File",
            "id": "fdd000422",
            "filenamepattern": [
                {
                    "value": "*.hdr",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdr"
                },
                {
                    "value": "*.flt",
                    "url": "https://www.digipres.org/formats/extensions/#*.flt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000422.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000422.xml"
            }
        },
        {
            "esrigridfloatoutputfile": "",
            "name": "ESRI GridFloat Output File",
            "id": "fdd000422",
            "filenamepattern": [
                {
                    "value": "*.hdr",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdr"
                },
                {
                    "value": "*.flt",
                    "url": "https://www.digipres.org/formats/extensions/#*.flt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000422.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000422.xml"
            }
        },
        {
            "esrigridfloatoutputfile": "",
            "name": "ESRI GridFloat Output File",
            "id": "fdd000422",
            "filenamepattern": [
                {
                    "value": "*.hdr",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdr"
                },
                {
                    "value": "*.flt",
                    "url": "https://www.digipres.org/formats/extensions/#*.flt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000422.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000422.xml"
            }
        },
        {
            "esrigridfloatoutputfile": "",
            "name": "ESRI GridFloat Output File",
            "id": "fdd000422",
            "filenamepattern": [
                {
                    "value": "*.hdr",
                    "url": "https://www.digipres.org/formats/extensions/#*.hdr"
                },
                {
                    "value": "*.flt",
                    "url": "https://www.digipres.org/formats/extensions/#*.flt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000422.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000422.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Package Format, ODF 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000424",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000424.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000424.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Package Format, ODF 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000424",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000424.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000424.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Package Format, ODF 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000424",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000424.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000424.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Package Format, ODF 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000424",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000424.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000424.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Package Format, ODF 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000424",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000424.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000424.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Package Format, ODF 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000424",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000424.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000424.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Package Format, ODF 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000424",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000424.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000424.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Package Format, ODF 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000424",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000424.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000424.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.2,part3:packages,iso263003:2015": "",
            "name": "OpenDocument Package Format, ODF 1.2, part 3: Packages,  ISO 26300-3:2015",
            "id": "fdd000425",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000425.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000425.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.2,part3:packages,iso263003:2015": "",
            "name": "OpenDocument Package Format, ODF 1.2, part 3: Packages,  ISO 26300-3:2015",
            "id": "fdd000425",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000425.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000425.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.2,part3:packages,iso263003:2015": "",
            "name": "OpenDocument Package Format, ODF 1.2, part 3: Packages,  ISO 26300-3:2015",
            "id": "fdd000425",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000425.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000425.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.2,part3:packages,iso263003:2015": "",
            "name": "OpenDocument Package Format, ODF 1.2, part 3: Packages,  ISO 26300-3:2015",
            "id": "fdd000425",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000425.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000425.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.2,part3:packages,iso263003:2015": "",
            "name": "OpenDocument Package Format, ODF 1.2, part 3: Packages,  ISO 26300-3:2015",
            "id": "fdd000425",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000425.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000425.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.2,part3:packages,iso263003:2015": "",
            "name": "OpenDocument Package Format, ODF 1.2, part 3: Packages,  ISO 26300-3:2015",
            "id": "fdd000425",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000425.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000425.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.2,part3:packages,iso263003:2015": "",
            "name": "OpenDocument Package Format, ODF 1.2, part 3: Packages,  ISO 26300-3:2015",
            "id": "fdd000425",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000425.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000425.xml"
            }
        },
        {
            "opendocumentpackageformat,odf1.2,part3:packages,iso263003:2015": "",
            "name": "OpenDocument Package Format, ODF 1.2, part 3: Packages,  ISO 26300-3:2015",
            "id": "fdd000425",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000425.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000425.xml"
            }
        },
        {
            "siard(softwareindependentarchivingofrelationaldatabases)version1.0": "",
            "name": "SIARD (Software Independent Archiving of Relational Databases) Version 1.0",
            "id": "fdd000426",
            "filenamepattern": [
                {
                    "value": "*.siard",
                    "url": "https://www.digipres.org/formats/extensions/#*.siard"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000426.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000426.xml"
            }
        },
        {
            "siard(softwareindependentarchivingofrelationaldatabases)version1.0": "",
            "name": "SIARD (Software Independent Archiving of Relational Databases) Version 1.0",
            "id": "fdd000426",
            "filenamepattern": [
                {
                    "value": "*.siard",
                    "url": "https://www.digipres.org/formats/extensions/#*.siard"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000426.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000426.xml"
            }
        },
        {
            "siard(softwareindependentarchivingofrelationaldatabases)version1.0": "",
            "name": "SIARD (Software Independent Archiving of Relational Databases) Version 1.0",
            "id": "fdd000426",
            "filenamepattern": [
                {
                    "value": "*.siard",
                    "url": "https://www.digipres.org/formats/extensions/#*.siard"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000426.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000426.xml"
            }
        },
        {
            "siard(softwareindependentarchivingofrelationaldatabases)version1.0": "",
            "name": "SIARD (Software Independent Archiving of Relational Databases) Version 1.0",
            "id": "fdd000426",
            "filenamepattern": [
                {
                    "value": "*.siard",
                    "url": "https://www.digipres.org/formats/extensions/#*.siard"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000426.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000426.xml"
            }
        },
        {
            "siard(softwareindependentarchivingofrelationaldatabases)version1.0": "",
            "name": "SIARD (Software Independent Archiving of Relational Databases) Version 1.0",
            "id": "fdd000426",
            "filenamepattern": [
                {
                    "value": "*.siard",
                    "url": "https://www.digipres.org/formats/extensions/#*.siard"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000426.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000426.xml"
            }
        },
        {
            "siard(softwareindependentarchivingofrelationaldatabases)version1.0": "",
            "name": "SIARD (Software Independent Archiving of Relational Databases) Version 1.0",
            "id": "fdd000426",
            "filenamepattern": [
                {
                    "value": "*.siard",
                    "url": "https://www.digipres.org/formats/extensions/#*.siard"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000426.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000426.xml"
            }
        },
        {
            "siard(softwareindependentarchivingofrelationaldatabases)version1.0": "",
            "name": "SIARD (Software Independent Archiving of Relational Databases) Version 1.0",
            "id": "fdd000426",
            "filenamepattern": [
                {
                    "value": "*.siard",
                    "url": "https://www.digipres.org/formats/extensions/#*.siard"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000426.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000426.xml"
            }
        },
        {
            "siard(softwareindependentarchivingofrelationaldatabases)version1.0": "",
            "name": "SIARD (Software Independent Archiving of Relational Databases) Version 1.0",
            "id": "fdd000426",
            "filenamepattern": [
                {
                    "value": "*.siard",
                    "url": "https://www.digipres.org/formats/extensions/#*.siard"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000426.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000426.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000427",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000427.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000427.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000427",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000427.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000427.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000427",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000427.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000427.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000427",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000427.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000427.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000427",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000427.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000427.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000427",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000427.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000427.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000427",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000427.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000427.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.1,iso/iec26300:2006": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.1,  ISO/IEC 26300:2006",
            "id": "fdd000427",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000427.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000427.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.2,iso263001:2015": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000428",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                },
                {
                    "value": "*.odm",
                    "url": "https://www.digipres.org/formats/extensions/#*.odm"
                },
                {
                    "value": "*.ott",
                    "url": "https://www.digipres.org/formats/extensions/#*.ott"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text-master",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-master"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000428.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000428.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.2,iso263001:2015": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000428",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                },
                {
                    "value": "*.odm",
                    "url": "https://www.digipres.org/formats/extensions/#*.odm"
                },
                {
                    "value": "*.ott",
                    "url": "https://www.digipres.org/formats/extensions/#*.ott"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text-master",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-master"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000428.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000428.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.2,iso263001:2015": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000428",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                },
                {
                    "value": "*.odm",
                    "url": "https://www.digipres.org/formats/extensions/#*.odm"
                },
                {
                    "value": "*.ott",
                    "url": "https://www.digipres.org/formats/extensions/#*.ott"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text-master",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-master"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000428.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000428.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.2,iso263001:2015": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000428",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                },
                {
                    "value": "*.odm",
                    "url": "https://www.digipres.org/formats/extensions/#*.odm"
                },
                {
                    "value": "*.ott",
                    "url": "https://www.digipres.org/formats/extensions/#*.ott"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text-master",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-master"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000428.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000428.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.2,iso263001:2015": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000428",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                },
                {
                    "value": "*.odm",
                    "url": "https://www.digipres.org/formats/extensions/#*.odm"
                },
                {
                    "value": "*.ott",
                    "url": "https://www.digipres.org/formats/extensions/#*.ott"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text-master",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-master"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000428.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000428.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.2,iso263001:2015": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000428",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                },
                {
                    "value": "*.odm",
                    "url": "https://www.digipres.org/formats/extensions/#*.odm"
                },
                {
                    "value": "*.ott",
                    "url": "https://www.digipres.org/formats/extensions/#*.ott"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text-master",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-master"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000428.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000428.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.2,iso263001:2015": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000428",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                },
                {
                    "value": "*.odm",
                    "url": "https://www.digipres.org/formats/extensions/#*.odm"
                },
                {
                    "value": "*.ott",
                    "url": "https://www.digipres.org/formats/extensions/#*.ott"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text-master",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-master"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000428.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000428.xml"
            }
        },
        {
            "opendocumenttextdocumentformat(odt),version1.2,iso263001:2015": "",
            "name": "OpenDocument Text Document Format (ODT), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000428",
            "filenamepattern": [
                {
                    "value": "*.odt",
                    "url": "https://www.digipres.org/formats/extensions/#*.odt"
                },
                {
                    "value": "*.odm",
                    "url": "https://www.digipres.org/formats/extensions/#*.odm"
                },
                {
                    "value": "*.ott",
                    "url": "https://www.digipres.org/formats/extensions/#*.ott"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.text-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text"
                },
                {
                    "value": "application/vnd.oasis.opendocument.text-master",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.text-master"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000428.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000428.xml"
            }
        },
        {
            "quicktimevideo,appleprores422codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 422 Codec Family",
            "id": "fdd000429",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000429.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000429.xml"
            }
        },
        {
            "quicktimevideo,appleprores422codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 422 Codec Family",
            "id": "fdd000429",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000429.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000429.xml"
            }
        },
        {
            "quicktimevideo,appleprores422codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 422 Codec Family",
            "id": "fdd000429",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000429.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000429.xml"
            }
        },
        {
            "quicktimevideo,appleprores422codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 422 Codec Family",
            "id": "fdd000429",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000429.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000429.xml"
            }
        },
        {
            "quicktimevideo,appleprores422codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 422 Codec Family",
            "id": "fdd000429",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000429.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000429.xml"
            }
        },
        {
            "quicktimevideo,appleprores422codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 422 Codec Family",
            "id": "fdd000429",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000429.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000429.xml"
            }
        },
        {
            "quicktimevideo,appleprores422codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 422 Codec Family",
            "id": "fdd000429",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000429.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000429.xml"
            }
        },
        {
            "quicktimevideo,appleprores422codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 422 Codec Family",
            "id": "fdd000429",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000429.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000429.xml"
            }
        },
        {
            "cpiminstantmessageformat": "",
            "name": "CPIM Instant Message Format",
            "id": "fdd000430",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/CPIM",
                    "url": "https://www.digipres.org/formats/mime-types/#message/cpim"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000430.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000430.xml"
            }
        },
        {
            "cpiminstantmessageformat": "",
            "name": "CPIM Instant Message Format",
            "id": "fdd000430",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/CPIM",
                    "url": "https://www.digipres.org/formats/mime-types/#message/cpim"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000430.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000430.xml"
            }
        },
        {
            "cpiminstantmessageformat": "",
            "name": "CPIM Instant Message Format",
            "id": "fdd000430",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/CPIM",
                    "url": "https://www.digipres.org/formats/mime-types/#message/cpim"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000430.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000430.xml"
            }
        },
        {
            "cpiminstantmessageformat": "",
            "name": "CPIM Instant Message Format",
            "id": "fdd000430",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/CPIM",
                    "url": "https://www.digipres.org/formats/mime-types/#message/cpim"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000430.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000430.xml"
            }
        },
        {
            "cpiminstantmessageformat": "",
            "name": "CPIM Instant Message Format",
            "id": "fdd000430",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/CPIM",
                    "url": "https://www.digipres.org/formats/mime-types/#message/cpim"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000430.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000430.xml"
            }
        },
        {
            "cpiminstantmessageformat": "",
            "name": "CPIM Instant Message Format",
            "id": "fdd000430",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/CPIM",
                    "url": "https://www.digipres.org/formats/mime-types/#message/cpim"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000430.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000430.xml"
            }
        },
        {
            "cpiminstantmessageformat": "",
            "name": "CPIM Instant Message Format",
            "id": "fdd000430",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/CPIM",
                    "url": "https://www.digipres.org/formats/mime-types/#message/cpim"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000430.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000430.xml"
            }
        },
        {
            "cpiminstantmessageformat": "",
            "name": "CPIM Instant Message Format",
            "id": "fdd000430",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "message/CPIM",
                    "url": "https://www.digipres.org/formats/mime-types/#message/cpim"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000430.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000430.xml"
            }
        },
        {
            "shortmessageservice(sms)messageformat": "",
            "name": "Short Message Service (SMS) Message Format",
            "id": "fdd000431",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000431.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000431.xml"
            }
        },
        {
            "shortmessageservice(sms)messageformat": "",
            "name": "Short Message Service (SMS) Message Format",
            "id": "fdd000431",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000431.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000431.xml"
            }
        },
        {
            "shortmessageservice(sms)messageformat": "",
            "name": "Short Message Service (SMS) Message Format",
            "id": "fdd000431",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000431.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000431.xml"
            }
        },
        {
            "shortmessageservice(sms)messageformat": "",
            "name": "Short Message Service (SMS) Message Format",
            "id": "fdd000431",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000431.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000431.xml"
            }
        },
        {
            "shortmessageservice(sms)messageformat": "",
            "name": "Short Message Service (SMS) Message Format",
            "id": "fdd000431",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000431.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000431.xml"
            }
        },
        {
            "shortmessageservice(sms)messageformat": "",
            "name": "Short Message Service (SMS) Message Format",
            "id": "fdd000431",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000431.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000431.xml"
            }
        },
        {
            "shortmessageservice(sms)messageformat": "",
            "name": "Short Message Service (SMS) Message Format",
            "id": "fdd000431",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000431.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000431.xml"
            }
        },
        {
            "shortmessageservice(sms)messageformat": "",
            "name": "Short Message Service (SMS) Message Format",
            "id": "fdd000431",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000431.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000431.xml"
            }
        },
        {
            "htmlelectroniccalendarandschedulingformat": "",
            "name": "HTML Electronic Calendar and Scheduling Format",
            "id": "fdd000432",
            "filenamepattern": [
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000432.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000432.xml"
            }
        },
        {
            "htmlelectroniccalendarandschedulingformat": "",
            "name": "HTML Electronic Calendar and Scheduling Format",
            "id": "fdd000432",
            "filenamepattern": [
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000432.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000432.xml"
            }
        },
        {
            "htmlelectroniccalendarandschedulingformat": "",
            "name": "HTML Electronic Calendar and Scheduling Format",
            "id": "fdd000432",
            "filenamepattern": [
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000432.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000432.xml"
            }
        },
        {
            "htmlelectroniccalendarandschedulingformat": "",
            "name": "HTML Electronic Calendar and Scheduling Format",
            "id": "fdd000432",
            "filenamepattern": [
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000432.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000432.xml"
            }
        },
        {
            "htmlelectroniccalendarandschedulingformat": "",
            "name": "HTML Electronic Calendar and Scheduling Format",
            "id": "fdd000432",
            "filenamepattern": [
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000432.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000432.xml"
            }
        },
        {
            "htmlelectroniccalendarandschedulingformat": "",
            "name": "HTML Electronic Calendar and Scheduling Format",
            "id": "fdd000432",
            "filenamepattern": [
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000432.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000432.xml"
            }
        },
        {
            "htmlelectroniccalendarandschedulingformat": "",
            "name": "HTML Electronic Calendar and Scheduling Format",
            "id": "fdd000432",
            "filenamepattern": [
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000432.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000432.xml"
            }
        },
        {
            "htmlelectroniccalendarandschedulingformat": "",
            "name": "HTML Electronic Calendar and Scheduling Format",
            "id": "fdd000432",
            "filenamepattern": [
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000432.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000432.xml"
            }
        },
        {
            "lotusnotesstoragefacility": "",
            "name": "Lotus Notes Storage Facility",
            "id": "fdd000433",
            "filenamepattern": [
                {
                    "value": "*.nsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.lotus-notes",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.lotus-notes"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000433.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000433.xml"
            }
        },
        {
            "lotusnotesstoragefacility": "",
            "name": "Lotus Notes Storage Facility",
            "id": "fdd000433",
            "filenamepattern": [
                {
                    "value": "*.nsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.lotus-notes",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.lotus-notes"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000433.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000433.xml"
            }
        },
        {
            "lotusnotesstoragefacility": "",
            "name": "Lotus Notes Storage Facility",
            "id": "fdd000433",
            "filenamepattern": [
                {
                    "value": "*.nsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.lotus-notes",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.lotus-notes"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000433.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000433.xml"
            }
        },
        {
            "lotusnotesstoragefacility": "",
            "name": "Lotus Notes Storage Facility",
            "id": "fdd000433",
            "filenamepattern": [
                {
                    "value": "*.nsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.lotus-notes",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.lotus-notes"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000433.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000433.xml"
            }
        },
        {
            "lotusnotesstoragefacility": "",
            "name": "Lotus Notes Storage Facility",
            "id": "fdd000433",
            "filenamepattern": [
                {
                    "value": "*.nsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.lotus-notes",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.lotus-notes"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000433.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000433.xml"
            }
        },
        {
            "lotusnotesstoragefacility": "",
            "name": "Lotus Notes Storage Facility",
            "id": "fdd000433",
            "filenamepattern": [
                {
                    "value": "*.nsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.lotus-notes",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.lotus-notes"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000433.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000433.xml"
            }
        },
        {
            "lotusnotesstoragefacility": "",
            "name": "Lotus Notes Storage Facility",
            "id": "fdd000433",
            "filenamepattern": [
                {
                    "value": "*.nsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.lotus-notes",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.lotus-notes"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000433.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000433.xml"
            }
        },
        {
            "lotusnotesstoragefacility": "",
            "name": "Lotus Notes Storage Facility",
            "id": "fdd000433",
            "filenamepattern": [
                {
                    "value": "*.nsf",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.lotus-notes",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.lotus-notes"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000433.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000433.xml"
            }
        },
        {
            "opendocumentpresentationdocumentformat(odp),version1.2,iso263001:2015": "",
            "name": "OpenDocument Presentation Document Format (ODP), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000435",
            "filenamepattern": [
                {
                    "value": "*.odp",
                    "url": "https://www.digipres.org/formats/extensions/#*.odp"
                },
                {
                    "value": "*.otp",
                    "url": "https://www.digipres.org/formats/extensions/#*.otp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation"
                },
                {
                    "value": "application/vnd.oasis.opendocument.presentation-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000435.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000435.xml"
            }
        },
        {
            "opendocumentpresentationdocumentformat(odp),version1.2,iso263001:2015": "",
            "name": "OpenDocument Presentation Document Format (ODP), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000435",
            "filenamepattern": [
                {
                    "value": "*.odp",
                    "url": "https://www.digipres.org/formats/extensions/#*.odp"
                },
                {
                    "value": "*.otp",
                    "url": "https://www.digipres.org/formats/extensions/#*.otp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation"
                },
                {
                    "value": "application/vnd.oasis.opendocument.presentation-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000435.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000435.xml"
            }
        },
        {
            "opendocumentpresentationdocumentformat(odp),version1.2,iso263001:2015": "",
            "name": "OpenDocument Presentation Document Format (ODP), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000435",
            "filenamepattern": [
                {
                    "value": "*.odp",
                    "url": "https://www.digipres.org/formats/extensions/#*.odp"
                },
                {
                    "value": "*.otp",
                    "url": "https://www.digipres.org/formats/extensions/#*.otp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation"
                },
                {
                    "value": "application/vnd.oasis.opendocument.presentation-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000435.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000435.xml"
            }
        },
        {
            "opendocumentpresentationdocumentformat(odp),version1.2,iso263001:2015": "",
            "name": "OpenDocument Presentation Document Format (ODP), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000435",
            "filenamepattern": [
                {
                    "value": "*.odp",
                    "url": "https://www.digipres.org/formats/extensions/#*.odp"
                },
                {
                    "value": "*.otp",
                    "url": "https://www.digipres.org/formats/extensions/#*.otp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation"
                },
                {
                    "value": "application/vnd.oasis.opendocument.presentation-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000435.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000435.xml"
            }
        },
        {
            "opendocumentpresentationdocumentformat(odp),version1.2,iso263001:2015": "",
            "name": "OpenDocument Presentation Document Format (ODP), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000435",
            "filenamepattern": [
                {
                    "value": "*.odp",
                    "url": "https://www.digipres.org/formats/extensions/#*.odp"
                },
                {
                    "value": "*.otp",
                    "url": "https://www.digipres.org/formats/extensions/#*.otp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation"
                },
                {
                    "value": "application/vnd.oasis.opendocument.presentation-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000435.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000435.xml"
            }
        },
        {
            "opendocumentpresentationdocumentformat(odp),version1.2,iso263001:2015": "",
            "name": "OpenDocument Presentation Document Format (ODP), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000435",
            "filenamepattern": [
                {
                    "value": "*.odp",
                    "url": "https://www.digipres.org/formats/extensions/#*.odp"
                },
                {
                    "value": "*.otp",
                    "url": "https://www.digipres.org/formats/extensions/#*.otp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation"
                },
                {
                    "value": "application/vnd.oasis.opendocument.presentation-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000435.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000435.xml"
            }
        },
        {
            "opendocumentpresentationdocumentformat(odp),version1.2,iso263001:2015": "",
            "name": "OpenDocument Presentation Document Format (ODP), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000435",
            "filenamepattern": [
                {
                    "value": "*.odp",
                    "url": "https://www.digipres.org/formats/extensions/#*.odp"
                },
                {
                    "value": "*.otp",
                    "url": "https://www.digipres.org/formats/extensions/#*.otp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation"
                },
                {
                    "value": "application/vnd.oasis.opendocument.presentation-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000435.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000435.xml"
            }
        },
        {
            "opendocumentpresentationdocumentformat(odp),version1.2,iso263001:2015": "",
            "name": "OpenDocument Presentation Document Format (ODP), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000435",
            "filenamepattern": [
                {
                    "value": "*.odp",
                    "url": "https://www.digipres.org/formats/extensions/#*.odp"
                },
                {
                    "value": "*.otp",
                    "url": "https://www.digipres.org/formats/extensions/#*.otp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.presentation",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation"
                },
                {
                    "value": "application/vnd.oasis.opendocument.presentation-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.presentation-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000435.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000435.xml"
            }
        },
        {
            "opendocumentdrawingdocumentformat(odg),version1.2,iso263001:2015": "",
            "name": "OpenDocument Drawing Document Format (ODG), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000436",
            "filenamepattern": [
                {
                    "value": "*.odg",
                    "url": "https://www.digipres.org/formats/extensions/#*.odg"
                },
                {
                    "value": "*.otg",
                    "url": "https://www.digipres.org/formats/extensions/#*.otg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.graphics",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics"
                },
                {
                    "value": "application/vnd.oasis.opendocument.graphics-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000436.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000436.xml"
            }
        },
        {
            "opendocumentdrawingdocumentformat(odg),version1.2,iso263001:2015": "",
            "name": "OpenDocument Drawing Document Format (ODG), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000436",
            "filenamepattern": [
                {
                    "value": "*.odg",
                    "url": "https://www.digipres.org/formats/extensions/#*.odg"
                },
                {
                    "value": "*.otg",
                    "url": "https://www.digipres.org/formats/extensions/#*.otg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.graphics",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics"
                },
                {
                    "value": "application/vnd.oasis.opendocument.graphics-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000436.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000436.xml"
            }
        },
        {
            "opendocumentdrawingdocumentformat(odg),version1.2,iso263001:2015": "",
            "name": "OpenDocument Drawing Document Format (ODG), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000436",
            "filenamepattern": [
                {
                    "value": "*.odg",
                    "url": "https://www.digipres.org/formats/extensions/#*.odg"
                },
                {
                    "value": "*.otg",
                    "url": "https://www.digipres.org/formats/extensions/#*.otg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.graphics",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics"
                },
                {
                    "value": "application/vnd.oasis.opendocument.graphics-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000436.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000436.xml"
            }
        },
        {
            "opendocumentdrawingdocumentformat(odg),version1.2,iso263001:2015": "",
            "name": "OpenDocument Drawing Document Format (ODG), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000436",
            "filenamepattern": [
                {
                    "value": "*.odg",
                    "url": "https://www.digipres.org/formats/extensions/#*.odg"
                },
                {
                    "value": "*.otg",
                    "url": "https://www.digipres.org/formats/extensions/#*.otg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.graphics",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics"
                },
                {
                    "value": "application/vnd.oasis.opendocument.graphics-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000436.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000436.xml"
            }
        },
        {
            "opendocumentdrawingdocumentformat(odg),version1.2,iso263001:2015": "",
            "name": "OpenDocument Drawing Document Format (ODG), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000436",
            "filenamepattern": [
                {
                    "value": "*.odg",
                    "url": "https://www.digipres.org/formats/extensions/#*.odg"
                },
                {
                    "value": "*.otg",
                    "url": "https://www.digipres.org/formats/extensions/#*.otg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.graphics",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics"
                },
                {
                    "value": "application/vnd.oasis.opendocument.graphics-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000436.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000436.xml"
            }
        },
        {
            "opendocumentdrawingdocumentformat(odg),version1.2,iso263001:2015": "",
            "name": "OpenDocument Drawing Document Format (ODG), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000436",
            "filenamepattern": [
                {
                    "value": "*.odg",
                    "url": "https://www.digipres.org/formats/extensions/#*.odg"
                },
                {
                    "value": "*.otg",
                    "url": "https://www.digipres.org/formats/extensions/#*.otg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.graphics",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics"
                },
                {
                    "value": "application/vnd.oasis.opendocument.graphics-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000436.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000436.xml"
            }
        },
        {
            "opendocumentdrawingdocumentformat(odg),version1.2,iso263001:2015": "",
            "name": "OpenDocument Drawing Document Format (ODG), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000436",
            "filenamepattern": [
                {
                    "value": "*.odg",
                    "url": "https://www.digipres.org/formats/extensions/#*.odg"
                },
                {
                    "value": "*.otg",
                    "url": "https://www.digipres.org/formats/extensions/#*.otg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.graphics",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics"
                },
                {
                    "value": "application/vnd.oasis.opendocument.graphics-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000436.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000436.xml"
            }
        },
        {
            "opendocumentdrawingdocumentformat(odg),version1.2,iso263001:2015": "",
            "name": "OpenDocument Drawing Document Format (ODG), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000436",
            "filenamepattern": [
                {
                    "value": "*.odg",
                    "url": "https://www.digipres.org/formats/extensions/#*.odg"
                },
                {
                    "value": "*.otg",
                    "url": "https://www.digipres.org/formats/extensions/#*.otg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.graphics",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics"
                },
                {
                    "value": "application/vnd.oasis.opendocument.graphics-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.graphics-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000436.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000436.xml"
            }
        },
        {
            "opendocumentchartdocumentformat(odc),version1.2,iso263001:2015": "",
            "name": "OpenDocument Chart Document Format (ODC), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000437",
            "filenamepattern": [
                {
                    "value": "*.odc",
                    "url": "https://www.digipres.org/formats/extensions/#*.odc"
                },
                {
                    "value": "*.otc",
                    "url": "https://www.digipres.org/formats/extensions/#*.otc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.chart-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.chart",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000437.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000437.xml"
            }
        },
        {
            "opendocumentchartdocumentformat(odc),version1.2,iso263001:2015": "",
            "name": "OpenDocument Chart Document Format (ODC), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000437",
            "filenamepattern": [
                {
                    "value": "*.odc",
                    "url": "https://www.digipres.org/formats/extensions/#*.odc"
                },
                {
                    "value": "*.otc",
                    "url": "https://www.digipres.org/formats/extensions/#*.otc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.chart-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.chart",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000437.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000437.xml"
            }
        },
        {
            "opendocumentchartdocumentformat(odc),version1.2,iso263001:2015": "",
            "name": "OpenDocument Chart Document Format (ODC), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000437",
            "filenamepattern": [
                {
                    "value": "*.odc",
                    "url": "https://www.digipres.org/formats/extensions/#*.odc"
                },
                {
                    "value": "*.otc",
                    "url": "https://www.digipres.org/formats/extensions/#*.otc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.chart-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.chart",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000437.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000437.xml"
            }
        },
        {
            "opendocumentchartdocumentformat(odc),version1.2,iso263001:2015": "",
            "name": "OpenDocument Chart Document Format (ODC), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000437",
            "filenamepattern": [
                {
                    "value": "*.odc",
                    "url": "https://www.digipres.org/formats/extensions/#*.odc"
                },
                {
                    "value": "*.otc",
                    "url": "https://www.digipres.org/formats/extensions/#*.otc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.chart-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.chart",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000437.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000437.xml"
            }
        },
        {
            "opendocumentchartdocumentformat(odc),version1.2,iso263001:2015": "",
            "name": "OpenDocument Chart Document Format (ODC), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000437",
            "filenamepattern": [
                {
                    "value": "*.odc",
                    "url": "https://www.digipres.org/formats/extensions/#*.odc"
                },
                {
                    "value": "*.otc",
                    "url": "https://www.digipres.org/formats/extensions/#*.otc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.chart-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.chart",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000437.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000437.xml"
            }
        },
        {
            "opendocumentchartdocumentformat(odc),version1.2,iso263001:2015": "",
            "name": "OpenDocument Chart Document Format (ODC), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000437",
            "filenamepattern": [
                {
                    "value": "*.odc",
                    "url": "https://www.digipres.org/formats/extensions/#*.odc"
                },
                {
                    "value": "*.otc",
                    "url": "https://www.digipres.org/formats/extensions/#*.otc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.chart-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.chart",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000437.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000437.xml"
            }
        },
        {
            "opendocumentchartdocumentformat(odc),version1.2,iso263001:2015": "",
            "name": "OpenDocument Chart Document Format (ODC), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000437",
            "filenamepattern": [
                {
                    "value": "*.odc",
                    "url": "https://www.digipres.org/formats/extensions/#*.odc"
                },
                {
                    "value": "*.otc",
                    "url": "https://www.digipres.org/formats/extensions/#*.otc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.chart-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.chart",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000437.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000437.xml"
            }
        },
        {
            "opendocumentchartdocumentformat(odc),version1.2,iso263001:2015": "",
            "name": "OpenDocument Chart Document Format (ODC), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000437",
            "filenamepattern": [
                {
                    "value": "*.odc",
                    "url": "https://www.digipres.org/formats/extensions/#*.odc"
                },
                {
                    "value": "*.otc",
                    "url": "https://www.digipres.org/formats/extensions/#*.otc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.chart-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart-template"
                },
                {
                    "value": "application/vnd.oasis.opendocument.chart",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.chart"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000437.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000437.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.1,iso26300:2006": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.1,  ISO 26300:2006",
            "id": "fdd000438",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000438.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000438.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.1,iso26300:2006": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.1,  ISO 26300:2006",
            "id": "fdd000438",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000438.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000438.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.1,iso26300:2006": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.1,  ISO 26300:2006",
            "id": "fdd000438",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000438.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000438.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.1,iso26300:2006": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.1,  ISO 26300:2006",
            "id": "fdd000438",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000438.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000438.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.1,iso26300:2006": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.1,  ISO 26300:2006",
            "id": "fdd000438",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000438.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000438.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.1,iso26300:2006": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.1,  ISO 26300:2006",
            "id": "fdd000438",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000438.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000438.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.1,iso26300:2006": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.1,  ISO 26300:2006",
            "id": "fdd000438",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000438.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000438.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.1,iso26300:2006": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.1,  ISO 26300:2006",
            "id": "fdd000438",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000438.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000438.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.2,iso26300:2015": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.2,  ISO 26300:2015",
            "id": "fdd000439",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000439.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000439.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.2,iso26300:2015": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.2,  ISO 26300:2015",
            "id": "fdd000439",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000439.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000439.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.2,iso26300:2015": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.2,  ISO 26300:2015",
            "id": "fdd000439",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000439.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000439.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.2,iso26300:2015": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.2,  ISO 26300:2015",
            "id": "fdd000439",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000439.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000439.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.2,iso26300:2015": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.2,  ISO 26300:2015",
            "id": "fdd000439",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000439.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000439.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.2,iso26300:2015": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.2,  ISO 26300:2015",
            "id": "fdd000439",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000439.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000439.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.2,iso26300:2015": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.2,  ISO 26300:2015",
            "id": "fdd000439",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000439.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000439.xml"
            }
        },
        {
            "opendocumentspreadsheetdocumentformat(ods),version1.2,iso26300:2015": "",
            "name": "OpenDocument Spreadsheet Document Format (ODS), Version 1.2,  ISO 26300:2015",
            "id": "fdd000439",
            "filenamepattern": [
                {
                    "value": "*.ods",
                    "url": "https://www.digipres.org/formats/extensions/#*.ods"
                },
                {
                    "value": "*.ots",
                    "url": "https://www.digipres.org/formats/extensions/#*.ots"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet"
                },
                {
                    "value": "application/vnd.oasis.opendocument.spreadsheet-template",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.spreadsheet-template"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000439.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000439.xml"
            }
        },
        {
            "matfilelevel5fileformat": "",
            "name": "MAT-File Level 5 File Format",
            "id": "fdd000440",
            "filenamepattern": [
                {
                    "value": "*.mat",
                    "url": "https://www.digipres.org/formats/extensions/#*.mat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/matlab-mat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/matlab-mat"
                },
                {
                    "value": "application/x-matlab-data",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-matlab-data"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000440.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000440.xml"
            }
        },
        {
            "matfilelevel5fileformat": "",
            "name": "MAT-File Level 5 File Format",
            "id": "fdd000440",
            "filenamepattern": [
                {
                    "value": "*.mat",
                    "url": "https://www.digipres.org/formats/extensions/#*.mat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/matlab-mat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/matlab-mat"
                },
                {
                    "value": "application/x-matlab-data",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-matlab-data"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000440.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000440.xml"
            }
        },
        {
            "matfilelevel5fileformat": "",
            "name": "MAT-File Level 5 File Format",
            "id": "fdd000440",
            "filenamepattern": [
                {
                    "value": "*.mat",
                    "url": "https://www.digipres.org/formats/extensions/#*.mat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/matlab-mat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/matlab-mat"
                },
                {
                    "value": "application/x-matlab-data",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-matlab-data"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000440.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000440.xml"
            }
        },
        {
            "matfilelevel5fileformat": "",
            "name": "MAT-File Level 5 File Format",
            "id": "fdd000440",
            "filenamepattern": [
                {
                    "value": "*.mat",
                    "url": "https://www.digipres.org/formats/extensions/#*.mat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/matlab-mat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/matlab-mat"
                },
                {
                    "value": "application/x-matlab-data",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-matlab-data"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000440.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000440.xml"
            }
        },
        {
            "matfilelevel5fileformat": "",
            "name": "MAT-File Level 5 File Format",
            "id": "fdd000440",
            "filenamepattern": [
                {
                    "value": "*.mat",
                    "url": "https://www.digipres.org/formats/extensions/#*.mat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/matlab-mat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/matlab-mat"
                },
                {
                    "value": "application/x-matlab-data",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-matlab-data"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000440.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000440.xml"
            }
        },
        {
            "matfilelevel5fileformat": "",
            "name": "MAT-File Level 5 File Format",
            "id": "fdd000440",
            "filenamepattern": [
                {
                    "value": "*.mat",
                    "url": "https://www.digipres.org/formats/extensions/#*.mat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/matlab-mat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/matlab-mat"
                },
                {
                    "value": "application/x-matlab-data",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-matlab-data"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000440.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000440.xml"
            }
        },
        {
            "matfilelevel5fileformat": "",
            "name": "MAT-File Level 5 File Format",
            "id": "fdd000440",
            "filenamepattern": [
                {
                    "value": "*.mat",
                    "url": "https://www.digipres.org/formats/extensions/#*.mat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/matlab-mat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/matlab-mat"
                },
                {
                    "value": "application/x-matlab-data",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-matlab-data"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000440.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000440.xml"
            }
        },
        {
            "matfilelevel5fileformat": "",
            "name": "MAT-File Level 5 File Format",
            "id": "fdd000440",
            "filenamepattern": [
                {
                    "value": "*.mat",
                    "url": "https://www.digipres.org/formats/extensions/#*.mat"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/matlab-mat",
                    "url": "https://www.digipres.org/formats/mime-types/#application/matlab-mat"
                },
                {
                    "value": "application/x-matlab-data",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-matlab-data"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000440.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000440.xml"
            }
        },
        {
            "opendocumentdatabasefrontenddocumentformat(odb),version1.2,iso263001:2015": "",
            "name": "OpenDocument Database Front End Document Format (ODB), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000441",
            "filenamepattern": [
                {
                    "value": "*.odb",
                    "url": "https://www.digipres.org/formats/extensions/#*.odb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.database",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.database"
                },
                {
                    "value": "application/vnd.oasis.opendocument.base",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.base"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000441.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000441.xml"
            }
        },
        {
            "opendocumentdatabasefrontenddocumentformat(odb),version1.2,iso263001:2015": "",
            "name": "OpenDocument Database Front End Document Format (ODB), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000441",
            "filenamepattern": [
                {
                    "value": "*.odb",
                    "url": "https://www.digipres.org/formats/extensions/#*.odb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.database",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.database"
                },
                {
                    "value": "application/vnd.oasis.opendocument.base",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.base"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000441.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000441.xml"
            }
        },
        {
            "opendocumentdatabasefrontenddocumentformat(odb),version1.2,iso263001:2015": "",
            "name": "OpenDocument Database Front End Document Format (ODB), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000441",
            "filenamepattern": [
                {
                    "value": "*.odb",
                    "url": "https://www.digipres.org/formats/extensions/#*.odb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.database",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.database"
                },
                {
                    "value": "application/vnd.oasis.opendocument.base",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.base"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000441.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000441.xml"
            }
        },
        {
            "opendocumentdatabasefrontenddocumentformat(odb),version1.2,iso263001:2015": "",
            "name": "OpenDocument Database Front End Document Format (ODB), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000441",
            "filenamepattern": [
                {
                    "value": "*.odb",
                    "url": "https://www.digipres.org/formats/extensions/#*.odb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.database",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.database"
                },
                {
                    "value": "application/vnd.oasis.opendocument.base",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.base"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000441.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000441.xml"
            }
        },
        {
            "opendocumentdatabasefrontenddocumentformat(odb),version1.2,iso263001:2015": "",
            "name": "OpenDocument Database Front End Document Format (ODB), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000441",
            "filenamepattern": [
                {
                    "value": "*.odb",
                    "url": "https://www.digipres.org/formats/extensions/#*.odb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.database",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.database"
                },
                {
                    "value": "application/vnd.oasis.opendocument.base",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.base"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000441.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000441.xml"
            }
        },
        {
            "opendocumentdatabasefrontenddocumentformat(odb),version1.2,iso263001:2015": "",
            "name": "OpenDocument Database Front End Document Format (ODB), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000441",
            "filenamepattern": [
                {
                    "value": "*.odb",
                    "url": "https://www.digipres.org/formats/extensions/#*.odb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.database",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.database"
                },
                {
                    "value": "application/vnd.oasis.opendocument.base",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.base"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000441.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000441.xml"
            }
        },
        {
            "opendocumentdatabasefrontenddocumentformat(odb),version1.2,iso263001:2015": "",
            "name": "OpenDocument Database Front End Document Format (ODB), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000441",
            "filenamepattern": [
                {
                    "value": "*.odb",
                    "url": "https://www.digipres.org/formats/extensions/#*.odb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.database",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.database"
                },
                {
                    "value": "application/vnd.oasis.opendocument.base",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.base"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000441.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000441.xml"
            }
        },
        {
            "opendocumentdatabasefrontenddocumentformat(odb),version1.2,iso263001:2015": "",
            "name": "OpenDocument Database Front End Document Format (ODB), Version 1.2,  ISO 26300-1:2015",
            "id": "fdd000441",
            "filenamepattern": [
                {
                    "value": "*.odb",
                    "url": "https://www.digipres.org/formats/extensions/#*.odb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.oasis.opendocument.database",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.database"
                },
                {
                    "value": "application/vnd.oasis.opendocument.base",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.oasis.opendocument.base"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000441.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000441.xml"
            }
        },
        {
            "avifileformatwithopendmlextensions,version1.02": "",
            "name": "AVI File Format with OpenDML Extensions, Version 1.02",
            "id": "fdd000442",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000442.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000442.xml"
            }
        },
        {
            "avifileformatwithopendmlextensions,version1.02": "",
            "name": "AVI File Format with OpenDML Extensions, Version 1.02",
            "id": "fdd000442",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000442.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000442.xml"
            }
        },
        {
            "avifileformatwithopendmlextensions,version1.02": "",
            "name": "AVI File Format with OpenDML Extensions, Version 1.02",
            "id": "fdd000442",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000442.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000442.xml"
            }
        },
        {
            "avifileformatwithopendmlextensions,version1.02": "",
            "name": "AVI File Format with OpenDML Extensions, Version 1.02",
            "id": "fdd000442",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000442.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000442.xml"
            }
        },
        {
            "avifileformatwithopendmlextensions,version1.02": "",
            "name": "AVI File Format with OpenDML Extensions, Version 1.02",
            "id": "fdd000442",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000442.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000442.xml"
            }
        },
        {
            "avifileformatwithopendmlextensions,version1.02": "",
            "name": "AVI File Format with OpenDML Extensions, Version 1.02",
            "id": "fdd000442",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000442.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000442.xml"
            }
        },
        {
            "avifileformatwithopendmlextensions,version1.02": "",
            "name": "AVI File Format with OpenDML Extensions, Version 1.02",
            "id": "fdd000442",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000442.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000442.xml"
            }
        },
        {
            "avifileformatwithopendmlextensions,version1.02": "",
            "name": "AVI File Format with OpenDML Extensions, Version 1.02",
            "id": "fdd000442",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000442.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000442.xml"
            }
        },
        {
            "ecmascriptlanguage(ecma262),includingjavascript": "",
            "name": "ECMAScript Language (ECMA-262), including JavaScript",
            "id": "fdd000443",
            "filenamepattern": [
                {
                    "value": "*.js",
                    "url": "https://www.digipres.org/formats/extensions/#*.js"
                },
                {
                    "value": "*.es",
                    "url": "https://www.digipres.org/formats/extensions/#*.es"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ecmascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ecmascript"
                },
                {
                    "value": "application/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/javascript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000443.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000443.xml"
            }
        },
        {
            "ecmascriptlanguage(ecma262),includingjavascript": "",
            "name": "ECMAScript Language (ECMA-262), including JavaScript",
            "id": "fdd000443",
            "filenamepattern": [
                {
                    "value": "*.js",
                    "url": "https://www.digipres.org/formats/extensions/#*.js"
                },
                {
                    "value": "*.es",
                    "url": "https://www.digipres.org/formats/extensions/#*.es"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ecmascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ecmascript"
                },
                {
                    "value": "application/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/javascript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000443.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000443.xml"
            }
        },
        {
            "ecmascriptlanguage(ecma262),includingjavascript": "",
            "name": "ECMAScript Language (ECMA-262), including JavaScript",
            "id": "fdd000443",
            "filenamepattern": [
                {
                    "value": "*.js",
                    "url": "https://www.digipres.org/formats/extensions/#*.js"
                },
                {
                    "value": "*.es",
                    "url": "https://www.digipres.org/formats/extensions/#*.es"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ecmascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ecmascript"
                },
                {
                    "value": "application/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/javascript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000443.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000443.xml"
            }
        },
        {
            "ecmascriptlanguage(ecma262),includingjavascript": "",
            "name": "ECMAScript Language (ECMA-262), including JavaScript",
            "id": "fdd000443",
            "filenamepattern": [
                {
                    "value": "*.js",
                    "url": "https://www.digipres.org/formats/extensions/#*.js"
                },
                {
                    "value": "*.es",
                    "url": "https://www.digipres.org/formats/extensions/#*.es"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ecmascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ecmascript"
                },
                {
                    "value": "application/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/javascript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000443.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000443.xml"
            }
        },
        {
            "ecmascriptlanguage(ecma262),includingjavascript": "",
            "name": "ECMAScript Language (ECMA-262), including JavaScript",
            "id": "fdd000443",
            "filenamepattern": [
                {
                    "value": "*.js",
                    "url": "https://www.digipres.org/formats/extensions/#*.js"
                },
                {
                    "value": "*.es",
                    "url": "https://www.digipres.org/formats/extensions/#*.es"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ecmascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ecmascript"
                },
                {
                    "value": "application/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/javascript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000443.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000443.xml"
            }
        },
        {
            "ecmascriptlanguage(ecma262),includingjavascript": "",
            "name": "ECMAScript Language (ECMA-262), including JavaScript",
            "id": "fdd000443",
            "filenamepattern": [
                {
                    "value": "*.js",
                    "url": "https://www.digipres.org/formats/extensions/#*.js"
                },
                {
                    "value": "*.es",
                    "url": "https://www.digipres.org/formats/extensions/#*.es"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ecmascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ecmascript"
                },
                {
                    "value": "application/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/javascript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000443.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000443.xml"
            }
        },
        {
            "ecmascriptlanguage(ecma262),includingjavascript": "",
            "name": "ECMAScript Language (ECMA-262), including JavaScript",
            "id": "fdd000443",
            "filenamepattern": [
                {
                    "value": "*.js",
                    "url": "https://www.digipres.org/formats/extensions/#*.js"
                },
                {
                    "value": "*.es",
                    "url": "https://www.digipres.org/formats/extensions/#*.es"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ecmascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ecmascript"
                },
                {
                    "value": "application/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/javascript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000443.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000443.xml"
            }
        },
        {
            "ecmascriptlanguage(ecma262),includingjavascript": "",
            "name": "ECMAScript Language (ECMA-262), including JavaScript",
            "id": "fdd000443",
            "filenamepattern": [
                {
                    "value": "*.js",
                    "url": "https://www.digipres.org/formats/extensions/#*.js"
                },
                {
                    "value": "*.es",
                    "url": "https://www.digipres.org/formats/extensions/#*.es"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ecmascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ecmascript"
                },
                {
                    "value": "application/javascript",
                    "url": "https://www.digipres.org/formats/mime-types/#application/javascript"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000443.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000443.xml"
            }
        },
        {
            "dwg(autocaddrawing)formatfamily": "",
            "name": "DWG (AutoCAD Drawing) Format Family",
            "id": "fdd000445",
            "filenamepattern": [
                {
                    "value": "*.dwg",
                    "url": "https://www.digipres.org/formats/extensions/#*.dwg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dwg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dwg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000445.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000445.xml"
            }
        },
        {
            "dwg(autocaddrawing)formatfamily": "",
            "name": "DWG (AutoCAD Drawing) Format Family",
            "id": "fdd000445",
            "filenamepattern": [
                {
                    "value": "*.dwg",
                    "url": "https://www.digipres.org/formats/extensions/#*.dwg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dwg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dwg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000445.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000445.xml"
            }
        },
        {
            "dwg(autocaddrawing)formatfamily": "",
            "name": "DWG (AutoCAD Drawing) Format Family",
            "id": "fdd000445",
            "filenamepattern": [
                {
                    "value": "*.dwg",
                    "url": "https://www.digipres.org/formats/extensions/#*.dwg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dwg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dwg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000445.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000445.xml"
            }
        },
        {
            "dwg(autocaddrawing)formatfamily": "",
            "name": "DWG (AutoCAD Drawing) Format Family",
            "id": "fdd000445",
            "filenamepattern": [
                {
                    "value": "*.dwg",
                    "url": "https://www.digipres.org/formats/extensions/#*.dwg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dwg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dwg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000445.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000445.xml"
            }
        },
        {
            "dwg(autocaddrawing)formatfamily": "",
            "name": "DWG (AutoCAD Drawing) Format Family",
            "id": "fdd000445",
            "filenamepattern": [
                {
                    "value": "*.dwg",
                    "url": "https://www.digipres.org/formats/extensions/#*.dwg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dwg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dwg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000445.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000445.xml"
            }
        },
        {
            "dwg(autocaddrawing)formatfamily": "",
            "name": "DWG (AutoCAD Drawing) Format Family",
            "id": "fdd000445",
            "filenamepattern": [
                {
                    "value": "*.dwg",
                    "url": "https://www.digipres.org/formats/extensions/#*.dwg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dwg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dwg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000445.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000445.xml"
            }
        },
        {
            "dwg(autocaddrawing)formatfamily": "",
            "name": "DWG (AutoCAD Drawing) Format Family",
            "id": "fdd000445",
            "filenamepattern": [
                {
                    "value": "*.dwg",
                    "url": "https://www.digipres.org/formats/extensions/#*.dwg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dwg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dwg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000445.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000445.xml"
            }
        },
        {
            "dwg(autocaddrawing)formatfamily": "",
            "name": "DWG (AutoCAD Drawing) Format Family",
            "id": "fdd000445",
            "filenamepattern": [
                {
                    "value": "*.dwg",
                    "url": "https://www.digipres.org/formats/extensions/#*.dwg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dwg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dwg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000445.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000445.xml"
            }
        },
        {
            "dxf(autocaddrawinginterchangeformat)family,asciivariant": "",
            "name": "DXF (AutoCAD Drawing Interchange Format) Family, ASCII variant",
            "id": "fdd000446",
            "filenamepattern": [
                {
                    "value": "*.dxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dxf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dxf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000446.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000446.xml"
            }
        },
        {
            "dxf(autocaddrawinginterchangeformat)family,asciivariant": "",
            "name": "DXF (AutoCAD Drawing Interchange Format) Family, ASCII variant",
            "id": "fdd000446",
            "filenamepattern": [
                {
                    "value": "*.dxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dxf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dxf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000446.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000446.xml"
            }
        },
        {
            "dxf(autocaddrawinginterchangeformat)family,asciivariant": "",
            "name": "DXF (AutoCAD Drawing Interchange Format) Family, ASCII variant",
            "id": "fdd000446",
            "filenamepattern": [
                {
                    "value": "*.dxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dxf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dxf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000446.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000446.xml"
            }
        },
        {
            "dxf(autocaddrawinginterchangeformat)family,asciivariant": "",
            "name": "DXF (AutoCAD Drawing Interchange Format) Family, ASCII variant",
            "id": "fdd000446",
            "filenamepattern": [
                {
                    "value": "*.dxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dxf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dxf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000446.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000446.xml"
            }
        },
        {
            "dxf(autocaddrawinginterchangeformat)family,asciivariant": "",
            "name": "DXF (AutoCAD Drawing Interchange Format) Family, ASCII variant",
            "id": "fdd000446",
            "filenamepattern": [
                {
                    "value": "*.dxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dxf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dxf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000446.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000446.xml"
            }
        },
        {
            "dxf(autocaddrawinginterchangeformat)family,asciivariant": "",
            "name": "DXF (AutoCAD Drawing Interchange Format) Family, ASCII variant",
            "id": "fdd000446",
            "filenamepattern": [
                {
                    "value": "*.dxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dxf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dxf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000446.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000446.xml"
            }
        },
        {
            "dxf(autocaddrawinginterchangeformat)family,asciivariant": "",
            "name": "DXF (AutoCAD Drawing Interchange Format) Family, ASCII variant",
            "id": "fdd000446",
            "filenamepattern": [
                {
                    "value": "*.dxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dxf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dxf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000446.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000446.xml"
            }
        },
        {
            "dxf(autocaddrawinginterchangeformat)family,asciivariant": "",
            "name": "DXF (AutoCAD Drawing Interchange Format) Family, ASCII variant",
            "id": "fdd000446",
            "filenamepattern": [
                {
                    "value": "*.dxf",
                    "url": "https://www.digipres.org/formats/extensions/#*.dxf"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dxf",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dxf"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000446.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000446.xml"
            }
        },
        {
            "industryfoundationclasses(ifc),cleartextfamily": "",
            "name": "Industry Foundation Classes (IFC), Clear Text Family",
            "id": "fdd000447",
            "filenamepattern": [
                {
                    "value": "*.ifc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000447.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000447.xml"
            }
        },
        {
            "industryfoundationclasses(ifc),cleartextfamily": "",
            "name": "Industry Foundation Classes (IFC), Clear Text Family",
            "id": "fdd000447",
            "filenamepattern": [
                {
                    "value": "*.ifc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000447.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000447.xml"
            }
        },
        {
            "industryfoundationclasses(ifc),cleartextfamily": "",
            "name": "Industry Foundation Classes (IFC), Clear Text Family",
            "id": "fdd000447",
            "filenamepattern": [
                {
                    "value": "*.ifc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000447.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000447.xml"
            }
        },
        {
            "industryfoundationclasses(ifc),cleartextfamily": "",
            "name": "Industry Foundation Classes (IFC), Clear Text Family",
            "id": "fdd000447",
            "filenamepattern": [
                {
                    "value": "*.ifc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000447.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000447.xml"
            }
        },
        {
            "industryfoundationclasses(ifc),cleartextfamily": "",
            "name": "Industry Foundation Classes (IFC), Clear Text Family",
            "id": "fdd000447",
            "filenamepattern": [
                {
                    "value": "*.ifc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000447.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000447.xml"
            }
        },
        {
            "industryfoundationclasses(ifc),cleartextfamily": "",
            "name": "Industry Foundation Classes (IFC), Clear Text Family",
            "id": "fdd000447",
            "filenamepattern": [
                {
                    "value": "*.ifc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000447.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000447.xml"
            }
        },
        {
            "industryfoundationclasses(ifc),cleartextfamily": "",
            "name": "Industry Foundation Classes (IFC), Clear Text Family",
            "id": "fdd000447",
            "filenamepattern": [
                {
                    "value": "*.ifc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000447.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000447.xml"
            }
        },
        {
            "industryfoundationclasses(ifc),cleartextfamily": "",
            "name": "Industry Foundation Classes (IFC), Clear Text Family",
            "id": "fdd000447",
            "filenamepattern": [
                {
                    "value": "*.ifc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ifc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000447.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000447.xml"
            }
        },
        {
            "stepfile,iso1030321": "",
            "name": "STEP-file,  ISO 10303-21",
            "id": "fdd000448",
            "filenamepattern": [
                {
                    "value": "*.stp",
                    "url": "https://www.digipres.org/formats/extensions/#*.stp"
                },
                {
                    "value": "*.p21",
                    "url": "https://www.digipres.org/formats/extensions/#*.p21"
                },
                {
                    "value": "*.step",
                    "url": "https://www.digipres.org/formats/extensions/#*.step"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/step",
                    "url": "https://www.digipres.org/formats/mime-types/#model/step"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000448.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000448.xml"
            }
        },
        {
            "stepfile,iso1030321": "",
            "name": "STEP-file,  ISO 10303-21",
            "id": "fdd000448",
            "filenamepattern": [
                {
                    "value": "*.stp",
                    "url": "https://www.digipres.org/formats/extensions/#*.stp"
                },
                {
                    "value": "*.p21",
                    "url": "https://www.digipres.org/formats/extensions/#*.p21"
                },
                {
                    "value": "*.step",
                    "url": "https://www.digipres.org/formats/extensions/#*.step"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/step",
                    "url": "https://www.digipres.org/formats/mime-types/#model/step"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000448.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000448.xml"
            }
        },
        {
            "stepfile,iso1030321": "",
            "name": "STEP-file,  ISO 10303-21",
            "id": "fdd000448",
            "filenamepattern": [
                {
                    "value": "*.stp",
                    "url": "https://www.digipres.org/formats/extensions/#*.stp"
                },
                {
                    "value": "*.p21",
                    "url": "https://www.digipres.org/formats/extensions/#*.p21"
                },
                {
                    "value": "*.step",
                    "url": "https://www.digipres.org/formats/extensions/#*.step"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/step",
                    "url": "https://www.digipres.org/formats/mime-types/#model/step"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000448.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000448.xml"
            }
        },
        {
            "stepfile,iso1030321": "",
            "name": "STEP-file,  ISO 10303-21",
            "id": "fdd000448",
            "filenamepattern": [
                {
                    "value": "*.stp",
                    "url": "https://www.digipres.org/formats/extensions/#*.stp"
                },
                {
                    "value": "*.p21",
                    "url": "https://www.digipres.org/formats/extensions/#*.p21"
                },
                {
                    "value": "*.step",
                    "url": "https://www.digipres.org/formats/extensions/#*.step"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/step",
                    "url": "https://www.digipres.org/formats/mime-types/#model/step"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000448.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000448.xml"
            }
        },
        {
            "stepfile,iso1030321": "",
            "name": "STEP-file,  ISO 10303-21",
            "id": "fdd000448",
            "filenamepattern": [
                {
                    "value": "*.stp",
                    "url": "https://www.digipres.org/formats/extensions/#*.stp"
                },
                {
                    "value": "*.p21",
                    "url": "https://www.digipres.org/formats/extensions/#*.p21"
                },
                {
                    "value": "*.step",
                    "url": "https://www.digipres.org/formats/extensions/#*.step"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/step",
                    "url": "https://www.digipres.org/formats/mime-types/#model/step"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000448.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000448.xml"
            }
        },
        {
            "stepfile,iso1030321": "",
            "name": "STEP-file,  ISO 10303-21",
            "id": "fdd000448",
            "filenamepattern": [
                {
                    "value": "*.stp",
                    "url": "https://www.digipres.org/formats/extensions/#*.stp"
                },
                {
                    "value": "*.p21",
                    "url": "https://www.digipres.org/formats/extensions/#*.p21"
                },
                {
                    "value": "*.step",
                    "url": "https://www.digipres.org/formats/extensions/#*.step"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/step",
                    "url": "https://www.digipres.org/formats/mime-types/#model/step"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000448.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000448.xml"
            }
        },
        {
            "stepfile,iso1030321": "",
            "name": "STEP-file,  ISO 10303-21",
            "id": "fdd000448",
            "filenamepattern": [
                {
                    "value": "*.stp",
                    "url": "https://www.digipres.org/formats/extensions/#*.stp"
                },
                {
                    "value": "*.p21",
                    "url": "https://www.digipres.org/formats/extensions/#*.p21"
                },
                {
                    "value": "*.step",
                    "url": "https://www.digipres.org/formats/extensions/#*.step"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/step",
                    "url": "https://www.digipres.org/formats/mime-types/#model/step"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000448.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000448.xml"
            }
        },
        {
            "stepfile,iso1030321": "",
            "name": "STEP-file,  ISO 10303-21",
            "id": "fdd000448",
            "filenamepattern": [
                {
                    "value": "*.stp",
                    "url": "https://www.digipres.org/formats/extensions/#*.stp"
                },
                {
                    "value": "*.p21",
                    "url": "https://www.digipres.org/formats/extensions/#*.p21"
                },
                {
                    "value": "*.step",
                    "url": "https://www.digipres.org/formats/extensions/#*.step"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/step",
                    "url": "https://www.digipres.org/formats/mime-types/#model/step"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000448.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000448.xml"
            }
        },
        {
            "expressdatamodelinglanguage,iso1030311": "",
            "name": "EXPRESS data modeling language,  ISO 10303-11",
            "id": "fdd000449",
            "filenamepattern": [
                {
                    "value": "*.exp",
                    "url": "https://www.digipres.org/formats/extensions/#*.exp"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000449.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000449.xml"
            }
        },
        {
            "expressdatamodelinglanguage,iso1030311": "",
            "name": "EXPRESS data modeling language,  ISO 10303-11",
            "id": "fdd000449",
            "filenamepattern": [
                {
                    "value": "*.exp",
                    "url": "https://www.digipres.org/formats/extensions/#*.exp"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000449.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000449.xml"
            }
        },
        {
            "expressdatamodelinglanguage,iso1030311": "",
            "name": "EXPRESS data modeling language,  ISO 10303-11",
            "id": "fdd000449",
            "filenamepattern": [
                {
                    "value": "*.exp",
                    "url": "https://www.digipres.org/formats/extensions/#*.exp"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000449.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000449.xml"
            }
        },
        {
            "expressdatamodelinglanguage,iso1030311": "",
            "name": "EXPRESS data modeling language,  ISO 10303-11",
            "id": "fdd000449",
            "filenamepattern": [
                {
                    "value": "*.exp",
                    "url": "https://www.digipres.org/formats/extensions/#*.exp"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000449.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000449.xml"
            }
        },
        {
            "expressdatamodelinglanguage,iso1030311": "",
            "name": "EXPRESS data modeling language,  ISO 10303-11",
            "id": "fdd000449",
            "filenamepattern": [
                {
                    "value": "*.exp",
                    "url": "https://www.digipres.org/formats/extensions/#*.exp"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000449.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000449.xml"
            }
        },
        {
            "expressdatamodelinglanguage,iso1030311": "",
            "name": "EXPRESS data modeling language,  ISO 10303-11",
            "id": "fdd000449",
            "filenamepattern": [
                {
                    "value": "*.exp",
                    "url": "https://www.digipres.org/formats/extensions/#*.exp"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000449.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000449.xml"
            }
        },
        {
            "expressdatamodelinglanguage,iso1030311": "",
            "name": "EXPRESS data modeling language,  ISO 10303-11",
            "id": "fdd000449",
            "filenamepattern": [
                {
                    "value": "*.exp",
                    "url": "https://www.digipres.org/formats/extensions/#*.exp"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000449.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000449.xml"
            }
        },
        {
            "expressdatamodelinglanguage,iso1030311": "",
            "name": "EXPRESS data modeling language,  ISO 10303-11",
            "id": "fdd000449",
            "filenamepattern": [
                {
                    "value": "*.exp",
                    "url": "https://www.digipres.org/formats/extensions/#*.exp"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000449.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000449.xml"
            }
        },
        {
            "rararchivefileformatfamily": "",
            "name": "RAR Archive File Format Family",
            "id": "fdd000450",
            "filenamepattern": [
                {
                    "value": "*.rev",
                    "url": "https://www.digipres.org/formats/extensions/#*.rev"
                },
                {
                    "value": "*.rar ",
                    "url": "https://www.digipres.org/formats/extensions/#*.rar "
                },
                {
                    "value": "*.r00",
                    "url": "https://www.digipres.org/formats/extensions/#*.r00"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rar"
                },
                {
                    "value": "application/x-rar-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-rar-compressed"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000450.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000450.xml"
            }
        },
        {
            "rararchivefileformatfamily": "",
            "name": "RAR Archive File Format Family",
            "id": "fdd000450",
            "filenamepattern": [
                {
                    "value": "*.rev",
                    "url": "https://www.digipres.org/formats/extensions/#*.rev"
                },
                {
                    "value": "*.rar ",
                    "url": "https://www.digipres.org/formats/extensions/#*.rar "
                },
                {
                    "value": "*.r00",
                    "url": "https://www.digipres.org/formats/extensions/#*.r00"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rar"
                },
                {
                    "value": "application/x-rar-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-rar-compressed"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000450.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000450.xml"
            }
        },
        {
            "rararchivefileformatfamily": "",
            "name": "RAR Archive File Format Family",
            "id": "fdd000450",
            "filenamepattern": [
                {
                    "value": "*.rev",
                    "url": "https://www.digipres.org/formats/extensions/#*.rev"
                },
                {
                    "value": "*.rar ",
                    "url": "https://www.digipres.org/formats/extensions/#*.rar "
                },
                {
                    "value": "*.r00",
                    "url": "https://www.digipres.org/formats/extensions/#*.r00"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rar"
                },
                {
                    "value": "application/x-rar-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-rar-compressed"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000450.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000450.xml"
            }
        },
        {
            "rararchivefileformatfamily": "",
            "name": "RAR Archive File Format Family",
            "id": "fdd000450",
            "filenamepattern": [
                {
                    "value": "*.rev",
                    "url": "https://www.digipres.org/formats/extensions/#*.rev"
                },
                {
                    "value": "*.rar ",
                    "url": "https://www.digipres.org/formats/extensions/#*.rar "
                },
                {
                    "value": "*.r00",
                    "url": "https://www.digipres.org/formats/extensions/#*.r00"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rar"
                },
                {
                    "value": "application/x-rar-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-rar-compressed"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000450.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000450.xml"
            }
        },
        {
            "rararchivefileformatfamily": "",
            "name": "RAR Archive File Format Family",
            "id": "fdd000450",
            "filenamepattern": [
                {
                    "value": "*.rev",
                    "url": "https://www.digipres.org/formats/extensions/#*.rev"
                },
                {
                    "value": "*.rar ",
                    "url": "https://www.digipres.org/formats/extensions/#*.rar "
                },
                {
                    "value": "*.r00",
                    "url": "https://www.digipres.org/formats/extensions/#*.r00"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rar"
                },
                {
                    "value": "application/x-rar-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-rar-compressed"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000450.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000450.xml"
            }
        },
        {
            "rararchivefileformatfamily": "",
            "name": "RAR Archive File Format Family",
            "id": "fdd000450",
            "filenamepattern": [
                {
                    "value": "*.rev",
                    "url": "https://www.digipres.org/formats/extensions/#*.rev"
                },
                {
                    "value": "*.rar ",
                    "url": "https://www.digipres.org/formats/extensions/#*.rar "
                },
                {
                    "value": "*.r00",
                    "url": "https://www.digipres.org/formats/extensions/#*.r00"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rar"
                },
                {
                    "value": "application/x-rar-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-rar-compressed"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000450.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000450.xml"
            }
        },
        {
            "rararchivefileformatfamily": "",
            "name": "RAR Archive File Format Family",
            "id": "fdd000450",
            "filenamepattern": [
                {
                    "value": "*.rev",
                    "url": "https://www.digipres.org/formats/extensions/#*.rev"
                },
                {
                    "value": "*.rar ",
                    "url": "https://www.digipres.org/formats/extensions/#*.rar "
                },
                {
                    "value": "*.r00",
                    "url": "https://www.digipres.org/formats/extensions/#*.r00"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rar"
                },
                {
                    "value": "application/x-rar-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-rar-compressed"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000450.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000450.xml"
            }
        },
        {
            "rararchivefileformatfamily": "",
            "name": "RAR Archive File Format Family",
            "id": "fdd000450",
            "filenamepattern": [
                {
                    "value": "*.rev",
                    "url": "https://www.digipres.org/formats/extensions/#*.rev"
                },
                {
                    "value": "*.rar ",
                    "url": "https://www.digipres.org/formats/extensions/#*.rar "
                },
                {
                    "value": "*.r00",
                    "url": "https://www.digipres.org/formats/extensions/#*.r00"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.rar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.rar"
                },
                {
                    "value": "application/x-rar-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-rar-compressed"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000450.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000450.xml"
            }
        },
        {
            "jats,journalarticletagsuite,nisoz39.96,versions1.x": "",
            "name": "JATS, Journal Article Tag Suite, NISO Z39.96, Versions 1.x",
            "id": "fdd000451",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000451.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000451.xml"
            }
        },
        {
            "jats,journalarticletagsuite,nisoz39.96,versions1.x": "",
            "name": "JATS, Journal Article Tag Suite, NISO Z39.96, Versions 1.x",
            "id": "fdd000451",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000451.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000451.xml"
            }
        },
        {
            "jats,journalarticletagsuite,nisoz39.96,versions1.x": "",
            "name": "JATS, Journal Article Tag Suite, NISO Z39.96, Versions 1.x",
            "id": "fdd000451",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000451.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000451.xml"
            }
        },
        {
            "jats,journalarticletagsuite,nisoz39.96,versions1.x": "",
            "name": "JATS, Journal Article Tag Suite, NISO Z39.96, Versions 1.x",
            "id": "fdd000451",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000451.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000451.xml"
            }
        },
        {
            "jats,journalarticletagsuite,nisoz39.96,versions1.x": "",
            "name": "JATS, Journal Article Tag Suite, NISO Z39.96, Versions 1.x",
            "id": "fdd000451",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000451.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000451.xml"
            }
        },
        {
            "jats,journalarticletagsuite,nisoz39.96,versions1.x": "",
            "name": "JATS, Journal Article Tag Suite, NISO Z39.96, Versions 1.x",
            "id": "fdd000451",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000451.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000451.xml"
            }
        },
        {
            "jats,journalarticletagsuite,nisoz39.96,versions1.x": "",
            "name": "JATS, Journal Article Tag Suite, NISO Z39.96, Versions 1.x",
            "id": "fdd000451",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000451.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000451.xml"
            }
        },
        {
            "jats,journalarticletagsuite,nisoz39.96,versions1.x": "",
            "name": "JATS, Journal Article Tag Suite, NISO Z39.96, Versions 1.x",
            "id": "fdd000451",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000451.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000451.xml"
            }
        },
        {
            "ncbiarch_3,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version3.0": "",
            "name": "NCBIArch_3, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 3.0",
            "id": "fdd000452",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000452.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000452.xml"
            }
        },
        {
            "ncbiarch_3,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version3.0": "",
            "name": "NCBIArch_3, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 3.0",
            "id": "fdd000452",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000452.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000452.xml"
            }
        },
        {
            "ncbiarch_3,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version3.0": "",
            "name": "NCBIArch_3, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 3.0",
            "id": "fdd000452",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000452.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000452.xml"
            }
        },
        {
            "ncbiarch_3,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version3.0": "",
            "name": "NCBIArch_3, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 3.0",
            "id": "fdd000452",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000452.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000452.xml"
            }
        },
        {
            "ncbiarch_3,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version3.0": "",
            "name": "NCBIArch_3, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 3.0",
            "id": "fdd000452",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000452.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000452.xml"
            }
        },
        {
            "ncbiarch_3,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version3.0": "",
            "name": "NCBIArch_3, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 3.0",
            "id": "fdd000452",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000452.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000452.xml"
            }
        },
        {
            "ncbiarch_3,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version3.0": "",
            "name": "NCBIArch_3, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 3.0",
            "id": "fdd000452",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000452.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000452.xml"
            }
        },
        {
            "ncbiarch_3,ncbi/nlmjournalarchivingandinterchangedocumenttypedefinition(nlmdtd),version3.0": "",
            "name": "NCBIArch_3, NCBI/NLM Journal Archiving and Interchange Document Type Definition (NLM DTD), Version 3.0",
            "id": "fdd000452",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000452.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000452.xml"
            }
        },
        {
            "bits(bookinterchangetagsuite),version2.0": "",
            "name": "BITS (Book Interchange Tag Suite), version 2.0",
            "id": "fdd000453",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000453.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000453.xml"
            }
        },
        {
            "bits(bookinterchangetagsuite),version2.0": "",
            "name": "BITS (Book Interchange Tag Suite), version 2.0",
            "id": "fdd000453",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000453.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000453.xml"
            }
        },
        {
            "bits(bookinterchangetagsuite),version2.0": "",
            "name": "BITS (Book Interchange Tag Suite), version 2.0",
            "id": "fdd000453",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000453.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000453.xml"
            }
        },
        {
            "bits(bookinterchangetagsuite),version2.0": "",
            "name": "BITS (Book Interchange Tag Suite), version 2.0",
            "id": "fdd000453",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000453.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000453.xml"
            }
        },
        {
            "bits(bookinterchangetagsuite),version2.0": "",
            "name": "BITS (Book Interchange Tag Suite), version 2.0",
            "id": "fdd000453",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000453.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000453.xml"
            }
        },
        {
            "bits(bookinterchangetagsuite),version2.0": "",
            "name": "BITS (Book Interchange Tag Suite), version 2.0",
            "id": "fdd000453",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000453.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000453.xml"
            }
        },
        {
            "bits(bookinterchangetagsuite),version2.0": "",
            "name": "BITS (Book Interchange Tag Suite), version 2.0",
            "id": "fdd000453",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000453.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000453.xml"
            }
        },
        {
            "bits(bookinterchangetagsuite),version2.0": "",
            "name": "BITS (Book Interchange Tag Suite), version 2.0",
            "id": "fdd000453",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000453.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000453.xml"
            }
        },
        {
            "hewlettpackardadvancewritetextfile": "",
            "name": "Hewlett Packard AdvanceWrite Text File",
            "id": "fdd000455",
            "filenamepattern": [
                {
                    "value": "*.aw",
                    "url": "https://www.digipres.org/formats/extensions/#*.aw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000455.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000455.xml"
            }
        },
        {
            "hewlettpackardadvancewritetextfile": "",
            "name": "Hewlett Packard AdvanceWrite Text File",
            "id": "fdd000455",
            "filenamepattern": [
                {
                    "value": "*.aw",
                    "url": "https://www.digipres.org/formats/extensions/#*.aw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000455.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000455.xml"
            }
        },
        {
            "hewlettpackardadvancewritetextfile": "",
            "name": "Hewlett Packard AdvanceWrite Text File",
            "id": "fdd000455",
            "filenamepattern": [
                {
                    "value": "*.aw",
                    "url": "https://www.digipres.org/formats/extensions/#*.aw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000455.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000455.xml"
            }
        },
        {
            "hewlettpackardadvancewritetextfile": "",
            "name": "Hewlett Packard AdvanceWrite Text File",
            "id": "fdd000455",
            "filenamepattern": [
                {
                    "value": "*.aw",
                    "url": "https://www.digipres.org/formats/extensions/#*.aw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000455.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000455.xml"
            }
        },
        {
            "hewlettpackardadvancewritetextfile": "",
            "name": "Hewlett Packard AdvanceWrite Text File",
            "id": "fdd000455",
            "filenamepattern": [
                {
                    "value": "*.aw",
                    "url": "https://www.digipres.org/formats/extensions/#*.aw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000455.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000455.xml"
            }
        },
        {
            "hewlettpackardadvancewritetextfile": "",
            "name": "Hewlett Packard AdvanceWrite Text File",
            "id": "fdd000455",
            "filenamepattern": [
                {
                    "value": "*.aw",
                    "url": "https://www.digipres.org/formats/extensions/#*.aw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000455.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000455.xml"
            }
        },
        {
            "hewlettpackardadvancewritetextfile": "",
            "name": "Hewlett Packard AdvanceWrite Text File",
            "id": "fdd000455",
            "filenamepattern": [
                {
                    "value": "*.aw",
                    "url": "https://www.digipres.org/formats/extensions/#*.aw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000455.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000455.xml"
            }
        },
        {
            "hewlettpackardadvancewritetextfile": "",
            "name": "Hewlett Packard AdvanceWrite Text File",
            "id": "fdd000455",
            "filenamepattern": [
                {
                    "value": "*.aw",
                    "url": "https://www.digipres.org/formats/extensions/#*.aw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000455.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000455.xml"
            }
        },
        {
            "aolpersonalfilingcabinet": "",
            "name": "AOL Personal Filing Cabinet",
            "id": "fdd000456",
            "filenamepattern": [
                {
                    "value": "*.pfc",
                    "url": "https://www.digipres.org/formats/extensions/#*.pfc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000456.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000456.xml"
            }
        },
        {
            "aolpersonalfilingcabinet": "",
            "name": "AOL Personal Filing Cabinet",
            "id": "fdd000456",
            "filenamepattern": [
                {
                    "value": "*.pfc",
                    "url": "https://www.digipres.org/formats/extensions/#*.pfc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000456.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000456.xml"
            }
        },
        {
            "aolpersonalfilingcabinet": "",
            "name": "AOL Personal Filing Cabinet",
            "id": "fdd000456",
            "filenamepattern": [
                {
                    "value": "*.pfc",
                    "url": "https://www.digipres.org/formats/extensions/#*.pfc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000456.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000456.xml"
            }
        },
        {
            "aolpersonalfilingcabinet": "",
            "name": "AOL Personal Filing Cabinet",
            "id": "fdd000456",
            "filenamepattern": [
                {
                    "value": "*.pfc",
                    "url": "https://www.digipres.org/formats/extensions/#*.pfc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000456.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000456.xml"
            }
        },
        {
            "aolpersonalfilingcabinet": "",
            "name": "AOL Personal Filing Cabinet",
            "id": "fdd000456",
            "filenamepattern": [
                {
                    "value": "*.pfc",
                    "url": "https://www.digipres.org/formats/extensions/#*.pfc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000456.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000456.xml"
            }
        },
        {
            "aolpersonalfilingcabinet": "",
            "name": "AOL Personal Filing Cabinet",
            "id": "fdd000456",
            "filenamepattern": [
                {
                    "value": "*.pfc",
                    "url": "https://www.digipres.org/formats/extensions/#*.pfc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000456.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000456.xml"
            }
        },
        {
            "aolpersonalfilingcabinet": "",
            "name": "AOL Personal Filing Cabinet",
            "id": "fdd000456",
            "filenamepattern": [
                {
                    "value": "*.pfc",
                    "url": "https://www.digipres.org/formats/extensions/#*.pfc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000456.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000456.xml"
            }
        },
        {
            "aolpersonalfilingcabinet": "",
            "name": "AOL Personal Filing Cabinet",
            "id": "fdd000456",
            "filenamepattern": [
                {
                    "value": "*.pfc",
                    "url": "https://www.digipres.org/formats/extensions/#*.pfc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000456.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000456.xml"
            }
        },
        {
            "rararchivefileformat,version2": "",
            "name": "RAR Archive File Format, Version 2",
            "id": "fdd000457",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000457.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000457.xml"
            }
        },
        {
            "rararchivefileformat,version2": "",
            "name": "RAR Archive File Format, Version 2",
            "id": "fdd000457",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000457.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000457.xml"
            }
        },
        {
            "rararchivefileformat,version2": "",
            "name": "RAR Archive File Format, Version 2",
            "id": "fdd000457",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000457.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000457.xml"
            }
        },
        {
            "rararchivefileformat,version2": "",
            "name": "RAR Archive File Format, Version 2",
            "id": "fdd000457",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000457.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000457.xml"
            }
        },
        {
            "rararchivefileformat,version2": "",
            "name": "RAR Archive File Format, Version 2",
            "id": "fdd000457",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000457.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000457.xml"
            }
        },
        {
            "rararchivefileformat,version2": "",
            "name": "RAR Archive File Format, Version 2",
            "id": "fdd000457",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000457.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000457.xml"
            }
        },
        {
            "rararchivefileformat,version2": "",
            "name": "RAR Archive File Format, Version 2",
            "id": "fdd000457",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000457.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000457.xml"
            }
        },
        {
            "rararchivefileformat,version2": "",
            "name": "RAR Archive File Format, Version 2",
            "id": "fdd000457",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000457.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000457.xml"
            }
        },
        {
            "rararchivefileformat,version4": "",
            "name": "RAR Archive File Format, Version 4",
            "id": "fdd000458",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000458.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000458.xml"
            }
        },
        {
            "rararchivefileformat,version4": "",
            "name": "RAR Archive File Format, Version 4",
            "id": "fdd000458",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000458.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000458.xml"
            }
        },
        {
            "rararchivefileformat,version4": "",
            "name": "RAR Archive File Format, Version 4",
            "id": "fdd000458",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000458.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000458.xml"
            }
        },
        {
            "rararchivefileformat,version4": "",
            "name": "RAR Archive File Format, Version 4",
            "id": "fdd000458",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000458.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000458.xml"
            }
        },
        {
            "rararchivefileformat,version4": "",
            "name": "RAR Archive File Format, Version 4",
            "id": "fdd000458",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000458.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000458.xml"
            }
        },
        {
            "rararchivefileformat,version4": "",
            "name": "RAR Archive File Format, Version 4",
            "id": "fdd000458",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000458.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000458.xml"
            }
        },
        {
            "rararchivefileformat,version4": "",
            "name": "RAR Archive File Format, Version 4",
            "id": "fdd000458",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000458.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000458.xml"
            }
        },
        {
            "rararchivefileformat,version4": "",
            "name": "RAR Archive File Format, Version 4",
            "id": "fdd000458",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000458.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000458.xml"
            }
        },
        {
            "rararchivefileformat,version3": "",
            "name": "RAR Archive File Format, Version 3",
            "id": "fdd000459",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000459.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000459.xml"
            }
        },
        {
            "rararchivefileformat,version3": "",
            "name": "RAR Archive File Format, Version 3",
            "id": "fdd000459",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000459.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000459.xml"
            }
        },
        {
            "rararchivefileformat,version3": "",
            "name": "RAR Archive File Format, Version 3",
            "id": "fdd000459",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000459.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000459.xml"
            }
        },
        {
            "rararchivefileformat,version3": "",
            "name": "RAR Archive File Format, Version 3",
            "id": "fdd000459",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000459.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000459.xml"
            }
        },
        {
            "rararchivefileformat,version3": "",
            "name": "RAR Archive File Format, Version 3",
            "id": "fdd000459",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000459.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000459.xml"
            }
        },
        {
            "rararchivefileformat,version3": "",
            "name": "RAR Archive File Format, Version 3",
            "id": "fdd000459",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000459.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000459.xml"
            }
        },
        {
            "rararchivefileformat,version3": "",
            "name": "RAR Archive File Format, Version 3",
            "id": "fdd000459",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000459.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000459.xml"
            }
        },
        {
            "rararchivefileformat,version3": "",
            "name": "RAR Archive File Format, Version 3",
            "id": "fdd000459",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000459.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000459.xml"
            }
        },
        {
            "rararchivefileformat,version5": "",
            "name": "RAR Archive File Format, Version 5",
            "id": "fdd000460",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000460.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000460.xml"
            }
        },
        {
            "rararchivefileformat,version5": "",
            "name": "RAR Archive File Format, Version 5",
            "id": "fdd000460",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000460.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000460.xml"
            }
        },
        {
            "rararchivefileformat,version5": "",
            "name": "RAR Archive File Format, Version 5",
            "id": "fdd000460",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000460.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000460.xml"
            }
        },
        {
            "rararchivefileformat,version5": "",
            "name": "RAR Archive File Format, Version 5",
            "id": "fdd000460",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000460.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000460.xml"
            }
        },
        {
            "rararchivefileformat,version5": "",
            "name": "RAR Archive File Format, Version 5",
            "id": "fdd000460",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000460.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000460.xml"
            }
        },
        {
            "rararchivefileformat,version5": "",
            "name": "RAR Archive File Format, Version 5",
            "id": "fdd000460",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000460.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000460.xml"
            }
        },
        {
            "rararchivefileformat,version5": "",
            "name": "RAR Archive File Format, Version 5",
            "id": "fdd000460",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000460.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000460.xml"
            }
        },
        {
            "rararchivefileformat,version5": "",
            "name": "RAR Archive File Format, Version 5",
            "id": "fdd000460",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000460.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000460.xml"
            }
        },
        {
            "sqlite,version3": "",
            "name": "SQLite, Version 3",
            "id": "fdd000461",
            "filenamepattern": [
                {
                    "value": "*.sqlite",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite"
                },
                {
                    "value": "*.sqlite3",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite3"
                },
                {
                    "value": "*.db3",
                    "url": "https://www.digipres.org/formats/extensions/#*.db3"
                },
                {
                    "value": "*.db",
                    "url": "https://www.digipres.org/formats/extensions/#*.db"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sqlite3    ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000461.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000461.xml"
            }
        },
        {
            "sqlite,version3": "",
            "name": "SQLite, Version 3",
            "id": "fdd000461",
            "filenamepattern": [
                {
                    "value": "*.sqlite",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite"
                },
                {
                    "value": "*.sqlite3",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite3"
                },
                {
                    "value": "*.db3",
                    "url": "https://www.digipres.org/formats/extensions/#*.db3"
                },
                {
                    "value": "*.db",
                    "url": "https://www.digipres.org/formats/extensions/#*.db"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sqlite3    ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000461.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000461.xml"
            }
        },
        {
            "sqlite,version3": "",
            "name": "SQLite, Version 3",
            "id": "fdd000461",
            "filenamepattern": [
                {
                    "value": "*.sqlite",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite"
                },
                {
                    "value": "*.sqlite3",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite3"
                },
                {
                    "value": "*.db3",
                    "url": "https://www.digipres.org/formats/extensions/#*.db3"
                },
                {
                    "value": "*.db",
                    "url": "https://www.digipres.org/formats/extensions/#*.db"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sqlite3    ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000461.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000461.xml"
            }
        },
        {
            "sqlite,version3": "",
            "name": "SQLite, Version 3",
            "id": "fdd000461",
            "filenamepattern": [
                {
                    "value": "*.sqlite",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite"
                },
                {
                    "value": "*.sqlite3",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite3"
                },
                {
                    "value": "*.db3",
                    "url": "https://www.digipres.org/formats/extensions/#*.db3"
                },
                {
                    "value": "*.db",
                    "url": "https://www.digipres.org/formats/extensions/#*.db"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sqlite3    ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000461.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000461.xml"
            }
        },
        {
            "sqlite,version3": "",
            "name": "SQLite, Version 3",
            "id": "fdd000461",
            "filenamepattern": [
                {
                    "value": "*.sqlite",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite"
                },
                {
                    "value": "*.sqlite3",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite3"
                },
                {
                    "value": "*.db3",
                    "url": "https://www.digipres.org/formats/extensions/#*.db3"
                },
                {
                    "value": "*.db",
                    "url": "https://www.digipres.org/formats/extensions/#*.db"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sqlite3    ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000461.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000461.xml"
            }
        },
        {
            "sqlite,version3": "",
            "name": "SQLite, Version 3",
            "id": "fdd000461",
            "filenamepattern": [
                {
                    "value": "*.sqlite",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite"
                },
                {
                    "value": "*.sqlite3",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite3"
                },
                {
                    "value": "*.db3",
                    "url": "https://www.digipres.org/formats/extensions/#*.db3"
                },
                {
                    "value": "*.db",
                    "url": "https://www.digipres.org/formats/extensions/#*.db"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sqlite3    ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000461.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000461.xml"
            }
        },
        {
            "sqlite,version3": "",
            "name": "SQLite, Version 3",
            "id": "fdd000461",
            "filenamepattern": [
                {
                    "value": "*.sqlite",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite"
                },
                {
                    "value": "*.sqlite3",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite3"
                },
                {
                    "value": "*.db3",
                    "url": "https://www.digipres.org/formats/extensions/#*.db3"
                },
                {
                    "value": "*.db",
                    "url": "https://www.digipres.org/formats/extensions/#*.db"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sqlite3    ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000461.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000461.xml"
            }
        },
        {
            "sqlite,version3": "",
            "name": "SQLite, Version 3",
            "id": "fdd000461",
            "filenamepattern": [
                {
                    "value": "*.sqlite",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite"
                },
                {
                    "value": "*.sqlite3",
                    "url": "https://www.digipres.org/formats/extensions/#*.sqlite3"
                },
                {
                    "value": "*.db3",
                    "url": "https://www.digipres.org/formats/extensions/#*.db3"
                },
                {
                    "value": "*.db",
                    "url": "https://www.digipres.org/formats/extensions/#*.db"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sqlite3    ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000461.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000461.xml"
            }
        },
        {
            "microsoftaccessmdbfileformatfamily": "",
            "name": "Microsoft Access MDB File Format Family",
            "id": "fdd000462",
            "filenamepattern": [
                {
                    "value": "*.mdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000462.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000462.xml"
            }
        },
        {
            "microsoftaccessmdbfileformatfamily": "",
            "name": "Microsoft Access MDB File Format Family",
            "id": "fdd000462",
            "filenamepattern": [
                {
                    "value": "*.mdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000462.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000462.xml"
            }
        },
        {
            "microsoftaccessmdbfileformatfamily": "",
            "name": "Microsoft Access MDB File Format Family",
            "id": "fdd000462",
            "filenamepattern": [
                {
                    "value": "*.mdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000462.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000462.xml"
            }
        },
        {
            "microsoftaccessmdbfileformatfamily": "",
            "name": "Microsoft Access MDB File Format Family",
            "id": "fdd000462",
            "filenamepattern": [
                {
                    "value": "*.mdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000462.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000462.xml"
            }
        },
        {
            "microsoftaccessmdbfileformatfamily": "",
            "name": "Microsoft Access MDB File Format Family",
            "id": "fdd000462",
            "filenamepattern": [
                {
                    "value": "*.mdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000462.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000462.xml"
            }
        },
        {
            "microsoftaccessmdbfileformatfamily": "",
            "name": "Microsoft Access MDB File Format Family",
            "id": "fdd000462",
            "filenamepattern": [
                {
                    "value": "*.mdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000462.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000462.xml"
            }
        },
        {
            "microsoftaccessmdbfileformatfamily": "",
            "name": "Microsoft Access MDB File Format Family",
            "id": "fdd000462",
            "filenamepattern": [
                {
                    "value": "*.mdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000462.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000462.xml"
            }
        },
        {
            "microsoftaccessmdbfileformatfamily": "",
            "name": "Microsoft Access MDB File Format Family",
            "id": "fdd000462",
            "filenamepattern": [
                {
                    "value": "*.mdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000462.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000462.xml"
            }
        },
        {
            "microsoftaccessaccdbfileformatfamily": "",
            "name": "Microsoft Access ACCDB File Format Family",
            "id": "fdd000463",
            "filenamepattern": [
                {
                    "value": "*.accdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.accdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000463.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000463.xml"
            }
        },
        {
            "microsoftaccessaccdbfileformatfamily": "",
            "name": "Microsoft Access ACCDB File Format Family",
            "id": "fdd000463",
            "filenamepattern": [
                {
                    "value": "*.accdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.accdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000463.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000463.xml"
            }
        },
        {
            "microsoftaccessaccdbfileformatfamily": "",
            "name": "Microsoft Access ACCDB File Format Family",
            "id": "fdd000463",
            "filenamepattern": [
                {
                    "value": "*.accdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.accdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000463.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000463.xml"
            }
        },
        {
            "microsoftaccessaccdbfileformatfamily": "",
            "name": "Microsoft Access ACCDB File Format Family",
            "id": "fdd000463",
            "filenamepattern": [
                {
                    "value": "*.accdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.accdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000463.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000463.xml"
            }
        },
        {
            "microsoftaccessaccdbfileformatfamily": "",
            "name": "Microsoft Access ACCDB File Format Family",
            "id": "fdd000463",
            "filenamepattern": [
                {
                    "value": "*.accdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.accdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000463.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000463.xml"
            }
        },
        {
            "microsoftaccessaccdbfileformatfamily": "",
            "name": "Microsoft Access ACCDB File Format Family",
            "id": "fdd000463",
            "filenamepattern": [
                {
                    "value": "*.accdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.accdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000463.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000463.xml"
            }
        },
        {
            "microsoftaccessaccdbfileformatfamily": "",
            "name": "Microsoft Access ACCDB File Format Family",
            "id": "fdd000463",
            "filenamepattern": [
                {
                    "value": "*.accdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.accdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000463.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000463.xml"
            }
        },
        {
            "microsoftaccessaccdbfileformatfamily": "",
            "name": "Microsoft Access ACCDB File Format Family",
            "id": "fdd000463",
            "filenamepattern": [
                {
                    "value": "*.accdb",
                    "url": "https://www.digipres.org/formats/extensions/#*.accdb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000463.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000463.xml"
            }
        },
        {
            "sastransportfileformat(xport)family": "",
            "name": "SAS Transport File Format (XPORT) Family",
            "id": "fdd000464",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sas-xport",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sas-xport"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000464.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000464.xml"
            }
        },
        {
            "sastransportfileformat(xport)family": "",
            "name": "SAS Transport File Format (XPORT) Family",
            "id": "fdd000464",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sas-xport",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sas-xport"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000464.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000464.xml"
            }
        },
        {
            "sastransportfileformat(xport)family": "",
            "name": "SAS Transport File Format (XPORT) Family",
            "id": "fdd000464",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sas-xport",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sas-xport"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000464.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000464.xml"
            }
        },
        {
            "sastransportfileformat(xport)family": "",
            "name": "SAS Transport File Format (XPORT) Family",
            "id": "fdd000464",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sas-xport",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sas-xport"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000464.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000464.xml"
            }
        },
        {
            "sastransportfileformat(xport)family": "",
            "name": "SAS Transport File Format (XPORT) Family",
            "id": "fdd000464",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sas-xport",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sas-xport"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000464.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000464.xml"
            }
        },
        {
            "sastransportfileformat(xport)family": "",
            "name": "SAS Transport File Format (XPORT) Family",
            "id": "fdd000464",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sas-xport",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sas-xport"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000464.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000464.xml"
            }
        },
        {
            "sastransportfileformat(xport)family": "",
            "name": "SAS Transport File Format (XPORT) Family",
            "id": "fdd000464",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sas-xport",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sas-xport"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000464.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000464.xml"
            }
        },
        {
            "sastransportfileformat(xport)family": "",
            "name": "SAS Transport File Format (XPORT) Family",
            "id": "fdd000464",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sas-xport",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sas-xport"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000464.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000464.xml"
            }
        },
        {
            "standardgeneralizedmarkuplanguage(sgml).iso8879:1986": "",
            "name": "Standard Generalized Markup Language (SGML).  ISO 8879:1986",
            "id": "fdd000465",
            "filenamepattern": [
                {
                    "value": "*.sgml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgml"
                },
                {
                    "value": "*.sgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sgml"
                },
                {
                    "value": "text/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/sgml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000465.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000465.xml"
            }
        },
        {
            "standardgeneralizedmarkuplanguage(sgml).iso8879:1986": "",
            "name": "Standard Generalized Markup Language (SGML).  ISO 8879:1986",
            "id": "fdd000465",
            "filenamepattern": [
                {
                    "value": "*.sgml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgml"
                },
                {
                    "value": "*.sgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sgml"
                },
                {
                    "value": "text/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/sgml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000465.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000465.xml"
            }
        },
        {
            "standardgeneralizedmarkuplanguage(sgml).iso8879:1986": "",
            "name": "Standard Generalized Markup Language (SGML).  ISO 8879:1986",
            "id": "fdd000465",
            "filenamepattern": [
                {
                    "value": "*.sgml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgml"
                },
                {
                    "value": "*.sgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sgml"
                },
                {
                    "value": "text/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/sgml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000465.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000465.xml"
            }
        },
        {
            "standardgeneralizedmarkuplanguage(sgml).iso8879:1986": "",
            "name": "Standard Generalized Markup Language (SGML).  ISO 8879:1986",
            "id": "fdd000465",
            "filenamepattern": [
                {
                    "value": "*.sgml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgml"
                },
                {
                    "value": "*.sgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sgml"
                },
                {
                    "value": "text/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/sgml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000465.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000465.xml"
            }
        },
        {
            "standardgeneralizedmarkuplanguage(sgml).iso8879:1986": "",
            "name": "Standard Generalized Markup Language (SGML).  ISO 8879:1986",
            "id": "fdd000465",
            "filenamepattern": [
                {
                    "value": "*.sgml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgml"
                },
                {
                    "value": "*.sgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sgml"
                },
                {
                    "value": "text/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/sgml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000465.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000465.xml"
            }
        },
        {
            "standardgeneralizedmarkuplanguage(sgml).iso8879:1986": "",
            "name": "Standard Generalized Markup Language (SGML).  ISO 8879:1986",
            "id": "fdd000465",
            "filenamepattern": [
                {
                    "value": "*.sgml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgml"
                },
                {
                    "value": "*.sgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sgml"
                },
                {
                    "value": "text/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/sgml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000465.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000465.xml"
            }
        },
        {
            "standardgeneralizedmarkuplanguage(sgml).iso8879:1986": "",
            "name": "Standard Generalized Markup Language (SGML).  ISO 8879:1986",
            "id": "fdd000465",
            "filenamepattern": [
                {
                    "value": "*.sgml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgml"
                },
                {
                    "value": "*.sgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sgml"
                },
                {
                    "value": "text/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/sgml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000465.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000465.xml"
            }
        },
        {
            "standardgeneralizedmarkuplanguage(sgml).iso8879:1986": "",
            "name": "Standard Generalized Markup Language (SGML).  ISO 8879:1986",
            "id": "fdd000465",
            "filenamepattern": [
                {
                    "value": "*.sgml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgml"
                },
                {
                    "value": "*.sgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.sgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sgml"
                },
                {
                    "value": "text/sgml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/sgml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000465.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000465.xml"
            }
        },
        {
            "sasversion5transportfileformat(xport)": "",
            "name": "SAS Version 5 Transport File Format (XPORT)",
            "id": "fdd000466",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000466.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000466.xml"
            }
        },
        {
            "sasversion5transportfileformat(xport)": "",
            "name": "SAS Version 5 Transport File Format (XPORT)",
            "id": "fdd000466",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000466.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000466.xml"
            }
        },
        {
            "sasversion5transportfileformat(xport)": "",
            "name": "SAS Version 5 Transport File Format (XPORT)",
            "id": "fdd000466",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000466.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000466.xml"
            }
        },
        {
            "sasversion5transportfileformat(xport)": "",
            "name": "SAS Version 5 Transport File Format (XPORT)",
            "id": "fdd000466",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000466.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000466.xml"
            }
        },
        {
            "sasversion5transportfileformat(xport)": "",
            "name": "SAS Version 5 Transport File Format (XPORT)",
            "id": "fdd000466",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000466.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000466.xml"
            }
        },
        {
            "sasversion5transportfileformat(xport)": "",
            "name": "SAS Version 5 Transport File Format (XPORT)",
            "id": "fdd000466",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000466.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000466.xml"
            }
        },
        {
            "sasversion5transportfileformat(xport)": "",
            "name": "SAS Version 5 Transport File Format (XPORT)",
            "id": "fdd000466",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000466.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000466.xml"
            }
        },
        {
            "sasversion5transportfileformat(xport)": "",
            "name": "SAS Version 5 Transport File Format (XPORT)",
            "id": "fdd000466",
            "filenamepattern": [
                {
                    "value": "*.xpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.xpt"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000466.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000466.xml"
            }
        },
        {
            "sasversion8transportfileformat(xport)": "",
            "name": "SAS Version 8 Transport File Format (XPORT)",
            "id": "fdd000467",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000467.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000467.xml"
            }
        },
        {
            "sasversion8transportfileformat(xport)": "",
            "name": "SAS Version 8 Transport File Format (XPORT)",
            "id": "fdd000467",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000467.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000467.xml"
            }
        },
        {
            "sasversion8transportfileformat(xport)": "",
            "name": "SAS Version 8 Transport File Format (XPORT)",
            "id": "fdd000467",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000467.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000467.xml"
            }
        },
        {
            "sasversion8transportfileformat(xport)": "",
            "name": "SAS Version 8 Transport File Format (XPORT)",
            "id": "fdd000467",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000467.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000467.xml"
            }
        },
        {
            "sasversion8transportfileformat(xport)": "",
            "name": "SAS Version 8 Transport File Format (XPORT)",
            "id": "fdd000467",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000467.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000467.xml"
            }
        },
        {
            "sasversion8transportfileformat(xport)": "",
            "name": "SAS Version 8 Transport File Format (XPORT)",
            "id": "fdd000467",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000467.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000467.xml"
            }
        },
        {
            "sasversion8transportfileformat(xport)": "",
            "name": "SAS Version 8 Transport File Format (XPORT)",
            "id": "fdd000467",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000467.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000467.xml"
            }
        },
        {
            "sasversion8transportfileformat(xport)": "",
            "name": "SAS Version 8 Transport File Format (XPORT)",
            "id": "fdd000467",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000467.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000467.xml"
            }
        },
        {
            "spssportablefile,asciiencoding": "",
            "name": "SPSS Portable File, ASCII encoding",
            "id": "fdd000468",
            "filenamepattern": [
                {
                    "value": "*.por",
                    "url": "https://www.digipres.org/formats/extensions/#*.por"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-por",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-por"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000468.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000468.xml"
            }
        },
        {
            "spssportablefile,asciiencoding": "",
            "name": "SPSS Portable File, ASCII encoding",
            "id": "fdd000468",
            "filenamepattern": [
                {
                    "value": "*.por",
                    "url": "https://www.digipres.org/formats/extensions/#*.por"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-por",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-por"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000468.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000468.xml"
            }
        },
        {
            "spssportablefile,asciiencoding": "",
            "name": "SPSS Portable File, ASCII encoding",
            "id": "fdd000468",
            "filenamepattern": [
                {
                    "value": "*.por",
                    "url": "https://www.digipres.org/formats/extensions/#*.por"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-por",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-por"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000468.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000468.xml"
            }
        },
        {
            "spssportablefile,asciiencoding": "",
            "name": "SPSS Portable File, ASCII encoding",
            "id": "fdd000468",
            "filenamepattern": [
                {
                    "value": "*.por",
                    "url": "https://www.digipres.org/formats/extensions/#*.por"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-por",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-por"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000468.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000468.xml"
            }
        },
        {
            "spssportablefile,asciiencoding": "",
            "name": "SPSS Portable File, ASCII encoding",
            "id": "fdd000468",
            "filenamepattern": [
                {
                    "value": "*.por",
                    "url": "https://www.digipres.org/formats/extensions/#*.por"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-por",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-por"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000468.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000468.xml"
            }
        },
        {
            "spssportablefile,asciiencoding": "",
            "name": "SPSS Portable File, ASCII encoding",
            "id": "fdd000468",
            "filenamepattern": [
                {
                    "value": "*.por",
                    "url": "https://www.digipres.org/formats/extensions/#*.por"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-por",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-por"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000468.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000468.xml"
            }
        },
        {
            "spssportablefile,asciiencoding": "",
            "name": "SPSS Portable File, ASCII encoding",
            "id": "fdd000468",
            "filenamepattern": [
                {
                    "value": "*.por",
                    "url": "https://www.digipres.org/formats/extensions/#*.por"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-por",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-por"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000468.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000468.xml"
            }
        },
        {
            "spssportablefile,asciiencoding": "",
            "name": "SPSS Portable File, ASCII encoding",
            "id": "fdd000468",
            "filenamepattern": [
                {
                    "value": "*.por",
                    "url": "https://www.digipres.org/formats/extensions/#*.por"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-por",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-por"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000468.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000468.xml"
            }
        },
        {
            "spsssystemdatafileformatfamily(.sav)": "",
            "name": "SPSS System Data File Format Family (.sav)",
            "id": "fdd000469",
            "filenamepattern": [
                {
                    "value": "*.zsav",
                    "url": "https://www.digipres.org/formats/extensions/#*.zsav"
                },
                {
                    "value": "*.sav",
                    "url": "https://www.digipres.org/formats/extensions/#*.sav"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-sav",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-sav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000469.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000469.xml"
            }
        },
        {
            "spsssystemdatafileformatfamily(.sav)": "",
            "name": "SPSS System Data File Format Family (.sav)",
            "id": "fdd000469",
            "filenamepattern": [
                {
                    "value": "*.zsav",
                    "url": "https://www.digipres.org/formats/extensions/#*.zsav"
                },
                {
                    "value": "*.sav",
                    "url": "https://www.digipres.org/formats/extensions/#*.sav"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-sav",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-sav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000469.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000469.xml"
            }
        },
        {
            "spsssystemdatafileformatfamily(.sav)": "",
            "name": "SPSS System Data File Format Family (.sav)",
            "id": "fdd000469",
            "filenamepattern": [
                {
                    "value": "*.zsav",
                    "url": "https://www.digipres.org/formats/extensions/#*.zsav"
                },
                {
                    "value": "*.sav",
                    "url": "https://www.digipres.org/formats/extensions/#*.sav"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-sav",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-sav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000469.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000469.xml"
            }
        },
        {
            "spsssystemdatafileformatfamily(.sav)": "",
            "name": "SPSS System Data File Format Family (.sav)",
            "id": "fdd000469",
            "filenamepattern": [
                {
                    "value": "*.zsav",
                    "url": "https://www.digipres.org/formats/extensions/#*.zsav"
                },
                {
                    "value": "*.sav",
                    "url": "https://www.digipres.org/formats/extensions/#*.sav"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-sav",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-sav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000469.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000469.xml"
            }
        },
        {
            "spsssystemdatafileformatfamily(.sav)": "",
            "name": "SPSS System Data File Format Family (.sav)",
            "id": "fdd000469",
            "filenamepattern": [
                {
                    "value": "*.zsav",
                    "url": "https://www.digipres.org/formats/extensions/#*.zsav"
                },
                {
                    "value": "*.sav",
                    "url": "https://www.digipres.org/formats/extensions/#*.sav"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-sav",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-sav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000469.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000469.xml"
            }
        },
        {
            "spsssystemdatafileformatfamily(.sav)": "",
            "name": "SPSS System Data File Format Family (.sav)",
            "id": "fdd000469",
            "filenamepattern": [
                {
                    "value": "*.zsav",
                    "url": "https://www.digipres.org/formats/extensions/#*.zsav"
                },
                {
                    "value": "*.sav",
                    "url": "https://www.digipres.org/formats/extensions/#*.sav"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-sav",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-sav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000469.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000469.xml"
            }
        },
        {
            "spsssystemdatafileformatfamily(.sav)": "",
            "name": "SPSS System Data File Format Family (.sav)",
            "id": "fdd000469",
            "filenamepattern": [
                {
                    "value": "*.zsav",
                    "url": "https://www.digipres.org/formats/extensions/#*.zsav"
                },
                {
                    "value": "*.sav",
                    "url": "https://www.digipres.org/formats/extensions/#*.sav"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-sav",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-sav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000469.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000469.xml"
            }
        },
        {
            "spsssystemdatafileformatfamily(.sav)": "",
            "name": "SPSS System Data File Format Family (.sav)",
            "id": "fdd000469",
            "filenamepattern": [
                {
                    "value": "*.zsav",
                    "url": "https://www.digipres.org/formats/extensions/#*.zsav"
                },
                {
                    "value": "*.sav",
                    "url": "https://www.digipres.org/formats/extensions/#*.sav"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-spss-sav",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-spss-sav"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000469.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000469.xml"
            }
        },
        {
            "rdataformatfamily(.rdata,.rda)": "",
            "name": "R Data Format Family (.rdata, .rda)",
            "id": "fdd000470",
            "filenamepattern": [
                {
                    "value": "*.rdata",
                    "url": "https://www.digipres.org/formats/extensions/#*.rdata"
                },
                {
                    "value": "*.rda",
                    "url": "https://www.digipres.org/formats/extensions/#*.rda"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000470.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000470.xml"
            }
        },
        {
            "rdataformatfamily(.rdata,.rda)": "",
            "name": "R Data Format Family (.rdata, .rda)",
            "id": "fdd000470",
            "filenamepattern": [
                {
                    "value": "*.rdata",
                    "url": "https://www.digipres.org/formats/extensions/#*.rdata"
                },
                {
                    "value": "*.rda",
                    "url": "https://www.digipres.org/formats/extensions/#*.rda"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000470.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000470.xml"
            }
        },
        {
            "rdataformatfamily(.rdata,.rda)": "",
            "name": "R Data Format Family (.rdata, .rda)",
            "id": "fdd000470",
            "filenamepattern": [
                {
                    "value": "*.rdata",
                    "url": "https://www.digipres.org/formats/extensions/#*.rdata"
                },
                {
                    "value": "*.rda",
                    "url": "https://www.digipres.org/formats/extensions/#*.rda"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000470.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000470.xml"
            }
        },
        {
            "rdataformatfamily(.rdata,.rda)": "",
            "name": "R Data Format Family (.rdata, .rda)",
            "id": "fdd000470",
            "filenamepattern": [
                {
                    "value": "*.rdata",
                    "url": "https://www.digipres.org/formats/extensions/#*.rdata"
                },
                {
                    "value": "*.rda",
                    "url": "https://www.digipres.org/formats/extensions/#*.rda"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000470.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000470.xml"
            }
        },
        {
            "rdataformatfamily(.rdata,.rda)": "",
            "name": "R Data Format Family (.rdata, .rda)",
            "id": "fdd000470",
            "filenamepattern": [
                {
                    "value": "*.rdata",
                    "url": "https://www.digipres.org/formats/extensions/#*.rdata"
                },
                {
                    "value": "*.rda",
                    "url": "https://www.digipres.org/formats/extensions/#*.rda"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000470.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000470.xml"
            }
        },
        {
            "rdataformatfamily(.rdata,.rda)": "",
            "name": "R Data Format Family (.rdata, .rda)",
            "id": "fdd000470",
            "filenamepattern": [
                {
                    "value": "*.rdata",
                    "url": "https://www.digipres.org/formats/extensions/#*.rdata"
                },
                {
                    "value": "*.rda",
                    "url": "https://www.digipres.org/formats/extensions/#*.rda"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000470.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000470.xml"
            }
        },
        {
            "rdataformatfamily(.rdata,.rda)": "",
            "name": "R Data Format Family (.rdata, .rda)",
            "id": "fdd000470",
            "filenamepattern": [
                {
                    "value": "*.rdata",
                    "url": "https://www.digipres.org/formats/extensions/#*.rdata"
                },
                {
                    "value": "*.rda",
                    "url": "https://www.digipres.org/formats/extensions/#*.rda"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000470.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000470.xml"
            }
        },
        {
            "rdataformatfamily(.rdata,.rda)": "",
            "name": "R Data Format Family (.rdata, .rda)",
            "id": "fdd000470",
            "filenamepattern": [
                {
                    "value": "*.rdata",
                    "url": "https://www.digipres.org/formats/extensions/#*.rdata"
                },
                {
                    "value": "*.rda",
                    "url": "https://www.digipres.org/formats/extensions/#*.rda"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000470.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000470.xml"
            }
        },
        {
            "statadatafileformat(.dta),version118": "",
            "name": "Stata Data File Format (.dta), Version 118",
            "id": "fdd000471",
            "filenamepattern": [
                {
                    "value": "*.dta",
                    "url": "https://www.digipres.org/formats/extensions/#*.dta"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000471.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000471.xml"
            }
        },
        {
            "statadatafileformat(.dta),version118": "",
            "name": "Stata Data File Format (.dta), Version 118",
            "id": "fdd000471",
            "filenamepattern": [
                {
                    "value": "*.dta",
                    "url": "https://www.digipres.org/formats/extensions/#*.dta"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000471.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000471.xml"
            }
        },
        {
            "statadatafileformat(.dta),version118": "",
            "name": "Stata Data File Format (.dta), Version 118",
            "id": "fdd000471",
            "filenamepattern": [
                {
                    "value": "*.dta",
                    "url": "https://www.digipres.org/formats/extensions/#*.dta"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000471.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000471.xml"
            }
        },
        {
            "statadatafileformat(.dta),version118": "",
            "name": "Stata Data File Format (.dta), Version 118",
            "id": "fdd000471",
            "filenamepattern": [
                {
                    "value": "*.dta",
                    "url": "https://www.digipres.org/formats/extensions/#*.dta"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000471.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000471.xml"
            }
        },
        {
            "statadatafileformat(.dta),version118": "",
            "name": "Stata Data File Format (.dta), Version 118",
            "id": "fdd000471",
            "filenamepattern": [
                {
                    "value": "*.dta",
                    "url": "https://www.digipres.org/formats/extensions/#*.dta"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000471.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000471.xml"
            }
        },
        {
            "statadatafileformat(.dta),version118": "",
            "name": "Stata Data File Format (.dta), Version 118",
            "id": "fdd000471",
            "filenamepattern": [
                {
                    "value": "*.dta",
                    "url": "https://www.digipres.org/formats/extensions/#*.dta"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000471.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000471.xml"
            }
        },
        {
            "statadatafileformat(.dta),version118": "",
            "name": "Stata Data File Format (.dta), Version 118",
            "id": "fdd000471",
            "filenamepattern": [
                {
                    "value": "*.dta",
                    "url": "https://www.digipres.org/formats/extensions/#*.dta"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000471.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000471.xml"
            }
        },
        {
            "statadatafileformat(.dta),version118": "",
            "name": "Stata Data File Format (.dta), Version 118",
            "id": "fdd000471",
            "filenamepattern": [
                {
                    "value": "*.dta",
                    "url": "https://www.digipres.org/formats/extensions/#*.dta"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000471.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000471.xml"
            }
        },
        {
            "mobipocketfileformat": "",
            "name": "Mobipocket File Format",
            "id": "fdd000472",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                },
                {
                    "value": "*.mobi",
                    "url": "https://www.digipres.org/formats/extensions/#*.mobi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mobipocket-ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mobipocket-ebook"
                },
                {
                    "value": "application/vnd.amazon.ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.amazon.ebook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000472.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000472.xml"
            }
        },
        {
            "mobipocketfileformat": "",
            "name": "Mobipocket File Format",
            "id": "fdd000472",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                },
                {
                    "value": "*.mobi",
                    "url": "https://www.digipres.org/formats/extensions/#*.mobi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mobipocket-ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mobipocket-ebook"
                },
                {
                    "value": "application/vnd.amazon.ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.amazon.ebook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000472.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000472.xml"
            }
        },
        {
            "mobipocketfileformat": "",
            "name": "Mobipocket File Format",
            "id": "fdd000472",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                },
                {
                    "value": "*.mobi",
                    "url": "https://www.digipres.org/formats/extensions/#*.mobi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mobipocket-ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mobipocket-ebook"
                },
                {
                    "value": "application/vnd.amazon.ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.amazon.ebook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000472.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000472.xml"
            }
        },
        {
            "mobipocketfileformat": "",
            "name": "Mobipocket File Format",
            "id": "fdd000472",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                },
                {
                    "value": "*.mobi",
                    "url": "https://www.digipres.org/formats/extensions/#*.mobi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mobipocket-ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mobipocket-ebook"
                },
                {
                    "value": "application/vnd.amazon.ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.amazon.ebook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000472.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000472.xml"
            }
        },
        {
            "mobipocketfileformat": "",
            "name": "Mobipocket File Format",
            "id": "fdd000472",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                },
                {
                    "value": "*.mobi",
                    "url": "https://www.digipres.org/formats/extensions/#*.mobi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mobipocket-ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mobipocket-ebook"
                },
                {
                    "value": "application/vnd.amazon.ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.amazon.ebook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000472.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000472.xml"
            }
        },
        {
            "mobipocketfileformat": "",
            "name": "Mobipocket File Format",
            "id": "fdd000472",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                },
                {
                    "value": "*.mobi",
                    "url": "https://www.digipres.org/formats/extensions/#*.mobi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mobipocket-ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mobipocket-ebook"
                },
                {
                    "value": "application/vnd.amazon.ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.amazon.ebook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000472.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000472.xml"
            }
        },
        {
            "mobipocketfileformat": "",
            "name": "Mobipocket File Format",
            "id": "fdd000472",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                },
                {
                    "value": "*.mobi",
                    "url": "https://www.digipres.org/formats/extensions/#*.mobi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mobipocket-ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mobipocket-ebook"
                },
                {
                    "value": "application/vnd.amazon.ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.amazon.ebook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000472.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000472.xml"
            }
        },
        {
            "mobipocketfileformat": "",
            "name": "Mobipocket File Format",
            "id": "fdd000472",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                },
                {
                    "value": "*.mobi",
                    "url": "https://www.digipres.org/formats/extensions/#*.mobi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mobipocket-ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mobipocket-ebook"
                },
                {
                    "value": "application/vnd.amazon.ebook",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.amazon.ebook"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000472.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000472.xml"
            }
        },
        {
            "richtextformat(rtf)family": "",
            "name": "Rich Text Format (RTF) Family",
            "id": "fdd000473",
            "filenamepattern": [
                {
                    "value": "*.rtf",
                    "url": "https://www.digipres.org/formats/extensions/#*.rtf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/rtf"
                },
                {
                    "value": "text/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/rtf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000473.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000473.xml"
            }
        },
        {
            "richtextformat(rtf)family": "",
            "name": "Rich Text Format (RTF) Family",
            "id": "fdd000473",
            "filenamepattern": [
                {
                    "value": "*.rtf",
                    "url": "https://www.digipres.org/formats/extensions/#*.rtf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/rtf"
                },
                {
                    "value": "text/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/rtf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000473.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000473.xml"
            }
        },
        {
            "richtextformat(rtf)family": "",
            "name": "Rich Text Format (RTF) Family",
            "id": "fdd000473",
            "filenamepattern": [
                {
                    "value": "*.rtf",
                    "url": "https://www.digipres.org/formats/extensions/#*.rtf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/rtf"
                },
                {
                    "value": "text/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/rtf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000473.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000473.xml"
            }
        },
        {
            "richtextformat(rtf)family": "",
            "name": "Rich Text Format (RTF) Family",
            "id": "fdd000473",
            "filenamepattern": [
                {
                    "value": "*.rtf",
                    "url": "https://www.digipres.org/formats/extensions/#*.rtf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/rtf"
                },
                {
                    "value": "text/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/rtf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000473.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000473.xml"
            }
        },
        {
            "richtextformat(rtf)family": "",
            "name": "Rich Text Format (RTF) Family",
            "id": "fdd000473",
            "filenamepattern": [
                {
                    "value": "*.rtf",
                    "url": "https://www.digipres.org/formats/extensions/#*.rtf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/rtf"
                },
                {
                    "value": "text/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/rtf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000473.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000473.xml"
            }
        },
        {
            "richtextformat(rtf)family": "",
            "name": "Rich Text Format (RTF) Family",
            "id": "fdd000473",
            "filenamepattern": [
                {
                    "value": "*.rtf",
                    "url": "https://www.digipres.org/formats/extensions/#*.rtf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/rtf"
                },
                {
                    "value": "text/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/rtf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000473.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000473.xml"
            }
        },
        {
            "richtextformat(rtf)family": "",
            "name": "Rich Text Format (RTF) Family",
            "id": "fdd000473",
            "filenamepattern": [
                {
                    "value": "*.rtf",
                    "url": "https://www.digipres.org/formats/extensions/#*.rtf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/rtf"
                },
                {
                    "value": "text/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/rtf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000473.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000473.xml"
            }
        },
        {
            "richtextformat(rtf)family": "",
            "name": "Rich Text Format (RTF) Family",
            "id": "fdd000473",
            "filenamepattern": [
                {
                    "value": "*.rtf",
                    "url": "https://www.digipres.org/formats/extensions/#*.rtf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/rtf"
                },
                {
                    "value": "text/rtf",
                    "url": "https://www.digipres.org/formats/mime-types/#text/rtf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000473.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000473.xml"
            }
        },
        {
            "pdf2.0,iso320002(2017,2020)": "",
            "name": "PDF 2.0, ISO 32000-2 (2017, 2020)",
            "id": "fdd000474",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000474.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000474.xml"
            }
        },
        {
            "pdf2.0,iso320002(2017,2020)": "",
            "name": "PDF 2.0, ISO 32000-2 (2017, 2020)",
            "id": "fdd000474",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000474.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000474.xml"
            }
        },
        {
            "pdf2.0,iso320002(2017,2020)": "",
            "name": "PDF 2.0, ISO 32000-2 (2017, 2020)",
            "id": "fdd000474",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000474.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000474.xml"
            }
        },
        {
            "pdf2.0,iso320002(2017,2020)": "",
            "name": "PDF 2.0, ISO 32000-2 (2017, 2020)",
            "id": "fdd000474",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000474.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000474.xml"
            }
        },
        {
            "pdf2.0,iso320002(2017,2020)": "",
            "name": "PDF 2.0, ISO 32000-2 (2017, 2020)",
            "id": "fdd000474",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000474.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000474.xml"
            }
        },
        {
            "pdf2.0,iso320002(2017,2020)": "",
            "name": "PDF 2.0, ISO 32000-2 (2017, 2020)",
            "id": "fdd000474",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000474.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000474.xml"
            }
        },
        {
            "pdf2.0,iso320002(2017,2020)": "",
            "name": "PDF 2.0, ISO 32000-2 (2017, 2020)",
            "id": "fdd000474",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000474.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000474.xml"
            }
        },
        {
            "pdf2.0,iso320002(2017,2020)": "",
            "name": "PDF 2.0, ISO 32000-2 (2017, 2020)",
            "id": "fdd000474",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000474.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000474.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)formatfamily": "",
            "name": "HyperText Markup Language (HTML) Format Family",
            "id": "fdd000475",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000475.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000475.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)formatfamily": "",
            "name": "HyperText Markup Language (HTML) Format Family",
            "id": "fdd000475",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000475.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000475.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)formatfamily": "",
            "name": "HyperText Markup Language (HTML) Format Family",
            "id": "fdd000475",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000475.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000475.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)formatfamily": "",
            "name": "HyperText Markup Language (HTML) Format Family",
            "id": "fdd000475",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000475.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000475.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)formatfamily": "",
            "name": "HyperText Markup Language (HTML) Format Family",
            "id": "fdd000475",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000475.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000475.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)formatfamily": "",
            "name": "HyperText Markup Language (HTML) Format Family",
            "id": "fdd000475",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000475.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000475.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)formatfamily": "",
            "name": "HyperText Markup Language (HTML) Format Family",
            "id": "fdd000475",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000475.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000475.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)formatfamily": "",
            "name": "HyperText Markup Language (HTML) Format Family",
            "id": "fdd000475",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000475.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000475.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html),versionspriorto2.0": "",
            "name": "HyperText Markup Language (HTML), versions prior to 2.0",
            "id": "fdd000476",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000476.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000476.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html),versionspriorto2.0": "",
            "name": "HyperText Markup Language (HTML), versions prior to 2.0",
            "id": "fdd000476",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000476.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000476.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html),versionspriorto2.0": "",
            "name": "HyperText Markup Language (HTML), versions prior to 2.0",
            "id": "fdd000476",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000476.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000476.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html),versionspriorto2.0": "",
            "name": "HyperText Markup Language (HTML), versions prior to 2.0",
            "id": "fdd000476",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000476.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000476.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html),versionspriorto2.0": "",
            "name": "HyperText Markup Language (HTML), versions prior to 2.0",
            "id": "fdd000476",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000476.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000476.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html),versionspriorto2.0": "",
            "name": "HyperText Markup Language (HTML), versions prior to 2.0",
            "id": "fdd000476",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000476.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000476.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html),versionspriorto2.0": "",
            "name": "HyperText Markup Language (HTML), versions prior to 2.0",
            "id": "fdd000476",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000476.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000476.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html),versionspriorto2.0": "",
            "name": "HyperText Markup Language (HTML), versions prior to 2.0",
            "id": "fdd000476",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000476.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000476.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)2.0": "",
            "name": "HyperText Markup Language (HTML) 2.0",
            "id": "fdd000477",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000477.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000477.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)2.0": "",
            "name": "HyperText Markup Language (HTML) 2.0",
            "id": "fdd000477",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000477.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000477.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)2.0": "",
            "name": "HyperText Markup Language (HTML) 2.0",
            "id": "fdd000477",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000477.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000477.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)2.0": "",
            "name": "HyperText Markup Language (HTML) 2.0",
            "id": "fdd000477",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000477.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000477.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)2.0": "",
            "name": "HyperText Markup Language (HTML) 2.0",
            "id": "fdd000477",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000477.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000477.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)2.0": "",
            "name": "HyperText Markup Language (HTML) 2.0",
            "id": "fdd000477",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000477.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000477.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)2.0": "",
            "name": "HyperText Markup Language (HTML) 2.0",
            "id": "fdd000477",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000477.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000477.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)2.0": "",
            "name": "HyperText Markup Language (HTML) 2.0",
            "id": "fdd000477",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000477.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000477.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)3.2": "",
            "name": "HyperText Markup Language (HTML) 3.2",
            "id": "fdd000478",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000478.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000478.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)3.2": "",
            "name": "HyperText Markup Language (HTML) 3.2",
            "id": "fdd000478",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000478.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000478.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)3.2": "",
            "name": "HyperText Markup Language (HTML) 3.2",
            "id": "fdd000478",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000478.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000478.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)3.2": "",
            "name": "HyperText Markup Language (HTML) 3.2",
            "id": "fdd000478",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000478.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000478.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)3.2": "",
            "name": "HyperText Markup Language (HTML) 3.2",
            "id": "fdd000478",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000478.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000478.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)3.2": "",
            "name": "HyperText Markup Language (HTML) 3.2",
            "id": "fdd000478",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000478.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000478.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)3.2": "",
            "name": "HyperText Markup Language (HTML) 3.2",
            "id": "fdd000478",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000478.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000478.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)3.2": "",
            "name": "HyperText Markup Language (HTML) 3.2",
            "id": "fdd000478",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000478.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000478.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.0": "",
            "name": "HyperText Markup Language (HTML) 4.0",
            "id": "fdd000479",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000479.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000479.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.0": "",
            "name": "HyperText Markup Language (HTML) 4.0",
            "id": "fdd000479",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000479.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000479.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.0": "",
            "name": "HyperText Markup Language (HTML) 4.0",
            "id": "fdd000479",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000479.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000479.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.0": "",
            "name": "HyperText Markup Language (HTML) 4.0",
            "id": "fdd000479",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000479.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000479.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.0": "",
            "name": "HyperText Markup Language (HTML) 4.0",
            "id": "fdd000479",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000479.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000479.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.0": "",
            "name": "HyperText Markup Language (HTML) 4.0",
            "id": "fdd000479",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000479.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000479.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.0": "",
            "name": "HyperText Markup Language (HTML) 4.0",
            "id": "fdd000479",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000479.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000479.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.0": "",
            "name": "HyperText Markup Language (HTML) 4.0",
            "id": "fdd000479",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000479.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000479.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.01": "",
            "name": "HyperText Markup Language (HTML) 4.01",
            "id": "fdd000480",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000480.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000480.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.01": "",
            "name": "HyperText Markup Language (HTML) 4.01",
            "id": "fdd000480",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000480.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000480.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.01": "",
            "name": "HyperText Markup Language (HTML) 4.01",
            "id": "fdd000480",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000480.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000480.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.01": "",
            "name": "HyperText Markup Language (HTML) 4.01",
            "id": "fdd000480",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000480.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000480.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.01": "",
            "name": "HyperText Markup Language (HTML) 4.01",
            "id": "fdd000480",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000480.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000480.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.01": "",
            "name": "HyperText Markup Language (HTML) 4.01",
            "id": "fdd000480",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000480.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000480.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.01": "",
            "name": "HyperText Markup Language (HTML) 4.01",
            "id": "fdd000480",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000480.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000480.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)4.01": "",
            "name": "HyperText Markup Language (HTML) 4.01",
            "id": "fdd000480",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000480.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000480.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)5": "",
            "name": "HyperText Markup Language (HTML) 5",
            "id": "fdd000481",
            "filenamepattern": [
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000481.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000481.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)5": "",
            "name": "HyperText Markup Language (HTML) 5",
            "id": "fdd000481",
            "filenamepattern": [
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000481.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000481.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)5": "",
            "name": "HyperText Markup Language (HTML) 5",
            "id": "fdd000481",
            "filenamepattern": [
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000481.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000481.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)5": "",
            "name": "HyperText Markup Language (HTML) 5",
            "id": "fdd000481",
            "filenamepattern": [
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000481.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000481.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)5": "",
            "name": "HyperText Markup Language (HTML) 5",
            "id": "fdd000481",
            "filenamepattern": [
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000481.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000481.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)5": "",
            "name": "HyperText Markup Language (HTML) 5",
            "id": "fdd000481",
            "filenamepattern": [
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000481.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000481.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)5": "",
            "name": "HyperText Markup Language (HTML) 5",
            "id": "fdd000481",
            "filenamepattern": [
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000481.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000481.xml"
            }
        },
        {
            "hypertextmarkuplanguage(html)5": "",
            "name": "HyperText Markup Language (HTML) 5",
            "id": "fdd000481",
            "filenamepattern": [
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000481.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000481.xml"
            }
        },
        {
            "cascadingstylesheet(css)fileformat": "",
            "name": "Cascading Style Sheet (CSS) File Format",
            "id": "fdd000482",
            "filenamepattern": [
                {
                    "value": "*.css",
                    "url": "https://www.digipres.org/formats/extensions/#*.css"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/css",
                    "url": "https://www.digipres.org/formats/mime-types/#text/css"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000482.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000482.xml"
            }
        },
        {
            "cascadingstylesheet(css)fileformat": "",
            "name": "Cascading Style Sheet (CSS) File Format",
            "id": "fdd000482",
            "filenamepattern": [
                {
                    "value": "*.css",
                    "url": "https://www.digipres.org/formats/extensions/#*.css"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/css",
                    "url": "https://www.digipres.org/formats/mime-types/#text/css"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000482.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000482.xml"
            }
        },
        {
            "cascadingstylesheet(css)fileformat": "",
            "name": "Cascading Style Sheet (CSS) File Format",
            "id": "fdd000482",
            "filenamepattern": [
                {
                    "value": "*.css",
                    "url": "https://www.digipres.org/formats/extensions/#*.css"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/css",
                    "url": "https://www.digipres.org/formats/mime-types/#text/css"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000482.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000482.xml"
            }
        },
        {
            "cascadingstylesheet(css)fileformat": "",
            "name": "Cascading Style Sheet (CSS) File Format",
            "id": "fdd000482",
            "filenamepattern": [
                {
                    "value": "*.css",
                    "url": "https://www.digipres.org/formats/extensions/#*.css"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/css",
                    "url": "https://www.digipres.org/formats/mime-types/#text/css"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000482.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000482.xml"
            }
        },
        {
            "cascadingstylesheet(css)fileformat": "",
            "name": "Cascading Style Sheet (CSS) File Format",
            "id": "fdd000482",
            "filenamepattern": [
                {
                    "value": "*.css",
                    "url": "https://www.digipres.org/formats/extensions/#*.css"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/css",
                    "url": "https://www.digipres.org/formats/mime-types/#text/css"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000482.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000482.xml"
            }
        },
        {
            "cascadingstylesheet(css)fileformat": "",
            "name": "Cascading Style Sheet (CSS) File Format",
            "id": "fdd000482",
            "filenamepattern": [
                {
                    "value": "*.css",
                    "url": "https://www.digipres.org/formats/extensions/#*.css"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/css",
                    "url": "https://www.digipres.org/formats/mime-types/#text/css"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000482.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000482.xml"
            }
        },
        {
            "cascadingstylesheet(css)fileformat": "",
            "name": "Cascading Style Sheet (CSS) File Format",
            "id": "fdd000482",
            "filenamepattern": [
                {
                    "value": "*.css",
                    "url": "https://www.digipres.org/formats/extensions/#*.css"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/css",
                    "url": "https://www.digipres.org/formats/mime-types/#text/css"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000482.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000482.xml"
            }
        },
        {
            "cascadingstylesheet(css)fileformat": "",
            "name": "Cascading Style Sheet (CSS) File Format",
            "id": "fdd000482",
            "filenamepattern": [
                {
                    "value": "*.css",
                    "url": "https://www.digipres.org/formats/extensions/#*.css"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/css",
                    "url": "https://www.digipres.org/formats/mime-types/#text/css"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000482.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000482.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml),1.0": "",
            "name": "Extensible HyperText Markup Language (XHTML), 1.0",
            "id": "fdd000483",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000483.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000483.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml),1.0": "",
            "name": "Extensible HyperText Markup Language (XHTML), 1.0",
            "id": "fdd000483",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000483.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000483.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml),1.0": "",
            "name": "Extensible HyperText Markup Language (XHTML), 1.0",
            "id": "fdd000483",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000483.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000483.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml),1.0": "",
            "name": "Extensible HyperText Markup Language (XHTML), 1.0",
            "id": "fdd000483",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000483.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000483.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml),1.0": "",
            "name": "Extensible HyperText Markup Language (XHTML), 1.0",
            "id": "fdd000483",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000483.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000483.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml),1.0": "",
            "name": "Extensible HyperText Markup Language (XHTML), 1.0",
            "id": "fdd000483",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000483.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000483.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml),1.0": "",
            "name": "Extensible HyperText Markup Language (XHTML), 1.0",
            "id": "fdd000483",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000483.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000483.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml),1.0": "",
            "name": "Extensible HyperText Markup Language (XHTML), 1.0",
            "id": "fdd000483",
            "filenamepattern": [
                {
                    "value": "*.htm",
                    "url": "https://www.digipres.org/formats/extensions/#*.htm"
                },
                {
                    "value": "*.xhtml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhtml"
                },
                {
                    "value": "*.xht",
                    "url": "https://www.digipres.org/formats/extensions/#*.xht"
                },
                {
                    "value": "*.html",
                    "url": "https://www.digipres.org/formats/extensions/#*.html"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xhtml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xhtml+xml"
                },
                {
                    "value": "text/html",
                    "url": "https://www.digipres.org/formats/mime-types/#text/html"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000483.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000483.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml)1.1": "",
            "name": "Extensible HyperText Markup Language (XHTML) 1.1",
            "id": "fdd000484",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000484.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000484.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml)1.1": "",
            "name": "Extensible HyperText Markup Language (XHTML) 1.1",
            "id": "fdd000484",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000484.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000484.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml)1.1": "",
            "name": "Extensible HyperText Markup Language (XHTML) 1.1",
            "id": "fdd000484",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000484.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000484.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml)1.1": "",
            "name": "Extensible HyperText Markup Language (XHTML) 1.1",
            "id": "fdd000484",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000484.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000484.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml)1.1": "",
            "name": "Extensible HyperText Markup Language (XHTML) 1.1",
            "id": "fdd000484",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000484.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000484.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml)1.1": "",
            "name": "Extensible HyperText Markup Language (XHTML) 1.1",
            "id": "fdd000484",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000484.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000484.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml)1.1": "",
            "name": "Extensible HyperText Markup Language (XHTML) 1.1",
            "id": "fdd000484",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000484.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000484.xml"
            }
        },
        {
            "extensiblehypertextmarkuplanguage(xhtml)1.1": "",
            "name": "Extensible HyperText Markup Language (XHTML) 1.1",
            "id": "fdd000484",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000484.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000484.xml"
            }
        },
        {
            "transportneutralencapsulationformat": "",
            "name": "Transport Neutral Encapsulation Format",
            "id": "fdd000485",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tnef",
                    "url": "https://www.digipres.org/formats/extensions/#*.tnef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-tnef",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-tnef"
                },
                {
                    "value": "application/ms-TNEF",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ms-tnef"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000485.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000485.xml"
            }
        },
        {
            "transportneutralencapsulationformat": "",
            "name": "Transport Neutral Encapsulation Format",
            "id": "fdd000485",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tnef",
                    "url": "https://www.digipres.org/formats/extensions/#*.tnef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-tnef",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-tnef"
                },
                {
                    "value": "application/ms-TNEF",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ms-tnef"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000485.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000485.xml"
            }
        },
        {
            "transportneutralencapsulationformat": "",
            "name": "Transport Neutral Encapsulation Format",
            "id": "fdd000485",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tnef",
                    "url": "https://www.digipres.org/formats/extensions/#*.tnef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-tnef",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-tnef"
                },
                {
                    "value": "application/ms-TNEF",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ms-tnef"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000485.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000485.xml"
            }
        },
        {
            "transportneutralencapsulationformat": "",
            "name": "Transport Neutral Encapsulation Format",
            "id": "fdd000485",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tnef",
                    "url": "https://www.digipres.org/formats/extensions/#*.tnef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-tnef",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-tnef"
                },
                {
                    "value": "application/ms-TNEF",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ms-tnef"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000485.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000485.xml"
            }
        },
        {
            "transportneutralencapsulationformat": "",
            "name": "Transport Neutral Encapsulation Format",
            "id": "fdd000485",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tnef",
                    "url": "https://www.digipres.org/formats/extensions/#*.tnef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-tnef",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-tnef"
                },
                {
                    "value": "application/ms-TNEF",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ms-tnef"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000485.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000485.xml"
            }
        },
        {
            "transportneutralencapsulationformat": "",
            "name": "Transport Neutral Encapsulation Format",
            "id": "fdd000485",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tnef",
                    "url": "https://www.digipres.org/formats/extensions/#*.tnef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-tnef",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-tnef"
                },
                {
                    "value": "application/ms-TNEF",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ms-tnef"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000485.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000485.xml"
            }
        },
        {
            "transportneutralencapsulationformat": "",
            "name": "Transport Neutral Encapsulation Format",
            "id": "fdd000485",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tnef",
                    "url": "https://www.digipres.org/formats/extensions/#*.tnef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-tnef",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-tnef"
                },
                {
                    "value": "application/ms-TNEF",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ms-tnef"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000485.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000485.xml"
            }
        },
        {
            "transportneutralencapsulationformat": "",
            "name": "Transport Neutral Encapsulation Format",
            "id": "fdd000485",
            "filenamepattern": [
                {
                    "value": "*.dat",
                    "url": "https://www.digipres.org/formats/extensions/#*.dat"
                },
                {
                    "value": "*.tnef",
                    "url": "https://www.digipres.org/formats/extensions/#*.tnef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-tnef",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-tnef"
                },
                {
                    "value": "application/ms-TNEF",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ms-tnef"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000485.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000485.xml"
            }
        },
        {
            "reflectancetransformationimaging(rti)fileformat": "",
            "name": "Reflectance Transformation Imaging (RTI) File Format",
            "id": "fdd000486",
            "filenamepattern": [
                {
                    "value": "*.rti",
                    "url": "https://www.digipres.org/formats/extensions/#*.rti"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000486.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000486.xml"
            }
        },
        {
            "reflectancetransformationimaging(rti)fileformat": "",
            "name": "Reflectance Transformation Imaging (RTI) File Format",
            "id": "fdd000486",
            "filenamepattern": [
                {
                    "value": "*.rti",
                    "url": "https://www.digipres.org/formats/extensions/#*.rti"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000486.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000486.xml"
            }
        },
        {
            "reflectancetransformationimaging(rti)fileformat": "",
            "name": "Reflectance Transformation Imaging (RTI) File Format",
            "id": "fdd000486",
            "filenamepattern": [
                {
                    "value": "*.rti",
                    "url": "https://www.digipres.org/formats/extensions/#*.rti"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000486.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000486.xml"
            }
        },
        {
            "reflectancetransformationimaging(rti)fileformat": "",
            "name": "Reflectance Transformation Imaging (RTI) File Format",
            "id": "fdd000486",
            "filenamepattern": [
                {
                    "value": "*.rti",
                    "url": "https://www.digipres.org/formats/extensions/#*.rti"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000486.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000486.xml"
            }
        },
        {
            "reflectancetransformationimaging(rti)fileformat": "",
            "name": "Reflectance Transformation Imaging (RTI) File Format",
            "id": "fdd000486",
            "filenamepattern": [
                {
                    "value": "*.rti",
                    "url": "https://www.digipres.org/formats/extensions/#*.rti"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000486.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000486.xml"
            }
        },
        {
            "reflectancetransformationimaging(rti)fileformat": "",
            "name": "Reflectance Transformation Imaging (RTI) File Format",
            "id": "fdd000486",
            "filenamepattern": [
                {
                    "value": "*.rti",
                    "url": "https://www.digipres.org/formats/extensions/#*.rti"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000486.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000486.xml"
            }
        },
        {
            "reflectancetransformationimaging(rti)fileformat": "",
            "name": "Reflectance Transformation Imaging (RTI) File Format",
            "id": "fdd000486",
            "filenamepattern": [
                {
                    "value": "*.rti",
                    "url": "https://www.digipres.org/formats/extensions/#*.rti"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000486.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000486.xml"
            }
        },
        {
            "reflectancetransformationimaging(rti)fileformat": "",
            "name": "Reflectance Transformation Imaging (RTI) File Format",
            "id": "fdd000486",
            "filenamepattern": [
                {
                    "value": "*.rti",
                    "url": "https://www.digipres.org/formats/extensions/#*.rti"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000486.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000486.xml"
            }
        },
        {
            "polynomialtexturemap(ptm)fileformat": "",
            "name": "Polynomial Texture Map (PTM) File Format",
            "id": "fdd000487",
            "filenamepattern": [
                {
                    "value": "*.ptm",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000487.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000487.xml"
            }
        },
        {
            "polynomialtexturemap(ptm)fileformat": "",
            "name": "Polynomial Texture Map (PTM) File Format",
            "id": "fdd000487",
            "filenamepattern": [
                {
                    "value": "*.ptm",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000487.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000487.xml"
            }
        },
        {
            "polynomialtexturemap(ptm)fileformat": "",
            "name": "Polynomial Texture Map (PTM) File Format",
            "id": "fdd000487",
            "filenamepattern": [
                {
                    "value": "*.ptm",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000487.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000487.xml"
            }
        },
        {
            "polynomialtexturemap(ptm)fileformat": "",
            "name": "Polynomial Texture Map (PTM) File Format",
            "id": "fdd000487",
            "filenamepattern": [
                {
                    "value": "*.ptm",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000487.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000487.xml"
            }
        },
        {
            "polynomialtexturemap(ptm)fileformat": "",
            "name": "Polynomial Texture Map (PTM) File Format",
            "id": "fdd000487",
            "filenamepattern": [
                {
                    "value": "*.ptm",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000487.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000487.xml"
            }
        },
        {
            "polynomialtexturemap(ptm)fileformat": "",
            "name": "Polynomial Texture Map (PTM) File Format",
            "id": "fdd000487",
            "filenamepattern": [
                {
                    "value": "*.ptm",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000487.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000487.xml"
            }
        },
        {
            "polynomialtexturemap(ptm)fileformat": "",
            "name": "Polynomial Texture Map (PTM) File Format",
            "id": "fdd000487",
            "filenamepattern": [
                {
                    "value": "*.ptm",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000487.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000487.xml"
            }
        },
        {
            "polynomialtexturemap(ptm)fileformat": "",
            "name": "Polynomial Texture Map (PTM) File Format",
            "id": "fdd000487",
            "filenamepattern": [
                {
                    "value": "*.ptm",
                    "url": "https://www.digipres.org/formats/extensions/#*.ptm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000487.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000487.xml"
            }
        },
        {
            "onixforbooks": "",
            "name": "ONIX for Books",
            "id": "fdd000488",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000488.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000488.xml"
            }
        },
        {
            "onixforbooks": "",
            "name": "ONIX for Books",
            "id": "fdd000488",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000488.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000488.xml"
            }
        },
        {
            "onixforbooks": "",
            "name": "ONIX for Books",
            "id": "fdd000488",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000488.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000488.xml"
            }
        },
        {
            "onixforbooks": "",
            "name": "ONIX for Books",
            "id": "fdd000488",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000488.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000488.xml"
            }
        },
        {
            "onixforbooks": "",
            "name": "ONIX for Books",
            "id": "fdd000488",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000488.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000488.xml"
            }
        },
        {
            "onixforbooks": "",
            "name": "ONIX for Books",
            "id": "fdd000488",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000488.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000488.xml"
            }
        },
        {
            "onixforbooks": "",
            "name": "ONIX for Books",
            "id": "fdd000488",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000488.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000488.xml"
            }
        },
        {
            "onixforbooks": "",
            "name": "ONIX for Books",
            "id": "fdd000488",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000488.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000488.xml"
            }
        },
        {
            "endnotelibraryformat": "",
            "name": "EndNote Library Format",
            "id": "fdd000489",
            "filenamepattern": [
                {
                    "value": "*.enlp",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlp"
                },
                {
                    "value": "*.enl",
                    "url": "https://www.digipres.org/formats/extensions/#*.enl"
                },
                {
                    "value": "*.enlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-endnote-library",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-library"
                },
                {
                    "value": "application/x-endnote-refer",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-refer"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000489.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000489.xml"
            }
        },
        {
            "endnotelibraryformat": "",
            "name": "EndNote Library Format",
            "id": "fdd000489",
            "filenamepattern": [
                {
                    "value": "*.enlp",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlp"
                },
                {
                    "value": "*.enl",
                    "url": "https://www.digipres.org/formats/extensions/#*.enl"
                },
                {
                    "value": "*.enlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-endnote-library",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-library"
                },
                {
                    "value": "application/x-endnote-refer",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-refer"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000489.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000489.xml"
            }
        },
        {
            "endnotelibraryformat": "",
            "name": "EndNote Library Format",
            "id": "fdd000489",
            "filenamepattern": [
                {
                    "value": "*.enlp",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlp"
                },
                {
                    "value": "*.enl",
                    "url": "https://www.digipres.org/formats/extensions/#*.enl"
                },
                {
                    "value": "*.enlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-endnote-library",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-library"
                },
                {
                    "value": "application/x-endnote-refer",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-refer"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000489.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000489.xml"
            }
        },
        {
            "endnotelibraryformat": "",
            "name": "EndNote Library Format",
            "id": "fdd000489",
            "filenamepattern": [
                {
                    "value": "*.enlp",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlp"
                },
                {
                    "value": "*.enl",
                    "url": "https://www.digipres.org/formats/extensions/#*.enl"
                },
                {
                    "value": "*.enlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-endnote-library",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-library"
                },
                {
                    "value": "application/x-endnote-refer",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-refer"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000489.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000489.xml"
            }
        },
        {
            "endnotelibraryformat": "",
            "name": "EndNote Library Format",
            "id": "fdd000489",
            "filenamepattern": [
                {
                    "value": "*.enlp",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlp"
                },
                {
                    "value": "*.enl",
                    "url": "https://www.digipres.org/formats/extensions/#*.enl"
                },
                {
                    "value": "*.enlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-endnote-library",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-library"
                },
                {
                    "value": "application/x-endnote-refer",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-refer"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000489.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000489.xml"
            }
        },
        {
            "endnotelibraryformat": "",
            "name": "EndNote Library Format",
            "id": "fdd000489",
            "filenamepattern": [
                {
                    "value": "*.enlp",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlp"
                },
                {
                    "value": "*.enl",
                    "url": "https://www.digipres.org/formats/extensions/#*.enl"
                },
                {
                    "value": "*.enlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-endnote-library",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-library"
                },
                {
                    "value": "application/x-endnote-refer",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-refer"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000489.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000489.xml"
            }
        },
        {
            "endnotelibraryformat": "",
            "name": "EndNote Library Format",
            "id": "fdd000489",
            "filenamepattern": [
                {
                    "value": "*.enlp",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlp"
                },
                {
                    "value": "*.enl",
                    "url": "https://www.digipres.org/formats/extensions/#*.enl"
                },
                {
                    "value": "*.enlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-endnote-library",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-library"
                },
                {
                    "value": "application/x-endnote-refer",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-refer"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000489.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000489.xml"
            }
        },
        {
            "endnotelibraryformat": "",
            "name": "EndNote Library Format",
            "id": "fdd000489",
            "filenamepattern": [
                {
                    "value": "*.enlp",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlp"
                },
                {
                    "value": "*.enl",
                    "url": "https://www.digipres.org/formats/extensions/#*.enl"
                },
                {
                    "value": "*.enlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.enlx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-endnote-library",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-library"
                },
                {
                    "value": "application/x-endnote-refer",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-endnote-refer"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000489.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000489.xml"
            }
        },
        {
            "extensible3d(x3d)fileformatfamily": "",
            "name": "Extensible 3D (X3D) File Format Family",
            "id": "fdd000490",
            "filenamepattern": [
                {
                    "value": "*.x3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3d"
                },
                {
                    "value": "*.x3db",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3db"
                },
                {
                    "value": "*.x3dbz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dbz"
                },
                {
                    "value": "*.x3dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dv"
                },
                {
                    "value": "*.x3dvz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dvz"
                },
                {
                    "value": "*.x3dz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/x3d+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+xml"
                },
                {
                    "value": "model/x3d-vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d-vrml"
                },
                {
                    "value": "model/x3d+fastinfoset",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+fastinfoset"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000490.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000490.xml"
            }
        },
        {
            "extensible3d(x3d)fileformatfamily": "",
            "name": "Extensible 3D (X3D) File Format Family",
            "id": "fdd000490",
            "filenamepattern": [
                {
                    "value": "*.x3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3d"
                },
                {
                    "value": "*.x3db",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3db"
                },
                {
                    "value": "*.x3dbz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dbz"
                },
                {
                    "value": "*.x3dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dv"
                },
                {
                    "value": "*.x3dvz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dvz"
                },
                {
                    "value": "*.x3dz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/x3d+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+xml"
                },
                {
                    "value": "model/x3d-vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d-vrml"
                },
                {
                    "value": "model/x3d+fastinfoset",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+fastinfoset"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000490.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000490.xml"
            }
        },
        {
            "extensible3d(x3d)fileformatfamily": "",
            "name": "Extensible 3D (X3D) File Format Family",
            "id": "fdd000490",
            "filenamepattern": [
                {
                    "value": "*.x3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3d"
                },
                {
                    "value": "*.x3db",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3db"
                },
                {
                    "value": "*.x3dbz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dbz"
                },
                {
                    "value": "*.x3dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dv"
                },
                {
                    "value": "*.x3dvz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dvz"
                },
                {
                    "value": "*.x3dz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/x3d+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+xml"
                },
                {
                    "value": "model/x3d-vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d-vrml"
                },
                {
                    "value": "model/x3d+fastinfoset",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+fastinfoset"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000490.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000490.xml"
            }
        },
        {
            "extensible3d(x3d)fileformatfamily": "",
            "name": "Extensible 3D (X3D) File Format Family",
            "id": "fdd000490",
            "filenamepattern": [
                {
                    "value": "*.x3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3d"
                },
                {
                    "value": "*.x3db",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3db"
                },
                {
                    "value": "*.x3dbz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dbz"
                },
                {
                    "value": "*.x3dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dv"
                },
                {
                    "value": "*.x3dvz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dvz"
                },
                {
                    "value": "*.x3dz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/x3d+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+xml"
                },
                {
                    "value": "model/x3d-vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d-vrml"
                },
                {
                    "value": "model/x3d+fastinfoset",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+fastinfoset"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000490.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000490.xml"
            }
        },
        {
            "extensible3d(x3d)fileformatfamily": "",
            "name": "Extensible 3D (X3D) File Format Family",
            "id": "fdd000490",
            "filenamepattern": [
                {
                    "value": "*.x3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3d"
                },
                {
                    "value": "*.x3db",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3db"
                },
                {
                    "value": "*.x3dbz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dbz"
                },
                {
                    "value": "*.x3dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dv"
                },
                {
                    "value": "*.x3dvz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dvz"
                },
                {
                    "value": "*.x3dz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/x3d+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+xml"
                },
                {
                    "value": "model/x3d-vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d-vrml"
                },
                {
                    "value": "model/x3d+fastinfoset",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+fastinfoset"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000490.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000490.xml"
            }
        },
        {
            "extensible3d(x3d)fileformatfamily": "",
            "name": "Extensible 3D (X3D) File Format Family",
            "id": "fdd000490",
            "filenamepattern": [
                {
                    "value": "*.x3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3d"
                },
                {
                    "value": "*.x3db",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3db"
                },
                {
                    "value": "*.x3dbz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dbz"
                },
                {
                    "value": "*.x3dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dv"
                },
                {
                    "value": "*.x3dvz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dvz"
                },
                {
                    "value": "*.x3dz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/x3d+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+xml"
                },
                {
                    "value": "model/x3d-vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d-vrml"
                },
                {
                    "value": "model/x3d+fastinfoset",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+fastinfoset"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000490.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000490.xml"
            }
        },
        {
            "extensible3d(x3d)fileformatfamily": "",
            "name": "Extensible 3D (X3D) File Format Family",
            "id": "fdd000490",
            "filenamepattern": [
                {
                    "value": "*.x3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3d"
                },
                {
                    "value": "*.x3db",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3db"
                },
                {
                    "value": "*.x3dbz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dbz"
                },
                {
                    "value": "*.x3dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dv"
                },
                {
                    "value": "*.x3dvz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dvz"
                },
                {
                    "value": "*.x3dz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/x3d+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+xml"
                },
                {
                    "value": "model/x3d-vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d-vrml"
                },
                {
                    "value": "model/x3d+fastinfoset",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+fastinfoset"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000490.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000490.xml"
            }
        },
        {
            "extensible3d(x3d)fileformatfamily": "",
            "name": "Extensible 3D (X3D) File Format Family",
            "id": "fdd000490",
            "filenamepattern": [
                {
                    "value": "*.x3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3d"
                },
                {
                    "value": "*.x3db",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3db"
                },
                {
                    "value": "*.x3dbz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dbz"
                },
                {
                    "value": "*.x3dv",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dv"
                },
                {
                    "value": "*.x3dvz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dvz"
                },
                {
                    "value": "*.x3dz",
                    "url": "https://www.digipres.org/formats/extensions/#*.x3dz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/x3d+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+xml"
                },
                {
                    "value": "model/x3d-vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d-vrml"
                },
                {
                    "value": "model/x3d+fastinfoset",
                    "url": "https://www.digipres.org/formats/mime-types/#model/x3d+fastinfoset"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000490.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000490.xml"
            }
        },
        {
            "universal3d(u3d)formatfamily.ecma363,editions14": "",
            "name": "Universal 3D (U3D) format family. ECMA-363, Editions 1-4",
            "id": "fdd000491",
            "filenamepattern": [
                {
                    "value": "*.u3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.u3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/u3d",
                    "url": "https://www.digipres.org/formats/mime-types/#model/u3d"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000491.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000491.xml"
            }
        },
        {
            "universal3d(u3d)formatfamily.ecma363,editions14": "",
            "name": "Universal 3D (U3D) format family. ECMA-363, Editions 1-4",
            "id": "fdd000491",
            "filenamepattern": [
                {
                    "value": "*.u3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.u3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/u3d",
                    "url": "https://www.digipres.org/formats/mime-types/#model/u3d"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000491.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000491.xml"
            }
        },
        {
            "universal3d(u3d)formatfamily.ecma363,editions14": "",
            "name": "Universal 3D (U3D) format family. ECMA-363, Editions 1-4",
            "id": "fdd000491",
            "filenamepattern": [
                {
                    "value": "*.u3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.u3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/u3d",
                    "url": "https://www.digipres.org/formats/mime-types/#model/u3d"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000491.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000491.xml"
            }
        },
        {
            "universal3d(u3d)formatfamily.ecma363,editions14": "",
            "name": "Universal 3D (U3D) format family. ECMA-363, Editions 1-4",
            "id": "fdd000491",
            "filenamepattern": [
                {
                    "value": "*.u3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.u3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/u3d",
                    "url": "https://www.digipres.org/formats/mime-types/#model/u3d"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000491.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000491.xml"
            }
        },
        {
            "universal3d(u3d)formatfamily.ecma363,editions14": "",
            "name": "Universal 3D (U3D) format family. ECMA-363, Editions 1-4",
            "id": "fdd000491",
            "filenamepattern": [
                {
                    "value": "*.u3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.u3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/u3d",
                    "url": "https://www.digipres.org/formats/mime-types/#model/u3d"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000491.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000491.xml"
            }
        },
        {
            "universal3d(u3d)formatfamily.ecma363,editions14": "",
            "name": "Universal 3D (U3D) format family. ECMA-363, Editions 1-4",
            "id": "fdd000491",
            "filenamepattern": [
                {
                    "value": "*.u3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.u3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/u3d",
                    "url": "https://www.digipres.org/formats/mime-types/#model/u3d"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000491.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000491.xml"
            }
        },
        {
            "universal3d(u3d)formatfamily.ecma363,editions14": "",
            "name": "Universal 3D (U3D) format family. ECMA-363, Editions 1-4",
            "id": "fdd000491",
            "filenamepattern": [
                {
                    "value": "*.u3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.u3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/u3d",
                    "url": "https://www.digipres.org/formats/mime-types/#model/u3d"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000491.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000491.xml"
            }
        },
        {
            "universal3d(u3d)formatfamily.ecma363,editions14": "",
            "name": "Universal 3D (U3D) format family. ECMA-363, Editions 1-4",
            "id": "fdd000491",
            "filenamepattern": [
                {
                    "value": "*.u3d",
                    "url": "https://www.digipres.org/formats/extensions/#*.u3d"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/u3d",
                    "url": "https://www.digipres.org/formats/mime-types/#model/u3d"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000491.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000491.xml"
            }
        },
        {
            "slpkscenelayerpackage(fori3slayers)": "",
            "name": "SLPK Scene Layer Package (for I3S layers)",
            "id": "fdd000492",
            "filenamepattern": [
                {
                    "value": "*.spk",
                    "url": "https://www.digipres.org/formats/extensions/#*.spk"
                },
                {
                    "value": "*.slpk",
                    "url": "https://www.digipres.org/formats/extensions/#*.slpk"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000492.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000492.xml"
            }
        },
        {
            "slpkscenelayerpackage(fori3slayers)": "",
            "name": "SLPK Scene Layer Package (for I3S layers)",
            "id": "fdd000492",
            "filenamepattern": [
                {
                    "value": "*.spk",
                    "url": "https://www.digipres.org/formats/extensions/#*.spk"
                },
                {
                    "value": "*.slpk",
                    "url": "https://www.digipres.org/formats/extensions/#*.slpk"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000492.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000492.xml"
            }
        },
        {
            "slpkscenelayerpackage(fori3slayers)": "",
            "name": "SLPK Scene Layer Package (for I3S layers)",
            "id": "fdd000492",
            "filenamepattern": [
                {
                    "value": "*.spk",
                    "url": "https://www.digipres.org/formats/extensions/#*.spk"
                },
                {
                    "value": "*.slpk",
                    "url": "https://www.digipres.org/formats/extensions/#*.slpk"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000492.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000492.xml"
            }
        },
        {
            "slpkscenelayerpackage(fori3slayers)": "",
            "name": "SLPK Scene Layer Package (for I3S layers)",
            "id": "fdd000492",
            "filenamepattern": [
                {
                    "value": "*.spk",
                    "url": "https://www.digipres.org/formats/extensions/#*.spk"
                },
                {
                    "value": "*.slpk",
                    "url": "https://www.digipres.org/formats/extensions/#*.slpk"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000492.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000492.xml"
            }
        },
        {
            "slpkscenelayerpackage(fori3slayers)": "",
            "name": "SLPK Scene Layer Package (for I3S layers)",
            "id": "fdd000492",
            "filenamepattern": [
                {
                    "value": "*.spk",
                    "url": "https://www.digipres.org/formats/extensions/#*.spk"
                },
                {
                    "value": "*.slpk",
                    "url": "https://www.digipres.org/formats/extensions/#*.slpk"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000492.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000492.xml"
            }
        },
        {
            "slpkscenelayerpackage(fori3slayers)": "",
            "name": "SLPK Scene Layer Package (for I3S layers)",
            "id": "fdd000492",
            "filenamepattern": [
                {
                    "value": "*.spk",
                    "url": "https://www.digipres.org/formats/extensions/#*.spk"
                },
                {
                    "value": "*.slpk",
                    "url": "https://www.digipres.org/formats/extensions/#*.slpk"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000492.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000492.xml"
            }
        },
        {
            "slpkscenelayerpackage(fori3slayers)": "",
            "name": "SLPK Scene Layer Package (for I3S layers)",
            "id": "fdd000492",
            "filenamepattern": [
                {
                    "value": "*.spk",
                    "url": "https://www.digipres.org/formats/extensions/#*.spk"
                },
                {
                    "value": "*.slpk",
                    "url": "https://www.digipres.org/formats/extensions/#*.slpk"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000492.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000492.xml"
            }
        },
        {
            "slpkscenelayerpackage(fori3slayers)": "",
            "name": "SLPK Scene Layer Package (for I3S layers)",
            "id": "fdd000492",
            "filenamepattern": [
                {
                    "value": "*.spk",
                    "url": "https://www.digipres.org/formats/extensions/#*.spk"
                },
                {
                    "value": "*.slpk",
                    "url": "https://www.digipres.org/formats/extensions/#*.slpk"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000492.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000492.xml"
            }
        },
        {
            "pdf/r1(rasterimagetransportandstorage.)basedonpdf1.41.7(iso320001)": "",
            "name": "PDF/R-1 (Raster image transport and storage.) Based on PDF 1.4-1.7 (ISO 32000-1)",
            "id": "fdd000493",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000493.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000493.xml"
            }
        },
        {
            "pdf/r1(rasterimagetransportandstorage.)basedonpdf1.41.7(iso320001)": "",
            "name": "PDF/R-1 (Raster image transport and storage.) Based on PDF 1.4-1.7 (ISO 32000-1)",
            "id": "fdd000493",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000493.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000493.xml"
            }
        },
        {
            "pdf/r1(rasterimagetransportandstorage.)basedonpdf1.41.7(iso320001)": "",
            "name": "PDF/R-1 (Raster image transport and storage.) Based on PDF 1.4-1.7 (ISO 32000-1)",
            "id": "fdd000493",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000493.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000493.xml"
            }
        },
        {
            "pdf/r1(rasterimagetransportandstorage.)basedonpdf1.41.7(iso320001)": "",
            "name": "PDF/R-1 (Raster image transport and storage.) Based on PDF 1.4-1.7 (ISO 32000-1)",
            "id": "fdd000493",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000493.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000493.xml"
            }
        },
        {
            "pdf/r1(rasterimagetransportandstorage.)basedonpdf1.41.7(iso320001)": "",
            "name": "PDF/R-1 (Raster image transport and storage.) Based on PDF 1.4-1.7 (ISO 32000-1)",
            "id": "fdd000493",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000493.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000493.xml"
            }
        },
        {
            "pdf/r1(rasterimagetransportandstorage.)basedonpdf1.41.7(iso320001)": "",
            "name": "PDF/R-1 (Raster image transport and storage.) Based on PDF 1.4-1.7 (ISO 32000-1)",
            "id": "fdd000493",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000493.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000493.xml"
            }
        },
        {
            "pdf/r1(rasterimagetransportandstorage.)basedonpdf1.41.7(iso320001)": "",
            "name": "PDF/R-1 (Raster image transport and storage.) Based on PDF 1.4-1.7 (ISO 32000-1)",
            "id": "fdd000493",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000493.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000493.xml"
            }
        },
        {
            "pdf/r1(rasterimagetransportandstorage.)basedonpdf1.41.7(iso320001)": "",
            "name": "PDF/R-1 (Raster image transport and storage.) Based on PDF 1.4-1.7 (ISO 32000-1)",
            "id": "fdd000493",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000493.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000493.xml"
            }
        },
        {
            "pdf/r1,(rasterimagetransportandstorage).encrypted,basedonpdf2.0(iso320002)": "",
            "name": "PDF/R-1, (Raster image transport and storage). Encrypted, based on PDF 2.0 (ISO 32000-2)",
            "id": "fdd000494",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000494.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000494.xml"
            }
        },
        {
            "pdf/r1,(rasterimagetransportandstorage).encrypted,basedonpdf2.0(iso320002)": "",
            "name": "PDF/R-1, (Raster image transport and storage). Encrypted, based on PDF 2.0 (ISO 32000-2)",
            "id": "fdd000494",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000494.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000494.xml"
            }
        },
        {
            "pdf/r1,(rasterimagetransportandstorage).encrypted,basedonpdf2.0(iso320002)": "",
            "name": "PDF/R-1, (Raster image transport and storage). Encrypted, based on PDF 2.0 (ISO 32000-2)",
            "id": "fdd000494",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000494.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000494.xml"
            }
        },
        {
            "pdf/r1,(rasterimagetransportandstorage).encrypted,basedonpdf2.0(iso320002)": "",
            "name": "PDF/R-1, (Raster image transport and storage). Encrypted, based on PDF 2.0 (ISO 32000-2)",
            "id": "fdd000494",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000494.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000494.xml"
            }
        },
        {
            "pdf/r1,(rasterimagetransportandstorage).encrypted,basedonpdf2.0(iso320002)": "",
            "name": "PDF/R-1, (Raster image transport and storage). Encrypted, based on PDF 2.0 (ISO 32000-2)",
            "id": "fdd000494",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000494.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000494.xml"
            }
        },
        {
            "pdf/r1,(rasterimagetransportandstorage).encrypted,basedonpdf2.0(iso320002)": "",
            "name": "PDF/R-1, (Raster image transport and storage). Encrypted, based on PDF 2.0 (ISO 32000-2)",
            "id": "fdd000494",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000494.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000494.xml"
            }
        },
        {
            "pdf/r1,(rasterimagetransportandstorage).encrypted,basedonpdf2.0(iso320002)": "",
            "name": "PDF/R-1, (Raster image transport and storage). Encrypted, based on PDF 2.0 (ISO 32000-2)",
            "id": "fdd000494",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000494.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000494.xml"
            }
        },
        {
            "pdf/r1,(rasterimagetransportandstorage).encrypted,basedonpdf2.0(iso320002)": "",
            "name": "PDF/R-1, (Raster image transport and storage). Encrypted, based on PDF 2.0 (ISO 32000-2)",
            "id": "fdd000494",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000494.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000494.xml"
            }
        },
        {
            "pdf/e1,engineeringdocumentformat,iso245171:2008": "",
            "name": "PDF/E-1, Engineering Document Format, ISO 24517-1:2008",
            "id": "fdd000495",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000495.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000495.xml"
            }
        },
        {
            "pdf/e1,engineeringdocumentformat,iso245171:2008": "",
            "name": "PDF/E-1, Engineering Document Format, ISO 24517-1:2008",
            "id": "fdd000495",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000495.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000495.xml"
            }
        },
        {
            "pdf/e1,engineeringdocumentformat,iso245171:2008": "",
            "name": "PDF/E-1, Engineering Document Format, ISO 24517-1:2008",
            "id": "fdd000495",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000495.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000495.xml"
            }
        },
        {
            "pdf/e1,engineeringdocumentformat,iso245171:2008": "",
            "name": "PDF/E-1, Engineering Document Format, ISO 24517-1:2008",
            "id": "fdd000495",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000495.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000495.xml"
            }
        },
        {
            "pdf/e1,engineeringdocumentformat,iso245171:2008": "",
            "name": "PDF/E-1, Engineering Document Format, ISO 24517-1:2008",
            "id": "fdd000495",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000495.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000495.xml"
            }
        },
        {
            "pdf/e1,engineeringdocumentformat,iso245171:2008": "",
            "name": "PDF/E-1, Engineering Document Format, ISO 24517-1:2008",
            "id": "fdd000495",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000495.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000495.xml"
            }
        },
        {
            "pdf/e1,engineeringdocumentformat,iso245171:2008": "",
            "name": "PDF/E-1, Engineering Document Format, ISO 24517-1:2008",
            "id": "fdd000495",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000495.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000495.xml"
            }
        },
        {
            "pdf/e1,engineeringdocumentformat,iso245171:2008": "",
            "name": "PDF/E-1, Engineering Document Format, ISO 24517-1:2008",
            "id": "fdd000495",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000495.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000495.xml"
            }
        },
        {
            "productrepresentationcompact(prc)fileformat": "",
            "name": "Product Representation Compact (PRC) File Format",
            "id": "fdd000496",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/prc",
                    "url": "https://www.digipres.org/formats/mime-types/#model/prc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000496.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000496.xml"
            }
        },
        {
            "productrepresentationcompact(prc)fileformat": "",
            "name": "Product Representation Compact (PRC) File Format",
            "id": "fdd000496",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/prc",
                    "url": "https://www.digipres.org/formats/mime-types/#model/prc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000496.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000496.xml"
            }
        },
        {
            "productrepresentationcompact(prc)fileformat": "",
            "name": "Product Representation Compact (PRC) File Format",
            "id": "fdd000496",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/prc",
                    "url": "https://www.digipres.org/formats/mime-types/#model/prc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000496.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000496.xml"
            }
        },
        {
            "productrepresentationcompact(prc)fileformat": "",
            "name": "Product Representation Compact (PRC) File Format",
            "id": "fdd000496",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/prc",
                    "url": "https://www.digipres.org/formats/mime-types/#model/prc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000496.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000496.xml"
            }
        },
        {
            "productrepresentationcompact(prc)fileformat": "",
            "name": "Product Representation Compact (PRC) File Format",
            "id": "fdd000496",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/prc",
                    "url": "https://www.digipres.org/formats/mime-types/#model/prc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000496.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000496.xml"
            }
        },
        {
            "productrepresentationcompact(prc)fileformat": "",
            "name": "Product Representation Compact (PRC) File Format",
            "id": "fdd000496",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/prc",
                    "url": "https://www.digipres.org/formats/mime-types/#model/prc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000496.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000496.xml"
            }
        },
        {
            "productrepresentationcompact(prc)fileformat": "",
            "name": "Product Representation Compact (PRC) File Format",
            "id": "fdd000496",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/prc",
                    "url": "https://www.digipres.org/formats/mime-types/#model/prc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000496.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000496.xml"
            }
        },
        {
            "productrepresentationcompact(prc)fileformat": "",
            "name": "Product Representation Compact (PRC) File Format",
            "id": "fdd000496",
            "filenamepattern": [
                {
                    "value": "*.prc",
                    "url": "https://www.digipres.org/formats/extensions/#*.prc"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/prc",
                    "url": "https://www.digipres.org/formats/mime-types/#model/prc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000496.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000496.xml"
            }
        },
        {
            "gltf(gltransmissionformat)family": "",
            "name": "glTF (GL Transmission Format) Family",
            "id": "fdd000498",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000498.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000498.xml"
            }
        },
        {
            "gltf(gltransmissionformat)family": "",
            "name": "glTF (GL Transmission Format) Family",
            "id": "fdd000498",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000498.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000498.xml"
            }
        },
        {
            "gltf(gltransmissionformat)family": "",
            "name": "glTF (GL Transmission Format) Family",
            "id": "fdd000498",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000498.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000498.xml"
            }
        },
        {
            "gltf(gltransmissionformat)family": "",
            "name": "glTF (GL Transmission Format) Family",
            "id": "fdd000498",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000498.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000498.xml"
            }
        },
        {
            "gltf(gltransmissionformat)family": "",
            "name": "glTF (GL Transmission Format) Family",
            "id": "fdd000498",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000498.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000498.xml"
            }
        },
        {
            "gltf(gltransmissionformat)family": "",
            "name": "glTF (GL Transmission Format) Family",
            "id": "fdd000498",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000498.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000498.xml"
            }
        },
        {
            "gltf(gltransmissionformat)family": "",
            "name": "glTF (GL Transmission Format) Family",
            "id": "fdd000498",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000498.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000498.xml"
            }
        },
        {
            "gltf(gltransmissionformat)family": "",
            "name": "glTF (GL Transmission Format) Family",
            "id": "fdd000498",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000498.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000498.xml"
            }
        },
        {
            "musicxml,version3.1": "",
            "name": "MusicXML, Version 3.1",
            "id": "fdd000499",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000499.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000499.xml"
            }
        },
        {
            "musicxml,version3.1": "",
            "name": "MusicXML, Version 3.1",
            "id": "fdd000499",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000499.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000499.xml"
            }
        },
        {
            "musicxml,version3.1": "",
            "name": "MusicXML, Version 3.1",
            "id": "fdd000499",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000499.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000499.xml"
            }
        },
        {
            "musicxml,version3.1": "",
            "name": "MusicXML, Version 3.1",
            "id": "fdd000499",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000499.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000499.xml"
            }
        },
        {
            "musicxml,version3.1": "",
            "name": "MusicXML, Version 3.1",
            "id": "fdd000499",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000499.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000499.xml"
            }
        },
        {
            "musicxml,version3.1": "",
            "name": "MusicXML, Version 3.1",
            "id": "fdd000499",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000499.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000499.xml"
            }
        },
        {
            "musicxml,version3.1": "",
            "name": "MusicXML, Version 3.1",
            "id": "fdd000499",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000499.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000499.xml"
            }
        },
        {
            "musicxml,version3.1": "",
            "name": "MusicXML, Version 3.1",
            "id": "fdd000499",
            "filenamepattern": [
                {
                    "value": "*.musicxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.musicxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000499.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000499.xml"
            }
        },
        {
            "gltf(gltransmissionformat)2.0": "",
            "name": "glTF (GL Transmission Format) 2.0",
            "id": "fdd000500",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000500.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000500.xml"
            }
        },
        {
            "gltf(gltransmissionformat)2.0": "",
            "name": "glTF (GL Transmission Format) 2.0",
            "id": "fdd000500",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000500.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000500.xml"
            }
        },
        {
            "gltf(gltransmissionformat)2.0": "",
            "name": "glTF (GL Transmission Format) 2.0",
            "id": "fdd000500",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000500.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000500.xml"
            }
        },
        {
            "gltf(gltransmissionformat)2.0": "",
            "name": "glTF (GL Transmission Format) 2.0",
            "id": "fdd000500",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000500.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000500.xml"
            }
        },
        {
            "gltf(gltransmissionformat)2.0": "",
            "name": "glTF (GL Transmission Format) 2.0",
            "id": "fdd000500",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000500.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000500.xml"
            }
        },
        {
            "gltf(gltransmissionformat)2.0": "",
            "name": "glTF (GL Transmission Format) 2.0",
            "id": "fdd000500",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000500.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000500.xml"
            }
        },
        {
            "gltf(gltransmissionformat)2.0": "",
            "name": "glTF (GL Transmission Format) 2.0",
            "id": "fdd000500",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000500.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000500.xml"
            }
        },
        {
            "gltf(gltransmissionformat)2.0": "",
            "name": "glTF (GL Transmission Format) 2.0",
            "id": "fdd000500",
            "filenamepattern": [
                {
                    "value": "*.gltf",
                    "url": "https://www.digipres.org/formats/extensions/#*.gltf"
                },
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/gltf+json",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf+json"
                },
                {
                    "value": "model/gltf-binary",
                    "url": "https://www.digipres.org/formats/mime-types/#model/gltf-binary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000500.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000500.xml"
            }
        },
        {
            "polygonfileformat(ply)family": "",
            "name": "Polygon File Format (PLY) Family",
            "id": "fdd000501",
            "filenamepattern": [
                {
                    "value": "*.ply",
                    "url": "https://www.digipres.org/formats/extensions/#*.ply"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000501.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000501.xml"
            }
        },
        {
            "polygonfileformat(ply)family": "",
            "name": "Polygon File Format (PLY) Family",
            "id": "fdd000501",
            "filenamepattern": [
                {
                    "value": "*.ply",
                    "url": "https://www.digipres.org/formats/extensions/#*.ply"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000501.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000501.xml"
            }
        },
        {
            "polygonfileformat(ply)family": "",
            "name": "Polygon File Format (PLY) Family",
            "id": "fdd000501",
            "filenamepattern": [
                {
                    "value": "*.ply",
                    "url": "https://www.digipres.org/formats/extensions/#*.ply"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000501.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000501.xml"
            }
        },
        {
            "polygonfileformat(ply)family": "",
            "name": "Polygon File Format (PLY) Family",
            "id": "fdd000501",
            "filenamepattern": [
                {
                    "value": "*.ply",
                    "url": "https://www.digipres.org/formats/extensions/#*.ply"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000501.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000501.xml"
            }
        },
        {
            "polygonfileformat(ply)family": "",
            "name": "Polygon File Format (PLY) Family",
            "id": "fdd000501",
            "filenamepattern": [
                {
                    "value": "*.ply",
                    "url": "https://www.digipres.org/formats/extensions/#*.ply"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000501.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000501.xml"
            }
        },
        {
            "polygonfileformat(ply)family": "",
            "name": "Polygon File Format (PLY) Family",
            "id": "fdd000501",
            "filenamepattern": [
                {
                    "value": "*.ply",
                    "url": "https://www.digipres.org/formats/extensions/#*.ply"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000501.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000501.xml"
            }
        },
        {
            "polygonfileformat(ply)family": "",
            "name": "Polygon File Format (PLY) Family",
            "id": "fdd000501",
            "filenamepattern": [
                {
                    "value": "*.ply",
                    "url": "https://www.digipres.org/formats/extensions/#*.ply"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000501.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000501.xml"
            }
        },
        {
            "polygonfileformat(ply)family": "",
            "name": "Polygon File Format (PLY) Family",
            "id": "fdd000501",
            "filenamepattern": [
                {
                    "value": "*.ply",
                    "url": "https://www.digipres.org/formats/extensions/#*.ply"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000501.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000501.xml"
            }
        },
        {
            "musicencodinginitiative(mei)formatfamily": "",
            "name": "Music Encoding Initiative (MEI) Format Family",
            "id": "fdd000502",
            "filenamepattern": [
                {
                    "value": "*.mei",
                    "url": "https://www.digipres.org/formats/extensions/#*.mei"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000502.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000502.xml"
            }
        },
        {
            "musicencodinginitiative(mei)formatfamily": "",
            "name": "Music Encoding Initiative (MEI) Format Family",
            "id": "fdd000502",
            "filenamepattern": [
                {
                    "value": "*.mei",
                    "url": "https://www.digipres.org/formats/extensions/#*.mei"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000502.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000502.xml"
            }
        },
        {
            "musicencodinginitiative(mei)formatfamily": "",
            "name": "Music Encoding Initiative (MEI) Format Family",
            "id": "fdd000502",
            "filenamepattern": [
                {
                    "value": "*.mei",
                    "url": "https://www.digipres.org/formats/extensions/#*.mei"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000502.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000502.xml"
            }
        },
        {
            "musicencodinginitiative(mei)formatfamily": "",
            "name": "Music Encoding Initiative (MEI) Format Family",
            "id": "fdd000502",
            "filenamepattern": [
                {
                    "value": "*.mei",
                    "url": "https://www.digipres.org/formats/extensions/#*.mei"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000502.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000502.xml"
            }
        },
        {
            "musicencodinginitiative(mei)formatfamily": "",
            "name": "Music Encoding Initiative (MEI) Format Family",
            "id": "fdd000502",
            "filenamepattern": [
                {
                    "value": "*.mei",
                    "url": "https://www.digipres.org/formats/extensions/#*.mei"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000502.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000502.xml"
            }
        },
        {
            "musicencodinginitiative(mei)formatfamily": "",
            "name": "Music Encoding Initiative (MEI) Format Family",
            "id": "fdd000502",
            "filenamepattern": [
                {
                    "value": "*.mei",
                    "url": "https://www.digipres.org/formats/extensions/#*.mei"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000502.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000502.xml"
            }
        },
        {
            "musicencodinginitiative(mei)formatfamily": "",
            "name": "Music Encoding Initiative (MEI) Format Family",
            "id": "fdd000502",
            "filenamepattern": [
                {
                    "value": "*.mei",
                    "url": "https://www.digipres.org/formats/extensions/#*.mei"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000502.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000502.xml"
            }
        },
        {
            "musicencodinginitiative(mei)formatfamily": "",
            "name": "Music Encoding Initiative (MEI) Format Family",
            "id": "fdd000502",
            "filenamepattern": [
                {
                    "value": "*.mei",
                    "url": "https://www.digipres.org/formats/extensions/#*.mei"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000502.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000502.xml"
            }
        },
        {
            "musicxml,version3.0": "",
            "name": "MusicXML, Version 3.0",
            "id": "fdd000503",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000503.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000503.xml"
            }
        },
        {
            "musicxml,version3.0": "",
            "name": "MusicXML, Version 3.0",
            "id": "fdd000503",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000503.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000503.xml"
            }
        },
        {
            "musicxml,version3.0": "",
            "name": "MusicXML, Version 3.0",
            "id": "fdd000503",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000503.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000503.xml"
            }
        },
        {
            "musicxml,version3.0": "",
            "name": "MusicXML, Version 3.0",
            "id": "fdd000503",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000503.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000503.xml"
            }
        },
        {
            "musicxml,version3.0": "",
            "name": "MusicXML, Version 3.0",
            "id": "fdd000503",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000503.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000503.xml"
            }
        },
        {
            "musicxml,version3.0": "",
            "name": "MusicXML, Version 3.0",
            "id": "fdd000503",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000503.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000503.xml"
            }
        },
        {
            "musicxml,version3.0": "",
            "name": "MusicXML, Version 3.0",
            "id": "fdd000503",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000503.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000503.xml"
            }
        },
        {
            "musicxml,version3.0": "",
            "name": "MusicXML, Version 3.0",
            "id": "fdd000503",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.recordare.musicxml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.recordare.musicxml+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000503.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000503.xml"
            }
        },
        {
            "stl(stereolithography)fileformatfamily": "",
            "name": "STL (STereoLithography) File Format Family",
            "id": "fdd000504",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000504.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000504.xml"
            }
        },
        {
            "stl(stereolithography)fileformatfamily": "",
            "name": "STL (STereoLithography) File Format Family",
            "id": "fdd000504",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000504.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000504.xml"
            }
        },
        {
            "stl(stereolithography)fileformatfamily": "",
            "name": "STL (STereoLithography) File Format Family",
            "id": "fdd000504",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000504.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000504.xml"
            }
        },
        {
            "stl(stereolithography)fileformatfamily": "",
            "name": "STL (STereoLithography) File Format Family",
            "id": "fdd000504",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000504.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000504.xml"
            }
        },
        {
            "stl(stereolithography)fileformatfamily": "",
            "name": "STL (STereoLithography) File Format Family",
            "id": "fdd000504",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000504.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000504.xml"
            }
        },
        {
            "stl(stereolithography)fileformatfamily": "",
            "name": "STL (STereoLithography) File Format Family",
            "id": "fdd000504",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000504.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000504.xml"
            }
        },
        {
            "stl(stereolithography)fileformatfamily": "",
            "name": "STL (STereoLithography) File Format Family",
            "id": "fdd000504",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000504.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000504.xml"
            }
        },
        {
            "stl(stereolithography)fileformatfamily": "",
            "name": "STL (STereoLithography) File Format Family",
            "id": "fdd000504",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000504.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000504.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,binary": "",
            "name": "STL (STereoLithography) File Format, Binary",
            "id": "fdd000505",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000505.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000505.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,binary": "",
            "name": "STL (STereoLithography) File Format, Binary",
            "id": "fdd000505",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000505.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000505.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,binary": "",
            "name": "STL (STereoLithography) File Format, Binary",
            "id": "fdd000505",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000505.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000505.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,binary": "",
            "name": "STL (STereoLithography) File Format, Binary",
            "id": "fdd000505",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000505.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000505.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,binary": "",
            "name": "STL (STereoLithography) File Format, Binary",
            "id": "fdd000505",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000505.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000505.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,binary": "",
            "name": "STL (STereoLithography) File Format, Binary",
            "id": "fdd000505",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000505.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000505.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,binary": "",
            "name": "STL (STereoLithography) File Format, Binary",
            "id": "fdd000505",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000505.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000505.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,binary": "",
            "name": "STL (STereoLithography) File Format, Binary",
            "id": "fdd000505",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000505.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000505.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,ascii": "",
            "name": "STL (STereoLithography) File Format, ASCII",
            "id": "fdd000506",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000506.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000506.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,ascii": "",
            "name": "STL (STereoLithography) File Format, ASCII",
            "id": "fdd000506",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000506.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000506.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,ascii": "",
            "name": "STL (STereoLithography) File Format, ASCII",
            "id": "fdd000506",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000506.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000506.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,ascii": "",
            "name": "STL (STereoLithography) File Format, ASCII",
            "id": "fdd000506",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000506.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000506.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,ascii": "",
            "name": "STL (STereoLithography) File Format, ASCII",
            "id": "fdd000506",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000506.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000506.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,ascii": "",
            "name": "STL (STereoLithography) File Format, ASCII",
            "id": "fdd000506",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000506.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000506.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,ascii": "",
            "name": "STL (STereoLithography) File Format, ASCII",
            "id": "fdd000506",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000506.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000506.xml"
            }
        },
        {
            "stl(stereolithography)fileformat,ascii": "",
            "name": "STL (STereoLithography) File Format, ASCII",
            "id": "fdd000506",
            "filenamepattern": [
                {
                    "value": "*.stl",
                    "url": "https://www.digipres.org/formats/extensions/#*.stl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/sla",
                    "url": "https://www.digipres.org/formats/mime-types/#application/sla"
                },
                {
                    "value": "model/stl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/stl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000506.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000506.xml"
            }
        },
        {
            "wavefrontobjfileformat": "",
            "name": "Wavefront OBJ File Format",
            "id": "fdd000507",
            "filenamepattern": [
                {
                    "value": "*.obj",
                    "url": "https://www.digipres.org/formats/extensions/#*.obj"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/obj",
                    "url": "https://www.digipres.org/formats/mime-types/#model/obj"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000507.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000507.xml"
            }
        },
        {
            "wavefrontobjfileformat": "",
            "name": "Wavefront OBJ File Format",
            "id": "fdd000507",
            "filenamepattern": [
                {
                    "value": "*.obj",
                    "url": "https://www.digipres.org/formats/extensions/#*.obj"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/obj",
                    "url": "https://www.digipres.org/formats/mime-types/#model/obj"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000507.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000507.xml"
            }
        },
        {
            "wavefrontobjfileformat": "",
            "name": "Wavefront OBJ File Format",
            "id": "fdd000507",
            "filenamepattern": [
                {
                    "value": "*.obj",
                    "url": "https://www.digipres.org/formats/extensions/#*.obj"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/obj",
                    "url": "https://www.digipres.org/formats/mime-types/#model/obj"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000507.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000507.xml"
            }
        },
        {
            "wavefrontobjfileformat": "",
            "name": "Wavefront OBJ File Format",
            "id": "fdd000507",
            "filenamepattern": [
                {
                    "value": "*.obj",
                    "url": "https://www.digipres.org/formats/extensions/#*.obj"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/obj",
                    "url": "https://www.digipres.org/formats/mime-types/#model/obj"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000507.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000507.xml"
            }
        },
        {
            "wavefrontobjfileformat": "",
            "name": "Wavefront OBJ File Format",
            "id": "fdd000507",
            "filenamepattern": [
                {
                    "value": "*.obj",
                    "url": "https://www.digipres.org/formats/extensions/#*.obj"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/obj",
                    "url": "https://www.digipres.org/formats/mime-types/#model/obj"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000507.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000507.xml"
            }
        },
        {
            "wavefrontobjfileformat": "",
            "name": "Wavefront OBJ File Format",
            "id": "fdd000507",
            "filenamepattern": [
                {
                    "value": "*.obj",
                    "url": "https://www.digipres.org/formats/extensions/#*.obj"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/obj",
                    "url": "https://www.digipres.org/formats/mime-types/#model/obj"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000507.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000507.xml"
            }
        },
        {
            "wavefrontobjfileformat": "",
            "name": "Wavefront OBJ File Format",
            "id": "fdd000507",
            "filenamepattern": [
                {
                    "value": "*.obj",
                    "url": "https://www.digipres.org/formats/extensions/#*.obj"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/obj",
                    "url": "https://www.digipres.org/formats/mime-types/#model/obj"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000507.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000507.xml"
            }
        },
        {
            "wavefrontobjfileformat": "",
            "name": "Wavefront OBJ File Format",
            "id": "fdd000507",
            "filenamepattern": [
                {
                    "value": "*.obj",
                    "url": "https://www.digipres.org/formats/extensions/#*.obj"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/obj",
                    "url": "https://www.digipres.org/formats/mime-types/#model/obj"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000507.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000507.xml"
            }
        },
        {
            "wavefrontmaterialtemplatelibrary(mtl)fileformat": "",
            "name": "Wavefront Material Template Library (MTL) File Format",
            "id": "fdd000508",
            "filenamepattern": [
                {
                    "value": "*.mtl",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtl"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/mtl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/mtl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000508.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000508.xml"
            }
        },
        {
            "wavefrontmaterialtemplatelibrary(mtl)fileformat": "",
            "name": "Wavefront Material Template Library (MTL) File Format",
            "id": "fdd000508",
            "filenamepattern": [
                {
                    "value": "*.mtl",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtl"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/mtl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/mtl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000508.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000508.xml"
            }
        },
        {
            "wavefrontmaterialtemplatelibrary(mtl)fileformat": "",
            "name": "Wavefront Material Template Library (MTL) File Format",
            "id": "fdd000508",
            "filenamepattern": [
                {
                    "value": "*.mtl",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtl"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/mtl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/mtl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000508.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000508.xml"
            }
        },
        {
            "wavefrontmaterialtemplatelibrary(mtl)fileformat": "",
            "name": "Wavefront Material Template Library (MTL) File Format",
            "id": "fdd000508",
            "filenamepattern": [
                {
                    "value": "*.mtl",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtl"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/mtl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/mtl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000508.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000508.xml"
            }
        },
        {
            "wavefrontmaterialtemplatelibrary(mtl)fileformat": "",
            "name": "Wavefront Material Template Library (MTL) File Format",
            "id": "fdd000508",
            "filenamepattern": [
                {
                    "value": "*.mtl",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtl"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/mtl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/mtl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000508.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000508.xml"
            }
        },
        {
            "wavefrontmaterialtemplatelibrary(mtl)fileformat": "",
            "name": "Wavefront Material Template Library (MTL) File Format",
            "id": "fdd000508",
            "filenamepattern": [
                {
                    "value": "*.mtl",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtl"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/mtl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/mtl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000508.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000508.xml"
            }
        },
        {
            "wavefrontmaterialtemplatelibrary(mtl)fileformat": "",
            "name": "Wavefront Material Template Library (MTL) File Format",
            "id": "fdd000508",
            "filenamepattern": [
                {
                    "value": "*.mtl",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtl"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/mtl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/mtl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000508.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000508.xml"
            }
        },
        {
            "wavefrontmaterialtemplatelibrary(mtl)fileformat": "",
            "name": "Wavefront Material Template Library (MTL) File Format",
            "id": "fdd000508",
            "filenamepattern": [
                {
                    "value": "*.mtl",
                    "url": "https://www.digipres.org/formats/extensions/#*.mtl"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/mtl",
                    "url": "https://www.digipres.org/formats/mime-types/#model/mtl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000508.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000508.xml"
            }
        },
        {
            "microsoftofficeword972003binaryfileformat(.doc)": "",
            "name": "Microsoft Office Word 97-2003 Binary File Format (.doc)",
            "id": "fdd000509",
            "filenamepattern": [
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000509.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000509.xml"
            }
        },
        {
            "microsoftofficeword972003binaryfileformat(.doc)": "",
            "name": "Microsoft Office Word 97-2003 Binary File Format (.doc)",
            "id": "fdd000509",
            "filenamepattern": [
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000509.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000509.xml"
            }
        },
        {
            "microsoftofficeword972003binaryfileformat(.doc)": "",
            "name": "Microsoft Office Word 97-2003 Binary File Format (.doc)",
            "id": "fdd000509",
            "filenamepattern": [
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000509.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000509.xml"
            }
        },
        {
            "microsoftofficeword972003binaryfileformat(.doc)": "",
            "name": "Microsoft Office Word 97-2003 Binary File Format (.doc)",
            "id": "fdd000509",
            "filenamepattern": [
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000509.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000509.xml"
            }
        },
        {
            "microsoftofficeword972003binaryfileformat(.doc)": "",
            "name": "Microsoft Office Word 97-2003 Binary File Format (.doc)",
            "id": "fdd000509",
            "filenamepattern": [
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000509.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000509.xml"
            }
        },
        {
            "microsoftofficeword972003binaryfileformat(.doc)": "",
            "name": "Microsoft Office Word 97-2003 Binary File Format (.doc)",
            "id": "fdd000509",
            "filenamepattern": [
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000509.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000509.xml"
            }
        },
        {
            "microsoftofficeword972003binaryfileformat(.doc)": "",
            "name": "Microsoft Office Word 97-2003 Binary File Format (.doc)",
            "id": "fdd000509",
            "filenamepattern": [
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000509.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000509.xml"
            }
        },
        {
            "microsoftofficeword972003binaryfileformat(.doc)": "",
            "name": "Microsoft Office Word 97-2003 Binary File Format (.doc)",
            "id": "fdd000509",
            "filenamepattern": [
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000509.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000509.xml"
            }
        },
        {
            "microsoftofficeexcel972003binaryfileformat(.xls,biff8)": "",
            "name": "Microsoft Office Excel 97-2003 Binary File Format (.xls, BIFF8)",
            "id": "fdd000510",
            "filenamepattern": [
                {
                    "value": "*.xls",
                    "url": "https://www.digipres.org/formats/extensions/#*.xls"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000510.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000510.xml"
            }
        },
        {
            "microsoftofficeexcel972003binaryfileformat(.xls,biff8)": "",
            "name": "Microsoft Office Excel 97-2003 Binary File Format (.xls, BIFF8)",
            "id": "fdd000510",
            "filenamepattern": [
                {
                    "value": "*.xls",
                    "url": "https://www.digipres.org/formats/extensions/#*.xls"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000510.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000510.xml"
            }
        },
        {
            "microsoftofficeexcel972003binaryfileformat(.xls,biff8)": "",
            "name": "Microsoft Office Excel 97-2003 Binary File Format (.xls, BIFF8)",
            "id": "fdd000510",
            "filenamepattern": [
                {
                    "value": "*.xls",
                    "url": "https://www.digipres.org/formats/extensions/#*.xls"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000510.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000510.xml"
            }
        },
        {
            "microsoftofficeexcel972003binaryfileformat(.xls,biff8)": "",
            "name": "Microsoft Office Excel 97-2003 Binary File Format (.xls, BIFF8)",
            "id": "fdd000510",
            "filenamepattern": [
                {
                    "value": "*.xls",
                    "url": "https://www.digipres.org/formats/extensions/#*.xls"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000510.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000510.xml"
            }
        },
        {
            "microsoftofficeexcel972003binaryfileformat(.xls,biff8)": "",
            "name": "Microsoft Office Excel 97-2003 Binary File Format (.xls, BIFF8)",
            "id": "fdd000510",
            "filenamepattern": [
                {
                    "value": "*.xls",
                    "url": "https://www.digipres.org/formats/extensions/#*.xls"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000510.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000510.xml"
            }
        },
        {
            "microsoftofficeexcel972003binaryfileformat(.xls,biff8)": "",
            "name": "Microsoft Office Excel 97-2003 Binary File Format (.xls, BIFF8)",
            "id": "fdd000510",
            "filenamepattern": [
                {
                    "value": "*.xls",
                    "url": "https://www.digipres.org/formats/extensions/#*.xls"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000510.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000510.xml"
            }
        },
        {
            "microsoftofficeexcel972003binaryfileformat(.xls,biff8)": "",
            "name": "Microsoft Office Excel 97-2003 Binary File Format (.xls, BIFF8)",
            "id": "fdd000510",
            "filenamepattern": [
                {
                    "value": "*.xls",
                    "url": "https://www.digipres.org/formats/extensions/#*.xls"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000510.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000510.xml"
            }
        },
        {
            "microsoftofficeexcel972003binaryfileformat(.xls,biff8)": "",
            "name": "Microsoft Office Excel 97-2003 Binary File Format (.xls, BIFF8)",
            "id": "fdd000510",
            "filenamepattern": [
                {
                    "value": "*.xls",
                    "url": "https://www.digipres.org/formats/extensions/#*.xls"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000510.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000510.xml"
            }
        },
        {
            "microsoftofficepowerpoint972003binaryfileformat(.ppt)": "",
            "name": "Microsoft Office PowerPoint 97-2003 Binary File Format (.ppt)",
            "id": "fdd000511",
            "filenamepattern": [
                {
                    "value": "*.ppt",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-powerpoint",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-powerpoint"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000511.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000511.xml"
            }
        },
        {
            "microsoftofficepowerpoint972003binaryfileformat(.ppt)": "",
            "name": "Microsoft Office PowerPoint 97-2003 Binary File Format (.ppt)",
            "id": "fdd000511",
            "filenamepattern": [
                {
                    "value": "*.ppt",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-powerpoint",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-powerpoint"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000511.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000511.xml"
            }
        },
        {
            "microsoftofficepowerpoint972003binaryfileformat(.ppt)": "",
            "name": "Microsoft Office PowerPoint 97-2003 Binary File Format (.ppt)",
            "id": "fdd000511",
            "filenamepattern": [
                {
                    "value": "*.ppt",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-powerpoint",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-powerpoint"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000511.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000511.xml"
            }
        },
        {
            "microsoftofficepowerpoint972003binaryfileformat(.ppt)": "",
            "name": "Microsoft Office PowerPoint 97-2003 Binary File Format (.ppt)",
            "id": "fdd000511",
            "filenamepattern": [
                {
                    "value": "*.ppt",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-powerpoint",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-powerpoint"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000511.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000511.xml"
            }
        },
        {
            "microsoftofficepowerpoint972003binaryfileformat(.ppt)": "",
            "name": "Microsoft Office PowerPoint 97-2003 Binary File Format (.ppt)",
            "id": "fdd000511",
            "filenamepattern": [
                {
                    "value": "*.ppt",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-powerpoint",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-powerpoint"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000511.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000511.xml"
            }
        },
        {
            "microsoftofficepowerpoint972003binaryfileformat(.ppt)": "",
            "name": "Microsoft Office PowerPoint 97-2003 Binary File Format (.ppt)",
            "id": "fdd000511",
            "filenamepattern": [
                {
                    "value": "*.ppt",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-powerpoint",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-powerpoint"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000511.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000511.xml"
            }
        },
        {
            "microsoftofficepowerpoint972003binaryfileformat(.ppt)": "",
            "name": "Microsoft Office PowerPoint 97-2003 Binary File Format (.ppt)",
            "id": "fdd000511",
            "filenamepattern": [
                {
                    "value": "*.ppt",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-powerpoint",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-powerpoint"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000511.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000511.xml"
            }
        },
        {
            "microsoftofficepowerpoint972003binaryfileformat(.ppt)": "",
            "name": "Microsoft Office PowerPoint 97-2003 Binary File Format (.ppt)",
            "id": "fdd000511",
            "filenamepattern": [
                {
                    "value": "*.ppt",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-powerpoint",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-powerpoint"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000511.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000511.xml"
            }
        },
        {
            "microsoftexcelbinary(xlsb)format(optioninexcel2007andlater)": "",
            "name": "Microsoft Excel Binary (XLSB) Format (option in Excel 2007 and later)",
            "id": "fdd000512",
            "filenamepattern": [
                {
                    "value": "*.xlsb",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel.sheet.binary.macroenabled.12"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000512.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000512.xml"
            }
        },
        {
            "microsoftexcelbinary(xlsb)format(optioninexcel2007andlater)": "",
            "name": "Microsoft Excel Binary (XLSB) Format (option in Excel 2007 and later)",
            "id": "fdd000512",
            "filenamepattern": [
                {
                    "value": "*.xlsb",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel.sheet.binary.macroenabled.12"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000512.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000512.xml"
            }
        },
        {
            "microsoftexcelbinary(xlsb)format(optioninexcel2007andlater)": "",
            "name": "Microsoft Excel Binary (XLSB) Format (option in Excel 2007 and later)",
            "id": "fdd000512",
            "filenamepattern": [
                {
                    "value": "*.xlsb",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel.sheet.binary.macroenabled.12"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000512.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000512.xml"
            }
        },
        {
            "microsoftexcelbinary(xlsb)format(optioninexcel2007andlater)": "",
            "name": "Microsoft Excel Binary (XLSB) Format (option in Excel 2007 and later)",
            "id": "fdd000512",
            "filenamepattern": [
                {
                    "value": "*.xlsb",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel.sheet.binary.macroenabled.12"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000512.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000512.xml"
            }
        },
        {
            "microsoftexcelbinary(xlsb)format(optioninexcel2007andlater)": "",
            "name": "Microsoft Excel Binary (XLSB) Format (option in Excel 2007 and later)",
            "id": "fdd000512",
            "filenamepattern": [
                {
                    "value": "*.xlsb",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel.sheet.binary.macroenabled.12"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000512.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000512.xml"
            }
        },
        {
            "microsoftexcelbinary(xlsb)format(optioninexcel2007andlater)": "",
            "name": "Microsoft Excel Binary (XLSB) Format (option in Excel 2007 and later)",
            "id": "fdd000512",
            "filenamepattern": [
                {
                    "value": "*.xlsb",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel.sheet.binary.macroenabled.12"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000512.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000512.xml"
            }
        },
        {
            "microsoftexcelbinary(xlsb)format(optioninexcel2007andlater)": "",
            "name": "Microsoft Excel Binary (XLSB) Format (option in Excel 2007 and later)",
            "id": "fdd000512",
            "filenamepattern": [
                {
                    "value": "*.xlsb",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel.sheet.binary.macroenabled.12"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000512.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000512.xml"
            }
        },
        {
            "microsoftexcelbinary(xlsb)format(optioninexcel2007andlater)": "",
            "name": "Microsoft Excel Binary (XLSB) Format (option in Excel 2007 and later)",
            "id": "fdd000512",
            "filenamepattern": [
                {
                    "value": "*.xlsb",
                    "url": "https://www.digipres.org/formats/extensions/#*.xlsb"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-excel.sheet.binary.macroenabled.12"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000512.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000512.xml"
            }
        },
        {
            "openxmlpaperspecification,(openxps),ecma388(.oxps)": "",
            "name": "Open XML Paper Specification, (OpenXPS), ECMA-388 (.oxps)",
            "id": "fdd000513",
            "filenamepattern": [
                {
                    "value": "*.oxps",
                    "url": "https://www.digipres.org/formats/extensions/#*.oxps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/oxps",
                    "url": "https://www.digipres.org/formats/mime-types/#application/oxps"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000513.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000513.xml"
            }
        },
        {
            "openxmlpaperspecification,(openxps),ecma388(.oxps)": "",
            "name": "Open XML Paper Specification, (OpenXPS), ECMA-388 (.oxps)",
            "id": "fdd000513",
            "filenamepattern": [
                {
                    "value": "*.oxps",
                    "url": "https://www.digipres.org/formats/extensions/#*.oxps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/oxps",
                    "url": "https://www.digipres.org/formats/mime-types/#application/oxps"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000513.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000513.xml"
            }
        },
        {
            "openxmlpaperspecification,(openxps),ecma388(.oxps)": "",
            "name": "Open XML Paper Specification, (OpenXPS), ECMA-388 (.oxps)",
            "id": "fdd000513",
            "filenamepattern": [
                {
                    "value": "*.oxps",
                    "url": "https://www.digipres.org/formats/extensions/#*.oxps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/oxps",
                    "url": "https://www.digipres.org/formats/mime-types/#application/oxps"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000513.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000513.xml"
            }
        },
        {
            "openxmlpaperspecification,(openxps),ecma388(.oxps)": "",
            "name": "Open XML Paper Specification, (OpenXPS), ECMA-388 (.oxps)",
            "id": "fdd000513",
            "filenamepattern": [
                {
                    "value": "*.oxps",
                    "url": "https://www.digipres.org/formats/extensions/#*.oxps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/oxps",
                    "url": "https://www.digipres.org/formats/mime-types/#application/oxps"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000513.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000513.xml"
            }
        },
        {
            "openxmlpaperspecification,(openxps),ecma388(.oxps)": "",
            "name": "Open XML Paper Specification, (OpenXPS), ECMA-388 (.oxps)",
            "id": "fdd000513",
            "filenamepattern": [
                {
                    "value": "*.oxps",
                    "url": "https://www.digipres.org/formats/extensions/#*.oxps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/oxps",
                    "url": "https://www.digipres.org/formats/mime-types/#application/oxps"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000513.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000513.xml"
            }
        },
        {
            "openxmlpaperspecification,(openxps),ecma388(.oxps)": "",
            "name": "Open XML Paper Specification, (OpenXPS), ECMA-388 (.oxps)",
            "id": "fdd000513",
            "filenamepattern": [
                {
                    "value": "*.oxps",
                    "url": "https://www.digipres.org/formats/extensions/#*.oxps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/oxps",
                    "url": "https://www.digipres.org/formats/mime-types/#application/oxps"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000513.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000513.xml"
            }
        },
        {
            "openxmlpaperspecification,(openxps),ecma388(.oxps)": "",
            "name": "Open XML Paper Specification, (OpenXPS), ECMA-388 (.oxps)",
            "id": "fdd000513",
            "filenamepattern": [
                {
                    "value": "*.oxps",
                    "url": "https://www.digipres.org/formats/extensions/#*.oxps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/oxps",
                    "url": "https://www.digipres.org/formats/mime-types/#application/oxps"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000513.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000513.xml"
            }
        },
        {
            "openxmlpaperspecification,(openxps),ecma388(.oxps)": "",
            "name": "Open XML Paper Specification, (OpenXPS), ECMA-388 (.oxps)",
            "id": "fdd000513",
            "filenamepattern": [
                {
                    "value": "*.oxps",
                    "url": "https://www.digipres.org/formats/extensions/#*.oxps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/oxps",
                    "url": "https://www.digipres.org/formats/mime-types/#application/oxps"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000513.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000513.xml"
            }
        },
        {
            "microsoftxmlpaperspecification,(xps)": "",
            "name": "Microsoft XML Paper Specification, (XPS)",
            "id": "fdd000514",
            "filenamepattern": [
                {
                    "value": "*.xps",
                    "url": "https://www.digipres.org/formats/extensions/#*.xps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-xpsdocument",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-xpsdocument"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000514.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000514.xml"
            }
        },
        {
            "microsoftxmlpaperspecification,(xps)": "",
            "name": "Microsoft XML Paper Specification, (XPS)",
            "id": "fdd000514",
            "filenamepattern": [
                {
                    "value": "*.xps",
                    "url": "https://www.digipres.org/formats/extensions/#*.xps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-xpsdocument",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-xpsdocument"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000514.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000514.xml"
            }
        },
        {
            "microsoftxmlpaperspecification,(xps)": "",
            "name": "Microsoft XML Paper Specification, (XPS)",
            "id": "fdd000514",
            "filenamepattern": [
                {
                    "value": "*.xps",
                    "url": "https://www.digipres.org/formats/extensions/#*.xps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-xpsdocument",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-xpsdocument"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000514.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000514.xml"
            }
        },
        {
            "microsoftxmlpaperspecification,(xps)": "",
            "name": "Microsoft XML Paper Specification, (XPS)",
            "id": "fdd000514",
            "filenamepattern": [
                {
                    "value": "*.xps",
                    "url": "https://www.digipres.org/formats/extensions/#*.xps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-xpsdocument",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-xpsdocument"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000514.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000514.xml"
            }
        },
        {
            "microsoftxmlpaperspecification,(xps)": "",
            "name": "Microsoft XML Paper Specification, (XPS)",
            "id": "fdd000514",
            "filenamepattern": [
                {
                    "value": "*.xps",
                    "url": "https://www.digipres.org/formats/extensions/#*.xps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-xpsdocument",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-xpsdocument"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000514.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000514.xml"
            }
        },
        {
            "microsoftxmlpaperspecification,(xps)": "",
            "name": "Microsoft XML Paper Specification, (XPS)",
            "id": "fdd000514",
            "filenamepattern": [
                {
                    "value": "*.xps",
                    "url": "https://www.digipres.org/formats/extensions/#*.xps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-xpsdocument",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-xpsdocument"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000514.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000514.xml"
            }
        },
        {
            "microsoftxmlpaperspecification,(xps)": "",
            "name": "Microsoft XML Paper Specification, (XPS)",
            "id": "fdd000514",
            "filenamepattern": [
                {
                    "value": "*.xps",
                    "url": "https://www.digipres.org/formats/extensions/#*.xps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-xpsdocument",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-xpsdocument"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000514.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000514.xml"
            }
        },
        {
            "microsoftxmlpaperspecification,(xps)": "",
            "name": "Microsoft XML Paper Specification, (XPS)",
            "id": "fdd000514",
            "filenamepattern": [
                {
                    "value": "*.xps",
                    "url": "https://www.digipres.org/formats/extensions/#*.xps"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-xpsdocument",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-xpsdocument"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000514.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000514.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)fileformatfamily": "",
            "name": "Scalable Vector Graphics (SVG) File Format Family",
            "id": "fdd000515",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000515.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000515.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)fileformatfamily": "",
            "name": "Scalable Vector Graphics (SVG) File Format Family",
            "id": "fdd000515",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000515.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000515.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)fileformatfamily": "",
            "name": "Scalable Vector Graphics (SVG) File Format Family",
            "id": "fdd000515",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000515.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000515.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)fileformatfamily": "",
            "name": "Scalable Vector Graphics (SVG) File Format Family",
            "id": "fdd000515",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000515.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000515.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)fileformatfamily": "",
            "name": "Scalable Vector Graphics (SVG) File Format Family",
            "id": "fdd000515",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000515.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000515.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)fileformatfamily": "",
            "name": "Scalable Vector Graphics (SVG) File Format Family",
            "id": "fdd000515",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000515.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000515.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)fileformatfamily": "",
            "name": "Scalable Vector Graphics (SVG) File Format Family",
            "id": "fdd000515",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000515.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000515.xml"
            }
        },
        {
            "scalablevectorgraphics(svg)fileformatfamily": "",
            "name": "Scalable Vector Graphics (SVG) File Format Family",
            "id": "fdd000515",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                },
                {
                    "value": "*.svgz",
                    "url": "https://www.digipres.org/formats/extensions/#*.svgz"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                },
                {
                    "value": "image/svg-xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg-xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000515.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000515.xml"
            }
        },
        {
            "extensiblebinarymetalanguage": "",
            "name": "Extensible Binary Meta Language",
            "id": "fdd000516",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000516.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000516.xml"
            }
        },
        {
            "extensiblebinarymetalanguage": "",
            "name": "Extensible Binary Meta Language",
            "id": "fdd000516",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000516.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000516.xml"
            }
        },
        {
            "extensiblebinarymetalanguage": "",
            "name": "Extensible Binary Meta Language",
            "id": "fdd000516",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000516.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000516.xml"
            }
        },
        {
            "extensiblebinarymetalanguage": "",
            "name": "Extensible Binary Meta Language",
            "id": "fdd000516",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000516.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000516.xml"
            }
        },
        {
            "extensiblebinarymetalanguage": "",
            "name": "Extensible Binary Meta Language",
            "id": "fdd000516",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000516.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000516.xml"
            }
        },
        {
            "extensiblebinarymetalanguage": "",
            "name": "Extensible Binary Meta Language",
            "id": "fdd000516",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000516.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000516.xml"
            }
        },
        {
            "extensiblebinarymetalanguage": "",
            "name": "Extensible Binary Meta Language",
            "id": "fdd000516",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000516.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000516.xml"
            }
        },
        {
            "extensiblebinarymetalanguage": "",
            "name": "Extensible Binary Meta Language",
            "id": "fdd000516",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000516.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000516.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version2": "",
            "name": "Scalable Vector Graphics (SVG), Version 2",
            "id": "fdd000517",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000517.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000517.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version2": "",
            "name": "Scalable Vector Graphics (SVG), Version 2",
            "id": "fdd000517",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000517.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000517.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version2": "",
            "name": "Scalable Vector Graphics (SVG), Version 2",
            "id": "fdd000517",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000517.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000517.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version2": "",
            "name": "Scalable Vector Graphics (SVG), Version 2",
            "id": "fdd000517",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000517.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000517.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version2": "",
            "name": "Scalable Vector Graphics (SVG), Version 2",
            "id": "fdd000517",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000517.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000517.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version2": "",
            "name": "Scalable Vector Graphics (SVG), Version 2",
            "id": "fdd000517",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000517.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000517.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version2": "",
            "name": "Scalable Vector Graphics (SVG), Version 2",
            "id": "fdd000517",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000517.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000517.xml"
            }
        },
        {
            "scalablevectorgraphics(svg),version2": "",
            "name": "Scalable Vector Graphics (SVG), Version 2",
            "id": "fdd000517",
            "filenamepattern": [
                {
                    "value": "*.svg",
                    "url": "https://www.digipres.org/formats/extensions/#*.svg"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/svg+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#image/svg+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000517.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000517.xml"
            }
        },
        {
            "webm": "",
            "name": "WebM",
            "id": "fdd000518",
            "filenamepattern": [
                {
                    "value": "*.webm",
                    "url": "https://www.digipres.org/formats/extensions/#*.webm"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#video/webm"
                },
                {
                    "value": "audio/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/webm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000518.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000518.xml"
            }
        },
        {
            "webm": "",
            "name": "WebM",
            "id": "fdd000518",
            "filenamepattern": [
                {
                    "value": "*.webm",
                    "url": "https://www.digipres.org/formats/extensions/#*.webm"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#video/webm"
                },
                {
                    "value": "audio/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/webm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000518.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000518.xml"
            }
        },
        {
            "webm": "",
            "name": "WebM",
            "id": "fdd000518",
            "filenamepattern": [
                {
                    "value": "*.webm",
                    "url": "https://www.digipres.org/formats/extensions/#*.webm"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#video/webm"
                },
                {
                    "value": "audio/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/webm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000518.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000518.xml"
            }
        },
        {
            "webm": "",
            "name": "WebM",
            "id": "fdd000518",
            "filenamepattern": [
                {
                    "value": "*.webm",
                    "url": "https://www.digipres.org/formats/extensions/#*.webm"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#video/webm"
                },
                {
                    "value": "audio/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/webm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000518.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000518.xml"
            }
        },
        {
            "webm": "",
            "name": "WebM",
            "id": "fdd000518",
            "filenamepattern": [
                {
                    "value": "*.webm",
                    "url": "https://www.digipres.org/formats/extensions/#*.webm"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#video/webm"
                },
                {
                    "value": "audio/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/webm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000518.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000518.xml"
            }
        },
        {
            "webm": "",
            "name": "WebM",
            "id": "fdd000518",
            "filenamepattern": [
                {
                    "value": "*.webm",
                    "url": "https://www.digipres.org/formats/extensions/#*.webm"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#video/webm"
                },
                {
                    "value": "audio/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/webm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000518.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000518.xml"
            }
        },
        {
            "webm": "",
            "name": "WebM",
            "id": "fdd000518",
            "filenamepattern": [
                {
                    "value": "*.webm",
                    "url": "https://www.digipres.org/formats/extensions/#*.webm"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#video/webm"
                },
                {
                    "value": "audio/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/webm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000518.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000518.xml"
            }
        },
        {
            "webm": "",
            "name": "WebM",
            "id": "fdd000518",
            "filenamepattern": [
                {
                    "value": "*.webm",
                    "url": "https://www.digipres.org/formats/extensions/#*.webm"
                }
            ],
            "mimetypes": [
                {
                    "value": "video/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#video/webm"
                },
                {
                    "value": "audio/webm",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/webm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000518.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000518.xml"
            }
        },
        {
            "epub(electronicpublication)version3preservation.iso/iects22424:2020": "",
            "name": "EPUB (Electronic Publication) Version 3 Preservation. ISO/IEC TS 22424:2020",
            "id": "fdd000519",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000519.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000519.xml"
            }
        },
        {
            "epub(electronicpublication)version3preservation.iso/iects22424:2020": "",
            "name": "EPUB (Electronic Publication) Version 3 Preservation. ISO/IEC TS 22424:2020",
            "id": "fdd000519",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000519.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000519.xml"
            }
        },
        {
            "epub(electronicpublication)version3preservation.iso/iects22424:2020": "",
            "name": "EPUB (Electronic Publication) Version 3 Preservation. ISO/IEC TS 22424:2020",
            "id": "fdd000519",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000519.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000519.xml"
            }
        },
        {
            "epub(electronicpublication)version3preservation.iso/iects22424:2020": "",
            "name": "EPUB (Electronic Publication) Version 3 Preservation. ISO/IEC TS 22424:2020",
            "id": "fdd000519",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000519.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000519.xml"
            }
        },
        {
            "epub(electronicpublication)version3preservation.iso/iects22424:2020": "",
            "name": "EPUB (Electronic Publication) Version 3 Preservation. ISO/IEC TS 22424:2020",
            "id": "fdd000519",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000519.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000519.xml"
            }
        },
        {
            "epub(electronicpublication)version3preservation.iso/iects22424:2020": "",
            "name": "EPUB (Electronic Publication) Version 3 Preservation. ISO/IEC TS 22424:2020",
            "id": "fdd000519",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000519.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000519.xml"
            }
        },
        {
            "epub(electronicpublication)version3preservation.iso/iects22424:2020": "",
            "name": "EPUB (Electronic Publication) Version 3 Preservation. ISO/IEC TS 22424:2020",
            "id": "fdd000519",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000519.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000519.xml"
            }
        },
        {
            "epub(electronicpublication)version3preservation.iso/iects22424:2020": "",
            "name": "EPUB (Electronic Publication) Version 3 Preservation. ISO/IEC TS 22424:2020",
            "id": "fdd000519",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000519.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000519.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc)formatfamily": "",
            "name": "GeoPackage Encoding Standard (OGC) Format Family",
            "id": "fdd000520",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/geopackage+sqlite3",
                    "url": "https://www.digipres.org/formats/mime-types/#application/geopackage+sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000520.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000520.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc)formatfamily": "",
            "name": "GeoPackage Encoding Standard (OGC) Format Family",
            "id": "fdd000520",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/geopackage+sqlite3",
                    "url": "https://www.digipres.org/formats/mime-types/#application/geopackage+sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000520.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000520.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc)formatfamily": "",
            "name": "GeoPackage Encoding Standard (OGC) Format Family",
            "id": "fdd000520",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/geopackage+sqlite3",
                    "url": "https://www.digipres.org/formats/mime-types/#application/geopackage+sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000520.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000520.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc)formatfamily": "",
            "name": "GeoPackage Encoding Standard (OGC) Format Family",
            "id": "fdd000520",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/geopackage+sqlite3",
                    "url": "https://www.digipres.org/formats/mime-types/#application/geopackage+sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000520.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000520.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc)formatfamily": "",
            "name": "GeoPackage Encoding Standard (OGC) Format Family",
            "id": "fdd000520",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/geopackage+sqlite3",
                    "url": "https://www.digipres.org/formats/mime-types/#application/geopackage+sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000520.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000520.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc)formatfamily": "",
            "name": "GeoPackage Encoding Standard (OGC) Format Family",
            "id": "fdd000520",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/geopackage+sqlite3",
                    "url": "https://www.digipres.org/formats/mime-types/#application/geopackage+sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000520.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000520.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc)formatfamily": "",
            "name": "GeoPackage Encoding Standard (OGC) Format Family",
            "id": "fdd000520",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/geopackage+sqlite3",
                    "url": "https://www.digipres.org/formats/mime-types/#application/geopackage+sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000520.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000520.xml"
            }
        },
        {
            "geopackageencodingstandard(ogc)formatfamily": "",
            "name": "GeoPackage Encoding Standard (OGC) Format Family",
            "id": "fdd000520",
            "filenamepattern": [
                {
                    "value": "*.gpkg",
                    "url": "https://www.digipres.org/formats/extensions/#*.gpkg"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/geopackage+sqlite3",
                    "url": "https://www.digipres.org/formats/mime-types/#application/geopackage+sqlite3"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000520.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000520.xml"
            }
        },
        {
            "computergraphicsmetafile(cgm)fileformatfamily": "",
            "name": "Computer Graphics Metafile (CGM) File Format Family",
            "id": "fdd000521",
            "filenamepattern": [
                {
                    "value": "*.cgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.cgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/cgm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/cgm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000521.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000521.xml"
            }
        },
        {
            "computergraphicsmetafile(cgm)fileformatfamily": "",
            "name": "Computer Graphics Metafile (CGM) File Format Family",
            "id": "fdd000521",
            "filenamepattern": [
                {
                    "value": "*.cgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.cgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/cgm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/cgm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000521.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000521.xml"
            }
        },
        {
            "computergraphicsmetafile(cgm)fileformatfamily": "",
            "name": "Computer Graphics Metafile (CGM) File Format Family",
            "id": "fdd000521",
            "filenamepattern": [
                {
                    "value": "*.cgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.cgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/cgm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/cgm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000521.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000521.xml"
            }
        },
        {
            "computergraphicsmetafile(cgm)fileformatfamily": "",
            "name": "Computer Graphics Metafile (CGM) File Format Family",
            "id": "fdd000521",
            "filenamepattern": [
                {
                    "value": "*.cgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.cgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/cgm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/cgm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000521.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000521.xml"
            }
        },
        {
            "computergraphicsmetafile(cgm)fileformatfamily": "",
            "name": "Computer Graphics Metafile (CGM) File Format Family",
            "id": "fdd000521",
            "filenamepattern": [
                {
                    "value": "*.cgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.cgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/cgm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/cgm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000521.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000521.xml"
            }
        },
        {
            "computergraphicsmetafile(cgm)fileformatfamily": "",
            "name": "Computer Graphics Metafile (CGM) File Format Family",
            "id": "fdd000521",
            "filenamepattern": [
                {
                    "value": "*.cgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.cgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/cgm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/cgm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000521.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000521.xml"
            }
        },
        {
            "computergraphicsmetafile(cgm)fileformatfamily": "",
            "name": "Computer Graphics Metafile (CGM) File Format Family",
            "id": "fdd000521",
            "filenamepattern": [
                {
                    "value": "*.cgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.cgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/cgm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/cgm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000521.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000521.xml"
            }
        },
        {
            "computergraphicsmetafile(cgm)fileformatfamily": "",
            "name": "Computer Graphics Metafile (CGM) File Format Family",
            "id": "fdd000521",
            "filenamepattern": [
                {
                    "value": "*.cgm",
                    "url": "https://www.digipres.org/formats/extensions/#*.cgm"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/cgm",
                    "url": "https://www.digipres.org/formats/mime-types/#image/cgm"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000521.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000521.xml"
            }
        },
        {
            "geopdffileformat(terrago)": "",
            "name": "GeoPDF File Format (TerraGo)",
            "id": "fdd000522",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000522.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000522.xml"
            }
        },
        {
            "geopdffileformat(terrago)": "",
            "name": "GeoPDF File Format (TerraGo)",
            "id": "fdd000522",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000522.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000522.xml"
            }
        },
        {
            "geopdffileformat(terrago)": "",
            "name": "GeoPDF File Format (TerraGo)",
            "id": "fdd000522",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000522.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000522.xml"
            }
        },
        {
            "geopdffileformat(terrago)": "",
            "name": "GeoPDF File Format (TerraGo)",
            "id": "fdd000522",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000522.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000522.xml"
            }
        },
        {
            "geopdffileformat(terrago)": "",
            "name": "GeoPDF File Format (TerraGo)",
            "id": "fdd000522",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000522.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000522.xml"
            }
        },
        {
            "geopdffileformat(terrago)": "",
            "name": "GeoPDF File Format (TerraGo)",
            "id": "fdd000522",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000522.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000522.xml"
            }
        },
        {
            "geopdffileformat(terrago)": "",
            "name": "GeoPDF File Format (TerraGo)",
            "id": "fdd000522",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000522.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000522.xml"
            }
        },
        {
            "geopdffileformat(terrago)": "",
            "name": "GeoPDF File Format (TerraGo)",
            "id": "fdd000522",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000522.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000522.xml"
            }
        },
        {
            "adobephotoshopfamily": "",
            "name": "Adobe Photoshop Family",
            "id": "fdd000523",
            "filenamepattern": [
                {
                    "value": "*.psb",
                    "url": "https://www.digipres.org/formats/extensions/#*.psb"
                },
                {
                    "value": "*.psd",
                    "url": "https://www.digipres.org/formats/extensions/#*.psd"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.adobe.photoshop",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.adobe.photoshop"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000523.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000523.xml"
            }
        },
        {
            "adobephotoshopfamily": "",
            "name": "Adobe Photoshop Family",
            "id": "fdd000523",
            "filenamepattern": [
                {
                    "value": "*.psb",
                    "url": "https://www.digipres.org/formats/extensions/#*.psb"
                },
                {
                    "value": "*.psd",
                    "url": "https://www.digipres.org/formats/extensions/#*.psd"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.adobe.photoshop",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.adobe.photoshop"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000523.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000523.xml"
            }
        },
        {
            "adobephotoshopfamily": "",
            "name": "Adobe Photoshop Family",
            "id": "fdd000523",
            "filenamepattern": [
                {
                    "value": "*.psb",
                    "url": "https://www.digipres.org/formats/extensions/#*.psb"
                },
                {
                    "value": "*.psd",
                    "url": "https://www.digipres.org/formats/extensions/#*.psd"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.adobe.photoshop",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.adobe.photoshop"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000523.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000523.xml"
            }
        },
        {
            "adobephotoshopfamily": "",
            "name": "Adobe Photoshop Family",
            "id": "fdd000523",
            "filenamepattern": [
                {
                    "value": "*.psb",
                    "url": "https://www.digipres.org/formats/extensions/#*.psb"
                },
                {
                    "value": "*.psd",
                    "url": "https://www.digipres.org/formats/extensions/#*.psd"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.adobe.photoshop",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.adobe.photoshop"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000523.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000523.xml"
            }
        },
        {
            "adobephotoshopfamily": "",
            "name": "Adobe Photoshop Family",
            "id": "fdd000523",
            "filenamepattern": [
                {
                    "value": "*.psb",
                    "url": "https://www.digipres.org/formats/extensions/#*.psb"
                },
                {
                    "value": "*.psd",
                    "url": "https://www.digipres.org/formats/extensions/#*.psd"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.adobe.photoshop",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.adobe.photoshop"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000523.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000523.xml"
            }
        },
        {
            "adobephotoshopfamily": "",
            "name": "Adobe Photoshop Family",
            "id": "fdd000523",
            "filenamepattern": [
                {
                    "value": "*.psb",
                    "url": "https://www.digipres.org/formats/extensions/#*.psb"
                },
                {
                    "value": "*.psd",
                    "url": "https://www.digipres.org/formats/extensions/#*.psd"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.adobe.photoshop",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.adobe.photoshop"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000523.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000523.xml"
            }
        },
        {
            "adobephotoshopfamily": "",
            "name": "Adobe Photoshop Family",
            "id": "fdd000523",
            "filenamepattern": [
                {
                    "value": "*.psb",
                    "url": "https://www.digipres.org/formats/extensions/#*.psb"
                },
                {
                    "value": "*.psd",
                    "url": "https://www.digipres.org/formats/extensions/#*.psd"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.adobe.photoshop",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.adobe.photoshop"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000523.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000523.xml"
            }
        },
        {
            "adobephotoshopfamily": "",
            "name": "Adobe Photoshop Family",
            "id": "fdd000523",
            "filenamepattern": [
                {
                    "value": "*.psb",
                    "url": "https://www.digipres.org/formats/extensions/#*.psb"
                },
                {
                    "value": "*.psd",
                    "url": "https://www.digipres.org/formats/extensions/#*.psd"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.adobe.photoshop",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.adobe.photoshop"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000523.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000523.xml"
            }
        },
        {
            "jpegxrfileformat(jxr)": "",
            "name": "JPEG XR File Format (JXR)",
            "id": "fdd000524",
            "filenamepattern": [
                {
                    "value": "*.jxr",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxr"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                },
                {
                    "value": "image/jxr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxr"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000524.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000524.xml"
            }
        },
        {
            "jpegxrfileformat(jxr)": "",
            "name": "JPEG XR File Format (JXR)",
            "id": "fdd000524",
            "filenamepattern": [
                {
                    "value": "*.jxr",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxr"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                },
                {
                    "value": "image/jxr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxr"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000524.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000524.xml"
            }
        },
        {
            "jpegxrfileformat(jxr)": "",
            "name": "JPEG XR File Format (JXR)",
            "id": "fdd000524",
            "filenamepattern": [
                {
                    "value": "*.jxr",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxr"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                },
                {
                    "value": "image/jxr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxr"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000524.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000524.xml"
            }
        },
        {
            "jpegxrfileformat(jxr)": "",
            "name": "JPEG XR File Format (JXR)",
            "id": "fdd000524",
            "filenamepattern": [
                {
                    "value": "*.jxr",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxr"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                },
                {
                    "value": "image/jxr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxr"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000524.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000524.xml"
            }
        },
        {
            "jpegxrfileformat(jxr)": "",
            "name": "JPEG XR File Format (JXR)",
            "id": "fdd000524",
            "filenamepattern": [
                {
                    "value": "*.jxr",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxr"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                },
                {
                    "value": "image/jxr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxr"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000524.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000524.xml"
            }
        },
        {
            "jpegxrfileformat(jxr)": "",
            "name": "JPEG XR File Format (JXR)",
            "id": "fdd000524",
            "filenamepattern": [
                {
                    "value": "*.jxr",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxr"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                },
                {
                    "value": "image/jxr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxr"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000524.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000524.xml"
            }
        },
        {
            "jpegxrfileformat(jxr)": "",
            "name": "JPEG XR File Format (JXR)",
            "id": "fdd000524",
            "filenamepattern": [
                {
                    "value": "*.jxr",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxr"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                },
                {
                    "value": "image/jxr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxr"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000524.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000524.xml"
            }
        },
        {
            "jpegxrfileformat(jxr)": "",
            "name": "JPEG XR File Format (JXR)",
            "id": "fdd000524",
            "filenamepattern": [
                {
                    "value": "*.jxr",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxr"
                },
                {
                    "value": "*.wdp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wdp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.ms-photo",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.ms-photo"
                },
                {
                    "value": "image/jxr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxr"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000524.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000524.xml"
            }
        },
        {
            "highefficiencyimagefile(heif)format,mpeghpart12": "",
            "name": "High Efficiency Image File (HEIF) Format, MPEG-H Part 12",
            "id": "fdd000525",
            "filenamepattern": [
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.heifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.heifs"
                },
                {
                    "value": "*.heif",
                    "url": "https://www.digipres.org/formats/extensions/#*.heif"
                },
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heif"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000525.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000525.xml"
            }
        },
        {
            "highefficiencyimagefile(heif)format,mpeghpart12": "",
            "name": "High Efficiency Image File (HEIF) Format, MPEG-H Part 12",
            "id": "fdd000525",
            "filenamepattern": [
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.heifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.heifs"
                },
                {
                    "value": "*.heif",
                    "url": "https://www.digipres.org/formats/extensions/#*.heif"
                },
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heif"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000525.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000525.xml"
            }
        },
        {
            "highefficiencyimagefile(heif)format,mpeghpart12": "",
            "name": "High Efficiency Image File (HEIF) Format, MPEG-H Part 12",
            "id": "fdd000525",
            "filenamepattern": [
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.heifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.heifs"
                },
                {
                    "value": "*.heif",
                    "url": "https://www.digipres.org/formats/extensions/#*.heif"
                },
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heif"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000525.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000525.xml"
            }
        },
        {
            "highefficiencyimagefile(heif)format,mpeghpart12": "",
            "name": "High Efficiency Image File (HEIF) Format, MPEG-H Part 12",
            "id": "fdd000525",
            "filenamepattern": [
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.heifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.heifs"
                },
                {
                    "value": "*.heif",
                    "url": "https://www.digipres.org/formats/extensions/#*.heif"
                },
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heif"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000525.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000525.xml"
            }
        },
        {
            "highefficiencyimagefile(heif)format,mpeghpart12": "",
            "name": "High Efficiency Image File (HEIF) Format, MPEG-H Part 12",
            "id": "fdd000525",
            "filenamepattern": [
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.heifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.heifs"
                },
                {
                    "value": "*.heif",
                    "url": "https://www.digipres.org/formats/extensions/#*.heif"
                },
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heif"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000525.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000525.xml"
            }
        },
        {
            "highefficiencyimagefile(heif)format,mpeghpart12": "",
            "name": "High Efficiency Image File (HEIF) Format, MPEG-H Part 12",
            "id": "fdd000525",
            "filenamepattern": [
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.heifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.heifs"
                },
                {
                    "value": "*.heif",
                    "url": "https://www.digipres.org/formats/extensions/#*.heif"
                },
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heif"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000525.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000525.xml"
            }
        },
        {
            "highefficiencyimagefile(heif)format,mpeghpart12": "",
            "name": "High Efficiency Image File (HEIF) Format, MPEG-H Part 12",
            "id": "fdd000525",
            "filenamepattern": [
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.heifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.heifs"
                },
                {
                    "value": "*.heif",
                    "url": "https://www.digipres.org/formats/extensions/#*.heif"
                },
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heif"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000525.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000525.xml"
            }
        },
        {
            "highefficiencyimagefile(heif)format,mpeghpart12": "",
            "name": "High Efficiency Image File (HEIF) Format, MPEG-H Part 12",
            "id": "fdd000525",
            "filenamepattern": [
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.heifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.heifs"
                },
                {
                    "value": "*.heif",
                    "url": "https://www.digipres.org/formats/extensions/#*.heif"
                },
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heif"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000525.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000525.xml"
            }
        },
        {
            "highefficiencyimagefileformat,heic/heixbrands": "",
            "name": "High Efficiency Image File Format, HEIC/HEIX brands",
            "id": "fdd000526",
            "filenamepattern": [
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heic-sequence",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic-sequence"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000526.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000526.xml"
            }
        },
        {
            "highefficiencyimagefileformat,heic/heixbrands": "",
            "name": "High Efficiency Image File Format, HEIC/HEIX brands",
            "id": "fdd000526",
            "filenamepattern": [
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heic-sequence",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic-sequence"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000526.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000526.xml"
            }
        },
        {
            "highefficiencyimagefileformat,heic/heixbrands": "",
            "name": "High Efficiency Image File Format, HEIC/HEIX brands",
            "id": "fdd000526",
            "filenamepattern": [
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heic-sequence",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic-sequence"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000526.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000526.xml"
            }
        },
        {
            "highefficiencyimagefileformat,heic/heixbrands": "",
            "name": "High Efficiency Image File Format, HEIC/HEIX brands",
            "id": "fdd000526",
            "filenamepattern": [
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heic-sequence",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic-sequence"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000526.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000526.xml"
            }
        },
        {
            "highefficiencyimagefileformat,heic/heixbrands": "",
            "name": "High Efficiency Image File Format, HEIC/HEIX brands",
            "id": "fdd000526",
            "filenamepattern": [
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heic-sequence",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic-sequence"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000526.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000526.xml"
            }
        },
        {
            "highefficiencyimagefileformat,heic/heixbrands": "",
            "name": "High Efficiency Image File Format, HEIC/HEIX brands",
            "id": "fdd000526",
            "filenamepattern": [
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heic-sequence",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic-sequence"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000526.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000526.xml"
            }
        },
        {
            "highefficiencyimagefileformat,heic/heixbrands": "",
            "name": "High Efficiency Image File Format, HEIC/HEIX brands",
            "id": "fdd000526",
            "filenamepattern": [
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heic-sequence",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic-sequence"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000526.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000526.xml"
            }
        },
        {
            "highefficiencyimagefileformat,heic/heixbrands": "",
            "name": "High Efficiency Image File Format, HEIC/HEIX brands",
            "id": "fdd000526",
            "filenamepattern": [
                {
                    "value": "*.heic",
                    "url": "https://www.digipres.org/formats/extensions/#*.heic"
                },
                {
                    "value": "*.heics",
                    "url": "https://www.digipres.org/formats/extensions/#*.heics"
                },
                {
                    "value": "*.hif",
                    "url": "https://www.digipres.org/formats/extensions/#*.hif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/heic-sequence",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic-sequence"
                },
                {
                    "value": "image/heic",
                    "url": "https://www.digipres.org/formats/mime-types/#image/heic"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000526.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000526.xml"
            }
        },
        {
            "appleprores4444codecfamily": "",
            "name": "Apple ProRes 4444 Codec Family",
            "id": "fdd000527",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000527.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000527.xml"
            }
        },
        {
            "appleprores4444codecfamily": "",
            "name": "Apple ProRes 4444 Codec Family",
            "id": "fdd000527",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000527.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000527.xml"
            }
        },
        {
            "appleprores4444codecfamily": "",
            "name": "Apple ProRes 4444 Codec Family",
            "id": "fdd000527",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000527.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000527.xml"
            }
        },
        {
            "appleprores4444codecfamily": "",
            "name": "Apple ProRes 4444 Codec Family",
            "id": "fdd000527",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000527.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000527.xml"
            }
        },
        {
            "appleprores4444codecfamily": "",
            "name": "Apple ProRes 4444 Codec Family",
            "id": "fdd000527",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000527.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000527.xml"
            }
        },
        {
            "appleprores4444codecfamily": "",
            "name": "Apple ProRes 4444 Codec Family",
            "id": "fdd000527",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000527.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000527.xml"
            }
        },
        {
            "appleprores4444codecfamily": "",
            "name": "Apple ProRes 4444 Codec Family",
            "id": "fdd000527",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000527.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000527.xml"
            }
        },
        {
            "appleprores4444codecfamily": "",
            "name": "Apple ProRes 4444 Codec Family",
            "id": "fdd000527",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000527.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000527.xml"
            }
        },
        {
            "appleproresrawcodecfamily": "",
            "name": "Apple ProRes RAW Codec Family",
            "id": "fdd000528",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000528.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000528.xml"
            }
        },
        {
            "appleproresrawcodecfamily": "",
            "name": "Apple ProRes RAW Codec Family",
            "id": "fdd000528",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000528.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000528.xml"
            }
        },
        {
            "appleproresrawcodecfamily": "",
            "name": "Apple ProRes RAW Codec Family",
            "id": "fdd000528",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000528.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000528.xml"
            }
        },
        {
            "appleproresrawcodecfamily": "",
            "name": "Apple ProRes RAW Codec Family",
            "id": "fdd000528",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000528.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000528.xml"
            }
        },
        {
            "appleproresrawcodecfamily": "",
            "name": "Apple ProRes RAW Codec Family",
            "id": "fdd000528",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000528.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000528.xml"
            }
        },
        {
            "appleproresrawcodecfamily": "",
            "name": "Apple ProRes RAW Codec Family",
            "id": "fdd000528",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000528.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000528.xml"
            }
        },
        {
            "appleproresrawcodecfamily": "",
            "name": "Apple ProRes RAW Codec Family",
            "id": "fdd000528",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000528.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000528.xml"
            }
        },
        {
            "appleproresrawcodecfamily": "",
            "name": "Apple ProRes RAW Codec Family",
            "id": "fdd000528",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000528.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000528.xml"
            }
        },
        {
            "quicktimevideo,appleprores4444codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 4444 Codec Family",
            "id": "fdd000529",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000529.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000529.xml"
            }
        },
        {
            "quicktimevideo,appleprores4444codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 4444 Codec Family",
            "id": "fdd000529",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000529.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000529.xml"
            }
        },
        {
            "quicktimevideo,appleprores4444codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 4444 Codec Family",
            "id": "fdd000529",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000529.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000529.xml"
            }
        },
        {
            "quicktimevideo,appleprores4444codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 4444 Codec Family",
            "id": "fdd000529",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000529.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000529.xml"
            }
        },
        {
            "quicktimevideo,appleprores4444codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 4444 Codec Family",
            "id": "fdd000529",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000529.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000529.xml"
            }
        },
        {
            "quicktimevideo,appleprores4444codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 4444 Codec Family",
            "id": "fdd000529",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000529.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000529.xml"
            }
        },
        {
            "quicktimevideo,appleprores4444codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 4444 Codec Family",
            "id": "fdd000529",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000529.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000529.xml"
            }
        },
        {
            "quicktimevideo,appleprores4444codecfamily": "",
            "name": "QuickTime Video, Apple ProRes 4444 Codec Family",
            "id": "fdd000529",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000529.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000529.xml"
            }
        },
        {
            "highefficiencyvideocoding(hevc)family,h.265,mpeghpart2": "",
            "name": "High Efficiency Video Coding (HEVC) Family, H.265, MPEG-H Part 2",
            "id": "fdd000530",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000530.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000530.xml"
            }
        },
        {
            "highefficiencyvideocoding(hevc)family,h.265,mpeghpart2": "",
            "name": "High Efficiency Video Coding (HEVC) Family, H.265, MPEG-H Part 2",
            "id": "fdd000530",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000530.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000530.xml"
            }
        },
        {
            "highefficiencyvideocoding(hevc)family,h.265,mpeghpart2": "",
            "name": "High Efficiency Video Coding (HEVC) Family, H.265, MPEG-H Part 2",
            "id": "fdd000530",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000530.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000530.xml"
            }
        },
        {
            "highefficiencyvideocoding(hevc)family,h.265,mpeghpart2": "",
            "name": "High Efficiency Video Coding (HEVC) Family, H.265, MPEG-H Part 2",
            "id": "fdd000530",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000530.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000530.xml"
            }
        },
        {
            "highefficiencyvideocoding(hevc)family,h.265,mpeghpart2": "",
            "name": "High Efficiency Video Coding (HEVC) Family, H.265, MPEG-H Part 2",
            "id": "fdd000530",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000530.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000530.xml"
            }
        },
        {
            "highefficiencyvideocoding(hevc)family,h.265,mpeghpart2": "",
            "name": "High Efficiency Video Coding (HEVC) Family, H.265, MPEG-H Part 2",
            "id": "fdd000530",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000530.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000530.xml"
            }
        },
        {
            "highefficiencyvideocoding(hevc)family,h.265,mpeghpart2": "",
            "name": "High Efficiency Video Coding (HEVC) Family, H.265, MPEG-H Part 2",
            "id": "fdd000530",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000530.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000530.xml"
            }
        },
        {
            "highefficiencyvideocoding(hevc)family,h.265,mpeghpart2": "",
            "name": "High Efficiency Video Coding (HEVC) Family, H.265, MPEG-H Part 2",
            "id": "fdd000530",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000530.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000530.xml"
            }
        },
        {
            "tapearchive(tar)fileformatfamily": "",
            "name": "Tape Archive (tar) File Format Family",
            "id": "fdd000531",
            "filenamepattern": [
                {
                    "value": "*.tar",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-gtar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-gtar"
                },
                {
                    "value": "application/x-compress",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compress"
                },
                {
                    "value": "application/x-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compressed"
                },
                {
                    "value": "multipart/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/x-tar"
                },
                {
                    "value": "application/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tar"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000531.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000531.xml"
            }
        },
        {
            "tapearchive(tar)fileformatfamily": "",
            "name": "Tape Archive (tar) File Format Family",
            "id": "fdd000531",
            "filenamepattern": [
                {
                    "value": "*.tar",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-gtar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-gtar"
                },
                {
                    "value": "application/x-compress",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compress"
                },
                {
                    "value": "application/x-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compressed"
                },
                {
                    "value": "multipart/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/x-tar"
                },
                {
                    "value": "application/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tar"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000531.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000531.xml"
            }
        },
        {
            "tapearchive(tar)fileformatfamily": "",
            "name": "Tape Archive (tar) File Format Family",
            "id": "fdd000531",
            "filenamepattern": [
                {
                    "value": "*.tar",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-gtar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-gtar"
                },
                {
                    "value": "application/x-compress",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compress"
                },
                {
                    "value": "application/x-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compressed"
                },
                {
                    "value": "multipart/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/x-tar"
                },
                {
                    "value": "application/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tar"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000531.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000531.xml"
            }
        },
        {
            "tapearchive(tar)fileformatfamily": "",
            "name": "Tape Archive (tar) File Format Family",
            "id": "fdd000531",
            "filenamepattern": [
                {
                    "value": "*.tar",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-gtar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-gtar"
                },
                {
                    "value": "application/x-compress",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compress"
                },
                {
                    "value": "application/x-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compressed"
                },
                {
                    "value": "multipart/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/x-tar"
                },
                {
                    "value": "application/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tar"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000531.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000531.xml"
            }
        },
        {
            "tapearchive(tar)fileformatfamily": "",
            "name": "Tape Archive (tar) File Format Family",
            "id": "fdd000531",
            "filenamepattern": [
                {
                    "value": "*.tar",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-gtar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-gtar"
                },
                {
                    "value": "application/x-compress",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compress"
                },
                {
                    "value": "application/x-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compressed"
                },
                {
                    "value": "multipart/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/x-tar"
                },
                {
                    "value": "application/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tar"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000531.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000531.xml"
            }
        },
        {
            "tapearchive(tar)fileformatfamily": "",
            "name": "Tape Archive (tar) File Format Family",
            "id": "fdd000531",
            "filenamepattern": [
                {
                    "value": "*.tar",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-gtar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-gtar"
                },
                {
                    "value": "application/x-compress",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compress"
                },
                {
                    "value": "application/x-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compressed"
                },
                {
                    "value": "multipart/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/x-tar"
                },
                {
                    "value": "application/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tar"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000531.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000531.xml"
            }
        },
        {
            "tapearchive(tar)fileformatfamily": "",
            "name": "Tape Archive (tar) File Format Family",
            "id": "fdd000531",
            "filenamepattern": [
                {
                    "value": "*.tar",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-gtar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-gtar"
                },
                {
                    "value": "application/x-compress",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compress"
                },
                {
                    "value": "application/x-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compressed"
                },
                {
                    "value": "multipart/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/x-tar"
                },
                {
                    "value": "application/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tar"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000531.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000531.xml"
            }
        },
        {
            "tapearchive(tar)fileformatfamily": "",
            "name": "Tape Archive (tar) File Format Family",
            "id": "fdd000531",
            "filenamepattern": [
                {
                    "value": "*.tar",
                    "url": "https://www.digipres.org/formats/extensions/#*.tar"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-gtar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-gtar"
                },
                {
                    "value": "application/x-compress",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compress"
                },
                {
                    "value": "application/x-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-compressed"
                },
                {
                    "value": "multipart/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/x-tar"
                },
                {
                    "value": "application/x-tar",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-tar"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000531.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000531.xml"
            }
        },
        {
            "pdf/a4,pdfforlongtermpreservation,useofiso320002": "",
            "name": "PDF/A-4, PDF for Long-term Preservation, Use of ISO 32000-2",
            "id": "fdd000532",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000532.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000532.xml"
            }
        },
        {
            "pdf/a4,pdfforlongtermpreservation,useofiso320002": "",
            "name": "PDF/A-4, PDF for Long-term Preservation, Use of ISO 32000-2",
            "id": "fdd000532",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000532.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000532.xml"
            }
        },
        {
            "pdf/a4,pdfforlongtermpreservation,useofiso320002": "",
            "name": "PDF/A-4, PDF for Long-term Preservation, Use of ISO 32000-2",
            "id": "fdd000532",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000532.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000532.xml"
            }
        },
        {
            "pdf/a4,pdfforlongtermpreservation,useofiso320002": "",
            "name": "PDF/A-4, PDF for Long-term Preservation, Use of ISO 32000-2",
            "id": "fdd000532",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000532.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000532.xml"
            }
        },
        {
            "pdf/a4,pdfforlongtermpreservation,useofiso320002": "",
            "name": "PDF/A-4, PDF for Long-term Preservation, Use of ISO 32000-2",
            "id": "fdd000532",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000532.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000532.xml"
            }
        },
        {
            "pdf/a4,pdfforlongtermpreservation,useofiso320002": "",
            "name": "PDF/A-4, PDF for Long-term Preservation, Use of ISO 32000-2",
            "id": "fdd000532",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000532.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000532.xml"
            }
        },
        {
            "pdf/a4,pdfforlongtermpreservation,useofiso320002": "",
            "name": "PDF/A-4, PDF for Long-term Preservation, Use of ISO 32000-2",
            "id": "fdd000532",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000532.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000532.xml"
            }
        },
        {
            "pdf/a4,pdfforlongtermpreservation,useofiso320002": "",
            "name": "PDF/A-4, PDF for Long-term Preservation, Use of ISO 32000-2",
            "id": "fdd000532",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000532.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000532.xml"
            }
        },
        {
            "tsv,tabseparatedvalues": "",
            "name": "TSV, Tab-Separated Values",
            "id": "fdd000533",
            "filenamepattern": [
                {
                    "value": "*.tsv",
                    "url": "https://www.digipres.org/formats/extensions/#*.tsv"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/tab-separated-values",
                    "url": "https://www.digipres.org/formats/mime-types/#text/tab-separated-values"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000533.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000533.xml"
            }
        },
        {
            "tsv,tabseparatedvalues": "",
            "name": "TSV, Tab-Separated Values",
            "id": "fdd000533",
            "filenamepattern": [
                {
                    "value": "*.tsv",
                    "url": "https://www.digipres.org/formats/extensions/#*.tsv"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/tab-separated-values",
                    "url": "https://www.digipres.org/formats/mime-types/#text/tab-separated-values"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000533.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000533.xml"
            }
        },
        {
            "tsv,tabseparatedvalues": "",
            "name": "TSV, Tab-Separated Values",
            "id": "fdd000533",
            "filenamepattern": [
                {
                    "value": "*.tsv",
                    "url": "https://www.digipres.org/formats/extensions/#*.tsv"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/tab-separated-values",
                    "url": "https://www.digipres.org/formats/mime-types/#text/tab-separated-values"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000533.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000533.xml"
            }
        },
        {
            "tsv,tabseparatedvalues": "",
            "name": "TSV, Tab-Separated Values",
            "id": "fdd000533",
            "filenamepattern": [
                {
                    "value": "*.tsv",
                    "url": "https://www.digipres.org/formats/extensions/#*.tsv"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/tab-separated-values",
                    "url": "https://www.digipres.org/formats/mime-types/#text/tab-separated-values"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000533.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000533.xml"
            }
        },
        {
            "tsv,tabseparatedvalues": "",
            "name": "TSV, Tab-Separated Values",
            "id": "fdd000533",
            "filenamepattern": [
                {
                    "value": "*.tsv",
                    "url": "https://www.digipres.org/formats/extensions/#*.tsv"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/tab-separated-values",
                    "url": "https://www.digipres.org/formats/mime-types/#text/tab-separated-values"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000533.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000533.xml"
            }
        },
        {
            "tsv,tabseparatedvalues": "",
            "name": "TSV, Tab-Separated Values",
            "id": "fdd000533",
            "filenamepattern": [
                {
                    "value": "*.tsv",
                    "url": "https://www.digipres.org/formats/extensions/#*.tsv"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/tab-separated-values",
                    "url": "https://www.digipres.org/formats/mime-types/#text/tab-separated-values"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000533.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000533.xml"
            }
        },
        {
            "tsv,tabseparatedvalues": "",
            "name": "TSV, Tab-Separated Values",
            "id": "fdd000533",
            "filenamepattern": [
                {
                    "value": "*.tsv",
                    "url": "https://www.digipres.org/formats/extensions/#*.tsv"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/tab-separated-values",
                    "url": "https://www.digipres.org/formats/mime-types/#text/tab-separated-values"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000533.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000533.xml"
            }
        },
        {
            "tsv,tabseparatedvalues": "",
            "name": "TSV, Tab-Separated Values",
            "id": "fdd000533",
            "filenamepattern": [
                {
                    "value": "*.tsv",
                    "url": "https://www.digipres.org/formats/extensions/#*.tsv"
                },
                {
                    "value": "*.tab",
                    "url": "https://www.digipres.org/formats/extensions/#*.tab"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/tab-separated-values",
                    "url": "https://www.digipres.org/formats/mime-types/#text/tab-separated-values"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000533.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000533.xml"
            }
        },
        {
            "quicktimevideo,appleproresrawcodecfamily": "",
            "name": "QuickTime Video, Apple ProRes RAW Codec Family",
            "id": "fdd000534",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000534.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000534.xml"
            }
        },
        {
            "quicktimevideo,appleproresrawcodecfamily": "",
            "name": "QuickTime Video, Apple ProRes RAW Codec Family",
            "id": "fdd000534",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000534.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000534.xml"
            }
        },
        {
            "quicktimevideo,appleproresrawcodecfamily": "",
            "name": "QuickTime Video, Apple ProRes RAW Codec Family",
            "id": "fdd000534",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000534.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000534.xml"
            }
        },
        {
            "quicktimevideo,appleproresrawcodecfamily": "",
            "name": "QuickTime Video, Apple ProRes RAW Codec Family",
            "id": "fdd000534",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000534.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000534.xml"
            }
        },
        {
            "quicktimevideo,appleproresrawcodecfamily": "",
            "name": "QuickTime Video, Apple ProRes RAW Codec Family",
            "id": "fdd000534",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000534.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000534.xml"
            }
        },
        {
            "quicktimevideo,appleproresrawcodecfamily": "",
            "name": "QuickTime Video, Apple ProRes RAW Codec Family",
            "id": "fdd000534",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000534.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000534.xml"
            }
        },
        {
            "quicktimevideo,appleproresrawcodecfamily": "",
            "name": "QuickTime Video, Apple ProRes RAW Codec Family",
            "id": "fdd000534",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000534.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000534.xml"
            }
        },
        {
            "quicktimevideo,appleproresrawcodecfamily": "",
            "name": "QuickTime Video, Apple ProRes RAW Codec Family",
            "id": "fdd000534",
            "filenamepattern": [
                {
                    "value": "*.mov",
                    "url": "https://www.digipres.org/formats/extensions/#*.mov"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000534.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000534.xml"
            }
        },
        {
            "interoperablemasterformat(imf)": "",
            "name": "Interoperable Master Format (IMF)",
            "id": "fdd000535",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000535.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000535.xml"
            }
        },
        {
            "interoperablemasterformat(imf)": "",
            "name": "Interoperable Master Format (IMF)",
            "id": "fdd000535",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000535.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000535.xml"
            }
        },
        {
            "interoperablemasterformat(imf)": "",
            "name": "Interoperable Master Format (IMF)",
            "id": "fdd000535",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000535.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000535.xml"
            }
        },
        {
            "interoperablemasterformat(imf)": "",
            "name": "Interoperable Master Format (IMF)",
            "id": "fdd000535",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000535.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000535.xml"
            }
        },
        {
            "interoperablemasterformat(imf)": "",
            "name": "Interoperable Master Format (IMF)",
            "id": "fdd000535",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000535.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000535.xml"
            }
        },
        {
            "interoperablemasterformat(imf)": "",
            "name": "Interoperable Master Format (IMF)",
            "id": "fdd000535",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000535.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000535.xml"
            }
        },
        {
            "interoperablemasterformat(imf)": "",
            "name": "Interoperable Master Format (IMF)",
            "id": "fdd000535",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000535.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000535.xml"
            }
        },
        {
            "interoperablemasterformat(imf)": "",
            "name": "Interoperable Master Format (IMF)",
            "id": "fdd000535",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000535.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000535.xml"
            }
        },
        {
            "jpegxlimageencoding": "",
            "name": "JPEG XL Image Encoding",
            "id": "fdd000536",
            "filenamepattern": [
                {
                    "value": "*.Not applicable.",
                    "url": "https://www.digipres.org/formats/extensions/#*.not applicable."
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000536.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000536.xml"
            }
        },
        {
            "jpegxlimageencoding": "",
            "name": "JPEG XL Image Encoding",
            "id": "fdd000536",
            "filenamepattern": [
                {
                    "value": "*.Not applicable.",
                    "url": "https://www.digipres.org/formats/extensions/#*.not applicable."
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000536.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000536.xml"
            }
        },
        {
            "jpegxlimageencoding": "",
            "name": "JPEG XL Image Encoding",
            "id": "fdd000536",
            "filenamepattern": [
                {
                    "value": "*.Not applicable.",
                    "url": "https://www.digipres.org/formats/extensions/#*.not applicable."
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000536.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000536.xml"
            }
        },
        {
            "jpegxlimageencoding": "",
            "name": "JPEG XL Image Encoding",
            "id": "fdd000536",
            "filenamepattern": [
                {
                    "value": "*.Not applicable.",
                    "url": "https://www.digipres.org/formats/extensions/#*.not applicable."
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000536.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000536.xml"
            }
        },
        {
            "jpegxlimageencoding": "",
            "name": "JPEG XL Image Encoding",
            "id": "fdd000536",
            "filenamepattern": [
                {
                    "value": "*.Not applicable.",
                    "url": "https://www.digipres.org/formats/extensions/#*.not applicable."
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000536.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000536.xml"
            }
        },
        {
            "jpegxlimageencoding": "",
            "name": "JPEG XL Image Encoding",
            "id": "fdd000536",
            "filenamepattern": [
                {
                    "value": "*.Not applicable.",
                    "url": "https://www.digipres.org/formats/extensions/#*.not applicable."
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000536.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000536.xml"
            }
        },
        {
            "jpegxlimageencoding": "",
            "name": "JPEG XL Image Encoding",
            "id": "fdd000536",
            "filenamepattern": [
                {
                    "value": "*.Not applicable.",
                    "url": "https://www.digipres.org/formats/extensions/#*.not applicable."
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000536.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000536.xml"
            }
        },
        {
            "jpegxlimageencoding": "",
            "name": "JPEG XL Image Encoding",
            "id": "fdd000536",
            "filenamepattern": [
                {
                    "value": "*.Not applicable.",
                    "url": "https://www.digipres.org/formats/extensions/#*.not applicable."
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000536.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000536.xml"
            }
        },
        {
            "hypercardstackfileformat": "",
            "name": "HyperCard Stack File Format",
            "id": "fdd000537",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000537.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000537.xml"
            }
        },
        {
            "hypercardstackfileformat": "",
            "name": "HyperCard Stack File Format",
            "id": "fdd000537",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000537.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000537.xml"
            }
        },
        {
            "hypercardstackfileformat": "",
            "name": "HyperCard Stack File Format",
            "id": "fdd000537",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000537.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000537.xml"
            }
        },
        {
            "hypercardstackfileformat": "",
            "name": "HyperCard Stack File Format",
            "id": "fdd000537",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000537.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000537.xml"
            }
        },
        {
            "hypercardstackfileformat": "",
            "name": "HyperCard Stack File Format",
            "id": "fdd000537",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000537.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000537.xml"
            }
        },
        {
            "hypercardstackfileformat": "",
            "name": "HyperCard Stack File Format",
            "id": "fdd000537",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000537.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000537.xml"
            }
        },
        {
            "hypercardstackfileformat": "",
            "name": "HyperCard Stack File Format",
            "id": "fdd000537",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000537.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000537.xml"
            }
        },
        {
            "hypercardstackfileformat": "",
            "name": "HyperCard Stack File Format",
            "id": "fdd000537",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000537.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000537.xml"
            }
        },
        {
            "jpegxlfileformat": "",
            "name": "JPEG XL File Format",
            "id": "fdd000538",
            "filenamepattern": [
                {
                    "value": "*.jxl",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxl"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000538.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000538.xml"
            }
        },
        {
            "jpegxlfileformat": "",
            "name": "JPEG XL File Format",
            "id": "fdd000538",
            "filenamepattern": [
                {
                    "value": "*.jxl",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxl"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000538.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000538.xml"
            }
        },
        {
            "jpegxlfileformat": "",
            "name": "JPEG XL File Format",
            "id": "fdd000538",
            "filenamepattern": [
                {
                    "value": "*.jxl",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxl"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000538.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000538.xml"
            }
        },
        {
            "jpegxlfileformat": "",
            "name": "JPEG XL File Format",
            "id": "fdd000538",
            "filenamepattern": [
                {
                    "value": "*.jxl",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxl"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000538.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000538.xml"
            }
        },
        {
            "jpegxlfileformat": "",
            "name": "JPEG XL File Format",
            "id": "fdd000538",
            "filenamepattern": [
                {
                    "value": "*.jxl",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxl"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000538.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000538.xml"
            }
        },
        {
            "jpegxlfileformat": "",
            "name": "JPEG XL File Format",
            "id": "fdd000538",
            "filenamepattern": [
                {
                    "value": "*.jxl",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxl"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000538.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000538.xml"
            }
        },
        {
            "jpegxlfileformat": "",
            "name": "JPEG XL File Format",
            "id": "fdd000538",
            "filenamepattern": [
                {
                    "value": "*.jxl",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxl"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000538.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000538.xml"
            }
        },
        {
            "jpegxlfileformat": "",
            "name": "JPEG XL File Format",
            "id": "fdd000538",
            "filenamepattern": [
                {
                    "value": "*.jxl",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxl"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxl",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxl"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000538.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000538.xml"
            }
        },
        {
            "7zfileformat": "",
            "name": "7z File Format",
            "id": "fdd000539",
            "filenamepattern": [
                {
                    "value": "*.7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.7z"
                },
                {
                    "value": "*.s7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.s7z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-7z-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-7z-compressed"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000539.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000539.xml"
            }
        },
        {
            "7zfileformat": "",
            "name": "7z File Format",
            "id": "fdd000539",
            "filenamepattern": [
                {
                    "value": "*.7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.7z"
                },
                {
                    "value": "*.s7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.s7z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-7z-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-7z-compressed"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000539.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000539.xml"
            }
        },
        {
            "7zfileformat": "",
            "name": "7z File Format",
            "id": "fdd000539",
            "filenamepattern": [
                {
                    "value": "*.7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.7z"
                },
                {
                    "value": "*.s7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.s7z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-7z-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-7z-compressed"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000539.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000539.xml"
            }
        },
        {
            "7zfileformat": "",
            "name": "7z File Format",
            "id": "fdd000539",
            "filenamepattern": [
                {
                    "value": "*.7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.7z"
                },
                {
                    "value": "*.s7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.s7z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-7z-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-7z-compressed"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000539.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000539.xml"
            }
        },
        {
            "7zfileformat": "",
            "name": "7z File Format",
            "id": "fdd000539",
            "filenamepattern": [
                {
                    "value": "*.7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.7z"
                },
                {
                    "value": "*.s7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.s7z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-7z-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-7z-compressed"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000539.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000539.xml"
            }
        },
        {
            "7zfileformat": "",
            "name": "7z File Format",
            "id": "fdd000539",
            "filenamepattern": [
                {
                    "value": "*.7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.7z"
                },
                {
                    "value": "*.s7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.s7z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-7z-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-7z-compressed"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000539.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000539.xml"
            }
        },
        {
            "7zfileformat": "",
            "name": "7z File Format",
            "id": "fdd000539",
            "filenamepattern": [
                {
                    "value": "*.7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.7z"
                },
                {
                    "value": "*.s7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.s7z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-7z-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-7z-compressed"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000539.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000539.xml"
            }
        },
        {
            "7zfileformat": "",
            "name": "7z File Format",
            "id": "fdd000539",
            "filenamepattern": [
                {
                    "value": "*.7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.7z"
                },
                {
                    "value": "*.s7z",
                    "url": "https://www.digipres.org/formats/extensions/#*.s7z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-7z-compressed",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-7z-compressed"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000539.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000539.xml"
            }
        },
        {
            "av1imagefileformat(avif)": "",
            "name": "AV1 Image File Format (AVIF)",
            "id": "fdd000540",
            "filenamepattern": [
                {
                    "value": "*.avifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.avifs"
                },
                {
                    "value": "*.avif",
                    "url": "https://www.digipres.org/formats/extensions/#*.avif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/avif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avif"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000540.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000540.xml"
            }
        },
        {
            "av1imagefileformat(avif)": "",
            "name": "AV1 Image File Format (AVIF)",
            "id": "fdd000540",
            "filenamepattern": [
                {
                    "value": "*.avifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.avifs"
                },
                {
                    "value": "*.avif",
                    "url": "https://www.digipres.org/formats/extensions/#*.avif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/avif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avif"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000540.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000540.xml"
            }
        },
        {
            "av1imagefileformat(avif)": "",
            "name": "AV1 Image File Format (AVIF)",
            "id": "fdd000540",
            "filenamepattern": [
                {
                    "value": "*.avifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.avifs"
                },
                {
                    "value": "*.avif",
                    "url": "https://www.digipres.org/formats/extensions/#*.avif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/avif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avif"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000540.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000540.xml"
            }
        },
        {
            "av1imagefileformat(avif)": "",
            "name": "AV1 Image File Format (AVIF)",
            "id": "fdd000540",
            "filenamepattern": [
                {
                    "value": "*.avifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.avifs"
                },
                {
                    "value": "*.avif",
                    "url": "https://www.digipres.org/formats/extensions/#*.avif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/avif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avif"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000540.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000540.xml"
            }
        },
        {
            "av1imagefileformat(avif)": "",
            "name": "AV1 Image File Format (AVIF)",
            "id": "fdd000540",
            "filenamepattern": [
                {
                    "value": "*.avifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.avifs"
                },
                {
                    "value": "*.avif",
                    "url": "https://www.digipres.org/formats/extensions/#*.avif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/avif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avif"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000540.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000540.xml"
            }
        },
        {
            "av1imagefileformat(avif)": "",
            "name": "AV1 Image File Format (AVIF)",
            "id": "fdd000540",
            "filenamepattern": [
                {
                    "value": "*.avifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.avifs"
                },
                {
                    "value": "*.avif",
                    "url": "https://www.digipres.org/formats/extensions/#*.avif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/avif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avif"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000540.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000540.xml"
            }
        },
        {
            "av1imagefileformat(avif)": "",
            "name": "AV1 Image File Format (AVIF)",
            "id": "fdd000540",
            "filenamepattern": [
                {
                    "value": "*.avifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.avifs"
                },
                {
                    "value": "*.avif",
                    "url": "https://www.digipres.org/formats/extensions/#*.avif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/avif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avif"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000540.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000540.xml"
            }
        },
        {
            "av1imagefileformat(avif)": "",
            "name": "AV1 Image File Format (AVIF)",
            "id": "fdd000540",
            "filenamepattern": [
                {
                    "value": "*.avifs",
                    "url": "https://www.digipres.org/formats/extensions/#*.avifs"
                },
                {
                    "value": "*.avif",
                    "url": "https://www.digipres.org/formats/extensions/#*.avif"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/avif",
                    "url": "https://www.digipres.org/formats/mime-types/#image/avif"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000540.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000540.xml"
            }
        },
        {
            "av1videoencoding(aomediavideo1)": "",
            "name": "AV1 Video Encoding (AOMedia Video 1)",
            "id": "fdd000541",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/av1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/av1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000541.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000541.xml"
            }
        },
        {
            "av1videoencoding(aomediavideo1)": "",
            "name": "AV1 Video Encoding (AOMedia Video 1)",
            "id": "fdd000541",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/av1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/av1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000541.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000541.xml"
            }
        },
        {
            "av1videoencoding(aomediavideo1)": "",
            "name": "AV1 Video Encoding (AOMedia Video 1)",
            "id": "fdd000541",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/av1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/av1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000541.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000541.xml"
            }
        },
        {
            "av1videoencoding(aomediavideo1)": "",
            "name": "AV1 Video Encoding (AOMedia Video 1)",
            "id": "fdd000541",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/av1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/av1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000541.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000541.xml"
            }
        },
        {
            "av1videoencoding(aomediavideo1)": "",
            "name": "AV1 Video Encoding (AOMedia Video 1)",
            "id": "fdd000541",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/av1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/av1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000541.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000541.xml"
            }
        },
        {
            "av1videoencoding(aomediavideo1)": "",
            "name": "AV1 Video Encoding (AOMedia Video 1)",
            "id": "fdd000541",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/av1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/av1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000541.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000541.xml"
            }
        },
        {
            "av1videoencoding(aomediavideo1)": "",
            "name": "AV1 Video Encoding (AOMedia Video 1)",
            "id": "fdd000541",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/av1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/av1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000541.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000541.xml"
            }
        },
        {
            "av1videoencoding(aomediavideo1)": "",
            "name": "AV1 Video Encoding (AOMedia Video 1)",
            "id": "fdd000541",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/av1",
                    "url": "https://www.digipres.org/formats/mime-types/#video/av1"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000541.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000541.xml"
            }
        },
        {
            "matroskafileformatwithadvancedaudiocodec(aac)audioencoding": "",
            "name": "Matroska File Format with Advanced Audio Codec (AAC) Audio Encoding",
            "id": "fdd000542",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000542.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000542.xml"
            }
        },
        {
            "matroskafileformatwithadvancedaudiocodec(aac)audioencoding": "",
            "name": "Matroska File Format with Advanced Audio Codec (AAC) Audio Encoding",
            "id": "fdd000542",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000542.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000542.xml"
            }
        },
        {
            "matroskafileformatwithadvancedaudiocodec(aac)audioencoding": "",
            "name": "Matroska File Format with Advanced Audio Codec (AAC) Audio Encoding",
            "id": "fdd000542",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000542.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000542.xml"
            }
        },
        {
            "matroskafileformatwithadvancedaudiocodec(aac)audioencoding": "",
            "name": "Matroska File Format with Advanced Audio Codec (AAC) Audio Encoding",
            "id": "fdd000542",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000542.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000542.xml"
            }
        },
        {
            "matroskafileformatwithadvancedaudiocodec(aac)audioencoding": "",
            "name": "Matroska File Format with Advanced Audio Codec (AAC) Audio Encoding",
            "id": "fdd000542",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000542.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000542.xml"
            }
        },
        {
            "matroskafileformatwithadvancedaudiocodec(aac)audioencoding": "",
            "name": "Matroska File Format with Advanced Audio Codec (AAC) Audio Encoding",
            "id": "fdd000542",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000542.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000542.xml"
            }
        },
        {
            "matroskafileformatwithadvancedaudiocodec(aac)audioencoding": "",
            "name": "Matroska File Format with Advanced Audio Codec (AAC) Audio Encoding",
            "id": "fdd000542",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000542.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000542.xml"
            }
        },
        {
            "matroskafileformatwithadvancedaudiocodec(aac)audioencoding": "",
            "name": "Matroska File Format with Advanced Audio Codec (AAC) Audio Encoding",
            "id": "fdd000542",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000542.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000542.xml"
            }
        },
        {
            "mxfarchiveandpreservationformatregistereddisclosuredocument(smpterdd48)": "",
            "name": "MXF Archive and Preservation Format Registered Disclosure Document (SMPTE RDD 48)",
            "id": "fdd000543",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000543.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000543.xml"
            }
        },
        {
            "mxfarchiveandpreservationformatregistereddisclosuredocument(smpterdd48)": "",
            "name": "MXF Archive and Preservation Format Registered Disclosure Document (SMPTE RDD 48)",
            "id": "fdd000543",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000543.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000543.xml"
            }
        },
        {
            "mxfarchiveandpreservationformatregistereddisclosuredocument(smpterdd48)": "",
            "name": "MXF Archive and Preservation Format Registered Disclosure Document (SMPTE RDD 48)",
            "id": "fdd000543",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000543.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000543.xml"
            }
        },
        {
            "mxfarchiveandpreservationformatregistereddisclosuredocument(smpterdd48)": "",
            "name": "MXF Archive and Preservation Format Registered Disclosure Document (SMPTE RDD 48)",
            "id": "fdd000543",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000543.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000543.xml"
            }
        },
        {
            "mxfarchiveandpreservationformatregistereddisclosuredocument(smpterdd48)": "",
            "name": "MXF Archive and Preservation Format Registered Disclosure Document (SMPTE RDD 48)",
            "id": "fdd000543",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000543.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000543.xml"
            }
        },
        {
            "mxfarchiveandpreservationformatregistereddisclosuredocument(smpterdd48)": "",
            "name": "MXF Archive and Preservation Format Registered Disclosure Document (SMPTE RDD 48)",
            "id": "fdd000543",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000543.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000543.xml"
            }
        },
        {
            "mxfarchiveandpreservationformatregistereddisclosuredocument(smpterdd48)": "",
            "name": "MXF Archive and Preservation Format Registered Disclosure Document (SMPTE RDD 48)",
            "id": "fdd000543",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000543.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000543.xml"
            }
        },
        {
            "mxfarchiveandpreservationformatregistereddisclosuredocument(smpterdd48)": "",
            "name": "MXF Archive and Preservation Format Registered Disclosure Document (SMPTE RDD 48)",
            "id": "fdd000543",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000543.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000543.xml"
            }
        },
        {
            "mxfgenericcontainermappedtoffv1encoding(smpterdd48amd1)": "",
            "name": "MXF Generic Container Mapped to FFV1 Encoding (SMPTE RDD 48 Amd 1)",
            "id": "fdd000544",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000544.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000544.xml"
            }
        },
        {
            "mxfgenericcontainermappedtoffv1encoding(smpterdd48amd1)": "",
            "name": "MXF Generic Container Mapped to FFV1 Encoding (SMPTE RDD 48 Amd 1)",
            "id": "fdd000544",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000544.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000544.xml"
            }
        },
        {
            "mxfgenericcontainermappedtoffv1encoding(smpterdd48amd1)": "",
            "name": "MXF Generic Container Mapped to FFV1 Encoding (SMPTE RDD 48 Amd 1)",
            "id": "fdd000544",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000544.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000544.xml"
            }
        },
        {
            "mxfgenericcontainermappedtoffv1encoding(smpterdd48amd1)": "",
            "name": "MXF Generic Container Mapped to FFV1 Encoding (SMPTE RDD 48 Amd 1)",
            "id": "fdd000544",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000544.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000544.xml"
            }
        },
        {
            "mxfgenericcontainermappedtoffv1encoding(smpterdd48amd1)": "",
            "name": "MXF Generic Container Mapped to FFV1 Encoding (SMPTE RDD 48 Amd 1)",
            "id": "fdd000544",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000544.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000544.xml"
            }
        },
        {
            "mxfgenericcontainermappedtoffv1encoding(smpterdd48amd1)": "",
            "name": "MXF Generic Container Mapped to FFV1 Encoding (SMPTE RDD 48 Amd 1)",
            "id": "fdd000544",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000544.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000544.xml"
            }
        },
        {
            "mxfgenericcontainermappedtoffv1encoding(smpterdd48amd1)": "",
            "name": "MXF Generic Container Mapped to FFV1 Encoding (SMPTE RDD 48 Amd 1)",
            "id": "fdd000544",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000544.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000544.xml"
            }
        },
        {
            "mxfgenericcontainermappedtoffv1encoding(smpterdd48amd1)": "",
            "name": "MXF Generic Container Mapped to FFV1 Encoding (SMPTE RDD 48 Amd 1)",
            "id": "fdd000544",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000544.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000544.xml"
            }
        },
        {
            "jpegxsencoding": "",
            "name": "JPEG XS Encoding",
            "id": "fdd000545",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "image/jxss",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxss"
                },
                {
                    "value": "image/jxsi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsi"
                },
                {
                    "value": "video/jxsv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/jxsv"
                },
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                },
                {
                    "value": "image/jxsc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000545.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000545.xml"
            }
        },
        {
            "jpegxsencoding": "",
            "name": "JPEG XS Encoding",
            "id": "fdd000545",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "image/jxss",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxss"
                },
                {
                    "value": "image/jxsi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsi"
                },
                {
                    "value": "video/jxsv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/jxsv"
                },
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                },
                {
                    "value": "image/jxsc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000545.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000545.xml"
            }
        },
        {
            "jpegxsencoding": "",
            "name": "JPEG XS Encoding",
            "id": "fdd000545",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "image/jxss",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxss"
                },
                {
                    "value": "image/jxsi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsi"
                },
                {
                    "value": "video/jxsv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/jxsv"
                },
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                },
                {
                    "value": "image/jxsc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000545.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000545.xml"
            }
        },
        {
            "jpegxsencoding": "",
            "name": "JPEG XS Encoding",
            "id": "fdd000545",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "image/jxss",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxss"
                },
                {
                    "value": "image/jxsi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsi"
                },
                {
                    "value": "video/jxsv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/jxsv"
                },
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                },
                {
                    "value": "image/jxsc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000545.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000545.xml"
            }
        },
        {
            "jpegxsencoding": "",
            "name": "JPEG XS Encoding",
            "id": "fdd000545",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "image/jxss",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxss"
                },
                {
                    "value": "image/jxsi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsi"
                },
                {
                    "value": "video/jxsv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/jxsv"
                },
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                },
                {
                    "value": "image/jxsc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000545.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000545.xml"
            }
        },
        {
            "jpegxsencoding": "",
            "name": "JPEG XS Encoding",
            "id": "fdd000545",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "image/jxss",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxss"
                },
                {
                    "value": "image/jxsi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsi"
                },
                {
                    "value": "video/jxsv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/jxsv"
                },
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                },
                {
                    "value": "image/jxsc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000545.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000545.xml"
            }
        },
        {
            "jpegxsencoding": "",
            "name": "JPEG XS Encoding",
            "id": "fdd000545",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "image/jxss",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxss"
                },
                {
                    "value": "image/jxsi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsi"
                },
                {
                    "value": "video/jxsv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/jxsv"
                },
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                },
                {
                    "value": "image/jxsc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000545.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000545.xml"
            }
        },
        {
            "jpegxsencoding": "",
            "name": "JPEG XS Encoding",
            "id": "fdd000545",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "image/jxss",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxss"
                },
                {
                    "value": "image/jxsi",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsi"
                },
                {
                    "value": "video/jxsv",
                    "url": "https://www.digipres.org/formats/mime-types/#video/jxsv"
                },
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                },
                {
                    "value": "image/jxsc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxsc"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000545.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000545.xml"
            }
        },
        {
            "jpegxsfileformat": "",
            "name": "JPEG XS File Format",
            "id": "fdd000546",
            "filenamepattern": [
                {
                    "value": "*.jxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxs"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000546.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000546.xml"
            }
        },
        {
            "jpegxsfileformat": "",
            "name": "JPEG XS File Format",
            "id": "fdd000546",
            "filenamepattern": [
                {
                    "value": "*.jxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxs"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000546.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000546.xml"
            }
        },
        {
            "jpegxsfileformat": "",
            "name": "JPEG XS File Format",
            "id": "fdd000546",
            "filenamepattern": [
                {
                    "value": "*.jxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxs"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000546.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000546.xml"
            }
        },
        {
            "jpegxsfileformat": "",
            "name": "JPEG XS File Format",
            "id": "fdd000546",
            "filenamepattern": [
                {
                    "value": "*.jxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxs"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000546.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000546.xml"
            }
        },
        {
            "jpegxsfileformat": "",
            "name": "JPEG XS File Format",
            "id": "fdd000546",
            "filenamepattern": [
                {
                    "value": "*.jxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxs"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000546.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000546.xml"
            }
        },
        {
            "jpegxsfileformat": "",
            "name": "JPEG XS File Format",
            "id": "fdd000546",
            "filenamepattern": [
                {
                    "value": "*.jxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxs"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000546.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000546.xml"
            }
        },
        {
            "jpegxsfileformat": "",
            "name": "JPEG XS File Format",
            "id": "fdd000546",
            "filenamepattern": [
                {
                    "value": "*.jxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxs"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000546.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000546.xml"
            }
        },
        {
            "jpegxsfileformat": "",
            "name": "JPEG XS File Format",
            "id": "fdd000546",
            "filenamepattern": [
                {
                    "value": "*.jxs",
                    "url": "https://www.digipres.org/formats/extensions/#*.jxs"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jxs",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jxs"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000546.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000546.xml"
            }
        },
        {
            "kmlzipped": "",
            "name": "KML Zipped",
            "id": "fdd000547",
            "filenamepattern": [
                {
                    "value": "*.kmz",
                    "url": "https://www.digipres.org/formats/extensions/#*.kmz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kmz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kmz"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000547.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000547.xml"
            }
        },
        {
            "kmlzipped": "",
            "name": "KML Zipped",
            "id": "fdd000547",
            "filenamepattern": [
                {
                    "value": "*.kmz",
                    "url": "https://www.digipres.org/formats/extensions/#*.kmz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kmz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kmz"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000547.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000547.xml"
            }
        },
        {
            "kmlzipped": "",
            "name": "KML Zipped",
            "id": "fdd000547",
            "filenamepattern": [
                {
                    "value": "*.kmz",
                    "url": "https://www.digipres.org/formats/extensions/#*.kmz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kmz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kmz"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000547.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000547.xml"
            }
        },
        {
            "kmlzipped": "",
            "name": "KML Zipped",
            "id": "fdd000547",
            "filenamepattern": [
                {
                    "value": "*.kmz",
                    "url": "https://www.digipres.org/formats/extensions/#*.kmz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kmz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kmz"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000547.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000547.xml"
            }
        },
        {
            "kmlzipped": "",
            "name": "KML Zipped",
            "id": "fdd000547",
            "filenamepattern": [
                {
                    "value": "*.kmz",
                    "url": "https://www.digipres.org/formats/extensions/#*.kmz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kmz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kmz"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000547.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000547.xml"
            }
        },
        {
            "kmlzipped": "",
            "name": "KML Zipped",
            "id": "fdd000547",
            "filenamepattern": [
                {
                    "value": "*.kmz",
                    "url": "https://www.digipres.org/formats/extensions/#*.kmz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kmz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kmz"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000547.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000547.xml"
            }
        },
        {
            "kmlzipped": "",
            "name": "KML Zipped",
            "id": "fdd000547",
            "filenamepattern": [
                {
                    "value": "*.kmz",
                    "url": "https://www.digipres.org/formats/extensions/#*.kmz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kmz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kmz"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000547.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000547.xml"
            }
        },
        {
            "kmlzipped": "",
            "name": "KML Zipped",
            "id": "fdd000547",
            "filenamepattern": [
                {
                    "value": "*.kmz",
                    "url": "https://www.digipres.org/formats/extensions/#*.kmz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.google-earth.kmz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.google-earth.kmz"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000547.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000547.xml"
            }
        },
        {
            "wellknowntext": "",
            "name": "Well-known Text",
            "id": "fdd000548",
            "filenamepattern": [
                {
                    "value": "*.wkt",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000548.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000548.xml"
            }
        },
        {
            "wellknowntext": "",
            "name": "Well-known Text",
            "id": "fdd000548",
            "filenamepattern": [
                {
                    "value": "*.wkt",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000548.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000548.xml"
            }
        },
        {
            "wellknowntext": "",
            "name": "Well-known Text",
            "id": "fdd000548",
            "filenamepattern": [
                {
                    "value": "*.wkt",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000548.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000548.xml"
            }
        },
        {
            "wellknowntext": "",
            "name": "Well-known Text",
            "id": "fdd000548",
            "filenamepattern": [
                {
                    "value": "*.wkt",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000548.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000548.xml"
            }
        },
        {
            "wellknowntext": "",
            "name": "Well-known Text",
            "id": "fdd000548",
            "filenamepattern": [
                {
                    "value": "*.wkt",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000548.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000548.xml"
            }
        },
        {
            "wellknowntext": "",
            "name": "Well-known Text",
            "id": "fdd000548",
            "filenamepattern": [
                {
                    "value": "*.wkt",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000548.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000548.xml"
            }
        },
        {
            "wellknowntext": "",
            "name": "Well-known Text",
            "id": "fdd000548",
            "filenamepattern": [
                {
                    "value": "*.wkt",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000548.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000548.xml"
            }
        },
        {
            "wellknowntext": "",
            "name": "Well-known Text",
            "id": "fdd000548",
            "filenamepattern": [
                {
                    "value": "*.wkt",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000548.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000548.xml"
            }
        },
        {
            "wellknownbinary": "",
            "name": "Well-known Binary",
            "id": "fdd000549",
            "filenamepattern": [
                {
                    "value": "*.wkb",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000549.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000549.xml"
            }
        },
        {
            "wellknownbinary": "",
            "name": "Well-known Binary",
            "id": "fdd000549",
            "filenamepattern": [
                {
                    "value": "*.wkb",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000549.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000549.xml"
            }
        },
        {
            "wellknownbinary": "",
            "name": "Well-known Binary",
            "id": "fdd000549",
            "filenamepattern": [
                {
                    "value": "*.wkb",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000549.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000549.xml"
            }
        },
        {
            "wellknownbinary": "",
            "name": "Well-known Binary",
            "id": "fdd000549",
            "filenamepattern": [
                {
                    "value": "*.wkb",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000549.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000549.xml"
            }
        },
        {
            "wellknownbinary": "",
            "name": "Well-known Binary",
            "id": "fdd000549",
            "filenamepattern": [
                {
                    "value": "*.wkb",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000549.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000549.xml"
            }
        },
        {
            "wellknownbinary": "",
            "name": "Well-known Binary",
            "id": "fdd000549",
            "filenamepattern": [
                {
                    "value": "*.wkb",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000549.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000549.xml"
            }
        },
        {
            "wellknownbinary": "",
            "name": "Well-known Binary",
            "id": "fdd000549",
            "filenamepattern": [
                {
                    "value": "*.wkb",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000549.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000549.xml"
            }
        },
        {
            "wellknownbinary": "",
            "name": "Well-known Binary",
            "id": "fdd000549",
            "filenamepattern": [
                {
                    "value": "*.wkb",
                    "url": "https://www.digipres.org/formats/extensions/#*.wkb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000549.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000549.xml"
            }
        },
        {
            "3dtilesarchivefileformat,version1.0": "",
            "name": "3D Tiles Archive File Format, Version 1.0",
            "id": "fdd000550",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.i3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.i3dm"
                },
                {
                    "value": "*.pnts",
                    "url": "https://www.digipres.org/formats/extensions/#*.pnts"
                },
                {
                    "value": "*.cmpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.cmpt"
                },
                {
                    "value": "*.b3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.b3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "application/vnd.maxar.archive.3tz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.maxar.archive.3tz+zip"
                },
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000550.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000550.xml"
            }
        },
        {
            "3dtilesarchivefileformat,version1.0": "",
            "name": "3D Tiles Archive File Format, Version 1.0",
            "id": "fdd000550",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.i3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.i3dm"
                },
                {
                    "value": "*.pnts",
                    "url": "https://www.digipres.org/formats/extensions/#*.pnts"
                },
                {
                    "value": "*.cmpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.cmpt"
                },
                {
                    "value": "*.b3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.b3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "application/vnd.maxar.archive.3tz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.maxar.archive.3tz+zip"
                },
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000550.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000550.xml"
            }
        },
        {
            "3dtilesarchivefileformat,version1.0": "",
            "name": "3D Tiles Archive File Format, Version 1.0",
            "id": "fdd000550",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.i3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.i3dm"
                },
                {
                    "value": "*.pnts",
                    "url": "https://www.digipres.org/formats/extensions/#*.pnts"
                },
                {
                    "value": "*.cmpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.cmpt"
                },
                {
                    "value": "*.b3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.b3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "application/vnd.maxar.archive.3tz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.maxar.archive.3tz+zip"
                },
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000550.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000550.xml"
            }
        },
        {
            "3dtilesarchivefileformat,version1.0": "",
            "name": "3D Tiles Archive File Format, Version 1.0",
            "id": "fdd000550",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.i3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.i3dm"
                },
                {
                    "value": "*.pnts",
                    "url": "https://www.digipres.org/formats/extensions/#*.pnts"
                },
                {
                    "value": "*.cmpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.cmpt"
                },
                {
                    "value": "*.b3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.b3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "application/vnd.maxar.archive.3tz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.maxar.archive.3tz+zip"
                },
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000550.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000550.xml"
            }
        },
        {
            "3dtilesarchivefileformat,version1.0": "",
            "name": "3D Tiles Archive File Format, Version 1.0",
            "id": "fdd000550",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.i3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.i3dm"
                },
                {
                    "value": "*.pnts",
                    "url": "https://www.digipres.org/formats/extensions/#*.pnts"
                },
                {
                    "value": "*.cmpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.cmpt"
                },
                {
                    "value": "*.b3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.b3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "application/vnd.maxar.archive.3tz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.maxar.archive.3tz+zip"
                },
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000550.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000550.xml"
            }
        },
        {
            "3dtilesarchivefileformat,version1.0": "",
            "name": "3D Tiles Archive File Format, Version 1.0",
            "id": "fdd000550",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.i3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.i3dm"
                },
                {
                    "value": "*.pnts",
                    "url": "https://www.digipres.org/formats/extensions/#*.pnts"
                },
                {
                    "value": "*.cmpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.cmpt"
                },
                {
                    "value": "*.b3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.b3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "application/vnd.maxar.archive.3tz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.maxar.archive.3tz+zip"
                },
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000550.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000550.xml"
            }
        },
        {
            "3dtilesarchivefileformat,version1.0": "",
            "name": "3D Tiles Archive File Format, Version 1.0",
            "id": "fdd000550",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.i3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.i3dm"
                },
                {
                    "value": "*.pnts",
                    "url": "https://www.digipres.org/formats/extensions/#*.pnts"
                },
                {
                    "value": "*.cmpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.cmpt"
                },
                {
                    "value": "*.b3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.b3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "application/vnd.maxar.archive.3tz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.maxar.archive.3tz+zip"
                },
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000550.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000550.xml"
            }
        },
        {
            "3dtilesarchivefileformat,version1.0": "",
            "name": "3D Tiles Archive File Format, Version 1.0",
            "id": "fdd000550",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.i3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.i3dm"
                },
                {
                    "value": "*.pnts",
                    "url": "https://www.digipres.org/formats/extensions/#*.pnts"
                },
                {
                    "value": "*.cmpt",
                    "url": "https://www.digipres.org/formats/extensions/#*.cmpt"
                },
                {
                    "value": "*.b3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.b3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/json",
                    "url": "https://www.digipres.org/formats/mime-types/#application/json"
                },
                {
                    "value": "application/vnd.maxar.archive.3tz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.maxar.archive.3tz+zip"
                },
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000550.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000550.xml"
            }
        },
        {
            "braillereadyformat": "",
            "name": "Braille Ready Format",
            "id": "fdd000551",
            "filenamepattern": [
                {
                    "value": "*.bfm",
                    "url": "https://www.digipres.org/formats/extensions/#*.bfm"
                },
                {
                    "value": "*.brf",
                    "url": "https://www.digipres.org/formats/extensions/#*.brf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000551.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000551.xml"
            }
        },
        {
            "braillereadyformat": "",
            "name": "Braille Ready Format",
            "id": "fdd000551",
            "filenamepattern": [
                {
                    "value": "*.bfm",
                    "url": "https://www.digipres.org/formats/extensions/#*.bfm"
                },
                {
                    "value": "*.brf",
                    "url": "https://www.digipres.org/formats/extensions/#*.brf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000551.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000551.xml"
            }
        },
        {
            "braillereadyformat": "",
            "name": "Braille Ready Format",
            "id": "fdd000551",
            "filenamepattern": [
                {
                    "value": "*.bfm",
                    "url": "https://www.digipres.org/formats/extensions/#*.bfm"
                },
                {
                    "value": "*.brf",
                    "url": "https://www.digipres.org/formats/extensions/#*.brf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000551.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000551.xml"
            }
        },
        {
            "braillereadyformat": "",
            "name": "Braille Ready Format",
            "id": "fdd000551",
            "filenamepattern": [
                {
                    "value": "*.bfm",
                    "url": "https://www.digipres.org/formats/extensions/#*.bfm"
                },
                {
                    "value": "*.brf",
                    "url": "https://www.digipres.org/formats/extensions/#*.brf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000551.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000551.xml"
            }
        },
        {
            "braillereadyformat": "",
            "name": "Braille Ready Format",
            "id": "fdd000551",
            "filenamepattern": [
                {
                    "value": "*.bfm",
                    "url": "https://www.digipres.org/formats/extensions/#*.bfm"
                },
                {
                    "value": "*.brf",
                    "url": "https://www.digipres.org/formats/extensions/#*.brf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000551.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000551.xml"
            }
        },
        {
            "braillereadyformat": "",
            "name": "Braille Ready Format",
            "id": "fdd000551",
            "filenamepattern": [
                {
                    "value": "*.bfm",
                    "url": "https://www.digipres.org/formats/extensions/#*.bfm"
                },
                {
                    "value": "*.brf",
                    "url": "https://www.digipres.org/formats/extensions/#*.brf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000551.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000551.xml"
            }
        },
        {
            "braillereadyformat": "",
            "name": "Braille Ready Format",
            "id": "fdd000551",
            "filenamepattern": [
                {
                    "value": "*.bfm",
                    "url": "https://www.digipres.org/formats/extensions/#*.bfm"
                },
                {
                    "value": "*.brf",
                    "url": "https://www.digipres.org/formats/extensions/#*.brf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000551.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000551.xml"
            }
        },
        {
            "braillereadyformat": "",
            "name": "Braille Ready Format",
            "id": "fdd000551",
            "filenamepattern": [
                {
                    "value": "*.bfm",
                    "url": "https://www.digipres.org/formats/extensions/#*.bfm"
                },
                {
                    "value": "*.brf",
                    "url": "https://www.digipres.org/formats/extensions/#*.brf"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000551.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000551.xml"
            }
        },
        {
            "wordstarfileformatfamily": "",
            "name": "WordStar File Format Family",
            "id": "fdd000552",
            "filenamepattern": [
                {
                    "value": "*.ws4",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws4"
                },
                {
                    "value": "*.ws",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws"
                },
                {
                    "value": "*.ws6",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws6"
                },
                {
                    "value": "*.ws3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws3"
                },
                {
                    "value": "*.ws2",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws2"
                },
                {
                    "value": "*.wsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wsd"
                },
                {
                    "value": "*.ws7",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws7"
                },
                {
                    "value": "*.ws5",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000552.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000552.xml"
            }
        },
        {
            "wordstarfileformatfamily": "",
            "name": "WordStar File Format Family",
            "id": "fdd000552",
            "filenamepattern": [
                {
                    "value": "*.ws4",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws4"
                },
                {
                    "value": "*.ws",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws"
                },
                {
                    "value": "*.ws6",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws6"
                },
                {
                    "value": "*.ws3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws3"
                },
                {
                    "value": "*.ws2",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws2"
                },
                {
                    "value": "*.wsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wsd"
                },
                {
                    "value": "*.ws7",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws7"
                },
                {
                    "value": "*.ws5",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000552.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000552.xml"
            }
        },
        {
            "wordstarfileformatfamily": "",
            "name": "WordStar File Format Family",
            "id": "fdd000552",
            "filenamepattern": [
                {
                    "value": "*.ws4",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws4"
                },
                {
                    "value": "*.ws",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws"
                },
                {
                    "value": "*.ws6",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws6"
                },
                {
                    "value": "*.ws3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws3"
                },
                {
                    "value": "*.ws2",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws2"
                },
                {
                    "value": "*.wsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wsd"
                },
                {
                    "value": "*.ws7",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws7"
                },
                {
                    "value": "*.ws5",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000552.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000552.xml"
            }
        },
        {
            "wordstarfileformatfamily": "",
            "name": "WordStar File Format Family",
            "id": "fdd000552",
            "filenamepattern": [
                {
                    "value": "*.ws4",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws4"
                },
                {
                    "value": "*.ws",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws"
                },
                {
                    "value": "*.ws6",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws6"
                },
                {
                    "value": "*.ws3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws3"
                },
                {
                    "value": "*.ws2",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws2"
                },
                {
                    "value": "*.wsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wsd"
                },
                {
                    "value": "*.ws7",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws7"
                },
                {
                    "value": "*.ws5",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000552.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000552.xml"
            }
        },
        {
            "wordstarfileformatfamily": "",
            "name": "WordStar File Format Family",
            "id": "fdd000552",
            "filenamepattern": [
                {
                    "value": "*.ws4",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws4"
                },
                {
                    "value": "*.ws",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws"
                },
                {
                    "value": "*.ws6",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws6"
                },
                {
                    "value": "*.ws3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws3"
                },
                {
                    "value": "*.ws2",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws2"
                },
                {
                    "value": "*.wsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wsd"
                },
                {
                    "value": "*.ws7",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws7"
                },
                {
                    "value": "*.ws5",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000552.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000552.xml"
            }
        },
        {
            "wordstarfileformatfamily": "",
            "name": "WordStar File Format Family",
            "id": "fdd000552",
            "filenamepattern": [
                {
                    "value": "*.ws4",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws4"
                },
                {
                    "value": "*.ws",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws"
                },
                {
                    "value": "*.ws6",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws6"
                },
                {
                    "value": "*.ws3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws3"
                },
                {
                    "value": "*.ws2",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws2"
                },
                {
                    "value": "*.wsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wsd"
                },
                {
                    "value": "*.ws7",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws7"
                },
                {
                    "value": "*.ws5",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000552.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000552.xml"
            }
        },
        {
            "wordstarfileformatfamily": "",
            "name": "WordStar File Format Family",
            "id": "fdd000552",
            "filenamepattern": [
                {
                    "value": "*.ws4",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws4"
                },
                {
                    "value": "*.ws",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws"
                },
                {
                    "value": "*.ws6",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws6"
                },
                {
                    "value": "*.ws3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws3"
                },
                {
                    "value": "*.ws2",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws2"
                },
                {
                    "value": "*.wsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wsd"
                },
                {
                    "value": "*.ws7",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws7"
                },
                {
                    "value": "*.ws5",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000552.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000552.xml"
            }
        },
        {
            "wordstarfileformatfamily": "",
            "name": "WordStar File Format Family",
            "id": "fdd000552",
            "filenamepattern": [
                {
                    "value": "*.ws4",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws4"
                },
                {
                    "value": "*.ws",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws"
                },
                {
                    "value": "*.ws6",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws6"
                },
                {
                    "value": "*.ws3",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws3"
                },
                {
                    "value": "*.ws2",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws2"
                },
                {
                    "value": "*.wsd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wsd"
                },
                {
                    "value": "*.ws7",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws7"
                },
                {
                    "value": "*.ws5",
                    "url": "https://www.digipres.org/formats/extensions/#*.ws5"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000552.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000552.xml"
            }
        },
        {
            "braillesensefileformat": "",
            "name": "BrailleSense File Format",
            "id": "fdd000553",
            "filenamepattern": [
                {
                    "value": "*.hbl",
                    "url": "https://www.digipres.org/formats/extensions/#*.hbl"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000553.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000553.xml"
            }
        },
        {
            "braillesensefileformat": "",
            "name": "BrailleSense File Format",
            "id": "fdd000553",
            "filenamepattern": [
                {
                    "value": "*.hbl",
                    "url": "https://www.digipres.org/formats/extensions/#*.hbl"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000553.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000553.xml"
            }
        },
        {
            "braillesensefileformat": "",
            "name": "BrailleSense File Format",
            "id": "fdd000553",
            "filenamepattern": [
                {
                    "value": "*.hbl",
                    "url": "https://www.digipres.org/formats/extensions/#*.hbl"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000553.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000553.xml"
            }
        },
        {
            "braillesensefileformat": "",
            "name": "BrailleSense File Format",
            "id": "fdd000553",
            "filenamepattern": [
                {
                    "value": "*.hbl",
                    "url": "https://www.digipres.org/formats/extensions/#*.hbl"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000553.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000553.xml"
            }
        },
        {
            "braillesensefileformat": "",
            "name": "BrailleSense File Format",
            "id": "fdd000553",
            "filenamepattern": [
                {
                    "value": "*.hbl",
                    "url": "https://www.digipres.org/formats/extensions/#*.hbl"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000553.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000553.xml"
            }
        },
        {
            "braillesensefileformat": "",
            "name": "BrailleSense File Format",
            "id": "fdd000553",
            "filenamepattern": [
                {
                    "value": "*.hbl",
                    "url": "https://www.digipres.org/formats/extensions/#*.hbl"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000553.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000553.xml"
            }
        },
        {
            "braillesensefileformat": "",
            "name": "BrailleSense File Format",
            "id": "fdd000553",
            "filenamepattern": [
                {
                    "value": "*.hbl",
                    "url": "https://www.digipres.org/formats/extensions/#*.hbl"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000553.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000553.xml"
            }
        },
        {
            "braillesensefileformat": "",
            "name": "BrailleSense File Format",
            "id": "fdd000553",
            "filenamepattern": [
                {
                    "value": "*.hbl",
                    "url": "https://www.digipres.org/formats/extensions/#*.hbl"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000553.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000553.xml"
            }
        },
        {
            "outlineprocessormarkuplanguage(opml)2.0": "",
            "name": "Outline Processor Markup Language (OPML) 2.0",
            "id": "fdd000554",
            "filenamepattern": [
                {
                    "value": "*.opml",
                    "url": "https://www.digipres.org/formats/extensions/#*.opml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-opml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-opml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000554.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000554.xml"
            }
        },
        {
            "outlineprocessormarkuplanguage(opml)2.0": "",
            "name": "Outline Processor Markup Language (OPML) 2.0",
            "id": "fdd000554",
            "filenamepattern": [
                {
                    "value": "*.opml",
                    "url": "https://www.digipres.org/formats/extensions/#*.opml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-opml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-opml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000554.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000554.xml"
            }
        },
        {
            "outlineprocessormarkuplanguage(opml)2.0": "",
            "name": "Outline Processor Markup Language (OPML) 2.0",
            "id": "fdd000554",
            "filenamepattern": [
                {
                    "value": "*.opml",
                    "url": "https://www.digipres.org/formats/extensions/#*.opml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-opml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-opml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000554.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000554.xml"
            }
        },
        {
            "outlineprocessormarkuplanguage(opml)2.0": "",
            "name": "Outline Processor Markup Language (OPML) 2.0",
            "id": "fdd000554",
            "filenamepattern": [
                {
                    "value": "*.opml",
                    "url": "https://www.digipres.org/formats/extensions/#*.opml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-opml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-opml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000554.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000554.xml"
            }
        },
        {
            "outlineprocessormarkuplanguage(opml)2.0": "",
            "name": "Outline Processor Markup Language (OPML) 2.0",
            "id": "fdd000554",
            "filenamepattern": [
                {
                    "value": "*.opml",
                    "url": "https://www.digipres.org/formats/extensions/#*.opml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-opml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-opml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000554.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000554.xml"
            }
        },
        {
            "outlineprocessormarkuplanguage(opml)2.0": "",
            "name": "Outline Processor Markup Language (OPML) 2.0",
            "id": "fdd000554",
            "filenamepattern": [
                {
                    "value": "*.opml",
                    "url": "https://www.digipres.org/formats/extensions/#*.opml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-opml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-opml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000554.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000554.xml"
            }
        },
        {
            "outlineprocessormarkuplanguage(opml)2.0": "",
            "name": "Outline Processor Markup Language (OPML) 2.0",
            "id": "fdd000554",
            "filenamepattern": [
                {
                    "value": "*.opml",
                    "url": "https://www.digipres.org/formats/extensions/#*.opml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-opml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-opml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000554.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000554.xml"
            }
        },
        {
            "outlineprocessormarkuplanguage(opml)2.0": "",
            "name": "Outline Processor Markup Language (OPML) 2.0",
            "id": "fdd000554",
            "filenamepattern": [
                {
                    "value": "*.opml",
                    "url": "https://www.digipres.org/formats/extensions/#*.opml"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-opml",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-opml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000554.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000554.xml"
            }
        },
        
        {
            "augmentedrealitymarkuplanguage(arml)2.0": "",
            "name": "Augmented Reality Markup Language (ARML) 2.0",
            "id": "fdd000556",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000556.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000556.xml"
            }
        },
        {
            "augmentedrealitymarkuplanguage(arml)2.0": "",
            "name": "Augmented Reality Markup Language (ARML) 2.0",
            "id": "fdd000556",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000556.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000556.xml"
            }
        },
        {
            "augmentedrealitymarkuplanguage(arml)2.0": "",
            "name": "Augmented Reality Markup Language (ARML) 2.0",
            "id": "fdd000556",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000556.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000556.xml"
            }
        },
        {
            "augmentedrealitymarkuplanguage(arml)2.0": "",
            "name": "Augmented Reality Markup Language (ARML) 2.0",
            "id": "fdd000556",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000556.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000556.xml"
            }
        },
        {
            "augmentedrealitymarkuplanguage(arml)2.0": "",
            "name": "Augmented Reality Markup Language (ARML) 2.0",
            "id": "fdd000556",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000556.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000556.xml"
            }
        },
        {
            "augmentedrealitymarkuplanguage(arml)2.0": "",
            "name": "Augmented Reality Markup Language (ARML) 2.0",
            "id": "fdd000556",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000556.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000556.xml"
            }
        },
        {
            "augmentedrealitymarkuplanguage(arml)2.0": "",
            "name": "Augmented Reality Markup Language (ARML) 2.0",
            "id": "fdd000556",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000556.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000556.xml"
            }
        },
        {
            "3dmanufacturingformat(3mf)": "",
            "name": "3D Manufacturing Format (3MF)",
            "id": "fdd000557",
            "filenamepattern": [
                {
                    "value": "*.3mf",
                    "url": "https://www.digipres.org/formats/extensions/#*.3mf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-printing.printticket+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-printing.printticket+xml"
                },
                {
                    "value": "application/vnd.ms-package.3dmanufacturing-3dmodel+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-package.3dmanufacturing-3dmodel+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000557.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000557.xml"
            }
        },
        {
            "3dmanufacturingformat(3mf)": "",
            "name": "3D Manufacturing Format (3MF)",
            "id": "fdd000557",
            "filenamepattern": [
                {
                    "value": "*.3mf",
                    "url": "https://www.digipres.org/formats/extensions/#*.3mf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-printing.printticket+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-printing.printticket+xml"
                },
                {
                    "value": "application/vnd.ms-package.3dmanufacturing-3dmodel+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-package.3dmanufacturing-3dmodel+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000557.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000557.xml"
            }
        },
        {
            "3dmanufacturingformat(3mf)": "",
            "name": "3D Manufacturing Format (3MF)",
            "id": "fdd000557",
            "filenamepattern": [
                {
                    "value": "*.3mf",
                    "url": "https://www.digipres.org/formats/extensions/#*.3mf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-printing.printticket+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-printing.printticket+xml"
                },
                {
                    "value": "application/vnd.ms-package.3dmanufacturing-3dmodel+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-package.3dmanufacturing-3dmodel+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000557.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000557.xml"
            }
        },
        {
            "3dmanufacturingformat(3mf)": "",
            "name": "3D Manufacturing Format (3MF)",
            "id": "fdd000557",
            "filenamepattern": [
                {
                    "value": "*.3mf",
                    "url": "https://www.digipres.org/formats/extensions/#*.3mf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-printing.printticket+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-printing.printticket+xml"
                },
                {
                    "value": "application/vnd.ms-package.3dmanufacturing-3dmodel+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-package.3dmanufacturing-3dmodel+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000557.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000557.xml"
            }
        },
        {
            "3dmanufacturingformat(3mf)": "",
            "name": "3D Manufacturing Format (3MF)",
            "id": "fdd000557",
            "filenamepattern": [
                {
                    "value": "*.3mf",
                    "url": "https://www.digipres.org/formats/extensions/#*.3mf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-printing.printticket+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-printing.printticket+xml"
                },
                {
                    "value": "application/vnd.ms-package.3dmanufacturing-3dmodel+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-package.3dmanufacturing-3dmodel+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000557.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000557.xml"
            }
        },
        {
            "3dmanufacturingformat(3mf)": "",
            "name": "3D Manufacturing Format (3MF)",
            "id": "fdd000557",
            "filenamepattern": [
                {
                    "value": "*.3mf",
                    "url": "https://www.digipres.org/formats/extensions/#*.3mf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-printing.printticket+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-printing.printticket+xml"
                },
                {
                    "value": "application/vnd.ms-package.3dmanufacturing-3dmodel+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-package.3dmanufacturing-3dmodel+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000557.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000557.xml"
            }
        },
        {
            "3dmanufacturingformat(3mf)": "",
            "name": "3D Manufacturing Format (3MF)",
            "id": "fdd000557",
            "filenamepattern": [
                {
                    "value": "*.3mf",
                    "url": "https://www.digipres.org/formats/extensions/#*.3mf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-printing.printticket+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-printing.printticket+xml"
                },
                {
                    "value": "application/vnd.ms-package.3dmanufacturing-3dmodel+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-package.3dmanufacturing-3dmodel+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000557.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000557.xml"
            }
        },
        {
            "3dmanufacturingformat(3mf)": "",
            "name": "3D Manufacturing Format (3MF)",
            "id": "fdd000557",
            "filenamepattern": [
                {
                    "value": "*.3mf",
                    "url": "https://www.digipres.org/formats/extensions/#*.3mf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.ms-printing.printticket+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-printing.printticket+xml"
                },
                {
                    "value": "application/vnd.ms-package.3dmanufacturing-3dmodel+xml ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.ms-package.3dmanufacturing-3dmodel+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000557.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000557.xml"
            }
        },
        {
            "autodeskfilmboxinterchangefile(fbx)": "",
            "name": "Autodesk Filmbox Interchange File (FBX)",
            "id": "fdd000558",
            "filenamepattern": [
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000558.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000558.xml"
            }
        },
        {
            "autodeskfilmboxinterchangefile(fbx)": "",
            "name": "Autodesk Filmbox Interchange File (FBX)",
            "id": "fdd000558",
            "filenamepattern": [
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000558.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000558.xml"
            }
        },
        {
            "autodeskfilmboxinterchangefile(fbx)": "",
            "name": "Autodesk Filmbox Interchange File (FBX)",
            "id": "fdd000558",
            "filenamepattern": [
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000558.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000558.xml"
            }
        },
        {
            "autodeskfilmboxinterchangefile(fbx)": "",
            "name": "Autodesk Filmbox Interchange File (FBX)",
            "id": "fdd000558",
            "filenamepattern": [
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000558.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000558.xml"
            }
        },
        {
            "autodeskfilmboxinterchangefile(fbx)": "",
            "name": "Autodesk Filmbox Interchange File (FBX)",
            "id": "fdd000558",
            "filenamepattern": [
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000558.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000558.xml"
            }
        },
        {
            "autodeskfilmboxinterchangefile(fbx)": "",
            "name": "Autodesk Filmbox Interchange File (FBX)",
            "id": "fdd000558",
            "filenamepattern": [
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000558.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000558.xml"
            }
        },
        {
            "autodeskfilmboxinterchangefile(fbx)": "",
            "name": "Autodesk Filmbox Interchange File (FBX)",
            "id": "fdd000558",
            "filenamepattern": [
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000558.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000558.xml"
            }
        },
        {
            "autodeskfilmboxinterchangefile(fbx)": "",
            "name": "Autodesk Filmbox Interchange File (FBX)",
            "id": "fdd000558",
            "filenamepattern": [
                {
                    "value": "*.fbx",
                    "url": "https://www.digipres.org/formats/extensions/#*.fbx"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000558.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000558.xml"
            }
        },
        {
            "blender3ddatafile(blend)": "",
            "name": "Blender 3D Data File (BLEND)",
            "id": "fdd000559",
            "filenamepattern": [
                {
                    "value": "*.blend",
                    "url": "https://www.digipres.org/formats/extensions/#*.blend"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-blender ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-blender"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000559.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000559.xml"
            }
        },
        {
            "blender3ddatafile(blend)": "",
            "name": "Blender 3D Data File (BLEND)",
            "id": "fdd000559",
            "filenamepattern": [
                {
                    "value": "*.blend",
                    "url": "https://www.digipres.org/formats/extensions/#*.blend"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-blender ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-blender"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000559.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000559.xml"
            }
        },
        {
            "blender3ddatafile(blend)": "",
            "name": "Blender 3D Data File (BLEND)",
            "id": "fdd000559",
            "filenamepattern": [
                {
                    "value": "*.blend",
                    "url": "https://www.digipres.org/formats/extensions/#*.blend"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-blender ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-blender"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000559.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000559.xml"
            }
        },
        {
            "blender3ddatafile(blend)": "",
            "name": "Blender 3D Data File (BLEND)",
            "id": "fdd000559",
            "filenamepattern": [
                {
                    "value": "*.blend",
                    "url": "https://www.digipres.org/formats/extensions/#*.blend"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-blender ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-blender"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000559.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000559.xml"
            }
        },
        {
            "blender3ddatafile(blend)": "",
            "name": "Blender 3D Data File (BLEND)",
            "id": "fdd000559",
            "filenamepattern": [
                {
                    "value": "*.blend",
                    "url": "https://www.digipres.org/formats/extensions/#*.blend"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-blender ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-blender"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000559.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000559.xml"
            }
        },
        {
            "blender3ddatafile(blend)": "",
            "name": "Blender 3D Data File (BLEND)",
            "id": "fdd000559",
            "filenamepattern": [
                {
                    "value": "*.blend",
                    "url": "https://www.digipres.org/formats/extensions/#*.blend"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-blender ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-blender"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000559.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000559.xml"
            }
        },
        {
            "blender3ddatafile(blend)": "",
            "name": "Blender 3D Data File (BLEND)",
            "id": "fdd000559",
            "filenamepattern": [
                {
                    "value": "*.blend",
                    "url": "https://www.digipres.org/formats/extensions/#*.blend"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-blender ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-blender"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000559.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000559.xml"
            }
        },
        {
            "blender3ddatafile(blend)": "",
            "name": "Blender 3D Data File (BLEND)",
            "id": "fdd000559",
            "filenamepattern": [
                {
                    "value": "*.blend",
                    "url": "https://www.digipres.org/formats/extensions/#*.blend"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-blender ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-blender"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000559.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000559.xml"
            }
        },
        {
            "autodeskalembic": "",
            "name": "Autodesk Alembic",
            "id": "fdd000560",
            "filenamepattern": [
                {
                    "value": "*.abc",
                    "url": "https://www.digipres.org/formats/extensions/#*.abc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000560.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000560.xml"
            }
        },
        {
            "autodeskalembic": "",
            "name": "Autodesk Alembic",
            "id": "fdd000560",
            "filenamepattern": [
                {
                    "value": "*.abc",
                    "url": "https://www.digipres.org/formats/extensions/#*.abc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000560.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000560.xml"
            }
        },
        {
            "autodeskalembic": "",
            "name": "Autodesk Alembic",
            "id": "fdd000560",
            "filenamepattern": [
                {
                    "value": "*.abc",
                    "url": "https://www.digipres.org/formats/extensions/#*.abc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000560.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000560.xml"
            }
        },
        {
            "autodeskalembic": "",
            "name": "Autodesk Alembic",
            "id": "fdd000560",
            "filenamepattern": [
                {
                    "value": "*.abc",
                    "url": "https://www.digipres.org/formats/extensions/#*.abc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000560.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000560.xml"
            }
        },
        {
            "autodeskalembic": "",
            "name": "Autodesk Alembic",
            "id": "fdd000560",
            "filenamepattern": [
                {
                    "value": "*.abc",
                    "url": "https://www.digipres.org/formats/extensions/#*.abc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000560.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000560.xml"
            }
        },
        {
            "autodeskalembic": "",
            "name": "Autodesk Alembic",
            "id": "fdd000560",
            "filenamepattern": [
                {
                    "value": "*.abc",
                    "url": "https://www.digipres.org/formats/extensions/#*.abc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000560.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000560.xml"
            }
        },
        {
            "autodeskalembic": "",
            "name": "Autodesk Alembic",
            "id": "fdd000560",
            "filenamepattern": [
                {
                    "value": "*.abc",
                    "url": "https://www.digipres.org/formats/extensions/#*.abc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000560.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000560.xml"
            }
        },
        {
            "autodeskalembic": "",
            "name": "Autodesk Alembic",
            "id": "fdd000560",
            "filenamepattern": [
                {
                    "value": "*.abc",
                    "url": "https://www.digipres.org/formats/extensions/#*.abc"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000560.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000560.xml"
            }
        },
        {
            "universalscenedescription(usd)": "",
            "name": "Universal Scene Description (USD)",
            "id": "fdd000561",
            "filenamepattern": [
                {
                    "value": "*.usda",
                    "url": "https://www.digipres.org/formats/extensions/#*.usda"
                },
                {
                    "value": "*.usdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdc"
                },
                {
                    "value": "*.usd",
                    "url": "https://www.digipres.org/formats/extensions/#*.usd"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/ynd.usda",
                    "url": "https://www.digipres.org/formats/mime-types/#model/ynd.usda"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000561.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000561.xml"
            }
        },
        {
            "universalscenedescription(usd)": "",
            "name": "Universal Scene Description (USD)",
            "id": "fdd000561",
            "filenamepattern": [
                {
                    "value": "*.usda",
                    "url": "https://www.digipres.org/formats/extensions/#*.usda"
                },
                {
                    "value": "*.usdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdc"
                },
                {
                    "value": "*.usd",
                    "url": "https://www.digipres.org/formats/extensions/#*.usd"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/ynd.usda",
                    "url": "https://www.digipres.org/formats/mime-types/#model/ynd.usda"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000561.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000561.xml"
            }
        },
        {
            "universalscenedescription(usd)": "",
            "name": "Universal Scene Description (USD)",
            "id": "fdd000561",
            "filenamepattern": [
                {
                    "value": "*.usda",
                    "url": "https://www.digipres.org/formats/extensions/#*.usda"
                },
                {
                    "value": "*.usdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdc"
                },
                {
                    "value": "*.usd",
                    "url": "https://www.digipres.org/formats/extensions/#*.usd"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/ynd.usda",
                    "url": "https://www.digipres.org/formats/mime-types/#model/ynd.usda"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000561.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000561.xml"
            }
        },
        {
            "universalscenedescription(usd)": "",
            "name": "Universal Scene Description (USD)",
            "id": "fdd000561",
            "filenamepattern": [
                {
                    "value": "*.usda",
                    "url": "https://www.digipres.org/formats/extensions/#*.usda"
                },
                {
                    "value": "*.usdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdc"
                },
                {
                    "value": "*.usd",
                    "url": "https://www.digipres.org/formats/extensions/#*.usd"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/ynd.usda",
                    "url": "https://www.digipres.org/formats/mime-types/#model/ynd.usda"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000561.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000561.xml"
            }
        },
        {
            "universalscenedescription(usd)": "",
            "name": "Universal Scene Description (USD)",
            "id": "fdd000561",
            "filenamepattern": [
                {
                    "value": "*.usda",
                    "url": "https://www.digipres.org/formats/extensions/#*.usda"
                },
                {
                    "value": "*.usdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdc"
                },
                {
                    "value": "*.usd",
                    "url": "https://www.digipres.org/formats/extensions/#*.usd"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/ynd.usda",
                    "url": "https://www.digipres.org/formats/mime-types/#model/ynd.usda"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000561.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000561.xml"
            }
        },
        {
            "universalscenedescription(usd)": "",
            "name": "Universal Scene Description (USD)",
            "id": "fdd000561",
            "filenamepattern": [
                {
                    "value": "*.usda",
                    "url": "https://www.digipres.org/formats/extensions/#*.usda"
                },
                {
                    "value": "*.usdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdc"
                },
                {
                    "value": "*.usd",
                    "url": "https://www.digipres.org/formats/extensions/#*.usd"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/ynd.usda",
                    "url": "https://www.digipres.org/formats/mime-types/#model/ynd.usda"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000561.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000561.xml"
            }
        },
        {
            "universalscenedescription(usd)": "",
            "name": "Universal Scene Description (USD)",
            "id": "fdd000561",
            "filenamepattern": [
                {
                    "value": "*.usda",
                    "url": "https://www.digipres.org/formats/extensions/#*.usda"
                },
                {
                    "value": "*.usdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdc"
                },
                {
                    "value": "*.usd",
                    "url": "https://www.digipres.org/formats/extensions/#*.usd"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/ynd.usda",
                    "url": "https://www.digipres.org/formats/mime-types/#model/ynd.usda"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000561.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000561.xml"
            }
        },
        {
            "universalscenedescription(usd)": "",
            "name": "Universal Scene Description (USD)",
            "id": "fdd000561",
            "filenamepattern": [
                {
                    "value": "*.usda",
                    "url": "https://www.digipres.org/formats/extensions/#*.usda"
                },
                {
                    "value": "*.usdc",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdc"
                },
                {
                    "value": "*.usd",
                    "url": "https://www.digipres.org/formats/extensions/#*.usd"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/ynd.usda",
                    "url": "https://www.digipres.org/formats/mime-types/#model/ynd.usda"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000561.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000561.xml"
            }
        },
        {
            "jupitertessellation(jt)": "",
            "name": "Jupiter Tessellation (JT)",
            "id": "fdd000562",
            "filenamepattern": [
                {
                    "value": "*.jt",
                    "url": "https://www.digipres.org/formats/extensions/#*.jt"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/jt",
                    "url": "https://www.digipres.org/formats/mime-types/#model/jt"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000562.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000562.xml"
            }
        },
        {
            "jupitertessellation(jt)": "",
            "name": "Jupiter Tessellation (JT)",
            "id": "fdd000562",
            "filenamepattern": [
                {
                    "value": "*.jt",
                    "url": "https://www.digipres.org/formats/extensions/#*.jt"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/jt",
                    "url": "https://www.digipres.org/formats/mime-types/#model/jt"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000562.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000562.xml"
            }
        },
        {
            "jupitertessellation(jt)": "",
            "name": "Jupiter Tessellation (JT)",
            "id": "fdd000562",
            "filenamepattern": [
                {
                    "value": "*.jt",
                    "url": "https://www.digipres.org/formats/extensions/#*.jt"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/jt",
                    "url": "https://www.digipres.org/formats/mime-types/#model/jt"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000562.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000562.xml"
            }
        },
        {
            "jupitertessellation(jt)": "",
            "name": "Jupiter Tessellation (JT)",
            "id": "fdd000562",
            "filenamepattern": [
                {
                    "value": "*.jt",
                    "url": "https://www.digipres.org/formats/extensions/#*.jt"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/jt",
                    "url": "https://www.digipres.org/formats/mime-types/#model/jt"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000562.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000562.xml"
            }
        },
        {
            "jupitertessellation(jt)": "",
            "name": "Jupiter Tessellation (JT)",
            "id": "fdd000562",
            "filenamepattern": [
                {
                    "value": "*.jt",
                    "url": "https://www.digipres.org/formats/extensions/#*.jt"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/jt",
                    "url": "https://www.digipres.org/formats/mime-types/#model/jt"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000562.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000562.xml"
            }
        },
        {
            "jupitertessellation(jt)": "",
            "name": "Jupiter Tessellation (JT)",
            "id": "fdd000562",
            "filenamepattern": [
                {
                    "value": "*.jt",
                    "url": "https://www.digipres.org/formats/extensions/#*.jt"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/jt",
                    "url": "https://www.digipres.org/formats/mime-types/#model/jt"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000562.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000562.xml"
            }
        },
        {
            "jupitertessellation(jt)": "",
            "name": "Jupiter Tessellation (JT)",
            "id": "fdd000562",
            "filenamepattern": [
                {
                    "value": "*.jt",
                    "url": "https://www.digipres.org/formats/extensions/#*.jt"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/jt",
                    "url": "https://www.digipres.org/formats/mime-types/#model/jt"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000562.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000562.xml"
            }
        },
        {
            "jupitertessellation(jt)": "",
            "name": "Jupiter Tessellation (JT)",
            "id": "fdd000562",
            "filenamepattern": [
                {
                    "value": "*.jt",
                    "url": "https://www.digipres.org/formats/extensions/#*.jt"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/jt",
                    "url": "https://www.digipres.org/formats/mime-types/#model/jt"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000562.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000562.xml"
            }
        },
        {
            "asteme573dfileformat(e57)": "",
            "name": "ASTEM E57 3D file format (E57)",
            "id": "fdd000563",
            "filenamepattern": [
                {
                    "value": "*.e57",
                    "url": "https://www.digipres.org/formats/extensions/#*.e57"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/e57",
                    "url": "https://www.digipres.org/formats/mime-types/#model/e57"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000563.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000563.xml"
            }
        },
        {
            "asteme573dfileformat(e57)": "",
            "name": "ASTEM E57 3D file format (E57)",
            "id": "fdd000563",
            "filenamepattern": [
                {
                    "value": "*.e57",
                    "url": "https://www.digipres.org/formats/extensions/#*.e57"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/e57",
                    "url": "https://www.digipres.org/formats/mime-types/#model/e57"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000563.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000563.xml"
            }
        },
        {
            "asteme573dfileformat(e57)": "",
            "name": "ASTEM E57 3D file format (E57)",
            "id": "fdd000563",
            "filenamepattern": [
                {
                    "value": "*.e57",
                    "url": "https://www.digipres.org/formats/extensions/#*.e57"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/e57",
                    "url": "https://www.digipres.org/formats/mime-types/#model/e57"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000563.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000563.xml"
            }
        },
        {
            "asteme573dfileformat(e57)": "",
            "name": "ASTEM E57 3D file format (E57)",
            "id": "fdd000563",
            "filenamepattern": [
                {
                    "value": "*.e57",
                    "url": "https://www.digipres.org/formats/extensions/#*.e57"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/e57",
                    "url": "https://www.digipres.org/formats/mime-types/#model/e57"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000563.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000563.xml"
            }
        },
        {
            "asteme573dfileformat(e57)": "",
            "name": "ASTEM E57 3D file format (E57)",
            "id": "fdd000563",
            "filenamepattern": [
                {
                    "value": "*.e57",
                    "url": "https://www.digipres.org/formats/extensions/#*.e57"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/e57",
                    "url": "https://www.digipres.org/formats/mime-types/#model/e57"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000563.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000563.xml"
            }
        },
        {
            "asteme573dfileformat(e57)": "",
            "name": "ASTEM E57 3D file format (E57)",
            "id": "fdd000563",
            "filenamepattern": [
                {
                    "value": "*.e57",
                    "url": "https://www.digipres.org/formats/extensions/#*.e57"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/e57",
                    "url": "https://www.digipres.org/formats/mime-types/#model/e57"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000563.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000563.xml"
            }
        },
        {
            "asteme573dfileformat(e57)": "",
            "name": "ASTEM E57 3D file format (E57)",
            "id": "fdd000563",
            "filenamepattern": [
                {
                    "value": "*.e57",
                    "url": "https://www.digipres.org/formats/extensions/#*.e57"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/e57",
                    "url": "https://www.digipres.org/formats/mime-types/#model/e57"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000563.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000563.xml"
            }
        },
        {
            "asteme573dfileformat(e57)": "",
            "name": "ASTEM E57 3D file format (E57)",
            "id": "fdd000563",
            "filenamepattern": [
                {
                    "value": "*.e57",
                    "url": "https://www.digipres.org/formats/extensions/#*.e57"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/e57",
                    "url": "https://www.digipres.org/formats/mime-types/#model/e57"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000563.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000563.xml"
            }
        },
        {
            "vrm": "",
            "name": "VRM",
            "id": "fdd000564",
            "filenamepattern": [
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                },
                {
                    "value": "*.vrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.vrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000564.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000564.xml"
            }
        },
        {
            "vrm": "",
            "name": "VRM",
            "id": "fdd000564",
            "filenamepattern": [
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                },
                {
                    "value": "*.vrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.vrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000564.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000564.xml"
            }
        },
        {
            "vrm": "",
            "name": "VRM",
            "id": "fdd000564",
            "filenamepattern": [
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                },
                {
                    "value": "*.vrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.vrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000564.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000564.xml"
            }
        },
        {
            "vrm": "",
            "name": "VRM",
            "id": "fdd000564",
            "filenamepattern": [
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                },
                {
                    "value": "*.vrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.vrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000564.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000564.xml"
            }
        },
        {
            "vrm": "",
            "name": "VRM",
            "id": "fdd000564",
            "filenamepattern": [
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                },
                {
                    "value": "*.vrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.vrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000564.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000564.xml"
            }
        },
        {
            "vrm": "",
            "name": "VRM",
            "id": "fdd000564",
            "filenamepattern": [
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                },
                {
                    "value": "*.vrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.vrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000564.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000564.xml"
            }
        },
        {
            "vrm": "",
            "name": "VRM",
            "id": "fdd000564",
            "filenamepattern": [
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                },
                {
                    "value": "*.vrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.vrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000564.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000564.xml"
            }
        },
        {
            "vrm": "",
            "name": "VRM",
            "id": "fdd000564",
            "filenamepattern": [
                {
                    "value": "*.glb",
                    "url": "https://www.digipres.org/formats/extensions/#*.glb"
                },
                {
                    "value": "*.vrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.vrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000564.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000564.xml"
            }
        },
        {
            "jpeg2000part15,highthroughput(htj2k)": "",
            "name": "JPEG 2000 Part 15, High Throughput (HTJ2K)",
            "id": "fdd000565",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000565.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000565.xml"
            }
        },
        {
            "jpeg2000part15,highthroughput(htj2k)": "",
            "name": "JPEG 2000 Part 15, High Throughput (HTJ2K)",
            "id": "fdd000565",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000565.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000565.xml"
            }
        },
        {
            "jpeg2000part15,highthroughput(htj2k)": "",
            "name": "JPEG 2000 Part 15, High Throughput (HTJ2K)",
            "id": "fdd000565",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000565.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000565.xml"
            }
        },
        {
            "jpeg2000part15,highthroughput(htj2k)": "",
            "name": "JPEG 2000 Part 15, High Throughput (HTJ2K)",
            "id": "fdd000565",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000565.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000565.xml"
            }
        },
        {
            "jpeg2000part15,highthroughput(htj2k)": "",
            "name": "JPEG 2000 Part 15, High Throughput (HTJ2K)",
            "id": "fdd000565",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000565.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000565.xml"
            }
        },
        {
            "jpeg2000part15,highthroughput(htj2k)": "",
            "name": "JPEG 2000 Part 15, High Throughput (HTJ2K)",
            "id": "fdd000565",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000565.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000565.xml"
            }
        },
        {
            "jpeg2000part15,highthroughput(htj2k)": "",
            "name": "JPEG 2000 Part 15, High Throughput (HTJ2K)",
            "id": "fdd000565",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000565.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000565.xml"
            }
        },
        {
            "jpeg2000part15,highthroughput(htj2k)": "",
            "name": "JPEG 2000 Part 15, High Throughput (HTJ2K)",
            "id": "fdd000565",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000565.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000565.xml"
            }
        },
        {
            "highthroughputjpeg2000fileformat": "",
            "name": "High-Throughput JPEG 2000 File Format",
            "id": "fdd000566",
            "filenamepattern": [
                {
                    "value": "*.jph",
                    "url": "https://www.digipres.org/formats/extensions/#*.jph"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jph",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jph"
                },
                {
                    "value": "image/jphc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jphc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000566.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000566.xml"
            }
        },
        {
            "highthroughputjpeg2000fileformat": "",
            "name": "High-Throughput JPEG 2000 File Format",
            "id": "fdd000566",
            "filenamepattern": [
                {
                    "value": "*.jph",
                    "url": "https://www.digipres.org/formats/extensions/#*.jph"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jph",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jph"
                },
                {
                    "value": "image/jphc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jphc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000566.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000566.xml"
            }
        },
        {
            "highthroughputjpeg2000fileformat": "",
            "name": "High-Throughput JPEG 2000 File Format",
            "id": "fdd000566",
            "filenamepattern": [
                {
                    "value": "*.jph",
                    "url": "https://www.digipres.org/formats/extensions/#*.jph"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jph",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jph"
                },
                {
                    "value": "image/jphc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jphc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000566.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000566.xml"
            }
        },
        {
            "highthroughputjpeg2000fileformat": "",
            "name": "High-Throughput JPEG 2000 File Format",
            "id": "fdd000566",
            "filenamepattern": [
                {
                    "value": "*.jph",
                    "url": "https://www.digipres.org/formats/extensions/#*.jph"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jph",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jph"
                },
                {
                    "value": "image/jphc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jphc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000566.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000566.xml"
            }
        },
        {
            "highthroughputjpeg2000fileformat": "",
            "name": "High-Throughput JPEG 2000 File Format",
            "id": "fdd000566",
            "filenamepattern": [
                {
                    "value": "*.jph",
                    "url": "https://www.digipres.org/formats/extensions/#*.jph"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jph",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jph"
                },
                {
                    "value": "image/jphc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jphc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000566.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000566.xml"
            }
        },
        {
            "highthroughputjpeg2000fileformat": "",
            "name": "High-Throughput JPEG 2000 File Format",
            "id": "fdd000566",
            "filenamepattern": [
                {
                    "value": "*.jph",
                    "url": "https://www.digipres.org/formats/extensions/#*.jph"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jph",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jph"
                },
                {
                    "value": "image/jphc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jphc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000566.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000566.xml"
            }
        },
        {
            "highthroughputjpeg2000fileformat": "",
            "name": "High-Throughput JPEG 2000 File Format",
            "id": "fdd000566",
            "filenamepattern": [
                {
                    "value": "*.jph",
                    "url": "https://www.digipres.org/formats/extensions/#*.jph"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jph",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jph"
                },
                {
                    "value": "image/jphc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jphc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000566.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000566.xml"
            }
        },
        {
            "highthroughputjpeg2000fileformat": "",
            "name": "High-Throughput JPEG 2000 File Format",
            "id": "fdd000566",
            "filenamepattern": [
                {
                    "value": "*.jph",
                    "url": "https://www.digipres.org/formats/extensions/#*.jph"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jph",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jph"
                },
                {
                    "value": "image/jphc",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jphc"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000566.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000566.xml"
            }
        },
        {
            "webvideotexttracksformat(webvtt)": "",
            "name": "Web Video Text Tracks Format (WebVTT)",
            "id": "fdd000567",
            "filenamepattern": [
                {
                    "value": "*.vtt",
                    "url": "https://www.digipres.org/formats/extensions/#*.vtt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000567.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000567.xml"
            }
        },
        {
            "webvideotexttracksformat(webvtt)": "",
            "name": "Web Video Text Tracks Format (WebVTT)",
            "id": "fdd000567",
            "filenamepattern": [
                {
                    "value": "*.vtt",
                    "url": "https://www.digipres.org/formats/extensions/#*.vtt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000567.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000567.xml"
            }
        },
        {
            "webvideotexttracksformat(webvtt)": "",
            "name": "Web Video Text Tracks Format (WebVTT)",
            "id": "fdd000567",
            "filenamepattern": [
                {
                    "value": "*.vtt",
                    "url": "https://www.digipres.org/formats/extensions/#*.vtt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000567.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000567.xml"
            }
        },
        {
            "webvideotexttracksformat(webvtt)": "",
            "name": "Web Video Text Tracks Format (WebVTT)",
            "id": "fdd000567",
            "filenamepattern": [
                {
                    "value": "*.vtt",
                    "url": "https://www.digipres.org/formats/extensions/#*.vtt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000567.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000567.xml"
            }
        },
        {
            "webvideotexttracksformat(webvtt)": "",
            "name": "Web Video Text Tracks Format (WebVTT)",
            "id": "fdd000567",
            "filenamepattern": [
                {
                    "value": "*.vtt",
                    "url": "https://www.digipres.org/formats/extensions/#*.vtt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000567.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000567.xml"
            }
        },
        {
            "webvideotexttracksformat(webvtt)": "",
            "name": "Web Video Text Tracks Format (WebVTT)",
            "id": "fdd000567",
            "filenamepattern": [
                {
                    "value": "*.vtt",
                    "url": "https://www.digipres.org/formats/extensions/#*.vtt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000567.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000567.xml"
            }
        },
        {
            "webvideotexttracksformat(webvtt)": "",
            "name": "Web Video Text Tracks Format (WebVTT)",
            "id": "fdd000567",
            "filenamepattern": [
                {
                    "value": "*.vtt",
                    "url": "https://www.digipres.org/formats/extensions/#*.vtt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000567.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000567.xml"
            }
        },
        {
            "webvideotexttracksformat(webvtt)": "",
            "name": "Web Video Text Tracks Format (WebVTT)",
            "id": "fdd000567",
            "filenamepattern": [
                {
                    "value": "*.vtt",
                    "url": "https://www.digipres.org/formats/extensions/#*.vtt"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/msword",
                    "url": "https://www.digipres.org/formats/mime-types/#application/msword"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000567.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000567.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion1(ttml1)": "",
            "name": "Timed Text Markup Language Version 1 (TTML1)",
            "id": "fdd000568",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ttml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ttml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000568.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000568.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion1(ttml1)": "",
            "name": "Timed Text Markup Language Version 1 (TTML1)",
            "id": "fdd000568",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ttml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ttml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000568.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000568.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion1(ttml1)": "",
            "name": "Timed Text Markup Language Version 1 (TTML1)",
            "id": "fdd000568",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ttml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ttml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000568.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000568.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion1(ttml1)": "",
            "name": "Timed Text Markup Language Version 1 (TTML1)",
            "id": "fdd000568",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ttml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ttml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000568.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000568.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion1(ttml1)": "",
            "name": "Timed Text Markup Language Version 1 (TTML1)",
            "id": "fdd000568",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ttml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ttml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000568.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000568.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion1(ttml1)": "",
            "name": "Timed Text Markup Language Version 1 (TTML1)",
            "id": "fdd000568",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ttml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ttml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000568.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000568.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion1(ttml1)": "",
            "name": "Timed Text Markup Language Version 1 (TTML1)",
            "id": "fdd000568",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ttml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ttml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000568.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000568.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion1(ttml1)": "",
            "name": "Timed Text Markup Language Version 1 (TTML1)",
            "id": "fdd000568",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/ttml+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/ttml+xml"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000568.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000568.xml"
            }
        },
        {
            "subripsubtitleformat(srt)": "",
            "name": "SubRip Subtitle format (SRT)",
            "id": "fdd000569",
            "filenamepattern": [
                {
                    "value": "*.srt",
                    "url": "https://www.digipres.org/formats/extensions/#*.srt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000569.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000569.xml"
            }
        },
        {
            "subripsubtitleformat(srt)": "",
            "name": "SubRip Subtitle format (SRT)",
            "id": "fdd000569",
            "filenamepattern": [
                {
                    "value": "*.srt",
                    "url": "https://www.digipres.org/formats/extensions/#*.srt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000569.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000569.xml"
            }
        },
        {
            "subripsubtitleformat(srt)": "",
            "name": "SubRip Subtitle format (SRT)",
            "id": "fdd000569",
            "filenamepattern": [
                {
                    "value": "*.srt",
                    "url": "https://www.digipres.org/formats/extensions/#*.srt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000569.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000569.xml"
            }
        },
        {
            "subripsubtitleformat(srt)": "",
            "name": "SubRip Subtitle format (SRT)",
            "id": "fdd000569",
            "filenamepattern": [
                {
                    "value": "*.srt",
                    "url": "https://www.digipres.org/formats/extensions/#*.srt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000569.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000569.xml"
            }
        },
        {
            "subripsubtitleformat(srt)": "",
            "name": "SubRip Subtitle format (SRT)",
            "id": "fdd000569",
            "filenamepattern": [
                {
                    "value": "*.srt",
                    "url": "https://www.digipres.org/formats/extensions/#*.srt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000569.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000569.xml"
            }
        },
        {
            "subripsubtitleformat(srt)": "",
            "name": "SubRip Subtitle format (SRT)",
            "id": "fdd000569",
            "filenamepattern": [
                {
                    "value": "*.srt",
                    "url": "https://www.digipres.org/formats/extensions/#*.srt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000569.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000569.xml"
            }
        },
        {
            "subripsubtitleformat(srt)": "",
            "name": "SubRip Subtitle format (SRT)",
            "id": "fdd000569",
            "filenamepattern": [
                {
                    "value": "*.srt",
                    "url": "https://www.digipres.org/formats/extensions/#*.srt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000569.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000569.xml"
            }
        },
        {
            "subripsubtitleformat(srt)": "",
            "name": "SubRip Subtitle format (SRT)",
            "id": "fdd000569",
            "filenamepattern": [
                {
                    "value": "*.srt",
                    "url": "https://www.digipres.org/formats/extensions/#*.srt"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000569.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000569.xml"
            }
        },
        {
            "subtitleindex(vobsubidx)": "",
            "name": "Subtitle Index (VobSub IDX)",
            "id": "fdd000570",
            "filenamepattern": [
                {
                    "value": "*.idx",
                    "url": "https://www.digipres.org/formats/extensions/#*.idx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000570.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000570.xml"
            }
        },
        {
            "subtitleindex(vobsubidx)": "",
            "name": "Subtitle Index (VobSub IDX)",
            "id": "fdd000570",
            "filenamepattern": [
                {
                    "value": "*.idx",
                    "url": "https://www.digipres.org/formats/extensions/#*.idx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000570.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000570.xml"
            }
        },
        {
            "subtitleindex(vobsubidx)": "",
            "name": "Subtitle Index (VobSub IDX)",
            "id": "fdd000570",
            "filenamepattern": [
                {
                    "value": "*.idx",
                    "url": "https://www.digipres.org/formats/extensions/#*.idx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000570.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000570.xml"
            }
        },
        {
            "subtitleindex(vobsubidx)": "",
            "name": "Subtitle Index (VobSub IDX)",
            "id": "fdd000570",
            "filenamepattern": [
                {
                    "value": "*.idx",
                    "url": "https://www.digipres.org/formats/extensions/#*.idx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000570.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000570.xml"
            }
        },
        {
            "subtitleindex(vobsubidx)": "",
            "name": "Subtitle Index (VobSub IDX)",
            "id": "fdd000570",
            "filenamepattern": [
                {
                    "value": "*.idx",
                    "url": "https://www.digipres.org/formats/extensions/#*.idx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000570.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000570.xml"
            }
        },
        {
            "subtitleindex(vobsubidx)": "",
            "name": "Subtitle Index (VobSub IDX)",
            "id": "fdd000570",
            "filenamepattern": [
                {
                    "value": "*.idx",
                    "url": "https://www.digipres.org/formats/extensions/#*.idx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000570.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000570.xml"
            }
        },
        {
            "subtitleindex(vobsubidx)": "",
            "name": "Subtitle Index (VobSub IDX)",
            "id": "fdd000570",
            "filenamepattern": [
                {
                    "value": "*.idx",
                    "url": "https://www.digipres.org/formats/extensions/#*.idx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000570.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000570.xml"
            }
        },
        {
            "subtitleindex(vobsubidx)": "",
            "name": "Subtitle Index (VobSub IDX)",
            "id": "fdd000570",
            "filenamepattern": [
                {
                    "value": "*.idx",
                    "url": "https://www.digipres.org/formats/extensions/#*.idx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000570.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000570.xml"
            }
        },
        {
            "vobsubsubtitlefile(sub)": "",
            "name": "VobSub Subtitle File (SUB)",
            "id": "fdd000571",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000571.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000571.xml"
            }
        },
        {
            "vobsubsubtitlefile(sub)": "",
            "name": "VobSub Subtitle File (SUB)",
            "id": "fdd000571",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000571.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000571.xml"
            }
        },
        {
            "vobsubsubtitlefile(sub)": "",
            "name": "VobSub Subtitle File (SUB)",
            "id": "fdd000571",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000571.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000571.xml"
            }
        },
        {
            "vobsubsubtitlefile(sub)": "",
            "name": "VobSub Subtitle File (SUB)",
            "id": "fdd000571",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000571.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000571.xml"
            }
        },
        {
            "vobsubsubtitlefile(sub)": "",
            "name": "VobSub Subtitle File (SUB)",
            "id": "fdd000571",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000571.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000571.xml"
            }
        },
        {
            "vobsubsubtitlefile(sub)": "",
            "name": "VobSub Subtitle File (SUB)",
            "id": "fdd000571",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000571.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000571.xml"
            }
        },
        {
            "vobsubsubtitlefile(sub)": "",
            "name": "VobSub Subtitle File (SUB)",
            "id": "fdd000571",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000571.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000571.xml"
            }
        },
        {
            "vobsubsubtitlefile(sub)": "",
            "name": "VobSub Subtitle File (SUB)",
            "id": "fdd000571",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000571.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000571.xml"
            }
        },
        {
            "synchronizedmultimediaintegrationlanguage(smil)": "",
            "name": "Synchronized Multimedia Integration Language (SMIL)",
            "id": "fdd000572",
            "filenamepattern": [
                {
                    "value": "*.smil",
                    "url": "https://www.digipres.org/formats/extensions/#*.smil"
                },
                {
                    "value": "*.sml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sml"
                },
                {
                    "value": "*.smi",
                    "url": "https://www.digipres.org/formats/extensions/#*.smi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/smil+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/smil+xml"
                },
                {
                    "value": "text/smil",
                    "url": "https://www.digipres.org/formats/mime-types/#text/smil"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000572.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000572.xml"
            }
        },
        {
            "synchronizedmultimediaintegrationlanguage(smil)": "",
            "name": "Synchronized Multimedia Integration Language (SMIL)",
            "id": "fdd000572",
            "filenamepattern": [
                {
                    "value": "*.smil",
                    "url": "https://www.digipres.org/formats/extensions/#*.smil"
                },
                {
                    "value": "*.sml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sml"
                },
                {
                    "value": "*.smi",
                    "url": "https://www.digipres.org/formats/extensions/#*.smi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/smil+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/smil+xml"
                },
                {
                    "value": "text/smil",
                    "url": "https://www.digipres.org/formats/mime-types/#text/smil"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000572.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000572.xml"
            }
        },
        {
            "synchronizedmultimediaintegrationlanguage(smil)": "",
            "name": "Synchronized Multimedia Integration Language (SMIL)",
            "id": "fdd000572",
            "filenamepattern": [
                {
                    "value": "*.smil",
                    "url": "https://www.digipres.org/formats/extensions/#*.smil"
                },
                {
                    "value": "*.sml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sml"
                },
                {
                    "value": "*.smi",
                    "url": "https://www.digipres.org/formats/extensions/#*.smi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/smil+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/smil+xml"
                },
                {
                    "value": "text/smil",
                    "url": "https://www.digipres.org/formats/mime-types/#text/smil"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000572.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000572.xml"
            }
        },
        {
            "synchronizedmultimediaintegrationlanguage(smil)": "",
            "name": "Synchronized Multimedia Integration Language (SMIL)",
            "id": "fdd000572",
            "filenamepattern": [
                {
                    "value": "*.smil",
                    "url": "https://www.digipres.org/formats/extensions/#*.smil"
                },
                {
                    "value": "*.sml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sml"
                },
                {
                    "value": "*.smi",
                    "url": "https://www.digipres.org/formats/extensions/#*.smi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/smil+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/smil+xml"
                },
                {
                    "value": "text/smil",
                    "url": "https://www.digipres.org/formats/mime-types/#text/smil"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000572.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000572.xml"
            }
        },
        {
            "synchronizedmultimediaintegrationlanguage(smil)": "",
            "name": "Synchronized Multimedia Integration Language (SMIL)",
            "id": "fdd000572",
            "filenamepattern": [
                {
                    "value": "*.smil",
                    "url": "https://www.digipres.org/formats/extensions/#*.smil"
                },
                {
                    "value": "*.sml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sml"
                },
                {
                    "value": "*.smi",
                    "url": "https://www.digipres.org/formats/extensions/#*.smi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/smil+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/smil+xml"
                },
                {
                    "value": "text/smil",
                    "url": "https://www.digipres.org/formats/mime-types/#text/smil"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000572.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000572.xml"
            }
        },
        {
            "synchronizedmultimediaintegrationlanguage(smil)": "",
            "name": "Synchronized Multimedia Integration Language (SMIL)",
            "id": "fdd000572",
            "filenamepattern": [
                {
                    "value": "*.smil",
                    "url": "https://www.digipres.org/formats/extensions/#*.smil"
                },
                {
                    "value": "*.sml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sml"
                },
                {
                    "value": "*.smi",
                    "url": "https://www.digipres.org/formats/extensions/#*.smi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/smil+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/smil+xml"
                },
                {
                    "value": "text/smil",
                    "url": "https://www.digipres.org/formats/mime-types/#text/smil"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000572.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000572.xml"
            }
        },
        {
            "synchronizedmultimediaintegrationlanguage(smil)": "",
            "name": "Synchronized Multimedia Integration Language (SMIL)",
            "id": "fdd000572",
            "filenamepattern": [
                {
                    "value": "*.smil",
                    "url": "https://www.digipres.org/formats/extensions/#*.smil"
                },
                {
                    "value": "*.sml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sml"
                },
                {
                    "value": "*.smi",
                    "url": "https://www.digipres.org/formats/extensions/#*.smi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/smil+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/smil+xml"
                },
                {
                    "value": "text/smil",
                    "url": "https://www.digipres.org/formats/mime-types/#text/smil"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000572.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000572.xml"
            }
        },
        {
            "synchronizedmultimediaintegrationlanguage(smil)": "",
            "name": "Synchronized Multimedia Integration Language (SMIL)",
            "id": "fdd000572",
            "filenamepattern": [
                {
                    "value": "*.smil",
                    "url": "https://www.digipres.org/formats/extensions/#*.smil"
                },
                {
                    "value": "*.sml",
                    "url": "https://www.digipres.org/formats/extensions/#*.sml"
                },
                {
                    "value": "*.smi",
                    "url": "https://www.digipres.org/formats/extensions/#*.smi"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/smil+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/smil+xml"
                },
                {
                    "value": "text/smil",
                    "url": "https://www.digipres.org/formats/mime-types/#text/smil"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000572.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000572.xml"
            }
        },
        {
            "digitalvideobroadcasting;subtitlingsystems(dvbsub)": "",
            "name": "Digital Video Broadcasting; Subtitling systems (DVB-SUB)",
            "id": "fdd000573",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dvb.subtitle",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dvb.subtitle"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000573.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000573.xml"
            }
        },
        {
            "digitalvideobroadcasting;subtitlingsystems(dvbsub)": "",
            "name": "Digital Video Broadcasting; Subtitling systems (DVB-SUB)",
            "id": "fdd000573",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dvb.subtitle",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dvb.subtitle"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000573.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000573.xml"
            }
        },
        {
            "digitalvideobroadcasting;subtitlingsystems(dvbsub)": "",
            "name": "Digital Video Broadcasting; Subtitling systems (DVB-SUB)",
            "id": "fdd000573",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dvb.subtitle",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dvb.subtitle"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000573.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000573.xml"
            }
        },
        {
            "digitalvideobroadcasting;subtitlingsystems(dvbsub)": "",
            "name": "Digital Video Broadcasting; Subtitling systems (DVB-SUB)",
            "id": "fdd000573",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dvb.subtitle",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dvb.subtitle"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000573.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000573.xml"
            }
        },
        {
            "digitalvideobroadcasting;subtitlingsystems(dvbsub)": "",
            "name": "Digital Video Broadcasting; Subtitling systems (DVB-SUB)",
            "id": "fdd000573",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dvb.subtitle",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dvb.subtitle"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000573.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000573.xml"
            }
        },
        {
            "digitalvideobroadcasting;subtitlingsystems(dvbsub)": "",
            "name": "Digital Video Broadcasting; Subtitling systems (DVB-SUB)",
            "id": "fdd000573",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dvb.subtitle",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dvb.subtitle"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000573.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000573.xml"
            }
        },
        {
            "digitalvideobroadcasting;subtitlingsystems(dvbsub)": "",
            "name": "Digital Video Broadcasting; Subtitling systems (DVB-SUB)",
            "id": "fdd000573",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dvb.subtitle",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dvb.subtitle"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000573.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000573.xml"
            }
        },
        {
            "digitalvideobroadcasting;subtitlingsystems(dvbsub)": "",
            "name": "Digital Video Broadcasting; Subtitling systems (DVB-SUB)",
            "id": "fdd000573",
            "filenamepattern": [
                {
                    "value": "*.sub",
                    "url": "https://www.digipres.org/formats/extensions/#*.sub"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/vnd.dvb.subtitle",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.dvb.subtitle"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000573.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000573.xml"
            }
        },
        {
            "universalscenedescriptionzipped(usdz)": "",
            "name": "Universal Scene Description Zipped (USDZ)",
            "id": "fdd000574",
            "filenamepattern": [
                {
                    "value": "*.usdz",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.usdz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.usdz+zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000574.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000574.xml"
            }
        },
        {
            "universalscenedescriptionzipped(usdz)": "",
            "name": "Universal Scene Description Zipped (USDZ)",
            "id": "fdd000574",
            "filenamepattern": [
                {
                    "value": "*.usdz",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.usdz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.usdz+zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000574.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000574.xml"
            }
        },
        {
            "universalscenedescriptionzipped(usdz)": "",
            "name": "Universal Scene Description Zipped (USDZ)",
            "id": "fdd000574",
            "filenamepattern": [
                {
                    "value": "*.usdz",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.usdz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.usdz+zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000574.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000574.xml"
            }
        },
        {
            "universalscenedescriptionzipped(usdz)": "",
            "name": "Universal Scene Description Zipped (USDZ)",
            "id": "fdd000574",
            "filenamepattern": [
                {
                    "value": "*.usdz",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.usdz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.usdz+zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000574.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000574.xml"
            }
        },
        {
            "universalscenedescriptionzipped(usdz)": "",
            "name": "Universal Scene Description Zipped (USDZ)",
            "id": "fdd000574",
            "filenamepattern": [
                {
                    "value": "*.usdz",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.usdz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.usdz+zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000574.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000574.xml"
            }
        },
        {
            "universalscenedescriptionzipped(usdz)": "",
            "name": "Universal Scene Description Zipped (USDZ)",
            "id": "fdd000574",
            "filenamepattern": [
                {
                    "value": "*.usdz",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.usdz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.usdz+zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000574.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000574.xml"
            }
        },
        {
            "universalscenedescriptionzipped(usdz)": "",
            "name": "Universal Scene Description Zipped (USDZ)",
            "id": "fdd000574",
            "filenamepattern": [
                {
                    "value": "*.usdz",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.usdz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.usdz+zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000574.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000574.xml"
            }
        },
        {
            "universalscenedescriptionzipped(usdz)": "",
            "name": "Universal Scene Description Zipped (USDZ)",
            "id": "fdd000574",
            "filenamepattern": [
                {
                    "value": "*.usdz",
                    "url": "https://www.digipres.org/formats/extensions/#*.usdz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.usdz+zip",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.usdz+zip"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000574.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000574.xml"
            }
        },
        {
            "apacheparquet": "",
            "name": "Apache Parquet",
            "id": "fdd000575",
            "filenamepattern": [
                {
                    "value": "*.parquet",
                    "url": "https://www.digipres.org/formats/extensions/#*.parquet"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000575.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000575.xml"
            }
        },
        {
            "apacheparquet": "",
            "name": "Apache Parquet",
            "id": "fdd000575",
            "filenamepattern": [
                {
                    "value": "*.parquet",
                    "url": "https://www.digipres.org/formats/extensions/#*.parquet"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000575.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000575.xml"
            }
        },
        {
            "apacheparquet": "",
            "name": "Apache Parquet",
            "id": "fdd000575",
            "filenamepattern": [
                {
                    "value": "*.parquet",
                    "url": "https://www.digipres.org/formats/extensions/#*.parquet"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000575.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000575.xml"
            }
        },
        {
            "apacheparquet": "",
            "name": "Apache Parquet",
            "id": "fdd000575",
            "filenamepattern": [
                {
                    "value": "*.parquet",
                    "url": "https://www.digipres.org/formats/extensions/#*.parquet"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000575.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000575.xml"
            }
        },
        {
            "apacheparquet": "",
            "name": "Apache Parquet",
            "id": "fdd000575",
            "filenamepattern": [
                {
                    "value": "*.parquet",
                    "url": "https://www.digipres.org/formats/extensions/#*.parquet"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000575.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000575.xml"
            }
        },
        {
            "apacheparquet": "",
            "name": "Apache Parquet",
            "id": "fdd000575",
            "filenamepattern": [
                {
                    "value": "*.parquet",
                    "url": "https://www.digipres.org/formats/extensions/#*.parquet"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000575.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000575.xml"
            }
        },
        {
            "apacheparquet": "",
            "name": "Apache Parquet",
            "id": "fdd000575",
            "filenamepattern": [
                {
                    "value": "*.parquet",
                    "url": "https://www.digipres.org/formats/extensions/#*.parquet"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000575.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000575.xml"
            }
        },
        {
            "apacheparquet": "",
            "name": "Apache Parquet",
            "id": "fdd000575",
            "filenamepattern": [
                {
                    "value": "*.parquet",
                    "url": "https://www.digipres.org/formats/extensions/#*.parquet"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000575.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000575.xml"
            }
        },
        {
            "webp": "",
            "name": "WebP",
            "id": "fdd000577",
            "filenamepattern": [
                {
                    "value": "*.webp",
                    "url": "https://www.digipres.org/formats/extensions/#*.webp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/webp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/webp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000577.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000577.xml"
            }
        },
        {
            "webp": "",
            "name": "WebP",
            "id": "fdd000577",
            "filenamepattern": [
                {
                    "value": "*.webp",
                    "url": "https://www.digipres.org/formats/extensions/#*.webp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/webp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/webp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000577.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000577.xml"
            }
        },
        {
            "webp": "",
            "name": "WebP",
            "id": "fdd000577",
            "filenamepattern": [
                {
                    "value": "*.webp",
                    "url": "https://www.digipres.org/formats/extensions/#*.webp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/webp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/webp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000577.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000577.xml"
            }
        },
        {
            "webp": "",
            "name": "WebP",
            "id": "fdd000577",
            "filenamepattern": [
                {
                    "value": "*.webp",
                    "url": "https://www.digipres.org/formats/extensions/#*.webp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/webp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/webp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000577.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000577.xml"
            }
        },
        {
            "webp": "",
            "name": "WebP",
            "id": "fdd000577",
            "filenamepattern": [
                {
                    "value": "*.webp",
                    "url": "https://www.digipres.org/formats/extensions/#*.webp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/webp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/webp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000577.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000577.xml"
            }
        },
        {
            "webp": "",
            "name": "WebP",
            "id": "fdd000577",
            "filenamepattern": [
                {
                    "value": "*.webp",
                    "url": "https://www.digipres.org/formats/extensions/#*.webp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/webp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/webp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000577.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000577.xml"
            }
        },
        {
            "webp": "",
            "name": "WebP",
            "id": "fdd000577",
            "filenamepattern": [
                {
                    "value": "*.webp",
                    "url": "https://www.digipres.org/formats/extensions/#*.webp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/webp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/webp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000577.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000577.xml"
            }
        },
        {
            "webp": "",
            "name": "WebP",
            "id": "fdd000577",
            "filenamepattern": [
                {
                    "value": "*.webp",
                    "url": "https://www.digipres.org/formats/extensions/#*.webp"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/webp",
                    "url": "https://www.digipres.org/formats/mime-types/#image/webp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000577.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000577.xml"
            }
        },
        {
            "vp8videocodec": "",
            "name": "VP8 Video Codec",
            "id": "fdd000578",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/vp8",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp8"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000578.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000578.xml"
            }
        },
        {
            "vp8videocodec": "",
            "name": "VP8 Video Codec",
            "id": "fdd000578",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/vp8",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp8"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000578.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000578.xml"
            }
        },
        {
            "vp8videocodec": "",
            "name": "VP8 Video Codec",
            "id": "fdd000578",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/vp8",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp8"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000578.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000578.xml"
            }
        },
        {
            "vp8videocodec": "",
            "name": "VP8 Video Codec",
            "id": "fdd000578",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/vp8",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp8"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000578.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000578.xml"
            }
        },
        {
            "vp8videocodec": "",
            "name": "VP8 Video Codec",
            "id": "fdd000578",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/vp8",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp8"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000578.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000578.xml"
            }
        },
        {
            "vp8videocodec": "",
            "name": "VP8 Video Codec",
            "id": "fdd000578",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/vp8",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp8"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000578.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000578.xml"
            }
        },
        {
            "vp8videocodec": "",
            "name": "VP8 Video Codec",
            "id": "fdd000578",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/vp8",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp8"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000578.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000578.xml"
            }
        },
        {
            "vp8videocodec": "",
            "name": "VP8 Video Codec",
            "id": "fdd000578",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/vp8",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp8"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000578.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000578.xml"
            }
        },
        {
            "vp9videocodec": "",
            "name": "VP9 Video Codec",
            "id": "fdd000579",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/VP9",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp9"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000579.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000579.xml"
            }
        },
        {
            "vp9videocodec": "",
            "name": "VP9 Video Codec",
            "id": "fdd000579",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/VP9",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp9"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000579.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000579.xml"
            }
        },
        {
            "vp9videocodec": "",
            "name": "VP9 Video Codec",
            "id": "fdd000579",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/VP9",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp9"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000579.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000579.xml"
            }
        },
        {
            "vp9videocodec": "",
            "name": "VP9 Video Codec",
            "id": "fdd000579",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/VP9",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp9"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000579.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000579.xml"
            }
        },
        {
            "vp9videocodec": "",
            "name": "VP9 Video Codec",
            "id": "fdd000579",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/VP9",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp9"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000579.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000579.xml"
            }
        },
        {
            "vp9videocodec": "",
            "name": "VP9 Video Codec",
            "id": "fdd000579",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/VP9",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp9"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000579.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000579.xml"
            }
        },
        {
            "vp9videocodec": "",
            "name": "VP9 Video Codec",
            "id": "fdd000579",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/VP9",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp9"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000579.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000579.xml"
            }
        },
        {
            "vp9videocodec": "",
            "name": "VP9 Video Codec",
            "id": "fdd000579",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "video/VP9",
                    "url": "https://www.digipres.org/formats/mime-types/#video/vp9"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000579.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000579.xml"
            }
        },
        {
            "extensibleformsdescriptionlanguage": "",
            "name": "Extensible Forms Description Language",
            "id": "fdd000580",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                },
                {
                    "value": "*.xfd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfd"
                },
                {
                    "value": "*.xfdl",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfdl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.xfdl",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.xfdl"
                },
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml (deprecated)",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml(deprecated)"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000580.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000580.xml"
            }
        },
        {
            "extensibleformsdescriptionlanguage": "",
            "name": "Extensible Forms Description Language",
            "id": "fdd000580",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                },
                {
                    "value": "*.xfd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfd"
                },
                {
                    "value": "*.xfdl",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfdl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.xfdl",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.xfdl"
                },
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml (deprecated)",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml(deprecated)"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000580.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000580.xml"
            }
        },
        {
            "extensibleformsdescriptionlanguage": "",
            "name": "Extensible Forms Description Language",
            "id": "fdd000580",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                },
                {
                    "value": "*.xfd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfd"
                },
                {
                    "value": "*.xfdl",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfdl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.xfdl",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.xfdl"
                },
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml (deprecated)",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml(deprecated)"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000580.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000580.xml"
            }
        },
        {
            "extensibleformsdescriptionlanguage": "",
            "name": "Extensible Forms Description Language",
            "id": "fdd000580",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                },
                {
                    "value": "*.xfd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfd"
                },
                {
                    "value": "*.xfdl",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfdl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.xfdl",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.xfdl"
                },
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml (deprecated)",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml(deprecated)"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000580.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000580.xml"
            }
        },
        {
            "extensibleformsdescriptionlanguage": "",
            "name": "Extensible Forms Description Language",
            "id": "fdd000580",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                },
                {
                    "value": "*.xfd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfd"
                },
                {
                    "value": "*.xfdl",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfdl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.xfdl",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.xfdl"
                },
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml (deprecated)",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml(deprecated)"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000580.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000580.xml"
            }
        },
        {
            "extensibleformsdescriptionlanguage": "",
            "name": "Extensible Forms Description Language",
            "id": "fdd000580",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                },
                {
                    "value": "*.xfd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfd"
                },
                {
                    "value": "*.xfdl",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfdl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.xfdl",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.xfdl"
                },
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml (deprecated)",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml(deprecated)"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000580.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000580.xml"
            }
        },
        {
            "extensibleformsdescriptionlanguage": "",
            "name": "Extensible Forms Description Language",
            "id": "fdd000580",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                },
                {
                    "value": "*.xfd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfd"
                },
                {
                    "value": "*.xfdl",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfdl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.xfdl",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.xfdl"
                },
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml (deprecated)",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml(deprecated)"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000580.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000580.xml"
            }
        },
        {
            "extensibleformsdescriptionlanguage": "",
            "name": "Extensible Forms Description Language",
            "id": "fdd000580",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                },
                {
                    "value": "*.xfd",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfd"
                },
                {
                    "value": "*.xfdl",
                    "url": "https://www.digipres.org/formats/extensions/#*.xfdl"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.xfdl",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.xfdl"
                },
                {
                    "value": "application/xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/xml"
                },
                {
                    "value": "text/xml (deprecated)",
                    "url": "https://www.digipres.org/formats/mime-types/#text/xml(deprecated)"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000580.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000580.xml"
            }
        },
        {
            "transmissionxraymicroscopy(txm)": "",
            "name": "Transmission X-Ray Microscopy (TXM)",
            "id": "fdd000581",
            "filenamepattern": [
                {
                    "value": "*.txm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txm"
                },
                {
                    "value": "*.txrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txrm"
                },
                {
                    "value": "*.xrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000581.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000581.xml"
            }
        },
        {
            "transmissionxraymicroscopy(txm)": "",
            "name": "Transmission X-Ray Microscopy (TXM)",
            "id": "fdd000581",
            "filenamepattern": [
                {
                    "value": "*.txm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txm"
                },
                {
                    "value": "*.txrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txrm"
                },
                {
                    "value": "*.xrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000581.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000581.xml"
            }
        },
        {
            "transmissionxraymicroscopy(txm)": "",
            "name": "Transmission X-Ray Microscopy (TXM)",
            "id": "fdd000581",
            "filenamepattern": [
                {
                    "value": "*.txm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txm"
                },
                {
                    "value": "*.txrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txrm"
                },
                {
                    "value": "*.xrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000581.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000581.xml"
            }
        },
        {
            "transmissionxraymicroscopy(txm)": "",
            "name": "Transmission X-Ray Microscopy (TXM)",
            "id": "fdd000581",
            "filenamepattern": [
                {
                    "value": "*.txm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txm"
                },
                {
                    "value": "*.txrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txrm"
                },
                {
                    "value": "*.xrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000581.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000581.xml"
            }
        },
        {
            "transmissionxraymicroscopy(txm)": "",
            "name": "Transmission X-Ray Microscopy (TXM)",
            "id": "fdd000581",
            "filenamepattern": [
                {
                    "value": "*.txm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txm"
                },
                {
                    "value": "*.txrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txrm"
                },
                {
                    "value": "*.xrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000581.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000581.xml"
            }
        },
        {
            "transmissionxraymicroscopy(txm)": "",
            "name": "Transmission X-Ray Microscopy (TXM)",
            "id": "fdd000581",
            "filenamepattern": [
                {
                    "value": "*.txm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txm"
                },
                {
                    "value": "*.txrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txrm"
                },
                {
                    "value": "*.xrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000581.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000581.xml"
            }
        },
        {
            "transmissionxraymicroscopy(txm)": "",
            "name": "Transmission X-Ray Microscopy (TXM)",
            "id": "fdd000581",
            "filenamepattern": [
                {
                    "value": "*.txm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txm"
                },
                {
                    "value": "*.txrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txrm"
                },
                {
                    "value": "*.xrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000581.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000581.xml"
            }
        },
        {
            "transmissionxraymicroscopy(txm)": "",
            "name": "Transmission X-Ray Microscopy (TXM)",
            "id": "fdd000581",
            "filenamepattern": [
                {
                    "value": "*.txm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txm"
                },
                {
                    "value": "*.txrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.txrm"
                },
                {
                    "value": "*.xrm",
                    "url": "https://www.digipres.org/formats/extensions/#*.xrm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000581.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000581.xml"
            }
        },
        {
            "chemdrawexchange": "",
            "name": "ChemDraw Exchange",
            "id": "fdd000582",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                },
                {
                    "value": "*.cdxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdxml"
                }
            ],
            "mimetypes": [
                {
                    "value": "chemical/x-cdx",
                    "url": "https://www.digipres.org/formats/mime-types/#chemical/x-cdx"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000582.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000582.xml"
            }
        },
        {
            "chemdrawexchange": "",
            "name": "ChemDraw Exchange",
            "id": "fdd000582",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                },
                {
                    "value": "*.cdxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdxml"
                }
            ],
            "mimetypes": [
                {
                    "value": "chemical/x-cdx",
                    "url": "https://www.digipres.org/formats/mime-types/#chemical/x-cdx"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000582.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000582.xml"
            }
        },
        {
            "chemdrawexchange": "",
            "name": "ChemDraw Exchange",
            "id": "fdd000582",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                },
                {
                    "value": "*.cdxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdxml"
                }
            ],
            "mimetypes": [
                {
                    "value": "chemical/x-cdx",
                    "url": "https://www.digipres.org/formats/mime-types/#chemical/x-cdx"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000582.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000582.xml"
            }
        },
        {
            "chemdrawexchange": "",
            "name": "ChemDraw Exchange",
            "id": "fdd000582",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                },
                {
                    "value": "*.cdxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdxml"
                }
            ],
            "mimetypes": [
                {
                    "value": "chemical/x-cdx",
                    "url": "https://www.digipres.org/formats/mime-types/#chemical/x-cdx"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000582.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000582.xml"
            }
        },
        {
            "chemdrawexchange": "",
            "name": "ChemDraw Exchange",
            "id": "fdd000582",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                },
                {
                    "value": "*.cdxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdxml"
                }
            ],
            "mimetypes": [
                {
                    "value": "chemical/x-cdx",
                    "url": "https://www.digipres.org/formats/mime-types/#chemical/x-cdx"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000582.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000582.xml"
            }
        },
        {
            "chemdrawexchange": "",
            "name": "ChemDraw Exchange",
            "id": "fdd000582",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                },
                {
                    "value": "*.cdxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdxml"
                }
            ],
            "mimetypes": [
                {
                    "value": "chemical/x-cdx",
                    "url": "https://www.digipres.org/formats/mime-types/#chemical/x-cdx"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000582.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000582.xml"
            }
        },
        {
            "chemdrawexchange": "",
            "name": "ChemDraw Exchange",
            "id": "fdd000582",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                },
                {
                    "value": "*.cdxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdxml"
                }
            ],
            "mimetypes": [
                {
                    "value": "chemical/x-cdx",
                    "url": "https://www.digipres.org/formats/mime-types/#chemical/x-cdx"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000582.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000582.xml"
            }
        },
        {
            "chemdrawexchange": "",
            "name": "ChemDraw Exchange",
            "id": "fdd000582",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                },
                {
                    "value": "*.cdxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdxml"
                }
            ],
            "mimetypes": [
                {
                    "value": "chemical/x-cdx",
                    "url": "https://www.digipres.org/formats/mime-types/#chemical/x-cdx"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000582.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000582.xml"
            }
        },
        {
            "openexr": "",
            "name": "OpenEXR",
            "id": "fdd000583",
            "filenamepattern": [
                {
                    "value": "*.exr",
                    "url": "https://www.digipres.org/formats/extensions/#*.exr"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-exr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr"
                },
                {
                    "value": "image/x-exr; version=\"2\"",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr;version=2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000583.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000583.xml"
            }
        },
        {
            "openexr": "",
            "name": "OpenEXR",
            "id": "fdd000583",
            "filenamepattern": [
                {
                    "value": "*.exr",
                    "url": "https://www.digipres.org/formats/extensions/#*.exr"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-exr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr"
                },
                {
                    "value": "image/x-exr; version=\"2\"",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr;version=2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000583.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000583.xml"
            }
        },
        {
            "openexr": "",
            "name": "OpenEXR",
            "id": "fdd000583",
            "filenamepattern": [
                {
                    "value": "*.exr",
                    "url": "https://www.digipres.org/formats/extensions/#*.exr"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-exr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr"
                },
                {
                    "value": "image/x-exr; version=\"2\"",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr;version=2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000583.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000583.xml"
            }
        },
        {
            "openexr": "",
            "name": "OpenEXR",
            "id": "fdd000583",
            "filenamepattern": [
                {
                    "value": "*.exr",
                    "url": "https://www.digipres.org/formats/extensions/#*.exr"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-exr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr"
                },
                {
                    "value": "image/x-exr; version=\"2\"",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr;version=2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000583.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000583.xml"
            }
        },
        {
            "openexr": "",
            "name": "OpenEXR",
            "id": "fdd000583",
            "filenamepattern": [
                {
                    "value": "*.exr",
                    "url": "https://www.digipres.org/formats/extensions/#*.exr"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-exr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr"
                },
                {
                    "value": "image/x-exr; version=\"2\"",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr;version=2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000583.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000583.xml"
            }
        },
        {
            "openexr": "",
            "name": "OpenEXR",
            "id": "fdd000583",
            "filenamepattern": [
                {
                    "value": "*.exr",
                    "url": "https://www.digipres.org/formats/extensions/#*.exr"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-exr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr"
                },
                {
                    "value": "image/x-exr; version=\"2\"",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr;version=2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000583.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000583.xml"
            }
        },
        {
            "openexr": "",
            "name": "OpenEXR",
            "id": "fdd000583",
            "filenamepattern": [
                {
                    "value": "*.exr",
                    "url": "https://www.digipres.org/formats/extensions/#*.exr"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-exr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr"
                },
                {
                    "value": "image/x-exr; version=\"2\"",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr;version=2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000583.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000583.xml"
            }
        },
        {
            "openexr": "",
            "name": "OpenEXR",
            "id": "fdd000583",
            "filenamepattern": [
                {
                    "value": "*.exr",
                    "url": "https://www.digipres.org/formats/extensions/#*.exr"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-exr",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr"
                },
                {
                    "value": "image/x-exr; version=\"2\"",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-exr;version=2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000583.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000583.xml"
            }
        },
        {
            "selfcontainedinformationretentionformat": "",
            "name": "Self-Contained Information Retention Format",
            "id": "fdd000584",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000584.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000584.xml"
            }
        },
        {
            "selfcontainedinformationretentionformat": "",
            "name": "Self-Contained Information Retention Format",
            "id": "fdd000584",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000584.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000584.xml"
            }
        },
        {
            "selfcontainedinformationretentionformat": "",
            "name": "Self-Contained Information Retention Format",
            "id": "fdd000584",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000584.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000584.xml"
            }
        },
        {
            "selfcontainedinformationretentionformat": "",
            "name": "Self-Contained Information Retention Format",
            "id": "fdd000584",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000584.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000584.xml"
            }
        },
        {
            "selfcontainedinformationretentionformat": "",
            "name": "Self-Contained Information Retention Format",
            "id": "fdd000584",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000584.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000584.xml"
            }
        },
        {
            "selfcontainedinformationretentionformat": "",
            "name": "Self-Contained Information Retention Format",
            "id": "fdd000584",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000584.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000584.xml"
            }
        },
        {
            "selfcontainedinformationretentionformat": "",
            "name": "Self-Contained Information Retention Format",
            "id": "fdd000584",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000584.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000584.xml"
            }
        },
        {
            "selfcontainedinformationretentionformat": "",
            "name": "Self-Contained Information Retention Format",
            "id": "fdd000584",
            "filenamepattern": [
                {
                    "value": "*.json",
                    "url": "https://www.digipres.org/formats/extensions/#*.json"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000584.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000584.xml"
            }
        },
        {
            "pictureexchange(pcx)": "",
            "name": "PiCture eXchange (PCX)",
            "id": "fdd000585",
            "filenamepattern": [
                {
                    "value": "*.dcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcx"
                },
                {
                    "value": "*.ppc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppc"
                },
                {
                    "value": "*.pcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pcx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pcx"
                },
                {
                    "value": "image/x-dcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dcx"
                },
                {
                    "value": "image/vnd.zbrush.pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.zbrush.pcx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000585.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000585.xml"
            }
        },
        {
            "pictureexchange(pcx)": "",
            "name": "PiCture eXchange (PCX)",
            "id": "fdd000585",
            "filenamepattern": [
                {
                    "value": "*.dcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcx"
                },
                {
                    "value": "*.ppc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppc"
                },
                {
                    "value": "*.pcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pcx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pcx"
                },
                {
                    "value": "image/x-dcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dcx"
                },
                {
                    "value": "image/vnd.zbrush.pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.zbrush.pcx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000585.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000585.xml"
            }
        },
        {
            "pictureexchange(pcx)": "",
            "name": "PiCture eXchange (PCX)",
            "id": "fdd000585",
            "filenamepattern": [
                {
                    "value": "*.dcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcx"
                },
                {
                    "value": "*.ppc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppc"
                },
                {
                    "value": "*.pcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pcx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pcx"
                },
                {
                    "value": "image/x-dcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dcx"
                },
                {
                    "value": "image/vnd.zbrush.pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.zbrush.pcx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000585.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000585.xml"
            }
        },
        {
            "pictureexchange(pcx)": "",
            "name": "PiCture eXchange (PCX)",
            "id": "fdd000585",
            "filenamepattern": [
                {
                    "value": "*.dcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcx"
                },
                {
                    "value": "*.ppc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppc"
                },
                {
                    "value": "*.pcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pcx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pcx"
                },
                {
                    "value": "image/x-dcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dcx"
                },
                {
                    "value": "image/vnd.zbrush.pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.zbrush.pcx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000585.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000585.xml"
            }
        },
        {
            "pictureexchange(pcx)": "",
            "name": "PiCture eXchange (PCX)",
            "id": "fdd000585",
            "filenamepattern": [
                {
                    "value": "*.dcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcx"
                },
                {
                    "value": "*.ppc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppc"
                },
                {
                    "value": "*.pcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pcx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pcx"
                },
                {
                    "value": "image/x-dcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dcx"
                },
                {
                    "value": "image/vnd.zbrush.pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.zbrush.pcx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000585.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000585.xml"
            }
        },
        {
            "pictureexchange(pcx)": "",
            "name": "PiCture eXchange (PCX)",
            "id": "fdd000585",
            "filenamepattern": [
                {
                    "value": "*.dcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcx"
                },
                {
                    "value": "*.ppc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppc"
                },
                {
                    "value": "*.pcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pcx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pcx"
                },
                {
                    "value": "image/x-dcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dcx"
                },
                {
                    "value": "image/vnd.zbrush.pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.zbrush.pcx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000585.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000585.xml"
            }
        },
        {
            "pictureexchange(pcx)": "",
            "name": "PiCture eXchange (PCX)",
            "id": "fdd000585",
            "filenamepattern": [
                {
                    "value": "*.dcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcx"
                },
                {
                    "value": "*.ppc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppc"
                },
                {
                    "value": "*.pcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pcx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pcx"
                },
                {
                    "value": "image/x-dcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dcx"
                },
                {
                    "value": "image/vnd.zbrush.pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.zbrush.pcx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000585.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000585.xml"
            }
        },
        {
            "pictureexchange(pcx)": "",
            "name": "PiCture eXchange (PCX)",
            "id": "fdd000585",
            "filenamepattern": [
                {
                    "value": "*.dcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.dcx"
                },
                {
                    "value": "*.ppc",
                    "url": "https://www.digipres.org/formats/extensions/#*.ppc"
                },
                {
                    "value": "*.pcx",
                    "url": "https://www.digipres.org/formats/extensions/#*.pcx"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/x-pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-pcx"
                },
                {
                    "value": "image/x-dcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/x-dcx"
                },
                {
                    "value": "image/vnd.zbrush.pcx",
                    "url": "https://www.digipres.org/formats/mime-types/#image/vnd.zbrush.pcx"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000585.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000585.xml"
            }
        },
        {
            "webarchivecollectionzipped": "",
            "name": "Web Archive Collection Zipped",
            "id": "fdd000586",
            "filenamepattern": [
                {
                    "value": "*.wacz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wacz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-wacz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-wacz"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000586.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000586.xml"
            }
        },
        {
            "webarchivecollectionzipped": "",
            "name": "Web Archive Collection Zipped",
            "id": "fdd000586",
            "filenamepattern": [
                {
                    "value": "*.wacz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wacz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-wacz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-wacz"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000586.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000586.xml"
            }
        },
        {
            "webarchivecollectionzipped": "",
            "name": "Web Archive Collection Zipped",
            "id": "fdd000586",
            "filenamepattern": [
                {
                    "value": "*.wacz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wacz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-wacz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-wacz"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000586.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000586.xml"
            }
        },
        {
            "webarchivecollectionzipped": "",
            "name": "Web Archive Collection Zipped",
            "id": "fdd000586",
            "filenamepattern": [
                {
                    "value": "*.wacz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wacz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-wacz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-wacz"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000586.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000586.xml"
            }
        },
        {
            "webarchivecollectionzipped": "",
            "name": "Web Archive Collection Zipped",
            "id": "fdd000586",
            "filenamepattern": [
                {
                    "value": "*.wacz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wacz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-wacz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-wacz"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000586.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000586.xml"
            }
        },
        {
            "webarchivecollectionzipped": "",
            "name": "Web Archive Collection Zipped",
            "id": "fdd000586",
            "filenamepattern": [
                {
                    "value": "*.wacz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wacz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-wacz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-wacz"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000586.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000586.xml"
            }
        },
        {
            "webarchivecollectionzipped": "",
            "name": "Web Archive Collection Zipped",
            "id": "fdd000586",
            "filenamepattern": [
                {
                    "value": "*.wacz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wacz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-wacz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-wacz"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000586.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000586.xml"
            }
        },
        {
            "webarchivecollectionzipped": "",
            "name": "Web Archive Collection Zipped",
            "id": "fdd000586",
            "filenamepattern": [
                {
                    "value": "*.wacz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wacz"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-wacz",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-wacz"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000586.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000586.xml"
            }
        },
        {
            "archiveexchangeformat": "",
            "name": "Archive eXchange Format",
            "id": "fdd000587",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000587.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000587.xml"
            }
        },
        {
            "archiveexchangeformat": "",
            "name": "Archive eXchange Format",
            "id": "fdd000587",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000587.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000587.xml"
            }
        },
        {
            "archiveexchangeformat": "",
            "name": "Archive eXchange Format",
            "id": "fdd000587",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000587.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000587.xml"
            }
        },
        {
            "archiveexchangeformat": "",
            "name": "Archive eXchange Format",
            "id": "fdd000587",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000587.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000587.xml"
            }
        },
        {
            "archiveexchangeformat": "",
            "name": "Archive eXchange Format",
            "id": "fdd000587",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000587.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000587.xml"
            }
        },
        {
            "archiveexchangeformat": "",
            "name": "Archive eXchange Format",
            "id": "fdd000587",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000587.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000587.xml"
            }
        },
        {
            "archiveexchangeformat": "",
            "name": "Archive eXchange Format",
            "id": "fdd000587",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000587.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000587.xml"
            }
        },
        {
            "archiveexchangeformat": "",
            "name": "Archive eXchange Format",
            "id": "fdd000587",
            "filenamepattern": [
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000587.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000587.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion2(ttml2)": "",
            "name": "Timed Text Markup Language Version 2 (TTML2)",
            "id": "fdd000588",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000588.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000588.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion2(ttml2)": "",
            "name": "Timed Text Markup Language Version 2 (TTML2)",
            "id": "fdd000588",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000588.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000588.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion2(ttml2)": "",
            "name": "Timed Text Markup Language Version 2 (TTML2)",
            "id": "fdd000588",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000588.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000588.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion2(ttml2)": "",
            "name": "Timed Text Markup Language Version 2 (TTML2)",
            "id": "fdd000588",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000588.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000588.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion2(ttml2)": "",
            "name": "Timed Text Markup Language Version 2 (TTML2)",
            "id": "fdd000588",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000588.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000588.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion2(ttml2)": "",
            "name": "Timed Text Markup Language Version 2 (TTML2)",
            "id": "fdd000588",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000588.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000588.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion2(ttml2)": "",
            "name": "Timed Text Markup Language Version 2 (TTML2)",
            "id": "fdd000588",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000588.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000588.xml"
            }
        },
        {
            "timedtextmarkuplanguageversion2(ttml2)": "",
            "name": "Timed Text Markup Language Version 2 (TTML2)",
            "id": "fdd000588",
            "filenamepattern": [
                {
                    "value": "*.ttml",
                    "url": "https://www.digipres.org/formats/extensions/#*.ttml"
                },
                {
                    "value": "*.dfxp",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxp"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000588.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000588.xml"
            }
        },
        {
            "macintoshbinarytransferformatfamily": "",
            "name": "Macintosh Binary Transfer Format Family",
            "id": "fdd000589",
            "filenamepattern": [
                {
                    "value": "*.bin",
                    "url": "https://www.digipres.org/formats/extensions/#*.bin"
                },
                {
                    "value": "*.macbin",
                    "url": "https://www.digipres.org/formats/extensions/#*.macbin"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-macbinary"
                },
                {
                    "value": "application/macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/macbinary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000589.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000589.xml"
            }
        },
        {
            "macintoshbinarytransferformatfamily": "",
            "name": "Macintosh Binary Transfer Format Family",
            "id": "fdd000589",
            "filenamepattern": [
                {
                    "value": "*.bin",
                    "url": "https://www.digipres.org/formats/extensions/#*.bin"
                },
                {
                    "value": "*.macbin",
                    "url": "https://www.digipres.org/formats/extensions/#*.macbin"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-macbinary"
                },
                {
                    "value": "application/macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/macbinary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000589.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000589.xml"
            }
        },
        {
            "macintoshbinarytransferformatfamily": "",
            "name": "Macintosh Binary Transfer Format Family",
            "id": "fdd000589",
            "filenamepattern": [
                {
                    "value": "*.bin",
                    "url": "https://www.digipres.org/formats/extensions/#*.bin"
                },
                {
                    "value": "*.macbin",
                    "url": "https://www.digipres.org/formats/extensions/#*.macbin"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-macbinary"
                },
                {
                    "value": "application/macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/macbinary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000589.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000589.xml"
            }
        },
        {
            "macintoshbinarytransferformatfamily": "",
            "name": "Macintosh Binary Transfer Format Family",
            "id": "fdd000589",
            "filenamepattern": [
                {
                    "value": "*.bin",
                    "url": "https://www.digipres.org/formats/extensions/#*.bin"
                },
                {
                    "value": "*.macbin",
                    "url": "https://www.digipres.org/formats/extensions/#*.macbin"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-macbinary"
                },
                {
                    "value": "application/macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/macbinary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000589.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000589.xml"
            }
        },
        {
            "macintoshbinarytransferformatfamily": "",
            "name": "Macintosh Binary Transfer Format Family",
            "id": "fdd000589",
            "filenamepattern": [
                {
                    "value": "*.bin",
                    "url": "https://www.digipres.org/formats/extensions/#*.bin"
                },
                {
                    "value": "*.macbin",
                    "url": "https://www.digipres.org/formats/extensions/#*.macbin"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-macbinary"
                },
                {
                    "value": "application/macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/macbinary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000589.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000589.xml"
            }
        },
        {
            "macintoshbinarytransferformatfamily": "",
            "name": "Macintosh Binary Transfer Format Family",
            "id": "fdd000589",
            "filenamepattern": [
                {
                    "value": "*.bin",
                    "url": "https://www.digipres.org/formats/extensions/#*.bin"
                },
                {
                    "value": "*.macbin",
                    "url": "https://www.digipres.org/formats/extensions/#*.macbin"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-macbinary"
                },
                {
                    "value": "application/macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/macbinary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000589.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000589.xml"
            }
        },
        {
            "macintoshbinarytransferformatfamily": "",
            "name": "Macintosh Binary Transfer Format Family",
            "id": "fdd000589",
            "filenamepattern": [
                {
                    "value": "*.bin",
                    "url": "https://www.digipres.org/formats/extensions/#*.bin"
                },
                {
                    "value": "*.macbin",
                    "url": "https://www.digipres.org/formats/extensions/#*.macbin"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-macbinary"
                },
                {
                    "value": "application/macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/macbinary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000589.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000589.xml"
            }
        },
        {
            "macintoshbinarytransferformatfamily": "",
            "name": "Macintosh Binary Transfer Format Family",
            "id": "fdd000589",
            "filenamepattern": [
                {
                    "value": "*.bin",
                    "url": "https://www.digipres.org/formats/extensions/#*.bin"
                },
                {
                    "value": "*.macbin",
                    "url": "https://www.digipres.org/formats/extensions/#*.macbin"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-macbinary"
                },
                {
                    "value": "application/macbinary",
                    "url": "https://www.digipres.org/formats/mime-types/#application/macbinary"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000589.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000589.xml"
            }
        },
        {
            "cdxinternetarchiveindexfile": "",
            "name": "CDX Internet Archive Index File",
            "id": "fdd000590",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000590.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000590.xml"
            }
        },
        {
            "cdxinternetarchiveindexfile": "",
            "name": "CDX Internet Archive Index File",
            "id": "fdd000590",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000590.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000590.xml"
            }
        },
        {
            "cdxinternetarchiveindexfile": "",
            "name": "CDX Internet Archive Index File",
            "id": "fdd000590",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000590.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000590.xml"
            }
        },
        {
            "cdxinternetarchiveindexfile": "",
            "name": "CDX Internet Archive Index File",
            "id": "fdd000590",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000590.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000590.xml"
            }
        },
        {
            "cdxinternetarchiveindexfile": "",
            "name": "CDX Internet Archive Index File",
            "id": "fdd000590",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000590.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000590.xml"
            }
        },
        {
            "cdxinternetarchiveindexfile": "",
            "name": "CDX Internet Archive Index File",
            "id": "fdd000590",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000590.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000590.xml"
            }
        },
        {
            "cdxinternetarchiveindexfile": "",
            "name": "CDX Internet Archive Index File",
            "id": "fdd000590",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000590.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000590.xml"
            }
        },
        {
            "cdxinternetarchiveindexfile": "",
            "name": "CDX Internet Archive Index File",
            "id": "fdd000590",
            "filenamepattern": [
                {
                    "value": "*.cdx",
                    "url": "https://www.digipres.org/formats/extensions/#*.cdx"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/plain",
                    "url": "https://www.digipres.org/formats/mime-types/#text/plain"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000590.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000590.xml"
            }
        },
        {
            "applecoreaudioformat": "",
            "name": "Apple Core Audio Format",
            "id": "fdd000591",
            "filenamepattern": [
                {
                    "value": "*.caf",
                    "url": "https://www.digipres.org/formats/extensions/#*.caf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-caf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-caf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000591.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000591.xml"
            }
        },
        {
            "applecoreaudioformat": "",
            "name": "Apple Core Audio Format",
            "id": "fdd000591",
            "filenamepattern": [
                {
                    "value": "*.caf",
                    "url": "https://www.digipres.org/formats/extensions/#*.caf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-caf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-caf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000591.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000591.xml"
            }
        },
        {
            "applecoreaudioformat": "",
            "name": "Apple Core Audio Format",
            "id": "fdd000591",
            "filenamepattern": [
                {
                    "value": "*.caf",
                    "url": "https://www.digipres.org/formats/extensions/#*.caf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-caf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-caf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000591.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000591.xml"
            }
        },
        {
            "applecoreaudioformat": "",
            "name": "Apple Core Audio Format",
            "id": "fdd000591",
            "filenamepattern": [
                {
                    "value": "*.caf",
                    "url": "https://www.digipres.org/formats/extensions/#*.caf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-caf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-caf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000591.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000591.xml"
            }
        },
        {
            "applecoreaudioformat": "",
            "name": "Apple Core Audio Format",
            "id": "fdd000591",
            "filenamepattern": [
                {
                    "value": "*.caf",
                    "url": "https://www.digipres.org/formats/extensions/#*.caf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-caf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-caf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000591.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000591.xml"
            }
        },
        {
            "applecoreaudioformat": "",
            "name": "Apple Core Audio Format",
            "id": "fdd000591",
            "filenamepattern": [
                {
                    "value": "*.caf",
                    "url": "https://www.digipres.org/formats/extensions/#*.caf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-caf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-caf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000591.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000591.xml"
            }
        },
        {
            "applecoreaudioformat": "",
            "name": "Apple Core Audio Format",
            "id": "fdd000591",
            "filenamepattern": [
                {
                    "value": "*.caf",
                    "url": "https://www.digipres.org/formats/extensions/#*.caf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-caf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-caf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000591.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000591.xml"
            }
        },
        {
            "applecoreaudioformat": "",
            "name": "Apple Core Audio Format",
            "id": "fdd000591",
            "filenamepattern": [
                {
                    "value": "*.caf",
                    "url": "https://www.digipres.org/formats/extensions/#*.caf"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/x-caf",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/x-caf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000591.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000591.xml"
            }
        },
        {
            "androidpackage": "",
            "name": "Android Package",
            "id": "fdd000592",
            "filenamepattern": [
                {
                    "value": "*.apk",
                    "url": "https://www.digipres.org/formats/extensions/#*.apk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.android.package-archive",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.android.package-archive"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000592.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000592.xml"
            }
        },
        {
            "androidpackage": "",
            "name": "Android Package",
            "id": "fdd000592",
            "filenamepattern": [
                {
                    "value": "*.apk",
                    "url": "https://www.digipres.org/formats/extensions/#*.apk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.android.package-archive",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.android.package-archive"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000592.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000592.xml"
            }
        },
        {
            "androidpackage": "",
            "name": "Android Package",
            "id": "fdd000592",
            "filenamepattern": [
                {
                    "value": "*.apk",
                    "url": "https://www.digipres.org/formats/extensions/#*.apk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.android.package-archive",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.android.package-archive"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000592.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000592.xml"
            }
        },
        {
            "androidpackage": "",
            "name": "Android Package",
            "id": "fdd000592",
            "filenamepattern": [
                {
                    "value": "*.apk",
                    "url": "https://www.digipres.org/formats/extensions/#*.apk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.android.package-archive",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.android.package-archive"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000592.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000592.xml"
            }
        },
        {
            "androidpackage": "",
            "name": "Android Package",
            "id": "fdd000592",
            "filenamepattern": [
                {
                    "value": "*.apk",
                    "url": "https://www.digipres.org/formats/extensions/#*.apk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.android.package-archive",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.android.package-archive"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000592.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000592.xml"
            }
        },
        {
            "androidpackage": "",
            "name": "Android Package",
            "id": "fdd000592",
            "filenamepattern": [
                {
                    "value": "*.apk",
                    "url": "https://www.digipres.org/formats/extensions/#*.apk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.android.package-archive",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.android.package-archive"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000592.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000592.xml"
            }
        },
        {
            "androidpackage": "",
            "name": "Android Package",
            "id": "fdd000592",
            "filenamepattern": [
                {
                    "value": "*.apk",
                    "url": "https://www.digipres.org/formats/extensions/#*.apk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.android.package-archive",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.android.package-archive"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000592.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000592.xml"
            }
        },
        {
            "androidpackage": "",
            "name": "Android Package",
            "id": "fdd000592",
            "filenamepattern": [
                {
                    "value": "*.apk",
                    "url": "https://www.digipres.org/formats/extensions/#*.apk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.android.package-archive",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.android.package-archive"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000592.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000592.xml"
            }
        },
        {
            "iosappstorepackage": "",
            "name": "iOS App Store Package",
            "id": "fdd000593",
            "filenamepattern": [
                {
                    "value": "*.ipa",
                    "url": "https://www.digipres.org/formats/extensions/#*.ipa"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-itunes-ipa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-itunes-ipa"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000593.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000593.xml"
            }
        },
        {
            "iosappstorepackage": "",
            "name": "iOS App Store Package",
            "id": "fdd000593",
            "filenamepattern": [
                {
                    "value": "*.ipa",
                    "url": "https://www.digipres.org/formats/extensions/#*.ipa"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-itunes-ipa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-itunes-ipa"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000593.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000593.xml"
            }
        },
        {
            "iosappstorepackage": "",
            "name": "iOS App Store Package",
            "id": "fdd000593",
            "filenamepattern": [
                {
                    "value": "*.ipa",
                    "url": "https://www.digipres.org/formats/extensions/#*.ipa"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-itunes-ipa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-itunes-ipa"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000593.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000593.xml"
            }
        },
        {
            "iosappstorepackage": "",
            "name": "iOS App Store Package",
            "id": "fdd000593",
            "filenamepattern": [
                {
                    "value": "*.ipa",
                    "url": "https://www.digipres.org/formats/extensions/#*.ipa"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-itunes-ipa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-itunes-ipa"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000593.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000593.xml"
            }
        },
        {
            "iosappstorepackage": "",
            "name": "iOS App Store Package",
            "id": "fdd000593",
            "filenamepattern": [
                {
                    "value": "*.ipa",
                    "url": "https://www.digipres.org/formats/extensions/#*.ipa"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-itunes-ipa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-itunes-ipa"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000593.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000593.xml"
            }
        },
        {
            "iosappstorepackage": "",
            "name": "iOS App Store Package",
            "id": "fdd000593",
            "filenamepattern": [
                {
                    "value": "*.ipa",
                    "url": "https://www.digipres.org/formats/extensions/#*.ipa"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-itunes-ipa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-itunes-ipa"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000593.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000593.xml"
            }
        },
        {
            "iosappstorepackage": "",
            "name": "iOS App Store Package",
            "id": "fdd000593",
            "filenamepattern": [
                {
                    "value": "*.ipa",
                    "url": "https://www.digipres.org/formats/extensions/#*.ipa"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-itunes-ipa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-itunes-ipa"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000593.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000593.xml"
            }
        },
        {
            "iosappstorepackage": "",
            "name": "iOS App Store Package",
            "id": "fdd000593",
            "filenamepattern": [
                {
                    "value": "*.ipa",
                    "url": "https://www.digipres.org/formats/extensions/#*.ipa"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-itunes-ipa",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-itunes-ipa"
                }
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000593.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000593.xml"
            }
        },
        {
            "appleproraw": "",
            "name": "Apple ProRAW",
            "id": "fdd000594",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000594.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000594.xml"
            }
        },
        {
            "appleproraw": "",
            "name": "Apple ProRAW",
            "id": "fdd000594",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000594.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000594.xml"
            }
        },
        {
            "appleproraw": "",
            "name": "Apple ProRAW",
            "id": "fdd000594",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000594.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000594.xml"
            }
        },
        {
            "appleproraw": "",
            "name": "Apple ProRAW",
            "id": "fdd000594",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000594.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000594.xml"
            }
        },
        {
            "appleproraw": "",
            "name": "Apple ProRAW",
            "id": "fdd000594",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000594.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000594.xml"
            }
        },
        {
            "appleproraw": "",
            "name": "Apple ProRAW",
            "id": "fdd000594",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000594.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000594.xml"
            }
        },
        {
            "appleproraw": "",
            "name": "Apple ProRAW",
            "id": "fdd000594",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000594.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000594.xml"
            }
        },
        {
            "appleproraw": "",
            "name": "Apple ProRAW",
            "id": "fdd000594",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000594.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000594.xml"
            }
        },
        {
            "silverlightapplicationpackage": "",
            "name": "Silverlight Application Package",
            "id": "fdd000595",
            "filenamepattern": [
                {
                    "value": "*.xap",
                    "url": "https://www.digipres.org/formats/extensions/#*.xap"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-silverlight-app",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-silverlight-app"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000595.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000595.xml"
            }
        },
        {
            "silverlightapplicationpackage": "",
            "name": "Silverlight Application Package",
            "id": "fdd000595",
            "filenamepattern": [
                {
                    "value": "*.xap",
                    "url": "https://www.digipres.org/formats/extensions/#*.xap"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-silverlight-app",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-silverlight-app"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000595.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000595.xml"
            }
        },
        {
            "silverlightapplicationpackage": "",
            "name": "Silverlight Application Package",
            "id": "fdd000595",
            "filenamepattern": [
                {
                    "value": "*.xap",
                    "url": "https://www.digipres.org/formats/extensions/#*.xap"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-silverlight-app",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-silverlight-app"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000595.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000595.xml"
            }
        },
        {
            "silverlightapplicationpackage": "",
            "name": "Silverlight Application Package",
            "id": "fdd000595",
            "filenamepattern": [
                {
                    "value": "*.xap",
                    "url": "https://www.digipres.org/formats/extensions/#*.xap"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-silverlight-app",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-silverlight-app"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000595.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000595.xml"
            }
        },
        {
            "silverlightapplicationpackage": "",
            "name": "Silverlight Application Package",
            "id": "fdd000595",
            "filenamepattern": [
                {
                    "value": "*.xap",
                    "url": "https://www.digipres.org/formats/extensions/#*.xap"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-silverlight-app",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-silverlight-app"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000595.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000595.xml"
            }
        },
        {
            "silverlightapplicationpackage": "",
            "name": "Silverlight Application Package",
            "id": "fdd000595",
            "filenamepattern": [
                {
                    "value": "*.xap",
                    "url": "https://www.digipres.org/formats/extensions/#*.xap"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-silverlight-app",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-silverlight-app"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000595.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000595.xml"
            }
        },
        {
            "silverlightapplicationpackage": "",
            "name": "Silverlight Application Package",
            "id": "fdd000595",
            "filenamepattern": [
                {
                    "value": "*.xap",
                    "url": "https://www.digipres.org/formats/extensions/#*.xap"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-silverlight-app",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-silverlight-app"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000595.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000595.xml"
            }
        },
        {
            "silverlightapplicationpackage": "",
            "name": "Silverlight Application Package",
            "id": "fdd000595",
            "filenamepattern": [
                {
                    "value": "*.xap",
                    "url": "https://www.digipres.org/formats/extensions/#*.xap"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-silverlight-app",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-silverlight-app"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000595.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000595.xml"
            }
        },
        {
            "microsoftwindowsshortcutfile": "",
            "name": "Microsoft Windows Shortcut File",
            "id": "fdd000596",
            "filenamepattern": [
                {
                    "value": "*.lnk",
                    "url": "https://www.digipres.org/formats/extensions/#*.lnk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-ms-shortcut",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ms-shortcut"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000596.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000596.xml"
            }
        },
        {
            "microsoftwindowsshortcutfile": "",
            "name": "Microsoft Windows Shortcut File",
            "id": "fdd000596",
            "filenamepattern": [
                {
                    "value": "*.lnk",
                    "url": "https://www.digipres.org/formats/extensions/#*.lnk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-ms-shortcut",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ms-shortcut"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000596.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000596.xml"
            }
        },
        {
            "microsoftwindowsshortcutfile": "",
            "name": "Microsoft Windows Shortcut File",
            "id": "fdd000596",
            "filenamepattern": [
                {
                    "value": "*.lnk",
                    "url": "https://www.digipres.org/formats/extensions/#*.lnk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-ms-shortcut",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ms-shortcut"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000596.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000596.xml"
            }
        },
        {
            "microsoftwindowsshortcutfile": "",
            "name": "Microsoft Windows Shortcut File",
            "id": "fdd000596",
            "filenamepattern": [
                {
                    "value": "*.lnk",
                    "url": "https://www.digipres.org/formats/extensions/#*.lnk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-ms-shortcut",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ms-shortcut"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000596.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000596.xml"
            }
        },
        {
            "microsoftwindowsshortcutfile": "",
            "name": "Microsoft Windows Shortcut File",
            "id": "fdd000596",
            "filenamepattern": [
                {
                    "value": "*.lnk",
                    "url": "https://www.digipres.org/formats/extensions/#*.lnk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-ms-shortcut",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ms-shortcut"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000596.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000596.xml"
            }
        },
        {
            "microsoftwindowsshortcutfile": "",
            "name": "Microsoft Windows Shortcut File",
            "id": "fdd000596",
            "filenamepattern": [
                {
                    "value": "*.lnk",
                    "url": "https://www.digipres.org/formats/extensions/#*.lnk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-ms-shortcut",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ms-shortcut"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000596.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000596.xml"
            }
        },
        {
            "microsoftwindowsshortcutfile": "",
            "name": "Microsoft Windows Shortcut File",
            "id": "fdd000596",
            "filenamepattern": [
                {
                    "value": "*.lnk",
                    "url": "https://www.digipres.org/formats/extensions/#*.lnk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-ms-shortcut",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ms-shortcut"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000596.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000596.xml"
            }
        },
        {
            "microsoftwindowsshortcutfile": "",
            "name": "Microsoft Windows Shortcut File",
            "id": "fdd000596",
            "filenamepattern": [
                {
                    "value": "*.lnk",
                    "url": "https://www.digipres.org/formats/extensions/#*.lnk"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-ms-shortcut",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-ms-shortcut"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000596.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000596.xml"
            }
        },
        {
            "desktopservicesstore": "",
            "name": "Desktop Services Store",
            "id": "fdd000597",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000597.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000597.xml"
            }
        },
        {
            "desktopservicesstore": "",
            "name": "Desktop Services Store",
            "id": "fdd000597",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000597.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000597.xml"
            }
        },
        {
            "desktopservicesstore": "",
            "name": "Desktop Services Store",
            "id": "fdd000597",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000597.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000597.xml"
            }
        },
        {
            "desktopservicesstore": "",
            "name": "Desktop Services Store",
            "id": "fdd000597",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000597.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000597.xml"
            }
        },
        {
            "desktopservicesstore": "",
            "name": "Desktop Services Store",
            "id": "fdd000597",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000597.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000597.xml"
            }
        },
        {
            "desktopservicesstore": "",
            "name": "Desktop Services Store",
            "id": "fdd000597",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000597.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000597.xml"
            }
        },
        {
            "desktopservicesstore": "",
            "name": "Desktop Services Store",
            "id": "fdd000597",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000597.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000597.xml"
            }
        },
        {
            "desktopservicesstore": "",
            "name": "Desktop Services Store",
            "id": "fdd000597",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "application/octet-stream",
                    "url": "https://www.digipres.org/formats/mime-types/#application/octet-stream"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000597.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000597.xml"
            }
        },
        {
            "javavirtualmachineclassfileformat": "",
            "name": "Java Virtual Machine Class File Format",
            "id": "fdd000598",
            "filenamepattern": [
                {
                    "value": "*.class",
                    "url": "https://www.digipres.org/formats/extensions/#*.class"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-httpd-java",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-httpd-java"
                },
                {
                    "value": "application/java-vm",
                    "url": "https://www.digipres.org/formats/mime-types/#application/java-vm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000598.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000598.xml"
            }
        },
        {
            "javavirtualmachineclassfileformat": "",
            "name": "Java Virtual Machine Class File Format",
            "id": "fdd000598",
            "filenamepattern": [
                {
                    "value": "*.class",
                    "url": "https://www.digipres.org/formats/extensions/#*.class"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-httpd-java",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-httpd-java"
                },
                {
                    "value": "application/java-vm",
                    "url": "https://www.digipres.org/formats/mime-types/#application/java-vm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000598.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000598.xml"
            }
        },
        {
            "javavirtualmachineclassfileformat": "",
            "name": "Java Virtual Machine Class File Format",
            "id": "fdd000598",
            "filenamepattern": [
                {
                    "value": "*.class",
                    "url": "https://www.digipres.org/formats/extensions/#*.class"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-httpd-java",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-httpd-java"
                },
                {
                    "value": "application/java-vm",
                    "url": "https://www.digipres.org/formats/mime-types/#application/java-vm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000598.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000598.xml"
            }
        },
        {
            "javavirtualmachineclassfileformat": "",
            "name": "Java Virtual Machine Class File Format",
            "id": "fdd000598",
            "filenamepattern": [
                {
                    "value": "*.class",
                    "url": "https://www.digipres.org/formats/extensions/#*.class"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-httpd-java",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-httpd-java"
                },
                {
                    "value": "application/java-vm",
                    "url": "https://www.digipres.org/formats/mime-types/#application/java-vm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000598.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000598.xml"
            }
        },
        {
            "javavirtualmachineclassfileformat": "",
            "name": "Java Virtual Machine Class File Format",
            "id": "fdd000598",
            "filenamepattern": [
                {
                    "value": "*.class",
                    "url": "https://www.digipres.org/formats/extensions/#*.class"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-httpd-java",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-httpd-java"
                },
                {
                    "value": "application/java-vm",
                    "url": "https://www.digipres.org/formats/mime-types/#application/java-vm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000598.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000598.xml"
            }
        },
        {
            "javavirtualmachineclassfileformat": "",
            "name": "Java Virtual Machine Class File Format",
            "id": "fdd000598",
            "filenamepattern": [
                {
                    "value": "*.class",
                    "url": "https://www.digipres.org/formats/extensions/#*.class"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-httpd-java",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-httpd-java"
                },
                {
                    "value": "application/java-vm",
                    "url": "https://www.digipres.org/formats/mime-types/#application/java-vm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000598.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000598.xml"
            }
        },
        {
            "javavirtualmachineclassfileformat": "",
            "name": "Java Virtual Machine Class File Format",
            "id": "fdd000598",
            "filenamepattern": [
                {
                    "value": "*.class",
                    "url": "https://www.digipres.org/formats/extensions/#*.class"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-httpd-java",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-httpd-java"
                },
                {
                    "value": "application/java-vm",
                    "url": "https://www.digipres.org/formats/mime-types/#application/java-vm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000598.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000598.xml"
            }
        },
        {
            "javavirtualmachineclassfileformat": "",
            "name": "Java Virtual Machine Class File Format",
            "id": "fdd000598",
            "filenamepattern": [
                {
                    "value": "*.class",
                    "url": "https://www.digipres.org/formats/extensions/#*.class"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-httpd-java",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-httpd-java"
                },
                {
                    "value": "application/java-vm",
                    "url": "https://www.digipres.org/formats/mime-types/#application/java-vm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000598.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000598.xml"
            }
        },
        {
            "gzip": "",
            "name": "GZIP",
            "id": "fdd000599",
            "filenamepattern": [
                {
                    "value": "*.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.gz"
                },
                {
                    "value": "*.z",
                    "url": "https://www.digipres.org/formats/extensions/#*.z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/gzip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/gzip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000599.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000599.xml"
            }
        },
        {
            "gzip": "",
            "name": "GZIP",
            "id": "fdd000599",
            "filenamepattern": [
                {
                    "value": "*.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.gz"
                },
                {
                    "value": "*.z",
                    "url": "https://www.digipres.org/formats/extensions/#*.z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/gzip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/gzip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000599.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000599.xml"
            }
        },
        {
            "gzip": "",
            "name": "GZIP",
            "id": "fdd000599",
            "filenamepattern": [
                {
                    "value": "*.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.gz"
                },
                {
                    "value": "*.z",
                    "url": "https://www.digipres.org/formats/extensions/#*.z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/gzip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/gzip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000599.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000599.xml"
            }
        },
        {
            "gzip": "",
            "name": "GZIP",
            "id": "fdd000599",
            "filenamepattern": [
                {
                    "value": "*.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.gz"
                },
                {
                    "value": "*.z",
                    "url": "https://www.digipres.org/formats/extensions/#*.z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/gzip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/gzip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000599.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000599.xml"
            }
        },
        {
            "gzip": "",
            "name": "GZIP",
            "id": "fdd000599",
            "filenamepattern": [
                {
                    "value": "*.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.gz"
                },
                {
                    "value": "*.z",
                    "url": "https://www.digipres.org/formats/extensions/#*.z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/gzip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/gzip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000599.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000599.xml"
            }
        },
        {
            "gzip": "",
            "name": "GZIP",
            "id": "fdd000599",
            "filenamepattern": [
                {
                    "value": "*.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.gz"
                },
                {
                    "value": "*.z",
                    "url": "https://www.digipres.org/formats/extensions/#*.z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/gzip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/gzip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000599.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000599.xml"
            }
        },
        {
            "gzip": "",
            "name": "GZIP",
            "id": "fdd000599",
            "filenamepattern": [
                {
                    "value": "*.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.gz"
                },
                {
                    "value": "*.z",
                    "url": "https://www.digipres.org/formats/extensions/#*.z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/gzip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/gzip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000599.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000599.xml"
            }
        },
        {
            "gzip": "",
            "name": "GZIP",
            "id": "fdd000599",
            "filenamepattern": [
                {
                    "value": "*.gz",
                    "url": "https://www.digipres.org/formats/extensions/#*.gz"
                },
                {
                    "value": "*.z",
                    "url": "https://www.digipres.org/formats/extensions/#*.z"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/gzip",
                    "url": "https://www.digipres.org/formats/mime-types/#application/gzip"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000599.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000599.xml"
            }
        },
        {
            "bzip2": "",
            "name": "bzip2",
            "id": "fdd000600",
            "filenamepattern": [
                {
                    "value": "*.bz2",
                    "url": "https://www.digipres.org/formats/extensions/#*.bz2"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-bzip2",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-bzip2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000600.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000600.xml"
            }
        },
        {
            "bzip2": "",
            "name": "bzip2",
            "id": "fdd000600",
            "filenamepattern": [
                {
                    "value": "*.bz2",
                    "url": "https://www.digipres.org/formats/extensions/#*.bz2"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-bzip2",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-bzip2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000600.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000600.xml"
            }
        },
        {
            "bzip2": "",
            "name": "bzip2",
            "id": "fdd000600",
            "filenamepattern": [
                {
                    "value": "*.bz2",
                    "url": "https://www.digipres.org/formats/extensions/#*.bz2"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-bzip2",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-bzip2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000600.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000600.xml"
            }
        },
        {
            "bzip2": "",
            "name": "bzip2",
            "id": "fdd000600",
            "filenamepattern": [
                {
                    "value": "*.bz2",
                    "url": "https://www.digipres.org/formats/extensions/#*.bz2"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-bzip2",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-bzip2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000600.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000600.xml"
            }
        },
        {
            "bzip2": "",
            "name": "bzip2",
            "id": "fdd000600",
            "filenamepattern": [
                {
                    "value": "*.bz2",
                    "url": "https://www.digipres.org/formats/extensions/#*.bz2"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-bzip2",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-bzip2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000600.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000600.xml"
            }
        },
        {
            "bzip2": "",
            "name": "bzip2",
            "id": "fdd000600",
            "filenamepattern": [
                {
                    "value": "*.bz2",
                    "url": "https://www.digipres.org/formats/extensions/#*.bz2"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-bzip2",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-bzip2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000600.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000600.xml"
            }
        },
        {
            "bzip2": "",
            "name": "bzip2",
            "id": "fdd000600",
            "filenamepattern": [
                {
                    "value": "*.bz2",
                    "url": "https://www.digipres.org/formats/extensions/#*.bz2"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-bzip2",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-bzip2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000600.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000600.xml"
            }
        },
        {
            "bzip2": "",
            "name": "bzip2",
            "id": "fdd000600",
            "filenamepattern": [
                {
                    "value": "*.bz2",
                    "url": "https://www.digipres.org/formats/extensions/#*.bz2"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-bzip2",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-bzip2"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000600.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000600.xml"
            }
        },
        {
            "3dmodelfileformatfamily": "",
            "name": "3D Model File Format Family",
            "id": "fdd000601",
            "filenamepattern": [
                {
                    "value": "*.3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.3dm",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.3dm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000601.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000601.xml"
            }
        },
        {
            "3dmodelfileformatfamily": "",
            "name": "3D Model File Format Family",
            "id": "fdd000601",
            "filenamepattern": [
                {
                    "value": "*.3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.3dm",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.3dm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000601.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000601.xml"
            }
        },
        {
            "3dmodelfileformatfamily": "",
            "name": "3D Model File Format Family",
            "id": "fdd000601",
            "filenamepattern": [
                {
                    "value": "*.3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.3dm",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.3dm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000601.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000601.xml"
            }
        },
        {
            "3dmodelfileformatfamily": "",
            "name": "3D Model File Format Family",
            "id": "fdd000601",
            "filenamepattern": [
                {
                    "value": "*.3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.3dm",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.3dm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000601.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000601.xml"
            }
        },
        {
            "3dmodelfileformatfamily": "",
            "name": "3D Model File Format Family",
            "id": "fdd000601",
            "filenamepattern": [
                {
                    "value": "*.3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.3dm",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.3dm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000601.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000601.xml"
            }
        },
        {
            "3dmodelfileformatfamily": "",
            "name": "3D Model File Format Family",
            "id": "fdd000601",
            "filenamepattern": [
                {
                    "value": "*.3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.3dm",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.3dm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000601.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000601.xml"
            }
        },
        {
            "3dmodelfileformatfamily": "",
            "name": "3D Model File Format Family",
            "id": "fdd000601",
            "filenamepattern": [
                {
                    "value": "*.3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.3dm",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.3dm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000601.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000601.xml"
            }
        },
        {
            "3dmodelfileformatfamily": "",
            "name": "3D Model File Format Family",
            "id": "fdd000601",
            "filenamepattern": [
                {
                    "value": "*.3dm",
                    "url": "https://www.digipres.org/formats/extensions/#*.3dm"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vnd.3dm",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vnd.3dm"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000601.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000601.xml"
            }
        },
        {
            "virtualrealitymodelinglanguagefamily": "",
            "name": "Virtual Reality Modeling Language Family",
            "id": "fdd000602",
            "filenamepattern": [
                {
                    "value": "*.wrl",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrl"
                },
                {
                    "value": "*.wrz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vrml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000602.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000602.xml"
            }
        },
        {
            "virtualrealitymodelinglanguagefamily": "",
            "name": "Virtual Reality Modeling Language Family",
            "id": "fdd000602",
            "filenamepattern": [
                {
                    "value": "*.wrl",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrl"
                },
                {
                    "value": "*.wrz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vrml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000602.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000602.xml"
            }
        },
        {
            "virtualrealitymodelinglanguagefamily": "",
            "name": "Virtual Reality Modeling Language Family",
            "id": "fdd000602",
            "filenamepattern": [
                {
                    "value": "*.wrl",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrl"
                },
                {
                    "value": "*.wrz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vrml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000602.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000602.xml"
            }
        },
        {
            "virtualrealitymodelinglanguagefamily": "",
            "name": "Virtual Reality Modeling Language Family",
            "id": "fdd000602",
            "filenamepattern": [
                {
                    "value": "*.wrl",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrl"
                },
                {
                    "value": "*.wrz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vrml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000602.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000602.xml"
            }
        },
        {
            "virtualrealitymodelinglanguagefamily": "",
            "name": "Virtual Reality Modeling Language Family",
            "id": "fdd000602",
            "filenamepattern": [
                {
                    "value": "*.wrl",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrl"
                },
                {
                    "value": "*.wrz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vrml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000602.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000602.xml"
            }
        },
        {
            "virtualrealitymodelinglanguagefamily": "",
            "name": "Virtual Reality Modeling Language Family",
            "id": "fdd000602",
            "filenamepattern": [
                {
                    "value": "*.wrl",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrl"
                },
                {
                    "value": "*.wrz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vrml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000602.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000602.xml"
            }
        },
        {
            "virtualrealitymodelinglanguagefamily": "",
            "name": "Virtual Reality Modeling Language Family",
            "id": "fdd000602",
            "filenamepattern": [
                {
                    "value": "*.wrl",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrl"
                },
                {
                    "value": "*.wrz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vrml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000602.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000602.xml"
            }
        },
        {
            "virtualrealitymodelinglanguagefamily": "",
            "name": "Virtual Reality Modeling Language Family",
            "id": "fdd000602",
            "filenamepattern": [
                {
                    "value": "*.wrl",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrl"
                },
                {
                    "value": "*.wrz",
                    "url": "https://www.digipres.org/formats/extensions/#*.wrz"
                }
            ],
            "mimetypes": [
                {
                    "value": "model/vrml",
                    "url": "https://www.digipres.org/formats/mime-types/#model/vrml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000602.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000602.xml"
            }
        },
        {
            "microstationdgnfamily": "",
            "name": "MicroStation DGN Family",
            "id": "fdd000603",
            "filenamepattern": [
                {
                    "value": "*.dgn",
                    "url": "https://www.digipres.org/formats/extensions/#*.dgn"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000603.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000603.xml"
            }
        },
        {
            "microstationdgnfamily": "",
            "name": "MicroStation DGN Family",
            "id": "fdd000603",
            "filenamepattern": [
                {
                    "value": "*.dgn",
                    "url": "https://www.digipres.org/formats/extensions/#*.dgn"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000603.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000603.xml"
            }
        },
        {
            "microstationdgnfamily": "",
            "name": "MicroStation DGN Family",
            "id": "fdd000603",
            "filenamepattern": [
                {
                    "value": "*.dgn",
                    "url": "https://www.digipres.org/formats/extensions/#*.dgn"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000603.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000603.xml"
            }
        },
        {
            "microstationdgnfamily": "",
            "name": "MicroStation DGN Family",
            "id": "fdd000603",
            "filenamepattern": [
                {
                    "value": "*.dgn",
                    "url": "https://www.digipres.org/formats/extensions/#*.dgn"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000603.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000603.xml"
            }
        },
        {
            "microstationdgnfamily": "",
            "name": "MicroStation DGN Family",
            "id": "fdd000603",
            "filenamepattern": [
                {
                    "value": "*.dgn",
                    "url": "https://www.digipres.org/formats/extensions/#*.dgn"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000603.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000603.xml"
            }
        },
        {
            "microstationdgnfamily": "",
            "name": "MicroStation DGN Family",
            "id": "fdd000603",
            "filenamepattern": [
                {
                    "value": "*.dgn",
                    "url": "https://www.digipres.org/formats/extensions/#*.dgn"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000603.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000603.xml"
            }
        },
        {
            "microstationdgnfamily": "",
            "name": "MicroStation DGN Family",
            "id": "fdd000603",
            "filenamepattern": [
                {
                    "value": "*.dgn",
                    "url": "https://www.digipres.org/formats/extensions/#*.dgn"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000603.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000603.xml"
            }
        },
        {
            "microstationdgnfamily": "",
            "name": "MicroStation DGN Family",
            "id": "fdd000603",
            "filenamepattern": [
                {
                    "value": "*.dgn",
                    "url": "https://www.digipres.org/formats/extensions/#*.dgn"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000603.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000603.xml"
            }
        },
        {
            "mayaasciiscenefileformat": "",
            "name": "Maya ASCII Scene File Format",
            "id": "fdd000604",
            "filenamepattern": [
                {
                    "value": "*.ma",
                    "url": "https://www.digipres.org/formats/extensions/#*.ma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000604.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000604.xml"
            }
        },
        {
            "mayaasciiscenefileformat": "",
            "name": "Maya ASCII Scene File Format",
            "id": "fdd000604",
            "filenamepattern": [
                {
                    "value": "*.ma",
                    "url": "https://www.digipres.org/formats/extensions/#*.ma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000604.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000604.xml"
            }
        },
        {
            "mayaasciiscenefileformat": "",
            "name": "Maya ASCII Scene File Format",
            "id": "fdd000604",
            "filenamepattern": [
                {
                    "value": "*.ma",
                    "url": "https://www.digipres.org/formats/extensions/#*.ma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000604.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000604.xml"
            }
        },
        {
            "mayaasciiscenefileformat": "",
            "name": "Maya ASCII Scene File Format",
            "id": "fdd000604",
            "filenamepattern": [
                {
                    "value": "*.ma",
                    "url": "https://www.digipres.org/formats/extensions/#*.ma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000604.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000604.xml"
            }
        },
        {
            "mayaasciiscenefileformat": "",
            "name": "Maya ASCII Scene File Format",
            "id": "fdd000604",
            "filenamepattern": [
                {
                    "value": "*.ma",
                    "url": "https://www.digipres.org/formats/extensions/#*.ma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000604.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000604.xml"
            }
        },
        {
            "mayaasciiscenefileformat": "",
            "name": "Maya ASCII Scene File Format",
            "id": "fdd000604",
            "filenamepattern": [
                {
                    "value": "*.ma",
                    "url": "https://www.digipres.org/formats/extensions/#*.ma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000604.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000604.xml"
            }
        },
        {
            "mayaasciiscenefileformat": "",
            "name": "Maya ASCII Scene File Format",
            "id": "fdd000604",
            "filenamepattern": [
                {
                    "value": "*.ma",
                    "url": "https://www.digipres.org/formats/extensions/#*.ma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000604.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000604.xml"
            }
        },
        {
            "mayaasciiscenefileformat": "",
            "name": "Maya ASCII Scene File Format",
            "id": "fdd000604",
            "filenamepattern": [
                {
                    "value": "*.ma",
                    "url": "https://www.digipres.org/formats/extensions/#*.ma"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000604.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000604.xml"
            }
        },
        {
            "mayabinaryscenefileformat": "",
            "name": "Maya Binary Scene File Format",
            "id": "fdd000605",
            "filenamepattern": [
                {
                    "value": "*.mb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000605.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000605.xml"
            }
        },
        {
            "mayabinaryscenefileformat": "",
            "name": "Maya Binary Scene File Format",
            "id": "fdd000605",
            "filenamepattern": [
                {
                    "value": "*.mb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000605.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000605.xml"
            }
        },
        {
            "mayabinaryscenefileformat": "",
            "name": "Maya Binary Scene File Format",
            "id": "fdd000605",
            "filenamepattern": [
                {
                    "value": "*.mb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000605.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000605.xml"
            }
        },
        {
            "mayabinaryscenefileformat": "",
            "name": "Maya Binary Scene File Format",
            "id": "fdd000605",
            "filenamepattern": [
                {
                    "value": "*.mb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000605.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000605.xml"
            }
        },
        {
            "mayabinaryscenefileformat": "",
            "name": "Maya Binary Scene File Format",
            "id": "fdd000605",
            "filenamepattern": [
                {
                    "value": "*.mb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000605.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000605.xml"
            }
        },
        {
            "mayabinaryscenefileformat": "",
            "name": "Maya Binary Scene File Format",
            "id": "fdd000605",
            "filenamepattern": [
                {
                    "value": "*.mb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000605.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000605.xml"
            }
        },
        {
            "mayabinaryscenefileformat": "",
            "name": "Maya Binary Scene File Format",
            "id": "fdd000605",
            "filenamepattern": [
                {
                    "value": "*.mb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000605.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000605.xml"
            }
        },
        {
            "mayabinaryscenefileformat": "",
            "name": "Maya Binary Scene File Format",
            "id": "fdd000605",
            "filenamepattern": [
                {
                    "value": "*.mb",
                    "url": "https://www.digipres.org/formats/extensions/#*.mb"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000605.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000605.xml"
            }
        },
        {
            "unifiedspeechandaudiocoding": "",
            "name": "Unified Speech and Audio Coding",
            "id": "fdd000606",
            "filenamepattern": [
                {
                    "value": "*.xhe",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhe"
                },
                {
                    "value": "*.loas",
                    "url": "https://www.digipres.org/formats/extensions/#*.loas"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/usac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/usac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000606.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000606.xml"
            }
        },
        {
            "unifiedspeechandaudiocoding": "",
            "name": "Unified Speech and Audio Coding",
            "id": "fdd000606",
            "filenamepattern": [
                {
                    "value": "*.xhe",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhe"
                },
                {
                    "value": "*.loas",
                    "url": "https://www.digipres.org/formats/extensions/#*.loas"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/usac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/usac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000606.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000606.xml"
            }
        },
        {
            "unifiedspeechandaudiocoding": "",
            "name": "Unified Speech and Audio Coding",
            "id": "fdd000606",
            "filenamepattern": [
                {
                    "value": "*.xhe",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhe"
                },
                {
                    "value": "*.loas",
                    "url": "https://www.digipres.org/formats/extensions/#*.loas"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/usac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/usac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000606.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000606.xml"
            }
        },
        {
            "unifiedspeechandaudiocoding": "",
            "name": "Unified Speech and Audio Coding",
            "id": "fdd000606",
            "filenamepattern": [
                {
                    "value": "*.xhe",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhe"
                },
                {
                    "value": "*.loas",
                    "url": "https://www.digipres.org/formats/extensions/#*.loas"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/usac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/usac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000606.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000606.xml"
            }
        },
        {
            "unifiedspeechandaudiocoding": "",
            "name": "Unified Speech and Audio Coding",
            "id": "fdd000606",
            "filenamepattern": [
                {
                    "value": "*.xhe",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhe"
                },
                {
                    "value": "*.loas",
                    "url": "https://www.digipres.org/formats/extensions/#*.loas"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/usac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/usac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000606.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000606.xml"
            }
        },
        {
            "unifiedspeechandaudiocoding": "",
            "name": "Unified Speech and Audio Coding",
            "id": "fdd000606",
            "filenamepattern": [
                {
                    "value": "*.xhe",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhe"
                },
                {
                    "value": "*.loas",
                    "url": "https://www.digipres.org/formats/extensions/#*.loas"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/usac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/usac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000606.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000606.xml"
            }
        },
        {
            "unifiedspeechandaudiocoding": "",
            "name": "Unified Speech and Audio Coding",
            "id": "fdd000606",
            "filenamepattern": [
                {
                    "value": "*.xhe",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhe"
                },
                {
                    "value": "*.loas",
                    "url": "https://www.digipres.org/formats/extensions/#*.loas"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/usac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/usac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000606.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000606.xml"
            }
        },
        {
            "unifiedspeechandaudiocoding": "",
            "name": "Unified Speech and Audio Coding",
            "id": "fdd000606",
            "filenamepattern": [
                {
                    "value": "*.xhe",
                    "url": "https://www.digipres.org/formats/extensions/#*.xhe"
                },
                {
                    "value": "*.loas",
                    "url": "https://www.digipres.org/formats/extensions/#*.loas"
                }
            ],
            "mimetypes": [
                {
                    "value": "audio/usac",
                    "url": "https://www.digipres.org/formats/mime-types/#audio/usac"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000606.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000606.xml"
            }
        },
        {
            "audiodefinitionmodel": "",
            "name": "Audio Definition Model",
            "id": "fdd000607",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000607.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000607.xml"
            }
        },
        {
            "audiodefinitionmodel": "",
            "name": "Audio Definition Model",
            "id": "fdd000607",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000607.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000607.xml"
            }
        },
        {
            "audiodefinitionmodel": "",
            "name": "Audio Definition Model",
            "id": "fdd000607",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000607.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000607.xml"
            }
        },
        {
            "audiodefinitionmodel": "",
            "name": "Audio Definition Model",
            "id": "fdd000607",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000607.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000607.xml"
            }
        },
        {
            "audiodefinitionmodel": "",
            "name": "Audio Definition Model",
            "id": "fdd000607",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000607.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000607.xml"
            }
        },
        {
            "audiodefinitionmodel": "",
            "name": "Audio Definition Model",
            "id": "fdd000607",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000607.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000607.xml"
            }
        },
        {
            "audiodefinitionmodel": "",
            "name": "Audio Definition Model",
            "id": "fdd000607",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000607.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000607.xml"
            }
        },
        {
            "audiodefinitionmodel": "",
            "name": "Audio Definition Model",
            "id": "fdd000607",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000607.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000607.xml"
            }
        },
        {
            "nullsoftstreamingvideo": "",
            "name": "Nullsoft Streaming Video",
            "id": "fdd000608",
            "filenamepattern": [
                {
                    "value": "*.NSV",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-winamp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-winamp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000608.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000608.xml"
            }
        },
        {
            "nullsoftstreamingvideo": "",
            "name": "Nullsoft Streaming Video",
            "id": "fdd000608",
            "filenamepattern": [
                {
                    "value": "*.NSV",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-winamp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-winamp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000608.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000608.xml"
            }
        },
        {
            "nullsoftstreamingvideo": "",
            "name": "Nullsoft Streaming Video",
            "id": "fdd000608",
            "filenamepattern": [
                {
                    "value": "*.NSV",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-winamp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-winamp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000608.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000608.xml"
            }
        },
        {
            "nullsoftstreamingvideo": "",
            "name": "Nullsoft Streaming Video",
            "id": "fdd000608",
            "filenamepattern": [
                {
                    "value": "*.NSV",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-winamp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-winamp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000608.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000608.xml"
            }
        },
        {
            "nullsoftstreamingvideo": "",
            "name": "Nullsoft Streaming Video",
            "id": "fdd000608",
            "filenamepattern": [
                {
                    "value": "*.NSV",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-winamp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-winamp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000608.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000608.xml"
            }
        },
        {
            "nullsoftstreamingvideo": "",
            "name": "Nullsoft Streaming Video",
            "id": "fdd000608",
            "filenamepattern": [
                {
                    "value": "*.NSV",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-winamp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-winamp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000608.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000608.xml"
            }
        },
        {
            "nullsoftstreamingvideo": "",
            "name": "Nullsoft Streaming Video",
            "id": "fdd000608",
            "filenamepattern": [
                {
                    "value": "*.NSV",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-winamp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-winamp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000608.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000608.xml"
            }
        },
        {
            "nullsoftstreamingvideo": "",
            "name": "Nullsoft Streaming Video",
            "id": "fdd000608",
            "filenamepattern": [
                {
                    "value": "*.NSV",
                    "url": "https://www.digipres.org/formats/extensions/#*.nsv"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-winamp",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-winamp"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000608.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000608.xml"
            }
        },
        {
            "sibeliusmusicnotationformat": "",
            "name": "Sibelius Music Notation Format",
            "id": "fdd000609",
            "filenamepattern": [
                {
                    "value": "*.si7",
                    "url": "https://www.digipres.org/formats/extensions/#*.si7"
                },
                {
                    "value": "*.sib",
                    "url": "https://www.digipres.org/formats/extensions/#*.sib"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sibelius-score",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sibelius-score"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000609.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000609.xml"
            }
        },
        {
            "sibeliusmusicnotationformat": "",
            "name": "Sibelius Music Notation Format",
            "id": "fdd000609",
            "filenamepattern": [
                {
                    "value": "*.si7",
                    "url": "https://www.digipres.org/formats/extensions/#*.si7"
                },
                {
                    "value": "*.sib",
                    "url": "https://www.digipres.org/formats/extensions/#*.sib"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sibelius-score",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sibelius-score"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000609.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000609.xml"
            }
        },
        {
            "sibeliusmusicnotationformat": "",
            "name": "Sibelius Music Notation Format",
            "id": "fdd000609",
            "filenamepattern": [
                {
                    "value": "*.si7",
                    "url": "https://www.digipres.org/formats/extensions/#*.si7"
                },
                {
                    "value": "*.sib",
                    "url": "https://www.digipres.org/formats/extensions/#*.sib"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sibelius-score",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sibelius-score"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000609.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000609.xml"
            }
        },
        {
            "sibeliusmusicnotationformat": "",
            "name": "Sibelius Music Notation Format",
            "id": "fdd000609",
            "filenamepattern": [
                {
                    "value": "*.si7",
                    "url": "https://www.digipres.org/formats/extensions/#*.si7"
                },
                {
                    "value": "*.sib",
                    "url": "https://www.digipres.org/formats/extensions/#*.sib"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sibelius-score",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sibelius-score"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000609.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000609.xml"
            }
        },
        {
            "sibeliusmusicnotationformat": "",
            "name": "Sibelius Music Notation Format",
            "id": "fdd000609",
            "filenamepattern": [
                {
                    "value": "*.si7",
                    "url": "https://www.digipres.org/formats/extensions/#*.si7"
                },
                {
                    "value": "*.sib",
                    "url": "https://www.digipres.org/formats/extensions/#*.sib"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sibelius-score",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sibelius-score"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000609.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000609.xml"
            }
        },
        {
            "sibeliusmusicnotationformat": "",
            "name": "Sibelius Music Notation Format",
            "id": "fdd000609",
            "filenamepattern": [
                {
                    "value": "*.si7",
                    "url": "https://www.digipres.org/formats/extensions/#*.si7"
                },
                {
                    "value": "*.sib",
                    "url": "https://www.digipres.org/formats/extensions/#*.sib"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sibelius-score",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sibelius-score"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000609.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000609.xml"
            }
        },
        {
            "sibeliusmusicnotationformat": "",
            "name": "Sibelius Music Notation Format",
            "id": "fdd000609",
            "filenamepattern": [
                {
                    "value": "*.si7",
                    "url": "https://www.digipres.org/formats/extensions/#*.si7"
                },
                {
                    "value": "*.sib",
                    "url": "https://www.digipres.org/formats/extensions/#*.sib"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sibelius-score",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sibelius-score"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000609.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000609.xml"
            }
        },
        {
            "sibeliusmusicnotationformat": "",
            "name": "Sibelius Music Notation Format",
            "id": "fdd000609",
            "filenamepattern": [
                {
                    "value": "*.si7",
                    "url": "https://www.digipres.org/formats/extensions/#*.si7"
                },
                {
                    "value": "*.sib",
                    "url": "https://www.digipres.org/formats/extensions/#*.sib"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-sibelius-score",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-sibelius-score"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000609.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000609.xml"
            }
        },
        {
            "kryofluxstreamfile": "",
            "name": "KryoFlux Stream File",
            "id": "fdd000610",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000610.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000610.xml"
            }
        },
        {
            "kryofluxstreamfile": "",
            "name": "KryoFlux Stream File",
            "id": "fdd000610",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000610.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000610.xml"
            }
        },
        {
            "kryofluxstreamfile": "",
            "name": "KryoFlux Stream File",
            "id": "fdd000610",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000610.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000610.xml"
            }
        },
        {
            "kryofluxstreamfile": "",
            "name": "KryoFlux Stream File",
            "id": "fdd000610",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000610.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000610.xml"
            }
        },
        {
            "kryofluxstreamfile": "",
            "name": "KryoFlux Stream File",
            "id": "fdd000610",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000610.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000610.xml"
            }
        },
        {
            "kryofluxstreamfile": "",
            "name": "KryoFlux Stream File",
            "id": "fdd000610",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000610.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000610.xml"
            }
        },
        {
            "kryofluxstreamfile": "",
            "name": "KryoFlux Stream File",
            "id": "fdd000610",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000610.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000610.xml"
            }
        },
        {
            "kryofluxstreamfile": "",
            "name": "KryoFlux Stream File",
            "id": "fdd000610",
            "filenamepattern": [
                {
                    "value": "*.raw",
                    "url": "https://www.digipres.org/formats/extensions/#*.raw"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000610.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000610.xml"
            }
        },
        {
            "digitalforensicsxml": "",
            "name": "Digital Forensics XML",
            "id": "fdd000611",
            "filenamepattern": [
                {
                    "value": "*.dfxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000611.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000611.xml"
            }
        },
        {
            "digitalforensicsxml": "",
            "name": "Digital Forensics XML",
            "id": "fdd000611",
            "filenamepattern": [
                {
                    "value": "*.dfxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000611.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000611.xml"
            }
        },
        {
            "digitalforensicsxml": "",
            "name": "Digital Forensics XML",
            "id": "fdd000611",
            "filenamepattern": [
                {
                    "value": "*.dfxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000611.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000611.xml"
            }
        },
        {
            "digitalforensicsxml": "",
            "name": "Digital Forensics XML",
            "id": "fdd000611",
            "filenamepattern": [
                {
                    "value": "*.dfxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000611.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000611.xml"
            }
        },
        {
            "digitalforensicsxml": "",
            "name": "Digital Forensics XML",
            "id": "fdd000611",
            "filenamepattern": [
                {
                    "value": "*.dfxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000611.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000611.xml"
            }
        },
        {
            "digitalforensicsxml": "",
            "name": "Digital Forensics XML",
            "id": "fdd000611",
            "filenamepattern": [
                {
                    "value": "*.dfxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000611.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000611.xml"
            }
        },
        {
            "digitalforensicsxml": "",
            "name": "Digital Forensics XML",
            "id": "fdd000611",
            "filenamepattern": [
                {
                    "value": "*.dfxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000611.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000611.xml"
            }
        },
        {
            "digitalforensicsxml": "",
            "name": "Digital Forensics XML",
            "id": "fdd000611",
            "filenamepattern": [
                {
                    "value": "*.dfxml",
                    "url": "https://www.digipres.org/formats/extensions/#*.dfxml"
                },
                {
                    "value": "*.xml",
                    "url": "https://www.digipres.org/formats/extensions/#*.xml"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000611.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000611.xml"
            }
        },
        {
            "moofdiskimage": "",
            "name": "MOOF Disk Image",
            "id": "fdd000612",
            "filenamepattern": [
                {
                    "value": "*.moof",
                    "url": "https://www.digipres.org/formats/extensions/#*.moof"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000612.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000612.xml"
            }
        },
        {
            "moofdiskimage": "",
            "name": "MOOF Disk Image",
            "id": "fdd000612",
            "filenamepattern": [
                {
                    "value": "*.moof",
                    "url": "https://www.digipres.org/formats/extensions/#*.moof"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000612.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000612.xml"
            }
        },
        {
            "moofdiskimage": "",
            "name": "MOOF Disk Image",
            "id": "fdd000612",
            "filenamepattern": [
                {
                    "value": "*.moof",
                    "url": "https://www.digipres.org/formats/extensions/#*.moof"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000612.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000612.xml"
            }
        },
        {
            "moofdiskimage": "",
            "name": "MOOF Disk Image",
            "id": "fdd000612",
            "filenamepattern": [
                {
                    "value": "*.moof",
                    "url": "https://www.digipres.org/formats/extensions/#*.moof"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000612.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000612.xml"
            }
        },
        {
            "moofdiskimage": "",
            "name": "MOOF Disk Image",
            "id": "fdd000612",
            "filenamepattern": [
                {
                    "value": "*.moof",
                    "url": "https://www.digipres.org/formats/extensions/#*.moof"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000612.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000612.xml"
            }
        },
        {
            "moofdiskimage": "",
            "name": "MOOF Disk Image",
            "id": "fdd000612",
            "filenamepattern": [
                {
                    "value": "*.moof",
                    "url": "https://www.digipres.org/formats/extensions/#*.moof"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000612.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000612.xml"
            }
        },
        {
            "moofdiskimage": "",
            "name": "MOOF Disk Image",
            "id": "fdd000612",
            "filenamepattern": [
                {
                    "value": "*.moof",
                    "url": "https://www.digipres.org/formats/extensions/#*.moof"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000612.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000612.xml"
            }
        },
        {
            "moofdiskimage": "",
            "name": "MOOF Disk Image",
            "id": "fdd000612",
            "filenamepattern": [
                {
                    "value": "*.moof",
                    "url": "https://www.digipres.org/formats/extensions/#*.moof"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000612.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000612.xml"
            }
        },
        {
            "hfe(hxcfloppyemulator)fileformat": "",
            "name": "HFE (HxC Floppy Emulator) File Format",
            "id": "fdd000613",
            "filenamepattern": [
                {
                    "value": "*.hfe",
                    "url": "https://www.digipres.org/formats/extensions/#*.hfe"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000613.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000613.xml"
            }
        },
        {
            "hfe(hxcfloppyemulator)fileformat": "",
            "name": "HFE (HxC Floppy Emulator) File Format",
            "id": "fdd000613",
            "filenamepattern": [
                {
                    "value": "*.hfe",
                    "url": "https://www.digipres.org/formats/extensions/#*.hfe"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000613.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000613.xml"
            }
        },
        {
            "hfe(hxcfloppyemulator)fileformat": "",
            "name": "HFE (HxC Floppy Emulator) File Format",
            "id": "fdd000613",
            "filenamepattern": [
                {
                    "value": "*.hfe",
                    "url": "https://www.digipres.org/formats/extensions/#*.hfe"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000613.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000613.xml"
            }
        },
        {
            "hfe(hxcfloppyemulator)fileformat": "",
            "name": "HFE (HxC Floppy Emulator) File Format",
            "id": "fdd000613",
            "filenamepattern": [
                {
                    "value": "*.hfe",
                    "url": "https://www.digipres.org/formats/extensions/#*.hfe"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000613.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000613.xml"
            }
        },
        {
            "hfe(hxcfloppyemulator)fileformat": "",
            "name": "HFE (HxC Floppy Emulator) File Format",
            "id": "fdd000613",
            "filenamepattern": [
                {
                    "value": "*.hfe",
                    "url": "https://www.digipres.org/formats/extensions/#*.hfe"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000613.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000613.xml"
            }
        },
        {
            "hfe(hxcfloppyemulator)fileformat": "",
            "name": "HFE (HxC Floppy Emulator) File Format",
            "id": "fdd000613",
            "filenamepattern": [
                {
                    "value": "*.hfe",
                    "url": "https://www.digipres.org/formats/extensions/#*.hfe"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000613.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000613.xml"
            }
        },
        {
            "hfe(hxcfloppyemulator)fileformat": "",
            "name": "HFE (HxC Floppy Emulator) File Format",
            "id": "fdd000613",
            "filenamepattern": [
                {
                    "value": "*.hfe",
                    "url": "https://www.digipres.org/formats/extensions/#*.hfe"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000613.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000613.xml"
            }
        },
        {
            "hfe(hxcfloppyemulator)fileformat": "",
            "name": "HFE (HxC Floppy Emulator) File Format",
            "id": "fdd000613",
            "filenamepattern": [
                {
                    "value": "*.hfe",
                    "url": "https://www.digipres.org/formats/extensions/#*.hfe"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000613.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000613.xml"
            }
        },
        {
            "groupwiseemailformat": "",
            "name": "GroupWise Email Format",
            "id": "fdd000614",
            "filenamepattern": [
                {
                    "value": "*.mlm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mlm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000614.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000614.xml"
            }
        },
        {
            "groupwiseemailformat": "",
            "name": "GroupWise Email Format",
            "id": "fdd000614",
            "filenamepattern": [
                {
                    "value": "*.mlm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mlm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000614.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000614.xml"
            }
        },
        {
            "groupwiseemailformat": "",
            "name": "GroupWise Email Format",
            "id": "fdd000614",
            "filenamepattern": [
                {
                    "value": "*.mlm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mlm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000614.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000614.xml"
            }
        },
        {
            "groupwiseemailformat": "",
            "name": "GroupWise Email Format",
            "id": "fdd000614",
            "filenamepattern": [
                {
                    "value": "*.mlm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mlm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000614.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000614.xml"
            }
        },
        {
            "groupwiseemailformat": "",
            "name": "GroupWise Email Format",
            "id": "fdd000614",
            "filenamepattern": [
                {
                    "value": "*.mlm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mlm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000614.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000614.xml"
            }
        },
        {
            "groupwiseemailformat": "",
            "name": "GroupWise Email Format",
            "id": "fdd000614",
            "filenamepattern": [
                {
                    "value": "*.mlm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mlm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000614.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000614.xml"
            }
        },
        {
            "groupwiseemailformat": "",
            "name": "GroupWise Email Format",
            "id": "fdd000614",
            "filenamepattern": [
                {
                    "value": "*.mlm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mlm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000614.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000614.xml"
            }
        },
        {
            "groupwiseemailformat": "",
            "name": "GroupWise Email Format",
            "id": "fdd000614",
            "filenamepattern": [
                {
                    "value": "*.mlm",
                    "url": "https://www.digipres.org/formats/extensions/#*.mlm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000614.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000614.xml"
            }
        },
        {
            "applemailemailformat": "",
            "name": "Apple Mail Email Format",
            "id": "fdd000615",
            "filenamepattern": [
                {
                    "value": "*.emlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.emlx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000615.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000615.xml"
            }
        },
        {
            "applemailemailformat": "",
            "name": "Apple Mail Email Format",
            "id": "fdd000615",
            "filenamepattern": [
                {
                    "value": "*.emlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.emlx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000615.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000615.xml"
            }
        },
        {
            "applemailemailformat": "",
            "name": "Apple Mail Email Format",
            "id": "fdd000615",
            "filenamepattern": [
                {
                    "value": "*.emlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.emlx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000615.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000615.xml"
            }
        },
        {
            "applemailemailformat": "",
            "name": "Apple Mail Email Format",
            "id": "fdd000615",
            "filenamepattern": [
                {
                    "value": "*.emlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.emlx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000615.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000615.xml"
            }
        },
        {
            "applemailemailformat": "",
            "name": "Apple Mail Email Format",
            "id": "fdd000615",
            "filenamepattern": [
                {
                    "value": "*.emlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.emlx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000615.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000615.xml"
            }
        },
        {
            "applemailemailformat": "",
            "name": "Apple Mail Email Format",
            "id": "fdd000615",
            "filenamepattern": [
                {
                    "value": "*.emlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.emlx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000615.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000615.xml"
            }
        },
        {
            "applemailemailformat": "",
            "name": "Apple Mail Email Format",
            "id": "fdd000615",
            "filenamepattern": [
                {
                    "value": "*.emlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.emlx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000615.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000615.xml"
            }
        },
        {
            "applemailemailformat": "",
            "name": "Apple Mail Email Format",
            "id": "fdd000615",
            "filenamepattern": [
                {
                    "value": "*.emlx",
                    "url": "https://www.digipres.org/formats/extensions/#*.emlx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000615.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000615.xml"
            }
        },
        {
            "virtualcardformat(vcard)": "",
            "name": "Virtual Card Format (vCard)",
            "id": "fdd000616",
            "filenamepattern": [
                {
                    "value": "*.vcf",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcf"
                },
                {
                    "value": "*.vcard",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcard"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vcard"
                },
                {
                    "value": "text/x-vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-vcard"
                },
                {
                    "value": "text/directory",
                    "url": "https://www.digipres.org/formats/mime-types/#text/directory"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000616.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000616.xml"
            }
        },
        {
            "virtualcardformat(vcard)": "",
            "name": "Virtual Card Format (vCard)",
            "id": "fdd000616",
            "filenamepattern": [
                {
                    "value": "*.vcf",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcf"
                },
                {
                    "value": "*.vcard",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcard"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vcard"
                },
                {
                    "value": "text/x-vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-vcard"
                },
                {
                    "value": "text/directory",
                    "url": "https://www.digipres.org/formats/mime-types/#text/directory"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000616.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000616.xml"
            }
        },
        {
            "virtualcardformat(vcard)": "",
            "name": "Virtual Card Format (vCard)",
            "id": "fdd000616",
            "filenamepattern": [
                {
                    "value": "*.vcf",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcf"
                },
                {
                    "value": "*.vcard",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcard"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vcard"
                },
                {
                    "value": "text/x-vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-vcard"
                },
                {
                    "value": "text/directory",
                    "url": "https://www.digipres.org/formats/mime-types/#text/directory"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000616.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000616.xml"
            }
        },
        {
            "virtualcardformat(vcard)": "",
            "name": "Virtual Card Format (vCard)",
            "id": "fdd000616",
            "filenamepattern": [
                {
                    "value": "*.vcf",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcf"
                },
                {
                    "value": "*.vcard",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcard"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vcard"
                },
                {
                    "value": "text/x-vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-vcard"
                },
                {
                    "value": "text/directory",
                    "url": "https://www.digipres.org/formats/mime-types/#text/directory"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000616.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000616.xml"
            }
        },
        {
            "virtualcardformat(vcard)": "",
            "name": "Virtual Card Format (vCard)",
            "id": "fdd000616",
            "filenamepattern": [
                {
                    "value": "*.vcf",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcf"
                },
                {
                    "value": "*.vcard",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcard"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vcard"
                },
                {
                    "value": "text/x-vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-vcard"
                },
                {
                    "value": "text/directory",
                    "url": "https://www.digipres.org/formats/mime-types/#text/directory"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000616.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000616.xml"
            }
        },
        {
            "virtualcardformat(vcard)": "",
            "name": "Virtual Card Format (vCard)",
            "id": "fdd000616",
            "filenamepattern": [
                {
                    "value": "*.vcf",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcf"
                },
                {
                    "value": "*.vcard",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcard"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vcard"
                },
                {
                    "value": "text/x-vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-vcard"
                },
                {
                    "value": "text/directory",
                    "url": "https://www.digipres.org/formats/mime-types/#text/directory"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000616.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000616.xml"
            }
        },
        {
            "virtualcardformat(vcard)": "",
            "name": "Virtual Card Format (vCard)",
            "id": "fdd000616",
            "filenamepattern": [
                {
                    "value": "*.vcf",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcf"
                },
                {
                    "value": "*.vcard",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcard"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vcard"
                },
                {
                    "value": "text/x-vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-vcard"
                },
                {
                    "value": "text/directory",
                    "url": "https://www.digipres.org/formats/mime-types/#text/directory"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000616.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000616.xml"
            }
        },
        {
            "virtualcardformat(vcard)": "",
            "name": "Virtual Card Format (vCard)",
            "id": "fdd000616",
            "filenamepattern": [
                {
                    "value": "*.vcf",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcf"
                },
                {
                    "value": "*.vcard",
                    "url": "https://www.digipres.org/formats/extensions/#*.vcard"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/vcard"
                },
                {
                    "value": "text/x-vcard",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-vcard"
                },
                {
                    "value": "text/directory",
                    "url": "https://www.digipres.org/formats/mime-types/#text/directory"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000616.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000616.xml"
            }
        },
        {
            "xyzpointcloud": "",
            "name": "XYZ Point Cloud",
            "id": "fdd000617",
            "filenamepattern": [
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                },
                {
                    "value": "*.xyz",
                    "url": "https://www.digipres.org/formats/extensions/#*.xyz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000617.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000617.xml"
            }
        },
        {
            "xyzpointcloud": "",
            "name": "XYZ Point Cloud",
            "id": "fdd000617",
            "filenamepattern": [
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                },
                {
                    "value": "*.xyz",
                    "url": "https://www.digipres.org/formats/extensions/#*.xyz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000617.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000617.xml"
            }
        },
        {
            "xyzpointcloud": "",
            "name": "XYZ Point Cloud",
            "id": "fdd000617",
            "filenamepattern": [
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                },
                {
                    "value": "*.xyz",
                    "url": "https://www.digipres.org/formats/extensions/#*.xyz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000617.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000617.xml"
            }
        },
        {
            "xyzpointcloud": "",
            "name": "XYZ Point Cloud",
            "id": "fdd000617",
            "filenamepattern": [
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                },
                {
                    "value": "*.xyz",
                    "url": "https://www.digipres.org/formats/extensions/#*.xyz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000617.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000617.xml"
            }
        },
        {
            "xyzpointcloud": "",
            "name": "XYZ Point Cloud",
            "id": "fdd000617",
            "filenamepattern": [
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                },
                {
                    "value": "*.xyz",
                    "url": "https://www.digipres.org/formats/extensions/#*.xyz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000617.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000617.xml"
            }
        },
        {
            "xyzpointcloud": "",
            "name": "XYZ Point Cloud",
            "id": "fdd000617",
            "filenamepattern": [
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                },
                {
                    "value": "*.xyz",
                    "url": "https://www.digipres.org/formats/extensions/#*.xyz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000617.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000617.xml"
            }
        },
        {
            "xyzpointcloud": "",
            "name": "XYZ Point Cloud",
            "id": "fdd000617",
            "filenamepattern": [
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                },
                {
                    "value": "*.xyz",
                    "url": "https://www.digipres.org/formats/extensions/#*.xyz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000617.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000617.xml"
            }
        },
        {
            "xyzpointcloud": "",
            "name": "XYZ Point Cloud",
            "id": "fdd000617",
            "filenamepattern": [
                {
                    "value": "*.txt",
                    "url": "https://www.digipres.org/formats/extensions/#*.txt"
                },
                {
                    "value": "*.csv",
                    "url": "https://www.digipres.org/formats/extensions/#*.csv"
                },
                {
                    "value": "*.xyz",
                    "url": "https://www.digipres.org/formats/extensions/#*.xyz"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000617.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000617.xml"
            }
        },
        {
            "exchangeableimagefileformat(exif)family": "",
            "name": "Exchangeable Image File Format (Exif) Family",
            "id": "fdd000618",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                },
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000618.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000618.xml"
            }
        },
        {
            "exchangeableimagefileformat(exif)family": "",
            "name": "Exchangeable Image File Format (Exif) Family",
            "id": "fdd000618",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                },
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000618.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000618.xml"
            }
        },
        {
            "exchangeableimagefileformat(exif)family": "",
            "name": "Exchangeable Image File Format (Exif) Family",
            "id": "fdd000618",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                },
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000618.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000618.xml"
            }
        },
        {
            "exchangeableimagefileformat(exif)family": "",
            "name": "Exchangeable Image File Format (Exif) Family",
            "id": "fdd000618",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                },
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000618.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000618.xml"
            }
        },
        {
            "exchangeableimagefileformat(exif)family": "",
            "name": "Exchangeable Image File Format (Exif) Family",
            "id": "fdd000618",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                },
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000618.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000618.xml"
            }
        },
        {
            "exchangeableimagefileformat(exif)family": "",
            "name": "Exchangeable Image File Format (Exif) Family",
            "id": "fdd000618",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                },
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000618.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000618.xml"
            }
        },
        {
            "exchangeableimagefileformat(exif)family": "",
            "name": "Exchangeable Image File Format (Exif) Family",
            "id": "fdd000618",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                },
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000618.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000618.xml"
            }
        },
        {
            "exchangeableimagefileformat(exif)family": "",
            "name": "Exchangeable Image File Format (Exif) Family",
            "id": "fdd000618",
            "filenamepattern": [
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.wav",
                    "url": "https://www.digipres.org/formats/extensions/#*.wav"
                },
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000618.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000618.xml"
            }
        },
        {
            "jpegfileinterchangeformatfamily": "",
            "name": "JPEG File Interchange Format Family",
            "id": "fdd000619",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000619.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000619.xml"
            }
        },
        {
            "jpegfileinterchangeformatfamily": "",
            "name": "JPEG File Interchange Format Family",
            "id": "fdd000619",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000619.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000619.xml"
            }
        },
        {
            "jpegfileinterchangeformatfamily": "",
            "name": "JPEG File Interchange Format Family",
            "id": "fdd000619",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000619.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000619.xml"
            }
        },
        {
            "jpegfileinterchangeformatfamily": "",
            "name": "JPEG File Interchange Format Family",
            "id": "fdd000619",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000619.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000619.xml"
            }
        },
        {
            "jpegfileinterchangeformatfamily": "",
            "name": "JPEG File Interchange Format Family",
            "id": "fdd000619",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000619.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000619.xml"
            }
        },
        {
            "jpegfileinterchangeformatfamily": "",
            "name": "JPEG File Interchange Format Family",
            "id": "fdd000619",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000619.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000619.xml"
            }
        },
        {
            "jpegfileinterchangeformatfamily": "",
            "name": "JPEG File Interchange Format Family",
            "id": "fdd000619",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000619.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000619.xml"
            }
        },
        {
            "jpegfileinterchangeformatfamily": "",
            "name": "JPEG File Interchange Format Family",
            "id": "fdd000619",
            "filenamepattern": [
                {
                    "value": "*.jpeg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpeg"
                },
                {
                    "value": "*.jfif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfif"
                },
                {
                    "value": "*.jls",
                    "url": "https://www.digipres.org/formats/extensions/#*.jls"
                },
                {
                    "value": "*.jfi",
                    "url": "https://www.digipres.org/formats/extensions/#*.jfi"
                },
                {
                    "value": "*.jpg",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpg"
                },
                {
                    "value": "*.jif",
                    "url": "https://www.digipres.org/formats/extensions/#*.jif"
                },
                {
                    "value": "*.jpe",
                    "url": "https://www.digipres.org/formats/extensions/#*.jpe"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/jpeg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpeg"
                },
                {
                    "value": "image/jpg",
                    "url": "https://www.digipres.org/formats/mime-types/#image/jpg"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000619.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000619.xml"
            }
        },
        {
            "pdfportfolio": "",
            "name": "PDF Portfolio",
            "id": "fdd000620",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000620.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000620.xml"
            }
        },
        {
            "pdfportfolio": "",
            "name": "PDF Portfolio",
            "id": "fdd000620",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000620.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000620.xml"
            }
        },
        {
            "pdfportfolio": "",
            "name": "PDF Portfolio",
            "id": "fdd000620",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000620.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000620.xml"
            }
        },
        {
            "pdfportfolio": "",
            "name": "PDF Portfolio",
            "id": "fdd000620",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000620.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000620.xml"
            }
        },
        {
            "pdfportfolio": "",
            "name": "PDF Portfolio",
            "id": "fdd000620",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000620.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000620.xml"
            }
        },
        {
            "pdfportfolio": "",
            "name": "PDF Portfolio",
            "id": "fdd000620",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000620.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000620.xml"
            }
        },
        {
            "pdfportfolio": "",
            "name": "PDF Portfolio",
            "id": "fdd000620",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000620.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000620.xml"
            }
        },
        {
            "pdfportfolio": "",
            "name": "PDF Portfolio",
            "id": "fdd000620",
            "filenamepattern": [
                {
                    "value": "*.pdf",
                    "url": "https://www.digipres.org/formats/extensions/#*.pdf"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/pdf",
                    "url": "https://www.digipres.org/formats/mime-types/#application/pdf"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000620.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000620.xml"
            }
        },
        {
            "wordperfectdocumentfamily": "",
            "name": "WordPerfect Document Family",
            "id": "fdd000621",
            "filenamepattern": [
                {
                    "value": "*.wpd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wpd"
                },
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                },
                {
                    "value": "*.wp5",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp5"
                },
                {
                    "value": "*.wp6",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp6"
                },
                {
                    "value": "*.wp7",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp7"
                },
                {
                    "value": "*.wp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp4"
                },
                {
                    "value": "*.wp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.wordperfect",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.wordperfect"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000621.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000621.xml"
            }
        },
        {
            "wordperfectdocumentfamily": "",
            "name": "WordPerfect Document Family",
            "id": "fdd000621",
            "filenamepattern": [
                {
                    "value": "*.wpd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wpd"
                },
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                },
                {
                    "value": "*.wp5",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp5"
                },
                {
                    "value": "*.wp6",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp6"
                },
                {
                    "value": "*.wp7",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp7"
                },
                {
                    "value": "*.wp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp4"
                },
                {
                    "value": "*.wp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.wordperfect",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.wordperfect"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000621.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000621.xml"
            }
        },
        {
            "wordperfectdocumentfamily": "",
            "name": "WordPerfect Document Family",
            "id": "fdd000621",
            "filenamepattern": [
                {
                    "value": "*.wpd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wpd"
                },
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                },
                {
                    "value": "*.wp5",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp5"
                },
                {
                    "value": "*.wp6",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp6"
                },
                {
                    "value": "*.wp7",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp7"
                },
                {
                    "value": "*.wp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp4"
                },
                {
                    "value": "*.wp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.wordperfect",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.wordperfect"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000621.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000621.xml"
            }
        },
        {
            "wordperfectdocumentfamily": "",
            "name": "WordPerfect Document Family",
            "id": "fdd000621",
            "filenamepattern": [
                {
                    "value": "*.wpd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wpd"
                },
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                },
                {
                    "value": "*.wp5",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp5"
                },
                {
                    "value": "*.wp6",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp6"
                },
                {
                    "value": "*.wp7",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp7"
                },
                {
                    "value": "*.wp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp4"
                },
                {
                    "value": "*.wp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.wordperfect",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.wordperfect"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000621.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000621.xml"
            }
        },
        {
            "wordperfectdocumentfamily": "",
            "name": "WordPerfect Document Family",
            "id": "fdd000621",
            "filenamepattern": [
                {
                    "value": "*.wpd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wpd"
                },
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                },
                {
                    "value": "*.wp5",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp5"
                },
                {
                    "value": "*.wp6",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp6"
                },
                {
                    "value": "*.wp7",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp7"
                },
                {
                    "value": "*.wp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp4"
                },
                {
                    "value": "*.wp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.wordperfect",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.wordperfect"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000621.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000621.xml"
            }
        },
        {
            "wordperfectdocumentfamily": "",
            "name": "WordPerfect Document Family",
            "id": "fdd000621",
            "filenamepattern": [
                {
                    "value": "*.wpd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wpd"
                },
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                },
                {
                    "value": "*.wp5",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp5"
                },
                {
                    "value": "*.wp6",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp6"
                },
                {
                    "value": "*.wp7",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp7"
                },
                {
                    "value": "*.wp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp4"
                },
                {
                    "value": "*.wp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.wordperfect",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.wordperfect"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000621.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000621.xml"
            }
        },
        {
            "wordperfectdocumentfamily": "",
            "name": "WordPerfect Document Family",
            "id": "fdd000621",
            "filenamepattern": [
                {
                    "value": "*.wpd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wpd"
                },
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                },
                {
                    "value": "*.wp5",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp5"
                },
                {
                    "value": "*.wp6",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp6"
                },
                {
                    "value": "*.wp7",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp7"
                },
                {
                    "value": "*.wp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp4"
                },
                {
                    "value": "*.wp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.wordperfect",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.wordperfect"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000621.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000621.xml"
            }
        },
        {
            "wordperfectdocumentfamily": "",
            "name": "WordPerfect Document Family",
            "id": "fdd000621",
            "filenamepattern": [
                {
                    "value": "*.wpd",
                    "url": "https://www.digipres.org/formats/extensions/#*.wpd"
                },
                {
                    "value": "*.doc",
                    "url": "https://www.digipres.org/formats/extensions/#*.doc"
                },
                {
                    "value": "*.wp5",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp5"
                },
                {
                    "value": "*.wp6",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp6"
                },
                {
                    "value": "*.wp7",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp7"
                },
                {
                    "value": "*.wp4",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp4"
                },
                {
                    "value": "*.wp",
                    "url": "https://www.digipres.org/formats/extensions/#*.wp"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/vnd.wordperfect",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.wordperfect"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000621.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000621.xml"
            }
        },
        {
            "fastadatabaseformat": "",
            "name": "FASTA Database Format",
            "id": "fdd000622",
            "filenamepattern": [
                {
                    "value": "*.fas",
                    "url": "https://www.digipres.org/formats/extensions/#*.fas"
                },
                {
                    "value": "*.fna",
                    "url": "https://www.digipres.org/formats/extensions/#*.fna"
                },
                {
                    "value": "*.fsa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fsa"
                },
                {
                    "value": "*.faa",
                    "url": "https://www.digipres.org/formats/extensions/#*.faa"
                },
                {
                    "value": "*.fasta",
                    "url": "https://www.digipres.org/formats/extensions/#*.fasta"
                },
                {
                    "value": "*.seq",
                    "url": "https://www.digipres.org/formats/extensions/#*.seq"
                },
                {
                    "value": "*.fa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fa"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-fasta                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-fasta"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000622.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000622.xml"
            }
        },
        {
            "fastadatabaseformat": "",
            "name": "FASTA Database Format",
            "id": "fdd000622",
            "filenamepattern": [
                {
                    "value": "*.fas",
                    "url": "https://www.digipres.org/formats/extensions/#*.fas"
                },
                {
                    "value": "*.fna",
                    "url": "https://www.digipres.org/formats/extensions/#*.fna"
                },
                {
                    "value": "*.fsa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fsa"
                },
                {
                    "value": "*.faa",
                    "url": "https://www.digipres.org/formats/extensions/#*.faa"
                },
                {
                    "value": "*.fasta",
                    "url": "https://www.digipres.org/formats/extensions/#*.fasta"
                },
                {
                    "value": "*.seq",
                    "url": "https://www.digipres.org/formats/extensions/#*.seq"
                },
                {
                    "value": "*.fa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fa"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-fasta                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-fasta"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000622.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000622.xml"
            }
        },
        {
            "fastadatabaseformat": "",
            "name": "FASTA Database Format",
            "id": "fdd000622",
            "filenamepattern": [
                {
                    "value": "*.fas",
                    "url": "https://www.digipres.org/formats/extensions/#*.fas"
                },
                {
                    "value": "*.fna",
                    "url": "https://www.digipres.org/formats/extensions/#*.fna"
                },
                {
                    "value": "*.fsa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fsa"
                },
                {
                    "value": "*.faa",
                    "url": "https://www.digipres.org/formats/extensions/#*.faa"
                },
                {
                    "value": "*.fasta",
                    "url": "https://www.digipres.org/formats/extensions/#*.fasta"
                },
                {
                    "value": "*.seq",
                    "url": "https://www.digipres.org/formats/extensions/#*.seq"
                },
                {
                    "value": "*.fa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fa"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-fasta                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-fasta"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000622.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000622.xml"
            }
        },
        {
            "fastadatabaseformat": "",
            "name": "FASTA Database Format",
            "id": "fdd000622",
            "filenamepattern": [
                {
                    "value": "*.fas",
                    "url": "https://www.digipres.org/formats/extensions/#*.fas"
                },
                {
                    "value": "*.fna",
                    "url": "https://www.digipres.org/formats/extensions/#*.fna"
                },
                {
                    "value": "*.fsa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fsa"
                },
                {
                    "value": "*.faa",
                    "url": "https://www.digipres.org/formats/extensions/#*.faa"
                },
                {
                    "value": "*.fasta",
                    "url": "https://www.digipres.org/formats/extensions/#*.fasta"
                },
                {
                    "value": "*.seq",
                    "url": "https://www.digipres.org/formats/extensions/#*.seq"
                },
                {
                    "value": "*.fa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fa"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-fasta                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-fasta"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000622.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000622.xml"
            }
        },
        {
            "fastadatabaseformat": "",
            "name": "FASTA Database Format",
            "id": "fdd000622",
            "filenamepattern": [
                {
                    "value": "*.fas",
                    "url": "https://www.digipres.org/formats/extensions/#*.fas"
                },
                {
                    "value": "*.fna",
                    "url": "https://www.digipres.org/formats/extensions/#*.fna"
                },
                {
                    "value": "*.fsa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fsa"
                },
                {
                    "value": "*.faa",
                    "url": "https://www.digipres.org/formats/extensions/#*.faa"
                },
                {
                    "value": "*.fasta",
                    "url": "https://www.digipres.org/formats/extensions/#*.fasta"
                },
                {
                    "value": "*.seq",
                    "url": "https://www.digipres.org/formats/extensions/#*.seq"
                },
                {
                    "value": "*.fa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fa"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-fasta                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-fasta"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000622.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000622.xml"
            }
        },
        {
            "fastadatabaseformat": "",
            "name": "FASTA Database Format",
            "id": "fdd000622",
            "filenamepattern": [
                {
                    "value": "*.fas",
                    "url": "https://www.digipres.org/formats/extensions/#*.fas"
                },
                {
                    "value": "*.fna",
                    "url": "https://www.digipres.org/formats/extensions/#*.fna"
                },
                {
                    "value": "*.fsa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fsa"
                },
                {
                    "value": "*.faa",
                    "url": "https://www.digipres.org/formats/extensions/#*.faa"
                },
                {
                    "value": "*.fasta",
                    "url": "https://www.digipres.org/formats/extensions/#*.fasta"
                },
                {
                    "value": "*.seq",
                    "url": "https://www.digipres.org/formats/extensions/#*.seq"
                },
                {
                    "value": "*.fa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fa"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-fasta                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-fasta"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000622.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000622.xml"
            }
        },
        {
            "fastadatabaseformat": "",
            "name": "FASTA Database Format",
            "id": "fdd000622",
            "filenamepattern": [
                {
                    "value": "*.fas",
                    "url": "https://www.digipres.org/formats/extensions/#*.fas"
                },
                {
                    "value": "*.fna",
                    "url": "https://www.digipres.org/formats/extensions/#*.fna"
                },
                {
                    "value": "*.fsa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fsa"
                },
                {
                    "value": "*.faa",
                    "url": "https://www.digipres.org/formats/extensions/#*.faa"
                },
                {
                    "value": "*.fasta",
                    "url": "https://www.digipres.org/formats/extensions/#*.fasta"
                },
                {
                    "value": "*.seq",
                    "url": "https://www.digipres.org/formats/extensions/#*.seq"
                },
                {
                    "value": "*.fa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fa"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-fasta                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-fasta"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000622.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000622.xml"
            }
        },
        {
            "fastadatabaseformat": "",
            "name": "FASTA Database Format",
            "id": "fdd000622",
            "filenamepattern": [
                {
                    "value": "*.fas",
                    "url": "https://www.digipres.org/formats/extensions/#*.fas"
                },
                {
                    "value": "*.fna",
                    "url": "https://www.digipres.org/formats/extensions/#*.fna"
                },
                {
                    "value": "*.fsa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fsa"
                },
                {
                    "value": "*.faa",
                    "url": "https://www.digipres.org/formats/extensions/#*.faa"
                },
                {
                    "value": "*.fasta",
                    "url": "https://www.digipres.org/formats/extensions/#*.fasta"
                },
                {
                    "value": "*.seq",
                    "url": "https://www.digipres.org/formats/extensions/#*.seq"
                },
                {
                    "value": "*.fa",
                    "url": "https://www.digipres.org/formats/extensions/#*.fa"
                }
            ],
            "mimetypes": [
                {
                    "value": "text/x-fasta                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#text/x-fasta"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000622.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000622.xml"
            }
        },
        {
            "mysqltabledefinitionformat": "",
            "name": "MySQL Table Definition Format",
            "id": "fdd000623",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mysql-db                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-db"
                },
                {
                    "value": "application/x-mysql-table-definition                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-table-definition"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000623.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000623.xml"
            }
        },
        {
            "mysqltabledefinitionformat": "",
            "name": "MySQL Table Definition Format",
            "id": "fdd000623",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mysql-db                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-db"
                },
                {
                    "value": "application/x-mysql-table-definition                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-table-definition"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000623.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000623.xml"
            }
        },
        {
            "mysqltabledefinitionformat": "",
            "name": "MySQL Table Definition Format",
            "id": "fdd000623",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mysql-db                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-db"
                },
                {
                    "value": "application/x-mysql-table-definition                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-table-definition"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000623.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000623.xml"
            }
        },
        {
            "mysqltabledefinitionformat": "",
            "name": "MySQL Table Definition Format",
            "id": "fdd000623",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mysql-db                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-db"
                },
                {
                    "value": "application/x-mysql-table-definition                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-table-definition"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000623.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000623.xml"
            }
        },
        {
            "mysqltabledefinitionformat": "",
            "name": "MySQL Table Definition Format",
            "id": "fdd000623",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mysql-db                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-db"
                },
                {
                    "value": "application/x-mysql-table-definition                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-table-definition"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000623.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000623.xml"
            }
        },
        {
            "mysqltabledefinitionformat": "",
            "name": "MySQL Table Definition Format",
            "id": "fdd000623",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mysql-db                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-db"
                },
                {
                    "value": "application/x-mysql-table-definition                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-table-definition"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000623.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000623.xml"
            }
        },
        {
            "mysqltabledefinitionformat": "",
            "name": "MySQL Table Definition Format",
            "id": "fdd000623",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mysql-db                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-db"
                },
                {
                    "value": "application/x-mysql-table-definition                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-table-definition"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000623.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000623.xml"
            }
        },
        {
            "mysqltabledefinitionformat": "",
            "name": "MySQL Table Definition Format",
            "id": "fdd000623",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-mysql-db                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-db"
                },
                {
                    "value": "application/x-mysql-table-definition                        ",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-mysql-table-definition"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000623.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000623.xml"
            }
        },
        {
            "portableembosserformat": "",
            "name": "Portable Embosser Format",
            "id": "fdd000624",
            "filenamepattern": [
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pef+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pef+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000624.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000624.xml"
            }
        },
        {
            "portableembosserformat": "",
            "name": "Portable Embosser Format",
            "id": "fdd000624",
            "filenamepattern": [
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pef+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pef+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000624.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000624.xml"
            }
        },
        {
            "portableembosserformat": "",
            "name": "Portable Embosser Format",
            "id": "fdd000624",
            "filenamepattern": [
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pef+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pef+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000624.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000624.xml"
            }
        },
        {
            "portableembosserformat": "",
            "name": "Portable Embosser Format",
            "id": "fdd000624",
            "filenamepattern": [
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pef+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pef+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000624.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000624.xml"
            }
        },
        {
            "portableembosserformat": "",
            "name": "Portable Embosser Format",
            "id": "fdd000624",
            "filenamepattern": [
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pef+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pef+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000624.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000624.xml"
            }
        },
        {
            "portableembosserformat": "",
            "name": "Portable Embosser Format",
            "id": "fdd000624",
            "filenamepattern": [
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pef+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pef+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000624.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000624.xml"
            }
        },
        {
            "portableembosserformat": "",
            "name": "Portable Embosser Format",
            "id": "fdd000624",
            "filenamepattern": [
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pef+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pef+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000624.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000624.xml"
            }
        },
        {
            "portableembosserformat": "",
            "name": "Portable Embosser Format",
            "id": "fdd000624",
            "filenamepattern": [
                {
                    "value": "*.pef",
                    "url": "https://www.digipres.org/formats/extensions/#*.pef"
                }
            ],
            "mimetypes": [
                {
                    "value": "application/x-pef+xml",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-pef+xml"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000624.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000624.xml"
            }
        },
        {
            "appledoubleresourcefork": "",
            "name": "AppleDouble Resource Fork",
            "id": "fdd000625",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "multipart/appledouble",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/appledouble"
                },
                {
                    "value": "application/applefile",
                    "url": "https://www.digipres.org/formats/mime-types/#application/applefile"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000625.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000625.xml"
            }
        },
        {
            "appledoubleresourcefork": "",
            "name": "AppleDouble Resource Fork",
            "id": "fdd000625",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "multipart/appledouble",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/appledouble"
                },
                {
                    "value": "application/applefile",
                    "url": "https://www.digipres.org/formats/mime-types/#application/applefile"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000625.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000625.xml"
            }
        },
        {
            "appledoubleresourcefork": "",
            "name": "AppleDouble Resource Fork",
            "id": "fdd000625",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "multipart/appledouble",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/appledouble"
                },
                {
                    "value": "application/applefile",
                    "url": "https://www.digipres.org/formats/mime-types/#application/applefile"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000625.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000625.xml"
            }
        },
        {
            "appledoubleresourcefork": "",
            "name": "AppleDouble Resource Fork",
            "id": "fdd000625",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "multipart/appledouble",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/appledouble"
                },
                {
                    "value": "application/applefile",
                    "url": "https://www.digipres.org/formats/mime-types/#application/applefile"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000625.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000625.xml"
            }
        },
        {
            "appledoubleresourcefork": "",
            "name": "AppleDouble Resource Fork",
            "id": "fdd000625",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "multipart/appledouble",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/appledouble"
                },
                {
                    "value": "application/applefile",
                    "url": "https://www.digipres.org/formats/mime-types/#application/applefile"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000625.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000625.xml"
            }
        },
        {
            "appledoubleresourcefork": "",
            "name": "AppleDouble Resource Fork",
            "id": "fdd000625",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "multipart/appledouble",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/appledouble"
                },
                {
                    "value": "application/applefile",
                    "url": "https://www.digipres.org/formats/mime-types/#application/applefile"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000625.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000625.xml"
            }
        },
        {
            "appledoubleresourcefork": "",
            "name": "AppleDouble Resource Fork",
            "id": "fdd000625",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "multipart/appledouble",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/appledouble"
                },
                {
                    "value": "application/applefile",
                    "url": "https://www.digipres.org/formats/mime-types/#application/applefile"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000625.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000625.xml"
            }
        },
        {
            "appledoubleresourcefork": "",
            "name": "AppleDouble Resource Fork",
            "id": "fdd000625",
            "filenamepattern": [
                ""
            ],
            "mimetypes": [
                {
                    "value": "multipart/appledouble",
                    "url": "https://www.digipres.org/formats/mime-types/#multipart/appledouble"
                },
                {
                    "value": "application/applefile",
                    "url": "https://www.digipres.org/formats/mime-types/#application/applefile"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000625.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000625.xml"
            }
        },
        {
            "arcgislayerfile": "",
            "name": "ArcGIS Layer File",
            "id": "fdd000626",
            "filenamepattern": [
                {
                    "value": "*.lyr",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyr"
                },
                {
                    "value": "*.lyrx",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyrx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000626.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000626.xml"
            }
        },
        {
            "arcgislayerfile": "",
            "name": "ArcGIS Layer File",
            "id": "fdd000626",
            "filenamepattern": [
                {
                    "value": "*.lyr",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyr"
                },
                {
                    "value": "*.lyrx",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyrx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000626.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000626.xml"
            }
        },
        {
            "arcgislayerfile": "",
            "name": "ArcGIS Layer File",
            "id": "fdd000626",
            "filenamepattern": [
                {
                    "value": "*.lyr",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyr"
                },
                {
                    "value": "*.lyrx",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyrx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000626.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000626.xml"
            }
        },
        {
            "arcgislayerfile": "",
            "name": "ArcGIS Layer File",
            "id": "fdd000626",
            "filenamepattern": [
                {
                    "value": "*.lyr",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyr"
                },
                {
                    "value": "*.lyrx",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyrx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000626.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000626.xml"
            }
        },
        {
            "arcgislayerfile": "",
            "name": "ArcGIS Layer File",
            "id": "fdd000626",
            "filenamepattern": [
                {
                    "value": "*.lyr",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyr"
                },
                {
                    "value": "*.lyrx",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyrx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000626.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000626.xml"
            }
        },
        {
            "arcgislayerfile": "",
            "name": "ArcGIS Layer File",
            "id": "fdd000626",
            "filenamepattern": [
                {
                    "value": "*.lyr",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyr"
                },
                {
                    "value": "*.lyrx",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyrx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000626.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000626.xml"
            }
        },
        {
            "arcgislayerfile": "",
            "name": "ArcGIS Layer File",
            "id": "fdd000626",
            "filenamepattern": [
                {
                    "value": "*.lyr",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyr"
                },
                {
                    "value": "*.lyrx",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyrx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000626.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000626.xml"
            }
        },
        {
            "arcgislayerfile": "",
            "name": "ArcGIS Layer File",
            "id": "fdd000626",
            "filenamepattern": [
                {
                    "value": "*.lyr",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyr"
                },
                {
                    "value": "*.lyrx",
                    "url": "https://www.digipres.org/formats/extensions/#*.lyrx"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "false",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000626.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000626.xml"
            }
        },
        {
            "mysqlviewdefinitionformat": "",
            "name": "MySQL View Definition Format",
            "id": "fdd000627",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000627.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000627.xml"
            }
        },
        {
            "mysqlviewdefinitionformat": "",
            "name": "MySQL View Definition Format",
            "id": "fdd000627",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000627.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000627.xml"
            }
        },
        {
            "mysqlviewdefinitionformat": "",
            "name": "MySQL View Definition Format",
            "id": "fdd000627",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000627.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000627.xml"
            }
        },
        {
            "mysqlviewdefinitionformat": "",
            "name": "MySQL View Definition Format",
            "id": "fdd000627",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000627.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000627.xml"
            }
        },
        {
            "mysqlviewdefinitionformat": "",
            "name": "MySQL View Definition Format",
            "id": "fdd000627",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000627.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000627.xml"
            }
        },
        {
            "mysqlviewdefinitionformat": "",
            "name": "MySQL View Definition Format",
            "id": "fdd000627",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000627.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000627.xml"
            }
        },
        {
            "mysqlviewdefinitionformat": "",
            "name": "MySQL View Definition Format",
            "id": "fdd000627",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000627.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000627.xml"
            }
        },
        {
            "mysqlviewdefinitionformat": "",
            "name": "MySQL View Definition Format",
            "id": "fdd000627",
            "filenamepattern": [
                {
                    "value": "*.frm",
                    "url": "https://www.digipres.org/formats/extensions/#*.frm"
                }
            ],
            "mimetypes": [
                ""
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000627.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000627.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.6": "",
            "name": "Adobe Digital Negative (DNG), Version 1.6",
            "id": "fdd000628",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000628.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000628.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.6": "",
            "name": "Adobe Digital Negative (DNG), Version 1.6",
            "id": "fdd000628",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000628.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000628.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.6": "",
            "name": "Adobe Digital Negative (DNG), Version 1.6",
            "id": "fdd000628",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000628.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000628.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.6": "",
            "name": "Adobe Digital Negative (DNG), Version 1.6",
            "id": "fdd000628",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000628.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000628.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.6": "",
            "name": "Adobe Digital Negative (DNG), Version 1.6",
            "id": "fdd000628",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000628.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000628.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.6": "",
            "name": "Adobe Digital Negative (DNG), Version 1.6",
            "id": "fdd000628",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000628.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000628.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.6": "",
            "name": "Adobe Digital Negative (DNG), Version 1.6",
            "id": "fdd000628",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000628.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000628.xml"
            }
        },
        {
            "adobedigitalnegative(dng),version1.6": "",
            "name": "Adobe Digital Negative (DNG), Version 1.6",
            "id": "fdd000628",
            "filenamepattern": [
                {
                    "value": "*.tif",
                    "url": "https://www.digipres.org/formats/extensions/#*.tif"
                },
                {
                    "value": "*.dng",
                    "url": "https://www.digipres.org/formats/extensions/#*.dng"
                }
            ],
            "mimetypes": [
                {
                    "value": "image/tiff",
                    "url": "https://www.digipres.org/formats/mime-types/#image/tiff"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000628.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000628.xml"
            }
        },
        {
            "flashswffileformatfamily": "",
            "name": "Flash SWF File Format Family",
            "id": "fdd000629",
            "filenamepattern": [
                {
                    "value": "*.swf",
                    "url": "https://www.digipres.org/formats/extensions/#*.swf"
                }
            ],
            "mimetypes": [
                {
                    "value": " application/x-shockwave-flash2-preview",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash2-preview"
                },
                {
                    "value": "application/vnd.adobe.flash.movie",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.flash.movie"
                },
                {
                    "value": "application/x-shockwave-flash",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000629.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000629.xml"
            }
        },
        {
            "flashswffileformatfamily": "",
            "name": "Flash SWF File Format Family",
            "id": "fdd000629",
            "filenamepattern": [
                {
                    "value": "*.swf",
                    "url": "https://www.digipres.org/formats/extensions/#*.swf"
                }
            ],
            "mimetypes": [
                {
                    "value": " application/x-shockwave-flash2-preview",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash2-preview"
                },
                {
                    "value": "application/vnd.adobe.flash.movie",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.flash.movie"
                },
                {
                    "value": "application/x-shockwave-flash",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000629.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000629.xml"
            }
        },
        {
            "flashswffileformatfamily": "",
            "name": "Flash SWF File Format Family",
            "id": "fdd000629",
            "filenamepattern": [
                {
                    "value": "*.swf",
                    "url": "https://www.digipres.org/formats/extensions/#*.swf"
                }
            ],
            "mimetypes": [
                {
                    "value": " application/x-shockwave-flash2-preview",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash2-preview"
                },
                {
                    "value": "application/vnd.adobe.flash.movie",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.flash.movie"
                },
                {
                    "value": "application/x-shockwave-flash",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000629.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000629.xml"
            }
        },
        {
            "flashswffileformatfamily": "",
            "name": "Flash SWF File Format Family",
            "id": "fdd000629",
            "filenamepattern": [
                {
                    "value": "*.swf",
                    "url": "https://www.digipres.org/formats/extensions/#*.swf"
                }
            ],
            "mimetypes": [
                {
                    "value": " application/x-shockwave-flash2-preview",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash2-preview"
                },
                {
                    "value": "application/vnd.adobe.flash.movie",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.flash.movie"
                },
                {
                    "value": "application/x-shockwave-flash",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000629.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000629.xml"
            }
        },
        {
            "flashswffileformatfamily": "",
            "name": "Flash SWF File Format Family",
            "id": "fdd000629",
            "filenamepattern": [
                {
                    "value": "*.swf",
                    "url": "https://www.digipres.org/formats/extensions/#*.swf"
                }
            ],
            "mimetypes": [
                {
                    "value": " application/x-shockwave-flash2-preview",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash2-preview"
                },
                {
                    "value": "application/vnd.adobe.flash.movie",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.flash.movie"
                },
                {
                    "value": "application/x-shockwave-flash",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000629.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000629.xml"
            }
        },
        {
            "flashswffileformatfamily": "",
            "name": "Flash SWF File Format Family",
            "id": "fdd000629",
            "filenamepattern": [
                {
                    "value": "*.swf",
                    "url": "https://www.digipres.org/formats/extensions/#*.swf"
                }
            ],
            "mimetypes": [
                {
                    "value": " application/x-shockwave-flash2-preview",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash2-preview"
                },
                {
                    "value": "application/vnd.adobe.flash.movie",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.flash.movie"
                },
                {
                    "value": "application/x-shockwave-flash",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000629.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000629.xml"
            }
        },
        {
            "flashswffileformatfamily": "",
            "name": "Flash SWF File Format Family",
            "id": "fdd000629",
            "filenamepattern": [
                {
                    "value": "*.swf",
                    "url": "https://www.digipres.org/formats/extensions/#*.swf"
                }
            ],
            "mimetypes": [
                {
                    "value": " application/x-shockwave-flash2-preview",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash2-preview"
                },
                {
                    "value": "application/vnd.adobe.flash.movie",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.flash.movie"
                },
                {
                    "value": "application/x-shockwave-flash",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000629.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000629.xml"
            }
        },
        {
            "flashswffileformatfamily": "",
            "name": "Flash SWF File Format Family",
            "id": "fdd000629",
            "filenamepattern": [
                {
                    "value": "*.swf",
                    "url": "https://www.digipres.org/formats/extensions/#*.swf"
                }
            ],
            "mimetypes": [
                {
                    "value": " application/x-shockwave-flash2-preview",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash2-preview"
                },
                {
                    "value": "application/vnd.adobe.flash.movie",
                    "url": "https://www.digipres.org/formats/mime-types/#application/vnd.adobe.flash.movie"
                },
                {
                    "value": "application/x-shockwave-flash",
                    "url": "https://www.digipres.org/formats/mime-types/#application/x-shockwave-flash"
                }
            ],
            "hasmagic": "true",
            "supertype": {
                "value": "",
                "url": "https://www.digipres.org/formats/mime-types/#"
            },
            "registrysource": {
                "value": "fdd000629.xml",
                "url": "https://www.digipres.orghttps://github.com/digipres/digipres.github.io/blob/master/_sources/registries/fdd/fddXML/fdd000629.xml"
            }
        }
    ]
}
export default fileExtensions