
const deleteFiles = async (selectedFilesForAction, uaiProject) => {

    await uaiProject.deleteFiles(selectedFilesForAction);
    // await uaiProject.deleteFiles(selectedFilesForAction);

}


const createFolder = async (folderName, uaiProject) => {
    await uaiProject.createFolder(folderName);
}

export {deleteFiles, createFolder}