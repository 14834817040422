const generateColors = (index) => {
            if (index == 1) return '!bg-uaipurple-1';
if (index == 2) return '!bg-uaipurple-2';
if (index == 3) return '!bg-uaipurple-3';
if (index == 4) return '!bg-uaipurple-4';
if (index == 5) return '!bg-uaipurple-5';
if (index == 6) return '!bg-uaipurple-6';
if (index == 7) return '!bg-uaipurple-7';
if (index == 8) return '!bg-uaipurple-8';
if (index == 9) return '!bg-uaipurple-9';
if (index == 10) return '!bg-uaipurple-10';
if (index == 11) return '!bg-uaipurple-11';
if (index == 12) return '!bg-uaipurple-12';
if (index == 13) return '!bg-uaipurple-13';
if (index == 14) return '!bg-uaipurple-14';
if (index == 15) return '!bg-uaipurple-15';
if (index == 16) return '!bg-uaipurple-16';
if (index == 17) return '!bg-uaipurple-17';
if (index == 18) return '!bg-uaipurple-18';
if (index == 19) return '!bg-uaipurple-19';
if (index == 20) return '!bg-uaipurple-20';
if (index == 21) return '!bg-uaipurple-21';
if (index == 22) return '!bg-uaipurple-22';
if (index == 23) return '!bg-uaipurple-23';
if (index == 24) return '!bg-uaipurple-24';
if (index == 25) return '!bg-uaipurple-25';
if (index == 26) return '!bg-uaipurple-26';
if (index == 27) return '!bg-uaipurple-27';
if (index == 28) return '!bg-uaipurple-28';
if (index == 29) return '!bg-uaipurple-29';
if (index == 30) return '!bg-uaipurple-30';
if (index == 31) return '!bg-uaipurple-31';
if (index == 32) return '!bg-uaipurple-32';
if (index == 33) return '!bg-uaipurple-33';
if (index == 34) return '!bg-uaipurple-34';
if (index == 35) return '!bg-uaipurple-35';
if (index == 36) return '!bg-uaipurple-36';
if (index == 37) return '!bg-uaipurple-37';
if (index == 38) return '!bg-uaipurple-38';
if (index == 39) return '!bg-uaipurple-39';
if (index == 40) return '!bg-uaipurple-40';
if (index == 41) return '!bg-uaipurple-41';
if (index == 42) return '!bg-uaipurple-42';
if (index == 43) return '!bg-uaipurple-43';
if (index == 44) return '!bg-uaipurple-44';
if (index == 45) return '!bg-uaipurple-45';
if (index == 46) return '!bg-uaipurple-46';
if (index == 47) return '!bg-uaipurple-47';
if (index == 48) return '!bg-uaipurple-48';
if (index == 49) return '!bg-uaipurple-49';
if (index == 50) return '!bg-uaipurple-50';
if (index == 1) return '!bg-uaiblue-1';
if (index == 2) return '!bg-uaiblue-2';
if (index == 3) return '!bg-uaiblue-3';
if (index == 4) return '!bg-uaiblue-4';
if (index == 5) return '!bg-uaiblue-5';
if (index == 6) return '!bg-uaiblue-6';
if (index == 7) return '!bg-uaiblue-7';
if (index == 8) return '!bg-uaiblue-8';
if (index == 9) return '!bg-uaiblue-9';
if (index == 10) return '!bg-uaiblue-10';
if (index == 11) return '!bg-uaiblue-11';
if (index == 12) return '!bg-uaiblue-12';
if (index == 13) return '!bg-uaiblue-13';
if (index == 14) return '!bg-uaiblue-14';
if (index == 15) return '!bg-uaiblue-15';
if (index == 16) return '!bg-uaiblue-16';
if (index == 17) return '!bg-uaiblue-17';
if (index == 18) return '!bg-uaiblue-18';
if (index == 19) return '!bg-uaiblue-19';
if (index == 20) return '!bg-uaiblue-20';
if (index == 21) return '!bg-uaiblue-21';
if (index == 22) return '!bg-uaiblue-22';
if (index == 23) return '!bg-uaiblue-23';
if (index == 24) return '!bg-uaiblue-24';
if (index == 25) return '!bg-uaiblue-25';
if (index == 26) return '!bg-uaiblue-26';
if (index == 27) return '!bg-uaiblue-27';
if (index == 28) return '!bg-uaiblue-28';
if (index == 29) return '!bg-uaiblue-29';
if (index == 30) return '!bg-uaiblue-30';
if (index == 31) return '!bg-uaiblue-31';
if (index == 32) return '!bg-uaiblue-32';
if (index == 33) return '!bg-uaiblue-33';
if (index == 34) return '!bg-uaiblue-34';
if (index == 35) return '!bg-uaiblue-35';
if (index == 36) return '!bg-uaiblue-36';
if (index == 37) return '!bg-uaiblue-37';
if (index == 38) return '!bg-uaiblue-38';
if (index == 39) return '!bg-uaiblue-39';
if (index == 40) return '!bg-uaiblue-40';
if (index == 41) return '!bg-uaiblue-41';
if (index == 42) return '!bg-uaiblue-42';
if (index == 43) return '!bg-uaiblue-43';
if (index == 44) return '!bg-uaiblue-44';
if (index == 45) return '!bg-uaiblue-45';
if (index == 46) return '!bg-uaiblue-46';
if (index == 47) return '!bg-uaiblue-47';
if (index == 48) return '!bg-uaiblue-48';
if (index == 49) return '!bg-uaiblue-49';
if (index == 50) return '!bg-uaiblue-50';
if (index == 1) return '!bg-uaiyellow-1';
if (index == 2) return '!bg-uaiyellow-2';
if (index == 3) return '!bg-uaiyellow-3';
if (index == 4) return '!bg-uaiyellow-4';
if (index == 5) return '!bg-uaiyellow-5';
if (index == 6) return '!bg-uaiyellow-6';
if (index == 7) return '!bg-uaiyellow-7';
if (index == 8) return '!bg-uaiyellow-8';
if (index == 9) return '!bg-uaiyellow-9';
if (index == 10) return '!bg-uaiyellow-10';
if (index == 11) return '!bg-uaiyellow-11';
if (index == 12) return '!bg-uaiyellow-12';
if (index == 13) return '!bg-uaiyellow-13';
if (index == 14) return '!bg-uaiyellow-14';
if (index == 15) return '!bg-uaiyellow-15';
if (index == 16) return '!bg-uaiyellow-16';
if (index == 17) return '!bg-uaiyellow-17';
if (index == 18) return '!bg-uaiyellow-18';
if (index == 19) return '!bg-uaiyellow-19';
if (index == 20) return '!bg-uaiyellow-20';
if (index == 21) return '!bg-uaiyellow-21';
if (index == 22) return '!bg-uaiyellow-22';
if (index == 23) return '!bg-uaiyellow-23';
if (index == 24) return '!bg-uaiyellow-24';
if (index == 25) return '!bg-uaiyellow-25';
if (index == 26) return '!bg-uaiyellow-26';
if (index == 27) return '!bg-uaiyellow-27';
if (index == 28) return '!bg-uaiyellow-28';
if (index == 29) return '!bg-uaiyellow-29';
if (index == 30) return '!bg-uaiyellow-30';
if (index == 31) return '!bg-uaiyellow-31';
if (index == 32) return '!bg-uaiyellow-32';
if (index == 33) return '!bg-uaiyellow-33';
if (index == 34) return '!bg-uaiyellow-34';
if (index == 35) return '!bg-uaiyellow-35';
if (index == 36) return '!bg-uaiyellow-36';
if (index == 37) return '!bg-uaiyellow-37';
if (index == 38) return '!bg-uaiyellow-38';
if (index == 39) return '!bg-uaiyellow-39';
if (index == 40) return '!bg-uaiyellow-40';
if (index == 41) return '!bg-uaiyellow-41';
if (index == 42) return '!bg-uaiyellow-42';
if (index == 43) return '!bg-uaiyellow-43';
if (index == 44) return '!bg-uaiyellow-44';
if (index == 45) return '!bg-uaiyellow-45';
if (index == 46) return '!bg-uaiyellow-46';
if (index == 47) return '!bg-uaiyellow-47';
if (index == 48) return '!bg-uaiyellow-48';
if (index == 49) return '!bg-uaiyellow-49';
if (index == 50) return '!bg-uaiyellow-50';
if (index == 1) return '!bg-uailightblue-1';
if (index == 2) return '!bg-uailightblue-2';
if (index == 3) return '!bg-uailightblue-3';
if (index == 4) return '!bg-uailightblue-4';
if (index == 5) return '!bg-uailightblue-5';
if (index == 6) return '!bg-uailightblue-6';
if (index == 7) return '!bg-uailightblue-7';
if (index == 8) return '!bg-uailightblue-8';
if (index == 9) return '!bg-uailightblue-9';
if (index == 10) return '!bg-uailightblue-10';
if (index == 11) return '!bg-uailightblue-11';
if (index == 12) return '!bg-uailightblue-12';
if (index == 13) return '!bg-uailightblue-13';
if (index == 14) return '!bg-uailightblue-14';
if (index == 15) return '!bg-uailightblue-15';
if (index == 16) return '!bg-uailightblue-16';
if (index == 17) return '!bg-uailightblue-17';
if (index == 18) return '!bg-uailightblue-18';
if (index == 19) return '!bg-uailightblue-19';
if (index == 20) return '!bg-uailightblue-20';
if (index == 21) return '!bg-uailightblue-21';
if (index == 22) return '!bg-uailightblue-22';
if (index == 23) return '!bg-uailightblue-23';
if (index == 24) return '!bg-uailightblue-24';
if (index == 25) return '!bg-uailightblue-25';
if (index == 26) return '!bg-uailightblue-26';
if (index == 27) return '!bg-uailightblue-27';
if (index == 28) return '!bg-uailightblue-28';
if (index == 29) return '!bg-uailightblue-29';
if (index == 30) return '!bg-uailightblue-30';
if (index == 31) return '!bg-uailightblue-31';
if (index == 32) return '!bg-uailightblue-32';
if (index == 33) return '!bg-uailightblue-33';
if (index == 34) return '!bg-uailightblue-34';
if (index == 35) return '!bg-uailightblue-35';
if (index == 36) return '!bg-uailightblue-36';
if (index == 37) return '!bg-uailightblue-37';
if (index == 38) return '!bg-uailightblue-38';
if (index == 39) return '!bg-uailightblue-39';
if (index == 40) return '!bg-uailightblue-40';
if (index == 41) return '!bg-uailightblue-41';
if (index == 42) return '!bg-uailightblue-42';
if (index == 43) return '!bg-uailightblue-43';
if (index == 44) return '!bg-uailightblue-44';
if (index == 45) return '!bg-uailightblue-45';
if (index == 46) return '!bg-uailightblue-46';
if (index == 47) return '!bg-uailightblue-47';
if (index == 48) return '!bg-uailightblue-48';
if (index == 49) return '!bg-uailightblue-49';
if (index == 50) return '!bg-uailightblue-50';
if (index == 1) return '!bg-uaiblack-1';
if (index == 2) return '!bg-uaiblack-2';
if (index == 3) return '!bg-uaiblack-3';
if (index == 4) return '!bg-uaiblack-4';
if (index == 5) return '!bg-uaiblack-5';
if (index == 6) return '!bg-uaiblack-6';
if (index == 7) return '!bg-uaiblack-7';
if (index == 8) return '!bg-uaiblack-8';
if (index == 9) return '!bg-uaiblack-9';
if (index == 10) return '!bg-uaiblack-10';
if (index == 11) return '!bg-uaiblack-11';
if (index == 12) return '!bg-uaiblack-12';
if (index == 13) return '!bg-uaiblack-13';
if (index == 14) return '!bg-uaiblack-14';
if (index == 15) return '!bg-uaiblack-15';
if (index == 16) return '!bg-uaiblack-16';
if (index == 17) return '!bg-uaiblack-17';
if (index == 18) return '!bg-uaiblack-18';
if (index == 19) return '!bg-uaiblack-19';
if (index == 20) return '!bg-uaiblack-20';
if (index == 21) return '!bg-uaiblack-21';
if (index == 22) return '!bg-uaiblack-22';
if (index == 23) return '!bg-uaiblack-23';
if (index == 24) return '!bg-uaiblack-24';
if (index == 25) return '!bg-uaiblack-25';
if (index == 26) return '!bg-uaiblack-26';
if (index == 27) return '!bg-uaiblack-27';
if (index == 28) return '!bg-uaiblack-28';
if (index == 29) return '!bg-uaiblack-29';
if (index == 30) return '!bg-uaiblack-30';
if (index == 31) return '!bg-uaiblack-31';
if (index == 32) return '!bg-uaiblack-32';
if (index == 33) return '!bg-uaiblack-33';
if (index == 34) return '!bg-uaiblack-34';
if (index == 35) return '!bg-uaiblack-35';
if (index == 36) return '!bg-uaiblack-36';
if (index == 37) return '!bg-uaiblack-37';
if (index == 38) return '!bg-uaiblack-38';
if (index == 39) return '!bg-uaiblack-39';
if (index == 40) return '!bg-uaiblack-40';
if (index == 41) return '!bg-uaiblack-41';
if (index == 42) return '!bg-uaiblack-42';
if (index == 43) return '!bg-uaiblack-43';
if (index == 44) return '!bg-uaiblack-44';
if (index == 45) return '!bg-uaiblack-45';
if (index == 46) return '!bg-uaiblack-46';
if (index == 47) return '!bg-uaiblack-47';
if (index == 48) return '!bg-uaiblack-48';
if (index == 49) return '!bg-uaiblack-49';
if (index == 50) return '!bg-uaiblack-50';
            
    }
 export {generateColors}