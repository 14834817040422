import { useMotionValue, useSpring, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
const AnimatedSlider = ({ value = 0, className="" , min=0, max=100, onUserChange=(e) => {}})=> {
  const ref = useRef(null);
  const motionValue = useMotionValue(0);
  const springValue = useSpring(motionValue);

  useEffect(() => {
    motionValue.set(value);
  }, [motionValue, value]);

  useEffect(
    () =>
      springValue.onChange((latest) => {
        if (ref.current) {
          ref.current.value = latest;
        }
      }),
    [springValue]
  );

  return (
    <motion.input   ref={ref} type="range" min={min} max={max}
    onChange={(e) => {
        onUserChange(e);
        // const newTime = (e.target.value / 100 )* videoview.current.duration;
        // videoview.current.currentTime  =  newTime;
      // const newTime = (e.target.value / 100 )* videoview.current.duration;
        // videoview.current.currentTime  =  newTime;
    }}
    className={`slider ${className}`}/>
  
  )
  ;
}

export default AnimatedSlider;