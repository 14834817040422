import { useState, useRef } from 'react';
import { Checkbox } from "@material-tailwind/react";
import KeyframeButton from '../keyframeButton';


const  KeyframedNumberInput = ({id, placeholder, onChange=(e)=>{}, inputClassName, value, min=-10000, max=10000, step=1, keyframeable=true}) => {

    return (<KeyframeButton keyframeable={keyframeable}>
        <input type='number' name={id} placeholder={placeholder} onChange={onChange} data-form-field={id}
            className={`!h-[20px] p-0 pl-2 appBlueBorder text-blue-500 ${inputClassName}`}
            value={value} min={min} max={max} step={step} id={id} />
    </KeyframeButton>)
}


const LineEdit = ({ value="",  placeholder="", onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= "", inputType="text" , id= "", keyframeable=true }) => {

    
    

    
    return (
        <InputContainer label={label} labelClassName={labelClassName} className={className}>
            <KeyframeButton keyframeable={keyframeable}>
        <input type={inputType} name={id} placeholder={placeholder} onChange={onChange} data-form-field={id}  
         className={`!h-[20px] p-0 pl-2  appBlueBorder text-blue-500 ${inputClassName}`}
     value={value} id={id}/>
     </KeyframeButton>
        </InputContainer>
    );

}

const InputContainer = ({ children, className= "" ,  labelClassName= "" , label= "" , keyframeable=true}) => {

    

    
    return (
        <div className={`col-lg-12 col-md-12 col-sm-12 form-group ${className}`}>
        <label className={`form-control-label mbr-fonts-style display-7 !text-white ${labelClassName}`}>{label}</label>
        {children}
        </div>
    );

}

const InputContainerHorizontal = ({ children, className= "" ,  labelClassName= "" , label= "" , keyframeable=true}) => {

    

    
    return (
        <div className={`col-lg-12 col-md-12 col-sm-12 form-group flex flex-col justify-start  items-start ${className}`}>
        <label className={`form-control-label mbr-fonts-style display-7 !text-white mb-0 ${labelClassName}`}>{label}</label>
        {children}
        </div>
    );

}


const SpinBoxEdit = ({ value=0, placeholder=0,min=0, max=100, onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= "", inputType="text" , id= "" , keyframeable=true}) => {

    

    
    return (

        <InputContainer label={label} labelClassName={labelClassName} className={className}>
        <KeyframedNumberInput id={id} placeholder={placeholder} onChange={onChange} inputClassName={inputClassName} value={value} min={min} max={max} step={1} />
        </InputContainer>
    );

}

const DoubleSpinBoxEdit = ({ value=0, placeholder=0,min=0, max=100, onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= "", inputType="text" , id= "" , keyframeable=true}) => {

    return (
        <InputContainer label={label} labelClassName={labelClassName} className={className}>


        <KeyframedNumberInput id={id} placeholder={placeholder} onChange={onChange} inputClassName={inputClassName} value={value} min={min} max={max} step={0.01} keyframeable={keyframeable} />

        </InputContainer>
    );

}


const TextAreaEdit = ({ value="", placeholder="",onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= ""  , id= "" , keyframeable=true}) => {

    
    return (
<InputContainer label={label} labelClassName={labelClassName} className={`${className}`}>
<KeyframeButton keyframeable={keyframeable}>

<textarea name="negativeprompt"  rows="4" onChange={onChange} placeholder={placeholder} data-form-field={id}
 className={`!h-40  p-0 pl-2  appBlueBorder text-white h-full ${inputClassName}`}
 value={value}  id={id}></textarea>
            </KeyframeButton>

</InputContainer>

    );

}

const CheckEdit = ({ value=false, onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= ""  , id= "", keyframeable=true }) => {

    
    return (
        
<InputContainerHorizontal label={label} labelClassName={labelClassName} className={className}>
<KeyframeButton keyframeable={keyframeable}>

        <Checkbox onChange={onChange} defaultChecked={value} containerProps={{className:"!px-0"}} className={` text-blue-500 !p-0 ${inputClassName}`}  id={id}/>
        </KeyframeButton>
        
        </InputContainerHorizontal>
    );

}

const SelectEdit = ({ value="", onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= ""  , id= "", options=[] , keyframeable=true}) => {

    return (
        <InputContainer label={label} labelClassName={labelClassName} className={className}>
            <KeyframeButton keyframeable={keyframeable}>

		<select
		name={id}
		class={`appBlueBorder w-full text-[#8a8aa0]  ${inputClassName}`}
		id={id}
		onChange={onChange}
		>
            {options.map((option, index) => {
                return (
                    <option className="" key={index} value={option}>{option}</option>
                );
            })}
		</select>
        </KeyframeButton>

        </InputContainer>
    );

}


const FileInputEdit = ({ value="", onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= ""  , id= "", base64=false , keyframeable=true}) => {
    const inputField  = useRef();
    const [file, setFile] = useState(value);
    
    return (
        
<InputContainer label={label} labelClassName={labelClassName} className={className}>
<KeyframeButton keyframeable={keyframeable}>

        <input ref={inputField} type="file"  name={id} onChange={(e)=>{
                const fileName = e.target.files[0].name;
                setFile(fileName);
            console.log(e);
            console.log(e.target.files[0]);
            console.log(base64);
            if(base64){
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    onChange(reader.result);
                };
            }else{
                onChange(e.target.files[0]);
            }
        }} data-form-field={id}  
        className={`!h-[20px] p-0 pl-2 hidden  appBlueBorder text-blue-500 ${inputClassName}`}
    id={id}/>
    <button onClick={()=>{ 
        inputField.current.click();
    }} className="btn btn-primary display-7  m-0 flex">Choose File</button>
    <p className="text-white w-40 !truncate">{file}</p>

            </KeyframeButton>

        </InputContainer>
    );

}

const ButtonEdit = ({ label="Generate", onClick=(e)=>{}, ref=undefined, className= "" ,  labelClassName= "" ,inputClassName= ""  , id= "" , keyframeable=true}) => {

    return (
        <div class={`col-auto m-0 ${className}`}>
		<button
		ref={ref}
		onClick={onClick}
		id={id}
		className={`w-100 w-100 w-100 btn btn-primary display-7  m-0 flex ${inputClassName}`}
		>
		{label}
		</button>
	</div>
    );

}




const VectorEdit = ({ value=[0, 0], onChange=(e)=>{}, float=true, className= "" ,  labelClassName= "" ,inputClassName= "" , label= "" , id= "" , keyframeable=true}) => {
    const handleInputChange = (e, index) => {
        const newValue = [...value];
        if(float){
        newValue[index] = parseFloat(e.target.value);
        }else{
            newValue[index] = parseInt(e.target.value);
        }
        onChange(newValue);
    };

    return (
        
<InputContainer label={label} labelClassName={labelClassName} className={className}>
            <div className="flex space-x-2">
                {
                    value.map((item, index) => {
                        return (
                            <KeyframedNumberInput id={`${id}_${index}`} placeholder={item} onChange={(e) => handleInputChange(e, index)} inputClassName={inputClassName} value={item} min={-10000} max={100000} step={0.01} keyframeable={keyframeable} />
                        );
                    })
                }
            </div>
        </InputContainer>
    );
}

const Vector2Edit = ({ value=[0, 0], float=true, onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= "" , id= "" , keyframeable=true}) => {
    const handleInputChange = (e, index) => {
        onChange(e);
    };

    return (
<VectorEdit value={value}  float={float} onChange={handleInputChange} className={className} labelClassName={labelClassName} inputClassName={inputClassName} label={label} id={id}  keyframeable={keyframeable} />
    );
}

const Vector3Edit = ({ value=[0, 0, 0], float=true, onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= "" , id= "" , keyframeable=true}) => {
    const handleInputChange = (e) => {
        onChange(e);
    };

    return (
        <VectorEdit value={value} float={float} onChange={handleInputChange} className={className} labelClassName={labelClassName} inputClassName={inputClassName} label={label} id={id} keyframeable={keyframeable} />
    );
}

const Vector4Edit = ({ value=[0, 0, 0, 0], float=true, onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= "" , id= "", keyframeable=true }) => {
    const handleInputChange = (e, index) => {
        onChange(e);

    };

    return (
        
        <VectorEdit value={value} float={float} onChange={handleInputChange} className={className} labelClassName={labelClassName} inputClassName={inputClassName} label={label} id={id} keyframeable={keyframeable}  />

    );
}

const ColorEdit = ({ value="#000000", onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= "" , id= "", keyframeable=true }) => {
    return (
        <InputContainer label={label} labelClassName={labelClassName} className={className}>
            <KeyframeButton keyframeable={keyframeable}>

            <input type='color' onChange={onChange} className={`!h-[20px] p-0 pl-2  appBlueBorder text-blue-500 ${inputClassName}`} value={value} id={id} />
            </KeyframeButton>
        
        </InputContainer>
    );
}

const Color4Edit = ({ value={ color: "#000000", alpha: 1.0 }, onChange=(e)=>{}, className= "" ,  labelClassName= "" ,inputClassName= "" , label= "" , id= "" , keyframeable=true }) => {
    const handleColorChange = (e) => {
        onChange({ ...value, color: e.target.value });
    };

    const handleAlphaChange = (e) => {
        onChange({ ...value, alpha: parseFloat(e.target.value) });
    };

    return (
        <InputContainer label={label} labelClassName={labelClassName} className={className}>
            <div className="flex space-x-2">
            <KeyframeButton keyframeable={keyframeable}>

                <input type='color' onChange={handleColorChange} className={`!h-[20px] p-0 pl-2  appBlueBorder text-blue-500 ${inputClassName}`} value={value.color} id={`${id}_color`} />
            </KeyframeButton>
            <KeyframeButton keyframeable={keyframeable}>
                
                <input type='number' min={0} max={1} step={0.01} onChange={handleAlphaChange} className={`!h-[20px] p-0 pl-2  appBlueBorder text-blue-500 ${inputClassName}`} value={value.alpha} id={`${id}_alpha`} />
                </KeyframeButton>
            
            </div>
        </InputContainer>
    );
}














export { 
    LineEdit, SpinBoxEdit, TextAreaEdit, CheckEdit, SelectEdit, FileInputEdit, ButtonEdit, DoubleSpinBoxEdit
    , Vector2Edit, Vector3Edit, Vector4Edit, ColorEdit, Color4Edit,
    KeyframedNumberInput


};

