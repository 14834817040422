import CartGrid from "./cartGrid";

const CardItem = ({ image, name, quantity, price, tax, total, commands }) => {
    return (
<div className="content-ranking">
    <CartGrid image={<div className="image"><img src={ image } alt="Binasea" /></div>} name={name} quantity={quantity} price={price} tax={tax} total={total} commands={commands} classOverride={"fontsize-small"} />
                                            </div>

);
};

export default CardItem;