import { TimelineAction, TimelineEffect, TimelineRow } from '@xzdarcy/react-timeline-editor';
import audioControl from '../../../../utils/audioController';

export const scaleWidth = 160;
export const scale = 5;
export const startLeft = 20;

export interface CustomTimelineAction extends TimelineAction {
  data: {
    src: string;
    name: string;
  };
}

export interface CusTomTimelineRow extends TimelineRow {
  actions: CustomTimelineAction[];
}

export const audioTimelineMockEffect: Record<string, TimelineEffect> = {
  audio: {
    id: 'audio',
    name: 'audio',
    source: {
      start: ({ action, engine, isPlaying, time }) => {
        if (isPlaying) {
          console.log(action);
          const src = action.data.src;
          audioControl.start({ id: src, src, startTime: action.start, engine, time });
        }
      },
      enter: ({ action, engine, isPlaying, time }) => {
        if (isPlaying) {
          const src = action.data.src;
          audioControl.start({ id: src, src, startTime: action.start, engine, time });
        }
      },
      leave: ({ action, engine }) => {
        const src = action.data.src;
        audioControl.stop({ id: src, engine });
      },
      stop: ({ action, engine }) => {
        const src = action.data.src;
        audioControl.stop({ id: src, engine });
      },
    },
  },
  // effect1: {
  //   id: 'effect1',
  //   name: 'image',
  //   source: {
  //     enter: ({ action, time }) => {
  //       const src = action.data.src;
  //       lottieControl.enter({ id: src, src, startTime: action.start, endTime: action.end, time });
  //     },
  //     update: ({ action, time }) => {
  //       const src = action.data.src;
  //       lottieControl.update({ id: src, src, startTime: action.start, endTime: action.end, time });
  //     },
  //     leave: ({ action, time }) => {
  //       const src = action.data.src;
  //       lottieControl.leave({ id: src, startTime: action.start, endTime: action.end, time });
  //     },
  //   },
  // },
};

export const emptyTimeline: CusTomTimelineRow[] = [
];

export const audioTimelineMockData: CusTomTimelineRow[] = [
  
  {
    id: '1',
    actions: [
      {
        id: 'action1',
        start: 0,
        end: 20,
        effectId: 'audio',
        data: {
          src: 'https://firebasestorage.googleapis.com/v0/b/uai-backend.appspot.com/o/stableV%2F151%2F2432-c4bc%2Fassets%2Faudio%2Fvocals.wav?alt=media',
          name: 'Vocals',
          components:[
            {
                "name":"Mix",
                "id":"uai.builtin.audio.base",
                "uuid": "e9d1f244-682d",
                "properties":[
                {"name": "Volume",
                "value": 2.1},
                {"name": "Balance",
                "value": -0.164},
                {"name": "Mute",
                "value": false}
                ]
                },
            {
                "name":"Transform",
                "id":"uai.builtin.transform.base",
                "uuid": "c597a348-b7c6",
                "properties":[
                {"name": "Flip Y",
                "value": true},
                ]
                },
            {
                "name":"PBR BSDF Material",
                "id":"uai.builtin.pbrbsdfmaterial",
                "uuid": "32a0fa46-1ce4",
                "properties":[
                {"name": "uvBaseColor",
                "value": [1.1,0.2]},
                {"name": "baseColorTexture",
                "value": "img.png"},
                ]
                },
          ],
          keyframes:[
            {
              "uuid": "e9d1f244-682d",
              "properties":[
              {"name": "Volume",
              "value": 1.0},
              ],
              "interpolation":"linear",
              "frame": 3,
              "enabled": true,
              "group": "",
              "color": "#ff0000",
              "code": "",
            },
            
            {
              "uuid": "e9d1f244-682d",
              "properties":[
              {"name": "Volume",
              "value": 0.0},
              ],
              "interpolation":"linear",
              "frame": 20,
              "enabled": true,
              "group": "",
              "color": "#ff0000",
              "code": "",
            },

          ]
        },

      },
    ],
  },{
    id: '2',
    actions: [
      {
        id: 'action2',
        start: 0,
        end: 20,
        effectId: 'audio',
        data: {
          src: 'https://firebasestorage.googleapis.com/v0/b/uai-backend.appspot.com/o/stableV%2F151%2F2432-c4bc%2Fassets%2Faudio%2Fdrums.wav?alt=media&token=089e8e78-ec6f-41bc-96cf-ec6ed0c845b2',
          name: 'Drums',
        },
        
          components:[
            {
                "name":"Mix",
                "id":"uai.builtin.audio.base",
                "uuid": "98a28c45-f68d",
                "properties":[
                {"name": "Volume",
                "value": 0.0},
                {"name": "Balance",
                "value": 0},
                {"name": "Mute",
                "value": false}
                ]
                }
          ],
          keyframes:[
      

          ]
      },
    ],
  },{
    id: '3',
    actions: [
      {
        id: 'action3',
        start: 0,
        end: 20,
        effectId: 'audio',
        data: {
          src: 'https://firebasestorage.googleapis.com/v0/b/uai-backend.appspot.com/o/stableV%2F151%2F2432-c4bc%2Fassets%2Faudio%2Fother.wav?alt=media&token=2fdf6a4d-cb14-4e91-a02b-fc4a916b6b23',
          name: 'Other',
        },
        
        components:[
          {
              "name":"Mix",
              "id":"uai.builtin.audio.base",
              "uuid": "73d2810e-3de2",
              "properties":[
              {"name": "Volume",
              "value": 0.0},
              {"name": "Balance",
              "value": 0},
              {"name": "Mute",
              "value": false}
              ]
              }
        ],
        keyframes:[
    

        ]
      },
    ],
  },{
    id: '3',
    actions: [
      {
        id: 'action4',
        start: 0,
        end: 20,
        effectId: 'audio',
        data: {
          src: 'https://firebasestorage.googleapis.com/v0/b/uai-backend.appspot.com/o/stableV%2F151%2F2432-c4bc%2Fassets%2Faudio%2Fbass.wav?alt=media&token=4fa2d15b-5dec-4c32-a9d9-b648b906275b',
          name: 'Bass',
        },
        components:[
          {
              "name":"Mix",
              "id":"uai.builtin.audio.base",
              "uuid": "fb1fad41-6e37",
              "properties":[
              {"name": "Volume",
              "value": 0.0},
              {"name": "Balance",
              "value": 0},
              {"name": "Mute",
              "value": false}
              ]
              }
        ],
        keyframes:[
    

        ]
      },
    ],
  },
];

export const audioTimelineMockSequence = {
  id: '67f19d41-70f4',
  project: '06e5-f218',
  name: 'Audio Sequence',
  thumbnail: '',
  comments: '',
  description: '',
  timeline: audioTimelineMockData,
  version: 1.0,
  settings: {
    scale: 5,
    scaleWidth: 160,
    startLeft: 20,
    fps: 24,
    in:0,
    out:20,
    render:{
        width: 1920,
        height: 1080,
        type: "video",
        codec: "h264",
        quality: 100,
        audio: true,
        audioCodec: "aac",
        audioQuality: 100,
        audioBitrate: 320,
        audioSampleRate: 44100,
        audioChannels: 2,
        audioVolume: 1.0,
        outputPath: "render/01/output.mp4",

    },
    
  }
};