import React , {useState} from 'react';
import TOS from '../uai/constants/tos';
import PropTypes from 'prop-types';
import icon1 from '../assets/images/svg/icon-create-5.svg'
import icon2 from '../assets/images/svg/icon-create-6.svg'
import icon3 from '../assets/images/svg/icon-create-3.svg'
import icon4 from '../assets/images/svg/icon-create-7.svg'
import icon5 from '../assets/images/svg/icon-create-8.svg'
import icon6 from '../assets/images/svg/icon-create-9.svg'
import { Link } from 'react-router-dom';

TOSPage.propTypes = {
    
};

function TOSPage(props) {
   
    return (
        <div>
            <section className="tf-page-title style-2">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">

                            <ul className="breadcrumbs">
                                <li><Link to="/">Home</Link></li>
                                <li>Terms of Service</li>
                            </ul>
                   
                        </div>
                    </div>
                </div>                    
            </section>
                
            <section className="tf-help-center">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12">


                            <TOS />
                            
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default TOSPage;