import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';
import ContentInfo from '../uai/components/ContentInfo/ContentInfo'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setProducts } from '../reducers/cartReducer';

import img1 from '../assets/images/details/imagegen/StableV_ImageGen_03.png'
import computerRoom_01 from "../assets/images/uaipage/computerRoom_01.png";
import computerRoom_02 from "../assets/images/uaipage/computerRoom_02.png";
import computerRoom_03 from "../assets/images/uaipage/computerRoom_03.png";
import computerRoom_04 from "../assets/images/uaipage/computerRoom_04.png";
import computerRoom_05 from "../assets/images/uaipage/computerRoom_05.png";
import computerRoom_06 from "../assets/images/uaipage/computerRoom_06.png";
import { uaiLicense } from '../uai/constants/products';

Details_ImageGen.propTypes = {
    
};

function Details_ImageGen(props) {

  const dispatch = useDispatch();
  const products = useSelector(state => state.appState.products);


  const LoadUAIPage = (e) => {
    e.preventDefault();
    const newProducts = {
      ... products,
    }
    newProducts.currentProduct = uaiLicense;
    dispatch(setProducts(newProducts));
    window.location = "/productDetails" ;

  }
    return (
        <div>

            <PageTitle sub='Learn More' title='UAI Toolkit' />
            
          <ContentInfo title="UAI TOOLKIT" subtitle="Industry Leading Gen AI Software Toolkit & Web API " bodytext="Experience the future of creative production with UAI, the industry-leading GenAI toolkit. From large-scale organizations to individual indie artists, UAI empowers you to generate, augment, and customize media like never before. Dive into an extensive range of AI processes, from production design to visualization, and ignite your imagination. Develop custom AI models using your own content, expanding the possibilities of your creations.    " bgImage={computerRoom_01} showTopRow={true} showSubtitle={true} shadowHeightTop={0} shadowHeightBottom={550} >
          <div className={"btn-slider marginAuto flex flex-col gap-10"}>
                                                        <Link to="/uaiproduct" onClick={LoadUAIPage} className="tf-button style-2 yellow-border pointer button-uai"><i className="fa fa-circle-info"></i> PURCHASE A LICENSE NOW</Link>
                                                        <Link to="/downloads" className="tf-button style-2 yellow-border pointer button-uai"><i className="fa fa-circle-info"></i> DOWNLOADS</Link>
                                                    </div>
            </ContentInfo>
            
          <ContentInfo title="Apps" subtitle="" bodytext="The UAI toolkit includes production-ready applications and plugins that seamlessly integrate into your existing workflow . Elevate your creative projects with UAI's cutting-edge tools, designed to enhance your media production and streamline your workflow." bgImage={computerRoom_03} showTopRow={true} showSubtitle={false} height='60vh'>
            </ContentInfo>
          <ContentInfo title="Tokens" subtitle="Top off your tokens now!" bodytext="Empower your creativity with UAI's 3000 Global Tokens per month, included in every license. Whether you're without an Nvidia GPU, using the Web API, or conserving processing power, these tokens empower you to generate and augment media seamlessly. Global Tokens roll over monthly, ensuring your creative journey remains uninterrupted. Need more tokens? Additional tokens are available for purchase, offering endless possibilities for your projects." bgImage={computerRoom_04} showTopRow={true} showSubtitle={false}   height='70vh'>
          <div className={"btn-slider marginAuto flex flex-col gap-10"}>
                                                        <Link to="/uaiproduct" onClick={LoadUAIPage} className="tf-button style-2 yellow-border pointer button-uai"><i className="fa fa-circle-info"></i> PURCHASE TOKENS NOW</Link>
                                                    </div></ContentInfo>
          <ContentInfo title="Web API" subtitle="Check out the documentation here." bodytext="Transform your creative vision into reality with UAI's Web API. Build and customize AI tools and experiences effortlessly through simple API requests. Powered by Nvidia A100 GPUs, the Web API enables you to process and generate media with speed and precision. By utilizing the Web API, you can streamline your workflow, ensuring efficient media production while maintaining optimal performance on your main device." bgImage={computerRoom_05} showTopRow={true} showSubtitle={true} shadowHeightTop={392} shadowHeightBottom={500} shadowBottom={-166} shadowTop={-243}>
            
          <div className={"btn-slider marginAuto flex flex-col gap-10"}>
                                                        <Link to="https://uai.software/docs/Modules/Overview" className="tf-button style-2 yellow-border pointer button-uai"><i className="fa fa-circle-info"></i> DOCUMENTATION</Link>
                                                        <Link to="/docs-stablev-imagegen" className="tf-button style-2 yellow-border pointer button-uai"><i className="fa fa-circle-info"></i> TUTORIALS</Link>
                                                    </div>
            </ContentInfo>

            
          <ContentInfo title="Training" subtitle="Create hyper personalized media" bodytext="Make your media truly yours with UAI's custom AI model training apps. Create a custom AI model using your own data, whether it's photos of yourself, your pet, or anything else. Stand out from the crowd by seamlessly integrating personalized elements into your images and videos, captivating your audience with content that resonates on a deeper level. Elevate your creative projects with UAI's training feature, unlocking endless possibilities for customization and creativity." bgImage={computerRoom_06} showTopRow={true} showSubtitle={true} shadowHeightTop={392} shadowHeightBottom={550} shadowBottom={-166} shadowTop={-243}>
          <div className={"btn-slider marginAuto flex flex-col gap-10"}>
                                                        <Link to="https://uai.software/docs/Modules/Overview" className="tf-button style-2 yellow-border pointer button-uai"><i className="fa fa-circle-info"></i> DOCUMENTATION</Link>
                                                        <Link to="/docs-stablev-imagegen" className="tf-button style-2 yellow-border pointer button-uai"><i className="fa fa-circle-info"></i> TUTORIALS</Link>
                                                    </div>
            </ContentInfo>


          <ContentInfo title="Start A Free Trial" subtitle="Join us in the future of creative media" bodytext="Get started on your creative journey with UAI's free trial. Experience the power of GenAI firsthand, exploring the toolkit's extensive features and capabilities. Dive into the world of AI-driven media production, discovering new ways to enhance your creative projects. With UAI's 7 day free trial, you can unleash your imagination and transform your ideas into reality, all with the industry-leading GenAI toolkit at your fingertips." bgImage={computerRoom_02} showTopRow={true} showSubtitle={true}  shadowHeightTop={392} shadowHeightBottom={550} shadowBottom={-166} shadowTop={-243} height='80vh'>
          <div className={"btn-slider marginAuto flex flex-col gap-10"}>
                                                        <Link to="/uaiproduct" onClick={LoadUAIPage} className="tf-button style-2 yellow-border pointer button-uai"><i className="fa fa-circle-info"></i> GET A LICENSE</Link>
                                                    </div>
            </ContentInfo>
        </div>
    );
}

export default Details_ImageGen;