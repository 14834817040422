import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import AppMenu from "../../pages/appMenu";
import UAIModules from "../../uai/components/UAIModules/uaiModules";
import { setHideNav } from "../../reducers/cartReducer";
import { useAPIKey } from "../../uai/api/useAPIKey";
import { useAuth } from "../../uai/api/useAuth";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

import NavMenuItem from "./menuItem";

import { faGem } from "@fortawesome/free-solid-svg-icons";
import AnimatedNumber from "../../uai/components/AnimatedNumber";
import AnimatedText from "../../uai/components/AnimatedText";
import useRuntime from "../../uai/components/UAIAPP/api/useRuntime";
import { useSignals } from "../../uai/api/signalSlots";
// import Button from '../button';
import { useSignal, useSlot } from 'react-signal-slot';
import {useHotkeys} from 'react-hotkeys-hook';
const Header = ({ manager = undefined }) => {
    const isDirty = useRuntime((state) => state.appTimeline.isDirty);
    const project = useRuntime((state) => state.project);
    const projectPathName = useRuntime((state) => state.projectPathName);
    const sequence = useRuntime((state) => state.sequence);
    const signal = useSignal();
    const moduleClicked = (module) => {
        switch (module) {
            case "logout":
                window.location.href = "/logout";

                break;

            case "account":
                window.location.href = "/dashboard";
                break;

            case "quit":
                window.location.href = "/dashboard";
                break;

            default:
                break;
        }
    };


    const menuItemClicked = (module) => {
        switch (module) {
            case "logout":
                window.location.href = "/logout";
                break;

            case "account":
                window.location.href = "/dashboard";
                break;

            case "file.quit":
                dispatch(setHideNav(false));
                localStorage.removeItem("hideNav");
                window.location.href = "/dashboard";
                console.log("quit");
                break;

            default:
                break;
        }
    };

    const state = useSelector((state) => state);
    const [tokens, setTokens] = useState(0);
    // const menus = AppMenu(moduleClicked, menuItemClicked);
    // const menus = AppMenu(moduleClicked, menuItemClicked);
    const uaimenu = UAIModules(
        (module_) => {
            moduleClicked(module_);
        },
        (result) => {},
        (menu_) => {},
        (functionName, value, onFinished = (res) => {}) => {},
        (id, onFinished = (res) => {}) => {},
        (id, onFinished = (res) => {}) => {}
    );
    const {
        pending: isLoading,
        isSignedIn: isAuthenticated,
        user: user,
        auth: auth_,
    } = useAuth();
    const { apiKey, verified, isLoading: apiKeyIsVerifying } = useAPIKey();

    // const { loginWithRedirect, logout, user, isAuthenticated ,isLoading } = useAuth0();
    const dispatch = useDispatch();
    const [scroll, setScroll] = useState(false);
    const [loading, setLoading] = useState(true);
    useSlot("refresh.token",
    (e) => {
        getTokens();
    })
    
    const signals = useSignals();
    const getTokens = () => {
        signal("get.user.tokens", { "onFinished":(res) => {
            setTokens(res);
     } });
    };

    useEffect(() => {
        if (state.appState.hideNav !== true) {
            dispatch(setHideNav(true));
            window.location.reload();
        }
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        };
    }, []);

    useEffect(() => {}, [manager.props.user.tokens]);

    const [openMenu, setOpenMenu] = React.useState(false);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };

    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = (index) => {
        // console.log('index', index);
        setActiveIndex(index);
    };
    useEffect(() => {
        // console.log('activeIndex', activeIndex);
    }, [activeIndex]);

    const idActive = (id) => {
        const split = id.split(".");
        // console.log("=================");

        // console.log(id, activeIndex);
        // console.log("-----------------");
        split.pop();
        const parentID = split.join(".");
        let isMatch = false;
        if (activeIndex ? activeIndex.includes(id) : false) {
            isMatch = true;
        }
        return activeIndex === parentID || isMatch;
    };

    const idParentActive = (id) => {
        const parentID = id;
        let isMatch = false;
        if (activeIndex ? activeIndex.includes(id) : false) {
            isMatch = true;
        }
        return activeIndex === parentID || isMatch;
    };
    const cont = () => {};
    const generateSubMenu = (data) => {
        console.log("data", data.sub);

        return (
            <>
                {data.namesub.map((submenu, idx) => (
                    <NavMenuItem manager={manager} data={data}></NavMenuItem>
                ))}
            </>
        );
    };

    const generateItem = (data, idx) => {
        return (
            <li
                key={idx}
                onClick={() => handleDropdown(idx)}
                className={`menu-item ${
                    data.namesub ? "menu-item-has-children" : ""
                } ${idActive(data.id) ? "active" : ""}`}
            >
                <Link to={data.links}>{data.name}</Link>
                {data.namesub && (
                    <ul className="sub-menu">
                        {data.namesub.map((submenu) => (
                            <li key={submenu.id} className="menu-item">
                                <NavLink to={submenu.links}>
                                    {submenu.sub}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                )}
            </li>
        );
    };

    return (
        <>
            <header
                className={`header ${
                    scroll ? "is-fixed" : ""
                } h-18 bg-[#0A0B10] blueborder-bottom  !w-[100%] pl-2`}
            >
                <div className="tf-container h-full !w-full  !bg-[#0A0B10] z-50">
                    <div className="row h-full !w-full">
                        <div className=" !w-full flex flex-col justify-center ">
                            <div id="site-header-inner h-full !w-full">
                                <div className="header-center h-full !w-full">
                                    <nav
                                        id="main-nav"
                                        className={` !w-full flex flex-row main-nav items-center ${
                                            menuActive ? "active" : ""
                                        } relative`}
                                    >
                                    <img className="font-xs h-[10px] w-[10px] mr-3 cursor-pointer" src="images/svg/world.svg" alt="" onClick={()=>{
                                        signal('operation.openWindow', {data:{metadata:{name: 'uai.builtin.headless.app.quit'}}});
                                    }} />

                                        {[ ...uaimenu.uaimenu].map(
                                            (data, idx) => {
                                                return (
                                                    <NavMenuItem
                                                        manager={manager}
                                                        data={data}
                                                        root={true}
                                                    />
                                                );
                                            }
                                        )}
                                        <AnimatedText className=" m-auto" duration={0.01}  speed={70} value={
(isDirty ? "*" : "" )+ " "+(project ? project.name 
    ?project.name + " | " + (projectPathName ? projectPathName.includes("_") ?  "v"+projectPathName.split("_").pop().split(".")[0] : "v0000" : "v0000")+ " | " +( sequence != {} ? sequence.name ? sequence.name : "No Sequence" : "No Sequence")
    : "No Project": "No Project")

                                        }/>
                                        <div className="w-fit flex flex-row items-center  absolute  -top-1 right-[-25px] text-white">
                                            <AnimatedNumber value={tokens} />
                                            {/* <p className="text-xs">{tokens}</p> */}
                                            <motion.div
                                                className="flex flex-row items-center"
                                                whileHover={{ scale: 1.4 }}
                                                whileTap={{
                                                    scale: 0.9,
                                                    rotate: 360,
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    className="pl-1 icon-fill border-0 borer-[#b9b8bb] rounded-full hover:text-yellow-800 cursor-pointer"
                                                    icon={faGem}
                                                    onClick={() => {
                                                        // signals.emit('refresh.user');
                                                        getTokens();
                                                    }}
                                                />
                                            </motion.div>
                                        </div>
                                    </nav>
                                </div>

                                <div
                                    className={`mobile-button ${
                                        menuActive ? "active" : ""
                                    }`}
                                    onClick={handleMenuActive}
                                >
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
