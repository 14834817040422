import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
const AnimatedText = ({ value = "", className="",duration= 0.15, speed=50 , onClick=()=>{} })=> {
  const [characters, setCharacters] = useState([]);
  const [delay, setDelay] = useState(0.1);


  useEffect(
    () =>{
      const chars = value.split("");
      setCharacters(chars);
      setDelay(chars.length / duration)
    },
    [value]
  );

  return (
    
    <div className={"text-white text-sm" + className}>
    {
        characters.map((char, index) => {
            return (
                <motion.span
                    key={index}
                    className={""}
                    initial={{ opacity: 0, scale:10 }}
  animate={{ opacity: 1, scale:100 }}
  transition={{
    duration: duration,
    delay: index/ speed ,
  }}
  onClick={onClick}
                >
                    {char}
                </motion.span>
            );
        })
    }
    
                                </div>
                                
                                )
}

export default AnimatedText;