

import img1 from '../images/slider/slider-1.jpg'
import img2 from '../images/slider/slider-5.png'
import avt1 from '../images/slider/slider-4.png'

const dataBanner = [
    {
        id: 1,
        
        heading: 'Image Generation',
        desc: 'Generate and augment images with our Production ready AI Models, or finetune a model to your own data.',
        img: img1,
        price: '1.56 wETH',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },
    {
        id: 2,
        
        heading: 'Audio Generation',
        desc: 'Generate and augment images with our Production ready AI Models, or finetune a model to your own data.',
        img: img1,
        price: '1.56 wETH',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },
    {
        id: 3,
        
        heading: 'Image Generation',
        desc: 'Generate and augment images with our Production ready AI Models, or finetune a model to your own data.',
        img: img1,
        price: '1.56 wETH',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },


    
    

]

export default dataBanner;