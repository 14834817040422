/* eslint-disable max-len */
/* eslint-disable camelcase */
import axios from 'axios';




export async function registerUser(user_) {
    // send axios post request to the server localhost:6002/apiv1/user/register/new with the username_ and password_
    const endpoint = `${process.env.REACT_APP_API}/apiv1/user/register/new`;
    const one = 1;
    // console.log(endpoint);
    const result = await axios
        .post(endpoint, JSON.stringify({
            user: user_,
        }), {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return {
            error: true,
            message: 'Error registering user',
        };
    }
}

export async function verifyAPIKey(apiKey) {
    // send axios post request to the server localhost:6002/apiv1/user/register/new with the username_ and password_
    const endpoint = `${process.env.REACT_APP_API}/apiv1/key/verify`;
    // console.log(endpoint);
    const result = await axios
        .post(endpoint, JSON.stringify({
            key: apiKey,
        }), {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => {
            console.log(err);
            return (false);
        });
    try {
        const res =  await result.data;
        if(res.status === 'active'){
            return true;
        }else{
            return false;
        }
    } catch {
        return false;
    }
}


export async function checkUser(userinfo) {
    let authend = 'frontend/a0Check';
    authend = 'a0Check';
    if (process.env.APP_STATE === 'TESTING') {
        authend = 'a0Checktest';
    }
    const endpoint = `${process.env.REACT_APP_API}/apiv1/user/${authend}`;
    // const endpoint = `${uaiHost}/auth/local`;
    if (userinfo !== undefined) {
    // console.log('DooDoop');
    // console.log(userinfo);
    const result = await axios
        .post(endpoint, {
            user: {
                email: userinfo.email,
            },
        }, {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => (err));
    try {
        console.log(await result);
        return await result;
    } catch {
        // console.log('DooDoop21');

        return false;
    }
    } else {
        return false;
    }
}
async function loginUser(username_, password_) {
    const endpoint = `${process.env.REACT_APP_API}/apiv1/user/login`;
    // const endpoint = `${uaiHost}/auth/local`;
    const result = await axios
        .post(endpoint, JSON.stringify({
            username: username_,
            password: password_,
        }), {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => (err));
    try {
        return await result.data.data;
    } catch {
        return false;
    }
}
export async function loginFrontEndUser() {
    // const endpoint = `${uaiHost}/auth/local`;
    const endpoint = `${process.env.REACT_APP_API}/apiv1/user/login`;
    const result = await axios
        .post(endpoint, JSON.stringify({
            username: process.env.REACT_APP_UAI_LICENSESERVER_USERNAME,
            password: process.env.REACT_APP_UAI_LICENSESERVER_PASSWORD,
        }), {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => (err));
    try {
        // console.log('frontend login', result);
        localStorage.setItem('frontendjwt', JSON.stringify(result.data.data.jwt));
        return await result.data.data;
    } catch {
        return false;
    }
}

export async function GetCustomerPortal(customer_Id, returnURL) {
    // console.log('GetCustomerPortal', customer_Id, returnURL);
  const outlink = await axios.post(`${process.env.REACT_APP_API }/create-customer-portal`, { customerId: customer_Id, returnUrl: returnURL });
  // console.log('GetCustomerPortal2', outlink);
    
  try {
        return await outlink.data.url;
    } catch {
        return false;
    }
}

export async function GetCustomerSubscriptionPortal(customer_Id, returnURL, subscriptionId,subscriptionState='subscription_update' ) {
    // console.log('GetCustomerPortal', customer_Id, returnURL);
  const outlink = await axios.post(`${process.env.REACT_APP_API }/create-customer-subscription-portal`, { customerId: customer_Id, returnUrl: returnURL

, subscriptionId: subscriptionId, subscriptionState: subscriptionState});
  // console.log('GetCustomerPortal2', outlink);
    
  try {
        return await outlink.data.url;
    } catch {
        return false;
    }
}

async function getLicenseTypes(jwt, uaiHost) {
    const endpoint = `${uaiHost}/license-types`;
    const result = await axios
        .get(endpoint, {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${jwt}`,
            },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}

export async function getFrontendJWT() {
    let jwt = '';
    try {
        jwt = JSON.parse(localStorage.getItem('frontendjwt'));
        if (jwt == null) {
        const loginuser = await loginFrontEndUser();
        jwt = loginuser.jwt;
        }
        // console.log(jwt);
        } catch {
        const loginuser = await loginFrontEndUser();
        // console.log(loginuser);
        jwt = JSON.parse(localStorage.getItem('frontendjwt'));
        }
    return jwt;
}

export async function createBetaSignup(betaSignup) {
    const endpoint = `${process.env.REACT_APP_API}/apiv1/user/signup/beta`;
    console.log(betaSignup);
    const result = await axios
        .post(endpoint, JSON.stringify(betaSignup), {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${betaSignup.jwt}`,

            },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}
export default loginUser;
