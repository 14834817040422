

// import './style.css';
import {useRef, useState  } from 'react';
import { useStorage } from "../../../../api/useStorage";
import { useSignals } from '../../../../api/signalSlots';
import {useAppManager} from '../../../../api/appManager';

import {DNA} from 'react-loader-spinner';
import { useSignal, useSlot } from 'react-signal-slot';

const EditProjectView = ({setShow = (e)=>{}, Title="Create Project"}) => {
    const fileInput = useRef(null);
    const thumbnailImage = useRef(null);
    const manager = useAppManager();
    const storage = useStorage();
    const [Extension, setExtension] = useState("png");
    const [imageName, setImageName] = useState("Untitled" + Extension);
    const [thumbnail, setThumbnail] = useState(null);
    const [newProject, setNewProject] = useState({name:"Untitled", description:"", tags:"", thumbnail:"https://devwares-pull-zone.b-cdn.net/mockimages/Background%3DPurple.png", projectType:"Mixer"});

    const [isLoading, setIsLoading] = useState(false);
    const signal = useSignal();

    const thumbnailFileClicked = () => {

        fileInput.current.onchange = (e) => {
            var fileExtension = "";
            var file_ = e.target.files[0];
            setThumbnail(file_);
            const fileName = file_.name;
            setImageName(fileName);
        if (fileName.lastIndexOf(".") > 0) {
            fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
            setExtension(fileExtension);
        }
        if (fileExtension.toLowerCase() == "png" || fileExtension.toLowerCase() == "jpg" || fileExtension.toLowerCase() == "jpeg" || fileExtension.toLowerCase() == "gif"){
            
            const reader = new FileReader();
            reader.onload = function(e) {
                setNewProject({...newProject, thumbnail:e.target.result});
            return true;
            }
            reader.readAsDataURL(e.target.files[0]);

            
        }
        else {
            alert("You must select an image file for upload");
            return false;
        }

            console.log(e.target.files[0]);
        }
        
        fileInput.current.click();
        
    }
    const createNewProject = async (project) => {
        setIsLoading(true);
        const user_ = localStorage.getItem("user");
        const userJSON = JSON.parse(user_);
        const imageExt = project.thumbnail.split(";")[0].split("/")[1];
        const img_ = newProject.thumbnail.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "").replace("data:image/gif;base64,", "").replace("data:image/jpg;base64,", "");
        const newID = crypto.randomUUID();
        project.id = newID;
        const newFilePath =await storage.uploadFiles([img_], {} ,userJSON.id, newID, manager.props.appName, Extension, false );
        const imageURL = newFilePath[0]['media'];
        project.thumbnail = imageURL;
        console.log(imageURL);
        manager.props.uaiProject.sendToast(`Creating Project, Please Wait...`, "top-right", 3000);

        setNewProject(project);

        await manager.props.uaiProject.createNewProject(project);
        setIsLoading(false);
        // signals.emit("project.createNewProject", project);
        // await setProject(project);
        
        manager.props.uaiProject.sendToast(`Created & Opened Project: ${project.name}`);
        manager.props.print(`Created & Opened Project: ${project.name}`);
        signal("panel.project.setShow",  {state:false});

        // closeClicked(false, true);
    }
    const closeClicked = (state, force = false) => {
        // if(state === true){
            setShow(false);
            // signals.emit("panel.project.setShow",  {state:state});
        // }else{
        // if(uaiProject.noProjects === true && force === false){
        // alert("You must first create a project to continue");
        // return;
        // }else{
        //     signals.emit("panel.project.setShow",  {state:false});

        // }
        // }
    }

    return (

        <>
     { isLoading ? 
     
     <div className="canvas-paper w-full h-full  flex items-center justify-center">
     <DNA className="m-auto" />
     </div>
     
     :
     <section className="mx-auto px-20 py-12 md:py-28 lg:gap-3 bg-transparent w-full relative isolate flex overflow-clip
    flex-col h-full overflow-auto">
  <p className="text-3xl font-bold md:text-5xl text-white text-left">{Title.toUpperCase()}</p>
  <div className="h-fit w-full flex flex-row">
    
    <div fillspace="yes" className="w-full h-full items-start grid gap-3">


      <div className="flex flex-col gap-3">
      <div className="grid gap-y-2  w-1/2">

        
      <div className="grid gap-y-2 ">
        <label className="text-lg font-medium text-white text-left">Project Type</label>
        <select fontfamily="Arial" type="select-one" className="p-3  form-control form-select display-7 text-blue-500" onChange={(e)=>{
            setNewProject({...newProject, projectType:e.target.value});
        }}>
          <option fontfamily="Arial" value="Mixer">Mixer</option>
          <option fontfamily="Arial" value="Dataset" >Dataset</option>
          <option fontfamily="Arial" value="XR Scene" >XR Scene</option>
        </select>
      </div>

      <div className="grid gap-y-2">
        <label alignself="start" className="text-lg font-medium justify-start items-start text-white
            text-left">Name</label>
        <input type="text" placeholder="Untitled" className="p-3 placeholder:text-gray-100 focus:outline-none focus:ring-1
            focus:ring-gray-600 w-full text-white bg-transparent !rounded-none"  onChange={(e)=>{
                setNewProject({...newProject, name:e.target.value});
            }}/>
      </div>

      





      </div>


      <div className="flex flex-col gap-2 w-full">
        
      
      <div className="grid gap-y-2 w-full">
        <label className="text-lg font-medium text-white text-left">Description</label>
        <textarea type="textarea" placeholder="Your message" rows="2" className="focus:outline-none focus:ring-1
            focus:ring-gray-600" onChange={(e)=>{
                setNewProject({...newProject, description:e.target.value});
            }}/>
      </div>

      
      <div className="grid gap-y-2">
        <label className="text-lg font-medium text-white text-left">Tags</label>
        <input type="text" placeholder="Please use comma separated tags" className="p-3 placeholder:text-gray-100  !rounded-none
            focus:outline-none focus:ring-1 focus:ring-gray-600  w-full text-white bg-transparent" onChange={(e)=>{
                let tags = [];
                if(e.target.value.includes(",")){
                    tags = e.target.value.split(",");
                }
                setNewProject({...newProject, tags:tags});
            }}/>
      </div>

      <div className=" flex flex-col gap-y-2   w-full">
        <label className="text-lg font-medium text-white text-left w-full">Thumbnail</label>
        
      <button type="submit" className=" h-20 p-1 !rounded-none !border-[#183272]  border w-fit " onClick={thumbnailFileClicked}><img ref={thumbnailImage} id="thumbnailImage" src={newProject.thumbnail} alt="portfolio hero image"
              className=" h-full w-auto"/></button>

          
      </div>



      </div>
      </div>

      <div className=" flex flex-row gap-6   w-full justify-end">
      <button type="submit" className="p-6 !rounded-none !border-[#183272]  border " onClick={closeClicked}>Cancel</button>
      <button type="submit" className="p-6 !rounded-none !border-[#183272]  border " onClick={()=>{
        createNewProject(newProject);
      }}>{Title}</button>
    </div>
    <input type="file" ref={fileInput} className="hidden" accept=".png,.jpg" />
    </div>
  </div>
</section>}

</>
    );
  };

  export default EditProjectView;