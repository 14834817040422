import React from 'react';
import PageTitle from '../components/pagetitle/PageTitle';

import DownloadsPage from './UAIInstallers/DownloadsPage';

Downloads.propTypes = {
    
};

function Downloads(props) {
    return (
        <div>

            <PageTitle sub='Applications' title='Downloads' />
            
            <DownloadsPage />
            
        </div>
    );
}

export default Downloads;