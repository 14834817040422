
const uaiLicense = {
    "id": 5,
    "name": "UAI License",
    "Description": "\n# Production-Ready\nExperience the future of creative production with UAI, the industry-leading GenAI toolkit. From large-scale organizations to individual indie artists, UAI empowers you to generate, augment, and customize media like never before. Dive into an extensive range of AI processes, from production design to visualization, and ignite your imagination. Develop custom AI models using your own content, expanding the possibilities of your creations.\n\n# Tokens\n\nEmpower your creativity with UAI's 3000 Global Tokens per month, included in every license.Whether you're without an Nvidia GPU, using the Web API, or conserving processing power, these tokens empower you to generate and augment media seamlessly. Global Tokens roll over monthly, ensuring your creative journey remains uninterrupted. Need more tokens? Additional tokens are available for purchase, offering endless possibilities for your projects.\n\n# Web API\n\nTransform your creative vision into reality with UAI's Web API. Build and customize AI tools and experiences effortlessly through simple API requests. Powered by Nvidia A100 GPUs, the Web API enables you to process and generate media with speed and precision. By utilizing the Web API, you can streamline your workflow, ensuring efficient media production while maintaining optimal performance on your main device.\n\n# Training\n\nMake your media truly yours with UAI's custom AI model training apps. Create a custom AI model using your own data, whether it's photos of yourself, your pet, or anything else. Stand out from the crowd by seamlessly integrating personalized elements into your images and videos, captivating your audience with content that resonates on a deeper level. Elevate your creative projects with UAI's training feature, unlocking endless possibilities for customization and creativity.\n",
    "card": {
        "id": 40,
        "Form": false,
        "Name": "UAI: Report Generator",
        "Tier": 0,
        "Usage": "Subscribtion Plan",
        "Website": "This App",
        "ImagePath": "aitype/placeholder.jpg",
        "NodeEditor": false,
        "TierReason": "Licensing",
        "Description": "Enter the world of Generative AI with our suite of industry standard AI applications.  With this monthly subscription you receive access to all of our applications along with 3000 free tokens to be used on our web based apps or custom API calls. Cancel anytime..",
        "DownloadSize": 0
    },
    "published_at": "2022-05-15T04:25:31.349Z",
    "created_at": "2022-05-15T04:25:04.794Z",
    "updated_at": "2024-04-26T19:02:42.259Z",
    "image": "https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/UAI_Square_1900.jpg",
    "category": "Everyday",
    "pricemsrp": 10.99,
    "pricecurrent": 30,
    "delivery": "download",
    "shortdescription": "Enter the world of Generative AI with our suite of industry standard AI applications.  With this monthly subscription you receive access to all of our applications along with 3000 free tokens to be used on our web based apps or custom API calls. Cancel anytime.",
    "licensetype": {
        "id": 4,
        "name": "commercial",
        "days": "365",
        "published_at": "2022-05-16T19:37:16.077Z",
        "created_at": "2022-05-16T19:37:14.414Z",
        "updated_at": "2022-05-16T19:37:16.121Z"
    },
    "showInShop": true,
    "productid": null,
    "usage": null,
    "PlanType": "Subscription",
    "productType": "App",
    "uploadPath": null,
    "includedWithLicense": null,
    "Variation": [
        {
            "id": 1,
            "Name": "Trial",
            "BasePrice": 0,
            "ProductID": null,
            "Plans": [
                {
                    "id": 12,
                    "Name": "Monthly",
                    "StripeID": "price_1LKqf8AkKGc9k98sTKSJx6iz",
                    "BillingPeriod": "Monthly",
                    "StripeIDProduction": "price_1LVe63AkKGc9k98sr5bSX0bB"
                },
                {
                    "id": 13,
                    "Name": "Yearly",
                    "StripeID": "price_1LKqfMAkKGc9k98swtXji2dc",
                    "BillingPeriod": "Yearly",
                    "StripeIDProduction": "price_1LVe63AkKGc9k98sfMMEtcKY"
                }
            ]
        },
        {
            "id": 2,
            "Name": "Commercial",
            "BasePrice": 10,
            "ProductID": "price_1LFQJ9AkKGc9k98sUSPa6DUh",
            "Plans": [
                {
                    "id": 2,
                    "Name": "Monthly",
                    "StripeID": "price_1NzRLpAkKGc9k98sxXhxKom7",
                    "BillingPeriod": "Monthly",
                    "StripeIDProduction": "price_1NzRReAkKGc9k98syYTHnKrh"
                },
                {
                    "id": 3,
                    "Name": "Yearly",
                    "StripeID": "price_1NzRLpAkKGc9k98sthkjd4rW",
                    "BillingPeriod": "Yearly",
                    "StripeIDProduction": "price_1NzRReAkKGc9k98sf0kd6HQJ"
                }
            ]
        },
        {
            "id": 3,
            "Name": "Academic",
            "BasePrice": 5,
            "ProductID": null,
            "Plans": [
                {
                    "id": 4,
                    "Name": "Monthly",
                    "StripeID": "price_1LKqeQAkKGc9k98sBE3dasWT",
                    "BillingPeriod": "Monthly",
                    "StripeIDProduction": "price_1LVe63AkKGc9k98s5wgiyhrn"
                },
                {
                    "id": 5,
                    "Name": "Yearly",
                    "StripeID": "price_1LKqeeAkKGc9k98seSmbMGnv",
                    "BillingPeriod": "Yearly",
                    "StripeIDProduction": "price_1LVe63AkKGc9k98sOYmcoe4b"
                }
            ]
        },
        {
            "id": 4,
            "Name": "Developer",
            "BasePrice": 0,
            "ProductID": null,
            "Plans": [
                {
                    "id": 6,
                    "Name": "Monthly",
                    "StripeID": "price_1LKqi4AkKGc9k98sPUC9YIvz",
                    "BillingPeriod": "Monthly",
                    "StripeIDProduction": "price_1LVe63AkKGc9k98sEarAnWOn"
                },
                {
                    "id": 7,
                    "Name": "Yearly",
                    "StripeID": "price_1LKqiLAkKGc9k98s8UsdZp77",
                    "BillingPeriod": "Yearly",
                    "StripeIDProduction": "price_1LVe63AkKGc9k98s1YY1WLDW"
                }
            ]
        },
        {
            "id": 5,
            "Name": "Reporter",
            "BasePrice": 2,
            "ProductID": null,
            "Plans": [
                {
                    "id": 8,
                    "Name": "Monthly",
                    "StripeID": "price_1LKqdVAkKGc9k98s094vqnHn",
                    "BillingPeriod": "Monthly",
                    "StripeIDProduction": "price_1LVe63AkKGc9k98s4QmebYmX"
                },
                {
                    "id": 9,
                    "Name": "Yearly",
                    "StripeID": "price_1LKqdvAkKGc9k98s42hTypDJ",
                    "BillingPeriod": "Yearly",
                    "StripeIDProduction": "price_1LVe63AkKGc9k98sAhmFyy9k"
                }
            ]
        },
        {
            "id": 6,
            "Name": "Enterprise",
            "BasePrice": 0.99,
            "ProductID": null,
            "Plans": [
                {
                    "id": 10,
                    "Name": "Monthly",
                    "StripeID": "price_1NzRLpAkKGc9k98sX3LxeyZw",
                    "BillingPeriod": "Monthly",
                    "StripeIDProduction": "price_1NzRReAkKGc9k98sp0FZoIVz"
                },
                {
                    "id": 11,
                    "Name": "Yearly",
                    "StripeID": "price_1NzRLpAkKGc9k98sKa48MHhn",
                    "BillingPeriod": "Yearly",
                    "StripeIDProduction": "price_1NzRReAkKGc9k98su43G9pBG"
                }
            ]
        }
    ],
    "stripeID": {
        "id": 1,
        "production": "prod_On1UBQyFF1z5ED",
        "development": "prod_On1O2Quh8d0Wl6"
    },
    "Images": [
        {
            "id": 1,
            "url": "https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/UAI_Square_1900.jpg"
        },
        {
            "id": 2,
            "url": "https://installers.uai.software/media/branding/apps/Icons/UAI_Brain_Server_Icon.png"
        },
        {
            "id": 3,
            "url": "https://installers.uai.software/media/branding/apps/Icons/RotoBot.png"
        },
        {
            "id": 4,
            "url": "https://installers.uai.software/media/branding/apps/Icons/Parrotech_Logo_01.png"
        }
    ],
    "includedItems": [
        {
            "id": 2,
            "name": "RotoBot",
            "Description": "Separate Humans from 2D image sequence, and either return Humans RGB Masks or Background RGB Masks",
            "card": {
                "id": 39,
                "Form": false,
                "Name": "RotoBot",
                "Tier": 0,
                "Usage": "Rotoscope | Background Removal | Object Removal | Compositing",
                "Website": "https://github.com/PeterL1n/RobustVideoMatting",
                "ImagePath": "aitype/RotoBot.jpg",
                "NodeEditor": false,
                "TierReason": "Licensing",
                "Description": "Separate Humans from 2D image sequence, and either return Humans RGB Masks or Background RGB Masks",
                "DownloadSize": 1
            },
            "published_at": "2022-05-15T04:21:52.270Z",
            "created_at": "2022-05-15T04:21:51.117Z",
            "updated_at": "2023-12-16T08:59:14.434Z",
            "image": "https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/dlc/RotoBot_SQ.jpg",
            "category": "Creative",
            "pricemsrp": 0,
            "pricecurrent": 0,
            "delivery": "download",
            "shortdescription": "Separate Humans from 2D image sequence, and either return Humans RGB Masks or Background RGB Masks",
            "licensetype": 4,
            "showInShop": false,
            "productid": null,
            "usage": null,
            "PlanType": "OneTime",
            "productType": "Plugins",
            "uploadPath": null,
            "includedWithLicense": true,
            "Variation": [
                {
                    "id": 8,
                    "Name": "OneTime",
                    "BasePrice": null,
                    "ProductID": null,
                    "Plans": [
                        {
                            "id": 15,
                            "Name": "OneTime",
                            "StripeID": "price_1LKse0AkKGc9k98seeypbttS",
                            "BillingPeriod": "Custom",
                            "StripeIDProduction": "price_1LVe6CAkKGc9k98szfcP4eYx"
                        }
                    ]
                }
            ],
            "stripeID": {
                "id": 5,
                "production": "-",
                "development": "-"
            },
            "Images": []
        },
        {
            "id": 3,
            "name": "MediaPipe",
            "Description": "Usage of MediaPipe",
            "card": {
                "id": 37,
                "Form": false,
                "Name": "MediaPipe: Holistic | Mocap",
                "Tier": 0,
                "Usage": "Motion Capture | Medical Analysis | Compositing | Puppetry | Simulation",
                "Website": "https://google.github.io/mediapipe/solutions/pose",
                "ImagePath": "aitype/placeholder.jpg",
                "NodeEditor": false,
                "TierReason": "Licensing",
                "Description": "Generate a sequence of obj files with data representing human joints along with a Facemesh and Hand tracking",
                "DownloadSize": 1
            },
            "published_at": "2022-05-15T04:23:09.135Z",
            "created_at": "2022-05-15T04:23:04.808Z",
            "updated_at": "2022-12-17T23:46:58.697Z",
            "image": "https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/dlc/FaceMesh_Sq.jpg",
            "category": "Creative",
            "pricemsrp": 10.99,
            "pricecurrent": 10.99,
            "delivery": "download",
            "shortdescription": "Usage of MediaPipe",
            "licensetype": 4,
            "showInShop": false,
            "productid": null,
            "usage": null,
            "PlanType": "OneTime",
            "productType": "Plugins",
            "uploadPath": null,
            "includedWithLicense": null,
            "Variation": [
                {
                    "id": 10,
                    "Name": "OneTime",
                    "BasePrice": null,
                    "ProductID": null,
                    "Plans": [
                        {
                            "id": 17,
                            "Name": "OneTime",
                            "StripeID": "price_1LKse0AkKGc9k98seeypbttS",
                            "BillingPeriod": "Monthly",
                            "StripeIDProduction": "price_1LVe6CAkKGc9k98szfcP4eYx"
                        }
                    ]
                }
            ],
            "stripeID": {
                "id": 7,
                "production": "-",
                "development": "-"
            },
            "Images": []
        },
        {
            "id": 9,
            "name": "Parrotek",
            "Description": "All the technology of a parrot minus the cleanup. Clone and augment voices. Generate singing voices.",
            "card": {
                "AppIcon": "https://www.dropbox.com/s/cpv9d1ch097x86k/Parrotech_Logo_01.png?raw=1",
                "AppName": "Parrotek",
                "AppSize": "6GB",
                "AppType": "Application",
                "AppImage": "https://www.dropbox.com/s/qxcupky7shjpt79/Parrotek_Screenshot_01.png?raw=1",
                "AppFeatures": {
                    "Features": [
                        "Clone a voice with only 6 minutes of input",
                        "Production ready audio",
                        "Text To Speech or Speech To Speech"
                    ]
                },
                "AppSubtitle": "Form",
                "AppDescription": " All the technology of a parrot minus the cleanup. Clone and augment voices. Generate singing voices."
            },
            "published_at": "2023-02-07T02:22:25.193Z",
            "created_at": "2023-02-07T02:20:00.041Z",
            "updated_at": "2023-12-16T09:17:08.794Z",
            "image": "https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/UAI_Square_1900.jpg",
            "category": "Creative",
            "pricemsrp": 0,
            "pricecurrent": 0,
            "delivery": "download",
            "shortdescription": null,
            "licensetype": null,
            "showInShop": false,
            "productid": null,
            "usage": null,
            "PlanType": "OneTime",
            "productType": "App",
            "uploadPath": "",
            "includedWithLicense": true,
            "Variation": [
                {
                    "id": 18,
                    "Name": "OneTime",
                    "BasePrice": null,
                    "ProductID": null,
                    "Plans": [
                        {
                            "id": 24,
                            "Name": "OneTime",
                            "StripeID": "prod_O1ObubZVeVXwLw",
                            "BillingPeriod": "Custom",
                            "StripeIDProduction": "prod_O1ObubZVeVXwLw"
                        }
                    ]
                }
            ],
            "stripeID": {
                "id": 12,
                "production": "prod_O1ObubZVeVXwLw",
                "development": "prod_O1ObubZVeVXwLw"
            },
            "Images": []
        },
        {
            "id": 10,
            "name": "StableV",
            "Description": "Create and augment images from text prompts. In painting and other things are available ",
            "card": {
                "AppIcon": "https://www.dropbox.com/scl/fi/t21iwr9vnnhswixwzenzl/StableV_AppIcon.png?rlkey=5s77z2f5n511l9ew4zpt4sh9z&raw=1",
                "AppName": "StableV",
                "AppSize": "14GB",
                "AppType": "Application",
                "AppImage": "https://www.dropbox.com/scl/fi/t21iwr9vnnhswixwzenzl/StableV_AppIcon.png?rlkey=5s77z2f5n511l9ew4zpt4sh9z&raw=1",
                "AppFeatures": {
                    "Features": [
                        "Clone a voice with only 6 minutes of input",
                        "Production ready audio",
                        "Text To Speech or Speech To Speech"
                    ]
                },
                "AppSubtitle": "Form",
                "AppDescription": " Create and augment images from text prompts. In painting and other things are available "
            },
            "published_at": "2023-02-07T02:21:36.859Z",
            "created_at": "2023-02-07T02:21:29.432Z",
            "updated_at": "2024-05-06T02:34:35.892Z",
            "image": "https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/dlc/ImGEN_Logo_Full.png",
            "category": "Creative",
            "pricemsrp": 0,
            "pricecurrent": 0,
            "delivery": "download",
            "shortdescription": "Create and augment images from text prompts",
            "licensetype": 4,
            "showInShop": false,
            "productid": null,
            "usage": null,
            "PlanType": "OneTime",
            "productType": "App",
            "uploadPath": "text2image/ImaGEN",
            "includedWithLicense": true,
            "Variation": [
                {
                    "id": 15,
                    "Name": "OneTime",
                    "BasePrice": null,
                    "ProductID": null,
                    "Plans": [
                        {
                            "id": 21,
                            "Name": "OneTime",
                            "StripeID": "price_1NIHo8AkKGc9k98sfJfUtBSq",
                            "BillingPeriod": "Custom",
                            "StripeIDProduction": "price_1NIHo8AkKGc9k98sfJfUtBSq"
                        }
                    ]
                }
            ],
            "stripeID": {
                "id": 9,
                "production": "prod_O4Qfyw4RMuyWgx",
                "development": "prod_O4Qfyw4RMuyWgx"
            },
            "Images": []
        }
    ],
    "appInstallers": [],
    "dependencies": [],
    "dlcs": [],
    "issues": []
}

export {uaiLicense};