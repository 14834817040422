

import { useState, useEffect } from 'react';

import { useSignals } from "../../../../api/signalSlots";
import { useAppManager } from "../../../../api/appManager";

import {
    LineEdit, SpinBoxEdit, ButtonEdit, CheckEdit, FileInputEdit, SelectEdit, TextAreaEdit , DoubleSpinBoxEdit,
    Vector2Edit, Vector3Edit, Vector4Edit, ColorEdit, Color3Edit, Color4Edit

} from './uiComponents';
import FXDropdown from '../../../FXDropdown';
import KeyframeButton from '../keyframeButton';
const DynamicEditUI = ({component=undefined, field = undefined, initialValue = "", ref=undefined, className="",   onChange=(e)=>{}}) => {

    const [value, setValue] = useState(initialValue);

    const onComponentChange = (e) => {
let value_ = e;
        switch(field.type){
            case "string":
                value_ = e.target.value;
                setValue(e.target.value);
                break;
            case "int":
                value_ = parseInt(e.target.value);
                setValue(parseInt(e.target.value));
                break;
            case "float":
                value_ = parseFloat(e.target.value);
                setValue(parseFloat(e.target.value));
                break;
            case "bool":
                value_ = e.target.checked;
                setValue(e.target.checked);
                break;
            case "file":
                value_ = e;
                setValue(e);
                break;
            case "select":
                value_ = e.target.value;
                setValue(e.target.value);
                break;
            case "textarea":
                value_ = e.target.value;
                setValue(e.target.value);
                break;
            default:
                value_ = e;
                setValue(e);
                break;
    }
    const newField = {...field, value:value_, component:component};
    onChange(newField);
    }
    useEffect (() => {
        setValue(initialValue);
    }, [component]);
    useEffect (() => {

        setValue(initialValue);
    }, [field]);
    useEffect (() => {

        setValue(initialValue);
    }, [initialValue]);
    const isMulti = field ? field.lines ? field.lines == "multi" ? true : false : false : false;

    const generateField = () => {


switch(field.type){
                                case "string":
                                    if (isMulti){
                                        return <TextAreaEdit value={value.toString()} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                    }
                                    return <LineEdit value={value.toString()} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                case "int":
                                    return <SpinBoxEdit value={value.toString()} min={field.min} max={field.max} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                case "float":
                                    return <DoubleSpinBoxEdit value={value.toString()} min={field.min} max={field.max} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                case "button":
                                    return <ButtonEdit value={value.toString()} label={field.label} ref={ref} className={className} onChange={onComponentChange}  keyframeable={field.keyframeable}/>
                                case "bool":
                                    return <CheckEdit value={value} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                case "file":
                                    return <FileInputEdit value={value.toString()} label={field.label} ref={ref} className={className} base64={field.base64} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                case "select":
                                    return <SelectEdit value={value.toString()} options={field.options} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                case "textarea":
                                    return <TextAreaEdit value={value.toString()} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                      
                                case "vector2":
                                    return <Vector2Edit value={value} label={field.label} ref={ref} className={className} onChange={onComponentChange}  keyframeable={field.keyframeable}/>
                                case "vector3":
                                    return <Vector3Edit value={value} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                case "vector4":
                                    return <Vector4Edit value={value} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                case "color":
                                    return <ColorEdit value={value} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                case "color4":
                                    return <Color4Edit value={value} label={field.label} ref={ref} className={className} onChange={onComponentChange} keyframeable={field.keyframeable} />
                                default:
                                    return <></>;
                            }


    }

    return(

        <div className={`flex gap-2 ${isMulti ? 'h-50' : ''}`}>
                
        {
            generateField()
        }
        
        </div>

    )


}

export default DynamicEditUI;