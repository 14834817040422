import React , {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from '@material-tailwind/react';
import PageTitle from '../components/pagetitle/PageTitle';
import { Link } from 'react-router-dom';

import icon1 from '../assets/images/icon/rain1.svg'
import icon2 from '../assets/images/icon/rain2.svg'

import avtd6 from '../assets/images/author/author-history-1.jpg'
import avtd7 from '../assets/images/author/author-history-2.jpg'
import { getLicenseTypes, createPlaceholderLicense } from '../uai/api/licenses';
import { copy } from '../uai/utils/common';
import {setProducts, setCart} from '../reducers/cartReducer';
import '../uai/uaistyle.css';



const  IncludedProducts = ({product}) => {
    const state = useSelector(state => state);

    const products = useSelector(state => state.appState.products);
    // const currentPlan = useSelector(state => state.appState.products.currentPlan);
    const dispatch = useDispatch();
    const [mode, setmode] = useState('loading');
  
  const openProduct = (newProduct, childProduct = false) => {
    let newProducts = products;
    if(childProduct == true){
    newProducts.parentProduct = copy(newProducts.currentProduct, true);
    }
    newProducts.currentProduct = newProduct;
    dispatch(setProducts(newProducts));
    window.location.reload();
// window.location = "/productDetails" ;
}

    



    return (



            <section className='tf-explore-more'>
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-heading">
                                <h4 className="heading">Included Products</h4>
                            </div>
                        </div>

                        {
                                     product.includedItems ? product.includedItems.slice(0,4).map(idx =>{
                                        console.log(idx);
                                        return(
                                        <div key={idx.id}  className="col-xl-3 col-lg-4 col-md-6 col-sm-6 ">
                                            <div className="sc-product style2">
                                            <div className="top">
                                                <Link to="/item-details-v1" className="tag">{idx.name}</Link>
                                                <div className="wish-list">
                                                    <Link to="#" className="heart-icon"></Link>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="details-product">
                                                    <div className="author">
                                                        <div className="avatar">
                                                            <img src={idx.image} alt="images" />
                                                        </div>
                                                        <div className="content">
                                                            <div className="position">{idx.productType}</div>
                                                            <div className="name"> <Link to="#" onClick={(e)=>{
                                                                            e.preventDefault();
                                                                            openProduct(idx, true);
                                                                }}>{""}</Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="features">
                                                <div className="product-media">
                                                    <img src={idx.image} alt="images" />
                                                </div>
                                                <div className="rain-drop1"><img src={icon1} alt="images" /></div>
                                                <div className="rain-drop2"><img src={icon2} alt="images" /></div>
                                            </div>
                                            <div className="bottom-style2">
                                                {/* <div className="price">
                                                    <div className="icon"><img src={icon3} alt="images" /></div>
                                                    <div className="content">
                                                        <div className="name">ETH</div>
                                                        <div className="cash">{idx.price}</div>
                                                    </div>
                                                </div> */}
                                                <div className="product-button">
                                                    <Link to="#" onClick={(e)=>{
                                                                            e.preventDefault();
                                                                            openProduct(idx, true);
                                                                }} data-toggle="modal" data-target="#popup_bid" className="tf-button"> View</Link>
                                                </div>
                                            </div>
            
                                        </div>
                                        </div>
                                        
                                    )}) : null
                                }

                    </div>
                </div>

           
            </section> 
            
            
    );
}

IncludedProducts.propTypes = {
    
};

export default IncludedProducts;