

export const getComponentFieldByName = (componentName, component) => {
    for (const subWindow in component.subwindows) {
        const fields = component.subwindows[subWindow].fields;
        for (const field in fields) {
            const field_ = fields[field];
            if(field_.name == componentName){
            return field_;
            }
        }

    }
    return null;

}

export const getComponentFieldByUUID = (uuid, component) => {
    let _component = null;
    for (const subWindow in component.subwindows) {
        const fields = component.subwindows[subWindow].fields;
        for (const field in fields) {
            const field_ = fields[field];
            if(field_.uuid == uuid){
                _component = field_;
            }
        }

    }
    return _component;
}

