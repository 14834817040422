 export class ImageRequestBase
    {
        constructor() {
        this.mode = "all";
        this.submode = "file";
        this.model = "/repository";
        this.subModel = "";
        this.onnxModel = "";
        this.modelType = "sdxll";
        this.inpaintModel = "stabilityai/stable-diffusion-2-inpainting";
        this.input = "";
        this.image = "";
        this.output = "";
        this.name = "";
        this.prompt = "";
        this.neg_prompt = "";
        this.negative_prompt = "";
        this.customSDBinWeights = "";
        this.textualInversionWeights = "";
        this.loraWeights = "";
        this.refinerRepo = "";
        this.vaeRepo = "";
        this.schedueler = "EulerAncestralDiscreteScheduler";
        this.device = "cuda";
        this.pythonPath = "";
        this.tempPath = "";
        this.paramsData = "";
        this.projectID = "";
        this.app = "";
        this.num_images_per_prompt = 1;
        this.steps = 15;
        this.width = 512;
        this.height = 512;
        this.highThreshold = 100;
        this.lowThreshold = 150;
        this.guidance_scale = 7.5;
        this.controlnet_scale = 1.2;
        this.image_guidance_scale = 0.8;
        this.configScale = 0.8;
        this.ratio = 1.0;
        this.rotationx = 0;
        this.rotationy = 0;
        this.rotationz = 0;
        this.scalex = 1;
        this.scaley = 1;
        this.scalez = 1;
        this.seed = 42;
        this.imagesToGenerate = 1;
        this.x = 0;
        this.y = 0;
        this.fps = 12;
        this.high_noise_frac = 0.8;
        this.maskIndex = 0;
        this.zip = false;
        this.isXL = false;
        this.debugmode = false;
        this.size = [1024,1024];
        this.mediaPaths = [];
        this.seeds = [];
        this.inputs = [];
        this.controlnet_conditioning_scales = [];
        this.faceFix = 1;
        this.version = 1.3;
        this.upscale = 2;
        this.bg_upsampler = "realesrgan";
        this.bg_tile = 400;
        this.suffix = "None";
        this.only_center_face = false;
        this.aligned = true;
        this.ext = "auto";
        this.weight = 0.75;

        // "options": [
        //     "S 1024X1024",
        //     "S 512X512",
        //     "S 1920X1920",
        //     "L 1920X1080",
        //     "L 1280X720",
        //     "L 1024X576",
        //     "P 1080X1920",
        //     "P 720X1280",
        //     "P 576X1240"
        // ],
        }
        load(json){
            Object.assign(this, json);
            if(json.num_images_per_prompt){
            this.imagesToGenerate = json.num_images_per_prompt;
            }else if (json.imagesToGenerate){
                this.num_images_per_prompt = json.imagesToGenerate;
            }
            const projectT = localStorage.getItem("project");
            const project = projectT ? JSON.parse(projectT) : null;
            if(project){
                this.projectID = project.id;
                this.app =  localStorage.getItem("appName");
            }
            if(json.neg_prompt){
                this.negative_prompt = json.neg_prompt;
            }
            if(json.imagesToGenerate){
                this.imagesToGenerate = parseInt( json.imagesToGenerate);
                this.num_images_per_prompt = parseInt( json.imagesToGenerate);
            }
            if(json.num_images_per_prompt){
                this.imagesToGenerate = parseInt( json.num_images_per_prompt);
                this.num_images_per_prompt = parseInt( json.num_images_per_prompt);
            }
            if(json.exportSize){
                switch(json.exportSize){
                    case "S 1024X1024":
                        this.size = [1024,1024];
                        break;
                    case "S 512X512":
                        this.size = [512,512];
                        break;
                    case "S 1920X1920":
                        this.size = [1920,1920];
                        break;
                    case "L 1920X1080":
                        this.size = [1920,1080];
                        break;
                    case "L 1280X720":
                        this.size = [1280,720];
                        break;
                    case "L 1024X576":
                        this.size = [1024,576];
                        break;
                    case "P 1080X1920":
                        this.size = [1080,1920];
                        break;
                    case "P 720X1280":
                        this.size = [720,1280];
                        break;
                    case "P 576X1240":
                        this.size = [576,1240];
                        break;
                    default:
                        this.size = [1024,1024];
                        break;
                }
            }
            
        }

        json(){
            return JSON.stringify(this);
        }
    }

export const createImageRequest = (json) => {
    let request = new ImageRequestBase();
    request.load(json);
    return request;
}
const sanitize = (name, value   ) => {

    switch(name){

        case "input":
            let regex = /base64,(.*)/;
            let match = value.match(regex);
            if(match){
                return match[1];
            }else{
                return value;
            }

        default:{
            return value;}

    }

}
export const createImageRequestFromOperation = (dictionary) => {
    const keys = Object.keys(dictionary);
    let values = {};
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const item = dictionary[key];
        for (let j = 0; j < item.subwindows.length; j++) {
            const subwindow = item.subwindows[j];
            for (let k = 0; k < subwindow.fields.length; k++) {
                const field = subwindow.fields[k];
                values[field.name] = sanitize(field.name, field.value);
            }
        }
    }
    if(values.model == "SG161222/RealVisXL_V4.0_Lightning"){
        values.model = "/repository"
    }
    const imageRequest = createImageRequest(values);
    return imageRequest;
}