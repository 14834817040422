import React from "react";
import { Checkbox } from "@material-tailwind/react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import useAppManager from '../../api/appManager';
import {sendToast} from '../../api/firebaseHandler';
import {useSignals} from '../../api/signalSlots';
import { useSignal, useSlot } from 'react-signal-slot';
import AnimatedButton from '../UAIAPP/components/AnimatedButton';
import useRuntime from '../UAIAPP/api/useRuntime';


function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}
 
const FXDropdown= ({ children,title="Untitled", component={}, className="", inputClassName="", showCheckbox=false,  showDelete=false, initialState=false})=> {
  const manager = useAppManager();
  const signal = useSignal();
  const [open, setOpen] = React.useState(component.initialState ? 1 : 0);
  const [enabled , setEnabled] = React.useState(true);
 const [initialized, setInitialized] = React.useState(false);
  const handleOpen = (value) =>{
    
    setOpen(open === value ? 0 : value)
    
  };
  React.useEffect(() => {
    console.log("component", component);
    if(!initialized){
      setOpen(component.initialState  ? 1 : 0);
      setInitialized(true);
    }
  }, [component]);

  const updateEnabled = (e) => {

    const newState = e.target.checked;
    setEnabled(newState);
    switch(newState){

      case true:
        signal("timeline.track.component.enable", {track:component.component, component:component});

      break;
      case false:
        signal("timeline.track.component.disabled", {track:component.component, component:component});

      break;

      default:
        signal("timeline.track.component.enable", {track:component.component, component:component});

      break;
    }

  }
 
  return (
    <>
      <Accordion className={`accordion appBlueBorder !rounded-none !bg-transparent  !hover:bg-[#3a57e85c]   !hover:text-[#ffffff]  ${className}`} open={open === 1 && enabled == true} icon={ component.toggleable && <Icon id={1} open={open === 1 && enabled == true ? 1 : 0 } />}>
        
        <AccordionHeader className="text-white !h-[20px] overflow-hidden" onClick={() => 
          
          {
            
            if (component.toggleable){
          handleOpen(1)}
        }}
          >
          <div className="flex items-center gap-3">
            {showDelete ? 
          <AnimatedButton  placement={"bottom"} icon="/images/svg/trash-icon.svg" alt="delete" className="!h-[14px] !w-[14px] cursor-pointer z-[50000] hover:texts-[#1e49b6]  p-[2px]" onClick={()=>{
                                            // setSequence(sequence);
                                            sendToast("Removed Component: " + component.name);
                                            useRuntime.getState().removeGenerateFX(component);
                                        }} whileHover={{ scale: 1.2, color:"#1e49b6" }}
                                                whileTap={{
                                                    scale: 0.9,
                                                    rotate: 360,
                                                }}
                                                helpTitle="Remove" helpBody={`Remove ${title} `}
                                                /> : <></>
                                              }

            {showCheckbox && component.toggleable &&
        <Checkbox onChange={updateEnabled} defaultChecked={enabled} containerProps={{className:"!px-0"}} className={` text-blue-500 !p-0 ${inputClassName}`}  />
      }

          {title}
          
          </div></AccordionHeader>
        <AccordionBody className="accordionBody py-1">
          {children}
        </AccordionBody>
      </Accordion>

      
    </>
  );
}

export default FXDropdown;