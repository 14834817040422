

// import './style.css';
import {useRef, useState, useEffect  } from 'react';
import { useStorage } from "../../../../api/useStorage";
import { useSignals } from '../../../../api/signalSlots';
import {DNA} from 'react-loader-spinner';
import useRuntime from '../../../../components/UAIAPP/api/useRuntime';
import {findFiles} from '../../../../api/firebaseHandler';
import { useSignal, useSlot } from 'react-signal-slot';


const RecentProjectView = ({ manager=undefined, setShow=(e)=>{}}) => {
    const fileInput = useRef(null);
    const thumbnailImage = useRef(null);
    const signals = useSignals();
    const storage = useStorage();
    const [isLoading, setIsLoading] = useState(true);
    const [Extension, setExtension] = useState("png");
    const [imageName, setImageName] = useState("Untitled" + Extension);
    const [thumbnail, setThumbnail] = useState(null);
    const [newProject, setNewProject] = useState({name:"Untitled", description:"", tags:"", thumbnail:"https://devwares-pull-zone.b-cdn.net/mockimages/Background%3DPurple.png", projectType:"Mixer"});
    const setProjects_ = useRuntime((state) => state.setProjects);
    const projects_ = useRuntime((state) => state.projects);
    const signal = useSignal();
    const loadProjects = async () => {
        const {files, folders} = await findFiles("stableV/151", ["uaipro", "uaiproj"]);
    }
    
    
    useEffect(() => {
        // loadProjects();
        if(isLoading){
        try{
                const projs = manager.props.uaiProject.projects;
                if(projs.length > 0){
                    setIsLoading(false);
                }else{

                    signals.emitGetSignal("project.getProjects.storage",{}, (e)=>{
                         console.log("project.getProjects", e);
                        manager.props.uaiProject.projects = e;
                        setProjects_(e);
                        setIsLoading(false);
                    });
                }
            
        }catch(e){
            console.error(e);   
        }
    }
            // console.log("project.getProjects", manager.props.uaiProject.projects);
    }, [manager.props.uaiProject]);
    

    const thumbnailFileClicked = () => {

        fileInput.current.onchange = (e) => {
            var fileExtension = "";
            var file_ = e.target.files[0];
            setThumbnail(file_);
            const fileName = file_.name;
            setImageName(fileName);
        if (fileName.lastIndexOf(".") > 0) {
            fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
            setExtension(fileExtension);
        }
        if (fileExtension.toLowerCase() == "png" || fileExtension.toLowerCase() == "jpg" || fileExtension.toLowerCase() == "jpeg" || fileExtension.toLowerCase() == "gif"){
            
            const reader = new FileReader();
            reader.onload = function(e) {
                setNewProject({...newProject, thumbnail:e.target.result});
            return true;
            }
            reader.readAsDataURL(e.target.files[0]);

            
        }
        else {
            alert("You must select an image file for upload");
            return false;
        }

            console.log(e.target.files[0]);
        }
        
        fileInput.current.click();
        
    }
    const createNewProject = async (project) => {
        
        const user_ = localStorage.getItem("user");
        const userJSON = JSON.parse(user_);
        const img_ = newProject.thumbnail.replace("data:image/png;base64,", "").replace("data:image/jpeg;base64,", "").replace("data:image/gif;base64,", "").replace("data:image/jpg;base64,", "");
        const newID = crypto.randomUUID();
        project.id = newID;
        const newFilePath =await storage.uploadFiles([img_], {} ,userJSON.id, newID, manager.props.appName, Extension, false );
        const imageURL = newFilePath[0]['media'];
        project.thumbnail = imageURL;
        console.log(imageURL);

        setNewProject(project);
        console.log("Creating Project", project);
        signal("project.setProject", project);
        // await setProject(project);
        console.log("Finished Creating Project");

        closeClicked(false, true);
    }
    const closeClicked = (state, force = false) => {
            setShow(true);
    }

    return (
        
        <section className="mx-auto px-20 py-12 md:py-28 lg:gap-3 bg-transparent w-full relative isolate flex overflow-clip
    flex-col  h-full overflow-auto">
  <p className="text-3xl font-bold md:text-5xl text-white text-left">{"RECENT PROJECTS"}</p>

{
    isLoading ? 
    <div className="canvas-paper w-full h-full flex  items-center justify-center">
    <DNA className="m-auto" />
    </div> : 

<>
  <div  className=" !text-white w-full !h-1/2 overflow-auto">
  <table className="table-fixed !text-white w-full h-full">
  <thead>
    <tr className="!h-[20px]">
      <th>Name</th>
      <th>Project Type</th>
      <th>Updated</th>
    </tr>
  </thead>
  <tbody>
    {
        projects_.map((project, index) => {
            console.log("PROJECT");
            return (
                <tr key={project.name } className="!h-[20px]">
                    <td className="cursor-pointer text-blue-800 hover:text-yellow-800 !h-[20px]" onClick={()=>{
                        // signal("project.setProject", project);
                        manager.props.uaiProject.SetLoadedProject(project);
                        manager.props.uaiProject.sendToast(`Opened Project: ${project.name}`);
                        manager.props.print(`Opened Project: ${project.name}`);
                        signal("panel.project.setShow",  {state:false});
                    }}> {project ? project.name ? project.name:project.name :project.name}</td>
                    <td className="!h-[20px]"> {project ? project.updated_at ? project.projectType : "-" : "-"}</td>
                    <td className="!h-[20px]">{project ? project.updated_at ? `${window.dayjs(project.updated_at.toDate()).format('MM/DD/YYYY hh:mm:ss A')  }` :"" : ""}</td>
                </tr>
            );
            }
        )


    }
   
  </tbody>
</table>
</div>

<div className=" flex flex-row gap-6   w-full justify-end">
      <button type="submit" className="p-6 !rounded-none !border-[#183272]  border " onClick={()=>{
        signal("panel.project.showNewProjectPanel")
      }}>{"Create Project"}</button>
    </div>

    </>

}



  <div className="h-fit absolute -left-14 bottom-10 w-full flex flex-row">
    
    <div fillspace="yes" className="w-full h-full items-start grid gap-3">




      <div className=" flex flex-row gap-6   w-full justify-end">
      <button type="submit" className="p-6 !rounded-none !border-[#183272]  border " onClick={()=>{setShow(false)}}>Cancel</button>
      
    </div>
    <input type="file" ref={fileInput} className="hidden" accept=".png,.jpg" />
    </div>
    
  </div>
</section>
    );
  };

  export default RecentProjectView;