// import './style.css';
import {useRef, useState, useEffect  } from 'react';
import { Modal } from "react-bootstrap";

const DynamicUIModal = ({   show=false, setShow=(e)=>{}, renderComponents=[], componentsFactory = (component)=>{}}) => {
    return (

        <Modal
   show={show}
   onHide={()=>{
    setShow(false);

   }}
   size='lg'
 >
   <Modal.Header closeButton></Modal.Header>
        
   {
  renderComponents.map((component) => {
    return componentsFactory(component);
  })
}



        </Modal>

    );
};

export default DynamicUIModal;
