import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Navigation, Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {setTrialState} from '../../reducers/cartReducer';

import ShadowBackground from '../shadow/ShadowBackground';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Link } from 'react-router-dom';
import useViewport from '../../uai/components/hooks/useViewport';
import img1 from '../../images/BaseEmptyBG.png';
import { set } from 'immutable';
import axios from 'axios';
import webapi from '../../assets/images/product/plugins/UAI_WebAPI_01.png';
import dinoegg2 from '../../assets/images/details/imagegen/dinoEgg_02.png';
import dinoegg3 from '../../assets/images/details/imagegen/dinoEgg_03.png';
import dinoegg4 from '../../assets/images/details/imagegen/dinoEgg_04.png';
import dinoegg5 from '../../assets/images/details/imagegen/dinoEgg_05.png';
import dinoegg6 from '../../assets/images/details/imagegen/dinoEgg_06.png';
import dinoegg7 from '../../assets/images/details/imagegen/dinoEgg_07.png';
import dinoegg8 from '../../assets/images/details/imagegen/dinoEgg_08.png';
import dinoegg9 from '../../assets/images/details/imagegen/dinoEgg_09.png';
import dinoegg10 from '../../assets/images/details/imagegen/dinoEgg_10.png';
import { prev } from 'dom7';
import { max } from 'moment';

const previewImages = [webapi, dinoegg2, dinoegg3, dinoegg4, dinoegg5, dinoegg6, dinoegg7, dinoegg8, dinoegg9, dinoegg10];

WebAPIHomePreviewPanel.propTypes = {
    
};


function WebAPIHomePreviewPanel(props) {
    const {data} = props;
    const dispatch = useDispatch();
    const {width, height, orientation} = useViewport();
    const [prompt, setPrompt] = useState("");
    const [isGenerating, setIsGenerating] = useState(false);
    const [generatedMedia, setGeneratedMedia] = useState(false);
    const trial = useSelector((state) =>
        state.appState ? state.appState.trial : null
    );
    const maxImages = 5;
    const [trialFinished, setTrialFinished] = useState(trial ?trial.imagegen > maxImages ? true : false:false);

    const pluginsPanel = useRef(null);
    const generateButton = useRef(null);
    const previewImageGen = useRef(null);
    const [ generatedImage, setGeneratedImage] = useState(<img className="previewImage-row" ref={previewImageGen}  src={img1} alt="Binasea" />);
    // const [imageClass, setImageClass] = useState(orientation != "Portrait" ? "previewImage-row" : "previewImage-row-portrait")

    const checkTrialFinished = (trialState) => {
        if(trialState.imagegen > 5){
            setTrialFinished(true);
        }
        }
    useEffect(() => {
        // const randomPreviewImage = Math.floor(Math.random() * previewImages.length);
        // previewImageGen.current.src = pluginsc4d;
        pluginsPanel.current.style.background = "url("+webapi+")";
        pluginsPanel.current.style.backgroundSize = "auto";
        pluginsPanel.current.style.backgroundColor = "rgba(0, 0, 0, 0.5)";

        console.log();
    }, []);

    useEffect(() => {
        console.log("width: ", width);
        console.log("height: ", height);
        console.log("orientation: ", orientation);
    }, [width, height, orientation]);

    const generateImage = async () => {

        if(maxImages < trial.imagegen){
            alert("You have reached the maximum number of generated images for the free trial. Please purchase a license or tokens to continue.");
            return;
        }
        const endpoint = "https://bravomedianyc--bravo-stablediffusionxllightning-flask-app.modal.run/sdxllightning";
        const baseButtonHTML = generateButton.current.innerText;
        setIsGenerating(true);
        setGeneratedMedia(true);

        generateButton.current.innerText = "Generating...";
        
        const randomNumber = Math.floor(Math.random() * 100000);
        const req = {
            "ratio": 1,
            "configScale": 0,
            "ratio": 1,
            "seed": randomNumber,
            "prompt": prompt,
            "negative_prompt": "nsfw. boobs. not safe for work. nudity. nipples. vagina. penis. high contrast. very contrast. .2d. drawing. painterly. multiple people. more than one person. (octane render, render, drawing, anime, bad photo, bad photography:1.3), (worst quality, low quality, blurry:1.2), (bad teeth, deformed teeth, deformed lips), (bad anatomy, bad proportions:1.1), (deformed iris, deformed pupils), (deformed eyes, bad eyes), (deformed face, ugly face, bad face), (deformed hands, bad hands, fused fingers), morbid, mutilated, mutation, disfigured",
            "num_images_per_prompt": 1,
            "size": [1920, 1920],
            "steps": 6,

            
    
    "faceFix": 1,
    "version" :1.3,
    "upscale" :2,
    "bg_upsampler" :"realesrgan",
    "bg_tile" :400,
    "suffix" :"None",
    "only_center_face" :false,
    "aligned" :true,
    "ext" :"auto",
    "weight" :0.75,
        }

        
        const response = await axios.post(
            endpoint, 
            req,
        );

        const newTrial = {... trial}
        newTrial.imagegen += 1;
   dispatch(setTrialState(newTrial));

   checkTrialFinished(newTrial);
    
        const base64img = response.data.media[0].media;
        const baseString=  "data:image/png;base64," + base64img;
        // previewImageGen.current.src = baseString;
        generateButton.current.innerText = baseButtonHTML;
setIsGenerating(false);
    }

    const validateInput = (value) =>{

        setPrompt(value);

    }

    return (
                <div className="tf-container w-100 py-12 overflow-hidden" ref={pluginsPanel}>
                    <div className="row z-index-ui  w-100">
                        <div className="col-md-12  w-100">
                                    <div className="tf-slider-item style-4 p-10px  w-100">
                                        <div className={"content-container" + (orientation != "Portrait" ? "" : "-portrait") + " w-100" }>



                                                <div className={"content-inner"+ (orientation != "Portrait" ? "" : " content-inner-portrait")  + " w-100 text-center items-center"}>
                                                    <h1 className={"heading"+ (orientation != "Portrait" ? "" : " heading-portrait marginAuto")+ " mb-0" }>
                                                        {"Web API"}
                                                        {/* {idx.heading} */}
                                                    </h1>
                                                    <p className={"sub-heading" + (orientation != "Portrait" ? "" : " sub-heading-portrait marginAuto") + " mb-0"}>{"Use our Web API to build and deploy your own AI powered applications and experiences." }</p>
                                                    

                                                    
                                              
                                                    <div className={"buttonsArea"+ (orientation != "Portrait"  ?  "" : width < 700  ? " buttonsArea-portrait marginAuto" : " justify-content-center") }>
                                                    <div className={"btn-slider "+ (orientation != "Portrait" ? "" : width < 700  ?  " marginAuto" : "" )}>
                                                       
                                                       
                                                        <div onClick={()=>{
                                                            if(trialFinished){
                                                                window.location.href = "/products";
                                                            }else{
                                                            if(!isGenerating){
                                                            generateImage();
                                                            }
                                                            }
                                                            }} className="tf-button style-2 pointer  z-index-ui"  ref={generateButton}>{"Documentation"}{trialFinished ? <i className="fa-solid fa-shop"></i>: <i className="fa fa-rocket"></i>} </div>
                                                    </div>


                                                    <div className={"btn-slider "+ (orientation != "Portrait" ?"" : width < 700  ?  " marginAuto" : "")}>
                                                        <Link to="/docs-stablev-imagegen" className="tf-button style-2 yellow-border pointer">Learn More <i className="fa fa-circle-info"></i></Link>
                                                    </div>
                                                    </div>
                                                </div>

                                                
{/* 
                                                <div className="image">
                                                    <img src={idx.img} alt="Binasea" />
                                                
                                                </div> */}
                                                </div>
                                            </div>
                        </div>
                    </div>
                    
          <ShadowBackground color={"#000000b5"} />
                </div>
    );
}

export default WebAPIHomePreviewPanel;