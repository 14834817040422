import { Howl, HowlerGlobal } from "howler";
import { TimelineEngine } from "@xzdarcy/react-timeline-editor";

class AudioControl {
  cacheMap: Record<string, Howl> = {};
  listenerMap: Record<
    string,
    {
      time?: (data: { time: number }) => void,
      rate?: (data: { rate: number }) => void,
    }
  > = {};

  start(data: {
    id: string,
    engine: TimelineEngine,
    src: string,
    startTime: number,
    time: number,
  }) {
    const { id, src, startTime, time, engine } = data;
    let item: Howl;
    if (this.cacheMap[id]) {
      item = this.cacheMap[id];
      item.rate(engine.getPlayRate());
      item.seek((time - startTime) % item.duration());
      item.play();
    } else {
      item = new Howl({ src, loop: true, autoplay: true });
      this.cacheMap[id] = item;
      item.on("load", () => {
        item.rate(engine.getPlayRate());
        item.seek((time - startTime) % item.duration());
      });
    }

    const timeListener = (data: { time: number }) => {
      const { time } = data;
      item.seek(time);
    };
    const rateListener = (data: { rate: number }) => {
      const { rate } = data;
      item.rate(rate);
    };
    if (!this.listenerMap[id]) this.listenerMap[id] = {};
    engine.on("afterSetTime", timeListener);
    engine.on("afterSetPlayRate", rateListener);
    this.listenerMap[id].time = timeListener;
    this.listenerMap[id].rate = rateListener;
  }

  stop(data: { id: string, engine: TimelineEngine }) {
    const { id, engine } = data;
    if (this.cacheMap[id]) {
      const item = this.cacheMap[id];
      item.stop();
      if (this.listenerMap[id]) {
        this.listenerMap[id].time &&
          engine.off("afterSetTime", this.listenerMap[id].time);
        this.listenerMap[id].rate &&
          engine.off("afterSetPlayRate", this.listenerMap[id].rate);
        delete this.listenerMap[id];
      }
    }
  }

  
}

const StartRecord =(timeline)=>{
  // ** Successful example of capturing Howl output to MediaRecorder
const audioURL = 'https://cdn.glitch.com/02dcea11-9bd2-4462-ac38-eeb6a5ad9530%2F331_full_beautiful-minds_0171_preview.mp3?1522829295082'
 
// example of recording one sound looping for 3 seconds
// connect MediaStreamDestination to Howler.masterGain 
let streamDest = HowlerGlobal.ctx.createMediaStreamDestination()   
HowlerGlobal.masterGain.connect(streamDest) // connect masterGain to destination
 
// set up media recorder to record output
let chunks = []
let mediaRecorder = new MediaRecorder(streamDest.stream, {mimeType: 'audio/webm'})
mediaRecorder.onstart = () => { console.log('Started recording Howl output...') }
mediaRecorder.ondataavailable = (e) => { chunks.push(e.data) }
mediaRecorder.onstop = () => { 
  console.log("Done recording. Now let's try playback...")
  let fileReader = new FileReader() // to convert blob to data url
  fileReader.onload = (e) => {  
    new Howl({src: e.target.result, format: ['webm']}).play() 
  }
  fileReader.readAsDataURL(new Blob(chunks)) // sends url to onLoad
}
 
// start sound and recording
timeline.play(); mediaRecorder.start()  
// stop in a few seconds 

}



export default new AudioControl();
