import { create } from "zustand";
import {getOperation, getOperations } from "./useOperations";
import {getGenerateFX} from "./useRuntime";
import {createDictionary} from '../../../api/firebaseHandler';
import {setRenderQueue} from './useGeneratedMedia';

import {emit} from './useSignalSlots';
const useComposite = create((set) => ({
    request: {},
    lastOutput: {},
    image:"",
    audio:"",
    video:"",
    text:"",
    renderQueue: [],
    setRequest : (request) =>
        set((state) => {
            return { request: request };
        }
    ),
    setLastOutput : (output) =>
        set((state) => {
            return { lastOutput: output };
        }
    ),
    extendLastOutput : (output) =>
        set((state) => {
            return { lastOutput: {...state.lastOutput, ...output} };
        }
    ),
    extendRequest : (request) =>
        set((state) => {
            return { request: {...state.request, ...request} };
        }
    ),
    setImage : (image) =>
        set((state) => {
            return { image: image };
        }
    ),
    setAudio : (audio) =>
        set((state) => {
            return { audio: audio };
        }
    ),
    setVideo : (video) =>
        set((state) => {
            return { video: video };
        }
    ),
    setText : (text) =>
        set((state) => {
            return { text: text };
        }
    ),
    
    addRenderQueue: (mediaCatalog) =>
        set((state) => {
            return { renderQueue: [...state.renderQueue, mediaCatalog] };
        }
    ),
    removeRenderQueue: (mediaCatalog) =>
        set((state) => {
            return {
                renderQueue: state.renderQueue.filter(
                    (item) => item !== mediaCatalog
                ),
            };
        }
    ),
    
}));

export default useComposite;

const runAction = (component, action) => {
    const operation  = `${action.module}.${action.operation}`; 
    const cleanedUp = getGenerateFX().map((item) => item.component);
    cleanedUp[0].action = action;
    const dict = createDictionary(cleanedUp);
    emit('operation.run', {operation: operation, value: cleanedUp, dictionary: dict})
}

export const getOperationByID =(id)=>{
    const match = getOperations().filter((item) => item.id == id);
    if(match.length > 0){
        return match[0];
    }else{
        return null;
    }
}

export const CreateFunction = (props, module) =>{
    const {operation, value, dictionary, component, action, signal, manager} = props;
return (async () => {
    const compositeState = useComposite.getState();
                const request = compositeState.request;
                const lastOutput = compositeState.lastOutput;
                const values = PreProcessValues(props);
                const outputData = await module.function({request,lastOutput,operation, values, dictionary, component, action, signal, manager, compositeState} );
                compositeState.extendLastOutput(outputData);
})

}

export const builtInFunctions = {


    "eval": (data) =>{
        return eval(data);
    },
    "textInput": (data) =>{
    return useComposite.getState().text;
    },
    "imageInput": (data) =>{
        return useComposite.getState().image;
    },
    "audioInput": (data) =>{
        return useComposite.getState().audio;
    },
    "videoInput": (data) =>{
        return useComposite.getState().video;
    },
    "request": (data) =>{
        return useComposite.getState().request;
    },
    "date": (data) =>{
        return new Date().toISOString();
    }

}



const parseValue = (value) =>{
    let outputValue = value;
    if(typeof value === 'string'){

            
        const matched = value.match(/\$\((.*)\)/);
        if(matched != null){
        const expression = matched[1];

        let args = {};
        let expressionBase = expression.match(/(\w+)\(/)[1];

        if(expressionBase.includes("()")){
        expressionBase = expressionBase.replace("()", "");
        }
        let withoutBase = expression.replace(expressionBase, "");
        
        let expressionArguments = withoutBase.match(/\(([^)]+)\)/);
        if(expressionArguments != null){
            const argsString = expressionArguments[1];
            
        const  jsonString = argsString.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2":');
        args = JSON.parse(jsonString);

            
            console.log(args);

        }
        
        const expressionData = builtInFunctions[expressionBase ];
        if(expressionData != undefined){
            const expressionOutput = expressionData(args);
            if(expressionOutput != undefined){
              
            if(expressionOutput != ""){
                outputValue = expressionOutput;
            }
            }
        }

    }

    }
    return outputValue;

}

export const PreProcessValues = (props) =>{
    const {operation, value, dictionary, component, action, signal, manager} = props;
    for(let i = 0; i < value.length; i++){
        const comp_ = value[i];
        const values = comp_.values;
   
            for (let k = 0; k < values.subwindows.length; k++) {
                const subwindow = values.subwindows[k];
                for (let l = 0; l < subwindow.fields.length; l++) {
                    const field = subwindow.fields[l];
                    value[i].values.subwindows[k].fields[l].value  = parseValue(field.value);
                }
            }
}
return value;
}

export const RunComponent = (props) =>{
    // {operation: operation, value: cleanedUp, dictionary: dict, component:component, action:action, signal:signal}
    const {operation, value, dictionary, component, action, signal, manager} = props;
    const preFunction = getOperationByID(action.preFunction);
    const postFunction = getOperationByID(action.postFunction);
    const op = getOperationByID( operation);
    let match = []
    if(preFunction != null){
        match.push(preFunction);
    }
    if(op != null){
        match.push(op);

    }
    if(postFunction != null){
        match.push(postFunction);

    }
    const isUI = operation.includes(".ui.");
    for (let i = 0; i < value.length; i++) {
    const dictKey = dictionary[Object.keys(dictionary)[i]];

        value[i].values = dictKey;
    }

    // setRenderQueue(match);
    console.log('match :', match);
    let uiQueue = [];
    let renderQueue = [];


    if(match.length > 0){
        for (let i = 0; i < match.length; i++) {
            match[i].props =  props ;
            if(isUI){
                console.log("AddToUIQueue");
                uiQueue.push(
                CreateFunction(props, match[i])
                );
            manager.props.uaiProject.AddToUIQueue(
                CreateFunction(props, match[i])) ;
        }else{
            console.log("AddToRenderQueue");

            renderQueue.push([
                CreateFunction(props, match[i])
            ]);
            
        }
        }
    }

    if(uiQueue.length > 0){
    manager.props.uaiProject.AddToUIQueue(uiQueue);
    }

    if(renderQueue.length > 0){
        manager.props.uaiProject.AddToRenderQueue(renderQueue);
    }


    if(!isUI){
        manager.props.uaiProject.StartRenderQueue();
    }else{
    }


    props.signal('operation.run', props);
    
}

const RunCompositeComponents = (components:[]) =>{
    
}