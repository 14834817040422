import { useEffect, useState, useRef } from "react";
import BGImage from "../../../../assets/images/background/BaseEmptyBGHD.png";
import PlayerButtonBar from "../../TimelineEditor/components/buttonbar";
import ThreeD  from "../../threeD";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { useSignals } from "../../../api/signalSlots";
import PreviewModeButtons from "./previewModeButtons";
import AnimatedSlider from "../../../components/AnimatedSlider";
import {useAppManager} from "../../../api/appManager";
import usePreviewArea from "../api/usePreviewArea";
import { useHotkeys } from "react-hotkeys-hook";
import { useSignal } from 'react-signal-slot';
import {  useSlot } from '../api/useSignalSlots';
// import { useSlot as useSlotGenerate } from '../api/useSignalSlots';

const baseGLTF = 'https://firebasestorage.googleapis.com/v0/b/uai-backend.appspot.com/o/common%2Fassets%2Fgeo%2Fcube.glb?alt=media';

let viewMode = "image";
let scale = 1;
const PreviewArea = ({ urlImg = BGImage, key_="", node=undefined }) => {
    const manager = useAppManager();
    const [initialized, setInitialized] = useState(false);
    
  const [playing, setPlaying] = useState(false);
    // const [mode, setMode] = useState("image");
    const [threeDUrl, setThreeDUrl] = useState(baseGLTF);
    // const [scale, setScale] = useState(100);
    const [threeDLoader, setThreeDLoader] = useState("gltf");
    const containerRef = useRef(null);
    const imageview = useRef(null);
    const videoview = useRef(null);
    const threejsview = useRef(null);
    const [ resolution, setResolution] = useState([1920, 1080])
    const  [frameTimestamp, setFrameTimestamp] = useState(0);
    const canvas = useRef();
    const parentResolutionPreview = usePreviewArea((state) =>    state.previewModeWindow)
    const parentResolutionTimeline = usePreviewArea((state) => state.timelineModeWindow )
    const signal = useSignal();
    const hotkeys = useHotkeys('space', () => {
       
        if (key_ == "timeline" && manager.props.focusedPanel.includes("timeline")) {
            if(manager.props.timelinePlaying){

            manager.props.timelinePlaying = false;
            manager.props.timeline.pause();
            }else{
                
            manager.props.timelinePlaying = true;
            manager.props.timeline.play();
            }
        }
    });
    const playPlayer = () => {
        if (viewMode === "video" || viewMode === "audio") {
            setPlaying(true);
            if (key_ == "timeline") {
                manager.props.timelinePlaying = true;
            } else {
                manager.props.previewPlaying = true;
            }
            videoview.current.play();
        }
    }
    useSlot("preview.showVideo.url", (e) => {
        if(e.key_ != key_) return;
        const url = e.url;
        videoview.current.src = url;
        manager.props.setMode("video");
        viewMode = "video";
        showCanvas();
    signal('previewArea.setMode.preview');

        // draw("video");
    });
    useSlot( "preview.showAudio.url", (e) => {
        if(e.key_ != key_) return;
        const url = e.url;
        videoview.current.src = url;
        manager.props.setMode("audio");
        viewMode = "audio";
        showCanvas();
signal('previewArea.setMode.preview');

        // draw("video");
    });
    useSlot("preview.gltf.url", (e) => {
        if(e.key_ != key_) return;
        const url = e.url;
        console.log(url);
        manager.props.print(url);
        setThreeDUrl(url);
        manager.props.setMode("gltf");
        setThreeDLoader("gltf");
        viewMode = "gltf";
        show3D();
        updatePlaymode(false);
    signal('previewArea.setMode.preview3D');

        // draw("video");
    });
    useSlot("preview.fbx.url", (e) => {
        if(e.key_ != key_) return;
        const url = e.url;
        setThreeDUrl(url);
        manager.props.setMode("fbx");
        setThreeDLoader("fbx");
        viewMode = "fbx";
        show3D();
        updatePlaymode(false);
    signal('previewArea.setMode.preview3D');

        // draw("video");
    });
    useSlot("preview.obj.url", (e) => {
        if(e.key_ != key_) return;
        const url = e.url;
        setThreeDUrl(url);
        manager.props.setMode("obj");
        setThreeDLoader("obj");
        viewMode = "obj";
        show3D();
        updatePlaymode(false);
    signal('previewArea.setMode.preview');

        // draw("video");
    });
    useSlot("preview.showImage.base64", (e) => {

        if(e.key_ != key_) return;
        const base64img = e.base64;
        const baseString=  "data:image/png;base64," + base64img;
        const previewImage = document.getElementById("previewImage");
        previewImage.src = baseString;
        manager.props.setMode("image");
        viewMode = "image";
    signal('previewArea.setMode.preview');

        showCanvas();
        // draw("image");
    });
    useSlot("preview.showImage.url", (e) => {
        if(e.key_ != key_) return;

        const url = e.url;
        const previewImage = document.getElementById(key_+"_view");
        previewImage.src = url;
        try{
        videoview.current.pause();
        videoview.current.currentTime = 0;
        }catch{

        }
        manager.props.setMode("image");
        viewMode = "image";
    signal('previewArea.setMode.preview');

        showCanvas();
        
        // draw("image");
    });
   
    useSlot("play.player", (e) => {
        if(e.key_ != key_) return;
        playPlayer();

    });
    useSlot('ffBack.player', (e) => {

        if(e.key_ != key_) return;
       FBack();
    });
    useSlot('ffForward.player', (e) => {

        if(e.key_ != key_) return;
      FForward();
    });
    useSlot("previewArea.setMode.preview", (e) => {
        // viewMode = "image";
        // console.log("image");

        if( key_ != 'preview') return;
        resetToPreviewMode();
    });
    useSlot("previewArea.setMode.preview3D", (e) => {
        // viewMode = "image";
        // console.log("image");

        if( key_ != 'preview') return;
        resetToPreviewMode3D();
    });
    useSlot("previewArea.setMode.timeline", (e) => {
            
        if( key_ != 'timeline') return;
        viewMode = "timeline";
        console.log("timeline");
        resetToTimelineMode();
    });
    useSlot("zoom.in.player", (e) => {
            
        if( key_ != e.key) return;
        const newScale = manager.props.previewViewerScale + 10;
        manager.props.previewViewerScale = newScale;
        console.log(newScale);
    });
    useSlot("zoom.out.player", (e) => {
            
        if( key_ != e.key) return;
        let newScale = manager.props.previewViewerScale - 10;
        if(newScale < 0){
            newScale = 0;
        }

    });
    useSlot();
    
    const resetToPreviewMode = () => {
        showCanvas();
    }

    const resetToPreviewMode3D = () => {
        show3D();
    }

    const resetToTimelineMode = () => {
        showCanvas();
    }

    const drawImageView = (context) => {
        if(imageview.current){
            drawImageScaled(imageview.current, context);
            // context.drawImage(imageview.current, 0, 0);
            }
        }


    const drawVideoView = (context) => {
        // const doc = document.getElementById("videoImage");
        // const timePercent = doc.currentTime / doc.duration;
        // const pointerPosition = timePercent * 1920;
            context.drawImage(videoview.current, 0, 0);
            // context.fillStyle = "red";
            // context.fillRect(pointerPosition, 1080-10, 3, 10);
            
        }


    const drawAudioView = (context) => {
        const doc = videoview.current;
        // const doc = document.getElementById("videoImage");
        const timePercent = doc.currentTime / doc.duration;
        const pointerPosition = timePercent * 1920;
            context.drawImage(doc, 0, 0);
            context.fillStyle = "red";
            context.fillRect(pointerPosition, 1080-1000, 3, 1000);
            
        }


    const draw3DView = (context) => {
        const doc = document.getElementById("ThreeD");
        }

    const drawTimelineView = (context) => {
        }

   
    function drawImageScaled(img, ctx) {
        var canvas = ctx.canvas ;
        var hRatio = canvas.width  / img.width    ;
        var vRatio =  canvas.height / img.height  ;
        var ratio  = Math.min ( hRatio, vRatio );
        var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
        var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  
        ctx.clearRect(0,0,canvas.width, canvas.height);
        try{
        ctx.drawImage(img, 0,0, img.width, img.height,
                           centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);  

        }catch{


        }
     }
     const FBack = () => {
        videoview.current.currentTime = 0;
        setFrameTimestamp(0);
        syncManager();
    }
    const FForward = () => {
        videoview.current.currentTime = videoview.current.duration;
        setFrameTimestamp(100);
        syncManager();
    }

     const show3D = () => {
            threejsview.current.className = threejsview.current.className.replace("hidden", "");

            if(!canvas.current.className.includes("hidden")){
                canvas.current.className +=" hidden";
            }
     }
     const showCanvas = () => {

        canvas.current.className = canvas.current.className.replace("hidden", "");
        if(!threejsview.current.className.includes("hidden")){
            threejsview.current.className += " hidden";
        }
     }
     const updatePlaymode = (state_) => {
        setPlaying(state_);
        if(key_ == "timeline"){
            manager.props.timelinePlaying = state_;
        }else{
            manager.props.previewPlaying = state_;
        }
        if(state_){
            if(viewMode == "video" || viewMode == "audio"){
            videoview.current.play();
            }
        }else{
           
            // if(viewMode == "video" || viewMode == "audio" ){
                videoview.current.pause();
                // }
        }
     }

     const syncManager = () => {

        if(key_ == "timeline"){
            manager.props.timelineViewer = videoview.current;
            manager.props.timelineFrameTimestamp = frameTimestamp;
            manager.props.timelinePlaying = playing;
        }else{
            manager.props.previewViewer = videoview.current
            manager.props.previewFrameTimestamp = frameTimestamp;

            manager.props.previewPlaying = playing;
        }

    }

    useEffect (() => {
        if(initialized == false && videoview){
            
           

        if(key_ == "timeline"){
            manager.props.timelineViewer = videoview.current;
            manager.props.timelineSetFrameTimestamp = setFrameTimestamp;
            manager.props.timelineFrameTimestamp = frameTimestamp;
            manager.props.timelinePlaying = playing;
            manager.props.timelinesetPlaying = setPlaying;
        }else{
            manager.props.previewViewer = videoview.current
            manager.props.previewSetFrameTimestamp = setFrameTimestamp
            manager.props.previewFrameTimestamp = frameTimestamp;

            manager.props.previewPlaying = playing;
            manager.props.previewsetPlaying = setPlaying;
        }
        videoview.current.addEventListener('loadeddata', function() {
            manager.props.setMode("video");
            viewMode = "video";
            signal("preview.video.loaded", { source: videoview.current });
            
            videoview.current.play();
            
            // Video is loaded and can be played
        }, false);
        videoview.current.addEventListener('timeupdate', function() {
            const perc = videoview.current.currentTime / videoview.current.duration;
            setFrameTimestamp(perc * 100);
           syncManager();
            // const  vidTimeline = document.getElementById("videoSlider");
            // vidTimeline.value = perc * 100;
            signal("video.timeupdate", { source: perc });
            
        });

        
        videoview.current.addEventListener('play', function() {
            signal("play.player", { source: videoview.current });
            
        });


        
        
        videoview.current.addEventListener('ended', function() {
            setPlaying(false);
            if(key_ == "timeline"){
                manager.props.timelinePlaying = false;
            }else{
                manager.props.previewPlaying = false;
            }
            signal("video.ended", { source: videoview.current });
            
        });

        
        videoview.current.addEventListener('playing', function() {
            setPlaying(true);
            if(key_ == "timeline"){
                manager.props.timelinePlaying = true;
            }else{
                manager.props.previewPlaying = true;
            }
            signal("video.playing", { source: videoview.current });
      
        });

        
        videoview.current.addEventListener('pause', function() {
            
            setPlaying(false);
            if(key_ == "timeline"){
                manager.props.timelinePlaying = false;
            }else{
                manager.props.previewPlaying = false;
            }
            signal("video.pause", { source: videoview.current });
          
        });

        videoview.current.addEventListener('durationchange', function() {
            signal("video.durationchange", { source: videoview.current });
        
        });


        videoview.current.addEventListener('ratechange', function() {
            signal("video.ratechange", { source: videoview.current });
       
        });


        canvas.current.width = 1920;
        canvas.current.height = 1080;
        requestAnimationFrame(()=>{
            draw();
        })
        setInitialized(true);
    }


    }, []);

    // useEffect(() => {

    //     const res = key_ == "timeline" ? parentResolutionTimeline : parentResolutionPreview;
    //     let wBigger = res[0] > res[1];
    //     res[0] = res[0] - 20;
    //     const aspectratio = 16/9;
    //     let newRes = [res[0],res[1] ];
    //     const hMaxSize = res[1] -  containerRef.current.offsetHeight;
    //     let w = res[0];
    //     let h = w / aspectratio;

    
    //     if(h > hMaxSize){

    //         h = hMaxSize;
    //         w = Math.round( h * aspectratio);

    //     }
    //     newRes = [w, h];

    //     // canvas.current.width = newRes[0];
    //     // canvas.current.height = newRes[1];
    //     // setResolution(newRes);
    // }, [parentResolutionTimeline, parentResolutionPreview]);

    const draw = () => {
        if(canvas.current == null) return;
        const context = canvas.current.getContext('2d');
        if(viewMode == "timeline"){
            drawTimelineView(context);
        }
        else if(viewMode == "image"){
            drawImageView(context);
        }
        else if(viewMode == "video"){
            drawVideoView(context);
        }
        else if(viewMode == "audio"){
            drawAudioView(context);
        }
        else if(viewMode == "gltf" || viewMode == "fbx" || viewMode == "obj"){
            draw3DView(context);
        }
        requestAnimationFrame(()=>{
            draw();
        })
    }


    return (
        <>



<div fillspace="no" className="shadow-sm justify-center items-center border border-gray-300 p-4 border-0
    flex flex-col !h-full relative"  onMouseOver={() =>{
        const newKey =  "previewArea." + key_;
        manager.props.focusedPanel =newKey;
        }}>


{/* <PreviewModeTabs manager={manager} /> */}
<PreviewModeButtons manager={manager} />
<div className=" !h-full w-full flex flex-col gap-3">
    

<div className="flex flex-row  aspect-video p-6">

    <div ref={threejsview} id="ThreeD" className="flex flex-row aspect-video h-full w-full max-h-[50vh] hidden">
<ThreeD url={threeDUrl} loader={threeDLoader == "gltf" ? GLTFLoader : threeDLoader == "fbx" ? FBXLoader  : threeDLoader == "obj" ? OBJLoader :GLTFLoader}/>
</div>

<canvas ref={canvas} id={"renderCanvas_" + key_ }  className={`  appBlueBorder rounded-none overflow-y m-auto w-full`} width={1920} height={1080} />
        {/* <Canvas canvas={canvas} draw={draw} height={1080} width={1920} frame={frameTimestamp}   classname="  appBlueBorder rounded-none  max-h-[50vh] "/> */}
{/* {mode === "image" ? */}
    <img
        src={BGImage} ref={imageview} alt="Preview Image" id={key_+"_view"} className="  appBlueBorder rounded-none  max-h-[50vh] hidden"/>
    
    
    <video
        ref={videoview}className="  appBlueBorder rounded-none  max-h-[50vh] hidden"/>
    
</div>
<PreviewButtonControls key_={key_} containerRef={containerRef} />

</div>

    
    
    
    {/* {PreviewButtonControls(frameTimestamp, videoview, key)} */}



</div>

    </>

    )


}

export default PreviewArea;

export const  PreviewButtonControls=({  key_="timeline", containerRef=undefined})=> {

    const manager = useAppManager();

    const syncManagerFrameTimeStamp = (frame) => {

        if(key_ == "timeline"){
            manager.props.timelineFrameTimestamp = frame;
        }else{
            manager.props.previewFrameTimestamp = frame;

        }

    }


    const FBack = () => {
        if(key_ == "timeline"){
            manager.props.timelineViewer.currentTime = 0;
            
            manager.props.timelineSetFrameTimestamp(0);
        }else{
            manager.props.previewViewer.currentTime = 0
            manager.props.previewSetFrameTimestamp(0);
        }
        syncManagerFrameTimeStamp(0);

    }
    const FForward = () => {
        
        if(key_ == "timeline"){
            manager.props.timelineViewer.currentTime =manager.props.timelineViewer.duration;
            manager.props.timelineSetFrameTimestamp(100);

        }else{
            manager.props.previewViewer.currentTime = manager.props.previewViewer.duration;
            manager.props.previewSetFrameTimestamp(100);
        }
        syncManagerFrameTimeStamp(100);

  
    }

    const generateButtonBar = () => {


        switch(manager.props.mode){


            case "image":
                return(
                    <div className="flex space-x-4 justify-center items-center w-full">
                        <PlayerButtonBar manager={manager} key_={key_} playing={key_ == "timeline" ?manager.props.timelinePlaying : manager.props.previewPlaying } setPlaying={key_ == "timeline" ?manager.props.timelinesetPlaying : manager.props.previewsetPlaying} FBack={FBack} FForward={FForward} />
                    </div>
                );
            
            case "video":
                return(
                    <div  className="flex space-x-4 justify-center items-center w-full">
                    <PlayerButtonBar manager={manager} key_={key_} playing={key_ == "timeline" ?manager.props.timelinePlaying : manager.props.previewPlaying } setPlaying={key_ == "timeline" ?manager.props.timelinesetPlaying : manager.props.previewsetPlaying} FBack={FBack} FForward={FForward}  />
                    </div>
                );
            default:
                return(
                    <div className="flex space-x-4 justify-center items-center w-full">
                    <PlayerButtonBar manager={manager} key_={key_} playing={key_ == "timeline" ?manager.props.timelinePlaying : manager.props.previewPlaying } setPlaying={key_ == "timeline" ?manager.props.timelinesetPlaying : manager.props.previewsetPlaying}  FBack={FBack} FForward={FForward}  />
                    </div>
                );
    }
    }



    return (<div className="w-full flex flex-col" ref={containerRef} >
        <AnimatedSlider value={key_ == "timeline" ?manager.props.timelineFrameTimestamp : manager.props.previewFrameTimestamp } min={0} max={100}
            onUserChange={(e) => {
                const view = key_ == "timeline" ?manager.props.timelineViewer : manager.props.previewViewer;
                const newTime = (e.target.value / 100) * view.duration;
                view.currentTime = newTime;
            } }
            className="slider" id="videoSlider" />

        <div className="w-full flex flex-col pt-4">

            {generateButtonBar()}

        </div>
    </div>);
}
