import axios from 'axios';


const sendPostRequest = async (request, endpoint) =>{

    // dictionary["570537e7-7"].subwindows[0].fields[0]
    console.log("request", request);
    console.log("endpoint", endpoint);
    const response = await axios.post(
        endpoint, 
        request,
        {headers:{ "Authorization": "Bearer " + process.env.REACT_APP_UAI_API_KEY}}
    );
    return response;
}

const setBrowserPath = (path) => {

    document.dispatchEvent(new CustomEvent("filebrowser.setpath", {detail: {url: path}}));
}



const setBrowserImagesPath = () => {
const url= `stableV/${JSON.parse(localStorage.getItem("user")).id.toString()}/${JSON.parse(localStorage.getItem("project")).id.toString()}/assets/image`;
setBrowserPath(url);
}

const setBrowserAudioPath = (path) => {
const url= `stableV/${JSON.parse(localStorage.getItem("user")).id.toString()}/${JSON.parse(localStorage.getItem("project")).id.toString()}/assets/audio/${path}`;
setBrowserPath(url);
}


export {sendPostRequest, setBrowserPath, setBrowserImagesPath, setBrowserAudioPath}