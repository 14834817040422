import React , {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Spinner } from '@material-tailwind/react';
import PageTitle from '../components/pagetitle/PageTitle';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import data from '../assets/fake-data/data-hotpick'
import icon1 from '../assets/images/icon/rain1.svg'
import icon2 from '../assets/images/icon/rain2.svg'
import icon3 from '../assets/images/icon/ethe.svg'
import CardModal from '../components/layouts/CardModal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import avtd1 from '../assets/images/author/author-detail-1.png'
import avtd2 from '../assets/images/author/author-detail-2.png'

import avtd6 from '../assets/images/author/author-history-1.jpg'
import avtd7 from '../assets/images/author/author-history-2.jpg'
import { getProductsList, getStripeInfo,getPriceByID ,getPriceInfo } from '../uai/api/dlc';
import { getLicenseTypes, createPlaceholderLicense } from '../uai/api/licenses';
import { getdlcs } from '../uai/api/dlc';
import { copy } from '../uai/utils/common';
import { StandardPack } from '../uai/utils/ProductStandardPack';
import {setProducts, setCart} from '../reducers/cartReducer';

import { Select, Option } from "@material-tailwind/react";
import '../uai/uaistyle.css';
import  LoadingSpinner  from './LoadingSpinner';
import RelatedProducts from './RelatedProducts';
import IncludedProducts from './IncludedProducts';
import {uaiLicense} from '../uai/constants/products';

function toTitleCase(str) {
    const newStr = str
      .split(' ')
      .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
    return newStr;
  }

  const toSkip = ["Reporter", "Developer", "Trial"];

const  ProductPage = ({props}) => {
    const state = useSelector(state => state);
    const productStripe = useSelector(state => state.appState.products.currentProductStripe);

    const product = useSelector(state => state.appState.products.currentProduct);
    const products = useSelector(state => state.appState.products);
    // const currentPlan = useSelector(state => state.appState.products.currentPlan);
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [catalogList, setcatalogList] = useState(undefined);
    const [mode, setmode] = useState('loading');
    
    const [plugins, setplugins] = useState([]);
    const [apps, setapps] = useState([]);
    const [loadingMessage, setloadingMessage] = useState('Loading...');
    const [currentProduct, setcurrentProduct] = useState([]);
    const [currentPlan, setcurrentPlan] = useState([]);
  const [currentLicenseType, setcurrentLicenseType] = useState(state.appState.products.currentProduct ? state.appState.products.currentProduct.Variation[0] : undefined);
  const [currentLicensePlan, setCurrentLicensePlan] = useState(state.appState.products.currentProduct ? state.appState.products.currentProduct.Variation.length > 0 &&  state.appState.products.currentProduct.Variation[0].Plans ? state.appState.products.currentProduct.Variation[0].Plans[0] : undefined: undefined);

  const [licenseTypes, setlicenseTypes] = useState(undefined);
  const [licenseTypesSelect, setlicenseTypesSelect] = useState([]);

  const [currentPriceModifier, setcurrentPriceModifier] = useState(1);
  const [currentPrice, setcurrentPrice] = useState(0);

    const setNewPrice = async (priceID) => {

        const priceInfo = await getPriceInfo(priceID);
        let newProduct = products;
        newProduct.currentPlanInfo = priceInfo;
        dispatch(setProducts(newProduct));
        setcurrentPrice(priceInfo.unit_amount / 100);
        return priceInfo;

    }
    const applyUAILicenseApp = () => {
        dispatch(setProducts(uaiLicense));
        window.location = "/productDetails" ;
    }
    const hideVariations = ["Trial", "Developer", "Reporter"];
    const changeIntervals = async (plan) => {


        let intervalsMenuSelectedIndex = 0;
        try{
             intervalsMenuSelectedIndex = document.getElementById('intervals').selectedIndex;

        }catch{
            intervalsMenuSelectedIndex = 0;
        }
        let priceInfo = undefined;
        const filteredPlans = plan.Plans.filter((variation) => hideVariations.includes(variation.Name) == false);
        let newProduct = products;
        newProduct.planIntervals  = 
        filteredPlans.map((variation) =>
                <option value={variation.Name}>{variation.Name}</option>
            );


                if(filteredPlans.length < intervalsMenuSelectedIndex){
                    const lastIndex = filteredPlans.length - 1;
                    priceInfo = await setNewPrice(filteredPlans[lastIndex].StripeIDProduction);

                }else{
                    priceInfo = await  setNewPrice(filteredPlans[intervalsMenuSelectedIndex].StripeIDProduction);
                }
        plan.Plan = priceInfo;
        newProduct.currentPlan = plan;
        newProduct.currentProduct.plan = newProduct.currentPlan;
        dispatch(setProducts(newProduct));


    }
    const planSelected = (e) => {

        const filter = state.appState.products.currentProduct.Variation.filter((item) => item.Name == e.target.value);
        const item = copy(filter[0],true);
        
        changeIntervals(item);
        
    }
    const planIntervalSelected = async (e) => {

        let priceInfo = undefined;

        const filter = products.currentPlan.Plans.filter((item) => item.Name == e.target.value);
        const item = copy(filter[0],true);

        priceInfo = await  setNewPrice(item.StripeIDProduction);
        let newState = state.appState.products;
        newState.currentPlan.Plan = priceInfo;
        newState.currentProduct.plan = newState.currentPlan;


        dispatch(setProducts(newState));


    }
    const getProductPrice = async (priceid) => {
        const newPrice =  await getPriceByID(priceid);
        let newState = state.appState.products;
        newState.currentLicenseType = newPrice;
        dispatch(setProducts(newState));
        setcurrentPlan(newPrice);
     }


      const updateLicenseVariation = async (variation) => {
        let newState = state.appState.products;
        newState.currentVariation = variation;
    dispatch(setProducts(newState));
        setcurrentLicenseType(variation);
        console.log(variation.Plans);
        setCurrentLicensePlan(variation.Plans[0]);
        await getProductPrice(variation.Plans[0].StripeIDProduction);
      }

      const updateLicensePlan = async (variation) => {
        setCurrentLicensePlan(variation);
        await getProductPrice(variation.StripeIDProduction);
      }

    const getToken = async () => {
        console.log(1);
        console.log(state.appState.products.currentProduct.stripeID.production);
        // const stripeInfo = await getStripeInfo(uaiLicense.stripeID.production);
        const stripeInfo = await getStripeInfo(state.appState.products.currentProduct.stripeID.production);
        let newState = state.appState.products;
        newState.currentProductStripe = stripeInfo;
        console.log(2);

        setcatalogList(state.appState.products.catalogList);
        setplugins(state.appState.products.plugins);
        setapps(state.appState.products.apps);
        setcurrentProduct(state.appState.products.currentProduct);
        setlicenseTypes(state.appState.products.licenseTypes);
        setlicenseTypesSelect(state.appState.products.licenseTypesSelect);
        const variations_ = state.appState.products.currentProduct.Variation.filter((idx) => {
            if(toSkip.includes(idx.Name) == false){
                return idx;
                    }  
            
        });
        if(variations_.length > 0){
       newState.currentVariation = variations_[0];
        }
        dispatch(setProducts(newState));
        console.log(3);
        console.log(variations_);

        if(variations_.length > 0){
           await updateLicenseVariation(variations_[0]);

        }
        // filter for  UAI: Base App in productsdlc.data
        setmode('main');

        // const variations = state.appState.products.currentProduct.Variation;
        // let filteredVariations = variations.filter((variation) => hideVariations.includes(variation.Name) == false);
        // let newProduct = products;
        // newProduct.planPlans  = 
        // filteredVariations.map((variation) => (
        //         <option value={variation.Name}>{variation.Name}</option>
        //     ));
        //     newProduct.currentPlan = filteredVariations[0];
        // dispatch(setProducts(newProduct));

        // changeIntervals(filteredVariations[0]);
    //     // console.log(store.getState().uaiUser.currentproductSelected);
    //     setloadingMessage('Loading license types');
    
    //     const userresult = await getLicenseTypes();
    //     setloadingMessage('Loading products');

    //     const productsdlc = await getdlcs();
    //     const data = await userresult;
    //     console.log(productsdlc);
    //     const licenseselect = [];
    //     let index = 0;
        
    //     for (index = 0; index < data.data.length; index += 1) {
    //       licenseselect.push({
    //         value: data.data[index].id,
    //         label: toTitleCase(data.data[index].name),
    //         color: '#f7a9c4',
    //       });
    //     }
    //     setlicenseTypes(data.data);
    //     setlicenseTypesSelect(licenseselect);
    //     setproducts(productsdlc.data);
    //     // filter for  UAI: Base App in productsdlc.data
    //     setcurrentProduct(StandardPack);
    
    // await getcatalog();
        // setlicenseTypesSelect(data.data);
      };

 
  const handleLicencenseTypeChange = (selectedOption) => {
    const filter = licenseTypes.filter((item) => {
      if (item.id === selectedOption.value) {
        return item;
      }
      return false;
    });


    // setcurrentPlan(filter[0]);
    switch (selectedOption.label) {
      case 'trial':
        setcurrentPriceModifier(0);
        // code block
        break;
      case 'commercial':
        setcurrentPriceModifier(1.5);
        // code block
        break;
      case 'reporter':
        setcurrentPriceModifier(1.5);
        // code block
        break;
      case 'academic':
        setcurrentPriceModifier(0.4);
        // code block
        break;
      case 'enterprise':
        setcurrentPriceModifier(2.2);
        // code block
        break;
      case 'dev':
        setcurrentPriceModifier(0);
        // code block
        break;
      default:
        setcurrentPriceModifier(1.5);
      // code block
    }
    // console.log(selectedOption);
  };
  const openProduct = (newProduct, childProduct = false) => {
    let newProducts = products;
    if(childProduct == true){
    newProducts.parentProduct = copy(newProducts.currentProduct, true);
    }
    newProducts.currentProduct = newProduct;
    dispatch(setProducts(newProducts));
// window.location = "/productDetails" ;
getToken();
}

    const addToCart = (e) => {
        e.preventDefault();
        let cart = state.appState.cart;
        console.log(currentProduct);
        const user_ = JSON.parse(localStorage.getItem('user'));
        const newlic = (   createPlaceholderLicense(user_, currentProduct, currentLicenseType, 'Sharing', products.currentPlanInfo.unit_amount / 100));
        console.log(newlic);
        newlic.plan = currentPlan;
        // newlic.plan = newlic.dlc.plan.Plan;

        cart.items.push(newlic);
        dispatch(setCart(cart));

        window.location.href = '/cart';


    }
    



    const [tabHistory] = useState([
        {
            id: 1,
            name : 'StableV',
            type : 'Image Gen',
            avt: avtd6,

        },
        {
            id: 2,
            name : 'Parrotek ',
            type : 'Voice Gen',
            avt: avtd7,

        },
        {
            id: 3,
            name : 'Personabot ',
            type : 'Assistant Bot',
            avt: avtd6,

        },
        {
            id: 4,
            name : 'Rotobot ',
            type : 'Rotoscope',
            avt: avtd7,

        },
        {
            id: 5,
            name : '3000 Tokens Per Month',
            type : 'Perk',
            avt: avtd6,

        },

    ])



    useEffect(() => {
        dispatch(setCart({
            "percentOff": 0,
            "totalPrice": 0,
            "discount": 0,
            "subtotal": 0,
            "items": [],
            "couponCode": "",
            "coupon": {},
            "metaData": {},
        }));
        if(mode === 'loading'){
        getToken();
        }
        }
        , [state.appState.products.currentLicenseType]);
        console.log(product);

    return (
        <div >


            <PageTitle sub={products.parentProduct  ? products.parentProduct.name :  'Products'} subOnClick={
                ()=>{
                    if(products.parentProduct){
                        let newProducts = products;
                        newProducts.currentProduct = copy( newProducts.parentProduct, true);
                        newProducts.parentProduct = undefined;
                        dispatch(setProducts(newProducts));
                        openProduct(products.currentProduct, false);
                    }
                }

            } title='Product Details' />
            {mode != "loading" ? 
            <>
            <section className="tf-item-detail w-5/6 m-auto">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-item-detail-inner">
                                <div className="image">
                                    <img className="w-[390px]" src={state.appState.products.currentProductStripe.images[0]} alt="Binasea" />
                                </div>
                                <div className="content">
                                    <div className="content-top">
                                        {/* <div className="author">
                                            <img src={avt1} alt="Binasea" />
                                            <h6 className="title">Trending Arts</h6>
                                        </div> */}

                                    </div>
                                    <h2 className="title-detail">{state.appState.products.currentProductStripe.name}</h2>
                
<div className="w-full flex mb-4">
<div className="w-full h-20 !bg-tranparent flow">
                                        <h3 className="title text-2xl m-auto pr-3 mr-3 mb-2 pb-2">Type</h3>
<div className="w-full h-20 !bg-tranparent flex  mb-4  pb-4">
                                    <select   className="selectDropdown h-16 my-auto text-center  hover:text-yellow-800" name="variation" id="vaiation"
                                    onChange={ async (e) => {
                                        const filter = state.appState.products.currentProduct.Variation.filter((item) => {
                                            if (item.Name === e.target.value) {
                                              return item;
                                            }
                                            return false;
                                          });
                                          setcurrentLicenseType(filter[0]);
                                          const newState = state.appState.products;
                                          newState.currentVariation = filter[0];
                                          dispatch(setProducts(newState));
                                          await updateLicenseVariation(filter[0]);
                                        await getProductPrice(filter[0].Plans[0].StripeIDProduction);


                                    
                                    }}
                                    >
                                        {state.appState.products.currentProduct.Variation.map(idx => {

                                                if(toSkip.includes(idx.Name) == false){
                                            return <option value={idx.Name}>{idx.Name}</option>
                                                }
                                        })}
</select>
{currentLicenseType.Plans ? 
                                    <select   className="selectDropdown h-16 ml-4 my-auto text-center  hover:text-yellow-800" name="cars" id="cars"
                                     onChange={ async (e) => {
                                        if(currentLicenseType.Plans && currentLicenseType.Plans.length > 0){
                                        const filter = currentLicenseType.Plans.filter((item) => {
                                            if (item.StripeIDProduction === e.target.value) {
                                              return item;
                                            }
                                            return false;
                                          });
                                          updateLicensePlan(filter[0]);
                                        }
                                        


                                    
                                    }
                                
                                }
                                    
                                    
                                    >

                                        {
                                            currentLicenseType.Plans ? currentLicenseType.Plans.map(idx => {
                                                return <option value={idx.StripeIDProduction}>{idx.Name}</option>
                                            }) : <></>
                                            }

                                        
                                        

</select>
: <></>}

<div className="wishlish selectDropdown h-16 w-fit cursor-pointer my-auto ml-4 "  onClick={addToCart} >
                                            <div className="number-wishlish  h-20  w-60 text-center my-auto hover:text-yellow-800">
                                                <FontAwesomeIcon  className='pr-6 icon-fill border-0 borer-[#b9b8bb] rounded-full hover:text-yellow-800 ' icon={faCartPlus} onClick={addToCart} />
{"$"}{currentPlan.unit_amount / 100} {"USD"}</div>

    </div>               

</div>
    
    </div>
    
                                        </div>
                                
                                    <Tabs className="tf-tab">
                                        <TabList className="menu-tab ">
                                            <Tab className="tab-title active"><Link to="#">Details</Link></Tab>
                                        <Tab className="tab-title "><Link to="#">Included Apps</Link></Tab>
                                        <Tab className="tab-title "><Link to="#">Size</Link></Tab>
                                        <Tab className="tab-title "><Link to="#">Download</Link></Tab>
        
                                            
                                            
                                        </TabList>

                                        <TabPanel >
                                            <div className="tab-details">
                                                    <div className="">
                                    <p className="except text-justify mb-5 !font-normal">{productStripe.description}</p>
                                                    </div>
                                                </div>
                                        </TabPanel>
                                        
                                        <TabPanel >

                                        <ul className="tab-history">
                                            {
                                                product.includedItems ? product.includedItems.map(idx => (
                                                    <li key={idx.id}>
                                                        <div className="box-history">
                                                            <div className="infor">
                                                                <div className="img w-20 cursor-pointer"><img src={idx.image} alt="Binasea" onClick={()=>{
                                                                            openProduct(idx, true);
                                                                }} /></div>
                                                                <div className="content">
                                                                    <h6 className="name">{idx.name}</h6>
                                                                    {/* <p className="time">8 hours ago</p> */}
                                                                </div>
                                                            </div>
                                                            <div className="price !text-lg ">
                                                                <p  className="!text-lg ">{idx.productType}</p>
                                                                {/* <span>= $12.245</span> */}
                                                            </div>
                                                        </div>
                                                    </li>
                                                )) : <></>
                                            }

                                                </ul>

                                        


                                        {/* <ul className="tab-history tab-details">
                                        <div className="title-propepties font-bold underline underline-offset-2"></div>
                                                    <ul className="properties ">
                                                        <li className=" "><Link to="#"><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 11.375L1.09375 7.53125L0 8.375L6 13.0312L12 8.375L10.9062 7.53125L6 11.375ZM6 9.65625L10.9062 5.84375L12 5L6 0.34375L0 5L1.09375 5.84375L6 9.65625ZM6 2.03125L9.8125 5L6 7.96875L2.1875 5L6 2.03125Z" fill="white"/>
                                                            </svg>Stable V</Link></li>
                                            
                                                        <li><Link to="#"><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 5C6.28125 5 7.34375 3.96875 7.34375 2.65625C7.34375 1.375 6.28125 0.34375 5 0.34375C3.71875 0.34375 2.65625 1.375 2.65625 2.65625C2.65625 3.96875 3.71875 5 5 5ZM5 1.65625C5.5625 1.65625 6 2.125 6 2.65625C6 3.21875 5.5625 3.65625 5 3.65625C4.4375 3.65625 4 3.21875 4 2.65625C4 2.125 4.4375 1.65625 5 1.65625ZM5.03125 8.34375H2.1875C2.84375 8 3.96875 7.65625 5 7.65625C5.0625 7.65625 5.15625 7.6875 5.21875 7.6875C5.46875 7.1875 5.84375 6.78125 6.3125 6.46875C5.84375 6.375 5.375 6.34375 5 6.34375C3.4375 6.34375 0.34375 7.125 0.34375 8.65625V9.65625H5V8.65625C5 8.5625 5 8.4375 5.03125 8.34375ZM10 6.65625C8.78125 6.65625 6.34375 7.34375 6.34375 8.65625V9.65625H13.6562V8.65625C13.6562 7.34375 11.2188 6.65625 10 6.65625ZM10.8125 5.46875C11.3125 5.15625 11.6562 4.625 11.6562 4C11.6562 3.09375 10.9062 2.34375 10 2.34375C9.09375 2.34375 8.34375 3.09375 8.34375 4C8.34375 4.625 8.6875 5.15625 9.1875 5.46875C9.4375 5.59375 9.71875 5.65625 10 5.65625C10.2812 5.65625 10.5625 5.59375 10.8125 5.46875Z" fill="white"/>
                                                            </svg>
                                                            Parrotek</Link></li>
                                                        <li><Link to="#"><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 5C6.28125 5 7.34375 3.96875 7.34375 2.65625C7.34375 1.375 6.28125 0.34375 5 0.34375C3.71875 0.34375 2.65625 1.375 2.65625 2.65625C2.65625 3.96875 3.71875 5 5 5ZM5 1.65625C5.5625 1.65625 6 2.125 6 2.65625C6 3.21875 5.5625 3.65625 5 3.65625C4.4375 3.65625 4 3.21875 4 2.65625C4 2.125 4.4375 1.65625 5 1.65625ZM5.03125 8.34375H2.1875C2.84375 8 3.96875 7.65625 5 7.65625C5.0625 7.65625 5.15625 7.6875 5.21875 7.6875C5.46875 7.1875 5.84375 6.78125 6.3125 6.46875C5.84375 6.375 5.375 6.34375 5 6.34375C3.4375 6.34375 0.34375 7.125 0.34375 8.65625V9.65625H5V8.65625C5 8.5625 5 8.4375 5.03125 8.34375ZM10 6.65625C8.78125 6.65625 6.34375 7.34375 6.34375 8.65625V9.65625H13.6562V8.65625C13.6562 7.34375 11.2188 6.65625 10 6.65625ZM10.8125 5.46875C11.3125 5.15625 11.6562 4.625 11.6562 4C11.6562 3.09375 10.9062 2.34375 10 2.34375C9.09375 2.34375 8.34375 3.09375 8.34375 4C8.34375 4.625 8.6875 5.15625 9.1875 5.46875C9.4375 5.59375 9.71875 5.65625 10 5.65625C10.2812 5.65625 10.5625 5.59375 10.8125 5.46875Z" fill="white"/>
                                                            </svg>
                                                            Personabot</Link></li>

                                                        <li><Link to="#"><svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 5C6.28125 5 7.34375 3.96875 7.34375 2.65625C7.34375 1.375 6.28125 0.34375 5 0.34375C3.71875 0.34375 2.65625 1.375 2.65625 2.65625C2.65625 3.96875 3.71875 5 5 5ZM5 1.65625C5.5625 1.65625 6 2.125 6 2.65625C6 3.21875 5.5625 3.65625 5 3.65625C4.4375 3.65625 4 3.21875 4 2.65625C4 2.125 4.4375 1.65625 5 1.65625ZM5.03125 8.34375H2.1875C2.84375 8 3.96875 7.65625 5 7.65625C5.0625 7.65625 5.15625 7.6875 5.21875 7.6875C5.46875 7.1875 5.84375 6.78125 6.3125 6.46875C5.84375 6.375 5.375 6.34375 5 6.34375C3.4375 6.34375 0.34375 7.125 0.34375 8.65625V9.65625H5V8.65625C5 8.5625 5 8.4375 5.03125 8.34375ZM10 6.65625C8.78125 6.65625 6.34375 7.34375 6.34375 8.65625V9.65625H13.6562V8.65625C13.6562 7.34375 11.2188 6.65625 10 6.65625ZM10.8125 5.46875C11.3125 5.15625 11.6562 4.625 11.6562 4C11.6562 3.09375 10.9062 2.34375 10 2.34375C9.09375 2.34375 8.34375 3.09375 8.34375 4C8.34375 4.625 8.6875 5.15625 9.1875 5.46875C9.4375 5.59375 9.71875 5.65625 10 5.65625C10.2812 5.65625 10.5625 5.59375 10.8125 5.46875Z" fill="white"/>
                                                            </svg>
                                                            Rotobot</Link></li>

                                                            <li><Link to="#"><svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10.125 8.28125L7.96875 7.21875C8.8125 6.53125 9.375 5.5 9.375 4.34375C9.375 2.3125 7.71875 0.65625 5.71875 0.65625C3.6875 0.65625 2.03125 2.3125 2.03125 4.34375C2.03125 5.75 2.875 7 4.03125 7.59375V9.78125C2.625 9.46875 2.6875 9.46875 2.53125 9.46875C2.1875 9.46875 1.84375 9.625 1.59375 9.875L0.65625 10.8125L4.0625 14.2188C4.34375 14.5 4.75 14.6562 5.15625 14.6562H9.375C10 14.6562 10.5625 14.1875 10.6875 13.5625L11.2188 10.4062C11.3438 9.53125 10.9062 8.6875 10.125 8.28125ZM9.90625 10.1875L9.375 13.3438H5.15625C5.09375 13.3438 5.03125 13.3125 5 13.2812L2.53125 10.8125L5.375 11.4062V4.34375C5.375 4.15625 5.53125 4 5.71875 4C5.90625 4 6.03125 4.15625 6.03125 4.34375V8.34375H7.21875L9.53125 9.5C9.78125 9.625 9.9375 9.90625 9.90625 10.1875ZM3.375 4.34375C3.375 3.03125 4.4375 2 5.71875 2C7 2 8.03125 3.03125 8.03125 4.34375C8.03125 4.96875 7.78125 5.53125 7.375 5.96875V4.34375C7.375 3.40625 6.625 2.65625 5.71875 2.65625C4.78125 2.65625 4.03125 3.40625 4.03125 4.34375V5.96875C3.625 5.53125 3.375 4.96875 3.375 4.34375Z" fill="white"/>
                                                            </svg>3000 Tokens Per Month</Link></li>
                                                    
                                                        <li><Link to="#" className="active">See more</Link></li>
                                                    </ul>

                                                </ul> */}
                                        </TabPanel>
                                    
                                        <TabPanel >
                                            <div className="tab-details">
                                                    <div className="">
                                    <p className="except text-justify mb-5 !font-normal">{state.appState.products.currentProductStripe.metadata ? state.appState.products.currentProductStripe.metadata.Size : ""}</p>
                                                    </div>
                                                </div>
                                        </TabPanel>

                            </Tabs> 
    
                                    {/* <div className="content-bottom">
                                        <div className="heading">
                                            <h6>Price</h6>
                                            <div className="price"><span className="icon p-14">{"$"}{currentProduct.price}{" USD"}</span></div>
                                        </div>
                                        <div className="button">
                                            <Link to="#" className="tf-button" data-toggle="modal" data-target="#popup_bid">Add To Cart</Link>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {product.includedItems ? product.includedItems.length > 0 ? 
            <IncludedProducts product={product}/> :
            <></>: <></>
        }
            
            </>:  <LoadingSpinner Title='Loading' Message={loadingMessage}/>}
            
        </div>
    );
}

ProductPage.propTypes = {
    
};

export default ProductPage;