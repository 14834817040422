
import {Layout, Model} from 'flexlayout-react';

export const defaultLayoutJSON = {
	"global": {
		"splitterSize": 2,
		"tabSetEnableSingleTabStretch": true
	},
	"borders": [],
	"name": "Default Editor",
	"layout": {
		"type": "row",
		"id": "1",
		"children": [
			{
				"type": "row",
				"id": "#a6f8d050-6bc1-438a-b1ff-678f5274c0b3",
				"weight": 120.46543463381245,
				"children": [
					
					{
						"type": "row",
						"id": "#446661f4-d68e-471d-8c68-bc0c2735df49",
						"weight": 59.70975602626207,
						"children": [
							{
								"type": "tabset",
								"id": "#7bd0e366-4ee6-4076-8542-5cc55194d3f7",
								"weight": 11.72713126670289,
								"children": [
									{
										"type": "tab",
										"id": "#45f9627e-db56-4c4e-bf96-6b859d70dc18",
										"name": "FXPanel",
										"component": "fxPanel",
										"icon": "images/svg/check-2.svg"
									}
								]
							},
							{
								"type": "row",
								"id": "#db771d40-c5dd-40da-ba18-468edc3606e3",
								"weight": 21.970183886751073,
								"children": [
									{
										"type": "tabset",
										"id": "#59de3cfc-57f2-4eef-bb43-1825cbc3fadd",
										"weight": 89.06976744186046,
										"children": [
											{
												"type": "tab",
												"id": "5",
												"name": "Preview Mode",
												"component": "previewMode",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#3b241454-e215-4d9f-bbb8-ec7198c5a634",
										"weight": 10.930232558139535,
										"children": [
											{
												"type": "tab",
												"id": "#9eb8403b-f140-48b7-97a5-2c9edfdf5e54",
												"name": "Preview Viewer Controls",
												"component": "previewViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "row",
								"id": "#5b61b0f2-47dc-4e06-8635-97aa6bea1a4a",
								"weight": 21.970183886751073,
								"children": [
									{
										"type": "tabset",
										"id": "#566dae4e-fbfa-46af-9fea-03fb34e3f48b",
										"weight": 89.07672301690508,
										"children": [
											{
												"type": "tab",
												"id": "3",
												"name": "Timeline Viewer",
												"component": "timelineViewer"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#e19eeeaa-b286-4271-9b89-afd585a73981",
										"weight": 10.923276983094928,
										"children": [
											{
												"type": "tab",
												"id": "#ba6d82e3-066c-453f-b298-2762420d8920",
												"name": "Timeline Viewer Controls",
												"component": "timelineViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#52476e71-e82d-4215-92e3-51507577acb4",
								"weight": 15.832794306818991,
								"children": [
									{
										"type": "tab",
										"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfa9",
										"name": "File Browser",
										"component": "fileBrowser",
										"icon": "images/svg/icon-properties-1.svg"
									}
								],
								"active": true
							}
						]
					},
					{
						"type": "row",
						"id": "#9979abf9-455e-42aa-8757-c2a9297df0b7",
						"weight": 22.981639704472204,
						"children": [
							{
								"type": "row",
								"id": "#e899f061-ae8e-4d92-8dc4-1e6fdc855258",
								"weight": 98.89117043121149,
								"children": [
									{
										"type": "tabset",
										"id": "#a4e92f53-c1d6-4bfd-ad11-b2219db7eea6",
										"weight": 16.260162601626018,
										"children": [
											{
												"type": "tab",
												"id": "#76651935-c466-4b4b-8f2a-2ea6c3639280",
												"name": "Timeline Controls",
												"component": "timeline Controls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#b83d034a-a028-4859-a46b-3841dc7e3324",
										"weight": 83.73983739837398,
										"children": [
											{
												"type": "tab",
												"id": "#2e78c4c9-e7f7-4d5c-8e7a-030d40e4621d",
												"name": "Timeline",
												"component": "timeline",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#de8913c6-525e-401a-90e3-23e51bc80be4",
								"weight": 1.108829568788501,
								"children": [
									{
										"type": "tab",
										"id": "#3b4c2c3d-f896-4e23-9ca3-ad81e70d4db8",
										"name": "Volume",
										"component": "volume",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							}
						]
					},
					{
						"type": "tabset",
						"id": "#311d07f3-b813-4dc1-9428-23dcbcde39bc",
						"weight": 4.721303625087945,
						"children": [
							{
								"type": "tab",
								"id": "#117c92f6-52fa-4cf7-ade5-f26e42daaab2",
								"name": "Status Bar",
								"component": "statusBar",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			}
		]
	}
}

export const layoutFocused = {
	"global": {
		"splitterSize": 2,
		"tabSetEnableSingleTabStretch": true
	},
	"borders": [],
	"name": "Focused",
	"layout": {
		"type": "row",
		"id": "1",
		"children": [
			{
				"type": "row",
				"id": "#a6f8d050-6bc1-438a-b1ff-678f5274c0b3",
				"weight": 120.46543463381245,
				"children": [
					{
						"type": "tabset",
						"id": "#de3f6aff-fe48-4d36-8303-eac53a62be27",
						"weight": 1.7312345012983221,
						"children": [
							{
								"type": "tab",
								"id": "#7f432443-9d5c-4ed1-a2f7-77e9badbbefe",
								"name": "FileMenu",
								"component": "fileMenu",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					},
					{
						"type": "row",
						"id": "#446661f4-d68e-471d-8c68-bc0c2735df49",
						"weight": 59.70975602626207,
						"children": [
							{
								"type": "tabset",
								"id": "#7bd0e366-4ee6-4076-8542-5cc55194d3f7",
								"weight": 11.72713126670289,
								"children": [
									{
										"type": "tab",
										"id": "#45f9627e-db56-4c4e-bf96-6b859d70dc18",
										"name": "FXPanel",
										"component": "fxPanel",
										"icon": "images/svg/check-2.svg"
									}
								]
							},
							{
								"type": "row",
								"id": "#be13a355-6a0b-4b3a-a10c-f3a1693d2911",
								"weight": 43.940367773502146,
								"children": [
									{
										"type": "tabset",
										"id": "4",
										"weight": 46.45464707325718,
										"children": [
											{
												"type": "tab",
												"id": "3",
												"name": "Timeline Viewer",
												"component": "timelineViewer"
											},
											{
												"type": "tab",
												"id": "5",
												"name": "Preview Mode",
												"component": "previewMode",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#68c9b3b5-266e-4fd0-bef8-941a76623ad0",
										"weight": 5.362468987704135,
										"children": [
											{
												"type": "tab",
												"id": "#ba6d82e3-066c-453f-b298-2762420d8920",
												"name": "Timeline Viewer Controls",
												"component": "timelineViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											},
											{
												"type": "tab",
												"id": "#9eb8403b-f140-48b7-97a5-2c9edfdf5e54",
												"name": "Preview Viewer Controls",
												"component": "previewViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#52476e71-e82d-4215-92e3-51507577acb4",
								"weight": 15.832794306818991,
								"children": [
									{
										"type": "tab",
										"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfa9",
										"name": "File Browser",
										"component": "fileBrowser",
										"icon": "images/svg/icon-properties-1.svg"
									},
									{
										"type": "tab",
										"id": "#1da6d83f-bb35-44f6-be18-3b81108c3c75",
										"name": "Sequences",
										"component": "fileBrowser",
										"icon": "images/svg/icon-properties-1.svg"
									}
								],
								"active": true
							}
						]
					},
					{
						"type": "row",
						"id": "#9979abf9-455e-42aa-8757-c2a9297df0b7",
						"weight": 22.981639704472204,
						"children": [
							{
								"type": "row",
								"id": "#e899f061-ae8e-4d92-8dc4-1e6fdc855258",
								"weight": 98.89117043121149,
								"children": [
									{
										"type": "tabset",
										"id": "#a4e92f53-c1d6-4bfd-ad11-b2219db7eea6",
										"weight": 16.260162601626018,
										"children": [
											{
												"type": "tab",
												"id": "#76651935-c466-4b4b-8f2a-2ea6c3639280",
												"name": "Timeline Controls",
												"component": "timeline Controls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#b83d034a-a028-4859-a46b-3841dc7e3324",
										"weight": 83.73983739837398,
										"children": [
											{
												"type": "tab",
												"id": "#2e78c4c9-e7f7-4d5c-8e7a-030d40e4621d",
												"name": "Timeline",
												"component": "timeline",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#de8913c6-525e-401a-90e3-23e51bc80be4",
								"weight": 1.108829568788501,
								"children": [
									{
										"type": "tab",
										"id": "#3b4c2c3d-f896-4e23-9ca3-ad81e70d4db8",
										"name": "Volume",
										"component": "volume",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							}
						]
					},
					{
						"type": "tabset",
						"id": "#311d07f3-b813-4dc1-9428-23dcbcde39bc",
						"weight": 4.721303625087945,
						"children": [
							{
								"type": "tab",
								"id": "#117c92f6-52fa-4cf7-ade5-f26e42daaab2",
								"name": "Status Bar",
								"component": "statusBar",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			}
		]
	}
}

export const compactLayoutJSON = {
	"global": {
		"splitterSize": 2,
		"tabSetEnableSingleTabStretch": true
	},
	"borders": [],
	"name": "Compact",
	"layout": {
		"type": "row",
		"id": "1",
		"children": [
			{
				"type": "row",
				"id": "#a6f8d050-6bc1-438a-b1ff-678f5274c0b3",
				"weight": 120.46543463381245,
				"children": [
					{
						"type": "tabset",
						"id": "#de3f6aff-fe48-4d36-8303-eac53a62be27",
						"weight": 1.7312345012983221,
						"children": [
							{
								"type": "tab",
								"id": "#7f432443-9d5c-4ed1-a2f7-77e9badbbefe",
								"name": "FileMenu",
								"component": "fileMenu",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					},
					{
						"type": "row",
						"id": "#446661f4-d68e-471d-8c68-bc0c2735df49",
						"weight": 59.70975602626207,
						"children": [
							{
								"type": "row",
								"id": "#17eba44b-23ea-4a07-a417-b923967205a1",
								"weight": 31.743624990980383,
								"children": [
									{
										"type": "tabset",
										"id": "2",
										"weight": 50,
										"children": [
											{
												"type": "tab",
												"id": "#45f9627e-db56-4c4e-bf96-6b859d70dc18",
												"name": "FXPanel",
												"component": "fxPanel",
												"icon": "images/svg/check-2.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#7bd0e366-4ee6-4076-8542-5cc55194d3f7",
										"weight": 50,
										"children": [
											{
												"type": "tab",
												"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfa9",
												"name": "File Browser",
												"component": "fileBrowser",
												"icon": "images/svg/icon-properties-1.svg"
											}
										],
										"active": true
									}
								]
							},
							{
								"type": "row",
								"id": "#be13a355-6a0b-4b3a-a10c-f3a1693d2911",
								"weight": 33.33450872533516,
								"children": [
									{
										"type": "tabset",
										"id": "4",
										"weight": 43.8592705697703,
										"children": [
											{
												"type": "tab",
												"id": "5",
												"name": "Preview Mode",
												"component": "previewMode",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#68c9b3b5-266e-4fd0-bef8-941a76623ad0",
										"weight": 7.9578454911910095,
										"children": [
											{
												"type": "tab",
												"id": "#9eb8403b-f140-48b7-97a5-2c9edfdf5e54",
												"name": "Preview Viewer Controls",
												"component": "previewViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "row",
								"id": "#c95ef0cb-3776-4d43-ab8b-ed66f760fc0c",
								"weight": 34.92186628368446,
								"children": [
									{
										"type": "tabset",
										"id": "#cf52c65d-865e-4161-9f5a-eb8db1c5f471",
										"weight": 84.87690504103165,
										"children": [
											{
												"type": "tab",
												"id": "3",
												"name": "Timeline Viewer",
												"component": "timelineViewer"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#583535c3-0a0c-4d2d-993f-54708bfb1c2b",
										"weight": 15.123094958968347,
										"children": [
											{
												"type": "tab",
												"id": "#ba6d82e3-066c-453f-b298-2762420d8920",
												"name": "Timeline Viewer Controls",
												"component": "timelineViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							}
						]
					},
					{
						"type": "row",
						"id": "#9979abf9-455e-42aa-8757-c2a9297df0b7",
						"weight": 22.981639704472204,
						"children": [
							{
								"type": "row",
								"id": "#e899f061-ae8e-4d92-8dc4-1e6fdc855258",
								"weight": 98.89117043121149,
								"children": [
									{
										"type": "tabset",
										"id": "#a4e92f53-c1d6-4bfd-ad11-b2219db7eea6",
										"weight": 16.260162601626018,
										"children": [
											{
												"type": "tab",
												"id": "#76651935-c466-4b4b-8f2a-2ea6c3639280",
												"name": "Timeline Controls",
												"component": "timeline Controls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#b83d034a-a028-4859-a46b-3841dc7e3324",
										"weight": 83.73983739837398,
										"children": [
											{
												"type": "tab",
												"id": "#2e78c4c9-e7f7-4d5c-8e7a-030d40e4621d",
												"name": "Timeline",
												"component": "timeline",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#de8913c6-525e-401a-90e3-23e51bc80be4",
								"weight": 1.108829568788501,
								"children": [
									{
										"type": "tab",
										"id": "#3b4c2c3d-f896-4e23-9ca3-ad81e70d4db8",
										"name": "Volume",
										"component": "volume",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							}
						]
					},
					{
						"type": "tabset",
						"id": "#311d07f3-b813-4dc1-9428-23dcbcde39bc",
						"weight": 4.721303625087945,
						"children": [
							{
								"type": "tab",
								"id": "#117c92f6-52fa-4cf7-ade5-f26e42daaab2",
								"name": "Status Bar",
								"component": "statusBar",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			}
		]
	}
}

export const defaultLayoutOLDJSON = {
	"global": {
		"tabSetEnableSingleTabStretch": true,
		"splitterSize": 2,
	},
	"borders": [],
	"layout": {
		"type": "row",
		"id": "1",
		"children": [
			{
				"type": "row",
				"id": "#a6f8d050-6bc1-438a-b1ff-678f5274c0b3",
				"weight": 120.46543463381245,
				"children": [
					{
						"type": "tabset",
						"id": "#de3f6aff-fe48-4d36-8303-eac53a62be27",
						"weight": 1.7312345012983221,
						"children": [
							{
								"type": "tab",
								"id": "#7f432443-9d5c-4ed1-a2f7-77e9badbbefe",
								"name": "FileMenu",
								"component": "fileMenu",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					},
					{
						"type": "row",
						"id": "#446661f4-d68e-471d-8c68-bc0c2735df49",
						"weight": 71.46188080681594,
						"children": [
							{
								"type": "tabset",
								"id": "2",
								"weight": 17.715874890180896,
								"children": [
									{
										"type": "tab",
										"id": "#45f9627e-db56-4c4e-bf96-6b859d70dc18",
										"name": "FXPanel",
										"component": "fxPanel",
										"icon": 'images/svg/check-2.svg'
									}
								]
							},
							{
								"type": "row",
								"id": "#be13a355-6a0b-4b3a-a10c-f3a1693d2911",
								"weight": 34.19921073667995,
								"children": [
									{
										"type": "tabset",
										"id": "4",
										"weight": 43.8592705697703,
										"children": [
											{
												"type": "tab",
												"id": "5",
												"name": "Preview Mode",
												"component": "previewMode",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#68c9b3b5-266e-4fd0-bef8-941a76623ad0",
										"weight": 7.9578454911910095,
										"children": [
											{
												"type": "tab",
												"id": "#9eb8403b-f140-48b7-97a5-2c9edfdf5e54",
												"name": "Preview Viewer Controls",
												"component": "previewViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										],
										"active": true
									}
								]
							},
							{
								"type": "row",
								"id": "#c95ef0cb-3776-4d43-ab8b-ed66f760fc0c",
								"weight": 48.08491437313916,
								"children": [
									{
										"type": "tabset",
										"id": "#cf52c65d-865e-4161-9f5a-eb8db1c5f471",
										"weight": 84.87690504103165,
										"children": [
											{
												"type": "tab",
												"id": "3",
												"name": "Timeline Viewer",
												"component": "timelineViewer"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#583535c3-0a0c-4d2d-993f-54708bfb1c2b",
										"weight": 15.123094958968347,
										"children": [
											{
												"type": "tab",
												"id": "#ba6d82e3-066c-453f-b298-2762420d8920",
												"name": "Timeline Viewer Controls",
												"component": "timelineViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							}
						]
					},
					{
						"type": "row",
						"id": "#9979abf9-455e-42aa-8757-c2a9297df0b7",
						"weight": 13.94662172262852,
						"children": [
							{
								"type": "row",
								"id": "#e899f061-ae8e-4d92-8dc4-1e6fdc855258",
								"weight": 98.89117043121149,
								"children": [
									{
										"type": "tabset",
										"id": "#a4e92f53-c1d6-4bfd-ad11-b2219db7eea6",
										"weight": 30,
										"children": [
											{
												"type": "tab",
												"id": "#76651935-c466-4b4b-8f2a-2ea6c3639280",
												"name": "Timeline Controls",
												"component": "timeline Controls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#b83d034a-a028-4859-a46b-3841dc7e3324",
										"weight": 70,
										"children": [
											{
												"type": "tab",
												"id": "#2e78c4c9-e7f7-4d5c-8e7a-030d40e4621d",
												"name": "Timeline",
												"component": "timeline",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#de8913c6-525e-401a-90e3-23e51bc80be4",
								"weight": 1.108829568788501,
								"children": [
									{
										"type": "tab",
										"id": "#3b4c2c3d-f896-4e23-9ca3-ad81e70d4db8",
										"name": "Volume",
										"component": "volume",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							}
						]
					},
					{
						"type": "tabset",
						"id": "#1ff8d350-f14f-470f-b3d9-f8e7b5d0a160",
						"weight": 10.856066142879488,
						"children": [
							{
								"type": "tab",
								"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfa9",
								"name": "File Browser",
								"component": "fileBrowser",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					},
					{
						"type": "tabset",
						"id": "#311d07f3-b813-4dc1-9428-23dcbcde39bc",
						"weight": 2.0041968263777514,
						"children": [
							{
								"type": "tab",
								"id": "#117c92f6-52fa-4cf7-ade5-f26e42daaab2",
								"name": "Status Bar",
								"component": "statusBar",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			}
		]
	}
}


export const layoutDavinci = {
	"global": {
		"splitterSize": 2,
		"tabSetEnableSingleTabStretch": true
	},
	"borders": [],
	"name": "Media Editor Sandwhich",
	"layout": {
		"type": "row",
		"id": "1",
		"children": [
			{
				"type": "row",
				"id": "#a6f8d050-6bc1-438a-b1ff-678f5274c0b3",
				"weight": 120.46543463381245,
				"children": [
					{
						"type": "tabset",
						"id": "#de3f6aff-fe48-4d36-8303-eac53a62be27",
						"weight": 1.7312345012983221,
						"children": [
							{
								"type": "tab",
								"id": "#7f432443-9d5c-4ed1-a2f7-77e9badbbefe",
								"name": "FileMenu",
								"component": "fileMenu",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					},
					{
						"type": "row",
						"id": "#446661f4-d68e-471d-8c68-bc0c2735df49",
						"weight": 59.70975602626207,
						"children": [
							{
								"type": "tabset",
								"id": "#7bd0e366-4ee6-4076-8542-5cc55194d3f7",
								"weight": 15.939991266661062,
								"children": [
									{
										"type": "tab",
										"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfa9",
										"name": "File Browser",
										"component": "fileBrowser",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							},
							{
								"type": "row",
								"id": "#be13a355-6a0b-4b3a-a10c-f3a1693d2911",
								"weight": 44.44242078403052,
								"children": [
									{
										"type": "tabset",
										"id": "4",
										"weight": 43.8592705697703,
										"children": [
											{
												"type": "tab",
												"id": "3",
												"name": "Timeline Viewer",
												"component": "timelineViewer"
											},
											{
												"type": "tab",
												"id": "5",
												"name": "Preview Mode",
												"component": "previewMode",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#68c9b3b5-266e-4fd0-bef8-941a76623ad0",
										"weight": 7.9578454911910095,
										"children": [
											{
												"type": "tab",
												"id": "#ba6d82e3-066c-453f-b298-2762420d8920",
												"name": "Timeline Viewer Controls",
												"component": "timelineViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											},
											{
												"type": "tab",
												"id": "#9eb8403b-f140-48b7-97a5-2c9edfdf5e54",
												"name": "Preview Viewer Controls",
												"component": "previewViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#52476e71-e82d-4215-92e3-51507577acb4",
								"weight": 11.117881296332454,
								"children": [
									{
										"type": "tab",
										"id": "#45f9627e-db56-4c4e-bf96-6b859d70dc18",
										"name": "FXPanel",
										"component": "fxPanel",
										"icon": "images/svg/check-2.svg"
									}
								],
								"active": true
							}
						]
					},
					{
						"type": "row",
						"id": "#9979abf9-455e-42aa-8757-c2a9297df0b7",
						"weight": 22.981639704472204,
						"children": [
							{
								"type": "row",
								"id": "#e899f061-ae8e-4d92-8dc4-1e6fdc855258",
								"weight": 98.89117043121149,
								"children": [
									{
										"type": "tabset",
										"id": "#a4e92f53-c1d6-4bfd-ad11-b2219db7eea6",
										"weight": 16.260162601626018,
										"children": [
											{
												"type": "tab",
												"id": "#76651935-c466-4b4b-8f2a-2ea6c3639280",
												"name": "Timeline Controls",
												"component": "timeline Controls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#b83d034a-a028-4859-a46b-3841dc7e3324",
										"weight": 83.73983739837398,
										"children": [
											{
												"type": "tab",
												"id": "#2e78c4c9-e7f7-4d5c-8e7a-030d40e4621d",
												"name": "Timeline",
												"component": "timeline",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#de8913c6-525e-401a-90e3-23e51bc80be4",
								"weight": 1.108829568788501,
								"children": [
									{
										"type": "tab",
										"id": "#3b4c2c3d-f896-4e23-9ca3-ad81e70d4db8",
										"name": "Volume",
										"component": "volume",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							}
						]
					},
					{
						"type": "tabset",
						"id": "#311d07f3-b813-4dc1-9428-23dcbcde39bc",
						"weight": 4.721303625087945,
						"children": [
							{
								"type": "tab",
								"id": "#117c92f6-52fa-4cf7-ade5-f26e42daaab2",
								"name": "Status Bar",
								"component": "statusBar",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			}
		]
	}
};

export const layoutPremiereFocused = {
	"global": {
		"splitterSize": 2,
		"tabSetEnableSingleTabStretch": true
	},
	"borders": [],
	"name": "Media Editor Focused",
	"layout": {
		"type": "row",
		"id": "1",
		"children": [
			{
				"type": "row",
				"id": "#b8ba95f7-4bcb-4c4e-ac54-ddf2226c9dbd",
				"weight": 37.88529325612647,
				"children": [
					{
						"type": "tabset",
						"id": "#216c82a4-23db-43c6-955a-5dd943e807fe",
						"weight": 64.57364341085271,
						"children": [
							{
								"type": "tab",
								"id": "#45f9627e-db56-4c4e-bf96-6b859d70dc18",
								"name": "FXPanel",
								"component": "fxPanel",
								"icon": "images/svg/check-2.svg"
							}
						]
					},
					{
						"type": "tabset",
						"id": "#e4bb9126-06ed-4670-84b6-6612ac1c3eb9",
						"weight": 35.42635658914729,
						"children": [
							{
								"type": "tab",
								"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfa9",
								"name": "File Browser",
								"component": "fileBrowser",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			},
			{
				"type": "row",
				"id": "#a6f8d050-6bc1-438a-b1ff-678f5274c0b3",
				"weight": 122.73528625562348,
				"children": [
					{
						"type": "row",
						"id": "#446661f4-d68e-471d-8c68-bc0c2735df49",
						"weight": 56.37124680249398,
						"children": [
							{
								"type": "row",
								"id": "#db771d40-c5dd-40da-ba18-468edc3606e3",
								"weight": 24.018549459570167,
								"children": [
									{
										"type": "tabset",
										"id": "#59de3cfc-57f2-4eef-bb43-1825cbc3fadd",
										"weight": 89.50542822677926,
										"children": [
											{
												"type": "tab",
												"id": "5",
												"name": "Preview Mode",
												"component": "previewMode",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#3b241454-e215-4d9f-bbb8-ec7198c5a634",
										"weight": 10.494571773220748,
										"children": [
											{
												"type": "tab",
												"id": "#9eb8403b-f140-48b7-97a5-2c9edfdf5e54",
												"name": "Preview Viewer Controls",
												"component": "previewViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "row",
								"id": "#5b61b0f2-47dc-4e06-8635-97aa6bea1a4a",
								"weight": 19.92181831393198,
								"children": [
									{
										"type": "tabset",
										"id": "#566dae4e-fbfa-46af-9fea-03fb34e3f48b",
										"weight": 89.38480096501809,
										"children": [
											{
												"type": "tab",
												"id": "3",
												"name": "Timeline Viewer",
												"component": "timelineViewer"
											}
										],
										"active": true
									},
									{
										"type": "tabset",
										"id": "#e19eeeaa-b286-4271-9b89-afd585a73981",
										"weight": 10.615199034981906,
										"children": [
											{
												"type": "tab",
												"id": "#ba6d82e3-066c-453f-b298-2762420d8920",
												"name": "Timeline Viewer Controls",
												"component": "timelineViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							}
						]
					},
					{
						"type": "row",
						"id": "#9979abf9-455e-42aa-8757-c2a9297df0b7",
						"weight": 26.320148928240272,
						"children": [
							{
								"type": "tabset",
								"id": "#b83d034a-a028-4859-a46b-3841dc7e3324",
								"weight": 98.89117043121149,
								"children": [
									{
										"type": "tab",
										"id": "#2e78c4c9-e7f7-4d5c-8e7a-030d40e4621d",
										"name": "Timeline",
										"component": "timeline",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							},
							{
								"type": "tabset",
								"id": "#de8913c6-525e-401a-90e3-23e51bc80be4",
								"weight": 1.108829568788501,
								"children": [
									{
										"type": "tab",
										"id": "#3b4c2c3d-f896-4e23-9ca3-ad81e70d4db8",
										"name": "Volume",
										"component": "volume",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							}
						]
					},
					{
						"type": "tabset",
						"id": "#311d07f3-b813-4dc1-9428-23dcbcde39bc",
						"weight": 4.721303625087945,
						"children": [
							{
								"type": "tab",
								"id": "#117c92f6-52fa-4cf7-ade5-f26e42daaab2",
								"name": "Status Bar",
								"component": "statusBar",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			}
		]
	}
}


export const layoutPremiere = {
	"global": {
		"splitterSize": 2,
		"tabSetEnableSingleTabStretch": true
	},
	"borders": [],
	"layout": {
		"type": "row",
		"id": "1",
		"children": [
			{
				"type": "row",
				"id": "#b8ba95f7-4bcb-4c4e-ac54-ddf2226c9dbd",
				"weight": 37.88529325612647,
				"children": [
					{
						"type": "tabset",
						"id": "#216c82a4-23db-43c6-955a-5dd943e807fe",
						"weight": 63.25581395348837,
						"children": [
							{
								"type": "tab",
								"id": "#45f9627e-db56-4c4e-bf96-6b859d70dc18",
								"name": "FXPanel",
								"component": "fxPanel",
								"icon": "images/svg/fxPanel.svg"
							},
							{
								"type": "tab",
								"id": "#eb0ab435-c082-4011-9955-50a04e0cff7c",
								"name": "Generate",
								"component": "generatePanel",
								"icon": "images/svg/aigenerate.svg"
							}
						]
					},
					{
						"type": "tabset",
						"id": "#e4bb9126-06ed-4670-84b6-6612ac1c3eb9",
						"weight": 36.74418604651163,
						"children": [
							{
								"type": "tab",
								"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfa9",
								"name": "File Browser",
								"component": "fileBrowser",
								"icon": "images/svg/fileBrowser.svg"
							},
							{
								"type": "tab",
								"id": "#1da6d83f-bb35-44f6-be18-3b81108c3c75",
								"name": "Sequences",
								"component": "sequences",
								"icon": "images/svg/sequences.svg"
							}
						]
					}
				]
			},
			{
				"type": "row",
				"id": "#a6f8d050-6bc1-438a-b1ff-678f5274c0b3",
				"weight": 122.73528625562348,
				"children": [
					{
						"type": "row",
						"id": "#446661f4-d68e-471d-8c68-bc0c2735df49",
						"weight": 43.14333690299179,
						"children": [
							{
								"type": "tabset",
								"id": "#59de3cfc-57f2-4eef-bb43-1825cbc3fadd",
								"weight": 21.970183886751073,
								"children": [
									{
										"type": "tab",
										"id": "5",
										"name": "Preview Mode",
										"component": "previewMode",
										"icon": "images/svg/tv.svg"
									},
									{
										"type": "tab",
										"id": "#60406091-9502-4038-a41d-fc76021bc78c",
										"name": "Debug",
										"component": "debugStatePanel",
										"icon": "images/svg/debug.svg"
									}
								]
							},
							{
								"type": "tabset",
								"id": "#566dae4e-fbfa-46af-9fea-03fb34e3f48b",
								"weight": 21.970183886751073,
								"children": [
									{
										"type": "tab",
										"id": "3",
										"name": "Timeline Viewer",
										"component": "timelineViewer",
										"icon": "images/svg/tv.svg"
									}
								]
							}
						]
					},
					{
						"type": "row",
						"id": "#9979abf9-455e-42aa-8757-c2a9297df0b7",
						"weight": 39.54805882774247,
						"children": [
							{
								"type": "row",
								"id": "#e899f061-ae8e-4d92-8dc4-1e6fdc855258",
								"weight": 98.89117043121149,
								"children": [
									{
										"type": "tabset",
										"id": "#a4e92f53-c1d6-4bfd-ad11-b2219db7eea6",
										"weight": 15.899581589958158,
										"children": [
											{
												"type": "tab",
												"id": "#76651935-c466-4b4b-8f2a-2ea6c3639280",
												"name": "Timeline Controls",
												"component": "timeline Controls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#b83d034a-a028-4859-a46b-3841dc7e3324",
										"weight": 84.10041841004184,
										"children": [
											{
												"type": "tab",
												"id": "#2e78c4c9-e7f7-4d5c-8e7a-030d40e4621d",
												"name": "Timeline",
												"component": "timeline",
												"icon": "images/svg/timeline.svg"
											}
										],
										"active": true
									}
								]
							},
							{
								"type": "tabset",
								"id": "#de8913c6-525e-401a-90e3-23e51bc80be4",
								"weight": 1.108829568788501,
								"children": [
									{
										"type": "tab",
										"id": "#3b4c2c3d-f896-4e23-9ca3-ad81e70d4db8",
										"name": "Volume",
										"component": "volume",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							}
						]
					},
					{
						"type": "tabset",
						"id": "#311d07f3-b813-4dc1-9428-23dcbcde39bc",
						"weight": 4.721303625087945,
						"children": [
							{
								"type": "tab",
								"id": "#117c92f6-52fa-4cf7-ade5-f26e42daaab2",
								"name": "Status Bar",
								"component": "statusBar",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			}
		]
	}
}


export const layoutGenerate = {
	"global": {
		"splitterSize": 2,
		"tabSetEnableSingleTabStretch": true
	},
	"borders": [],
	"layout": {
		"type": "row",
		"id": "1",
		"children": [
			{
				"type": "row",
				"id": "#b8ba95f7-4bcb-4c4e-ac54-ddf2226c9dbd",
				"weight": 37.88529325612647,
				"children": [
					{
						"type": "tabset",
						"id": "#216c82a4-23db-43c6-955a-5dd943e807fe",
						"weight": 63.25581395348837,
						"children": [
						
							{
								"type": "tab",
								"id": "#eb0ab435-c082-4011-9955-50a04e0cff7c",
								"name": "Generate",
								"component": "generatePanel",
								"icon": "images/svg/aigenerate.svg"
							}
						]
					},
					{
						"type": "tabset",
						"id": "#e4bb9126-06ed-4670-84b6-6612ac1c3eb9",
						"weight": 36.74418604651163,
						"children": [
							{
								"type": "tab",
								"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfa9",
								"name": "File Browser",
								"component": "fileBrowser",
								"icon": "images/svg/fileBrowser.svg"
							},
							{
								"type": "tab",
								"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfs4",
								"name": "FX Browser",
								"component": "fxBrowser",
								"icon": "images/svg/fileBrowser.svg"
							}
						]
					}
				]
			},
			{
				"type": "row",
				"id": "#a6f8d050-6bc1-438a-b1ff-678f5274c0b3",
				"weight": 122.73528625562348,
				"children": [
					{
						"type": "row",
						"id": "#446661f4-d68e-471d-8c68-bc0c2735df49",
						"weight": 43.14333690299179,
						"children": [
							{
								"type": "tabset",
								"id": "#59de3cfc-57f2-4eef-bb43-1825cbc3fadd",
								"weight": 21.970183886751073,
								"children": [
									{
										"type": "tab",
										"id": "5",
										"name": "Preview Mode",
										"component": "previewMode",
										"icon": "images/svg/tv.svg"
									},
									{
										"type": "tab",
										"id": "#60406091-9502-4038-a41d-fc76021bc78c",
										"name": "Debug",
										"component": "debugStatePanel",
										"icon": "images/svg/debug.svg"
									}
								]
							}
						]
					},
					{
						"type": "tabset",
						"id": "#311d07f3-b813-4dc1-9428-23dcbcde39bc",
						"weight": 4.721303625087945,
						"children": [
							{
								"type": "tab",
								"id": "#117c92f6-52fa-4cf7-ade5-f26e42daaab2",
								"name": "Status Bar",
								"component": "statusBar",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			}
		]
	}
}

export const layoutEditor = {
	"global": {
		"splitterSize": 2,
		"tabSetEnableSingleTabStretch": true
	},
	"name": "Editor",
	"borders": [],
	"layout": {
		"type": "row",
		"id": "1",
		"children": [
			{
				"type": "row",
				"id": "#a6f8d050-6bc1-438a-b1ff-678f5274c0b3",
				"weight": 120.46543463381245,
				"children": [
					{
						"type": "tabset",
						"id": "#de3f6aff-fe48-4d36-8303-eac53a62be27",
						"weight": 1.7312345012983221,
						"children": [
							{
								"type": "tab",
								"id": "#7f432443-9d5c-4ed1-a2f7-77e9badbbefe",
								"name": "FileMenu",
								"component": "fileMenu",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					},
					{
						"type": "row",
						"id": "#446661f4-d68e-471d-8c68-bc0c2735df49",
						"weight": 59.70975602626207,
						"children": [
							{
								"type": "tabset",
								"id": "#7bd0e366-4ee6-4076-8542-5cc55194d3f7",
								"weight": 11.72713126670289,
								"children": [
									{
										"type": "tab",
										"id": "#45f9627e-db56-4c4e-bf96-6b859d70dc18",
										"name": "FXPanel",
										"component": "fxPanel",
										"icon": "images/svg/check-2.svg"
									}
								]
							},
							{
								"type": "row",
								"id": "#db771d40-c5dd-40da-ba18-468edc3606e3",
								"weight": 21.970183886751073,
								"children": [
									{
										"type": "tabset",
										"id": "#59de3cfc-57f2-4eef-bb43-1825cbc3fadd",
										"weight": 89.06976744186046,
										"children": [
											{
												"type": "tab",
												"id": "5",
												"name": "Preview Mode",
												"component": "previewMode",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#3b241454-e215-4d9f-bbb8-ec7198c5a634",
										"weight": 10.930232558139535,
										"children": [
											{
												"type": "tab",
												"id": "#9eb8403b-f140-48b7-97a5-2c9edfdf5e54",
												"name": "Preview Viewer Controls",
												"component": "previewViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "row",
								"id": "#5b61b0f2-47dc-4e06-8635-97aa6bea1a4a",
								"weight": 21.970183886751073,
								"children": [
									{
										"type": "tabset",
										"id": "#566dae4e-fbfa-46af-9fea-03fb34e3f48b",
										"weight": 89.07672301690508,
										"children": [
											{
												"type": "tab",
												"id": "3",
												"name": "Timeline Viewer",
												"component": "timelineViewer"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#e19eeeaa-b286-4271-9b89-afd585a73981",
										"weight": 10.923276983094928,
										"children": [
											{
												"type": "tab",
												"id": "#ba6d82e3-066c-453f-b298-2762420d8920",
												"name": "Timeline Viewer Controls",
												"component": "timelineViewerControls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#52476e71-e82d-4215-92e3-51507577acb4",
								"weight": 15.832794306818991,
								"children": [
									{
										"type": "tab",
										"id": "#7b061871-9954-4b61-82f8-c5a8e33ccfa9",
										"name": "File Browser",
										"component": "fileBrowser",
										"icon": "images/svg/icon-properties-1.svg"
									}
								],
								"active": true
							}
						]
					},
					{
						"type": "row",
						"id": "#9979abf9-455e-42aa-8757-c2a9297df0b7",
						"weight": 22.981639704472204,
						"children": [
							{
								"type": "row",
								"id": "#e899f061-ae8e-4d92-8dc4-1e6fdc855258",
								"weight": 98.89117043121149,
								"children": [
									{
										"type": "tabset",
										"id": "#a4e92f53-c1d6-4bfd-ad11-b2219db7eea6",
										"weight": 16.260162601626018,
										"children": [
											{
												"type": "tab",
												"id": "#76651935-c466-4b4b-8f2a-2ea6c3639280",
												"name": "Timeline Controls",
												"component": "timeline Controls",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									},
									{
										"type": "tabset",
										"id": "#b83d034a-a028-4859-a46b-3841dc7e3324",
										"weight": 83.73983739837398,
										"children": [
											{
												"type": "tab",
												"id": "#2e78c4c9-e7f7-4d5c-8e7a-030d40e4621d",
												"name": "Timeline",
												"component": "timeline",
												"icon": "images/svg/icon-properties-1.svg"
											}
										]
									}
								]
							},
							{
								"type": "tabset",
								"id": "#de8913c6-525e-401a-90e3-23e51bc80be4",
								"weight": 1.108829568788501,
								"children": [
									{
										"type": "tab",
										"id": "#3b4c2c3d-f896-4e23-9ca3-ad81e70d4db8",
										"name": "Volume",
										"component": "volume",
										"icon": "images/svg/icon-properties-1.svg"
									}
								]
							}
						]
					},
					{
						"type": "tabset",
						"id": "#311d07f3-b813-4dc1-9428-23dcbcde39bc",
						"weight": 4.721303625087945,
						"children": [
							{
								"type": "tab",
								"id": "#117c92f6-52fa-4cf7-ade5-f26e42daaab2",
								"name": "Status Bar",
								"component": "statusBar",
								"icon": "images/svg/icon-properties-1.svg"
							}
						]
					}
				]
			}
		]
	}
}



export const buitInLayouts = [defaultLayoutJSON, layoutEditor, layoutGenerate, layoutPremiere, layoutPremiereFocused, layoutDavinci, compactLayoutJSON , layoutFocused];

export const defaultLayout = Model.fromJson(layoutGenerate);