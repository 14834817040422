import {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import { useSignals } from '../../../../api/signalSlots';
import { Modal } from "react-bootstrap";

import NewProjectPanel from '../layouts/NewProjectPanel';
import RecentProjectView from './recentProjectsView';
import EditProjectView from './editProjectView';
import ConfirmModal from './confirmModal';
import ConfirmationModal from './confirmationModal';
import DynamicUIModal from './dynamicUIModal';
import DynamicWindow from '../ui/dynamicWindow';
import { setHideNav} from '../../../../../reducers/cartReducer';
import {useAppManager} from '../../../../api/appManager';
import useOperations from '../../api/useOperations';
import useRuntime from '../../api/useRuntime';
import {getComponentFieldByName } from '../../api/utils';
import { useSignal, useSlot } from 'react-signal-slot';
import {useHotkeys} from 'react-hotkeys-hook';
import {sendToast, loadProject, saveProjectFile, downloadStringFileToCache, deleteProject, getCurrentUAIProjectFileDirectory} from '../../../../api/firebaseHandler';
const ProjectView = ({}) => {
    const dispatch = useDispatch();
    const manager  = useAppManager();
    const addOperations = useOperations((state) => state.addOperations);
    const sequence = useRuntime((state) => state.sequence);
    const sequences = useRuntime((state) => state.sequences);
    const project = useRuntime((state) => state.project);
    const projectPath = useRuntime((state) => state.projectPath);
    const setSequences = useRuntime((state) => state.setSequences);
    const setProjectPath = useRuntime((state) => state.setProjectPath);
    const resetRuntime = useRuntime((state) => state.resetRuntime);
    const addSequences = useRuntime((state) => state.addSequences);
    const setSequence = useRuntime((state) => state.setSequence);
    const setIsDirty = useRuntime(state => state.setIsDirty);
    const [initialized, setInitialized] = useState(false);
    const [show, setShow] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showConfirmQuit, setShowConfirmQuit] = useState(false);
    const [confirmFunction, setConfirmFunction] = useState(()=>{});
    const [confirmTitle, setConfirmTitle] = useState("Delete Project");
    const [confirmBody, setConfirmBody] = useState("Are you sure you want to delete this project?");
    const [renderComponents , setRenderComponents] = useState([]);
    const [currentComponent, setCurrentComponent] = useState("NewProject");
    const signals = useSignals([["project.setSequences", (e) => {
      manager.props.projectData.sequences = e.detail.sequences;
      // setSequences(e.sequences);
      if(e.sequences.length > 0){
      manager.props.projectData.sequence = e.detail.sequences[0];
      }
    }]]);
    const signal = useSignal();

  
    useSlot("test",(e) => {
      console.log(e);
  });

    useSlot("panel.project.showNewProjectPanel",(e) => {
      console.log("panel.project.showNewProjectPanel");
      showPanel("NewProject");
  });

  useSlot("panel.project.showRecentProjectsPanel",(e) => {
    console.log("show recent projects");
    showPanel("RecentProjects");
    e.onFinished("Boomerang");
});


  useSlot("operation.openWindow", async (e) => {
         
    const id_ = e.data.metadata.name;
    console.log(id_);

    if (e.data.metadata.window=="Modal" ) {
      setRenderComponents([id_]);
      setShow(true);
      // setShow(e.state);
  }if (id_=="uai.builtin.headless.project.save" ) {
    await saveProject({...e, newVersion: false});
    // setShow(e.state);
}if (id_=="uai.builtin.headless.project.saveversionup" ) {
    await saveProject({...e, newVersion: true});
    // setShow(e.state);
}if (id_=="uai.builtin.headless.project.delete" ) {

    setShowConfirmDelete(true);
}if (id_=="uai.builtin.headless.app.quit" ) {

  setShowConfirmQuit(true);

  dispatch(setHideNav(false));
  localStorage.removeItem('hideNav');
  window.location.href= '/dashboard';
  }
}
  );


  
  useSlot("panel.project.showRecentProjectsPanel",(e) => {
    console.log("show recent projects");
    showPanel("RecentProjects");
    e.onFinished("Boomerang");
});





useSlot("panel.project.setComponent", (e) => {
  setCurrentComponent(e.component);
  setShow(e.state);
});


useSlot("panel.project.setShow", (e) => {
  setShow(e.state);
});



    const showPanel = (component) => {
        setCurrentComponent(component);
        setShow(true);
    }
   

    manager.props.projectView = this;
    const deleteProject_ = async () =>{
      console.log("DeleteProject 1",project);
      
      await DeleteProject(project, manager);
    }
    const loadProject_ = async () => {
      const content_ = await loadProject();
    }

    const saveProject = async (props) => {
     
      const user = JSON.parse(localStorage.getItem('user')).id;
      const appName = localStorage.getItem('appName');
      // const projectSequences = sequences;
      // const projectSequences = manager.projectData.sequences;

      // if(projectSequences.length == 0){
      // sendToast("Please create a sequence first.");
      // return;
      // }
      let outDict = {
    };
      const project_path = projectPath;
      if(project_path != null && project_path.endsWith(".uaipro")){
      const projectFileData = await downloadStringFileToCache(project_path);
      console.log(projectFileData);
      if(projectFileData != null){
          outDict = projectFileData;
      }
      }
      // const project__ = project;
      // const project__ = JSON.parse(localStorage.getItem('project'));
      let version = 0;
      if(project.version != undefined){
          version = project.version;
      }else{
        if(
          outDict.version != undefined){
          version = outDict.version;
      }
    }
      const newSequences = [...sequences];
      //TODO: Save Project
      for (let i = 0; i < newSequences.length; i++) {
          const seq = newSequences[i];
          if(sequence != null ){
          if (seq.id == sequence.id) {
              newSequences[i] = sequence;
          }
          }
      }
      const zeroPad = (num, places) => String(num).padStart(places, '0')

      outDict = {
          ...outDict,
          ...project,
          sequences: newSequences,
          version: version + (props.newVersion ? 1 : 0)
      };
      localStorage.setItem('project', JSON.stringify(outDict));
      const newPath_ = `${appName}/${user.id}/${project.id}/${project.name.replace(" ", "_")}_${version}.uaipro`;
      const newPath = await saveProjectFile(user,
        project.id,
        appName,
        project.name,
        zeroPad(outDict.version, 4),
        outDict,
      )
      console.log("Project Saved: " + newPath);
      // localStorage.setItem('projectPath', newPath);
      setProjectPath(newPath_);
      sendToast("Project Saved: " + `${project.name}_${zeroPad(outDict.version, 4)}.uaipro`);
      setIsDirty(false);
      console.log(outDict);


  };
const template = 
    {
        "uuid": "de7984bd-c",
        "name": "uai.builtin.ui.sequence.create",
        "subwindows": [
          {
            "uuid": "1331d03d-2",
            "name": "settings",
            "fields": [
              {
                "uuid": "67e5a69e-c",
                "name": "name",
                "value": "GGGG"
              },
              {
                "uuid": "9b513f2c-d",
                "name": "description",
                "value": ""
              },
              {
                "uuid": "4a2f9d3d-3",
                "name": "comments",
                "value": ""
              }
            ]
          },
          {
            "uuid": "d0386991-b",
            "name": "render",
            "fields": [
              {
                "uuid": "f4c44444-0",
                "name": "resolution",
                "value": [
                  1920,
                  1080
                ]
              },
              {
                "uuid": "7a4bd2ca-d",
                "name": "fps",
                "value": 23.976
              },
              {
                "uuid": "ba896622-6",
                "name": "audiobitrate",
                "value": "256k"
              },
              {
                "uuid": "e3132b00-1",
                "name": "stereo",
                "value": true
              }
            ]
          }
        ]
      }

    const CreateSequence = async ({operation, value, dictionary}) =>{
        const keys = Object.keys(dictionary);
        
        // const newSeq = sequence();
        dictionary.uuid = crypto.randomUUID();
        dictionary.timeline = [];
        dictionary.tracks = [];
        dictionary.modules = [];
        dictionary.metadata = dictionary[keys[0]];
        dictionary.type = "sequence";
        dictionary[keys[0]] = null;
    let name_ = getComponentFieldByName("name", value[0].values);
        dictionary.name = name_.value;
        manager.props.projectData.sequences.push(dictionary);
        manager.props.projectData.sequence=({...manager.props.projectData.sequence, ...dictionary});
        addSequences([dictionary]);
        setSequence(dictionary);
        
        manager.props.uaiProject.sendToast("Sequence Created: " + name_.value);
        setShow(false);
        setIsDirty(true);
        return manager.props.projectData;
    
    }
    
    
    const CancelProjectView = async (props) =>{
        
        setShow(false);
    }
    
    
    
    const DeleteProject = async (project, manager) =>{
        console.log("DeleteProject",project);
        localStorage.removeItem('project');
        localStorage.removeItem('timeline');


      await deleteProject(project, ()=>{
        setRenderComponents(["uai.builtin.ui.project.open"]);
        setShow(true);
        resetRuntime();

      });

    }
    
    
    
    
    const defaultOperations = () =>{
        return( 
    [
        
        {
            "id": "internal.ui.projectView.cancel",
            "function": CancelProjectView,
        },
        {
            "id": "internal.ui.sequence.createSequence",
            "function": CreateSequence,
        },
        {
            "id": "internal.headless.app.quit",
            "function": DeleteProject,
        },
        {
            "id": "internal.headless.project.deleteProjectHeadless",
            "function": DeleteProject,
        }
    ]
    )
    }

    useEffect(() => {
        if(initialized == false){
        addOperations(defaultOperations());
        setInitialized(true);
        }
    }
    ,[]);
    

    useEffect(() => {
      
    }
    ,[manager.props.projectData]);
    

    useEffect(() => {
      console.log("SEQUENCES2", sequences);
    }
    ,[sequences]);
    useEffect(() => {
      console.log("project2", project);
    }
    ,[project]);
    
    useEffect(() => {
    }
    ,[manager.props.uaiProject]);
    
    const overrideComponents = (component) =>{
      switch(component){
        case "uai.builtin.ui.project.create": 
        return <EditProjectView show={show} setShow={setShow} Title="Create Project"  manager={manager}/>;
        
        case "uai.builtin.ui.project.edit":
        return <EditProjectView show={show} setShow={setShow} Title="Edit Project"  manager={manager}/>;

        case "uai.builtin.ui.project.open":
        return <RecentProjectView show={show} setShow={setShow} manager={manager}/>;

        case "uai.builtin.ui.project.recent":
        return <RecentProjectView show={show} setShow={setShow} manager={manager}/>;

        default:
        return <DynamicWindow setShow={setShow} ComponentIDs={renderComponents} Title="Create Project" onChange={(e)=>{}} />
    }
    }


    return (
        <>

      <DynamicUIModal show={show && renderComponents.length > 0} setShow={setShow} renderComponents={renderComponents} componentsFactory={overrideComponents} />
        

    <ConfirmationModal show={showConfirmDelete} setShow={setShowConfirmDelete} onAccept={()=>{
      setShowConfirmDelete(false);
      deleteProject_();
      }} title={confirmTitle} body={confirmBody} />

    <ConfirmationModal show={showConfirmQuit} setShow={setShowConfirmQuit} onAccept={()=>{
      dispatch(setHideNav(false));
      localStorage.removeItem('hideNav');
      window.location.href= '/dashboard';
      
      }} title={confirmTitle} body={confirmBody} />
        </>
    );


}

export default ProjectView;