import React from 'react';
import { useEffect, useState } from 'react';
import dataBanner from '../../../../../assets/fake-data/data-banner';
import dataCategory from '../../../../../assets/fake-data/data-category';
import dataCollection from '../../../../../assets/fake-data/data-collection';
import dataCreate from '../../../../../assets/fake-data/data-create';
import dataExplore from '../../../../../assets/fake-data/data-explore';
import dataHotpick from '../../../../../assets/fake-data/data-hotpick';
import dataLiveaution from '../../../../../assets/fake-data/data-liveaution';
import dataSeller from '../../../../../assets/fake-data/data-topseller';
import Banner01UAI from '../../../../../components/banner/Banner01UAI';
import Category from '../../../../../components/category/Category';
import Collection from '../../../../../components/collection/Collection';
import Create from '../../../../../components/create/Create';
import Explore from '../../../../../components/explore/Explore';
import CommunitySpotlight from '../../../../../components/communitySpotlight/CommunitySpotlight';
import LiveAutions from '../../../../../components/liveautions/LiveAuctions';
import {DivVideo} from '../../../../../components/divs/DivVideo';
import {HeaderWithVideo} from '../../../../../components/header/HeaderWithVideo';
import { useAPIKey } from "../../../../api/useAPIKey";
import {  useAuth } from '../../../../api/useAuth';
import TopSeller from '../../../../../components/topseller/TopSeller';
import  LoadingSpinner  from '../../../../../pages/LoadingSpinner';
import Banner05 from '../../../../../components/banner/Banner05';
import TestPanelImageGeneration from '../../../../../components/banner/TestPanelImageGeneration';
import PluginsHomePreviewPanel from '../../../../../components/banner/PluginsHomePreviewPanel';
import WebAPIHomePreviewPanel from '../../../../../components/banner/WebAPIHomePreviewPanel';
import UAIAppBase from '../../base';
import APIKeyMessageModal    from '../layouts/APIKeyMessageModal';
import {useAppManager} from '../../../../api/appManager';

import useStableVOperations from '../../modules/apps/stableVOperations';

function StableV(props) {
    const { pending:isLoading, isSignedIn:isAuthenticated,user:user, auth:auth_ } = useAuth()
    const {apiKey , verified, isLoading: apiKeyIsVerifying}  =  useAPIKey();
    const [showAPIKeyModal, setShowAPIKeyModal] = useState(false);
    const [apiKeyModalTitle, setAPIKeyModalTitle] = useState('Missing API Key');
    const [apiKeyModalBody, setAPIKeyModalBody] = useState('An active API Key is required to utilize this application. Please select an active API Key on your Account Page.');
    const stableVOperations = useStableVOperations();
    useEffect(() => {
        
        setShowAPIKeyModal(!verified);

    }, [apiKey , verified, apiKeyIsVerifying]);



    return (
        <>
        <div className="home-1 h-[100vh]">
            {apiKeyIsVerifying ?             <LoadingSpinner  Title="Loading" Message="Please wait"/> : <></>


            
}
            <div id='page' className={apiKeyIsVerifying ? 'hidden' : '' + "  h-full"}>

            {/* <HeaderWithVideo headerHeight="100%" translateX="0px" translateY="0px" /> */}

{/* <Banner01UAI data={dataBanner} /> */}

                

                <UAIAppBase appName={"stableV"} />

                {/* <LiveAutions data={dataLiveaution} /> */}

                {/* <Explore data={dataExplore} /> */}

                {/* <CommunitySpotlight data={dataHotpick} /> */}

                {/* <Collection data={dataCollection} /> */}

                {/* <TopSeller data={dataSeller} /> */}

            </div>
        </div>

        <APIKeyMessageModal />
        </>
    );
}

export default StableV;