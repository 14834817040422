import React from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {useEffect, useState} from 'react';


const MarkDownLoader = ({markDownFile = null, classOverride=""}) =>{

    const [data, setData] = useState("");
    useEffect(() => {

        fetch(markDownFile)
        .then((response) => response.text())
        .then((text) => setData(text));
    }
    ,[]);

    return(
        <Markdown remarkPlugins={[remarkGfm]} className={classOverride}>{data}</Markdown>
    )

}

export default MarkDownLoader;