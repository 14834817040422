import TextToImageGeneration from "../../../../components/banner/TextToImageGeneration";


const TextToImage = ({ onRun = () => {} }) => {


    return (

        <div className="w-full h-full">
<TextToImageGeneration maxImagesGenerated={-1} />
{/* <iframe className="gradio-instance  h-full" src="https://webapps-bcmw3.ondigitalocean.app/texttoimage_lightning" title="TextToImage"></iframe> */}

</div>

    )


}

export default TextToImage;