/* eslint-disable react/forbid-prop-types */
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';


import { getuaiInstallerByID } from '../../../uai/api/downloads';


const header = [
  { id: 1, title: '#' },
  { id: 2, title: 'Name' },
  { id: 3, title: 'Version' },
  { id: 4, title: 'Build Type' },
  { id: 5, title: 'OS' },
  { id: 6, title: 'Link' },
  { id: 7, title: 'Uploaded' },
  { id: 7, title: 'SHA256' },
];

function toTitleCase(str) {
  const newStr = str.split(' ')
  .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
  .join(' ');
  return (newStr);
}

const defaultComponent = (title, downloads, downloadClicked) => {
  // console.log('downloads', downloads);
  const v = 0;
  // Create our number formatter.
  return (
    <div lg={12} title={title}>
      <table responsive className="responsive">
        <thead>
          <tr>
            {header.map(item => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {downloads.map((item) => {
const on = 9;
const updatedat = new Date(item.updated_at).toLocaleString('en-US');
  // console.log('updated_at', updatedat);

              return (
                <tr key={item.id}>
                  <td className="downloadItem-textt">{item.id}</td>
                  <td className="downloadItem-textt">{item.name}</td>
                  <td className="downloadItem-textt">{item.version.replaceAll('_', '.')}</td>
                  <td className="downloadItem-textt">{item.buildtype}</td>
                  <td className="downloadItem-textt">{item.os}</td>
                  <td>
                    <button
                      id="upgradelicense"
                      className='button-smaller'
                      outline
                      onClick={() => {
                            downloadClicked(item.id);
                          }}
                    ><span className="lnr lnr-download" /> Download
                    </button>


                    
                  </td>
                  <td className="downloadItem-textt">{updatedat}</td>
                  <td className="downloadItem-text">{item.sha256}</td>
                </tr>
            );
        })}

        </tbody>
      </table>
    </div>
    );
};

const DownloadsTable = ({ downloads, title }) => {
  const [renderedComponent, setrenderedComponent] = useState(<p>Loading</p>);


  const editclicked = async (e) => {
    // console.log('editclicked', e);
    const appdata = await getuaiInstallerByID(process.env.REACT_APP_API, e);
    // console.log('appdata', appdata.data.url);
    // open in a new tab and download the file at appdata.data.url
    window.open(appdata.data.url, '_blank');
    // setrenderedComponent(<Redirect to="/account/update/license" />);
  };

  
  return (defaultComponent(title, downloads, editclicked));
};

DownloadsTable.propTypes = {
  downloads: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,

};


export default DownloadsTable;
