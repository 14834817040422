
    import useAppManager from "../../api/appManager";
    import { uaimenu,uaiComponents } from "./uaiModuleConstants";
    import { useEffect} from "react";

    const UAIModules =({ })=> {

const manager = useAppManager();



const getUAIComponent = (_name) => {
    let match = {};
    for(var i = 0; i < uaiComponents.components.length; i++){
        // console.log("uaiComponents.components " , uaiComponents.components[i])
    }
const filtered = uaiComponents.components.filter(component => component.name == _name);
try{
    return filtered[0];

}
catch{
    return null;
}
}


const getDefaultUAIComponents = (type="image") => {

    const filtered = uaiComponents['components'].filter(component => component.automatic.includes(type));
    try{
    
    return filtered;
    }
    
    catch{
        return null;
   }
    }



    manager.props.uaiMenu = uaimenu;
    manager.props.uaiComponents = uaiComponents;
    manager.props.getUAIComponent = getUAIComponent;
    manager.props.getDefaultUAIComponents = getDefaultUAIComponents;
    

return {uaimenu, uaiComponents, getUAIComponent, getDefaultUAIComponents};

}

export default UAIModules;
export {UAIModules};



    