import { useHotkeys } from 'react-hotkeys-hook';
import { useSignal } from 'react-signal-slot';

const AppHotkeys = () => {

    const openWindow = (name, windowType="") => {
        let metaData = {name: name};
        if(windowType){
            metaData.window = windowType;
        }
        signal('operation.openWindow', {data:{metadata:metaData}});

    }

const signal = useSignal();


    useHotkeys('ctrl+s', () => {
        openWindow('uai.builtin.headless.project.save');
    }, { preventDefault: true })


    useHotkeys('ctrl+alt+shift+s', () => {
        openWindow('uai.builtin.headless.project.saveversionup');

    }, { preventDefault: true })



    useHotkeys('alt+n', () => {
        openWindow('uai.builtin.ui.project.create', "Modal");
    }

    , { preventDefault: true })



    useHotkeys('alt+shift+n', () => {
        openWindow('uai.builtin.ui.sequence.create');
    }

    , { preventDefault: true })



    useHotkeys('ctrl+o', () => {
        openWindow('uai.builtin.ui.project.open', "Modal");
    }, { preventDefault: true }
)



    useHotkeys('ctrl+q', () => {
        openWindow('uai.builtin.headless.app.quit');
    }, { preventDefault: true }
)





    return( <></>);

}

export default AppHotkeys;