import React , {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';

import data from '../assets/fake-data/data-hotpick'
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import icon1 from '../assets/images/icon/rain1.svg'
import icon2 from '../assets/images/icon/rain2.svg'
import icon3 from '../assets/images/icon/ethe.svg'
import img from '../assets/images/svg/range-price-dark.svg'
import baseEmptyImage from '../images/BaseEmptyBG.png';
import CardModal from '../components/layouts/CardModal';

import { refreshProducts } from '../uai/api/dlc';
import LoadingSpinner from './LoadingSpinner';
import { setIsProcessing, setProducts } from '../reducers/cartReducer';

const checkAllFilters = (filters, search, product) => {

    let result = false;

    if (search !== "") {
        if (product.name.toLowerCase().includes(search.toLowerCase())) {
        return true && product.showInShop == true;
        }else{
            return false;
        
        }
    }
    if (filters.length == 0) {
        return product.showInShop == true;
    }

    for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        if (filter === "Plugins" && product.productType === "Plugins") {
            result = true;
        } else if (filter === "Apps" && product.productType === "App") {
            result = true;
        } 
        
    }
  
    
    return result && product.showInShop == true;


}

const Products = ({props}) => {
    const state = useSelector(state => state);
    const productsState = useSelector(state_ => state_.appState.products);
    const productItems = useSelector(state_ => state_.appState.products.products);
    const [filters, setFilters] = useState([]);
    const [search, setSearch] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [productItemsStore, setProductItemsStore] = useState([]);

    // const productItemsStore = useSelector(state_ => state_.appState.products ? state_.appState.products.products ? state_.appState.products.products.filter(x => x != null? checkAllFilters(filters , search,x): false) : [] : []);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded , setIsLoaded] = useState(false);

    const openUAIProduct = () => {
        openProduct(productsState.uaiLicenseProduct);
    }

    const openProduct = (newProduct) => {
        let newProducts = productsState;
        newProducts.currentProduct = newProduct;
        dispatch(setProducts(newProducts));
    window.location = "/productDetails" ;
    }
    const syncProducts = () => {
        const filtered = state.appState.products.products.filter(x => x != null? checkAllFilters(filters , search,x): false);
        setProductItemsStore(filtered);
    }
    useEffect(() => {
        const _refreshProducts = async () => {
            setIsLoading(true);
            dispatch(setIsProcessing)
         const resp = await refreshProducts(state, dispatch);
         syncProducts();
         setIsLoading(false);
         setIsLoaded(true);
         console.log(productsState);
         console.log(productItemsStore);
        }
        _refreshProducts();


    }, []);

    const runSearch = () => {
        console.log(search);

        syncProducts();
    }

    useEffect(() => {
        console.log(productItemsStore);
        console.log(filters);

        syncProducts();
    }, [filters]);

    const [modalShow, setModalShow] = useState(false);
    return (
        <div>
            <PageTitle sub='Products' title='Products' />

            <section className="tf-explore-sidebar">
                <div className="tf-container">
                    <div className="row ">
                    {state.appState.loading.show == true  ? <LoadingSpinner Title={state.appState.loading.title} Message={state.appState.loading.message}/> :


<>
                        <div className="col-md-3">
                            <div className="sidebar sidebar-explore">
                                <div className="widget widget-search">
                                    <h6 className="widget-title">Search</h6>
                                    <form action="#">
                                        <input type="text" placeholder="Search" required="" onChange={(e) => {
        setSearch(e.target.value);

                                        }} />
                                        <Link to='' onClick={(e)=>{
                                            e.preventDefault();
                                            runSearch();
                                        }} className="btn-search"><i className="icon-fl-search-filled"></i></Link>
                                    </form>
                                </div>
                              
                                <div className="widget widget-category widget-accordion">
                                    <h6 className="widget-title">Categories</h6>
                                    <div className="widget-content">
                                        <form action="#" className="form-checkbox">
                                            <label className="checkbox-item">
                                                <span className="custom-checkbox">
                                                    <input type="checkbox" onChange={(e)=>{
                                                        if(e.target.checked){
                                                            setFilters([...filters, "Plugins"]);
                                                        }else{
                                                            setFilters(filters.filter(x => x != "Plugins"));
                                                        }
                                                    }} />
                                                    <span className="btn-checkbox"></span>
                                                </span>
                                                <span>Plugins</span>
                                            </label>
                                            <label className="checkbox-item">
                                                <span className="custom-checkbox">
                                                    <input type="checkbox"  onChange={(e)=>{
                                                        if(e.target.checked){
                                                            setFilters([...filters, "Apps"]);
                                                        }else{
                                                            setFilters(filters.filter(x => x != "Apps"));
                                                        }
                                                    }}/>
                                                    <span className="btn-checkbox"></span>
                                                </span>
                                                <span>Apps</span>
                                            </label>
                                        </form>
                                    </div>
                                </div>
                                {/* <div className="widget widget-price">
                                    <h6 className="widget-title">Price</h6>
                                    <img src={img} alt="Binasea" id="img-range-price" />
                                    <div id="slider-range"></div>
                                    <div className="slider-labels"> 
                                        <span id="slider-range-value1"></span>
                                        <span id="slider-range-value2"></span>        
                                    </div>
                                </div> */}

                            </div>
                        </div>
                        <div className="col-md-9 ">
                            <div className="top-option">
                                <div className="left-option">{"Showing "+ "1" +"–"+ productItemsStore.length.toString() +" of " + productItemsStore.length.toString() + " results"}</div>
                                {/* <div className="right-option">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className=''>
                                            Single Items  
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                        <Dropdown.Item href="#">
                                            <li><span>All Items</span></li>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#">
                                            <li className="active"><span>Bundles</span></li>
                                        </Dropdown.Item>
                                        
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic" className=''>
                                       Recently Create
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                    <Dropdown.Item href="#">
                                        <li><span>Recently Listed</span></li>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#">
                                        <li className="active"><span>Recently Created</span></li>
                                    </Dropdown.Item>
                                    <Dropdown.Item href="#">
                                        <li><span>Recently Sold</span></li>
                                    </Dropdown.Item>
                                    
                                    </Dropdown.Menu>
                                </Dropdown>
    
                                    <ul className="option-view">
                                        <li><Link to="#" className="btn-grid active"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.59811 0H2.40189C1.07801 0 0 1.07855 0 2.40188V5.59906C0 6.93083 1.07801 8 2.40189 8H5.59811C6.93144 8 8 6.93083 8 5.59906V2.40188C8 1.07855 6.93144 0 5.59811 0Z" fill="white"/>
                                            <path d="M5.59811 10H2.40189C1.07801 10 0 11.0785 0 12.4019V15.5991C0 16.9308 1.07801 18 2.40189 18H5.59811C6.93144 18 8 16.9308 8 15.5991V12.4019C8 11.0785 6.93144 10 5.59811 10Z" fill="white"/>
                                            <path d="M15.5981 0H12.4019C11.0686 0 10 1.07855 10 2.40188V5.59906C10 6.93083 11.0686 8 12.4019 8H15.5981C16.922 8 18 6.93083 18 5.59906V2.40188C18 1.07855 16.922 0 15.5981 0Z" fill="white"/>
                                            <path d="M15.5981 10H12.4019C11.0686 10 10 11.0701 10 12.4019V15.5991C10 16.9215 11.0686 18 12.4019 18H15.5981C16.922 18 18 16.9215 18 15.5991V12.4019C18 11.0701 16.922 10 15.5981 10Z" fill="white"/>
                                            </svg>
                                            </Link></li>
                                        <li><Link to="#" className="btn-list"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.49882 0H1.50118C0.673759 0 0 0.674092 0 1.50117V3.49941C0 4.33177 0.673759 5 1.50118 5H3.49882C4.33215 5 5 4.33177 5 3.49941V1.50117C5 0.674092 4.33215 0 3.49882 0Z" fill="white"/>
                                            <path d="M3.49882 6.5H1.50118C0.673759 6.5 0 7.17409 0 8.00117V9.99941C0 10.8318 0.673759 11.5 1.50118 11.5H3.49882C4.33215 11.5 5 10.8318 5 9.99941V8.00117C5 7.17409 4.33215 6.5 3.49882 6.5Z" fill="white"/>
                                            <path d="M3.49882 13H1.50118C0.673759 13 0 13.6741 0 14.5012V16.4994C0 17.3318 0.673759 18 1.50118 18H3.49882C4.33215 18 5 17.3318 5 16.4994V14.5012C5 13.6741 4.33215 13 3.49882 13Z" fill="white"/>
                                            <path d="M9.99979 0H8.00216C7.16883 0 6.50098 0.674091 6.50098 1.50117V3.49941C6.50098 4.33177 7.16883 5 8.00216 5H9.99979C10.8272 5 11.501 4.33177 11.501 3.49941V1.50117C11.501 0.674091 10.8272 0 9.99979 0Z" fill="white"/>
                                            <path d="M9.99979 6.49805H8.00216C7.16883 6.49805 6.50098 7.17214 6.50098 7.99922V9.99746C6.50098 10.8298 7.16883 11.498 8.00216 11.498H9.99979C10.8272 11.498 11.501 10.8298 11.501 9.99746V7.99922C11.501 7.17214 10.8272 6.49805 9.99979 6.49805Z" fill="white"/>
                                            <path d="M9.99979 13H8.00216C7.16883 13 6.50098 13.6741 6.50098 14.5012V16.4994C6.50098 17.3318 7.16883 18 8.00216 18H9.99979C10.8272 18 11.501 17.3318 11.501 16.4994V14.5012C11.501 13.6741 10.8272 13 9.99979 13Z" fill="white"/>
                                            <path d="M16.4988 0H14.5012C13.6678 0 13 0.674091 13 1.50117V3.49941C13 4.33177 13.6678 5 14.5012 5H16.4988C17.3262 5 18 4.33177 18 3.49941V1.50117C18 0.674091 17.3262 0 16.4988 0Z" fill="white"/>
                                            <path d="M16.4988 6.5H14.5012C13.6678 6.5 13 7.17409 13 8.00117V9.99941C13 10.8318 13.6678 11.5 14.5012 11.5H16.4988C17.3262 11.5 18 10.8318 18 9.99941V8.00117C18 7.17409 17.3262 6.5 16.4988 6.5Z" fill="white"/>
                                            <path d="M16.4988 13H14.5012C13.6678 13 13 13.6741 13 14.5012V16.4994C13 17.3318 13.6678 18 14.5012 18H16.4988C17.3262 18 18 17.3318 18 16.4994V14.5012C18 13.6741 17.3262 13 16.4988 13Z" fill="white"/>
                                            </svg>
                                            </Link></li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="tf-explore-sidebar-wrap">
                                {
productItemsStore.slice(0,6).map(idx =>{
                                        console.log(idx);
                                        return (
                                        <div key={idx.id} className="sc-product style2">
                                            <div className="top">
                                                <Link to="/item-details-v1"  onClick={(e) => 
                                                    {
                                                        e.preventDefault();
                                                     
                                                            if( idx.name.includes("UAI License")){

                                                               openUAIProduct();
                                                            }else{
                                                                if(idx.pricecurrent != 0){
                                                            openProduct( idx);
                                                                }else{
                                                            openProduct( idx);
                                                                    
                                                                    // openUAIProduct();
                                                                }

                                                        
                                                        }
                                                    
                                                    }
                                                    
                                                    
                                                    }  className="tag">{idx.name}</Link>
                                                <div className="wish-list">
                                                    <Link to="#" className="heart-icon"></Link>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="details-product">
                                                    <div className="author">
                                                        <div className="avatar">
                                                            <img src={idx.Images.length != 0 ?  idx.Images.length >= 1 ?  idx.Images[1].url : idx.Images[0].url : baseEmptyImage} alt="images" />
                                                        </div>
                                                        <div className="content">
                                                            <div className="position">Plan Type</div>
                                                            <div className="name"> <Link to="#">{idx.PlanType}</Link></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="features">
                                                <div className="product-media">
                                                    <img src={idx.card ? idx.card.AppImage ?  idx.card.AppImage : idx.Images.length != 0 ? idx.Images[0].url : baseEmptyImage : baseEmptyImage} alt="images" />
                                                </div>
                                                <div className="rain-drop1"><img src={icon1} alt="images" /></div>
                                                <div className="rain-drop2"><img src={icon2} alt="images" /></div>
                                            </div>
                                            <div className="bottom-style2">
                                                <div className="price">
                                                    {/* <div className="icon"><img src={icon3} alt="images" /></div> */}
                                                    <div className="content">
                                                        <div className="name">{idx.pricecurrent != 0 ? 'Starting At' : 'Included With' }</div>
                                                        <div className="cash">{idx.pricecurrent != 0 ? idx.pricecurrent : <span onClick={openUAIProduct}> UAI License</span> }</div>
                                                    </div>
                                                </div>
                                                <div className="product-button">
                                                    <Link to="#" onClick={(e) => 
                                                    {
                                                        e.preventDefault();
                                                     
                                                            if( idx.name.includes("UAI License")){

                                                               openUAIProduct();
                                                            }else{
                                                                if(idx.pricecurrent != 0){
                                                            openProduct( idx);
                                                                }else{
                                                            openProduct( idx);
                                                                    
                                                                    // openUAIProduct();
                                                                }

                                                        
                                                        }
                                                    
                                                    }
                                                    
                                                    
                                                    } data-toggle="modal" data-target="#popup_bid" className="tf-button"> {idx.pricecurrent == 0 ? "View" : "Learn More"} </Link>
                                                </div>
                                            </div>
            
                                        </div>
                                    )})
                                }
                            </div>

{/*                             
                            <div className="btn-loadmore ">
                                <Link to="#" className="tf-button loadmore">Load More</Link>
                            </div>
                             */}
                        </div>
                        </>}

                    </div>
                    
                </div>

                <CardModal 
                show={modalShow}
                onHide={() => setModalShow(false)} 
            />
            </section>
            
        </div>
    );
}
Products.propTypes = {
    
};

export default Products;