/* eslint-disable prefer-destructuring */
import {getCollection } from './firebaseHandler';
import axios from 'axios';

export async function getuaiInstallers(uaiHost) {
    const endpoint = `${uaiHost}/downloads/uai-installers/datesorted`;
    // console.log('endpoint', endpoint);
    const result = await axios
        .get(endpoint).catch(err => (err));
    try {
        return await result.data;
    } catch {
        return false;
    }
}

export async function getuaiInstallerByID(uaiHost, apID) {
    const endpoint = `${uaiHost}/downloads/uai-installers/find`;
    // console.log('endpoint', endpoint);
    const result = await axios
        .post(endpoint, { app: { id: apID } }).catch(err => (err));
    try {
        const endd = await result;
    // console.log('endd', endd);

        return await endd;
    } catch {
        return false;
    }
}


export async function raiseUAIDownload(uaiHost, apID) {
    const endpoint = `${uaiHost}/downloads/uai-apps/id/download`;
    // console.log('endpoint', endpoint);
    const result = await axios
        .post(endpoint, {  id: apID  }).catch(err => (err));
    try {
        const endd = await result.data;
    // console.log('endd', endd);

        return await endd;
    } catch {
        return false;
    }
}

export async function getuaiAppsAll(uaiHost) {
    const endpoint = `${uaiHost}/downloads/downloads/uai-apps/all`;
    // console.log('endpoint', endpoint);
    const result = await axios
        .get(endpoint).catch(err => (err));
    try {
        const endd = await result;
    // console.log('endd', endd);

        return await endd.data;
    } catch {
        return false;
    }
}

export async function getuaiAppsVisible(uaiHost) {
    const apps = await getCollection("uai-apps");
    const apps_ = apps.filter((item) => {
        return (item.apptype != "dependency" && item.app.showInShop == true)
    });
    
    const appnames = apps_.map((item) => {
        return item.name;
        
    });
    return appnames;
}

export async function getuaiAppByName(uaiHost, appname) {

    
    const apps = await getCollection("uai-apps");
    const apps_ = apps.filter((item) => {
        return (item.name == appname)
    });

    return apps_;

    
}

export default getuaiInstallers;
