

let imageMap = {

};
let canvas_ = null;

const processImageTrack = (action, engine, isPlaying, time,  image) => {
    console.log("image2", image);

    if (canvas_ == null) {
        canvas_ = document.getElementById("renderCanvas_timeline");
    }
    console.log("canvas_", canvas_);

    const ctx = canvas_.getContext("2d");

    console.log("processImageTrack", action);
    ctx.drawImage(image, 0, 0);

}

export const imageTrackEffect: Record<string, TimelineEffect> = {
    image: {
        id: "image",
        name: "image",
        source: {
            
            start: ({ action, engine }) => {
                console.log("start", action);
 
                // const src = action.data.src;
                // audioControl.stop({ id: src, engine });
            },
            update: ({ action, engine, isPlaying, time }) => {
              
                const image = document.getElementById(`imageTrack_${action.data.id}`);
                if(image != null)
{                
                processImageTrack(action, engine, isPlaying, time, image);
}
                // if (isPlaying) {
                //     const src = action.data.src;
                //     audioControl.start({
                //         id: src,
                //         src,
                //         startTime: action.start,
                //         engine,
                //         time,
                //     });
                // }
            },
            
            leave: ({ action, engine }) => {
                console.log("leave", action);
                imageMap[action.data.id] = null;

                // const src = action.data.src;
                // audioControl.stop({ id: src, engine });
            },
            stop: ({ action, engine }) => {
                console.log("stop", action);

                // const src = action.data.src;
                // audioControl.stop({ id: src, engine });
            },
        },
    },
};
