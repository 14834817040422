import audioControl from "../../../../utils/audioController";

export const audioTrackEffect: Record<string, TimelineEffect> = {
    audio: {
        id: "audio",
        name: "audio",
        source: {
            start: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                    const src = action.data.src;
                    audioControl.start({
                        id: src,
                        src,
                        startTime: action.start,
                        engine,
                        time,
                    });
                }
            },
            enter: ({ action, engine, isPlaying, time }) => {
                if (isPlaying) {
                    const src = action.data.src;
                    audioControl.start({
                        id: src,
                        src,
                        startTime: action.start,
                        engine,
                        time,
                    });
                }
            },
            leave: ({ action, engine }) => {
                const src = action.data.src;
                audioControl.stop({ id: src, engine });
            },
            stop: ({ action, engine }) => {
                const src = action.data.src;
                audioControl.stop({ id: src, engine });
            },
        },
    },
};
