import React from 'react';
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';
import { useDispatch , useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCartShopping, faPhotoFilm, faComments } from '@fortawesome/free-solid-svg-icons';
import img1 from '../assets/images/collection/banner-collection.jpg'
import img2 from '../assets/images/collection/img-banner-collection.png'
import { Link } from 'react-router-dom';
import MediaContentPick4 from '../components/mediaContent/MediaContentPick4';

const UserMediaCollection = ({props}) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const {isAuthenticated, user, isLoading} = useAuth0();

    React.useEffect(() => {
        console.log(state.appState.currentMediaCollection);
        const params = new URLSearchParams(window.location.search);
        const collectionId = params.get('id');
        console.log(collectionId);
        if(collectionId != -1){
            const match = state.appState.usercollections.filter(idx => idx.id == collectionId);
        }

    }, []);

    return (
        <div className='page-collection'>
            <PageTitle sub='Explore' title='Collection' />

            <section className="tf-banner-collection">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-collection-inner">
                                <img src={img1} alt="Binasea" className="img-bg" />
                                <img src={img2} alt="Binasea" className="img-banner" />
                                <div className="button-top">
                                    <Link to="#" className="btn-wishlish"><i className="fas fa-heart"></i> Add to Wishlist</Link>
                                    <Link to="#" className="btn-collect"><i className="fas fa-long-arrow-right"></i></Link>
                                    <div className="btn-option"><i className="fas fa-ellipsis-h"></i>
                                        <div className="option_popup">
                                            <Link to="#">Delete</Link>
                                            <Link to="#">Edit</Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div className="tf-heading style-5">
                                <h2 className="heading">{state.appState ? state.appState.currentMediaCollection  ? state.appState.currentMediaCollection.name : "Untitled" : ""}</h2>
                                <p className="sub-heading">{"@"}{state.appState.strapiUser.username}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
                
            <MediaContentPick4 data={state.appState.currentMediaCollection.mediaContent} />
            
        </div>
    );
}

UserMediaCollection.propTypes = {
    
};

export default UserMediaCollection;