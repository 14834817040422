import { Timeline } from '@xzdarcy/react-timeline-editor';
import { cloneDeep } from 'lodash';
import { useHotkeys, isHotkeyPressed } from 'react-hotkeys-hook'
import BrokenMediaFound from '../../../assets/images/background/BrokenMediaFound.png';
import React, { useEffect, useState, useRef } from 'react';
import { mockData, mockEffect, newTimeline } from './mock';
import { TimelineEffect, TimelineRow } from '@xzdarcy/react-timeline-editor';
import { useTimeline } from './utils/timelineUtils';
import { ImagePanel } from './components/imagePanel';
import { AudioPanel } from './components/audioPanel';
import {audioTimelineMockData, audioTimelineMockEffect, emptyTimeline} from '../../constants/mock/audio/audioTimeline/audioTimelineMock';
import './style.css';
import TimelinePlayer from '../TimelinePlayerControls';
import { registerListener, emit, useSignals } from '../../api/signalSlots';
import useRuntime from '../UAIAPP/api/useRuntime';
import { useDrop } from 'react-dnd'

import { audioTrackEffect} from '../UAIAPP/components/timeline/audioTrack';
import { imageTrackEffect} from '../UAIAPP/components/timeline/imageTrack';
import { useSignal, useSlot } from 'react-signal-slot';

//const eventPreviewShowImageBase64 = new Event("preview.showImage.base64", { bubbles: true }, );
const mockData_: TimelineRow[]  = [
  {
    id: "0",
    actions: [],
  },
  {
    id: "1",
    actions: [],
  },
  {
    id: "2",
    actions: [],
  },
  {
    id: "3",
    actions: [],
  },
  {
    id: "4",
    actions: [],
  },
  {
    id: "5",
    actions: [],
  },
  {
    id: "6",
    actions: [],
  },
  {
    id: "7",
    actions: [],
  },
];
registerListener("preview.showImage.base64", (e) => {

  const base64img = e.base64;
  const baseString=  "data:image/png;base64," + base64img;
  const previewImage = document.getElementById("previewImage");
  previewImage.src = baseString;
});


registerListener("preview.showImage.url", (e) => {
  const url = e.url;
  const previewImage = document.getElementById("previewImage");
  previewImage.src = url;
});


const defaultEditorData = cloneDeep(mockData);
const defaultTimeline: TimelineRow[] = [];
const TimelineEditor = (
    {manager=undefined}
) => {
  const [data, setData] = useState(defaultEditorData);
  const createLayer = useRuntime(state => state.createLayer);

  const [initialized, setInitialed] = useState(false);
  const [ selectedAction, setSelectedAction] = useState(null);
  const timelineState = useRef();
  const domRef = useRef();
const autoScrollWhenPlay = useRef(true);
const [loadedTimeline, setLoadedTimeline] = useState([]);
const updateTimeline = useRuntime(state => state.updateTimeline);
const deleteSelectedTimelineItems = useRuntime(state => state.deleteSelectedTimelineItems);
const sequence = useRuntime(state => state.sequence);
const selected = useRuntime(state => state.appTimeline.selected);
const timeline = sequence ? sequence.timeline != null ? sequence.timeline != [] ? sequence.timeline.length != 0 ? sequence.timeline :  emptyTimeline: emptyTimeline: emptyTimeline: emptyTimeline;
// const timeline = mockData_;
const signal = useSignal();
  useHotkeys('delete', () => {
    const ctrlDown = isHotkeyPressed('delete');
    if( selected != [] && ctrlDown){
      for (const id of  selected) {
        deleteSelectedTimelineItems(id);
      }
    }

  });

  
  const [{ isOver }, drop] = useDrop({
    accept: 'dnd-chonky-file-entry',
    drop: (item, monitor) => onItemDrop(item, monitor),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const onItemDrop = (item, monitor) => {
  console.log('item, monitor :', item, monitor);
    const filepath = item.payload.draggedFile.id;
    const baseFileName = item.payload.draggedFile.name;
    const isImage = item.payload.draggedFile.name.includes('.png') || item.payload.draggedFile.name.includes('.jpg') || item.payload.draggedFile.name.includes('.jpeg');
    const isAudio = item.payload.draggedFile.name.includes('.mp3') || item.payload.draggedFile.name.includes('.wav') || item.payload.draggedFile.name.includes('.ogg');
    const isVideo = item.payload.draggedFile.name.includes('.mp4') || item.payload.draggedFile.name.includes('.mov') || item.payload.draggedFile.name.includes('.avi');
    const type_ = isImage ? 'image' : isAudio ? 'audio' : isVideo ? 'video' : 'image';
    createLayer(
      type_,
      item.payload.draggedFile.name,
      type_,
      0,
      1,
      item.payload.draggedFile
    );

  }

  useSlot('play.player.timeline', (e) => {
    manager.props.timeline.play();
 });
  useSlot('pause.player.timeline', (e) => {

    manager.props.timeline.pause();
 });
  useSlot('ffBack.player.timeline', (e) => {

    manager.props.timeline.setTime(0);
 });
  useSlot('ffForward.player.timeline', (e) => {

    manager.props.timeline.setTime(1);
 });


  

  const dataUpdated = (data) => {

    setData(data);
     manager.props.timeline.updateTimeline(data);

    // updateTimeline(data);
  };
  // const emptyTimeline = [
  //   {
  //     id: "0",
  //     actions: [],
  //   }
  // ]



  const showSelectedMedia = (action) => {
    if(action.data.media == undefined || action.data.media == null){
      
      signal("preview.showImage.url", { detail: { url: BrokenMediaFound } });
      return;
    }
    if(action.data.media[0].media.includes("http")){
      signal("preview.showImage.url", { url: action.data.media[0].media });
      return;
    }
    signal("preview.showImage.base64", { base64: action.data.media[0].media });
  }


    // useEffect(() => {
    //   if(initialized == false && timeline){
    //   setInitialed(true);
    //   }
    //     console.log( manager.props.timeline.timeline);
    // }, [timeline]);


  return (
    <>
    {
          timeline == [] || timeline == null ? <></> :
    <div className="timeline-editor-example0 width-100 blueborder-top" ref={drop}>
    
      <Timeline
      ref={ manager.props.timeline.editor}
        onChange={ updateTimeline}
        editorData={ timeline == [] ? emptyTimeline : timeline}
        effects={ {...audioTrackEffect, ...imageTrackEffect}}
        hideCursor={false}
        autoScroll={true}
        onClickAction={(event, {action, row, number}) => {
          // console.log('onClickAction', action, row, number);
          // if(isHotkeyPressed('shift') ){
          //    manager.props.timeline.addSelected(action.id);
          // }else{
          //    manager.props.timeline.setSelected(action.id);
          // }
          // showSelectedMedia(action);
          // setSelectedAction(action);

        }
        }
       
        getActionRender={(action, row) => {
          if (action.effectId.includes('image')) {
            return <ImagePanel action={action} row={row} />;
          }else if(action.effectId.includes('audio')){
            return <AudioPanel action={action} row={row} />;  
            }else{
            return <ImagePanel action={action} row={row} />;  
            }
          
          }}
        style={{ width: '100%',
        backgroundColor: '#00000000'


        }}
      />

    </div>
}
</>
  );
};

export default TimelineEditor;