import React from "react";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
createMediaContent,
getMediaContent,
generatedMedia,
} from "../../uai/api/firebaseHandler";
import { useAPIKey } from "../../uai/api/useAPIKey";
import { useStorage } from "../../uai/api/useStorage";
import { useUser } from "../../uai/api/useUser";
import { Navigation, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { setTrialState } from "../../reducers/cartReducer";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { Link } from "react-router-dom";
import useViewport from "../../uai/components/hooks/useViewport";
import img1 from "../../images/BaseEmptyBG.png";
import { set } from "immutable";
import axios from "axios";
import dinoegg1 from "../../assets/images/details/imagegen/dinoEgg_01.png";
import dinoegg2 from "../../assets/images/details/imagegen/dinoEgg_02.png";
import dinoegg3 from "../../assets/images/details/imagegen/dinoEgg_03.png";
import dinoegg4 from "../../assets/images/details/imagegen/dinoEgg_04.png";
import dinoegg5 from "../../assets/images/details/imagegen/dinoEgg_05.png";
import dinoegg6 from "../../assets/images/details/imagegen/dinoEgg_06.png";
import dinoegg7 from "../../assets/images/details/imagegen/dinoEgg_07.png";
import dinoegg8 from "../../assets/images/details/imagegen/dinoEgg_08.png";
import dinoegg9 from "../../assets/images/details/imagegen/dinoEgg_09.png";
import dinoegg10 from "../../assets/images/details/imagegen/dinoEgg_10.png";
import { prev } from "dom7";
import { max } from "moment";
import {
Accordion,
AccordionHeader,
AccordionBody,
} from "@material-tailwind/react";
import useAppManager from "../../uai/api/appManager";
import {
getStorage,
ref,
uploadString,
getDownloadURL,
} from "firebase/storage";
import { useAuth } from "../../uai/api/useAuth";
import  useRuntime from "../../uai/components/UAIAPP/api/useRuntime";
import { FileInputEdit, SelectEdit, ButtonEdit} from '../../uai/components/UAIAPP/components/ui/uiComponents';
import "../../uai/uaistyle.css";
const weekday = [
"Sunday",
"Monday",
"Tuesday",
"Wednesday",
"Thursday",
"Friday",
"Saturday",
];
function getBase64(file) {
return new Promise((resolve, reject) => {
const reader = new FileReader();
reader.readAsDataURL(file);
reader.onload = () => resolve(reader.result);
reader.onerror = (error) => reject(error);
});
}


function Icon({ id, open }) {
return (
<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 24 24"
	strokeWidth={2}
	stroke="currentColor"
	className={`${
	id === open ? "rotate-180" : ""
	} h-5 w-5 transition-transform`}
>
	<path
	strokeLinecap="round"
	strokeLinejoin="round"
	d="M19.5 8.25l-7.5 7.5-7.5-7.5"
	/>
</svg>
);
}

const previewImages = [
dinoegg1,
dinoegg2,
dinoegg3,
dinoegg4,
dinoegg5,
dinoegg6,
dinoegg7,
dinoegg8,
dinoegg9,
dinoegg10,
];

AudioSplitForm.propTypes = {};

function AudioSplitForm({ maxImagesGenerated = 5 }) {
const manager = useAppManager();
const dispatch = useDispatch();
const auth = useAuth();
const storage = useStorage();
const createMedia = useRuntime(state => state.createMedia);
const { apiKey, verified, isLoading: apiKeyIsVerifying } = useAPIKey();
const { width, height, orientation } = useViewport();
const [input, setInput] = useState("");
const [model, setModel] = useState("spleeter:2stems");
const [isGenerating, setIsGenerating] = useState(false);
const [userUID, setUserUIDd] = useState("");
const [generatedMedia, setGeneratedMedia] = useState(false);
const trial = useSelector((state) => state.appState.trial);
const maxImages = maxImagesGenerated;
const [trialFinished, setTrialFinished] = useState(
maxImages == -1 ? false : trial.imagegen > maxImages ? true : false
);

const generateButton = useRef(null);
const previewImageGen = useRef(null);
const [generatedImage, setGeneratedImage] = useState(
<img
	className="previewImage-row"
	ref={previewImageGen}
	src={img1}
	alt="Binasea"
/>
);
// const [imageClass, setImageClass] = useState(orientation != "Portrait" ? "previewImage-row" : "previewImage-row-portrait")

const checkTrialFinished = (trialState) => {
if (maxImages != -1 && trialState.imagegen > 5) {
	setTrialFinished(true);
}
};
useEffect(() => {
checkTrialFinished(trial);
if (generatedMedia == false && previewImageGen.current != null) {
	const randomPreviewImage = Math.floor(
	Math.random() * previewImages.length
	);
	previewImageGen.current.src = previewImages[randomPreviewImage];
}
}, []);

useEffect(() => {
console.log("auth: ", auth.user ? auth.user.uid : "");
setUserUIDd(auth.user ? auth.user.uid : "");
}, [auth.user]);

useEffect(() => {
console.log("width: ", width);
console.log("height: ", height);
console.log("orientation: ", orientation);
}, [width, height, orientation]);

const createTimelineItems = async (media) => {
// const vocals = [ item for item in media if item.type == "vocals"];
let vocals = {};
let drums = {};
let bass = {};
let piano = {};
let other = {};
const user_ = localStorage.getItem("user");

for (let i = 0; i < media.length; i++) {
	const item = media[i];
	// const item_ = await createMediaContent( item.media, item.id, user_ ? user_.id : -1, "stableV", true, item);
	const item_ = item;
	if (item.prompt == "vocals") {
	createMedia(
		"audio",
		item.prompt,
		"audio.split.vocal",
		0,
		1,
		item_
	);
	vocals = item;
	}
	if (item.prompt == "drums") {
	createMedia(
		"audio",
		item.prompt,
		"audio.split.drums",
		0,
		1,
		item_
	);
	drums = item;
	}
	if (item.prompt == "bass") {
	createMedia(
		"audio",
		item.prompt,
		"audio.split.bass",
		0,
		1,
		item_
	);
	bass = item;
	}
	if (item.prompt == "piano") {
	createMedia(
		"audio",
		item.prompt,
		"audio.split.piano",
		0,
		1,
		item_
	);
	piano = item;
	}
	if (item.prompt == "other") {
	createMedia(
		"audio",
		item.prompt,
		"audio.split.other",
		0,
		1,
		item_
	);
	other = item;
	}
}
};
const generate = async () => {
if (maxImages != -1 && maxImages < trial.imagegen) {
	alert(
	"You have reached the maximum number of generated images for the free trial. Please purchase a license or tokens to continue."
	);
	return;
}
// const endpoint = "https://uai.software/warp/api/v1/music/audio/to/audio/stems";
const endpoint =
	"https://uai.software/warp/api/v1/music/audio/to/audio/stems";
// const endpoint = "http://10.0.0.11:8080/api/v1/music/audio/to/audio/stems";
const baseButtonHTML = generateButton.current.innerText;
setIsGenerating(true);
setGeneratedMedia(true);

generateButton.current.innerText = "Generating...";
const inputB64 = await getBase64(input);
const project_ = JSON.parse(localStorage.getItem("project"));
const user_ = JSON.parse(localStorage.getItem("user"));
const req = {
	media: inputB64.replace("data:audio/wav;base64,", ""),
	model: model,
	modelType: "split.v1",
	appName: localStorage.getItem("appName"),
	projectID: project_.id,
	userID: user_.id,
};

const response = await axios.post(endpoint, req, {
	headers: { Authorization: "Bearer " + process.env.REACT_APP_UAI_API_KEY },
});

console.log(response);
const id = response.data.id ? response.data.id : crypto.randomUUID();
for (let i = 0; i < response.data.media.length; i++) {
	response.data.media[i].id = crypto.randomUUID();
}
req["id"] = id;
response.data.id = id;

const outputURLS = response.data;

const newTrial = { ...trial };
newTrial.imagegen += 1;
dispatch(setTrialState(newTrial));
await createTimelineItems(response.data.media);
const outputData = {
	...outputURLS,
	request: req,
	type: "audio",
};
checkTrialFinished(newTrial);
// setMediaCatalogs([...mediaCatalogs, outputData]);
// setCurrentCatalog(outputData);

generateButton.current.innerText = baseButtonHTML;
setIsGenerating(false);
const firstPathSplit = response.data.media[0].url.split("/");
const secondToLast = firstPathSplit[firstPathSplit.length - 2];
document.dispatchEvent(
	new CustomEvent("filebrowser.setpath", {
	detail: {
		url: `stableV/${user_.id.toString()}/${project_.id.toString()}/assets/audio/${secondToLast}`,
	},
	})
);
};

return (
<>
	<div class="dragArea row w-full flow-root m-auto">
	{/* <div id="inputImageDiv" data-for="topOffset" className="col-lg-12 col-md-12 col-sm-12 form-group">
<label for="inputImage-formbuilder-6" className="form-control-label mbr-fonts-style display-7">Input Image</label>
<input type="file" name="inputImage" id="inputImage-formbuilder-6" data-form-field="inputImage" className="form-control display-7 text-white" step="1"onChange={(e)=>{}}/>
</div>

	*/}
	<FileInputEdit 
		onChange={(e) => {
			setInput(e.target.files[0]);
		}}
		label="Input WAV"
		id="inputImage-formbuilder-6"
		/>
		
	<SelectEdit id="model-formbuilder-6"
		onChange={(e) => {
			console.log(e.target.value);
			setModel(e.target.value);
		}}
		label="Stem Count"
		options={[
			{label:"4", value: "spleeter:4stems"},
			{label:"5", value: "spleeter:5stems"}
		 ]}
		/>

	<ButtonEdit label="Generate"
	onClick={(e) => {
			e.preventDefault();
			generate();
		}}
		label={
			<>
			<span class="fa fa-magic mbr-iconfont mbr-iconfont-btn text-base"></span>
		Generate&nbsp;
			</>
		}
		ref={generateButton}
		id="generateButton"
		/>


	{/* 

<div class="col-auto m-0">
<button ref={generateButton} onClick={(e)=>{
e.preventDefault();
testGenerate();
}} id="testGenerateButton" class="w-100 w-100 w-100 btn btn-primary display-7  m-0 flex"><span class="fa fa-magic mbr-iconfont mbr-iconfont-btn text-base"></span>Test Generate&nbsp;
</button>
</div> */}
	</div>
</>
);
}

export default AudioSplitForm;
