
export const StandardPack = {
    id: 5,
    src: 'https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/UAI_Square_1900.jpg',
    title: 'Standard Pack',
    price: 10.99,
    description: 'The Standard Pack including HugginfFace, and other implementations. ',
    colors: [
        '#00b3c6',
        '#50e3c2',
        '#fa4a86',
    ],
    new: true,
    sale: false,
};

export default StandardPack;
