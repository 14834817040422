import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiamond } from '@fortawesome/free-solid-svg-icons';

import {motion } from 'framer-motion';

const KeyframeButton = ({children, keyframeable = true}) => {
    // State variables
    const [count, setCount] = useState(0);

    // Effects
    useEffect(() => {
        // Code to run on component mount

        // Return a cleanup function if needed
        return () => {
            // Code to run on component unmount
        };
    }, []);

    return (
        <div className="flex flex-row gap-2 content-center items-center">
            {children}
            <motion.button className="!bg-transparent" onClick={() => setCount(count + 1)}
            whileTap={{color:'#F5A214'}}
            whileHover={{ color:'#1e49b6' }}
            transition={{ duration: 0.03 }}
            >
               { keyframeable && <FontAwesomeIcon icon={faDiamond} />}
            </motion.button>

            </div>
    );
};

export default KeyframeButton;