import React from 'react';
import {auth, getUser} from '../uai/api/firebaseHandler';
import { useAuth} from '../uai/api/useAuth';
import { signOut } from "firebase/auth";
import LoadingSpinner from './LoadingSpinner';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Logout = () => {
    const { pending:isLoading, isSignedIn,user:user, auth:auth_ } = useAuth()
    const state = useSelector(state => state);
    const dispatch = useDispatch();


    useEffect(() => {
        logoutClicked(user);
    }, [])




    const logoutClicked = async (e) => {
        localStorage.setItem('user', "{}");
        localStorage.removeItem('APIKey');
        localStorage.clear();
        await signOut(auth);
        window.location.href = '/';
    }


    return (
        <div>

<LoadingSpinner Title="Loading" Message="Logging Out"/>
            
        </div>
    );
}

export default Logout;