import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import {useTimeline} from '../components/TimelineEditor/utils/timelineUtils';

const useAppManagerImpl = () => {
  
    let props = {
        appName: "",
        previewViewerScale : 1,
        timelineViewerScale : 1,
        focusedPanel: "",
        projectData : {
            project: null,
            projects: [],
            projectPath: "",
            sequence: {
                metadata:{
    
                },
                timeline:[],
            },
            sequences: [],
            addSequence: (sequence) => {
                props.projectData.sequences.push(sequence);
            },
    
        }

    }
    


    const setProps = (newProps) => {
        props = {...props, ...newProps};
    }

    const addProps = (key, value) => {
        props[key] = value;
    }

    const getProjectPath = ()=>
     {   
        return `${props.appName}//${props.uaiProject.project.id}`;
    }

    const getProject = ()=>
     {   
        return props.uaiProject.project;
    }

    const getProjects = ()=>
     {   
        if(props.uaiProject === undefined){
            return [];
        }
        return props.uaiProject.projects;
    }

    return { props, setProps, addProps, getProject,getProjects, getProjectPath,


    };

}
const useAppManager = singletonHook({appName:""}, useAppManagerImpl);

export default  useAppManager;
export { useAppManager };