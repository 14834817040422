import { useEffect, useState } from 'react';
import { useAPIKey } from '../../../../api/useAPIKey';

import {  useAuth } from '../../../../api/useAuth';
import  LoadingSpinner  from '../../components/LoadingSpinner';
import TextToImage from '../../../../pages/ai/TextToImage';
import APIKeyMessageModal    from '../../components/layouts/APIKeyMessageModal';


function TextToImageLightning(props) {
    const { pending:isLoading, isSignedIn:isAuthenticated,user:user, auth:auth_ } = useAuth()
    const {apiKey , verified, isLoading: apiKeyIsVerifying}  =  useAPIKey();
    const [showAPIKeyModal, setShowAPIKeyModal] = useState(false);
    const [apiKeyModalTitle, setAPIKeyModalTitle] = useState('Missing API Key');
    const [apiKeyModalBody, setAPIKeyModalBody] = useState('An active API Key is required to utilize this application. Please select an active API Key on your Account Page.');

    useEffect(() => {
        setShowAPIKeyModal(!verified);
    }, [apiKey , verified, apiKeyIsVerifying]);

    return (
        <>
        <div className="home-1 h-[100vh]">
            {apiKeyIsVerifying ?             <LoadingSpinner  Title="Loading" Message="Please wait"/> : <></>


            
}
            <div id='page' className={apiKeyIsVerifying ? 'hidden' : '' + "  h-full"}>

            {/* <HeaderWithVideo headerHeight="100%" translateX="0px" translateY="0px" /> */}

{/* <Banner01UAI data={dataBanner} /> */}

                

                <TextToImage />

                {/* <LiveAutions data={dataLiveaution} /> */}

                {/* <Explore data={dataExplore} /> */}

                {/* <CommunitySpotlight data={dataHotpick} /> */}

                {/* <Collection data={dataCollection} /> */}

                {/* <TopSeller data={dataSeller} /> */}

            </div>
        </div>

        <APIKeyMessageModal />
        </>
    );
}

export default TextToImageLightning;