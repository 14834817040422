// import { ADD_ITEM, DELETE_ITEM, SET_CODE, SET_OPTIN,SET_CODEOBJ,SET_OPTINS,SET_IMAGESSHOT,SET_MESSAGEBOX,CLOSE_MESSAGEBOX, SET_MESSAGEBOXMESSAGE,SET_MEDIAITEMS,SET_PHOTOBOOTHSETTINGSMODE, SET_EXPERIENCE,SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE, SET_IMAGEREQ, RESET, SET_PHOTOBOOTHSETTINGS } from "../actionTypes/actionTypes";
import { ImageRequestBase } from "../actionTypes/imageRequest";
import { UAIMessageBox } from "../actionTypes/messageBox";
import { AISettings } from "../actionTypes/aiSettings";
import { MediaItems } from "../actionTypes/mediaItems";
import { CMSState } from "../actionTypes/cmsState";
import { AppState } from "../actionTypes/appState";
import { OptIns, SetOptIn } from "../actionTypes/optins";
import axios from "axios";

import { PhotoboothSettings } from "../actionTypes/photoboothSettings";
import { timeline } from "@material-tailwind/react";

export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const SET_CODE = "SET_CODE";
export const SET_CODEOBJ = "SET_CODEOBJ";
export const SET_EXPERIENCE = "SET_EXPERIENCE";
export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";
export const SET_IMAGEREQ = "SET_IMAGEREQ";
export const SET_PHOTOBOOTHSETTINGS = "SET_PHOTOBOOTHSETTINGS";
export const SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE = "SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE";
export const SET_PHOTOBOOTHSETTINGSMODE = "SET_PHOTOBOOTHSETTINGSMODE";
export const SET_MEDIAITEMS = "SET_MEDIAITEMS";
export const SET_MESSAGEBOX = "SET_MESSAGEBOX";
export const SET_HIDENAV = "SET_HIDENAV";
export const SET_OPTINS = "SET_OPTINS";
export const SET_OPTIN = "SET_OPTIN";
export const SET_MESSAGEBOXMESSAGE = "SET_MESSAGEBOXMESSAGE";
export const SET_IMAGESTODOWNLOAD = "SET_IMAGESTODOWNLOAD";
export const SET_IMAGESSHOT = "SET_IMAGESSHOT";
export const SET_CMSSTATE = "SET_CMSSTATE";
export const SET_CMSSELECTEDINDEX = "SET_CMSSELECTEDINDEX";
export const SET_SELECTEDPLAYLISTITEM = "SET_SELECTEDPLAYLISTITEM";
export const SET_AISETTINGS = "SET_AISETTINGS";
export const SET_PREVIOUSPAGE = "SET_PREVIOUSPAGE";
export const SET_STRAPIUSER = "SET_STRAPIUSER";
export const SET_USER_MEDIACONTENT = "SET_USER_MEDIACONTENT";
export const SET_CURRENT_MEDIACOLLECTION = "SET_CURRENT_MEDIACOLLECTION";
export const SET_CURRENT_VIEWMEDIA = "SET_CURRENT_VIEWMEDIA";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const SHOW_MODAL = "SHOW_MODAL";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const SET_MEDIACOLLECTIONS = "SET_MEDIACOLLECTIONS";
export const ADD_CARTITEM = "ADD_CARTITEM";
export const REMOVE_CARTITEM = "REMOVE_CARTITEM";
export const SET_CART = "SET_CART";
export const SET_MODE = "SET_MODE";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_APPSTATE = "SET_APPSTATE";
export const SET_TRIALSTATE = "SET_TRIALSTATE";
export const SET_ISPROCESSING = "SET_ISPROCESSING";
export const SET_TIMELINE = "SET_TIMELINE";
export const SET_TIMELINEEFFECTS = "SET_TIMELINEEFFECTS";
export const SET_TIMELINESELECTED = "SET_TIMELINESELECTED";
export const SET_TIMELINERENDERING = "SET_TIMELINERENDERING";
export const SET_TIMELINEGENERATING = "SET_TIMELINEGENERATING";

export const CLOSE_MESSAGEBOX = "CLOSE_MESSAGEBOX";
export const RESET = "RESET";

export const SET_IS_RENDERING = "SET_IS_RENDERING";
export const SET_IS_GENERATING = "SET_IS_GENERATING";
export const SET_IS_DIRTY = "SET_IS_DIRTY";
export const SET_PROGRESS_RENDER = "SET_PROGRESS_RENDER";
export const SET_PROGRESS_LOAD = "SET_PROGRESS_LOAD";
export const SET_STATE = "SET_STATE";
export const SET_FX_PANEL_MODE = "SET_FX_PANEL_MODE";
export const SET_BROWSER_PANEL_MODE = "SET_BROWSER_PANEL_MODE";
export const SET_PREVIEW_PANEL_MODE = "SET_PREVIEW_PANEL_MODE";
export const SET_FOCUSED_WINDOW = "SET_FOCUSED_WINDOW";
export const SET_WINDOW_CONTEXT = "SET_WINDOW_CONTEXT";
export const SET_SOCKETS = "SET_SOCKETS";
export const SET_FINISHED_TUTORIALS = "SET_FINISHED_TUTORIALS";
export const SET_CURRENT_TIMELINE = "SET_CURRENT_TIMELINE";

const initialState = {
  numOfItems: 0,
  code:"",
  previousPage:"/",
  codeObject:{},
  selectedExperience:{},
  selectedPlaylistItem:{},
  appTimeline:{
    timeline: [],
    isRendering: false,
    isGenerating: false,
    isDirty: false,
    progressRender: 0,
    progressLoad: 0,
    state:"loading",
    fxPanelMode:"track",
    browserPanelMode:"file",
    previewPanelMode:"preview",
    focusedWindow:"",
    windowContext:"previewArea",
    currentTimeline: "",
    sockets:[],
    finishedTutorials:[],
  },
  appState: new AppState(),
  cmsState: new CMSState(),
  imageReq: new ImageRequestBase(),
  mediaItems: new MediaItems(),
  imagesToDownload: [],
  usercollections: [],
  photoboothSettings: new PhotoboothSettings(),
  uaiMessageBox: new UAIMessageBox(),
  optIns: new OptIns(),
  aiSettings: new AISettings(),

};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_PREVIOUSPAGE:
      state.appState.returnPage = action.payload.returnPage;
      state.appState.callbackData = action.payload.callback;
      return state;
    case SET_PRODUCTS:
      state.appState.products = action.payload.products;
      return state;
      case SET_CURRENT_TIMELINE:
        state.appTimeline.currentTimeline = action.payload.currentTimeline;
        return state;
    case SET_CART:
      state.appState.cart = action.payload.cart;
      return state;

      case SET_TRIALSTATE:
        state.appState.trial = action.payload.trial;
        return state;

    case SET_MODE:
      state.appState.mode = action.payload.mode;
      return state;

    case ADD_CARTITEM:
      if(state.appState.cart == undefined){
        state.appState.cart = [];
      }
      if(state.appState.cart.includes(action.payload.cartItem)){
        return state;
      }
      state.appState.cart.push(action.payload.cartItem);
      return state;

    case REMOVE_CARTITEM:
      if(state.appState.cart == undefined){
        state.appState.cart = [];
      }
      if(state.appState.cart.includes(action.payload.cartItem)){
        state.appState.cart.splice(state.appState.cart.indexOf(action.payload.cartItem),1);
      }
      return state;

    case SET_STRAPIUSER:
      state.appState.strapiUser = action.payload.strapiUser.user;
      state.appState.jwt = action.payload.strapiUser.jwt;
      return state;

    case SET_USER_MEDIACONTENT:
      state.appState.mediaContent = action.payload.mediaContent;
      return state;
    case SET_CURRENT_MEDIACOLLECTION:
      state.appState.currentMediaCollection = action.payload.mediaCollection;
      return state;
    case SET_CURRENT_VIEWMEDIA:
      state.appState.currentViewMedia = action.payload.media;
      return state;
    case SHOW_LOADING:
      state.appState.loading.title = action.payload.title;
      state.appState.loading.message = action.payload.message;
      state.appState.loading.show = true;
      return state;

    case SET_ISPROCESSING:
      state.appState.isProcessing = action.payload.isProcessing;
      return state;
    
    case SET_TIMELINE:
      state.appTimeline.timeline = action.payload.timeline;
      return state;

    case SET_TIMELINEEFFECTS:
      state.appTimeline.effects = action.payload.effects;
      return state;
      
    case SET_TIMELINESELECTED:
      state.appTimeline.selected = action.payload.selected;
      return state;
    
    case SET_TIMELINERENDERING:
      state.appTimeline.isRendering = action.payload.isRendering;
      return state;
    
    case SET_TIMELINEGENERATING:
      state.appTimeline.isGenerating = action.payload.isGenerating;
      return state;

      case SET_IS_DIRTY:
        state.appTimeline.isDirty = action.payload.isDirty;
        return state;
      case SET_PROGRESS_RENDER:
        state.appTimeline.progressRender = action.payload.progressRender;
        return state;
      case SET_PROGRESS_LOAD:
        state.appTimeline.progressLoad = action.payload.progressLoad;
        return state;
      case SET_STATE:
        state.appTimeline.state = action.payload.state;
        return state;
      case SET_FX_PANEL_MODE:
        state.appTimeline.fxPanelMode = action.payload.fxPanelMode;
        return state;
      case SET_BROWSER_PANEL_MODE:
        state.appTimeline.browserPanelMode = action.payload.browserPanelMode;
        return state;
      case SET_PREVIEW_PANEL_MODE:
        state.appTimeline.previewPanelMode = action.payload.previewPanelMode;
        return state;
      case SET_FOCUSED_WINDOW:
        state.appTimeline.focusedWindow = action.payload.focusedWindow;
        return state;
      case SET_WINDOW_CONTEXT:
        state.appTimeline.windowContext = action.payload.windowContext;
        return state;
      case SET_SOCKETS:
        state.appTimeline.sockets = action.payload.sockets;
        return state;
      case SET_FINISHED_TUTORIALS:
        state.appTimeline.finishedTutorials = action.payload.finishedTutorials;
        return state;

    case HIDE_LOADING:
      state.appState.loading.show = false;
      return state;

    case SHOW_MODAL:
      state.appState.modal.title = action.payload.title;
      state.appState.modal.message = action.payload.message;
      state.appState.modal.show = true;
      state.appState.modal.accept = action.payload.accept;
      state.appState.modal.decline = action.payload.decline;
      state.appState.modal.onAccept = action.payload.onAccept;
      state.appState.modal.onReject = action.payload.onReject;
      return state;

    case SHOW_NOTIFICATION:
      state.appState.notification.title = action.payload.title;
      state.appState.notification.message = action.payload.message;
      state.appState.notification.show = true;
      return state;


    case SET_MEDIACOLLECTIONS:
      state.appState.usercollections = action.payload.collections;
      return state;

    case SET_APPSTATE:
      state.appState = action.payload.appState;
      return state;


    case SET_AISETTINGS:
      state.aiSettings = action.payload.aiSettings;
      return state;
    case ADD_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems + 1,
      };

    case DELETE_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems - 1,
      };

      

    case SET_CMSSTATE:
      return {
        ...state,
        cmsState: action.payload.cmsState,
      };

    case SET_CODE:
      return {
        ...state,
        code: action.payload.code,
      };

    case SET_CODEOBJ:
      return {
        ...state,
        codeObject: action.payload.codeObject,
      };

    case SET_EXPERIENCE:
      return {
        ...state,
        selectedExperience: action.payload.experience,
      };

    case UPDATE_EXPERIENCE:
      const newCodeObject = state.codeObject;
      for(let i = 0; i < newCodeObject.experiences.length; i++){
        if(newCodeObject.experiences[i].name == action.payload.experience.name){
          newCodeObject.experiences[i] = state.selectedExperience;
        }
      }
      state.selectedExperience = action.payload.experience;
      state.codeObject = newCodeObject;

      axios.post("https://uai.software/uai-license-server/xperience/update", { xperience:newCodeObject}).then((response) => {
      // axios.post("https://uai.software/uai-license-server/xperience/update", { xperience:newCodeObject}).then((response) => {
    console.log(response);
    if(response.status == 200){
      alert("Experience Updated!");
    }else{
      alert("Error updating scene: " + response.status);
    }
    
  }).catch((err) => {
      console.log(err);

    });

      return state;


    case SET_IMAGEREQ:
      return {
        ...state,
        imageReq: action.payload.imageReq,
      };

    case SET_PHOTOBOOTHSETTINGS:
      return {
        ...state,
        photoboothSettings: action.payload.photoboothSettings,
      };

    case SET_MEDIAITEMS:
      return {
        ...state,
        mediaItems: action.payload.mediaItems,
      };


    case SET_HIDENAV:
      if(state.appState == undefined){
        state.appState = new AppState();
      }
      state.appState.hideNav= action.payload.hideNav
      return state;

    case SET_MESSAGEBOX:
      return {
        ...state,
        uaiMessageBox: action.payload.uaiMessageBox,
      };

    case SET_IMAGESSHOT:
      state.photoboothSettings.imagesShot = action.payload.imagesShot;
      return state;


    case SET_MESSAGEBOXMESSAGE:
      state.uaiMessageBox.title = action.payload.title;
      state.uaiMessageBox.body = action.payload.body;
      state.uaiMessageBox.open = true;
      return state;


    case CLOSE_MESSAGEBOX:
      state.uaiMessageBox.open = false;
      return state;

    case SET_CMSSELECTEDINDEX:
      state.cmsState.selectedSceneIndex = action.payload.selectedSceneIndex;
      return state;


    case SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE:
      state.photoboothSettings.selectedStyle = action.payload.selectedStyle;
      return state;


    case SET_PHOTOBOOTHSETTINGSMODE:
      state.photoboothSettings.mode = action.payload.mode;
      return state;


    case SET_IMAGESTODOWNLOAD:
      state.imagesToDownload= action.payload.imagesToDownload;
      return state;

    case SET_OPTINS:
      state.optIns = action.payload.optIns;
      return state;


    case SET_OPTIN:
      state.optIns.optInTarget = action.payload.optIn;
      state.optIns.optInTargetValue = action.payload.value;
      state.optIns = SetOptIn(state.optIns);

      return state;

    case RESET:
      state = initialState;
      return state;


    default:
      return state;
  }
};

export const setAppState = (appState) => {
  return {
    type: SET_APPSTATE,
    payload: {
      appState
    },
  };
};
export const setPreviousPage = (returnPage, callback) => {
  return {
    type: SET_PREVIOUSPAGE,
    payload: {
      returnPage,
      callback,
    },
  };
};

export const setMode = (mode) => {
  return {
    type: SET_MODE,
    payload: {
      mode,
    },
  };
};
export const setCart = (cart) => {
  return {
    type: SET_CART,
    payload: {
      cart,
    },
  };
};

export const setTrialState = (trial) => {
  return {
    type: SET_TRIALSTATE,
    payload: {
      trial,
    },
  };
};
export const setProducts = (products) => {
  return {
    type: SET_PRODUCTS,
    payload: {
      products,
    },
  };
};

export const addCartItem = (cartItem) => {
  return {
    type: ADD_CARTITEM,
    payload: {
      cartItem,
    },
  };
};

export const removeCartItem = (cartItem) => {
  return {
    type: REMOVE_CARTITEM,
    payload: {
      cartItem,
    },
  };
};

export const addItem = () => {
  return {
    type: ADD_ITEM,
  };
};

export const deleteItem = () => {
  return {
    type: DELETE_ITEM,
  };
};

export const setCMSState = (cmsState) => {

  return {
    type: SET_CMSSTATE,
    payload: {
      cmsState,
    },
  };
} 

export const setTimeline = (timeline) => {
  return {
    type: SET_TIMELINE,
    payload: {
      timeline,
    },
  };

  }

export const setTimelineEffects = (effects) => {
  return {
    type: SET_TIMELINEEFFECTS,
    payload: {
      effects,
    },
  };

  }

export const setTimelineSelected = (selected) => {
  return {
    type: SET_TIMELINESELECTED,
    payload: {
      selected,
    },
  };
}

export const setTimelineRendering = (isRendering) => {
  return {
    type: SET_TIMELINERENDERING,
    payload: {
      isRendering,
    },
  };
}

export const setTimelineGenerating = (isGenerating) => {
  return {
    type: SET_TIMELINEGENERATING,
    payload: {
      isGenerating,
    },
  };
}


export const setCMSSelectedIndex = (selectedSceneIndex) => {

  return {
    type: SET_CMSSELECTEDINDEX,
    payload: {
      selectedSceneIndex,
    },
  };
} 


export const setCode = (code) => {

  return {
    type: SET_CODE,
    payload: {
      code,
    },
  };
} 

export const setStrapiUser = (strapiUser) => {
  return {
    type: SET_STRAPIUSER,
    payload: {
      strapiUser,
    },
  };

  }

export const setUserMediaContent = (mediaContent) => {
  return {
    type: SET_USER_MEDIACONTENT,
    payload: {
      mediaContent,
    },
  };

  }

export const setCurrentMediaCollectoin = (mediaCollection) => {
  return {
    type: SET_CURRENT_MEDIACOLLECTION,
    payload: {
      mediaCollection,
    },
  };

  }


export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
    payload: {
    },
  };
}

export const setIsProcessing = (isProcessing) => {
  return {
    type: SET_ISPROCESSING,
    payload: {
      isProcessing,
    },
  };
}
export const setCurrentViewMedia = (media) => {
  return {
    type: SET_CURRENT_VIEWMEDIA,
    payload: {
      media,
    },
  };
}

export const showModal = (title = "Loading", message= "Please wait", accept="Ok", decline="Close", onAccept=undefined, onReject=undefined) => {
  return {
    type: SHOW_LOADING,
    payload: {
      title,
      message,
      accept,
      decline,
      onAccept,
      onReject,
    },
  };
  }


export const showLoading = (title = "Loading", message= "Please wait") => {
  return {
    type: SHOW_LOADING,
    payload: {
      title,
      message,
    },
  };
  }

  
export const showNotification = (title = "Loading", message= "Please wait") => {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      title,
      message,
    },
  };
  }


export const setMediaCollections = (collections) => {
  return {
    type: SET_MEDIACOLLECTIONS,
    payload: {
      collections,
    },
  };

  }


export const setAISettings = (aiSettings) => {

  return {
    type: SET_AISETTINGS,
    payload: {
      aiSettings,
    },
  };
} 
export const setImagesToDownload = (imagesToDownload) => {

  return {
    type: SET_IMAGESTODOWNLOAD,
    payload: {
      imagesToDownload,
    },
  };
} 

export const setOptIn = (optIns) => {
  
    return {
      type: SET_OPTINS,
      payload: {
        optIns,
      },
    };
  }

  export const setOptInTarget = (optIn,value) => {
  
    return {
      type: SET_OPTIN,
      payload: {
        optIn,value
      },
    };
  }
  export const setCodeObj = (codeObject) => {
  
    return {
      type: SET_CODEOBJ,
      payload: {
        codeObject,
      },
    };
  }

  export const setExperience = (experience) => {
    
      return {
        type: SET_EXPERIENCE,
        payload: {
          experience,
        },
      };
    }

  export const updateExperience = (experience) => {
    
      return {
        type: UPDATE_EXPERIENCE,
        payload: {
          experience,
        },
      };
    }


    export const setImagesShot = (imagesShot) => {
    
      return {
        type: SET_IMAGESSHOT,
        payload: {
          imagesShot,
        },
      };
    }


    export const setImagereq = (imageReq) => {
    
      return {
        type: SET_IMAGEREQ,
        payload: {
          imageReq,
        },
      };
    }

    export const setPhotoboothSettings = (photoboothSettings) => {
    
      return {
        type: SET_PHOTOBOOTHSETTINGS,
        payload: {
          photoboothSettings,
        },
      };
    }
    export const setMediaItems = (mediaItems) => {
    
      return {
        type: SET_MEDIAITEMS,
        payload: {
          mediaItems,
        },
      };
    }
    export const setHideNav = (hideNav) => {
    
      return {
        type: SET_HIDENAV,
        payload: {
          hideNav,
        },
      };
    }
    export const setMessageBox = (uaiMessageBox) => {
    
      return {
        type: SET_MESSAGEBOX,
        payload: {
          uaiMessageBox,
        },
      };
    }
    export const setMessageBoxMessage = (title, body) => {
    
      return {
        type: SET_MESSAGEBOXMESSAGE,
        payload: {
          title,
          body,
        },
      };
    }

    export const closeMessageBox = () => {
    
      return {
        type: CLOSE_MESSAGEBOX,
        payload: {
          
        },
      };
    }

    export const setPhotoboothSettingsSelectedStyle = (selectedStyle) => {
    
      return {
        type: SET_PHOTOBOOTHSETTINGSSELECTEDSTYLE,
        payload: {
          selectedStyle,
        },
      };
    }

    export const setCurrentTimeline = (currentTimeline) => ({
      type: SET_CURRENT_TIMELINE,
      payload: { currentTimeline },
    });
    
    export const setPhotoboothSettingsMode = (mode) => {
    
      return {
        type: SET_PHOTOBOOTHSETTINGSMODE,
        payload: {
          mode,
        },
      };
    }


export const setIsDirty = (isDirty) => ({
  type: SET_IS_DIRTY,
  payload: { isDirty },
});

export const setProgressRender = (progressRender) => ({
  type: SET_PROGRESS_RENDER,
  payload: { progressRender },
});

export const setProgressLoad = (progressLoad) => ({
  type: SET_PROGRESS_LOAD,
  payload: { progressLoad },
});

export const setState = (state) => ({
  type: SET_STATE,
  payload: { state },
});

export const setFxPanelMode = (fxPanelMode) => ({
  type: SET_FX_PANEL_MODE,
  payload: { fxPanelMode },
});

export const setBrowserPanelMode = (browserPanelMode) => ({
  type: SET_BROWSER_PANEL_MODE,
  payload: { browserPanelMode },
});

export const setPreviewPanelMode = (previewPanelMode) => ({
  type: SET_PREVIEW_PANEL_MODE,
  payload: { previewPanelMode },
});

export const setFocusedWindow = (focusedWindow) => ({
  type: SET_FOCUSED_WINDOW,
  payload: { focusedWindow },
});

export const setWindowContext = (windowContext) => ({
  type: SET_WINDOW_CONTEXT,
  payload: { windowContext },
});

export const setSockets = (sockets) => ({
  type: SET_SOCKETS,
  payload: { sockets },
});

export const setFinishedTutorials = (finishedTutorials) => ({
  type: SET_FINISHED_TUTORIALS,
  payload: { finishedTutorials },
});

    export const reset = () => {
    
      return {
        type: RESET
              };
    }


    