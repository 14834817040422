import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BaseEmptyBG from '../../images/BaseEmptyBG.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCartShopping, faPhotoFilm, faComments } from '@fortawesome/free-solid-svg-icons';
import icon1 from '../../assets/images/icon/rain1.svg'
import icon2 from '../../assets/images/icon/rain2.svg'
import icon3 from '../../assets/images/icon/ethe.svg'
import CardModal from '../layouts/CardModal';
import { MediaContentCard} from './MediaContentCard';


const MediaContentPick4 = ({data = []}) => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    const [modalShow, setModalShow] = useState(false);
    const addDefaultSrc = (ev) =>{
        // delete the ev item from document
        // document.body.removeChild(ev.target);
        ev.target.src = BaseEmptyBG;
      }
    
      useEffect(() => {

        console.log(data);
        }, [data]);
    const [dataTab] = useState([
        {
            id: 1,
            title: '3D MODEL',
            item: 0,
        },
        {
            id: 2,
            title: 'ANIME/MANGA',
            item: 3,
        },
        {
            id: 3,
            title: 'CYBER PUNK',
            item: 1,
        },
        {
            id: 4,
            title: 'PIXEL ART',
            item: 4,
        },
        {
            id: 5,
            title: 'MUSIC',
            item: 2,
        },
        {
            id: 6,
            title: 'ABSTRACT',
            item: 1,
        },
        {
            id: 7,
            title: '2D ARTS',
            item: 3,
        },

    ]);
    return (
        <section className="tf-section tf-hot-pick tf-filter">
                <div className="tf-container">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="tf-heading style-3 mb26 wow fadeInUp">
                                <h3 className="heading">Hot Sales</h3>
                                <p className="sub-heading">The most creative creator - Based on the last 30 days </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                        <Tabs>
                                <div className="d-flex justify-content-between mb-wr">
                                    <TabList>
                                        {
                                            dataTab.map(idx => (
                                                <Tab key={idx.id}>{idx.title}</Tab>
                                            ))
                                        }
                                        
                                    </TabList>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className=''>
                                            Recently created    
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                        <Dropdown.Item href="#">
                                            <li><span>Recently Listed</span></li>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#">
                                            <li className="active"><span>Recently Created</span></li>
                                        </Dropdown.Item>
                                        <Dropdown.Item href="#">
                                            <li><span>Recently Sold</span></li>
                                        </Dropdown.Item>
                                        
                                        </Dropdown.Menu>
                                    </Dropdown>
            
                                </div>


                                {
                                    dataTab.map(idx => (
                                        <TabPanel key={idx.id}>
                                            <div className="row tf-filter-container wow fadeInUp">
                                                {
                                                    data.map(idx =>{
                                                        if(idx.url.includes("auth0|") || idx.url.includes("auth0-")){
                                                            return <></>;
                                                        }
                                                        return  <MediaContentCard mediaContent={idx} />
                                                    })
                                                }
                                            
                                            </div>
                                        </TabPanel>
                                    ))
                                }
                                

                            </Tabs> 
      
                        </div>
                    </div>
    
                    <div className="col-md-12">
                        <div className="btn-loadmore wow fadeInUp">
                            <Link to="/explore-v1" className="tf-button style-8 loadmore">Explore More <i className="fas fa-long-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>

                <CardModal 
                show={modalShow}
                onHide={() => setModalShow(false)} 
            />
            </section>
    );
}
MediaContentPick4.propTypes = {
    data : PropTypes.array,
};

export default MediaContentPick4;