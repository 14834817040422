

import { useState, useEffect } from 'react';

import { useSignals } from "../../../../api/signalSlots";
import { useAppManager } from "../../../../api/appManager";

import {LineEdit, SpinBoxEdit, ButtonEdit, CheckEdit, FileInputEdit, SelectEdit, TextAreaEdit , DoubleSpinBoxEdit} from './uiComponents';
import FXDropdown from '../../../FXDropdown';
import DynamicEditUI from './dynamicEditUI';
import {createImageRequest} from '../../../../../actionTypes/imageRequest';
import useRuntime from '../../api/useRuntime';
import { getUAIComponent } from '../../../../api/firebaseHandler';

const DynamicUI = ({component=undefined, onChange = ({newValue, component}) => { }, runAction=(data)=>{}, showFunctions=false}) => {
    const manager = useAppManager();
    const [value, setValue] = useState([]);
    const fieldGenerateFXUpdated= useRuntime((state) => state.fieldGenerateFXUpdated);

    const onValueChange = (e) => {
        const newValue = [...value];
        const index = newValue.findIndex((item) => item.uuid == e.uuid)
        if (index > -1) {
            newValue[index] = e;
        } else {
            newValue.push(e);
        }
        setValue(newValue);
        if(component.isFX == true){
        manager.props.timeline.fieldUpdated(e, component);
    }else{
        fieldGenerateFXUpdated(e, component);
    }
    onChange({newValue, component});
    }
    useEffect (() => {


    },[]);
   
    
    useEffect(() => {
        const data =  getUAIComponent(component.name);
        // setValue(data);
        // setComponent(data);
        // console.log("data", data);
        console.log(component);

    }, [component]);

    return(

        <>
               <FXDropdown title={component.title} showCheckbox={true} showDelete={true} component={component} >


{ component.subwindows &&
    component.subwindows.map((subwindow, index) => {

if(subwindow.title){
        return(
            <div className="flex w-full">
        <FXDropdown title={subwindow.title} className="!m-3" component={component} initialState={component.initialState} >

            {
                subwindow.fields.map((field, indexF) => {
                    let initialValue = field.value;
                    try{
                    const filtered = component.component.properties.filter((item) => item.name == field.name || item.id == field.name);
                    if(filtered.length > 0){
                        initialValue = filtered[0].value;
                    }
                }catch{

                }
                    return <DynamicEditUI field={field} key={indexF} onChange={onValueChange} component={subwindow} initialValue={initialValue} />

                })

            }
            
        </FXDropdown>
        
        </div>
        )
    }else{
        return(
            <></>
        )
    }



    })
}
<div className="flex flex-row w-full justify-end ">
<div className="!m-3">
            { 
            showFunctions  &&
            component.functions.map((func, index) => {
                if(func.type == "submit" && func.executable != "python.exe"){
                return <button key={index} onClick={() =>{runAction({component:component, action:func})}} className="ml-2 p-2 bg-transparent appBlueBorder text-white">{func.name}</button>
                }else{
                    return false;
                }
            })
            }
            </div>
            </div>
        </FXDropdown>
               
        
        </>

    )


}

export default DynamicUI;