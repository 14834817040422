

export class AppState
{
    constructor() {
    this.returnPage = "/";
    this.callbackData = {
        "code": "",
        "state": "",
        "access_token": "",
        "refresh_token": "",
        "metaData": {},
    };
    this.hideNav = false;
    this.error = "";
    this.jwt = "";
    
    this.cart = {
        "percentOff": 0,
        "totalPrice": 0,
        "discount": 0,
        "subtotal": 0,
        "items": [],
        "couponCode": "",
        "coupon": {},
        "metaData": {},
    };
    this.products = {
        "currentProduct": {},
        "currentProductStripe": {},
        "products": [],
        "licenseTypes": [],
        "licenseTypesSelect": [],
        "catalogList": [],
        "viewResults": [],
        "plugins": [],
        "apps": [],
        "filters": {
            "searchText": "",
            "licenseType": [],
            "solutions": [],
            "price": [],
            "status": [],
            "categories": [],
            "tags": [],
            "results": [],
        },
        "currentLicenseType": {},
        "currentPlanInterval": {},
        "currentPlanPlan": {},
        "planPlans": <option value="commercial">Commercial</option>,
        "planIntervals":  <option value="monthly">Monthly</option>,
        "currentPriceModifier":1,
        "currentPrice":0,
        "parentPlan":{},
        "currentPlan":{},
        "currentPlanInfo":{},
        "currentVariation":{},
        "currentVariationIndex":0,
        "uaiLicenseProduct":{},
        "addons": [],
    }
    this.loading = {
        "show": false,
        "message": "Please Wait",
        "title": "Loading",
    }
    this.trial = {
        "imagegen": 0,
        "tts": 0,
        "videogen": 0,
        "mocap": 0,
        "removebg": 0,
        "voiceclone": 0,
        "photobooth": 0,
        "personabot": 0,
        "chatbot": 0,
        "imagevariation": 0,
        "imagecontrolnetcanny": 0,
        "imagecontrolnetdepth": 0,
        "imagecontrolnetscribble": 0,
    }
    this.modal = {
        "show": false,
        "message": "Please Wait",
        "title": "Loading",
        "accept": "Ok",
        "decline": "Cancel",
        "onAccept": {},
        "onReject": {},
    }
    this.notification = {
        "show": false,
        "message": "Please Wait",
        "title": "Loading",
        "accept": "Ok",
        "decline": "Cancel",
        "onAccept": {},
        "onReject": {},
    }
    this.isProcessing = false;
    this.mode = "main";
    this.strapiUser = {};
    this.mediaContent = [];
    this.collections = [];
    this.currentMediaCollection = {};
    this.currentViewMedia = {};
    this.optIns = [];
    }

    

    json(){
        return JSON.stringify(this);
    }
}