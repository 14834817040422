import ColorBackground from "../colorBackground/ColorBackground";
// const shadowProps = {
//     shadowTopBottom: {
//         top: "-310px",
//         blur: "65px",
//         width: "500%",
//         left: "-200px",
//     },
//     shadowTopBottom: {
//         top: "300px",
//         blur: "55px",
//         width: "500%",
//         left: "-100px",
//     },
// }

const ShadowBackground = ({
    shadowTop= {
        top: "-310px",
        blur: "65px",
        width: "500%",
        left: "-200px",
    },
    shadowBottom= {
        top: "300px",
        blur: "55px",
        width: "500%",
        left: "-100px",
    },
    color="#000000b5"
}) => {


    return (
        <>
        <ColorBackground color={color} />
          <div className="shadow shadow-top" style={{top:shadowTop.top, filter: "blur(" + shadowTop.blur + ")", width:shadowTop.width , left:shadowTop.left}} />
          <div className="shadow shadow-bottom" style={{top:shadowBottom.top, filter: "blur(" + shadowBottom.blur + ")", width:shadowBottom.width , left:shadowBottom.left}} />
            
        </>
    )
}

export default ShadowBackground;
