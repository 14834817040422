

import img1 from '../images/ai/stableV/PrincessPeach2.png'

import img2 from '../images/slider/slider-5.png'
import avt1 from '../images/ai/avatars/avatar_RedPanda.png'

const dataBanner = [
    {
        id: 1,
        
        heading: 'Boundless Creativity',
        desc: "Explore content creation's next frontier with our Production Ready Gen AI toolkit.",
        img: img1,
        price: 'Image Gen',
        app: 'Stable V',
        avt: avt1,
        prompt: '“A painting of Princess Peach singing a Decleration of Independence.Masterpiece. Cinematic lighting. Award Winning”',
        tag: '@SolvadorDali'
    },
    

    
    

]

export default dataBanner;