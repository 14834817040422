import {
    ChonkyActions,
    FileContextMenu,
    FileData,
    FileNavbar,
    FileBrowser,
    FileViewMode,
    FileList,
    ChonkyIconName,
    defineFileAction,
} from "chonky";
import FileTools from "./FileTools.tsx";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

import { useRef } from "react";
import { createFolder, deleteFiles } from "./api";
import { DNA } from "react-loader-spinner";
import useAppManager from "../../api/appManager";
export const UAIBrowser = ({}) => {
    const manager = useAppManager();
    // const browser = useRef(null);
    const inputFiles = useRef(null);

    const useTinyThumbnails = defineFileAction({
        id: "use_tiny_thumbnails",
        button: {
            name: "Small Grid",
            toolbar: true,
            contextMenu: true,
            icon: ChonkyIconName.smallThumbnail,
        },
        fileViewConfig: {
            mode: FileViewMode.Grid,
            entryWidth: 75,
            entryHeight: 75,
        },
    });

    const FileActions = [
        useTinyThumbnails,
        ChonkyActions.UploadFiles,
        ChonkyActions.DownloadFiles,
        ChonkyActions.DeleteFiles,
        ChonkyActions.CreateFolder,
    ];
    const handleAction: FileActionHandler = async (data) => {
        console.log(data);
        if (data.id === "upload_files") {
            inputFiles.current.click();
        }
        if (data.id === "download_files") {
            for (var i = 0; i < data.state.selectedFilesForAction.length; i++) {
                const file = data.state.selectedFilesForAction[i];
                await manager.props.uaiProject.downloadFile(file.id, file.name);
            }
        }
        if (data.id === ChonkyActions.OpenFiles.id) {
            console.log("ChonkyActions.OpenFiles");

            const { targetFile, files } = data.payload;
            const fileToOpen = targetFile ?? files[0];

            if (fileToOpen && fileToOpen.isDir) {
                // setCurrentFileBrowserPath(fileToOpen.id);
                await manager.props.uaiProject.setCurrentFolderId(
                    fileToOpen.id
                );
                return;
            } else {
                await manager.props.uaiProject.handleFileClicked(fileToOpen.id);
            }
        } else if (data.id === ChonkyActions.DeleteFiles.id) {
            await deleteFiles(
                data.state.selectedFilesForAction,
                manager.props.uaiProject
            );

            await manager.props.uaiProject.refresh();
        } else if (data.id === ChonkyActions.MoveFiles.id) {
            // moveFiles(
            //     data.payload.files,
            //     data.payload.source,
            //     data.payload.destination
            // );
        } else if (data.id === ChonkyActions.CreateFolder.id) {
            const folderName = prompt("Provide the name for your new folder:");
            console.log(
                manager.props.uaiProject.currentPath + "/" + folderName
            );
            console.log("Create Folder 1");

            await createFolder(folderName, manager.props.uaiProject);
            console.log("Create Folder 2");

            await manager.props.uaiProject.refresh();
            // await manager.props.uaiProject.createFolder(folderName);
            console.log("Create Folder 3");
        }

        // showActionNotification(data);
    };
    const getThumbnail = async (file: FileData) => {
        if (file.isDir) {
            return null;
        }
        if (
            file.name.includes("png") ||
            file.name.includes("jpg") ||
            file.name.includes("jpeg") ||
            file.name.includes("gif") ||
            file.name.includes("webp")
        ) {
            const storage = getStorage();
            const storageRef = ref(storage, file.id);
            const downloadurl = await getDownloadURL(storageRef);
            return downloadurl;
        } else {
            return null;
        }
    };

    const actionsToDisable: string[] = [ChonkyActions.ToggleHiddenFiles.id];

    const [{ isOver }, drop] = useDrop({
        accept: ["dnd-chonky-file-entry", NativeTypes.FILE],
        drop: (item, monitor) => onItemDrop(item, monitor),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const onItemDrop = (item, monitor) => {
        console.log("item, monitor :", item, monitor);
        if (item.files) {
            const files = item.files;
            const file = item.files[0];

            const baseFileName = file.name;
            const fileExt = baseFileName.split(".").pop();
            // const isImage = item.payload.draggedFile.name.includes('.png') || item.payload.draggedFile.name.includes('.jpg') || item.payload.draggedFile.name.includes('.jpeg');
            // const isAudio = item.payload.draggedFile.name.includes('.mp3') || item.payload.draggedFile.name.includes('.wav') || item.payload.draggedFile.name.includes('.ogg');
            // const isVideo = item.payload.draggedFile.name.includes('.mp4') || item.payload.draggedFile.name.includes('.mov') || item.payload.draggedFile.name.includes('.avi');
            // const type_ = isImage ? 'image' : isAudio ? 'audio' : isVideo ? 'video' : 'image';
            console.log("file: ", file);
            console.log("baseFileName: ", baseFileName);
        }
    };

    return (
        <div className="!h-full" ref={drop}>
            <input
                type="file"
                ref={inputFiles}
                style={{ display: "none" }}
                onChange={async (e) => {
                    await manager.props.uaiProject.uploadFileByFiles(
                        e.target.files
                    );
                    console.log("uploadFileByFiles");
                    await manager.props.uaiProject.RefreshProjectFiles();
                    e.target.value = null;
                }}
            />
            {manager.props.uaiProject ? (
                manager.props.uaiProject.loading ? (
                    <div className="canvas-paper w-full h-full flex  items-center justify-center">
                        <DNA className="m-auto" />
                    </div>
                ) : (
                    <FileBrowser
                        instanceId="fileBrowser"
                        files={manager.props.uaiProject.chonkyFiles}
                        fileActions={FileActions}
                        ref={manager.props.uaiBrowser}
                        onFileAction={handleAction}
                        disableDefaultFileActions={actionsToDisable}
                        defaultFileViewActionId={
                            ChonkyActions.EnableListView.id
                        }
                        folderChain={manager.props.uaiProject.folderChain}
                        thumbnailGenerator={getThumbnail}
                        scale={20}
                        startLeft={2}
                    >
                        <FileNavbar />
                        <FileTools />
                        <FileList />
                        <FileContextMenu />
                    </FileBrowser>
                )
            ) : (
                <></>
            )}
        </div>
    );
};
