import MarkDownLoader from './markDownLoader.js';

import privacy from './privacy.md';

const PrivacyPolicy = () =>{


    return(
        <MarkDownLoader markDownFile={privacy}/>
    )

}

export default PrivacyPolicy;