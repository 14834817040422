import React , { useState , useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
import {loggedInMenu} from '../../pages/menu';
import { Dropdown } from 'react-bootstrap';
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";

import {auth, getUser} from '../../uai/api/firebaseHandler';
import { useAPIKey } from "../../uai/api/useAPIKey";
import { useHideNav } from "../../uai/api/useHideNav";
import {useAuth} from '../../uai/api/useAuth';
// import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from "@material-tailwind/react";
import './styles.scss';
import logo from '../../assets/images/logo/logo.png';
import logodark from '../../assets/images/logo/logo_dark.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCartShopping, faBug, faTable } from '@fortawesome/free-solid-svg-icons';
import DarkMode from './DarkMode';

import {setPreviousPage} from '../../reducers/cartReducer';

// import Button from '../button';



const Header = () => {

    
    const {hideNav} = useHideNav();
    const state = useSelector(state => state);
    const { pending:isLoading, isSignedIn:isAuthenticated,user:user, auth:auth_ } = useAuth()
    const {apiKey , verified, isLoading:apiKeyIsVerifying}  = useAPIKey();

    // const { loginWithRedirect, logout, user, isAuthenticated ,isLoading } = useAuth0();
    const dispatch = useDispatch();
    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);
        useEffect(() => {
    }, [state.appState]);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };

    const generateItem = (data,idx) => {

        return (
        <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} 
        
        >
            <Link to={data.links}>{data.name}</Link>
            {
                data.namesub &&
                <ul className="sub-menu">
                    {
                        data.namesub.map((submenu) => (
                            <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                        ))
                    }
                </ul>
            }
            
        </li>
        );
    }

    return (
        <>

        {state.appState ? state.appState.hideNav == true ? <></> :
        <header className={`header ${scroll ? 'is-fixed' : ''} ${state.appState.hideNav == true ? 'hidden' : ''}`}>
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">                              
                            <div id="site-header-inner">                                 
                                <div id="site-logo" className="clearfix">
                                    <div id="site-logo-inner">
                                        <Link to="/" rel="home" className="main-logo">
                                            <img id="logo_header" className='logo-dark max-h-8' src={logodark} alt="Binasea" />
                                            <img id="logo_header" className='logo-light' src={logo} alt="Binasea" />
                                        </Link>
                                    </div>
                                </div>
                                
                               <div className="header-center">
                                <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            apiKey?   
                                            loggedInMenu.map((data,idx) => (
                                                <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} 
                                                
                                                >
                                                    <Link to={data.links}>{data.name}</Link>
                                                    {
                                                        data.namesub &&
                                                        <ul className="sub-menu">
                                                            {
                                                                data.namesub.map((submenu) => (
                                                                    <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                    
                                                </li>
                                            ))
                                            :
                                            menus.map((data,idx) => (
                                                <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} 
                                                
                                                >
                                                    <Link to={data.links}>{data.name}</Link>
                                                    {
                                                        data.namesub &&
                                                        <ul className="sub-menu">
                                                            {
                                                                data.namesub.map((submenu) => (
                                                                    <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                                                ))
                                                            }
                                                        </ul>
                                                    }
                                                    
                                                </li>
                                            ))
                                        }

                                    </ul>
                                </nav>
                            </div>

                                <div className="header-right"  id="main-nav" >
                                
                                <ul id="menu-primary-menu" className="menu">

                                {user? 
                                <>
                                <li key={100} onClick={()=> handleDropdown(102)} className={`menu-item  ${activeIndex === 102 ? 'active' : ''}`} >
                                <div className=" rounded-full bg-black p-3 !border-slate-700  border tf-button !border-0 !bg-transparent ">
                                                    <Link to='#' onClick={(e)=>{
                                                        e.preventDefault();
                                                        console.log(state);
                                                        
                                                    }} className='!text-2xl'><div className="w-full text-white flex"><FontAwesomeIcon  className='pr-3' icon={faTable} /></div></Link>
                                                    </div>
                                                    </li>

                                <li key={152} onClick={()=> handleDropdown(152)} className={`menu-item  ${activeIndex === 152 ? 'active' : ''}`} >
                                <div className=" rounded-full bg-black p-3 !border-slate-700  border tf-button !border-0 !bg-transparent ">
                                                    <Link to='/reset' className='!text-2xl'><div className="w-full text-white flex"><FontAwesomeIcon  className='pr-3' icon={faBug} /></div></Link>
                                                    </div>
                                                    </li>
                                                    </>
                                
                                                     : undefined  }





                                <li key={120} onClick={()=> handleDropdown(120)} className={`menu-item  ${activeIndex === 120 ? 'active' : ''}`} >
                                <div className=" rounded-full bg-black p-3 !border-slate-700  border tf-button !border-0 !bg-transparent ">
                                                    <Link to='/cart' className='!text-2xl'><div className="w-full text-white flex"><FontAwesomeIcon  className='pr-3' icon={faCartShopping} /></div></Link>
                                                    </div>
                                                    </li>

                                                     

                                                <li key={130} onClick={()=> handleDropdown(130)} className={`menu-item menu-item-has-children ${activeIndex === 130 ? 'active' : ''}`} >
                                <div className=" rounded-full bg-black p-3 !border-slate-700 !border-solid border tf-button ">
                                                    <Link to='#' className='!text-2xl'><div className="w-full text-white flex"><FontAwesomeIcon  className='pr-3' icon={faUser} />{user ? user.email.split("@")[0] :'User'} </div></Link>
                                                    </div>




                                                    <ul className="sub-menu">
                                                          {user ? 
                                                          <>
                                                          <li key={1} className="menu-item"><NavLink to={"/dashboard"} >Account</NavLink></li>
                                                          <li key={2} className="menu-item"><NavLink to={"/logout"} onClick={
                                                            (e)=>{e.preventDefault();
                                                                
                                                                    
                                                                    window.location.href = '/logout';

                                                            //   logout({
                                                            //     logoutParams: {
                                                            //       returnTo: window.location.origin,
                                                            //     },
                                                            //   });
                                                        }}>{"Logout"}</NavLink></li>
</>
                                                        :
                                                        <>
                                                        <li key={3} className="menu-item"><NavLink to={"/signup"} onClick={
                                                            (e)=>{e.preventDefault();
                                                                dispatch(setPreviousPage(window.location.href,
                                                                    {
                                                                        "code": "signup",
                                                                        "state": "signup",
                                                                        "returnPage": window.location.href,
                                                                        "access_token": "",
                                                                        "refresh_token": "",
                                                                        "metaData": {},
                                                                    }
                                                                    ));
                                                                    
                                                                    window.location.href = '/signup';
                                                            // loginWithRedirect({authorizationParams: { screen_hint: "signup", }});
                                                        }}>{"Sign Up"}</NavLink></li>

                                                        <li key={4} className="menu-item"><NavLink to={"/login"} onClick={
                                                            (e)=>{e.preventDefault();
                                                                dispatch(setPreviousPage(window.location.href,
                                                                    {
                                                                        "code": "login",
                                                                        "state": "login",
                                                                        "returnPage": window.location.href,
                                                                        "access_token": "",
                                                                        "refresh_token": "",
                                                                        "metaData": {},
                                                                    }
                                                                    ));
                                                                    window.location.href = '/login';
                                                            // loginWithRedirect();
                                                        }}>{"Login"}</NavLink></li>
                                                        </>
                                                        
                                                        
                                                        }
                                            </ul>
                                                    </li>
                                                    </ul>
                                                    
                                </div>  

                                <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </header>
            :  <></>
            
                                                    }
            </>
       
    );
}

export default Header;