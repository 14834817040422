import {operations as SDOperations} from '../image/stableDiffusionOperations.js';
import useOperations from '../../api/useOperations.jsx';
import  {useEffect} from 'react';

export const operations = [
    ... SDOperations,
]

const useStableVOperations = () => {
    const addOperations = useOperations((state) => state.addOperations);

    useEffect(() => {

        addOperations(operations);
    } ,[])

    return {}

}


export default useStableVOperations;