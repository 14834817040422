import { create } from "zustand";

const useGeneratedMedia = create((set) => ({
    mediaCatalogs: [],
    currentMediaCatalog: {},
    currentGeneratedMedia: {},
    renderQueue: [],
    manager:{},
    setManager: (manager) =>
        set((state) => {
            return { manager: manager };
        }
    ),
    
    loadMediaCatalogs: (mediaCatalogs) =>
        set((state) => {
            return { mediaCatalogs: mediaCatalogs };
        }
    ),
    addMediaCatalog: (mediaCatalog) =>
        set((state) => {
            return { mediaCatalogs: [...state.mediaCatalogs, mediaCatalog] };
        }),
    removeMediaCatalog: (mediaCatalog) =>
        set((state) => {
            return {
                mediaCatalogs: state.mediaCatalogs.filter(
                    (item) => item !== mediaCatalog
                ),
            };
        }
    ),
    setCurrentCatalog: (mediaCatalog) =>
        set((state) => {
            return { currentMediaCatalog: mediaCatalog };
        }
    ),
    setCurrentGeneratedMedia: (mediaCatalog) =>
        set((state) => {
            return { currentGeneratedMedia: mediaCatalog };
        } ),
    addRenderQueue: (mediaCatalog) =>
        set((state) => {
            return { renderQueue: [...state.renderQueue, mediaCatalog] };
        }
    ),
    removeRenderQueue: (mediaCatalog) =>
        set((state) => {
            return {
                renderQueue: state.renderQueue.filter(
                    (item) => item !== mediaCatalog
                ),
            };
        }
    ),
    
}));

export default useGeneratedMedia;

export const getMediaCatalogs = () => {
    return useGeneratedMedia.getState().mediaCatalogs;
}   
export const getCurrentMediaCatalog = () => {
    return useGeneratedMedia.getState().currentMediaCatalog;
}
export const getCurrentGeneratedMedia = () => {
    return useGeneratedMedia.getState().currentGeneratedMedia;
}
export const getRenderQueue = () => {
    return useGeneratedMedia.getState().renderQueue;
}
export const addMediaCatalog = (mediaCatalog) => {
    useGeneratedMedia.getState().addMediaCatalog(mediaCatalog);
}
export const removeMediaCatalog = (mediaCatalog) => {
    useGeneratedMedia.getState().removeMediaCatalog(mediaCatalog);
}
export const setCurrentCatalog = (mediaCatalog) => {
    useGeneratedMedia.getState().setCurrentCatalog(mediaCatalog);
}
export const setCurrentGeneratedMedia = (mediaCatalog) => {
    useGeneratedMedia.getState().setCurrentGeneratedMedia(mediaCatalog);
}
export const addRenderQueue = (mediaCatalog) => {
    useGeneratedMedia.getState().addRenderQueue(mediaCatalog);
}
export const extendRenderQueue = (mediaCatalogs) => {
    useGeneratedMedia.getState().extendRenderQueue(mediaCatalogs);
}
export const removeRenderQueue = (mediaCatalog) => {
    useGeneratedMedia.getState().removeRenderQueue(mediaCatalog);
}

export const setRenderQueue = (mediaCatalogs) => {
    useGeneratedMedia.getState().setRenderQueue(mediaCatalogs);
}
export const clearRenderQueue = () => {
    useGeneratedMedia.getState().clearRenderQueue();
}
export const getRenderQueueLength = () => {
    return useGeneratedMedia.getState().renderQueue.length;
}
export const getRenderQueueItem = (index) => {
    return useGeneratedMedia.getState().renderQueue[index];
}

export const getManager = () => {
    return useGeneratedMedia.getState().manager;
}
export const setManager = (manager) => {
    useGeneratedMedia.getState().setManager(manager);
}