

import img1 from '../images/svg/icon-create-1.svg'
import img2 from '../images/svg/icon-create-2.svg'
import img3 from '../images/svg/icon-create-3.svg'
import img4 from '../images/svg/icon-create-4.svg'

const dataCreate = [
    {
        id: 1,
        img: img3,
        heading: 'Utilize',
        text: 'Utilize over 20 built-in and custom Gen AI & Machine-Learning processes with our production ready models.'
    },
    {
        id: 2,
        img: img2,
        heading: 'Create',
        text: 'Create & Train custom AI & Machine-Learning processes for your specific needs.'
    },
    {
        id: 3,
        img: img1,
        heading: 'Deploy',
        text: 'Ready to go Web API so you and your team can create custom applications and pipelines.'
    }


]

export default dataCreate;