import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Spinner } from "@material-tailwind/react";
import { reset } from '../reducers/cartReducer';


function Reset(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(reset());
        window.location.href = '/';
    }, []);

    return (
        <div className="home-1">
            <div id='page'>

            <section className="tf-section tf-create-and-sell">
                <div className="tf-container">
                    <div className="row justify-center w-full">
                        {/* <h1>Loading..</h1> */}
                        <Spinner />
                    </div>
                </div>
            </section>
                {/* <TopSeller data={dataSeller} /> */}

            </div>
        </div>

    );
}

export default Reset;