import axios from 'axios';
import { sendPostRequest, setBrowserImagesPath, setBrowserAudioPath} from '../utils';

import {createImageRequest, createImageRequestFromOperation} from '../../../../../actionTypes/imageRequest';
import useGeneratedMedia from '../../api/useGeneratedMedia';
import {useSignal} from 'react-signal-slot';
import {emit} from '../../api/useSignalSlots';
import  useRuntime from "../../../UAIAPP/api/useRuntime";
import {sendToast} from '../../../../api/firebaseHandler';

const imageRequestPreProcessed = ({request,operation, value, dictionary, component, action, signal, manager, compositeState}) =>{

    const imageRequest = createImageRequestFromOperation(dictionary);
    compositeState.extendRequest(imageRequest);
    return imageRequest;

}
function getBase64(file) {
    return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    });
    }


    
const createTimelineItems = async (media) => {
    // const vocals = [ item for item in media if item.type == "vocals"];
    let vocals = {};
    let drums = {};
    let bass = {};
    let piano = {};
    let other = {};
    const user_ = localStorage.getItem("user");
    const createMedia = useRuntime.getState().createMedia;
    
    for (let i = 0; i < media.length; i++) {
        const item = media[i];
        // const item_ = await createMediaContent( item.media, item.id, user_ ? user_.id : -1, "stableV", true, item);
        const item_ = item;
        if (item.prompt == "vocals") {
        createMedia(
            "audio",
            item.prompt,
            "audio.split.vocal",
            0,
            1,
            item_
        );
        vocals = item;
        }
        if (item.prompt == "drums") {
        createMedia(
            "audio",
            item.prompt,
            "audio.split.drums",
            0,
            1,
            item_
        );
        drums = item;
        }
        if (item.prompt == "bass") {
        createMedia(
            "audio",
            item.prompt,
            "audio.split.bass",
            0,
            1,
            item_
        );
        bass = item;
        }
        if (item.prompt == "piano") {
        createMedia(
            "audio",
            item.prompt,
            "audio.split.piano",
            0,
            1,
            item_
        );
        piano = item;
        }
        if (item.prompt == "other") {
        createMedia(
            "audio",
            item.prompt,
            "audio.split.other",
            0,
            1,
            item_
        );
        other = item;
        }
    }
    };


const splitAudio = async ({request,operation, value, dictionary, component, action, signal, manager, compositeState}) =>{
    // const url =
	// "https://uai.software/warp/api/v1/music/audio/to/audio/stems";
    const url =
	"http://10.0.0.11:8080/api/v1/music/audio/to/audio/stems";
    console.log(request);
    console.log(dictionary);
    console.log(value);
    // return;
// const endpoint = "http://10.0.0.11:8080/api/v1/music/audio/to/audio/stems";
// const baseButtonHTML = generateButton.current.innerText;
// setIsGenerating(true);
// setGeneratedMedia(true);

// generateButton.current.innerText = "Generating...";
// const inputB64 = await getBase64(request.input);
const project_ = JSON.parse(localStorage.getItem("project"));
if(project_== null){
    sendToast("Please create a project first");
    return;
}
const user_ = JSON.parse(localStorage.getItem("user"));
const req = {
	media: request.input.replace("data:audio/wav;base64,", ""),
	model: request.model,
	modelType: "split.v1",
	appName: localStorage.getItem("appName"),
	projectID: project_.id,
	userID: user_.id,
};
console.log("Splitting audio. Please Wait...");

sendToast("Splitting audio. Please Wait");
const response = await sendPostRequest(   req, url   );
console.log(response);

const outputData = {
    ...response.data,
    request: req,
    type: "audio.split",
   }
const addMediaCatalog = useGeneratedMedia.getState().addMediaCatalog;
const setCurrentCatalog = useGeneratedMedia.getState().setCurrentCatalog;
   addMediaCatalog(outputData);
   setCurrentCatalog(outputData);
   sendToast("Processing Splitting audio");

   console.log(response.data);
//   await createTimelineItems(response.data.media);
   
   // compositeState.setAudio(response.data.media[0].url);
   let regex = /audio\/([^\/]+)\/vocals\.wav/;

    let match = response.data.media[0].url.match(regex);
   
   setBrowserAudioPath(match[1]);
   sendToast("Splitting audio finished!");
emit("show.filebrowser", {key_:"filebrowser"});
    return response.data;

}
const sdxllTextToImage = async ({request,operation, value, dictionary, component, action, signal, manager, compositeState}) =>{

    // dictionary["570537e7-7"].subwindows[0].fields[0]
    const imageRequest = request;
    // const imageRequest = createImageRequestFromOperation(dictionary);
    const url = action.url;
    const response = await sendPostRequest(   imageRequest, url   );
const id =  response.data.id ? response.data.id : crypto.randomUUID();
imageRequest.id = id;


// const outputURLS = await storage.uploadMediaCatalog(response.data.media,req,userUID,  req["id"], "stableV", "png",  true);

// console.log(outputURLS);

//    createMedia("image", "imageGen", 0, 1, outputURLS);
   const outputData = {
    ...response.data,
    request: imageRequest,
    type: "image",
   }
const addMediaCatalog = useGeneratedMedia.getState().addMediaCatalog;
const setCurrentCatalog = useGeneratedMedia.getState().setCurrentCatalog;
   addMediaCatalog(outputData);
   setCurrentCatalog(outputData);

   emit("preview.showImage.url", {key_:"preview",url:response.data.media[0].url});
//         generateButton.current.innerText = baseButtonHTML;
// setIsGenerating(false);
setBrowserImagesPath();
    
compositeState.setImage(response.data.media[0].url);
    return response.data;
}


export const operations = [
    {
        "id":"com.uai.stableV.stableDiffusionXL.Lightning.text2image",
        "function":sdxllTextToImage
    },{
        "id":"com.uai.createImageRequest",
        "function":imageRequestPreProcessed
    },{
        "id":"internal.audio.splitAudio",
        "function":splitAudio
    }
]

export default operations;