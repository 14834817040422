import React from 'react';
import { useEffect } from 'react';
import LoadingSpinner from "./LoadingSpinner";
const ReRoute = ({path = "/" , Title="Loading" ,Message="Please wait"}) => {
    useEffect(() => {
        
        window.location.href = path;
    }, []);

    return (
        <div className="home-1">
            <LoadingSpinner  Title={Title} Message={Message}/>
        </div>

    );
}

export default ReRoute;