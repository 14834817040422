import { getAuth } from "firebase/auth";
import { useState, useEffect } from 'react';
import { auth } from "./firebaseHandler";
import { app } from "./firebaseHandler";


export function useAuth() {
    const [authState, setAuthState] = useState({ isSignedIn: false, pending: true, user: null });

    useEffect(() => {
        const unregisterAuthObserver = getAuth(app).onAuthStateChanged(user => setAuthState({
            user,
            pending: false,
            isSignedIn: !!user
        }));
        return () => unregisterAuthObserver();
    }, []);
    return {
        auth,
        ...authState
    };
}
