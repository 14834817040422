import MarkDownLoader from './markDownLoader.js';

import tos from './tos.md';

const TOS = () =>{


    return(
        <MarkDownLoader markDownFile={tos}/>
    )

}

export default TOS;