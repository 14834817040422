import { create } from "zustand";

const usePreviewArea = create((set) => ({
    resolution: [1920, 1080],
    previewModeWindow: [0,0] ,
    timelineModeWindow: [0,0] ,
    updateResolution: (res, key) =>{
        if(key === 'preview'){
            set( { previewModeWindow: res });
        }else{
            set( { timelineModeWindow: res });
        }
    },
    resetResolution: () => set({ resolution: [1920, 1080] }),
}));

export default usePreviewArea;
