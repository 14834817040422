import { usePreviewWindow } from "./previewWindow";
import { useState, useEffect, useRef } from 'react';
import {
    getStorage,
    ref,
    uploadString,
    getDownloadURL,
    listAll,
    deleteObject,
    uploadBytes,
    getMetadata
} from "firebase/storage";
import { sequence } from '../constants/scene/sequence';
import { useSignals } from "./signalSlots";
import { getUserProjectsByStorage, downloadFile, generateBaseScene, getUserByID, audioContext, canvasToBlob, uploadFileFiles, listStorageFiles, generateFolderChain, getProject, getUserProjects, updateProjectByID, createProject, sendToast, printStatus } from "./firebaseHandler";
import { useUser } from "./useUser";
import {useTimeline} from "../components/TimelineEditor/utils/timelineUtils";
import useRuntime from "../components/UAIAPP/api/useRuntime";
import Queue from "queue-promise";
import localforage from 'localforage';
import { useSignal, useSlot } from 'react-signal-slot';

export const useProject = (appName = "stableV", skipFileMapping = true) => {

    const [project, setProject] = useState(null);
    const [projects, setProjects] = useState([]);
    const [noProject, setNoProject] = useState(true);
    const [noProjects, setNoProjects] = useState(true);
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    const [files, setFiles] = useState([]);
    const [folderChain, setFolderChain] = useState([]);
    const [chonkyFiles, setChonkyFiles] = useState([]);
    const [projectSequences, setSequences] = useState([]);
    const [fileMap, setFileMap] = useState({});
    const [currentSequence, setCurrentSequence] = useState(sequence());
    const [projectPath, setProjectPath] = useState("");
    const [statusMessage, setStatusMessage] = useState("Initialized");
    const [currentPath, setCurrentPath] = useState("");
    const [versions, setVersions] = useState({ versions: [] });
    const [previewMode, setPreviewMode] = useState("preview");
    const [mediaCatalogs, setMediaCatalogs] = useState([]);
    const [currentCatalog, setCurrentCatalog] = useState(null);
    const [selectProject, setSelectProject] = useState(false);
    const [manager, setManager] = useState(null);
    const [mode, setMode] = useState("image");
    const runtimeProject = useRuntime((state) => state.project);
    const setRuntimeProject = useRuntime((state) => state.setProject);
    const setRuntimeProjectPath = useRuntime((state) => state.setProjectPath);
    const setProjectPathName = useRuntime((state) => state.setProjectPathName);
    const resetRuntime = useRuntime((state) => state.reset);
    const setRuntimeSequences = useRuntime((state) => state.setSequences);
    const renderQueue = new Queue({
        concurrent: 1,
        interval: 2000,
        start: true,
      });
      
    const uiQueue = new Queue({
        concurrent: 2,
        interval: 1000,
        start: true,
      });
      
    const [runtime, setRuntime] = useState(
        {
            projectData : {
                project: null,
                projects: [],
                projectPath: "",
                sequence: {
                    metadata:{
        
                    },
                    timeline:[],
                },
                sequences: [],
                addSequence: (sequence) => {
                    const updatedRuntime = {... runtime};
                    updatedRuntime.projectData.sequences.push(sequence);
                    setRuntime(updatedRuntime);
                    return updatedRuntime;
                },
        
            },
        }
    );

    const uaiBrowser = useRef(null);
    const contentArea = useRef(null);
    const user = useUser();
    const timeline = useTimeline();

    const previewWindow = usePreviewWindow();
    const signals = useSignals([['filebrowser.setpath', async (e) => {
        const data = e.detail.url;
        RefreshByPath(data);

    }],[
        "print", async (e) => {
            console.log("Print: ", e.detail.data);
            setStatusMessage(e.detail.data);
        }
    ],[
        'project.getProjects.storage', async (e) => {
            const projects_ = await getUserProjectsByStorage();
            e
                .detail
                .onFinished(projects_);
        }
    ]]);
    const signal = useSignal();
    useSlot('filebrowser.refresh', async () => {
        await refresh();
    });
    useSlot("previewArea.setMode.preview", async (e) => {
        setPreviewMode("preview");
    });
    useSlot("previewArea.setMode.timeline", async (e) => {
        setPreviewMode("timeline");
    });
    useSlot( "print", async (e) => {
        console.log("Print: ", e.data);
        setStatusMessage(e.data);
    });
    useSlot("refresh.user", async (e) => {
        user.refreshUser();
    });
    useSlot("get.user.tokens", async (e) => {
        const tokens = await GetTokens();
        e.onFinished(tokens);
    });
    

    const AddToRenderQueue = (operation) => {
        
        for (let i = 0; i < operation.length; i++) {
            renderQueue.enqueue(operation[i]); // resolved/rejected after 0ms
        }
    }



    const AddToUIQueue = (operation) => {
        for (let i = 0; i < operation.length; i++) {
            uiQueue.enqueue(operation[i]); // resolved/rejected after 0ms
        }
    }


    const StartRenderQueue = async () => {
        renderQueue.start(); // resolved/rejected after 0ms
    }


    const StartUIQueue = async () => {
        uiQueue.start(); // resolved/rejected after 0ms
    }


    const StopRenderQueue = async () => {
        renderQueue.stop(); // resolved/rejected after 0ms
    }

    const StopUIQueue = async () => {
        uiQueue.stop(); // resolved/rejected after 0ms
    }

    const RefreshProjectPath = () => {

        const projectText = localStorage.getItem('project');
        // const projectJSON = runtimeProject;
        const projectJSON = JSON.parse(projectText);
        const userText = localStorage.getItem('user');
        const userJSON = JSON.parse(userText);
        if (projectJSON === null || projectJSON === undefined) {
            return "";
        }
        if (projectJSON.sequences == undefined) {
            projectJSON.sequences = [];
            localStorage.setItem('project', JSON.stringify(projectJSON));
        }
        const path = `${appName}/${userJSON.id}/${projectJSON.id}`;
        setCurrentPath(path);
        setProjectPath(path);
        setCurrentSequence(projectJSON.sequences[0]);
        setSequences(projectJSON.sequences);
        if (skipFileMapping == false) {
            // console.log("Refreshing Project Path1: ", path);
            RefreshStorageFiles(path, projectJSON);
            getTimelineSequenceMeta(path);
        }
        signal('refresh.user');
        return path;
    };

    const GetTokens = async () => {

        const user_ = JSON.parse(localStorage.getItem('user'));
        const user__ = await getUserByID(user_.id);
        return user__.tokens;
    };

    const RunOperations = async () => {

        for (let i = 0; i < manager.props.operations.length; i++) {


        }


        
     
    };

    const RefreshByPath = (path) => {
        const projectText = localStorage.getItem('project');
        const projectJSON = JSON.parse(projectText);
        const userText = localStorage.getItem('user');
        const userJSON = JSON.parse(userText);
        setCurrentPath(path);
        setProjectPath(path);
        if (skipFileMapping == false) {
            // console.log("Refreshing Project Path1: ", path);
            RefreshStorageFiles(path, projectJSON);
        }

        signal("refresh.token");
        return path;
    };

    const RefreshProjectFiles = () => {
        const projectText = localStorage.getItem('project');
        const projectJSON = JSON.parse(projectText);
        if (skipFileMapping == false) {
            RefreshStorageFiles(currentPath, projectJSON);
        }
        return currentPath;
    };

    const moveFiles = async (files: FileData[], source: FileData, destination: FileData) => {
        const storage = getStorage();
        const sourceRef = ref(storage, source.fullPath);
        const destinationRef = ref(storage, destination.fullPath);
        files.forEach(async (file) => {
            const fileRef = ref(storage, file.fullPath);
            await uploadString(destinationRef, fileRef);
            await uploadString(fileRef, 'init');
        });
        await uploadString(sourceRef, 'init');

    };
    const range = (min, max, steps) => {
        // minimum step size
        var stepsize = (max - min) / steps;
        // increase the step size to a nice boundary for example, 1/10th of the 10^n
        // range that includes it
        const pow = Math.trunc(Math.log10(stepsize)) - 1;
        stepsize = Math.trunc(stepsize / 10 ** pow) * 10 ** pow;
        // round min to the same boundary
        let result = [min];
        min = Math.trunc(min / 10 ** pow) * 10 ** pow;
        for (let i = 0; i < steps - 1; i++) {
            min += stepsize;
            result.push(min);
        }
        result.push(max);
        return result;
    };
    function generateIndexes(minIndex, maxIndex, numberOfItems) {
        if (numberOfItems < 1 || maxIndex < minIndex) {
            throw new Error("Invalid input values. 'lastIndex' must be non-negative and 'numberOfItems' must " +
                "be positive.");
        }

        const indexes = [];
        const step = (maxIndex - minIndex) / (numberOfItems - 1);

        for (let i = 0; i < numberOfItems; i++) {
            indexes.push(Math.round(minIndex + i * step));
        }

        return indexes;
    }
    const addSequence = (sequence) => {

        const newSequences = [...projectSequences];
        newSequences.push(sequence);
        setSequences(newSequences);
        return newSequences;

    }

    const saveProject = async () => {

        console.log(projectSequences);
        if(projectSequences.length == 0){
        console.log("Please create a sequence first.");
        sendToast("Please create a sequence first.");
        return;
        }
        console.log("Rendering");

        const project__ = JSON.parse(localStorage.getItem('project'));
        let version = 0;
        if(project__.version != undefined){
            version = project__.version;
        }
        console.log(version);
        console.log(project__.version);
        const newSequences = [...projectSequences];
        //TODO: Save Project
        currentSequence.timeline = [];
        for (let i = 0; i < newSequences.length; i++) {
            const seq = newSequences[i];
            if (seq.id == manager.props.uaiProject.runtime.projectData.sequence.id) {
                newSequences[i] = manager.props.uaiProject.runtime.projectData.sequence;
            }
        }
        const outDict = {
            ...project__,
            sequences: projectSequences,
            version: version + 1
        };

        var store = localforage.createInstance({
            name: "project"
          });

        await store.setItem(project__.id, outDict);
        localStorage.setItem('project', JSON.stringify(outDict));


    };

    const getTimelineSequenceMeta = async (projectPath) => {

        const storage = getStorage();
        const path = `${projectPath}/pipeline/project/init.meta`;
        console.log("path Data!", path);

        const storageRef = ref(storage, path);

        console.log("path storageRef!", storageRef);

        try {
            const docMeta = await getMetadata(storageRef);
            console.log(docMeta);
        } catch (err) {

            console.log("No Meta Data!", err);
            const newData = {
                versions: []
            };
            setVersions(newData);
            await uploadString(storageRef, JSON.stringify(newData));
        }

    };

    const generateWaveform = async (file, width, height, fillStyle = '#222', strokeStyle = '#121', globalCompositeOperation = 'source-over') => {

        const buff = await audioContext.decodeAudioData(file);
        var canvasWidth = width, canvasHeight = height;
        var contextDoc = document.createElement('canvas');
        var context = contextDoc.getContext('2d');
        contextDoc.width = canvasWidth;
        contextDoc.height = canvasHeight;
        context.width = canvasWidth;
        context.height = canvasHeight;

        var leftChannel = buff.getChannelData(0); // Float32Array describing left channel
        var lineOpacity = canvasWidth / leftChannel.length;
        context.fillStyle = fillStyle;
        // context.fillRect(0,0,canvasWidth,canvasHeight );
        context.strokeStyle = strokeStyle;
        context.globalCompositeOperation = globalCompositeOperation;
        context.translate(0, canvasHeight / 2);
        context.globalAlpha = 1; // lineOpacity ;
        const smallerRange = generateIndexes(0, leftChannel.length, 500);
        leftChannel = smallerRange.map((item, index) => {
            return leftChannel[item];
        });
        for (var i = 0; i < leftChannel.length; i++) {
            // on which line do we get ?
            var x = Math.floor(canvasWidth * i / leftChannel.length);
            var y = leftChannel[i] * (canvasHeight / 2);
            // var yabs = canvasHeight - Math.abs(leftChannel[i]) * canvasHeight ;
            context.beginPath();
            context.moveTo(x, 0);
            context.lineTo(x + 1, y);
            context.stroke();
        }
        const canvasData = await canvasToBlob(contextDoc);
        contextDoc = null;
        context = null;
        return canvasData;

    };
    const generateWaveFormFromURL = async (url, width, height, fillStyle = '#222', strokeStyle = '#121', globalCompositeOperation = 'source-over') => {
        const fb = await fetch(url);
        const blob = await fb.blob();
        const url_ = URL.createObjectURL(blob);
        const file = await fetch(url_);
        const fileData = await file.arrayBuffer();
        const canvasData = await generateWaveform(fileData, width, height, fillStyle, strokeStyle, globalCompositeOperation);
        return canvasData;
    };

    const uploadMissingWaveforms = async (files) => {
        for (let i = 0; i < files.length; i++) {
            const url = files[i];
            const canvasData = await generateWaveFormFromURL(url, 1280, 720, 'transparent ', '#ffffff');


        }

    };
    const createDependencyFiles = async (files) => {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const ext = file
                .name
                .split('.')
                .pop();
            if (ext.includes("wav") || ext.includes("mp3") || ext.includes("ogg")) {
                const fileData = await file.arrayBuffer();
                const canvasData = await generateWaveform(fileData, 1280, 720, 'transparent ', '#ffffff');
                const path_ = `${currentPath}/${file.name}.pkf`;
                const storage_ = getStorage();
                const storageRef_ = ref(storage_, path_);
                await uploadBytes(storageRef_, canvasData);

            }
        }

    };

    const uploadFileByFiles = async (files) => {

        const user_ = JSON.parse(localStorage.getItem('user'));

        await uploadFileFiles(files, currentPath, {}, user_.id, project.id, appName);
        await createDependencyFiles(files);

    };

    const setCurrentFolderId = async (folderId) => {

        console.log("Refreshing Project Path2 ", folderId);
        setCurrentPath(folderId);
        const newItem = await RefreshStorageFiles(folderId, project);

        return folderId;
    };

    const getFilesRecursive = async (path) => {
        const storage = getStorage();
        // Create a reference under which you want to list
        const listRef = ref(storage, path);
        // Find all the prefixes and items.
        let resp = await listAll(listRef);
        let files = [];
        let folders = [];
        console.log(resp);
        files = [...resp.items];
        folders = [...resp.prefixes];
        console.log(files, folders);

        for (let i = 0; i < resp.prefixes.length; i++) {
            const folder = resp.prefixes[i];
            const { newFiles2, newFolders2 } = await getFilesRecursive(folder.fullPath);

            files = [...newFiles2];
            folders = [...newFolders2];
        }

        console.log(files, folders);
        return { files, folders };

    };

    const deleteFiles = async (files: CustomFileData[]) => {
        const storage = getStorage();
        files.forEach(async (file) => {
            if (typeof file === 'string') {
                const fileRef = ref(storage, file);
                await deleteObject(fileRef);
                await RefreshProjectFiles();
            } else {
                try {
                    let fileRef = null;
                    if (file.isDir) {
                        const { files, folders } = await getFilesRecursive(file.id);

                        for (let i = 0; i < files.length; i++) {
                            const file_ = files[i];
                            // fileRef = ref(storage, file_.fullPath);
                            console.log("Deleting File: ", file_.fullPath);
                            await deleteObject(file_);
                            await RefreshProjectFiles();
                        }
                        return;
                    } else {
                        fileRef = ref(storage, file.id);
                    }
                    await deleteObject(fileRef);
                    await RefreshProjectFiles();
                } catch {

                    const fileRef = ref(storage, file.fullPath);
                    await deleteObject(fileRef);
                    await RefreshProjectFiles();
                }
            }
        });
    };

    const createFolder = async (folderName: string) => {
        console.log("Creating Folder: ", folderName);

        const storage = getStorage();
        const folderPath = `${currentPath}/${folderName}`;
        console.log("Creating Folder: ", folderPath);
        // const folderRef = ref(storage, folderPath);
        const folderInitRef = ref(storage, folderPath + "/init.meta");
        await uploadString(folderInitRef, 'init');

    };

    const GenerateFileMapItem = async (path, folders, items) => {

        const fileMap_ = {
            "rootFolderId": path,
            "fileMap": {}
        };
        let childNames = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            childNames.push(item.fullPath);
            const storage = getStorage();
            const storageRef = ref(storage, item.fullPath);
            const downloadurl = await getDownloadURL(storageRef);
            // const url = await getDownloadURL(item); fileMap[url] = item;
            const newItem = {
                "id": item.fullPath,
                "name": item.name,
                "isDir": false,
                "isHidden": false,
                "size": item.size,
                "thumbnailUrl": downloadurl,
                "modDate": "2020-10-20T03:11:50.570Z",
                "parentId": path
            };
            fileMap_["fileMap"][item.fullPath] = newItem;
        }

        for (let i = 0; i < folders.length; i++) {

            const folder = folders[i];
            childNames.push(folder.fullPath);
            const newItem = {
                "id": folder.fullPath,
                "name": folder.name,
                "isDir": true,
                "isHidden": false,
                "size": 0,
                "modDate": "2020-10-20T03:11:50.570Z",
                "parentId": path,
                "childrenIds": [],
                "childrenCount": 0
            };
            fileMap_["fileMap"][folder.fullPath] = newItem;
        }
        const filesOutput = [];
        const keys = Object.keys(fileMap_["fileMap"]);
        for (let i = 0; i < keys.length; i++) {
            filesOutput.push(fileMap_["fileMap"][keys[i]]);
        }
        return { fileMap_, filesOutput };
    };
    const GenerateFilesMap = async (path, folders, items) => {

        const fileMap_ = {
            "rootFolderId": path,
            "fileMap": {}
        };
        let childNames = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            childNames.push(item.fullPath);
            // const url = await getDownloadURL(item); fileMap[url] = item;
            const newItem = {
                "id": item.fullPath,
                "name": item.name,
                "isDir": false,
                "isHidden": false,
                "size": item.size,
                "modDate": "2020-10-20T03:11:50.570Z",
                "parentId": path
            };
            fileMap_["fileMap"][item.fullPath] = newItem;
        }

        for (let i = 0; i < folders.length; i++) {

            const folder = folders[i];
            childNames.push(folder.fullPath);
            const newItem = {
                "id": folder.fullPath,
                "name": folder.name,
                "isDir": true,
                "isHidden": false,
                "size": 0,
                "modDate": "2020-10-20T03:11:50.570Z",
                "parentId": path,
                "childrenIds": [],
                "childrenCount": 0
            };
            fileMap_["fileMap"][folder.fullPath] = newItem;
        }
        const newFolderRoot = {
            "id": path,
            "name": path,
            "isDir": true,
            "isHidden": false,
            "size": 0,
            "modDate": "2020-10-20T03:11:50.570Z",
            "parentId": "/",
            "childrenIds": childNames,
            "childrenCount": 0
        };
        fileMap_["fileMap"][path] = newFolderRoot;
        setFileMap(fileMap_);

        return fileMap_;

    };

    const RefreshStorageFiles = async (path, _project) => {

        const { newFolders, newItems, filesOut } = await listStorageFiles(path);
        const foldchain = await generateFolderChain(path, "", _project
            ? _project.id
            : "", _project
            ? _project.name
            : "");
        setFolderChain(foldchain);

        setFiles(filesOut);
        setChonkyFiles(filesOut);
    };

    const SetLoadedProject = async (project_) => {

        setProject(project_);
        setRuntimeProject(project_);
        console.log("Setting Project: ", project_);
        localStorage.setItem('project', JSON.stringify(project_));
        await init();
        // await loadProject(project_);
    };

    const loadProject = async (proj = undefined) => {
        
        console.log("loadProject 0: ", proj);
        var store = localforage.createInstance({
            name: "project"
          });

        let project_json = proj;

        setLoading(true);
        if (proj == undefined) {


            // const project_ = runtimeProject;
            const user_ = JSON.parse(localStorage.getItem('user'));
            const project_ = localStorage.getItem('project');
            let _project_json = {};

            if (!project_ || project_ == "{}") {
                setLoading(false);
                setProject(null);
                setNoProject(true);
                return null;
            }else{
             _project_json = JSON.parse(project_);
            //  _project_json = runtimeProject;
            console.log("project_", _project_json);

             if(_project_json.id == undefined){
                setLoading(false);
                setProject(null);
                setNoProject(true);
                return null;
            }
            }

            project_json = _project_json;
            console.log(_project_json);
            // setSequences(_project_json.sequences);
            // project_json =await store.getItem(_project_json.id);
            try{
            if(_project_json.sequences.length > 0){
                setRuntimeSequences( _project_json.sequences);
        }
    }catch{
        setRuntimeSequences( []);

    }

        }
        console.log("loadProject 1: ", project_json);
        // console.log("loadProject 1: ", project_json);
        const content_ = await getProject(project_json.id);
        console.log("loadProject content_: ", content_);

        // console.log("loadProject 2: ", content_);
        setProject(content_.project);
        setRuntimeProject(content_.project);
        setProjectPathName(content_.name);
        
        setRuntimeProjectPath(content_.path);
        setNoProject(false);
        return content_.project;
    };

    const loadUserProjects = async () => {
        const user_ = localStorage.getItem('users');
        if (!user_) {
            setNoProjects(true);
            return null;
        }
        const user_json = JSON.parse(user_);
        const proj = await getUserProjects(user_json.id);
        setProjects(proj);

        setNoProjects(false);
        return proj;

    };

    const init = async () => {
        setLoading(true);

        const ppath = RefreshProjectPath();
        if (ppath == "") {
            setLoading(false);
            setInitialized(true);
            setNoProject(true);
            setNoProjects(true);

            return;
        }

        setNoProject(true);
        setNoProjects(true);
        setLoading(true);
        setInitialized(false);
        console.log("loadProject 1: ", ppath);

        await loadProject();
        console.log("loadProject 10: ", ppath);





        // await loadUserProjects();
        setInitialized(true);

        // if(projects.length == 0){
        // const projects_ = await getUserProjectsByStorage();
        // console.log("Projects: ", projects_);
        // setProjects(projects_);
        // }
        setLoading(false);

    };

    const resetToNoProject = async () => {
        resetRuntime();

        //TODO: Make the UI reset and go to Create new Project Window.
    };

    const refresh = async () => {
        await init();
    };

    // Access the client
    useEffect(() => {
        if (project == null && initialized == false) {
            localStorage.setItem('appName', appName);
            setupQueues(renderQueue,  uiQueue);
            init();

        }

    }, []);

    const updateProject = async (props) => {
        setLoading(true);

        const user_ = JSON.parse(localStorage.getItem('user'));
        let result = {};
        result = await updateProjectByID(props.id, props);
        setProject(result);
        localStorage.setItem('project', JSON.stringify(result));
        setLoading(false);

    };
    const createNewProject = async (props) => {
        setLoading(true);
        const user_ = JSON.parse(localStorage.getItem('user'));
        let result = {};

        result = await createProject(user_.id, props.id, props.app, props.name, props.projectType, props.description, props.tags, props.thumbnail, props.tags, props.thumbnail, props.visibility, props.metadata);
        
        setRuntimeProject(result);
        setProject(result);
        localStorage.setItem('project', JSON.stringify(result));
        setLoading(false);
        await init();

    };
    const createSequence = (props) =>{
        const newSeq = sequence();
        console.log(props);
    
    
    }
    
    
    
    
    const handleFileClicked = async (file) => {

        let extraFiles = [];
        const fileExtension = file
            .split('.')
            .pop();
        if (fileExtension == "wav" || fileExtension == "mp3" || fileExtension == "ogg") {
            extraFiles.push(file + ".pkf");
        }
        let downloadURL = "";

        const storageRef = ref(getStorage(), file);
        downloadURL = await getDownloadURL(storageRef);
        await previewWindow.showMediaPreview(fileExtension, downloadURL);
        console.log("downloadURL: ", downloadURL);
        for (let i = 0; i < extraFiles.length; i++) {
            const file_ = extraFiles[i];
            const fileExtension_ = file_
                .split('.')
                .pop();
            let downloadURL_ = "";

            const storageRef_ = ref(getStorage(), file_);
            try {
                downloadURL_ = await getDownloadURL(storageRef_);
            } catch {
                await generateWaveFormFromURL(downloadURL, 1280, 720, 'transparent ', '#ffffff');
                console.log("Generated WaveFormFromURL");

                downloadURL_ = await getDownloadURL(storageRef_);

            }
            await previewWindow.showMediaPreview(fileExtension_, downloadURL_);
        }
        sendToast("Opened File: " + file.split("/").pop());
        printStatus("Opened File: " + file.split("/").pop());


    };

    const setupQueues = () => {
        renderQueue.on("dequeue", () => {
    
            sendToast("Render Started");
        });
        renderQueue.on("resolve", data => { });
        renderQueue.on("reject", error => { });
        renderQueue.on("start", () => {
                sendToast("Render Queue Started");
        });
        renderQueue.on("stop", () => {
                sendToast("Render Queue Stopped");
    
        });
        renderQueue.on("end", () => {
                sendToast("Render Queue Finished");
        });
    
    
        uiQueue.on("dequeue", () => {
            console.log("dequeue");
        });
        uiQueue.on("resolve", data => { 
            console.log("resolve", data);

        });
        uiQueue.on("reject", error => {
            console.log("resolve", error);

         });
        uiQueue.on("start", () => {
            console.log("uiQueue", "start");

        });
        uiQueue.on("stop", () => {
            console.log("uiQueue", "stop");

        });
        uiQueue.on("end", () => {
            console.log("uiQueue", "end");

        });
    }
    

    return {
        project,
        updateProject,
        loading,
        getProject,
        getUserProjects,
        refresh,
        init,
        projects,
        noProject,
        noProjects,
        updateProjectByID,
        createNewProject,
        fileMap,
        currentPath,
        projectPath,
        deleteFiles,
        createFolder,
        setCurrentFolderId,
        chonkyFiles,
        setChonkyFiles,
        RefreshProjectFiles,
        folderChain,
        handleFileClicked,
        generateFolderChain,
        generateBaseScene,
        uploadFileFiles,
        uploadFileByFiles,
        getUserProjectsByStorage,
        downloadFile,
        previewMode,
        SetLoadedProject,
        setPreviewMode,
        statusMessage,
        setStatusMessage,
        sendToast,
        printStatus,
        user,
        timeline,
        uaiBrowser,
        contentArea,
        addSequence,
        runtime, setRuntime,
        manager, setManager,
        renderQueue, uiQueue,
        AddToRenderQueue, StartRenderQueue,StartUIQueue,
        AddToUIQueue, 
        StopRenderQueue, StopUIQueue,
        RunOperations,
    mediaCatalogs, setMediaCatalogs, currentCatalog, setCurrentCatalog, selectProject, setSelectProject, mode, setMode
    ,setSequences,  currentSequence, setCurrentSequence, createSequence, saveProject
    };
};

