import Blog01 from "./Blog01";
import Blog02 from "./Blog02";
import BlogDetails01 from "./BlogDetails01";
import BlogDetails02 from "./BlogDetails02";
import Collection from "./Collection";
import UserMediaCollection from "./UserMediaCollection";
import Contact from "./Contact";
import Create from "./Create";
import Dashboard from "./Dashboard";
import Explore01 from "./Explore01";
import Explore02 from "./Explore02";
import Explore03 from "./Explore03";
import Explore04 from "./Explore04";
import Faqs from "./Faqs";
import HelpCenter from "./HelpCenter";
import CallBack from "./CallBack";
import Reset from "./Reset";
import HomeUAI from "./HomeUAI";
import TextToImageLightning from "../uai/components/UAIAPP/modules/image/TextToImageLightning";
import StableV from "../uai/components/UAIAPP/components/apps/StableV";
import Home01 from "./Home01";
import Privacy from "./Privacy";
import TOSPage from "./TOSPage";
import Home02 from "./Home02";
import Home03 from "./Home03";
import Home04 from "./Home04";
import Home05 from "./Home05";
import Home06 from "./Home06";
import MediaItemDetails from "./MediaItemDetails";
import ProductPage from "./ProductPage";
import UAILicenseProductPage from "./UAILicenseProductPage";
// import AIApp from "../components/banner/AIApp";
import Cart from "./Cart";
import ItemDetails01 from "./ItemDetails01";
import ItemDetails02 from "./ItemDetails02";
import LiveAutions01 from "./LiveAutions01";
import LiveAutions02 from "./LiveAutions02";
import Details_ImageGen from "./Details_ImageGen";
import Details_UAIToolkit from "./Details_UAIToolkit";
import Downloads from "./Downloads";
import Login from "./Login";
import Logout from "./Logout";
import Ranking from "./Ranking";
import ThreeD from "../uai/components/threeD";
import SignUp from "./SignUp";
import Wallet from "./Wallet";
import ReRoute from "./ReRoute";
import Products from "./Products";
import SuccessfulPurchase from "./SuccessfulPurchase";
import { ColorsUAI } from "../uai/constants/colors";


const routes = [
  { path: '/', component: <HomeUAI />},
  { path: '/reset', component: <Reset />},
  { path: '/userCollection', component: <UserMediaCollection />},
  { path: '/callback', component: <CallBack />},
  { path: '/uaiproduct', component: <UAILicenseProductPage />},
  { path: '/texttoimage_lightning', component: <TextToImageLightning />},
  { path: '/stableV', component: <StableV />},
  { path: '/uaiProductDetails', component: <UAILicenseProductPage />},
  { path: '/productDetails', component: <ProductPage />},
  { path: '/cart', component: <Cart />},
  // { path: '/aiapp', component: <AIApp />},
  { path: '/successfulPurchase', component: <SuccessfulPurchase />},
  { path: '/products', component: <Products />},
  { path: '/account/profile', component: <ReRoute path="/dashboard" Title="Loading" Message="Loading user account." />},
  { path: '/home-v2', component: <Home02 />},
  { path: '/home-v3', component: <Home03 />},
  { path: '/home-v4', component: <Home04 />},
  { path: '/home-v5', component: <Home05 />},
  { path: '/home-v6', component: <Home06 />},
  { path: '/explore-v1', component: <Explore01 />},
  { path: '/explore-v2', component: <Explore02 />},
  { path: '/explore-v3', component: <Explore03 />},
  { path: '/explore-v4', component: <Explore04 />},
  { path: '/collection', component: <Collection />},
  { path: '/live-auctions-v1', component: <LiveAutions01 />},
  { path: '/live-auctions-v2', component: <LiveAutions02 />},
  { path: '/mediaDetails', component: <MediaItemDetails />},
  { path: '/item-details-v1', component: <ItemDetails01 />},
  { path: '/item-details-v2', component: <ItemDetails02 />},
  { path: '/docs-stablev-imagegen', component: <Details_ImageGen />},
  { path: '/docs-uaitoolkit', component: <Details_UAIToolkit />},
  { path: '/dashboard', component: <Dashboard />},
  { path: '/threeD', component: <ThreeD />},
  { path: '/ranking', component: <Ranking />},
  { path: '/downloads', component: <Downloads />},
  { path: '/policy', component: <Privacy />},
  { path: '/privacy', component: <Privacy />},
  { path: '/tos', component: <TOSPage />},
  { path: '/help-center', component: <HelpCenter />},
  { path: '/faqs', component: <Faqs />},
  { path: '/wallet', component: <Wallet />},
  { path: '/signout', component: <Logout/>},
  { path: '/logout', component: <Logout/>},
  { path: '/login', component: <Login title="Login"/>},
  { path: '/signup', component: <Login title="Sign Up To UAI" />},
  { path: '/create', component: <Create />},
  { path: '/blog-v1', component: <Blog01 />},
  { path: '/blog-v2', component: <Blog02 />},
  { path: '/blog-details-v1', component: <BlogDetails01 />},
  { path: '/blog-details-v2', component: <BlogDetails02 />},
  { path: '/contact', component: <Contact />},
  { path: '/coll', component: <ColorsUAI />},



]

export default routes;