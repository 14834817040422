/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
import { React} from 'react';
import PropTypes from 'prop-types';
import '../../style.css';
// import { useResize } from '../../../api/useResize';
export const DivVideo = ({
headerHeight = '250px', bgOpacity = 'rgba(10,11,16, 0.5)', translateX = '-200px', translateY = '-400px', mp4Url = 'https://www.dropbox.com/s/hb4x4nmtwm7bkoe/SplashScreen_2022Logo_withTagline_lr.mp4?raw=1', poster = 'img/abstractbg_dark.png', childComponent = null,
}) => { 
  const v = 0;
  // const { width, height } = useResize(componentRef);
  
  
  return (
    <div id="services" className="section">                   
      <div className="section-wrapper-thinB block content-1171 center-relative">                                                
        <div className="content-wrapper">

          <div className="single ">
            {/* <div className="service-holder" /> */}
            <div
              className="video-wrapperBG"
              style={{
      height: headerHeight,
      maxHeight: headerHeight,
  }}
            >
              {/* <div id="overlay" style={{ backgroundColor: `rgba(0, 0, 0, ${bgOpacity})` }}> </div> */}
              <div id="overlay" style={{ backgroundColor: bgOpacity }}> </div>
              <video className="video-BG" playsinline autoPlay muted loop poster={poster}>
                <source src={mp4Url} />
                Your browser does not support the video tag.
              </video>
              <div className="videoBGContent">
                {childComponent}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      
        
);
};
DivVideo.propTypes = {
  bgOpacity: PropTypes.string,
  mp4Url: PropTypes.string,
  poster: PropTypes.string,
  translateY: PropTypes.string,
  translateX: PropTypes.string,
  headerHeight: PropTypes.string,
  childComponent: PropTypes.element,

};

DivVideo.defaultProps = {
  mp4Url: 'https://www.dropbox.com/s/hb4x4nmtwm7bkoe/SplashScreen_2022Logo_withTagline_lr.mp4?raw=1',
  poster: 'img/abstractbg_dark.png',
  translateY: '-400px',
  translateX: '-200px',
  headerHeight: '250px',
  bgOpacity: 'rgba(10,11,16, 0.5)',
  childComponent: null,
};

export default DivVideo;
