// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, deleteDoc } from "firebase/firestore";
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { getDoc } from "firebase/firestore";
import { baseScene } from "../components/FileBrowser/constants";
import {uaiComponents  } from "../components/UAIModules/uaiModuleConstants";
import {
    getStorage,
    ref,
    uploadString,
    getDownloadURL,
    listAll,
    deleteObject,
    uploadBytes,
    getMetadata,
} from "firebase/storage";
import { sequence } from "../constants/scene/sequence";
import { emit } from "./signalSlots";
import { toast, Bounce } from "react-toastify";
import { localforage } from "localforage";
import axios from 'axios';

export const audioContext = new (window.AudioContext ||
    window.webkitAudioContext)();
var currentBuffer = null;
const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];


const firebaseConfig = {
    apiKey: "AIzaSyCR51YVFknCU8bFfDDtWpB1dkr5z-NhmEI",
    authDomain: "uai-backend.firebaseapp.com",
    projectId: "uai-backend",
    storageBucket: "uai-backend.appspot.com",
    messagingSenderId: "160410010735",
    appId: "1:160410010735:web:0499f3035607f92dd3b99a",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export default app;

export const canvasToBlob = (canvas) => {
    return new Promise(function (resolve) {
        canvas.toBlob(resolve);
    });
};

export const sendToast = (
    message,
    position = "bottom-right",
    autoClose = 2000
) => {
    toast(message, {
        position: position,
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        className: "!bg-[#0a0b109f] appBlueBorder",
        progressClassName: "!bg-[#3A57E8]",
        transition: Bounce,
    });
};


export const getUAIComponent = (_name) => {
    let match = {};
    for(var i = 0; i < uaiComponents.components.length; i++){
        // console.log("uaiComponents.components " , uaiComponents.components[i])
    }
const filtered = uaiComponents.components.filter(component => component.name == _name);
try{
    return filtered[0];

}
catch{
    return null;
}
}


export const uploadFileFiles = async (
    items,
    parentPath,
    metaData,
    useruid = "",
    uid = "",
    app = "stableV"
) => {
    if (uid == "") {
        uid = crypto.randomUUID();
    }
    let outURLS = [];
    for (let i = 0; i < items.length; i++) {
        let tempPath = "";
        let contentType_ = items[i].type;
        // let extension = contentType_.split("/")[1];

        tempPath = `${parentPath}/${items[i].name}`;
        const storageRef = ref(getStorage(), tempPath);
        await uploadBytes(storageRef, items[i]);
        // storageRef.makePublic();
        const downloadURL = await getDownloadURL(storageRef);

        outURLS.push({
            media: downloadURL,
            request: metaData,
            type: contentType_.split("/")[0],
        });
    }

    return outURLS;
};

export const setApp = (appName) => {
    localStorage.setItem("appName", appName);
};
export const getApp = () => {
    const text = localStorage.getItem("appName");
    try {
        return text;
    } catch {
        return "";
    }
};
export const getCurrentProject = () => {
    const text = localStorage.getItem("project");
    try {
        return JSON.parse(text);
    } catch {
        return {};
    }
};

export const downloadURL = async (url, filename) => {
    const fb = await fetch(url);
    const blob = await fb.blob();
    const url_ = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url_;
    a.setAttribute("download", filename);
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
};

export const downloadStringFileToCache = async (path) => {
    let downloadurl = "";
    if (path.includes("http")) {
        downloadurl = path;
    } else {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        downloadurl = await getDownloadURL(storageRef);
    }
    let jsonData = {};
    const req = await axios.get(downloadurl);
    jsonData = req.data;
    return jsonData;
    
};

export const getDownloadURLFromPath = async (path) => {
    let downloadurl = "";
  
        const storage = getStorage();
        const storageRef = ref(storage, path);
        downloadurl = await getDownloadURL(storageRef);
        // var match = downloadurl.match(/^.*(?=(\?alt=media))/);
    // return match[0];
    return downloadurl;
};


export const downloadFile = async (path, filename) => {
    let downloadurl = "";
    if (path.includes("http")) {
        downloadurl = path;
    } else {
        const storage = getStorage();
        const storageRef = ref(storage, path);
        downloadurl = await getDownloadURL(storageRef);
    }
    await downloadURL(downloadurl, filename);
};


export const getCurrentProjectID = () => {
    const text = localStorage.getItem("project");
    try {
        return JSON.parse(text).id;
    } catch {
        return "";
    }
};

export const openDirectoryInBrowser = async (
    path = "",
    useruid = "",
    projectid = "",
    app = "stableV"
) => {
    emit("filebrowser.setpath", {
        url: `${app}/${useruid}/${projectid}/${path}`,
    });
};

export const openAssetsImagesInBrowser = async (
    useruid = "",
    projectid = "",
    app = "stableV"
) => {};

export const uploadFiles = async (
    items,
    metaData,
    useruid = "",
    uid = "",
    app = "stableV",
    extension = "png",
    temp = true,
    forcePath = false
) => {
    const id = crypto.randomUUID();
    if (uid == "") {
        uid = crypto.randomUUID();
    }
    const today = new Date();
    let day = weekday[today.getDay()];
    let outputURLS = {
        media: [],
    };
    const storage = getStorage();

    for (let i = 0; i < items.length; i++) {
        let tempPath = "";
        tempPath = `${app}/${useruid}/${uid}/${id}_${i}.${extension}`;
        const storageRef = ref(storage, tempPath);
        await uploadString(storageRef, items[i], "base64");
        const downloadURL = await getDownloadURL(storageRef);
        outputURLS["media"].push({
            media: downloadURL,
            request: metaData,
            type: "image",
        });
    }

    return outputURLS["media"];
};

export const uploadJSONFile = async (
    item,
    path = "project.json",
) => {
    
    const storage = getStorage();
        const storageRef = ref(storage, path);
        await uploadString(storageRef, item);
        const downloadURL = await getDownloadURL(storageRef);
        console.log("uploadJSONFile: ", path);
        console.log("Download URL: ", downloadURL);
    return downloadURL;
};

export const uploadStringFile = async (
    item,
    useruid = "",
    projectid = "",
    app = "stableV",
    path = "project.json",
) => {
    const storage = getStorage();
        let tempPath = `${app}/${useruid}/${projectid}}/${path}`;
        const storageRef = ref(storage, tempPath);
        await uploadString(storageRef, item);
        const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
};

export const uploadMediaCatalog = async (
    items,
    metaData,
    useruid = "",
    uid = "",
    app = "stableV",
    extension = "png",
    temp = true
) => {
    let items_ = [];
    for (let i = 0; i < items.length; i++) {
        items_.push(items[i].media);
    }
    let outputURLS = await uploadFiles(items_, metaData);

    for (let i = 0; i < outputURLS.length; i++) {
        let item = items[i];
        const itemKeys = Object.keys(item);
        for (let j = 0; j < itemKeys.length; j++) {
            const key = itemKeys[j];
            if (key != "media") {
                outputURLS[i][key] = item[key];
            }
        }
    }
    return { media: outputURLS, id: uid };
};

export const uploadFile = async (item, metaData) => {
    const outputURLS = await uploadFiles([item], metaData);

    return outputURLS;
};

export const createSequence = async (
    project,
    name = "Untitled",
    thumbnail = "",
    comments = "",
    description = "",
    timeline = [],
    timelines = [],
    version = 1.0,
    settings = {
        scale: 5,
        scaleWidth: 160,
        startLeft: 20,
        fps: 24,
        in: 0,
        out: 20,
        render: {
            width: 1920,
            height: 1080,
            type: "video",
            codec: "h264",
            quality: 100,
            audio: true,
            audioCodec: "aac",
            audioQuality: 100,
            audioBitrate: 320,
            audioSampleRate: 44100,
            audioChannels: 2,
            audioVolume: 1.0,
            outputPath: "render/01/output.mp4",
        },
    }
) => {
    const newSeq = sequence();
    const outDict = {
        ...newSeq,
        project: project,
        name: name,
        thumbnail: thumbnail,
        comments: comments,
        description: description,
        timelines: timelines,
        timeline: timeline,
        version: version,
        settings: settings,
    };
    return outDict;
};

export const generateFolderChain = async (
    path,
    prefix = "",
    projectID = "",
    projectName = "Untitled"
) => {
    let split = [];
    let _path = path;
    let toSkip = "";
    if (path.includes(projectID)) {
        let pathSplit = path.split(projectID);
        let projPath = pathSplit[0];
        let toSkip_ = projPath.split("/");
        if (toSkip_.length > 0) {
            toSkip = toSkip_.join("/");
            toSkip = toSkip.substring(0, toSkip.length - 1);
        }
    }

    if (_path.includes("/")) {
        split = _path.split("/");
    } else {
        split = [_path];
    }

    let chain = [];
    let template = {
        id: prefix + "fullpath",
        name: "basename",
        isDir: true,
        openable: true,
    };
    for (let i = 0; i < split.length; i++) {
        const item = split[i];
        const fullpath = split.slice(0, i + 1).join("/");
        if (toSkip.includes(fullpath)) {
            continue;
        }
        let newTemplate = {
            ...template,
        };
        newTemplate.id = prefix + fullpath;
        newTemplate.name = item;
        if (projectID == item) {
            newTemplate.name = projectName;
        }
        chain.push(newTemplate);
    }

    return chain;
};



export const getCurrentUAIProjectFileDirectory =  () => {
    const user_ = JSON.parse(localStorage.getItem("user"));
    const project_ = JSON.parse(localStorage.getItem("project"));
    const appName = getApp();
    return `${appName}/${user_.id}/${project_.name}`;
}

export const getUAIProjectFileDirectory =  (projectName) => {
    const user_ = JSON.parse(localStorage.getItem("user"));
    const appName = getApp();
    return `${appName}/${user_.id}/${projectName}`;
}


export const findUserCurrentUAIProjectFilesInProject = async () => {

    const project_ = JSON.parse(localStorage.getItem("project"));
    const data = await findUserUAIProjectFilesInProject(project_.name);
    return data;
}

    export const findUserUAIProjectFilesInProject = async (projectName) => {
    const basePath = getUAIProjectFileDirectory(projectName);
    const storage = getStorage();
    const listRef = ref(storage, basePath);
    // Find all the prefixes and items.
    let resp = await listAll(listRef);
    let files = [];
    for(let i = 0; i < resp.items.length; i++){
        const item = resp.items[i];
        const extension = item.name.split(".").pop();
        if(extension == "uaipro" || extension == "uaiproj"){
            const metaData = await getMetadata(item);
            const updated = metaData.updated;
            const downloadURL = await getDownloadURL(item);
            files.push({downloadURL:downloadURL,updated:updated, name:item.name, path:item.fullPath });
        }
    }
    return files;
    // return await findFiles(tempPath, ["uaipro", "uaiproj"]);

}



export const findUAIProjectFilesInPath = async (basePath) => {

    return await findFiles(basePath, ["uaipro", "uaiproj"]);

}


export const findFilesByExtension = async (basePath, extensions=[]) => {

    return await findFiles(basePath, extensions);

}

export const findFiles = async (basePath, extensions=[]) => {

    const storage = getStorage();
    const listRef = ref(storage, basePath);
    // Find all the prefixes and items.
    let resp = await listAll(listRef);
    let files = [];
    let folders = [];

    // files = [...resp.items];
    for (let i = 0; i < resp.items.length; i++) {
        const item = resp.items[i];
        const extension = item.name.split(".").pop();
        if (extensions.includes(extension)) {
            files.push(item);
        }
    }
    folders = [...resp.prefixes];
    for (let i = 0; i < resp.prefixes.length; i++) {
        const folder = resp.prefixes[i];
        const { newFiles2, newFolders2 } = await findFiles(folder.fullPath, extensions);
        if(newFiles2){
        files = [...files, ...newFiles2];
        }if(newFolders2){
        folders = [...folders, ...newFolders2];
    }
    }
    return {files, folders};

}
export const listStorageFiles = async (path) => {
    const storage = getStorage();
    // Create a reference under which you want to list
    const listRef = ref(storage, path);
    // Find all the prefixes and items.
    let resp = await listAll(listRef);
    if (resp.items.length === 0 && resp.prefixes.length === 0) {
        const storageRef = ref(storage, path + "/ini.meta");
        await uploadString(storageRef, "init");
        resp = await listAll(listRef);
    }
    let fileMap_ = {};
    let newFolders = [];
    let newItems = [];
    let filesOut = [];
    newFolders.concat(resp.prefixes);
    newItems.concat(resp.items);
    const folders = resp.prefixes;
    const hiddenFiles = ["meta", "pkf", "uaicache"];

    for (let i = 0; i < resp.items.length; i++) {
        const item = resp.items[i];

        const storageRef = ref(storage, item.fullPath);
        const meta = await getMetadata(storageRef);
        const newItem = {
            id: item.fullPath,
            name: item.name,
            isDir: false,
            isHidden: hiddenFiles.includes(item.name.split(".").pop()),
            size: meta.size,
            modDate: meta.updated,
            parentId: path,
        };
        filesOut.push(newItem);
        fileMap_[item.fullPath] = newItem;
        // Your code here
    }
    let lastParent = path;

    for (let i = 0; i < folders.length; i++) {
        const folder = folders[i];
        // console.log("Folder fullPath: ", folder.fullPath);
        // const {f, it, fo, fm} = await listStorageFiles(folder.fullPath);
        // console.log("Folder: ",folder,  f, it, fo, fm); if(f === undefined){
        // continue; } const childIDS = fo.map((item) => { return item.id; });

        const newItem = {
            id: folder.fullPath,
            name: folder.name,
            isDir: true,
            isHidden: false,
            size: 0,
            modDate: "2020-10-20T03:11:50.570Z",
            parentId: lastParent,
            childrenIds: [],
            childrenCount: 0,
        };
        // console.log("New Item: ", newItem);
        filesOut.push(newItem);
        // lastParent = f.fullPath; const fmKeys = Object.keys(fm); for (let j = 0; j <
        // fmKeys.length; j++) {     fileMap_[fmKeys[j]] = fm[fmKeys[j]]; }
        // filesOut.concat(newItem); newFolders.concat(f); newItems.concat(it);
    }

    return { newFolders, newItems, filesOut, fileMap_ };
};

const getStorageFilesRecursive = async (path) => {
    const storage = getStorage();
    // Create a reference under which you want to list
    const listRef = ref(storage, path);
    // Find all the prefixes and items.
    let resp = await listAll(listRef);
    let files = [];
    let folders = [];
    console.log(resp);
    files = [...resp.items];
    folders = [...resp.prefixes];
    console.log(files, folders);

    for (let i = 0; i < resp.prefixes.length; i++) {
        const folder = resp.prefixes[i];
        const { newFiles2, newFolders2 } = await getStorageFilesRecursive(
            folder.fullPath
        );
        try {
            files = [...newFiles2];
        } catch {}
        try {
            folders = [...newFolders2];
        } catch {}
    }

    console.log(files, folders);
    return { files, folders };
};

const deleteStorageFiles = async (files: CustomFileData[]) => {
    const storage = getStorage();
    files.forEach(async (file) => {
        if (typeof file === "string") {
            const fileRef = ref(storage, file);
            await deleteObject(fileRef);
        } else {
            try {
                let fileRef = null;

                if (file.isDir) {
                    const { files, folders } = await getStorageFilesRecursive(
                        file.id
                    );

                    for (let i = 0; i < files.length; i++) {
                        const file_ = files[i];
                        // fileRef = ref(storage, file_.fullPath);
                        console.log("Deleting File 0: ", file_);
                        await deleteObject(file_);
                    }
                    return;
                } else {
                    fileRef = ref(storage, file.id);
                }
                console.log("Deleting File 0.5: ", file);

                await deleteObject(fileRef);
            } catch {
                console.log("Deleting File 1: ", file);
                const fileRef = ref(storage, file.id);
                if (file.id.includes(".")) {
                    await deleteObject(fileRef);
                } else {
                    const { files, folders } = await getStorageFilesRecursive(
                        file.id
                    );

                    for (let i = 0; i < files.length; i++) {
                        const file_ = files[i];
                        // fileRef = ref(storage, file_.fullPath);
                        console.log("Deleting File 1.5: ", file_);
                        await deleteObject(file_);
                    }
                }
            }
        }
    });
};

export const getUserProjectsByStorage = async () => {
    const user_ = JSON.parse(localStorage.getItem("user"));
    const appName = getApp();
    const tempPath = `${appName}/${user_.id}`;

    const storage = getStorage();
    const listRef = ref(storage, tempPath);
    let resp = await listAll(listRef);
    let projects = [];
    for (let i = 0; i < resp.prefixes.length; i++) {
        const project_ = resp.prefixes[i];
        let proj = await getProject(project_.name);
        if (proj == null) {
            // await deleteStorageFiles([
            //     { id: project_.fullPath, isDir: true, reference: project_ },
            // ]);
        } else {
            projects.push({
                id: project_.name,
                name: proj.project.name,
                metadata: proj.project,
            });
        }
    }
    console.log('projects :', projects);
    return projects;
};

export const deleteProject = async (project, onFinished = ()=>{}) => {

    
    const user_ = JSON.parse(localStorage.getItem("user"));
    const appName = getApp();
    const tempPath = `${appName}/${user_.id}/${project.id}`;
    const storage = getStorage();
    const project_ = ref(storage, tempPath);
    sendToast("Deleting Project Files: " + project.name);
    await deleteStorageFiles([
        { id: tempPath, isDir: true, reference: project_ },
    ]);
    sendToast("Deleting Project DB Entry: " + project.name);

    const db = await getFirestore(app);
    const docRef = doc(db, "projects", project.id);
    await deleteDoc(docRef);
    sendToast("Project Deleted: " + project.name);
    onFinished();

}

export const printStatus = (message) => {
    emit("print", { data: message });
};

export const auth = getAuth(app);

export const createNewUser = async (
    email,
    provider,
    phone = "5551234567",
    picture = "https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/UAIUserBig" +
        ".jpg"
) => {
    const start = Date.now();
    const startDateTime = new Date(start).toISOString();
    const newUser = {
        id: await getNextUserID(),
        username: email.split("@")[0],
        email: email,
        provider: provider,
        confirmed: false,
        blocked: false,
        role: {
            id: 1,
            name: "Authenticated",
            description: "Default role given to authenticated user.",
            type: "authenticated",
        },
        created_at: startDateTime,
        updated_at: startDateTime,
        usertype: "Trial",
        industry: "Stuff",
        paymenttype: "trial",
        avatar: picture,
        phone: phone,
        stripeId: "NA",
        sub: "NA",
        allowNSFW: false,
        tokens: [],
        apiusage: [],
        licenses: [],
        triggeredwarnings: [],
        argeements: [],
        playlists: [],
        fav_playlists: [],
        upvoted_playlists: [],
        upvoted_media: [],
        fav_media: [],
        issues: [],
        organizations: [],
    };
    const db = await getFirestore(app);
    const collection_ = collection(db, "users");
    collection_.doc(newUser.id.toString()).set(newUser);
    return newUser;
};

export const updateProject = async (id) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "projects");
    const docRef = doc(collection_, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const dict = docSnap.data();
        dict["id"] = id;
        return dict;
    } else {
        return null;
    }
};

export const getProject = async (id) => {

    const db = await getFirestore(app);
    const collection_ = collection(db, "projects");
    const docRef = doc(collection_, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const dict = docSnap.data();

        dict["id"] = id;
    const d = await findUserUAIProjectFilesInProject(id);

        if(d.length > 0){
            const newest = d.pop();
            const loadedProject = await downloadStringFileToCache(newest.downloadURL);
            // return loadedProject;
            
        return {project:loadedProject, path:newest.path,  name:newest.name };

        }
        return {project:dict, path:"", name:dict.name};
    } else {
        return null;
    }
};

export const getUAIProjectFiles = async (id) => {
    let outputFiles = [];
    const matchingExtension = ["uaipro", "uaiproj", "uaiprojmeta"];
    const basePath =  `${getApp()}/${id}`;
    const { filesOut, fileMap_ } = await listStorageFiles(basePath);
    return 
};

export const loadProject = async (proj = undefined, manager = undefined, setLoading=(e)=>{}, setProject=(e)=>{}, setNoProject=(e)=>{},) => {
        
    var store = localforage.createInstance({
        name: "project"
      });

    let project_json = proj;

    setLoading(true);
    if (proj == undefined) {


        const project_ = localStorage.getItem('project');


        if (!project_) {
            setLoading(false);
            setProject(null);
            setNoProject(true);
            return null;
        }

        const _project_json = JSON.parse(project_);
        project_json = _project_json;
        console.log(_project_json);
        // setSequences(_project_json.sequences);


        project_json =await store.getItem(_project_json.id);

        if(project_json.sequences.length > 0){
            if(manager){
        manager.props.projectData.sequences = project_json.sequences;
        // manager.props.projectData.sequence = _project_json.sequences[0];
        }else{
            emit("project.setSequences", {sequences: project_json.sequences});
        }
    }
        // 

    }
    // console.log("loadProject 1: ", project_json);
    const content_ = await getProject(project_json.id);
    // console.log("loadProject 2: ", content_);
    setProject(content_.project);
    setNoProject(false);
    return content_.project;
};
export const getProjectByID = async (id) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "projects");
    const docRef = doc(collection_, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const dict = docSnap.data();

        dict["id"] = id;
        return dict;
    } else {
        return null;
    }
};

export const getUserProjects = async (userID) => {
    const userInfo = await getUser(userID);
    const projects = [];
    const outputProjects = [];
    try {
        projects = userInfo.projects;
    } catch {}
    for (let i = 0; i < projects.length; i++) {
        const project = await getProject(projects[i]);
        outputProjects.push(project.project);
    }

    return outputProjects;
};
export const generateBaseScene = async (path) => {
    const baseScene_ = baseScene(path);
    const storage = getStorage();
    for (let i = 0; i < baseScene_.length; i++) {
        console.log("Uploading: ", baseScene_[i].id);
        const item = baseScene_[i];
        const itemRef = ref(storage, item.id);
        await uploadString(itemRef, "init");
    }
};

export const createProject = async (
    user,
    id = -1,
    app_ = "stableV",
    name = "Untitled",
    projectType = "multi",
    description = "",
    tags = [],
    thumbnail = "",
    visibility = "Private",
    metadata = {}
) => {
    if (id == -1) {
        id = crypto.randomUUID();
    }
    console.log("Creating Project 3");

    const db = getFirestore(app);

    const date = new Date();
    const newDict = {
        comments: [],
        description: description,
        likes: 0,
        media: [],
        metadata: metadata,
        name: name,
        projectType: projectType,
        saves: 0,
        servers: [],
        sequences: [],
        tags: tags,
        thumbnail: thumbnail,
        users: [user.toString()],
        visibility: visibility,
        created_at: Timestamp.fromDate(date),
        updated_at: Timestamp.fromDate(date),
    };
    const collection_ = collection(db, "projects");
    console.log("Creating Project 4");
    const docRef = doc(collection_, id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
    }
    await setDoc(docRef, newDict);

    console.log("Creating Project 5");
    newDict["id"] = id;
    localStorage.setItem("project", JSON.stringify(newDict));
    const outPath = `${app_}/${user}/${id}/${name.replace(" ", "_")}_0001.uaipro`;
    await generateBaseScene(`${app_}/${user}/${id}`);
    await uploadJSONFile( JSON.stringify(newDict), `${app_}/${user}/${id}/${name.replace(" ", "_")}_0001.uaipro`);
    localStorage.setItem("projectPath", outPath);
    return newDict;
};

export const saveProjectFile = async (
    user,
    id = -1,
    app_ = "stableV",
    name = "Untitled",
    version = "0001",
    data = "",
)  => {
    const newPath = `${app_}/${user}/${id}/${name.replace(" ", "_")}_${version}.uaipro`;
    await uploadJSONFile( JSON.stringify(data), newPath);
    return newPath;
}
export     const createDictionary = (component) => {
    // [0].subwindows[0].fields[0].name
    let dictionary = { 
    };
    for (let i = 0; i < component.length; i++) {
        dictionary[component[i].uuid] ={ uuid:component[i].uuid, name:component[i].name, subwindows:[]};
        for (let j = 0; j < component[i].subwindows.length; j++) {
            let subWindow = { uuid:component[i].subwindows[j].uuid, name:component[i].subwindows[j].name, fields:[]};
            for (let k = 0; k < component[i].subwindows[j].fields.length; k++) {
                subWindow.fields.push( {
                    uuid:component[i].subwindows[j].fields[k].uuid,
                    name:component[i].subwindows[j].fields[k].name,
                    value:component[i].subwindows[j].fields[k].value
                });
            }
            dictionary[component[i].uuid].subwindows.push(subWindow);

        }
    }
    return dictionary;
}

export const updateProjectByID = async (id, data = {}) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "projects");
    collection_.doc(id).set(data);
    localStorage.setItem("project", JSON.stringify(data));
    return data;
};

export const createMediaContent = async (
    media,
    id,
    user,
    app_ = "stableV",
    temp = true,
    metadata = {}
) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "media");

    const date = new Date();
    var endDate = new Date(date || Date.now());
    var days = 7;
    endDate.setDate(endDate.getDate() + days);
    const newDict = {
        user: {
            id: user,
        },
        app: app_,
        temp: temp,
        media: media,
        metadata: metadata,
        created_at: Timestamp.fromDate(date),
        updated_at: Timestamp.fromDate(date),
        expires_at: Timestamp.fromDate(endDate),
        visibility: "unlisted",
        likes: 0,
        shares: 0,
        views: 0,
        remix: false,
        tags: [],
        comments: [],
        collections: [],
        remixes: [],
    };
    collection_.doc(id).set(newDict);
    newDict["id"] = id;
    return newDict;
};

export const getUserMediaContent = async (user) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "media");
    const querySnapshot = await getDocs(collection_);
    const items = [];
    for (var i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i];
        const item = doc.data();
        if (item.user.id == user) {
            items.push(item);
        }
    }
    return items;
};

export const getMediaContent = async (id) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "media");
    const querySnapshot = await getDocs(collection_);
    const items = [];
    for (var i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i];
        const item = doc.data();
        if (item.id == id) {
            items.push(item);
        }
    }
    return items;
};

export const getNextUserID = async () => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "users");
    const items = collection_;
    const querySnapshot = await getDocs(items);
    const count = querySnapshot.size;
    return count + 1;
};

export const getProducts = async (showInShop = false) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "dlcs");
    const querySnapshot = await getDocs(collection_);
    const items = [];
    for (var i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i];
        const item = doc.data();
        if (showInShop) {
            if (item.showInShop) {
                items.push(item);
            }
        } else {
            items.push(item);
        }
    }
    return items;
};

export const getCollection = async (collectionName) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, collectionName);
    const querySnapshot = await getDocs(collection_);
    const items = [];
    for (var i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i];
        const item = doc.data();
        items.push(item);
    }
    return items;
};

export const getUserByID = async (user) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "users");
    const docRef = doc(collection_, user.toString());
    const docSnap = await getDoc(docRef);
    // console.log(user);
    // console.log(docSnap);
    if (docSnap.exists()) {
        // console.log(docSnap.exists());

        const dict = docSnap.data();
        dict["id"] = user;
        return dict;
    } else {
        return null;
    }
};

export const getUser = async (user) => {
    const db = await getFirestore(app);
    const collection_ = collection(db, "users");
    const items = collection_;
    const querySnapshot = await getDocs(items);
    const count = querySnapshot.size;
    console.log(count);
    let matched = false;
    let match = {};
    for (var i = 0; i < querySnapshot.docs.length; i++) {
        const doc = querySnapshot.docs[i];
        const item = doc.data();
        if (item.email === user.email) {
            match = item;
            matched = true;
            break;
        }
    }
    if (matched) {
        return match;
    } else {
        const newUser = await createNewUser(
            user.email,
            "google",
            user.providerData[0].phoneNumber,
            user.photoURL
        );
        return newUser;
    }
};
