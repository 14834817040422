/* eslint-disable max-len */
import axios from 'axios';
import { getFrontendJWT } from './auth';
import computeSHA256 from '../utils/crypto';

export async function registerLicensesFromCart(cart_) {
    const endpoint = `${process.env.REACT_APP_API}/apiv1/user/register/cart/license`;
    // const endpoint = `${uaiHost}/apiv1/user/register/cart/license`;
    // console.log('cart_', cart_);
    // console.log(endpoint);
    const result = await axios
        .post(endpoint, JSON.stringify({
            cart: cart_,
        }), {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}


export async function updateLicensesPurchaseOrder(licenses, purchaseOrder, uaiHost) {
    let indxx = 0;
    const jwt = await getFrontendJWT();
    const outarr = [];
    licenses.forEach(async (license) => {
    const endpoint = `${uaiHost}/licenses/${license.id.toString()}`;
    const result = await axios
        .put(endpoint, JSON.stringify(
            { id: license.id, purchase_order: purchaseOrder },
            ), {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${jwt}`,

            },
        }).catch(err => (err));
        // console.log(endpoint);
        // console.log('Updated Licenses', result);
        outarr.push(result.data);
        indxx += 1;
      });
    try {
        return await outarr;
    } catch {
        return false;
    }
}

export async function getLicenseTypes() {
    const endpoint = `${process.env.REACT_APP_API }/license-types/list`;
    const result = await axios
        .get(endpoint, {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}
export async function getUserSubscriptions(userID) {
    const endpoint = `${process.env.REACT_APP_API }/apiv1/user/getSubscriptions`;
    const result = await axios
        .post(endpoint, JSON.stringify({
            customerID: userID,
        }), {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => (err));
    try {
        const data = await result.data;
        return data;
    } catch {
        return false;
    }
}
export async function getMatchingCoupon(couponCode) {
    const endpoint = `${process.env.REACT_APP_API }/apiv1/products/coupon/match`;
    const result = await axios
        .post(endpoint, JSON.stringify({
            code: couponCode,
        }), {
            headers: {
                'content-type': 'application/json',
            },
        }).catch(err => (err));
    try {
        const data = await result.data;
        const filteredAta = data.filter(item => item.code === couponCode);
        return filteredAta;
    } catch {
        return false;
    }
}

export async function getUserLicenses(userID, jwt_) {
    const endpoint = `${process.env.REACT_APP_API}/apiv1/user/licenses`;
    const result = await axios
        .post(endpoint, 
            { user: userID, jwt: jwt_ },
             {
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${jwt_}`,
            },
        }).catch(err => (err));
    try {
        return await result;
    } catch {
        return false;
    }
}

export const createPlaceholderLicense = (userInput, dlcInput, currentPlan, licensetypeInput, sharingtypeInput, priceInput) => {
    const today = new Date();
    const userr = userInput;
    // remove mediaContent from userr
    // if (userr.mediaContent) {
    //     delete userr.stripeLicenses;
    //     delete userr.licenses;
    //     delete userr.mediaContent;
    // }

return ({
    id: 29,
    published_at: today,
    created_at: today,
    updated_at: today,
    key: 'GENERATE',
    plan: currentPlan,
    dlc: dlcInput,
    user: userInput,
    usersubscribed: 0,
    userdeleted: false,
    renewed: today,
    expires: today,
    machinesInfo: null,
    sharing: sharingtypeInput,
    activated: '0',
    purchaseprice: priceInput,
    transfercount: '0',
    originaluser: userInput,
    licensetype: licensetypeInput,
    purchase_order: null,
    machines: [],
});
};


export const templatelicense = {
    id: 29,
    uid: null,
    published_at: '2022-05-16T19:13:17.015Z',
    created_at: '2022-05-16T19:13:17.024Z',
    updated_at: '2022-05-25T18:12:22.358Z',
    key: 'vI0CTkhpjdVnYK8SIVsaOZMxFgmDpGmUlnGtBW2Owxk7',
    dlc: {
        id: 3,
        name: 'MediaPipe',
        Description: 'Usage of MediaPipe',
        card: {
            id: 37,
            Form: false,
            Name: 'MediaPipe: Holistic | Mocap',
            Tier: 0,
            Usage: 'Motion Capture | Medical Analysis | Compositing | Puppetry | Simulation',
            Website: 'https://google.github.io/mediapipe/solutions/pose',
            ImagePath: 'aitype/placeholder.jpg',
            NodeEditor: false,
            TierReason: 'Licensing',
            Description: 'Generate a sequence of obj files with data representing human joints along with a Facemesh and Hand tracking',
            DownloadSize: 1,
        },
        published_at: '2022-05-15T04:23:09.135Z',
        created_at: '2022-05-15T04:23:04.808Z',
        updated_at: '2022-05-25T18:57:06.542Z',
        image: 'https://uai-web-bucket-2201.s3.amazonaws.com/dashsite/images/defaults/dlc/FaceMesh_Sq.jpg',
        category: 'Creative',
        pricemsrp: 10.99,
        pricecurrent: 10.99,
        delivery: 'download',
        shortdescription: 'Usage of MediaPipe',
        licensetype: 4,
        showInShop: false,
    },
    user: {
        id: 1,
        username: 'johndoe',
        email: 'johndoe@media.com',
        provider: 'local',
        confirmed: true,
        blocked: false,
        role: 1,
        created_at: '2022-05-15T03:53:22.005Z',
        updated_at: '2022-05-19T04:49:53.428Z',
        usertype: 'Commercial',
        industry: 'Dev',
        paymenttype: 'dev',
        avatar: 'https://images.unsplash.com/photo-1652874916629-ea0810ff490d?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=927',
        phone: null,
    },
    usersubscribed: 0,
    userdeleted: false,
    renewed: '2022-05-28T16:00:00.000Z',
    expires: '2027-05-28T16:00:00.000Z',
    machinesInfo: null,
    sharing: 'Single',
    activated: '1',
    purchaseprice: 20,
    transfercount: '0',
    originaluser: {
        id: 1,
        username: 'johndoe',
        email: 'johndoe@media.com',
        provider: 'local',
        confirmed: true,
        blocked: false,
        role: 1,
        created_at: '2022-05-15T03:53:22.005Z',
        updated_at: '2022-05-19T04:49:53.428Z',
        usertype: 'Commercial',
        industry: 'Dev',
        paymenttype: 'dev',
        avatar: 'https://images.unsplash.com/photo-1652874916629-ea0810ff490d?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=927',
        phone: null,
    },
    licensetype: {
        id: 1,
        name: 'trial',
        days: '15',
        published_at: '2022-05-16T19:35:56.525Z',
        created_at: '2022-05-16T19:35:54.301Z',
        updated_at: '2022-05-16T19:35:56.550Z',
    },
    purchase_order: {
        id: 2,
        buyer: 1,
        price: 512.99,
        Notes: null,
        refunded: false,
        refundreason: '-',
        orderid: '100049998',
        published_at: '2022-05-25T18:12:25.025Z',
        created_at: '2022-05-25T18:12:22.343Z',
        updated_at: '2022-05-25T18:12:25.062Z',
        notes: '-',
        ip: '35.122.1.5.6',
        name: 'order100049998',
    },
    machines: [
        {
            id: 1,
            name: 'Staging',
            ip: '0.0.0.0.0',
            description: 'Staging',
            published_at: '2022-05-16T21:04:11.094Z',
            created_at: '2022-05-16T19:10:09.434Z',
            updated_at: '2022-05-16T21:04:11.125Z',
            lastseen: '2022-05-16T16:00:00.000Z',
            toremove: false,
            locale: 'New York',
        },
    ],
};

export default getLicenseTypes;


