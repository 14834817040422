import React , {useState} from 'react';
import PropTypes from 'prop-types';

import {  Scrollbar, A11y   } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import { Link } from 'react-router-dom';
import CardModal from '../layouts/CardModal';

Banner05.propTypes = {
    
};

function Banner05(props) {
    const {data} = props;

    const [modalShow, setModalShow] = useState(false);
    return (
        <section className="tf-slider">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-123">
                        
                        {
                            data.map(idx => (
                                    <div class="slider-item">


                                        <div class="tf-slider-item style-5 bg-transparent">
                                            <div class="content-inner">
                                               
                                                <h1 class="heading">
                                                   Boundless <span>Creativity </span>  
                                                </h1>
                                                <p class="sub-heading !font-normal">{idx.desc}</p>
                                                <div class="btn-slider ">
                                                    <Link to="/docs-uaitoolkit" class="tf-button">Explore</Link>
                                                    <Link to="/docs-uaitoolkit" class="tf-button style-2">Create</Link>
                                                </div>
                                            </div>
                                            
                                        </div>


                                        
                                    </div>
                                
                            ))
                        }

                            
                        </div>
                    </div>
                </div>

                <CardModal 
                show={modalShow}
                onHide={() => setModalShow(false)} 
            />
            </section>
    );
}

export default Banner05;