import { useState, useEffect } from 'react';
import { useSignals } from "./signalSlots";
import { getUserByID } from "./firebaseHandler";
import { useSignal, useSlot } from 'react-signal-slot';
import { useSignal as us } from '../components/UAIAPP/api/useSignalSlots';


export function useUser() {
    const [user, setUser] = useState(null);
    const [userDB, setUserDB] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tokens, setTokens] = useState(0);
    const signal = useSignal();
    const signalB = us();
    useEffect(() => {
        refreshUser();
        setLoading(true);
    }, []);

    const refreshUser = async () => {
        const key_ = localStorage.getItem('user');
        if (key_) {
            const parsed = JSON.parse(key_);
            setUser(parsed);
            await getUserData(parsed);
        }
    };

    const getUserData = async (user_ = undefined) => {

        const user__ = await getUserByID(user_ != undefined ? user_.id : user.id);
        if (user__ != null) {
            setTokens(user__.tokens);
            // console.log("User Tokens: ", user__);
            signal("refresh.token", 100);
            signalB("refresh.token", 100);
        }
        setUserDB(user__);
    };

    return { user, loading, userDB, tokens, getUserData, refreshUser };
}
