import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import avt1 from '../assets/images/author/author-login-1.png';
import avt2 from '../assets/images/author/author-login-2.png';
import {auth,  getUser} from '../uai/api/firebaseHandler';
import {useAuth} from '../uai/api/useAuth';
import { signInWithPopup, GoogleAuthProvider, signOut, GithubAuthProvider, FacebookAuthProvider  } from "firebase/auth";
import { useState, useEffect } from 'react';
import {  GetUserMediaContents } from '../uai/api/mediaContent';
import {setStrapiUser, setUserMediaContent, setMediaCollections, setAppState} from '../reducers/cartReducer';
import { useDispatch, useSelector } from 'react-redux';
import {MediaContentToPlaylists, baseCollection} from '../uai/utils/common';

import { checkUser } from '../uai/api/auth';

const Login = ({title="Log In"}) => {
    const { pending:isLoading, isSignedIn,user:user, auth:auth_ } = useAuth()
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const checkProviderUser = async (user) => {
        let newState = state.appState;
        const userResp = await checkUser(user);
        let userExists = userResp.data;
        console.log(userExists);
        newState.strapiUser = userExists;
        newState.jwt = userExists.jwt;
        dispatch(setStrapiUser(userExists));
        const Projects = await GetUserMediaContents(userExists.id, userExists.jwt);
        dispatch(setUserMediaContent(Projects));
        newState.mediaContent = Projects;
        userExists.mediaContent = Projects;
        localStorage.setItem('user', JSON.stringify(userExists));
        const collections = MediaContentToPlaylists(state);
        newState.collections = collections;
        dispatch(setMediaCollections(collections));
        console.log('collections', collections);
        console.log('Projects', Projects);
        userExists.collections = collections;
        console.log('newState', newState);
        dispatch(setAppState(newState));
        localStorage.setItem('user', JSON.stringify(userExists));
        window.location.href = '/dashboard';

    }

    

    const fbClicked = async (e) => {
        e.preventDefault();
        const provider = new FacebookAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        await checkProviderUser(user);
    }

    const googleClicked = async (e) => {
        e.preventDefault();
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        await checkProviderUser(user);
    }

    const githubClicked = async (e) => {
        e.preventDefault();
        const provider = new GithubAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const credential = GithubAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        await checkProviderUser(user);
    }

    useEffect(() => {

        console.log(user);
    }, [user])




    const logoutClicked = async (e) => {
        e.preventDefault();
        window.location.href = '/logout';
    }


    return (
        <div>

<section className="tf-page-title style-2">    
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">

                            <ul className="breadcrumbs">
                                <li><Link to="/blog-v2">Home</Link></li>
                                <li>{isSignedIn ==false ? 'Sign In' : 'Accounts' }</li>
                            </ul>
                   
                        </div>
                    </div>
                </div>                    
            </section>
                
            <section className="tf-login">
                <div className="tf-container border !border-[1px solid #dee2e62e] p-10 !w-fit">
                    <div className="row justify-content-center">
                        
                        <div className="col-md-12">
                            <div className="tf-heading style-5">
                                
                                <h4 className="heading">{isSignedIn ==false ? title : 'Accounts' }</h4>
                                <p className="subheading text-lg">Please Login with your prefered provider to continue</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-8 col-md-12 !py-0 !my-0">

                        {/* {isSignedIn  ? 
                        <div className="tf-account-wrap place-content-center">
                                <div className="tf-account">
                                    <div className="button-close" onClick={logoutClicked}><i className="fas fa-times"></i></div>
                                    <div className="image">
                                        <img src={user.photoURL} alt="Binasea" />
                                    </div>
                                    <h6 className="name"><Link to="/dashboard">{user.displayName}</Link></h6>
                                </div>
                            </div>

                            : <></> } */}
                            
                        {/* {isSignedIn ==false ?  */}
                            <form action="#" id="contactform" className=' !flex flex-row gap-4 m-auto place-content-center'>
                                {/* <div className="button-gg"><Link to="#" ><i className="fab fa-facebook"></i>Facebook</Link></div> */}
                                
                                <div className="button-gg  w-fit mb-0 text-nowrap "><Link to="#" className=" !p-10 !py-5 " onClick={googleClicked} ><i className="fab fa-google"></i>Google</Link>
                                </div>

                                
                                <div className="button-gg w-fit mb-0 text-nowrap"><Link to="#" className=" !p-10 !py-5 " onClick={githubClicked} ><i className="fab fa-github"></i>Github</Link>
                                </div>

{/*                                 
                                <div className="button-gg w-fit mb-0 text-nowrap"><Link to="#" className=" !p-10 !py-5 " onClick={fbClicked} ><i className="fab fa-facebook"></i>Meta</Link>
                                </div> */}


                            </form>
                                {/* :
                                 <div className="button-gg w-fit mb-0 "><Link to="#" onClick={logoutClicked} ><i className="fa fa-sign-out"></i>LogOut</Link>
                                </div>
                                 } */}

                        
                            
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    );
}

export default Login;