import useRuntime from "../api/useRuntime";
import { useEffect, useState} from "react";
import {useAppManager} from "../../../api/appManager";

import ReactJson from 'react-json-view'
const DebugState = ({extra={}}) => {
    const manager = useAppManager();
    const projectState = useRuntime((state) => state);
    const [updatedProps, setUpdatedProps] = useState({});
    useEffect(() => {

        setUpdatedProps({
        appName: manager.appName,
        uaiProject:{
            // chonkyFiles: manager.props.uaiProject ? manager.props.uaiProject.chonkyFiles : [],
            projectPath: manager.props.uaiProject ? manager.props.uaiProject.projectPath : "",
            // timelineUAIProject: manager.props.uaiProject ? manager.props.uaiProject.timeline : [],
            mediaCatalogs: manager.props.uaiProject ? manager.props.uaiProject.mediaCatalogs :[],
            currentCatalog: manager.props.uaiProject ? manager.props.uaiProject.currentCatalog : {},
    
            mode: manager.props.uaiProject ? manager.props.uaiProject.mode : "",
            chonkyFiles: manager.props.uaiProject ? manager.props.uaiProject.chonkyFiles : [],
    
    
            isTimeline: manager.props.uaiProject ? manager.props.uaiProject.previewMode == "timeline" : false,
            previewMode: manager.props.uaiProject ? manager.props.uaiProject.previewMode : "",
            focusedPanel: "",
            operations: [],
            finishedOperations: [],
            },
        // user: manager.props.uaiProject ? manager.props.uaiProject.user : {},
        ...extra
        
            });
            } ,[ manager.props]);

    return (
        <ReactJson src={{
            project:{
                ...projectState
            },
            ... updatedProps
        }} theme="google" displayDataTypes={false} style={{backgroundColor: "transparent"}}/>
    )

}
export default DebugState;