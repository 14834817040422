
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, Button } from "@material-tailwind/react";
import { useEffect } from 'react';
import { useState } from 'react';
import { useSignals } from '../../../api/signalSlots';
import { faPlay, faPause, faFastBackward, faFastForward , faSave, faFloppyDisk,
  faMagnifyingGlassPlus, faMagnifyingGlassMinus

} from "@fortawesome/free-solid-svg-icons";
import { useSignal, useSlot } from 'react-signal-slot';

const PlayerButtonBar = ({manager=undefined, key_="timeline",playing=false, setPlaying = (e)=>{}, FBack = (e)=>{}, FForward = (e)=>{}}) =>{
  const [paused, setPaused] = useState(false);
  const [stopped, setStopped] = useState(false);
  const isTimeline = key_ == "timeline" ? true : false;
  const signal = useSignal();
  // const isTimeline = manager.props.isTimeline;
  const appendText = isTimeline ? ".timeline" : "";
  const buttonStyle = ` ${ !isTimeline ?  manager.props.mode == "video" ?  'text-white' : 'text-gray-600' : 'text-white'}`;
  const disabled = !isTimeline ? manager.props.mode == "video" ?  false : true : false;
const togglePlayMode = () =>{

  if(playing){
    setPlaying(false);
  }
  else{
    setPlaying(true);
  }
}
useEffect (()=>{
},[manager]);

return(
    <div className="Buttontoolbar w-[575px] h-5 pr-[11px] justify-center items-center gap-3.5 inline-flex">



  <ButtonGroup>

    
  <Button disabled={disabled}  className={`!w-fit !h-fit    p-[5px] rounded-none bg-transparent cursor-pointer z-50`} onClick={()=>{
          // FBack();
      
          signal("ffBack.player" + appendText, {key: key_});
      }}>
  <FontAwesomeIcon   className={`pr-3 ${buttonStyle}`} icon={faFastBackward}/>
  </Button>
  

  <Button  disabled={disabled}  className={`!w-fit !h-fit    p-[5px] rounded-none bg-transparent cursor-pointer z-50 `} onClick={()=>{
    
  
    if(playing){
      setPaused(true);
      setStopped(false);
      setPlaying(false);
      signal('pause.player' + appendText);
    }else{
      setPaused(false);
      setStopped(false);
      setPlaying(true);
      signal('play.player' + appendText);
    }
    }}>
      
  {!playing ? 
  <FontAwesomeIcon  className={`pr-3 ${buttonStyle}`} icon={faPlay}/>
  :  
  <FontAwesomeIcon  className={`pr-3 ${buttonStyle}`} icon={faPause}/>
}
</Button>


    
<Button  disabled={disabled}  className={`!w-fit !h-fit    p-[5px] rounded-none bg-transparent cursor-pointer z-50`} onClick={()=>{
// FForward();


signal('ffForward.player' + appendText, {key: key_});
      }}>


<FontAwesomeIcon   className={`pr-3 ${buttonStyle}`} icon={faFastForward}/>

</Button>

  
<Button   className={`!w-fit !h-fit    p-[5px] rounded-none bg-transparent `} onClick={()=>{
  signal('save.player');
      }}>

<FontAwesomeIcon  className='pr-3 text-white' icon={faFloppyDisk}/>

</Button>


{/* <Button   className={`!w-fit !h-fit    p-[5px] rounded-none bg-transparent `} onClick={()=>{
  signal('zoom.out.player', {key: key});
      }}>

<FontAwesomeIcon  className='pr-3 text-white' icon={faMagnifyingGlassPlus}/>

</Button>
<Button   className={`!w-fit !h-fit    p-[5px] rounded-none bg-transparent `} onClick={()=>{
  signal('zoom.in.player', {key: key});
      }}>

<FontAwesomeIcon  className='pr-3 text-white' icon={faMagnifyingGlassPlus}/>

</Button> */}

  </ButtonGroup>
</div>
)



}


export default PlayerButtonBar;